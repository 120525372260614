import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  GitBranch, 
  Search, 
  RefreshCw, 
  Settings, 
  FileCog, 
  Check, 
  X, 
  Edit, 
  Save, 
  ChevronRight,
  AlertTriangle,
  HelpCircle,
  Info
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import WorkflowStateTransitionDiagram from '../../components/shared/WorkflowStateTransitionDiagram';
import apiService from '../../services/api.service';



const AchWorkflowConfiguration = () => {
  const navigate = useNavigate();
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [configData, setConfigData] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'list'
  
  // Charger la liste des workflows ACH
  useEffect(() => {
    fetchWorkflows();
  }, []);

  // Filtrer les workflows selon le terme de recherche
  const filteredWorkflows = workflows.filter(workflow => 
    workflow.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    workflow.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (workflow.description && workflow.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Méthode pour charger les workflows
  const fetchWorkflows = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Cette URL est une approximation basée sur vos chemins d'API
      const response = await apiService.workflow.getAllWorkflowDefinitions(0, 100);
      
      // Filtrer pour ne garder que les workflows ACH
      const achWorkflows = response.workflows.filter(wf => 
        wf.code.includes("DECLARATION_PROCESS") && 
        (wf.code.startsWith("DECLARATION_PROCESS") || 
         wf.code.startsWith("DECLARATION_PROCESS") || 
         wf.code.startsWith("DECLARATION_PROCESS") || 
         wf.code.startsWith("DECLARATION_PROCESS"))
      );
      
      setWorkflows(achWorkflows);
      setSuccessMessage("Workflows ACH chargés avec succès");
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors du chargement des workflows ACH:", err);
      setError("Impossible de charger les workflows ACH. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Méthode pour charger les détails d'un workflow
  const fetchWorkflowDetails = async (code) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await apiService.workflow.getWorkflowDefinition(code);
      setSelectedWorkflow(response);
      setConfigData({
        code: response.code,
        name: response.name,
        description: response.description,
        initialState: response.initialState,
        finalStates: [...response.finalStates],
        isActive: response.isActive !== false // Par défaut à true si non spécifié
      });
    } catch (err) {
      console.error("Erreur lors du chargement des détails du workflow:", err);
      setError("Impossible de charger les détails du workflow. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Méthode pour mettre à jour un workflow
  const updateWorkflow = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Création de l'objet de mise à jour
      const updateData = {
        ...selectedWorkflow,
        name: configData.name,
        description: configData.description,
        initialState: configData.initialState,
        finalStates: configData.finalStates,
        isActive: configData.isActive
      };
      
      await apiService.workflow.saveDefinition(updateData);
      setSuccessMessage("Workflow mis à jour avec succès");
      setIsConfiguring(false);
      
      // Rafraîchir les données
      fetchWorkflowDetails(selectedWorkflow.code);
      fetchWorkflows();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du workflow:", err);
      setError("Impossible de mettre à jour le workflow. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Méthode pour annuler l'édition
  const cancelEditing = () => {
    setIsConfiguring(false);
    // Restaurer les données originales
    if (selectedWorkflow) {
      setConfigData({
        code: selectedWorkflow.code,
        name: selectedWorkflow.name,
        description: selectedWorkflow.description,
        initialState: selectedWorkflow.initialState,
        finalStates: [...selectedWorkflow.finalStates],
        isActive: selectedWorkflow.isActive !== false
      });
    }
  };

  // Méthode pour sélectionner un workflow et afficher ses détails
  const handleSelectWorkflow = (workflow) => {
    fetchWorkflowDetails(workflow.code);
  };

  // Configurer les colonnes pour la vue liste
  const columns = [
    {
      key: 'code',
      header: 'Code',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.code}
        </div>
      )
    },
    {
      key: 'name',
      header: 'Nom',
      render: (row) => (
        <div>
          <div className="font-medium">{row.name}</div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      key: 'initialState',
      header: 'État initial',
      render: (row) => (
        <div>
          <StatusBadge status="active" customLabel={row.initialState} size="sm" />
        </div>
      )
    },
    {
      key: 'status',
      header: 'Statut',
      render: (row) => (
        <StatusBadge 
          status={row.isActive !== false ? 'active' : 'inactive'} 
          customLabel={row.isActive !== false ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Settings}
            onClick={() => handleSelectWorkflow(row)}
          >
            Configurer
          </Button>
        </div>
      )
    }
  ];

  // Rendu de la vue principale selon le mode (avec ou sans workflow sélectionné)
  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <FileCog className="h-6 w-6 mr-2 text-blue-500" />
            Configuration des Workflows ACH
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Configurez et gérez les workflows de traitement ACH
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={viewMode === 'grid' ? GitBranch : Settings}
            onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
          >
            {viewMode === 'grid' ? 'Vue Liste' : 'Vue Grille'}
          </Button>
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchWorkflows}
            loading={loading && !selectedWorkflow}
          >
            Actualiser
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Section de recherche */}
      {!selectedWorkflow && (
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Rechercher un workflow..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}

      {/* Vue détaillée d'un workflow sélectionné */}
      {selectedWorkflow ? (
        <div className="space-y-6">
          {/* En-tête avec bouton retour */}
          <div className="flex items-center">
            <Button
              variant="outline"
              size="sm"
              icon={ChevronRight}
              iconPosition="left"
              onClick={() => setSelectedWorkflow(null)}
              className="transform rotate-180 mr-4"
            >
              Retour
            </Button>
            <h2 className="text-xl font-semibold">
              Workflow: {selectedWorkflow.name}
            </h2>
          </div>

          {/* Panneau d'information et de configuration */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Carte d'informations générales */}
            <Card 
              title="Informations générales" 
              icon={Info}
              actions={
                isConfiguring ? (
                  <div className="flex space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      icon={X}
                      onClick={cancelEditing}
                    >
                      Annuler
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      icon={Save}
                      onClick={updateWorkflow}
                      loading={loading}
                    >
                      Enregistrer
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Edit}
                    onClick={() => setIsConfiguring(true)}
                  >
                    Modifier
                  </Button>
                )
              }
            >
              <div className="space-y-4">
                {isConfiguring ? (
                  // Mode édition
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Code
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400"
                        value={configData.code}
                        disabled
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nom
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={configData.name}
                        onChange={(e) => setConfigData({...configData, name: e.target.value})}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Description
                      </label>
                      <textarea
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={configData.description || ''}
                        onChange={(e) => setConfigData({...configData, description: e.target.value})}
                        rows={3}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        État initial
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={configData.initialState}
                        onChange={(e) => setConfigData({...configData, initialState: e.target.value})}
                      />
                    </div>
                    <div>
                      <label className="flex items-center space-x-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                        <input
                          type="checkbox"
                          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={configData.isActive}
                          onChange={(e) => setConfigData({...configData, isActive: e.target.checked})}
                        />
                        <span>Workflow actif</span>
                      </label>
                    </div>
                  </>
                ) : (
                  // Mode affichage
                  <>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Code:</span>
                      <span className="font-medium text-gray-900 dark:text-white">{selectedWorkflow.code}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Nom:</span>
                      <span className="font-medium text-gray-900 dark:text-white">{selectedWorkflow.name}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Description:</span>
                      <span className="font-medium text-gray-900 dark:text-white text-right">{selectedWorkflow.description || '-'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">État initial:</span>
                      <span className="font-medium text-gray-900 dark:text-white">{selectedWorkflow.initialState}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                      <StatusBadge
                        status={selectedWorkflow.isActive !== false ? 'active' : 'inactive'}
                        customLabel={selectedWorkflow.isActive !== false ? 'Actif' : 'Inactif'}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>

            {/* Carte pour les états finaux */}
            <Card title="États finaux" icon={Check}>
              <div className="space-y-4">
                {isConfiguring ? (
                  // Mode édition pour les états finaux
                  <div className="space-y-2">
                    {configData.finalStates.map((state, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          type="text"
                          className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={state}
                          onChange={(e) => {
                            const updatedStates = [...configData.finalStates];
                            updatedStates[index] = e.target.value;
                            setConfigData({...configData, finalStates: updatedStates});
                          }}
                        />
                        <Button
                          variant="outline"
                          size="sm"
                          icon={X}
                          onClick={() => {
                            const updatedStates = configData.finalStates.filter((_, i) => i !== index);
                            setConfigData({...configData, finalStates: updatedStates});
                          }}
                        />
                      </div>
                    ))}
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        setConfigData({
                          ...configData, 
                          finalStates: [...configData.finalStates, '']
                        });
                      }}
                    >
                      Ajouter un état final
                    </Button>
                  </div>
                ) : (
                  // Mode affichage pour les états finaux
                  <div className="space-y-2">
                    {selectedWorkflow.finalStates && selectedWorkflow.finalStates.length > 0 ? (
                      selectedWorkflow.finalStates.map((state, index) => (
                        <div key={index} className="flex items-center space-x-2">
                          <StatusBadge status="inactive" customLabel={state} />
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-500 dark:text-gray-400">Aucun état final défini</p>
                    )}
                  </div>
                )}
              </div>
            </Card>

            {/* Carte pour les transitions */}
            <Card title="Transitions" icon={GitBranch}>
              <div className="space-y-4 max-h-60 overflow-y-auto">
                {selectedWorkflow.transitions && selectedWorkflow.transitions.length > 0 ? (
                  <div className="space-y-3">
                    {selectedWorkflow.transitions.map((transition, index) => (
                      <div key={index} className="p-2 border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <StatusBadge status="warning" customLabel={transition.fromState} size="sm" />
                            <ChevronRight className="h-4 w-4 text-gray-500" />
                            <StatusBadge 
                              status={selectedWorkflow.finalStates.includes(transition.toState) ? 'inactive' : 'warning'} 
                              customLabel={transition.toState} 
                              size="sm"
                            />
                          </div>
                        </div>
                        <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                          <span className="bg-blue-100 dark:bg-blue-900/30 px-2 py-1 rounded text-blue-800 dark:text-blue-300 text-xs">
                            {transition.event}
                          </span>
                          {transition.isAutomatic && (
                            <span className="ml-2 bg-green-100 dark:bg-green-900/30 px-2 py-1 rounded text-green-800 dark:text-green-300 text-xs">
                              Automatique
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 dark:text-gray-400">Aucune transition définie</p>
                )}
              </div>
            </Card>
          </div>

          {/* Diagramme du workflow */}
          <WorkflowStateTransitionDiagram
            workflowDefinition={selectedWorkflow}
            onRefresh={() => fetchWorkflowDetails(selectedWorkflow.code)}
            loading={loading}
          />
        </div>
      ) : (
        // Vue liste des workflows
        <>
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          ) : viewMode === 'list' ? (
            <Card>
              <DataGrid
                data={filteredWorkflows}
                columns={columns}
                loading={loading}
                emptyMessage="Aucun workflow ACH trouvé"
                onRowClick={handleSelectWorkflow}
              />
            </Card>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredWorkflows.length > 0 ? (
                filteredWorkflows.map((workflow) => (
                  <Card
                    key={workflow.code}
                    className="hover:shadow-md transition-shadow duration-200 cursor-pointer"
                    onClick={() => handleSelectWorkflow(workflow)}
                  >
                    <div className="space-y-4">
                      <div className="flex justify-between items-start">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{workflow.name}</h3>
                        <StatusBadge 
                          status={workflow.isActive !== false ? 'active' : 'inactive'} 
                          customLabel={workflow.isActive !== false ? 'Actif' : 'Inactif'} 
                          size="sm"
                        />
                      </div>
                      
                      <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
                        {workflow.description || "Aucune description disponible"}
                      </p>
                      
                      <div className="pt-4 border-t border-gray-100 dark:border-gray-800">
                        <div className="flex justify-between items-center">
                          <div className="text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-medium">État initial:</span> {workflow.initialState}
                          </div>
                          <Button
                            variant="text"
                            size="sm"
                            icon={Settings}
                          >
                            Configurer
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))
              ) : (
                <div className="col-span-3 flex justify-center items-center h-64">
                  <div className="text-center text-gray-500 dark:text-gray-400">
                    <HelpCircle className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                    <p>Aucun workflow ACH trouvé</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AchWorkflowConfiguration;