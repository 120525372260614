import React, { useState, useEffect } from 'react';
import { 
  Clock, 
  RefreshCw, 
  RotateCcw, 
  XCircle, 
  Search,
  Calendar,
  CheckCircle,
  AlertTriangle,
  Eye,
  ChevronRight,
  ChevronDown,
  Info,
  X,
  Filter,
  Download,
  FileText,
  Loader,
  PlayCircle,
  Plus,
  ChevronLeft
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

/**
 * Gestion des exécutions de tâches
 * Permet de visualiser, déclencher et gérer les exécutions de tâches planifiées
 */
const ExecutionsPage = () => {
  // États pour les données et le chargement
  const [executions, setExecutions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showTriggerModal, setShowTriggerModal] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  
  // État pour le formulaire de déclenchement manuel
  const [triggerForm, setTriggerForm] = useState({
    jobId: null,
    parameters: {},
    async: true
  });

  // Fonction pour sécuriser l'affichage des dates
  const SafeDateDisplay = ({ date, format = 'default', showTime = false }) => {
    if (!date) {
      return <span className="text-gray-400 dark:text-gray-500">-</span>;
    }
    
    try {
      // Vérifier si la date est valide
      const dateObj = new Date(date);
      if (isNaN(dateObj.getTime())) {
        return <span className="text-gray-400 dark:text-gray-500">Date invalide</span>;
      }
      
      // Si la date est valide, utiliser le DateFormatter
      return <DateFormatter date={date} format={format} showTime={showTime} />;
    } catch (error) {
      console.warn("Erreur d'affichage de date:", error);
      return <span className="text-gray-400 dark:text-gray-500">Date invalide</span>;
    }
  };

  // Fonction pour formater correctement les dates
  const formatDate = (dateInput) => {
    if (!dateInput) return null;
    
    try {
      // Cas spécial: si la date est un tableau [année, mois, jour, heure, minute, seconde, ms]
      if (Array.isArray(dateInput)) {
        // Format [année, mois-1, jour, heure, minute, seconde, ms]
        // Note: Dans JavaScript, le mois est 0-indexé (0 = janvier, 11 = décembre)
        // Mais dans les données reçues, le mois est probablement 1-indexé, donc nous devons soustraire 1
        const [year, month, day, hour, minute, second, ms] = dateInput;
        const date = new Date(year, month-1, day, hour, minute, second, ms ? Math.floor(ms/1000) : 0);
        
        // Vérifier si la date est valide
        if (isNaN(date.getTime())) {
          console.warn("Date invalide (tableau) détectée:", dateInput);
          return null;
        }
        
        return date.toISOString();
      }
      
      // Si c'est une chaîne ou un autre format
      let date;
      if (typeof dateInput === 'string') {
        // Vérifier si la date contient déjà un T (format ISO)
        if (dateInput.includes('T')) {
          date = new Date(dateInput);
        } else {
          // Si c'est une chaîne sans T, essayer de la convertir en format ISO
          date = new Date(dateInput.replace(/(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2})/, '$1T$2'));
        }
      } else {
        date = new Date(dateInput);
      }
      
      // Vérifier si la date est valide
      if (isNaN(date.getTime())) {
        console.warn("Date invalide détectée:", dateInput);
        return null;
      }
      
      return date.toISOString();
    } catch (error) {
      console.warn("Erreur lors du formatage de la date:", error, "Type:", typeof dateInput);
      return null;
    }
  };

  // Fonction pour charger la liste des tâches
  const fetchJobs = async () => {
    try {
      const response = await apiService.scheduler.jobs.getAll();
      console.log('Jobs data received:', response);
      
      // Normaliser les données pour ajouter la propriété 'id' basée sur 'jobId'
      const formattedJobs = response.map(job => ({
        ...job,
        id: job.jobId || job.id, // Utiliser jobId s'il existe, sinon id
        statusLabel: job.active ? 'Actif' : 'Inactif'
      }));
      
      console.log('Jobs data normalized:', formattedJobs);
      setJobs(formattedJobs);
      
      // Si aucune tâche n'est sélectionnée et qu'il y a au moins une tâche
      if (!selectedJobId && formattedJobs.length > 0) {
        setSelectedJobId(formattedJobs[0].id);
      }
    } catch (err) {
      console.error('Erreur lors du chargement des tâches:', err);
      setError("Impossible de charger la liste des tâches.");
    }
  };

  // Fonction pour charger les exécutions d'une tâche
  const fetchExecutions = async (jobId) => {
    if (!jobId) return;
    
    setLoading(true);
    setError(null);
    
    try {
      let status = filterStatus === 'all' ? null : filterStatus;
      const response = await apiService.scheduler.executions.getForJob(jobId, status);
      console.log('Executions data received:', response);
      
      // Trouver le job correspondant
      const jobData = jobs.find(job => job.id === parseInt(jobId));
      
      // Formater les données pour l'affichage et sécuriser les dates
      const formattedExecutions = response.map(execution => {
        // Formatage correct des dates
        let startTime = formatDate(execution.startTime);
        let endTime = formatDate(execution.endTime);
        
        return {
          ...execution,
          id: execution.executionId || execution.id, // Normaliser l'ID
          startTime: startTime,
          endTime: endTime,
          statusLabel: getStatusLabel(execution.status),
          job: execution.job || jobData // Utiliser les données du job si disponibles, sinon utiliser le job trouvé
        };
      });
      
      console.log('Executions data normalized:', formattedExecutions);
      setExecutions(formattedExecutions);
      setTotalItems(formattedExecutions.length);
    } catch (err) {
      console.error('Erreur lors du chargement des exécutions:', err);
      setError("Impossible de charger les exécutions de cette tâche.");
    } finally {
      setLoading(false);
    }
  };

  // Chargement initial
  useEffect(() => {
    fetchJobs();
  }, []);

  // Charger les exécutions lorsqu'une tâche est sélectionnée ou que le filtre change
  useEffect(() => {
    if (selectedJobId) {
      fetchExecutions(selectedJobId);
      setCurrentPage(1); // Réinitialiser la pagination lors du changement de job ou de filtre
    }
  }, [selectedJobId, filterStatus]);

  // Fonction pour obtenir le libellé du statut
  const getStatusLabel = (status) => {
    const statusLabels = {
      PENDING: 'En attente',
      RUNNING: 'En cours',
      COMPLETED: 'Terminé',
      FAILED: 'Échoué',
      CANCELLED: 'Annulé',
      TIMEOUT: 'Timeout',
      SKIPPED: 'Ignoré'
    };
    
    return statusLabels[status] || status;
  };

  // Fonction pour obtenir la variante du statut (pour StatusBadge)
  const getStatusVariant = (status) => {
    switch (status) {
      case 'COMPLETED':
        return 'active';
      case 'RUNNING':
      case 'PENDING':
        return 'warning';
      case 'FAILED':
      case 'CANCELLED':
      case 'TIMEOUT':
        return 'inactive';
      case 'SKIPPED':
        return 'paused';
      default:
        return 'warning';
    }
  };

  // Fonction pour filtrer les exécutions
  const filteredExecutions = executions.filter(execution => {
    if (searchTerm === '') return true;
    
    // Recherche dans plusieurs champs
    return (
      execution.id.toString().includes(searchTerm) ||
      (execution.executedBy && execution.executedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (execution.message && execution.message.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (execution.parameters && JSON.stringify(execution.parameters).toLowerCase().includes(searchTerm.toLowerCase())) ||
      (execution.job && execution.job.name && execution.job.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  // Pagination des résultats
  const paginatedExecutions = filteredExecutions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Nombre total de pages
  const totalPages = Math.ceil(filteredExecutions.length / itemsPerPage);

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour revenir à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Déclencher l'exécution d'une tâche
  const triggerJobExecution = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.scheduler.executions.trigger(triggerForm);
      setSuccessMessage("Tâche déclenchée avec succès!");
      setShowTriggerModal(false);
      
      // Actualiser les exécutions après le déclenchement
      setTimeout(() => {
        fetchExecutions(selectedJobId);
      }, 1000);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors du déclenchement de la tâche:', err);
      setError("Impossible de déclencher la tâche.");
    } finally {
      setLoading(false);
    }
  };

  // Relancer une exécution
  const retryExecution = async (executionId) => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.scheduler.executions.retry(executionId);
      setSuccessMessage("Exécution relancée avec succès!");
      
      // Actualiser les exécutions
      fetchExecutions(selectedJobId);
      
      // Fermer le modal de détails si ouvert
      if (showDetailModal) {
        setShowDetailModal(false);
      }
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la relance de l\'exécution:', err);
      setError("Impossible de relancer l'exécution.");
    } finally {
      setLoading(false);
    }
  };

  // Annuler une exécution
  const cancelExecution = async (executionId) => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.scheduler.executions.cancel(executionId);
      setSuccessMessage("Exécution annulée avec succès!");
      
      // Actualiser les exécutions
      fetchExecutions(selectedJobId);
      
      // Fermer le modal de détails si ouvert
      if (showDetailModal) {
        setShowDetailModal(false);
      }
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de l\'annulation de l\'exécution:', err);
      setError("Impossible d'annuler l'exécution.");
    } finally {
      setLoading(false);
    }
  };

  // Afficher les détails d'une exécution
  const viewExecutionDetails = async (executionId) => {
    setLoading(true);
    setError(null);
    
    try {
      const execution = await apiService.scheduler.executions.getById(executionId);
      
      // Formater correctement les dates
      let startTime = formatDate(execution.startTime);
      let endTime = formatDate(execution.endTime);
      
      // Récupérer les détails du job associé si non déjà présent
      let jobDetails = execution.job;
      if (!jobDetails && execution.jobId) {
        const jobData = jobs.find(job => job.id === execution.jobId || job.id === parseInt(execution.jobId));
        if (jobData) {
          jobDetails = jobData;
        }
      }
      
      const normalizedExecution = {
        ...execution,
        id: execution.executionId || execution.id,
        startTime,
        endTime,
        job: jobDetails
      };
      
      setSelectedExecution(normalizedExecution);
      setShowDetailModal(true);
    } catch (err) {
      console.error('Erreur lors du chargement des détails de l\'exécution:', err);
      setError("Impossible de charger les détails de l'exécution.");
    } finally {
      setLoading(false);
    }
  };

  // Ouvrir le modal de déclenchement manuel
  const openTriggerModal = (jobId) => {
    const job = jobs.find(j => j.id === jobId);
    
    setTriggerForm({
      jobId: jobId,
      parameters: {},
      async: true
    });
    
    setShowTriggerModal(true);
  };

  // Gérer les changements de paramètres pour le déclenchement manuel
  const handleParameterChange = (key, value) => {
    setTriggerForm(prev => ({
      ...prev,
      parameters: {
        ...prev.parameters,
        [key]: value
      }
    }));
  };

  // Ajouter un nouveau paramètre pour le déclenchement manuel
  const addParameter = () => {
    const key = prompt("Entrez le nom du paramètre:");
    if (key && key.trim() !== '') {
      const value = prompt("Entrez la valeur du paramètre:");
      if (value !== null) {
        handleParameterChange(key.trim(), value);
      }
    }
  };

  // Supprimer un paramètre pour le déclenchement manuel
  const removeParameter = (key) => {
    setTriggerForm(prev => {
      const updatedParams = { ...prev.parameters };
      delete updatedParams[key];
      return {
        ...prev,
        parameters: updatedParams
      };
    });
  };

  // Calculer la durée d'exécution
  const calculateDuration = (row) => {
    if (row.result && row.result.executionTimeMillis) {
      // Formater la durée
      const ms = row.result.executionTimeMillis;
      if (ms < 1000) {
        return <span>{ms} ms</span>;
      } else if (ms < 60000) {
        return <span>{(ms / 1000).toFixed(2)} s</span>;
      } else {
        const minutes = Math.floor(ms / 60000);
        const seconds = ((ms % 60000) / 1000).toFixed(0);
        return <span>{minutes} min {seconds} s</span>;
      }
    }
    
    // Si la tâche est en cours, calculer le temps écoulé depuis le démarrage
    if (row.status === 'RUNNING' && row.startTime) {
      try {
        const start = new Date(row.startTime).getTime();
        if (isNaN(start)) {
          return <span className="text-gray-400 dark:text-gray-500">-</span>;
        }
        
        const now = new Date().getTime();
        const elapsed = now - start;
        
        if (elapsed < 60000) {
          return (
            <div className="flex items-center">
              <Loader size={14} className="animate-spin mr-1 text-yellow-500" />
              <span>{(elapsed / 1000).toFixed(0)} s</span>
            </div>
          );
        } else {
          const minutes = Math.floor(elapsed / 60000);
          const seconds = ((elapsed % 60000) / 1000).toFixed(0);
          return (
            <div className="flex items-center">
              <Loader size={14} className="animate-spin mr-1 text-yellow-500" />
              <span>{minutes} min {seconds} s</span>
            </div>
          );
        }
      } catch (e) {
        console.warn('Erreur de calcul de durée:', e);
        return <span className="text-gray-400 dark:text-gray-500">-</span>;
      }
    }
    
    return <span className="text-gray-400 dark:text-gray-500">-</span>;
  };

  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'id',
      header: 'ID',
      sortable: true,
      render: (row) => (
        <span className="font-medium text-blue-600 dark:text-blue-400 cursor-pointer hover:underline" onClick={() => viewExecutionDetails(row.id)}>
          #{row.id}
        </span>
      )
    },
    {
      key: 'job',
      header: 'Tâche',
      sortable: true,
      render: (row) => (
        <div className="flex flex-col">
          <span className="font-medium">{row.job?.name || 'N/A'}</span>
          {row.job?.methodName && (
            <span className="text-xs text-gray-500">{row.job.methodName}</span>
          )}
        </div>
      )
    },
    {
      key: 'startTime',
      header: 'Démarrage',
      sortable: true,
      render: (row) => <SafeDateDisplay date={row.startTime} format="default" showTime />
    },
    {
      key: 'endTime',
      header: 'Fin',
      sortable: true,
      render: (row) => (
        row.endTime ? (
          <SafeDateDisplay date={row.endTime} format="default" showTime />
        ) : (
          <span className="text-gray-400 dark:text-gray-500">-</span>
        )
      )
    },
    {
      key: 'status',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={getStatusVariant(row.status)} 
          customLabel={row.statusLabel} 
          showIcon={true}
        />
      )
    },
    {
      key: 'executedBy',
      header: 'Exécuté par',
      sortable: true,
      render: (row) => (
        <span className="text-gray-700 dark:text-gray-300">
          {row.executedBy || 'Système'}
        </span>
      )
    },
    {
      key: 'duration',
      header: 'Durée',
      sortable: false,
      render: calculateDuration
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex items-center justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            onClick={() => viewExecutionDetails(row.id)}
            title="Voir les détails"
          />
          
          {row.status === 'RUNNING' && (
            <Button
              variant="text"
              size="sm"
              icon={XCircle}
              onClick={() => cancelExecution(row.id)}
              title="Annuler"
            />
          )}
          
          {(row.status === 'FAILED' || row.status === 'TIMEOUT') && (
            <Button
              variant="text"
              size="sm"
              icon={RotateCcw}
              onClick={() => retryExecution(row.id)}
              title="Réessayer"
            />
          )}
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Clock className="h-6 w-6 mr-2 text-blue-500" />
            Exécutions des tâches
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Suivez et gérez les exécutions des tâches planifiées
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={() => fetchExecutions(selectedJobId)}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlayCircle}
            onClick={() => openTriggerModal(selectedJobId)}
          >
            Exécuter
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Sélecteur de tâche et filtres */}
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/3">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Tâche
          </label>
          <select
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={selectedJobId || ''}
            onChange={(e) => setSelectedJobId(e.target.value ? parseInt(e.target.value) : null)}
          >
            {jobs.map(job => (
              <option key={`job-${job.id}`} value={job.id}>
                {job.name}
              </option>
            ))}
          </select>
        </div>
        
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Recherche
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Rechercher par ID, exécuteur, message..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        
        <div className="w-full md:w-1/4">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Statut
          </label>
          <select
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option key="status-all" value="all">Tous les statuts</option>
            <option key="status-running" value="RUNNING">En cours</option>
            <option key="status-completed" value="COMPLETED">Terminés</option>
            <option key="status-failed" value="FAILED">Échoués</option>
            <option key="status-cancelled" value="CANCELLED">Annulés</option>
            <option key="status-timeout" value="TIMEOUT">Timeout</option>
          </select>
        </div>
      </div>

      {/* Tableau des exécutions */}
      <Card>
        <DataGrid
          data={paginatedExecutions}
          columns={columns}
          loading={loading}
          emptyMessage="Aucune exécution trouvée pour cette tâche"
        />
        
        {/* Pagination */}
        {filteredExecutions.length > 0 && (
          <div className="mt-4 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 px-4 py-3">
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
              <span>
                Affichage de <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> à{' '}
                <span className="font-medium">
                  {Math.min(currentPage * itemsPerPage, filteredExecutions.length)}
                </span>{' '}
                sur <span className="font-medium">{filteredExecutions.length}</span> exécutions
              </span>
            </div>
            
            <div className="flex items-center space-x-2">
              <select
                className="px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setCurrentPage(1); // Réinitialiser à la première page
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
              
              <nav className="flex items-center space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  icon={ChevronLeft}
                  disabled={currentPage === 1}
                  onClick={prevPage}
                >
                  Précédent
                </Button>
                
                <span className="px-3 py-1 rounded-md bg-gray-100 dark:bg-gray-800 text-sm">
                  {currentPage} / {totalPages || 1}
                </span>
                
                <Button
                  variant="outline"size="sm"
                  icon={ChevronRight}
                  iconPosition="right"
                  disabled={currentPage >= totalPages}
                  onClick={nextPage}
                >
                  Suivant
                </Button>
              </nav>
            </div>
          </div>
        )}
      </Card>

      {/* Modal de détails d'exécution */}
      {showDetailModal && selectedExecution && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-3xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <FileText className="w-5 h-5 mr-2 text-blue-500" />
                Détails de l'exécution #{selectedExecution.id}
              </h3>
              <button
                onClick={() => setShowDetailModal(false)}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="px-6 py-4 max-h-[70vh] overflow-y-auto">
              <div className="space-y-6">
                {/* Section d'informations générales */}
                <div>
                  <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-3">
                    Informations générales
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">ID:</span>
                        <span className="font-medium">{selectedExecution.id}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Tâche:</span>
                        <span className="font-medium">{selectedExecution.job?.name || 'N/A'}</span>
                      </div>
                      {selectedExecution.job?.methodName && (
                        <div className="flex justify-between">
                          <span className="text-gray-500 dark:text-gray-400">Méthode:</span>
                          <span className="font-medium">{selectedExecution.job.methodName}</span>
                        </div>
                      )}
                      {selectedExecution.job?.className && (
                        <div className="flex justify-between">
                          <span className="text-gray-500 dark:text-gray-400">Classe:</span>
                          <span className="font-medium truncate max-w-[200px]" title={selectedExecution.job.className}>
                            {selectedExecution.job.className}
                          </span>
                        </div>
                      )}
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                        <StatusBadge 
                          status={getStatusVariant(selectedExecution.status)} 
                          customLabel={getStatusLabel(selectedExecution.status)} 
                        />
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Exécuté par:</span>
                        <span className="font-medium">{selectedExecution.executedBy || 'Système'}</span>
                      </div>
                      {selectedExecution.nodeId && (
                        <div className="flex justify-between">
                          <span className="text-gray-500 dark:text-gray-400">Noeud:</span>
                          <span className="font-medium">{selectedExecution.nodeId}</span>
                        </div>
                      )}
                    </div>
                    
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Démarrage:</span>
                        <SafeDateDisplay date={selectedExecution.startTime} format="full" showTime />
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Fin:</span>
                        {selectedExecution.endTime ? (
                          <SafeDateDisplay date={selectedExecution.endTime} format="full" showTime />
                        ) : (
                          <span className="text-gray-400 dark:text-gray-500">-</span>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Durée:</span>
                        <span className="font-medium">
                          {selectedExecution.result?.executionTimeMillis ? (
                            `${(selectedExecution.result.executionTimeMillis / 1000).toFixed(2)} secondes`
                          ) : (
                            selectedExecution.status === 'RUNNING' ? (
                              <span className="flex items-center">
                                <Loader size={14} className="animate-spin mr-1 text-yellow-500" />
                                <span>En cours</span>
                              </span>
                            ) : (
                              <span>N/A</span>
                            )
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Tentatives:</span>
                        <span className="font-medium">{selectedExecution.retryCount || 0}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Section des résultats */}
                <div>
                  <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-3">
                    Résultat</h4>
                  {selectedExecution.result ? (
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Succès:</span>
                        <span className={selectedExecution.result.success ? 'text-green-500' : 'text-red-500'}>
                          {selectedExecution.result.success ? 'Oui' : 'Non'}
                        </span>
                      </div>
                      {selectedExecution.result.message && (
                        <div>
                          <span className="text-gray-500 dark:text-gray-400">Message:</span>
                          <div className="mt-1 p-3 bg-gray-50 dark:bg-gray-700 rounded-md">
                            <p className="whitespace-pre-wrap text-gray-800 dark:text-gray-200">
                              {selectedExecution.result.message}
                            </p>
                          </div>
                        </div>
                      )}
                      {selectedExecution.result.errorMessage && (
                        <div>
                          <span className="text-gray-500 dark:text-gray-400">Erreur:</span>
                          <div className="mt-1 p-3 bg-red-50 dark:bg-red-900/20 rounded-md">
                            <p className="whitespace-pre-wrap text-red-800 dark:text-red-200">
                              {selectedExecution.result.errorMessage}
                            </p>
                          </div>
                        </div>
                      )}
                      {selectedExecution.failureReason && !selectedExecution.result.errorMessage && (
                        <div>
                          <span className="text-gray-500 dark:text-gray-400">Raison échec:</span>
                          <div className="mt-1 p-3 bg-red-50 dark:bg-red-900/20 rounded-md">
                            <p className="whitespace-pre-wrap text-red-800 dark:text-red-200">
                              {selectedExecution.failureReason}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-md">
                      <p className="text-gray-500 dark:text-gray-400">
                        {selectedExecution.status === 'RUNNING' ? (
                          <span className="flex items-center">
                            <Loader size={16} className="animate-spin mr-2 text-yellow-500" />
                            Exécution en cours...
                          </span>
                        ) : (
                          'Aucun résultat disponible'
                        )}
                      </p>
                    </div>
                  )}
                </div>
                
                {/* Section des paramètres */}
                {selectedExecution.parameters && Object.keys(selectedExecution.parameters).length > 0 && (
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-3">
                      Paramètres
                    </h4>
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-md p-4">
                      <div className="space-y-2">
                        {Object.entries(selectedExecution.parameters).map(([key, value]) => (
                          <div key={`param-${key}`} className="flex justify-between">
                            <span className="text-gray-700 dark:text-gray-300 font-mono">{key}:</span>
                            <span className="text-gray-900 dark:text-gray-100 font-mono">
                              {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className="px-6 py-4 border-t border-gray-200 dark:border-gray-700 flex justify-end space-x-2">
              {selectedExecution.status === 'RUNNING' && (
                <Button
                  variant="danger"
                  icon={XCircle}
                  onClick={() => cancelExecution(selectedExecution.id)}
                >
                  Annuler
                </Button>
              )}
              {(selectedExecution.status === 'FAILED' || selectedExecution.status === 'TIMEOUT') && (
                <Button
                  variant="primary"
                  icon={RotateCcw}
                  onClick={() => retryExecution(selectedExecution.id)}
                >
                  Réessayer
                </Button>
              )}
              <Button
                variant="outline"
                onClick={() => setShowDetailModal(false)}
              >
                Fermer
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de déclenchement manuel */}
      {showTriggerModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <PlayCircle className="w-5 h-5 mr-2 text-blue-500" />
                Déclencher une exécution manuelle
              </h3>
              <button
                onClick={() => setShowTriggerModal(false)}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={triggerJobExecution}>
              <div className="px-6 py-4">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Tâche à exécuter
                    </label>
                    <select
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={triggerForm.jobId || ''}
                      onChange={(e) => setTriggerForm({...triggerForm, jobId: parseInt(e.target.value)})}
                      required
                    >
                      {jobs.map(job => (
                        <option key={`trigger-job-${job.id}`} value={job.id}>
                          {job.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Paramètres
                      </label>
                      <Button
                        variant="outline"
                        size="sm"
                        icon={Plus}
                        onClick={addParameter}
                        type="button"
                      >
                        Ajouter
                      </Button>
                    </div>
                    {Object.keys(triggerForm.parameters).length > 0 ? (
                      <div className="space-y-2 bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
                        {Object.entries(triggerForm.parameters).map(([key, value]) => (
                          <div key={`param-field-${key}`} className="flex items-center justify-between">
                            <div className="flex-1 flex items-center space-x-2">
                              <span className="font-medium text-gray-700 dark:text-gray-300">{key}:</span>
                              <input
                                type="text"
                                className="flex-1 px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                value={value}
                                onChange={(e) => handleParameterChange(key, e.target.value)}
                              />
                            </div>
                            <Button
                              variant="text"
                              size="sm"
                              icon={X}
                              onClick={() => removeParameter(key)}
                              type="button"
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-gray-500 dark:text-gray-400 text-sm mb-2">
                        Aucun paramètre. Cliquez sur "Ajouter" pour définir des paramètres.
                      </div>
                    )}
                  </div>
                  
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="asyncExecution"
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={triggerForm.async}
                      onChange={(e) => setTriggerForm({...triggerForm, async: e.target.checked})}
                    />
                    <label htmlFor="asyncExecution" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                      Exécution asynchrone
                    </label>
                    <div className="ml-2 group relative">
                      <Info size={16} className="text-gray-400 cursor-help" />
                      <div className="absolute bottom-full mb-2 w-64 bg-gray-900 text-white text-xs rounded shadow-lg p-2 invisible group-hover:visible">
                        L'exécution asynchrone permet de lancer la tâche en arrière-plan sans bloquer l'interface utilisateur.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 flex justify-end space-x-2">
                <Button
                  variant="outline"
                  onClick={() => setShowTriggerModal(false)}
                  type="button"
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={PlayCircle}
                  type="submit"
                  loading={loading}
                >
                  Exécuter
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExecutionsPage;