import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, CheckCircle, Clock, ArrowRight, FileText } from 'lucide-react';
import apiService from '../../services/api.service';

const DeclarationWorkflow = () => {
  const navigate = useNavigate();
  const [declarationNumber, setDeclarationNumber] = useState('');
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  const startWorkflow = async () => {
    if (!declarationNumber) {
      setError('Le numéro de déclaration est requis');
      return;
    }

    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('Session expirée');
      navigate('/login');
      return;
    }

    setLoading(true);
    try {
      const workflowData = {
        declarationNumber: declarationNumber,
        additionalData: {
          declarantCode: "DEC123",
          officeCode: "001"
        }
      };

      const data = await apiService.declarationWorkflow.start(workflowData);
      setCurrentWorkflow(data);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors du démarrage du workflow');
    } finally {
      setLoading(false);
    }
  };

  const initiatePayment = async () => {
    if (!currentWorkflow?.reference) return;
    
    try {
      const paymentData = {
        paymentMethod: 'BANK_TRANSFER',
        bankCode: '001',
        amount: currentWorkflow.totalAmount
      };
      const data = await apiService.declarationWorkflow.initiatePayment(
        currentWorkflow.reference, 
        paymentData
      );
      setCurrentWorkflow(data);
    } catch (err) {
      setError(err.message || 'Erreur lors de l\'initiation du paiement');
    }
  };

  const validatePayment = async () => {
    if (!currentWorkflow?.reference) return;

    try {
      const data = await apiService.declarationWorkflow.validatePayment(currentWorkflow.reference);
      setCurrentWorkflow(data);
    } catch (err) {
      setError(err.message || 'Erreur lors de la validation du paiement');
    }
  };

  const completePayment = async () => {
    if (!currentWorkflow?.reference) return;

    try {
      const completionData = {
        workflowReference: currentWorkflow.reference,
        officeCode: currentWorkflow.officeCode,
        declarantCode: currentWorkflow.declarantCode,
      };
      const data = await apiService.declarationWorkflow.completePayment(
        currentWorkflow.reference,
        completionData
      );
      setCurrentWorkflow(data);
    } catch (err) {
      setError(err.message || 'Erreur lors de la finalisation du paiement');
    }
  };

  const Status = ({ currentState }) => {
    const statusConfigs = {
      'DRAFT': {
        Icon: Clock,
        color: 'text-gray-500',
        bg: 'bg-gray-100'
      },
      'PAYMENT_INITIATED': {
        Icon: ArrowRight,
        color: 'text-blue-500',
        bg: 'bg-blue-100'
      },
      'PAYMENT_VALIDATED': {
        Icon: CheckCircle,
        color: 'text-green-500',
        bg: 'bg-green-100'
      },
      'COMPLETED': {
        Icon: CheckCircle,
        color: 'text-green-500',
        bg: 'bg-green-100'
      },
      'ERROR': {
        Icon: AlertTriangle,
        color: 'text-red-500',
        bg: 'bg-red-100'
      }
    };

    const status = currentState || 'DRAFT';
    const config = statusConfigs[status] || statusConfigs.DRAFT;
    const { Icon, color, bg } = config;

    return (
      <div className={`flex items-center space-x-2 p-2 rounded-lg ${bg}`}>
        <Icon className={`w-5 h-5 ${color}`} />
        <span className="font-medium">{status}</span>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg border shadow-sm">
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Gestion des déclarations ASYCUDA</h2>
          
          <div className="space-y-4">
            {error && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 text-red-700">
                {error}
              </div>
            )}

            <div className="flex space-x-4">
              <input 
                type="text"
                value={declarationNumber}
                onChange={(e) => setDeclarationNumber(e.target.value)}
                placeholder="Numéro de déclaration"
                className="flex-1 max-w-sm px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <button 
                onClick={startWorkflow}
                disabled={loading}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
              >
                {loading ? 'Chargement...' : 'Démarrer'}
              </button>
            </div>

            {currentWorkflow && (
              <div className="space-y-4 pt-4 border-t">
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="font-medium">Référence: {currentWorkflow.reference}</h3>
                    <p className="text-sm text-gray-500">
                      Déclarant: {currentWorkflow.declarantName}
                    </p>
                  </div>
                  <Status currentState={currentWorkflow.currentState} />
                </div>

                <div className="flex space-x-4">
                  <button
                    onClick={initiatePayment}
                    disabled={currentWorkflow.currentState !== 'DRAFT'}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
                  >
                    Initier le paiement
                  </button>
                  <button
                    onClick={validatePayment}
                    disabled={currentWorkflow.currentState !== 'PAYMENT_INITIATED'}
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 disabled:opacity-50"
                  >
                    Valider le paiement
                  </button>
                  <button
                    onClick={completePayment}
                    disabled={currentWorkflow.currentState !== 'PAYMENT_VALIDATED'}
                    className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 disabled:opacity-50"
                  >
                    Finaliser le paiement
                  </button>
                </div>

                {currentWorkflow.history?.length > 0 && (
                  <div className="mt-4">
                    <h4 className="font-medium mb-2">Historique</h4>
                    <div className="space-y-2">
                      {currentWorkflow.history.map((entry, index) => (
                        <div 
                          key={index}
                          className="flex items-center space-x-2 text-sm text-gray-600"
                        >
                          <FileText className="w-4 h-4" />
                          <span>{entry.event}</span>
                          <span className="text-gray-400">•</span>
                          <span>{new Date(entry.timestamp).toLocaleString()}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclarationWorkflow;