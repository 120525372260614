// components/shared/workflow/WorkflowControls.js
import React from 'react';
import { Save, Plus, Trash2, Edit, Download, Upload, RotateCcw, Settings } from 'lucide-react';
import { Panel } from 'reactflow';

// En-tête du workflow avec actions
export function WorkflowHeader({ 
  name, 
  onSettingsClick, 
  onImport, 
  onExport, 
  onReset, 
  onSave 
}) {
  return (
    <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-gray-700">
      <div className="flex items-center">
        <h1 className="text-xl font-bold text-gray-900 dark:text-white">
          {name}
        </h1>
        <button 
          onClick={onSettingsClick}
          className="ml-2 p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-full"
        >
          <Settings size={16} />
        </button>
      </div>
      
      <div className="flex gap-2">
        <div className="relative">
          <input
            type="file"
            id="import-file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            accept=".json"
            onChange={onImport}
          />
          <button className="flex items-center bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 px-3 py-1.5 text-sm rounded-md hover:bg-gray-200 dark:hover:bg-gray-700">
            <Upload size={16} className="mr-1" />
            Importer
          </button>
        </div>
        
        <button 
          onClick={onExport}
          className="flex items-center bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 px-3 py-1.5 text-sm rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
        >
          <Download size={16} className="mr-1" />
          Exporter
        </button>
        
        <button 
          onClick={onReset}
          className="flex items-center bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-3 py-1.5 text-sm rounded-md hover:bg-red-100 dark:hover:bg-red-900/30"
        >
          <RotateCcw size={16} className="mr-1" />
          Réinitialiser
        </button>
        
        <button 
          onClick={onSave} 
          className="flex items-center bg-blue-600 text-white px-3 py-1.5 text-sm rounded-md hover:bg-blue-700"
        >
          <Save size={16} className="mr-1" />
          Enregistrer
        </button>
      </div>
    </div>
  );
}

// Panneau des outils de création de nœuds
export function NodeToolsPanel({ onAddNode }) {
  return (
    <Panel position="top-left" className="bg-white dark:bg-gray-800 p-2 rounded-md shadow-md">
      <div className="flex flex-col gap-2">
        <button
          onClick={() => onAddNode('stateNode')}
          className="flex items-center bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-3 py-1.5 text-sm rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
        >
          <Plus size={16} className="mr-2" />
          État
        </button>
        
        <button
          onClick={() => onAddNode('initialNode')}
          className="flex items-center bg-green-100 dark:bg-green-900/20 text-green-700 dark:text-green-400 px-3 py-1.5 text-sm rounded-md hover:bg-green-200 dark:hover:bg-green-900/30"
        >
          <Plus size={16} className="mr-2" />
          État Initial
        </button>
        
        <button
          onClick={() => onAddNode('finalNode')}
          className="flex items-center bg-red-100 dark:bg-red-900/20 text-red-700 dark:text-red-400 px-3 py-1.5 text-sm rounded-md hover:bg-red-200 dark:hover:bg-red-900/30"
        >
          <Plus size={16} className="mr-2" />
          État Final
        </button>
      </div>
    </Panel>
  );
}

// Panneau d'édition des éléments sélectionnés
export function SelectionControlsPanel({ 
  selectedNode, 
  selectedEdge, 
  onEditNode, 
  onEditEdge, 
  onDelete 
}) {
  if (!selectedNode && !selectedEdge) return null;
  
  return (
    <Panel position="top-right" className="bg-white dark:bg-gray-800 p-2 rounded-md shadow-md">
      <div className="flex flex-col gap-2">
        {selectedNode && (
          <button
            onClick={onEditNode}
            className="flex items-center bg-blue-100 dark:bg-blue-900/20 text-blue-700 dark:text-blue-400 px-3 py-1.5 text-sm rounded-md hover:bg-blue-200 dark:hover:bg-blue-900/30"
          >
            <Edit size={16} className="mr-2" />
            Modifier l'état
          </button>
        )}
        
        {selectedEdge && (
          <button
            onClick={onEditEdge}
            className="flex items-center bg-blue-100 dark:bg-blue-900/20 text-blue-700 dark:text-blue-400 px-3 py-1.5 text-sm rounded-md hover:bg-blue-200 dark:hover:bg-blue-900/30"
          >
            <Edit size={16} className="mr-2" />
            Modifier la transition
          </button>
        )}
        
        <button
          onClick={onDelete}
          className="flex items-center bg-red-100 dark:bg-red-900/20 text-red-700 dark:text-red-400 px-3 py-1.5 text-sm rounded-md hover:bg-red-200 dark:hover:bg-red-900/30"
        >
          <Trash2 size={16} className="mr-2" />
          Supprimer
        </button>
      </div>
    </Panel>
  );
}