import React, { useState, useEffect } from 'react';
import { GitBranch, Search, RefreshCw, AlertTriangle, ArrowRight, Eye, Calendar } from 'lucide-react';
import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import WorkflowStateTransitionDiagram from '../../components/shared/WorkflowStateTransitionDiagram';
import apiService from '../../services/api.service';

const WorkflowManagement = () => {
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'detail'

  // Fonction pour charger la liste des workflows
  const fetchWorkflows = async () => {
    setLoading(true);
    try {
      const response = await apiService.workflow.getAllWorkflowDefinitions(0, 100); // Pagination par défaut
      setWorkflows(response.workflows || []);
      setError(null);
    } catch (err) {
      setError('Erreur lors du chargement des workflows: ' + (err.message || 'Une erreur est survenue'));
      console.error('Error fetching workflows:', err);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger les détails d'un workflow spécifique
  const fetchWorkflowDetails = async (code) => {
    setLoading(true);
    try {
      const response = await apiService.workflow.getWorkflowDefinition(code);
      setSelectedWorkflow(response);
      setViewMode('detail');
      setError(null);
    } catch (err) {
      setError('Erreur lors du chargement du workflow: ' + (err.message || 'Une erreur est survenue'));
      console.error('Error fetching workflow details:', err);
    } finally {
      setLoading(false);
    }
  };

  // Chargement initial des workflows
  useEffect(() => {
    fetchWorkflows();
  }, []);

  // Filtrer les workflows selon la recherche
  const filteredWorkflows = workflows.filter(workflow => 
    workflow.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    workflow.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (workflow.description && workflow.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Fonction pour revenir à la liste
  const backToList = () => {
    setViewMode('list');
    setSelectedWorkflow(null);
  };

  // Fonction auxiliaire pour vérifier si une date est valide
  const isValidDate = (dateStr) => {
    if (!dateStr) return false;
    const date = new Date(dateStr);
    return !isNaN(date.getTime());
  };

  // Affichage de l'écran de chargement
  if (loading && viewMode === 'list') {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Affichage de l'erreur
  if (error) {
    return (
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-900/10 dark:text-red-400">
        <AlertTriangle className="flex-shrink-0 inline w-5 h-5 mr-3" />
        <span className="font-medium">{error}</span>
      </div>
    );
  }

  // Affichage des détails d'un workflow
  if (viewMode === 'detail' && selectedWorkflow) {
    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <div className="flex items-center space-x-2">
              <Button
                variant="text"
                icon={ArrowRight}
                iconPosition="left"
                onClick={backToList}
              >
                Retour à la liste
              </Button>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                {selectedWorkflow.name}
              </h1>
              <StatusBadge 
                status={selectedWorkflow.activeInstances > 0 ? 'active' : 'inactive'} 
                customLabel={`${selectedWorkflow.activeInstances} instance${selectedWorkflow.activeInstances !== 1 ? 's' : ''}`}
              />
            </div>
            {selectedWorkflow.description && (
              <p className="text-gray-500 dark:text-gray-400 mt-1">
                {selectedWorkflow.description}
              </p>
            )}
          </div>
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={() => fetchWorkflowDetails(selectedWorkflow.code)}
          >
            Actualiser
          </Button>
        </div>

        {/* Information générale du workflow */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <Card title="Informations générales" icon={GitBranch}>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">Code:</span>
                <span className="font-medium">{selectedWorkflow.code}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">État initial:</span>
                <span className="font-medium">{selectedWorkflow.initialState}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">États finaux:</span>
                <div className="flex flex-wrap justify-end gap-1">
                  {selectedWorkflow.finalStates && selectedWorkflow.finalStates.length > 0 ? (
                    selectedWorkflow.finalStates.map(state => (
                      <StatusBadge 
                        key={state} 
                        status="inactive" 
                        customLabel={state} 
                        size="sm"
                      />
                    ))
                  ) : (
                    <span className="text-gray-400">-</span>
                  )}
                </div>
              </div>
            </div>
          </Card>
          
          {/* Paramètres */}
          <Card title="Paramètres" icon={Settings}>
            {selectedWorkflow.parameters && Object.keys(selectedWorkflow.parameters).length > 0 ? (
              <div className="space-y-2">
                {Object.entries(selectedWorkflow.parameters).map(([key, value]) => (
                  <div key={key} className="flex justify-between">
                    <span className="text-gray-500 dark:text-gray-400">{key}:</span>
                    <span className="font-medium">{value}</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-4 text-gray-500 dark:text-gray-400">
                Aucun paramètre défini
              </div>
            )}
          </Card>
          
          {/* Statistiques */}
          <Card title="Statistiques" icon={Chart}>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">Instances actives:</span>
                <span className="font-medium">{selectedWorkflow.activeInstances}</span>
              </div>
              {isValidDate(selectedWorkflow.lastUpdated) && (
                <div className="flex justify-between">
                  <span className="text-gray-500 dark:text-gray-400">Dernière mise à jour:</span>
                  <DateFormatter date={selectedWorkflow.lastUpdated} format="full" />
                </div>
              )}
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">Transitions:</span>
                <span className="font-medium">{selectedWorkflow.transitions?.length || 0}</span>
              </div>
            </div>
          </Card>
        </div>

        {/* Visualisation du workflow */}
        <WorkflowStateTransitionDiagram 
          workflowDefinition={selectedWorkflow} 
          onRefresh={() => fetchWorkflowDetails(selectedWorkflow.code)}
          loading={loading}
        />
      </div>
    );
  }

  // Affichage de la liste des workflows
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Gestion des Workflows
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Liste des définitions de workflow disponibles dans le système
          </p>
        </div>
        <Button
          variant="outline"
          icon={RefreshCw}
          onClick={fetchWorkflows}
        >
          Actualiser
        </Button>
      </div>

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md 
                    bg-white dark:bg-gray-700 text-gray-900 dark:text-white 
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher un workflow..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des workflows */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredWorkflows.length === 0 ? (
          <div className="col-span-3 text-center py-8 text-gray-500 dark:text-gray-400">
            Aucun workflow trouvé
          </div>
        ) : (
          filteredWorkflows.map(workflow => (
            <Card 
              key={workflow.code}
              className="hover:shadow-md transition-shadow duration-200 cursor-pointer"
              onClick={() => fetchWorkflowDetails(workflow.code)}
            >
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {workflow.name}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2 mt-1">
                    {workflow.description || 'Aucune description'}
                  </p>
                </div>
                <StatusBadge 
                  status={workflow.activeInstances > 0 ? 'active' : 'inactive'} 
                  customLabel={`${workflow.activeInstances}`}
                  size="sm"
                />
              </div>
              
              <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-800 flex justify-between items-center text-sm">
                <div className="flex items-center text-gray-500 dark:text-gray-400">
                  <GitBranch size={14} className="mr-1" />
                  <span>État initial: <strong>{workflow.initialState}</strong></span>
                </div>
                
                <Button
                  variant="text"
                  size="sm"
                  icon={Eye}
                >
                  Détails
                </Button>
              </div>
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

// Ajout d'icônes manquantes
const Settings = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...props}>
      <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
      <circle cx="12" cy="12" r="3"></circle>
    </svg>
  );
};

const Chart = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...props}>
      <path d="M3 3v18h18"></path>
      <path d="M18 17V9"></path>
      <path d="M13 17V5"></path>
      <path d="M8 17v-3"></path>
    </svg>
  );
};

export default WorkflowManagement;