import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button as MuiButton,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Divider,
  Card as MuiCard,
  CardHeader,
  CardContent,
  CardActions,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Switch,
  FormControlLabel,
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  LinearProgress,
  CircularProgress,
  Tooltip,
  AppBar,
  Toolbar
} from '@mui/material';

import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RTooltip,
  Legend,
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Cell,
  Scatter,
  ScatterChart,
  ZAxis,
  ComposedChart
} from 'recharts';

import {
  AlertTriangle,
  Clock,
  CheckCircle,
  XCircle,
  BarChart2,
  FileText,
  Filter,
  Search,
  RefreshCw,
  Download,
  ChevronDown,
  ChevronUp,
  Calendar,
  Play,
  ArrowLeft,
  ArrowRight,
  Clipboard,
  SkipBack,
  SkipForward,
  Maximize2,
  Minimize2,
  Eye,
  EyeOff,
  List as ListIcon,
  Grid as GridIcon,
  PieChart as PieChartIcon,
  Activity,
  Settings,
  Layers,
  Repeat,
  Target,
  HelpCircle,
  Info,
  Zap,
  Database,
  Server,
  Network,
  Code,
  Home,
  Users,
  Briefcase,
  BarChart as BarChartIcon,
  LineChart as LineChartIcon,
  GitCommit,
  Command,
  Heart,
  CloudLightning
} from 'lucide-react';

import api from '../../services/api.service';
import DateFormatter from '../../components/shared/DateFormatter';

// Définir les couleurs du thème comme dans MissionControlDashboard
const COLORS = {
  primary: '#3f51b5',
  secondary: '#f50057',
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
  info: '#2196f3',
  campaign: '#3f51b5',
  testCase: '#673ab7',
  execution: '#009688', 
  result: '#ff5722',
  chartColors: ['#3f51b5', '#03a9f4', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#673ab7', '#009688', '#ff5722']
};

const TestResultAnalyzer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const executionIdFromUrl = queryParams.get('executionId');
  
  // États principaux pour les données
  const [execution, setExecution] = useState(null);
  const [testResults, setTestResults] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const [executionHistory, setExecutionHistory] = useState([]);
  const [selectedExecution, setSelectedExecution] = useState(null);
  
  // États pour les filtres et l'UI
  const [activeTab, setActiveTab] = useState('overview');
  const [resultFilter, setResultFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('startTime');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filteredResults, setFilteredResults] = useState([]);
  const [trendPeriod, setTrendPeriod] = useState('month'); // 'week', 'month', 'year'
  
  // États pour le chargement et les erreurs
  const [loading, setLoading] = useState({
    execution: true,
    results: true,
    campaign: false,
    history: false,
    stats: false
  });
  const [error, setError] = useState({
    execution: null,
    results: null,
    campaign: null,
    history: null,
    stats: null
  });
  
  // Métriques et statistiques calculées
  const [metrics, setMetrics] = useState({
    totalTests: 0,
    passRate: 0,
    avgDuration: 0,
    criticalTestsStatus: {
      passed: 0,
      failed: 0,
      skipped: 0,
      error: 0
    },
    testsByStatus: {
      passed: 0,
      failed: 0,
      skipped: 0,
      error: 0
    },
    testsByDuration: [],
    topFailingTests: [],
    statusDistribution: [],
    executionTrend: []
  });

  // État pour le mode plein écran
  const [fullScreenMode, setFullScreenMode] = useState(null);
  
  // Récupérer les détails de l'exécution
  const fetchExecutionDetails = useCallback(async (executionId) => {
    if (!executionId) return;
    
    setLoading(prev => ({ ...prev, execution: true }));
    setError(prev => ({ ...prev, execution: null }));
    
    try {
      const response = await api.automata.executions.getById(executionId);
      setExecution(response);
      
      // Si l'exécution a une campagne associée, récupérer les détails de la campagne
      if (response.campaign && response.campaign.id) {
        fetchCampaignDetails(response.campaign.id);
        
        // Et récupérer l'historique des exécutions pour cette campagne
        fetchExecutionHistory(response.campaign.id);
      }
      
      // Définir cette exécution comme l'exécution sélectionnée
      setSelectedExecution(response);
      
    } catch (err) {
      console.error('Error fetching execution details:', err);
      setError(prev => ({ ...prev, execution: 'Failed to load execution details' }));
    } finally {
      setLoading(prev => ({ ...prev, execution: false }));
    }
  }, []);
  
  // Récupérer les résultats des tests
  const fetchTestResults = useCallback(async (executionId) => {
    if (!executionId) return;
    
    setLoading(prev => ({ ...prev, results: true }));
    setError(prev => ({ ...prev, results: null }));
    
    try {
      const response = await api.automata.executions.getResults(executionId);
      setTestResults(response);
      
      // Calculer les métriques
      calculateMetrics(response, execution);
      
    } catch (err) {
      console.error('Error fetching test results:', err);
      setError(prev => ({ ...prev, results: 'Failed to load test results' }));
    } finally {
      setLoading(prev => ({ ...prev, results: false }));
    }
  }, [execution]);
  
  // Récupérer les détails de la campagne
  const fetchCampaignDetails = async (campaignId) => {
    if (!campaignId) return;
    
    setLoading(prev => ({ ...prev, campaign: true }));
    setError(prev => ({ ...prev, campaign: null }));
    
    try {
      const response = await api.automata.campaigns.getById(campaignId);
      setCampaign(response);
    } catch (err) {
      console.error('Error fetching campaign details:', err);
      setError(prev => ({ ...prev, campaign: 'Failed to load campaign details' }));
    } finally {
      setLoading(prev => ({ ...prev, campaign: false }));
    }
  };
  
  // Récupérer l'historique des exécutions
  const fetchExecutionHistory = async (campaignId) => {
    if (!campaignId) return;
    
    setLoading(prev => ({ ...prev, history: true }));
    setError(prev => ({ ...prev, history: null }));
    
    try {
      const response = await api.automata.executions.getAll(campaignId);
      
      // Trier par date de début
      const sortedExecutions = response.sort((a, b) => {
        return new Date(b.startTime) - new Date(a.startTime);
      });
      
      setExecutionHistory(sortedExecutions);
      
      // Calculer les tendances
      calculateTrends(sortedExecutions);
      
    } catch (err) {
      console.error('Error fetching execution history:', err);
      setError(prev => ({ ...prev, history: 'Failed to load execution history' }));
    } finally {
      setLoading(prev => ({ ...prev, history: false }));
    }
  };
  
  // Calculer les statistiques supplémentaires
  const calculateExecutionStats = async (executionId) => {
    if (!executionId) return;
    
    setLoading(prev => ({ ...prev, stats: true }));
    setError(prev => ({ ...prev, stats: null }));
    
    try {
      const response = await api.automata.executions.calculateStatistics(executionId);
      
      // Mettre à jour l'exécution avec les nouvelles statistiques
      setExecution(response);
      
      // Si c'est l'exécution sélectionnée, mettre à jour également
      if (selectedExecution && selectedExecution.id === executionId) {
        setSelectedExecution(response);
      }
      
    } catch (err) {
      console.error('Error calculating execution statistics:', err);
      setError(prev => ({ ...prev, stats: 'Failed to calculate statistics' }));
    } finally {
      setLoading(prev => ({ ...prev, stats: false }));
    }
  };
  
  // Générer un rapport
  const generateReport = async (formatType = 'PDF') => {
    if (!execution || !execution.id) return;
    
    try {
      // Récupérer l'ID utilisateur (à adapter selon votre système d'authentification)
      const userId = localStorage.getItem('user_id') || 'anonymous';
      
      // Métadonnées pour le rapport
      const metadata = {
        generatedBy: userId,
        timestamp: new Date().toISOString(),
        title: `Rapport d'exécution - ${execution.executionName || 'Sans nom'}`
      };
      
      const response = await api.automata.reports.generateExecutionReport(
        execution.id, 
        formatType, 
        metadata, 
        userId
      );
      
      // Rediriger vers la page de téléchargement du rapport
      if (response && response.id) {
        window.location.href = `/atm-campaign/cpg-06-reportinghub?reportId=${response.id}`;
      }
      
    } catch (err) {
      console.error('Error generating report:', err);
      // Afficher une notification d'erreur
    }
  };
  
  // Calculer les métriques à partir des résultats
  const calculateMetrics = (results, executionData) => {
    if (!results || results.length === 0 || !executionData) return;
    
    // Comptage par statut
    const testsByStatus = {
      passed: 0,
      failed: 0,
      skipped: 0,
      error: 0
    };
    
    // Comptage pour les tests critiques
    const criticalTestsStatus = {
      passed: 0,
      failed: 0,
      skipped: 0,
      error: 0
    };
    
    // Durations pour calculer la moyenne
    let totalDuration = 0;
    const testsByDuration = [];
    
    // Trouver les tests qui échouent le plus souvent
    const failingTests = [];
    
    // Traiter chaque résultat
    results.forEach(result => {
      // Statut du test
      const status = result.status ? result.status.toLowerCase() : 'unknown';
      
      // Incrémenter le compteur approprié
      if (status === 'passed') testsByStatus.passed++;
      else if (status === 'failed') testsByStatus.failed++;
      else if (status === 'skipped') testsByStatus.skipped++;
      else testsByStatus.error++;
      
      // Si le test est critique, incrémenter le compteur des tests critiques
      if (result.testCase && result.testCase.isCritical) {
        if (status === 'passed') criticalTestsStatus.passed++;
        else if (status === 'failed') criticalTestsStatus.failed++;
        else if (status === 'skipped') criticalTestsStatus.skipped++;
        else criticalTestsStatus.error++;
      }
      
      // Calculer la durée d'exécution
      if (result.startTime && result.endTime) {
        const start = new Date(result.startTime);
        const end = new Date(result.endTime);
        const duration = (end - start) / 1000; // en secondes
        
        totalDuration += duration;
        
        testsByDuration.push({
          testId: result.testCase ? result.testCase.id : 'unknown',
          testName: result.testCase ? result.testCase.name : 'Unknown Test',
          duration,
          status
        });
        
        // Si le test a échoué, l'ajouter à la liste des tests défaillants
        if (status === 'failed' || status === 'error') {
          failingTests.push({
            testId: result.testCase ? result.testCase.id : 'unknown',
            testName: result.testCase ? result.testCase.name : 'Unknown Test',
            errorMessage: result.errorMessage,
            duration,
            status
          });
        }
      }
    });
    
    // Calcul du taux de réussite
    const totalTests = Object.values(testsByStatus).reduce((sum, count) => sum + count, 0);
    const passRate = totalTests > 0 ? (testsByStatus.passed / totalTests) * 100 : 0;
    
    // Calcul de la durée moyenne
    const avgDuration = totalTests > 0 ? totalDuration / totalTests : 0;
    
    // Trier les tests par durée
    testsByDuration.sort((a, b) => b.duration - a.duration);
    
    // Trier les tests défaillants
    failingTests.sort((a, b) => b.duration - a.duration);
    
    // Préparer les données pour le graphique de distribution des statuts
    const statusDistribution = [
      { name: 'Réussis', value: testsByStatus.passed, color: COLORS.success },
      { name: 'Échoués', value: testsByStatus.failed, color: COLORS.error },
      { name: 'Ignorés', value: testsByStatus.skipped, color: COLORS.warning },
      { name: 'Erreurs', value: testsByStatus.error, color: '#6B7280' }
    ];
    
    // Mettre à jour les métriques
    setMetrics({
      totalTests,
      passRate,
      avgDuration,
      criticalTestsStatus,
      testsByStatus,
      testsByDuration,
      topFailingTests: failingTests.slice(0, 5), // Top 5
      statusDistribution,
      executionTrend: metrics.executionTrend // Préserver les données de tendance
    });
  };
  
  // Calculer les tendances à partir de l'historique des exécutions
  const calculateTrends = (executions) => {
    if (!executions || executions.length === 0) return;
    
    // Grouper les exécutions par période
    let groupedExecutions = [];
    
    // Fonction pour formater la date selon la période
    const formatDate = (date, period) => {
      const d = new Date(date);
      if (period === 'week') {
        // Semaine avec année
        const firstDayOfWeek = new Date(d.setDate(d.getDate() - d.getDay()));
        return `${firstDayOfWeek.toISOString().slice(0, 10)}`;
      } else if (period === 'month') {
        // Mois avec année
        return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}`;
      } else { // year
        // Année
        return `${d.getFullYear()}`;
      }
    };
    
    // Créer un map pour grouper les exécutions par période
    const executionsByPeriod = {};
    
    executions.forEach(exec => {
      if (!exec.startTime) return;
      
      const period = formatDate(exec.startTime, trendPeriod);
      
      if (!executionsByPeriod[period]) {
        executionsByPeriod[period] = {
          period,
          totalExecutions: 0,
          passedTests: 0,
          failedTests: 0,
          skippedTests: 0,
          errorTests: 0,
          avgDuration: 0,
          totalDuration: 0
        };
      }
      
      // Incrémenter les compteurs
      executionsByPeriod[period].totalExecutions++;
      executionsByPeriod[period].passedTests += exec.passedTests || 0;
      executionsByPeriod[period].failedTests += exec.failedTests || 0;
      executionsByPeriod[period].skippedTests += exec.skippedTests || 0;
      executionsByPeriod[period].errorTests += exec.errorTests || 0;
      
      // Calculer la durée
      if (exec.startTime && exec.endTime) {
        const start = new Date(exec.startTime);
        const end = new Date(exec.endTime);
        const duration = (end - start) / 1000 / 60; // en minutes
        
        executionsByPeriod[period].totalDuration += duration;
      }
    });
    
    // Convertir le map en tableau et calculer les moyennes
    groupedExecutions = Object.values(executionsByPeriod).map(period => {
      const totalTests = period.passedTests + period.failedTests + period.skippedTests + period.errorTests;
      const passRate = totalTests > 0 ? (period.passedTests / totalTests) * 100 : 0;
      const avgDuration = period.totalExecutions > 0 ? period.totalDuration / period.totalExecutions : 0;
      
      return {
        ...period,
        totalTests,
        passRate,
        avgDuration
      };
    });
    
    // Trier par période
    groupedExecutions.sort((a, b) => a.period.localeCompare(b.period));
    
    // Mettre à jour les métriques
    setMetrics(prev => ({
      ...prev,
      executionTrend: groupedExecutions
    }));
  };
  
  // Filtrer les résultats des tests en fonction des critères
  const filterResults = useCallback(() => {
    if (!testResults || testResults.length === 0) {
      setFilteredResults([]);
      return;
    }
    
    let filtered = [...testResults];
    
    // Filtrer par statut
    if (resultFilter !== 'all') {
      filtered = filtered.filter(result => 
        result.status && result.status.toLowerCase() === resultFilter.toLowerCase()
      );
    }
    
    // Filtrer par terme de recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(result => 
        (result.testCase && result.testCase.name && result.testCase.name.toLowerCase().includes(term)) ||
        (result.testCase && result.testCase.testCaseId && result.testCase.testCaseId.toLowerCase().includes(term)) ||
        (result.resultId && result.resultId.toLowerCase().includes(term)) ||
        (result.errorMessage && result.errorMessage.toLowerCase().includes(term))
      );
    }
    
    // Trier les résultats
    filtered.sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];
      
      // Traitement spécial pour les champs imbriqués (par exemple, testCase.name)
      if (sortField === 'testCase.name') {
        aValue = a.testCase ? a.testCase.name : '';
        bValue = b.testCase ? b.testCase.name : '';
      }
      
      // Traitement spécial pour les dates
      if (sortField === 'startTime' || sortField === 'endTime') {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      }
      
      // Tri ascendant ou descendant
      if (sortDirection === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });
    
    setFilteredResults(filtered);
  }, [testResults, resultFilter, searchTerm, sortField, sortDirection]);
  
  // Effet pour charger les données initiales
  useEffect(() => {
    if (executionIdFromUrl) {
      fetchExecutionDetails(executionIdFromUrl);
    }
  }, [executionIdFromUrl, fetchExecutionDetails]);
  
  // Effet pour charger les résultats des tests lorsque l'exécution change
  useEffect(() => {
    if (execution && execution.id) {
      fetchTestResults(execution.id);
    }
  }, [execution, fetchTestResults]);
  
  // Effet pour recalculer les tendances lorsque la période change
  useEffect(() => {
    if (executionHistory.length > 0) {
      calculateTrends(executionHistory);
    }
  }, [trendPeriod, executionHistory]);
  
  // Effet pour filtrer les résultats lorsque les filtres changent
  useEffect(() => {
    filterResults();
  }, [filterResults, testResults, resultFilter, searchTerm, sortField, sortDirection]);
  
  // Gérer le changement d'exécution pour la comparaison
  const handleExecutionChange = (direction) => {
    if (!executionHistory || executionHistory.length <= 1) return;
    
    // Trouver l'index actuel
    const currentIndex = executionHistory.findIndex(exec => 
      exec.id === selectedExecution.id
    );
    
    if (currentIndex === -1) return;
    
    let newIndex;
    if (direction === 'prev') {
      newIndex = (currentIndex + 1) % executionHistory.length;
    } else { // 'next'
      newIndex = (currentIndex - 1 + executionHistory.length) % executionHistory.length;
    }
    
    setSelectedExecution(executionHistory[newIndex]);
    fetchTestResults(executionHistory[newIndex].id);
  };
  
  // Obtenir l'icône pour un statut de test
  const getStatusIcon = (status, size = 18) => {
    switch (status ? status.toLowerCase() : '') {
      case 'passed':
        return <CheckCircle size={size} color={COLORS.success} />;
      case 'failed':
        return <XCircle size={size} color={COLORS.error} />;
      case 'skipped':
        return <AlertTriangle size={size} color={COLORS.warning} />;
      default:
        return <AlertTriangle size={size} color="#9e9e9e" />;
    }
  };

  // Obtenir la couleur pour un statut de test
  const getStatusColor = (status) => {
    switch (status ? status.toLowerCase() : '') {
      case 'passed':
        return COLORS.success;
      case 'failed':
        return COLORS.error;
      case 'skipped':
        return COLORS.warning;
      default:
        return '#9e9e9e';
    }
  };
  
  // Fonction pour basculer en mode plein écran
  const toggleFullScreen = (elementId) => {
    if (fullScreenMode === elementId) {
      setFullScreenMode(null);
    } else {
      setFullScreenMode(elementId);
    }
  };
  
  // Rendu de la vue d'ensemble
  const renderOverview = () => {
    if (loading.execution || loading.results) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 8 }}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (!execution) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 8, color: 'text.secondary' }}>
          Aucune exécution sélectionnée
        </Box>
      );
    }
    
    return (
      <Box sx={{ py: 2 }}>
        <Grid container spacing={3}>
          {/* KPI principaux */}
          <Grid item xs={12} md={3}>
            <MuiCard sx={{ bgcolor: COLORS.success, color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {metrics.passRate.toFixed(1)}%
                    </Typography>
                    <Typography variant="subtitle1">Taux de réussite</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    <CheckCircle size={32} />
                  </Avatar>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={metrics.passRate} 
                  sx={{ mt: 2, height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{metrics.testsByStatus.passed} réussis</Typography>
                  <Typography variant="caption">sur {metrics.totalTests} tests</Typography>
                </Box>
              </CardContent>
            </MuiCard>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <MuiCard sx={{ bgcolor: COLORS.warning, color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {metrics.criticalTestsStatus.passed} / {metrics.criticalTestsStatus.failed}
                    </Typography>
                    <Typography variant="subtitle1">Tests critiques</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    <AlertTriangle size={32} />
                  </Avatar>
                </Box>
                <Box sx={{ mt: 2, position: 'relative', height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}>
                  <Box 
                    sx={{ 
                      position: 'absolute', 
                      left: 0, 
                      top: 0, 
                      height: '100%', 
                      width: `${(metrics.criticalTestsStatus.passed / (metrics.criticalTestsStatus.passed + metrics.criticalTestsStatus.failed + metrics.criticalTestsStatus.skipped + metrics.criticalTestsStatus.error)) * 100 || 0}%`,
                      bgcolor: COLORS.success,
                      borderRadius: 4
                    }} 
                  />
                  <Box 
                    sx={{ 
                      position: 'absolute', 
                      left: `${(metrics.criticalTestsStatus.passed / (metrics.criticalTestsStatus.passed + metrics.criticalTestsStatus.failed + metrics.criticalTestsStatus.skipped + metrics.criticalTestsStatus.error)) * 100 || 0}%`, 
                      top: 0, 
                      height: '100%', 
                      width: `${(metrics.criticalTestsStatus.failed / (metrics.criticalTestsStatus.passed + metrics.criticalTestsStatus.failed + metrics.criticalTestsStatus.skipped + metrics.criticalTestsStatus.error)) * 100 || 0}%`,
                      bgcolor: COLORS.error,
                      borderRadius: 4
                    }} 
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">Réussis / Échoués</Typography>
                  <Typography variant="caption">(Tests critiques)</Typography>
                </Box>
              </CardContent>
            </MuiCard>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <MuiCard sx={{ bgcolor: COLORS.info, color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {metrics.avgDuration.toFixed(1)}s
                    </Typography>
                    <Typography variant="subtitle1">Durée moyenne</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    <Clock size={32} />
                  </Avatar>
                </Box>
                <Box sx={{ mt: 2, height: 8 }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                  <Typography variant="caption">
                    Par test
                  </Typography>
                </Box>
              </CardContent>
            </MuiCard>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <MuiCard sx={{ bgcolor: execution.status.includes('SUCCESS') ? COLORS.success : 
                            execution.status.includes('FAILURES') ? COLORS.warning : 
                            execution.status.includes('ERRORS') ? COLORS.error : 
                            COLORS.primary, 
                     color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {execution.status.replace('COMPLETED_', '')}
                    </Typography>
                    <Typography variant="subtitle1">Statut global</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    {execution.status.includes('SUCCESS') ? 
                      <CheckCircle size={32} /> : 
                      execution.status.includes('FAILURES') ? 
                      <AlertTriangle size={32} /> : 
                      <XCircle size={32} />}
                  </Avatar>
                </Box>
                <Box sx={{ mt: 2, height: 8 }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                  <Typography variant="caption">
                    Terminée le <DateFormatter date={execution.endTime} format="relative" />
                  </Typography>
                </Box>
              </CardContent>
            </MuiCard>
          </Grid>
          
          {/* Graphiques */}
          <Grid item xs={12} md={6}>
            <MuiCard sx={{ height: '100%', boxShadow: 2 }}>
              <CardHeader 
                title="Distribution des résultats"
                avatar={<PieChartIcon size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <IconButton onClick={() => toggleFullScreen('distribution')}>
                    {fullScreenMode === 'distribution' ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ height: fullScreenMode === 'distribution' ? 'calc(100vh - 200px)' : 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={metrics.statusDistribution}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius="80%"
                      fill="#8884d8"
                      dataKey="value"
                      nameKey="name"
                      label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    >
                      {metrics.statusDistribution.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <RTooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </MuiCard>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <MuiCard sx={{ height: '100%', boxShadow: 2 }}>
              <CardHeader 
                title="Top 10 tests les plus longs"
                avatar={<BarChartIcon size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <IconButton onClick={() => toggleFullScreen('duration')}>
                    {fullScreenMode === 'duration' ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ height: fullScreenMode === 'duration' ? 'calc(100vh - 200px)' : 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={metrics.testsByDuration.slice(0, 10)}
                    layout="vertical"
                    margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" label={{ value: 'Durée (s)', position: 'insideBottom', offset: -5 }} />
                    <YAxis 
                      type="category" 
                      dataKey="testName" 
                      tick={{ fontSize: 12 }} 
                      width={100}
                    />
                    <RTooltip 
                      formatter={(value) => [`${value.toFixed(2)}s`, 'Durée']}
                      labelFormatter={(value) => `Test: ${value}`}
                    />
                    <Legend />
                    <Bar 
                      dataKey="duration" 
                      name="Durée (s)" 
                      fill={COLORS.primary} 
                    />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </MuiCard>
          </Grid>
          
          {/* Tests critiques échoués */}
          {metrics.criticalTestsStatus.failed > 0 && (
            <Grid item xs={12}>
              <MuiCard sx={{ boxShadow: 2, border: `1px solid ${COLORS.error}` }}>
                <CardHeader 
                  title="Tests critiques échoués"
                  avatar={<AlertTriangle size={20} color={COLORS.error} />}
                  titleTypographyProps={{ variant: 'h6', color: COLORS.error }}
                />
                <Divider />
                <CardContent>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Test</TableCell>
                          <TableCell>Message d'erreur</TableCell>
                          <TableCell>Durée</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {testResults
                          .filter(result => 
                            result.testCase && 
                            result.testCase.isCritical && 
                            (result.status === 'FAILED' || result.status === 'ERROR')
                          )
                          .map((result) => (
                            <TableRow key={result.id} hover>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  {getStatusIcon(result.status)}
                                  <Box sx={{ ml: 1 }}>
                                    <Typography variant="body2" fontWeight="medium">
                                      {result.testCase ? result.testCase.name : 'N/A'}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                      {result.testCase ? result.testCase.testCaseId : 'N/A'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" color="error">
                                  {result.errorMessage || 'Aucun message d\'erreur'}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {result.startTime && result.endTime ? (
                                  `${((new Date(result.endTime) - new Date(result.startTime)) / 1000).toFixed(2)}s`
                                ) : 'N/A'}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </MuiCard>
            </Grid>
          )}
          
          {/* Informations sur l'exécution */}
          <Grid item xs={12}>
            <MuiCard sx={{ boxShadow: 2 }}>
              <CardHeader 
                title="Informations sur l'exécution"
                avatar={<Info size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                      <Typography variant="subtitle1" gutterBottom fontWeight="medium">Détails</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">ID:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">{execution.executionId}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">Nom:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">{execution.executionName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">Campagne:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {execution.campaign ? execution.campaign.name : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">Statut:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Chip 
                            label={execution.status} 
                            size="small"
                            color={
                              execution.status.includes('SUCCESS') ? 'success' :
                              execution.status.includes('FAILURES') ? 'warning' :
                              execution.status.includes('ERRORS') ? 'error' :
                              'default'
                            }
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                      <Typography variant="subtitle1" gutterBottom fontWeight="medium">Chronologie</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">Démarré le:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            <DateFormatter date={execution.startTime} format="full" showTime={true} />
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">Terminé le:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {execution.endTime ? (
                              <DateFormatter date={execution.endTime} format="full" showTime={true} />
                            ) : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">Durée:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {execution.startTime && execution.endTime ? (
                              `${((new Date(execution.endTime) - new Date(execution.startTime)) / 1000 / 60).toFixed(2)} minutes`
                            ) : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="text.secondary">Exécuté par:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {execution.executedBy || 'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </MuiCard>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Rendu des résultats détaillés
  const renderDetailedResults = () => {
    return (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          {/* Filtres */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mb: 3, boxShadow: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Rechercher des résultats..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search size={20} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Statut</InputLabel>
                    <Select
                      value={resultFilter}
                      onChange={(e) => setResultFilter(e.target.value)}
                      label="Statut"
                    >
                      <MenuItem value="all">Tous les statuts</MenuItem>
                      <MenuItem value="passed">Réussis</MenuItem>
                      <MenuItem value="failed">Échoués</MenuItem>
                      <MenuItem value="skipped">Ignorés</MenuItem>
                      <MenuItem value="error">Erreurs</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <MuiButton
                      variant="outlined"
                      startIcon={<RefreshCw size={18} />}
                      onClick={() => {
                        if (execution && execution.id) {
                          fetchTestResults(execution.id);
                        }
                      }}
                    >
                      Actualiser
                    </MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          
          {/* Tableau des résultats */}
          <Grid item xs={12}>
            <MuiCard sx={{ boxShadow: 2 }}>
              <CardHeader 
                title="Résultats des tests"
                avatar={<List size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <MuiButton
                      variant="outlined"
                      size="small"
                      startIcon={<Download size={18} />}
                      onClick={() => generateReport('EXCEL')}
                    >
                      Exporter
                    </MuiButton>
                    <IconButton onClick={() => toggleFullScreen('results')}>
                      {fullScreenMode === 'results' ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
                    </IconButton>
                  </Box>
                }
              />
              <Divider />
              <CardContent sx={{ height: fullScreenMode === 'results' ? 'calc(100vh - 200px)' : 'auto', p: 0 }}>
                <TableContainer sx={{ maxHeight: fullScreenMode === 'results' ? 'calc(100vh - 250px)' : 400 }}>
                  {loading.results ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
                      <CircularProgress />
                    </Box>
                  ) : filteredResults.length === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4, color: 'text.secondary' }}>
                      Aucun résultat trouvé
                    </Box>
                  ) : (
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={sortField === 'testCase.name'}
                              direction={sortField === 'testCase.name' ? sortDirection : 'asc'}
                              onClick={() => {
                                setSortField('testCase.name');
                                setSortDirection(sortField === 'testCase.name' && sortDirection === 'asc' ? 'desc' : 'asc');
                              }}
                            >
                              Test
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={sortField === 'status'}
                              direction={sortField === 'status' ? sortDirection : 'asc'}
                              onClick={() => {
                                setSortField('status');
                                setSortDirection(sortField === 'status' && sortDirection === 'asc' ? 'desc' : 'asc');
                              }}
                            >
                              Statut
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={sortField === 'startTime'}
                              direction={sortField === 'startTime' ? sortDirection : 'asc'}
                              onClick={() => {
                                setSortField('startTime');
                                setSortDirection(sortField === 'startTime' && sortDirection === 'asc' ? 'desc' : 'asc');
                              }}
                            >
                              Début
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>Durée</TableCell>
                          <TableCell>Message d'erreur</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredResults.map((result) => (
                          <TableRow 
                            key={result.id} 
                            hover
                            sx={{ 
                              borderLeft: result.testCase?.isCritical ? `4px solid ${getStatusColor(result.status)}` : 'none'
                            }}
                          >
                            <TableCell>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {getStatusIcon(result.status)}
                                <Box sx={{ ml: 1 }}>
                                  <Typography variant="body2" fontWeight="medium">
                                    {result.testCase ? result.testCase.name : 'N/A'}
                                  </Typography>
                                  <Typography variant="caption" color="text.secondary">
                                    {result.testCase ? result.testCase.testCaseId : 'N/A'}
                                  </Typography>
                                  {result.testCase?.isCritical && (
                                    <Chip 
                                      size="small" 
                                      label="Critique" 
                                      sx={{ ml: 1, height: 18, fontSize: '0.625rem' }}
                                      color="error"
                                    />
                                  )}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Chip 
                                size="small" 
                                label={result.status || 'UNKNOWN'} 
                                color={
                                  result.status === 'PASSED' ? 'success' :
                                  result.status === 'FAILED' ? 'error' :
                                  result.status === 'SKIPPED' ? 'warning' :
                                  'default'
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <DateFormatter date={result.startTime} format="default" showTime={true} />
                            </TableCell>
                            <TableCell>
                              {result.startTime && result.endTime ? (
                                `${((new Date(result.endTime) - new Date(result.startTime)) / 1000).toFixed(2)}s`
                              ) : 'N/A'}
                            </TableCell>
                            <TableCell>
                              {result.errorMessage ? (
                                <Tooltip title={result.errorMessage}>
                                  <Typography 
                                    variant="body2" 
                                    color="error"
                                    sx={{ 
                                      maxWidth: '200px', 
                                      overflow: 'hidden', 
                                      textOverflow: 'ellipsis', 
                                      whiteSpace: 'nowrap' 
                                    }}
                                  >
                                    {result.errorMessage}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography variant="body2" color="text.secondary">
                                  -
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              <IconButton size="small">
                                <Eye size={18} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </CardContent>
            </MuiCard>
          </Grid>
          
          {/* Résumé du statut et des erreurs */}
          <Grid item xs={12} md={6}>
            <MuiCard sx={{ boxShadow: 2, height: '100%' }}>
              <CardHeader 
                title="Distribution des statuts"
                avatar={<PieChartIcon size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <Divider />
              <CardContent sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={metrics.statusDistribution}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius="80%"
                      fill="#8884d8"
                      dataKey="value"
                      nameKey="name"
                      label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    >
                      {metrics.statusDistribution.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <RTooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </MuiCard>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <MuiCard sx={{ boxShadow: 2, height: '100%' }}>
              <CardHeader 
                title="Top échecs"
                avatar={<XCircle size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <Divider />
              <CardContent sx={{ height: 300, overflow: 'auto', px: 0 }}>
                {metrics.topFailingTests.length === 0 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', color: 'text.secondary' }}>
                    <CheckCircle size={48} color={COLORS.success} />
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Aucun échec détecté
                    </Typography>
                  </Box>
                ) : (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Test</TableCell>
                        <TableCell>Message d'erreur</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {metrics.topFailingTests.map((test, index) => (
                        <TableRow key={index} hover>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {getStatusIcon(test.status, 16)}
                              <Box sx={{ ml: 1 }}>
                                <Typography variant="body2" fontWeight="medium">
                                  {test.testName}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {test.duration.toFixed(2)}s
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={test.errorMessage || 'Aucun message d\'erreur'}>
                              <Typography 
                                variant="body2" 
                                color="error"
                                sx={{ 
                                  maxWidth: '300px', 
                                  overflow: 'hidden', 
                                  textOverflow: 'ellipsis', 
                                  whiteSpace: 'nowrap' 
                                }}
                              >
                                {test.errorMessage || 'Aucun message d\'erreur'}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </CardContent>
            </MuiCard>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Rendu des tendances et comparaisons
  const renderTrends = () => {
    if (loading.history) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 8 }}>
          <CircularProgress />
        </Box>
      );
    }
    
    return (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          {/* Sélecteur de période */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mb: 3, boxShadow: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="h6">
                    Tendances d'exécution
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {campaign ? `Campagne: ${campaign.name}` : 'Toutes les campagnes'}
                  </Typography>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Stack direction="row" spacing={1} justifyContent="center">
                    <MuiButton
                      variant={trendPeriod === 'week' ? "contained" : "outlined"}
                      size="small"
                      onClick={() => setTrendPeriod('week')}
                    >
                      Semaine
                    </MuiButton>
                    <MuiButton
                      variant={trendPeriod === 'month' ? "contained" : "outlined"}
                      size="small"
                      onClick={() => setTrendPeriod('month')}
                    >
                      Mois
                    </MuiButton>
                    <MuiButton
                      variant={trendPeriod === 'year' ? "contained" : "outlined"}
                      size="small"
                      onClick={() => setTrendPeriod('year')}
                    >
                      Année
                    </MuiButton>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          
          {/* Graphiques de tendance */}
          <Grid item xs={12} md={6}>
            <MuiCard sx={{ boxShadow: 2, height: '100%' }}>
              <CardHeader 
                title="Tendance du taux de réussite"
                avatar={<LineChartIcon size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <IconButton onClick={() => toggleFullScreen('trendSuccess')}>
                    {fullScreenMode === 'trendSuccess' ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ height: fullScreenMode === 'trendSuccess' ? 'calc(100vh - 200px)' : 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={metrics.executionTrend}
                    margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="period" />
                    <YAxis 
                      domain={[0, 100]}
                      label={{ value: '%', angle: -90, position: 'insideLeft', offset: -5 }}
                    />
                    <RTooltip formatter={(value) => [`${value.toFixed(1)}%`, 'Taux de réussite']} />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey="passRate" 
                      name="Taux de réussite" 
                      stroke={COLORS.success} 
                      strokeWidth={2}
                      dot={{ r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </MuiCard>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <MuiCard sx={{ boxShadow: 2, height: '100%' }}>
              <CardHeader 
                title="Évolution des statuts de test"
                avatar={<BarChartIcon size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <IconButton onClick={() => toggleFullScreen('trendStatus')}>
                    {fullScreenMode === 'trendStatus' ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ height: fullScreenMode === 'trendStatus' ? 'calc(100vh - 200px)' : 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={metrics.executionTrend}
                    margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="period" />
                    <YAxis />
                    <RTooltip />
                    <Legend />
                    <Area 
                      type="monotone" 
                      dataKey="passedTests" 
                      name="Réussis" 
                      stackId="1"
                      stroke={COLORS.success} 
                      fill={COLORS.success} 
                      fillOpacity={0.6}
                    />
                    <Area 
                      type="monotone" 
                      dataKey="failedTests" 
                      name="Échoués" 
                      stackId="1"
                      stroke={COLORS.error} 
                      fill={COLORS.error} 
                      fillOpacity={0.6}
                    />
                    <Area 
                      type="monotone" 
                      dataKey="skippedTests" 
                      name="Ignorés" 
                      stackId="1"
                      stroke={COLORS.warning} 
                      fill={COLORS.warning} 
                      fillOpacity={0.6}
                    />
                    <Area 
                      type="monotone" 
                      dataKey="errorTests" 
                      name="Erreurs" 
                      stackId="1"
                      stroke="#6B7280" 
                      fill="#6B7280" 
                      fillOpacity={0.6}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </CardContent>
            </MuiCard>
          </Grid>
          
          {/* Comparaison d'exécutions */}
          <Grid item xs={12}>
            <MuiCard sx={{ boxShadow: 2 }}>
              <CardHeader 
                title="Comparaison d'exécutions"
                avatar={<Repeat size={20} />}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <Divider />
              <CardContent>
                {executionHistory.length <= 1 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 200, color: 'text.secondary' }}>
                    <Calendar size={48} color="#9e9e9e" />
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Historique d'exécution insuffisant pour la comparaison
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
                      <MuiButton
                        variant="outlined"
                        startIcon={<SkipBack size={18} />}
                        onClick={() => handleExecutionChange('prev')}
                        sx={{ mx: 2 }}
                      >
                        Précédente
                      </MuiButton>
                      
                      <Box sx={{ textAlign: 'center', px: 2 }}>
                        <Typography variant="h6">
                          {selectedExecution?.executionName || 'Exécution'}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          <DateFormatter date={selectedExecution?.startTime} format="default" showTime={true} />
                        </Typography>
                      </Box>
                      
                      <MuiButton
                        variant="outlined"
                        endIcon={<SkipForward size={18} />}
                        onClick={() => handleExecutionChange('next')}
                        sx={{ mx: 2 }}
                      >
                        Suivante
                      </MuiButton>
                    </Box>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                          <Typography variant="subtitle2" gutterBottom color="text.secondary">
                            Taux de réussite
                          </Typography>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', color: COLORS.success }}>
                            {selectedExecution?.passedTests && (selectedExecution.passedTests + selectedExecution.failedTests + selectedExecution.skippedTests + selectedExecution.errorTests) > 0 ? (
                              `${((selectedExecution.passedTests / (selectedExecution.passedTests + selectedExecution.failedTests + selectedExecution.skippedTests + selectedExecution.errorTests)) * 100).toFixed(1)}%`
                            ) : 'N/A'}
                          </Typography>
                        </Paper>
                      </Grid>
                      
                      <Grid item xs={12} md={3}>
                        <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                          <Typography variant="subtitle2" gutterBottom color="text.secondary">
                            Tests réussis
                          </Typography>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', color: COLORS.success }}>
                            {selectedExecution?.passedTests || 0}
                          </Typography>
                        </Paper>
                      </Grid>
                      
                      <Grid item xs={12} md={3}>
                        <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                          <Typography variant="subtitle2" gutterBottom color="text.secondary">
                            Tests échoués
                          </Typography>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', color: COLORS.error }}>
                            {selectedExecution?.failedTests || 0}
                          </Typography>
                        </Paper>
                      </Grid>
                      
                      <Grid item xs={12} md={3}>
                        <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                          <Typography variant="subtitle2" gutterBottom color="text.secondary">
                            Durée totale
                          </Typography>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', color: COLORS.info }}>
                            {selectedExecution?.startTime && selectedExecution?.endTime ? (
                              `${((new Date(selectedExecution.endTime) - new Date(selectedExecution.startTime)) / 1000 / 60).toFixed(1)} min`
                            ) : 'N/A'}
                          </Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                      <MuiButton
                        variant="contained"
                        color="primary"
                        startIcon={<Eye size={18} />}
                        onClick={() => {
                          if (selectedExecution && selectedExecution.id) {
                            window.location.href = `/atm-campaign/cpg-05-testresultanalyzer?executionId=${selectedExecution.id}`;
                          }
                        }}
                      >
                        Voir les détails complets
                      </MuiButton>
                    </Box>
                  </>
                )}
              </CardContent>
            </MuiCard>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  return (
    <Box sx={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* En-tête */}
      <Box 
        component={Paper} 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          zIndex: 10
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BarChart2 size={24} />
            Analyse des résultats de test
          </Typography>
          {execution && (
            <Typography variant="body2" color="text.secondary">
              {execution.executionName || execution.executionId} - 
              {execution.campaign ? ` Campagne: ${execution.campaign.name}` : ''}
            </Typography>
          )}
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          <MuiButton
            variant="outlined"
            startIcon={<RefreshCw size={18} />}
            onClick={() => {
              if (execution && execution.id) {
                fetchExecutionDetails(execution.id);
                fetchTestResults(execution.id);
              }
            }}
          >
            Actualiser
          </MuiButton>
          
          <MuiButton
            variant="outlined"
            startIcon={<BarChart2 size={18} />}
            onClick={() => {
              if (execution && execution.id) {
                calculateExecutionStats(execution.id);
              }
            }}
            color="secondary"
          >
            Recalculer les statistiques
          </MuiButton>
          
          <MuiButton
            variant="contained"
            startIcon={<Download size={18} />}
            onClick={() => generateReport('PDF')}
            color="primary"
          >
            Générer un rapport
          </MuiButton>
        </Box>
      </Box>
      
      {/* Onglets de navigation */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
        <Tabs 
          value={activeTab} 
          onChange={(e, newValue) => setActiveTab(newValue)} 
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab 
            label="Vue d'ensemble" 
            value="overview" 
            icon={<Home size={18} />} 
            iconPosition="start"
          />
          <Tab 
            label="Résultats détaillés" 
            value="results" 
            icon={<List size={18} />} 
            iconPosition="start"
          />
          <Tab 
            label="Tendances & Comparaisons" 
            value="trends" 
            icon={<LineChartIcon size={18} />} 
            iconPosition="start"
          />
        </Tabs>
      </Box>
      
      {/* Contenu principal */}
      <Box sx={{ flex: 1, overflow: 'auto', bgcolor: '#f5f7fa' }}>
        {activeTab === 'overview' && renderOverview()}
        {activeTab === 'results' && renderDetailedResults()}
        {activeTab === 'trends' && renderTrends()}
      </Box>
    </Box>
  );
};

export default TestResultAnalyzer;