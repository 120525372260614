import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ArrowUpRight, ArrowDownRight, Activity, Clock, Users, GitBranch, AlertTriangle } from 'lucide-react';

// Données d'exemple - à remplacer par des appels API réels
const generateMockData = () => {
  const workflows = ['Déclaration', 'Paiement', 'Validation', 'Document'];
  const status = ['Complété', 'En cours', 'En attente', 'Échoué'];
  const colors = ['#10B981', '#3B82F6', '#F59E0B', '#EF4444'];
  
  // Données pour le graphique d'activité des 7 derniers jours
  const now = new Date();
  const activityData = Array.from({ length: 7 }, (_, i) => {
    const date = new Date();
    date.setDate(now.getDate() - (6 - i));
    return {
      date: date.toISOString().split('T')[0],
      instances: Math.floor(Math.random() * 40) + 10,
      transitions: Math.floor(Math.random() * 100) + 30,
    };
  });
  
  // Données pour la répartition par état
  const statusData = status.map((label, i) => ({
    name: label,
    value: Math.floor(Math.random() * 50) + 10,
    color: colors[i]
  }));
  
  // Données pour les performances par workflow
  const performanceData = workflows.map(name => ({
    name,
    tempsExecution: Math.floor(Math.random() * 300) + 30,
    transitions: Math.floor(Math.random() * 20) + 5,
  }));
  
  // Données pour les workflows actifs
  const workflowsData = workflows.map(name => ({
    name,
    instances: Math.floor(Math.random() * 30) + 5,
    completed: Math.floor(Math.random() * 100),
    performance: Math.floor(Math.random() * 100),
  }));
  
  return { activityData, statusData, performanceData, workflowsData };
};

const WorkflowDashboard = () => {
  const [data, setData] = useState(() => generateMockData());
  const [loading, setLoading] = useState(false);
  const [timeRange, setTimeRange] = useState('1w'); // 1d, 1w, 1m, 3m
  
  // Métriques globales calculées
  const totalActive = data.workflowsData.reduce((sum, item) => sum + item.instances, 0);
  const totalCompleted = data.statusData.find(d => d.name === 'Complété')?.value || 0;
  const averageTime = data.performanceData.reduce((sum, item) => sum + item.tempsExecution, 0) / data.performanceData.length;
  const criticalCount = data.statusData.find(d => d.name === 'Échoué')?.value || 0;
  
  const refreshData = () => {
    setLoading(true);
    // Simuler un appel API
    setTimeout(() => {
      setData(generateMockData());
      setLoading(false);
    }, 800);
  };
  
  useEffect(() => {
    // Charger les données initiales
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  
  // Mise à jour des données lors du changement de plage de temps
  useEffect(() => {
    refreshData();
  }, [timeRange]);
  
  return (
    <div className="space-y-6">
      <header className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Tableau de bord des workflows
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Vue d'ensemble de l'activité et des performances des workflows
          </p>
        </div>
        
        {/* Filtres de date */}
        <div className="flex items-center space-x-2">
          <select 
            className="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md px-3 py-2 text-sm"
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
          >
            <option value="1d">Dernier jour</option>
            <option value="1w">Dernière semaine</option>
            <option value="1m">Dernier mois</option>
            <option value="3m">3 derniers mois</option>
          </select>
          
          <button 
            className="bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 px-3 py-2 rounded-md text-sm font-medium hover:bg-blue-100 dark:hover:bg-blue-900/30 transition-colors"
            onClick={refreshData}
            disabled={loading}
          >
            {loading ? 'Actualisation...' : 'Actualiser'}
          </button>
        </div>
      </header>
      
      {/* Cartes de métriques clés */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <MetricCard 
          title="Workflows actifs" 
          value={totalActive} 
          change={+8} 
          icon={<Activity className="h-5 w-5 text-blue-500" />} 
          color="blue"
        />
        <MetricCard 
          title="Workflows terminés" 
          value={totalCompleted} 
          change={+12} 
          icon={<Users className="h-5 w-5 text-green-500" />} 
          color="green"
        />
        <MetricCard 
          title="Temps moyen (min)" 
          value={Math.round(averageTime)} 
          change={-5} 
          icon={<Clock className="h-5 w-5 text-yellow-500" />} 
          color="yellow"
        />
        <MetricCard 
          title="Incidents critiques" 
          value={criticalCount} 
          change={+2} 
          icon={<AlertTriangle className="h-5 w-5 text-red-500" />} 
          color="red"
        />
      </div>
      
      {/* Graphiques principaux */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Graphique d'activité */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">Activité des Workflows</h2>
          </div>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={data.activityData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="instances" stroke="#3B82F6" activeDot={{ r: 8 }} name="Instances" />
                <Line type="monotone" dataKey="transitions" stroke="#10B981" name="Transitions" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        
        {/* Répartition par état */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">État des Workflows</h2>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="h-60">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={data.statusData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {data.statusData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="grid grid-cols-1 gap-2 self-center">
              {data.statusData.map((status) => (
                <div key={status.name} className="flex items-center space-x-2">
                  <div className="w-3 h-3 rounded-full" style={{ backgroundColor: status.color }}></div>
                  <span className="text-sm text-gray-600 dark:text-gray-300">{status.name}</span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white ml-auto">{status.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Temps d'exécution par workflow */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">Temps d'exécution moyen (min)</h2>
          </div>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data.performanceData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="tempsExecution" name="Temps (min)" fill="#8884d8" />
                <Bar dataKey="transitions" name="Nb. Transitions" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        
        {/* Liste des workflows actifs */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">Workflows Actifs</h2>
          </div>
          <div className="space-y-6">
            {data.workflowsData.map((workflow) => (
              <div key={workflow.name} className="border-b border-gray-100 dark:border-gray-700 pb-4 last:border-0 last:pb-0">
                <div className="flex justify-between mb-2">
                  <div className="flex items-center">
                    <GitBranch className="h-4 w-4 text-gray-400 mr-2" />
                    <span className="text-sm font-medium text-gray-800 dark:text-gray-200">{workflow.name}</span>
                  </div>
                  <span className="text-sm font-medium text-blue-600 dark:text-blue-400">
                    {workflow.instances} instances
                  </span>
                </div>
                <div className="flex space-x-4">
                  <ProgressBar 
                    value={workflow.completed} 
                    label="Complétion" 
                    color="green" 
                  />
                  <ProgressBar 
                    value={workflow.performance} 
                    label="Performance" 
                    color="blue" 
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Composant carte de métrique
const MetricCard = ({ title, value, change, icon, color }) => {
  const colors = {
    blue: "bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400",
    green: "bg-green-50 dark:bg-green-900/20 text-green-600 dark:text-green-400",
    yellow: "bg-yellow-50 dark:bg-yellow-900/20 text-yellow-600 dark:text-yellow-400",
    red: "bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400",
  };
  
  const bgColor = colors[color] || colors.blue;
  
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
      <div className="flex justify-between items-start">
        <div>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
          <p className="mt-2 text-3xl font-bold text-gray-900 dark:text-white">{value}</p>
        </div>
        <div className={`p-2 rounded-lg ${bgColor}`}>
          {icon}
        </div>
      </div>
      <div className="mt-4 flex items-center">
        {change > 0 ? (
          <ArrowUpRight className="w-4 h-4 text-green-500" />
        ) : (
          <ArrowDownRight className="w-4 h-4 text-red-500" />
        )}
        <p className={`text-sm ml-1 ${change > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
          {Math.abs(change)}% {change > 0 ? 'hausse' : 'baisse'}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 ml-2">depuis la dernière période</p>
      </div>
    </div>
  );
};

// Composant barre de progression
const ProgressBar = ({ value, max = 100, label, color }) => {
  const colorClasses = {
    blue: "bg-blue-500",
    green: "bg-green-500",
    yellow: "bg-yellow-500",
    red: "bg-red-500"
  };
  
  const bgClass = colorClasses[color] || colorClasses.blue;
  
  return (
    <div className="flex-1">
      <div className="flex justify-between mb-1">
        <span className="text-xs text-gray-500 dark:text-gray-400">{label}</span>
        <span className="text-xs font-medium text-gray-700 dark:text-gray-300">{value}%</span>
      </div>
      <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
        <div 
          className={`${bgClass} h-2 rounded-full`} 
          style={{ width: `${value}%` }}
        ></div>
      </div>
    </div>
  );
};

export default WorkflowDashboard;