import React, { useState, useEffect } from 'react';
import { 
  Tag, 
  Plus, 
  Edit, 
  Trash2, 
  RefreshCw, 
  CheckCircle, 
  AlertTriangle,
  X,
  Search,
  Filter,
  Info,
  Code,
  Hash
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

/**
 * Placeholders Management
 * 
 * This component provides a complete interface for managing placeholders
 * with features including:
 * - List view with filtering
 * - Create/edit forms with validation
 * - Detail view
 * - Delete functionality
 */
const Placeholders = () => {
  // State for placeholders data
  const [placeholders, setPlaceholders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [filterActive, setFilterActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  
  // State for selected placeholder and modal visibility
  const [selectedPlaceholder, setSelectedPlaceholder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(true);
  
  // State for form validation
  const [validationErrors, setValidationErrors] = useState({});

  // Data types for placeholders
  const dataTypes = [
    { value: 'STRING', label: 'Texte', description: 'Pour les chaînes de caractères' },
    { value: 'NUMBER', label: 'Nombre', description: 'Pour les valeurs numériques' },
    { value: 'DATE', label: 'Date', description: 'Pour les dates au format YYYY-MM-DD' },
    { value: 'BOOLEAN', label: 'Booléen', description: 'Pour les valeurs true/false' },
    { value: 'EMAIL', label: 'Email', description: 'Pour les adresses email' },
    { value: 'PHONE', label: 'Téléphone', description: 'Pour les numéros de téléphone' },
    { value: 'CURRENCY', label: 'Devise', description: 'Pour les montants monétaires' }
  ];

  // Form state
  const [placeholderForm, setPlaceholderForm] = useState({
    code: '',
    name: '',
    description: '',
    defaultValue: '',
    dataType: 'STRING',
    validationRegex: '',
    required: false,
    active: true
  });

  // Reset form state
  const resetForm = () => {
    setPlaceholderForm({
      code: '',
      name: '',
      description: '',
      defaultValue: '',
      dataType: 'STRING',
      validationRegex: '',
      required: false,
      active: true
    });
    setValidationErrors({});
  };

  // Load placeholders on component mount
  useEffect(() => {
    fetchPlaceholders();
  }, []);

  // Fetch placeholders from API
// Fetch placeholders from API
const fetchPlaceholders = async () => {
  try {
    const response = await apiService.editic.placeholders.getAll();
    setPlaceholders(response);
  } catch (err) {
    console.error('Error fetching placeholders:', err);
  }
};

  // Handle opening the create placeholder modal
  const openCreateModal = () => {
    resetForm();
    setIsCreateMode(true);
    setShowModal(true);
  };

  // Handle opening the edit placeholder modal
  const openEditModal = (placeholder) => {
    setPlaceholderForm({
      code: placeholder.code,
      name: placeholder.name,
      description: placeholder.description || '',
      defaultValue: placeholder.defaultValue || '',
      dataType: placeholder.dataType,
      validationRegex: placeholder.validationRegex || '',
      required: placeholder.required,
      active: placeholder.active
    });
    setSelectedPlaceholder(placeholder);
    setIsCreateMode(false);
    setShowModal(true);
  };

  // Handle opening the detail view modal
  const openDetailModal = (placeholder) => {
    setSelectedPlaceholder(placeholder);
    setShowDetailModal(true);
  };

  // Handle form change
  const handleFormChange = (field, value) => {
    // Clear validation error when field is updated
    if (validationErrors[field]) {
      setValidationErrors({
        ...validationErrors,
        [field]: null
      });
    }

    setPlaceholderForm(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!placeholderForm.code.trim()) {
      errors.code = 'Le code est requis';
    } else if (!/^[A-Z][A-Z0-9_]*$/.test(placeholderForm.code)) {
      errors.code = 'Le code doit commencer par une lettre majuscule et contenir uniquement des lettres majuscules, des chiffres et des underscores';
    }
    
    if (!placeholderForm.name.trim()) {
      errors.name = 'Le nom est requis';
    }
    
    if (!placeholderForm.dataType) {
      errors.dataType = 'Le type de données est requis';
    }
    
    // Validate regex pattern if provided
    if (placeholderForm.validationRegex) {
      try {
        new RegExp(placeholderForm.validationRegex);
      } catch (e) {
        errors.validationRegex = 'Expression régulière invalide';
      }
    }
    
    // Validate default value against regex pattern if both provided
    if (placeholderForm.defaultValue && placeholderForm.validationRegex) {
      try {
        const regex = new RegExp(placeholderForm.validationRegex);
        if (!regex.test(placeholderForm.defaultValue)) {
          errors.defaultValue = 'La valeur par défaut ne correspond pas au motif de validation';
        }
      } catch (e) {
        // Regex validation error already handled above
      }
    }
    
    // Validate default value against data type
    if (placeholderForm.defaultValue && placeholderForm.dataType) {
      switch (placeholderForm.dataType) {
        case 'NUMBER':
          if (isNaN(Number(placeholderForm.defaultValue))) {
            errors.defaultValue = 'La valeur par défaut doit être un nombre';
          }
          break;
        case 'DATE':
          if (!/^\d{4}-\d{2}-\d{2}$/.test(placeholderForm.defaultValue)) {
            errors.defaultValue = 'La valeur par défaut doit être au format YYYY-MM-DD';
          }
          break;
        case 'BOOLEAN':
          if (placeholderForm.defaultValue !== 'true' && placeholderForm.defaultValue !== 'false') {
            errors.defaultValue = 'La valeur par défaut doit être "true" ou "false"';
          }
          break;
        case 'EMAIL':
          if (!/^\S+@\S+\.\S+$/.test(placeholderForm.defaultValue)) {
            errors.defaultValue = 'La valeur par défaut doit être une adresse email valide';
          }
          break;
      }
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit form (create or update)
  const submitForm = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      if (isCreateMode) {
        // Create placeholder
        await apiService.editic.placeholders.create(placeholderForm);
        setSuccessMessage('Placeholder créé avec succès!');
      } else {
        // Update placeholder
        await apiService.editic.placeholders.update(selectedPlaceholder.id, placeholderForm);
        setSuccessMessage('Placeholder mis à jour avec succès!');
      }
      
      // Refresh placeholders and close modal
      fetchPlaceholders();
      setShowModal(false);
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error saving placeholder:', err);
      setError(`Impossible de ${isCreateMode ? 'créer' : 'mettre à jour'} le placeholder.`);
    } finally {
      setLoading(false);
    }
  };

  // Delete a placeholder
  const deletePlaceholder = async (id) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce placeholder?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.editic.placeholders.delete(id);
      setSuccessMessage('Placeholder supprimé avec succès!');
      fetchPlaceholders();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error deleting placeholder:', err);
      setError('Impossible de supprimer le placeholder.');
    } finally {
      setLoading(false);
    }
  };

  // Get data type label
  const getDataTypeLabel = (type) => {
    const dataType = dataTypes.find(dt => dt.value === type);
    return dataType ? dataType.label : type;
  };

  // Get data type description
  const getDataTypeDescription = (type) => {
    const dataType = dataTypes.find(dt => dt.value === type);
    return dataType ? dataType.description : '';
  };

  // Filter placeholders based on search term and active filter
  const filteredPlaceholders = placeholders.filter(placeholder => {
    const matchesSearch = searchTerm === '' || 
      placeholder.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      placeholder.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (placeholder.description && placeholder.description.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesActive = !filterActive || placeholder.active;
    
    return matchesSearch && matchesActive;
  });

  // Column configuration for the DataGrid
  const columns = [
    {
      key: 'name',
      header: 'Nom',
      sortable: true,
      render: (row) => (
        <div>
          <div className="font-medium text-blue-600 dark:text-blue-400 cursor-pointer hover:underline" onClick={() => openDetailModal(row)}>
            {row.name}
          </div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-xs">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      key: 'code',
      header: 'Code',
      sortable: true,
      render: (row) => (
        <span className="font-mono text-sm bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded">
          {row.code}
        </span>
      )
    },
    {
      key: 'dataType',
      header: 'Type',
      sortable: true,
      render: (row) => (
        <div className="flex flex-col">
          <span className="font-medium text-gray-900 dark:text-white">
            {getDataTypeLabel(row.dataType)}
          </span>
          <span className="text-xs text-gray-500 dark:text-gray-400">
            {row.dataType}
          </span>
        </div>
      )
    },
    {
      key: 'defaultValue',
      header: 'Valeur par défaut',
      sortable: true,
      render: (row) => (
        <span className="text-gray-700 dark:text-gray-300">
          {row.defaultValue || <span className="text-gray-400 dark:text-gray-500 italic">Non définie</span>}
        </span>
      )
    },
    {
      key: 'required',
      header: 'Requis',
      sortable: true,
      render: (row) => (
        <span className="px-2 py-1 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 text-xs font-medium">
          {row.required ? 'Oui' : 'Non'}
        </span>
      )
    },
    {
      key: 'active',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={row.active ? 'active' : 'inactive'} 
          customLabel={row.active ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex items-center justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Info}
            onClick={() => openDetailModal(row)}
            title="Détails"
          />
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => openEditModal(row)}
            title="Modifier"
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => deletePlaceholder(row.id)}
            title="Supprimer"
          />
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Tag className="h-6 w-6 mr-2 text-blue-500" />
            Placeholders
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les variables de remplacement pour vos modèles de documents
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchPlaceholders}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={Plus}
            onClick={openCreateModal}
          >
            Nouveau Placeholder
          </Button>
        </div>
      </div>

      {/* Success message */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Error message */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Filters and search */}
      <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Rechercher par nom, code ou description..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex items-center space-x-2">
          <Filter className="h-5 w-5 text-gray-400" />
          <span className="text-gray-700 dark:text-gray-300 whitespace-nowrap">Filtre :</span>
          <div className="flex items-center space-x-2">
            <input
              id="activeOnly"
              type="checkbox"
              checked={filterActive}
              onChange={() => setFilterActive(!filterActive)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="activeOnly" className="text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">
              Actifs uniquement
            </label>
          </div>
        </div>
      </div>

      {/* Placeholders list */}
      <Card>
        <DataGrid
          data={filteredPlaceholders}
          columns={columns}
          loading={loading}
          emptyMessage="Aucun placeholder trouvé"
        />
      </Card>

      {/* Modal for creating/editing placeholders */}
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-2xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <Tag className="h-5 w-5 mr-2 text-blue-500" />
                {isCreateMode ? 'Créer un nouveau placeholder' : 'Modifier le placeholder'}
              </h3>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={submitForm}>
              <div className="px-6 py-4 space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Code <span className="text-red-500">*</span>
                    </label>
                    <div className="flex">
                      <input
                        type="text"
                        className={`w-full px-3 py-2 border ${
                          validationErrors.code 
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                            : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                        } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                        value={placeholderForm.code}
                        onChange={(e) => handleFormChange('code', e.target.value)}
                        placeholder="CODE_PLACEHOLDER"
                        disabled={!isCreateMode} // Code cannot be changed when editing
                      />
                    </div>
                    {validationErrors.code && (
                      <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                        {validationErrors.code}
                      </p>
                    )}
                    {!validationErrors.code && (
                      <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                        Format: lettres majuscules, chiffres et underscore (ex: NOM_CLIENT)
                      </p>
                    )}
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      className={`w-full px-3 py-2 border ${
                        validationErrors.name 
                          ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                          : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                      } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                      value={placeholderForm.name}
                      onChange={(e) => handleFormChange('name', e.target.value)}
                      placeholder="Nom du placeholder"
                    />
                    {validationErrors.name && (
                      <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                        {validationErrors.name}
                      </p>
                    )}
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={placeholderForm.description}
                    onChange={(e) => handleFormChange('description', e.target.value)}
                    placeholder="Description du placeholder"
                    rows={2}
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de données <span className="text-red-500">*</span>
                    </label>
                    <select
                      className={`w-full px-3 py-2 border ${
                        validationErrors.dataType 
                          ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                          : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                      } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                      value={placeholderForm.dataType}
                      onChange={(e) => handleFormChange('dataType', e.target.value)}
                    >
                      {dataTypes.map(type => (
                        <option key={type.value} value={type.value}>
                          {type.label} ({type.value})
                        </option>
                      ))}
                    </select>
                    {validationErrors.dataType && (
                      <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                        {validationErrors.dataType}
                      </p>
                    )}
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      {getDataTypeDescription(placeholderForm.dataType)}
                    </p>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Valeur par défaut
                    </label>
                    <input
                      type="text"
                      className={`w-full px-3 py-2 border ${
                        validationErrors.defaultValue 
                          ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                          : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                      } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                      value={placeholderForm.defaultValue}
                      onChange={(e) => handleFormChange('defaultValue', e.target.value)}
                      placeholder="Valeur par défaut (optionnelle)"
                    />
                    {validationErrors.defaultValue && (
                      <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                        {validationErrors.defaultValue}
                      </p>
                    )}
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Expression régulière de validation
                  </label>
                  <div className="flex">
                    <div className="relative flex-grow">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Code className="h-4 w-4 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        className={`w-full pl-10 pr-3 py-2 border ${
                          validationErrors.validationRegex 
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                            : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                        } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 font-mono text-sm`}
                        value={placeholderForm.validationRegex}
                        onChange={(e) => handleFormChange('validationRegex', e.target.value)}
                        placeholder="^[A-Za-z0-9]+$"
                      />
                    </div>
                  </div>
                  {validationErrors.validationRegex && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {validationErrors.validationRegex}
                    </p>
                  )}
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Expression régulière pour valider les valeurs (optionnelle)
                  </p>
                </div>
                
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center">
                    <input
                      id="placeholderRequired"
                      type="checkbox"
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={placeholderForm.required}
                      onChange={(e) => handleFormChange('required', e.target.checked)}
                    />
                    <label htmlFor="placeholderRequired" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                      Champ obligatoire
                    </label>
                  </div>
                  
                  <div className="flex items-center">
                    <input
                      id="placeholderActive"
                      type="checkbox"
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={placeholderForm.active}
                      onChange={(e) => handleFormChange('active', e.target.checked)}
                    />
                    <label htmlFor="placeholderActive" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                      Actif
                    </label>
                  </div>
                </div>
              </div>
              
              <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 flex justify-end space-x-2">
                <Button
                  variant="outline"
                  onClick={() => setShowModal(false)}
                  type="button"
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  loading={loading}
                >
                  {isCreateMode ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal for viewing placeholder details */}
      {showDetailModal && selectedPlaceholder && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-2xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <Tag className="h-5 w-5 mr-2 text-blue-500" />
                Détails du placeholder
              </h3>
              <button
                onClick={() => setShowDetailModal(false)}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="px-6 py-4">
              <div className="space-y-6">
                {/* Header with code and name */}
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center">
                    <span className="font-mono text-sm bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-300 px-2 py-1 rounded mr-2">
                      {selectedPlaceholder.code}
                    </span>
                    <StatusBadge 
                      status={selectedPlaceholder.active ? 'active' : 'inactive'} 
                      customLabel={selectedPlaceholder.active ? 'Actif' : 'Inactif'} 
                    />
                  </div>
                  <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                    {selectedPlaceholder.name}
                  </h2>
                  {selectedPlaceholder.description && (
                    <p className="text-gray-600 dark:text-gray-300">
                      {selectedPlaceholder.description}
                    </p>
                  )}
                </div>
                
                {/* Technical details */}
                <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg space-y-3">
                  <h4 className="font-medium text-gray-900 dark:text-white">Informations techniques</h4>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-400">Type de données</div>
                      <div className="flex items-center mt-1">
                        <Hash className="h-4 w-4 text-gray-400 mr-1" />
                        <span className="text-gray-900 dark:text-white">
                          {getDataTypeLabel(selectedPlaceholder.dataType)} ({selectedPlaceholder.dataType})
                        </span>
                      </div>
                      <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                        {getDataTypeDescription(selectedPlaceholder.dataType)}
                      </div>
                    </div>
                    
                    <div>
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-400">Requis</div>
                      <div className="mt-1 text-gray-900 dark:text-white">
                        {selectedPlaceholder.required ? 'Oui' : 'Non'}
                      </div>
                    </div>
                  </div>
                  
                  <div className="pt-3 border-t border-gray-200 dark:border-gray-600">
                    <div className="text-sm font-medium text-gray-500 dark:text-gray-400">Valeur par défaut</div>
                    <div className="mt-1">
                      {selectedPlaceholder.defaultValue ? (
                        <div className="px-3 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded font-mono text-sm text-gray-900 dark:text-white">
                          {selectedPlaceholder.defaultValue}
                        </div>
                      ) : (
                        <div className="text-gray-500 dark:text-gray-400 italic">
                          Aucune valeur par défaut définie
                        </div>
                      )}
                    </div>
                  </div>
                  
                  {selectedPlaceholder.validationRegex && (
                    <div className="pt-3 border-t border-gray-200 dark:border-gray-600">
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-400">Expression régulière de validation</div>
                      <div className="mt-1">
                        <div className="px-3 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded font-mono text-sm text-gray-900 dark:text-white flex items-center">
                          <Code className="h-4 w-4 text-gray-400 mr-2" />
                          {selectedPlaceholder.validationRegex}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Metadata */}
                <div className="text-xs text-gray-500 dark:text-gray-400 grid grid-cols-2 gap-4">
                  <div>
                    <div className="font-medium">Créé par</div>
                    <div>{selectedPlaceholder.createdBy || 'Système'}</div>
                    <div>{new Date(selectedPlaceholder.createdAt).toLocaleString()}</div>
                  </div>
                  
                  {selectedPlaceholder.updatedAt && (
                    <div>
                      <div className="font-medium">Dernière mise à jour</div>
                      <div>{selectedPlaceholder.updatedBy || 'Système'}</div>
                      <div>{new Date(selectedPlaceholder.updatedAt).toLocaleString()}</div>
                    </div>
                  )}
                </div>
                
                {/* Usage example */}
                <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg">
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">Exemple d'utilisation dans un modèle</h4>
                  <div className="px-3 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded font-mono text-sm text-gray-900 dark:text-white">
                    {`{{${selectedPlaceholder.code}}}`}
                  </div>
                </div>
              </div>
            </div>
            
            <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={() => setShowDetailModal(false)}
              >
                Fermer
              </Button>
              <Button
                variant="primary"
                icon={Edit}
                onClick={() => {
                  setShowDetailModal(false);
                  openEditModal(selectedPlaceholder);
                }}
              >
                Modifier
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Placeholders;