import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button as MuiButton,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Tooltip,
  CircularProgress,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardActions,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Switch,
  FormControlLabel,
  Divider,
  Avatar,
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  LinearProgress,
  Fab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Snackbar
} from '@mui/material';

import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RTooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area
} from 'recharts';

import {
  Play,
  Pause,
  Square,
  RefreshCw,
  Clock,
  CheckCircle,
  XCircle,
  AlertTriangle,
  BarChart2,
  FileText,
  ChevronRight,
  Search,
  Filter,
  List,
  Grid as GridIcon,
  ArrowRight,
  Calendar,
  Settings,
  Info,
  Eye,
  ChevronDown,
  ChevronUp,
  Plus,
  Loader,
  Sliders,
  ExternalLink,
  MoreVertical,
  Download,
  Terminal,
  Server,
  Database,
  Activity,
  Cpu,
  HardDrive,
  Zap,
  Network,
  Monitor,
  SkipBack,
  SkipForward,
  Menu,
  Workflow,
  PlayCircle,
  PauseCircle,
  StopCircle,
  Gauge,
  ArrowUp,
  ArrowDown,
  RotateCw,
  GitBranch
} from 'lucide-react';

import api from '../../services/api.service';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';

// Définir les couleurs du thème
const COLORS = {
  primary: '#3f51b5',
  secondary: '#f50057',
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
  info: '#2196f3',
  environment: '#3f51b5',
  agent: '#673ab7',
  function: '#009688',
  task: '#ff5722',
  chartColors: ['#3f51b5', '#03a9f4', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#673ab7', '#009688', '#ff5722']
};

const ExecutionCenter = () => {
  // États pour les données principales
  const [executions, setExecutions] = useState([]);
  const [activeExecutions, setActiveExecutions] = useState([]);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [testResults, setTestResults] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  
  // États pour le filtrage
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(''); // tous les statuts par défaut
  const [campaignFilter, setCampaignFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState({
    start: '',
    end: ''
  });
  const [filteredExecutions, setFilteredExecutions] = useState([]);
  
  // États pour les contrôles de l'interface
  const [showStartModal, setShowStartModal] = useState(false);
  const [showParameters, setShowParameters] = useState(false);
  const [executionParameters, setExecutionParameters] = useState({});
  const [selectedCampaignForExecution, setSelectedCampaignForExecution] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [consoleOutput, setConsoleOutput] = useState([]);
  const consoleEndRef = useRef(null);
  
  // Notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  // États pour le chargement et les erreurs
  const [loading, setLoading] = useState({
    executions: true,
    execution: false,
    testResults: false,
    campaigns: false
  });
  const [error, setError] = useState({
    executions: null,
    execution: null,
    testResults: null,
    campaigns: null
  });
  
  // État pour le suivi en temps réel
  const [isPolling, setIsPolling] = useState(false);
  const pollingInterval = useRef(null);
  const pollingDelay = 3000; // 3 secondes
  
  // Récupération initiale des données
  useEffect(() => {
    fetchExecutions();
    fetchCampaigns();
    
    // Nettoyage des intervalles à la destruction du composant
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, []);
  
  // Effet pour filtrer les exécutions lorsque les filtres changent
  useEffect(() => {
    applyFilters();
  }, [executions, searchTerm, statusFilter, campaignFilter, dateRangeFilter]);
  
  // Effet pour démarrer ou arrêter le polling en fonction des exécutions actives
  useEffect(() => {
    const hasActiveExecutions = activeExecutions.length > 0;
    
    if (hasActiveExecutions && !isPolling) {
      startPolling();
    } else if (!hasActiveExecutions && isPolling) {
      stopPolling();
    }
    
    return () => {
      stopPolling();
    };
  }, [activeExecutions]);
  
  // Effet pour faire défiler la console vers le bas lors de nouvelles entrées
  useEffect(() => {
    if (consoleEndRef.current) {
      consoleEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [consoleOutput]);
  
  // Fonction pour récupérer toutes les exécutions
  const fetchExecutions = async () => {
    setLoading(prev => ({ ...prev, executions: true }));
    try {
      const response = await api.automata.executions.getAll();
      setExecutions(response);
      
      // Filtrer les exécutions actives (status RUNNING ou PAUSED)
      const active = response.filter(exec => 
        exec.status === 'RUNNING' || exec.status === 'PAUSED' || exec.status === 'INITIALIZING'
      );
      setActiveExecutions(active);
      
      // Sélectionner par défaut la première exécution si aucune n'est sélectionnée
      if (!selectedExecution && response.length > 0) {
        setSelectedExecution(response[0]);
        fetchExecutionDetails(response[0].id);
      }
      
      // Ajouter une entrée à la console pour indiquer l'actualisation des exécutions
      if (active.length > 0) {
        addConsoleEntry({
          type: 'info',
          message: `${active.length} exécution(s) active(s) détectée(s)`,
          timestamp: new Date()
        });
      }
    } catch (err) {
      console.error('Error fetching executions:', err);
      setError(prev => ({ ...prev, executions: 'Erreur lors du chargement des exécutions' }));
      showSnackbar('Erreur lors du chargement des exécutions', 'error');
    } finally {
      setLoading(prev => ({ ...prev, executions: false }));
    }
  };
  
  // Fonction pour récupérer les détails d'une exécution spécifique
  const fetchExecutionDetails = async (executionId) => {
    if (!executionId) return;
    
    setLoading(prev => ({ ...prev, execution: true }));
    try {
      const response = await api.automata.executions.getById(executionId);
      setSelectedExecution(response);
      fetchTestResults(executionId);
      
      // Ajouter une entrée à la console pour indiquer le chargement des détails
      addConsoleEntry({
        type: 'info',
        message: `Détails chargés pour l'exécution #${executionId}`,
        timestamp: new Date()
      });
    } catch (err) {
      console.error('Error fetching execution details:', err);
      setError(prev => ({ ...prev, execution: 'Erreur lors du chargement des détails de l\'exécution' }));
      showSnackbar('Erreur lors du chargement des détails', 'error');
    } finally {
      setLoading(prev => ({ ...prev, execution: false }));
    }
  };
  
  // Fonction pour récupérer les résultats de test d'une exécution
  const fetchTestResults = async (executionId) => {
    if (!executionId) return;
    
    setLoading(prev => ({ ...prev, testResults: true }));
    try {
      const response = await api.automata.executions.getResults(executionId);
      setTestResults(response);
      
      // Compter les résultats par statut
      const counts = response.reduce((acc, result) => {
        const status = result.status || 'UNKNOWN';
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {});
      
      // Ajouter une entrée à la console pour indiquer les résultats
      addConsoleEntry({
        type: 'success',
        message: `Résultats chargés: ${Object.entries(counts).map(([status, count]) => `${count} ${status}`).join(', ')}`,
        timestamp: new Date()
      });
    } catch (err) {
      console.error('Error fetching test results:', err);
      setError(prev => ({ ...prev, testResults: 'Erreur lors du chargement des résultats de test' }));
    } finally {
      setLoading(prev => ({ ...prev, testResults: false }));
    }
  };
  
  // Fonction pour récupérer les campagnes disponibles
  const fetchCampaigns = async () => {
    setLoading(prev => ({ ...prev, campaigns: true }));
    try {
      // Récupérer uniquement les campagnes actives
      const response = await api.automata.campaigns.getAll(null, true);
      setCampaigns(response);
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      setError(prev => ({ ...prev, campaigns: 'Erreur lors du chargement des campagnes' }));
    } finally {
      setLoading(prev => ({ ...prev, campaigns: false }));
    }
  };
  
  // Fonction pour démarrer une nouvelle exécution
  const startExecution = async () => {
    if (!selectedCampaignForExecution) {
      showSnackbar('Veuillez sélectionner une campagne', 'warning');
      return;
    }
    
    try {
      // Récupérer l'ID utilisateur (à adapter selon votre système d'authentification)
      const userId = localStorage.getItem('user_id') || 'anonymous';
      
      // Démarrer l'exécution avec les paramètres
      await api.automata.executions.start(
        selectedCampaignForExecution.id, 
        executionParameters,
        userId
      );
      
      // Fermer le modal et actualiser les données
      setShowStartModal(false);
      setSelectedCampaignForExecution(null);
      setExecutionParameters({});
      
      // Attendre un court instant pour laisser le temps au serveur de créer l'exécution
      setTimeout(fetchExecutions, 1000);
      
      // Ajouter une entrée à la console
      addConsoleEntry({
        type: 'success',
        message: `Démarrage de l'exécution de la campagne "${selectedCampaignForExecution.name}"`,
        timestamp: new Date()
      });
      
      showSnackbar('Exécution démarrée avec succès', 'success');
    } catch (err) {
      console.error('Error starting execution:', err);
      showSnackbar('Erreur lors du démarrage de l\'exécution', 'error');
      
      // Ajouter une entrée à la console
      addConsoleEntry({
        type: 'error',
        message: `Échec du démarrage: ${err.message || 'Erreur inconnue'}`,
        timestamp: new Date()
      });
    }
  };
  
  // Fonction pour arrêter une exécution
  const stopExecution = async (executionId) => {
    if (!executionId) return;
    
    try {
      // Récupérer l'ID utilisateur (à adapter selon votre système d'authentification)
      const userId = localStorage.getItem('user_id') || 'anonymous';
      
      // Arrêter l'exécution
      await api.automata.executions.stop(executionId, userId);
      
      // Actualiser les données
      fetchExecutions();
      if (selectedExecution && selectedExecution.id === executionId) {
        fetchExecutionDetails(executionId);
      }
      
      // Ajouter une entrée à la console
      addConsoleEntry({
        type: 'warning',
        message: `Arrêt de l'exécution #${executionId}`,
        timestamp: new Date()
      });
      
      showSnackbar('Exécution arrêtée avec succès', 'info');
    } catch (err) {
      console.error('Error stopping execution:', err);
      showSnackbar('Erreur lors de l\'arrêt de l\'exécution', 'error');
    }
  };
  
  // Fonction pour mettre en pause une exécution
  const pauseExecution = async (executionId) => {
    if (!executionId) return;
    
    try {
      // Récupérer l'ID utilisateur (à adapter selon votre système d'authentification)
      const userId = localStorage.getItem('user_id') || 'anonymous';
      
      // Mettre en pause l'exécution
      await api.automata.executions.pause(executionId, userId);
      
      // Actualiser les données
      fetchExecutions();
      if (selectedExecution && selectedExecution.id === executionId) {
        fetchExecutionDetails(executionId);
      }
      
      // Ajouter une entrée à la console
      addConsoleEntry({
        type: 'info',
        message: `Pause de l'exécution #${executionId}`,
        timestamp: new Date()
      });
      
      showSnackbar('Exécution mise en pause avec succès', 'info');
    } catch (err) {
      console.error('Error pausing execution:', err);
      showSnackbar('Erreur lors de la mise en pause de l\'exécution', 'error');
    }
  };
  
  // Fonction pour reprendre une exécution
  const resumeExecution = async (executionId) => {
    if (!executionId) return;
    
    try {
      // Récupérer l'ID utilisateur (à adapter selon votre système d'authentification)
      const userId = localStorage.getItem('user_id') || 'anonymous';
      
      // Reprendre l'exécution
      await api.automata.executions.resume(executionId, userId);
      
      // Actualiser les données
      fetchExecutions();
      if (selectedExecution && selectedExecution.id === executionId) {
        fetchExecutionDetails(executionId);
      }
      
      // Ajouter une entrée à la console
      addConsoleEntry({
        type: 'success',
        message: `Reprise de l'exécution #${executionId}`,
        timestamp: new Date()
      });
      
      showSnackbar('Exécution reprise avec succès', 'success');
    } catch (err) {
      console.error('Error resuming execution:', err);
      showSnackbar('Erreur lors de la reprise de l\'exécution', 'error');
    }
  };
  
  // Fonction pour calculer les statistiques
  const calculateStatistics = async (executionId) => {
    if (!executionId) return;
    
    try {
      await api.automata.executions.calculateStatistics(executionId);
      
      // Actualiser les détails après le calcul
      fetchExecutionDetails(executionId);
      
      // Ajouter une entrée à la console
      addConsoleEntry({
        type: 'info',
        message: `Statistiques calculées pour l'exécution #${executionId}`,
        timestamp: new Date()
      });
      
      showSnackbar('Statistiques calculées avec succès', 'success');
    } catch (err) {
      console.error('Error calculating statistics:', err);
      showSnackbar('Erreur lors du calcul des statistiques', 'error');
    }
  };
  
  // Filtrer les exécutions en fonction des critères
  const applyFilters = () => {
    let filtered = [...executions];
    
    // Filtre par terme de recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(execution => 
        execution.executionName?.toLowerCase().includes(term) ||
        execution.executionId?.toLowerCase().includes(term) ||
        execution.campaign?.name?.toLowerCase().includes(term)
      );
    }
    
    // Filtre par statut
    if (statusFilter) {
      filtered = filtered.filter(execution => execution.status === statusFilter);
    }
    
    // Filtre par campagne
    if (campaignFilter) {
      filtered = filtered.filter(execution => 
        execution.campaign && execution.campaign.id === parseInt(campaignFilter)
      );
    }
    
    // Filtre par plage de dates
    if (dateRangeFilter.start) {
      const startDate = new Date(dateRangeFilter.start);
      filtered = filtered.filter(execution => 
        execution.startTime && new Date(execution.startTime) >= startDate
      );
    }
    if (dateRangeFilter.end) {
      const endDate = new Date(dateRangeFilter.end);
      endDate.setHours(23, 59, 59, 999); // Fin de la journée
      filtered = filtered.filter(execution => 
        execution.startTime && new Date(execution.startTime) <= endDate
      );
    }
    
    // Tri par date de démarrage (plus récent en premier)
    filtered.sort((a, b) => {
      if (!a.startTime) return 1;
      if (!b.startTime) return -1;
      return new Date(b.startTime) - new Date(a.startTime);
    });
    
    setFilteredExecutions(filtered);
  };
  
  // Fonction pour démarrer le polling des exécutions actives
  const startPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }
    
    pollingInterval.current = setInterval(() => {
      fetchExecutions();
      
      // Si une exécution est sélectionnée, actualiser ses détails et résultats
      if (selectedExecution) {
        fetchExecutionDetails(selectedExecution.id);
      }
      
      // Ajouter une entrée à la console pour indiquer l'actualisation périodique
      addConsoleEntry({
        type: 'system',
        message: `Actualisation automatique...`,
        timestamp: new Date()
      });
    }, pollingDelay);
    
    setIsPolling(true);
  };
  
  // Fonction pour arrêter le polling
  const stopPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
    }
    
    setIsPolling(false);
  };
  
  // Fonction pour ajouter une entrée à la console
  const addConsoleEntry = (entry) => {
    setConsoleOutput(prev => [...prev, entry]);
  };
  
  // Fonction pour afficher un snackbar
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };
  
  // Fonction pour fermer le snackbar
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };
  
  // Obtenir l'icône correspondant au statut d'exécution
  const getStatusIcon = (status, size = 18) => {
    switch (status) {
      case 'RUNNING':
        return <Play style={{ color: COLORS.info }} size={size} />;
      case 'PAUSED':
        return <Pause style={{ color: COLORS.warning }} size={size} />;
      case 'COMPLETED_SUCCESS':
        return <CheckCircle style={{ color: COLORS.success }} size={size} />;
      case 'COMPLETED_WITH_FAILURES':
        return <AlertTriangle style={{ color: COLORS.warning }} size={size} />;
      case 'COMPLETED_WITH_ERRORS':
        return <XCircle style={{ color: COLORS.error }} size={size} />;
      case 'CANCELLED':
        return <Square style={{ color: '#9e9e9e' }} size={size} />;
      case 'INITIALIZING':
        return <Loader style={{ color: COLORS.info }} size={size} />;
      default:
        return <Clock style={{ color: '#9e9e9e' }} size={size} />;
    }
  };
  
  // Obtenir la couleur correspondant au statut d'exécution
  const getStatusColor = (status) => {
    switch (status) {
      case 'RUNNING':
        return 'info';
      case 'PAUSED':
        return 'warning';
      case 'COMPLETED_SUCCESS':
        return 'success';
      case 'COMPLETED_WITH_FAILURES':
        return 'warning';
      case 'COMPLETED_WITH_ERRORS':
        return 'error';
      case 'CANCELLED':
        return 'default';
      case 'INITIALIZING':
        return 'info';
      default:
        return 'default';
    }
  };
  
  // Obtenir la couleur de fond pour la console
  const getConsoleEntryColor = (type) => {
    switch (type) {
      case 'success':
        return 'rgba(76, 175, 80, 0.1)';
      case 'error':
        return 'rgba(244, 67, 54, 0.1)';
      case 'warning':
        return 'rgba(255, 152, 0, 0.1)';
      case 'info':
        return 'rgba(33, 150, 243, 0.1)';
      default:
        return 'rgba(0, 0, 0, 0.05)';
    }
  };
  
  // Calculer la progression de l'exécution
  const calculateProgress = (execution) => {
    if (!execution) return 0;
    
    const total = (execution.passedTests || 0) + 
                 (execution.failedTests || 0) + 
                 (execution.skippedTests || 0) + 
                 (execution.errorTests || 0);
    
    if (total === 0) return 0;
    
    // Si l'exécution est terminée, 100%
    if (execution.status.startsWith('COMPLETED_') || execution.status === 'CANCELLED') {
      return 100;
    }
    
    // Estimer la progression en fonction des cas de test de la campagne
    if (execution.campaign && execution.campaign.testCases) {
      const totalTestCases = execution.campaign.testCases.length;
      if (totalTestCases > 0) {
        return Math.min(Math.round((total / totalTestCases) * 100), 99);
      }
    }
    
    return Math.min(95, total * 5); // Estimation par défaut (max 95% sauf si terminé)
  };
  
  // Données pour les graphiques
  const getChartData = () => {
    if (!selectedExecution) return { status: [], progress: [] };
    
    // Données pour le graphique circulaire des statuts
    const statusData = [
      { name: 'Réussis', value: selectedExecution.passedTests || 0, color: COLORS.success },
      { name: 'Échoués', value: selectedExecution.failedTests || 0, color: COLORS.error },
      { name: 'Ignorés', value: selectedExecution.skippedTests || 0, color: COLORS.warning },
      { name: 'Erreurs', value: selectedExecution.errorTests || 0, color: '#9e9e9e' }
    ];
    
    // Données pour le graphique de progression
    // Simulons quelques données historiques (à adapter avec des données réelles)
    const now = new Date();
    const progressData = Array.from({ length: 10 }, (_, i) => {
      const time = new Date(now);
      time.setMinutes(now.getMinutes() - (10 - i));
      return {
        time: time.toISOString(),
        passed: Math.floor((selectedExecution.passedTests || 0) * (i + 1) / 10),
        failed: Math.floor((selectedExecution.failedTests || 0) * (i + 1) / 10),
        skipped: Math.floor((selectedExecution.skippedTests || 0) * (i + 1) / 10)
      };
    });
    
    // Données de performances simulées
    const performanceData = Array.from({ length: 10 }, (_, i) => {
      const time = new Date(now);
      time.setMinutes(now.getMinutes() - (10 - i));
      return {
        time: time.toISOString(),
        cpu: 30 + Math.random() * 40,
        memory: 40 + Math.random() * 30,
        duration: Math.random() * 2 + 0.5
      };
    });
    
    return { 
      status: statusData, 
      progress: progressData,
      performance: performanceData
    };
  };
  
  // Rendu du panneau principal contenant les détails de l'exécution sélectionnée
  const renderExecutionDetails = () => {
    if (!selectedExecution) {
      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          height: '100%', 
          p: 3 
        }}>
          <Clock size={64} color="#9e9e9e" />
          <Typography variant="h6" sx={{ mt: 2, color: 'text.secondary' }}>
            Aucune exécution sélectionnée
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 3, textAlign: 'center' }}>
            Sélectionnez une exécution dans la liste ou démarrez une nouvelle exécution
          </Typography>
          <MuiButton
            variant="contained"
            color="primary"
            startIcon={<Play />}
            onClick={() => setShowStartModal(true)}
          >
            Nouvelle exécution
          </MuiButton>
        </Box>
      );
    }
    
    const chartData = getChartData();
    const progress = calculateProgress(selectedExecution);
    
    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* En-tête avec les informations de base et les actions */}
        <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 
              selectedExecution.status === 'RUNNING' ? COLORS.info :
              selectedExecution.status === 'PAUSED' ? COLORS.warning :
              selectedExecution.status === 'COMPLETED_SUCCESS' ? COLORS.success :
              selectedExecution.status.includes('FAILURE') ? COLORS.error :
              'grey.500',
              mr: 2
            }}>
              {getStatusIcon(selectedExecution.status, 24)}
            </Avatar>
            <Box>
              <Typography variant="h6">
                {selectedExecution.executionName || `Exécution #${selectedExecution.id}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ID: {selectedExecution.executionId} • Campagne: {selectedExecution.campaign?.name || 'Inconnue'}
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Chip
              label={selectedExecution.status}
              color={getStatusColor(selectedExecution.status)}
              icon={getStatusIcon(selectedExecution.status, 16)}
            />
            
            {/* Contrôles d'exécution */}
            {selectedExecution.status === 'RUNNING' && (
              <>
                <MuiButton
                  variant="outlined"
                  color="warning"
                  startIcon={<Pause />}
                  onClick={() => pauseExecution(selectedExecution.id)}
                >
                  Pause
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  color="error"
                  startIcon={<Square />}
                  onClick={() => stopExecution(selectedExecution.id)}
                >
                  Arrêter
                </MuiButton>
              </>
            )}
            
            {selectedExecution.status === 'PAUSED' && (
              <>
                <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<Play />}
                  onClick={() => resumeExecution(selectedExecution.id)}
                >
                  Reprendre
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  color="error"
                  startIcon={<Square />}
                  onClick={() => stopExecution(selectedExecution.id)}
                >
                  Arrêter
                </MuiButton>
              </>
            )}
            
            {/* Actions pour les exécutions terminées */}
            {(selectedExecution.status.startsWith('COMPLETED_') || selectedExecution.status === 'CANCELLED') && (
              <>
                <MuiButton
                  variant="outlined"
                  startIcon={<BarChart2 />}
                  onClick={() => calculateStatistics(selectedExecution.id)}
                >
                  Calculer les statistiques
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  startIcon={<FileText />}
                  onClick={() => window.location.href = `/atm-campaign/cpg-06-reportinghub?executionId=${selectedExecution.id}`}
                >
                  Générer un rapport
                </MuiButton>
              </>
            )}
          </Box>
        </Paper>
        
        {/* Onglets pour la navigation */}
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
        >
          <Tab 
            label="Tableau de bord" 
            icon={<BarChart2 size={18} />}
            iconPosition="start"
          />
          <Tab 
            label="Résultats de test" 
            icon={<CheckCircle size={18} />}
            iconPosition="start"
          />
          <Tab 
            label="Performances" 
            icon={<Activity size={18} />}
            iconPosition="start"
          />
          <Tab 
            label="Console" 
            icon={<Terminal size={18} />}
            iconPosition="start"
          />
          <Tab 
            label="Paramètres" 
            icon={<Settings size={18} />}
            iconPosition="start"
          />
        </Tabs>
        
        {/* Contenu de l'onglet */}
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {/* Onglet Tableau de bord */}
          {activeTab === 0 && (
            <Grid container spacing={2}>
              {/* KPIs */}
              <Grid item xs={12} md={3}>
                <MuiCard>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Progression
                    </Typography>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={progress}
                        size={80}
                        thickness={4}
                        sx={{ color: 
                          selectedExecution.status === 'RUNNING' ? COLORS.info :
                          selectedExecution.status === 'PAUSED' ? COLORS.warning :
                          selectedExecution.status === 'COMPLETED_SUCCESS' ? COLORS.success :
                          COLORS.error
                        }}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant="h6" component="div">
                          {`${Math.round(progress)}%`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Statut: <Chip size="small" label={selectedExecution.status} color={getStatusColor(selectedExecution.status)} />
                      </Typography>
                    </Box>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12} md={3}>
                <MuiCard>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Démarré le
                    </Typography>
                    <Typography variant="h6">
                      <DateFormatter date={selectedExecution.startTime} format="full" showTime={true} />
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {selectedExecution.endTime ? (
                        `Terminé le: ${new Date(selectedExecution.endTime).toLocaleString()}`
                      ) : (
                        'En cours d\'exécution'
                      )}
                    </Typography>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12} md={3}>
                <MuiCard>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Durée
                    </Typography>
                    <Typography variant="h6">
                      {selectedExecution.startTime ? (
                        calculerDuree(selectedExecution.startTime, selectedExecution.endTime || new Date())
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {selectedExecution.status === 'RUNNING' && 'Temps écoulé'}
                      {selectedExecution.status === 'PAUSED' && 'Exécution en pause'}
                      {(selectedExecution.status.startsWith('COMPLETED_') || selectedExecution.status === 'CANCELLED') && 'Durée totale'}
                    </Typography>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12} md={3}>
                <MuiCard>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Taux de réussite
                    </Typography>
                    <Typography variant="h6">
                      {calculerTauxReussite(selectedExecution)}%
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <CheckCircle size={16} color={COLORS.success} style={{ marginRight: 4 }} />
                      <Typography variant="body2" color="text.secondary">
                        {selectedExecution.passedTests || 0} sur {
                          (selectedExecution.passedTests || 0) + 
                          (selectedExecution.failedTests || 0) + 
                          (selectedExecution.skippedTests || 0)
                        } tests
                      </Typography>
                    </Box>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              {/* Graphiques */}
              <Grid item xs={12} md={6}>
                <MuiCard sx={{ height: '100%' }}>
                  <CardHeader 
                    title="Répartition des résultats" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    subheaderTypographyProps={{ variant: 'caption' }}
                    subheader="Distribution des statuts de test"
                  />
                  <Divider />
                  <CardContent sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={chartData.status}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                          outerRadius={100}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {chartData.status.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <RTooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <MuiCard sx={{ height: '100%' }}>
                  <CardHeader 
                    title="Progression de l'exécution" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    subheaderTypographyProps={{ variant: 'caption' }}
                    subheader="Évolution des résultats dans le temps"
                  />
                  <Divider />
                  <CardContent sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={chartData.progress}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="time" 
                          tickFormatter={(value) => new Date(value).toLocaleTimeString()} 
                        />
                        <YAxis />
                        <RTooltip 
                          formatter={(value, name) => [value, name === 'passed' ? 'Réussis' : name === 'failed' ? 'Échoués' : 'Ignorés']}
                          labelFormatter={(value) => new Date(value).toLocaleTimeString()}
                        />
                        <Legend />
                        <Area type="monotone" dataKey="passed" stackId="1" stroke={COLORS.success} fill={COLORS.success} />
                        <Area type="monotone" dataKey="failed" stackId="1" stroke={COLORS.error} fill={COLORS.error} />
                        <Area type="monotone" dataKey="skipped" stackId="1" stroke={COLORS.warning} fill={COLORS.warning} />
                      </AreaChart>
                    </ResponsiveContainer>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              {/* Liste des résultats récents */}
              <Grid item xs={12}>
                <MuiCard>
                  <CardHeader 
                    title="Derniers résultats de test" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    subheaderTypographyProps={{ variant: 'caption' }}
                    subheader="5 derniers tests exécutés"
                    action={
                      <MuiButton 
                        variant="text" 
                        size="small" 
                        endIcon={<ChevronRight size={16} />}
                        onClick={() => setActiveTab(1)}
                      >
                        Voir tous les résultats
                      </MuiButton>
                    }
                  />
                  <Divider />
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Test</TableCell>
                          <TableCell>Statut</TableCell>
                          <TableCell>Démarré</TableCell>
                          <TableCell>Durée</TableCell>
                          <TableCell>Détails</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading.testResults ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <CircularProgress size={24} />
                            </TableCell>
                          </TableRow>
                        ) : testResults.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              Aucun résultat de test disponible
                            </TableCell>
                          </TableRow>
                        ) : (
                          testResults.slice(0, 5).map((result) => (
                            <TableRow key={result.id} hover>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  {result.status === 'PASSED' && <CheckCircle size={16} color={COLORS.success} style={{ marginRight: 8 }} />}
                                  {result.status === 'FAILED' && <XCircle size={16} color={COLORS.error} style={{ marginRight: 8 }} />}
                                  {result.status === 'SKIPPED' && <AlertTriangle size={16} color={COLORS.warning} style={{ marginRight: 8 }} />}
                                  <Typography variant="body2">
                                    {result.testCase?.name || 'Test inconnu'}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Chip 
                                  size="small" 
                                  label={result.status} 
                                  color={
                                    result.status === 'PASSED' ? 'success' :
                                    result.status === 'FAILED' ? 'error' :
                                    'warning'
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <DateFormatter date={result.startTime} format="time" />
                              </TableCell>
                              <TableCell>
                                {result.startTime && result.endTime ? (
                                  calculerDuree(result.startTime, result.endTime)
                                ) : 'N/A'}
                              </TableCell>
                              <TableCell>
                                <IconButton size="small">
                                  <Eye size={16} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MuiCard>
              </Grid>
            </Grid>
          )}
          
          {/* Onglet Résultats de test */}
          {activeTab === 1 && (
            <Box>
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TextField
                  placeholder="Rechercher des résultats..."
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search size={20} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: 300 }}
                />
                
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel>Statut</InputLabel>
                    <Select
                      label="Statut"
                      value="all"
                    >
                      <MenuItem value="all">Tous les statuts</MenuItem>
                      <MenuItem value="PASSED">Réussis</MenuItem>
                      <MenuItem value="FAILED">Échoués</MenuItem>
                      <MenuItem value="SKIPPED">Ignorés</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <MuiButton
                    variant="outlined"
                    startIcon={<RefreshCw size={16} />}
                    onClick={() => fetchTestResults(selectedExecution.id)}
                  >
                    Actualiser
                  </MuiButton>
                </Box>
              </Box>
              
              <MuiCard>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Test</TableCell>
                        <TableCell>Statut</TableCell>
                        <TableCell>Démarré le</TableCell>
                        <TableCell>Terminé le</TableCell>
                        <TableCell>Durée</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading.testResults ? (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <CircularProgress size={24} />
                          </TableCell>
                        </TableRow>
                      ) : testResults.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            Aucun résultat de test disponible
                          </TableCell>
                        </TableRow>
                      ) : (
                        testResults.map((result) => (
                          <TableRow key={result.id} hover>
                            <TableCell>
                              <Box>
                                <Typography variant="body2" fontWeight="medium">
                                  {result.testCase?.name || 'Test inconnu'}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {result.testCase?.testCaseId || 'ID inconnu'}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Chip 
                                size="small" 
                                label={result.status} 
                                color={
                                  result.status === 'PASSED' ? 'success' :
                                  result.status === 'FAILED' ? 'error' :
                                  result.status === 'SKIPPED' ? 'warning' :
                                  'default'
                                }
                                icon={
                                  result.status === 'PASSED' ? <CheckCircle size={14} /> :
                                  result.status === 'FAILED' ? <XCircle size={14} /> :
                                  result.status === 'SKIPPED' ? <AlertTriangle size={14} /> :
                                  <Clock size={14} />
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <DateFormatter date={result.startTime} format="datetime" />
                            </TableCell>
                            <TableCell>
                              {result.endTime ? (
                                <DateFormatter date={result.endTime} format="datetime" />
                              ) : 'En cours'}
                            </TableCell>
                            <TableCell>
                              {result.startTime && result.endTime ? (
                                calculerDuree(result.startTime, result.endTime)
                              ) : 'N/A'}
                            </TableCell>
                            <TableCell>
                              <IconButton size="small" color="primary" onClick={() => {
                                // Afficher les détails du résultat
                                addConsoleEntry({
                                  type: 'info',
                                  message: `Consultation des détails du test "${result.testCase?.name}"`,
                                  timestamp: new Date()
                                });
                              }}>
                                <Eye size={18} />
                              </IconButton>
                              {result.status === 'FAILED' && (
                                <Tooltip title="Voir les logs d'erreur">
                                  <IconButton size="small" color="error">
                                    <Terminal size={18} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MuiCard>
            </Box>
          )}
          
          {/* Onglet Performances */}
          {activeTab === 2 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <MuiCard sx={{ height: '100%' }}>
                  <CardHeader 
                    title="Utilisation CPU" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                  />
                  <Divider />
                  <CardContent sx={{ height: 250 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={getChartData().performance}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="time" 
                          tickFormatter={(value) => new Date(value).toLocaleTimeString()} 
                        />
                        <YAxis />
                        <RTooltip 
                          formatter={(value) => [`${value.toFixed(1)}%`, 'CPU']}
                          labelFormatter={(value) => new Date(value).toLocaleTimeString()}
                        />
                        <Legend />
                        <Line type="monotone" dataKey="cpu" stroke={COLORS.primary} activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <MuiCard sx={{ height: '100%' }}>
                  <CardHeader 
                    title="Utilisation Mémoire" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                  />
                  <Divider />
                  <CardContent sx={{ height: 250 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={getChartData().performance}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="time" 
                          tickFormatter={(value) => new Date(value).toLocaleTimeString()} 
                        />
                        <YAxis />
                        <RTooltip 
                          formatter={(value) => [`${value.toFixed(1)}%`, 'Mémoire']}
                          labelFormatter={(value) => new Date(value).toLocaleTimeString()}
                        />
                        <Legend />
                        <Area type="monotone" dataKey="memory" stroke={COLORS.secondary} fill={COLORS.secondary + '40'} />
                      </AreaChart>
                    </ResponsiveContainer>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12}>
                <MuiCard>
                  <CardHeader 
                    title="Durée d'exécution des tests" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                  />
                  <Divider />
                  <CardContent sx={{ height: 250 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={getChartData().performance}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="time" 
                          tickFormatter={(value) => new Date(value).toLocaleTimeString()} 
                        />
                        <YAxis />
                        <RTooltip 
                          formatter={(value) => [`${value.toFixed(2)}s`, 'Durée']}
                          labelFormatter={(value) => new Date(value).toLocaleTimeString()}
                        />
                        <Legend />
                        <Bar dataKey="duration" fill={COLORS.info} />
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12}>
                <Alert severity="info">
                  Ces graphiques de performance sont basés sur des données simulées. Dans un environnement de production,
                  ils afficheraient des métriques réelles pour suivre les performances du système pendant l'exécution des tests.
                </Alert>
              </Grid>
            </Grid>
          )}
          
          {/* Onglet Console */}
          {activeTab === 3 && (
            <MuiCard sx={{ height: 'calc(100vh - 350px)', display: 'flex', flexDirection: 'column' }}>
              <CardHeader 
                title="Console d'exécution" 
                titleTypographyProps={{ variant: 'subtitle1' }}
                action={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton size="small" onClick={() => setConsoleOutput([])}>
                      <XCircle size={16} />
                    </IconButton>
                    <IconButton size="small" onClick={() => {
                      // Simuler une entrée de console
                      addConsoleEntry({
                        type: 'info',
                        message: 'Actualisation manuelle de la console',
                        timestamp: new Date()
                      });
                    }}>
                      <RefreshCw size={16} />
                    </IconButton>
                  </Box>
                }
              />
              <Divider />
              <CardContent sx={{ flex: 1, overflow: 'auto', bgcolor: '#f5f5f5', fontFamily: 'monospace', p: 0 }}>
                <Box sx={{ p: 2 }}>
                  {consoleOutput.length === 0 ? (
                    <Typography variant="body2" color="text.secondary" align="center">
                      Aucune sortie de console disponible
                    </Typography>
                  ) : (
                    consoleOutput.map((entry, index) => (
                      <Box 
                        key={index} 
                        sx={{ 
                          mb: 1, 
                          p: 1, 
                          borderRadius: 1, 
                          backgroundColor: getConsoleEntryColor(entry.type)
                        }}
                      >
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <Typography variant="caption" color="text.secondary" sx={{ flex: '0 0 80px' }}>
                            {new Date(entry.timestamp).toLocaleTimeString()}
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                            {entry.type === 'error' && <XCircle size={14} color={COLORS.error} style={{ marginRight: 4, verticalAlign: 'middle' }} />}
                            {entry.type === 'warning' && <AlertTriangle size={14} color={COLORS.warning} style={{ marginRight: 4, verticalAlign: 'middle' }} />}
                            {entry.type === 'success' && <CheckCircle size={14} color={COLORS.success} style={{ marginRight: 4, verticalAlign: 'middle' }} />}
                            {entry.type === 'info' && <Info size={14} color={COLORS.info} style={{ marginRight: 4, verticalAlign: 'middle' }} />}
                            {entry.message}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  )}
                  <div ref={consoleEndRef} />
                </Box>
              </CardContent>
              <Divider />
              <Box sx={{ p: 1, bgcolor: '#f0f0f0', display: 'flex' }}>
                <TextField
                  placeholder="Commande..."
                  variant="outlined"
                  size="small"
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && e.target.value) {
                      addConsoleEntry({
                        type: 'system',
                        message: `$ ${e.target.value}`,
                        timestamp: new Date()
                      });
                      
                      // Simuler une réponse
                      setTimeout(() => {
                        addConsoleEntry({
                          type: 'info',
                          message: `Commande exécutée: ${e.target.value}`,
                          timestamp: new Date()
                        });
                      }, 500);
                      
                      e.target.value = '';
                    }
                  }}
                />
                <IconButton color="primary">
                  <Play size={18} />
                </IconButton>
              </Box>
            </MuiCard>
          )}
          
          {/* Onglet Paramètres */}
          {activeTab === 4 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <MuiCard>
                  <CardHeader 
                    title="Paramètres d'exécution" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                  />
                  <Divider />
                  <CardContent>
                    {selectedExecution.executionParameters && Object.keys(selectedExecution.executionParameters).length > 0 ? (
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Paramètre</TableCell>
                              <TableCell>Valeur</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(selectedExecution.executionParameters).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{value}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography variant="body2" color="text.secondary" align="center">
                        Aucun paramètre spécifié pour cette exécution
                      </Typography>
                    )}
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <MuiCard>
                  <CardHeader 
                    title="Informations de la campagne" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                  />
                  <Divider />
                  <CardContent>
                    {selectedExecution.campaign ? (
                      <Box>
                        <Typography variant="body1" gutterBottom>
                          {selectedExecution.campaign.name}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                          <Chip 
                            label={`${selectedExecution.campaign.testCases?.length || 0} cas de test`} 
                            size="small" 
                            icon={<FileText size={14} />} 
                          />
                          <Chip 
                            label={selectedExecution.campaign.status} 
                            size="small" 
                            color={
                              selectedExecution.campaign.status === 'ACTIVE' ? 'success' :
                              selectedExecution.campaign.status === 'IN_PROGRESS' ? 'info' :
                              'default'
                            }
                          />
                        </Box>
                        <MuiButton
                          variant="outlined"
                          size="small"
                          startIcon={<ExternalLink size={16} />}
                          sx={{ mt: 2 }}
                          onClick={() => window.location.href = `/atm-campaign/cpg-02-campaignmanager?id=${selectedExecution.campaign.id}`}
                        >
                          Voir la campagne
                        </MuiButton>
                      </Box>
                    ) : (
                      <Typography variant="body2" color="text.secondary" align="center">
                        Aucune campagne associée
                      </Typography>
                    )}
                  </CardContent>
                </MuiCard>
              </Grid>
              
              <Grid item xs={12}>
                <MuiCard>
                  <CardHeader 
                    title="Options d'exécution" 
                    titleTypographyProps={{ variant: 'subtitle1' }}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Switch checked={isPolling} onChange={() => isPolling ? stopPolling() : startPolling()} />}
                          label="Actualisation automatique"
                        />
                        <Typography variant="caption" color="text.secondary" display="block">
                          Actualise automatiquement les données toutes les {pollingDelay / 1000} secondes
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Délai d'actualisation</InputLabel>
                          <Select
                            value={pollingDelay}
                            onChange={(e) => {/* Changement du délai */}}
                            label="Délai d'actualisation"
                          >
                            <MenuItem value={1000}>1 seconde</MenuItem>
                            <MenuItem value={3000}>3 secondes</MenuItem>
                            <MenuItem value={5000}>5 secondes</MenuItem>
                            <MenuItem value={10000}>10 secondes</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </MuiCard>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    );
  };
  
  // Rendu du panneau latéral contenant la liste des exécutions
  const renderExecutionsList = () => {
    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <TextField
            placeholder="Rechercher des exécutions..."
            variant="outlined"
            size="small"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Statut</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  label="Statut"
                >
                  <MenuItem value="">Tous les statuts</MenuItem>
                  <MenuItem value="RUNNING">En cours</MenuItem>
                  <MenuItem value="PAUSED">En pause</MenuItem>
                  <MenuItem value="COMPLETED_SUCCESS">Succès</MenuItem>
                  <MenuItem value="COMPLETED_WITH_FAILURES">Échecs partiels</MenuItem>
                  <MenuItem value="COMPLETED_WITH_ERRORS">Erreurs</MenuItem>
                  <MenuItem value="CANCELLED">Annulé</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Campagne</InputLabel>
                <Select
                  value={campaignFilter}
                  onChange={(e) => setCampaignFilter(e.target.value)}
                  label="Campagne"
                >
                  <MenuItem value="">Toutes les campagnes</MenuItem>
                  {campaigns.map((campaign) => (
                    <MenuItem key={campaign.id} value={campaign.id}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" fontWeight="medium">
            Exécutions {isPolling && <Chip 
              size="small" 
              label="Actualisation en direct" 
              color="info" 
              icon={<Zap size={14} />} 
            />}
          </Typography>
          
          <IconButton color="primary" onClick={fetchExecutions}>
            <RefreshCw size={18} />
          </IconButton>
        </Box>
        
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {loading.executions ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          ) : filteredExecutions.length === 0 ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 4 }}>
              <Clock size={48} color="#9e9e9e" />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Aucune exécution trouvée
              </Typography>
            </Box>
          ) : (
            <Stack spacing={1}>
              {filteredExecutions.map((execution) => (
                <Paper
                  key={execution.id}
                  sx={{ 
                    p: 2, 
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    borderLeft: 4,
                    borderColor: execution.status === 'RUNNING' ? COLORS.info :
                                 execution.status === 'PAUSED' ? COLORS.warning :
                                 execution.status === 'COMPLETED_SUCCESS' ? COLORS.success :
                                 execution.status.includes('FAILURE') ? COLORS.error :
                                 '#9e9e9e',
                    bgcolor: selectedExecution && selectedExecution.id === execution.id ? 
                             'rgba(63, 81, 181, 0.08)' : 'background.paper',
                    '&:hover': {
                      bgcolor: 'rgba(0, 0, 0, 0.04)',
                      transform: 'translateY(-2px)',
                      boxShadow: 1
                    }
                  }}
                  onClick={() => {
                    setSelectedExecution(execution);
                    fetchExecutionDetails(execution.id);
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {getStatusIcon(execution.status)}
                      <Typography variant="body1" fontWeight="medium" sx={{ ml: 1 }}>
                        {execution.executionName?.substring(0, 30) || `Exécution #${execution.id}`}
                      </Typography>
                    </Box>
                    <Chip 
                      size="small" 
                      label={execution.status} 
                      color={getStatusColor(execution.status)} 
                    />
                  </Box>
                  
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    {execution.campaign?.name || 'Campagne inconnue'}
                  </Typography>
                  
                  {(execution.status === 'RUNNING' || execution.status === 'PAUSED') && (
                    <LinearProgress 
                      variant={execution.status === 'RUNNING' ? 'determinate' : 'determinate'} 
                      value={calculateProgress(execution)} 
                      sx={{ 
                        mb: 1, 
                        height: 6, 
                        borderRadius: 1,
                        bgcolor: 'rgba(0, 0, 0, 0.08)'
                      }}
                      color={execution.status === 'RUNNING' ? 'primary' : 'warning'}
                    />
                  )}
                  
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="caption" color="text.secondary">
                      <DateFormatter date={execution.startTime} format="relative" />
                    </Typography>
                    
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {execution.passedTests > 0 && (
                        <Chip 
                          size="small"
                          label={execution.passedTests}
                          sx={{ 
                            bgcolor: 'rgba(76, 175, 80, 0.1)', 
                            color: COLORS.success,
                            height: 22
                          }}
                          icon={<CheckCircle size={12} color={COLORS.success} />}
                        />
                      )}
                      
                      {execution.failedTests > 0 && (
                        <Chip 
                          size="small"
                          label={execution.failedTests}
                          sx={{ 
                            bgcolor: 'rgba(244, 67, 54, 0.1)', 
                            color: COLORS.error,
                            height: 22
                          }}
                          icon={<XCircle size={12} color={COLORS.error} />}
                        />
                      )}
                      
                      {execution.skippedTests > 0 && (
                        <Chip 
                          size="small"
                          label={execution.skippedTests}
                          sx={{ 
                            bgcolor: 'rgba(255, 152, 0, 0.1)', 
                            color: COLORS.warning,
                            height: 22
                          }}
                          icon={<AlertTriangle size={12} color={COLORS.warning} />}
                        />
                      )}
                    </Box>
                  </Box>
                </Paper>
              ))}
            </Stack>
          )}
        </Box>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Box sx={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', bgcolor: '#f5f5f5' }}>
      {/* En-tête */}
      <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PlayCircle size={24} color={COLORS.primary} />
          <Typography variant="h5" sx={{ ml: 1, fontWeight: 'medium' }}>
            Centre d'Exécution
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          <MuiButton
            variant="outlined"
            startIcon={<RefreshCw />}
            onClick={() => {
              fetchExecutions();
              addConsoleEntry({
                type: 'system',
                message: 'Actualisation manuelle des données',
                timestamp: new Date()
              });
            }}
          >
            Actualiser
          </MuiButton>
          <MuiButton
            variant="contained"
            color="primary"
            startIcon={<Play />}
            onClick={() => setShowStartModal(true)}
          >
            Nouvelle exécution
          </MuiButton>
        </Box>
      </Paper>
      
      {/* Contenu principal */}
      <Box sx={{ display: 'flex', flex: 1, gap: 2, overflow: 'hidden', px: 2, pb: 2 }}>
        {/* Panneau latéral avec la liste des exécutions */}
        <Box sx={{ width: 400, height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          {renderExecutionsList()}
        </Box>
        
        {/* Panneau principal avec les détails de l'exécution */}
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <Paper sx={{ p: 0, height: '100%', overflow: 'hidden' }}>
            {renderExecutionDetails()}
          </Paper>
        </Box>
      </Box>
      
      {/* Modal de nouvelle exécution */}
      <Dialog 
        open={showStartModal} 
        onClose={() => setShowStartModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PlayCircle size={24} color={COLORS.primary} sx={{ mr: 1 }} />
            Lancer une nouvelle exécution
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Sélectionnez une campagne
              </Typography>
              
              {loading.campaigns ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              ) : campaigns.length === 0 ? (
                <Alert severity="warning" sx={{ mb: 2 }}>
                  Aucune campagne active disponible. Veuillez créer ou activer une campagne.
                </Alert>
              ) : (
                <Grid container spacing={2}>
                  {campaigns.map(campaign => (
                    <Grid item xs={12} sm={6} key={campaign.id}>
                      <Paper
                        sx={{
                          p: 2,
                          cursor: 'pointer',
                          border: '1px solid',
                          borderColor: selectedCampaignForExecution && selectedCampaignForExecution.id === campaign.id ? 
                                     COLORS.primary : 'divider',
                          bgcolor: selectedCampaignForExecution && selectedCampaignForExecution.id === campaign.id ? 
                                  'rgba(63, 81, 181, 0.08)' : 'background.paper',
                          transition: 'all 0.2s',
                          '&:hover': {
                            boxShadow: 1,
                            borderColor: COLORS.primary
                          }
                        }}
                        onClick={() => setSelectedCampaignForExecution(campaign)}
                      >
                        <Typography variant="subtitle1" fontWeight="medium">
                          {campaign.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                          {campaign.campaignId}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Chip 
                            size="small" 
                            label={campaign.status} 
                            color={
                              campaign.status === 'ACTIVE' ? 'success' :
                              campaign.status === 'IN_PROGRESS' ? 'info' :
                              'default'
                            }
                          />
                          <Chip 
                            size="small" 
                            label={`${campaign.testCases?.length || 0} tests`} 
                            variant="outlined"
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            
            {selectedCampaignForExecution && (
              <Grid item xs={12}>
                <Accordion expanded={showParameters} onChange={() => setShowParameters(!showParameters)}>
                  <AccordionSummary expandIcon={<ChevronDown />}>
                    <Typography>Paramètres d'exécution</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Environnement"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={executionParameters.environment || ''}
                          onChange={(e) => setExecutionParameters({...executionParameters, environment: e.target.value})}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Version"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={executionParameters.version || ''}
                          onChange={(e) => setExecutionParameters({...executionParameters, version: e.target.value})}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Notes"
                          fullWidth
                          multiline
                          rows={3}
                          variant="outlined"
                          size="small"
                          value={executionParameters.notes || ''}
                          onChange={(e) => setExecutionParameters({...executionParameters, notes: e.target.value})}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setShowStartModal(false)}>
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="primary"
            onClick={startExecution}
            disabled={!selectedCampaignForExecution}
            startIcon={<Play />}
          >
            Lancer l'exécution
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

// Fonction pour calculer la durée entre deux dates
const calculerDuree = (startTime, endTime) => {
  if (!startTime || !endTime) return 'N/A';
  
  const start = new Date(startTime);
  const end = new Date(endTime);
  const diff = Math.floor((end - start) / 1000); // en secondes
  
  if (diff < 60) {
    return `${diff} sec`;
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    return `${minutes} min ${seconds} sec`;
  } else {
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    return `${hours} h ${minutes} min`;
  }
};

// Fonction pour calculer le taux de réussite
const calculerTauxReussite = (execution) => {
  if (!execution) return 0;
  
  const total = (execution.passedTests || 0) + 
               (execution.failedTests || 0) + 
               (execution.skippedTests || 0) + 
               (execution.errorTests || 0);
  
  if (total === 0) return 0;
  
  return Math.round((execution.passedTests || 0) / total * 100);
};

export default ExecutionCenter;