import React, { useState, useEffect, useCallback } from 'react';
import { 
  GitBranch, 
  RefreshCw, 
  Filter, 
  Search, 
  AlertTriangle, 
  Check, 
  X, 
  Eye, 
  Clock, 
  Play, 
  Square,
  XSquare,
  CheckCircle,
  XCircle,
  PauseCircle,
  Loader,
  ChevronDown,
  ChevronRight,
  Calendar,
  BarChart2,
  Clock8,
  RotateCcw,
  ArrowRightLeft,
  AlertCircle,
  Send,
  FileText,
  Activity,
  MessageSquare,
  ArrowDownCircle,
  ArrowUpCircle
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import WorkflowStateTransitionDiagram from '../../components/shared/WorkflowStateTransitionDiagram';
import apiService from '../../services/api.service';

/**
 * Composant pour le suivi des instances de workflow RTGS V10 en cours
 */
const RtgsWorkflowMonitor = () => {
  // États pour les instances de workflow
  const [workflowInstances, setWorkflowInstances] = useState([]);
  const [filteredInstances, setFilteredInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [expandedInstanceId, setExpandedInstanceId] = useState(null);
  const [instanceHistory, setInstanceHistory] = useState([]);
  const [availableTransitions, setAvailableTransitions] = useState([]);
  
  // États pour les workflows disponibles (définitions)
  const [workflowDefinitions, setWorkflowDefinitions] = useState([]);
  const [selectedDefinition, setSelectedDefinition] = useState(null);
  
  // États pour les filtres
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [directionFilter, setDirectionFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [messageTypeFilter, setMessageTypeFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState({
    start: null,
    end: null
  });
  const [showFilters, setShowFilters] = useState(false);
  
  // États UI
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showWorkflowDiagram, setShowWorkflowDiagram] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(30); // secondes
  const [timeLeft, setTimeLeft] = useState(refreshInterval);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [statsVisible, setStatsVisible] = useState(true);
  const [workflowStats, setWorkflowStats] = useState({
    total: 0,
    active: 0,
    completed: 0,
    failed: 0,
    mtToIso: 0,
    isoToMt: 0,
    byType: {}
  });
  
  // Initialisation
  useEffect(() => {
    Promise.all([
      fetchWorkflowInstances(),
      fetchWorkflowDefinitions()
    ]).then(() => setLoading(false))
      .catch(err => {
        console.error("Erreur lors du chargement des données:", err);
        setError("Une erreur s'est produite lors du chargement des données. Veuillez réessayer.");
        setLoading(false);
      });
  }, []);
  
  // Mise à jour des statistiques lorsque les instances changent
  useEffect(() => {
    if (workflowInstances.length > 0) {
      updateWorkflowStats(workflowInstances);
    }
  }, [workflowInstances]);
  
  // Auto-rafraîchissement
  useEffect(() => {
    let intervalId;
    
    if (autoRefresh) {
      setTimeLeft(refreshInterval);
      
      // Décompte du temps restant
      const countdownId = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            return refreshInterval;
          }
          return prev - 1;
        });
      }, 1000);
      
      // Rafraîchissement périodique
      intervalId = setInterval(() => {
        fetchWorkflowInstances();
      }, refreshInterval * 1000);
      
      return () => {
        clearInterval(intervalId);
        clearInterval(countdownId);
      };
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [autoRefresh, refreshInterval]);
  
  // Filtrage des instances
  useEffect(() => {
    if (!workflowInstances.length) return;
    
    let filtered = [...workflowInstances];
    
    // Recherche textuelle
    if (searchTerm.trim() !== '') {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(instance => 
        instance.reference?.toLowerCase().includes(searchLower) ||
        instance.currentState?.toLowerCase().includes(searchLower) ||
        instance.workflowCode?.toLowerCase().includes(searchLower) ||
        instance.context?.messageReference?.toLowerCase().includes(searchLower) ||
        instance.context?.sourceIdentifier?.toLowerCase().includes(searchLower) ||
        instance.context?.destinationIdentifier?.toLowerCase().includes(searchLower)
      );
    }
    
    // Filtre par statut
    if (statusFilter !== 'all') {
      if (statusFilter === 'active') {
        filtered = filtered.filter(instance => 
          !instance.completed && !instance.finalState
        );
      } else if (statusFilter === 'completed') {
        filtered = filtered.filter(instance => 
          instance.completed && !instance.error
        );
      } else if (statusFilter === 'failed') {
        filtered = filtered.filter(instance => 
          instance.error || instance.status === 'REJECTED'
        );
      } else {
        filtered = filtered.filter(instance => 
          instance.currentState === statusFilter
        );
      }
    }
    
    // Filtre par direction (MT vers ISO ou ISO vers MT)
    if (directionFilter !== 'all') {
      if (directionFilter === 'mtToIso') {
        filtered = filtered.filter(instance => 
          instance.workflowCode.includes('_MT_TO_') || instance.workflowCode.includes('_MTTO')
        );
      } else if (directionFilter === 'isoToMt') {
        filtered = filtered.filter(instance => 
          (instance.workflowCode.includes('_TO_MT') || instance.workflowCode.includes('_TOMT')) && 
          !instance.workflowCode.includes('_MT_TO_')
        );
      }
    }
    
    // Filtre par type de workflow
    if (typeFilter !== 'all') {
      filtered = filtered.filter(instance => 
        instance.workflowCode === typeFilter
      );
    }
    
    // Filtre par type de message
    if (messageTypeFilter !== 'all') {
      filtered = filtered.filter(instance => 
        (instance.workflowCode.includes(messageTypeFilter) ||
        (instance.context && instance.context.messageType === messageTypeFilter))
      );
    }
    
    // Filtre par date
    if (dateFilter.start) {
      const startDate = new Date(dateFilter.start);
      filtered = filtered.filter(instance => 
        new Date(instance.createdAt) >= startDate
      );
    }
    
    if (dateFilter.end) {
      const endDate = new Date(dateFilter.end);
      filtered = filtered.filter(instance => 
        new Date(instance.createdAt) <= endDate
      );
    }
    
    setFilteredInstances(filtered);
  }, [workflowInstances, searchTerm, statusFilter, directionFilter, typeFilter, messageTypeFilter, dateFilter]);
  
  // Récupération des instances de workflow
  const fetchWorkflowInstances = async () => {
    try {
      // Tentative d'appel à l'API réelle
      let response;
      try {
        // Utiliser l'API de workflow pour récupérer toutes les instances
        response = await apiService.workflow.getAllWorkflowDetailsList();
        
        // Filtrer seulement les instances RTGS V10
        if (response && response.data) {
          response.data = response.data.filter(instance => 
            instance.workflowCode && instance.workflowCode.includes('RTGSV10')
          );
        }
      } catch (apiError) {
        console.warn("API non disponible, utilisation des données simulées:", apiError);
        
        // Données simulées pour le développement - adaptées pour RTGS V10
        const mockInstances = [
          {
            id: "wf-rtgs-001",
            reference: "WF-RTGSV10-PACS008-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_MT103_TO_PACS008_PROCESS",
            workflowName: "[Conversion] [MT103] [PACS.008]",
            initialState: "RECEPTION_PARTICIPANT",
            currentState: "VALIDATION_FORMAT",
            finalState: false,
            completed: false,
            error: false,
            createdAt: new Date(Date.now() - 3600000).toISOString(), // 1h avant
            lastUpdatedAt: new Date(Date.now() - 1800000).toISOString(), // 30min avant
            context: {
              messageReference: "MT103REF202402150001",
              messageType: "MT103",
              sourceIdentifier: "ABCDFRPP",
              destinationIdentifier: "EFGHBEBB",
              amount: 450000,
              currency: "XAF"
            }
          },
          {
            id: "wf-rtgs-002",
            reference: "WF-RTGSV10-MT202-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_MT202_TO_PACS009_PROCESS",
            workflowName: "[Conversion] [MT202] [PACS.009]",
            initialState: "RECEPTION_PARTICIPANT",
            currentState: "PREPARATION",
            finalState: false,
            completed: false,
            error: false,
            createdAt: new Date(Date.now() - 7200000).toISOString(), // 2h avant
            lastUpdatedAt: new Date(Date.now() - 3600000).toISOString(), // 1h avant
            context: {
              messageReference: "MT202REF202402150001",
              messageType: "MT202",
              sourceIdentifier: "EFGHBEBB",
              destinationIdentifier: "ABCDFRPP",
              amount: 275000,
              currency: "XAF"
            }
          },
          {
            id: "wf-rtgs-003",
            reference: "WF-RTGSV10-PACS009-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_PACS009_TO_MT202_PROCESS",
            workflowName: "[Conversion] [PACS.009] [MT202]",
            initialState: "RECEPTION_BEAC",
            currentState: "EMISSION_PARTICIPANT",
            finalState: true,
            completed: true,
            error: false,
            createdAt: new Date(Date.now() - 86400000).toISOString(), // 1 jour avant
            lastUpdatedAt: new Date(Date.now() - 43200000).toISOString(), // 12h avant
            context: {
              messageReference: "PACS009REF202402150001",
              messageType: "PACS.009",
              sourceIdentifier: "BEACCMCX",
              destinationIdentifier: "MNOPUS33",
              amount: 650000,
              currency: "XAF"
            }
          },
          {
            id: "wf-rtgs-004",
            reference: "WF-RTGSV10-MT192-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_MT192_TO_CAMT056_PROCESS",
            workflowName: "[Conversion] [MT192/MT292] [CAMT.056]",
            initialState: "RECEPTION_PARTICIPANT",
            currentState: "REJECTED",
            finalState: true,
            completed: true,
            error: true,
            createdAt: new Date(Date.now() - 172800000).toISOString(), // 2 jours avant
            lastUpdatedAt: new Date(Date.now() - 86400000).toISOString(), // 1 jour avant
            context: {
              messageReference: "MT192REF202402150001",
              messageType: "MT192",
              sourceIdentifier: "MNOPUS33",
              destinationIdentifier: "BEACCMCX",
              errorMessage: "Erreur de validation du format BIC"
            }
          },
          {
            id: "wf-rtgs-005",
            reference: "WF-RTGSV10-CAMT025-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_CAMT025_TO_MTN96_PROCESS",
            workflowName: "[Conversion] [CAMT.025] [MTn96]",
            initialState: "RECEPTION_BEAC",
            currentState: "TRANSFORMATION_MX_MT",
            finalState: false,
            completed: false,
            error: false,
            createdAt: new Date(Date.now() - 43200000).toISOString(), // 12h avant
            lastUpdatedAt: new Date(Date.now() - 21600000).toISOString(), // 6h avant
            context: {
              messageReference: "CAMT025REF202402150001",
              messageType: "CAMT.025",
              sourceIdentifier: "BEACCMCX",
              destinationIdentifier: "UVWXCN11"
            }
          },
          {
            id: "wf-rtgs-006",
            reference: "WF-RTGSV10-CAMT053-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_CAMT053ENDY_TO_MT950_PROCESS",
            workflowName: "[Conversion] [CAMT.053/ENDY] [MT950]",
            initialState: "RECEPTION_BEAC",
            currentState: "VERIFICATION_SIGNATURE",
            finalState: false,
            completed: false,
            error: false,
            createdAt: new Date(Date.now() - 10800000).toISOString(), // 3h avant
            lastUpdatedAt: new Date(Date.now() - 10800000).toISOString(), // 3h avant
            context: {
              messageReference: "CAMT053REF202402150001",
              messageType: "CAMT.053",
              sourceIdentifier: "BEACCMCX",
              destinationIdentifier: "BCDEPL22",
              accountNumber: "RTGS5678901234567890"
            }
          },
          {
            id: "wf-rtgs-007",
            reference: "WF-RTGSV10-MT940-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_CAMT053GCST_TO_MT940_PROCESS",
            workflowName: "[Conversion] [CAMT.053/GCST] [MT940]",
            initialState: "RECEPTION_BEAC",
            currentState: "TRANSFORMATION_MX_MT",
            finalState: false,
            completed: false,
            error: false,
            createdAt: new Date(Date.now() - 18000000).toISOString(), // 5h avant
            lastUpdatedAt: new Date(Date.now() - 14400000).toISOString(), // 4h avant
            context: {
              messageReference: "CAMT053REF202402150002",
              messageType: "CAMT.053",
              sourceIdentifier: "BEACCMCX",
              destinationIdentifier: "YZABRUC9",
              accountNumber: "RTGS987654321234567"
            }
          },
          {
            id: "wf-rtgs-008",
            reference: "WF-RTGSV10-CAMT054-001",
            workflowCode: "CEMAC_RTGSV10_ADAPTER_CAMT054CR_TO_MT910_PROCESS",
            workflowName: "[Conversion] [CAMT.054/CR] [MT910]",
            initialState: "RECEPTION_BEAC",
            currentState: "VALIDATION_DONNEES",
            finalState: false,
            completed: false,
            error: false,
            createdAt: new Date(Date.now() - 28800000).toISOString(), // 8h avant
            lastUpdatedAt: new Date(Date.now() - 25200000).toISOString(), // 7h avant
            context: {
              messageReference: "CAMT054REF202402150001",
              messageType: "CAMT.054",
              sourceIdentifier: "BEACCMCX",
              destinationIdentifier: "HIJKSG3M",
              amount: 890000,
              currency: "XAF"
            }
          }
        ];
        
        response = { data: mockInstances };
      }
      
      setWorkflowInstances(response.data);
      setFilteredInstances(response.data);
      return response.data;
    } catch (err) {
      console.error("Erreur lors du chargement des instances de workflow:", err);
      setError("Impossible de charger les instances de workflow. Veuillez réessayer.");
      return [];
    }
  };
  
  // Récupération des définitions de workflow
  const fetchWorkflowDefinitions = async () => {
    try {
      // Tentative d'appel à l'API réelle
      let response;
      try {
        response = await apiService.workflow.getAllWorkflowDefinitions();
        
        // Filtrer seulement les définitions RTGS V10
        if (response && response.data) {
          response.data = response.data.filter(def => 
            def.code && def.code.includes('RTGSV10')
          );
        }
      } catch (apiError) {
        console.warn("API non disponible, utilisation des définitions simulées:", apiError);
        
        // Définitions simulées basées sur les fichiers fournis
        const mockDefinitions = [
          {
            code: "CEMAC_RTGSV10_ADAPTER_MT103_TO_PACS008_PROCESS",
            name: "[Conversion] [MT103] [PACS.008]",
            description: "Processus de conversion des messages MT103 en pacs.008 pour RTGS V10",
            initialState: "RECEPTION_PARTICIPANT",
            finalStates: ["EMISSION_BEAC"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_MT202_TO_PACS009_PROCESS",
            name: "[Conversion] [MT202] [PACS.009]",
            description: "Processus de conversion des messages MT202 en pacs.009 pour RTGS V10",
            initialState: "RECEPTION_PARTICIPANT",
            finalStates: ["EMISSION_BEAC"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_MT192_TO_CAMT056_PROCESS",
            name: "[Conversion] [MT192/MT292] [CAMT.056]",
            description: "Processus de conversion des messages MT192/MT292 en camt.056 pour RTGS V10",
            initialState: "RECEPTION_PARTICIPANT",
            finalStates: ["EMISSION_BEAC"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_PACS009_TO_MT202_PROCESS",
            name: "[Conversion] [PACS.009] [MT202]",
            description: "Processus de conversion des messages pacs.009 en MT202 pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_PACS008_TO_MT103_PROCESS",
            name: "[Conversion] [PACS.008] [MT103]",
            description: "Processus de conversion des messages pacs.008 en MT103 pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_PACS002_TO_MTN96_PROCESS",
            name: "[Conversion] [PACS.002] [MTn96]",
            description: "Processus de conversion des messages pacs.002 en MTn96/STAT ou MTn96/ERRC pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_CAMT025_TO_MTN96_PROCESS",
            name: "[Conversion] [CAMT.025] [MTn96]",
            description: "Processus de conversion des messages camt.025 en MTn96/OK ou MTn96/ERRC ou MTn96/PRTY pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_CAMT029_TO_MTN96CANC_PROCESS",
            name: "[Conversion] [CAMT.029] [MTn96/CANC]",
            description: "Processus de conversion des messages camt.029 en MTn96/CANC pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_CAMT054CR_TO_MT910_PROCESS",
            name: "[Conversion] [CAMT.054/CR] [MT910]",
            description: "Processus de conversion des messages camt.054/CR en MT910 pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_CAMT054DR_TO_MT900_PROCESS",
            name: "[Conversion] [CAMT.054/DR] [MT900]",
            description: "Processus de conversion des messages camt.054/DR en MT900 pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_CAMT053GCST_TO_MT940_PROCESS",
            name: "[Conversion] [CAMT.053/GCST] [MT940]",
            description: "Processus de conversion des messages camt.053/GCST en MT940 pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          },
          {
            code: "CEMAC_RTGSV10_ADAPTER_CAMT053ENDY_TO_MT950_PROCESS",
            name: "[Conversion] [CAMT.053/ENDY] [MT950]",
            description: "Processus de conversion des messages camt.053/ENDY en MT950 pour RTGS V10",
            initialState: "RECEPTION_BEAC",
            finalStates: ["EMISSION_PARTICIPANT"]
          }
        ];
        
        response = { data: mockDefinitions };
      }
      
      setWorkflowDefinitions(response.data);
      return response.data;
    } catch (err) {
      console.error("Erreur lors du chargement des définitions de workflow:", err);
      setError("Impossible de charger les définitions de workflow. Veuillez réessayer.");
      return [];
    }
  };
  
  // Récupérer les détails d'une instance de workflow
  const fetchWorkflowDetails = async (instanceId) => {
    setHistoryLoading(true);
    try {
      // Tentative d'appel à l'API réelle
      let historyResponse, transitionsResponse;
      try {
        // Récupérer l'historique de l'instance
        historyResponse = await apiService.workflow.getWorkflowHistory(instanceId);
        
        // Récupérer les transitions disponibles
        transitionsResponse = await apiService.workflow.getWorkflowDetails(instanceId);
      } catch (apiError) {
        console.warn("API non disponible, utilisation des données simulées:", apiError);
        
        // Déterminer le type de workflow pour adapter l'historique simulé
        const instance = workflowInstances.find(inst => inst.id === instanceId);
        const isMtToIso = instance?.workflowCode.includes('_MT_TO_') || instance?.workflowCode.includes('_MTTO');
        
        // Simuler l'historique selon le type de flux
        let mockHistory;
        
        if (isMtToIso) {
          // Historique pour flux MT vers ISO
          mockHistory = [
            {
              id: "hist-1",
              timestamp: new Date(Date.now() - 3600000).toISOString(),
              fromState: "RECEPTION_PARTICIPANT",
              toState: "VALIDATION_FORMAT",
              event: "VALIDATE_STRUCTURE",
              user: "system",
              comment: "Validation initiale de la structure"
            },
            {
              id: "hist-2",
              timestamp: new Date(Date.now() - 3300000).toISOString(),
              fromState: "VALIDATION_FORMAT",
              toState: "VALIDATION_DONNEES",
              event: "VALIDATE_CONTENT",
              user: "system",
              comment: "Validation du contenu du message"
            }
          ];
        } else {
          // Historique pour flux ISO vers MT
          mockHistory = [
            {
              id: "hist-1",
              timestamp: new Date(Date.now() - 7200000).toISOString(),
              fromState: "RECEPTION_BEAC",
              toState: "VALIDATION_FORMAT",
              event: "VALIDATE_STRUCTURE",
              user: "system",
              comment: "Validation initiale de la structure"
            },
            {
              id: "hist-2",
              timestamp: new Date(Date.now() - 6900000).toISOString(),
              fromState: "VALIDATION_FORMAT",
              toState: "VALIDATION_DONNEES",
              event: "VALIDATE_CONTENT",
              user: "system",
              comment: "Validation du contenu du message"
            },
            {
              id: "hist-3",
              timestamp: new Date(Date.now() - 6600000).toISOString(),
              fromState: "VALIDATION_DONNEES",
              toState: "VERIFICATION_SIGNATURE",
              event: "VERIFY_SIGNATURE",
              user: "system",
              comment: "Vérification de la signature"
            },
            {
              id: "hist-4",
              timestamp: new Date(Date.now() - 6300000).toISOString(),
              fromState: "VERIFICATION_SIGNATURE",
              toState: "PREPARATION",
              event: "PREPARE_DATA",
              user: "system",
              comment: "Préparation des données"
            },
            {
              id: "hist-5",
              timestamp: new Date(Date.now() - 6000000).toISOString(),
              fromState: "PREPARATION",
              toState: "TRANSFORMATION_MX_MT",
              event: "TRANSFORM_MX_TO_MT",
              user: "system",
              comment: "Transformation du message"
            }
          ];
        }
        
        // Simuler les transitions disponibles basées sur l'état actuel
        let mockTransitions = [];
        const currentState = instance?.currentState;
        
        if (currentState === "VALIDATION_FORMAT") {
          mockTransitions = [
            {
              event: "VALIDATE_CONTENT",
              fromState: "VALIDATION_FORMAT",
              toState: "VALIDATION_DONNEES"
            }
          ];
        } else if (currentState === "VALIDATION_DONNEES") {
          if (isMtToIso) {
            mockTransitions = [
              {
                event: "PREPARE_DATA",
                fromState: "VALIDATION_DONNEES",
                toState: "PREPARATION"
              }
            ];
          } else {
            mockTransitions = [
              {
                event: "VERIFY_SIGNATURE",
                fromState: "VALIDATION_DONNEES",
                toState: "VERIFICATION_SIGNATURE"
              }
            ];
          }
        } else if (currentState === "PREPARATION") {
          if (isMtToIso) {
            mockTransitions = [
              {
                event: "TRANSFORM_MT_TO_MX",
                fromState: "PREPARATION",
                toState: "TRANSFORMATION_MT_MX"
              }
            ];
          } else {
            mockTransitions = [
              {
                event: "TRANSFORM_MX_TO_MT",
                fromState: "PREPARATION",
                toState: "TRANSFORMATION_MX_MT"
              }
            ];
          }
        } else if (currentState === "TRANSFORMATION_MX_MT") {
          mockTransitions = [
            {
              event: "VALIDATE_MESSAGE",
              fromState: "TRANSFORMATION_MX_MT",
              toState: "VALIDATED"
            }
          ];
        } else if (currentState === "TRANSFORMATION_MT_MX") {
			mockTransitions = [
            {
              event: "VALIDATE_ISO20022",
              fromState: "TRANSFORMATION_MT_MX",
              toState: "VALIDATED_ISO20022"
            }
          ];
        } else if (currentState === "VERIFICATION_SIGNATURE") {
          mockTransitions = [
            {
              event: "PREPARE_DATA",
              fromState: "VERIFICATION_SIGNATURE",
              toState: "PREPARATION"
            }
          ];
        } else if (currentState === "VALIDATED") {
          mockTransitions = [
            {
              event: "EMIT_TO_PARTICIPANT",
              fromState: "VALIDATED",
              toState: "EMISSION_PARTICIPANT"
            }
          ];
        } else if (currentState === "VALIDATED_ISO20022") {
          mockTransitions = [
            {
              event: "EMIT_TO_BEAC",
              fromState: "VALIDATED_ISO20022",
              toState: "EMISSION_BEAC"
            }
          ];
        }
        
        historyResponse = { data: mockHistory };
        transitionsResponse = { data: { availableTransitions: mockTransitions } };
      }
      
      setInstanceHistory(historyResponse.data);
      setAvailableTransitions(transitionsResponse.data.availableTransitions || []);
    } catch (err) {
      console.error("Erreur lors du chargement des détails du workflow:", err);
      setError("Impossible de charger les détails du workflow. Veuillez réessayer.");
    } finally {
      setHistoryLoading(false);
    }
  };
  
  // Mettre à jour les statistiques des workflows
  const updateWorkflowStats = (instances) => {
    const stats = {
      total: instances.length,
      active: 0,
      completed: 0,
      failed: 0,
      mtToIso: 0,
      isoToMt: 0,
      byType: {},
      byMessageType: {}
    };
    
    // Compter les différentes statistiques
    instances.forEach(instance => {
      // Compter par état
      if (instance.error || instance.currentState === "REJECTED") {
        stats.failed++;
      } else if (instance.completed) {
        stats.completed++;
      } else {
        stats.active++;
      }
      
      // Compter par direction
      if (instance.workflowCode.includes('_MT_TO_') || instance.workflowCode.includes('_MTTO')) {
        stats.mtToIso++;
      } else if ((instance.workflowCode.includes('_TO_MT') || instance.workflowCode.includes('_TOMT'))) {
        stats.isoToMt++;
      }
      
      // Compter par type de workflow
      if (!stats.byType[instance.workflowCode]) {
        stats.byType[instance.workflowCode] = {
          name: instance.workflowName,
          count: 0,
          active: 0,
          completed: 0,
          failed: 0
        };
      }
      
      stats.byType[instance.workflowCode].count++;
      
      if (instance.error || instance.currentState === "REJECTED") {
        stats.byType[instance.workflowCode].failed++;
      } else if (instance.completed) {
        stats.byType[instance.workflowCode].completed++;
      } else {
        stats.byType[instance.workflowCode].active++;
      }
      
      // Compter par type de message
      if (instance.context && instance.context.messageType) {
        const messageType = instance.context.messageType;
        
        if (!stats.byMessageType[messageType]) {
          stats.byMessageType[messageType] = {
            count: 0,
            active: 0,
            completed: 0,
            failed: 0
          };
        }
        
        stats.byMessageType[messageType].count++;
        
        if (instance.error || instance.currentState === "REJECTED") {
          stats.byMessageType[messageType].failed++;
        } else if (instance.completed) {
          stats.byMessageType[messageType].completed++;
        } else {
          stats.byMessageType[messageType].active++;
        }
      }
    });
    
    setWorkflowStats(stats);
  };
  
  // Traiter une action sur une instance de workflow
  const handleProcessEvent = async (instanceId, event) => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Tentative d'appel à l'API réelle
      let response;
      try {
        response = await apiService.workflow.processEvent(
          instanceId,
          event,
          { timestamp: new Date().toISOString() }
        );
      } catch (apiError) {
        console.warn("API non disponible, simulation du traitement:", apiError);
        
        // Simuler un délai
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Simuler l'avancement du workflow dans les données locales
        const updatedInstances = workflowInstances.map(instance => {
          if (instance.id === instanceId) {
            // Logique pour déterminer le nouvel état selon la direction du flux
            const isMtToIso = instance.workflowCode.includes('_MT_TO_') || instance.workflowCode.includes('_MTTO');
            
            // Transitions pour les flux MT vers ISO
            const mtToIsoTransitions = {
              "RECEPTION_PARTICIPANT": "VALIDATION_FORMAT",
              "VALIDATION_FORMAT": "VALIDATION_DONNEES",
              "VALIDATION_DONNEES": "VERIFICATION_COMPTE",
              "VERIFICATION_COMPTE": "PREPARATION",
              "PREPARATION": "TRANSFORMATION_MT_MX",
              "TRANSFORMATION_MT_MX": "VALIDATED_ISO20022",
              "VALIDATED_ISO20022": "EMISSION_BEAC"
            };
            
            // Transitions pour les flux ISO vers MT
            const isoToMtTransitions = {
              "RECEPTION_BEAC": "VALIDATION_FORMAT",
              "VALIDATION_FORMAT": "VALIDATION_DONNEES",
              "VALIDATION_DONNEES": "VERIFICATION_SIGNATURE",
              "VERIFICATION_SIGNATURE": "PREPARATION",
              "PREPARATION": "TRANSFORMATION_MX_MT",
              "TRANSFORMATION_MX_MT": "VALIDATED",
              "VALIDATED": "EMISSION_PARTICIPANT"
            };
            
            // Sélectionner le bon ensemble de transitions
            const transitions = isMtToIso ? mtToIsoTransitions : isoToMtTransitions;
            
            // Obtenir le nouvel état
            const newState = transitions[instance.currentState] || 
              (isMtToIso ? "EMISSION_BEAC" : "EMISSION_PARTICIPANT");
              
            // Vérifier si le nouvel état est un état final
            const isFinalState = isMtToIso ? 
              newState === "EMISSION_BEAC" : 
              newState === "EMISSION_PARTICIPANT";
            
            return {
              ...instance,
              currentState: newState,
              completed: isFinalState,
              lastUpdatedAt: new Date().toISOString()
            };
          }
          return instance;
        });
        
        setWorkflowInstances(updatedInstances);
        
        // Si l'instance est développée, mettre à jour son historique
        if (expandedInstanceId === instanceId) {
          const instance = workflowInstances.find(i => i.id === instanceId);
          const newHistoryEntry = {
            id: `hist-${Date.now()}`,
            timestamp: new Date().toISOString(),
            fromState: instance.currentState,
            toState: updatedInstances.find(i => i.id === instanceId).currentState,
            event: event,
            user: "currentUser",
            comment: "Action manuelle"
          };
          
          setInstanceHistory(prev => [...prev, newHistoryEntry]);
        }
        
        // Simuler la réponse
        response = { success: true };
      }
      
      setSuccessMessage(`L'événement a été traité avec succès.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      // Rafraîchir les données
      await fetchWorkflowInstances();
      
      // Si l'instance est développée, mettre à jour ses détails
      if (expandedInstanceId === instanceId) {
        fetchWorkflowDetails(instanceId);
      }
      
    } catch (err) {
      console.error("Erreur lors du traitement de l'événement:", err);
      setError(`Impossible de traiter l'événement. ${err.message || "Veuillez réessayer."}`);
    } finally {
      setActionLoading(false);
    }
  };
  
  // Annuler une instance de workflow
  const handleCancelWorkflow = async (instanceId) => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Demander confirmation
      const confirmation = window.confirm("Êtes-vous sûr de vouloir annuler ce workflow ? Cette action est irréversible.");
      if (!confirmation) {
        setActionLoading(false);
        return;
      }
      
      // Tentative d'appel à l'API réelle
      let response;
      try {
        response = await apiService.workflow.processEvent(
          instanceId,
          "REJECT_PROCESS",
          { reason: "Annulation manuelle par l'utilisateur" }
        );
      } catch (apiError) {
        console.warn("API non disponible, simulation de l'annulation:", apiError);
        
        // Simuler un délai
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Simuler l'annulation du workflow dans les données locales
        const updatedInstances = workflowInstances.map(instance => {
          if (instance.id === instanceId) {
            return {
              ...instance,
              currentState: "REJECTED",
              completed: true,
              error: true,
              lastUpdatedAt: new Date().toISOString()
            };
          }
          return instance;
        });
        
        setWorkflowInstances(updatedInstances);
        
        // Simuler la réponse
        response = { success: true };
      }
      
      setSuccessMessage(`Le workflow a été annulé avec succès.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      // Rafraîchir les données
      await fetchWorkflowInstances();
      
    } catch (err) {
      console.error("Erreur lors de l'annulation du workflow:", err);
      setError(`Impossible d'annuler le workflow. ${err.message || "Veuillez réessayer."}`);
    } finally {
      setActionLoading(false);
    }
  };
  
  // Développer/réduire une instance
  const toggleInstanceExpansion = (instanceId) => {
    if (expandedInstanceId === instanceId) {
      setExpandedInstanceId(null);
      setInstanceHistory([]);
      setAvailableTransitions([]);
    } else {
      setExpandedInstanceId(instanceId);
      fetchWorkflowDetails(instanceId);
    }
  };
  
  // Afficher les détails d'une instance
  const handleShowDetails = (instance) => {
    setSelectedInstance(instance);
    
    // Trouver la définition de workflow correspondante
    const definition = workflowDefinitions.find(def => def.code === instance.workflowCode);
    if (definition) {
      setSelectedDefinition(definition);
    }
    
    setShowDetailsModal(true);
    fetchWorkflowDetails(instance.id);
  };
  
  // Formater le nom d'un état pour affichage
  const formatStateName = (state) => {
    if (!state) return '';
    
    // Remplacer les underscores par des espaces et capitaliser chaque mot
    return state.split('_')
      .map(word => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  // Obtenir l'icône pour un type de workflow
  const getWorkflowIcon = (workflowCode) => {
    if (!workflowCode) return <GitBranch />;
    
    // Direction du flux
    if (workflowCode.includes('_MT_TO_') || workflowCode.includes('_MTTO')) {
      return <ArrowDownCircle />; // MT vers ISO
    } else if (workflowCode.includes('_TO_MT') || workflowCode.includes('_TOMT')) {
      return <ArrowUpCircle />; // ISO vers MT
    }
    
    // Types de messages
    if (workflowCode.includes('MT103') || workflowCode.includes('PACS008')) {
      return <Send />; // Virements clients
    } else if (workflowCode.includes('MT202') || workflowCode.includes('PACS009')) {
      return <ArrowRightLeft />; // Virements interbancaires
    } else if (workflowCode.includes('CAMT053') || workflowCode.includes('MT940') || workflowCode.includes('MT950')) {
      return <FileText />; // Relevés de compte
    } else if (workflowCode.includes('MT192') || workflowCode.includes('CAMT056')) {
      return <RotateCcw />; // Annulations
    } else if (workflowCode.includes('MTN96') || workflowCode.includes('CAMT025')) {
      return <MessageSquare />; // Réponses et status
    }
    
    return <Activity />; // Default
  };
  
  // Rendu d'un badge de statut en fonction de l'état du workflow
  const renderStatusBadge = (instance) => {
    if (instance.error || instance.currentState === "REJECTED") {
      return <StatusBadge status="inactive" customLabel="Échoué" />;
    }
    
    if (instance.completed) {
      return <StatusBadge status="active" customLabel="Terminé" />;
    }
    
    if (instance.currentState === instance.initialState) {
      return <StatusBadge status="warning" customLabel="Démarré" />;
    }
    
    return <StatusBadge status="warning" customLabel="En cours" />;
  };
  
  // Calculer la progression estimée du workflow
  const calculateProgress = (instance) => {
    if (!instance) return 0;
    
    if (instance.completed) return 100;
    if (instance.error) return 100;
    
    // Récupérer le sens du flux
    const isMtToIso = instance.workflowCode.includes('_MT_TO_') || instance.workflowCode.includes('_MTTO');
    
    // Définition des étapes selon le type de flux (MT → ISO)
    const mtToIsoSteps = [
      "RECEPTION_PARTICIPANT", 
      "VALIDATION_FORMAT", 
      "VALIDATION_DONNEES", 
      "VERIFICATION_COMPTE", 
      "PREPARATION", 
      "TRANSFORMATION_MT_MX", 
      "VALIDATED_ISO20022", 
      "EMISSION_BEAC"
    ];
    
    // Définition des étapes selon le type de flux (ISO → MT)
    const isoToMtSteps = [
      "RECEPTION_BEAC", 
      "VALIDATION_FORMAT", 
      "VALIDATION_DONNEES", 
      "VERIFICATION_SIGNATURE", 
      "PREPARATION", 
      "TRANSFORMATION_MX_MT", 
      "VALIDATED", 
      "EMISSION_PARTICIPANT"
    ];
    
    // Sélectionner les bonnes étapes
    const steps = isMtToIso ? mtToIsoSteps : isoToMtSteps;
    
    // Calculer la progression
    const currentIndex = steps.indexOf(instance.currentState);
    if (currentIndex === -1) return 50; // État non trouvé
    
    return Math.round((currentIndex / (steps.length - 1)) * 100);
  };
  
  // Réinitialiser les filtres
  const resetFilters = () => {
    setSearchTerm('');
    setStatusFilter('all');
    setDirectionFilter('all');
    setTypeFilter('all');
    setMessageTypeFilter('all');
    setDateFilter({
      start: null,
      end: null
    });
  };
  
  // Rendu du composant
  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <GitBranch className="h-6 w-6 mr-2 text-blue-500" />
            Suivi des flux RTGS V10
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Moniteur des messages ISO20022 et SWIFT pour RTGS V10
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline" 
            icon={RefreshCw}
            onClick={fetchWorkflowInstances}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant={autoRefresh ? "primary" : "outline"}
            icon={Clock}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            {autoRefresh ? `Auto (${timeLeft}s)` : "Auto-refresh"}
          </Button>
          <Button
            variant={showWorkflowDiagram ? "primary" : "outline"}
            icon={GitBranch}
            onClick={() => setShowWorkflowDiagram(!showWorkflowDiagram)}
          >
            Diagrammes
          </Button>
        </div>
      </div>
      
      {/* Messages de succès et d'erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Statistiques et résumé */}
      {statsVisible && workflowStats.total > 0 && (
        <Card 
          title="Statistiques des flux RTGS V10" 
          icon={BarChart2}
          actions={
            <Button
              variant="text"
              size="sm"
              icon={ChevronDown}
              onClick={() => setStatsVisible(false)}
            />
          }
        >
          <div className="mb-4">
            <div className="grid grid-cols-1 sm:grid-cols-5 gap-4">
              <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-blue-700 dark:text-blue-300">Total</p>
                    <p className="text-2xl font-bold text-blue-900 dark:text-blue-100">{workflowStats.total}</p>
                  </div>
                  <div className="bg-blue-100 dark:bg-blue-800 p-2 rounded-full">
                    <GitBranch className="h-5 w-5 text-blue-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-yellow-700 dark:text-yellow-300">En cours</p>
                    <p className="text-2xl font-bold text-yellow-900 dark:text-yellow-100">{workflowStats.active}</p>
                  </div>
                  <div className="bg-yellow-100 dark:bg-yellow-800 p-2 rounded-full">
                    <Clock className="h-5 w-5 text-yellow-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-green-700 dark:text-green-300">Terminés</p>
                    <p className="text-2xl font-bold text-green-900 dark:text-green-100">{workflowStats.completed}</p>
                  </div>
                  <div className="bg-green-100 dark:bg-green-800 p-2 rounded-full">
                    <CheckCircle className="h-5 w-5 text-green-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-indigo-50 dark:bg-indigo-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-indigo-700 dark:text-indigo-300">MT → ISO</p>
                    <p className="text-2xl font-bold text-indigo-900 dark:text-indigo-100">{workflowStats.mtToIso}</p>
                  </div>
                  <div className="bg-indigo-100 dark:bg-indigo-800 p-2 rounded-full">
                    <ArrowDownCircle className="h-5 w-5 text-indigo-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-purple-50 dark:bg-purple-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-purple-700 dark:text-purple-300">ISO → MT</p>
                    <p className="text-2xl font-bold text-purple-900 dark:text-purple-100">{workflowStats.isoToMt}</p>
                  </div>
                  <div className="bg-purple-100 dark:bg-purple-800 p-2 rounded-full">
                    <ArrowUpCircle className="h-5 w-5 text-purple-500" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Détails par type de workflow */}
            <div>
              <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Par type de workflow</h4>
              <div className="space-y-2 max-h-64 overflow-y-auto">
                {Object.keys(workflowStats.byType).map(wfCode => (
                  <div key={wfCode} className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <div className="flex items-center">
                      <div className="p-2 mr-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                        {getWorkflowIcon(wfCode)}
                      </div>
                      <div className="overflow-hidden">
                        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">{workflowStats.byType[wfCode].name}</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400 truncate">{wfCode}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <div className="flex items-center text-sm">
                        <div className="w-3 h-3 rounded-full bg-yellow-400 mr-1"></div>
                        <span>{workflowStats.byType[wfCode].active}</span>
                      </div>
                      <div className="flex items-center text-sm">
                        <div className="w-3 h-3 rounded-full bg-green-400 mr-1"></div>
                        <span>{workflowStats.byType[wfCode].completed}</span>
                      </div>
                      <div className="flex items-center text-sm">
                        <div className="w-3 h-3 rounded-full bg-red-400 mr-1"></div>
                        <span>{workflowStats.byType[wfCode].failed}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Détails par type de message */}
            <div>
              <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Par type de message</h4>
              <div className="space-y-2 max-h-64 overflow-y-auto">
                {Object.keys(workflowStats.byMessageType).map(msgType => (
                  <div key={msgType} className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <div className="flex items-center">
                      <div className="p-2 mr-3 bg-purple-100 dark:bg-purple-900/30 rounded-full">
                        <MessageSquare className="h-5 w-5 text-purple-500" />
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">{msgType}</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">Message SWIFT/ISO20022</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <div className="flex items-center text-sm">
                        <div className="w-3 h-3 rounded-full bg-yellow-400 mr-1"></div>
                        <span>{workflowStats.byMessageType[msgType].active}</span>
                      </div>
                      <div className="flex items-center text-sm">
                        <div className="w-3 h-3 rounded-full bg-green-400 mr-1"></div>
                        <span>{workflowStats.byMessageType[msgType].completed}</span>
                      </div>
                      <div className="flex items-center text-sm">
                        <div className="w-3 h-3 rounded-full bg-red-400 mr-1"></div>
                        <span>{workflowStats.byMessageType[msgType].failed}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>
      )}
      
      {/* Filtres de recherche */}
      <div className="mb-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
        <div className="flex-1 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Rechercher par référence, état, codes BIC..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex gap-2">
          <Button
            variant={showFilters ? "primary" : "outline"}
            icon={Filter}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filtres
          </Button>
        </div>
      </div>
      
      {showFilters && (
        <Card className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label htmlFor="statusFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Statut
              </label>
              <select
                id="statusFilter"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Tous les statuts</option>
                <option value="active">En cours</option>
                <option value="completed">Terminés</option>
                <option value="failed">Échoués</option>
                {/* États spécifiques des workflows RTGS V10 */}
                <option value="RECEPTION_PARTICIPANT">Réception participant</option>
                <option value="RECEPTION_BEAC">Réception BEAC</option>
                <option value="VALIDATION_FORMAT">Validation format</option>
                <option value="TRANSFORMATION_MT_MX">Transformation MT → ISO</option>
                <option value="TRANSFORMATION_MX_MT">Transformation ISO → MT</option>
                <option value="VALIDATION_DONNEES">Validation données</option>
                <option value="VERIFICATION_SIGNATURE">Vérification signature</option>
                <option value="PREPARATION">Préparation</option>
                <option value="VALIDATED">Validé MT</option>
                <option value="VALIDATED_ISO20022">Validé ISO20022</option>
                <option value="EMISSION_PARTICIPANT">Émission participant</option>
                <option value="EMISSION_BEAC">Émission BEAC</option>
              </select>
            </div>
            
            <div>
              <label htmlFor="directionFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Direction
              </label>
              <select
                id="directionFilter"
                value={directionFilter}
                onChange={(e) => setDirectionFilter(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Toutes les directions</option>
                <option value="mtToIso">MT vers ISO</option>
                <option value="isoToMt">ISO vers MT</option>
              </select>
            </div>
            
            <div>
              <label htmlFor="typeFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Type de workflow
              </label>
              <select
                id="typeFilter"
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Tous les types</option>
                {workflowDefinitions.map(def => (
                  <option key={def.code} value={def.code}>{def.name}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label htmlFor="messageTypeFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Type de message
              </label>
              <select
                id="messageTypeFilter"
                value={messageTypeFilter}
                onChange={(e) => setMessageTypeFilter(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Tous les messages</option>
                <option value="PACS.008">PACS.008</option>
                <option value="MT103">MT103</option>
                <option value="PACS.009">PACS.009</option>
                <option value="MT202">MT202</option>
                <option value="CAMT.053">CAMT.053</option>
                <option value="MT940">MT940</option>
                <option value="MT950">MT950</option>
                <option value="CAMT.056">CAMT.056</option>
                <option value="MT192">MT192</option>
                <option value="CAMT.025">CAMT.025</option>
                <option value="MTn96">MTn96</option>
                <option value="CAMT.054">CAMT.054</option>
                <option value="MT900">MT900</option>
                <option value="MT910">MT910</option>
              </select>
            </div>
          </div>
          
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Date de création
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <div>
                <input
                  type="date"
                  id="startDate"
                  value={dateFilter.start || ''}
                  onChange={(e) => setDateFilter({...dateFilter, start: e.target.value})}
                  className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Date début"
                />
              </div>
              <div>
                <input
                  type="date"
                  id="endDate"
                  value={dateFilter.end || ''}
                  onChange={(e) => setDateFilter({...dateFilter, end: e.target.value})}
                  className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Date fin"
                />
              </div>
            </div>
          </div>
          
          <div className="mt-4 flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={resetFilters}
            >
              Réinitialiser
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowFilters(false)}
            >
              Appliquer
            </Button>
          </div>
        </Card>
      )}
      
      {/* Affichage du diagramme de workflow sélectionné */}
      {showWorkflowDiagram && (
        <Card
          title="Diagrammes de workflow RTGS V10"
          icon={GitBranch}
          actions={
            <Button
              variant="outline"
              size="sm"
              icon={X}
              onClick={() => setShowWorkflowDiagram(false)}
            >
              Fermer
            </Button>
          }
        >
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Sélectionner un workflow
            </label>
            <select
              value={selectedDefinition?.code || ''}
              onChange={(e) => {
                const def = workflowDefinitions.find(d => d.code === e.target.value);
                setSelectedDefinition(def || null);
              }}
              className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Sélectionner un workflow</option>
              {workflowDefinitions.map(def => (
                <option key={def.code} value={def.code}>{def.name}</option>
              ))}
            </select>
          </div>
          
          {selectedDefinition && (
            <WorkflowStateTransitionDiagram
              workflowDefinition={selectedDefinition}
              onRefresh={() => fetchWorkflowDefinitions()}
            />
          )}
        </Card>
      )}
      
      {/* Liste des instances de workflow */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : filteredInstances.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-64 bg-white dark:bg-gray-800 rounded-lg shadow">
          <GitBranch className="h-16 w-16 text-gray-300 dark:text-gray-600 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">Aucune instance de workflow trouvée</h3>
          <p className="text-gray-500 dark:text-gray-400 mt-2 text-center max-w-md">
            Aucune instance de workflow ne correspond à vos critères de recherche. Vous pouvez modifier vos filtres ou attendre que de nouvelles instances soient créées.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {filteredInstances.map(instance => (
            <Card
              key={instance.id}
              className={`overflow-hidden transition-all ${expandedInstanceId === instance.id ? 'ring-2 ring-blue-500' : ''}`}
            >
              {/* En-tête de l'instance */}
              <div 
                className="px-4 py-3 flex justify-between items-center cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700/50"
                onClick={() => toggleInstanceExpansion(instance.id)}
              >
                <div className="flex items-center">
                  <div className="p-2 mr-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                    {getWorkflowIcon(instance.workflowCode)}
                  </div>
                  <div>
                    <div className="flex items-center">
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mr-2">
                        {instance.reference}
                      </h3>
                      {renderStatusBadge(instance)}
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {instance.workflowName} - <span className="font-medium">{formatStateName(instance.currentState)}</span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm text-gray-500 dark:text-gray-400 mr-4">
                    <Calendar className="h-4 w-4 inline mr-1" />
                    <DateFormatter date={instance.createdAt} format="short" />
                  </p>
                  {expandedInstanceId === instance.id ? (
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  ) : (
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                  )}
                </div>
              </div>
              
              {/* Barre de progression */}
              <div className="h-1.5 bg-gray-200 dark:bg-gray-700">
                <div
                  className={`h-full ${
                    instance.error ? 'bg-red-500' : instance.completed ? 'bg-green-500' : 'bg-blue-500'
                  }`}
                  style={{ width: `${calculateProgress(instance)}%` }}
                ></div>
              </div>
              
              {/* Détails développés */}
              {expandedInstanceId === instance.id && (
                <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                        Informations du workflow
                      </h4>
                      <div className="space-y-2">
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">Code workflow:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.workflowCode}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">État initial:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{formatStateName(instance.initialState)}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">État actuel:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{formatStateName(instance.currentState)}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">Terminé:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.completed ? 'Oui' : 'Non'}</span>
                        </div>
                        {instance.error && (
                          <div className="flex justify-between">
                            <span className="text-sm text-gray-500 dark:text-gray-400">Erreur:</span>
                            <span className="text-sm font-medium text-red-500">{instance.context?.errorMessage || 'Erreur inconnue'}</span>
                          </div>
                        )}
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">Direction:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">
                            {(instance.workflowCode.includes('_MT_TO_') || instance.workflowCode.includes('_MTTO')) ? 'MT vers ISO' : 'ISO vers MT'}
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                        Informations du message
                      </h4>
                      {instance.context ? (
                        <div className="space-y-2">
                          {instance.context.messageReference && (
                            <div className="flex justify-between">
                              <span className="text-sm text-gray-500 dark:text-gray-400">Référence message:</span>
                              <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.context.messageReference}</span>
                            </div>
                          )}
                          {instance.context.messageType && (
                            <div className="flex justify-between">
                              <span className="text-sm text-gray-500 dark:text-gray-400">Type de message:</span>
                              <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.context.messageType}</span>
                            </div>
                          )}
                          {instance.context.sourceIdentifier && (
                            <div className="flex justify-between">
                              <span className="text-sm text-gray-500 dark:text-gray-400">Émetteur:</span>
                              <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.context.sourceIdentifier}</span>
                            </div>
                          )}
                          {instance.context.destinationIdentifier && (
                            <div className="flex justify-between">
                              <span className="text-sm text-gray-500 dark:text-gray-400">Destinataire:</span>
                              <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.context.destinationIdentifier}</span>
                            </div>
                          )}
                          {instance.context.amount && instance.context.currency && (
                            <div className="flex justify-between">
                              <span className="text-sm text-gray-500 dark:text-gray-400">Montant:</span>
                              <span className="text-sm font-medium text-gray-900 dark:text-white">
                                {new Intl.NumberFormat('fr-FR', {
                                  style: 'currency',
                                  currency: instance.context.currency,
                                  minimumFractionDigits: 2
                                }).format(instance.context.amount)}
                              </span>
                            </div>
                          )}
                          {instance.context.accountNumber && (
                            <div className="flex justify-between">
                              <span className="text-sm text-gray-500 dark:text-gray-400">Numéro de compte:</span>
                              <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.context.accountNumber}</span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Aucune information de message disponible
                        </p>
                      )}
                    </div>
                  </div>
                  
                  {/* Historique du workflow */}
                  <div className="mb-4">
                    <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
                      <Clock8 className="h-4 w-4 mr-1" />
                      Historique des transitions
                    </h4>
                    
                    {historyLoading ? (
                      <div className="flex justify-center items-center py-4">
                        <Loader className="h-6 w-6 text-blue-500 animate-spin" />
                      </div>
                    ) : instanceHistory.length === 0 ? (
                      <p className="text-sm text-gray-500 dark:text-gray-400 py-2">
                        Aucun historique disponible
                      </p>
                    ) : (
                      <div className="space-y-1 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
                        {instanceHistory.map((entry, index) => (
                          <div key={entry.id || index} className="relative">
                            <div className="absolute -left-[9px] mt-1.5">
                              <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                            </div>
                            <div className="pl-4 pb-3">
                              <p className="text-sm font-medium text-gray-900 dark:text-white">
                                {formatStateName(entry.fromState)} &rarr; {formatStateName(entry.toState)}
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                <DateFormatter date={entry.timestamp} format="short" showTime /> | Événement: {entry.event}
                              </p>
                              {entry.comment && (
                                <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                                  {entry.comment}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  
                  {/* Actions disponibles */}
                  <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                    <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Actions disponibles
                    </h4>
                    
                    <div className="flex flex-wrap gap-2">
                      {availableTransitions.length > 0 ? (
                        <>
                          {availableTransitions.map(transition => (
                            <Button
                              key={transition.event}
                              variant="outline"
                              size="sm"
                              onClick={() => handleProcessEvent(instance.id, transition.event)}
                              disabled={actionLoading}
                            >
                              {transition.event}
                            </Button>
                          ))}
                        </>
                      ) : (
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {instance.completed ? 'Workflow terminé' : 'Aucune action disponible'}
                        </p>
                      )}
                      
                      <div className="ml-auto">
                        <Button
                          variant="outline"
                          size="sm"
                          icon={Eye}
                          onClick={() => handleShowDetails(instance)}
                        >
                          Détails
                        </Button>
                        
                        {!instance.completed && (
                          <Button
                            variant="outline"
                            size="sm"
                            icon={XSquare}
                            onClick={() => handleCancelWorkflow(instance.id)}
                            className="ml-2 text-red-500 border-red-300 hover:bg-red-50"
                            disabled={actionLoading}
                          >
                            Annuler
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          ))}
        </div>
      )}
      
      {/* Modal de détail d'une instance */}
      {showDetailsModal && selectedInstance && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-4xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  <GitBranch className="w-5 h-5 mr-2 text-blue-500" />
                  Détails du workflow {selectedInstance.reference}
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                {/* Informations principales */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    Informations générales
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Workflow:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {selectedInstance.workflowName} ({selectedInstance.workflowCode})
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Référence:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {selectedInstance.reference}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Date de création:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        <DateFormatter date={selectedInstance.createdAt} format="full" showTime />
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Dernière mise à jour:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        <DateFormatter date={selectedInstance.lastUpdatedAt} format="full" showTime />
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        État initial:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {formatStateName(selectedInstance.initialState)}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        État actuel:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {formatStateName(selectedInstance.currentState)}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Direction:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {(selectedInstance.workflowCode.includes('_MT_TO_') || selectedInstance.workflowCode.includes('_MTTO')) ? 'MT vers ISO' : 'ISO vers MT'}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Statut:
                      </p>
                      <div className="text-sm font-medium">
                        {renderStatusBadge(selectedInstance)}
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Informations du message */}
                {selectedInstance.context && (
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                      Informations du message
                    </h4>
                    <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {selectedInstance.context.messageReference && (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Référence message:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedInstance.context.messageReference}
                            </p>
                          </div>
                        )}
                        {selectedInstance.context.messageType && (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Type de message:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedInstance.context.messageType}
                            </p>
                          </div>
                        )}
                        {selectedInstance.context.sourceIdentifier && (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Émetteur:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedInstance.context.sourceIdentifier}
                            </p>
                          </div>
                        )}
                        {selectedInstance.context.destinationIdentifier && (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Destinataire:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedInstance.context.destinationIdentifier}
                            </p>
                          </div>
                        )}
                        {selectedInstance.context.amount && selectedInstance.context.currency && (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Montant:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {new Intl.NumberFormat('fr-FR', {
                                style: 'currency',
                                currency: selectedInstance.context.currency,
                                minimumFractionDigits: 2
                              }).format(selectedInstance.context.amount)}
                            </p>
                          </div>
                        )}
                        {selectedInstance.context.accountNumber && (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Numéro de compte:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedInstance.context.accountNumber}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                
                {/* Historique des transitions */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                    <Clock8 className="w-5 h-5 mr-2 text-blue-500" />
                    Historique des transitions
                  </h4>
                  
                  {historyLoading ? (
                    <div className="flex justify-center items-center py-8">
                      <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                    </div>
                  ) : instanceHistory.length === 0 ? (
                    <p className="text-sm text-gray-500 dark:text-gray-400 py-4">
                      Aucun historique disponible pour cette instance.
                    </p>
                  ) : (
                    <div className="space-y-2 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
                      {instanceHistory.map((entry, index) => (
                        <div key={entry.id || index} className="relative">
                          <div className="absolute -left-[25px] mt-1.5">
                            <div className="w-5 h-5 rounded-full bg-blue-500"></div>
                          </div>
                          <div className="pl-4 pb-4">
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {formatStateName(entry.fromState)} &rarr; {formatStateName(entry.toState)}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              <DateFormatter date={entry.timestamp} format="full" showTime /> | Événement: {entry.event}
                            </p>
                            {entry.comment && (
                              <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                                {entry.comment}
                              </p>
                            )}
                            {entry.user && (
                              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                Utilisateur: {entry.user}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                {/* Diagramme du workflow */}
                {selectedDefinition && (
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                      <GitBranch className="w-5 h-5 mr-2 text-blue-500" />
                      Diagramme du workflow
                    </h4>
                    
                    <WorkflowStateTransitionDiagram
                      workflowDefinition={selectedDefinition}
                      onRefresh={() => fetchWorkflowDefinitions()}
                    />
                  </div>
                )}
                
                {/* Actions disponibles */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                  <div className="flex justify-between">
                    <div>
                      {availableTransitions.length > 0 && !selectedInstance.completed && (
                        <div className="space-y-2">
                          <h4 className="text-md font-medium text-gray-900 dark:text-white mb-2">
                            Actions disponibles:
                          </h4>
                          <div className="flex flex-wrap gap-2">
                            {availableTransitions.map(transition => (
                              <Button
                                key={transition.event}
                                variant="outline"
                                onClick={() => {
                                  handleProcessEvent(selectedInstance.id, transition.event);
                                  setShowDetailsModal(false);
                                }}
                                disabled={actionLoading}
                              >
                                {transition.event}
                              </Button>
                            ))}
                          </div>
                        </div>
                      )}
                      
                      {selectedInstance.error && (
                        <div className="mt-4 p-3 bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 text-red-700 dark:text-red-400">
                          <div className="flex items-start">
                            <AlertCircle className="h-5 w-5 mr-2 mt-0.5" />
                            <div>
                              <p className="font-medium">Erreur dans le workflow</p>
                              <p className="text-sm mt-1">{selectedInstance.context?.errorMessage || "Une erreur est survenue lors de l'exécution du workflow."}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex space-x-3 items-start">
                      {!selectedInstance.completed && !selectedInstance.error && (
                        <Button
                          variant="outline"
                          icon={XSquare}
                          onClick={() => {
                            handleCancelWorkflow(selectedInstance.id);
                            setShowDetailsModal(false);
                          }}
                          className="text-red-500 border-red-300 hover:bg-red-50"
                          disabled={actionLoading}
                        >
                          Annuler le workflow
                        </Button>
                      )}
                      <Button
                        variant="outline"
                        onClick={() => setShowDetailsModal(false)}
                      >
                        Fermer
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RtgsWorkflowMonitor;