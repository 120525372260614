// components/shared/workflow/WorkflowDomainManager.js
import apiService from '../../../services/api.service';

/**
 * Gestionnaire des éléments de domaine des workflows
 * 
 * Cette classe gère les éléments spécifiques au domaine métier
 * tels que les événements prédéfinis, les rôles, etc.
 */
export class WorkflowDomainManager {
  constructor() {
    this.events = [];
    this.roles = [];
    this.statuses = [];
    this.actions = [];
    this.initialized = false;
  }
  
  /**
   * Initialise le gestionnaire en chargeant les données du domaine
   */
  async initialize() {
    if (this.initialized) {
      return;
    }
    
    try {
      // Charger les événements prédéfinis depuis l'API
      const eventsResponse = await apiService.workflow.getWorkflowEvents();
      this.events = eventsResponse?.events || [];
      
      // Charger les rôles disponibles
      const rolesResponse = await apiService.security.roles.getAll();
      this.roles = rolesResponse?.roles || [];
      
      // Charger les statuts prédéfinis
      const statusesResponse = await apiService.workflow.getWorkflowStatuses();
      this.statuses = statusesResponse?.statuses || [];
      
      // Charger les actions disponibles
      const actionsResponse = await apiService.workflow.getWorkflowActions();
      this.actions = actionsResponse?.actions || [];
      
      this.initialized = true;
    } catch (error) {
      console.error('Erreur lors de l\'initialisation du WorkflowDomainManager:', error);
      // Fallback sur des valeurs par défaut si l'API n'est pas disponible
      this.setupDefaultValues();
      this.initialized = true;
    }
  }
  
  /**
   * Configure des valeurs par défaut en cas d'échec de l'API
   */
  setupDefaultValues() {
    // Événements de base
    this.events = [
      { code: 'SUBMIT', name: 'Soumettre', description: 'L\'utilisateur soumet un formulaire' },
      { code: 'APPROVE', name: 'Approuver', description: 'Approuver une demande' },
      { code: 'REJECT', name: 'Rejeter', description: 'Rejeter une demande' },
      { code: 'REVIEW', name: 'Examiner', description: 'Examiner une demande' },
      { code: 'COMPLETE', name: 'Terminer', description: 'Marquer comme terminé' },
      { code: 'CANCEL', name: 'Annuler', description: 'Annuler une demande' },
      { code: 'RETRY', name: 'Réessayer', description: 'Nouvelle tentative après échec' },
      { code: 'PAY', name: 'Payer', description: 'Effectuer un paiement' },
      { code: 'CONFIRM', name: 'Confirmer', description: 'Confirmer une action' }
    ];
    
    // Rôles de base
    this.roles = [
      { code: 'ADMIN', name: 'Administrateur' },
      { code: 'USER', name: 'Utilisateur' },
      { code: 'MANAGER', name: 'Gestionnaire' },
      { code: 'APPROVER', name: 'Approbateur' },
      { code: 'REVIEWER', name: 'Examinateur' },
      { code: 'SYSTEM', name: 'Système' }
    ];
    
    // Statuts de base
    this.statuses = [
      { code: 'DRAFT', name: 'Brouillon', description: 'Document en cours de rédaction' },
      { code: 'SUBMITTED', name: 'Soumis', description: 'Document soumis pour examen' },
      { code: 'IN_REVIEW', name: 'En cours d\'examen', description: 'Document en cours d\'examen' },
      { code: 'APPROVED', name: 'Approuvé', description: 'Document approuvé' },
      { code: 'REJECTED', name: 'Rejeté', description: 'Document rejeté' },
      { code: 'COMPLETED', name: 'Terminé', description: 'Processus terminé' },
      { code: 'CANCELLED', name: 'Annulé', description: 'Processus annulé' }
    ];
    
    // Actions de base
    this.actions = [
      { code: 'SEND_EMAIL', name: 'Envoyer un email', description: 'Envoyer une notification par email' },
      { code: 'GENERATE_DOCUMENT', name: 'Générer un document', description: 'Générer un document automatiquement' },
      { code: 'UPDATE_STATUS', name: 'Mettre à jour le statut', description: 'Mettre à jour le statut dans le système' },
      { code: 'NOTIFY_USER', name: 'Notifier l\'utilisateur', description: 'Envoyer une notification à l\'utilisateur' },
      { code: 'LOG_AUDIT', name: 'Journaliser l\'audit', description: 'Ajouter une entrée dans le journal d\'audit' },
      { code: 'TRIGGER_INTEGRATION', name: 'Déclencher une intégration', description: 'Appeler une intégration externe' }
    ];
  }
  
  /**
   * Retourne les événements disponibles pour un type de workflow
   * 
   * @param {string} workflowType - Type de workflow (optionnel)
   * @returns {Array} - Liste des événements
   */
  async getEvents(workflowType = null) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    if (!workflowType) {
      return this.events;
    }
    
    try {
      // Si l'API supporte le filtrage par type de workflow
      const response = await apiService.workflow.getWorkflowEventsByType(workflowType);
      return response?.events || this.events;
    } catch (error) {
      console.error('Erreur lors de la récupération des événements par type:', error);
      return this.events;
    }
  }
  
  /**
   * Retourne les événements disponibles entre deux états spécifiques
   * 
   * @param {string} sourceStateCode - Code de l'état source
   * @param {string} targetStateCode - Code de l'état cible
   * @returns {Array} - Liste des événements disponibles
   */
  async getEventsBetweenStates(sourceStateCode, targetStateCode) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    try {
      // Tentative d'appel API pour récupérer les événements spécifiques
      const response = await apiService.workflow.getEventsBetweenStates(sourceStateCode, targetStateCode);
      return response?.events || [];
    } catch (error) {
      console.error('Erreur lors de la récupération des événements entre états:', error);
      
      // Mode simulation : filtrer les événements en fonction des règles métier
      return this.events.filter(event => {
        // Logique personnalisée pour déterminer quels événements sont valides
        // entre un état source et un état cible
        
        // Exemples de règles :
        if (sourceStateCode === 'DRAFT' && targetStateCode === 'SUBMITTED') {
          return ['SUBMIT'].includes(event.code);
        }
        if (sourceStateCode === 'SUBMITTED' && targetStateCode === 'APPROVED') {
          return ['APPROVE'].includes(event.code);
        }
        if (sourceStateCode === 'SUBMITTED' && targetStateCode === 'REJECTED') {
          return ['REJECT'].includes(event.code);
        }
        // État d'approbation vers traitement
        if (sourceStateCode === 'APPROVED' && targetStateCode === 'PROCESSING') {
          return ['PROCESS'].includes(event.code);
        }
        // État de traitement vers complet
        if (sourceStateCode === 'PROCESSING' && targetStateCode === 'COMPLETED') {
          return ['COMPLETE'].includes(event.code);
        }
        // État de traitement vers annulé
        if (sourceStateCode === 'PROCESSING' && targetStateCode === 'CANCELLED') {
          return ['CANCEL'].includes(event.code);
        }
        
        // Par défaut, autoriser tous les événements
        return true;
      });
    }
  }
  
  /**
   * Retourne les rôles disponibles
   * 
   * @returns {Array} - Liste des rôles
   */
  async getRoles() {
    if (!this.initialized) {
      await this.initialize();
    }
    
    return this.roles;
  }
  
  /**
   * Retourne les statuts disponibles pour un type de workflow
   * 
   * @param {string} workflowType - Type de workflow (optionnel)
   * @returns {Array} - Liste des statuts
   */
  async getStatuses(workflowType = null) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    if (!workflowType) {
      return this.statuses;
    }
    
    try {
      // Si l'API supporte le filtrage par type de workflow
      const response = await apiService.workflow.getWorkflowStatusesByType(workflowType);
      return response?.statuses || this.statuses;
    } catch (error) {
      console.error('Erreur lors de la récupération des statuts par type:', error);
      return this.statuses;
    }
  }
  
  /**
   * Retourne les actions disponibles
   * 
   * @returns {Array} - Liste des actions
   */
  async getActions() {
    if (!this.initialized) {
      await this.initialize();
    }
    
    return this.actions;
  }
  
  /**
   * Vérifie si un événement existe
   * 
   * @param {string} eventCode - Code de l'événement
   * @returns {boolean} - true si l'événement existe
   */
  async eventExists(eventCode) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    return this.events.some(event => event.code === eventCode);
  }
  
  /**
   * Vérifie si un rôle existe
   * 
   * @param {string} roleCode - Code du rôle
   * @returns {boolean} - true si le rôle existe
   */
  async roleExists(roleCode) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    return this.roles.some(role => role.code === roleCode);
  }
  
  /**
   * Vérifie si un statut existe
   * 
   * @param {string} statusCode - Code du statut
   * @returns {boolean} - true si le statut existe
   */
  async statusExists(statusCode) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    return this.statuses.some(status => status.code === statusCode);
  }
  
  /**
   * Vérifie si une action existe
   * 
   * @param {string} actionCode - Code de l'action
   * @returns {boolean} - true si l'action existe
   */
  async actionExists(actionCode) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    return this.actions.some(action => action.code === actionCode);
  }
}

// Créer une instance unique pour toute l'application
export const workflowDomainManager = new WorkflowDomainManager();