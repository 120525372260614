// Fixed version with correct imports
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  InputAdornment,
  LinearProgress,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  Breadcrumbs,
  Link
} from '@mui/material';

import {
  Terminal,
  Folder,
  FolderOpen,
  File,
  ArrowUp,
  ArrowDown,
  Search,
  RefreshCw,
  HardDrive,
  Server,
  Cpu,
  Clock,
  User,
  Users,
  Wifi,
  WifiOff,
  Activity,
  AlertTriangle,
  CheckCircle,
  XCircle,
  MoreVertical,
  Play,
  StopCircle,
  Download,
  Upload,
  Edit,
  Trash2,
  Copy,
  FilePlus,
  FolderPlus,
  FileText,
  Eye,
  Settings,
  ChevronRight,
  ChevronDown,
  Home,
  Lock,
  Zap,
  Info,
  Save,
  Database
} from 'lucide-react';

/**
 * LinuxCapture Component - Interface réutilisable pour interagir avec des systèmes Linux
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles du système Linux
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const LinuxCapture = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États pour l'onglet Terminal
  const [command, setCommand] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [terminalOutput, setTerminalOutput] = useState([]);
  const [isExecuting, setIsExecuting] = useState(false);
  
  // États pour l'onglet Explorateur de fichiers
  const [currentPath, setCurrentPath] = useState('/');
  const [filesList, setFilesList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([{ name: 'root', path: '/' }]);
  
  // États pour l'onglet Processus
  const [processList, setProcessList] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [processDetails, setProcessDetails] = useState(null);
  const [isLoadingProcesses, setIsLoadingProcesses] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(null);
  
  // États pour l'onglet Système
  const [systemInfo, setSystemInfo] = useState(null);
  const [resourceUsage, setResourceUsage] = useState(null);
  const [isLoadingSystem, setIsLoadingSystem] = useState(false);
  
  // États généraux
  const [activeTab, setActiveTab] = useState(0);
  const [lastAction, setLastAction] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [connectionInfo, setConnectionInfo] = useState({
    server: '',
    hostname: '',
    user: '',
    sshKey: '',
    connectionId: '',
    status: 'disconnected'
  });
  
  // Référence pour le terminal et l'historique de commandes
  const terminalRef = useRef(null);
  const commandInputRef = useRef(null);
  
  // Effets pour récupérer les informations de connexion
  useEffect(() => {
    if (data?.technicalProperties?.connection) {
      const conn = data.technicalProperties.connection;
      setConnectionInfo({
        server: conn.server || '',
        hostname: conn.hostname || '',
        user: conn.user || '',
        sshKey: conn.sshKey || '',
        connectionId: conn.connectionId || '',
        status: connectionStatus ? 'connected' : 'disconnected'
      });
    }
  }, [data, connectionStatus]);
  
  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les résultats de commande
      if (data.technicalProperties.commandOutput) {
        handleCommandResults(data.technicalProperties.commandOutput);
      }
      
      // Traiter les listes de fichiers
      if (data.technicalProperties.filesList) {
        handleFilesListResults(data.technicalProperties.filesList);
      }
      
      // Traiter le contenu d'un fichier
      if (data.technicalProperties.fileContent) {
        handleFileContentResults(data.technicalProperties.fileContent);
      }
      
      // Traiter la liste des processus
      if (data.technicalProperties.processList) {
        handleProcessListResults(data.technicalProperties.processList);
      }
      
      // Traiter les détails d'un processus
      if (data.technicalProperties.processDetails) {
        handleProcessDetailsResults(data.technicalProperties.processDetails);
      }
      
      // Traiter les informations système
      if (data.technicalProperties.systemInfo) {
        handleSystemInfoResults(data.technicalProperties.systemInfo);
      }
      
      // Traiter l'utilisation des ressources
      if (data.technicalProperties.resourceUsage) {
        handleResourceUsageResults(data.technicalProperties.resourceUsage);
      }
    }
  }, [data]);
  
  // Effet pour faire défiler le terminal vers le bas
  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [terminalOutput]);
  
  // Effet pour rafraîchir les processus automatiquement
  useEffect(() => {
    if (activeTab === 2 && connectionStatus && !disabled) {
      const interval = setInterval(() => {
        refreshProcessList();
      }, 10000); // Rafraîchir toutes les 10 secondes
      
      setRefreshInterval(interval);
      return () => clearInterval(interval);
    } else if (refreshInterval) {
      clearInterval(refreshInterval);
      setRefreshInterval(null);
    }
  }, [activeTab, connectionStatus, disabled]);
  
  // Handler pour les résultats de commande
  const handleCommandResults = (output) => {
    setIsExecuting(false);
    
    // Ajouter la sortie aux résultats du terminal
    setTerminalOutput(prev => [
      ...prev,
      {
        type: 'output',
        content: output.text || '',
        exitCode: output.exitCode,
        timestamp: new Date()
      }
    ]);
  };
  
  // Handler pour les résultats de liste de fichiers
  const handleFilesListResults = (filesList) => {
    setIsLoadingFiles(false);
    setFilesList(filesList.files || []);
    
    // Mettre à jour les fils d'Ariane
    const pathParts = currentPath.split('/').filter(part => part);
    const breadcrumbsItems = [{ name: 'root', path: '/' }];
    
    let currentAccumulatedPath = '';
    for (const part of pathParts) {
      currentAccumulatedPath += `/${part}`;
      breadcrumbsItems.push({
        name: part,
        path: currentAccumulatedPath
      });
    }
    
    setBreadcrumbs(breadcrumbsItems);
  };
  
  // Handler pour les résultats de contenu de fichier
  const handleFileContentResults = (content) => {
    setFileContent({
      content: content.text || '',
      mimeType: content.mimeType || 'text/plain',
      size: content.size || 0,
      permissions: content.permissions || '',
      owner: content.owner || '',
      lastModified: content.lastModified ? new Date(content.lastModified) : null
    });
  };
  
  // Handler pour les résultats de liste de processus
  const handleProcessListResults = (processList) => {
    setIsLoadingProcesses(false);
    setProcessList(processList.processes || []);
  };
  
  // Handler pour les résultats de détails de processus
  const handleProcessDetailsResults = (details) => {
    setProcessDetails(details);
  };
  
  // Handler pour les résultats d'informations système
  const handleSystemInfoResults = (info) => {
    setIsLoadingSystem(false);
    setSystemInfo(info);
  };
  
  // Handler pour les résultats d'utilisation de ressources
  const handleResourceUsageResults = (usage) => {
    setResourceUsage(usage);
  };
  
  // Fonction pour exécuter une commande
  const executeCommand = () => {
    if (!command.trim() || disabled || !connectionStatus) return;
    
    setIsExecuting(true);
    
    // Ajouter la commande à l'historique du terminal
    setTerminalOutput(prev => [
      ...prev,
      {
        type: 'command',
        content: command,
        timestamp: new Date()
      }
    ]);
    
    // Mettre à jour l'historique des commandes
    setCommandHistory(prev => [command, ...prev]);
    setHistoryIndex(-1);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ShellCommand",
        nodeName: "ExecuteCommand",
        nodeValue: command,
        actionType: "Linux",
        actionValue: command,
        actionName: "ExecuteCommand",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ShellCommand',
      name: command.length > 30 ? `${command.substring(0, 30)}...` : command,
      timestamp: new Date()
    });
    
    // Réinitialiser la commande
    setCommand('');
  };
  
  // Fonction pour accéder à l'historique des commandes
  const navigateCommandHistory = (direction) => {
    if (commandHistory.length === 0) return;
    
    let newIndex;
    if (direction === 'up') {
      newIndex = historyIndex < commandHistory.length - 1 ? historyIndex + 1 : historyIndex;
    } else {
      newIndex = historyIndex > 0 ? historyIndex - 1 : -1;
    }
    
    if (newIndex === -1) {
      setCommand('');
    } else {
      setCommand(commandHistory[newIndex]);
    }
    
    setHistoryIndex(newIndex);
  };
  
  // Fonction pour naviguer dans le système de fichiers
  const navigateToPath = (path) => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingFiles(true);
    setCurrentPath(path);
    setSelectedFile(null);
    setFileContent(null);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "FileExplorer",
        nodeName: "ListFiles",
        nodeValue: path,
        actionType: "Linux",
        actionValue: `ls -la ${path}`,
        actionName: "ListFiles",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'FileExplorer',
      name: `List files in ${path}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour visualiser un fichier
  const viewFile = (file) => {
    if (disabled || !connectionStatus || file.isDirectory) return;
    
    const filePath = currentPath === '/' ? `/${file.name}` : `${currentPath}/${file.name}`;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "FileExplorer",
        nodeName: "ViewFile",
        nodeValue: filePath,
        actionType: "Linux",
        actionValue: `cat ${filePath}`,
        actionName: "ViewFile",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'FileExplorer',
      name: `View file ${file.name}`,
      timestamp: new Date()
    });
    
    setSelectedFile(file);
  };
  
  // Fonction pour rafraîchir la liste des fichiers
  const refreshFilesList = () => {
    navigateToPath(currentPath);
  };
  
  // Fonction pour aller au dossier parent
  const navigateToParent = () => {
    if (currentPath === '/') return;
    
    const pathParts = currentPath.split('/').filter(part => part);
    pathParts.pop();
    const parentPath = pathParts.length === 0 ? '/' : `/${pathParts.join('/')}`;
    
    navigateToPath(parentPath);
  };
  
  // Fonction pour rafraîchir la liste des processus
  const refreshProcessList = () => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingProcesses(true);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ProcessMonitor",
        nodeName: "ListProcesses",
        nodeValue: "ps",
        actionType: "Linux",
        actionValue: "ps aux",
        actionName: "ListProcesses",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ProcessMonitor',
      name: "List processes",
      timestamp: new Date()
    });
  };
  
  // Fonction pour visualiser les détails d'un processus
  const viewProcessDetails = (process) => {
    if (disabled || !connectionStatus) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ProcessMonitor",
        nodeName: "ProcessDetails",
        nodeValue: process.pid.toString(),
        actionType: "Linux",
        actionValue: `ps -p ${process.pid} -o pid,ppid,cmd,%cpu,%mem,stat,start,time,user`,
        actionName: "ProcessDetails",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ProcessMonitor',
      name: `View process ${process.pid}`,
      timestamp: new Date()
    });
    
    setSelectedProcess(process);
  };
  
  // Fonction pour envoyer un signal à un processus
  const sendSignalToProcess = (process, signal) => {
    if (disabled || !connectionStatus) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ProcessMonitor",
        nodeName: "SignalProcess",
        nodeValue: `${signal}:${process.pid}`,
        actionType: "Linux",
        actionValue: `kill -${signal} ${process.pid}`,
        actionName: "SignalProcess",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ProcessMonitor',
      name: `Send signal ${signal} to process ${process.pid}`,
      timestamp: new Date()
    });
    
    setSnackbar({
      open: true,
      message: `Signal ${signal} envoyé au processus ${process.pid}`,
      severity: 'info'
    });
    
    // Rafraîchir la liste après un court délai
    setTimeout(() => {
      refreshProcessList();
    }, 1000);
  };
  
  // Fonction pour rafraîchir les informations système
  const refreshSystemInfo = () => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingSystem(true);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SystemMonitor",
        nodeName: "SystemInfo",
        nodeValue: "sysinfo",
        actionType: "Linux",
        actionValue: "uname -a && hostname && uptime",
        actionName: "SystemInfo",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    // Aussi demander l'utilisation des ressources
    const resourcePayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SystemMonitor",
        nodeName: "ResourceUsage",
        nodeValue: "resources",
        actionType: "Linux",
        actionValue: "free -m && df -h",
        actionName: "ResourceUsage",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(resourcePayload);
    
    setLastAction({
      type: 'SystemMonitor',
      name: "Refresh system information",
      timestamp: new Date()
    });
  };
  
  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Server sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              Linux OS {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionStatus ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionStatus ? 'success' : 'error'}
            label={connectionStatus ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Serveur
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.server || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Hostname
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.hostname || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Utilisateur
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.user || 'N/A'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant de terminal
  const TerminalComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Terminal size={16} style={{ marginRight: 8 }} />
          Terminal Shell
        </Typography>
        
        <Box 
          ref={terminalRef}
          sx={{
            flex: 1,
            bgcolor: 'black',
            color: 'lightgreen',
            p: 2,
            borderRadius: 1,
            fontFamily: 'monospace',
            fontSize: '0.9rem',
            overflowY: 'auto',
            mb: 2
          }}
        >
          {terminalOutput.map((output, index) => (
            <Box key={index} sx={{ mb: 1 }}>
              {output.type === 'command' ? (
                <Box sx={{ color: 'cyan', display: 'flex' }}>
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontFamily: 'inherit', 
                      color: 'orange',
                      mr: 1
                    }}
                  >
                    {connectionInfo.user}@{connectionInfo.hostname}:~$
                  </Typography>
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontFamily: 'inherit'
                    }}
                  >
                    {output.content}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography 
                    component="div" 
                    sx={{ 
                      fontFamily: 'inherit',
                      color: output.exitCode === 0 ? 'lightgreen' : 'lightcoral',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {output.content}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
          {isExecuting && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ color: 'white', mr: 1 }} />
              <Typography sx={{ fontFamily: 'inherit' }}>Exécution en cours...</Typography>
            </Box>
          )}
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            sx={{ 
              color: 'primary.main', 
              fontFamily: 'monospace',
              mr: 1
            }}
          >
            {connectionInfo.user}@{connectionInfo.hostname}:~$
          </Typography>
          <TextField
            inputRef={commandInputRef}
            fullWidth
            variant="outlined"
            size="small"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                executeCommand();
              } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                navigateCommandHistory('up');
              } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                navigateCommandHistory('down');
              }
            }}
            placeholder="Entrez une commande shell..."
            disabled={disabled || !connectionStatus || isExecuting}
            InputProps={{
              sx: { fontFamily: 'monospace' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={executeCommand}
                    disabled={!command.trim() || disabled || !connectionStatus || isExecuting}
                  >
                    <Play size={16} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'primary.light',
                },
              },
            }}
          />
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button
            size="small"
            startIcon={<Trash2 size={16} />}
            onClick={() => setTerminalOutput([])}
            disabled={terminalOutput.length === 0}
          >
            Effacer le terminal
          </Button>
        </Box>
      </Box>
    );
  };
  
  // Composant d'explorateur de fichiers
  const FileExplorerComponent = () => {
    // Format de la taille en unités lisibles
    const formatFileSize = (bytes) => {
      if (bytes === 0) return '0 B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };
    
    // Format des permissions en notation symbolique
    const formatPermissions = (perms) => {
      if (!perms) return '';
      return perms;
    };
    
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Folder size={16} style={{ marginRight: 8 }} />
          Explorateur de fichiers
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Breadcrumbs separator={<ChevronRight size={16} />} aria-label="breadcrumb">
            {breadcrumbs.map((crumb, index) => (
              <Link
                key={index}
                component="button"
                underline="hover"
                color="inherit"
                onClick={() => navigateToPath(crumb.path)}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {index === 0 ? <Home size={16} style={{ marginRight: 4 }} /> : null}
                {crumb.name}
              </Link>
            ))}
          </Breadcrumbs>
          
          <Box>
            <IconButton
              size="small"
              onClick={navigateToParent}
              disabled={currentPath === '/' || disabled || !connectionStatus}
              sx={{ mr: 1 }}
              title="Dossier parent"
            >
              <ArrowUp size={16} />
            </IconButton>
            <IconButton
              size="small"
              onClick={refreshFilesList}
              disabled={disabled || !connectionStatus}
              title="Rafraîchir"
            >
              <RefreshCw size={16} />
            </IconButton>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
          {/* Liste des fichiers */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '60%', 
              mr: 2, 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {isLoadingFiles ? (
              <Box sx={{ p: 2 }}>
                <LinearProgress />
              </Box>
            ) : filesList.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Aucun fichier trouvé
                </Typography>
              </Box>
            ) : (
              <List dense>
                {filesList.map((file, index) => (
                  <ListItem 
                    key={index}
                    disablePadding
                    secondaryAction={
                      <IconButton 
                        edge="end" 
                        size="small"
                        onClick={(e) => {
                          setContextMenu({
                            mouseX: e.clientX,
                            mouseY: e.clientY,
                            file
                          });
                        }}
                      >
                        <MoreVertical size={16} />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      selected={selectedFile && selectedFile.name === file.name}
                      onClick={() => {
                        if (file.isDirectory) {
                          navigateToPath(currentPath === '/' ? `/${file.name}` : `${currentPath}/${file.name}`);
                        } else {
                          viewFile(file);
                        }
                      }}
                      dense
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {file.isDirectory ? (
                          <FolderOpen size={20} color="#FFB74D" />
                        ) : (
                          <FileText size={20} color="#90CAF9" />
                        )}
                      </ListItemIcon>
                      <ListItemText 
                        primary={file.name} 
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.secondary"
                              sx={{ display: 'block' }}
                            >
                              {formatPermissions(file.permissions)} {file.owner}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.secondary"
                              sx={{ display: 'block' }}
                            >
                              {!file.isDirectory && formatFileSize(file.size)}
                              {file.lastModified && ` • ${new Date(file.lastModified).toLocaleString()}`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
          
          {/* Aperçu de fichier */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '40%', 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {selectedFile ? (
              <React.Fragment>
                <Box sx={{ 
                  p: 2, 
                  bgcolor: 'grey.100', 
                  borderBottom: 1, 
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Typography variant="subtitle2">
                    {selectedFile.name}
                  </Typography>
                  <Chip
                    size="small"
                    label={formatFileSize(selectedFile.size)}
                  />
                </Box>
                
                {fileContent ? (
                  <Box sx={{ p: 2 }}>
                    {/* Aperçu du contenu selon le type */}
                    <Box 
                      sx={{ 
                        fontFamily: 'monospace', 
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        fontSize: '0.875rem',
                        p: 2,
                        bgcolor: 'grey.900',
                        color: 'grey.100',
                        borderRadius: 1,
                        overflow: 'auto',
                        maxHeight: '400px'
                      }}
                    >
                      {fileContent.content}
                    </Box>
                    
                    <Divider sx={{ my: 2 }} />
                    
                    {/* Informations du fichier */}
                    <Typography variant="subtitle2" gutterBottom>
                      Informations du fichier
                    </Typography>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Type:</Typography>
                        <Typography variant="body2">{fileContent.mimeType}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Taille:</Typography>
                        <Typography variant="body2">{formatFileSize(fileContent.size)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Permissions:</Typography>
                        <Typography variant="body2">{fileContent.permissions}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Propriétaire:</Typography>
                        <Typography variant="body2">{fileContent.owner}</Typography>
                      </Grid>
                      {fileContent.lastModified && (
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">Dernière modification:</Typography>
                          <Typography variant="body2">{fileContent.lastModified.toLocaleString()}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <CircularProgress size={24} />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Chargement du contenu...
                    </Typography>
                  </Box>
                )}
              </React.Fragment>
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Sélectionnez un fichier pour voir son contenu
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    );
  };
  
  // Composant moniteur de processus
  const ProcessMonitorComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Activity size={16} style={{ marginRight: 8 }} />
          Moniteur de processus
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            startIcon={<RefreshCw size={16} />}
            onClick={refreshProcessList}
            disabled={disabled || !connectionStatus || isLoadingProcesses}
            size="small"
          >
            Rafraîchir
          </Button>
        </Box>
        
        <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
          {/* Liste des processus */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '60%', 
              mr: 2, 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {isLoadingProcesses ? (
              <Box sx={{ p: 2 }}>
                <LinearProgress />
              </Box>
            ) : processList.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Aucun processus trouvé
                </Typography>
              </Box>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>PID</TableCell>
                      <TableCell>Utilisateur</TableCell>
                      <TableCell>%CPU</TableCell>
                      <TableCell>%MEM</TableCell>
                      <TableCell>Commande</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processList.map((process, index) => (
                      <TableRow key={index} hover
                        selected={selectedProcess && selectedProcess.pid === process.pid}
                        onClick={() => viewProcessDetails(process)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{process.pid}</TableCell>
                        <TableCell>{process.user}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ minWidth: 36 }}>
                              {parseFloat(process.cpu).toFixed(1)}%
                            </Typography>
                            <LinearProgress 
                              variant="determinate" 
                              value={Math.min(parseFloat(process.cpu), 100)} 
                              sx={{ 
                                ml: 1, 
                                width: 50,
                                height: 6,
                                borderRadius: 3,
                                backgroundColor: 'grey.300',
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: parseFloat(process.cpu) > 50 ? 'error.main' : 'success.main'
                                }
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ minWidth: 36 }}>
                              {parseFloat(process.mem).toFixed(1)}%
                            </Typography>
                            <LinearProgress 
                              variant="determinate" 
                              value={Math.min(parseFloat(process.mem), 100)} 
                              sx={{ 
                                ml: 1, 
                                width: 50,
                                height: 6,
                                borderRadius: 3,
                                backgroundColor: 'grey.300',
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: parseFloat(process.mem) > 50 ? 'error.main' : 'info.main'
                                }
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={process.command}>
                            <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                              {process.command}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              sendSignalToProcess(process, 15); // SIGTERM
                            }}
                            title="Terminer (SIGTERM)"
                            sx={{ mr: 1 }}
                          >
                            <StopCircle size={16} color="error" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              sendSignalToProcess(process, 9); // SIGKILL
                            }}
                            title="Kill (SIGKILL)"
                          >
                            <XCircle size={16} color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
          
          {/* Détails du processus */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '40%', 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {selectedProcess ? (
              <React.Fragment>
                <Box sx={{ 
                  p: 2, 
                  bgcolor: 'grey.100', 
                  borderBottom: 1, 
                  borderColor: 'divider'
                }}>
                  <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Activity size={16} style={{ marginRight: 8 }} />
                    Processus {selectedProcess.pid}
                  </Typography>
                </Box>
                
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">PID:</Typography>
                      <Typography variant="body2">{selectedProcess.pid}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">PPID:</Typography>
                      <Typography variant="body2">{selectedProcess.ppid}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Utilisateur:</Typography>
                      <Typography variant="body2">{selectedProcess.user}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">État:</Typography>
                      <Typography variant="body2">{selectedProcess.state}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">CPU:</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">{parseFloat(selectedProcess.cpu).toFixed(1)}%</Typography>
                        <LinearProgress 
                          variant="determinate" 
                          value={Math.min(parseFloat(selectedProcess.cpu), 100)} 
                          sx={{ 
                            ml: 1, 
                            width: 100,
                            height: 6,
                            borderRadius: 3,
                            backgroundColor: 'grey.300',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: parseFloat(selectedProcess.cpu) > 50 ? 'error.main' : 'success.main'
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Mémoire:</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">{parseFloat(selectedProcess.mem).toFixed(1)}%</Typography>
                        <LinearProgress 
                          variant="determinate" 
                          value={Math.min(parseFloat(selectedProcess.mem), 100)} 
                          sx={{ 
                            ml: 1, 
                            width: 100,
                            height: 6,
                            borderRadius: 3,
                            backgroundColor: 'grey.300',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: parseFloat(selectedProcess.mem) > 50 ? 'error.main' : 'info.main'
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Commande:</Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontFamily: 'monospace',
                          whiteSpace: 'pre-wrap',
                          bgcolor: 'grey.900',
                          color: 'grey.100',
                          p: 1,
                          borderRadius: 1,
                          mt: 1
                        }}
                      >
                        {selectedProcess.command}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Actions:</Typography>
                      <Box sx={{ mt: 1 }}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          startIcon={<StopCircle size={16} />}
                          onClick={() => sendSignalToProcess(selectedProcess, 15)} // SIGTERM
                          sx={{ mr: 1 }}
                        >
                          Terminer (SIGTERM)
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          startIcon={<XCircle size={16} />}
                          onClick={() => sendSignalToProcess(selectedProcess, 9)} // SIGKILL
                        >
                          Kill (SIGKILL)
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Sélectionnez un processus pour voir ses détails
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    );
  };
  
  // Composant moniteur système
  const SystemMonitorComponent = () => {
    const formatProgressColor = (value) => {
      if (value > 80) return 'error';
      if (value > 60) return 'warning';
      return 'success';
    };
    
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Server size={16} style={{ marginRight: 8 }} />
          Informations système
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            startIcon={<RefreshCw size={16} />}
            onClick={refreshSystemInfo}
            disabled={disabled || !connectionStatus || isLoadingSystem}
            size="small"
          >
            Rafraîchir
          </Button>
        </Box>
        
        {isLoadingSystem ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : !systemInfo ? (
          <Box sx={{ textAlign: 'center', p: 4 }}>
            <Typography variant="body2" color="text.secondary">
              Cliquez sur Rafraîchir pour charger les informations système
            </Typography>
          </Box>
        ) : (
          <Box sx={{ flex: 1 }}>
            <Grid container spacing={3}>
              {/* Informations système */}
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Info size={16} style={{ marginRight: 8 }} />
                    Informations générales
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Système d'exploitation:</Typography>
                      <Typography variant="body2">{systemInfo.osName} {systemInfo.osVersion}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Noyau:</Typography>
                      <Typography variant="body2">{systemInfo.kernelVersion}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Architecture:</Typography>
                      <Typography variant="body2">{systemInfo.architecture}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Hostname:</Typography>
                      <Typography variant="body2">{systemInfo.hostname}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Uptime:</Typography>
                      <Typography variant="body2">{systemInfo.uptime}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Date/Heure:</Typography>
                      <Typography variant="body2">{systemInfo.currentTime}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              
              {/* Utilisation CPU */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Cpu size={16} style={{ marginRight: 8 }} />
                    Utilisation CPU
                  </Typography>
                  
                  {resourceUsage && resourceUsage.cpuUsage && (
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Utilisation totale</Typography>
                        <Typography variant="body2" color={formatProgressColor(resourceUsage.cpuUsage.total)}>
                          {resourceUsage.cpuUsage.total}%
                        </Typography>
                      </Box>
                      <LinearProgress 
                        variant="determinate" 
                        value={resourceUsage.cpuUsage.total} 
                        color={formatProgressColor(resourceUsage.cpuUsage.total)}
                        sx={{ height: 10, borderRadius: 5, mb: 2 }}
                      />
                      
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="caption" color="text.secondary">User</Typography>
                          <LinearProgress 
                            variant="determinate" 
                            value={resourceUsage.cpuUsage.user} 
                            color="primary"
                            sx={{ height: 6, borderRadius: 3 }}
                          />
                          <Typography variant="caption" align="right" display="block">
                            {resourceUsage.cpuUsage.user}%
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="caption" color="text.secondary">System</Typography>
                          <LinearProgress 
                            variant="determinate" 
                            value={resourceUsage.cpuUsage.system} 
                            color="secondary"
                            sx={{ height: 6, borderRadius: 3 }}
                          />
                          <Typography variant="caption" align="right" display="block">
                            {resourceUsage.cpuUsage.system}%
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="caption" color="text.secondary">I/O Wait</Typography>
                          <LinearProgress 
                            variant="determinate" 
                            value={resourceUsage.cpuUsage.iowait} 
                            color="warning"
                            sx={{ height: 6, borderRadius: 3 }}
                          />
                          <Typography variant="caption" align="right" display="block">
                            {resourceUsage.cpuUsage.iowait}%
                          </Typography>
                        </Grid>
                      </Grid>
                      
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="text.secondary">Informations CPU:</Typography>
                        <Typography variant="body2">{resourceUsage.cpuInfo.model}</Typography>
                        <Typography variant="body2">{resourceUsage.cpuInfo.cores} cœurs, {resourceUsage.cpuInfo.threads} threads</Typography>
                      </Box>
                    </Box>
                  )}
                </Paper>
              </Grid>
              
              {/* Utilisation Mémoire */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Database size={16} style={{ marginRight: 8 }} />
                    Utilisation Mémoire
                  </Typography>
                  
                  {resourceUsage && resourceUsage.memoryUsage && (
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Utilisation RAM</Typography>
                        <Typography variant="body2" color={formatProgressColor(resourceUsage.memoryUsage.percentUsed)}>
                          {resourceUsage.memoryUsage.percentUsed}%
                        </Typography>
                      </Box>
                      <LinearProgress 
                        variant="determinate" 
                        value={resourceUsage.memoryUsage.percentUsed} 
                        color={formatProgressColor(resourceUsage.memoryUsage.percentUsed)}
                        sx={{ height: 10, borderRadius: 5, mb: 2 }}
                      />
                      
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">Total:</Typography>
                          <Typography variant="body2">{resourceUsage.memoryUsage.total} MB</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">Utilisé:</Typography>
                          <Typography variant="body2">{resourceUsage.memoryUsage.used} MB</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">Libre:</Typography>
                          <Typography variant="body2">{resourceUsage.memoryUsage.free} MB</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">Buffers/Cache:</Typography>
                          <Typography variant="body2">{resourceUsage.memoryUsage.cached} MB</Typography>
                        </Grid>
                      </Grid>
                      
                      <Divider sx={{ my: 2 }} />
                      
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Utilisation Swap</Typography>
                        <Typography variant="body2" color={formatProgressColor(resourceUsage.swapUsage.percentUsed)}>
                          {resourceUsage.swapUsage.percentUsed}%
                        </Typography>
                      </Box>
                      <LinearProgress 
                        variant="determinate" 
                        value={resourceUsage.swapUsage.percentUsed} 
                        color={formatProgressColor(resourceUsage.swapUsage.percentUsed)}
                        sx={{ height: 6, borderRadius: 3, mb: 1 }}
                      />
                      
                      <Typography variant="caption">
                        {resourceUsage.swapUsage.used} MB / {resourceUsage.swapUsage.total} MB
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Grid>
              
              {/* Utilisation disque */}
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <HardDrive size={16} style={{ marginRight: 8 }} />
                    Utilisation disque
                  </Typography>
                  
                  {resourceUsage && resourceUsage.diskUsage && (
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Point de montage</TableCell>
                            <TableCell>Système de fichiers</TableCell>
                            <TableCell>Utilisation</TableCell>
                            <TableCell>Taille</TableCell>
                            <TableCell>Utilisé</TableCell>
                            <TableCell>Disponible</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {resourceUsage.diskUsage.map((disk, index) => (
                            <TableRow key={index}>
                              <TableCell>{disk.mountPoint}</TableCell>
                              <TableCell>{disk.filesystem}</TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <LinearProgress 
                                    variant="determinate" 
                                    value={parseInt(disk.usedPercent)} 
                                    color={formatProgressColor(parseInt(disk.usedPercent))}
                                    sx={{ width: 60, height: 6, borderRadius: 3, mr: 1 }}
                                  />
                                  <Typography variant="body2">
                                    {disk.usedPercent}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>{disk.size}</TableCell>
                              <TableCell>{disk.used}</TableCell>
                              <TableCell>{disk.available}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Paper>
              </Grid>
              
              {/* Utilisateurs connectés */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Users size={16} style={{ marginRight: 8 }} />
                    Utilisateurs connectés
                  </Typography>
                  
                  {systemInfo && systemInfo.loggedInUsers && (
                    <List dense>
                      {systemInfo.loggedInUsers.map((user, index) => (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <User size={20} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={user.username} 
                            secondary={`${user.tty} - Depuis ${user.loginTime}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Paper>
              </Grid>
              
              {/* Services système */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Settings size={16} style={{ marginRight: 8 }} />
                    Services système
                  </Typography>
                  
                  {systemInfo && systemInfo.services && (
                    <List dense>
                      {systemInfo.services.map((service, index) => (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            {service.status === 'running' ? (
                              <CheckCircle size={20} color="green" />
                            ) : (
                              <StopCircle size={20} color="red" />
                            )}
                          </ListItemIcon>
                          <ListItemText 
                            primary={service.name} 
                            secondary={service.description}
                          />
                          <Chip 
                            size="small" 
                            label={service.status} 
                            color={service.status === 'running' ? 'success' : 'error'} 
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };
  
  // Menu contextuel pour les fichiers
  const FileContextMenu = () => {
    if (!contextMenu) return null;
    
    const handleClose = () => {
      setContextMenu(null);
    };
    
    const handleAction = (action) => {
      // Implémenter les actions du menu contextuel
      handleClose();
    };
    
    return (
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => handleAction('view')}>
          <ListItemIcon>
            <Eye size={16} />
          </ListItemIcon>
          <ListItemText>Visualiser</ListItemText>
        </MenuItem>
        {contextMenu.file && !contextMenu.file.isDirectory && (
          <MenuItem onClick={() => handleAction('edit')}>
            <ListItemIcon>
              <Edit size={16} />
            </ListItemIcon>
            <ListItemText>Éditer</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleAction('rename')}>
          <ListItemIcon>
            <Edit size={16} />
          </ListItemIcon>
          <ListItemText>Renommer</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAction('delete')}>
          <ListItemIcon>
            <Trash2 size={16} />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
        {contextMenu.file && !contextMenu.file.isDirectory && (
          <MenuItem onClick={() => handleAction('download')}>
            <ListItemIcon>
              <Download size={16} />
            </ListItemIcon>
            <ListItemText>Télécharger</ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={() => handleAction('properties')}>
          <ListItemIcon>
            <Info size={16} />
          </ListItemIcon>
          <ListItemText>Propriétés</ListItemText>
        </MenuItem>
      </Menu>
    );
  };
  
  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionStatus) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionStatus ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          bgcolor: 'background.paper'
        }}
      >
        <Tab icon={<Terminal size={16} />} label="Terminal" />
        <Tab icon={<Folder size={16} />} label="Fichiers" />
        <Tab icon={<Activity size={16} />} label="Processus" />
        <Tab icon={<Server size={16} />} label="Système" />
      </Tabs>
      
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {activeTab === 0 && <TerminalComponent />}
        {activeTab === 1 && <FileExplorerComponent />}
        {activeTab === 2 && <ProcessMonitorComponent />}
        {activeTab === 3 && <SystemMonitorComponent />}
      </Box>
      
      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
      
      <FileContextMenu />
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default LinuxCapture;