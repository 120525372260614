// components/shared/workflow/WorkflowModals.js
import React from 'react';
import { AlertTriangle, X, Settings } from 'lucide-react';

// Modal d'ajout de nœud
export function AddNodeModal({ isOpen, node, onClose, onSubmit, onChange }) {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Ajouter un état
        </h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="node-label" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Nom de l'état
            </label>
            <input
              id="node-label"
              type="text"
              value={node.label}
              onChange={(e) => onChange({ ...node, label: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Nom de l'état"
            />
          </div>
          
          <div>
            <label htmlFor="node-description" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Description (optionnel)
            </label>
            <textarea
              id="node-description"
              value={node.description || ''}
              onChange={(e) => onChange({ ...node, description: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Description"
              rows={3}
            />
          </div>
        </div>
        
        <div className="mt-6 flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            Annuler
          </button>
          <button
            onClick={onSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            disabled={!node.label}
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  );
}

// Modal d'édition de nœud
export function EditNodeModal({ isOpen, node, onClose, onSubmit, onChange }) {
  if (!isOpen || !node) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Modifier l'état
        </h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="edit-node-label" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Nom de l'état
            </label>
            <input
              id="edit-node-label"
              type="text"
              value={node.data?.label || ''}
              onChange={(e) => onChange({
                ...node,
                data: { ...node.data, label: e.target.value }
              })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Nom de l'état"
            />
          </div>
          
          <div>
            <label htmlFor="edit-node-description" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Description (optionnel)
            </label>
            <textarea
              id="edit-node-description"
              value={node.data?.description || ''}
              onChange={(e) => onChange({
                ...node,
                data: { ...node.data, description: e.target.value }
              })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Description"
              rows={3}
            />
          </div>
        </div>
        
        <div className="mt-6 flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            Annuler
          </button>
          <button
            onClick={onSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            disabled={!node.data?.label}
          >
            Mettre à jour
          </button>
        </div>
      </div>
    </div>
  );
}

// Modal d'ajout de transition
export function AddEdgeModal({ isOpen, edge, onClose, onSubmit, onChange }) {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Ajouter une transition
        </h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="edge-label" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Événement
            </label>
            <input
              id="edge-label"
              type="text"
              value={edge.label || ''}
              onChange={(e) => onChange({ ...edge, label: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Nom de l'événement"
            />
          </div>
          
          <div className="flex items-center">
            <input
              id="automatic-transition"
              type="checkbox"
              checked={edge.isAutomatic || false}
              onChange={(e) => onChange({ ...edge, isAutomatic: e.target.checked })}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="automatic-transition" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
              Transition automatique
            </label>
          </div>

          <div>
            <label htmlFor="roles" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Rôles requis (séparés par des virgules)
            </label>
            <input
              id="roles"
              type="text"
              value={(edge.roles || []).join(', ')}
              onChange={(e) => onChange({ 
                ...edge, 
                roles: e.target.value.split(',').map(r => r.trim()).filter(r => r) 
              })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="ADMIN, USER, etc."
            />
          </div>
          
          <div>
            <label htmlFor="actions" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Actions (séparées par des virgules)
            </label>
            <input
              id="actions"
              type="text"
              value={(edge.actions || []).join(', ')}
              onChange={(e) => onChange({ 
                ...edge, 
                actions: e.target.value.split(',').map(a => a.trim()).filter(a => a) 
              })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="sendEmail, validateDocument, etc."
            />
          </div>
        </div>
        
        <div className="mt-6 flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            Annuler
          </button>
          <button
            onClick={onSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  );
}

// Modal d'édition de transition
export function EditEdgeModal({ isOpen, edge, onClose, onSubmit, onChange }) {
  if (!isOpen || !edge) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Modifier la transition
        </h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="edit-edge-label" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Événement
            </label>
            <input
              id="edit-edge-label"
              type="text"
              value={edge.label || ''}
              onChange={(e) => onChange({
                ...edge,
                label: e.target.value
              })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Nom de l'événement"
            />
          </div>
          
          <div className="flex items-center">
            <input
              id="edit-automatic-transition"
              type="checkbox"
              checked={edge.data?.isAutomatic || false}
              onChange={(e) => onChange({
                ...edge,
                data: { ...edge.data, isAutomatic: e.target.checked }
              })}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="edit-automatic-transition" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
              Transition automatique
            </label>
          </div>
          
          <div>
            <label htmlFor="edit-roles" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Rôles requis (séparés par des virgules)
            </label>
            <input
              id="edit-roles"
              type="text"
              value={(edge.data?.roles || []).join(', ')}
              onChange={(e) => onChange({
                ...edge,
                data: { 
                  ...edge.data, 
                  roles: e.target.value.split(',').map(r => r.trim()).filter(r => r) 
                }
              })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="ADMIN, USER, etc."
            />
          </div>
          
          <div>
            <label htmlFor="edit-actions" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Actions (séparées par des virgules)
            </label>
            <input
              id="edit-actions"
              type="text"
              value={(edge.data?.actions || []).join(', ')}
              onChange={(e) => onChange({
                ...edge,
                data: { 
                  ...edge.data, 
                  actions: e.target.value.split(',').map(a => a.trim()).filter(a => a) 
                }
              })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="sendEmail, validateDocument, etc."
            />
          </div>
        </div>
        
        <div className="mt-6 flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            Annuler
          </button>
          <button
            onClick={onSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Mettre à jour
          </button>
        </div>
      </div>
    </div>
  );
}

// Modal des paramètres du workflow
export function WorkflowSettingsModal({ isOpen, name, description, onClose, onSubmit, onChange }) {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Paramètres du workflow
        </h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="workflow-name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Nom du workflow
            </label>
            <input
              id="workflow-name"
              type="text"
              value={name || ''}
              onChange={(e) => onChange('name', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Nom du workflow"
            />
          </div>
          
          <div>
            <label htmlFor="workflow-description" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Description
            </label>
            <textarea
              id="workflow-description"
              value={description || ''}
              onChange={(e) => onChange('description', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Description du workflow"
              rows={4}
            />
          </div>
        </div>
        
        <div className="mt-6 flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            Annuler
          </button>
          <button
            onClick={onSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Appliquer
          </button>
        </div>
      </div>
    </div>
  );
}

// Modal de confirmation de réinitialisation
export function ConfirmResetModal({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full">
        <div className="flex items-center mb-4">
          <div className="flex-shrink-0 mr-4">
            <div className="w-10 h-10 rounded-full bg-red-100 dark:bg-red-900/20 flex items-center justify-center">
              <AlertTriangle className="h-6 w-6 text-red-600 dark:text-red-400" />
            </div>
          </div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Réinitialiser le workflow
          </h3>
        </div>
        
        <div className="text-sm text-gray-500 dark:text-gray-400 mb-6">
          Êtes-vous sûr de vouloir réinitialiser le workflow ? Cette action supprimera tous les états et transitions actuels et ne peut pas être annulée.
        </div>
        
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            Annuler
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Réinitialiser
          </button>
        </div>
      </div>
    </div>
  );
}