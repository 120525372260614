import React, { useState, useEffect } from 'react';
import { 
  Calendar, 
  Clock, 
  Plus, 
  Trash2, 
  Edit, 
  RefreshCw, 
  PlayCircle, 
  PauseCircle,
  AlertTriangle,
  CheckCircle,
  Search,
  Filter,
  HelpCircle,
  X,
  Info
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

/**
 * Gestion des tâches planifiées
 * Permet de créer, modifier, supprimer, activer et désactiver des jobs
 */
const JobsPage = () => {
  // États pour les données et le chargement
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showJobModal, setShowJobModal] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Formulaire pour création/édition de job
  const [jobForm, setJobForm] = useState({
    name: '',
    description: '',
    type: 'SCHEDULED',
    priority: 'MEDIUM',
    className: '',
    methodName: '',
    parameters: {},
    maxRetries: 3,
    timeout: 60000,
    schedule: {
      cronExpression: '',
      timezone: 'UTC',
      startDate: null,
      endDate: null,
      maxExecutions: null,
      fixedDelay: null,
      pauseOnFailure: false
    }
  });

  // Chargement initial des jobs
  useEffect(() => {
    fetchJobs();
  }, []);

  // Chargement des jobs
  // Chargement des jobs
const fetchJobs = async () => {
  setLoading(true);
  setError(null);
  
  try {
    const response = await apiService.scheduler.jobs.getAll(
      filterType !== 'all' ? filterType : null,
      filterStatus !== 'all' ? filterStatus : null
    );
    
    // Débogage: Afficher la structure complète des données reçues
    console.log('Jobs data received raw:', response);
    
    // Normaliser les données pour ajouter la propriété 'id' basée sur 'jobId'
    const formattedJobs = response.map(job => ({
      ...job,
      id: job.jobId, // Ajouter 'id' basé sur 'jobId'
      statusLabel: job.active ? 'Actif' : 'Inactif'
    }));
    
    console.log('Jobs data normalized:', formattedJobs);
    
    setJobs(formattedJobs);
  } catch (err) {
    console.error('Erreur lors du chargement des jobs:', err);
    setError('Impossible de charger les tâches planifiées.');
  } finally {
    setLoading(false);
  }
};

  // Filtrer les jobs en fonction de la recherche
  const filteredJobs = jobs.filter(job => {
    const matchesSearch = searchTerm === '' || 
      job.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (job.description && job.description.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesType = filterType === 'all' || job.type === filterType;
    const matchesStatus = filterStatus === 'all' || 
      (filterStatus === 'ACTIVE' && job.active) || 
      (filterStatus === 'INACTIVE' && !job.active);
    
    return matchesSearch && matchesType && matchesStatus;
  });

  // Gérer le changement dans le formulaire
  const handleFormChange = (field, value) => {
    if (field.includes('.')) {
      // Pour les champs imbriqués comme schedule.cronExpression
      const [parent, child] = field.split('.');
      setJobForm(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setJobForm(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  // Gérer les changements dans les paramètres
  const handleParameterChange = (key, value) => {
    setJobForm(prev => ({
      ...prev,
      parameters: {
        ...prev.parameters,
        [key]: value
      }
    }));
  };

  // Ajouter un nouveau paramètre
  const addParameter = () => {
    // Ouvrir une boîte de dialogue pour saisir la clé et la valeur
    const key = prompt("Entrez le nom du paramètre:");
    if (key && key.trim() !== '') {
      const value = prompt("Entrez la valeur du paramètre:");
      if (value !== null) {
        handleParameterChange(key.trim(), value);
      }
    }
  };

  // Supprimer un paramètre
  const removeParameter = (key) => {
    setJobForm(prev => {
      const updatedParams = { ...prev.parameters };
      delete updatedParams[key];
      return {
        ...prev,
        parameters: updatedParams
      };
    });
  };

  // Ouvrir le modal pour créer un job
  const openCreateModal = () => {
    setIsCreateMode(true);
    setJobForm({
      name: '',
      description: '',
      type: 'SCHEDULED',
      priority: 'MEDIUM',
      className: '',
      methodName: '',
      parameters: {},
      maxRetries: 3,
      timeout: 60000,
      schedule: {
        cronExpression: '',
        timezone: 'UTC',
        startDate: null,
        endDate: null,
        maxExecutions: null,
        fixedDelay: null,
        pauseOnFailure: false
      }
    });
    setShowJobModal(true);
  };

  // Ouvrir le modal pour éditer un job
  const openEditModal = async (job) => {
    // Vérifier si on a un objet job valide
    if (!job) {
      console.error('Erreur: job est undefined');
      setError("Impossible de modifier : tâche non sélectionnée");
      return;
    }
    
    // Extraire l'ID et vérifier sa validité
    const jobId = job.id;
    if (jobId === undefined || jobId === null) {
      console.error('ID invalide reçu:', jobId);
      setError("Impossible de modifier : ID de tâche manquant ou invalide");
      return;
    }
    
    // Conversion sécurisée de l'ID en nombre
    const numericJobId = typeof jobId === 'string' ? parseInt(jobId, 10) : jobId;
    
    if (isNaN(numericJobId)) {
      console.error('ID invalide reçu (non numérique):', jobId);
      setError("ID de tâche invalide: doit être un nombre");
      return;
    }
    
    setIsCreateMode(false);
    setLoading(true);
    
    try {
      const jobDetails = await apiService.scheduler.jobs.getById(numericJobId);
      
      // Vérifier si nous avons obtenu des données valides
      if (!jobDetails) {
        throw new Error(`Aucune donnée trouvée pour le job ${numericJobId}`);
      }
      
      setJobForm({
        id: jobDetails.id,
        name: jobDetails.name || '',
        description: jobDetails.description || '',
        type: jobDetails.type || 'SCHEDULED',
        priority: jobDetails.priority || 'MEDIUM',
        className: jobDetails.className || '',
        methodName: jobDetails.methodName || '',
        parameters: jobDetails.parameters || {},
        maxRetries: jobDetails.maxRetries || 3,
        timeout: jobDetails.timeout || 60000,
        schedule: jobDetails.schedule || {
          cronExpression: '',
          timezone: 'UTC',
          startDate: null,
          endDate: null,
          maxExecutions: null,
          fixedDelay: null,
          pauseOnFailure: false
        }
      });
      setShowJobModal(true);
    } catch (err) {
      console.error(`Erreur lors du chargement du job ${numericJobId}:`, err);
      setError(`Impossible de charger les détails du job ${numericJobId}.`);
    } finally {
      setLoading(false);
    }
  };

  // Fermer le modal de job
  const closeJobModal = () => {
    setShowJobModal(false);
  };

  // Soumettre le formulaire de job
  const submitJobForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      if (isCreateMode) {
        await apiService.scheduler.jobs.create(jobForm);
        setSuccessMessage('Tâche créée avec succès!');
      } else {
        // Pour l'édition, nous utilisons la mise à jour du schedule car il n'y a pas d'endpoint direct
        // pour mettre à jour le job complet
        if (!jobForm.id) {
          throw new Error("ID de tâche manquant pour la mise à jour");
        }
        await apiService.scheduler.schedules.update(jobForm.id, jobForm.schedule);
        setSuccessMessage('Tâche mise à jour avec succès!');
      }
      
      setShowJobModal(false);
      fetchJobs();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Erreur lors de la sauvegarde du job:', err);
      setError(isCreateMode ? 'Impossible de créer la tâche.' : 'Impossible de mettre à jour la tâche.');
    } finally {
      setLoading(false);
    }
  };

  // Gérer l'activation/désactivation d'un job
  const toggleJobStatus = async (job) => {
    // Vérifier si on a un objet job valide
    if (!job) {
      console.error('Erreur: job est undefined');
      setError("Impossible de modifier le statut : tâche non sélectionnée");
      return;
    }
    
    // Extraire l'ID et vérifier sa validité
    const jobId = job.id;
    const currentStatus = job.active;
    
    if (jobId === undefined || jobId === null) {
      console.error('ID invalide reçu:', jobId);
      setError("Impossible de modifier le statut : ID de tâche manquant ou invalide");
      return;
    }
    
    // Conversion sécurisée de l'ID en nombre
    const numericJobId = typeof jobId === 'string' ? parseInt(jobId, 10) : jobId;
    
    if (isNaN(numericJobId)) {
      console.error('ID invalide reçu (non numérique):', jobId);
      setError("ID de tâche invalide: doit être un nombre");
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      if (currentStatus) {
        await apiService.scheduler.jobs.deactivate(numericJobId);
        setSuccessMessage("Tâche désactivée.");
      } else {
        await apiService.scheduler.jobs.activate(numericJobId);
        setSuccessMessage("Tâche activée.");
      }
      
      fetchJobs();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Erreur lors du changement de statut du job:', err);
      setError(`Impossible de ${currentStatus ? 'désactiver' : 'activer'} la tâche.`);
    } finally {
      setLoading(false);
    }
  };

  // Ouvrir la confirmation de suppression
  const confirmDeleteJob = (job) => {
    // Vérifier si on a un objet job valide
    if (!job) {
      console.error('Erreur: job est undefined');
      setError("Impossible de supprimer : tâche non sélectionnée");
      return;
    }
    
    // Extraire l'ID et vérifier sa validité
    const jobId = job.id;
    
    if (jobId === undefined || jobId === null) {
      console.error('ID invalide reçu:', jobId);
      setError("Impossible de supprimer : ID de tâche manquant ou invalide");
      return;
    }
    
    // Conversion sécurisée de l'ID en nombre
    const numericJobId = typeof jobId === 'string' ? parseInt(jobId, 10) : jobId;
    
    if (isNaN(numericJobId)) {
      console.error('ID invalide reçu (non numérique):', jobId);
      setError("ID de tâche invalide: doit être un nombre");
      return;
    }
    
    setJobToDelete(numericJobId);
    setShowConfirmDelete(true);
  };

  // Fermer la confirmation de suppression
  const closeConfirmDelete = () => {
    setShowConfirmDelete(false);
    setJobToDelete(null);
  };

  // Supprimer un job
  const deleteJob = async () => {
    if (!jobToDelete) return;
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.scheduler.jobs.delete(jobToDelete);
      setSuccessMessage('Tâche supprimée avec succès!');
      fetchJobs();
      closeConfirmDelete();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression du job:', err);
      setError('Impossible de supprimer la tâche.');
    } finally {
      setLoading(false);
    }
  };

  // Afficher les détails d'un job
  const viewJobDetails = (job) => {
    // Vérifier si on a un objet job valide
    if (!job) {
      console.error('Erreur: job est undefined');
      setError("Impossible d'afficher les détails : tâche non sélectionnée");
      return;
    }
    
    setSelectedJob(job);
  };

  // Déclencher manuellement un job
  const triggerJob = async (job) => {
    // Vérifier si on a un objet job valide
    if (!job) {
      console.error('Erreur: job est undefined');
      setError("Impossible de déclencher : tâche non sélectionnée");
      return;
    }
    
    // Extraire l'ID et vérifier sa validité
    const jobId = job.id;
    
    if (jobId === undefined || jobId === null) {
      console.error('ID invalide reçu:', jobId);
      setError("Impossible de déclencher : ID de tâche manquant ou invalide");
      return;
    }
    
    // Conversion sécurisée de l'ID en nombre
    const numericJobId = typeof jobId === 'string' ? parseInt(jobId, 10) : jobId;
    
    if (isNaN(numericJobId)) {
      console.error('ID invalide reçu (non numérique):', jobId);
      setError("ID de tâche invalide: doit être un nombre");
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.scheduler.executions.trigger({
        jobId: numericJobId,
        async: true
      });
      
      setSuccessMessage('Tâche déclenchée avec succès!');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors du déclenchement de la tâche:', err);
      setError('Impossible de déclencher la tâche.');
    } finally {
      setLoading(false);
    }
  };

  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'name',
      header: 'Nom',
      sortable: true,
      render: (row) => (
        <div>
          <div className="font-medium text-blue-600 dark:text-blue-400 cursor-pointer hover:underline" onClick={() => viewJobDetails(row)}>
            {row.name}
          </div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md mt-1">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      key: 'type',
      header: 'Type',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <span className="px-2 py-1 rounded-full text-xs bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
            {row.type}
          </span>
        </div>
      )
    },
    {
      key: 'status',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={row.active ? 'active' : 'inactive'} 
          customLabel={row.active ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      key: 'priority',
      header: 'Priorité',
      sortable: true,
      render: (row) => {
        const priorityConfig = {
          HIGH: { color: 'text-red-600 dark:text-red-400', bg: 'bg-red-100 dark:bg-red-900/20' },
          MEDIUM: { color: 'text-yellow-600 dark:text-yellow-400', bg: 'bg-yellow-100 dark:bg-yellow-900/20' },
          LOW: { color: 'text-blue-600 dark:text-blue-400', bg: 'bg-blue-100 dark:bg-blue-900/20' },
          CRITICAL: { color: 'text-purple-600 dark:text-purple-400', bg: 'bg-purple-100 dark:bg-purple-900/20' }
        };
        
        const config = priorityConfig[row.priority] || priorityConfig.MEDIUM;
        
        return (
          <span className={`px-2 py-1 rounded-full text-xs ${config.bg} ${config.color}`}>
            {row.priority}
          </span>
        );
      }
    },
    {
      key: 'jobMethod',
      header: 'Méthode',
      sortable: true,
      render: (row) => (
        <div className="text-xs font-mono bg-gray-100 dark:bg-gray-800 p-1 rounded">
          {row.methodName || '-'}
        </div>
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => {
        return (
          <div className="flex items-center justify-end space-x-2">
            <Button
              variant="text"
              size="sm"
              icon={row.active ? PauseCircle : PlayCircle}
              onClick={() => toggleJobStatus(row)}
              title={row.active ? 'Désactiver' : 'Activer'}
            />
            <Button
              variant="text"
              size="sm"
              icon={PlayCircle}
              onClick={() => triggerJob(row)}
              title="Exécuter maintenant"
            />
            <Button
              variant="text"
              size="sm"
              icon={Edit}
              onClick={() => openEditModal(row)}
              title="Modifier"
            />
            <Button
              variant="text"
              size="sm"
              icon={Trash2}
              onClick={() => confirmDeleteJob(row)}
              title="Supprimer"
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Calendar className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des tâches planifiées
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Créez, modifiez et gérez les tâches programmées du système
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchJobs}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={Plus}
            onClick={openCreateModal}
          >
            Nouvelle tâche
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Filtres */}
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Rechercher une tâche..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex space-x-2">
          <select
            className="px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="all">Tous les types</option>
            <option value="SCHEDULED">Planifié (CRON)</option>
            <option value="RECURRING">Récurrent</option>
            <option value="TRIGGERED">Déclenché</option>
            <option value="ONE_TIME">Une seule fois</option>
          </select>
          
          <select
            className="px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">Tous les statuts</option>
            <option value="ACTIVE">Actifs</option>
            <option value="INACTIVE">Inactifs</option>
          </select>
        </div>
      </div>

      {/* Tableau des tâches */}
      <Card>
        <DataGrid
          data={filteredJobs}
          columns={columns}
          loading={loading}
          emptyMessage="Aucune tâche planifiée trouvée"
        />
      </Card>

      {/* Modal de création/édition de tâche */}
      {showJobModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-3xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {isCreateMode ? 'Créer une nouvelle tâche' : 'Modifier la tâche'}
              </h3>
              <button
                onClick={closeJobModal}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={submitJobForm}>
              <div className="px-6 py-4 max-h-[70vh] overflow-y-auto">
                <div className="space-y-4">
                  {/* Informations de base */}
                  <h4 className="font-medium text-gray-900 dark:text-white">Informations de base</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nom <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={jobForm.name}
                        onChange={(e) => handleFormChange('name', e.target.value)}
                        required
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Description
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={jobForm.description}
                        onChange={(e) => handleFormChange('description', e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Type <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={jobForm.type}
                        onChange={(e) => handleFormChange('type', e.target.value)}
                        required
                      >
                        <option value="SCHEDULED">Planifié (CRON)</option>
                        <option value="RECURRING">Récurrent</option>
                        <option value="TRIGGERED">Déclenché</option>
                        <option value="ONE_TIME">Une seule fois</option>
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Priorité
                      </label>
                      <select
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={jobForm.priority}
                        onChange={(e) => handleFormChange('priority', e.target.value)}
                      >
                        <option value="LOW">Basse</option>
                        <option value="MEDIUM">Moyenne</option>
                        <option value="HIGH">Haute</option>
                        <option value="CRITICAL">Critique</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nombre de tentatives max.
                      </label>
                      <input
                        type="number"
                        min="0"
                        max="10"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={jobForm.maxRetries}
                        onChange={(e) => handleFormChange('maxRetries', parseInt(e.target.value))}
                      />
                    </div>
                  </div>

                  {/* Configuration technique */}
                  <h4 className="font-medium text-gray-900 dark:text-white mt-6">Configuration technique</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Classe <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={jobForm.className}
                        onChange={(e) => handleFormChange('className', e.target.value)}
                        required
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Méthode <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={jobForm.methodName}
                        onChange={(e) => handleFormChange('methodName', e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Timeout (ms)
                    </label>
                    <input
                      type="number"
                      min="1000"
                      step="1000"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={jobForm.timeout}
                      onChange={(e) => handleFormChange('timeout', parseInt(e.target.value))}
                    />
                  </div>

                  {/* Paramètres */}
                  <div className="mt-6">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium text-gray-900 dark:text-white">Paramètres</h4>
                      <Button
                        variant="outline"
                        size="sm"
                        icon={Plus}
                        onClick={addParameter}
                      >
                        Ajouter
                      </Button>
                    </div>
                    
                    {Object.keys(jobForm.parameters).length > 0 ? (
                      <div className="bg-gray-50 dark:bg-gray-800 rounded-md p-3 space-y-2">
                        {Object.entries(jobForm.parameters).map(([key, value]) => (
                          <div key={key} className="flex items-center justify-between p-2 border border-gray-200 dark:border-gray-700 rounded">
                            <div className="flex-1">
                              <span className="font-medium text-gray-700 dark:text-gray-300">{key}:</span>
                              <span className="ml-2 text-gray-600 dark:text-gray-400">{String(value)}</span>
                            </div>
                            <Button
                              variant="text"
                              size="sm"
                              icon={X}
                              onClick={() => removeParameter(key)}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-gray-500 dark:text-gray-400 text-sm">
                        Aucun paramètre. Cliquez sur "Ajouter" pour définir des paramètres.
                      </div>
                    )}
                  </div>

                  {/* Configuration de la planification */}
                  <h4 className="font-medium text-gray-900 dark:text-white mt-6">Planification</h4>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Expression Cron
                        </label>
                        <div className="flex space-x-2">
                          <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={jobForm.schedule.cronExpression}
                            onChange={(e) => handleFormChange('schedule.cronExpression', e.target.value)}
                            placeholder="0 0 * * * ?"
                          />
                          <div className="relative group">
                            <HelpCircle className="w-5 h-5 text-gray-400 cursor-help" />
                            <div className="absolute right-0 bottom-full mb-2 w-72 bg-gray-900 text-white text-xs rounded shadow-lg p-2 z-50 invisible group-hover:visible">
                              Format: secondes minutes heures jour-du-mois mois jour-de-semaine<br />
                              Exemples:<br />
                              - 0 0 * * * ? (Tous les jours à minuit)<br />
                              - 0 0/15 * * * ? (Toutes les 15 minutes)<br />
                              - 0 0 8-18 ? * MON-FRI (En semaine de 8h à 18h)
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Fuseau horaire
                        </label>
                        <select
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={jobForm.schedule.timezone}
                          onChange={(e) => handleFormChange('schedule.timezone', e.target.value)}
                        >
                          <option value="UTC">UTC</option>
                          <option value="Europe/Paris">Europe/Paris</option>
                          <option value="America/New_York">America/New_York</option>
                          <option value="Asia/Tokyo">Asia/Tokyo</option>
                          <option value="Africa/Douala">Africa/Douala</option>
                        </select>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Date de début
                        </label>
                        <input
                          type="datetime-local"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={jobForm.schedule.startDate || ''}
                          onChange={(e) => handleFormChange('schedule.startDate', e.target.value)}
                        />
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Date de fin
                        </label>
                        <input
                          type="datetime-local"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={jobForm.schedule.endDate || ''}
                          onChange={(e) => handleFormChange('schedule.endDate', e.target.value)}
                        />
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Délai fixe (ms)
                        </label>
                        <input
                          type="number"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={jobForm.schedule.fixedDelay || ''}
                          onChange={(e) => handleFormChange('schedule.fixedDelay', e.target.value ? parseInt(e.target.value) : null)}
                          placeholder="Alternative à l'expression cron"
                        />
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Nombre max d'exécutions
                        </label>
                        <input
                          type="number"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={jobForm.schedule.maxExecutions || ''}
                          onChange={(e) => handleFormChange('schedule.maxExecutions', e.target.value ? parseInt(e.target.value) : null)}
                        />
                      </div>
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="pauseOnFailure"
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={jobForm.schedule.pauseOnFailure}
                        onChange={(e) => handleFormChange('schedule.pauseOnFailure', e.target.checked)}
                      />
                      <label htmlFor="pauseOnFailure" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                        Mettre en pause en cas d'échec
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 flex justify-end space-x-2">
                <Button
                  variant="outline"
                  onClick={closeJobModal}
                  type="button"
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  loading={loading}
                >
                  {isCreateMode ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showConfirmDelete && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-md w-full p-6">
            <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-red-100 dark:bg-red-900/20">
              <AlertTriangle className="h-6 w-6 text-red-600" />
            </div>
            <h3 className="text-lg font-medium text-center text-gray-900 dark:text-white mb-4">
              Confirmer la suppression
            </h3>
            <p className="text-center text-gray-500 dark:text-gray-400 mb-6">
              Êtes-vous sûr de vouloir supprimer cette tâche ? Cette action est irréversible.
            </p>
            <div className="flex justify-center space-x-4">
              <Button
                variant="outline"
                onClick={closeConfirmDelete}
              >
                Annuler
              </Button>
              <Button
                variant="danger"
                onClick={deleteJob}
                loading={loading}
              >
                Supprimer
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'affichage des détails d'un job */}
      {selectedJob && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-3xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <Calendar className="h-5 w-5 mr-2 text-blue-500" />
                Détails de la tâche
              </h3>
              <button
                onClick={() => setSelectedJob(null)}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="px-6 py-4 max-h-[70vh] overflow-y-auto">
              <div className="space-y-6">
                {/* En-tête */}
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {selectedJob.name}
                  </h3>
                  {selectedJob.description && (
                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                      {selectedJob.description}
                    </p>
                  )}
                </div>
                
                {/* Informations de base */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-3">
                      Informations de base
                    </h4>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">ID:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedJob.id}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Type:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedJob.type}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Priorité:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedJob.priority}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                        <StatusBadge 
                          status={selectedJob.active ? 'active' : 'inactive'} 
                          customLabel={selectedJob.active ? 'Actif' : 'Inactif'} 
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-3">
                      Configuration technique
                    </h4>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Classe:</span>
                        <span className="font-mono text-sm text-gray-900 dark:text-white">{selectedJob.className}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Méthode:</span>
                        <span className="font-mono text-sm text-gray-900 dark:text-white">{selectedJob.methodName}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Tentatives max:</span>
                        <span className="text-gray-900 dark:text-white">{selectedJob.maxRetries || 0}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Timeout:</span>
                        <span className="text-gray-900 dark:text-white">{selectedJob.timeout ? `${selectedJob.timeout} ms` : 'Non défini'}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Paramètres */}
                {selectedJob.parameters && Object.keys(selectedJob.parameters).length > 0 && (
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-3">
                      Paramètres
                    </h4>
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-md p-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {Object.entries(selectedJob.parameters).map(([key, value]) => (
                          <div key={key} className="flex justify-between">
                            <span className="font-medium text-gray-600 dark:text-gray-300">{key}:</span>
                            <span className="text-gray-800 dark:text-gray-200">{String(value)}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                
                {/* Configuration de planification */}
                {selectedJob.schedule && (
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-3">
                      Planification
                    </h4>
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-md p-4">
                      <div className="space-y-3">
                        {selectedJob.schedule.cronExpression && (
                          <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Expression Cron:</span>
                            <span className="font-mono text-gray-800 dark:text-gray-200">{selectedJob.schedule.cronExpression}</span>
                          </div>
                        )}
                        
                        {selectedJob.schedule.timezone && (
                          <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Fuseau horaire:</span>
                            <span className="text-gray-800 dark:text-gray-200">{selectedJob.schedule.timezone || 'UTC'}</span>
                          </div>
                        )}
                        
                        {selectedJob.schedule.fixedDelay && (
                          <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Délai fixe:</span>
                            <span className="text-gray-800 dark:text-gray-200">{selectedJob.schedule.fixedDelay} ms</span>
                          </div>
                        )}
                        
                        {selectedJob.schedule.startDate && (
                          <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Date de début:</span>
                            <DateFormatter date={selectedJob.schedule.startDate} format="full" showTime />
                          </div>
                        )}
                        
                        {selectedJob.schedule.endDate && (
                          <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Date de fin:</span>
                            <DateFormatter date={selectedJob.schedule.endDate} format="full" showTime />
                          </div>
                        )}
                        
                        {selectedJob.schedule.maxExecutions && (
                          <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Exécutions max:</span>
                            <span className="text-gray-800 dark:text-gray-200">{selectedJob.schedule.maxExecutions}</span>
                          </div>
                        )}
                        
                        <div className="flex justify-between">
                          <span className="text-gray-600 dark:text-gray-300">Pause en cas d'échec:</span>
                          <span className="text-gray-800 dark:text-gray-200">
                            {selectedJob.schedule.pauseOnFailure ? 'Oui' : 'Non'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={() => openEditModal(selectedJob)}
                icon={Edit}
              >
                Modifier
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  triggerJob(selectedJob);
                  setSelectedJob(null);
                }}
                icon={PlayCircle}
              >
                Exécuter maintenant
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Guide d'utilisation */}
      <Card
        title="Guide d'utilisation"
        icon={HelpCircle}
        collapsible={true}
      >
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              Expressions Cron
            </h3>
            <p className="text-gray-700 dark:text-gray-300 mb-2">
              Les expressions Cron permettent de définir des planifications précises et récurrentes. Voici quelques exemples:
            </p>
            <div className="bg-gray-50 dark:bg-gray-800 rounded-md p-4">
              <div className="space-y-3">
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 * * * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Tous les jours à minuit</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 12 * * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Tous les jours à midi</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 15 10 ? * MON-FRI</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">En semaine à 10h15</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0/15 * * * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Toutes les 15 minutes</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 0 1 * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Le premier jour de chaque mois</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 0 ? * MON</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Tous les lundis à minuit</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              Délai fixe vs. Expression Cron
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Vous pouvez choisir entre deux modes de planification:
            </p>
            <ul className="list-disc pl-5 space-y-2 mt-2 text-gray-700 dark:text-gray-300">
              <li>
                <strong>Expression Cron:</strong> Permet de définir des planifications précises et récurrentes basées sur le calendrier.
              </li>
              <li>
                <strong>Délai fixe:</strong> Définit un intervalle constant entre la fin d'une exécution et le début de la suivante, indépendamment de l'heure.
              </li>
            </ul>
          </div>
          
          <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              Conseils pratiques
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-700 dark:text-gray-300">
              <li>
                Définissez des dates de début et de fin pour limiter la période d'activité de la planification.
              </li>
              <li>
                Utilisez un nombre maximum d'exécutions pour les tâches qui doivent s'arrêter après un certain nombre de fois.
              </li>
              <li>
                Activez "Mettre en pause en cas d'échec" pour les tâches critiques afin d'éviter des erreurs répétées.
              </li>
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default JobsPage;