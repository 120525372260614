import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Divider,
  Chip,
  Tooltip,
  CircularProgress,
  Switch,
  FormControlLabel,
  Card,
  CardContent,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar
} from '@mui/material';

import {
  Plus,
  Edit2,
  Trash2,
  Search,
  RefreshCw,
  ChevronDown,
  CheckCircle,
  XCircle,
  Copy,
  Star,
  StarOff,
  Code,
  PenTool,
  FileCode,
  Hash,
  Database,
  Package,
  Link as LinkIcon,  // Renommé l'icône Link en LinkIcon pour éviter le conflit
  Tag,
  Layers,
  Filter,
  Globe,
  BarChart2,
  ExternalLink,
  Settings,
  Info,
  List as ListIcon,
  Grid as GridIcon,
  Calendar,
  Clock,
  X  // Ajout de l'icône X qui était manquante
} from 'lucide-react';

import api from '../../services/api.service';

/**
 * Page de gestion des fonctions d'automatisation
 */
const Functions = () => {
  // États pour les fonctions
  const [functions, setFunctions] = useState([]);
  const [filteredFunctions, setFilteredFunctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [environments, setEnvironments] = useState([]);
  const [functionTypes, setFunctionTypes] = useState([]);
  const [functionDomains, setFunctionDomains] = useState([]);
  
  // États pour les filtres
  const [selectedEnvironment, setSelectedEnvironment] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [domainFilter, setDomainFilter] = useState('all');
  
  // État pour la pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  // État pour le tri
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('functionName');
  
  // États pour les dialogues
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  
  // État pour le formulaire
  const [formValues, setFormValues] = useState({
    functionName: '',
    functionDescription: '',
    functionProgram: '',
    functionType: '',
    functionDomain: '',
    functionModule: '',
    isActive: true,
    isSpecific: false,
    usedByClient: false,
    priority: 3,
    level: 'basic',
    environment: { id: '' }
  });
  
  // État pour l'affichage
  const [viewMode, setViewMode] = useState('table');
  const [activeTab, setActiveTab] = useState(0);
  
  // État pour les messages
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // Extraction des types et domaines uniques des fonctions
  useEffect(() => {
    if (functions.length > 0) {
      const uniqueTypes = [...new Set(functions.map(func => func.functionType).filter(Boolean))];
      const uniqueDomains = [...new Set(functions.map(func => func.functionDomain).filter(Boolean))];
      
      setFunctionTypes(uniqueTypes);
      setFunctionDomains(uniqueDomains);
    }
  }, [functions]);
  
  // Chargement initial des données
  useEffect(() => {
    fetchEnvironments();
    fetchAllFunctions();
  }, []);
  
  // Application des filtres quand les fonctions ou les filtres changent
  useEffect(() => {
    applyFilters();
  }, [functions, selectedEnvironment, searchQuery, statusFilter, typeFilter, domainFilter]);
  
  // Fonction pour charger les environnements
  const fetchEnvironments = async () => {
    try {
      const response = await api.automata.environments.getAll();
      setEnvironments(response);
    } catch (error) {
      console.error('Erreur lors du chargement des environnements:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des environnements',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour charger toutes les fonctions
  const fetchAllFunctions = async () => {
    setLoading(true);
    try {
      const response = await api.automata.functions.getAll();
      setFunctions(response);
    } catch (error) {
      console.error('Erreur lors du chargement des fonctions:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des fonctions',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour charger les fonctions par environnement
  const fetchFunctionsByEnvironment = async (environmentId) => {
    setLoading(true);
    try {
      const response = await api.automata.functions.getAll(environmentId);
      setFunctions(response);
    } catch (error) {
      console.error(`Erreur lors du chargement des fonctions pour l'environnement ${environmentId}:`, error);
      setSnackbar({
        open: true,
        message: `Erreur lors du chargement des fonctions pour l'environnement sélectionné`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour appliquer les filtres
  const applyFilters = () => {
    let filteredData = [...functions];
    
    // Filtre par environnement
    if (selectedEnvironment !== 'all') {
      filteredData = filteredData.filter(
        func => func.environment && func.environment.id === selectedEnvironment
      );
    }
    
    // Filtre par statut
    if (statusFilter === 'active') {
      filteredData = filteredData.filter(func => func.isActive);
    } else if (statusFilter === 'inactive') {
      filteredData = filteredData.filter(func => !func.isActive);
    }
    
    // Filtre par type
    if (typeFilter !== 'all') {
      filteredData = filteredData.filter(func => func.functionType === typeFilter);
    }
    
    // Filtre par domaine
    if (domainFilter !== 'all') {
      filteredData = filteredData.filter(func => func.functionDomain === domainFilter);
    }
    
    // Recherche textuelle
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter(
        func =>
          func.functionName.toLowerCase().includes(lowerQuery) ||
          (func.functionDescription && func.functionDescription.toLowerCase().includes(lowerQuery)) ||
          (func.functionProgram && func.functionProgram.toLowerCase().includes(lowerQuery))
      );
    }
    
    // Appliquer le tri
    filteredData = stableSort(filteredData, getComparator(order, orderBy));
    
    setFilteredFunctions(filteredData);
  };
  
  // Fonctions de tri
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  // Fonctions pour les dialogues
  const handleOpenCreateDialog = () => {
    setFormValues({
      functionName: '',
      functionDescription: '',
      functionProgram: '',
      functionType: '',
      functionDomain: '',
      functionModule: '',
      isActive: true,
      isSpecific: false,
      usedByClient: false,
      priority: 3,
      level: 'basic',
      environment: { id: '' }
    });
    setOpenCreateDialog(true);
  };
  
  const handleOpenEditDialog = (func) => {
    setSelectedFunction(func);
    setFormValues({
      functionName: func.functionName || '',
      functionDescription: func.functionDescription || '',
      functionProgram: func.functionProgram || '',
      functionType: func.functionType || '',
      functionDomain: func.functionDomain || '',
      functionModule: func.functionModule || '',
      isActive: func.isActive ?? true,
      isSpecific: func.isSpecific ?? false,
      usedByClient: func.usedByClient ?? false,
      priority: func.priority || 3,
      level: func.level || 'basic',
      environment: { id: func.environment?.id || '' }
    });
    setOpenEditDialog(true);
  };
  
  const handleOpenDeleteDialog = (func) => {
    setSelectedFunction(func);
    setOpenDeleteDialog(true);
  };
  
  const handleOpenDetailsDrawer = (func) => {
    setSelectedFunction(func);
    setOpenDetailsDrawer(true);
  };
  
  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenDetailsDrawer(false);
    setSelectedFunction(null);
  };
  
  // Fonctions pour les formulaires
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'environmentId') {
      setFormValues({ ...formValues, environment: { id: value } });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  
  const handleBooleanChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };
  
  // Fonction pour créer une fonction
  const handleCreateFunction = async () => {
    try {
      await api.automata.functions.create(formValues);
      setSnackbar({
        open: true,
        message: 'Fonction créée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAllFunctions();
    } catch (error) {
      console.error('Erreur lors de la création de la fonction:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la création de la fonction',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour mettre à jour une fonction
  const handleUpdateFunction = async () => {
    try {
      await api.automata.functions.update(selectedFunction.id, formValues);
      setSnackbar({
        open: true,
        message: 'Fonction mise à jour avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAllFunctions();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la fonction:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la mise à jour de la fonction',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour supprimer une fonction
  const handleDeleteFunction = async () => {
    try {
      await api.automata.functions.delete(selectedFunction.id);
      setSnackbar({
        open: true,
        message: 'Fonction supprimée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAllFunctions();
    } catch (error) {
      console.error('Erreur lors de la suppression de la fonction:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la suppression de la fonction',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour changer d'environnement
  const handleEnvironmentChange = (e) => {
    const value = e.target.value;
    setSelectedEnvironment(value);
    
    if (value === 'all') {
      fetchAllFunctions();
    } else {
      fetchFunctionsByEnvironment(value);
    }
  };
  
  // Rendu du composant
  return (
    <Box sx={{ p: 0, height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils principale */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Code size={24} />
          Gestion des Fonctions
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={handleOpenCreateDialog}
          >
            Nouvelle Fonction
          </Button>
          
          <IconButton 
            color="primary" 
            onClick={fetchAllFunctions}
            title="Rafraîchir"
          >
            <RefreshCw />
          </IconButton>
          
          <IconButton
            color={viewMode === 'table' ? 'primary' : 'default'}
            onClick={() => setViewMode('table')}
            title="Vue tableau"
          >
            <ListIcon />
          </IconButton>
          
          <IconButton
            color={viewMode === 'grid' ? 'primary' : 'default'}
            onClick={() => setViewMode('grid')}
            title="Vue grille"
          >
            <GridIcon />
          </IconButton>
        </Box>
      </Paper>
      
      {/* Filtres et recherche */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant="outlined"
              label="Rechercher"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                )
              }}
              size="small"
            />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Environnement</InputLabel>
              <Select
                value={selectedEnvironment}
                onChange={handleEnvironmentChange}
                label="Environnement"
              >
                <MenuItem value="all">Tous les environnements</MenuItem>
                {environments.map((env) => (
                  <MenuItem key={env.id} value={env.id}>
                    {env.appName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Statut</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Statut"
              >
                <MenuItem value="all">Tous</MenuItem>
                <MenuItem value="active">Actif</MenuItem>
                <MenuItem value="inactive">Inactif</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                label="Type"
              >
                <MenuItem value="all">Tous</MenuItem>
                {functionTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Domaine</InputLabel>
              <Select
                value={domainFilter}
                onChange={(e) => setDomainFilter(e.target.value)}
                label="Domaine"
              >
                <MenuItem value="all">Tous</MenuItem>
                {functionDomains.map((domain) => (
                  <MenuItem key={domain} value={domain}>
                    {domain}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Vue tableau ou grille selon le mode */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Vue tableau */}
          {viewMode === 'table' && (
            <TableContainer component={Paper} sx={{ flex: 1, overflow: 'auto' }}>
              <Table stickyHeader aria-label="tableau des fonctions">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 80 }}>#</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'functionName'}
                        direction={orderBy === 'functionName' ? order : 'asc'}
                        onClick={() => handleRequestSort('functionName')}
                      >
                        Nom
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Domaine</TableCell>
                    <TableCell>Module</TableCell>
                    <TableCell>Programme</TableCell>
                    <TableCell>Environnement</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'priority'}
                        direction={orderBy === 'priority' ? order : 'asc'}
                        onClick={() => handleRequestSort('priority')}
                      >
                        Priorité
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredFunctions.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <Typography variant="body1">Aucune fonction trouvée</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredFunctions.map((func) => (
                      <TableRow 
                        key={func.id}
                        hover
                        onClick={() => handleOpenDetailsDrawer(func)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{func.id}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body2" fontWeight="medium">
                              {func.functionName}
                            </Typography>
                            {func.usedByClient && (
                              <Tooltip title="Utilisé par le client">
                                <Star size={16} color="#FFD700" />
                              </Tooltip>
                            )}
                          </Box>
                          {func.functionDescription && (
                            <Typography variant="caption" color="text.secondary" noWrap>
                              {func.functionDescription}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {func.functionType ? (
                            <Chip 
                              size="small" 
                              label={func.functionType}
                              color="primary"
                              variant="outlined"
                            />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>
                          {func.functionDomain ? (
                            <Chip 
                              size="small" 
                              label={func.functionDomain}
                              color="secondary"
                              variant="outlined"
                            />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>{func.functionModule || '-'}</TableCell>
                        <TableCell>
                          <Tooltip title={func.functionProgram || ''}>
                            <Typography variant="body2" noWrap sx={{ maxWidth: 150 }}>
                              {func.functionProgram || '-'}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {func.environment ? (
                            <Chip 
                              size="small" 
                              label={func.environment.appName}
                              color="info"
                            />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>
                          {typeof func.priority === 'number' ? (
                            <Chip
                              size="small"
                              label={func.priority}
                              color={
                                func.priority <= 2 ? 'success' :
                                func.priority <= 4 ? 'warning' :
                                'error'
                              }
                            />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>
                          {func.isActive ? (
                            <Chip
                              size="small"
                              color="success"
                              icon={<CheckCircle size={14} />}
                              label="Actif"
                            />
                          ) : (
                            <Chip
                              size="small"
                              color="error"
                              icon={<XCircle size={14} />}
                              label="Inactif"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenEditDialog(func);
                              }}
                            >
                              <Edit2 size={18} />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDeleteDialog(func);
                              }}
                            >
                              <Trash2 size={18} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          
          {/* Vue grille */}
          {viewMode === 'grid' && (
            <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
              <Grid container spacing={2}>
                {filteredFunctions.length === 0 ? (
                  <Grid item xs={12}>
                    <Paper sx={{ p: 3, textAlign: 'center' }}>
                      <Typography variant="body1">Aucune fonction trouvée</Typography>
                    </Paper>
                  </Grid>
                ) : (
                  filteredFunctions.map((func) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={func.id}>
                      <Card 
                        sx={{ 
                          height: '100%', 
                          display: 'flex', 
                          flexDirection: 'column',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: 6
                          }
                        }}
                      >
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="h6" component="div" noWrap>
                              {func.functionName}
                            </Typography>
                            {func.isActive ? (
                              <Chip
                                size="small"
                                color="success"
                                icon={<CheckCircle size={14} />}
                                label="Actif"
                              />
                            ) : (
                              <Chip
                                size="small"
                                color="error"
                                icon={<XCircle size={14} />}
                                label="Inactif"
                              />
                            )}
                          </Box>
                          
                          {func.functionDescription && (
                            <Typography 
                              variant="body2" 
                              color="text.secondary" 
                              sx={{ mb: 2, height: 40, overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                              {func.functionDescription}
                            </Typography>
                          )}
                          
                          <Grid container spacing={1} sx={{ mb: 2 }}>
                            {func.functionType && (
                              <Grid item>
                                <Chip 
                                  size="small" 
                                  label={func.functionType}
                                  color="primary"
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            
                            {func.functionDomain && (
                              <Grid item>
                                <Chip 
                                  size="small" 
                                  label={func.functionDomain}
                                  color="secondary"
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            
                            {func.environment && (
                              <Grid item>
                                <Chip 
                                  size="small" 
                                  label={func.environment.appName}
                                  color="info"
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                          </Grid>
                          
                          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                              <FileCode size={16} />
                              <Typography variant="caption">
                                {func.functionProgram || '-'}
                              </Typography>
                            </Box>
                            
                            {func.functionModule && (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <Package size={16} />
                                <Typography variant="caption">
                                  {func.functionModule}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </CardContent>
                        
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          px: 2,
                          py: 1,
                          borderTop: 1,
                          borderColor: 'divider'
                        }}>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => handleOpenEditDialog(func)}
                            >
                              <Edit2 size={18} />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => handleOpenDeleteDialog(func)}
                            >
                              <Trash2 size={18} />
                            </IconButton>
                          </Box>
                          
                          <Button
                            variant="text"
                            size="small"
                            endIcon={<Info size={16} />}
                            onClick={() => handleOpenDetailsDrawer(func)}
                          >
                            Détails
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </Box>
          )}
        </>
      )}
      
      {/* Dialogue de création */}
      <Dialog 
        open={openCreateDialog} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Plus size={20} />
            Créer une nouvelle fonction
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Tabs 
              value={activeTab} 
              onChange={(e, newValue) => setActiveTab(newValue)}
              sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Informations de base" />
              <Tab label="Propriétés avancées" />
            </Tabs>
            
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Nom de la fonction"
                    name="functionName"
                    value={formValues.functionName}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Environnement</InputLabel>
                    <Select
                      name="environmentId"
                      value={formValues.environment.id}
                      onChange={handleInputChange}
                      label="Environnement"
                    >
                      {environments.map((env) => (
                        <MenuItem key={env.id} value={env.id}>
                          {env.appName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="functionDescription"
                    value={formValues.functionDescription}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Programme"
                    name="functionProgram"
                    value={formValues.functionProgram}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Module"
                    name="functionModule"
                    value={formValues.functionModule}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Type de fonction"
                    name="functionType"
                    value={formValues.functionType}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Domaine de fonction"
                    name="functionDomain"
                    value={formValues.functionDomain}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            )}
            
            {activeTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Priorité</InputLabel>
                    <Select
                      name="priority"
                      value={formValues.priority}
                      onChange={handleInputChange}
                      label="Priorité"
                    >
                      <MenuItem value={1}>1 - Très basse</MenuItem>
                      <MenuItem value={2}>2 - Basse</MenuItem>
                      <MenuItem value={3}>3 - Moyenne</MenuItem>
                      <MenuItem value={4}>4 - Haute</MenuItem>
                      <MenuItem value={5}>5 - Très haute</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Niveau</InputLabel>
                    <Select
                      name="level"
                      value={formValues.level}
                      onChange={handleInputChange}
                      label="Niveau"
                    >
                      <MenuItem value="basic">Basique</MenuItem>
                      <MenuItem value="intermediate">Intermédiaire</MenuItem>
                      <MenuItem value="advanced">Avancé</MenuItem>
                      <MenuItem value="expert">Expert</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.isActive}
                          onChange={handleBooleanChange}
                          name="isActive"
                        />
                      }
                      label="Actif"
                    />
                    
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.isSpecific}
                          onChange={handleBooleanChange}
                          name="isSpecific"
                        />
                      }
                      label="Spécifique"
                    />
                    
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.usedByClient}
                          onChange={handleBooleanChange}
                          name="usedByClient"
                        />
                      }
                      label="Utilisé par le client"
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateFunction}
            disabled={!formValues.functionName || !formValues.environment.id}
          >
            Créer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'édition */}
      <Dialog 
        open={openEditDialog} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Edit2 size={20} />
            Modifier la fonction
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Tabs 
              value={activeTab} 
              onChange={(e, newValue) => setActiveTab(newValue)}
              sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Informations de base" />
              <Tab label="Propriétés avancées" />
            </Tabs>
            
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Nom de la fonction"
                    name="functionName"
                    value={formValues.functionName}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Environnement</InputLabel>
                    <Select
                      name="environmentId"
                      value={formValues.environment.id}
                      onChange={handleInputChange}
                      label="Environnement"
                    >
                      {environments.map((env) => (
                        <MenuItem key={env.id} value={env.id}>
                          {env.appName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="functionDescription"
                    value={formValues.functionDescription}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Programme"
                    name="functionProgram"
                    value={formValues.functionProgram}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Module"
                    name="functionModule"
                    value={formValues.functionModule}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Type de fonction"
                    name="functionType"
                    value={formValues.functionType}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Domaine de fonction"
                    name="functionDomain"
                    value={formValues.functionDomain}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            )}
            
            {activeTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Priorité</InputLabel>
                    <Select
                      name="priority"
                      value={formValues.priority}
                      onChange={handleInputChange}
                      label="Priorité"
                    >
                      <MenuItem value={1}>1 - Très basse</MenuItem>
                      <MenuItem value={2}>2 - Basse</MenuItem>
                      <MenuItem value={3}>3 - Moyenne</MenuItem>
                      <MenuItem value={4}>4 - Haute</MenuItem>
                      <MenuItem value={5}>5 - Très haute</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Niveau</InputLabel>
                    <Select
                      name="level"
                      value={formValues.level}
                      onChange={handleInputChange}
                      label="Niveau"
                    >
                      <MenuItem value="basic">Basique</MenuItem>
                      <MenuItem value="intermediate">Intermédiaire</MenuItem>
                      <MenuItem value="advanced">Avancé</MenuItem>
                      <MenuItem value="expert">Expert</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.isActive}
                          onChange={handleBooleanChange}
                          name="isActive"
                        />
                      }
                      label="Actif"
                    />
                    
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.isSpecific}
                          onChange={handleBooleanChange}
                          name="isSpecific"
                        />
                      }
                      label="Spécifique"
                    />
                    
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.usedByClient}
                          onChange={handleBooleanChange}
                          name="usedByClient"
                        />
                      }
                      label="Utilisé par le client"
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUpdateFunction}
            disabled={!formValues.functionName || !formValues.environment.id}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Êtes-vous sûr de vouloir supprimer la fonction "{selectedFunction?.functionName}" ?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Cette action est irréversible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button variant="contained" color="error" onClick={handleDeleteFunction}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Tiroir de détails */}
      <Drawer
        anchor="right"
        open={openDetailsDrawer}
        onClose={handleCloseDialog}
        sx={{
          width: 450,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 450,
          },
        }}
      >
        {selectedFunction && (
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5">Détails de la fonction</Typography>
              <IconButton onClick={handleCloseDialog}>
                <X size={20} />
              </IconButton>
            </Box>
            
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>{selectedFunction.functionName}</Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                {selectedFunction.functionDescription || 'Aucune description disponible'}
              </Typography>
              
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="caption" color="text.secondary">ID</Typography>
                  <Typography variant="body2">{selectedFunction.id}</Typography>
                </Grid>
                
                {selectedFunction.md5Key && (
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary">Clé MD5</Typography>
                    <Typography variant="body2">{selectedFunction.md5Key}</Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Détails techniques</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Programme</Typography>
                <Typography variant="body2">{selectedFunction.functionProgram || '-'}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Module</Typography>
                <Typography variant="body2">{selectedFunction.functionModule || '-'}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Type</Typography>
                <Typography variant="body2">{selectedFunction.functionType || '-'}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Domaine</Typography>
                <Typography variant="body2">{selectedFunction.functionDomain || '-'}</Typography>
              </Grid>
            </Grid>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Attributs</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Priorité</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip
                    size="small"
                    label={selectedFunction.priority || '-'}
                    color={
                      selectedFunction.priority <= 2 ? 'success' :
                      selectedFunction.priority <= 4 ? 'warning' :
                      'error'
                    }
                  />
                </Box>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Niveau</Typography>
                <Typography variant="body2">{selectedFunction.level || '-'}</Typography>
              </Grid>
              
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedFunction.isActive}
                      disabled
                    />
                  }
                  label="Actif"
                />
              </Grid>
              
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedFunction.isSpecific}
                      disabled
                    />
                  }
                  label="Spécifique"
                />
              </Grid>
              
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedFunction.usedByClient}
                      disabled
                    />
                  }
                  label="Utilisé par client"
                />
              </Grid>
            </Grid>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Environnement</Typography>
            
            {selectedFunction.environment ? (
              <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1, border: 1, borderColor: 'divider' }}>
                <Typography variant="subtitle2">{selectedFunction.environment.appName}</Typography>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary">Type</Typography>
                    <Typography variant="body2">{selectedFunction.environment.appType || '-'}</Typography>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary">Version</Typography>
                    <Typography variant="body2">{selectedFunction.environment.appVersion || '-'}</Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Aucun environnement associé
              </Typography>
            )}
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Métadonnées</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Créé par</Typography>
                <Typography variant="body2">{selectedFunction.createdBy || '-'}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Modifié par</Typography>
                <Typography variant="body2">{selectedFunction.modifiedBy || '-'}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Date de création</Typography>
                <Typography variant="body2">
                  {selectedFunction.createdDate 
                    ? new Date(selectedFunction.createdDate).toLocaleString()
                    : '-'}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Date de modification</Typography>
                <Typography variant="body2">
                  {selectedFunction.modifiedDate 
                    ? new Date(selectedFunction.modifiedDate).toLocaleString()
                    : '-'}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Dernière exécution</Typography>
                <Typography variant="body2">
                  {selectedFunction.lastExecutionDate 
                    ? new Date(selectedFunction.lastExecutionDate).toLocaleString()
                    : 'Jamais'}
                </Typography>
              </Grid>
            </Grid>
            
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<Edit2 />}
                onClick={() => {
                  handleCloseDialog();
                  handleOpenEditDialog(selectedFunction);
                }}
              >
                Modifier
              </Button>
              
              <Button
                variant="contained"
                color="primary"
                component={CustomLink}
                to={`/automata/tasks?functionId=${selectedFunction.id}`}
                endIcon={<ExternalLink size={16} />}
              >
                Voir les tâches
              </Button>
            </Box>
          </Box>
        )}
      </Drawer>
      
      {/* Snackbar pour les messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

// Ajout du composant Link manquant pour la démo - RENOMMÉ pour éviter le conflit
const CustomLink = ({ to, children, ...props }) => {
  return (
    <Button
      {...props}
      onClick={() => console.log(`Navigation vers: ${to}`)}
    >
      {children}
    </Button>
  );
};

export default Functions;