import React, { useState, useEffect, useRef } from 'react';
import { 
  Scan, 
  FileText, 
  Camera, 
  Upload, 
  RefreshCw, 
  Check, 
  AlertTriangle,
  Edit,
  Trash2,
  Plus,
  List,
  Grid,
  Send,
  Save,
  X
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const ChequeScanning = () => {
  // États pour la gestion des chèques
  const [scanMode, setScanMode] = useState('single'); // 'single' ou 'batch'
  const [scannedCheques, setScannedCheques] = useState([]);
  const [currentLot, setCurrentLot] = useState(null);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'list'
  
  // États pour le traitement d'un chèque
  const [scanningInProgress, setScanningInProgress] = useState(false);
  const [currentScannedCheque, setCurrentScannedCheque] = useState(null);
  const [selectedCheque, setSelectedCheque] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  
  // États UI
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // Référence au scanner virtuel
  const scannerRef = useRef(null);
  
  // Charger les données initiales au démarrage
  useEffect(() => {
    loadCurrentLot();
  }, []);
  
  // Charger le lot en cours de constitution
  const loadCurrentLot = async () => {
    setLoading(true);
    try {
      // Simulation de l'appel API
      const response = await new Promise(resolve => {
        setTimeout(() => {
          resolve({
            data: {
              lotId: "LOT" + new Date().toISOString().split('T')[0].replace(/-/g, ''),
              dateCreation: new Date().toISOString(),
              status: "EN_COURS",
              chequesCount: 0,
              cheques: []
            }
          });
        }, 800);
      });
      
      setCurrentLot(response.data);
      setScannedCheques(response.data.cheques);
    } catch (error) {
      setError("Impossible de charger le lot en cours. Veuillez réessayer.");
      console.error("Erreur chargement du lot:", error);
    } finally {
      setLoading(false);
    }
  };
  
  // Simuler un scan de chèque
  const handleScanCheque = async () => {
    setScanningInProgress(true);
    
    try {
      // Simuler le temps de scan
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Simule un chèque scanné avec reconnaissance OCR
      const scannedCheque = {
        id: `CHQ${Date.now()}`,
        reference: `REF${Math.floor(Math.random() * 10000)}`,
        montant: Math.floor(Math.random() * 1000000) + 50000,
        devise: "XAF",
        dateCheque: new Date().toISOString(),
        banqueEmettrice: ["BEAC", "AFRILAND", "SGBC", "UBA"][Math.floor(Math.random() * 4)],
        beneficiaire: "ACME Corporation",
        compteBeneficiaire: "CM21 00010 01010 0000098765X",
        tireur: `Client ${Math.floor(Math.random() * 100)}`,
        compteTireur: `CM21 0001${Math.floor(Math.random() * 10000)}`,
        cmc7: `${Math.floor(Math.random() * 10000000000)}`,
        imageRecto: "/api/placeholder/400/250",
        imageVerso: "/api/placeholder/400/250",
        recognitionConfidence: Math.random() * 50 + 50,
        status: "SCANNE"
      };
      
      setCurrentScannedCheque(scannedCheque);
      
      // En mode lot, ajouter directement au lot
      if (scanMode === 'batch') {
        addChequeToLot(scannedCheque);
        setSuccessMessage("Chèque ajouté au lot avec succès.");
        setTimeout(() => setSuccessMessage(null), 3000);
        
        // Prêt pour le prochain scan
        setTimeout(() => {
          setCurrentScannedCheque(null);
        }, 1500);
      }
      
    } catch (error) {
      setError("Erreur lors du scan. Veuillez réessayer.");
      console.error("Erreur scan:", error);
    } finally {
      setScanningInProgress(false);
    }
  };
  
  // Ajouter un chèque scanné au lot
  const addChequeToLot = (cheque) => {
    const updatedCheques = [...scannedCheques, cheque];
    setScannedCheques(updatedCheques);
    setCurrentLot({
      ...currentLot,
      chequesCount: updatedCheques.length,
      cheques: updatedCheques
    });
  };
  
  // Confirmer l'ajout d'un chèque en mode unitaire
  const confirmAddCheque = () => {
    if (!currentScannedCheque) return;
    
    addChequeToLot(currentScannedCheque);
    setCurrentScannedCheque(null);
    setSuccessMessage("Chèque ajouté au lot avec succès.");
    setTimeout(() => setSuccessMessage(null), 3000);
  };
  
  // Rejeter un scan en mode unitaire
  const rejectScan = () => {
    setCurrentScannedCheque(null);
    setError("Scan rejeté. Veuillez réessayer.");
    setTimeout(() => setError(null), 3000);
  };
  
  // Supprimer un chèque du lot
  const removeChequeFromLot = (chequeId) => {
    const updatedCheques = scannedCheques.filter(c => c.id !== chequeId);
    setScannedCheques(updatedCheques);
    setCurrentLot({
      ...currentLot,
      chequesCount: updatedCheques.length,
      cheques: updatedCheques
    });
    setSuccessMessage("Chèque retiré du lot.");
    setTimeout(() => setSuccessMessage(null), 3000);
  };
  
  // Modifier les données d'un chèque
  const editCheque = (cheque) => {
    setSelectedCheque(cheque);
    setShowEditModal(true);
  };
  
  // Sauvegarder les modifications d'un chèque
  const saveEditedCheque = (updatedCheque) => {
    const updatedCheques = scannedCheques.map(c => 
      c.id === updatedCheque.id ? updatedCheque : c
    );
    setScannedCheques(updatedCheques);
    setCurrentLot({
      ...currentLot,
      cheques: updatedCheques
    });
    setShowEditModal(false);
    setSelectedCheque(null);
    setSuccessMessage("Modifications enregistrées.");
    setTimeout(() => setSuccessMessage(null), 3000);
  };
  
  // Soumettre le lot complet à la compensation
  const submitLot = async () => {
    if (scannedCheques.length === 0) {
      setError("Impossible de soumettre un lot vide.");
      return;
    }
    
    setLoading(true);
    try {
      // Simulation d'envoi du lot
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setSuccessMessage(`Lot ${currentLot.lotId} soumis avec succès (${scannedCheques.length} chèques).`);
      setTimeout(() => {
        setSuccessMessage(null);
        // Réinitialiser pour un nouveau lot
        loadCurrentLot();
      }, 3000);
    } catch (error) {
      setError("Erreur lors de la soumission du lot. Veuillez réessayer.");
      console.error("Erreur soumission:", error);
    } finally {
      setLoading(false);
    }
  };
  
  // Rendu de l'interface
  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Scan className="h-6 w-6 mr-2 text-blue-500" />
            Scannarisation des chèques
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Numérisez des chèques et constituez des lots pour la compensation
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline" 
            icon={RefreshCw}
            onClick={loadCurrentLot}
            loading={loading}
          >
            Actualiser
          </Button>
          
          <Button
            variant={viewMode === 'grid' ? 'primary' : 'outline'}
            icon={Grid}
            onClick={() => setViewMode('grid')}
          >
            Grille
          </Button>
          
          <Button
            variant={viewMode === 'list' ? 'primary' : 'outline'}
            icon={List}
            onClick={() => setViewMode('list')}
          >
            Liste
          </Button>
        </div>
      </div>
      
      {/* Messages de succès et d'erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Section principale divisée en deux */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Section de scan */}
        <div className="lg:col-span-1">
          <Card title="Scanner un chèque" icon={Camera}>
            <div className="space-y-4">
              {/* Sélection du mode de scan */}
              <div className="flex rounded-md overflow-hidden border border-gray-300 dark:border-gray-600">
                <button
                  className={`flex-1 py-2 px-4 text-sm font-medium ${
                    scanMode === 'single' 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => setScanMode('single')}
                >
                  Chèque unitaire
                </button>
                <button
                  className={`flex-1 py-2 px-4 text-sm font-medium ${
                    scanMode === 'batch' 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => setScanMode('batch')}
                >
                  Lot de chèques
                </button>
              </div>
              
              {/* Zone de scan */}
              <div 
                className={`
                  border-2 border-dashed rounded-lg p-6 text-center
                  ${scanningInProgress ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/10' : 'border-gray-300 dark:border-gray-700'}
                `}
              >
                {scanningInProgress ? (
                  <div className="space-y-3">
                    <div className="animate-pulse flex justify-center">
                      <Scan className="h-12 w-12 text-blue-500" />
                    </div>
                    <p className="text-blue-600 dark:text-blue-400">Scan en cours...</p>
                  </div>
                ) : currentScannedCheque ? (
                  <div className="space-y-4">
                    <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                      <img
                        src={currentScannedCheque.imageRecto}
                        alt="Chèque scanné"
                        className="mx-auto border border-gray-300 dark:border-gray-600 rounded"
                      />
                    </div>
                    <div className="text-left space-y-2">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Montant détecté:</p>
                        <p className="text-lg font-semibold text-gray-900 dark:text-white">
                          {new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: currentScannedCheque.devise,
                            minimumFractionDigits: 0
                          }).format(currentScannedCheque.montant)}
                        </p>
                      </div>
                      <div className="flex space-x-1">
                        <StatusBadge
                          status={currentScannedCheque.recognitionConfidence > 80 ? 'active' : 'warning'}
                          customLabel={`Confiance OCR: ${Math.round(currentScannedCheque.recognitionConfidence)}%`}
                          size="sm"
                        />
                      </div>
                    </div>
                    
                    {scanMode === 'single' && (
                      <div className="flex space-x-2 justify-center">
                        <Button
                          variant="outline"
                          size="sm"
                          icon={Trash2}
                          onClick={rejectScan}
                        >
                          Rejeter
                        </Button>
                        <Button
                          variant="primary"
                          size="sm"
                          icon={Check}
                          onClick={confirmAddCheque}
                        >
                          Confirmer
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="space-y-3">
                    <Scan className="h-12 w-12 text-gray-400 mx-auto" />
                    <p className="text-gray-500 dark:text-gray-400">
                      {scanMode === 'single' 
                        ? 'Cliquez sur Scanner pour numériser un chèque' 
                        : 'Placez le lot de chèques dans le scanner'
                      }
                    </p>
                    <Button
                      variant="primary"
                      icon={Camera}
                      onClick={handleScanCheque}
                      disabled={scanningInProgress}
                    >
                      Scanner
                    </Button>
                  </div>
                )}
              </div>
              
              {/* Informations sur le lot */}
              {currentLot && (
                <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                  <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Lot en cours
                  </h3>
                  <div className="space-y-1">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500 dark:text-gray-400">Référence:</span>
                      <span className="text-sm font-medium text-gray-900 dark:text-white">{currentLot.lotId}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500 dark:text-gray-400">Nombre de chèques:</span>
                      <span className="text-sm font-medium text-gray-900 dark:text-white">{currentLot.chequesCount}</span>
                    </div>
                  </div>
                  
                  <div className="mt-4">
                    <Button
                      variant="primary"
                      icon={Send}
                      onClick={submitLot}
                      disabled={scannedCheques.length === 0 || loading}
                      className="w-full"
                    >
                      Soumettre le lot
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
        
        {/* Section des chèques scannés */}
        <div className="lg:col-span-2">
          <Card 
            title="Chèques scannés" 
            icon={FileText}
            subtitle={scannedCheques.length > 0 ? `${scannedCheques.length} chèque(s) dans le lot` : "Aucun chèque scanné"}
          >
            {scannedCheques.length === 0 ? (
              <div className="py-12 text-center">
                <FileText className="h-12 w-12 text-gray-300 dark:text-gray-700 mx-auto mb-3" />
                <p className="text-gray-500 dark:text-gray-400">
                  Aucun chèque scanné dans le lot actuel
                </p>
              </div>
            ) : viewMode === 'grid' ? (
              // Vue en grille
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {scannedCheques.map((cheque) => (
                  <div 
                    key={cheque.id}
                    className="border rounded-lg overflow-hidden bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    <div className="aspect-video bg-gray-100 dark:bg-gray-700 relative">
                      <img
                        src={cheque.imageRecto}
                        alt={`Chèque ${cheque.reference}`}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute top-2 right-2 flex space-x-1">
                        <Button
                          variant="outline"
                          size="sm"
                          icon={Edit}
                          onClick={() => editCheque(cheque)}
                          className="bg-white/80 hover:bg-white"
                        />
                        <Button
                          variant="outline"
                          size="sm"
                          icon={Trash2}
                          onClick={() => removeChequeFromLot(cheque.id)}
                          className="bg-white/80 hover:bg-white text-red-500"
                        />
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="flex justify-between items-center mb-1">
                        <span className="font-medium text-sm text-blue-600 dark:text-blue-400">{cheque.reference}</span>
                        <StatusBadge
                          status={'active'}
                          customLabel={cheque.status}
                          size="sm"
                        />
                      </div>
                      <p className="text-gray-500 dark:text-gray-400 text-xs mb-1">{cheque.banqueEmettrice}</p>
                      <p className="font-medium text-gray-900 dark:text-white">
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: cheque.devise,
                          minimumFractionDigits: 0
                        }).format(cheque.montant)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              // Vue en liste
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-800">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Référence
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Montant
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Banque
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tireur
                      </th>
                      <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    {scannedCheques.map((cheque) => (
                      <tr key={cheque.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="font-medium text-blue-600 dark:text-blue-400">{cheque.reference}</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="font-medium">
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: cheque.devise,
                              minimumFractionDigits: 0
                            }).format(cheque.montant)}
                          </div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {cheque.banqueEmettrice}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {cheque.tireur}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-right">
                          <div className="flex justify-end space-x-1">
                            <Button
                              variant="outline"
                              size="sm"
                              icon={Edit}
                              onClick={() => editCheque(cheque)}
                            />
                            <Button
                              variant="outline"
                              size="sm"
                              icon={Trash2}
                              onClick={() => removeChequeFromLot(cheque.id)}
                              className="text-red-500"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Card>
        </div>
      </div>
      
      {/* Modal d'édition d'un chèque */}
      {showEditModal && selectedCheque && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  <Edit className="w-5 h-5 mr-2 text-blue-500" />
                  Éditer le chèque
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowEditModal(false)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Référence
                    </label>
                    <input
                      type="text"
                      value={selectedCheque.reference}
                      onChange={(e) => setSelectedCheque({...selectedCheque, reference: e.target.value})}
                      className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Montant
                    </label>
                    <input
                      type="number"
                      value={selectedCheque.montant}
                      onChange={(e) => setSelectedCheque({...selectedCheque, montant: Number(e.target.value)})}
                      className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Banque
                    </label>
                    <input
                      type="text"
                      value={selectedCheque.banqueEmettrice}
                      onChange={(e) => setSelectedCheque({...selectedCheque, banqueEmettrice: e.target.value})}
                      className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Date chèque
                    </label>
                    <input
                      type="date"
                      value={selectedCheque.dateCheque.split('T')[0]}
                      onChange={(e) => setSelectedCheque({...selectedCheque, dateCheque: e.target.value})}
                      className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Tireur
                  </label>
                  <input
                    type="text"
                    value={selectedCheque.tireur}
                    onChange={(e) => setSelectedCheque({...selectedCheque, tireur: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Compte tireur
                  </label>
                  <input
                    type="text"
                    value={selectedCheque.compteTireur}
                    onChange={(e) => setSelectedCheque({...selectedCheque, compteTireur: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Ligne CMC7
                  </label>
                  <input
                    type="text"
                    value={selectedCheque.cmc7}
                    onChange={(e) => setSelectedCheque({...selectedCheque, cmc7: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Bénéficiaire
                    </label>
                    <input
                      type="text"
                      value={selectedCheque.beneficiaire}
                      onChange={(e) => setSelectedCheque({...selectedCheque, beneficiaire: e.target.value})}
                      className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Compte bénéficiaire
                    </label>
                    <input
                      type="text"
                      value={selectedCheque.compteBeneficiaire}
                      onChange={(e) => setSelectedCheque({...selectedCheque, compteBeneficiaire: e.target.value})}
                      className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                
                <div className="flex justify-end space-x-3">
                  <Button
                    variant="outline"
                    onClick={() => setShowEditModal(false)}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="primary"
                    icon={Save}
                    onClick={() => saveEditedCheque(selectedCheque)}
                  >
                    Sauvegarder
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChequeScanning;