import React, { useState, useEffect, useRef } from 'react';
import { 
  FileText, 
  Plus, 
  Upload, 
  Download, 
  Search, 
  Filter, 
  RefreshCw, 
  Calendar,
  ChevronDown, 
  CheckCircle, 
  XCircle, 
  AlertTriangle, 
  Check, 
  X, 
  Eye, 
  Clock, 
  Image, 
  FilePen,
  PenLine,
  FileBarChart2,
  ArrowUpDown,
  Printer,
  TabletSmartphone,
  CheckSquare
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import DataGrid from '../../components/shared/DataGrid';
import apiService from '../../services/api.service';

/**
 * Composant pour le traitement des chèques émis et reçus
 */
const ChequeProcessing = () => {
  // États pour la gestion des onglets
  const [activeTab, setActiveTab] = useState('received'); // 'received' ou 'sent'
  
  // États pour les chèques
  const [chequesEmis, setChequesEmis] = useState([]);
  const [chequesRecus, setChequesRecus] = useState([]);
  const [selectedCheque, setSelectedCheque] = useState(null);
  const [selectedCheques, setSelectedCheques] = useState([]);
  
  // États pour les actions spécifiques
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  
  // États pour les filtres
  const [filteredChequesEmis, setFilteredChequesEmis] = useState([]);
  const [filteredChequesRecus, setFilteredChequesRecus] = useState([]);
  const [emitSearchTerm, setEmitSearchTerm] = useState('');
  const [receivedSearchTerm, setReceivedSearchTerm] = useState('');
  const [emitStatusFilter, setEmitStatusFilter] = useState('all');
  const [receivedStatusFilter, setReceivedStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState({
    start: null,
    end: null
  });
  const [showFilters, setShowFilters] = useState(false);
  
  // États UI
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [statsVisible, setStatsVisible] = useState(true);
  const [remiseStats, setRemiseStats] = useState({
    totalEmis: 0,
    totalRecus: 0,
    montantEmis: 0,
    montantRecus: 0,
    enCours: 0,
    traites: 0,
    rejetes: 0
  });
  
  // Références pour les fichiers
  const fileInputRef = useRef(null);
  
  // Initialisation du composant
  useEffect(() => {
    fetchData();
  }, []);
  
  // Mettre à jour les filtres lorsque les données changent
  useEffect(() => {
    applyFilters();
  }, [
    chequesEmis, 
    chequesRecus, 
    emitSearchTerm, 
    receivedSearchTerm, 
    emitStatusFilter, 
    receivedStatusFilter, 
    dateFilter
  ]);
  
  // Mettre à jour les statistiques lorsque les données changent
  useEffect(() => {
    updateStats();
  }, [chequesEmis, chequesRecus]);
  
  // Fonction pour charger les données
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Chargement des chèques émis
      let emisResponse;
      try {
        emisResponse = await apiService.cheques.getEmitCheques();
      } catch (apiError) {
        console.warn("API non disponible, utilisation des données simulées:", apiError);
        
        // Données simulées pour les chèques émis
        const mockChequesEmis = [
          {
            id: "che-001",
            reference: "CHE20240315001",
            montant: 450000,
            devise: "XAF",
            datePresentaion: new Date(Date.now() - 2 * 86400000).toISOString(),
            banqueDestination: "BEAC",
            beneficiaire: "Entreprise Alpha",
            compteBeneficiaire: "CM21 00010 01010 0000012345M",
            motif: "Paiement facture #F0012345",
            status: "VALIDE",
            imagePath: "/cheques/CHE20240315001.jpg",
            remiseId: "REM20240315001"
          },
          {
            id: "che-002",
            reference: "CHE20240315002",
            montant: 275000,
            devise: "XAF",
            datePresentaion: new Date(Date.now() - 2 * 86400000).toISOString(),
            banqueDestination: "AFRILAND",
            beneficiaire: "Société Beta",
            compteBeneficiaire: "CM21 00020 01010 0000067890X",
            motif: "Paiement services",
            status: "EN_COURS",
            imagePath: "/cheques/CHE20240315002.jpg",
            remiseId: "REM20240315001"
          },
          {
            id: "che-003",
            reference: "CHE20240314001",
            montant: 780000,
            devise: "XAF",
            datePresentaion: new Date(Date.now() - 3 * 86400000).toISOString(),
            banqueDestination: "SGBC",
            beneficiaire: "Entreprise Gamma",
            compteBeneficiaire: "CM21 00030 01010 0000054321Z",
            motif: "Règlement commande #C765",
            status: "TRANSMIS",
            imagePath: "/cheques/CHE20240314001.jpg",
            remiseId: "REM20240314001"
          },
          {
            id: "che-004",
            reference: "CHE20240312001",
            montant: 125000,
            devise: "XAF",
            datePresentaion: new Date(Date.now() - 5 * 86400000).toISOString(),
            banqueDestination: "UBA",
            beneficiaire: "Consultant Delta",
            compteBeneficiaire: "CM21 00040 01010 0000098765Y",
            motif: "Honoraires conseil",
            status: "REJETE",
            motifRejet: "Signature non conforme",
            imagePath: "/cheques/CHE20240312001.jpg",
            remiseId: "REM20240312001"
          }
        ];
        
        emisResponse = { data: mockChequesEmis };
      }
      
      setChequesEmis(emisResponse.data);
      
      // Chargement des chèques reçus
      let recusResponse;
      try {
        recusResponse = await apiService.cheques.getReceivedCheques();
      } catch (apiError) {
        console.warn("API non disponible, utilisation des données simulées:", apiError);
        
        // Données simulées pour les chèques reçus
        const mockChequesRecus = [
          {
            id: "chr-001",
            reference: "CHR20240315001",
            montant: 350000,
            devise: "XAF",
            dateReception: new Date(Date.now() - 1 * 86400000).toISOString(),
            banqueEmettrice: "AFRILAND",
            tireur: "Entreprise Omega",
            compteTireur: "CM21 00020 01010 0000054321Z",
            beneficiaire: "ACME Corporation",
            compteBeneficiaire: "CM21 00010 01010 0000098765X",
            status: "RECU",
            verificationStatus: "NON_VERIFIE",
            imagePath: "/cheques/CHR20240315001.jpg",
            lotId: "LOT20240315001"
          },
          {
            id: "chr-002",
            reference: "CHR20240315002",
            montant: 820000,
            devise: "XAF",
            dateReception: new Date(Date.now() - 1 * 86400000).toISOString(),
            banqueEmettrice: "SGBC",
            tireur: "Entreprise Sigma",
            compteTireur: "CM21 00030 01010 0000012345Z",
            beneficiaire: "ACME Corporation",
            compteBeneficiaire: "CM21 00010 01010 0000098765X",
            status: "EN_VERIFICATION",
            verificationStatus: "EN_COURS",
            imagePath: "/cheques/CHR20240315002.jpg",
            lotId: "LOT20240315001"
          },
          {
            id: "chr-003",
            reference: "CHR20240314001",
            montant: 650000,
            devise: "XAF",
            dateReception: new Date(Date.now() - 2 * 86400000).toISOString(),
            banqueEmettrice: "UBA",
            tireur: "Consultant Epsilon",
            compteTireur: "CM21 00040 01010 0000023456Y",
            beneficiaire: "ACME Corporation",
            compteBeneficiaire: "CM21 00010 01010 0000098765X",
            status: "VALIDE",
            verificationStatus: "VALIDE",
            imagePath: "/cheques/CHR20240314001.jpg",
            lotId: "LOT20240314001"
          },
          {
            id: "chr-004",
            reference: "CHR20240313001",
            montant: 175000,
            devise: "XAF",
            dateReception: new Date(Date.now() - 3 * 86400000).toISOString(),
            banqueEmettrice: "BEAC",
            tireur: "Société Zeta",
            compteTireur: "CM21 00010 01010 0000045678M",
            beneficiaire: "ACME Corporation",
            compteBeneficiaire: "CM21 00010 01010 0000098765X",
            status: "REJETE",
            verificationStatus: "REJETE",
            motifRejet: "Provision insuffisante",
            imagePath: "/cheques/CHR20240313001.jpg",
            lotId: "LOT20240313001"
          }
        ];
        
        recusResponse = { data: mockChequesRecus };
      }
      
      setChequesRecus(recusResponse.data);
    } catch (err) {
      console.error("Erreur lors du chargement des données:", err);
      setError("Une erreur s'est produite lors du chargement des données. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };
  
  // Appliquer les filtres de recherche
  const applyFilters = () => {
    // Filtres pour les chèques émis
    let filteredEmis = [...chequesEmis];
    
    if (emitSearchTerm.trim() !== '') {
      const searchLower = emitSearchTerm.toLowerCase();
      filteredEmis = filteredEmis.filter(c => 
        c.reference?.toLowerCase().includes(searchLower) ||
        c.beneficiaire?.toLowerCase().includes(searchLower) ||
        c.compteBeneficiaire?.toLowerCase().includes(searchLower) ||
        c.banqueDestination?.toLowerCase().includes(searchLower) ||
        c.motif?.toLowerCase().includes(searchLower)
      );
    }
    
    if (emitStatusFilter !== 'all') {
      filteredEmis = filteredEmis.filter(c => c.status === emitStatusFilter);
    }
    
    if (dateFilter.start) {
      const startDate = new Date(dateFilter.start);
      filteredEmis = filteredEmis.filter(c => 
        new Date(c.datePresentaion) >= startDate
      );
    }
    
    if (dateFilter.end) {
      const endDate = new Date(dateFilter.end);
      filteredEmis = filteredEmis.filter(c => 
        new Date(c.datePresentaion) <= endDate
      );
    }
    
    setFilteredChequesEmis(filteredEmis);
    
    // Filtres pour les chèques reçus
    let filteredRecus = [...chequesRecus];
    
    if (receivedSearchTerm.trim() !== '') {
      const searchLower = receivedSearchTerm.toLowerCase();
      filteredRecus = filteredRecus.filter(c => 
        c.reference?.toLowerCase().includes(searchLower) ||
        c.tireur?.toLowerCase().includes(searchLower) ||
        c.compteTireur?.toLowerCase().includes(searchLower) ||
        c.banqueEmettrice?.toLowerCase().includes(searchLower)
      );
    }
    
    if (receivedStatusFilter !== 'all') {
      filteredRecus = filteredRecus.filter(c => c.status === receivedStatusFilter);
    }
    
    if (dateFilter.start) {
      const startDate = new Date(dateFilter.start);
      filteredRecus = filteredRecus.filter(c => 
        new Date(c.dateReception) >= startDate
      );
    }
    
    if (dateFilter.end) {
      const endDate = new Date(dateFilter.end);
      filteredRecus = filteredRecus.filter(c => 
        new Date(c.dateReception) <= endDate
      );
    }
    
    setFilteredChequesRecus(filteredRecus);
  };
  
  // Mettre à jour les statistiques
  const updateStats = () => {
    const stats = {
      totalEmis: chequesEmis.length,
      totalRecus: chequesRecus.length,
      montantEmis: chequesEmis.reduce((sum, c) => sum + c.montant, 0),
      montantRecus: chequesRecus.reduce((sum, c) => sum + c.montant, 0),
      enCours: chequesEmis.filter(c => c.status === 'EN_COURS').length + 
               chequesRecus.filter(c => c.status === 'EN_VERIFICATION').length,
      traites: chequesEmis.filter(c => c.status === 'VALIDE' || c.status === 'TRANSMIS').length + 
               chequesRecus.filter(c => c.status === 'VALIDE').length,
      rejetes: chequesEmis.filter(c => c.status === 'REJETE').length + 
               chequesRecus.filter(c => c.status === 'REJETE').length
    };
    
    setRemiseStats(stats);
  };
  
  // Gérer la sélection d'un chèque
  const handleSelectCheque = (chequeId) => {
    const cheque = activeTab === 'sent' 
      ? chequesEmis.find(c => c.id === chequeId)
      : chequesRecus.find(c => c.id === chequeId);
    
    setSelectedCheque(cheque);
    setShowDetailsModal(true);
  };
  
  // Gérer la sélection multiple de chèques
  const handleSelectMultipleChecks = (selectedIds) => {
    setSelectedCheques(selectedIds);
  };
  
  // Gérer l'upload de fichiers
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  
  // Gérer le changement de fichiers
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploadFiles(files);
    setShowUploadModal(true);
  };
  
  // Soumettre les fichiers
  const handleSubmitFiles = async () => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Simuler un envoi de fichiers
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      setSuccessMessage("Les fichiers ont été téléchargés avec succès.");
      setTimeout(() => setSuccessMessage(null), 3000);
      
      setShowUploadModal(false);
      setUploadFiles([]);
      
      // Rafraîchir les données
      fetchData();
    } catch (err) {
      console.error("Erreur lors du téléchargement des fichiers:", err);
      setError("Impossible de télécharger les fichiers. Veuillez réessayer.");
    } finally {
      setActionLoading(false);
    }
  };
  
  // Ouvrir le modal de vérification d'un chèque
  const handleOpenVerification = (cheque) => {
    setSelectedCheque(cheque);
    setShowVerificationModal(true);
  };
  
  // Ouvrir le modal d'image d'un chèque
  const handleOpenImage = (cheque) => {
    setSelectedCheque(cheque);
    setShowImageModal(true);
  };
  
  // Valider un chèque reçu
  const handleValidateCheque = async (chequeId) => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Simuler une validation
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Mettre à jour les données locales
      const updatedCheques = chequesRecus.map(c => {
        if (c.id === chequeId) {
          return {
            ...c,
            status: 'VALIDE',
            verificationStatus: 'VALIDE'
          };
        }
        return c;
      });
      
      setChequesRecus(updatedCheques);
      
      setSuccessMessage("Le chèque a été validé avec succès.");
      setTimeout(() => setSuccessMessage(null), 3000);
      
      setShowVerificationModal(false);
    } catch (err) {
      console.error("Erreur lors de la validation du chèque:", err);
      setError("Impossible de valider le chèque. Veuillez réessayer.");
    } finally {
      setActionLoading(false);
    }
  };
  
  // Rejeter un chèque reçu
  const handleRejectCheque = async (chequeId, motif) => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Simuler un rejet
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Mettre à jour les données locales
      const updatedCheques = chequesRecus.map(c => {
        if (c.id === chequeId) {
          return {
            ...c,
            status: 'REJETE',
            verificationStatus: 'REJETE',
            motifRejet: motif
          };
        }
        return c;
      });
      
      setChequesRecus(updatedCheques);
      
      setSuccessMessage("Le chèque a été rejeté.");
      setTimeout(() => setSuccessMessage(null), 3000);
      
      setShowVerificationModal(false);
    } catch (err) {
      console.error("Erreur lors du rejet du chèque:", err);
      setError("Impossible de rejeter le chèque. Veuillez réessayer.");
    } finally {
      setActionLoading(false);
    }
  };
  
  // Soumettre les chèques sélectionnés pour la compensation
  const handleSubmitForClearing = async () => {
    if (selectedCheques.length === 0) {
      setError("Veuillez sélectionner au moins un chèque.");
      return;
    }
    
    setActionLoading(true);
    setError(null);
    
    try {
      // Simuler un envoi pour compensation
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Mettre à jour les données locales
      const updatedCheques = chequesEmis.map(c => {
        if (selectedCheques.includes(c.id)) {
          return {
            ...c,
            status: 'TRANSMIS'
          };
        }
        return c;
      });
      
      setChequesEmis(updatedCheques);
      
      setSuccessMessage(`${selectedCheques.length} chèque(s) envoyé(s) pour compensation.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      setSelectedCheques([]);
    } catch (err) {
      console.error("Erreur lors de l'envoi pour compensation:", err);
      setError("Impossible d'envoyer les chèques pour compensation. Veuillez réessayer.");
    } finally {
      setActionLoading(false);
    }
  };
  
  // Réinitialiser les filtres
  const resetFilters = () => {
    setEmitSearchTerm('');
    setReceivedSearchTerm('');
    setEmitStatusFilter('all');
    setReceivedStatusFilter('all');
    setDateFilter({
      start: null,
      end: null
    });
  };
  
  // Colonnes pour la DataGrid des chèques émis
  const colonnesEmis = [
    {
      key: 'reference',
      header: 'Référence',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.reference}
        </div>
      )
    },
    {
      key: 'montant',
      header: 'Montant',
      render: (row) => (
        <div className="font-medium">
          {new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: row.devise,
            minimumFractionDigits: 0
          }).format(row.montant)}
        </div>
      )
    },
    {
      key: 'banque',
      header: 'Banque',
      render: (row) => (
        <div>
          {row.banqueDestination}
        </div>
      )
    },
    {
      key: 'beneficiaire',
      header: 'Bénéficiaire',
      render: (row) => (
        <div>
          <div>{row.beneficiaire}</div>
          <div className="text-xs text-gray-500">{row.compteBeneficiaire}</div>
        </div>
      )
    },
    {
      key: 'date',
      header: 'Date',
      render: (row) => (
        <DateFormatter date={row.datePresentaion} format="short" />
      )
    },
    {
      key: 'status',
      header: 'Statut',
      render: (row) => {
        let status = "inactive";
        let label = "Non défini";
        
        switch (row.status) {
          case 'EN_COURS':
            status = "warning";
            label = "En cours";
            break;
          case 'VALIDE':
            status = "active";
            label = "Validé";
            break;
          case 'TRANSMIS':
            status = "active";
            label = "Transmis";
            break;
          case 'REJETE':
            status = "inactive";
            label = "Rejeté";
            break;
          default:
            break;
        }
        
        return <StatusBadge status={status} customLabel={label} />;
      }
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-1">
          <Button
            variant="outline"
            size="sm"
            icon={Image}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenImage(row);
            }}
          />
          <Button
            variant="outline"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectCheque(row.id);
            }}
          >
            Détails
          </Button>
        </div>
      )
    }
  ];
  
  // Colonnes pour la DataGrid des chèques reçus
  const colonnesRecus = [
    {
      key: 'reference',
      header: 'Référence',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.reference}
        </div>
      )
    },
    {
      key: 'montant',
      header: 'Montant',
      render: (row) => (
        <div className="font-medium">
          {new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: row.devise,
            minimumFractionDigits: 0
          }).format(row.montant)}
        </div>
      )
    },
    {
      key: 'banque',
      header: 'Banque émettrice',
      render: (row) => (
        <div>
          {row.banqueEmettrice}
        </div>
      )
    },
    {
      key: 'tireur',
      header: 'Tireur',
      render: (row) => (
        <div>
          <div>{row.tireur}</div>
          <div className="text-xs text-gray-500">{row.compteTireur}</div>
        </div>
      )
    },
    {
      key: 'date',
      header: 'Date réception',
      render: (row) => (
        <DateFormatter date={row.dateReception} format="short" />
      )
    },
    {
      key: 'status',
      header: 'Statut',
      render: (row) => {
        let status = "inactive";
        let label = "Non défini";
        
        switch (row.status) {
          case 'RECU':
            status = "warning";
            label = "Reçu";
            break;
          case 'EN_VERIFICATION':
            status = "warning";
            label = "En vérification";
            break;
          case 'VALIDE':
            status = "active";
            label = "Validé";
            break;
          case 'REJETE':
            status = "inactive";
            label = "Rejeté";
            break;
          default:
            break;
        }
        
        return <StatusBadge status={status} customLabel={label} />;
      }
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-1">
          <Button
            variant="outline"
            size="sm"
            icon={Image}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenImage(row);
            }}
          />
          {(row.status === 'RECU' || row.status === 'EN_VERIFICATION') && (
            <Button
              variant="outline"
              size="sm"
              icon={PenLine}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenVerification(row);
              }}
            >
              Vérifier
            </Button>
          )}
          <Button
            variant="outline"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectCheque(row.id);
            }}
          >
            Détails
          </Button>
        </div>
      )
    }
  ];
  
  // Rendu du composant
  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <FileText className="h-6 w-6 mr-2 text-blue-500" />
            Traitement des chèques
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les chèques émis pour la compensation et traitez les chèques reçus
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline" 
            icon={RefreshCw}
            onClick={fetchData}
            loading={loading}
          >
            Actualiser
          </Button>
          
          {activeTab === 'sent' && (
            <>
              <Button
                variant="outline"
                icon={Upload}
                onClick={handleUploadClick}
                disabled={actionLoading}
              >
                Remise de chèques
              </Button>
              <input 
                type="file" 
                ref={fileInputRef} 
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
                accept=".jpg,.jpeg,.png,.pdf,.tiff"
              />
            </>
          )}
          
          {activeTab === 'received' && (
            <Button
              variant="outline"
              icon={Download}
              onClick={() => {}}
              disabled={actionLoading}
            >
              Rapport
            </Button>
          )}
        </div>
      </div>
      
      {/* Messages de succès et d'erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Statistiques et résumé */}
      {statsVisible && (remiseStats.totalEmis > 0 || remiseStats.totalRecus > 0) && (
        <Card 
          title="Statistiques des chèques" 
          icon={FileBarChart2}
          actions={
            <Button
              variant="text"
              size="sm"
              icon={ChevronDown}
              onClick={() => setStatsVisible(false)}
            />
          }
        >
          <div className="mb-4">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
              <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-blue-700 dark:text-blue-300">En cours</p>
                    <p className="text-2xl font-bold text-blue-900 dark:text-blue-100">{remiseStats.enCours}</p>
                  </div>
                  <div className="bg-blue-100 dark:bg-blue-800 p-2 rounded-full">
                    <Clock className="h-5 w-5 text-blue-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-green-700 dark:text-green-300">Traités</p>
                    <p className="text-2xl font-bold text-green-900 dark:text-green-100">{remiseStats.traites}</p>
                  </div>
                  <div className="bg-green-100 dark:bg-green-800 p-2 rounded-full">
                    <CheckCircle className="h-5 w-5 text-green-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-red-700 dark:text-red-300">Rejetés</p>
                    <p className="text-2xl font-bold text-red-900 dark:text-red-100">{remiseStats.rejetes}</p>
                  </div>
                  <div className="bg-red-100 dark:bg-red-800 p-2 rounded-full">
                    <XCircle className="h-5 w-5 text-red-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-purple-50 dark:bg-purple-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-purple-700 dark:text-purple-300">Total</p>
                    <p className="text-2xl font-bold text-purple-900 dark:text-purple-100">{remiseStats.totalEmis + remiseStats.totalRecus}</p>
                  </div>
                  <div className="bg-purple-100 dark:bg-purple-800 p-2 rounded-full">
                    <FileText className="h-5 w-5 text-purple-500" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Détails des montants */}
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Montants</h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="p-3 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                      <Upload className="h-5 w-5 text-blue-500" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">Chèques émis</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">{remiseStats.totalEmis} chèque(s)</p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="text-lg font-semibold text-gray-900 dark:text-white">
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: 'XAF',
                        minimumFractionDigits: 0
                      }).format(remiseStats.montantEmis)}
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="p-3 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <div className="p-2 bg-amber-100 dark:bg-amber-900/30 rounded-full">
                      <Download className="h-5 w-5 text-amber-500" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">Chèques reçus</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">{remiseStats.totalRecus} chèque(s)</p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="text-lg font-semibold text-gray-900 dark:text-white">
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: 'XAF',
                        minimumFractionDigits: 0
                      }).format(remiseStats.montantRecus)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      
      {/* Onglets pour changer entre émis et reçus */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <nav className="-mb-px flex space-x-6" aria-label="Tabs">
          <button
            className={`py-2 px-1 border-b-2 font-medium text-sm whitespace-nowrap ${
              activeTab === 'received'
                ? 'border-blue-500 text-blue-600 dark:text-blue-500'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-600'
            }`}
            onClick={() => setActiveTab('received')}
          >
            <Download className="w-4 h-4 inline-block mr-2" />
            Chèques reçus
          </button>
          <button
            className={`py-2 px-1 border-b-2 font-medium text-sm whitespace-nowrap ${
              activeTab === 'sent'
                ? 'border-blue-500 text-blue-600 dark:text-blue-500'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-600'
            }`}
            onClick={() => setActiveTab('sent')}
          >
            <Upload className="w-4 h-4 inline-block mr-2" />
            Chèques émis
          </button>
        </nav>
      </div>
      
      {/* Filtres de recherche */}
      <div className="mb-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
        <div className="flex-1 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder={activeTab === 'sent' ? "Rechercher par référence, bénéficiaire, banque..." : "Rechercher par référence, tireur, banque..."}
            value={activeTab === 'sent' ? emitSearchTerm : receivedSearchTerm}
            onChange={(e) => activeTab === 'sent' ? setEmitSearchTerm(e.target.value) : setReceivedSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex gap-2">
          <Button
            variant={showFilters ? "primary" : "outline"}
            icon={Filter}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filtres
          </Button>
        </div>
      </div>
      
      {/* Panneau de filtres */}
      {showFilters && (
        <Card className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="statusFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Statut
              </label>
              <select
                id="statusFilter"
                value={activeTab === 'sent' ? emitStatusFilter : receivedStatusFilter}
                onChange={(e) => activeTab === 'sent' ? setEmitStatusFilter(e.target.value) : setReceivedStatusFilter(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Tous les statuts</option>
                {activeTab === 'sent' ? (
                  <>
                    <option value="EN_COURS">En cours</option>
                    <option value="VALIDE">Validés</option>
                    <option value="TRANSMIS">Transmis</option>
                    <option value="REJETE">Rejetés</option>
                  </>
                ) : (
                  <>
                    <option value="RECU">Reçus</option>
                    <option value="EN_VERIFICATION">En vérification</option>
                    <option value="VALIDE">Validés</option>
                    <option value="REJETE">Rejetés</option>
                  </>
                )}
              </select>
            </div>
            <div>
              <label htmlFor="dateFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {activeTab === 'sent' ? "Date de présentation" : "Date de réception"}
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    type="date"
                    id="startDate"
                    value={dateFilter.start || ''}
                    onChange={(e) => setDateFilter({...dateFilter, start: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Date début"
                  />
                </div>
                <div>
                  <input
                    type="date"
                    id="endDate"
                    value={dateFilter.end || ''}
                    onChange={(e) => setDateFilter({...dateFilter, end: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Date fin"
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-4 flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={resetFilters}
            >
              Réinitialiser
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowFilters(false)}
            >
              Appliquer
            </Button>
          </div>
        </Card>
      )}
      
      {/* Contenu des onglets */}
      <div>
        {activeTab === 'received' ? (
          <div>
            <Card>
              <DataGrid
                data={filteredChequesRecus}
                columns={colonnesRecus}
                loading={loading}
                emptyMessage="Aucun chèque reçu trouvé"
                onRowClick={(cheque) => handleSelectCheque(cheque.id)}
              />
            </Card>
          </div>
        ) : (
          <div>
            <div className="mb-4 flex justify-between items-center">
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {selectedCheques.length > 0 ? (
                  <span>{selectedCheques.length} chèque(s) sélectionné(s)</span>
                ) : (
                  <span>Sélectionnez des chèques pour effectuer des actions</span>
                )}
              </div>
              
              {selectedCheques.length > 0 && (
                <Button
                  variant="primary"
                  icon={ArrowUpDown}
                  onClick={handleSubmitForClearing}
                  disabled={actionLoading}
                >
                  Envoyer vers compensation
                </Button>
              )}
            </div>
            
            <Card>
              <DataGrid
                data={filteredChequesEmis}
                columns={colonnesEmis}
                loading={loading}
                emptyMessage="Aucun chèque émis trouvé"
                onRowClick={(cheque) => handleSelectCheque(cheque.id)}
                selectable={true}
                onSelect={handleSelectMultipleChecks}
                selectedItems={selectedCheques}
              />
            </Card>
          </div>
        )}
      </div>
      
      {/* Modal de détails */}
      {showDetailsModal && selectedCheque && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-4xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  <FileText className="w-5 h-5 mr-2 text-blue-500" />
                  Détails du chèque {selectedCheque.reference}
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                {/* Informations principales */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                      Informations du chèque
                    </h4>
                    <div className="space-y-3">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Référence:
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {selectedCheque.reference}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Montant:
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: selectedCheque.devise,
                            minimumFractionDigits: 0
                          }).format(selectedCheque.montant)}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {activeTab === 'sent' ? "Date de présentation:" : "Date de réception:"}
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          <DateFormatter 
                            date={activeTab === 'sent' ? selectedCheque.datePresentaion : selectedCheque.dateReception} 
                            format="full" 
                            showTime
                          />
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {activeTab === 'sent' ? "Banque destinataire:" : "Banque émettrice:"}
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {activeTab === 'sent' ? selectedCheque.banqueDestination : selectedCheque.banqueEmettrice}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Statut:
                        </p>
                        <div className="text-sm font-medium mt-1">
                          {activeTab === 'sent' ? (
                            <StatusBadge 
                              status={selectedCheque.status === 'REJETE' ? 'inactive' : 
                                    (selectedCheque.status === 'VALIDE' || selectedCheque.status === 'TRANSMIS') ? 'active' : 'warning'} 
                              customLabel={selectedCheque.status === 'VALIDE' ? 'Validé' :
                                          selectedCheque.status === 'TRANSMIS' ? 'Transmis' :
                                          selectedCheque.status === 'EN_COURS' ? 'En cours' :
                                          selectedCheque.status === 'REJETE' ? 'Rejeté' : selectedCheque.status} 
                            />
                          ) : (
                            <StatusBadge 
                              status={selectedCheque.status === 'REJETE' ? 'inactive' : 
                                    selectedCheque.status === 'VALIDE' ? 'active' : 'warning'} 
                              customLabel={selectedCheque.status === 'VALIDE' ? 'Validé' :
                                          selectedCheque.status === 'RECU' ? 'Reçu' :
                                          selectedCheque.status === 'EN_VERIFICATION' ? 'En vérification' :
                                          selectedCheque.status === 'REJETE' ? 'Rejeté' : selectedCheque.status} 
                            />
                          )}
                        </div>
                      </div>
                      {selectedCheque.motifRejet && (
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Motif de rejet:
                          </p>
                          <p className="text-sm font-medium text-red-600 dark:text-red-400">
                            {selectedCheque.motifRejet}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                      {activeTab === 'sent' ? "Informations du bénéficiaire" : "Informations du tireur"}
                    </h4>
                    <div className="space-y-3">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {activeTab === 'sent' ? "Bénéficiaire:" : "Tireur:"}
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {activeTab === 'sent' ? selectedCheque.beneficiaire : selectedCheque.tireur}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {activeTab === 'sent' ? "Compte bénéficiaire:" : "Compte tireur:"}
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {activeTab === 'sent' ? selectedCheque.compteBeneficiaire : selectedCheque.compteTireur}
                        </p>
                      </div>
                      {activeTab === 'received' && (
                        <>
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Bénéficiaire:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedCheque.beneficiaire}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Compte bénéficiaire:
                            </p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedCheque.compteBeneficiaire}
                            </p>
                          </div>
                        </>
                      )}
                      {selectedCheque.motif && (
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Motif:
                          </p>
                          <p className="text-sm font-medium text-gray-900 dark:text-white">
                            {selectedCheque.motif}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                
                {/* Image du chèque */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                    <Image className="w-5 h-5 mr-2 text-blue-500" />
                    Image du chèque
                  </h4>
                  
                  <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg text-center">
                    <img
                      src={selectedCheque.imagePath ? selectedCheque.imagePath : "/api/placeholder/600/300"}
                      alt="Image du chèque"
                      className="mx-auto max-w-full h-auto border border-gray-300 dark:border-gray-600 rounded"
                      style={{ maxHeight: '300px' }}
                    />
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      Cliquez pour agrandir
                    </p>
                  </div>
                </div>
                
                {/* Actions disponibles */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                  <div className="flex justify-between">
                    <div>
                      {activeTab === 'received' && selectedCheque.status !== 'VALIDE' && selectedCheque.status !== 'REJETE' && (
                        <Button
                          variant="outline"
                          icon={PenLine}
                          onClick={() => {
                            setShowDetailsModal(false);
                            handleOpenVerification(selectedCheque);
                          }}
                          className="mr-2"
                        >
                          Vérifier le chèque
                        </Button>
                      )}
                      
                      <Button
                        variant="outline"
                        icon={Printer}
                        onClick={() => {}}
                      >
                        Imprimer
                      </Button>
                    </div>
                    <Button
                      variant="outline"
                      onClick={() => setShowDetailsModal(false)}
                    >
                      Fermer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
	  {/* Modal pour la vérification des chèques */}
      {showVerificationModal && selectedCheque && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-4xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  <PenLine className="w-5 h-5 mr-2 text-blue-500" />
                  Vérification du chèque {selectedCheque.reference}
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowVerificationModal(false)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                {/* Informations principales */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Montant:
                    </p>
                    <p className="text-md font-medium text-gray-900 dark:text-white">
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: selectedCheque.devise,
                        minimumFractionDigits: 0
                      }).format(selectedCheque.montant)}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Tireur:
                    </p>
                    <p className="text-md font-medium text-gray-900 dark:text-white">
                      {selectedCheque.tireur}
                    </p>
                  </div>
                </div>
                
                {/* Image du chèque */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Image du chèque (recto)
                    </p>
                    <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-lg text-center">
                      <img
                        src={selectedCheque.imagePath ? selectedCheque.imagePath : "/api/placeholder/400/250"}
                        alt="Image du chèque recto"
                        className="mx-auto max-w-full h-auto border border-gray-300 dark:border-gray-600 rounded"
                        style={{ maxHeight: '250px' }}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Image du chèque (verso)
                    </p>
                    <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-lg text-center">
                      <img
                        src="/api/placeholder/400/250" 
                        alt="Image du chèque verso"
                        className="mx-auto max-w-full h-auto border border-gray-300 dark:border-gray-600 rounded"
                        style={{ maxHeight: '250px' }}
                      />
                    </div>
                  </div>
                </div>
                
                {/* Étapes de vérification */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Étapes de vérification
                  </h4>
                  
                  <div className="space-y-4">
                    <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                      <div className="flex items-start space-x-3">
                        <div className="flex-shrink-0 pt-0.5">
                          <CheckSquare className="h-5 w-5 text-green-500" />
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-gray-900 dark:text-white">
                            Validation de l'image
                          </h5>
                          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                            Vérifier que l'image du chèque est claire et lisible.
                          </p>
                          <div className="mt-2">
                            <label className="inline-flex items-center space-x-2">
                              <input type="checkbox" className="rounded border-gray-300 text-blue-600 focus:ring-blue-500" defaultChecked />
                              <span className="text-sm text-gray-700 dark:text-gray-300">Image claire et nette</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                      <div className="flex items-start space-x-3">
                        <div className="flex-shrink-0 pt-0.5">
                          <CheckSquare className="h-5 w-5 text-green-500" />
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-gray-900 dark:text-white">
                            Vérification de la signature
                          </h5>
                          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                            Vérifier que la signature correspond à celle du tireur.
                          </p>
                          <div className="mt-2">
                            <div className="flex items-center space-x-4">
                              <label className="inline-flex items-center space-x-2">
                                <input type="radio" name="signature" value="conform" className="border-gray-300 text-blue-600 focus:ring-blue-500" defaultChecked />
                                <span className="text-sm text-gray-700 dark:text-gray-300">Signature conforme</span>
                              </label>
                              <label className="inline-flex items-center space-x-2">
                                <input type="radio" name="signature" value="notconform" className="border-gray-300 text-blue-600 focus:ring-blue-500" />
                                <span className="text-sm text-gray-700 dark:text-gray-300">Signature non conforme</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                      <div className="flex items-start space-x-3">
                        <div className="flex-shrink-0 pt-0.5">
                          <CheckSquare className="h-5 w-5 text-green-500" />
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-gray-900 dark:text-white">
                            Vérification de provision
                          </h5>
                          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                            Vérifier que le compte du tireur dispose d'une provision suffisante.
                          </p>
                          <div className="mt-2">
                            <div className="flex items-center space-x-4">
                              <label className="inline-flex items-center space-x-2">
                                <input type="radio" name="provision" value="sufficient" className="border-gray-300 text-blue-600 focus:ring-blue-500" defaultChecked />
                                <span className="text-sm text-gray-700 dark:text-gray-300">Provision suffisante</span>
                              </label>
                              <label className="inline-flex items-center space-x-2">
                                <input type="radio" name="provision" value="insufficient" className="border-gray-300 text-blue-600 focus:ring-blue-500" />
                                <span className="text-sm text-gray-700 dark:text-gray-300">Provision insuffisante</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                      <div className="flex items-start space-x-3">
                        <div className="flex-shrink-0 pt-0.5">
                          <CheckSquare className="h-5 w-5 text-green-500" />
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-gray-900 dark:text-white">
                            Commentaires / Motif de rejet
                          </h5>
                          <div className="mt-2">
                            <textarea
                              rows="3"
                              className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Saisir un commentaire ou motif de rejet si nécessaire"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Actions de vérification */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                  <div className="flex justify-end space-x-3">
                    <Button
                      variant="outline"
                      onClick={() => setShowVerificationModal(false)}
                    >
                      Annuler
                    </Button>
                    <Button
                      variant="outline"
                      icon={XCircle}
                      onClick={() => handleRejectCheque(selectedCheque.id, "Signature non conforme")}
                      loading={actionLoading}
                      className="text-red-500 border-red-300 hover:bg-red-50"
                    >
                      Rejeter
                    </Button>
                    <Button
                      variant="primary"
                      icon={CheckCircle}
                      onClick={() => handleValidateCheque(selectedCheque.id)}
                      loading={actionLoading}
                    >
                      Valider
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Modal pour l'image du chèque */}
      {showImageModal && selectedCheque && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-5xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  <Image className="w-5 h-5 mr-2 text-blue-500" />
                  Image du chèque {selectedCheque.reference}
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowImageModal(false)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg text-center">
                  <img
                    src={selectedCheque.imagePath ? selectedCheque.imagePath : "/api/placeholder/800/400"}
                    alt="Image du chèque"
                    className="mx-auto max-w-full h-auto border border-gray-300 dark:border-gray-600 rounded"
                    style={{ maxHeight: '600px' }}
                  />
                </div>
                
                <div className="flex justify-between items-center">
                  <div>
                    <Button
                      variant="outline"
                      icon={Printer}
                      onClick={() => {}}
                    >
                      Imprimer
                    </Button>
                  </div>
                  <Button
                    variant="outline"
                    onClick={() => setShowImageModal(false)}
                  >
                    Fermer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Modal pour l'upload de chèques */}
      {showUploadModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  <Upload className="w-5 h-5 mr-2 text-blue-500" />
                  Remise de chèques
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => {
                    setShowUploadModal(false);
                    setUploadFiles([]);
                  }}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                <div>
                  <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Fichiers sélectionnés
                  </h4>
                  
                  {uploadFiles.length > 0 ? (
                    <div className="space-y-2">
                      {uploadFiles.map((file, index) => (
                        <div key={index} className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg flex items-center justify-between">
                          <div className="flex items-center">
                            <FileText className="h-5 w-5 text-blue-500 mr-2" />
                            <div>
                              <p className="text-sm font-medium text-gray-900 dark:text-white">{file.name}</p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                {(file.size / 1024).toFixed(2)} KB
                              </p>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                            onClick={() => {
                              const newFiles = uploadFiles.filter((_, i) => i !== index);
                              setUploadFiles(newFiles);
                            }}
                          >
                            <X className="w-5 h-5" />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-8">
                      <p className="text-gray-500 dark:text-gray-400">
                        Aucun fichier sélectionné
                      </p>
                    </div>
                  )}
                </div>
                
                <div>
                  <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Informations de la remise
                  </h4>
                  
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="remiseDate" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Date de présentation
                      </label>
                      <input
                        type="date"
                        id="remiseDate"
                        className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        defaultValue={new Date().toISOString().split('T')[0]}
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="remiseReference" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Référence de la remise
                      </label>
                      <input
                        type="text"
                        id="remiseReference"
                        className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Référence automatique"
                        defaultValue={`REM${new Date().toISOString().split('T')[0].replace(/-/g, '')}`}
                        readOnly
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="remiseComments" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Commentaires (optionnel)
                      </label>
                      <textarea
                        id="remiseComments"
                        rows="3"
                        className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Ajouter des commentaires pour cette remise"
                      ></textarea>
                    </div>
                  </div>
                </div>
                
                <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                  <div className="flex justify-between">
                    <Button
                      variant="outline"
                      icon={Upload}
                      onClick={handleUploadClick}
                    >
                      Ajouter des fichiers
                    </Button>
                    
                    <div>
                      <Button
                        variant="outline"
                        onClick={() => {
                          setShowUploadModal(false);
                          setUploadFiles([]);
                        }}
                        className="mr-2"
                      >
                        Annuler
                      </Button>
                      <Button
                        variant="primary"
                        icon={CheckCircle}
                        onClick={handleSubmitFiles}
                        loading={actionLoading}
                        disabled={uploadFiles.length === 0}
                      >
                        Soumettre la remise
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChequeProcessing;