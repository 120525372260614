import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Search, Filter, Download, Calendar, ArrowUpDown,
  FileText, RefreshCw, ChevronDown, Clock
} from 'lucide-react';
import apiService from '../../services/api.service';

// Composant de détail du workflow
const WorkflowDetail = ({ workflow }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-sm border p-4 space-y-4">
      {/* En-tête avec informations principales */}
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-medium">{workflow.declarationNumber}</h3>
          <p className="text-sm text-gray-500">Ref: {workflow.reference}</p>
        </div>
        <button
          onClick={() => setExpanded(!expanded)}
          className="flex items-center text-gray-500 hover:text-gray-700"
        >
          <span className="mr-2">{expanded ? 'Moins de détails' : 'Plus de détails'}</span>
          <ChevronDown className={`transform transition-transform ${expanded ? 'rotate-180' : ''}`} />
        </button>
      </div>

      {/* Informations générales toujours visibles */}
      <div className="grid grid-cols-3 gap-4">
        <div>
          <span className="block text-sm text-gray-500">Déclarant</span>
          <span>{workflow.declarantName}</span>
        </div>
        <div>
          <span className="block text-sm text-gray-500">Bureau</span>
          <span>{workflow.officeName}</span>
        </div>
        <div>
          <span className="block text-sm text-gray-500">Montant</span>
          <span>{workflow.totalAmount?.toLocaleString()} XAF</span>
        </div>
      </div>

      {/* Détails étendus */}
      {expanded && (
        <div className="pt-4 border-t mt-4 space-y-4">
          <div>
            <h4 className="font-medium mb-2">Historique des états</h4>
            <div className="space-y-2">
              {workflow.history?.map((entry, index) => (
                <div key={index} className="flex items-start text-sm">
                  <Clock className="w-4 h-4 mt-1 mr-2 text-gray-400" />
                  <div>
                    <div className="font-medium">{entry.event}</div>
                    <div className="text-gray-500 text-xs">
                      {new Date(entry.timestamp).toLocaleString()}
                    </div>
                    <div className="text-gray-500 text-xs">
                      {entry.fromState} → {entry.toState}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {workflow.context && (
            <div>
              <h4 className="font-medium mb-2">Détails du paiement</h4>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <span className="text-gray-500">Méthode</span>
                  <p>{workflow.context.paymentMethod}</p>
                </div>
                <div>
                  <span className="text-gray-500">Banque</span>
                  <p>{workflow.context.bankCode}</p>
                </div>
                <div>
                  <span className="text-gray-500">Date</span>
                  <p>{workflow.context.paymentDate}</p>
                </div>
                <div>
                  <span className="text-gray-500">Référence</span>
                  <p>{workflow.context.bankReference}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ConsultationWorkflow = () => {
  const navigate = useNavigate();
  
  // États
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  // États des filtres
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });
  
  // Gestion de la pagination
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Vérification de l'authentification
  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchWorkflows();
  }, [navigate, page, statusFilter]);

  const fetchWorkflows = async () => {
    setLoading(true);
    try {
      const data = await apiService.declarationWorkflow.getAll({
        page,
        size: 10,
        status: statusFilter !== 'all' ? statusFilter : undefined
      });
      
      setWorkflows(data.workflows);
      setTotalPages(data.totalPages);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors du chargement des workflows');
    } finally {
      setLoading(false);
    }
  };

  // Filtrer les workflows
  const filteredWorkflows = workflows.filter(workflow => {
    // Filtre par recherche
    if (searchQuery && !workflow.declarationNumber.toLowerCase().includes(searchQuery.toLowerCase())) {
      return false;
    }
    
    // Filtre par date
    if (dateRange.start && dateRange.end) {
      const workflowDate = new Date(workflow.startedAt);
      const start = new Date(dateRange.start);
      const end = new Date(dateRange.end);
      if (workflowDate < start || workflowDate > end) {
        return false;
      }
    }
    
    return true;
  });

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            Consultation des workflows
          </h1>
          <p className="mt-2 text-gray-600">
            Visualisez et suivez l'état des workflows de déclaration
          </p>
        </div>

        <div className="flex items-center space-x-4">
          <button
            onClick={fetchWorkflows}
            className="flex items-center px-4 py-2 border rounded-lg hover:bg-gray-50"
          >
            <RefreshCw className="w-4 h-4 mr-2" />
            Actualiser
          </button>
          <button
            onClick={() => {/* Logique d'export */}}
            className="flex items-center px-4 py-2 border rounded-lg hover:bg-gray-50"
          >
            <Download className="w-4 h-4 mr-2" />
            Exporter
          </button>
        </div>
      </div>

      {/* Filtres */}
      <div className="bg-white rounded-lg border p-4 space-y-4">
        <div className="grid grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              Rechercher
            </label>
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-10 w-full p-2 border rounded-lg"
                placeholder="N° déclaration..."
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Statut
            </label>
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="w-full p-2 border rounded-lg"
            >
              <option value="all">Tous les statuts</option>
              <option value="DRAFT">Brouillon</option>
              <option value="PAYMENT_INITIATED">Paiement initié</option>
              <option value="PAYMENT_VALIDATED">Paiement validé</option>
              <option value="COMPLETED">Terminé</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Date début
            </label>
            <input
              type="date"
              value={dateRange.start}
              onChange={(e) => setDateRange(prev => ({...prev, start: e.target.value}))}
              className="w-full p-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Date fin
            </label>
            <input
              type="date"
              value={dateRange.end}
              onChange={(e) => setDateRange(prev => ({...prev, end: e.target.value}))}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>
      </div>

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 text-red-700">
          {error}
        </div>
      )}

      {/* Liste des workflows */}
      <div className="space-y-4">
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
            <p className="mt-2 text-gray-600">Chargement des workflows...</p>
          </div>
        ) : filteredWorkflows.length > 0 ? (
          <>
            {filteredWorkflows.map(workflow => (
              <WorkflowDetail key={workflow.reference} workflow={workflow} />
            ))}
            
            {/* Pagination */}
            <div className="flex justify-center space-x-2 pt-4">
              <button
                onClick={() => setPage(prev => Math.max(prev - 1, 0))}
                disabled={page === 0}
                className="px-4 py-2 border rounded-lg disabled:opacity-50"
              >
                Précédent
              </button>
              <div className="px-4 py-2 border rounded-lg">
                Page {page + 1} sur {totalPages}
              </div>
              <button
                onClick={() => setPage(prev => prev + 1)}
                disabled={page >= totalPages - 1}
                className="px-4 py-2 border rounded-lg disabled:opacity-50"
              >
                Suivant
              </button>
            </div>
          </>
        ) : (
          <div className="text-center py-12 text-gray-500">
            Aucun workflow trouvé
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsultationWorkflow;