import React, { useState, useEffect } from 'react';
import {
  User,
  Mail,
  Phone,
  Lock,
  Save,
  AlertTriangle,
  CheckCircle,
  Eye,
  EyeOff,
  Shield,
  Settings,
  Key
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import apiService from '../../services/api.service';

const ProfileSettings = () => {
  // États des données utilisateur
  const [userProfile, setUserProfile] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États du formulaire de profil
  const [profileForm, setProfileForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
  });
  
  // États du formulaire de changement de mot de passe
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false
  });
  
  // États pour l'interface
  const [activeTab, setActiveTab] = useState('profile'); // 'profile', 'password', 'security'
  
  // Récupération des données de l'utilisateur au chargement
  useEffect(() => {
    const fetchUserProfile = async () => {
  setLoading(true);
  
  try {
    // Récupérer l'ID utilisateur depuis le stockage local ou le contexte
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      throw new Error('Utilisateur non authentifié');
    }
    
    // Essayer d'extraire les informations de base du token
    const tokenData = parseJwt(authToken);
    const username = tokenData.sub || tokenData.username || 'admin'; // Essayer de récupérer le nom d'utilisateur
    
    // Pour développement uniquement : utiliser un profil fictif plutôt que d'appeler l'API
    // Cela vous permet de continuer à développer pendant que vous résolvez les problèmes CORS
    
    console.log('Création d\'un profil utilisateur fictif pour le développement');
    
    const mockUserData = {
      id: 1,
      username: username,
      email: 'admin@example.com',
      firstName: 'Admin',
      lastName: 'Système',
      phoneNumber: '+237 123 456 789',
      status: 'ACTIVE',
      enabled: true,
      roles: ['ADMIN', 'USER'],
      createdAt: new Date().toISOString()
    };
    
    setUserProfile(mockUserData);
    setUserRoles(mockUserData.roles);
    
    setProfileForm({
      email: mockUserData.email,
      firstName: mockUserData.firstName,
      lastName: mockUserData.lastName,
      phoneNumber: mockUserData.phoneNumber
    });
    
    setError(null);
  } catch (err) {
    console.error('Erreur lors du chargement du profil utilisateur:', err);
    setError('Impossible de charger les informations du profil. Veuillez vous reconnecter.');
  } finally {
    setLoading(false);
  }
};
    
    fetchUserProfile();
  }, []);
  
  // Fonction pour parser le token JWT
  const parseJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error('Erreur lors du décodage du token:', e);
      return {};
    }
  };
  
  // Gestionnaire de changement pour le formulaire de profil
  const handleProfileChange = (field, value) => {
    setProfileForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  // Gestionnaire de changement pour le formulaire de mot de passe
  const handlePasswordChange = (field, value) => {
    setPasswordForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  // Soumission du formulaire de profil
  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    
    if (!userProfile?.id) {
      setError('Impossible de mettre à jour le profil: identifiant utilisateur non disponible');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Préparer les données à envoyer
      const updateData = {
        ...profileForm,
        enabled: userProfile.enabled // Conserver le statut actuel
      };
      
      // Appeler l'API pour mettre à jour le profil
      await apiService.security.users.update(userProfile.id, updateData);
      
      // Mettre à jour le profil local
      setUserProfile(prev => ({
        ...prev,
        ...updateData
      }));
      
      setSuccessMessage('Profil mis à jour avec succès');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la mise à jour du profil:', err);
      setError(err.message || 'Impossible de mettre à jour le profil. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Soumission du formulaire de changement de mot de passe
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    if (!userProfile?.id) {
      setError('Impossible de changer le mot de passe: identifiant utilisateur non disponible');
      return;
    }
    
    // Vérifier que les mots de passe correspondent
    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }
    
    // Vérifier la complexité du mot de passe
    if (passwordForm.newPassword.length < 8) {
      setError('Le mot de passe doit contenir au moins 8 caractères');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Dans un scénario réel, il y aurait un endpoint dédié pour changer le mot de passe
      // En l'absence d'un tel endpoint dans l'API fournie, nous utilisons une approche simulée
      
      // En réalité, vous devriez utiliser un endpoint comme:
      // await apiService.security.users.changePassword(userProfile.id, {
      //   currentPassword: passwordForm.currentPassword,
      //   newPassword: passwordForm.newPassword
      // });
      
      // Pour simuler le changement de mot de passe:
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setSuccessMessage('Mot de passe changé avec succès');
      
      // Réinitialiser le formulaire
      setPasswordForm({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors du changement de mot de passe:', err);
      setError(err.message || 'Impossible de changer le mot de passe. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour basculer l'affichage du mot de passe
  const togglePasswordVisibility = (field) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };
  
  // Rendu pour le formulaire de profil
  const renderProfileForm = () => (
    <form onSubmit={handleProfileSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Informations de base */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
            Informations personnelles
          </h3>
          
          <div className="space-y-4">
            {/* Prénom */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Prénom
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={profileForm.firstName}
                  onChange={(e) => handleProfileChange('firstName', e.target.value)}
                  placeholder="Prénom"
                />
              </div>
            </div>
            
            {/* Nom */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Nom
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={profileForm.lastName}
                  onChange={(e) => handleProfileChange('lastName', e.target.value)}
                  placeholder="Nom"
                />
              </div>
            </div>
            
            {/* Email */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Adresse email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={profileForm.email}
                  onChange={(e) => handleProfileChange('email', e.target.value)}
                  placeholder="email@exemple.com"
                  required
                />
              </div>
            </div>
            
            {/* Téléphone */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Téléphone
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="tel"
                  className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={profileForm.phoneNumber}
                  onChange={(e) => handleProfileChange('phoneNumber', e.target.value)}
                  placeholder="+237 123 456 789"
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Informations d'identification et rôles */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
            Informations du compte
          </h3>
          
          <div className="space-y-4">
            {/* Nom d'utilisateur */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Nom d'utilisateur
              </label>
              <input
                type="text"
                className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-white"
                value={userProfile?.username || ''}
                readOnly
                disabled
              />
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Le nom d'utilisateur ne peut pas être modifié
              </p>
            </div>
            
            {/* Rôles */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Rôles
              </label>
              <div className="p-3 bg-gray-50 dark:bg-gray-700 rounded-md">
                {userRoles.length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {userRoles.map((role, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300"
                      >
                        <Shield className="w-3 h-3 mr-1" />
                        {role}
                      </span>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Aucun rôle attribué
                  </p>
                )}
              </div>
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Les rôles sont attribués par les administrateurs
              </p>
            </div>
            
            {/* Date de création */}
            {userProfile?.createdAt && (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Compte créé le
                </label>
                <div className="p-2 bg-gray-50 dark:bg-gray-700 rounded-md">
                  <p className="text-sm text-gray-700 dark:text-gray-300">
                    {new Date(userProfile.createdAt).toLocaleDateString('fr-FR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Bouton d'action */}
      <div className="flex justify-end pt-4 border-t border-gray-200 dark:border-gray-700">
        <Button
          variant="primary"
          type="submit"
          icon={Save}
          loading={loading}
        >
          Sauvegarder les modifications
        </Button>
      </div>
    </form>
  );
  
  // Rendu pour le formulaire de changement de mot de passe
  const renderPasswordForm = () => (
    <form onSubmit={handlePasswordSubmit} className="space-y-6 max-w-md mx-auto">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
        Changer de mot de passe
      </h3>
      
      {/* Mot de passe actuel */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Mot de passe actuel
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Lock className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type={showPassword.current ? "text" : "password"}
            className="block w-full pl-10 pr-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            value={passwordForm.currentPassword}
            onChange={(e) => handlePasswordChange('currentPassword', e.target.value)}
            required
            placeholder="Entrez votre mot de passe actuel"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500"
              onClick={() => togglePasswordVisibility('current')}
            >
              {showPassword.current ? (
                <EyeOff className="h-5 w-5" />
              ) : (
                <Eye className="h-5 w-5" />
              )}
            </button>
          </div>
        </div>
      </div>
      
      {/* Nouveau mot de passe */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Nouveau mot de passe
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Key className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type={showPassword.new ? "text" : "password"}
            className="block w-full pl-10 pr-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            value={passwordForm.newPassword}
            onChange={(e) => handlePasswordChange('newPassword', e.target.value)}
            required
            placeholder="Entrez votre nouveau mot de passe"
            minLength="8"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500"
              onClick={() => togglePasswordVisibility('new')}
            >
              {showPassword.new ? (
                <EyeOff className="h-5 w-5" />
              ) : (
                <Eye className="h-5 w-5" />
              )}
            </button>
          </div>
        </div>
        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
          Le mot de passe doit comporter au moins 8 caractères
        </p>
      </div>
      
      {/* Confirmer le nouveau mot de passe */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Confirmer le nouveau mot de passe
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Key className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type={showPassword.confirm ? "text" : "password"}
            className="block w-full pl-10 pr-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            value={passwordForm.confirmPassword}
            onChange={(e) => handlePasswordChange('confirmPassword', e.target.value)}
            required
            placeholder="Confirmez votre nouveau mot de passe"
            minLength="8"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500"
              onClick={() => togglePasswordVisibility('confirm')}
            >
              {showPassword.confirm ? (
                <EyeOff className="h-5 w-5" />
              ) : (
                <Eye className="h-5 w-5" />
              )}
            </button>
          </div>
        </div>
      </div>
      
      {/* Bouton d'action */}
      <div className="flex justify-end pt-4 border-t border-gray-200 dark:border-gray-700">
        <Button
          variant="primary"
          type="submit"
          icon={Save}
          loading={loading}
        >
          Changer le mot de passe
        </Button>
      </div>
    </form>
  );
  
  // Rendu pour les paramètres de sécurité
  const renderSecuritySettings = () => (
    <div className="space-y-6 max-w-2xl mx-auto">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
        Paramètres de sécurité
      </h3>
      
      {/* Authentification à deux facteurs */}
      <Card>
        <div className="p-5">
          <div className="flex justify-between items-start">
            <div>
              <h4 className="text-lg font-medium text-gray-900 dark:text-white">
                Authentification à deux facteurs
              </h4>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Renforcez la sécurité de votre compte en ajoutant une couche de protection supplémentaire
              </p>
            </div>
            <div>
              <label htmlFor="2fa-toggle" className="inline-flex items-center cursor-pointer">
                <span className="relative">
                  <input id="2fa-toggle" type="checkbox" className="sr-only peer" disabled />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </span>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-white">
                  Non disponible
                </span>
              </label>
            </div>
          </div>
          <div className="mt-4 bg-yellow-50 dark:bg-yellow-900/20 p-3 rounded-md">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-yellow-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700 dark:text-yellow-300">
                  L'authentification à deux facteurs n'est pas encore disponible. Cette fonctionnalité sera bientôt ajoutée.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
      
      {/* Sessions actives */}
      <Card>
        <div className="p-5">
          <div>
            <h4 className="text-lg font-medium text-gray-900 dark:text-white">
              Sessions actives
            </h4>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Gérer vos sessions actives sur différents appareils
            </p>
          </div>
          
          <div className="mt-4 border border-gray-200 dark:border-gray-700 rounded-md overflow-hidden">
            <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 border-b border-gray-200 dark:border-gray-700">
              <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Session actuelle
              </h5>
            </div>
            <div className="p-4 flex justify-between items-center">
              <div>
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {navigator.userAgent}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  {new Date().toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </p>
              </div>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300">
                Actuelle
              </span>
            </div>
          </div>
          
          <div className="mt-4 text-center">
            <Button
              variant="danger"
              size="sm"
              disabled
            >
              Déconnecter toutes les autres sessions
            </Button>
          </div>
        </div>
      </Card>
      
      {/* Journal d'activité */}
      <Card>
        <div className="p-5">
          <div>
            <h4 className="text-lg font-medium text-gray-900 dark:text-white">
              Journal d'activité
            </h4>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Suivez les activités récentes de votre compte
            </p>
          </div>
          
          <div className="mt-4 border border-gray-200 dark:border-gray-700 rounded-md overflow-hidden">
            <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 border-b border-gray-200 dark:border-gray-700">
              <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Activités récentes
              </h5>
            </div>
            <div className="p-4 text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Le journal d'activité n'est pas encore disponible.
              </p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
          <User className="h-6 w-6 mr-2 text-blue-500" />
          Paramètres du compte
        </h1>
        <p className="text-gray-500 dark:text-gray-400">
          Gérez vos informations personnelles et vos paramètres de sécurité
        </p>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Onglets de navigation */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          <li className="mr-2">
            <button
              className={`inline-flex items-center py-4 px-4 text-sm font-medium text-center border-b-2 ${
                activeTab === 'profile'
                  ? 'text-blue-600 border-blue-600 dark:text-blue-400 dark:border-blue-400'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('profile')}
            >
              <User className="w-4 h-4 mr-2" />
              Profil
            </button>
          </li>
          <li className="mr-2">
            <button
              className={`inline-flex items-center py-4 px-4 text-sm font-medium text-center border-b-2 ${
                activeTab === 'password'
                  ? 'text-blue-600 border-blue-600 dark:text-blue-400 dark:border-blue-400'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('password')}
            >
              <Lock className="w-4 h-4 mr-2" />
              Mot de passe
            </button>
          </li>
          <li>
            <button
              className={`inline-flex items-center py-4 px-4 text-sm font-medium text-center border-b-2 ${
                activeTab === 'security'
                  ? 'text-blue-600 border-blue-600 dark:text-blue-400 dark:border-blue-400'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('security')}
            >
              <Shield className="w-4 h-4 mr-2" />
              Sécurité
            </button>
          </li>
        </ul>
      </div>
      
      {/* Contenu principal basé sur l'onglet actif */}
      <Card>
        <div className="p-6">
          {loading && !userProfile ? (
            <div className="text-center py-10">
              <div className="inline-block animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent"></div>
              <p className="mt-2 text-gray-500 dark:text-gray-400">Chargement des informations...</p>
            </div>
          ) : (
            <>
              {activeTab === 'profile' && renderProfileForm()}
              {activeTab === 'password' && renderPasswordForm()}
              {activeTab === 'security' && renderSecuritySettings()}
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ProfileSettings;