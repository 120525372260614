import React, { useState, useEffect, useMemo } from 'react';
import { 
  ArrowDownCircle, 
  ArrowUpCircle, 
  Filter, 
  RefreshCw, 
  Download, 
  Clock, 
  Search, 
  CheckCircle, 
  XCircle, 
  AlertTriangle, 
  Globe, 
  Eye, 
  Settings, 
  ChevronDown,
  ArrowRightLeft,
  GitBranch,
  Calendar,
  FileText,
  X,
  Info
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import DataGrid from '../../components/shared/DataGrid';
import DataFilters from '../../components/shared/DataFilters';
import apiService from '../../services/api.service';

/**
 * Composant pour afficher un tableau de tous les flux RTGS V10 échangés
 */
const RtgsFluxTable = () => {
  // États pour les données et filtres
  const [fluxData, setFluxData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(30);
  const [timeLeft, setTimeLeft] = useState(refreshInterval);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  
  // États des filtres
  const [filters, setFilters] = useState({
    direction: 'all',
    messageSource: 'all',
    messageTarget: 'all',
    status: 'all',
    startDate: null,
    endDate: null,
    searchTerm: ''
  });
  
  // États pour le tri
  const [sortConfig, setSortConfig] = useState({
    key: 'timestamp',
    direction: 'desc'
  });
  
  // Fonction pour charger les données
  const fetchFluxData = async () => {
    setLoading(true);
    
    try {
      // Tentative d'appel à l'API réelle
      let response;
      try {
        // Utiliser l'API appropriée pour récupérer les flux RTGS V10
        response = await apiService.workflow.getAllWorkflowDetailsList();
        
        // Filtrer seulement les instances RTGS V10
        if (response && response.data) {
          response.data = response.data.filter(instance => 
            instance.workflowCode && instance.workflowCode.includes('CEMAC_RTGSV10_ADAPTER')
          );
        }
      } catch (apiError) {
        console.warn("API non disponible, utilisation des données simulées:", apiError);
        
        // Données de flux simulées
        const mockFluxData = [
          {
            id: "flux-001",
            reference: "FLUX-RTGSV10-2402150001",
            direction: "mtToMx",
            messageSource: "MT103",
            messageTarget: "PACS.008",
            processCode: "CEMAC_RTGSV10_ADAPTER_MT103_TO_PACS008_PROCESS",
            processName: "[Conversion] [MT103] [PACS.008]",
            status: "completed",
            statusDetails: "EMISSION_BEAC",
            timestamp: new Date(Date.now() - 3600000).toISOString(),
            sourceIdentifier: "BCAOCMCX",
            destinationIdentifier: "BEACGBA0",
            messageReference: "MT103REF202402150001",
            amount: 450000,
            currency: "XAF",
            priority: "NORM"
          },
          {
            id: "flux-002",
            reference: "FLUX-RTGSV10-2402150002",
            direction: "mxToMt",
            messageSource: "PACS.008",
            messageTarget: "MT103",
            processCode: "CEMAC_RTGSV10_ADAPTER_PACS008_TO_MT103_PROCESS",
            processName: "[Conversion] [PACS.008] [MT103]",
            status: "active",
            statusDetails: "VERIFICATION_SIGNATURE",
            timestamp: new Date(Date.now() - 7200000).toISOString(),
            sourceIdentifier: "BEACGBA0",
            destinationIdentifier: "BCAOCMCX",
            messageReference: "PACS008REF202402150001",
            amount: 275000,
            currency: "XAF",
            priority: "NORM"
          },
          {
            id: "flux-003",
            reference: "FLUX-RTGSV10-2402150003",
            direction: "mtToMx",
            messageSource: "MT202",
            messageTarget: "PACS.009",
            processCode: "CEMAC_RTGSV10_ADAPTER_MT202_TO_PACS009_PROCESS",
            processName: "[Conversion] [MT202] [PACS.009]",
            status: "completed",
            statusDetails: "EMISSION_BEAC",
            timestamp: new Date(Date.now() - 86400000).toISOString(),
            sourceIdentifier: "SGBDCMCX",
            destinationIdentifier: "BEACGBA0",
            messageReference: "MT202REF202402150001",
            amount: 650000,
            currency: "XAF",
            priority: "HIGH"
          },
          {
            id: "flux-004",
            reference: "FLUX-RTGSV10-2402150004",
            direction: "mxToMt",
            messageSource: "PACS.009",
            messageTarget: "MT202",
            processCode: "CEMAC_RTGSV10_ADAPTER_PACS009_TO_MT202_PROCESS",
            processName: "[Conversion] [PACS.009] [MT202]",
            status: "failed",
            statusDetails: "REJECTED",
            timestamp: new Date(Date.now() - 172800000).toISOString(),
            sourceIdentifier: "BEACGBA0",
            destinationIdentifier: "SGBDCMCX",
            messageReference: "PACS009REF202402150001",
            amount: 320000,
            currency: "XAF",
            priority: "HIGH",
            errorMessage: "Erreur de validation du format BIC"
          },
          {
            id: "flux-005",
            reference: "FLUX-RTGSV10-2402150005",
            direction: "mtToMx",
            messageSource: "MT192",
            messageTarget: "CAMT.056",
            processCode: "CEMAC_RTGSV10_ADAPTER_MT192_TO_CAMT056_PROCESS",
            processName: "[Conversion] [MT192/MT292] [CAMT.056]",
            status: "active",
            statusDetails: "PREPARATION",
            timestamp: new Date(Date.now() - 43200000).toISOString(),
            sourceIdentifier: "BGFICMCX",
            destinationIdentifier: "BEACGBA0",
            messageReference: "MT192REF202402150001",
            reasonCode: "DUPL",
            originalMessageReference: "MT103REF202402140088"
          },
          {
            id: "flux-006",
            reference: "FLUX-RTGSV10-2402150006",
            direction: "mxToMt",
            messageSource: "CAMT.056",
            messageTarget: "MT192",
            processCode: "CEMAC_RTGSV10_ADAPTER_CAMT056_TO_MT192_PROCESS",
            processName: "[Conversion] [CAMT.056] [MT192]",
            status: "active",
            statusDetails: "VALIDATED",
            timestamp: new Date(Date.now() - 54000000).toISOString(),
            sourceIdentifier: "BEACGBA0",
            destinationIdentifier: "BGFICMCX",
            messageReference: "CAMT056REF202402150001",
            reasonCode: "TECH"
          },
          {
            id: "flux-007",
            reference: "FLUX-RTGSV10-2402150007",
            direction: "mxToMt",
            messageSource: "CAMT.053/GCST",
            messageTarget: "MT940",
            processCode: "CEMAC_RTGSV10_ADAPTER_CAMT053GCST_TO_MT940_PROCESS",
            processName: "[Conversion] [CAMT.053/GCST] [MT940]",
            status: "active",
            statusDetails: "VERIFICATION_SIGNATURE",
            timestamp: new Date(Date.now() - 10800000).toISOString(),
            sourceIdentifier: "BEACGBA0",
            destinationIdentifier: "CCIGCMCX",
            messageReference: "CAMT053REF202402150001",
            accountNumber: "CM12345678901234567890"
          },
          {
            id: "flux-008",
            reference: "FLUX-RTGSV10-2402150008",
            direction: "mxToMt",
            messageSource: "CAMT.053/ENDY",
            messageTarget: "MT950",
            processCode: "CEMAC_RTGSV10_ADAPTER_CAMT053ENDY_TO_MT950_PROCESS",
            processName: "[Conversion] [CAMT.053/ENDY] [MT950]",
            status: "active",
            statusDetails: "TRANSFORMATION_MX_MT",
            timestamp: new Date(Date.now() - 18000000).toISOString(),
            sourceIdentifier: "BEACGBA0",
            destinationIdentifier: "ECBACMCX",
            messageReference: "CAMT053REF202402150002",
            accountNumber: "GA12345678901234567890"
          },
          {
            id: "flux-009",
            reference: "FLUX-RTGSV10-2402150009",
            direction: "mtToMx",
            messageSource: "MT102",
            messageTarget: "PACS.008",
            processCode: "CEMAC_RTGSV10_ADAPTER_MT102_TO_PACS008_PROCESS",
            processName: "[Conversion] [MT102] [PACS.008]",
            status: "active",
            statusDetails: "VERIFICATION_COMPTE",
            timestamp: new Date(Date.now() - 28800000).toISOString(),
            sourceIdentifier: "UNAFCMCX",
            destinationIdentifier: "BEACGBA0",
            messageReference: "MT102REF202402150001",
            amount: 890000,
            currency: "XAF",
            priority: "NORM"
          },
          {
            id: "flux-010",
            reference: "FLUX-RTGSV10-2402150010",
            direction: "mxToMt",
            messageSource: "CAMT.054/DR",
            messageTarget: "MT900",
            processCode: "CEMAC_RTGSV10_ADAPTER_CAMT054DR_TO_MT900_PROCESS",
            processName: "[Conversion] [CAMT.054/DR] [MT900]",
            status: "completed",
            statusDetails: "EMISSION_PARTICIPANT",
            timestamp: new Date(Date.now() - 36000000).toISOString(),
            sourceIdentifier: "BEACGBA0",
            destinationIdentifier: "BICECGQ",
            messageReference: "CAMT054REF202402150001",
            amount: 1250000,
            currency: "XAF",
            accountNumber: "GQ12345678901234567890"
          },
          {
            id: "flux-011",
            reference: "FLUX-RTGSV10-2402150011",
            direction: "mtToMx",
            messageSource: "MT940",
            messageTarget: "CAMT.053/GCST",
            processCode: "CEMAC_RTGSV10_ADAPTER_MT940_TO_CAMT053GCST_PROCESS",
            processName: "[Conversion] [MT940] [CAMT.053/GCST]",
            status: "failed",
            statusDetails: "REJECTED",
            timestamp: new Date(Date.now() - 64800000).toISOString(),
            sourceIdentifier: "COBACGQ",
            destinationIdentifier: "BEACGBA0",
            messageReference: "MT940REF202402150001",
            accountNumber: "CF12345678901234567890",
            errorMessage: "Format de compte invalide"
          },
          {
            id: "flux-012",
            reference: "FLUX-RTGSV10-2402150012",
            direction: "mxToMt",
            messageSource: "CAMT.054/CR",
            messageTarget: "MT910",
            processCode: "CEMAC_RTGSV10_ADAPTER_CAMT054CR_TO_MT910_PROCESS",
            processName: "[Conversion] [CAMT.054/CR] [MT910]",
            status: "completed",
            statusDetails: "EMISSION_PARTICIPANT",
            timestamp: new Date(Date.now() - 72000000).toISOString(),
            sourceIdentifier: "BEACGBA0",
            destinationIdentifier: "BGFITDCF",
            messageReference: "CAMT054REF202402150003",
            amount: 478000,
            currency: "XAF",
            accountNumber: "TD12345678901234567890"
          }
        ];
        
        response = { data: mockFluxData };
      }
      
      // Transformer les données pour le tableau
      const formattedData = response.data.map(item => ({
        ...item,
        statusLabel: getStatusLabel(item.status),
        directionLabel: item.direction === 'mxToMt' ? 'MX → MT' : 'MT → MX'
      }));
      
      setFluxData(formattedData);
      // Initialement, les données filtrées sont identiques aux données complètes
      applyFilters(formattedData, filters);
      
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      setError("Une erreur s'est produite lors du chargement des données.");
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour obtenir le libellé d'un statut
  const getStatusLabel = (status) => {
    switch (status) {
      case 'completed':
        return 'Terminé';
      case 'active':
        return 'En cours';
      case 'failed':
        return 'Échoué';
      default:
        return status;
    }
  };
  
  // Détermine la couleur du statut
  const getStatusVariant = (status) => {
    switch (status) {
      case 'completed':
        return 'active';
      case 'active':
        return 'warning';
      case 'failed':
        return 'inactive';
      default:
        return 'warning';
    }
  };
  
  // Effet d'initialisation
  useEffect(() => {
    fetchFluxData();
  }, []);
  
  // Auto-rafraîchissement
  useEffect(() => {
    let intervalId;
    
    if (autoRefresh) {
      setTimeLeft(refreshInterval);
      
      // Décompte du temps restant
      const countdownId = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            return refreshInterval;
          }
          return prev - 1;
        });
      }, 1000);
      
      // Rafraîchissement périodique
      intervalId = setInterval(() => {
        fetchFluxData();
      }, refreshInterval * 1000);
      
      return () => {
        clearInterval(intervalId);
        clearInterval(countdownId);
      };
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [autoRefresh, refreshInterval]);
  
  // Appliquer les filtres aux données
  const applyFilters = (data, currentFilters) => {
    let filtered = [...data];
    
    // Filtre par direction
    if (currentFilters.direction !== 'all') {
      filtered = filtered.filter(item => item.direction === currentFilters.direction);
    }
    
    // Filtre par message source
    if (currentFilters.messageSource !== 'all') {
      filtered = filtered.filter(item => item.messageSource === currentFilters.messageSource);
    }
    
    // Filtre par message cible
    if (currentFilters.messageTarget !== 'all') {
      filtered = filtered.filter(item => item.messageTarget === currentFilters.messageTarget);
    }
    
    // Filtre par statut
    if (currentFilters.status !== 'all') {
      filtered = filtered.filter(item => item.status === currentFilters.status);
    }
    
    // Filtre par date
    if (currentFilters.startDate) {
      const startDate = new Date(currentFilters.startDate);
      filtered = filtered.filter(item => new Date(item.timestamp) >= startDate);
    }
    
    if (currentFilters.endDate) {
      const endDate = new Date(currentFilters.endDate);
      endDate.setHours(23, 59, 59, 999); // Fin de journée
      filtered = filtered.filter(item => new Date(item.timestamp) <= endDate);
    }
    
    // Recherche textuelle
    if (currentFilters.searchTerm) {
      const searchLower = currentFilters.searchTerm.toLowerCase();
      filtered = filtered.filter(item => 
        (item.reference && item.reference.toLowerCase().includes(searchLower)) ||
        (item.messageReference && item.messageReference.toLowerCase().includes(searchLower)) ||
        (item.sourceIdentifier && item.sourceIdentifier.toLowerCase().includes(searchLower)) ||
        (item.destinationIdentifier && item.destinationIdentifier.toLowerCase().includes(searchLower)) ||
        (item.processName && item.processName.toLowerCase().includes(searchLower))
      );
    }
    
    // Tri des données
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    
    setFilteredData(filtered);
  };
  
  // Effet pour appliquer les filtres quand ils changent
  useEffect(() => {
    applyFilters(fluxData, filters);
  }, [filters, sortConfig, fluxData]);
  
  // Gestionnaire pour le changement de filtre
  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  // Gestionnaire pour la réinitialisation des filtres
  const resetFilters = () => {
    setFilters({
      direction: 'all',
      messageSource: 'all',
      messageTarget: 'all',
      status: 'all',
      startDate: null,
      endDate: null,
      searchTerm: ''
    });
  };
  
  // Gestionnaire pour le tri
  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };
  
  // Fonction pour afficher les détails d'un flux
  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setShowDetailsModal(true);
  };
  
  // Extraction de listes uniques pour les filtres
  const uniqueMessageSources = useMemo(() => {
    const sources = [...new Set(fluxData.map(item => item.messageSource))];
    return sources.sort();
  }, [fluxData]);
  
  const uniqueMessageTargets = useMemo(() => {
    const targets = [...new Set(fluxData.map(item => item.messageTarget))];
    return targets.sort();
  }, [fluxData]);
  
  // Définition des colonnes pour le tableau
  const columns = [
    {
      key: 'reference',
      label: 'Référence',
      sortable: true,
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400 cursor-pointer hover:underline"
             onClick={() => handleViewDetails(row)}>
          {row.reference}
        </div>
      )
    },
    {
      key: 'timestamp',
      label: 'Date',
      sortable: true,
      render: (row) => (
        <DateFormatter date={row.timestamp} format="short" showTime />
      )
    },
    {
      key: 'direction',
      label: 'Direction',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          {row.direction === 'mxToMt' ? (
            <>
              <ArrowDownCircle size={16} className="text-indigo-500 mr-2" />
              <span>MX → MT</span>
            </>
          ) : (
            <>
              <ArrowUpCircle size={16} className="text-purple-500 mr-2" />
              <span>MT → MX</span>
            </>
          )}
        </div>
      )
    },
    {
      key: 'messageSource',
      label: 'Message Source',
      sortable: true
    },
    {
      key: 'messageTarget',
      label: 'Message Cible',
      sortable: true
    },
    {
      key: 'status',
      label: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={getStatusVariant(row.status)} 
          customLabel={row.statusLabel} 
          showIcon={true}
        />
      )
    },
    {
      key: 'statusDetails',
      label: 'État',
      sortable: true,
      render: (row) => (
        <span className="text-gray-600 dark:text-gray-300 text-sm">
          {row.statusDetails ? row.statusDetails.replace(/_/g, ' ') : ''}
        </span>
      )
    },
    {
      key: 'sourceIdentifier',
      label: 'Émetteur',
      sortable: true,
      className: 'text-center',
      render: (row) => (
        <div className="inline-flex items-center px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded text-xs">
          <Globe size={12} className="mr-1 text-gray-500" />
          {row.sourceIdentifier}
        </div>
      )
    },
    {
      key: 'destinationIdentifier',
      label: 'Destinataire',
      sortable: true,
      className: 'text-center',
      render: (row) => (
        <div className="inline-flex items-center px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded text-xs">
          <Globe size={12} className="mr-1 text-gray-500" />
          {row.destinationIdentifier}
        </div>
      )
    },
    {
      key: 'amount',
      label: 'Montant',
      sortable: true,
      className: 'text-right',
      render: (row) => (
        row.amount && row.currency ? (
          <span className="font-medium">
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: row.currency,
              minimumFractionDigits: 2
            }).format(row.amount)}
          </span>
        ) : ('')
      )
    },
    {
      key: 'actions',
      label: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-1">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetails(row);
            }}
          />
        </div>
      )
    }
  ];
  
  // Calcul des statistiques
  const stats = useMemo(() => {
    return {
      total: fluxData.length,
      completed: fluxData.filter(item => item.status === 'completed').length,
      active: fluxData.filter(item => item.status === 'active').length,
      failed: fluxData.filter(item => item.status === 'failed').length,
      mxToMt: fluxData.filter(item => item.direction === 'mxToMt').length,
      mtToMx: fluxData.filter(item => item.direction === 'mtToMx').length
    };
  }, [fluxData]);
  
  // Exporter les données
  const exportData = () => {
    // Transformer les données pour l'export
    const exportData = filteredData.map(item => ({
      Référence: item.reference,
      Date: new Date(item.timestamp).toLocaleString(),
      Direction: item.directionLabel,
      'Message Source': item.messageSource,
      'Message Cible': item.messageTarget,
      Processus: item.processName,
      Statut: item.statusLabel,
      État: item.statusDetails ? item.statusDetails.replace(/_/g, ' ') : '',
      Émetteur: item.sourceIdentifier,
      Destinataire: item.destinationIdentifier,
      Montant: item.amount ? `${item.amount} ${item.currency}` : '',
      'Référence Message': item.messageReference || '',
      Priorité: item.priority || ''
    }));
    
    // Convertir en CSV
    const headers = Object.keys(exportData[0]);
    const csvContent = [
      headers.join(','),
      ...exportData.map(row => 
        headers.map(header => 
          `"${row[header] ? row[header].toString().replace(/"/g, '""') : ''}"`
        ).join(',')
      )
    ].join('\n');
    
    // Télécharger le fichier
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `flux-rtgsv10-${new Date().toISOString().slice(0, 10)}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <GitBranch className="h-6 w-6 mr-2 text-blue-500" />
            Tableau des flux CEMAC RTGS V10
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Liste de tous les messages échangés via les adaptateurs RTGS V10
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline" 
            icon={RefreshCw}
            onClick={fetchFluxData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant={autoRefresh ? "primary" : "outline"}
            icon={Clock}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            {autoRefresh ? `Auto (${timeLeft}s)` : "Auto-refresh"}
          </Button>
          <Button
            variant="outline"
            icon={Filter}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filtres
          </Button>
          <Button
            variant="outline"
            icon={Download}
            onClick={exportData}
            disabled={filteredData.length === 0}
          >
            Exporter
          </Button>
        </div>
      </div>
      
      {/* Statistiques */}
      <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Total</p>
              <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">{stats.total}</p>
            </div>
            <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full">
              <GitBranch className="h-5 w-5 text-blue-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Terminés</p>
              <p className="text-2xl font-bold text-green-600 dark:text-green-400">{stats.completed}</p>
            </div>
            <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-full">
              <CheckCircle className="h-5 w-5 text-green-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">En cours</p>
              <p className="text-2xl font-bold text-yellow-600 dark:text-yellow-400">{stats.active}</p>
            </div>
            <div className="p-2 bg-yellow-100 dark:bg-yellow-900/30 rounded-full">
              <Clock className="h-5 w-5 text-yellow-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Échecs</p>
              <p className="text-2xl font-bold text-red-600 dark:text-red-400">{stats.failed}</p>
            </div>
            <div className="p-2 bg-red-100 dark:bg-red-900/30 rounded-full">
              <XCircle className="h-5 w-5 text-red-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">MX → MT</p>
              <p className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">{stats.mxToMt}</p>
            </div>
            <div className="p-2 bg-indigo-100 dark:bg-indigo-900/30 rounded-full">
              <ArrowDownCircle className="h-5 w-5 text-indigo-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">MT → MX</p>
              <p className="text-2xl font-bold text-purple-600 dark:text-purple-400">{stats.mtToMx}</p>
            </div>
            <div className="p-2 bg-purple-100 dark:bg-purple-900/30 rounded-full">
              <ArrowUpCircle className="h-5 w-5 text-purple-500" />
            </div>
          </div>
        </Card>
      </div>
      
      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Rechercher par référence, BIC, processus..."
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          value={filters.searchTerm}
          onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
        />
      </div>
      
      {/* Filtres avancés */}
      <DataFilters
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        filters={filters}
        onChange={setFilters}
        options={{
          direction: [
            { value: 'all', label: 'Toutes les directions' },
            { value: 'mxToMt', label: 'MX → MT' },
            { value: 'mtToMx', label: 'MT → MX' }
          ],
          messageSource: [
            { value: 'all', label: 'Tous les messages source' },
            ...uniqueMessageSources.map(source => ({ value: source, label: source }))
          ],
          messageTarget: [
            { value: 'all', label: 'Tous les messages cible' },
            ...uniqueMessageTargets.map(target => ({ value: target, label: target }))
          ],
          status: [
            { value: 'all', label: 'Tous les statuts' },
            { value: 'active', label: 'En cours' },
            { value: 'completed', label: 'Terminé' },
            { value: 'failed', label: 'Échoué' }
          ]
        }}
      />
      
      {/* Tableau principal */}
      <DataGrid
        data={filteredData}
        columns={columns}
        selectable={false}
        sortable={true}
        onSort={handleSort}
        loading={loading}
        emptyMessage="Aucun flux RTGS V10 trouvé"
      />
      
      {/* Modal de détails */}
      {showDetailsModal && selectedItem && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/20 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-3xl w-full p-6">
            {/* En-tête du modal */}
            <div className="flex justify-between items-center mb-6 border-b pb-4">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <GitBranch className="h-5 w-5 mr-2 text-blue-500" />
                Détails du flux: {selectedItem.reference}
              </h3>
              <button
                onClick={() => setShowDetailsModal(false)}
                className="text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            {/* Contenu du modal */}
            <div className="space-y-6">
              {/* Information générales */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card title="Informations générales" icon={Info}>
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Référence:</span>
                      <span className="font-medium">{selectedItem.reference}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Type de processus:</span>
                      <span className="font-medium">{selectedItem.processName}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Date:</span>
                      <DateFormatter date={selectedItem.timestamp} format="full" showTime />
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Direction:</span>
                      <div className="flex items-center">
                        {selectedItem.direction === 'mxToMt' ? (
                          <>
                            <ArrowDownCircle size={16} className="text-indigo-500 mr-2" />
                            <span>MX → MT</span>
                          </>
                        ) : (
                          <>
                            <ArrowUpCircle size={16} className="text-purple-500 mr-2" />
                            <span>MT → MX</span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Message source:</span>
                      <span className="font-medium">{selectedItem.messageSource}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Message cible:</span>
                      <span className="font-medium">{selectedItem.messageTarget}</span>
                    </div>
                  </div>
                </Card>
                
                <Card title="État et traitement" icon={Settings}>
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                      <StatusBadge 
                        status={getStatusVariant(selectedItem.status)} 
                        customLabel={selectedItem.statusLabel} 
                        showIcon={true}
                      />
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">État de traitement:</span>
                      <span className="font-medium">{selectedItem.statusDetails ? selectedItem.statusDetails.replace(/_/g, ' ') : ''}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Priorité:</span>
                      <span className="font-medium">{selectedItem.priority || 'NORM'}</span>
                    </div>
                    {selectedItem.errorMessage && (
                      <div className="pt-2 border-t border-gray-200 dark:border-gray-700">
                        <span className="text-gray-500 dark:text-gray-400">Message d'erreur:</span>
                        <div className="mt-1 p-2 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded">
                          {selectedItem.errorMessage}
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              </div>
              
              {/* Détails du message */}
              <Card title="Détails du message" icon={FileText}>
                <div className="space-y-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Expéditeur:</span>
                      <div className="mt-1 flex items-center space-x-2">
                        <Globe size={16} className="text-gray-500" />
                        <span className="font-medium">{selectedItem.sourceIdentifier}</span>
                      </div>
                    </div>
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Destinataire:</span>
                      <div className="mt-1 flex items-center space-x-2">
                        <Globe size={16} className="text-gray-500" />
                        <span className="font-medium">{selectedItem.destinationIdentifier}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-3 border-t border-gray-200 dark:border-gray-700">
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Référence message:</span>
                      <div className="mt-1 font-medium">{selectedItem.messageReference || '-'}</div>
                    </div>
                    {selectedItem.uetr && (
                      <div>
                        <span className="text-gray-500 dark:text-gray-400">UETR:</span>
                        <div className="mt-1 font-medium">{selectedItem.uetr}</div>
                      </div>
                    )}
                  </div>
                  
                  {/* Montant si présent */}
                  {selectedItem.amount && (
                    <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
                      <span className="text-gray-500 dark:text-gray-400">Montant:</span>
                      <div className="mt-1 text-xl font-bold">
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: selectedItem.currency,
                          minimumFractionDigits: 2
                        }).format(selectedItem.amount)}
                      </div>
                    </div>
                  )}
                  
                  {/* Informations supplémentaires spécifiques à certains types de messages */}
                  {(selectedItem.reasonCode || selectedItem.originalMessageReference || selectedItem.accountNumber) && (
                    <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
                      <h4 className="font-medium text-gray-900 dark:text-white mb-2">Informations supplémentaires</h4>
                      <div className="space-y-2">
                        {selectedItem.reasonCode && (
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Code motif:</span>
                            <span className="font-medium">{selectedItem.reasonCode}</span>
                          </div>
                        )}
                        {selectedItem.originalMessageReference && (
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Référence message original:</span>
                            <span className="font-medium">{selectedItem.originalMessageReference}</span>
                          </div>
                        )}
                        {selectedItem.accountNumber && (
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Numéro de compte:</span>
                            <span className="font-medium">{selectedItem.accountNumber}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Card>
              
              {/* Pied du modal */}
              <div className="flex justify-end space-x-3 pt-4 border-t border-gray-200 dark:border-gray-700">
                <Button
                  variant="outline"
                  icon={RefreshCw}
                  onClick={() => {
                    fetchFluxData();
                    setShowDetailsModal(false);
                  }}
                >
                  Actualiser et fermer
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setShowDetailsModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RtgsFluxTable;