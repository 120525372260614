import React, { useState, useEffect } from 'react';
import { 
  Users, 
  Network, 
  Play,
  PlusCircle, 
  Trash2, 
  Edit, 
  Search, 
  RefreshCw, 
  Settings, 
  CheckCircle2, 
  XCircle, 
  Info, 
  ChevronDown,
  ChevronUp,
  Eye,
  Save,
  X,
  BarChart3,
  CheckSquare,
  Filter,
  ArrowRightLeft,
  Clock,
  Layers
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const PartnersAndInterfaces = () => {
  // État pour gérer les onglets
  const [activeTab, setActiveTab] = useState('partners');

  // État pour stocker la relation entre les partenaires et les interfaces
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [relatedInterfaces, setRelatedInterfaces] = useState([]);
  const [interfaceExecutions, setInterfaceExecutions] = useState([]);

  // Fonction pour changer d'onglet
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Fonction pour afficher les interfaces liées à un partenaire
  const showPartnerInterfaces = (partner) => {
    setSelectedPartner(partner);
    setActiveTab('interfaces');
    fetchInterfacesForPartner(partner.code);
  };

  // Fonction pour afficher les exécutions d'une interface
  const showInterfaceExecutions = (interfaceItem) => {
    setActiveTab('executions');
    fetchExecutionsForInterface(interfaceItem.code);
  };

  // Fonction pour récupérer les interfaces associées à un partenaire
  const fetchInterfacesForPartner = async (partnerCode) => {
    try {
      // Dans un cas réel, vous auriez un endpoint spécifique pour cela
      // Ici, on simule en récupérant toutes les interfaces et en filtrant
      const interfaces = await apiService.integration.interfaces.search({ partnerCode });
      setRelatedInterfaces(interfaces);
    } catch (error) {
      console.error('Erreur lors de la récupération des interfaces du partenaire:', error);
    }
  };

  // Fonction pour récupérer les exécutions d'une interface
  const fetchExecutionsForInterface = async (interfaceCode) => {
    try {
      const executions = await apiService.integration.interfaceExecutions.getInterfaceExecutions(interfaceCode, 0, 20);
      setInterfaceExecutions(executions);
    } catch (error) {
      console.error('Erreur lors de la récupération des exécutions d\'interface:', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Layers className="h-6 w-6 mr-2 text-blue-500" />
            Partenaires et Interfaces
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gestion des partenaires, des interfaces et de leur exécution
          </p>
        </div>
      </div>

      {/* Onglets de navigation */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <nav className="flex -mb-px space-x-8">
          <button
            onClick={() => handleTabChange('partners')}
            className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center ${
              activeTab === 'partners'
                ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
          >
            <Users className="h-5 w-5 mr-2" />
            Partenaires
          </button>
          
          <button
            onClick={() => handleTabChange('interfaces')}
            className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center ${
              activeTab === 'interfaces'
                ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
          >
            <Network className="h-5 w-5 mr-2" />
            Interfaces {selectedPartner && `de ${selectedPartner.name}`}
          </button>
          
          <button
            onClick={() => handleTabChange('executions')}
            className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center ${
              activeTab === 'executions'
                ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
          >
            <Play className="h-5 w-5 mr-2" />
            Exécutions
          </button>
        </nav>
      </div>

      {/* Contenu des onglets */}
      <div className="mt-6">
        {activeTab === 'partners' && <PartnersTab onSelectPartner={showPartnerInterfaces} />}
        {activeTab === 'interfaces' && <InterfacesTab partner={selectedPartner} interfaces={relatedInterfaces} onSelectInterface={showInterfaceExecutions} />}
        {activeTab === 'executions' && <ExecutionsTab executions={interfaceExecutions} />}
      </div>
    </div>
  );
};

// Composant pour l'onglet Partenaires
const PartnersTab = ({ onSelectPartner }) => {
  // États pour gérer les données et les états UI
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    description: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    address: '',
    country: '',
    category: '',
    metadata: {}
  });
  const [configData, setConfigData] = useState({
    connectionType: '',
    securityLevel: '',
    allowedInterfaces: [],
    parameters: {},
    sla: '',
    maxConcurrentConnections: 10,
    timeoutInSeconds: 30,
    maxRetries: 3,
    notificationEmail: '',
    notificationPhone: '',
    alertsEnabled: true,
    supportLevel: 'standard',
    encryptionType: '',
    companyIdentifier: '',
    isTestAccount: false
  });
  const [newAllowedInterface, setNewAllowedInterface] = useState('');
  const [newParamKey, setNewParamKey] = useState('');
  const [newParamValue, setNewParamValue] = useState('');

  // Filtrer les partenaires en fonction du terme de recherche
  const filteredPartners = partners.filter(partner => {
    return (
      partner.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (partner.description && partner.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (partner.category && partner.category.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  useEffect(() => {
    fetchPartners();
  }, []);

  // Fonction pour charger les partenaires
  const fetchPartners = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.partners.getAll();
      setPartners(response);
    } catch (err) {
      console.error("Erreur lors du chargement des partenaires :", err);
      setError("Impossible de charger les partenaires. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger un partenaire spécifique
  const fetchPartner = async (code) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.partners.getById(code);
      setSelectedPartner(response);
      
      // Préparer les données pour le formulaire d'édition
      setFormData({
        code: response.code,
        name: response.name,
        description: response.description || '',
        contactName: response.contactName || '',
        contactEmail: response.contactEmail || '',
        contactPhone: response.contactPhone || '',
        address: response.address || '',
        country: response.country || '',
        category: response.category || '',
        metadata: response.metadata || {}
      });
      
      // Préparer les données pour le formulaire de configuration
      setConfigData({
        connectionType: response.config?.connectionType || '',
        securityLevel: response.config?.securityLevel || '',
        allowedInterfaces: response.config?.allowedInterfaces || [],
        parameters: response.config?.parameters || {},
        sla: response.config?.sla || '',
        maxConcurrentConnections: response.config?.maxConcurrentConnections || 10,
        timeoutInSeconds: response.config?.timeoutInSeconds || 30,
        maxRetries: response.config?.maxRetries || 3,
        notificationEmail: response.config?.notificationEmail || '',
        notificationPhone: response.config?.notificationPhone || '',
        alertsEnabled: response.config?.alertsEnabled !== false,
        supportLevel: response.config?.supportLevel || 'standard',
        encryptionType: response.config?.encryptionType || '',
        companyIdentifier: response.config?.companyIdentifier || '',
        isTestAccount: response.config?.isTestAccount || false
      });
    } catch (err) {
      console.error("Erreur lors du chargement du partenaire :", err);
      setError("Impossible de charger les détails du partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de création de partenaire
  const handleCreatePartner = async () => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.create(formData);
      setSuccessMessage("Partenaire créé avec succès !");
      setShowCreateModal(false);
      fetchPartners();
      resetForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création du partenaire :", err);
      setError("Impossible de créer le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de mise à jour de partenaire
  const handleUpdatePartner = async () => {
    if (!selectedPartner) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.update(selectedPartner.code, formData);
      setSuccessMessage("Partenaire mis à jour avec succès !");
      setShowEditModal(false);
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du partenaire :", err);
      setError("Impossible de mettre à jour le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de mise à jour de configuration
  const handleUpdateConfig = async () => {
    if (!selectedPartner) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.updateConfig(selectedPartner.code, configData);
      setSuccessMessage("Configuration du partenaire mise à jour avec succès !");
      setShowConfigModal(false);
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la configuration :", err);
      setError("Impossible de mettre à jour la configuration. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de suppression de partenaire
  const handleDeletePartner = async () => {
    if (!selectedPartner) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.delete(selectedPartner.code);
      setSuccessMessage("Partenaire supprimé avec succès !");
      setShowDeleteModal(false);
      setSelectedPartner(null);
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression du partenaire :", err);
      setError("Impossible de supprimer le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire d'activation de partenaire
  const handleActivatePartner = async (code) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.activate(code);
      setSuccessMessage("Partenaire activé avec succès !");
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'activation du partenaire :", err);
      setError("Impossible d'activer le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de désactivation de partenaire
  const handleDeactivatePartner = async (code) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.deactivate(code);
      setSuccessMessage("Partenaire désactivé avec succès !");
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la désactivation du partenaire :", err);
      setError("Impossible de désactiver le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Réinitialisation du formulaire
  const resetForm = () => {
    setFormData({
      code: '',
      name: '',
      description: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      address: '',
      country: '',
      category: '',
      metadata: {}
    });
  };

  // Gestion des changements dans le formulaire
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Gestion des changements dans le formulaire de configuration
  const handleConfigChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConfigData({
      ...configData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Ajout d'une interface autorisée à la liste
  const handleAddAllowedInterface = () => {
    if (newAllowedInterface.trim() === '') return;
    
    setConfigData({
      ...configData,
      allowedInterfaces: [...configData.allowedInterfaces, newAllowedInterface]
    });
    setNewAllowedInterface('');
  };

  // Retrait d'une interface autorisée de la liste
  const handleRemoveAllowedInterface = (index) => {
    const updatedInterfaces = [...configData.allowedInterfaces];
    updatedInterfaces.splice(index, 1);
    setConfigData({
      ...configData,
      allowedInterfaces: updatedInterfaces
    });
  };

  // Ajout d'un paramètre à la configuration
  const handleAddParameter = () => {
    if (newParamKey.trim() === '') return;
    
    setConfigData({
      ...configData,
      parameters: {
        ...configData.parameters,
        [newParamKey]: newParamValue
      }
    });
    setNewParamKey('');
    setNewParamValue('');
  };

  // Retrait d'un paramètre de la configuration
  const handleRemoveParameter = (key) => {
    const updatedParams = { ...configData.parameters };
    delete updatedParams[key];
    setConfigData({
      ...configData,
      parameters: updatedParams
    });
  };

  // Configuration des colonnes pour la grille de données
  const columns = [
    {
      header: 'Code',
      key: 'code',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.code}
        </div>
      )
    },
    {
      header: 'Nom / Description',
      key: 'name',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.name}
          </div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Catégorie',
      key: 'category',
      render: (row) => (
        <div>
          {row.category || '-'}
        </div>
      )
    },
    {
      header: 'Contact',
      key: 'contact',
      render: (row) => (
        <div>
          <div>{row.contactName || '-'}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {row.contactEmail || '-'}
          </div>
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => (
        <StatusBadge 
          status={row.status === 'ACTIVE' ? 'active' : 'inactive'} 
          customLabel={row.status === 'ACTIVE' ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Network}
            onClick={() => onSelectPartner(row)}
          >
            Interfaces
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Settings}
            onClick={(e) => {
              e.stopPropagation();
              fetchPartner(row.code);
              setShowConfigModal(true);
            }}
          >
            Config
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            onClick={(e) => {
              e.stopPropagation();
              fetchPartner(row.code);
              setShowEditModal(true);
            }}
          >
            Modifier
          </Button>
          {row.status === 'ACTIVE' ? (
            <Button
              variant="outline"
              size="sm"
              icon={XCircle}
              onClick={(e) => {
                e.stopPropagation();
                handleDeactivatePartner(row.code);
              }}
            >
              Désactiver
            </Button>
          ) : (
            <Button
              variant="outline"
              size="sm"
              icon={CheckCircle2}
              onClick={(e) => {
                e.stopPropagation();
                handleActivatePartner(row.code);
              }}
            >
              Activer
            </Button>
          )}
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPartner(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de l'onglet */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
            <Users className="h-5 w-5 mr-2 text-blue-500" />
            Gestion des Partenaires d'Intégration
          </h2>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchPartners}
            loading={loading && !showCreateModal && !showEditModal && !showConfigModal && !showDeleteModal}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetForm();
              setShowCreateModal(true);
            }}
          >
            Nouveau partenaire
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher un partenaire..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des partenaires */}
      <Card>
        {loading && !showCreateModal && !showEditModal && !showConfigModal && !showDeleteModal ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <DataGrid
            data={filteredPartners}
            columns={columns}
            emptyMessage="Aucun partenaire trouvé"
          />
        )}
      </Card>

      {/* Modal de création de partenaire */}
      {showCreateModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-3xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer un nouveau partenaire
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Code*
                    </label>
                    <input
                      type="text"
                      name="code"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.code}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={3}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Catégorie
                    </label>
                    <input
                      type="text"
                      name="category"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.category}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                
                {/* Informations de contact */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du contact
                    </label>
                    <input
                      type="text"
                      name="contactName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactName}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Email du contact
                    </label>
                    <input
                      type="email"
                      name="contactEmail"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactEmail}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Téléphone du contact
                    </label>
                    <input
                      type="text"
                      name="contactPhone"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactPhone}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Adresse
                    </label>
                    <input
                      type="text"
                      name="address"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.address}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Pays
                    </label>
                    <input
                      type="text"
                      name="country"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.country}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreatePartner}
                  loading={loading}
                  disabled={!formData.code || !formData.name}
                >
                  Créer le partenaire
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Autres modaux (Edit, Delete, Config) - non affichés ici pour brièveté */}
    </div>
  );
};

// Composant pour l'onglet Interfaces
const InterfacesTab = ({ partner, interfaces, onSelectInterface }) => {
  // États pour gérer les données et les états UI
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [allInterfaces, setAllInterfaces] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedInterface, setSelectedInterface] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    description: '',
    type: '',
    config: {
      connectionRef: '',
      timeout: '30',
      retryCount: 3,
      parameters: {},
      errorHandlingStrategy: 'DEFAULT',
      securityLevel: 'MEDIUM',
      loggingEnabled: true,
      logLevel: 'INFO',
      monitoringEnabled: true,
      alertsEnabled: true
    }
  });

  // Utiliser les interfaces transmises ou charger toutes les interfaces
  useEffect(() => {
    if (interfaces && interfaces.length > 0) {
      setAllInterfaces(interfaces);
    } else {
      fetchAllInterfaces();
    }
  }, [interfaces]);

  // Filtrer les interfaces en fonction du terme de recherche
  const filteredInterfaces = allInterfaces.filter(itf => {
    return (
      itf.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      itf.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (itf.description && itf.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  // Fonction pour charger toutes les interfaces
  const fetchAllInterfaces = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.interfaces.getAll();
      setAllInterfaces(response);
    } catch (err) {
      console.error("Erreur lors du chargement des interfaces :", err);
      setError("Impossible de charger les interfaces. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Configuration des colonnes pour la grille de données
  const columns = [
    {
      header: 'Code',
      key: 'code',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.code}
        </div>
      )
    },
    {
      header: 'Nom / Description',
      key: 'name',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.name}
          </div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Type',
      key: 'type',
      render: (row) => (
        <div>
          {row.type || '-'}
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => (
        <StatusBadge 
          status={row.status === 'ACTIVE' ? 'active' : 'inactive'} 
          customLabel={row.status === 'ACTIVE' ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      header: 'Exécution',
      key: 'execution',
      render: (row) => (
        <div className="flex flex-col space-y-1 text-xs">
          <div>
            <span className="font-medium">Succès:</span> {row.successCount || 0}
          </div>
          <div>
            <span className="font-medium">Échecs:</span> {row.failureCount || 0}
          </div>
          {row.lastExecutionTime && (
            <div>
              <span className="font-medium">Dernière:</span> <DateFormatter date={row.lastExecutionTime} format="relative" />
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Play}
            onClick={() => onSelectInterface(row)}
          >
            Exécutions
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Settings}
            onClick={(e) => {
              e.stopPropagation();
              // fetchInterface(row.code);
              // setShowConfigModal(true);
            }}
          >
            Config
          </Button>
          <Button
            variant="primary"
            size="sm"
            icon={Play}
            onClick={(e) => {
              e.stopPropagation();
              // handleExecuteInterface(row.code);
            }}
          >
            Exécuter
          </Button>
        </div>
      )
    }
  ];
  
  return (
    <div className="space-y-6">
      {/* En-tête de l'onglet */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
            <Network className="h-5 w-5 mr-2 text-blue-500" />
            Interfaces {partner ? `de ${partner.name}` : 'de tous les partenaires'}
          </h2>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchAllInterfaces}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              // resetForm();
              setShowCreateModal(true);
            }}
          >
            Nouvelle interface
          </Button>
        </div>
      </div>
      
      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher une interface..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      
      {/* Liste des interfaces */}
      <Card>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <DataGrid
            data={filteredInterfaces}
            columns={columns}
            emptyMessage="Aucune interface trouvée"
          />
        )}
      </Card>
      
      {/* Modaux (Create, Edit, Delete) - non affichés ici pour brièveté */}
    </div>
  );
};

// Composant pour l'onglet Exécutions
const ExecutionsTab = ({ executions }) => {
  // États pour gérer les données et les états UI
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [allExecutions, setAllExecutions] = useState([]);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  // Utiliser les exécutions transmises ou charger toutes les exécutions
  useEffect(() => {
    if (executions && executions.length > 0) {
      setAllExecutions(executions);
    } else {
      fetchAllExecutions();
    }
  }, [executions]);

  // Filtrer les exécutions en fonction du terme de recherche
  const filteredExecutions = allExecutions.filter(exec => {
    return (
      exec.executionId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (exec.itfDef?.code && exec.itfDef.code.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (exec.status && exec.status.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  // Fonction pour charger toutes les exécutions
  const fetchAllExecutions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.interfaceExecutions.getAllInterfacesStatus();
      setAllExecutions(response);
    } catch (err) {
      console.error("Erreur lors du chargement des exécutions :", err);
      setError("Impossible de charger les exécutions. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour réessayer une exécution
  const handleRetryExecution = async (executionId) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.interfaceExecutions.retryExecution(executionId);
      setSuccessMessage("Exécution relancée avec succès !");
      fetchAllExecutions();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la relance de l'exécution :", err);
      setError("Impossible de relancer l'exécution. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour annuler une exécution
  const handleCancelExecution = async (executionId) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.interfaceExecutions.cancelExecution(executionId);
      setSuccessMessage("Exécution annulée avec succès !");
      fetchAllExecutions();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'annulation de l'exécution :", err);
      setError("Impossible d'annuler l'exécution. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Configuration des colonnes pour la grille de données
  const columns = [
    {
      header: 'ID Exécution',
      key: 'executionId',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.executionId}
        </div>
      )
    },
    {
      header: 'Interface',
      key: 'interface',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.itfDef?.code || '-'}
          </div>
          {row.itfDef?.name && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
              {row.itfDef.name}
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => {
        let status = 'inactive';
        let label = row.status || 'Inconnu';
        
        switch (row.status) {
          case 'COMPLETED':
            status = 'active';
            label = 'Terminé';
            break;
          case 'FAILED':
            status = 'inactive';
            label = 'Échoué';
            break;
          case 'PENDING':
            status = 'pending';
            label = 'En attente';
            break;
          case 'RUNNING':
            status = 'pending';
            label = 'En cours';
            break;
          case 'CANCELLED':
            status = 'inactive';
            label = 'Annulé';
            break;
        }
        
        return (
          <StatusBadge 
            status={status} 
            customLabel={label} 
          />
        );
      }
    },
    {
      header: 'Horodatages',
      key: 'timestamps',
      render: (row) => (
        <div className="space-y-1 text-xs">
          <div>
            <span className="font-medium">Début:</span> <DateFormatter date={row.startTime} format="default" showTime={true} />
          </div>
          {row.endTime && (
            <div>
              <span className="font-medium">Fin:</span> <DateFormatter date={row.endTime} format="default" showTime={true} />
            </div>
          )}
          {row.result?.executionTimeMs && (
            <div>
              <span className="font-medium">Durée:</span> {row.result.executionTimeMs} ms
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Résultats',
      key: 'results',
      render: (row) => (
        <div className="space-y-1 text-xs">
          {row.result?.recordsProcessed !== undefined && (
            <div>
              <span className="font-medium">Traités:</span> {row.result.recordsProcessed}
            </div>
          )}
          {row.result?.successCount !== undefined && (
            <div>
              <span className="font-medium">Succès:</span> {row.result.successCount}
            </div>
          )}
          {row.result?.failureCount !== undefined && (
            <div>
              <span className="font-medium">Échecs:</span> {row.result.failureCount}
            </div>
          )}
          {row.retryCount > 0 && (
            <div>
              <span className="font-medium">Tentatives:</span> {row.retryCount + 1}
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Eye}
            onClick={() => {
              setSelectedExecution(row);
              setShowDetailsModal(true);
            }}
          >
            Détails
          </Button>
          {(row.status === 'FAILED') && (
            <Button
              variant="outline"
              size="sm"
              icon={RefreshCw}
              onClick={() => handleRetryExecution(row.executionId)}
            >
              Réessayer
            </Button>
          )}
          {(row.status === 'PENDING' || row.status === 'RUNNING') && (
            <Button
              variant="danger"
              size="sm"
              icon={X}
              onClick={() => handleCancelExecution(row.executionId)}
            >
              Annuler
            </Button>
          )}
        </div>
      )
    }
  ];
  
  return (
    <div className="space-y-6">
      {/* En-tête de l'onglet */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
            <Play className="h-5 w-5 mr-2 text-blue-500" />
            Exécutions d'Interfaces
          </h2>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchAllExecutions}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="outline"
            icon={Clock}
          >
            Dernières 24h
          </Button>
        </div>
      </div>
      
      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher une exécution..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      
      {/* Liste des exécutions */}
      <Card>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <DataGrid
            data={filteredExecutions}
            columns={columns}
            emptyMessage="Aucune exécution trouvée"
          />
        )}
      </Card>
      
      {/* Modal de détails d'exécution - non affiché ici pour brièveté */}
    </div>
  );
};

export default PartnersAndInterfaces;