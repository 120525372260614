import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ChevronLeft,
  Sun,
  Moon,
  Maximize2,
  Minimize2
} from 'lucide-react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Layout = ({ features = [] }) => {
  // États
  const [isOpen, setIsOpen] = useState(true);
  const [isDark, setIsDark] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(280);
  const [isResizing, setIsResizing] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const location = useLocation();

  // Gestion du thème
  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDark);
  }, [isDark]);

  // Gestion du responsive
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 1024;
      setIsMobile(mobile);
      if (mobile) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Gestion du plein écran
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  // Gestion du redimensionnement de la sidebar
  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsResizing(true);

    const handleMouseMove = (e) => {
      if (isResizing) {
        const newWidth = Math.max(250, Math.min(600, e.clientX));
        setSidebarWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // Animation variants pour le contenu principal
  const mainContentVariants = {
    open: { marginLeft: isMobile ? 0 : sidebarWidth },
    closed: { marginLeft: 0 }
  };

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-50 dark:bg-gray-900">
      {/* Navbar */}
      <Navbar 
        onMenuClick={() => setIsOpen(!isOpen)}
        isDark={isDark}
        setIsDark={setIsDark}
      />

      {/* Container principal */}
      <div className="flex flex-1 overflow-hidden pt-16">
        {/* Sidebar */}
        <motion.div
          initial={false}
          animate={{
            width: sidebarWidth,
            x: isOpen ? 0 : -sidebarWidth
          }}
          transition={{ type: "spring", bounce: 0, duration: 0.3 }}
          className="fixed top-16 bottom-0 left-0 z-30 overflow-hidden flex flex-col"
          style={{ height: 'calc(100vh - 4rem)' }} /* 4rem = 16 (pt-16) */
        >
          <div className="h-full w-full overflow-hidden">
            <Sidebar 
              features={features}
              isOpen={isOpen}
              onToggle={() => setIsOpen(!isOpen)}
              width={sidebarWidth}
              isDark={isDark}
              setIsDark={setIsDark}
              isFullscreen={isFullscreen}
              toggleFullscreen={toggleFullscreen}
            />
          </div>

          {/* Resize Handle */}
          {!isMobile && (
            <div
              className="absolute top-0 right-0 w-1 h-full cursor-ew-resize group"
              onMouseDown={handleMouseDown}
            >
              <div className="absolute top-0 right-0 w-1 h-full 
                            transition-colors duration-200
                            group-hover:bg-blue-500/50" />
            </div>
          )}
        </motion.div>

        {/* Toggle Sidebar Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsOpen(!isOpen)}
          className={`
            fixed z-40 bottom-4 transition-all duration-300
            w-10 h-10 rounded-full shadow-lg
            bg-gray-900 dark:bg-white 
            text-white dark:text-gray-900
            flex items-center justify-center
            ${isOpen ? 'left-[280px]' : 'left-4'}
            hover:bg-gray-800 dark:hover:bg-gray-100
          `}
          style={{ left: isOpen ? `${sidebarWidth + 10}px` : '1rem' }}
        >
          <ChevronLeft 
            size={20} 
            className={`transform transition-transform duration-300 ${isOpen ? '' : 'rotate-180'}`}
          />
        </motion.button>

        {/* Overlay pour mobile */}
        {isMobile && isOpen && (
          <div
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-20"
            onClick={() => setIsOpen(false)}
          />
        )}

        {/* Contenu principal */}
        <motion.main
          variants={mainContentVariants}
          animate={isOpen ? 'open' : 'closed'}
          transition={{ type: "spring", bounce: 0, duration: 0.3 }}
          className="flex-1 overflow-hidden"
        >
          <div className="h-full overflow-y-auto p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={location.pathname}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                <Outlet />
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.main>
      </div>
    </div>
  );
};

export default Layout;