import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Activity,
  CheckCircle,
  XCircle,
  AlertTriangle,
  Settings,
  Clock,
  RefreshCw,
  Server,
  Database,
  Network,
  Wrench 
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const HealthMetricCard = ({ 
  title, 
  value, 
  status = 'normal',
  icon: Icon,
  details,
  lastChecked,
  onClick
}) => (
  <Card className="cursor-pointer hover:shadow-lg transition-shadow duration-200" onClick={onClick}>
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className={`p-3 rounded-lg ${
            status === 'critical' ? 'bg-red-100 text-red-600' :
            status === 'warning' ? 'bg-yellow-100 text-yellow-600' :
            'bg-green-100 text-green-600'
          }`}>
            <Icon size={24} />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">{title}</h3>
            <p className="text-lg font-semibold">{value}</p>
          </div>
        </div>
        <StatusBadge 
          status={status} 
          size="sm"
        />
      </div>
      
      {details && (
        <div className="mt-4 pt-4 border-t border-gray-100 space-y-2">
          {Object.entries(details).map(([key, value]) => (
            <div key={key} className="flex justify-between text-sm">
              <span className="text-gray-500">{key}</span>
              <span className="font-medium">{value}</span>
            </div>
          ))}
        </div>
      )}

      {lastChecked && (
        <div className="mt-4 pt-4 border-t border-gray-100 flex items-center text-sm text-gray-500">
          <Clock size={14} className="mr-1" />
          Dernière vérification : <DateFormatter date={lastChecked} format="relative" />
        </div>
      )}
    </div>
  </Card>
);

const ServiceDetailModal = ({ service, onClose, onToggleMaintenance }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <Card className="w-full max-w-2xl">
      <div className="p-6">
        <div className="flex justify-between items-start mb-6">
          <div>
            <h3 className="text-lg font-semibold flex items-center">
              <Server className="mr-2" size={20} />
              {service.systemId}
            </h3>
            <p className="text-sm text-gray-500 mt-1">
              Détails du service
            </p>
          </div>
          <Button
            variant="text"
            size="sm"
            icon={XCircle}
            onClick={onClose}
          />
        </div>

        <div className="space-y-6">
          {/* État actuel */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center space-x-3">
              <StatusBadge 
                status={service.status.toLowerCase()}
                size="lg"
              />
              <div className="text-sm text-gray-500">
                Uptime: {service.uptime.toFixed(2)}%
              </div>
            </div>
            <Button
              variant={service.maintenance ? 'danger' : 'primary'}
              icon={service.maintenance ? Wrench  : Settings}
              onClick={() => onToggleMaintenance(service.systemId, !service.maintenance)}
            >
              {service.maintenance ? 'Désactiver maintenance' : 'Activer maintenance'}
            </Button>
          </div>

          {/* Composants */}
          <div>
            <h4 className="font-medium mb-3">Composants</h4>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(service.components).map(([name, status]) => (
                <div key={name} className="p-3 bg-white border rounded-lg flex justify-between items-center">
                  <span className="text-sm font-medium">{name}</span>
                  <StatusBadge 
                    status={status.toLowerCase()}
                    size="sm"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Détails */}
          {service.details && (
            <div>
              <h4 className="font-medium mb-3">Informations détaillées</h4>
              <div className="space-y-2">
                {Object.entries(service.details).map(([key, value]) => (
                  <div key={key} className="flex justify-between py-2 border-b">
                    <span className="text-sm text-gray-600">{key}</span>
                    <span className="text-sm font-medium">{value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Messages */}
          {service.message && (
            <div className="p-4 bg-yellow-50 rounded-lg">
              <div className="flex items-center">
                <AlertTriangle className="text-yellow-500 mr-2" size={16} />
                <p className="text-sm text-yellow-700">{service.message}</p>
              </div>
            </div>
          )}

          <div className="text-sm text-gray-500 flex items-center mt-4">
            <Clock size={14} className="mr-1" />
            Dernière vérification : <DateFormatter date={service.lastChecked} format="relative" />
          </div>
        </div>
      </div>
    </Card>
  </div>
);

const HealthMonitoring = () => {
  const navigate = useNavigate();
  const [systemHealth, setSystemHealth] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(true);

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }

    fetchData();

    if (autoRefresh) {
      const interval = setInterval(fetchData, 30000); // Rafraîchir toutes les 30 secondes
      return () => clearInterval(interval);
    }
  }, [navigate, autoRefresh]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [healthResponse, servicesResponse] = await Promise.all([
        apiService.monitoring.health.getSystem(),
        apiService.monitoring.health.getAllServices()
      ]);
      
      setSystemHealth(healthResponse);
      setServices(servicesResponse);
      setError(null);
    } catch (err) {
      setError("Erreur lors du chargement des données : " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleServiceClick = async (serviceId) => {
    try {
      const service = await apiService.monitoring.health.getService(serviceId);
      setSelectedService(service);
    } catch (err) {
      setError("Erreur lors du chargement du service : " + err.message);
    }
  };

  const handleToggleMaintenance = async (serviceId, maintenance) => {
    try {
      await apiService.monitoring.health.setMaintenance(serviceId, maintenance);
      // Rafraîchir les données après la mise à jour
      fetchData();
      setError(null);
    } catch (err) {
      setError("Erreur lors de la mise à jour du mode maintenance : " + err.message);
    }
  };

  if (loading && !systemHealth) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <Card>
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-bold">
              État de Santé du Système
            </h2>
            <p className="text-gray-500">
              Surveillance en temps réel des services et composants
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <Button
              variant={autoRefresh ? 'primary' : 'outline'}
              icon={RefreshCw}
              onClick={() => setAutoRefresh(!autoRefresh)}
            >
              Auto-refresh
            </Button>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={fetchData}
            >
              Actualiser
            </Button>
          </div>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2" />
              {error}
            </div>
          </div>
        )}
      </Card>

      {/* Vue d'ensemble du système */}
      {systemHealth && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <HealthMetricCard
            title="État Global"
            value={systemHealth.status}
            status={systemHealth.status.toLowerCase()}
            icon={Activity}
            details={{
              'Uptime': `${systemHealth.uptime.toFixed(2)}%`,
              'Version': systemHealth.version
            }}
            lastChecked={systemHealth.lastChecked}
          />
          
          {Object.entries(systemHealth.components || {}).map(([name, status]) => (
            <HealthMetricCard
              key={name}
              title={name}
              value={status}
              status={status.toLowerCase()}
              icon={
                name.includes('Database') ? Database :
                name.includes('Network') ? Network :
                Server
              }
              lastChecked={systemHealth.lastChecked}
            />
          ))}
        </div>
      )}

      {/* Liste des services */}
      <Card>
        <div className="mb-4">
          <h3 className="text-lg font-medium">Services</h3>
          <p className="text-sm text-gray-500">
            État détaillé de chaque service
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {services.map((service) => (
            <Card
              key={service.systemId}
              className="cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => handleServiceClick(service.systemId)}
            >
              <div className="p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <Server className="text-gray-400" />
                    <div>
                      <h4 className="font-medium">{service.systemId}</h4>
                      <p className="text-sm text-gray-500">
                        Uptime: {service.uptime?.toFixed(2)}%
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {service.maintenance && (
                      <StatusBadge 
                        status="warning"
                        customLabel="Maintenance"
                        size="sm"
                      />
                    )}
                    <StatusBadge 
                      status={service.status.toLowerCase()}
                      size="sm"
                    />
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </Card>

      {/* Modal de détails du service */}
      {selectedService && (
        <ServiceDetailModal
          service={selectedService}
          onClose={() => setSelectedService(null)}
          onToggleMaintenance={handleToggleMaintenance}
        />
      )}
    </div>
  );
};

export default HealthMonitoring;