import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ScrollText,
  RefreshCw,
  Filter,
  Download,
  Save,
  Search,
  Calendar,
  AlertTriangle,
  Info,
  Clock,
  User,
  Globe
} from 'lucide-react';
import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const AuditLogViewer = () => {
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(), // Dernier 24h
    endDate: new Date().toISOString()
  });

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchLogs();
  }, [navigate, filters]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await apiService.monitoring.audit.getLogs(
        filters.startDate,
        filters.endDate
      );
      setLogs(response);
      setError(null);
    } catch (err) {
      setError('Erreur lors du chargement des logs : ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleArchive = async () => {
    if (!window.confirm('Êtes-vous sûr de vouloir archiver les anciens logs ?')) {
      return;
    }

    try {
      await apiService.monitoring.audit.archiveOldLogs(filters.endDate);
      fetchLogs();
      setError(null);
    } catch (err) {
      setError('Erreur lors de l\'archivage : ' + err.message);
    }
  };

  const handleLogClick = async (id) => {
    try {
      const log = await apiService.monitoring.audit.getLog(id);
      setSelectedLog(log);
    } catch (err) {
      setError('Erreur lors du chargement du log : ' + err.message);
    }
  };

  const getEventTypeIcon = (eventType) => {
    switch (eventType?.toLowerCase()) {
      case 'create':
        return Save;
      case 'update':
        return RefreshCw;
      case 'delete':
        return AlertTriangle;
      default:
        return Info;
    }
  };

  const getEventTypeColor = (eventType) => {
    switch (eventType?.toLowerCase()) {
      case 'create':
        return 'text-green-500';
      case 'update':
        return 'text-blue-500';
      case 'delete':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  const columns = [
    {
      key: 'eventType',
      header: 'Type',
      render: (log) => {
        const Icon = getEventTypeIcon(log.eventType);
        return (
          <div className={`flex items-center ${getEventTypeColor(log.eventType)}`}>
            <Icon size={16} className="mr-2" />
            {log.eventType}
          </div>
        );
      }
    },
    {
      key: 'action',
      header: 'Action',
      render: (log) => (
        <div>
          <span className="font-medium">{log.action}</span>
          <div className="text-sm text-gray-500">
            Resource: {log.resourceId}
          </div>
        </div>
      )
    },
    {
      key: 'user',
      header: 'Utilisateur',
      render: (log) => (
        <div className="flex items-center space-x-2">
          <User size={16} />
          <span>{log.userId}</span>
          <span className="text-gray-400">|</span>
          <Globe size={16} />
          <span className="text-gray-500">{log.userIp}</span>
        </div>
      )
    },
    {
      key: 'result',
      header: 'Résultat',
      render: (log) => (
        <StatusBadge
          status={log.result === 'SUCCESS' ? 'success' : 'error'}
          customLabel={log.result}
        />
      )
    },
    {
      key: 'timestamp',
      header: 'Date',
      render: (log) => (
        <div className="flex items-center text-gray-500">
          <Clock size={16} className="mr-1" />
          <DateFormatter date={log.timestamp} format="full" />
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      <Card>
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">
              Journaux d'Audit
            </h2>
            <p className="text-gray-500 dark:text-gray-400">
              Historique des actions système
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <Button
              variant="outline"
              icon={Filter}
              onClick={() => setShowFilters(!showFilters)}
            >
              Filtres
            </Button>
            <Button
              variant="outline"
              icon={Save}
              onClick={handleArchive}
            >
              Archiver
            </Button>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={fetchLogs}
            >
              Actualiser
            </Button>
          </div>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 text-red-700 dark:text-red-400">
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2" />
              {error}
            </div>
          </div>
        )}

        {/* Filtres */}
        {showFilters && (
          <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-gray-300">
                  Date de début
                </label>
                <input
                  type="datetime-local"
                  value={filters.startDate.slice(0, 16)}
                  onChange={(e) => setFilters({
                    ...filters,
                    startDate: new Date(e.target.value).toISOString()
                  })}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-gray-300">
                  Date de fin
                </label>
                <input
                  type="datetime-local"
                  value={filters.endDate.slice(0, 16)}
                  onChange={(e) => setFilters({
                    ...filters,
                    endDate: new Date(e.target.value).toISOString()
                  })}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
            </div>
          </div>
        )}

        {/* Liste des logs */}
        <DataGrid
          data={logs}
          columns={columns}
          loading={loading}
          onRowClick={(log) => handleLogClick(log.id)}
          emptyMessage="Aucun log pour la période sélectionnée"
        />
      </Card>

      {/* Modal de détails du log */}
      {selectedLog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Card className="w-full max-w-2xl">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-lg font-medium">
                  Détails du Log
                </h3>
                <Button
                  variant="text"
                  icon={RefreshCw}
                  onClick={() => setSelectedLog(null)}
                />
              </div>

              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      ID Événement
                    </label>
                    <p>{selectedLog.eventId}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Type
                    </label>
                    <p>{selectedLog.eventType}</p>
                  </div>
                </div>

                <div>
                  <label className="text-sm font-medium text-gray-500">
                    Action
                  </label>
                  <p>{selectedLog.action}</p>
                </div>

                <div>
                  <label className="text-sm font-medium text-gray-500">
                    Resource
                  </label>
                  <p>{selectedLog.resourceId}</p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Utilisateur
                    </label>
                    <p>{selectedLog.userId}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      IP
                    </label>
                    <p>{selectedLog.userIp}</p>
                  </div>
                </div>

                {selectedLog.metadata && (
                  <div>
                    <label className="text-sm font-medium text-gray-500">
                      Métadonnées
                    </label>
                    <pre className="mt-1 p-2 bg-gray-50 dark:bg-gray-800 rounded-lg overflow-auto">
                      {JSON.stringify(selectedLog.metadata, null, 2)}
                    </pre>
                  </div>
                )}

                {selectedLog.errorDetails && (
                  <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
                    <label className="text-sm font-medium text-red-500">
                      Détails de l'erreur
                    </label>
                    <p className="mt-1 text-red-700 dark:text-red-400">
                      {selectedLog.errorDetails}
                    </p>
                  </div>
                )}

                <div className="text-sm text-gray-500">
                  <Clock size={14} className="inline mr-1" />
                  <DateFormatter date={selectedLog.timestamp} format="full" />
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default AuditLogViewer;