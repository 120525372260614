import React, { useState, useEffect } from 'react';
import { 
  BarChart2, 
  Server, 
  Settings, 
  Layers, 
  CheckCircle, 
  AlertTriangle,
  Folder,
  Database,
  Sliders,
  Eye,
  Grid,
  Box,
  Activity,
  RefreshCw,
  ExternalLink
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const ConfigDashboard = () => {
  // États
  const [environments, setEnvironments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalConfigs: 0,
    activeConfigs: 0,
    inactiveConfigs: 0,
    totalGroups: 0,
    totalEnvironments: 0,
    configsByScope: {},
    configsByGroup: {},
    environmentStats: []
  });
  
  // Chargement des données
  const fetchData = async () => {
    setLoading(true);
    
    try {
      const [envData, groupData, configData] = await Promise.all([
        apiService.config.environments.getAll(),
        apiService.config.groups.getAll(),
        apiService.config.configurations.getAll()
      ]);
      
      setEnvironments(envData || []);
      setGroups(groupData || []);
      setConfigurations(configData || []);
      
      // Calculer les statistiques
      calculateStats(envData || [], groupData || [], configData || []);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des données:', err);
      setError('Impossible de charger les données du tableau de bord. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Calculer les statistiques à partir des données
  const calculateStats = (envs, grps, configs) => {
    // Configs actives/inactives
    const activeConfigs = configs.filter(config => config.isActive).length;
    const inactiveConfigs = configs.length - activeConfigs;
    
    // Configs par portée
    const configsByScope = configs.reduce((acc, config) => {
      acc[config.scope] = (acc[config.scope] || 0) + 1;
      return acc;
    }, {});
    
    // Configs par groupe
    const configsByGroup = configs.reduce((acc, config) => {
      const groupName = config.group ? config.group.name : 'Sans groupe';
      acc[groupName] = (acc[groupName] || 0) + 1;
      return acc;
    }, {});
    
    // Statistiques par environnement
    const environmentStats = envs.map(env => {
      const configValues = configs.reduce((acc, config) => {
        const hasValue = config.values && config.values.some(val => val.environmentCode === env.code);
        if (hasValue) acc.configured++;
        else acc.unconfigured++;
        return acc;
      }, { configured: 0, unconfigured: 0 });
      
      return {
        code: env.code,
        name: env.name,
        type: env.type,
        isActive: env.isActive,
        configuredValues: configValues.configured,
        unconfiguredValues: configValues.unconfigured,
        totalConfigs: configValues.configured + configValues.unconfigured,
        configurationRate: Math.round((configValues.configured / (configs.length || 1)) * 100)
      };
    });
    
    setStats({
      totalConfigs: configs.length,
      activeConfigs,
      inactiveConfigs,
      totalGroups: grps.length,
      totalEnvironments: envs.length,
      configsByScope,
      configsByGroup,
      environmentStats
    });
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  const scopeColors = {
    GLOBAL: 'blue',
    ENVIRONMENT: 'green',
    TENANT: 'purple',
    APPLICATION: 'orange'
  };
  
  const scopeLabels = {
    GLOBAL: 'Global',
    ENVIRONMENT: 'Environnement',
    TENANT: 'Tenant',
    APPLICATION: 'Application'
  };
  
  const StatusBox = ({ icon: Icon, title, value, status, description }) => (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-5 flex flex-col">
      <div className="flex items-start justify-between">
        <div className="flex-shrink-0">
          <div className={`p-3 rounded-full bg-${status || 'blue'}-100 dark:bg-${status || 'blue'}-900/20`}>
            <Icon className={`h-6 w-6 text-${status || 'blue'}-600 dark:text-${status || 'blue'}-400`} />
          </div>
        </div>
        <div className="flex flex-col items-end">
          <div className="text-2xl font-bold text-gray-900 dark:text-white">
            {value}
          </div>
          <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
            {title}
          </div>
        </div>
      </div>
      {description && (
        <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">{description}</p>
      )}
    </div>
  );
  
  const ProgressBar = ({ value, max, color }) => {
    const percentage = Math.min(100, Math.max(0, (value / max) * 100));
    return (
      <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 my-2">
        <div 
          className={`bg-${color || 'blue'}-500 h-2.5 rounded-full`} 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    );
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <BarChart2 className="mr-2 h-6 w-6 text-blue-500" />
            Tableau de bord de configuration
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Vue d'ensemble du système de configuration
          </p>
        </div>
        
        <Button
          variant="outline"
          icon={RefreshCw}
          onClick={fetchData}
          loading={loading}
        >
          Actualiser
        </Button>
      </div>
      
      {/* Messages d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 mb-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-red-700 dark:text-red-300">{error}</span>
          </div>
        </div>
      )}
      
      {/* Statistiques globales */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatusBox 
          icon={Settings} 
          title="Configurations" 
          value={stats.totalConfigs}
          status="blue"
        />
        <StatusBox 
          icon={Folder} 
          title="Groupes" 
          value={stats.totalGroups}
          status="purple"
        />
        <StatusBox 
          icon={Database} 
          title="Environnements" 
          value={stats.totalEnvironments}
          status="green"
        />
        <StatusBox 
          icon={CheckCircle} 
          title="Configs actives" 
          value={`${stats.activeConfigs}/${stats.totalConfigs}`}
          status="green"
          description={`${stats.inactiveConfigs} configurations inactives`}
        />
      </div>
      
      {/* Distribution par portée */}
      <Card>
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
            Distribution par portée
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {Object.keys(scopeLabels).map(scope => (
              <div 
                key={scope}
                className={`border border-${scopeColors[scope]}-200 dark:border-${scopeColors[scope]}-800 rounded-lg p-4 bg-${scopeColors[scope]}-50 dark:bg-${scopeColors[scope]}-900/10`}
              >
                <div className="flex justify-between items-center mb-2">
                  <div className="font-medium text-gray-800 dark:text-white">
                    {scopeLabels[scope]}
                  </div>
                  <StatusBadge 
                    status={scopeColors[scope]} 
                    customLabel={stats.configsByScope[scope] || '0'}
                  />
                </div>
                <ProgressBar 
                  value={stats.configsByScope[scope] || 0} 
                  max={stats.totalConfigs} 
                  color={scopeColors[scope]}
                />
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {((stats.configsByScope[scope] || 0) / (stats.totalConfigs || 1) * 100).toFixed(1)}% du total
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      
      {/* Statistiques par environnement */}
      <Card>
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
            État des configurations par environnement
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Environnement
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Statut
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Valeurs configurées
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Progression
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                {stats.environmentStats.map((env) => (
                  <tr key={env.code}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {env.name}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <StatusBadge 
                        status={
                          env.type === 'DEVELOPMENT' ? 'blue' :
                          env.type === 'TESTING' ? 'purple' :
                          env.type === 'STAGING' ? 'orange' :
                          env.type === 'PRODUCTION' ? 'red' : 'yellow'
                        } 
                        customLabel={env.type}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <StatusBadge 
                        status={env.isActive ? 'active' : 'inactive'} 
                        customLabel={env.isActive ? 'Actif' : 'Inactif'}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-white">
                        {env.configuredValues} / {stats.totalConfigs}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-full max-w-xs">
                        <div className="flex justify-between items-center">
                          <div className="text-sm text-gray-500 dark:text-gray-400">
                            {env.configurationRate}%
                          </div>
                        </div>
                        <ProgressBar 
                          value={env.configuredValues} 
                          max={stats.totalConfigs} 
                          color={env.configurationRate >= 90 ? 'green' : env.configurationRate >= 60 ? 'yellow' : 'red'}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Button
                        variant="outline"
                        size="sm"
                        icon={Eye}
                        onClick={() => window.open(`/CFG/configvalues?env=${env.code}`, '_self')}
                      >
                        Voir
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
      
      {/* Analyse par groupe */}
      <Card>
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
            Distribution par groupe
          </h2>
          
          {Object.keys(stats.configsByGroup).length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                {Object.entries(stats.configsByGroup).slice(0, Math.ceil(Object.keys(stats.configsByGroup).length / 2)).map(([group, count]) => (
                  <div key={group} className="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
                    <div className="flex justify-between items-center mb-2">
                      <div className="font-medium text-gray-800 dark:text-white flex items-center">
                        <Folder className="h-4 w-4 mr-2 text-blue-500" />
                        {group}
                      </div>
                      <div className="text-sm font-medium text-gray-600 dark:text-gray-300">
                        {count} configuration{count > 1 ? 's' : ''}
                      </div>
                    </div>
                    <ProgressBar value={count} max={stats.totalConfigs} color="blue" />
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      {(count / stats.totalConfigs * 100).toFixed(1)}% du total
                    </div>
                  </div>
                ))}
              </div>
              <div className="space-y-4">
                {Object.entries(stats.configsByGroup).slice(Math.ceil(Object.keys(stats.configsByGroup).length / 2)).map(([group, count]) => (
                  <div key={group} className="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
                    <div className="flex justify-between items-center mb-2">
                      <div className="font-medium text-gray-800 dark:text-white flex items-center">
                        <Folder className="h-4 w-4 mr-2 text-blue-500" />
                        {group}
                      </div>
                      <div className="text-sm font-medium text-gray-600 dark:text-gray-300">
                        {count} configuration{count > 1 ? 's' : ''}
                      </div>
                    </div>
                    <ProgressBar value={count} max={stats.totalConfigs} color="blue" />
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      {(count / stats.totalConfigs * 100).toFixed(1)}% du total
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center py-12">
              <Folder className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                Aucun groupe de configuration
              </h3>
              <p className="text-gray-500 dark:text-gray-400 max-w-md mx-auto">
                Créez des groupes de configuration pour organiser vos paramètres et mieux les gérer.
              </p>
              <div className="mt-4">
                <Button
                  variant="outline"
                  icon={ExternalLink}
                  onClick={() => window.open('/CFG/configgroups', '_self')}
                >
                  Gérer les groupes
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
      
      {/* Liens rapides */}
      <Card>
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
            Accès rapides
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <Button
              variant="outline"
              icon={Database}
              onClick={() => window.open('/CFG/environments', '_self')}
              className="h-16 justify-start"
            >
              Gérer les environnements
            </Button>
            <Button
              variant="outline"
              icon={Folder}
              onClick={() => window.open('/CFG/configgroups', '_self')}
              className="h-16 justify-start"
            >
              Gérer les groupes
            </Button>
            <Button
              variant="outline"
              icon={Settings}
              onClick={() => window.open('/CFG/configurations', '_self')}
              className="h-16 justify-start"
            >
              Gérer les configurations
            </Button>
            <Button
              variant="outline"
              icon={Sliders}
              onClick={() => window.open('/CFG/configvalues', '_self')}
              className="h-16 justify-start"
            >
              Gérer les valeurs
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ConfigDashboard;