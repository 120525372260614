import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Bell,
  Plus,
  RefreshCw,
  AlertTriangle,
  CheckCircle,
  XCircle,
  Edit,
  Trash,
  User
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const AlertForm = ({ alert, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    severity: 'WARNING',
    rules: [
      {
        metricName: '',
        condition: '>',
        threshold: 0,
        durationMinutes: 5
      }
    ],
    targetSystem: '',
    notificationChannel: 'EMAIL'
  });

  useEffect(() => {
    if (alert) {
      setFormData(alert);
    }
  }, [alert]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const addRule = () => {
    setFormData(prev => ({
      ...prev,
      rules: [...prev.rules, {
        metricName: '',
        condition: '>',
        threshold: 0,
        durationMinutes: 5
      }]
    }));
  };

  const removeRule = (index) => {
    setFormData(prev => ({
      ...prev,
      rules: prev.rules.filter((_, i) => i !== index)
    }));
  };

  const updateRule = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      rules: prev.rules.map((rule, i) => 
        i === index ? { ...rule, [field]: value } : rule
      )
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <label className="block text-sm font-medium mb-1">Nom de l'alerte</label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="w-full p-2 border rounded-lg"
            required
          />
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium mb-1">Description</label>
          <textarea
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            className="w-full p-2 border rounded-lg"
            rows={3}
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Sévérité</label>
          <select
            value={formData.severity}
            onChange={(e) => setFormData({ ...formData, severity: e.target.value })}
            className="w-full p-2 border rounded-lg"
          >
            <option value="INFO">Information</option>
            <option value="WARNING">Avertissement</option>
            <option value="CRITICAL">Critique</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Système cible</label>
          <input
            type="text"
            value={formData.targetSystem}
            onChange={(e) => setFormData({ ...formData, targetSystem: e.target.value })}
            className="w-full p-2 border rounded-lg"
            required
          />
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium mb-2">Règles</label>
          <div className="space-y-4">
            {formData.rules.map((rule, index) => (
              <div key={index} className="flex gap-4 items-end">
                <div>
                  <label className="block text-xs mb-1">Métrique</label>
                  <input
                    type="text"
                    value={rule.metricName}
                    onChange={(e) => updateRule(index, 'metricName', e.target.value)}
                    className="w-full p-2 border rounded-lg"
                    required
                  />
                </div>

                <div>
                  <label className="block text-xs mb-1">Condition</label>
                  <select
                    value={rule.condition}
                    onChange={(e) => updateRule(index, 'condition', e.target.value)}
                    className="w-full p-2 border rounded-lg"
                  >
                    <option value=">">Supérieur à</option>
                    <option value="<">Inférieur à</option>
                    <option value="=">=</option>
                  </select>
                </div>

                <div>
                  <label className="block text-xs mb-1">Seuil</label>
                  <input
                    type="number"
                    value={rule.threshold}
                    onChange={(e) => updateRule(index, 'threshold', parseFloat(e.target.value))}
                    className="w-full p-2 border rounded-lg"
                    required
                  />
                </div>

                <div>
                  <label className="block text-xs mb-1">Durée (min)</label>
                  <input
                    type="number"
                    value={rule.durationMinutes}
                    onChange={(e) => updateRule(index, 'durationMinutes', parseInt(e.target.value))}
                    className="w-full p-2 border rounded-lg"
                    required
                  />
                </div>

                <Button
                  variant="text"
                  icon={XCircle}
                  onClick={() => removeRule(index)}
                  className="text-red-500"
                />
              </div>
            ))}

            <Button
              type="button"
              variant="outline"
              onClick={addRule}
              icon={Plus}
            >
              Ajouter une règle
            </Button>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Canal de notification</label>
          <select
            value={formData.notificationChannel}
            onChange={(e) => setFormData({ ...formData, notificationChannel: e.target.value })}
            className="w-full p-2 border rounded-lg"
          >
            <option value="EMAIL">Email</option>
            <option value="SLACK">Slack</option>
            <option value="WEBHOOK">Webhook</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end space-x-3">
        <Button variant="secondary" onClick={onCancel}>
          Annuler
        </Button>
        <Button type="submit" variant="primary">
          {alert ? 'Mettre à jour' : 'Créer'}
        </Button>
      </div>
    </form>
  );
};

const AlertsManagement = () => {
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchAlerts();
  }, [navigate]);

  const fetchAlerts = async () => {
    setLoading(true);
    try {
      const response = await apiService.monitoring.alerts.getAll();
      setAlerts(response);
      setError(null);
    } catch (err) {
      setError('Erreur lors du chargement des alertes : ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async (data) => {
    try {
      const response = await apiService.monitoring.alerts.create(data);
      setAlerts(prev => [...prev, response]);
      setShowForm(false);
      setError(null);
    } catch (err) {
      setError('Erreur lors de la création : ' + err.message);
    }
  };

  const handleUpdate = async (data) => {
    try {
      const response = await apiService.monitoring.alerts.update(selectedAlert.id, data);
      setAlerts(prev => prev.map(alert => 
        alert.id === selectedAlert.id ? response : alert
      ));
      setShowForm(false);
      setSelectedAlert(null);
      setError(null);
    } catch (err) {
      setError('Erreur lors de la mise à jour : ' + err.message);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette alerte ?')) return;
    
    try {
      await apiService.monitoring.alerts.delete(id);
      setAlerts(prev => prev.filter(alert => alert.id !== id));
      setError(null);
    } catch (err) {
      setError('Erreur lors de la suppression : ' + err.message);
    }
  };

  const handleAcknowledge = async (alertId, userId) => {
    try {
      const response = await apiService.monitoring.alerts.acknowledge(alertId, userId);
      setAlerts(prev => prev.map(alert => 
        alert.id === alertId ? response : alert
      ));
      setError(null);
    } catch (err) {
      setError('Erreur lors de l\'acquittement : ' + err.message);
    }
  };

  const columns = [
    {
      key: 'severity',
      header: 'Sévérité',
      render: (alert) => (
        <StatusBadge 
          status={alert.severity.toLowerCase()}
          customLabel={alert.severity}
        />
      )
    },
    {
      key: 'name',
      header: 'Nom',
      render: (alert) => (
        <div>
          <div className="font-medium">{alert.name}</div>
          <div className="text-sm text-gray-500">{alert.description}</div>
        </div>
      )
    },
    {
      key: 'rules',
      header: 'Règles',
      render: (alert) => (
        <div className="space-y-1">
          {alert.rules.map((rule, index) => (
            <div key={index} className="text-sm">
              {rule.metricName} {rule.condition} {rule.threshold}
              <span className="text-gray-500">
                {' '}pendant {rule.durationMinutes} min
              </span>
            </div>
          ))}
        </div>
      )
    },
    {
      key: 'targetSystem',
      header: 'Système',
      render: (alert) => alert.targetSystem
    },
    {
      key: 'status',
      header: 'Statut',
      render: (alert) => (
        <StatusBadge 
          status={alert.status.toLowerCase()}
          customLabel={alert.status}
        />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (alert) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={CheckCircle}
            onClick={() => handleAcknowledge(alert.id, 'currentUser')}
            className="text-green-500"
            title="Acquitter"
          />
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => {
              setSelectedAlert(alert);
              setShowForm(true);
            }}
            title="Modifier"
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash}
            onClick={() => handleDelete(alert.id)}
            className="text-red-500"
            title="Supprimer"
          />
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      <Card>
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-bold">
              Gestion des Alertes
            </h2>
            <p className="text-gray-500">
              Configurez et gérez les alertes du système
            </p>
          </div>
          <div className="flex space-x-4">
            <Button
              variant="outline"
              icon={Plus}
              onClick={() => {
                setSelectedAlert(null);
                setShowForm(true);
              }}
            >
              Nouvelle alerte
            </Button>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={fetchAlerts}
            >
              Actualiser
            </Button>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2" />
              {error}
            </div>
          </div>
        )}

        <DataGrid
          data={alerts}
          columns={columns}
          loading={loading}
          emptyMessage="Aucune alerte configurée"
        />
      </Card>

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Card className="w-full max-w-4xl">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium">
                {selectedAlert ? "Modifier l'alerte" : "Nouvelle alerte"}
              </h3>
              <Button
                variant="text"
                icon={XCircle}
                onClick={() => {
                  setShowForm(false);
                  setSelectedAlert(null);
                }}
              />
            </div>
            
            <AlertForm
              alert={selectedAlert}
              onSubmit={selectedAlert ? handleUpdate : handleCreate}
              onCancel={() => {
                setShowForm(false);
                setSelectedAlert(null);
              }}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default AlertsManagement;