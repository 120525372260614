import React, { useState, useEffect } from 'react';
import { 
  Folder, 
  FolderPlus, 
  Edit, 
  Trash2, 
  RefreshCw, 
  Eye, 
  EyeOff, 
  AlertTriangle,
  ChevronUp, 
  ChevronDown, 
  CheckCircle, 
  Save,
  X,
  Filter,
  Search,
  ArrowUpDown
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

/**
 * Document Categories Management
 * 
 * This component provides a complete interface for managing document categories
 * with features including:
 * - List view with sorting/filtering
 * - Create/edit forms
 * - Category ordering
 * - Status toggling
 */
const DocumentCategories = () => {
  // State for categories data
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [filterActive, setFilterActive] = useState(false);

  // State for selected category and modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(true);
  
  // State for reordering mode
  const [reorderMode, setReorderMode] = useState(false);
  const [reorderedCategories, setReorderedCategories] = useState([]);
  
  // State for form validation
  const [validationErrors, setValidationErrors] = useState({});

  // Form state
  const [categoryForm, setCategoryForm] = useState({
    code: '',
    name: '',
    description: '',
    sortOrder: 0,
    active: true
  });

  // Reset form state
  const resetForm = () => {
    setCategoryForm({
      code: '',
      name: '',
      description: '',
      sortOrder: 0,
      active: true
    });
    setValidationErrors({});
  };

  // Load categories on component mount
  useEffect(() => {
    fetchCategories();
  }, [filterActive]);

  // Fetch categories from API
  const fetchCategories = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await apiService.editic.categories.getAll(filterActive);
      
      // Sort categories by sortOrder
      const sortedCategories = [...response].sort((a, b) => a.sortOrder - b.sortOrder);
      setCategories(sortedCategories);
      setReorderedCategories(sortedCategories);
    } catch (err) {
      console.error('Error fetching document categories:', err);
      setError('Impossible de charger les catégories de documents. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  // Handle opening the create category modal
  const openCreateModal = () => {
    resetForm();
    setIsCreateMode(true);
    setShowModal(true);
  };

  // Handle opening the edit category modal
  const openEditModal = (category) => {
    setCategoryForm({
      code: category.code,
      name: category.name,
      description: category.description || '',
      sortOrder: category.sortOrder,
      active: category.active
    });
    setSelectedCategory(category);
    setIsCreateMode(false);
    setShowModal(true);
  };

  // Handle form change
  const handleFormChange = (field, value) => {
    // Clear validation error when field is updated
    if (validationErrors[field]) {
      setValidationErrors({
        ...validationErrors,
        [field]: null
      });
    }

    setCategoryForm(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!categoryForm.code.trim()) {
      errors.code = 'Le code est requis';
    } else if (!/^[A-Z0-9_]+$/.test(categoryForm.code)) {
      errors.code = 'Le code doit contenir uniquement des lettres majuscules, des chiffres et des underscores';
    }
    
    if (!categoryForm.name.trim()) {
      errors.name = 'Le nom est requis';
    }

    if (categoryForm.sortOrder === null || categoryForm.sortOrder === undefined) {
      errors.sortOrder = 'L\'ordre de tri est requis';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit form (create or update)
  const submitForm = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      if (isCreateMode) {
        // Create category
        await apiService.editic.categories.create(categoryForm);
        setSuccessMessage('Catégorie créée avec succès!');
      } else {
        // Update category
        await apiService.editic.categories.update(selectedCategory.id, categoryForm);
        setSuccessMessage('Catégorie mise à jour avec succès!');
      }
      
      // Refresh categories and close modal
      fetchCategories();
      setShowModal(false);
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error saving category:', err);
      setError(`Impossible de ${isCreateMode ? 'créer' : 'mettre à jour'} la catégorie.`);
    } finally {
      setLoading(false);
    }
  };

  // Delete a category
  const deleteCategory = async (id) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.editic.categories.delete(id);
      setSuccessMessage('Catégorie supprimée avec succès!');
      fetchCategories();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error deleting category:', err);
      setError('Impossible de supprimer la catégorie.');
    } finally {
      setLoading(false);
    }
  };

  // Toggle category status
  const toggleCategoryStatus = async (id) => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.editic.categories.toggleStatus(id);
      setSuccessMessage('Statut de la catégorie mis à jour avec succès!');
      fetchCategories();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error toggling category status:', err);
      setError('Impossible de changer le statut de la catégorie.');
    } finally {
      setLoading(false);
    }
  };

  // Start reordering mode
  const startReordering = () => {
    setReorderMode(true);
    setReorderedCategories([...categories]);
  };

  // Cancel reordering
  const cancelReordering = () => {
    setReorderMode(false);
  };

  // Save new order
  const saveNewOrder = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Save each category with its new order
      const updatePromises = reorderedCategories.map((category, index) => 
        apiService.editic.categories.updateOrder(category.id, index + 1)
      );
      
      await Promise.all(updatePromises);
      setSuccessMessage('Ordre des catégories mis à jour avec succès!');
      setReorderMode(false);
      fetchCategories();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating category order:', err);
      setError('Impossible de mettre à jour l\'ordre des catégories.');
    } finally {
      setLoading(false);
    }
  };

  // Move category up in order
  const moveCategoryUp = (index) => {
    if (index === 0) return;
    
    const newOrder = [...reorderedCategories];
    const temp = newOrder[index];
    newOrder[index] = newOrder[index - 1];
    newOrder[index - 1] = temp;
    
    setReorderedCategories(newOrder);
  };

  // Move category down in order
  const moveCategoryDown = (index) => {
    if (index === reorderedCategories.length - 1) return;
    
    const newOrder = [...reorderedCategories];
    const temp = newOrder[index];
    newOrder[index] = newOrder[index + 1];
    newOrder[index + 1] = temp;
    
    setReorderedCategories(newOrder);
  };

  // Column configuration for the DataGrid
  const columns = [
    {
      key: 'sortOrder',
      header: 'Ordre',
      sortable: true,
      render: (row) => (
        <span className="text-center font-medium">{row.sortOrder}</span>
      )
    },
    {
      key: 'name',
      header: 'Nom',
      sortable: true,
      render: (row) => (
        <div>
          <div className="font-medium text-blue-600 dark:text-blue-400">{row.name}</div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      key: 'code',
      header: 'Code',
      sortable: true,
      render: (row) => (
        <span className="font-mono text-sm bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded">
          {row.code}
        </span>
      )
    },
    {
      key: 'active',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={row.active ? 'active' : 'inactive'} 
          customLabel={row.active ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex items-center justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={row.active ? EyeOff : Eye}
            onClick={() => toggleCategoryStatus(row.id)}
            title={row.active ? 'Désactiver' : 'Activer'}
          />
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => openEditModal(row)}
            title="Modifier"
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => deleteCategory(row.id)}
            title="Supprimer"
          />
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Folder className="h-6 w-6 mr-2 text-blue-500" />
            Catégories de Documents
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les catégories pour organiser vos modèles de documents
          </p>
        </div>
        
        <div className="flex space-x-2">
          {!reorderMode ? (
            <>
              <Button
                variant="outline"
                icon={ArrowUpDown}
                onClick={startReordering}
              >
                Réorganiser
              </Button>
              <Button
                variant="outline"
                icon={RefreshCw}
                onClick={fetchCategories}
                loading={loading}
              >
                Actualiser
              </Button>
              <Button
                variant="primary"
                icon={FolderPlus}
                onClick={openCreateModal}
              >
                Nouvelle Catégorie
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outline"
                icon={X}
                onClick={cancelReordering}
              >
                Annuler
              </Button>
              <Button
                variant="primary"
                icon={Save}
                onClick={saveNewOrder}
                loading={loading}
              >
                Enregistrer l'ordre
              </Button>
            </>
          )}
        </div>
      </div>

      {/* Success message */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Error message */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Filters */}
      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <Filter className="h-5 w-5 text-gray-400" />
          <span className="text-gray-700 dark:text-gray-300">Filtre:</span>
        </div>
        <div className="flex items-center space-x-2">
          <input
            id="activeOnly"
            type="checkbox"
            checked={filterActive}
            onChange={() => setFilterActive(!filterActive)}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="activeOnly" className="text-sm text-gray-700 dark:text-gray-300">
            Afficher uniquement les catégories actives
          </label>
        </div>
      </div>

      {/* Categories list or reordering interface */}
      <Card>
        {reorderMode ? (
          <div className="space-y-2 p-2">
            <div className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              Faites glisser les catégories pour changer leur ordre ou utilisez les flèches.
            </div>
            {reorderedCategories.map((category, index) => (
              <div
                key={category.id}
                className="flex items-center justify-between p-3 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg mb-2 shadow-sm"
              >
                <div className="flex items-center">
                  <span className="font-medium text-gray-900 dark:text-white mr-4">
                    {index + 1}
                  </span>
                  <span className="text-gray-900 dark:text-white">
                    {category.name}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <Button
                    variant="text"
                    size="sm"
                    icon={ChevronUp}
                    onClick={() => moveCategoryUp(index)}
                    disabled={index === 0}
                  />
                  <Button
                    variant="text"
                    size="sm"
                    icon={ChevronDown}
                    onClick={() => moveCategoryDown(index)}
                    disabled={index === reorderedCategories.length - 1}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <DataGrid
            data={categories}
            columns={columns}
            loading={loading}
            emptyMessage="Aucune catégorie trouvée"
          />
        )}
      </Card>

      {/* Modal for creating/editing categories */}
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-lg w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <Folder className="h-5 w-5 mr-2 text-blue-500" />
                {isCreateMode ? 'Créer une nouvelle catégorie' : 'Modifier la catégorie'}
              </h3>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={submitForm}>
              <div className="px-6 py-4 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className={`w-full px-3 py-2 border ${
                      validationErrors.code 
                        ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                        : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                    } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                    value={categoryForm.code}
                    onChange={(e) => handleFormChange('code', e.target.value)}
                    placeholder="CODE_CATEGORIE"
                    disabled={!isCreateMode} // Code cannot be changed when editing
                  />
                  {validationErrors.code && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {validationErrors.code}
                    </p>
                  )}
                  {!validationErrors.code && (
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      Code unique en majuscules, chiffres et underscores (ex: DOC_CONTRACT)
                    </p>
                  )}
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className={`w-full px-3 py-2 border ${
                      validationErrors.name 
                        ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                        : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                    } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                    value={categoryForm.name}
                    onChange={(e) => handleFormChange('name', e.target.value)}
                    placeholder="Nom de la catégorie"
                  />
                  {validationErrors.name && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {validationErrors.name}
                    </p>
                  )}
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={categoryForm.description}
                    onChange={(e) => handleFormChange('description', e.target.value)}
                    placeholder="Description de la catégorie (optionnel)"
                    rows={3}
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Ordre de tri <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    min="0"
                    className={`w-full px-3 py-2 border ${
                      validationErrors.sortOrder 
                        ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                        : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                    } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                    value={categoryForm.sortOrder}
                    onChange={(e) => handleFormChange('sortOrder', parseInt(e.target.value || 0))}
                  />
                  {validationErrors.sortOrder && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {validationErrors.sortOrder}
                    </p>
                  )}
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Ordre d'affichage (les catégories sont triées par ordre croissant)
                  </p>
                </div>
                
                <div className="flex items-center">
                  <input
                    id="categoryActive"
                    type="checkbox"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={categoryForm.active}
                    onChange={(e) => handleFormChange('active', e.target.checked)}
                  />
                  <label htmlFor="categoryActive" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                    Actif
                  </label>
                </div>
              </div>
              
              <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 flex justify-end space-x-2">
                <Button
                  variant="outline"
                  onClick={() => setShowModal(false)}
                  type="button"
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  loading={loading}
                >
                  {isCreateMode ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentCategories;