import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const DataGrid = ({
  data = [],
  columns = [],
  selectable = false,
  onSelect = () => {},
  selectedItems = [],
  sortable = true,
  onSort = () => {},
  className = '',
  rowClassName = '',
  emptyMessage = "Aucune donnée disponible"
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });

  // Gestion du tri
  const handleSort = (key) => {
    if (!sortable) return;
    
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    
    setSortConfig({ key, direction });
    onSort(key, direction);
  };

  // Gestion des colonnes avec tri
  const renderHeaderCell = (column) => {
    const isSorted = sortConfig.key === column.key;
    const Icon = sortConfig.direction === 'asc' ? ChevronUp : ChevronDown;

    return (
      <th 
        key={column.key}
        className={`
          px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
          ${sortable && column.sortable !== false ? 'cursor-pointer hover:text-gray-700' : ''}
          ${column.headerClassName || ''}
        `}
        onClick={() => column.sortable !== false && handleSort(column.key)}
      >
        <div className="flex items-center space-x-1">
          <span>{column.header}</span>
          {sortable && column.sortable !== false && isSorted && (
            <Icon className="w-4 h-4" />
          )}
        </div>
      </th>
    );
  };

  // Rendu de la valeur d'une cellule
  const renderCell = (row, column) => {
    if (column.render) {
      return column.render(row);
    }

    let value = column.key.split('.').reduce((obj, key) => obj?.[key], row);
    return value ?? '-';
  };

  // Sélection de toutes les lignes
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      onSelect(data.map(item => item.id));
    } else {
      onSelect([]);
    }
  };

  // Sélection d'une ligne
  const handleSelectRow = (id) => {
    if (selectedItems.includes(id)) {
      onSelect(selectedItems.filter(itemId => itemId !== id));
    } else {
      onSelect([...selectedItems, id]);
    }
  };

  return (
    <div className={`overflow-x-auto ${className}`}>
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-50 dark:bg-gray-800">
          <tr>
            {selectable && (
              <th className="px-6 py-3 w-px">
                <input
                  type="checkbox"
                  className="rounded border-gray-300 text-blue-600"
                  checked={data.length > 0 && selectedItems.length === data.length}
                  onChange={handleSelectAll}
                />
              </th>
            )}
            {columns.map(renderHeaderCell)}
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
          {data.length === 0 ? (
            <tr>
              <td 
                colSpan={columns.length + (selectable ? 1 : 0)}
                className="px-6 py-4 text-center text-sm text-gray-500 dark:text-gray-400"
              >
                {emptyMessage}
              </td>
            </tr>
          ) : (
            data.map((row, rowIndex) => (
              <tr 
                key={row.id || rowIndex}
                className={`
                  hover:bg-gray-50 dark:hover:bg-gray-800/50
                  ${selectedItems.includes(row.id) ? 'bg-blue-50 dark:bg-blue-900/20' : ''}
                  ${rowClassName}
                `}
              >
                {selectable && (
                  <td className="px-6 py-4 whitespace-nowrap w-px">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300 text-blue-600"
                      checked={selectedItems.includes(row.id)}
                      onChange={() => handleSelectRow(row.id)}
                    />
                  </td>
                )}
                {columns.map((column, colIndex) => (
                  <td 
                    key={`${row.id}-${column.key || colIndex}`}
                    className={`px-6 py-4 whitespace-nowrap ${column.className || ''}`}
                  >
                    {renderCell(row, column)}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataGrid;