// Modifications à apporter au fichier DateFormatter.js pour corriger les erreurs

import React from 'react';

const DateFormatter = ({ 
  date, 
  format = 'default',
  className = '',
  showTime = false,
  locale = 'fr-FR'
}) => {
  // Vérifier si la date est valide avant de la formater
  const isValidDate = (date) => {
    if (!date) return false;
    
    // Si c'est déjà un objet Date
    if (date instanceof Date) return !isNaN(date.getTime());
    
    // Si c'est une chaîne ou un nombre, essayer de créer un objet Date
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime());
  };
  
  const formatDate = (date) => {
    // Vérifier si la date est valide
    if (!isValidDate(date)) {
      console.warn('Invalid date provided to DateFormatter');
      return 'Date invalide';
    }
    
    const dateObj = new Date(date);
    
    switch (format) {
      case 'relative':
        return getRelativeTime(dateObj);
      
      case 'time':
        return dateObj.toLocaleTimeString(locale, {
          hour: '2-digit',
          minute: '2-digit'
        });
      
      case 'full':
        return dateObj.toLocaleString(locale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: showTime ? '2-digit' : undefined,
          minute: showTime ? '2-digit' : undefined
        });
      
      case 'short':
        return dateObj.toLocaleDateString(locale, {
          year: '2-digit',
          month: 'short',
          day: 'numeric'
        });
      
      case 'month':
        return dateObj.toLocaleDateString(locale, {
          month: 'long',
          year: 'numeric'
        });

      case 'year':
        return dateObj.getFullYear().toString();
      
      case 'default':
      default:
        return dateObj.toLocaleDateString(locale, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: showTime ? '2-digit' : undefined,
          minute: showTime ? '2-digit' : undefined
        });
    }
  };

  const getRelativeTime = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `Il y a ${years} an${years > 1 ? 's' : ''}`;
    } else if (months > 0) {
      return `Il y a ${months} mois`;
    } else if (days > 7) {
      return formatDate(date);
    } else if (days > 0) {
      return `Il y a ${days} jour${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `Il y a ${hours} heure${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `Il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (seconds > 30) {
      return `Il y a ${seconds} seconde${seconds > 1 ? 's' : ''}`;
    } else {
      return 'À l\'instant';
    }
  };

  // Vérification des props et gestion des erreurs
  if (!date) {
    return <span className={className}>Date non disponible</span>;
  }

  // Vérifier si la date est valide avant de la passer à l'attribut datetime
  const validDateTime = isValidDate(date) ? new Date(date).toISOString() : null;
  
  const baseClasses = 'transition-colors duration-200';
  const finalClassName = `${baseClasses} ${className}`.trim();

  return (
    <time 
      dateTime={validDateTime}
      className={finalClassName}
      title={isValidDate(date) ? new Date(date).toLocaleString(locale) : 'Date non disponible'}
    >
      {formatDate(date)}
    </time>
  );
};

// Constantes pour les formats disponibles
DateFormatter.formats = {
  DEFAULT: 'default',
  RELATIVE: 'relative',
  TIME: 'time',
  FULL: 'full',
  SHORT: 'short',
  MONTH: 'month',
  YEAR: 'year'
};

export default DateFormatter;