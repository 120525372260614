import React from 'react';
import { 
  CheckCircle, 
  Clock, 
  XCircle, 
  AlertCircle, 
  PauseCircle,
  Circle
} from 'lucide-react';

const statusConfig = {
  active: {
    bg: 'bg-green-100 dark:bg-green-900/20',
    text: 'text-green-800 dark:text-green-400',
    icon: CheckCircle,
    border: 'border-green-200 dark:border-green-800/30',
    label: 'Actif'
  },
  pending: {
    bg: 'bg-yellow-100 dark:bg-yellow-900/20',
    text: 'text-yellow-800 dark:text-yellow-400',
    icon: Clock,
    border: 'border-yellow-200 dark:border-yellow-800/30',
    label: 'En attente'
  },
  inactive: {
    bg: 'bg-red-100 dark:bg-red-900/20',
    text: 'text-red-800 dark:text-red-400',
    icon: XCircle,
    border: 'border-red-200 dark:border-red-800/30',
    label: 'Inactif'
  },
  warning: {
    bg: 'bg-orange-100 dark:bg-orange-900/20',
    text: 'text-orange-800 dark:text-orange-400',
    icon: AlertCircle,
    border: 'border-orange-200 dark:border-orange-800/30',
    label: 'Attention'
  },
  paused: {
    bg: 'bg-gray-100 dark:bg-gray-900/20',
    text: 'text-gray-800 dark:text-gray-400',
    icon: PauseCircle,
    border: 'border-gray-200 dark:border-gray-800/30',
    label: 'En pause'
  },
  draft: {
    bg: 'bg-blue-100 dark:bg-blue-900/20',
    text: 'text-blue-800 dark:text-blue-400',
    icon: Circle,
    border: 'border-blue-200 dark:border-blue-800/30',
    label: 'Brouillon'
  }
};

const StatusBadge = ({ 
  status, 
  className = '', 
  customLabel,
  showIcon = true,
  size = 'md',
  variant = 'default'
}) => {
  // Configuration par défaut si le statut n'existe pas
  const config = statusConfig[status] || statusConfig.inactive;

  // Styles de taille
  const sizes = {
    sm: 'px-2 py-0.5 text-xs',
    md: 'px-2.5 py-1 text-sm',
    lg: 'px-3 py-1.5 text-base'
  };

  // Styles de variante
  const variants = {
    default: `${config.bg} ${config.text}`,
    outline: `border ${config.border} ${config.text} bg-transparent`,
    solid: `${config.text.replace('text', 'bg')} text-white dark:text-gray-900`
  };

  // Tailles d'icônes
  const iconSizes = {
    sm: 12,
    md: 14,
    lg: 16
  };

  return (
    <span 
      className={`
        inline-flex items-center gap-1.5 font-medium rounded-full
        transition-all duration-200
        ${sizes[size]}
        ${variants[variant]}
        ${className}
      `}
    >
      {showIcon && (
        <config.icon 
          size={iconSizes[size]} 
          className="shrink-0"
          aria-hidden="true"
        />
      )}
      <span>
        {customLabel || config.label}
      </span>
    </span>
  );
};

// Types pour l'autocomplétion
StatusBadge.statuses = {
  ACTIVE: 'active',
  PENDING: 'pending',
  INACTIVE: 'inactive',
  WARNING: 'warning',
  PAUSED: 'paused',
  DRAFT: 'draft'
};

StatusBadge.sizes = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
};

StatusBadge.variants = {
  DEFAULT: 'default',
  OUTLINE: 'outline',
  SOLID: 'solid'
};

export default StatusBadge;