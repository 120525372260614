import React, { useState, useEffect } from 'react';
import { 
  Users, 
  PlusCircle, 
  Trash2, 
  Edit, 
  Search, 
  RefreshCw, 
  Settings, 
  CheckCircle2, 
  XCircle, 
  Info, 
  ChevronDown,
  ChevronUp,
  Eye,
  Save,
  X
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const PartnersPage = () => {
  // États pour gérer les données et les états UI
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    description: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    address: '',
    country: '',
    category: '',
    metadata: {}
  });
  const [configData, setConfigData] = useState({
    connectionType: '',
    securityLevel: '',
    allowedInterfaces: [],
    parameters: {},
    sla: '',
    maxConcurrentConnections: 10,
    timeoutInSeconds: 30,
    maxRetries: 3,
    notificationEmail: '',
    notificationPhone: '',
    alertsEnabled: true,
    supportLevel: 'standard',
    encryptionType: '',
    companyIdentifier: '',
    isTestAccount: false
  });
  const [newAllowedInterface, setNewAllowedInterface] = useState('');
  const [newParamKey, setNewParamKey] = useState('');
  const [newParamValue, setNewParamValue] = useState('');

  // Filtrer les partenaires en fonction du terme de recherche
  const filteredPartners = partners.filter(partner => {
    return (
      partner.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (partner.description && partner.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (partner.category && partner.category.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  useEffect(() => {
    fetchPartners();
  }, []);

  // Fonction pour charger les partenaires
  const fetchPartners = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.partners.getAll();
      setPartners(response);
    } catch (err) {
      console.error("Erreur lors du chargement des partenaires :", err);
      setError("Impossible de charger les partenaires. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger un partenaire spécifique
  const fetchPartner = async (code) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.partners.getById(code);
      setSelectedPartner(response);
      
      // Préparer les données pour le formulaire d'édition
      setFormData({
        code: response.code,
        name: response.name,
        description: response.description || '',
        contactName: response.contactName || '',
        contactEmail: response.contactEmail || '',
        contactPhone: response.contactPhone || '',
        address: response.address || '',
        country: response.country || '',
        category: response.category || '',
        metadata: response.metadata || {}
      });
      
      // Préparer les données pour le formulaire de configuration
      setConfigData({
        connectionType: response.config?.connectionType || '',
        securityLevel: response.config?.securityLevel || '',
        allowedInterfaces: response.config?.allowedInterfaces || [],
        parameters: response.config?.parameters || {},
        sla: response.config?.sla || '',
        maxConcurrentConnections: response.config?.maxConcurrentConnections || 10,
        timeoutInSeconds: response.config?.timeoutInSeconds || 30,
        maxRetries: response.config?.maxRetries || 3,
        notificationEmail: response.config?.notificationEmail || '',
        notificationPhone: response.config?.notificationPhone || '',
        alertsEnabled: response.config?.alertsEnabled !== false,
        supportLevel: response.config?.supportLevel || 'standard',
        encryptionType: response.config?.encryptionType || '',
        companyIdentifier: response.config?.companyIdentifier || '',
        isTestAccount: response.config?.isTestAccount || false
      });
    } catch (err) {
      console.error("Erreur lors du chargement du partenaire :", err);
      setError("Impossible de charger les détails du partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de création de partenaire
  const handleCreatePartner = async () => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.create(formData);
      setSuccessMessage("Partenaire créé avec succès !");
      setShowCreateModal(false);
      fetchPartners();
      resetForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création du partenaire :", err);
      setError("Impossible de créer le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de mise à jour de partenaire
  const handleUpdatePartner = async () => {
    if (!selectedPartner) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.update(selectedPartner.code, formData);
      setSuccessMessage("Partenaire mis à jour avec succès !");
      setShowEditModal(false);
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du partenaire :", err);
      setError("Impossible de mettre à jour le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de mise à jour de configuration
  const handleUpdateConfig = async () => {
    if (!selectedPartner) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.updateConfig(selectedPartner.code, configData);
      setSuccessMessage("Configuration du partenaire mise à jour avec succès !");
      setShowConfigModal(false);
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la configuration :", err);
      setError("Impossible de mettre à jour la configuration. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de suppression de partenaire
  const handleDeletePartner = async () => {
    if (!selectedPartner) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.delete(selectedPartner.code);
      setSuccessMessage("Partenaire supprimé avec succès !");
      setShowDeleteModal(false);
      setSelectedPartner(null);
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression du partenaire :", err);
      setError("Impossible de supprimer le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire d'activation de partenaire
  const handleActivatePartner = async (code) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.activate(code);
      setSuccessMessage("Partenaire activé avec succès !");
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'activation du partenaire :", err);
      setError("Impossible d'activer le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de désactivation de partenaire
  const handleDeactivatePartner = async (code) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.partners.deactivate(code);
      setSuccessMessage("Partenaire désactivé avec succès !");
      fetchPartners();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la désactivation du partenaire :", err);
      setError("Impossible de désactiver le partenaire. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Réinitialisation du formulaire
  const resetForm = () => {
    setFormData({
      code: '',
      name: '',
      description: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      address: '',
      country: '',
      category: '',
      metadata: {}
    });
  };

  // Gestion des changements dans le formulaire
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Gestion des changements dans le formulaire de configuration
  const handleConfigChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConfigData({
      ...configData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Ajout d'une interface autorisée à la liste
  const handleAddAllowedInterface = () => {
    if (newAllowedInterface.trim() === '') return;
    
    setConfigData({
      ...configData,
      allowedInterfaces: [...configData.allowedInterfaces, newAllowedInterface]
    });
    setNewAllowedInterface('');
  };

  // Retrait d'une interface autorisée de la liste
  const handleRemoveAllowedInterface = (index) => {
    const updatedInterfaces = [...configData.allowedInterfaces];
    updatedInterfaces.splice(index, 1);
    setConfigData({
      ...configData,
      allowedInterfaces: updatedInterfaces
    });
  };

  // Ajout d'un paramètre à la configuration
  const handleAddParameter = () => {
    if (newParamKey.trim() === '') return;
    
    setConfigData({
      ...configData,
      parameters: {
        ...configData.parameters,
        [newParamKey]: newParamValue
      }
    });
    setNewParamKey('');
    setNewParamValue('');
  };

  // Retrait d'un paramètre de la configuration
  const handleRemoveParameter = (key) => {
    const updatedParams = { ...configData.parameters };
    delete updatedParams[key];
    setConfigData({
      ...configData,
      parameters: updatedParams
    });
  };

  // Configuration des colonnes pour la grille de données
  const columns = [
    {
      header: 'Code',
      key: 'code',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.code}
        </div>
      )
    },
    {
      header: 'Nom / Description',
      key: 'name',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.name}
          </div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Catégorie',
      key: 'category',
      render: (row) => (
        <div>
          {row.category || '-'}
        </div>
      )
    },
    {
      header: 'Contact',
      key: 'contact',
      render: (row) => (
        <div>
          <div>{row.contactName || '-'}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {row.contactEmail || '-'}
          </div>
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => (
        <StatusBadge 
          status={row.status === 'ACTIVE' ? 'active' : 'inactive'} 
          customLabel={row.status === 'ACTIVE' ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              fetchPartner(row.code);
              setShowConfigModal(true);
            }}
          >
            Config
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            onClick={(e) => {
              e.stopPropagation();
              fetchPartner(row.code);
              setShowEditModal(true);
            }}
          >
            Modifier
          </Button>
          {row.status === 'ACTIVE' ? (
            <Button
              variant="outline"
              size="sm"
              icon={XCircle}
              onClick={(e) => {
                e.stopPropagation();
                handleDeactivatePartner(row.code);
              }}
            >
              Désactiver
            </Button>
          ) : (
            <Button
              variant="outline"
              size="sm"
              icon={CheckCircle2}
              onClick={(e) => {
                e.stopPropagation();
                handleActivatePartner(row.code);
              }}
            >
              Activer
            </Button>
          )}
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPartner(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Users className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Partenaires d'Intégration
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez vos partenaires d'intégration et leurs configurations
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchPartners}
            loading={loading && !showCreateModal && !showEditModal && !showConfigModal && !showDeleteModal}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetForm();
              setShowCreateModal(true);
            }}
          >
            Nouveau partenaire
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher un partenaire..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des partenaires */}
      <Card>
        {loading && !showCreateModal && !showEditModal && !showConfigModal && !showDeleteModal ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <DataGrid
            data={filteredPartners}
            columns={columns}
            emptyMessage="Aucun partenaire trouvé"
          />
        )}
      </Card>

      {/* Modal de création de partenaire */}
      {showCreateModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-3xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer un nouveau partenaire
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Code*
                    </label>
                    <input
                      type="text"
                      name="code"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.code}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={3}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Catégorie
                    </label>
                    <input
                      type="text"
                      name="category"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.category}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                
                {/* Informations de contact */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du contact
                    </label>
                    <input
                      type="text"
                      name="contactName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactName}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Email du contact
                    </label>
                    <input
                      type="email"
                      name="contactEmail"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactEmail}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Téléphone du contact
                    </label>
                    <input
                      type="text"
                      name="contactPhone"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactPhone}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Adresse
                    </label>
                    <input
                      type="text"
                      name="address"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.address}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Pays
                    </label>
                    <input
                      type="text"
                      name="country"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.country}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreatePartner}
                  loading={loading}
                  disabled={!formData.code || !formData.name}
                >
                  Créer le partenaire
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'édition de partenaire */}
      {showEditModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-3xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Modifier le partenaire
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowEditModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Code*
                    </label>
                    <input
                      type="text"
                      name="code"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400 cursor-not-allowed"
                      value={formData.code}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={3}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Catégorie
                    </label>
                    <input
                      type="text"
                      name="category"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.category}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                
                {/* Informations de contact */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du contact
                    </label>
                    <input
                      type="text"
                      name="contactName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactName}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Email du contact
                    </label>
                    <input
                      type="email"
                      name="contactEmail"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactEmail}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Téléphone du contact
                    </label>
                    <input
                      type="text"
                      name="contactPhone"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.contactPhone}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Adresse
                    </label>
                    <input
                      type="text"
                      name="address"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.address}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Pays
                    </label>
                    <input
                      type="text"
                      name="country"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.country}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowEditModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUpdatePartner}
                  loading={loading}
                  disabled={!formData.name}
                >
                  Mettre à jour
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de configuration du partenaire */}
      {showConfigModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Configuration du partenaire: {selectedPartner?.name}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowConfigModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Paramètres généraux */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Paramètres généraux
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de connexion
                    </label>
                    <select
                      name="connectionType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.connectionType}
                      onChange={handleConfigChange}
                    >
                      <option value="">Sélectionner un type</option>
                      <option value="REST">REST</option>
                      <option value="SOAP">SOAP</option>
                      <option value="FILE">Fichier</option>
                      <option value="FTP">FTP</option>
                      <option value="DATABASE">Base de données</option>
                      <option value="JMS">JMS</option>
                      <option value="CUSTOM">Personnalisé</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Niveau de sécurité
                    </label>
                    <select
                      name="securityLevel"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.securityLevel}
                      onChange={handleConfigChange}
                    >
                      <option value="">Sélectionner un niveau</option>
                      <option value="LOW">Faible</option>
                      <option value="MEDIUM">Moyen</option>
                      <option value="HIGH">Élevé</option>
                      <option value="VERY_HIGH">Très élevé</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de chiffrement
                    </label>
                    <input
                      type="text"
                      name="encryptionType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.encryptionType}
                      onChange={handleConfigChange}
                      placeholder="ex: AES256, RSA2048"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Identifiant d'entreprise
                    </label>
                    <input
                      type="text"
                      name="companyIdentifier"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.companyIdentifier}
                      onChange={handleConfigChange}
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      SLA
                    </label>
                    <input
                      type="text"
                      name="sla"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.sla}
                      onChange={handleConfigChange}
                      placeholder="ex: 99.9%"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Niveau de support
                    </label>
                    <select
                      name="supportLevel"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.supportLevel}
                      onChange={handleConfigChange}
                    >
                      <option value="basic">Basique</option>
                      <option value="standard">Standard</option>
                      <option value="premium">Premium</option>
                      <option value="enterprise">Entreprise</option>
                    </select>
                  </div>
                  
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="isTestAccount"
                      name="isTestAccount"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={configData.isTestAccount}
                      onChange={handleConfigChange}
                    />
                    <label htmlFor="isTestAccount" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Compte de test
                    </label>
                  </div>
                </div>
                
                {/* Paramètres techniques */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Paramètres techniques
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nombre maximal de connexions simultanées
                    </label>
                    <input
                      type="number"
                      name="maxConcurrentConnections"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.maxConcurrentConnections}
                      onChange={handleConfigChange}
                      min="1"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Délai d'attente (en secondes)
                    </label>
                    <input
                      type="number"
                      name="timeoutInSeconds"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.timeoutInSeconds}
                      onChange={handleConfigChange}
                      min="1"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nombre maximal de nouvelles tentatives
                    </label>
                    <input
                      type="number"
                      name="maxRetries"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.maxRetries}
                      onChange={handleConfigChange}
                      min="0"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Email de notification
                    </label>
                    <input
                      type="email"
                      name="notificationEmail"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.notificationEmail}
                      onChange={handleConfigChange}
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Téléphone de notification
                    </label>
                    <input
                      type="text"
                      name="notificationPhone"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.notificationPhone}
                      onChange={handleConfigChange}
                    />
                  </div>
                  
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="alertsEnabled"
                      name="alertsEnabled"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={configData.alertsEnabled}
                      onChange={handleConfigChange}
                    />
                    <label htmlFor="alertsEnabled" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Activer les alertes
                    </label>
                  </div>
                </div>
              </div>
              
              {/* Interfaces autorisées */}
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
                  Interfaces autorisées
                </h3>
                
                <div className="flex space-x-2 mb-3">
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newAllowedInterface}
                    onChange={(e) => setNewAllowedInterface(e.target.value)}
                    placeholder="Ajouter une interface autorisée"
                  />
                  <Button
                    variant="outline"
                    onClick={handleAddAllowedInterface}
                    disabled={newAllowedInterface.trim() === ''}
                  >
                    Ajouter
                  </Button>
                </div>
                
                <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                  {configData.allowedInterfaces.length > 0 ? (
                    <ul className="space-y-2">
                      {configData.allowedInterfaces.map((itf, index) => (
                        <li key={index} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                          <span className="text-gray-900 dark:text-white">{itf}</span>
                          <button
                            className="text-red-500 hover:text-red-600"
                            onClick={() => handleRemoveAllowedInterface(index)}
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                      Aucune interface autorisée définie
                    </div>
                  )}
                </div>
              </div>
              
              {/* Paramètres supplémentaires */}
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
                  Paramètres supplémentaires
                </h3>
                
                <div className="flex space-x-2 mb-3">
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newParamKey}
                    onChange={(e) => setNewParamKey(e.target.value)}
                    placeholder="Clé"
                  />
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newParamValue}
                    onChange={(e) => setNewParamValue(e.target.value)}
                    placeholder="Valeur"
                  />
                  <Button
                    variant="outline"
                    onClick={handleAddParameter}
                    disabled={newParamKey.trim() === ''}
                  >
                    Ajouter
                  </Button>
                </div>
                
                <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                  {Object.keys(configData.parameters).length > 0 ? (
                    <ul className="space-y-2">
                      {Object.entries(configData.parameters).map(([key, value]) => (
                        <li key={key} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                          <div>
                            <span className="font-medium text-gray-900 dark:text-white">{key}:</span>
                            <span className="ml-2 text-gray-700 dark:text-gray-300">{value}</span>
                          </div>
                          <button
                            className="text-red-500 hover:text-red-600"
                            onClick={() => handleRemoveParameter(key)}
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                      Aucun paramètre supplémentaire défini
                    </div>
                  )}
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowConfigModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUpdateConfig}
                  loading={loading}
                >
                  Enregistrer la configuration
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20">
                  <Trash2 className="h-6 w-6 text-red-600 dark:text-red-500" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  Supprimer le partenaire
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir supprimer le partenaire "{selectedPartner?.name}" ? Cette action est irréversible.
                </p>
              </div>
              
              <div className="mt-6 flex justify-center space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  onClick={handleDeletePartner}
                  loading={loading}
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnersPage;