// components/shared/workflow/ImprovedNodeModal.js
import React, { useState, useEffect } from 'react';
import { X, AlertTriangle, Settings, File, GitBranch, Tag, PlusCircle } from 'lucide-react';
import WorkflowDomainSelector from './WorkflowDomainSelector';

/**
 * Modal amélioré pour l'ajout et l'édition des nœuds (états)
 * Prend en compte les statuts du domaine métier
 */
export function ImprovedNodeModal({
  isOpen,
  node,
  type = 'add', // 'add' ou 'edit'
  nodeType, // 'stateNode', 'initialNode', 'finalNode'
  workflowType = null,
  onClose,
  onSubmit,
  onChange
}) {
  const [validationErrors, setValidationErrors] = useState([]);
  const [useStatusCode, setUseStatusCode] = useState(false);
  
  // Réinitialiser les erreurs à l'ouverture
  useEffect(() => {
    if (isOpen) {
      setValidationErrors([]);
      setUseStatusCode(node.data?.useStatusCode || false);
    }
  }, [isOpen, node]);
  
  if (!isOpen) return null;
  
  // Validation du formulaire avant soumission
  const handleSubmit = () => {
    const errors = [];
    
    // Vérifier que le label est défini
    if (!node.data?.label || node.data.label.trim() === '') {
      errors.push('Le nom de l\'état est obligatoire');
    }
    
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }
    
    onSubmit();
  };
  
  // Mettre à jour les données du nœud
  const updateNodeData = (key, value) => {
    const newData = { ...(node.data || {}) };
    newData[key] = value;
    
    onChange({
      ...node,
      data: newData
    });
  };
  
  // Obtenir le titre en fonction du type de nœud
  const getTitle = () => {
    const action = type === 'add' ? 'Ajouter' : 'Modifier';
    
    switch (nodeType) {
      case 'initialNode':
        return `${action} l'état initial`;
      case 'finalNode':
        return `${action} l'état final`;
      default:
        return `${action} un état`;
    }
  };
  
  // Obtenir l'icône en fonction du type de nœud
  const getIcon = () => {
    switch (nodeType) {
      case 'initialNode':
        return <GitBranch className="h-5 w-5 text-green-500" />;
      case 'finalNode':
        return <Tag className="h-5 w-5 text-red-500" />;
      default:
        return <File className="h-5 w-5 text-blue-500" />;
    }
  };
  
  // Obtenir la classe CSS pour l'en-tête du modal
  const getHeaderClass = () => {
    switch (nodeType) {
      case 'initialNode':
        return 'bg-green-50 dark:bg-green-900/20 text-green-700 dark:text-green-400';
      case 'finalNode':
        return 'bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-400';
      default:
        return 'bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-400';
    }
  };
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-[500px] max-w-full max-h-[90vh] overflow-y-auto">
        {/* En-tête du modal */}
        <div className={`flex justify-between items-center p-4 rounded-t-lg ${getHeaderClass()}`}>
          <div className="flex items-center">
            {getIcon()}
            <h3 className="text-lg font-semibold ml-2">
              {getTitle()}
            </h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
          >
            <X size={20} />
          </button>
        </div>
        
        {/* Corps du modal */}
        <div className="p-6">
          {/* Afficher les erreurs de validation */}
          {validationErrors.length > 0 && (
            <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800/30 rounded-md">
              <div className="flex">
                <AlertTriangle className="h-5 w-5 text-red-400 dark:text-red-500 mr-2" />
                <div className="text-sm text-red-700 dark:text-red-400">
                  <div className="font-medium">Veuillez corriger les erreurs suivantes :</div>
                  <ul className="mt-1 list-disc list-inside">
                    {validationErrors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          
          {/* Section des champs de formulaire */}
          <div className="space-y-4">
            {/* Option pour utiliser un statut du domaine métier */}
            <div className="flex items-center">
              <input
                id="use-status-code"
                type="checkbox"
                checked={useStatusCode}
                onChange={(e) => {
                  setUseStatusCode(e.target.checked);
                  updateNodeData('useStatusCode', e.target.checked);
                }}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="use-status-code" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                Utiliser un statut existant
              </label>
              <div className="ml-1">
                <div className="relative group">
                  <div className="cursor-help text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                    Sélectionner un statut prédéfini dans le domaine métier au lieu de créer un nouvel état personnalisé.
                  </div>
                </div>
              </div>
            </div>
            
            {useStatusCode ? (
              /* Sélecteur de statut du domaine */
              <WorkflowDomainSelector
                type="status"
                label="Statut"
                placeholder="Sélectionner un statut..."
                selectedItems={node.data?.statusCode ? [node.data.statusCode] : []}
                onChange={(selected) => {
                  updateNodeData('statusCode', selected);
                  // Si un statut est sélectionné, on met à jour aussi le label
                  if (selected) {
                    updateNodeData('label', selected);
                  }
                }}
                multiple={false}
                required={true}
                workflowType={workflowType}
              />
            ) : (
              /* Champ pour créer un nouvel état personnalisé */
              <div>
                <label htmlFor="node-label" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Nom de l'état <span className="text-red-500">*</span>
                </label>
                <input
                  id="node-label"
                  type="text"
                  value={node.data?.label || ''}
                  onChange={(e) => updateNodeData('label', e.target.value)}
                  className="mt-1 w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Nom de l'état"
                />
              </div>
            )}
            
            {/* Description */}
            <div>
              <label htmlFor="node-description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Description
              </label>
              <textarea
                id="node-description"
                value={node.data?.description || ''}
                onChange={(e) => updateNodeData('description', e.target.value)}
                className="mt-1 w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Description de l'état"
                rows={3}
              />
            </div>
            
            {/* Options avancées */}
            <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
              <details className="group">
                <summary className="flex items-center cursor-pointer">
                  <Settings size={16} className="text-gray-500 dark:text-gray-400 mr-2" />
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Options avancées</span>
                  <svg className="ml-2 h-5 w-5 text-gray-500 group-open:rotate-180 transition-transform" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </summary>
                
                <div className="mt-4 space-y-4 pl-2">
                  {/* Identifiant technique */}
                  <div>
                    <label htmlFor="node-code" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Identifiant technique
                    </label>
                    <input
                      id="node-code"
                      type="text"
                      value={node.data?.code || ''}
                      onChange={(e) => updateNodeData('code', e.target.value)}
                      className="mt-1 w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="ETAT_CODE"
                    />
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      Code technique utilisé dans le système (généré automatiquement si vide)
                    </p>
                  </div>
                  
                  {/* Timeout */}
                  <div>
                    <label htmlFor="node-timeout" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Timeout (minutes)
                    </label>
                    <input
                      id="node-timeout"
                      type="number"
                      min="0"
                      step="1"
                      value={node.data?.timeout || ''}
                      onChange={(e) => updateNodeData('timeout', e.target.value ? parseInt(e.target.value) : '')}
                      className="mt-1 w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="0"
                    />
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      Temps maximum (en minutes) avant timeout (0 = pas de timeout)
                    </p>
                  </div>
                  
                  {/* Actions d'entrée */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Actions d'entrée
                    </label>
                    <WorkflowDomainSelector
                      type="action"
                      placeholder="Sélectionner les actions..."
                      selectedItems={node.data?.entryActions || []}
                      onChange={(selected) => updateNodeData('entryActions', selected)}
                      multiple={true}
                      allowCustom={true}
                    />
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      Actions exécutées automatiquement lors de l'entrée dans cet état
                    </p>
                  </div>
                  
                  {/* Actions de sortie */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Actions de sortie
                    </label>
                    <WorkflowDomainSelector
                      type="action"
                      placeholder="Sélectionner les actions..."
                      selectedItems={node.data?.exitActions || []}
                      onChange={(selected) => updateNodeData('exitActions', selected)}
                      multiple={true}
                      allowCustom={true}
                    />
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      Actions exécutées automatiquement lors de la sortie de cet état
                    </p>
                  </div>
                </div>
              </details>
            </div>
          </div>
          
          {/* Pied du modal avec boutons d'action */}
          <div className="mt-6 flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Annuler
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {type === 'add' ? 'Ajouter' : 'Mettre à jour'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImprovedNodeModal;