import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AlertCircle, 
  Download, 
  Filter, 
  RefreshCw,
  Eye,
  Search,
  Calendar,
  User,
  Tag
} from 'lucide-react';
import apiService from '../../services/api.service';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';

const AuditLogs = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [logs, setLogs] = useState([]);
  const [searchParams, setSearchParams] = useState({
    severity: null,
    userId: '',
    resourceType: '',
    resourceId: '',
    limit: 20,
    page: 0
  });
  const [totalItems, setTotalItems] = useState(0);
  const [showFilters, setShowFilters] = useState(false);

  // Chargement initial et rafraîchissement
  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchLogs();
  }, [navigate, searchParams]);

  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'timestamp',
      header: 'Date',
      render: (log) => (
        <div className="flex items-center">
          <Calendar className="w-4 h-4 text-gray-400 mr-2" />
          <DateFormatter date={log.action.performedAt} format="full" />
        </div>
      )
    },
    {
      key: 'action',
      header: 'Action',
      render: (log) => (
        <div>
          <span className="font-medium">{log.action.actionName}</span>
          <p className="text-sm text-gray-500 mt-1">{log.action.description}</p>
        </div>
      )
    },
    {
      key: 'user',
      header: 'Utilisateur',
      render: (log) => (
        <div className="flex items-center">
          <User className="w-4 h-4 text-gray-400 mr-2" />
          <span>{log.action.performedBy}</span>
        </div>
      )
    },
    {
      key: 'resource',
      header: 'Ressource',
      render: (log) => (
        <div className="flex items-center">
          <Tag className="w-4 h-4 text-gray-400 mr-2" />
          <div>
            <span className="font-medium">{log.action.resourceType}</span>
            <p className="text-sm text-gray-500">{log.action.resourceId}</p>
          </div>
        </div>
      )
    },
    {
      key: 'severity',
      header: 'Sévérité',
      render: (log) => (
        <StatusBadge 
          status={log.severity.toLowerCase()} 
          customLabel={log.severity}
        />
      )
    },
    {
      key: 'status',
      header: 'Statut',
      render: (log) => (
        <StatusBadge 
          status={log.success ? 'success' : 'error'}
          customLabel={log.success ? 'Succès' : 'Échec'}
        />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (log) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            onClick={() => navigate(`/audit/${log.id}`)}
            title="Voir les détails"
          />
        </div>
      )
    }
  ];

  // Fonction pour charger les logs
  const fetchLogs = async () => {
    setLoading(true);
    setError('');

    try {
      let response;

      if (searchParams.severity) {
        response = await apiService.audit.getBySeverity(
          searchParams.severity, 
          searchParams.limit
        );
      } else if (searchParams.userId) {
        response = await apiService.audit.getByUser(
          searchParams.userId,
          searchParams.page,
          searchParams.limit
        );
      } else if (searchParams.resourceType && searchParams.resourceId) {
        response = await apiService.audit.getByResource(
          searchParams.resourceType,
          searchParams.resourceId,
          searchParams.page,
          searchParams.limit
        );
      } else {
        response = await apiService.audit.getRecent(searchParams.limit);
      }

      setLogs(response);

      // Si la réponse contient des métadonnées de pagination
      if (response.totalElements) {
        setTotalItems(response.totalElements);
      }
    } catch (err) {
      setError('Erreur lors du chargement des logs : ' + err.message);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de changement de page
  const handlePageChange = (newPage) => {
    setSearchParams(prev => ({
      ...prev,
      page: newPage
    }));
  };

  // Gestionnaire pour les filtres
  const handleFilterChange = (filters) => {
    setSearchParams(prev => ({
      ...prev,
      ...filters,
      page: 0 // Réinitialiser la page lors du changement de filtres
    }));
  };

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <Card>
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">
              Logs d'Audit
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Consultez et analysez l'historique des actions système
            </p>
          </div>

          <div className="flex items-center space-x-4">
            <Button
              variant="outline"
              icon={Filter}
              onClick={() => setShowFilters(!showFilters)}
            >
              Filtres
            </Button>
            <Button
              variant="outline"
              icon={Download}
              onClick={() => navigate('/audit/export')}
            >
              Exporter
            </Button>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={fetchLogs}
              loading={loading}
            >
              Actualiser
            </Button>
          </div>
        </div>

        {/* Message d'erreur */}
        {error && (
          <div className="mb-6">
            <div className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-900/10 dark:text-red-400">
              <AlertCircle className="w-4 h-4 mr-2" />
              {error}
            </div>
          </div>
        )}

        {/* Filtres */}
        {showFilters && (
          <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Sévérité
                </label>
                <select
                  value={searchParams.severity || ''}
                  onChange={(e) => handleFilterChange({ severity: e.target.value || null })}
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                >
                  <option value="">Toutes</option>
                  <option value="INFO">Information</option>
                  <option value="WARNING">Avertissement</option>
                  <option value="ERROR">Erreur</option>
                  <option value="CRITICAL">Critique</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Utilisateur
                </label>
                <input
                  type="text"
                  value={searchParams.userId}
                  onChange={(e) => handleFilterChange({ userId: e.target.value })}
                  placeholder="ID utilisateur"
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Type de ressource
                </label>
                <input
                  type="text"
                  value={searchParams.resourceType}
                  onChange={(e) => handleFilterChange({ resourceType: e.target.value })}
                  placeholder="Type de ressource"
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  ID de ressource
                </label>
                <input
                  type="text"
                  value={searchParams.resourceId}
                  onChange={(e) => handleFilterChange({ resourceId: e.target.value })}
                  placeholder="ID de la ressource"
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                />
              </div>
            </div>
          </div>
        )}
      </Card>

      {/* Tableau des logs */}
      <Card>
        <DataGrid
          data={logs}
          columns={columns}
          loading={loading}
          pagination={{
            page: searchParams.page,
            pageSize: searchParams.limit,
            totalItems: totalItems,
            onPageChange: handlePageChange
          }}
          emptyMessage="Aucun log d'audit trouvé"
        />
      </Card>
    </div>
  );
};

export default AuditLogs;