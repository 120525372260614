import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BarChart, RefreshCw, Filter, Clock,
  Layers, GitBranch, Database, Activity
} from 'lucide-react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import DateFormatter from '../../components/shared/DateFormatter';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const ApplicationMetrics = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedApp, setSelectedApp] = useState('');
  const [timeRange, setTimeRange] = useState({
    startTime: new Date(Date.now() - 3600000).toISOString(),
    endTime: new Date().toISOString()
  });

  const applications = [
    { id: 'app1', name: 'Application 1' },
    { id: 'app2', name: 'Application 2' },
    { id: 'app3', name: 'Application 3' }
  ];

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    if (selectedApp) {
      fetchMetrics();
    }
  }, [navigate, selectedApp, timeRange]);

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const response = await apiService.monitoring.metrics.application.getAll(
        selectedApp,
        timeRange.startTime,
        timeRange.endTime
      );
      setMetrics(response);
      setError(null);
    } catch (err) {
      setError(`Erreur lors de la récupération des métriques : ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const timeRangeOptions = [
    { value: '1h', label: 'Dernière heure', ms: 3600000 },
    { value: '6h', label: '6 heures', ms: 21600000 },
    { value: '24h', label: '24 heures', ms: 86400000 }
  ];

  const updateTimeRange = (ms) => {
    setTimeRange({
      startTime: new Date(Date.now() - ms).toISOString(),
      endTime: new Date().toISOString()
    });
  };

  const columns = [
    {
      key: 'timestamp',
      header: 'Horodatage',
      render: (row) => <DateFormatter date={row.timestamp} format="full" />
    },
    {
      key: 'metricName',
      header: 'Métrique',
      render: (row) => row.metricName
    },
    {
      key: 'value',
      header: 'Valeur',
      render: (row) => `${row.value?.toFixed(2)} ${row.unit || ''}`
    },
    {
      key: 'environment',
      header: 'Environnement',
      render: (row) => (
        <StatusBadge
          status={row.environment.toLowerCase()}
          customLabel={row.environment}
        />
      )
    },
    {
      key: 'tags',
      header: 'Tags',
      render: (row) => (
        <div className="flex flex-wrap gap-2">
          {Object.entries(row.tags || {}).map(([key, value]) => (
            <span
              key={key}
              className="px-2 py-1 text-xs rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300"
            >
              {key}: {value}
            </span>
          ))}
        </div>
      )
    }
  ];

  const MetricCard = ({ title, value, unit, icon: Icon, trend }) => (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-3 rounded-lg bg-blue-100 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400">
            <Icon size={24} />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</h3>
            <div className="flex items-baseline space-x-2">
              <p className="text-2xl font-semibold">{value}</p>
              {unit && <span className="text-sm text-gray-500">{unit}</span>}
            </div>
          </div>
        </div>
        {trend && (
          <div className={`flex items-center text-sm ${trend > 0 ? 'text-green-500' : 'text-red-500'}`}>
            {trend > 0 ? '+' : ''}{trend}%
          </div>
        )}
      </div>
    </Card>
  );

  const getAggregatedMetrics = () => {
    if (!metrics.length) return {};
    
    const latest = metrics.reduce((acc, metric) => {
      if (!acc[metric.metricName] || new Date(metric.timestamp) > new Date(acc[metric.metricName].timestamp)) {
        acc[metric.metricName] = metric;
      }
      return acc;
    }, {});

    return latest;
  };

  const aggregatedMetrics = getAggregatedMetrics();

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Métriques Applications
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Surveillance des performances des applications
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            value={selectedApp}
            onChange={(e) => setSelectedApp(e.target.value)}
            className="rounded-lg border border-gray-300 p-2"
          >
            <option value="">Sélectionner une application</option>
            {applications.map(app => (
              <option key={app.id} value={app.id}>{app.name}</option>
            ))}
          </select>

          <select
            value={timeRangeOptions.find(opt => opt.ms === (new Date(timeRange.endTime) - new Date(timeRange.startTime)))?.value}
            onChange={(e) => updateTimeRange(timeRangeOptions.find(opt => opt.value === e.target.value).ms)}
            className="rounded-lg border border-gray-300 p-2"
          >
            {timeRangeOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchMetrics}
            disabled={!selectedApp}
          >
            Actualiser
          </Button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4">
          <p className="text-red-700 dark:text-red-400">{error}</p>
        </div>
      )}

      {selectedApp ? (
        <>
          {/* Métriques clés */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <MetricCard
              title="Temps de réponse"
              value={aggregatedMetrics['response_time']?.value?.toFixed(2) || '-'}
              unit="ms"
              icon={Clock}
              trend={-2.5}
            />
            <MetricCard
              title="Requêtes/sec"
              value={aggregatedMetrics['requests_per_second']?.value?.toFixed(2) || '-'}
              icon={Activity}
              trend={1.8}
            />
            <MetricCard
              title="Utilisation mémoire"
              value={aggregatedMetrics['memory_usage']?.value?.toFixed(2) || '-'}
              unit="%"
              icon={Database}
              trend={0.5}
            />
            <MetricCard
              title="Threads actifs"
              value={aggregatedMetrics['active_threads']?.value?.toFixed(0) || '-'}
              icon={GitBranch}
              trend={-1.2}
            />
          </div>

          {/* Graphique des performances */}
          <Card>
            <div className="p-6">
              <h2 className="text-lg font-semibold mb-4">Évolution des performances</h2>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={metrics} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="timestamp"
                      tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                    />
                    <YAxis />
                    <Tooltip
                      labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                    />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Card>

          {/* Tableau détaillé */}
          <Card>
            <div className="p-6">
              <h2 className="text-lg font-semibold mb-4">Historique détaillé</h2>
              <DataGrid
                data={metrics}
                columns={columns}
                loading={loading}
                emptyMessage="Aucune donnée disponible pour la période sélectionnée"
              />
            </div>
          </Card>
        </>
      ) : (
        <Card>
          <div className="p-6 text-center">
            <Layers className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">Aucune application sélectionnée</h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Sélectionnez une application pour voir ses métriques
            </p>
          </div>
        </Card>
      )}
    </div>
  );
};

export default ApplicationMetrics;