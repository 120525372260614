// services/api.service.js
import config from '../config/env';
import EventSourcePolyfill from 'eventsource-polyfill';
import { createRealTimeConnection } from '../utils/real-time-utils';


let activeConnections = {};

const getOrCreateRealTimeConnection = (userId) => {
  const connectionKey = `sse_${userId}`;
  
  if (!activeConnections[connectionKey]) {
    activeConnections[connectionKey] = createRealTimeConnection({
      baseUrl: config.API_URL,
      endpoint: '/automata/record/events',
      pollingEndpoint: '/automata/record/poll',
      userId,
      debug: true
    });
    activeConnections[connectionKey].connect();
  }
  
  return activeConnections[connectionKey];
};


const isTokenExpired = (token) => {
  if (!token) return true;
  
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = JSON.parse(window.atob(base64));
    const expiration = payload.exp * 1000; // Convertir en millisecondes
    return Date.now() >= expiration;
  } catch (error) {
    console.error('Erreur lors de la vérification du token:', error);
    return true;
  }
};

const handleTokenExpiration = () => {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('refresh_token');
  window.location.href = '/login';
};

/*
const callApi = async (endpoint, options = {}) => {
  const token = localStorage.getItem('auth_token');
  
  // Vérifier si le token est expiré
  if (token && isTokenExpired(token)) {
    handleTokenExpiration();
    throw new Error('Token expiré');
  }
  
  if (!token && !endpoint.includes('/auth/login')) {
    console.warn('No token found for protected endpoint:', endpoint);
    handleTokenExpiration();
    throw new Error('Authentication required');
  }

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    console.log('Making API call to:', `${config.API_URL}${endpoint}`);
    console.log('With headers:', headers);
    
    const response = await fetch(`${config.API_URL}${endpoint}`, {
      ...options,
      headers: {
        ...headers,
        ...options.headers
      }
    });

    if (!response.ok) {
      if (response.status === 401) {
        handleTokenExpiration();
        throw new Error('Session expirée');
      }
      
      const errorText = await response.text();
      console.error('API Error:', {
        status: response.status,
        statusText: response.statusText,
        error: errorText
      });
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('API Call failed:', error);
    throw error;
  }
};

*/

const callApi = async (endpoint, options = {}) => {
  const token = localStorage.getItem('auth_token');
  
  // Vérifier si le token est expiré
  if (token && isTokenExpired(token)) {
    handleTokenExpiration();
    throw new Error('Token expiré');
  }
  
  if (!token && !endpoint.includes('/auth/login')) {
    console.warn('No token found for protected endpoint:', endpoint);
    handleTokenExpiration();
    throw new Error('Authentication required');
  }

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  // Traitement des paramètres uniquement pour les requêtes GET
  let url = `${config.API_URL}${endpoint}`;
  if (options.method === 'GET' && options.params) {
    const queryParams = new URLSearchParams();
    for (const key in options.params) {
      if (options.params[key] !== undefined && options.params[key] !== null) {
        queryParams.append(key, options.params[key]);
      }
    }
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
  }

  try {
    console.log('Making API call to:', url);
    console.log('With headers:', headers);
    
    // Créer une copie des options sans modifier l'original
    const fetchOptions = { ...options };
    
    // Pour les méthodes GET, supprimer params de fetchOptions
    // car on l'a déjà traité dans l'URL
    if (fetchOptions.method === 'GET') {
      delete fetchOptions.params;
    }
    
    const response = await fetch(url, {
      ...fetchOptions,
      headers: {
        ...headers,
        ...options.headers
      }
    });

    if (!response.ok) {
      if (response.status === 401) {
        handleTokenExpiration();
        throw new Error('Session expirée');
      }
      
      const errorText = await response.text();
      console.error('API Error:', {
        status: response.status,
        statusText: response.statusText,
        error: errorText
      });
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('API Call failed:', error);
    throw error;
  }
};

const apiService = {
  auth: {
    login: async (credentials) => {
      try {
        const response = await callApi('/auth/login', {
          method: 'POST',
          body: JSON.stringify({
            username: credentials.username || credentials.login,
            password: credentials.password
          })
        });
        
        if (response.accessToken) {
          console.log('Storing tokens...');
          localStorage.setItem('auth_token', response.accessToken);
          localStorage.setItem('refresh_token', response.refreshToken);
          console.log('Tokens stored successfully');
        } else {
          console.error('No access token in response:', response);
          throw new Error('Authentication failed: No access token received');
        }
        
        return response;
      } catch (error) {
        console.error('Login failed:', error);
        throw error;
      }
    },

    logout: async () => {
      localStorage.removeItem('auth_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login';
    },

    isAuthenticated: () => {
      const token = localStorage.getItem('auth_token');
      return token && !isTokenExpired(token);
    }
  },
  
declarationWorkflow: {
    // Méthode existante
    start: async (workflowData) => {
      return await callApi('/declaration-workflows/start', {
        method: 'POST',
        body: JSON.stringify(workflowData)
      });
    },

    // Ajout de la méthode getAll
    getAll: async () => {
      return await callApi('/declaration-workflows', {
        method: 'GET'
      });
    },

    // Ajout de la méthode initiatePayment 
    initiatePayment: async (reference, paymentData) => {
      return await callApi(`/declaration-workflows/${reference}/initiate-payment`, {
        method: 'POST',
        body: JSON.stringify(paymentData)
      });
    },

    // Il serait également utile d'ajouter ces autres méthodes utilisées dans le workflow
    validatePayment: async (reference) => {
      return await callApi(`/declaration-workflows/${reference}/validate-payment`, {
        method: 'POST'
      });
    },

    completePayment: async (reference, completionData) => {
      return await callApi(`/declaration-workflows/${reference}/complete-payment`, {
        method: 'POST',
        body: JSON.stringify(completionData)
      });
    },

    getStatus: async (reference) => {
      return await callApi(`/declaration-workflows/${reference}`, {
        method: 'GET'
      });
    },

    getHistory: async (reference) => {
      return await callApi(`/declaration-workflows/${reference}/history`, {
        method: 'GET'  
      });
    }
  },
  
  
  
  
 // À ajouter dans l'objet apiService existant dans api.service.js
security: {
  users: {
    getAll: async () => {
      return await callApi('/security/users', {
        method: 'GET'
      });
    },
    
    getById: async (id) => {
      return await callApi(`/security/users/${id}`, {
        method: 'GET'
      });
    },

    create: async (userData) => {
      return await callApi('/security/users', {
        method: 'POST',
        body: JSON.stringify(userData)
      });
    },

    update: async (id, userData) => {
      return await callApi(`/security/users/${id}`, {
        method: 'PUT',
        body: JSON.stringify(userData)
      });
    },

    delete: async (id) => {
      return await callApi(`/security/users/${id}`, {
        method: 'DELETE'
      });
    }
  },

  roles: {
    getAll: async () => {
      return await callApi('/security/roles', {
        method: 'GET'
      });
    },

    getById: async (id) => {
      return await callApi(`/security/roles/${id}`, {
        method: 'GET'
      });
    },

    create: async (roleData) => {
      return await callApi('/security/roles', {
        method: 'POST',
        body: JSON.stringify(roleData)
      });
    },

    delete: async (id) => {
      return await callApi(`/security/roles/${id}`, {
        method: 'DELETE'
      });
    }
  },

  permissions: {
    getAll: async () => {
      return await callApi('/security/permissions', {
        method: 'GET'
      });
    },

    getById: async (id) => {
      return await callApi(`/security/permissions/${id}`, {
        method: 'GET'
      });
    },

    create: async (permissionData) => {
      return await callApi('/security/permissions', {
        method: 'POST',
        body: JSON.stringify(permissionData)
      });
    },

    getByGroup: async (group) => {
      return await callApi(`/security/permissions/group/${group}`, {
        method: 'GET'
      });
    },

    delete: async (id) => {
      return await callApi(`/security/permissions/${id}`, {
        method: 'DELETE'
      });
    }
  },

  organizations: {
    getAll: async () => {
      return await callApi('/security/organizations', {
        method: 'GET'
      });
    },

    getById: async (id) => {
      return await callApi(`/security/organizations/${id}`, {
        method: 'GET'
      });
    },

    create: async (orgData) => {
      return await callApi('/security/organizations', {
        method: 'POST',
        body: JSON.stringify(orgData)
      });
    },

    update: async (id, orgData) => {
      return await callApi(`/security/organizations/${id}`, {
        method: 'PUT',
        body: JSON.stringify(orgData)
      });
    },

    updateStatus: async (id, status) => {
      return await callApi(`/security/organizations/${id}/status`, {
        method: 'PUT',
        params: { status }
      });
    },

    delete: async (id) => {
      return await callApi(`/security/organizations/${id}`, {
        method: 'DELETE'
      });
    }
  }
},
  
  // À ajouter dans l'objet apiService

config: {
  configurations: {
    getAll: async () => {
      return await callApi('/configuration/configurations', {
        method: 'GET'
      });
    },
    
    getByKey: async (key) => {
      return await callApi(`/configuration/configurations/${key}`, {
        method: 'GET'
      });
    },
    
    create: async (configData) => {
      return await callApi('/configuration/configurations', {
        method: 'POST',
        body: JSON.stringify(configData)
      });
    },
    
    update: async (key, configData) => {
      return await callApi(`/configuration/configurations/${key}`, {
        method: 'PUT',
        body: JSON.stringify(configData)
      });
    },
    
    delete: async (key) => {
      return await callApi(`/configuration/configurations/${key}`, {
        method: 'DELETE'
      });
    },
    
    getByGroup: async (groupName) => {
      return await callApi(`/configuration/configurations/group/${groupName}`, {
        method: 'GET'
      });
    },
    
    setValue: async (key, value, environmentCode) => {
      return await callApi(`/configuration/configurations/${key}/value`, {
        method: 'POST',
        body: JSON.stringify({ value }),
        params: { environmentCode }
      });
    },
    
    getValue: async (key, environmentCode) => {
      return await callApi(`/configuration/configurations/${key}/value?environmentCode=${environmentCode}`, {
        method: 'GET'
      });
    },
    
    getValues: async (keys, environmentCode) => {
      return await callApi('/configuration/configurations/bulk-values', {
        method: 'POST',
        body: JSON.stringify(keys),
        params: { environmentCode }
      });
    },
    
    refreshCache: async () => {
      return await callApi('/configuration/configurations/refresh-cache', {
        method: 'POST'
      });
    }
  },
  
  groups: {
    getAll: async () => {
      return await callApi('/configuration/configuration-groups', {
        method: 'GET'
      });
    },
    
    getById: async (id) => {
      return await callApi(`/configuration/configuration-groups/${id}`, {
        method: 'GET'
      });
    },
    
    create: async (groupData) => {
      return await callApi('/configuration/configuration-groups', {
        method: 'POST',
        body: JSON.stringify(groupData)
      });
    },
    
    update: async (id, groupData) => {
      return await callApi(`/configuration/configuration-groups/${id}`, {
        method: 'PUT',
        body: JSON.stringify(groupData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/configuration/configuration-groups/${id}`, {
        method: 'DELETE'
      });
    }
  },
  
  environments: {
    getAll: async () => {
      return await callApi('/configuration/environments', {
        method: 'GET'
      });
    },
    
    getByCode: async (code) => {
      return await callApi(`/configuration/environments/${code}`, {
        method: 'GET'
      });
    },
    
    create: async (environmentData) => {
      return await callApi('/configuration/environments', {
        method: 'POST',
        body: JSON.stringify(environmentData)
      });
    },
    
    update: async (code, environmentData) => {
      return await callApi(`/configuration/environments/${code}`, {
        method: 'PUT', 
        body: JSON.stringify(environmentData)
      });
    },
    
    activate: async (code) => {
      return await callApi(`/configuration/environments/${code}/activate`, {
        method: 'POST'
      });
    },
    
    deactivate: async (code) => {
      return await callApi(`/configuration/environments/${code}/deactivate`, {
        method: 'POST'
      });
    },
    
    copyConfiguration: async (sourceEnv, targetEnv) => {
      return await callApi('/configuration/environments/copy', {
        method: 'POST',
        params: { sourceEnv, targetEnv }
      });
    },
    
    delete: async (code) => {
      return await callApi(`/configuration/environments/${code}`, {
        method: 'DELETE'
      });
    }
  }
},
  
  
   // Bloc à ajouter à api.service.js

integration: {
  // Gestion des partenaires
  partners: {
    getAll: async () => {
      return await callApi('/integration/partners', {
        method: 'GET'
      });
    },
    getById: async (code) => {
      return await callApi(`/integration/partners/${code}`, {
        method: 'GET'
      });
    },
    create: async (partnerData) => {
      return await callApi('/integration/partners', {
        method: 'POST',
        body: JSON.stringify(partnerData)
      });
    },
    update: async (code, partnerData) => {
      return await callApi(`/integration/partners/${code}`, {
        method: 'PUT',
        body: JSON.stringify(partnerData)
      });
    },
    delete: async (code) => {
      return await callApi(`/integration/partners/${code}`, {
        method: 'DELETE'
      });
    },
    activate: async (code) => {
      return await callApi(`/integration/partners/${code}/activate`, {
        method: 'PUT'
      });
    },
    deactivate: async (code) => {
      return await callApi(`/integration/partners/${code}/deactivate`, {
        method: 'PUT'
      });
    },
    updateConfig: async (code, configData) => {
      return await callApi(`/integration/partners/${code}/config`, {
        method: 'PUT',
        body: JSON.stringify(configData)
      });
    }
  },

  // Gestion des interfaces
  interfaces: {
    getAll: async (queryParams = {}) => {
      const queryString = new URLSearchParams(queryParams).toString();
      return await callApi(`/integration/interfaces?${queryString}`, {
        method: 'GET'
      });
    },
    getById: async (code) => {
      return await callApi(`/integration/interfaces/${code}`, {
        method: 'GET'
      });
    },
    create: async (interfaceData) => {
      return await callApi('/integration/interfaces', {
        method: 'POST',
        body: JSON.stringify(interfaceData)
      });
    },
    update: async (code, interfaceData) => {
      return await callApi(`/integration/interfaces/${code}`, {
        method: 'PUT',
        body: JSON.stringify(interfaceData)
      });
    },
    delete: async (code) => {
      return await callApi(`/integration/interfaces/${code}`, {
        method: 'DELETE'
      });
    },
    activate: async (code) => {
      return await callApi(`/integration/interfaces/${code}/activate`, {
        method: 'PUT'
      });
    },
    deactivate: async (code) => {
      return await callApi(`/integration/interfaces/${code}/deactivate`, {
        method: 'PUT'
      });
    }
  },

  // Exécution des interfaces
  execution: {
    execute: async (code, context) => {
      return await callApi(`/integration/interface-executions/${code}/execute`, {
        method: 'POST',
        body: JSON.stringify(context)
      });
    },
    getExecution: async (executionId) => {
      return await callApi(`/integration/interface-executions/${executionId}`, {
        method: 'GET'
      });
    },
    getExecutions: async (code, page = 0, size = 20) => {
      return await callApi(`/integration/interface-executions/interface/${code}?page=${page}&size=${size}`, {
        method: 'GET'
      });
    },
    retry: async (executionId) => {
      return await callApi(`/integration/interface-executions/${executionId}/retry`, {
        method: 'POST'
      });
    },
    cancel: async (executionId) => {
      return await callApi(`/integration/interface-executions/${executionId}/cancel`, {
        method: 'POST'
      });
    },
    getAllStatus: async () => {
      return await callApi('/integration/interface-executions/status/all', {
        method: 'GET'
      });
    },
    getStatus: async (code) => {
      return await callApi(`/integration/interface-executions/status/${code}`, {
        method: 'GET'
      });
    },
    getMetrics: async (code) => {
      return await callApi(`/integration/interface-executions/metrics/${code}`, {
        method: 'GET'
      });
    },
    getHealth: async () => {
      return await callApi('/integration/interface-executions/health', {
        method: 'GET'
      });
    }
  },

  // Gestion des connexions REST
  restConnections: {
    getAll: async () => {
      return await callApi('/integration/connections/rest', {
        method: 'GET'
      });
    },
    getById: async (code) => {
      return await callApi(`/integration/connections/rest/${code}`, {
        method: 'GET'
      });
    },
    create: async (connectionData) => {
      return await callApi('/integration/connections/rest', {
        method: 'POST',
        body: JSON.stringify(connectionData)
      });
    },
    update: async (code, connectionData) => {
      return await callApi(`/integration/connections/rest/${code}`, {
        method: 'PUT',
        body: JSON.stringify(connectionData)
      });
    },
    addEndpoint: async (code, endpointData) => {
      return await callApi(`/integration/connections/rest/${code}/endpoints`, {
        method: 'POST',
        body: JSON.stringify(endpointData)
      });
    },
    removeEndpoint: async (code, endpointName) => {
      return await callApi(`/integration/connections/rest/${code}/endpoints/${endpointName}`, {
        method: 'DELETE'
      });
    },
    testEndpoint: async (code, endpointName) => {
      return await callApi(`/integration/connections/rest/${code}/endpoints/${endpointName}/test`, {
        method: 'POST'
      });
    },
    executeMethod: async (code, endpointName, methodName, parameters) => {
      return await callApi(`/integration/connections/rest/${code}/endpoints/${endpointName}/methods/${methodName}`, {
        method: 'POST',
        body: JSON.stringify(parameters)
      });
    },
    getEndpoints: async (code) => {
      return await callApi(`/integration/connections/rest/${code}/endpoints`, {
        method: 'GET'
      });
    }
  },

  // Gestion des connexions SOAP
  soapConnections: {
    getAll: async () => {
      return await callApi('/integration/connections/soap', {
        method: 'GET'
      });
    },
    getById: async (code) => {
      return await callApi(`/integration/connections/soap/${code}`, {
        method: 'GET'
      });
    },
    create: async (connectionData) => {
      return await callApi('/integration/connections/soap', {
        method: 'POST',
        body: JSON.stringify(connectionData)
      });
    },
    update: async (code, connectionData) => {
      return await callApi(`/integration/connections/soap/${code}`, {
        method: 'PUT',
        body: JSON.stringify(connectionData)
      });
    },
    parseWsdl: async (wsdlUrl) => {
      return await callApi('/integration/connections/soap/parse-wsdl', {
        method: 'POST',
        params: { wsdlUrl }
      });
    },
    getOperations: async (code) => {
      return await callApi(`/integration/connections/soap/${code}/operations`, {
        method: 'GET'
      });
    },
    testOperation: async (code, operationName) => {
      return await callApi(`/integration/connections/soap/${code}/operations/${operationName}/test`, {
        method: 'POST'
      });
    },
    executeOperation: async (code, operationName, parameters) => {
      return await callApi(`/integration/connections/soap/${code}/operations/${operationName}/execute`, {
        method: 'POST',
        body: JSON.stringify(parameters)
      });
    },
    updateWsdlDefinition: async (code, wsdlDefinition) => {
      return await callApi(`/integration/connections/soap/${code}/wsdl`, {
        method: 'PUT',
        body: JSON.stringify(wsdlDefinition)
      });
    }
  },

  // Gestion des connexions de fichiers
  fileConnections: {
    getAll: async () => {
      return await callApi('/integration/connections/file', {
        method: 'GET'
      });
    },
    getById: async (code) => {
      return await callApi(`/integration/connections/file/${code}`, {
        method: 'GET'
      });
    },
    create: async (connectionData) => {
      return await callApi('/integration/connections/file', {
        method: 'POST',
        body: JSON.stringify(connectionData)
      });
    },
    update: async (code, connectionData) => {
      return await callApi(`/integration/connections/file/${code}`, {
        method: 'PUT',
        body: JSON.stringify(connectionData)
      });
    },
    verifyLocation: async (locationData) => {
      return await callApi('/integration/connections/file/verify-location', {
        method: 'POST',
        body: JSON.stringify(locationData)
      });
    },
    downloadFile: async (code, filePath) => {
      return await callApi(`/integration/connections/file/${code}/download`, {
        method: 'GET',
        params: { filePath },
        responseType: 'blob'
      });
    },
    uploadFile: async (code, file, fileName) => {
      const formData = new FormData();
      formData.append('file', file);
      if (fileName) formData.append('fileName', fileName);
      
      return await callApi(`/integration/connections/file/${code}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  },

  // Gestion des mappings
  mappings: {
    getAll: async () => {
      return await callApi('/integration/mappings', {
        method: 'GET'
      });
    },
    getById: async (id) => {
      return await callApi(`/integration/mappings/${id}`, {
        method: 'GET'
      });
    },
    create: async (mappingData) => {
      return await callApi('/integration/mappings', {
        method: 'POST',
        body: JSON.stringify(mappingData)
      });
    },
    update: async (id, mappingData) => {
      return await callApi(`/integration/mappings/${id}`, {
        method: 'PUT',
        body: JSON.stringify(mappingData)
      });
    },
    delete: async (id) => {
      return await callApi(`/integration/mappings/${id}`, {
        method: 'DELETE'
      });
    },
    getMappingsForInterface: async (interfaceCode) => {
      return await callApi(`/integration/mappings/interface/${interfaceCode}`, {
        method: 'GET'
      });
    },
    addValidationRule: async (mappingId, ruleData) => {
      return await callApi(`/integration/mappings/${mappingId}/validation-rules`, {
        method: 'POST',
        body: JSON.stringify(ruleData)
      });
    },
    addTransformationRule: async (mappingId, ruleData) => {
      return await callApi(`/integration/mappings/${mappingId}/transformation-rules`, {
        method: 'POST',
        body: JSON.stringify(ruleData)
      });
    },
    addFieldMapping: async (mappingId, fieldMappingData) => {
      return await callApi(`/integration/mappings/${mappingId}/field-mappings`, {
        method: 'POST',
        body: JSON.stringify(fieldMappingData)
      });
    },
    removeFieldMapping: async (mappingId, fieldMappingId) => {
      return await callApi(`/integration/mappings/${mappingId}/field-mappings/${fieldMappingId}`, {
        method: 'DELETE'
      });
    }
  },

  // Gestion des certificats
  certificates: {
    getAll: async () => {
      return await callApi('/integration/certificates', {
        method: 'GET'
      });
    },
    getById: async (id) => {
      return await callApi(`/integration/certificates/${id}`, {
        method: 'GET'
      });
    },
    upload: async (certificateData) => {
      return await callApi('/integration/certificates', {
        method: 'POST',
        body: JSON.stringify(certificateData)
      });
    },
    delete: async (id) => {
      return await callApi(`/integration/certificates/${id}`, {
        method: 'DELETE'
      });
    },
    renew: async (id) => {
      return await callApi(`/integration/certificates/${id}/renew`, {
        method: 'POST'
      });
    },
    verify: async (id) => {
      return await callApi(`/integration/certificates/${id}/verify`, {
        method: 'GET'
      });
    },
    createKeyStore: async (keyStoreData) => {
      return await callApi('/integration/certificates/keystores', {
        method: 'POST',
        body: JSON.stringify(keyStoreData)
      });
    },
    createTrustStore: async (trustStoreData) => {
      return await callApi('/integration/certificates/truststores', {
        method: 'POST',
        body: JSON.stringify(trustStoreData)
      });
    }
  },

  // Surveillance des composants
  monitoring: {
    checkHealth: async (componentId) => {
      return await callApi(`/integration/monitoring/health/${componentId}`, {
        method: 'GET'
      });
    },
    checkAllHealth: async () => {
      return await callApi('/integration/monitoring/health', {
        method: 'GET'
      });
    },
    getMetrics: async (componentId) => {
      return await callApi(`/integration/monitoring/metrics/${componentId}`, {
        method: 'GET'
      });
    },
    createAlert: async (alertData) => {
      return await callApi('/integration/monitoring/alerts', {
        method: 'POST',
        body: JSON.stringify(alertData)
      });
    },
    getActiveAlerts: async () => {
      return await callApi('/integration/monitoring/alerts', {
        method: 'GET'
      });
    },
    acknowledgeAlert: async (alertId, acknowledgedBy) => {
      return await callApi(`/integration/monitoring/alerts/${alertId}/acknowledge`, {
        method: 'POST',
        params: { acknowledgedBy }
      });
    },
    resolveAlert: async (alertId, resolvedBy, comment) => {
      return await callApi(`/integration/monitoring/alerts/${alertId}/resolve`, {
        method: 'POST',
        params: { resolvedBy, comment }
      });
    }
  },

  // Gestion de l'authentification
  auth: {
    createAuthentication: async (authData) => {
      return await callApi('/integration/authentications', {
        method: 'POST',
        body: JSON.stringify(authData)
      });
    },
    updateAuthentication: async (id, authData) => {
      return await callApi(`/integration/authentications/${id}`, {
        method: 'PUT',
        body: JSON.stringify(authData)
      });
    },
    getAuthentication: async (id) => {
      return await callApi(`/integration/authentications/${id}`, {
        method: 'GET'
      });
    },
    deleteAuthentication: async (id) => {
      return await callApi(`/integration/authentications/${id}`, {
        method: 'DELETE'
      });
    },
    refreshToken: async (refreshToken) => {
      return await callApi('/integration/authentications/token/refresh', {
        method: 'POST',
        params: { refreshToken }
      });
    },
    validateToken: async (token) => {
      return await callApi('/integration/authentications/token/validate', {
        method: 'POST',
        params: { token }
      });
    }
  }
},
  
  interface: {
    // Configuration management
    config: {
      getAll: async () => {
        return await callApi('/interfaces/config', {
          method: 'GET'
        });
      },
      
      getByCode: async (code) => {
        return await callApi(`/interfaces/config/${code}`, {
          method: 'GET'
        });
      },
      
      create: async (configData) => {
        return await callApi('/interfaces/config', {
          method: 'POST',
          body: JSON.stringify(configData)
        });
      },
      
      update: async (code, configData) => {
        return await callApi(`/interfaces/config/${code}`, {
          method: 'PUT', 
          body: JSON.stringify(configData)
        });
      },
      
      delete: async (code) => {
        return await callApi(`/interfaces/config/${code}`, {
          method: 'DELETE'
        });
      },
      
      activate: async (code) => {
        return await callApi(`/interfaces/config/${code}/activate`, {
          method: 'POST'
        });
      },
      
      deactivate: async (code) => {
        return await callApi(`/interfaces/config/${code}/deactivate`, {
          method: 'POST'
        });
      }
    },

    // Execution management 
    execution: {
      execute: async (code, executionData) => {
        return await callApi(`/interfaces/execution/${code}/execute`, {
          method: 'POST',
          body: JSON.stringify(executionData)
        });
      },

      getById: async (executionId) => {
        return await callApi(`/interfaces/execution/${executionId}`, {
          method: 'GET'
        });
      },

      getForInterface: async (code, page = 0, size = 20) => {
        return await callApi(`/interfaces/execution/interface/${code}?page=${page}&size=${size}`, {
          method: 'GET'
        });
      },

      retry: async (executionId) => {
        return await callApi(`/interfaces/execution/${executionId}/retry`, {
          method: 'POST'
        });
      },

      cancel: async (executionId) => {
        return await callApi(`/interfaces/execution/${executionId}/cancel`, {
          method: 'POST'
        });
      }
    },

    // Monitoring
    monitoring: {
      getAllStatus: async () => {
        return await callApi('/interfaces/monitoring/status', {
          method: 'GET'
        });
      },

      getStatus: async (code) => {
        return await callApi(`/interfaces/monitoring/${code}/status`, {
          method: 'GET'
        });
      },

      getMetrics: async (code) => {
        return await callApi(`/interfaces/monitoring/metrics?code=${code}`, {
          method: 'GET'
        });
      },

      getHealth: async () => {
        return await callApi('/interfaces/monitoring/health', {
          method: 'GET'
        });
      }
    }
  },
  
  audit: {
  // Gestion des logs individuels
  getById: async (id) => {
    return await callApi(`/audit/${id}`, {
      method: 'GET'
    });
  },

  delete: async (id) => {
    return await callApi(`/audit/${id}`, {
      method: 'DELETE'
    });
  },

  // Recherche et filtrage
  getRecent: async (limit = 20) => {
    return await callApi(`/audit/search/recent?limit=${limit}`, {
      method: 'GET'
    });
  },

  getBySeverity: async (severity, limit = 20) => {
    return await callApi(`/audit/search/severity/${severity}?limit=${limit}`, {
      method: 'GET'
    });
  },

  getByUser: async (userId, page = 0, size = 20) => {
    return await callApi(`/audit/search/user/${userId}?page=${page}&size=${size}`, {
      method: 'GET'
    });
  },

  getByResource: async (resourceType, resourceId, page = 0, size = 20) => {
    return await callApi(`/audit/search/resource/${resourceType}/${resourceId}?page=${page}&size=${size}`, {
      method: 'GET'
    });
  },

  // Export
  export: async (params) => {
    return await callApi('/audit/export', {
      method: 'POST',
      body: JSON.stringify(params)
    });
  }
},










// Dans api.service.js, ajoutez :

// Code to add to api.service.js in the apiService object

editic: {
  // Gestion des catégories
  categories: {
    getAll: async (activeOnly = false) => {
      return await callApi(`/editic/categories?activeOnly=${activeOnly}`, {
        method: 'GET'
      });
    },
    
    getById: async (id) => {
      return await callApi(`/editic/categories/${id}`, {
        method: 'GET'
      });
    },

    create: async (categoryData) => {
      return await callApi('/editic/categories', {
        method: 'POST',
        body: JSON.stringify(categoryData)
      });
    },

    update: async (id, categoryData) => {
      return await callApi(`/editic/categories/${id}`, {
        method: 'PUT',
        body: JSON.stringify(categoryData)
      });
    },

    delete: async (id) => {
      return await callApi(`/editic/categories/${id}`, {
        method: 'DELETE'
      });
    },

    updateOrder: async (id, newOrder) => {
      return await callApi(`/editic/categories/${id}/order/${newOrder}`, {
        method: 'PUT'
      });
    },

    toggleStatus: async (id) => {
      return await callApi(`/editic/categories/${id}/toggle-status`, {
        method: 'PUT'
      });
    }
  },

  // Gestion des templates
  templates: {
    getAll: async () => {
      return await callApi('/editic/templates', {
        method: 'GET'
      });
    },

    getById: async (id) => {
      return await callApi(`/editic/templates/${id}`, {
        method: 'GET'
      });
    },

    create: async (templateData) => {
      return await callApi('/editic/templates', {
        method: 'POST',
        body: JSON.stringify(templateData)
      });
    },

    delete: async (id) => {
      return await callApi(`/editic/templates/${id}`, {
        method: 'DELETE'
      });
    }
  },

  // Gestion des documents
  documents: {
    generate: async (data) => {
      return await callApi('/editic/documents/generate', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    },

    download: async (documentId) => {
      return await callApi(`/editic/documents/${documentId}/download`, {
        method: 'GET',
        responseType: 'blob'
      });
    }
  },

  // Gestion des placeholders
  placeholders: {
    getAll: async () => {
      return await callApi('/editic/placeholders', {
        method: 'GET'
      });
    },

    getById: async (id) => {
      return await callApi(`/editic/placeholders/${id}`, {
        method: 'GET'
      });
    },

    getByCode: async (code) => {
      return await callApi(`/editic/placeholders/code/${code}`, {
        method: 'GET'
      });
    },

    create: async (placeholderData) => {
      return await callApi('/editic/placeholders', {
        method: 'POST',
        body: JSON.stringify(placeholderData)
      });
    },

    update: async (id, placeholderData) => {
      return await callApi(`/editic/placeholders/${id}`, {
        method: 'PUT',
        body: JSON.stringify(placeholderData)
      });
    },

    delete: async (id) => {
      return await callApi(`/editic/placeholders/${id}`, {
        method: 'DELETE'
      });
    },

    getByCodes: async (codes) => {
      return await callApi('/editic/placeholders/by-codes', {
        method: 'POST',
        body: JSON.stringify(codes)
      });
    }
  }
},

license: {
  // Gestion des licences
  generate: async (data) => {
    return await callApi('licenses', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  },

  getDetails: async (licenseKey) => {
    return await callApi(`licenses/${licenseKey}`, {
      method: 'GET'
    });
  },

  getAll: async (productId, status) => {
    let url = 'licenses';
    const params = new URLSearchParams();
    if (productId) params.append('productId', productId);
    if (status) params.append('status', status);
    if (params.toString()) url += `?${params.toString()}`;
    return await callApi(url, {
      method: 'GET'
    });
  },

  update: async (licenseKey, data) => {
    return await callApi(`licenses/${licenseKey}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  },

  revoke: async (licenseKey) => {
    return await callApi(`licenses/${licenseKey}`, {
      method: 'DELETE'
    });
  },

  // Activation des licences
  activation: {
    activate: async (data) => {
      return await callApi('licenses/activation/activate', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    },

    deactivate: async (data) => {
      return await callApi('licenses/activation/deactivate', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    },

    getStatus: async (licenseKey, machineId) => {
      return await callApi(`licenses/activation/${licenseKey}/status?machineId=${machineId}`, {
        method: 'GET'
      });
    }
  },

  // Validation des licences
  validation: {
    validate: async (data) => {
      return await callApi('licenses/keys/validate', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    },

    getKeyFormat: async () => {
      return await callApi('licenses/keys/format', {
        method: 'GET'
      });
    },

    checkFeature: async (licenseKey, featureCode) => {
      return await callApi(`licenses/keys/${licenseKey}/feature/${featureCode}`, {
        method: 'GET'
      });
    }
  }
},

scheduler: {
  jobs: {
    create: async (jobData) => {
      return await callApi('/scheduler/jobs', {
        method: 'POST',
        body: JSON.stringify(jobData)
      });
    },

    getById: async (id) => {
      return await callApi(`/scheduler/jobs/${id}`, {
        method: 'GET' 
      });
    },

    getAll: async (type, status) => {
      let url = '/scheduler/jobs';
      const params = [];
      if (type) params.push(`type=${type}`);
      if (status) params.push(`status=${status}`);
      if (params.length > 0) url += `?${params.join('&')}`;
      return await callApi(url, {
        method: 'GET'
      });
    },

    activate: async (id) => {
      return await callApi(`/scheduler/jobs/${id}/activate`, {
        method: 'PUT'
      });
    },

    deactivate: async (id) => {
      return await callApi(`/scheduler/jobs/${id}/deactivate`, {
        method: 'PUT' 
      });
    },

    delete: async (id) => {
      return await callApi(`/scheduler/jobs/${id}`, {
        method: 'DELETE'
      });
    }
  },

  executions: {
    trigger: async (data) => {
      return await callApi('/scheduler/executions/trigger', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    },

    getForJob: async (jobId, status) => {
      let url = `/scheduler/executions/job/${jobId}`;
      if (status) url += `?status=${status}`;
      return await callApi(url, {
        method: 'GET'
      });
    },

    getById: async (id) => {
      return await callApi(`/scheduler/executions/${id}`, {
        method: 'GET'
      });
    },

    retry: async (id) => {
      return await callApi(`/scheduler/executions/${id}/retry`, {
        method: 'POST'
      });
    },

    cancel: async (id) => {
      return await callApi(`/scheduler/executions/${id}/cancel`, {
        method: 'POST'
      });
    }
  },

  schedules: {
    getForJob: async (jobId) => {
      return await callApi(`/scheduler/schedules/job/${jobId}`, {
        method: 'GET'
      });
    },

    update: async (jobId, scheduleData) => {
      return await callApi(`/scheduler/schedules/job/${jobId}`, {
        method: 'PUT',
        body: JSON.stringify(scheduleData)
      });
    },

    pause: async (jobId) => {
      return await callApi(`/scheduler/schedules/job/${jobId}/pause`, {
        method: 'PUT'
      });
    },

    resume: async (jobId) => {
      return await callApi(`/scheduler/schedules/job/${jobId}/resume`, {
        method: 'PUT'
      });
    },

    getNextExecution: async (jobId) => {
      return await callApi(`/scheduler/schedules/job/${jobId}/next-execution`, {
        method: 'GET'
      });
    }
  },

  monitoring: {
    checkHealth: async (jobId) => {
      return await callApi(`/scheduler/monitoring/jobs/${jobId}/health`, {
        method: 'GET'
      });
    },

    monitorExecution: async (executionId) => {
      return await callApi(`/scheduler/monitoring/executions/${executionId}`, {
        method: 'GET'
      });
    },

    getActiveCount: async () => {
      return await callApi('/scheduler/monitoring/active-executions/count', {
        method: 'GET'
      });
    },

    getRunning: async () => {
      return await callApi('/scheduler/monitoring/executions/running', {
        method: 'GET'
      });
    },

    getJobStatus: async (jobId) => {
      return await callApi(`/scheduler/monitoring/jobs/${jobId}/status`, {
        method: 'GET'
      });
    }
  }
}


,
monitoring: {
  // Gestion des métriques
  metrics: {
    system: {
      getAll: async (startTime, endTime) => {
        return await callApi('/monitoring/metrics/system', {
          method: 'GET',
          params: { startTime, endTime }
        });
      }
    },
    
    application: {
      getAll: async (applicationId, startTime, endTime) => {
        return await callApi('/monitoring/metrics/application', {
          method: 'GET',
          params: { applicationId, startTime, endTime }
        });
      }
    },

    database: {
      getAll: async (databaseId, startTime, endTime) => {
        return await callApi('/monitoring/metrics/database', {
          method: 'GET',
          params: { databaseId, startTime, endTime }
        });
      }
    },

    network: {
      getAll: async (startTime, endTime) => {
        return await callApi('/monitoring/metrics/network', {
          method: 'GET',
          params: { startTime, endTime }
        });
      }
    },

    thresholds: {
      set: async (thresholdData) => {
        return await callApi('/monitoring/metrics/thresholds', {
          method: 'POST',
          body: JSON.stringify(thresholdData)
        });
      }
    },

    dashboard: {
      getMetrics: async () => {
        return await callApi('/monitoring/metrics/dashboard', {
          method: 'GET'
        });
      }
    }
  },

  // Gestion des alertes
  alerts: {
    create: async (alertData) => {
      return await callApi('/monitoring/alerts', {
        method: 'POST',
        body: JSON.stringify(alertData)
      });
    },

    getAll: async () => {
      return await callApi('/monitoring/alerts', {
        method: 'GET'
      });
    },

    getById: async (id) => {
      return await callApi(`/monitoring/alerts/${id}`, {
        method: 'GET'
      });
    },

    update: async (id, alertData) => {
      return await callApi(`/monitoring/alerts/${id}`, {
        method: 'PUT',
        body: JSON.stringify(alertData)
      });
    },

    delete: async (id) => {
      return await callApi(`/monitoring/alerts/${id}`, {
        method: 'DELETE'
      });
    },

    acknowledge: async (id, userId) => {
      return await callApi(`/monitoring/alerts/${id}/acknowledge`, {
        method: 'POST',
        params: { userId }
      });
    }
  },

  // Surveillance de l'état de santé
  health: {
    getSystem: async () => {
      return await callApi('/monitoring/health', {
        method: 'GET'
      });
    },

    getAllServices: async () => {
      return await callApi('/monitoring/health/services', {
        method: 'GET'
      });
    },

    getService: async (serviceId) => {
      return await callApi(`/monitoring/health/services/${serviceId}`, {
        method: 'GET'
      });
    },

    setMaintenance: async (serviceId, maintenance) => {
      return await callApi(`/monitoring/health/services/${serviceId}/maintenance`, {
        method: 'POST',
        params: { maintenance }
      });
    }
  },

  // Gestion des audits
  audit: {
    getLogs: async (startDate, endDate) => {
      return await callApi('/monitoring/audit', {
        method: 'GET',
        params: { startDate, endDate }
      });
    },

    getById: async (id) => {
      return await callApi(`/monitoring/audit/${id}`, {
        method: 'GET'
      });
    },

    archive: async (beforeDate) => {
      return await callApi('/monitoring/audit/archive', {
        method: 'POST',
        params: { beforeDate }
      });
    }
  }
},
notification: {
  // Gestion des notifications
  send: async (notificationData) => {
    return await callApi('/notifications/send', {
      method: 'POST',
      body: JSON.stringify(notificationData)
    });
  },

  sendBulk: async (bulkData) => {
    return await callApi('/notifications/bulk', {
      method: 'POST', 
      body: JSON.stringify(bulkData)
    });
  },

  getById: async (referenceId) => {
    return await callApi(`/notifications/${referenceId}`, {
      method: 'GET'
    });
  },

  getStatus: async (referenceId) => {
    return await callApi(`/notifications/${referenceId}/status`, {
      method: 'GET'
    });
  },

  cancel: async (referenceId) => {
    return await callApi(`/notifications/${referenceId}`, {
      method: 'DELETE'
    });
  },

  // Gestion des préférences
  preferences: {
    getUserPreferences: async (userId) => {
      return await callApi(`/notifications/notification-preferences/user/${userId}`, {
        method: 'GET'
      });
    },

    updateUserPreferences: async (userId, preferenceData) => {
      return await callApi(`/notifications/notification-preferences/user/${userId}`, {
        method: 'PUT',
        body: JSON.stringify(preferenceData)
      });
    },

    verifyChannel: async (userId, channel, verificationCode) => {
      return await callApi(`/notifications/notification-preferences/user/${userId}/channel/verify`, {
        method: 'POST',
        params: { channel, verificationCode }
      });
    }
  },

  // Gestion des templates
  templates: {
    getAll: async () => {
      return await callApi('/notifications/notification-templates', {
        method: 'GET'
      });
    },

    getByCode: async (code) => {
      return await callApi(`/notifications/notification-templates/${code}`, {
        method: 'GET'
      });
    },

    create: async (templateData) => {
      return await callApi('/notifications/notification-templates', {
        method: 'POST',
        body: JSON.stringify(templateData)
      });
    },

    update: async (code, templateData) => {
      return await callApi(`/notifications/notification-templates/${code}`, {
        method: 'PUT',
        body: JSON.stringify(templateData)
      });
    },

    delete: async (code) => {
      return await callApi(`/notifications/notification-templates/${code}`, {
        method: 'DELETE'
      });
    }
  }
},



// À ajouter dans l'objet apiService dans api.service.js

// À ajouter dans l'objet workflow de api.service.js

workflow: {
  // Obtenir toutes les définitions de workflow
  getAllWorkflowDefinitions: async (page = 0, size = 10) => {
    return await callApi(`/workflows?page=${page}&size=${size}`, {
      method: 'GET'
    });
  },
  
  // Obtenir toutes les instances de workflow actives
  getAllWorkflowInstances: async () => {
    // Cet endpoint doit être implémenté sur le backend
    // Il devrait retourner toutes les instances de workflow actives
    return await callApi('/workflows/instances', {
      method: 'GET'
    });
  },
  
  // Obtenir une définition de workflow spécifique par code
  getWorkflowDefinition: async (code) => {
    return await callApi(`/workflows/${code}`, {
      method: 'GET'
    });
  },
  
  // Obtenir les détails d'un workflow par référence
  getWorkflowDetails: async (reference) => {
    return await callApi(`/workflows/${reference}/details`, {
      method: 'GET'
    });
  },
  
  // Obtenir l'historique d'un workflow par référence
  getWorkflowHistory: async (reference) => {
    return await callApi(`/workflows/${reference}/history`, {
      method: 'GET'
    });
  },
  
  // Traiter un événement pour faire avancer un workflow
  processEvent: async (reference, event, context) => {
    return await callApi(`/workflows/${reference}/event`, {
      method: 'POST',
      body: JSON.stringify({
        event: event,
        context: context
      })
    });
  }
},

automata: {
	
	campaigns: {
    getAll: async (status, active) => {
      const params = {};
      if (status) params.status = status;
      if (active !== undefined) params.active = active;
      
      return await callApi('/automata/campaigns', {
        method: 'GET',
        params
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/campaigns/${id}`, {
        method: 'GET'
      });
    },
    
    search: async (keyword) => {
      return await callApi('/automata/campaigns/search', {
        method: 'GET',
        params: { keyword }
      });
    },
    
    getScheduled: async (start, end) => {
      return await callApi('/automata/campaigns/scheduled', {
        method: 'GET',
        params: { start, end }
      });
    },
    
    create: async (campaignData) => {
      return await callApi('/automata/campaigns', {
        method: 'POST',
        body: JSON.stringify(campaignData)
      });
    },
    
    update: async (id, campaignData) => {
      return await callApi(`/automata/campaigns/${id}`, {
        method: 'PUT',
        body: JSON.stringify(campaignData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/campaigns/${id}`, {
        method: 'DELETE'
      });
    },
    
    updateStatus: async (id, status) => {
      return await callApi(`/automata/campaigns/${id}/status`, {
        method: 'PATCH',
        params: { status }
      });
    },
    
    linkTestCase: async (campaignId, testCaseId) => {
      return await callApi(`/automata/campaigns/${campaignId}/link-test/${testCaseId}`, {
        method: 'POST'
      });
    },
    
    removeTestCase: async (campaignId, testCaseId) => {
      return await callApi(`/automata/campaigns/${campaignId}/testcases/${testCaseId}`, {
        method: 'DELETE'
      });
    }
  },
  
  executions: {
    start: async (campaignId, executionParameters, userId) => {
      return await callApi(`/automata/executions/campaigns/${campaignId}/start`, {
        method: 'POST',
        body: JSON.stringify({ parameters: executionParameters }),
        headers: {
          'User-ID': userId
        }
      });
    },
    
    stop: async (executionId, userId) => {
      return await callApi(`/automata/executions/${executionId}/stop`, {
        method: 'POST',
        headers: {
          'User-ID': userId
        }
      });
    },
    
    pause: async (executionId, userId) => {
      return await callApi(`/automata/executions/${executionId}/pause`, {
        method: 'POST',
        headers: {
          'User-ID': userId
        }
      });
    },
    
    resume: async (executionId, userId) => {
      return await callApi(`/automata/executions/${executionId}/resume`, {
        method: 'POST',
        headers: {
          'User-ID': userId
        }
      });
    },
    
    getAll: async (campaignId, status) => {
      const params = {};
      if (campaignId) params.campaignId = campaignId;
      if (status) params.status = status;
      
      return await callApi('/automata/executions', {
        method: 'GET',
        params
      });
    },
    
    getById: async (executionId) => {
      return await callApi(`/automata/executions/${executionId}`, {
        method: 'GET'
      });
    },
    
    getResults: async (executionId) => {
      return await callApi(`/automata/executions/${executionId}/results`, {
        method: 'GET'
      });
    },
    
    recordResult: async (executionId, testResult) => {
      return await callApi(`/automata/executions/${executionId}/results`, {
        method: 'POST',
        body: JSON.stringify(testResult)
      });
    },
    
    updateStatus: async (executionId, status) => {
      return await callApi(`/automata/executions/${executionId}/status`, {
        method: 'PATCH',
        params: { status }
      });
    },
    
    calculateStatistics: async (executionId) => {
      return await callApi(`/automata/executions/${executionId}/statistics`, {
        method: 'POST'
      });
    }
  },
  
  reports: {
    generateExecutionReport: async (executionId, format, metadata, userId) => {
      return await callApi(`/automata/reports/executions/${executionId}`, {
        method: 'POST',
        params: { format },
        body: JSON.stringify(metadata),
        headers: {
          'User-ID': userId
        }
      });
    },
    
    generateCampaignReport: async (campaignId, format, metadata, userId) => {
      return await callApi(`/automata/reports/campaigns/${campaignId}`, {
        method: 'POST',
        params: { format },
        body: JSON.stringify(metadata),
        headers: {
          'User-ID': userId
        }
      });
    },
    
    getAll: async (campaignId, executionId) => {
      const params = {};
      if (campaignId) params.campaignId = campaignId;
      if (executionId) params.executionId = executionId;
      
      return await callApi('/automata/reports', {
        method: 'GET',
        params
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/reports/${id}`, {
        method: 'GET'
      });
    },
    
    download: async (id) => {
      return await callApi(`/automata/reports/${id}/download`, {
        method: 'GET',
        responseType: 'blob'
      });
    },
    
    archive: async (id) => {
      return await callApi(`/automata/reports/${id}/archive`, {
        method: 'PATCH'
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/reports/${id}`, {
        method: 'DELETE'
      });
    }
  },
  
  testCases: {
    getAll: async (active) => {
      const params = {};
      if (active !== undefined) params.active = active;
      
      return await callApi('/automata/testcases', {
        method: 'GET',
        params
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/testcases/${id}`, {
        method: 'GET'
      });
    },
    
    search: async (keyword) => {
      return await callApi('/automata/testcases/search', {
        method: 'GET',
        params: { keyword }
      });
    },
    
    create: async (testCaseData, userId) => {
      return await callApi('/automata/testcases', {
        method: 'POST',
        body: JSON.stringify(testCaseData),
        headers: {
          'User-ID': userId
        }
      });
    },
    
    update: async (id, testCaseData) => {
      return await callApi(`/automata/testcases/${id}`, {
        method: 'PUT',
        body: JSON.stringify(testCaseData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/testcases/${id}`, {
        method: 'DELETE'
      });
    }
  },
	
	
	
	
	
	
	
	
	
	
  // Gestion des environnements
  environments: {
    getAll: async () => {
      return await callApi('/automata/environments', {
        method: 'GET'
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/environments/${id}`, {
        method: 'GET'
      });
    },
    
    create: async (environmentData) => {
      return await callApi('/automata/environments', {
        method: 'POST',
        body: JSON.stringify(environmentData)
      });
    },
    
    update: async (id, environmentData) => {
      return await callApi(`/automata/environments/${id}`, {
        method: 'PUT',
        body: JSON.stringify(environmentData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/environments/${id}`, {
        method: 'DELETE'
      });
    },
    
    setActive: async (id, active) => {
      return await callApi(`/automata/environments/${id}/active`, {
        method: 'PATCH',
        params: { active }
      });
    },
    
    getFunctions: async (id) => {
      return await callApi(`/automata/environments/${id}/functions`, {
        method: 'GET'
      });
    },
    
    getActiveAgents: async () => {
      return await callApi('/automata/environments/agents/active', {
        method: 'GET'
      });
    },
    
    assignAgent: async (environmentId, agentId) => {
      return await callApi(`/automata/environments/${environmentId}/assign-agent`, {
        method: 'POST',
        params: { agentId }
      });
    },
    
    isAgentDeployed: async (id) => {
      return await callApi(`/automata/environments/${id}/agent-deployed`, {
        method: 'GET'
      });
    }
  },
  
  // Gestion des agents
  agents: {
    getAll: async () => {
      return await callApi('/automata/agents', {
        method: 'GET'
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/agents/${id}`, {
        method: 'GET'
      });
    },
    
    create: async (agentData) => {
      return await callApi('/automata/agents', {
        method: 'POST',
        body: JSON.stringify(agentData)
      });
    },
    
    update: async (id, agentData) => {
      return await callApi(`/automata/agents/${id}`, {
        method: 'PUT',
        body: JSON.stringify(agentData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/agents/${id}`, {
        method: 'DELETE'
      });
    },
    
    getByType: async (type) => {
      return await callApi(`/automata/agents/type/${type}`, {
        method: 'GET'
      });
    },
    
    getActive: async () => {
      return await callApi('/automata/agents/active', {
        method: 'GET'
      });
    },
    
    setActive: async (id, active) => {
      return await callApi(`/automata/agents/${id}/active`, {
        method: 'PATCH',
        params: { active }
      });
    },
    
    testConnection: async (id) => {
      return await callApi(`/automata/agents/${id}/test-connection`, {
        method: 'GET'
      });
    },
    
    deploy: async (agentId, environmentId) => {
      return await callApi(`/automata/agents/${agentId}/deploy`, {
        method: 'POST',
        params: { environmentId }
      });
    }
  },
  
  // Gestion des fonctions
  functions: {
    getAll: async (environmentId) => {
      const params = environmentId ? { environmentId } : {};
      return await callApi('/automata/functions', {
        method: 'GET',
        params
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/functions/${id}`, {
        method: 'GET'
      });
    },
    
    create: async (functionData) => {
      return await callApi('/automata/functions', {
        method: 'POST',
        body: JSON.stringify(functionData)
      });
    },
    
    update: async (id, functionData) => {
      return await callApi(`/automata/functions/${id}`, {
        method: 'PUT',
        body: JSON.stringify(functionData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/functions/${id}`, {
        method: 'DELETE'
      });
    },
    
    getByType: async (type) => {
      return await callApi(`/automata/functions/type/${type}`, {
        method: 'GET'
      });
    },
    
    getByDomain: async (domain) => {
      return await callApi(`/automata/functions/domain/${domain}`, {
        method: 'GET'
      });
    },
    
    getActive: async () => {
      return await callApi('/automata/functions/active', {
        method: 'GET'
      });
    },
    
    getUsedByClient: async () => {
      return await callApi('/automata/functions/used-by-client', {
        method: 'GET'
      });
    },
    
    getByMd5Key: async (md5Key) => {
      return await callApi(`/automata/functions/md5/${md5Key}`, {
        method: 'GET'
      });
    }
  },
  
  // Gestion des tâches
  tasks: {
    getAll: async () => {
      return await callApi('/automata/tasks', {
        method: 'GET'
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/tasks/${id}`, {
        method: 'GET'
      });
    },
    
    create: async (taskData) => {
      return await callApi('/automata/tasks', {
        method: 'POST',
        body: JSON.stringify(taskData)
      });
    },
    
    update: async (id, taskData) => {
      return await callApi(`/automata/tasks/${id}`, {
        method: 'PUT',
        body: JSON.stringify(taskData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/tasks/${id}`, {
        method: 'DELETE'
      });
    },
    
    getByStatus: async (status) => {
      return await callApi(`/automata/tasks/status/${status}`, {
        method: 'GET'
      });
    },
    
    getByFunction: async (functionId) => {
      return await callApi(`/automata/tasks/function/${functionId}`, {
        method: 'GET'
      });
    }
  },
  
  // Gestion des anomalies
  anomalies: {
    getAll: async (functionId, status) => {
      const params = {};
      if (functionId) params.functionId = functionId;
      if (status) params.status = status;
      
      return await callApi('/automata/anomalies', {
        method: 'GET',
        params
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/anomalies/${id}`, {
        method: 'GET'
      });
    },
    
    create: async (anomalyData) => {
      return await callApi('/automata/anomalies', {
        method: 'POST',
        body: JSON.stringify(anomalyData)
      });
    },
    
    update: async (id, anomalyData) => {
      return await callApi(`/automata/anomalies/${id}`, {
        method: 'PUT',
        body: JSON.stringify(anomalyData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/anomalies/${id}`, {
        method: 'DELETE'
      });
    },
    
    updateStatus: async (id, status) => {
      return await callApi(`/automata/anomalies/${id}/status`, {
        method: 'PATCH',
        params: { status }
      });
    },
    
    assign: async (id, userId) => {
      return await callApi(`/automata/anomalies/${id}/assign`, {
        method: 'PATCH',
        params: { userId }
      });
    }
  },
  
  // Gestion des évolutions
  evolutions: {
    getAll: async (functionId, status) => {
      const params = {};
      if (functionId) params.functionId = functionId;
      if (status) params.status = status;
      
      return await callApi('/automata/evolutions', {
        method: 'GET',
        params
      });
    },
    
    getById: async (id) => {
      return await callApi(`/automata/evolutions/${id}`, {
        method: 'GET'
      });
    },
    
    create: async (evolutionData) => {
      return await callApi('/automata/evolutions', {
        method: 'POST',
        body: JSON.stringify(evolutionData)
      });
    },
    
    update: async (id, evolutionData) => {
      return await callApi(`/automata/evolutions/${id}`, {
        method: 'PUT',
        body: JSON.stringify(evolutionData)
      });
    },
    
    delete: async (id) => {
      return await callApi(`/automata/evolutions/${id}`, {
        method: 'DELETE'
      });
    },
    
    updateStatus: async (id, status) => {
      return await callApi(`/automata/evolutions/${id}/status`, {
        method: 'PATCH',
        params: { status }
      });
    },
    
    assign: async (id, userId) => {
      return await callApi(`/automata/evolutions/${id}/assign`, {
        method: 'PATCH',
        params: { userId }
      });
    },
    
    approve: async (id) => {
      return await callApi(`/automata/evolutions/${id}/approve`, {
        method: 'POST'
      });
    }
  },
  
  // Enregistrement de tâches


// Suppression de la méthode createPollingEmitter et simplification de createSseEmitter
record: {
    start: async (userId, taskData) => {
      try {
        const response = await callApi(`/automata/record/start?userId=${encodeURIComponent(userId)}`, {
          method: 'POST',
          body: JSON.stringify(taskData)
        });
        
        return response;
      } catch (error) {
        console.error('Error starting record:', error);
        throw error;
      }
    },
    
    stop: async (userId) => {
      try {
        return await callApi('/automata/record/stop', {
          method: 'POST',
          headers: {
            'User-ID': userId
          }
        });
      } catch (error) {
        console.error('Error stopping record:', error);
        throw error;
      }
    },
    
save: async (userId, taskData) => {
  try {
    return await callApi(`/automata/record/save?userId=${encodeURIComponent(userId)}`, {
      method: 'POST',
      body: JSON.stringify(taskData)
    });
  } catch (error) {
    console.error('Error saving task:', error);
    throw error;
  }
},
    
    sendInteraction: async (userId, interactionData) => {
      try {
        return await callApi('/automata/record/interaction', {
          method: 'POST',
          body: JSON.stringify(interactionData),
          headers: {
            'User-ID': userId
          }
        });
      } catch (error) {
        console.error('Error sending interaction:', error);
        throw error;
      }
    },
    
    createWebSocket: (userId) => {
  console.log(`Creating WebSocket connection for user: ${userId}`);
  const connectionKey = `ws_${userId}`;
  
  if (!activeConnections[connectionKey]) {
    activeConnections[connectionKey] = createRealTimeConnection({
      baseUrl: config.API_URL,
      endpoint: '/automata/record/ws',  // Vérifiez que c'est le bon chemin
      userId,
      debug: true
    });
    
    activeConnections[connectionKey].connect();
  }
  
  return activeConnections[connectionKey];
},
    
    // NOUVEAU: ajouter cette méthode pour envoyer des messages WebSocket
    sendWebSocketMessage: (userId, message) => {
      const connectionKey = `ws_${userId}`;
      if (!activeConnections[connectionKey]) {
        console.error(`No WebSocket connection for user: ${userId}`);
        return false;
      }
      
      return activeConnections[connectionKey].sendMessage(message);
    },
    
    // Adapter ces méthodes existantes pour WebSocket
    closeConnections: (userId) => {
      const connectionKey = `ws_${userId}`;
      if (activeConnections[connectionKey]) {
        console.log(`Closing WebSocket connection for user: ${userId}`);
        activeConnections[connectionKey].close();
        delete activeConnections[connectionKey];
      }
    },
    
    closeAllConnections: () => {
      console.log("Closing all WebSocket connections");
      Object.keys(activeConnections).forEach(key => {
        if (activeConnections[key]) {
          activeConnections[key].close();
        }
      });
      // Réinitialiser la collection
      activeConnections = {};
    }
},
  
  // Exécution de tâches
  execution: {
    start: async (userId, taskData) => {
      try {
        const response = await callApi('/automata/execute/start', {
          method: 'POST',
          body: JSON.stringify(taskData),
          headers: {
            'User-ID': userId
          }
        });
        return response;
      } catch (error) {
        console.error('Error starting execution:', error);
        throw error;
      }
    },
    
    stop: async (userId) => {
      try {
        return await callApi('/automata/execute/stop', {
          method: 'POST',
          headers: {
            'User-ID': userId
          }
        });
      } catch (error) {
        console.error('Error stopping execution:', error);
        throw error;
      }
    },
    
    getTasks: async (functionId) => {
      return await callApi('/automata/execute/tasks', {
        method: 'GET',
        params: { functionId }
      });
    },
    
    getTaskDetails: async (taskId) => {
      return await callApi(`/automata/execute/tasks/${taskId}`, {
        method: 'GET'
      });
    },
    
    getInteractions: async (taskId) => {
      return await callApi('/automata/execute/interactions', {
        method: 'GET',
        params: { taskId }
      });
    },
    
    createWebSocket: (userId) => {
      console.log(`Creating Execution WebSocket connection for user: ${userId}`);
      const connectionKey = `execution_ws_${userId}`;
      
      if (!activeConnections[connectionKey]) {
        activeConnections[connectionKey] = createRealTimeConnection({
          baseUrl: config.API_URL,
          endpoint: '/automata/execute/ws',
          userId,
          debug: true
        });
        
        activeConnections[connectionKey].connect();
      }
      
      return activeConnections[connectionKey];
    },
    
    sendWebSocketMessage: (userId, message) => {
      const connectionKey = `execution_ws_${userId}`;
      if (!activeConnections[connectionKey]) {
        console.error(`No Execution WebSocket connection for user: ${userId}`);
        return false;
      }
      
      return activeConnections[connectionKey].sendMessage(message);
    },
    
    closeConnection: (userId) => {
      const connectionKey = `execution_ws_${userId}`;
      if (activeConnections[connectionKey]) {
        console.log(`Closing Execution WebSocket connection for user: ${userId}`);
        activeConnections[connectionKey].close();
        delete activeConnections[connectionKey];
      }
    }
  }
}

  
  
};

export default apiService;