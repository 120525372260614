import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box,
  Typography,
  Paper,
  Grid,
  Button as MuiButton,
  IconButton,
  TextField,
  Tabs,
  Tab,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardActions,
  Avatar,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Stack,
  Switch,
  FormControlLabel,
  Alert,
  Snackbar,
} from '@mui/material';

import { 
  Search, Plus, Edit, Trash2, RefreshCw, 
  Grid as GridIcon, List as ListIcon, Filter, X, Check, 
  FileText, CheckCircle, AlertTriangle, Tag,
  Copy, Link, ChevronDown, ChevronUp, Info,
  Calendar, Clock, Settings, Star, ExternalLink,
  User, HelpCircle, Download, Upload, Save, Code, 
  ArrowRight, Menu, Eye, EyeOff
} from 'lucide-react';

import api from '../../services/api.service';
import DateFormatter from '../../components/shared/DateFormatter';

// Définir les couleurs du thème
const COLORS = {
  primary: '#3f51b5',
  secondary: '#f50057',
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
  info: '#2196f3',
  campaign: '#3f51b5',
  testCase: '#673ab7',
  function: '#009688',
  task: '#ff5722',
  chartColors: ['#3f51b5', '#03a9f4', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#673ab7', '#009688', '#ff5722']
};

const TestCaseLibrary = () => {
  // États pour la bibliothèque
  const [testCases, setTestCases] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'grid'
  const [showDetails, setShowDetails] = useState(false);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState(null); // null, true, false
  const [criticalFilter, setCriticalFilter] = useState(null); // null, true, false
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filteredTestCases, setFilteredTestCases] = useState([]);
  
  // États pour les modales
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  
  // État pour le formulaire
  const [testCaseForm, setTestCaseForm] = useState({
    name: '',
    description: '',
    preconditions: '',
    testCaseId: '',
    expectedResult: '',
    isCritical: false,
    isActive: true,
    executionOrder: 0,
    inputParam: {},
    outputParam: {},
    metadata: {}
  });
  
  // États pour le chargement et les erreurs
  const [loading, setLoading] = useState({
    testCases: true,
    testCase: false,
    campaigns: false,
    form: false
  });
  const [error, setError] = useState({
    testCases: null,
    testCase: null,
    campaigns: null,
    form: null
  });
  
  // État pour la pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // État pour les notifications
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // Fonction pour charger tous les cas de test
  const fetchTestCases = useCallback(async () => {
    setLoading(prev => ({ ...prev, testCases: true }));
    setError(prev => ({ ...prev, testCases: null }));
    
    try {
      const response = await api.automata.testCases.getAll();
      setTestCases(response);
    } catch (err) {
      console.error('Error fetching test cases:', err);
      setError(prev => ({ ...prev, testCases: 'Failed to load test cases' }));
    } finally {
      setLoading(prev => ({ ...prev, testCases: false }));
    }
  }, []);
  
  // Fonction pour récupérer les détails d'un cas de test
  const fetchTestCaseDetails = async (id) => {
    if (!id) return;
    
    setLoading(prev => ({ ...prev, testCase: true }));
    setError(prev => ({ ...prev, testCase: null }));
    
    try {
      const response = await api.automata.testCases.getById(id);
      setSelectedTestCase(response);
    } catch (err) {
      console.error('Error fetching test case details:', err);
      setError(prev => ({ ...prev, testCase: 'Failed to load test case details' }));
    } finally {
      setLoading(prev => ({ ...prev, testCase: false }));
    }
  };
  
  // Fonction pour récupérer toutes les campagnes disponibles
  const fetchCampaigns = async () => {
    setLoading(prev => ({ ...prev, campaigns: true }));
    setError(prev => ({ ...prev, campaigns: null }));
    
    try {
      const response = await api.automata.campaigns.getAll();
      setCampaigns(response);
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      setError(prev => ({ ...prev, campaigns: 'Failed to load campaigns' }));
    } finally {
      setLoading(prev => ({ ...prev, campaigns: false }));
    }
  };
  
  // Fonction pour filtrer et trier les cas de test
  const filterAndSortTestCases = useCallback(() => {
    let filtered = [...testCases];
    
    // Appliquer le filtre de recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(tc => 
        tc.name?.toLowerCase().includes(term) ||
        tc.description?.toLowerCase().includes(term) ||
        tc.testCaseId?.toLowerCase().includes(term) ||
        tc.preconditions?.toLowerCase().includes(term) ||
        tc.expectedResult?.toLowerCase().includes(term)
      );
    }
    
    // Appliquer le filtre d'activité
    if (activeFilter !== null) {
      filtered = filtered.filter(tc => tc.isActive === activeFilter);
    }
    
    // Appliquer le filtre de criticité
    if (criticalFilter !== null) {
      filtered = filtered.filter(tc => tc.isCritical === criticalFilter);
    }
    
    // Appliquer le tri
    filtered.sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];
      
      // Gestion des champs potentiellement nuls
      if (aValue === undefined || aValue === null) aValue = '';
      if (bValue === undefined || bValue === null) bValue = '';
      
      // Traitement spécial pour les dates
      if (sortField === 'creationDate' || sortField === 'lastModifiedDate') {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      }
      
      // Tri ascendant ou descendant
      if (sortDirection === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });
    
    setFilteredTestCases(filtered);
  }, [testCases, searchTerm, activeFilter, criticalFilter, sortField, sortDirection]);
  
  // Effet pour charger les cas de test au montage
  useEffect(() => {
    fetchTestCases();
  }, [fetchTestCases]);
  
  // Effet pour filtrer et trier les cas de test quand les critères changent
  useEffect(() => {
    filterAndSortTestCases();
  }, [filterAndSortTestCases, testCases, searchTerm, activeFilter, criticalFilter, sortField, sortDirection]);
  
  // Gestion de la pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  // Fonctions pour gérer le formulaire
  const resetTestCaseForm = () => {
    setTestCaseForm({
      name: '',
      description: '',
      preconditions: '',
      testCaseId: '',
      expectedResult: '',
      isCritical: false,
      isActive: true,
      executionOrder: 0,
      inputParam: {},
      outputParam: {},
      metadata: {}
    });
  };
  
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTestCaseForm(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  const populateFormWithTestCase = (testCase) => {
    setTestCaseForm({
      name: testCase.name || '',
      description: testCase.description || '',
      preconditions: testCase.preconditions || '',
      testCaseId: testCase.testCaseId || '',
      expectedResult: testCase.expectedResult || '',
      isCritical: testCase.isCritical || false,
      isActive: testCase.isActive !== undefined ? testCase.isActive : true,
      executionOrder: testCase.executionOrder || 0,
      inputParam: testCase.inputParam || {},
      outputParam: testCase.outputParam || {},
      metadata: testCase.metadata || {}
    });
  };
  
  // Fonctions pour les opérations CRUD
  const createTestCase = async () => {
    setLoading(prev => ({ ...prev, form: true }));
    setError(prev => ({ ...prev, form: null }));
    
    try {
      // Récupérer l'ID utilisateur depuis le stockage local (à adapter selon votre système d'authentification)
      const userId = localStorage.getItem('user_id') || 'anonymous';
      
      // Préparer l'objet cas de test
      const testCaseData = {
        ...testCaseForm,
        creationDate: new Date().toISOString()
      };
      
      // Créer le cas de test
      const response = await api.automata.testCases.create(testCaseData, userId);
      
      // Fermer le modal et actualiser la liste
      setShowCreateModal(false);
      await fetchTestCases();
      
      // Sélectionner le nouveau cas de test
      setSelectedTestCase(response);
      
      // Réinitialiser le formulaire
      resetTestCaseForm();

      // Afficher une notification
      setNotification({
        open: true,
        message: 'Cas de test créé avec succès',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error creating test case:', err);
      setError(prev => ({ ...prev, form: 'Failed to create test case' }));
      setNotification({
        open: true,
        message: 'Erreur lors de la création du cas de test',
        severity: 'error'
      });
    } finally {
      setLoading(prev => ({ ...prev, form: false }));
    }
  };
  
  const updateTestCase = async () => {
    if (!selectedTestCase) return;
    
    setLoading(prev => ({ ...prev, form: true }));
    setError(prev => ({ ...prev, form: null }));
    
    try {
      // Préparer l'objet cas de test
      const testCaseData = {
        ...selectedTestCase,
        ...testCaseForm,
        lastModifiedDate: new Date().toISOString()
      };
      
      // Mettre à jour le cas de test
      await api.automata.testCases.update(selectedTestCase.id, testCaseData);
      
      // Fermer le modal et actualiser la liste et les détails
      setShowEditModal(false);
      await fetchTestCases();
      await fetchTestCaseDetails(selectedTestCase.id);
      
      // Réinitialiser le formulaire
      resetTestCaseForm();

      // Afficher une notification
      setNotification({
        open: true,
        message: 'Cas de test mis à jour avec succès',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating test case:', err);
      setError(prev => ({ ...prev, form: 'Failed to update test case' }));
      setNotification({
        open: true,
        message: 'Erreur lors de la mise à jour du cas de test',
        severity: 'error'
      });
    } finally {
      setLoading(prev => ({ ...prev, form: false }));
    }
  };
  
  const deleteTestCase = async () => {
    if (!selectedTestCase) return;
    
    setLoading(prev => ({ ...prev, form: true }));
    setError(prev => ({ ...prev, form: null }));
    
    try {
      // Supprimer le cas de test
      await api.automata.testCases.delete(selectedTestCase.id);
      
      // Fermer le modal et actualiser la liste
      setShowDeleteModal(false);
      await fetchTestCases();
      
      // Désélectionner le cas de test
      setSelectedTestCase(null);

      // Afficher une notification
      setNotification({
        open: true,
        message: 'Cas de test supprimé avec succès',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error deleting test case:', err);
      setError(prev => ({ ...prev, form: 'Failed to delete test case' }));
      setNotification({
        open: true,
        message: 'Erreur lors de la suppression du cas de test',
        severity: 'error'
      });
    } finally {
      setLoading(prev => ({ ...prev, form: false }));
    }
  };
  
  const assignTestCaseToCampaign = async (campaignId) => {
    if (!selectedTestCase || !campaignId) return;
    
    try {
      // Lier le cas de test à la campagne
      await api.automata.campaigns.linkTestCase(campaignId, selectedTestCase.id);
      
      // Fermer le modal et afficher une notification
      setShowAssignModal(false);
      
      setNotification({
        open: true,
        message: 'Cas de test assigné à la campagne avec succès',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error assigning test case to campaign:', err);
      setNotification({
        open: true,
        message: 'Erreur lors de l\'assignation du cas de test à la campagne',
        severity: 'error'
      });
    }
  };
  
  // Gestionnaire pour changer le champ de tri
  const handleSortChange = (field) => {
    if (sortField === field) {
      // Si le champ est déjà sélectionné, changer la direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Sinon, définir le nouveau champ et réinitialiser la direction
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Gestionnaire pour la sélection de cas de test
  const handleTestCaseSelect = (id) => {
    // Si on clique sur le cas de test déjà sélectionné, le désélectionner
    if (selectedTestCase && selectedTestCase.id === id) {
      setSelectedTestCase(null);
      return;
    }
    
    // Chercher le cas de test dans la liste filtrée
    const testCase = filteredTestCases.find(tc => tc.id === id);
    if (testCase) {
      setSelectedTestCase(testCase);
      fetchTestCaseDetails(id);
    }
  };
  
  // Gestionnaire pour la sélection multiple
  const handleMultipleSelect = (event, id) => {
    event.stopPropagation();
    
    setSelectedTestCases(prev => {
      if (prev.includes(id)) {
        return prev.filter(testId => testId !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  
  // Fonction pour supprimer plusieurs cas de test
  const deleteMultipleTestCases = async () => {
    if (selectedTestCases.length === 0) return;
    
    try {
      // Créer un tableau de promesses pour les suppressions
      const deletePromises = selectedTestCases.map(id => 
        api.automata.testCases.delete(id)
      );
      
      // Attendre que toutes les suppressions soient terminées
      await Promise.all(deletePromises);
      
      // Actualiser la liste et désélectionner
      await fetchTestCases();
      setSelectedTestCases([]);
      setSelectedTestCase(null);
      
      setNotification({
        open: true,
        message: `${selectedTestCases.length} cas de test supprimés avec succès`,
        severity: 'success'
      });
    } catch (err) {
      console.error('Error deleting multiple test cases:', err);
      setNotification({
        open: true,
        message: 'Erreur lors de la suppression des cas de test',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour dupliquer un cas de test
  const duplicateTestCase = async (testCase) => {
    if (!testCase) return;
    
    try {
      // Préparer l'objet cas de test dupliqué
      const duplicatedTestCase = {
        ...testCase,
        name: `${testCase.name} (Copy)`,
        testCaseId: '', // Le backend générera un nouvel ID
        creationDate: new Date().toISOString()
      };
      
      // Supprimer l'ID pour créer un nouvel enregistrement
      delete duplicatedTestCase.id;
      
      // Créer le cas de test dupliqué
      const userId = localStorage.getItem('user_id') || 'anonymous';
      await api.automata.testCases.create(duplicatedTestCase, userId);
      
      // Actualiser la liste
      await fetchTestCases();
      
      setNotification({
        open: true,
        message: 'Cas de test dupliqué avec succès',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error duplicating test case:', err);
      setNotification({
        open: true,
        message: 'Erreur lors de la duplication du cas de test',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour modifier l'ordre d'exécution d'un cas de test
  const updateExecutionOrder = async (testCase, newOrder) => {
    if (!testCase) return;
    
    try {
      // Mettre à jour l'ordre d'exécution
      const updatedTestCase = {
        ...testCase,
        executionOrder: newOrder
      };
      
      // Envoyer la mise à jour
      await api.automata.testCases.update(testCase.id, updatedTestCase);
      
      // Actualiser la liste
      await fetchTestCases();
      
      // Si le cas de test modifié est sélectionné, actualiser les détails
      if (selectedTestCase && selectedTestCase.id === testCase.id) {
        fetchTestCaseDetails(testCase.id);
      }
      
      setNotification({
        open: true,
        message: 'Ordre d\'exécution mis à jour avec succès',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating execution order:', err);
      setNotification({
        open: true,
        message: 'Erreur lors de la mise à jour de l\'ordre d\'exécution',
        severity: 'error'
      });
    }
  };

  // Fermer la notification
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };
  
  // Rendu de la vue liste
  const renderListView = () => {
    return (
      <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {/* Checkbox de sélection pour tous les éléments */}
              </TableCell>
              <TableCell 
                onClick={() => handleSortChange('name')}
                sx={{ cursor: 'pointer' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Nom
                  {sortField === 'name' && (
                    sortDirection === 'asc' ? <ChevronUp size={18} /> : <ChevronDown size={18} />
                  )}
                </Box>
              </TableCell>
              <TableCell>Description</TableCell>
              <TableCell 
                onClick={() => handleSortChange('isActive')}
                sx={{ cursor: 'pointer' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Statut
                  {sortField === 'isActive' && (
                    sortDirection === 'asc' ? <ChevronUp size={18} /> : <ChevronDown size={18} />
                  )}
                </Box>
              </TableCell>
              <TableCell 
                onClick={() => handleSortChange('isCritical')}
                sx={{ cursor: 'pointer' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Critique
                  {sortField === 'isCritical' && (
                    sortDirection === 'asc' ? <ChevronUp size={18} /> : <ChevronDown size={18} />
                  )}
                </Box>
              </TableCell>
              <TableCell 
                onClick={() => handleSortChange('executionOrder')}
                sx={{ cursor: 'pointer' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Ordre
                  {sortField === 'executionOrder' && (
                    sortDirection === 'asc' ? <ChevronUp size={18} /> : <ChevronDown size={18} />
                  )}
                </Box>
              </TableCell>
              <TableCell 
                onClick={() => handleSortChange('creationDate')}
                sx={{ cursor: 'pointer' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Créé le
                  {sortField === 'creationDate' && (
                    sortDirection === 'asc' ? <ChevronUp size={18} /> : <ChevronDown size={18} />
                  )}
                </Box>
              </TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredTestCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredTestCases
            ).map((testCase) => (
              <TableRow 
                key={testCase.id}
                hover
                onClick={() => handleTestCaseSelect(testCase.id)}
                selected={selectedTestCase?.id === testCase.id}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell padding="checkbox">
                  <IconButton 
                    size="small"
                    onClick={(e) => handleMultipleSelect(e, testCase.id)}
                  >
                    {selectedTestCases.includes(testCase.id) ? 
                      <Check size={16} style={{ color: COLORS.primary }} /> : 
                      <div style={{ width: 16, height: 16, border: '1px solid #aaa', borderRadius: '2px' }} />
                    }
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="body2" fontWeight="medium">
                      {testCase.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {testCase.testCaseId}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 250 }}>
                    {testCase.description || 'Aucune description'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip 
                    size="small"
                    color={testCase.isActive ? 'success' : 'default'}
                    label={testCase.isActive ? 'Actif' : 'Inactif'}
                    icon={testCase.isActive ? <CheckCircle size={14} /> : <X size={14} />}
                  />
                </TableCell>
                <TableCell>
                  {testCase.isCritical ? (
                    <Chip 
                      size="small"
                      color="warning"
                      label="Critique"
                      icon={<AlertTriangle size={14} />}
                    />
                  ) : (
                    <Typography variant="body2" color="text.secondary">Non</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    value={testCase.executionOrder || 0}
                    onChange={(e) => {
                      e.stopPropagation();
                      updateExecutionOrder(testCase, parseInt(e.target.value, 10));
                    }}
                    onClick={(e) => e.stopPropagation()}
                    InputProps={{
                      sx: { width: 70 }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <DateFormatter date={testCase.creationDate} format="default" />
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Tooltip title="Dupliquer">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          duplicateTestCase(testCase);
                        }}
                      >
                        <Copy size={18} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Modifier">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedTestCase(testCase);
                          populateFormWithTestCase(testCase);
                          setShowEditModal(true);
                        }}
                      >
                        <Edit size={18} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedTestCase(testCase);
                          setShowDeleteModal(true);
                        }}
                      >
                        <Trash2 size={18} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={filteredTestCases.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Lignes par page"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
        />
      </TableContainer>
    );
  };

  // Rendu de la vue grille
  const renderGridView = () => {
    return (
      <Grid container spacing={2}>
        {(rowsPerPage > 0
          ? filteredTestCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : filteredTestCases
        ).map((testCase) => (
          <Grid item key={testCase.id} xs={12} sm={6} md={4} lg={3}>
            <MuiCard 
              sx={{ 
                height: '100%',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 6
                },
                boxShadow: 2,
                cursor: 'pointer',
                position: 'relative'
              }}
              onClick={() => handleTestCaseSelect(testCase.id)}
            >
              {/* Badge de sélection */}
              <Box sx={{ position: 'absolute', top: 8, left: 8 }}>
                <IconButton 
                  size="small"
                  onClick={(e) => handleMultipleSelect(e, testCase.id)}
                  sx={{ 
                    bgcolor: selectedTestCases.includes(testCase.id) ? COLORS.primary : 'rgba(255,255,255,0.8)',
                    '&:hover': { bgcolor: selectedTestCases.includes(testCase.id) ? COLORS.primary : 'rgba(255,255,255,0.9)' },
                    boxShadow: 1
                  }}
                >
                  {selectedTestCases.includes(testCase.id) ? 
                    <Check size={16} style={{ color: 'white' }} /> : 
                    <Plus size={16} style={{ color: '#666' }} />
                  }
                </IconButton>
              </Box>

              <CardHeader
                title={
                  <Tooltip title={testCase.name}>
                    <Typography variant="h6" noWrap>
                      {testCase.name}
                    </Typography>
                  </Tooltip>
                }
                subheader={testCase.testCaseId}
                avatar={
                  <Avatar sx={{ bgcolor: COLORS.testCase }}>
                    <FileText size={20} />
                  </Avatar>
                }
                action={
                  <Box sx={{ mt: 1, mr: 1, display: 'flex', gap: 0.5 }}>
                    <Chip 
                      size="small"
                      color={testCase.isActive ? 'success' : 'default'}
                      label={testCase.isActive ? 'Actif' : 'Inactif'}
                    />
                    {testCase.isCritical && (
                      <Chip 
                        size="small"
                        color="warning"
                        label="Critique"
                      />
                    )}
                  </Box>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, height: 60, overflow: 'hidden' }}>
                  {testCase.description || 'Aucune description'}
                </Typography>
                
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary">
                      Ordre d'exécution
                    </Typography>
                    <Typography variant="body2">
                      {testCase.executionOrder || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary">
                      Créé le
                    </Typography>
                    <Typography variant="body2">
                      <DateFormatter date={testCase.creationDate} format="short" />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <MuiButton 
                  size="small" 
                  startIcon={<Copy size={14} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    duplicateTestCase(testCase);
                  }}
                >
                  Dupliquer
                </MuiButton>
                <MuiButton 
                  size="small"
                  startIcon={<Edit size={14} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTestCase(testCase);
                    populateFormWithTestCase(testCase);
                    setShowEditModal(true);
                  }}
                >
                  Modifier
                </MuiButton>
                <MuiButton 
                  size="small" 
                  color="error"
                  startIcon={<Trash2 size={14} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTestCase(testCase);
                    setShowDeleteModal(true);
                  }}
                >
                  Supprimer
                </MuiButton>
              </CardActions>
            </MuiCard>
          </Grid>
        ))}
        {/* Pagination pour la vue grille */}
        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[8, 12, 20, 40]}
            component="div"
            count={filteredTestCases.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Éléments par page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
          />
        </Grid>
      </Grid>
    );
  };

  // Rendu principal
  return (
    <Box sx={{ p: 3, height: 'calc(100vh - 100px)', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" fontWeight="bold">
          Bibliothèque de cas de test
        </Typography>
        <MuiButton 
          variant="contained" 
          color="primary"
          startIcon={<Plus size={20} />}
          onClick={() => {
            resetTestCaseForm();
            setShowCreateModal(true);
          }}
        >
          Nouveau cas de test
        </MuiButton>
      </Box>
      
      {/* Barre de filtrage */}
      <Paper sx={{ p: 2, mb: 3, boxShadow: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder="Rechercher des cas de test..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                ),
                size: 'medium'
              }}
              variant="outlined"
            />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Statut</InputLabel>
              <Select
                value={activeFilter === null ? '' : activeFilter ? 'active' : 'inactive'}
                onChange={(e) => {
                  const value = e.target.value;
                  setActiveFilter(value === '' ? null : value === 'active');
                }}
                label="Statut"
              >
                <MenuItem value="">Tous les statuts</MenuItem>
                <MenuItem value="active">Actifs</MenuItem>
                <MenuItem value="inactive">Inactifs</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Criticité</InputLabel>
              <Select
                value={criticalFilter === null ? '' : criticalFilter ? 'critical' : 'noncritical'}
                onChange={(e) => {
                  const value = e.target.value;
                  setCriticalFilter(value === '' ? null : value === 'critical');
                }}
                label="Criticité"
              >
                <MenuItem value="">Tous les niveaux</MenuItem>
                <MenuItem value="critical">Critiques</MenuItem>
                <MenuItem value="noncritical">Non critiques</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Tooltip title="Vue liste">
                <IconButton 
                  color={viewMode === 'list' ? 'primary' : 'default'}
                  onClick={() => setViewMode('list')}
                >
                  <ListIcon size={20} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Vue grille">
                <IconButton 
                  color={viewMode === 'grid' ? 'primary' : 'default'}
                  onClick={() => setViewMode('grid')}
                >
                  <GridIcon size={20} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Actualiser">
                <IconButton onClick={fetchTestCases}>
                  <RefreshCw size={20} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Actions pour les éléments sélectionnés */}
      {selectedTestCases.length > 0 && (
        <Paper 
          sx={{ 
            p: 1.5, 
            mb: 2, 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            bgcolor: 'primary.light',
            color: 'white'
          }}
        >
          <Typography variant="body1">
            {selectedTestCases.length} cas de test sélectionnés
          </Typography>
          <Stack direction="row" spacing={1}>
            <MuiButton 
              variant="contained"
              size="small"
              startIcon={<Link size={16} />}
              color="secondary"
              onClick={() => {
                fetchCampaigns();
                setShowAssignModal(true);
              }}
            >
              Assigner à une campagne
            </MuiButton>
            <MuiButton 
              variant="contained"
              size="small"
              startIcon={<Trash2 size={16} />}
              color="error"
              onClick={deleteMultipleTestCases}
            >
              Supprimer
            </MuiButton>
          </Stack>
        </Paper>
      )}
      
      {/* Contenu principal (Liste ou Grille) */}
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {loading.testCases ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : error.testCases ? (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%',
            color: 'error.main'
          }}>
            <AlertTriangle size={48} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {error.testCases}
            </Typography>
          </Box>
        ) : filteredTestCases.length === 0 ? (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%',
            color: 'text.secondary'
          }}>
            <FileText size={48} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Aucun cas de test trouvé
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Modifiez vos critères de filtrage ou créez un nouveau cas de test
            </Typography>
          </Box>
        ) : (
          viewMode === 'list' ? renderListView() : renderGridView()
        )}
      </Box>
      
      {/* Modal de détails du cas de test */}
      <Dialog
        open={showDetails && !!selectedTestCase}
        onClose={() => setShowDetails(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedTestCase && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: COLORS.testCase, mr: 2 }}>
                    <FileText size={24} />
                  </Avatar>
                  <Typography variant="h6">
                    {selectedTestCase.name}
                  </Typography>
                </Box>
                <Box>
                  <Chip 
                    color={selectedTestCase.isActive ? 'success' : 'default'}
                    size="small"
                    label={selectedTestCase.isActive ? 'Actif' : 'Inactif'}
                    sx={{ mr: 1 }}
                  />
                  {selectedTestCase.isCritical && (
                    <Chip 
                      color="warning"
                      size="small"
                      label="Critique"
                    />
                  )}
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              {loading.testCase ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                      Description
                    </Typography>
                    <Paper sx={{ p: 2, bgcolor: 'background.default', minHeight: 100 }}>
                      <Typography variant="body2">
                        {selectedTestCase.description || 'Aucune description'}
                      </Typography>
                    </Paper>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                      Préconditions
                    </Typography>
                    <Paper sx={{ p: 2, bgcolor: 'background.default', minHeight: 100 }}>
                      <Typography variant="body2">
                        {selectedTestCase.preconditions || 'Aucune précondition'}
                      </Typography>
                    </Paper>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                      Résultat attendu
                    </Typography>
                    <Paper sx={{ p: 2, bgcolor: 'background.default' }}>
                      <Typography variant="body2">
                        {selectedTestCase.expectedResult || 'Aucun résultat attendu défini'}
                      </Typography>
                    </Paper>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                      Informations
                    </Typography>
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ width: '40%' }}>
                              ID
                            </TableCell>
                            <TableCell>{selectedTestCase.id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Référence
                            </TableCell>
                            <TableCell>{selectedTestCase.testCaseId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Créé le
                            </TableCell>
                            <TableCell>
                              <DateFormatter date={selectedTestCase.creationDate} format="full" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Dernière modification
                            </TableCell>
                            <TableCell>
                              {selectedTestCase.lastModifiedDate ? (
                                <DateFormatter date={selectedTestCase.lastModifiedDate} format="full" />
                              ) : 'Jamais'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Ordre d'exécution
                            </TableCell>
                            <TableCell>{selectedTestCase.executionOrder || 0}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                      Paramètres
                    </Typography>
                    <Tabs value={0} sx={{ mb: 2 }}>
                      <Tab label="Entrée" />
                      <Tab label="Sortie" />
                    </Tabs>
                    <Paper sx={{ p: 2, bgcolor: 'background.default', height: 173, overflow: 'auto' }}>
                      {Object.keys(selectedTestCase.inputParam || {}).length > 0 ? (
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Paramètre</TableCell>
                                <TableCell>Valeur</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(selectedTestCase.inputParam || {}).map(([key, value]) => (
                                <TableRow key={key}>
                                  <TableCell>{key}</TableCell>
                                  <TableCell>{value}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ py: 4, textAlign: 'center' }}>
                          Aucun paramètre d'entrée défini
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <MuiButton
                startIcon={<Edit size={18} />}
                onClick={() => {
                  setShowDetails(false);
                  populateFormWithTestCase(selectedTestCase);
                  setShowEditModal(true);
                }}
              >
                Modifier
              </MuiButton>
              <MuiButton
                startIcon={<Copy size={18} />}
                onClick={() => {
                  setShowDetails(false);
                  duplicateTestCase(selectedTestCase);
                }}
              >
                Dupliquer
              </MuiButton>
              <MuiButton onClick={() => setShowDetails(false)}>
                Fermer
              </MuiButton>
            </DialogActions>
          </>
        )}
      </Dialog>
      
      {/* Modal de création de cas de test */}
      <Dialog
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: COLORS.testCase, mr: 2 }}>
              <Plus size={24} />
            </Avatar>
            <Typography variant="h6">
              Créer un nouveau cas de test
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nom du cas de test"
                name="name"
                value={testCaseForm.name}
                onChange={handleFormChange}
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="ID du cas de test"
                name="testCaseId"
                value={testCaseForm.testCaseId}
                onChange={handleFormChange}
                helperText="Généré automatiquement si vide"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={testCaseForm.description}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Préconditions"
                name="preconditions"
                value={testCaseForm.preconditions}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Résultat attendu"
                name="expectedResult"
                value={testCaseForm.expectedResult}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Ordre d'exécution"
                name="executionOrder"
                type="number"
                value={testCaseForm.executionOrder}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={testCaseForm.isCritical}
                      onChange={handleFormChange}
                      name="isCritical"
                    />
                  }
                  label="Cas de test critique"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={testCaseForm.isActive}
                      onChange={handleFormChange}
                      name="isActive"
                    />
                  }
                  label="Cas de test actif"
                />
              </Box>
            </Grid>
          </Grid>
          {error.form && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error.form}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowCreateModal(false)}
            disabled={loading.form}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="primary"
            onClick={createTestCase}
            disabled={loading.form || !testCaseForm.name}
            startIcon={loading.form ? <CircularProgress size={20} /> : <Save size={20} />}
          >
            Créer
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Modal de modification de cas de test */}
      <Dialog
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: COLORS.testCase, mr: 2 }}>
              <Edit size={24} />
            </Avatar>
            <Typography variant="h6">
              Modifier le cas de test
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nom du cas de test"
                name="name"
                value={testCaseForm.name}
                onChange={handleFormChange}
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="ID du cas de test"
                name="testCaseId"
                value={testCaseForm.testCaseId}
                onChange={handleFormChange}
                disabled
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={testCaseForm.description}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Préconditions"
                name="preconditions"
                value={testCaseForm.preconditions}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Résultat attendu"
                name="expectedResult"
                value={testCaseForm.expectedResult}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Ordre d'exécution"
                name="executionOrder"
                type="number"
                value={testCaseForm.executionOrder}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={testCaseForm.isCritical}
                      onChange={handleFormChange}
                      name="isCritical"
                    />
                  }
                  label="Cas de test critique"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={testCaseForm.isActive}
                      onChange={handleFormChange}
                      name="isActive"
                    />
                  }
                  label="Cas de test actif"
                />
              </Box>
            </Grid>
          </Grid>
          {error.form && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error.form}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton 
            color="error"
            startIcon={<Trash2 size={20} />}
            onClick={() => {
              setShowEditModal(false);
              setShowDeleteModal(true);
            }}
            disabled={loading.form}
          >
            Supprimer
          </MuiButton>
          <Box sx={{ flex: 1 }} />
          <MuiButton 
            onClick={() => setShowEditModal(false)}
            disabled={loading.form}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="primary"
            onClick={updateTestCase}
            disabled={loading.form || !testCaseForm.name}
            startIcon={loading.form ? <CircularProgress size={20} /> : <Save size={20} />}
          >
            Enregistrer
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Modal de confirmation de suppression */}
      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ color: 'error.main' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Trash2 size={24} sx={{ mr: 2 }} />
            Confirmer la suppression
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedTestCase && (
            <Typography variant="body1" gutterBottom>
              Êtes-vous sûr de vouloir supprimer le cas de test <strong>"{selectedTestCase.name}"</strong> ? Cette action est irréversible.
            </Typography>
          )}
          <Alert severity="warning" sx={{ mt: 2 }}>
            Cette action supprimera définitivement ce cas de test et le retirera de toutes les campagnes associées.
          </Alert>
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowDeleteModal(false)}
            disabled={loading.form}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="error"
            onClick={deleteTestCase}
            disabled={loading.form}
            startIcon={loading.form ? <CircularProgress size={20} /> : null}
          >
            Supprimer
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Modal d'assignation à une campagne */}
      <Dialog
        open={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: COLORS.campaign, mr: 2 }}>
              <Link size={24} />
            </Avatar>
            <Typography variant="h6">
              Assigner à une campagne
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {loading.campaigns ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          ) : error.campaigns ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error.campaigns}
            </Alert>
          ) : campaigns.length === 0 ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center', 
              alignItems: 'center', 
              p: 4,
              color: 'text.secondary'
            }}>
              <AlertTriangle size={48} />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Aucune campagne disponible
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, mb: 2, textAlign: 'center' }}>
                Vous devez d'abord créer une campagne avant de pouvoir y assigner des cas de test.
              </Typography>
              <MuiButton 
                variant="outlined"
                startIcon={<ArrowRight size={18} />}
                onClick={() => window.location.href = '/atm-campaign/cpg-02-campaignmanager'}
              >
                Gérer les campagnes
              </MuiButton>
            </Box>
          ) : (
            <>
              <Typography variant="body1" paragraph>
                Sélectionnez une campagne pour y assigner {selectedTestCases.length > 0 ? 
                  `les ${selectedTestCases.length} cas de test sélectionnés` : 
                  'ce cas de test'}.
              </Typography>
              <Grid container spacing={2}>
                {campaigns.map(campaign => (
                  <Grid item xs={12} md={6} key={campaign.id}>
                    <MuiCard 
                      sx={{ 
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          boxShadow: 3,
                          transform: 'translateY(-2px)'
                        }
                      }}
                      onClick={() => assignTestCaseToCampaign(campaign.id)}
                    >
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: COLORS.campaign }}>
                            <Calendar size={20} />
                          </Avatar>
                        }
                        title={campaign.name}
                        subheader={campaign.campaignId}
                        action={
                          <Chip 
                            label={campaign.status}
                            size="small"
                            color={
                              campaign.status === 'ACTIVE' ? 'success' :
                              campaign.status === 'IN_PROGRESS' ? 'info' :
                              campaign.status === 'DRAFT' ? 'default' :
                              'default'
                            }
                          />
                        }
                      />
                      <CardActions>
                        <MuiButton 
                          startIcon={<Link size={16} />}
                          sx={{ ml: 'auto' }}
                        >
                          Assigner
                        </MuiButton>
                      </CardActions>
                    </MuiCard>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setShowAssignModal(false)}>
            Fermer
          </MuiButton>
        </DialogActions>
      </Dialog>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TestCaseLibrary;