import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Typography, Paper, Grid, Divider, Card, CardContent, 
  Accordion, AccordionSummary, AccordionDetails, Chip, Tooltip,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, IconButton, TextField, Stack, Tabs, Tab
} from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { 
  ExpandMore, AccountBalance, Payments, SwapHoriz, 
  AccountBalanceWallet, Person, Business, Schedule, 
  AttachMoney, Visibility, CheckCircle, Error, Info,
  CreditCard, Assignment, ArrowForward, Edit, CurrencyExchange
} from '@mui/icons-material';
import { motion } from 'framer-motion';

// Thème personnalisé pour un look bancaire/financier professionnel
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1e4d8c', // Bleu professionnel foncé
      light: '#4776b4',
      dark: '#122c50',
    },
    secondary: {
      main: '#00695c', // Vert bancaire
      light: '#439889',
      dark: '#003d33',
    },
    background: {
      default: '#f5f7fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#2d3748',
      secondary: '#4a5568',
    },
    success: {
      main: '#2e7d32',
    },
    warning: {
      main: '#ed6c02',
    },
    error: {
      main: '#d32f2f',
    },
    info: {
      main: '#0288d1',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '2.2rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.8rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.3rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.1rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: '16px 0',
            borderRadius: 8,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: '8px 8px 0 0',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '16px 0',
        },
      },
    },
  },
});

// Styles des éléments personnalisés
const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  marginBottom: theme.spacing(4),
}));

const StyledMessageSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderLeft: `4px solid ${theme.palette.primary.main}`,
}));

const InfoChip = styled(Chip)(({ theme, type = 'default' }) => {
  let color = theme.palette.primary.main;
  
  if (type === 'mandatory') color = theme.palette.error.main;
  if (type === 'optional') color = theme.palette.info.main;
  if (type === 'xml') color = theme.palette.success.main;
  
  return {
    backgroundColor: `${color}12`, // Using alpha
    color: color,
    fontWeight: 600,
    border: `1px solid ${color}40`, // Using alpha
    '& .MuiChip-icon': {
      color: color,
    },
  };
});

// Composant pour afficher un champ du message pacs.008
const MessageField = ({ field, value, mandatory = false, type = "text", maxLength, description }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
      <Box sx={{ minWidth: 180, mr: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
          {field}
        </Typography>
        <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
          {mandatory ? (
            <InfoChip
              label="Obligatoire"
              type="mandatory"
              size="small"
              icon={<CheckCircle fontSize="small" />}
            />
          ) : (
            <InfoChip
              label="Optionnel"
              type="optional"
              size="small"
              icon={<Info fontSize="small" />}
            />
          )}
          {maxLength && (
            <InfoChip
              label={`Max: ${maxLength}`}
              size="small"
              icon={<Info fontSize="small" />}
            />
          )}
        </Stack>
      </Box>
      <TextField 
        fullWidth
        variant="outlined"
        size="small"
        value={value}
        type={type}
        disabled
        sx={{ bgcolor: 'background.paper' }}
        helperText={description}
      />
    </Box>
  );
};

// Composant principal pour la modélisation du message pacs.008
const Pacs008MessageVisualizer = () => {
  const [activeTab, setActiveTab] = useState(0);
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  // Données d'exemple pour un message pacs.008
  const sampleData = {
    groupHeader: {
      messageId: "MSGID/20230615/00001",
      creationDateTime: "2023-06-15T10:15:30Z",
      numberOfTransactions: "1",
      settlementMethod: "CLRG",
      settlementAccount: "NOSTRO",
    },
    paymentInformation: {
      paymentInformationId: "PAYINFID/20230615/00001",
      paymentMethod: "TRF",
      requestedExecutionDate: "2023-06-15",
      debtor: {
        name: "John Doe",
        postalAddress: "10 Main Street, New York, NY 10001, USA",
        accountId: "DE89370400440532013000",
        accountType: "CACC",
      },
      debtorAgent: {
        financialInstitutionId: "BANKDEFFXXX",
        name: "Deutsche Bank",
      },
    },
    creditTransferTransaction: {
      paymentId: {
        instructionId: "INSTRID/20230615/00001",
        endToEndId: "E2EID/20230615/00001",
        transactionId: "TXNID/20230615/00001",
      },
      amount: {
        instructedAmount: {
          value: "1000.00",
          currency: "EUR",
        },
        exchangeRate: "1.1234",
      },
      chargeBearer: "DEBT",
      creditorAgent: {
        financialInstitutionId: "BNPAFRPPXXX",
        name: "BNP Paribas",
      },
      creditor: {
        name: "Jane Smith",
        postalAddress: "25 Rue de la Paix, 75002 Paris, France",
        accountId: "FR7630006000011234567890189",
        accountType: "CACC",
      },
      purpose: "CASH",
      remittanceInformation: {
        unstructured: "Invoice 12345 payment",
        structured: {
          referredDocumentInformation: {
            type: "CINV",
            number: "INV-12345",
            relatedDate: "2023-06-01",
          },
          referredDocumentAmount: {
            duePayableAmount: "1000.00",
            currency: "EUR",
          },
        },
      },
      regulatoryReporting: {
        details: {
          type: "CRED",
          information: "International transfer",
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
        <StyledHeaderContainer>
          <Typography variant="h1" component="h1" align="center" gutterBottom>
            Message pacs.008 - ISO 20022
          </Typography>
          <Typography variant="h5" align="center" paragraph sx={{ maxWidth: 800, mx: 'auto' }}>
            Modélisation d'un message de virement interbancaire (FIToFICustomerCreditTransfer)
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <InfoChip
              label="ISO 20022"
              icon={<CheckCircle />}
            />
            <InfoChip
              label="pacs.008.001.08"
              icon={<Info />}
              type="xml"
            />
            <InfoChip
              label="SEPA Credit Transfer"
              icon={<CurrencyExchange />}
            />
          </Box>
        </StyledHeaderContainer>

        <Container>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="Structure du Message" icon={<Assignment />} iconPosition="start" />
            <Tab label="Visualisation Graphique" icon={<Visibility />} iconPosition="start" />
            <Tab label="Exemple XML" icon={<Code />} iconPosition="start" />
          </Tabs>

          {/* Onglet 1: Structure du Message */}
          {activeTab === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MessageStructureView data={sampleData} />
              </Grid>
            </Grid>
          )}

          {/* Onglet 2: Visualisation Graphique */}
          {activeTab === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <GraphicalView data={sampleData} />
              </Grid>
            </Grid>
          )}

          {/* Onglet 3: Exemple XML */}
          {activeTab === 2 && (
            <Grid item xs={12}>
              <XMLView data={sampleData} />
            </Grid>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

// Composant pour afficher la structure du message
const MessageStructureView = ({ data }) => {
  return (
    <>
      {/* GroupHeader */}
      <StyledMessageSection elevation={2}>
        <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <AccountBalance sx={{ mr: 1.5 }} /> Group Header (GrpHdr)
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Informations communes à l'ensemble des transactions incluses dans le message.
        </Typography>
        <Divider />
        
        <Box sx={{ mt: 2 }}>
          <MessageField 
            field="Message ID (MsgId)" 
            value={data.groupHeader.messageId} 
            mandatory={true}
            maxLength={35}
            description="Référence unique du message, attribuée par l'expéditeur"
          />
          <MessageField 
            field="Creation Date Time (CreDtTm)" 
            value={data.groupHeader.creationDateTime} 
            mandatory={true}
            type="datetime-local"
            description="Date et heure de création du message"
          />
          <MessageField 
            field="Number of Transactions (NbOfTxs)" 
            value={data.groupHeader.numberOfTransactions} 
            mandatory={true}
            type="number"
            maxLength={15}
            description="Nombre total de transactions individuelles dans le message"
          />
          <MessageField 
            field="Settlement Method (SttlmMtd)" 
            value={data.groupHeader.settlementMethod} 
            mandatory={true}
            maxLength={4}
            description="Spécifie la méthode de règlement: CLRG (Clearing), COVE (CoveredSettlement), INGA (InstructedAgent), INDA (InstructingAgent)"
          />
          <MessageField 
            field="Settlement Account (SttlmAcct)" 
            value={data.groupHeader.settlementAccount} 
            mandatory={false}
            description="Compte utilisé pour traiter le règlement financier"
          />
        </Box>
      </StyledMessageSection>

      {/* PaymentInformation */}
      <StyledMessageSection elevation={2}>
        <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <AccountBalanceWallet sx={{ mr: 1.5 }} /> Payment Information (PmtInf)
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Ensemble d'informations liées au débiteur, communes à toutes les transactions de crédit.
        </Typography>
        <Divider />
        
        <Box sx={{ mt: 2 }}>
          <MessageField 
            field="Payment Info ID (PmtInfId)" 
            value={data.paymentInformation.paymentInformationId} 
            mandatory={true}
            maxLength={35}
            description="Référence unique attribuée par l'expéditeur pour identifier sans ambiguïté le bloc d'information de paiement"
          />
          <MessageField 
            field="Payment Method (PmtMtd)" 
            value={data.paymentInformation.paymentMethod} 
            mandatory={true}
            maxLength={3}
            description="Spécifie le moyen de paiement: TRF (CreditTransfer), CHK (Cheque)"
          />
          <MessageField 
            field="Requested Execution Date (ReqdExctnDt)" 
            value={data.paymentInformation.requestedExecutionDate} 
            mandatory={true}
            type="date"
            description="Date à laquelle la partie initiatrice demande à la partie chargée du traitement de traiter le paiement"
          />
        </Box>

        {/* Debtor */}
        <Box sx={{ mt: 3, ml: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Person sx={{ mr: 1.5 }} /> Debtor (Dbtr)
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Informations sur la partie qui envoie un montant d'argent à un créancier.
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Name (Nm)" 
              value={data.paymentInformation.debtor.name} 
              mandatory={true}
              maxLength={140}
              description="Nom du débiteur"
            />
            <MessageField 
              field="Postal Address (PstlAdr)" 
              value={data.paymentInformation.debtor.postalAddress} 
              mandatory={false}
              description="Adresse postale du débiteur"
            />
            <MessageField 
              field="Account ID (AcctId)" 
              value={data.paymentInformation.debtor.accountId} 
              mandatory={true}
              maxLength={34}
              description="Identifiant unique et sans ambiguïté du compte du débiteur (IBAN en contexte SEPA)"
            />
            <MessageField 
              field="Account Type (Tp)" 
              value={data.paymentInformation.debtor.accountType} 
              mandatory={false}
              maxLength={4}
              description="Type de compte: CACC (CurrentAccount), SVGS (SavingsAccount), etc."
            />
          </Box>
        </Box>

        {/* DebtorAgent */}
        <Box sx={{ mt: 3, ml: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Business sx={{ mr: 1.5 }} /> Debtor Agent (DbtrAgt)
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Institution financière où le débiteur détient son compte.
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Financial Institution ID (FinInstnId)" 
              value={data.paymentInformation.debtorAgent.financialInstitutionId} 
              mandatory={true}
              maxLength={11}
              description="Identifiant de l'institution financière du débiteur (généralement un code BIC en contexte SEPA)"
            />
            <MessageField 
              field="Name (Nm)" 
              value={data.paymentInformation.debtorAgent.name} 
              mandatory={false}
              maxLength={140}
              description="Nom de l'institution financière du débiteur"
            />
          </Box>
        </Box>
      </StyledMessageSection>

      {/* CreditTransferTransactionInformation */}
      <StyledMessageSection elevation={2}>
        <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <SwapHoriz sx={{ mr: 1.5 }} /> Credit Transfer Transaction (CdtTrfTxInf)
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Ensemble d'éléments fournissant des informations sur les transactions de transfert de crédit individuelles.
        </Typography>
        <Divider />

        {/* PaymentIdentification */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Assignment sx={{ mr: 1.5 }} /> Payment Identification (PmtId)
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Instruction ID (InstrId)" 
              value={data.creditTransferTransaction.paymentId.instructionId} 
              mandatory={false}
              maxLength={35}
              description="Identifiant unique d'instruction attribué par une partie instructrice"
            />
            <MessageField 
              field="End-to-End ID (EndToEndId)" 
              value={data.creditTransferTransaction.paymentId.endToEndId} 
              mandatory={true}
              maxLength={35}
              description="Identifiant unique attribué par la partie initiatrice pour référencer sans ambiguïté la transaction"
            />
            <MessageField 
              field="Transaction ID (TxId)" 
              value={data.creditTransferTransaction.paymentId.transactionId} 
              mandatory={true}
              maxLength={35}
              description="Identifiant unique attribué par la première partie instructrice pour identifier sans ambiguïté la transaction"
            />
          </Box>
        </Box>

        {/* Amount */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoney sx={{ mr: 1.5 }} /> Amount (Amt)
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Instructed Amount (InstdAmt)" 
              value={data.creditTransferTransaction.amount.instructedAmount.value} 
              mandatory={true}
              type="number"
              description="Montant à transférer entre le débiteur et le créancier"
            />
            <MessageField 
              field="Currency (Ccy)" 
              value={data.creditTransferTransaction.amount.instructedAmount.currency} 
              mandatory={true}
              maxLength={3}
              description="Code de devise selon ISO 4217"
            />
            <MessageField 
              field="Exchange Rate (XchgRate)" 
              value={data.creditTransferTransaction.amount.exchangeRate} 
              mandatory={false}
              type="number"
              description="Taux de change utilisé pour la conversion des devises"
            />
          </Box>
        </Box>

        {/* ChargeBearer */}
        <Box sx={{ mt: 3 }}>
          <MessageField 
            field="Charge Bearer (ChrgBr)" 
            value={data.creditTransferTransaction.chargeBearer} 
            mandatory={true}
            maxLength={4}
            description="Spécifie quelle(s) partie(s) supportera(ont) les frais: DEBT (Debtor), CRED (Creditor), SHAR (Shared), SLEV (ServiceLevelAgreement)"
          />
        </Box>

        {/* CreditorAgent */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Business sx={{ mr: 1.5 }} /> Creditor Agent (CdtrAgt)
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Institution financière où le créancier détient son compte.
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Financial Institution ID (FinInstnId)" 
              value={data.creditTransferTransaction.creditorAgent.financialInstitutionId} 
              mandatory={true}
              maxLength={11}
              description="Identifiant de l'institution financière du créancier (généralement un code BIC en contexte SEPA)"
            />
            <MessageField 
              field="Name (Nm)" 
              value={data.creditTransferTransaction.creditorAgent.name} 
              mandatory={false}
              maxLength={140}
              description="Nom de l'institution financière du créancier"
            />
          </Box>
        </Box>

        {/* Creditor */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Person sx={{ mr: 1.5 }} /> Creditor (Cdtr)
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Informations sur la partie qui reçoit un montant d'argent du débiteur.
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Name (Nm)" 
              value={data.creditTransferTransaction.creditor.name} 
              mandatory={true}
              maxLength={140}
              description="Nom du créancier"
            />
            <MessageField 
              field="Postal Address (PstlAdr)" 
              value={data.creditTransferTransaction.creditor.postalAddress} 
              mandatory={false}
              description="Adresse postale du créancier"
            />
            <MessageField 
              field="Account ID (AcctId)" 
              value={data.creditTransferTransaction.creditor.accountId} 
              mandatory={true}
              maxLength={34}
              description="Identifiant unique et sans ambiguïté du compte du créancier (IBAN en contexte SEPA)"
            />
            <MessageField 
              field="Account Type (Tp)" 
              value={data.creditTransferTransaction.creditor.accountType} 
              mandatory={false}
              maxLength={4}
              description="Type de compte: CACC (CurrentAccount), SVGS (SavingsAccount), etc."
            />
          </Box>
        </Box>

        {/* Purpose */}
        <Box sx={{ mt: 3 }}>
          <MessageField 
            field="Purpose (Purp)" 
            value={data.creditTransferTransaction.purpose} 
            mandatory={false}
            maxLength={4}
            description="Code du motif du paiement (ex: CASH, CORT, DIVI, SALA, etc.)"
          />
        </Box>

        {/* RemittanceInformation */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Info sx={{ mr: 1.5 }} /> Remittance Information (RmtInf)
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Informations qui permettent de faire correspondre un paiement avec les éléments qu'il est destiné à régler.
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Unstructured (Ustrd)" 
              value={data.creditTransferTransaction.remittanceInformation.unstructured} 
              mandatory={false}
              maxLength={140}
              description="Information sous forme libre utilisée pour permettre la réconciliation d'une entrée avec les éléments que l'entrée est censée régler"
            />
            
            <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>Information structurée (Strd)</Typography>
            
            <Box sx={{ ml: 3 }}>
              <Typography variant="subtitle2">Referred Document Information (RfrdDocInf)</Typography>
              <Box sx={{ mt: 1, ml: 3 }}>
                <MessageField 
                  field="Type (Tp)" 
                  value={data.creditTransferTransaction.remittanceInformation.structured.referredDocumentInformation.type} 
                  mandatory={false}
                  maxLength={4}
                  description="Code du type de document référencé: CINV (CommercialInvoice), CREN (CreditNote), etc."
                />
                <MessageField 
                  field="Number (Nb)" 
                  value={data.creditTransferTransaction.remittanceInformation.structured.referredDocumentInformation.number} 
                  mandatory={false}
                  maxLength={35}
                  description="Numéro unique et non ambigu assigné par l'émetteur au document référencé"
                />
                <MessageField 
                  field="Related Date (RltdDt)" 
                  value={data.creditTransferTransaction.remittanceInformation.structured.referredDocumentInformation.relatedDate} 
                  mandatory={false}
                  type="date"
                  description="Date associée au document référencé"
                />
              </Box>
            </Box>
            
            <Box sx={{ ml: 3, mt: 2 }}>
              <Typography variant="subtitle2">Referred Document Amount (RfrdDocAmt)</Typography>
              <Box sx={{ mt: 1, ml: 3 }}>
                <MessageField 
                  field="Due Payable Amount (DuePyblAmt)" 
                  value={data.creditTransferTransaction.remittanceInformation.structured.referredDocumentAmount.duePayableAmount} 
                  mandatory={false}
                  type="number"
                  description="Montant dû/payable selon le document référencé"
                />
                <MessageField 
                  field="Currency (Ccy)" 
                  value={data.creditTransferTransaction.remittanceInformation.structured.referredDocumentAmount.currency} 
                  mandatory={false}
                  maxLength={3}
                  description="Code de devise selon ISO 4217"
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* RegulatoryReporting */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Assignment sx={{ mr: 1.5 }} /> Regulatory Reporting (RgltryRptg)
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Informations requises pour répondre aux exigences réglementaires.
          </Typography>
          <Divider />
          
          <Box sx={{ mt: 2 }}>
            <MessageField 
              field="Type (Tp)" 
              value={data.creditTransferTransaction.regulatoryReporting.details.type} 
              mandatory={false}
              maxLength={4}
              description="Type de déclaration réglementaire: CRED (Credit), DEBT (Debit), BOTH (Both)"
            />
            <MessageField 
              field="Information (Inf)" 
              value={data.creditTransferTransaction.regulatoryReporting.details.information} 
              mandatory={false}
              maxLength={35}
              description="Information supplémentaire concernant la déclaration réglementaire"
            />
          </Box>
        </Box>
      </StyledMessageSection>
    </>
  );
};

// Composant pour la vue graphique du message pacs.008
const GraphicalView = ({ data }) => {
  // Ref pour le conteneur SVG
  const svgContainerRef = useRef(null);
  
  // Définitions des éléments graphiques
  const elements = [
    {
      id: "groupHeader",
      title: "Group Header",
      icon: <AccountBalance />,
      color: "#1e4d8c",
    },
    {
      id: "paymentInfo",
      title: "Payment Information",
      icon: <AccountBalanceWallet />,
      color: "#00695c",
    },
    {
      id: "debtor",
      title: "Debtor",
      icon: <Person />,
      color: "#7b1fa2",
      parent: "paymentInfo",
    },
    {
      id: "debtorAgent",
      title: "Debtor Agent",
      icon: <Business />,
      color: "#5e35b1",
      parent: "paymentInfo",
    },
    {
      id: "creditTransfer",
      title: "Credit Transfer Transaction",
      icon: <SwapHoriz />,
      color: "#d32f2f",
    },
    {
      id: "paymentId",
      title: "Payment ID",
      icon: <Assignment />,
      color: "#f57c00",
      parent: "creditTransfer",
    },
    {
      id: "amount",
      title: "Amount",
      icon: <AttachMoney />,
      color: "#689f38",
      parent: "creditTransfer",
    },
    {
      id: "creditorAgent",
      title: "Creditor Agent",
      icon: <Business />,
      color: "#0288d1",
      parent: "creditTransfer",
    },
    {
      id: "creditor",
      title: "Creditor",
      icon: <Person />,
      color: "#00acc1",
      parent: "creditTransfer",
    },
    {
      id: "remittanceInfo",
      title: "Remittance Information",
      icon: <Info />,
      color: "#e64a19",
      parent: "creditTransfer",
    },
  ];

  return (
    <Paper elevation={2} sx={{ p: 3, position: 'relative', minHeight: 600, overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom>
        Visualisation Graphique du Message pacs.008
      </Typography>
      <Divider sx={{ mb: 4 }} />

      {/* Sandbox pour la visualisation SVG */}
      <Box 
        ref={svgContainerRef}
        sx={{ 
          width: '100%', 
          height: 500, 
          position: 'relative',
          bgcolor: '#f8fafc',
          borderRadius: 2,
          border: '1px solid #e2e8f0',
          overflow: 'hidden',
        }}
      >
        <svg width="100%" height="100%" style={{ overflow: 'visible' }}>
          {/* Lignes de flux */}
          <path 
            d="M 200,100 L 500,100" 
            stroke="#1e4d8c" 
            strokeWidth="3" 
            fill="none"
            strokeDasharray="5,5"
          />
          <path 
            d="M 200,230 L 500,230" 
            stroke="#00695c" 
            strokeWidth="3" 
            fill="none"
            strokeDasharray="5,5"
          />
          <path 
            d="M 200,360 L 500,360" 
            stroke="#d32f2f" 
            strokeWidth="3" 
            fill="none"
            strokeDasharray="5,5"
          />
          
          {/* Lignes de relation hiérarchique */}
          <path 
            d="M 550,170 L 700,170" 
            stroke="#7b1fa2" 
            strokeWidth="2" 
            fill="none" 
          />
          <path 
            d="M 550,290 L 700,290" 
            stroke="#5e35b1" 
            strokeWidth="2" 
            fill="none" 
          />
          <path 
            d="M 550,420 L 700,420" 
            stroke="#f57c00" 
            strokeWidth="2" 
            fill="none" 
          />
          <path 
            d="M 550,490 L 700,490" 
            stroke="#689f38" 
            strokeWidth="2" 
            fill="none" 
          />
          <path 
            d="M 550,560 L 700,560" 
            stroke="#0288d1" 
            strokeWidth="2" 
            fill="none" 
          />
          <path 
            d="M 550,630 L 700,630" 
            stroke="#00acc1" 
            strokeWidth="2" 
            fill="none" 
          />
          <path 
            d="M 550,700 L 700,700" 
            stroke="#e64a19" 
            strokeWidth="2" 
            fill="none" 
          />
        </svg>

        {/* Nœuds principaux */}
        <MessageNode
          title="Document"
          position={{ x: 60, y: 100 }}
          color="#455a64"
          icon={<Assignment />}
          mainNode={true}
        />

        <MessageNode
          title="Group Header"
          position={{ x: 200, y: 100 }}
          color="#1e4d8c"
          icon={<AccountBalance />}
          key="groupHeader"
          data={[
            { label: "Message ID", value: data.groupHeader.messageId },
            { label: "Creation Date Time", value: data.groupHeader.creationDateTime },
            { label: "Number of Transactions", value: data.groupHeader.numberOfTransactions },
          ]}
        />

        <MessageNode
          title="Payment Information"
          position={{ x: 200, y: 230 }}
          color="#00695c"
          icon={<AccountBalanceWallet />}
          key="paymentInfo"
          data={[
            { label: "Payment Info ID", value: data.paymentInformation.paymentInformationId },
            { label: "Payment Method", value: data.paymentInformation.paymentMethod },
            { label: "Requested Execution Date", value: data.paymentInformation.requestedExecutionDate },
          ]}
        />

        <MessageNode
          title="Credit Transfer Transaction"
          position={{ x: 200, y: 360 }}
          color="#d32f2f"
          icon={<SwapHoriz />}
          key="creditTransfer"
          data={[
            { label: "Charge Bearer", value: data.creditTransferTransaction.chargeBearer },
            { label: "Purpose", value: data.creditTransferTransaction.purpose },
          ]}
        />

        {/* Nœuds enfants */}
        <MessageNode
          title="Debtor"
          position={{ x: 550, y: 170 }}
          color="#7b1fa2"
          icon={<Person />}
          key="debtor"
          data={[
            { label: "Name", value: data.paymentInformation.debtor.name },
            { label: "Account ID", value: data.paymentInformation.debtor.accountId },
          ]}
          small
        />

        <MessageNode
          title="Debtor Agent"
          position={{ x: 550, y: 290 }}
          color="#5e35b1"
          icon={<Business />}
          key="debtorAgent"
          data={[
            { label: "Financial Institution ID", value: data.paymentInformation.debtorAgent.financialInstitutionId },
            { label: "Name", value: data.paymentInformation.debtorAgent.name },
          ]}
          small
        />

        <MessageNode
          title="Payment ID"
          position={{ x: 550, y: 420 }}
          color="#f57c00"
          icon={<Assignment />}
          key="paymentId"
          data={[
            { label: "End-to-End ID", value: data.creditTransferTransaction.paymentId.endToEndId },
            { label: "Transaction ID", value: data.creditTransferTransaction.paymentId.transactionId },
          ]}
          small
        />

        <MessageNode
          title="Amount"
          position={{ x: 550, y: 490 }}
          color="#689f38"
          icon={<AttachMoney />}
          key="amount"
          data={[
            { label: "Value", value: data.creditTransferTransaction.amount.instructedAmount.value },
            { label: "Currency", value: data.creditTransferTransaction.amount.instructedAmount.currency },
          ]}
          small
        />

        <MessageNode
          title="Creditor Agent"
          position={{ x: 550, y: 560 }}
          color="#0288d1"
          icon={<Business />}
          key="creditorAgent"
          data={[
            { label: "Financial Institution ID", value: data.creditTransferTransaction.creditorAgent.financialInstitutionId },
            { label: "Name", value: data.creditTransferTransaction.creditorAgent.name },
          ]}
          small
        />

        <MessageNode
          title="Creditor"
          position={{ x: 550, y: 630 }}
          color="#00acc1"
          icon={<Person />}
          key="creditor"
          data={[
            { label: "Name", value: data.creditTransferTransaction.creditor.name },
            { label: "Account ID", value: data.creditTransferTransaction.creditor.accountId },
          ]}
          small
        />

        <MessageNode
          title="Remittance Info"
          position={{ x: 550, y: 700 }}
          color="#e64a19"
          icon={<Info />}
          key="remittanceInfo"
          data={[
            { label: "Unstructured", value: data.creditTransferTransaction.remittanceInformation.unstructured },
          ]}
          small
        />

        {/* Flèches de flux du virement */}
        <Box sx={{ position: 'absolute', top: 400, left: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ position: 'relative', width: '80%', height: 80 }}>
            <motion.div
              initial={{ x: '0%' }}
              animate={{ x: '100%' }}
              transition={{ 
                duration: 4,
                repeat: Infinity,
                ease: "linear"
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: '#d32f2f',
                zIndex: 10
              }}
            />
            <Box sx={{ 
              position: 'absolute', 
              top: 10, 
              left: 0, 
              right: 0, 
              height: 4, 
              bgcolor: '#d32f2f',
              borderRadius: 2 
            }} />
            
            <Box sx={{ position: 'absolute', top: 30, left: 0, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar sx={{ bgcolor: '#7b1fa2', width: 24, height: 24 }}>
                <Person sx={{ fontSize: 16 }} />
              </Avatar>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>Débiteur</Typography>
            </Box>
            
            <Box sx={{ position: 'absolute', top: 30, right: 0, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>Créditeur</Typography>
              <Avatar sx={{ bgcolor: '#00acc1', width: 24, height: 24 }}>
                <Person sx={{ fontSize: 16 }} />
              </Avatar>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Légende */}
      <Box sx={{ mt: 4, display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
        {elements.map(element => (
          <Chip
            key={element.id}
            icon={React.cloneElement(element.icon, { fontSize: 'small' })}
            label={element.title}
            sx={{ 
              bgcolor: `${element.color}15`,
              color: element.color,
              border: `1px solid ${element.color}40`,
              '& .MuiChip-icon': { color: element.color },
            }}
          />
        ))}
      </Box>
    </Paper>
  );
};

// Composant pour représenter un nœud dans le graphique
const MessageNode = ({ title, position, color, icon, data = [], small = false, mainNode = false }) => {
  // Taille du nœud basée sur le type
  const width = small ? 260 : mainNode ? 120 : 280;
  const height = small ? (data.length > 0 ? 120 : 60) : mainNode ? 60 : (data.length > 0 ? a50 : 80);
  
  return (
    <Box
      sx={{
        position: 'absolute',
        top: position.y - height/2,
        left: position.x - width/2,
        width: width,
        height: height,
        bgcolor: 'white',
        borderRadius: 2,
        boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
        borderLeft: `5px solid ${color}`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        zIndex: 10,
      }}
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* En-tête du nœud */}
      <Box sx={{ 
        p: small ? 1 : 2, 
        display: 'flex',
        alignItems: 'center',
        bgcolor: `${color}10`,
        borderBottom: `1px solid ${color}30`,
      }}>
        <Box sx={{ 
          mr: 1.5, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          color: color,
        }}>
          {React.cloneElement(icon, { fontSize: small ? 'small' : 'medium' })}
        </Box>
        <Typography 
          variant={small ? "subtitle2" : "h6"} 
          sx={{ fontWeight: 600, color }}
        >
          {title}
        </Typography>
      </Box>
      
      {/* Contenu du nœud avec les données */}
      {data.length > 0 && (
        <Box sx={{ p: small ? 1 : 2, flex: 1 }}>
          {data.map((item, index) => (
            <Box key={index} sx={{ mb: index < data.length - 1 ? 1 : 0, display: 'flex' }}>
              <Typography variant="caption" sx={{ fontWeight: 600, minWidth: small ? 80 : 120, color: 'text.secondary' }}>
                {item.label}:
              </Typography>
              <Typography variant="caption" sx={{ ml: 1, flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

// Composant pour l'affichage de l'exemple XML
const XMLView = ({ data }) => {
  // Créer une représentation XML du message pacs.008
  const xml = `<?xml version="1.0" encoding="UTF-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <FIToFICstmrCdtTrf>
    <GrpHdr>
      <MsgId>${data.groupHeader.messageId}</MsgId>
      <CreDtTm>${data.groupHeader.creationDateTime}</CreDtTm>
      <NbOfTxs>${data.groupHeader.numberOfTransactions}</NbOfTxs>
      <SttlmMtd>${data.groupHeader.settlementMethod}</SttlmMtd>
      <SttlmAcct>
        <Id>
          <Othr>
            <Id>${data.groupHeader.settlementAccount}</Id>
          </Othr>
        </Id>
      </SttlmAcct>
    </GrpHdr>
    <PmtInf>
      <PmtInfId>${data.paymentInformation.paymentInformationId}</PmtInfId>
      <PmtMtd>${data.paymentInformation.paymentMethod}</PmtMtd>
      <ReqdExctnDt>
        <Dt>${data.paymentInformation.requestedExecutionDate}</Dt>
      </ReqdExctnDt>
      <Dbtr>
        <Nm>${data.paymentInformation.debtor.name}</Nm>
        <PstlAdr>
          <AdrLine>${data.paymentInformation.debtor.postalAddress}</AdrLine>
        </PstlAdr>
      </Dbtr>
      <DbtrAcct>
        <Id>
          <IBAN>${data.paymentInformation.debtor.accountId}</IBAN>
        </Id>
        <Tp>
          <Cd>${data.paymentInformation.debtor.accountType}</Cd>
        </Tp>
      </DbtrAcct>
      <DbtrAgt>
        <FinInstnId>
          <BICFI>${data.paymentInformation.debtorAgent.financialInstitutionId}</BICFI>
          <Nm>${data.paymentInformation.debtorAgent.name}</Nm>
        </FinInstnId>
      </DbtrAgt>
      <CdtTrfTxInf>
        <PmtId>
          <InstrId>${data.creditTransferTransaction.paymentId.instructionId}</InstrId>
          <EndToEndId>${data.creditTransferTransaction.paymentId.endToEndId}</EndToEndId>
          <TxId>${data.creditTransferTransaction.paymentId.transactionId}</TxId>
        </PmtId>
        <Amt>
          <InstdAmt Ccy="${data.creditTransferTransaction.amount.instructedAmount.currency}">${data.creditTransferTransaction.amount.instructedAmount.value}</InstdAmt>
          <XchgRate>${data.creditTransferTransaction.amount.exchangeRate}</XchgRate>
        </Amt>
        <ChrgBr>${data.creditTransferTransaction.chargeBearer}</ChrgBr>
        <CdtrAgt>
          <FinInstnId>
            <BICFI>${data.creditTransferTransaction.creditorAgent.financialInstitutionId}</BICFI>
            <Nm>${data.creditTransferTransaction.creditorAgent.name}</Nm>
          </FinInstnId>
        </CdtrAgt>
        <Cdtr>
          <Nm>${data.creditTransferTransaction.creditor.name}</Nm>
          <PstlAdr>
            <AdrLine>${data.creditTransferTransaction.creditor.postalAddress}</AdrLine>
          </PstlAdr>
        </Cdtr>
        <CdtrAcct>
          <Id>
            <IBAN>${data.creditTransferTransaction.creditor.accountId}</IBAN>
          </Id>
          <Tp>
            <Cd>${data.creditTransferTransaction.creditor.accountType}</Cd>
          </Tp>
        </CdtrAcct>
        <Purp>
          <Cd>${data.creditTransferTransaction.purpose}</Cd>
        </Purp>
        <RmtInf>
          <Ustrd>${data.creditTransferTransaction.remittanceInformation.unstructured}</Ustrd>
          <Strd>
            <RfrdDocInf>
              <Tp>
                <CdOrPrtry>
                  <Cd>${data.creditTransferTransaction.remittanceInformation.structured.referredDocumentInformation.type}</Cd>
                </CdOrPrtry>
              </Tp>
              <Nb>${data.creditTransferTransaction.remittanceInformation.structured.referredDocumentInformation.number}</Nb>
              <RltdDt>${data.creditTransferTransaction.remittanceInformation.structured.referredDocumentInformation.relatedDate}</RltdDt>
            </RfrdDocInf>
            <RfrdDocAmt>
              <DuePyblAmt Ccy="${data.creditTransferTransaction.remittanceInformation.structured.referredDocumentAmount.currency}">${data.creditTransferTransaction.remittanceInformation.structured.referredDocumentAmount.duePayableAmount}</DuePyblAmt>
            </RfrdDocAmt>
          </Strd>
        </RmtInf>
        <RgltryRptg>
          <Dtls>
            <Tp>${data.creditTransferTransaction.regulatoryReporting.details.type}</Tp>
            <Inf>${data.creditTransferTransaction.regulatoryReporting.details.information}</Inf>
          </Dtls>
        </RgltryRptg>
      </CdtTrfTxInf>
    </PmtInf>
  </FIToFICstmrCdtTrf>
</Document>`;

  // Formater le XML pour l'affichage
  const formatXML = (xmlString) => {
    // Cette fonction est simplifiée - dans une implémentation réelle,
    // on utiliserait une bibliothèque pour correctement formatter et colorer le XML
    return xmlString;
  };

  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Exemple XML du message pacs.008
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        Représentation XML conforme à la norme ISO 20022 pacs.008.001.08.
      </Typography>
      <Divider sx={{ mb: 3 }} />
      
      <Box 
        sx={{ 
          bgcolor: '#282c34', 
          p: 3, 
          borderRadius: 2, 
          fontFamily: 'monospace',
          fontSize: '0.875rem',
          overflow: 'auto',
          color: '#abb2bf',
          whiteSpace: 'pre-wrap'
        }}
      >
        {formatXML(xml)}
      </Box>
      
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          variant="outlined" 
          color="primary" 
          startIcon={<Download />}
        >
          Télécharger le XML
        </Button>
      </Box>
    </Paper>
  );
};

// Ajouter les imports manquants
const Container = styled(Box)(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  padding: theme.spacing(0, 2),
}));

const Code = () => <Assignment />;

export default Pacs008MessageVisualizer;