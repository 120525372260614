import React, { useState, useEffect } from 'react';
import { 
  RefreshCw, 
  PlusCircle, 
  Bell, 
  CheckCircle2, 
  XCircle, 
  AlertTriangle, 
  Info, 
  Activity,
  Server,
  HardDrive,
  Database,
  BarChart4,
  X,
  Clock,
  CheckSquare,
  Search
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const MonitoringPage = () => {
  // États pour gérer les données et les états UI
  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [componentMetrics, setComponentMetrics] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState({
    components: false,
    metrics: false,
    alerts: false
  });
  const [error, setError] = useState(null);
  const [showCreateAlertModal, setShowCreateAlertModal] = useState(false);
  const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
  const [showResolveModal, setShowResolveModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Données du formulaire d'alerte
  const [alertFormData, setAlertFormData] = useState({
    componentId: '',
    componentName: '',
    severity: 'WARNING',
    message: '',
    contextData: {}
  });
  
  // Données pour accusé de réception d'alerte
  const [acknowledgeData, setAcknowledgeData] = useState({
    acknowledgedBy: ''
  });
  
  // Données pour résolution d'alerte
  const [resolveData, setResolveData] = useState({
    resolvedBy: '',
    comment: ''
  });

  // Filtrer les alertes en fonction du terme de recherche
  const filteredAlerts = alerts.filter(alert => {
    return (
      alert.componentId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      alert.componentName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      alert.message?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      alert.severity?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Charger les données au chargement initial
  useEffect(() => {
    fetchAllHealthData();
    fetchAlerts();
  }, []);

  // Charger les métriques lorsqu'un composant est sélectionné
  useEffect(() => {
    if (selectedComponent) {
      fetchComponentMetrics(selectedComponent.componentId);
    }
  }, [selectedComponent]);

  // Fonction pour obtenir tous les états de santé des composants
  const fetchAllHealthData = async () => {
    setLoading(prev => ({ ...prev, components: true }));
    setError(null);
    try {
      const response = await apiService.integration.monitoring.checkAllHealth();
      setComponents(response);
    } catch (err) {
      console.error("Erreur lors du chargement des données de santé :", err);
      setError("Impossible de charger les données de santé des composants. Veuillez réessayer.");
    } finally {
      setLoading(prev => ({ ...prev, components: false }));
    }
  };

  // Fonction pour vérifier la santé d'un composant spécifique
  const checkComponentHealth = async (componentId) => {
    setLoading(prev => ({ ...prev, components: true }));
    setError(null);
    try {
      const response = await apiService.integration.monitoring.checkHealth(componentId);
      
      // Mettre à jour le composant dans la liste
      setComponents(prevComponents => {
        const updatedComponents = [...prevComponents];
        const index = updatedComponents.findIndex(c => c.componentId === componentId);
        if (index !== -1) {
          updatedComponents[index] = response;
        } else {
          updatedComponents.push(response);
        }
        return updatedComponents;
      });
      
      // Mettre à jour le composant sélectionné si nécessaire
      if (selectedComponent && selectedComponent.componentId === componentId) {
        setSelectedComponent(response);
      }
      
      setSuccessMessage(`État de santé mis à jour pour le composant: ${componentId}`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error(`Erreur lors de la vérification de la santé du composant ${componentId}:`, err);
      setError(`Impossible de vérifier la santé du composant ${componentId}. Veuillez réessayer.`);
    } finally {
      setLoading(prev => ({ ...prev, components: false }));
    }
  };

  // Fonction pour obtenir les métriques d'un composant
  const fetchComponentMetrics = async (componentId) => {
    setLoading(prev => ({ ...prev, metrics: true }));
    setError(null);
    try {
      const response = await apiService.integration.monitoring.getMetrics(componentId);
      setComponentMetrics(response);
    } catch (err) {
      console.error(`Erreur lors du chargement des métriques pour le composant ${componentId}:`, err);
      setError(`Impossible de charger les métriques pour le composant ${componentId}. Veuillez réessayer.`);
    } finally {
      setLoading(prev => ({ ...prev, metrics: false }));
    }
  };

  // Fonction pour récupérer les alertes actives
  const fetchAlerts = async () => {
    setLoading(prev => ({ ...prev, alerts: true }));
    setError(null);
    try {
      const response = await apiService.integration.monitoring.getActiveAlerts();
      setAlerts(response);
    } catch (err) {
      console.error("Erreur lors du chargement des alertes :", err);
      setError("Impossible de charger les alertes. Veuillez réessayer.");
    } finally {
      setLoading(prev => ({ ...prev, alerts: false }));
    }
  };

  // Fonction pour créer une alerte
  const handleCreateAlert = async () => {
    setLoading(prev => ({ ...prev, alerts: true }));
    setError(null);
    try {
      await apiService.integration.monitoring.createAlert(alertFormData);
      setSuccessMessage("Alerte créée avec succès !");
      setShowCreateAlertModal(false);
      resetAlertForm();
      fetchAlerts();
    } catch (err) {
      console.error("Erreur lors de la création de l'alerte :", err);
      setError("Impossible de créer l'alerte. Veuillez réessayer.");
    } finally {
      setLoading(prev => ({ ...prev, alerts: false }));
    }
  };

  // Fonction pour accuser réception d'une alerte
  const handleAcknowledgeAlert = async () => {
    if (!selectedAlert) return;
    
    setLoading(prev => ({ ...prev, alerts: true }));
    setError(null);
    try {
      await apiService.integration.monitoring.acknowledgeAlert(
        selectedAlert.alertId, 
        acknowledgeData.acknowledgedBy
      );
      setSuccessMessage("Accusé de réception de l'alerte effectué avec succès !");
      setShowAcknowledgeModal(false);
      setSelectedAlert(null);
      resetAcknowledgeForm();
      fetchAlerts();
    } catch (err) {
      console.error("Erreur lors de l'accusé de réception de l'alerte :", err);
      setError("Impossible d'accuser réception de l'alerte. Veuillez réessayer.");
    } finally {
      setLoading(prev => ({ ...prev, alerts: false }));
    }
  };

  // Fonction pour résoudre une alerte
  const handleResolveAlert = async () => {
    if (!selectedAlert) return;
    
    setLoading(prev => ({ ...prev, alerts: true }));
    setError(null);
    try {
      await apiService.integration.monitoring.resolveAlert(
        selectedAlert.alertId, 
        resolveData.resolvedBy, 
        resolveData.comment
      );
      setSuccessMessage("Alerte résolue avec succès !");
      setShowResolveModal(false);
      setSelectedAlert(null);
      resetResolveForm();
      fetchAlerts();
    } catch (err) {
      console.error("Erreur lors de la résolution de l'alerte :", err);
      setError("Impossible de résoudre l'alerte. Veuillez réessayer.");
    } finally {
      setLoading(prev => ({ ...prev, alerts: false }));
    }
  };

  // Réinitialisation des formulaires
  const resetAlertForm = () => {
    setAlertFormData({
      componentId: '',
      componentName: '',
      severity: 'WARNING',
      message: '',
      contextData: {}
    });
  };

  const resetAcknowledgeForm = () => {
    setAcknowledgeData({
      acknowledgedBy: ''
    });
  };

  const resetResolveForm = () => {
    setResolveData({
      resolvedBy: '',
      comment: ''
    });
  };

  // Gestion des changements dans les formulaires
  const handleAlertFormChange = (e) => {
    const { name, value } = e.target;
    setAlertFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAcknowledgeFormChange = (e) => {
    const { name, value } = e.target;
    setAcknowledgeData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleResolveFormChange = (e) => {
    const { name, value } = e.target;
    setResolveData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Obtenir la classe CSS et l'icône en fonction du statut de santé
  const getHealthStatusUI = (status) => {
    switch (status) {
      case 'UP':
        return {
          color: 'text-green-500',
          bgColor: 'bg-green-100 dark:bg-green-900/20',
          icon: <CheckCircle2 className="h-5 w-5 text-green-500" />
        };
      case 'DOWN':
        return {
          color: 'text-red-500',
          bgColor: 'bg-red-100 dark:bg-red-900/20',
          icon: <XCircle className="h-5 w-5 text-red-500" />
        };
      case 'DEGRADED':
        return {
          color: 'text-orange-500',
          bgColor: 'bg-orange-100 dark:bg-orange-900/20',
          icon: <AlertTriangle className="h-5 w-5 text-orange-500" />
        };
      case 'WARNING':
        return {
          color: 'text-yellow-500',
          bgColor: 'bg-yellow-100 dark:bg-yellow-900/20',
          icon: <AlertTriangle className="h-5 w-5 text-yellow-500" />
        };
      case 'UNKNOWN':
      default:
        return {
          color: 'text-gray-500',
          bgColor: 'bg-gray-100 dark:bg-gray-900/20',
          icon: <Info className="h-5 w-5 text-gray-500" />
        };
    }
  };

  // Obtenir l'icône et la couleur en fonction de la sévérité d'une alerte
  const getAlertSeverityUI = (severity) => {
    switch (severity) {
      case 'ERROR':
        return {
          color: 'text-red-500',
          bgColor: 'bg-red-100 dark:bg-red-900/20',
          icon: <XCircle className="h-5 w-5 text-red-500" />
        };
      case 'WARNING':
        return {
          color: 'text-yellow-500',
          bgColor: 'bg-yellow-100 dark:bg-yellow-900/20',
          icon: <AlertTriangle className="h-5 w-5 text-yellow-500" />
        };
      case 'INFO':
      default:
        return {
          color: 'text-blue-500',
          bgColor: 'bg-blue-100 dark:bg-blue-900/20',
          icon: <Info className="h-5 w-5 text-blue-500" />
        };
    }
  };

  // Fonction pour obtenir l'icône du type de composant
  const getComponentIcon = (componentType) => {
    switch (componentType?.toLowerCase()) {
      case 'server':
      case 'service':
        return <Server className="h-6 w-6 text-blue-500" />;
      case 'database':
        return <Database className="h-6 w-6 text-green-500" />;
      case 'storage':
        return <HardDrive className="h-6 w-6 text-purple-500" />;
      default:
        return <Activity className="h-6 w-6 text-gray-500" />;
    }
  };

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Activity className="h-6 w-6 mr-2 text-blue-500" />
            Surveillance des Composants
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Suivez l'état de santé et les performances des composants d'intégration
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={() => {
              fetchAllHealthData();
              fetchAlerts();
            }}
            loading={loading.components || loading.alerts}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetAlertForm();
              setShowCreateAlertModal(true);
            }}
          >
            Nouvelle alerte
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Vue d'ensemble des composants */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Carte pour le nombre total de composants */}
        <Card>
          <div className="p-4 flex items-center">
            <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900/20 mr-4">
              <Server className="h-6 w-6 text-blue-500" />
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                {components.length}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Composants surveillés
              </p>
            </div>
          </div>
        </Card>
        
        {/* Carte pour les composants en bon état */}
        <Card>
          <div className="p-4 flex items-center">
            <div className="p-2 rounded-full bg-green-100 dark:bg-green-900/20 mr-4">
              <CheckCircle2 className="h-6 w-6 text-green-500" />
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                {components.filter(c => c.status === 'UP').length}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Composants en bon état
              </p>
            </div>
          </div>
        </Card>
        
        {/* Carte pour les composants dégradés */}
        <Card>
          <div className="p-4 flex items-center">
            <div className="p-2 rounded-full bg-yellow-100 dark:bg-yellow-900/20 mr-4">
              <AlertTriangle className="h-6 w-6 text-yellow-500" />
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                {components.filter(c => c.status === 'DEGRADED' || c.status === 'WARNING').length}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Composants dégradés
              </p>
            </div>
          </div>
        </Card>
        
        {/* Carte pour les alertes actives */}
        <Card>
          <div className="p-4 flex items-center">
            <div className="p-2 rounded-full bg-red-100 dark:bg-red-900/20 mr-4">
              <Bell className="h-6 w-6 text-red-500" />
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                {alerts.length}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Alertes actives
              </p>
            </div>
          </div>
        </Card>
      </div>

      {/* État de santé des composants */}
      <div className="space-y-4">
        <h2 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
          <Activity className="h-5 w-5 mr-2 text-blue-500" />
          État de santé des composants
        </h2>
        
        <Card>
          {loading.components ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          ) : components.length === 0 ? (
            <div className="p-6 text-center text-gray-500 dark:text-gray-400">
              Aucun composant à afficher
            </div>
          ) : (
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              {components.map((component) => {
                const healthUI = getHealthStatusUI(component.status);
                
                return (
                  <div 
                    key={component.componentId}
                    className="p-4 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors cursor-pointer"
                    onClick={() => setSelectedComponent(component)}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-4">
                        {getComponentIcon(component.componentType)}
                        <div>
                          <h3 className="font-medium text-gray-900 dark:text-white">
                            {component.componentName || component.componentId}
                          </h3>
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            {component.componentType || 'Service'} • 
                            <span className="ml-2">
                              Dernière vérification : <DateFormatter date={component.checkTime} format="relative" />
                            </span>
                          </p>
                        </div>
                      </div>
                      
                      <div className="flex items-center space-x-4">
                        <div className={`px-3 py-1 rounded-full flex items-center ${healthUI.bgColor}`}>
                          {healthUI.icon}
                          <span className={`ml-2 font-medium ${healthUI.color}`}>
                            {component.status}
                          </span>
                        </div>
                        <Button
                          variant="outline"
                          size="sm"
                          icon={RefreshCw}
                          onClick={(e) => {
                            e.stopPropagation();
                            checkComponentHealth(component.componentId);
                          }}
                        >
                          Vérifier
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Card>
      </div>

      {/* Métriques du composant sélectionné */}
      {selectedComponent && (
        <div className="space-y-4">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
            <BarChart4 className="h-5 w-5 mr-2 text-blue-500" />
            Métriques pour {selectedComponent.componentName || selectedComponent.componentId}
          </h2>
          
          <Card>
            {loading.metrics ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              </div>
            ) : !componentMetrics ? (
              <div className="p-6 text-center text-gray-500 dark:text-gray-400">
                Aucune métrique disponible pour ce composant
              </div>
            ) : (
              <div className="p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {/* CPU Usage */}
                  {componentMetrics.cpuUsage !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Utilisation CPU
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {(componentMetrics.cpuUsage * 100).toFixed(1)}%
                        </span>
                      </div>
                      <div className="relative w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full">
                        <div 
                          className="absolute top-0 left-0 h-2 rounded-full bg-blue-500"
                          style={{ width: `${componentMetrics.cpuUsage * 100}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                  
                  {/* Memory Usage */}
                  {componentMetrics.memoryUsage !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Utilisation Mémoire
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {(componentMetrics.memoryUsage * 100).toFixed(1)}%
                        </span>
                      </div>
                      <div className="relative w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full">
                        <div 
                          className="absolute top-0 left-0 h-2 rounded-full bg-green-500"
                          style={{ width: `${componentMetrics.memoryUsage * 100}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                  
                  {/* Thread Count */}
                  {componentMetrics.threadCount !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Nombre de threads
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {componentMetrics.threadCount}
                        </span>
                      </div>
                    </div>
                  )}
                  
                  {/* Active Connections */}
                  {componentMetrics.activeConnections !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Connexions actives
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {componentMetrics.activeConnections}
                        </span>
                      </div>
                    </div>
                  )}
                  
                  {/* Error Count */}
                  {componentMetrics.errorCount !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Nombre d'erreurs
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {componentMetrics.errorCount}
                        </span>
                      </div>
                    </div>
                  )}
                  
                  {/* Latency */}
                  {componentMetrics.latencyMs !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Latence
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {componentMetrics.latencyMs} ms
                        </span>
                      </div>
                    </div>
                  )}
                  
                  {/* Throughput */}
                  {componentMetrics.throughput !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Débit
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {componentMetrics.throughput} req/s
                        </span>
                      </div>
                    </div>
                  )}
                  
                  {/* Uptime */}
                  {componentMetrics.uptime !== undefined && (
                    <div className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Disponibilité
                        </span>
                        <span className="text-lg font-bold text-gray-900 dark:text-white">
                          {Math.floor(componentMetrics.uptime / 3600)} h {Math.floor((componentMetrics.uptime % 3600) / 60)} m
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Métriques additionnelles */}
                {componentMetrics.additionalMetrics && Object.keys(componentMetrics.additionalMetrics).length > 0 && (
                  <>
                    <h3 className="text-md font-medium text-gray-900 dark:text-white mt-6 mb-3">
                      Métriques additionnelles
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {Object.entries(componentMetrics.additionalMetrics).map(([key, value]) => (
                        <div key={key} className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                          <div className="flex justify-between items-center">
                            <span className="text-sm font-medium text-gray-500 dark:text-gray-400 capitalize">
                              {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                            </span>
                            <span className="text-lg font-bold text-gray-900 dark:text-white">
                              {typeof value === 'number' ? value.toLocaleString() : String(value)}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                
                {/* Détails supplémentaires */}
                {selectedComponent.details && Object.keys(selectedComponent.details).length > 0 && (
                  <>
                    <h3 className="text-md font-medium text-gray-900 dark:text-white mt-6 mb-3">
                      Détails du composant
                    </h3>
                    <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2">
                        {Object.entries(selectedComponent.details).map(([key, value]) => (
                          <div key={key} className="flex">
                            <span className="text-sm font-medium text-gray-500 dark:text-gray-400 min-w-[150px]">
                              {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
                            </span>
                            <span className="text-sm text-gray-900 dark:text-white ml-2">
                              {String(value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </Card>
        </div>
      )}

      {/* Gestion des alertes */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
            <Bell className="h-5 w-5 mr-2 text-blue-500" />
            Alertes actives
          </h2>
          
          {/* Barre de recherche pour les alertes */}
          <div className="relative w-64">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
              placeholder="Rechercher dans les alertes..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        
        <Card>
          {loading.alerts ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          ) : filteredAlerts.length === 0 ? (
            <div className="p-6 text-center text-gray-500 dark:text-gray-400">
              Aucune alerte active
            </div>
          ) : (
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              {filteredAlerts.map((alert) => {
                const severityUI = getAlertSeverityUI(alert.severity);
                
                return (
                  <div key={alert.alertId} className="p-4">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                      <div className="flex items-start space-x-3">
                        <div className={`mt-1 p-1 rounded-full ${severityUI.bgColor}`}>
                          {severityUI.icon}
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900 dark:text-white">
                            {alert.componentName || alert.componentId}
                          </h3>
                          <p className="text-sm text-gray-700 dark:text-gray-300 mt-1">
                            {alert.message}
                          </p>
                          <div className="flex items-center mt-2">
                            <Clock className="h-4 w-4 text-gray-400 mr-1" />
                            <span className="text-xs text-gray-500 dark:text-gray-400">
                              <DateFormatter date={alert.createdAt} format="full" />
                            </span>
                          </div>
                        </div>
                      </div>
                      
                      <div className="flex items-center space-x-2 mt-4 md:mt-0">
                        <StatusBadge 
                          status={alert.status === 'NEW' ? 'pending' : (alert.status === 'ACKNOWLEDGED' ? 'warning' : 'draft')} 
                          customLabel={alert.status} 
                        />
                        
                        {alert.status === 'NEW' && (
                          <Button
                            variant="outline"
                            size="sm"
                            icon={CheckCircle2}
                            onClick={() => {
                              setSelectedAlert(alert);
                              setShowAcknowledgeModal(true);
                            }}
                          >
                            Accuser réception
                          </Button>
                        )}
                        
                        {alert.status !== 'RESOLVED' && (
                          <Button
                            variant="outline"
                            size="sm"
                            icon={CheckSquare}
                            onClick={() => {
                              setSelectedAlert(alert);
                              setShowResolveModal(true);
                            }}
                          >
                            Résoudre
                          </Button>
                        )}
                      </div>
                    </div>
                    
                    {/* Informations sur l'accusé de réception */}
                    {alert.status === 'ACKNOWLEDGED' && alert.acknowledgedBy && (
                      <div className="mt-3 pl-8 text-xs text-gray-500 dark:text-gray-400">
                        Accusé de réception par <span className="font-medium">{alert.acknowledgedBy}</span> le{' '}
                        <DateFormatter date={alert.acknowledgedAt} format="full" />
                      </div>
                    )}
                    
                    {/* Informations sur la résolution */}
                    {alert.status === 'RESOLVED' && alert.resolvedBy && (
                      <div className="mt-3 pl-8 text-xs text-gray-500 dark:text-gray-400">
                        Résolu par <span className="font-medium">{alert.resolvedBy}</span> le{' '}
                        <DateFormatter date={alert.resolvedAt} format="full" />
                        {alert.resolutionComment && (
                          <div className="mt-1">
                            Commentaire: {alert.resolutionComment}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </Card>
      </div>

      {/* Modal de création d'alerte */}
      {showCreateAlertModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer une nouvelle alerte
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateAlertModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Identifiant du composant*
                  </label>
                  <input
                    type="text"
                    name="componentId"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={alertFormData.componentId}
                    onChange={handleAlertFormChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom du composant
                  </label>
                  <input
                    type="text"
                    name="componentName"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={alertFormData.componentName}
                    onChange={handleAlertFormChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Sévérité*
                  </label>
                  <select
                    name="severity"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={alertFormData.severity}
                    onChange={handleAlertFormChange}
                    required
                  >
                    <option value="ERROR">Erreur</option>
                    <option value="WARNING">Avertissement</option>
                    <option value="INFO">Information</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Message*
                  </label>
                  <textarea
                    name="message"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={alertFormData.message}
                    onChange={handleAlertFormChange}
                    rows={3}
                    required
                  />
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateAlertModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreateAlert}
                  loading={loading.alerts}
                  disabled={!alertFormData.componentId || !alertFormData.message}
                >
                  Créer l'alerte
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'accusé de réception d'alerte */}
      {showAcknowledgeModal && selectedAlert && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Accusé de réception de l'alerte
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowAcknowledgeModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg mb-6">
                <div className="flex items-start space-x-3">
                  {getAlertSeverityUI(selectedAlert.severity).icon}
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">
                      {selectedAlert.componentName || selectedAlert.componentId}
                    </h3>
                    <p className="text-sm text-gray-700 dark:text-gray-300 mt-1">
                      {selectedAlert.message}
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Accusé de réception par*
                  </label>
                  <input
                    type="text"
                    name="acknowledgedBy"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={acknowledgeData.acknowledgedBy}
                    onChange={handleAcknowledgeFormChange}
                    placeholder="Votre nom ou identifiant"
                    required
                  />
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowAcknowledgeModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleAcknowledgeAlert}
                  loading={loading.alerts}
                  disabled={!acknowledgeData.acknowledgedBy}
                >
                  Confirmer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de résolution d'alerte */}
      {showResolveModal && selectedAlert && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Résoudre l'alerte
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowResolveModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg mb-6">
                <div className="flex items-start space-x-3">
                  {getAlertSeverityUI(selectedAlert.severity).icon}
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">
                      {selectedAlert.componentName || selectedAlert.componentId}
                    </h3>
                    <p className="text-sm text-gray-700 dark:text-gray-300 mt-1">
                      {selectedAlert.message}
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Résolu par*
                  </label>
                  <input
                    type="text"
                    name="resolvedBy"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={resolveData.resolvedBy}
                    onChange={handleResolveFormChange}
                    placeholder="Votre nom ou identifiant"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Commentaire
                  </label>
                  <textarea
                    name="comment"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={resolveData.comment}
                    onChange={handleResolveFormChange}
                    rows={3}
                    placeholder="Décrivez comment le problème a été résolu"
                  />
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowResolveModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleResolveAlert}
                  loading={loading.alerts}
                  disabled={!resolveData.resolvedBy}
                >
                  Résoudre
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default MonitoringPage;