import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Divider,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Switch,
  FormControlLabel,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Drawer,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Slider,
  Badge,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';

import {
  PlayCircle,
  StopCircle,
  Save,
  RefreshCw,
  AlertTriangle,
  Info,
  Settings,
  ChevronDown,
  ChevronRight,
  X,
  List as ListIcon,
  Code,
  Clock,
  ArrowUpRight,
  ArrowDownLeft,
  CheckCircle,
  Search,
  Terminal,
  MessageSquare,
  Trash2,
  Download,
  Copy,
  Folder,
  FileText,
  Database,
  Server,
  Activity,
  Eye,
  EyeOff,
  AlertOctagon
} from 'lucide-react';

import GeneroCapture from '../../components/shared/GeneroCapture';
import iOSAppCapture from '../../components/shared/iOSAppCapture';
import AndroidAppCapture from '../../components/shared/AndroidAppCapture';
import InformixCapture from '../../components/shared/InformixCapture';
import OracleCapture from '../../components/shared/OracleCapture';
import LinuxCapture from '../../components/shared/LinuxCapture';
import WindowsCapture from '../../components/shared/WindowsCapture';
import RestApiCapture from '../../components/shared/RestApiCapture';
import SoapApiCapture from '../../components/shared/SoapApiCapture';
import WebBrowserCapture from '../../components/shared/WebBrowserCapture';

import api from '../../services/api.service';

/**
 * Page d'enregistrement des tâches automatisées
 * Permet d'enregistrer les interactions avec une application Genero
 */
const RecordCapture = () => {
  // États pour les environnements et fonctions
  const [environments, setEnvironments] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [functionInfo, setFunctionInfo] = useState(null);
  const [tasksForFunction, setTasksForFunction] = useState([]);
  const [expandedEnvironments, setExpandedEnvironments] = useState({});
  const [expandedFunctions, setExpandedFunctions] = useState({});
  const [isTreeLoading, setIsTreeLoading] = useState(false);
  
  // État pour le client IP
  const [clientIp, setClientIp] = useState('');
  
  // État pour l'application Genero
  const [generoData, setGeneroData] = useState(null);
  const [panelHighlighted, setPanelHighlighted] = useState(false);
  const [panelDisabled, setPanelDisabled] = useState(false);
  
  // État pour vérifier si l'environnement a un agent compatible
  const [agentCompatibility, setAgentCompatibility] = useState({
    compatible: false,
    agentType: null
  });
  
  // État pour la connexion
  const [connectionState, setConnectionState] = useState({
    connected: false
  });
  
  // État pour la tâche
  const [task, setTask] = useState({
    taskName: '',
    taskDescription: '',
    taskScenario: '',
    notes: '',
    login: '',
    password: '',
    menu: '',
    expectedResult: '',
    taskType: 'ACCEPTABILITY',
    taskNature: 'FUNCTIONAL',
    taskPriority: 'MEDIUM',
    taskStatus_typ: 'DRAFT',
    executionType_typ: 'AUTOMATIC',
    origin_typ: 'RECORD',
    isActive: true
  });
  
  // États pour les interactions et l'enregistrement
  const [interactions, setInteractions] = useState([]);
  const [recording, setRecording] = useState(false);
  const [interactionCounter, setInteractionCounter] = useState(0);
  
  // États pour l'interface utilisateur
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [connectionAlertOpen, setConnectionAlertOpen] = useState(false);
  
  // États pour les dialogues
  const [jsonDialogOpen, setJsonDialogOpen] = useState(false);
  const [selectedTechnicalProps, setSelectedTechnicalProps] = useState(null);
  
  // État pour la console de débogage
  const [debugConsoleOpen, setDebugConsoleOpen] = useState(false);
  const [debugLogs, setDebugLogs] = useState([]);
  const [consoleOpacity, setConsoleOpacity] = useState(0.9);
  const [consoleHeight, setConsoleHeight] = useState(300);
  const debugConsoleRef = useRef(null);
  
  // Refs
  const eventSourceRef = useRef(null);
  const interactionsEndRef = useRef(null);
  
  // Fonction pour vérifier si un environnement a un agent compatible
  const checkAgentCompatibility = (environment) => {
    if (!environment || !environment.raw || !environment.raw.agent) {
      return { compatible: false, agentType: null };
    }
    
    const agentType = environment.raw.agent.agentType;
    
    // Vérifier si le type d'agent est supporté
    const supportedAgentTypes = [
      'GENERO_GDC', 'GENERO_GAS', 
      'DATABASE_IFX', 'DATABASE_ORA', 'DATABASE_POSTGRES',
      'OS_LINUX', 'OS_WINDOWS', 'OS_AIX', 'OS_SOLARIS',
      'API_REST', 'API_SOAP', 'API_GRAPHQL',
      'JAVA_SE', 'JAVA_EE',
      'MOBILE_IOS', 'MOBILE_ANDROID',
      'WEB_BROWSER'
    ];
    
    return { 
      compatible: supportedAgentTypes.includes(agentType), 
      agentType: agentType 
    };
  };
  
  // Fonction de log personnalisée
  const debugLog = (message, type = 'info', data = null) => {
    const logEntry = {
      timestamp: new Date(),
      message,
      type,
      data
    };
    
    setDebugLogs(prev => [...prev, logEntry]);
    
    // Aussi envoyer au console.log pour le débogage dans la console du navigateur
    switch(type) {
      case 'error':
        console.error(message, data);
        break;
      case 'warn':
        console.warn(message, data);
        break;
      case 'success':
        console.info(`✅ ${message}`, data);
        break;
      default:
        console.log(message, data);
    }
    
    // Faire défiler la console jusqu'en bas
    if (debugConsoleRef.current) {
      setTimeout(() => {
        debugConsoleRef.current.scrollTop = debugConsoleRef.current.scrollHeight;
      }, 100);
    }
  };
  
  // Effets pour le chargement initial
  useEffect(() => {
    fetchClientIp();
    fetchEnvironments();
    
    debugLog('Composant RecordCapture initialisé', 'info');
    
    return () => {
      closeEventSource();
      debugLog('Composant RecordCapture démonté, nettoyage effectué', 'info');
    };
  }, []);
  
  // useEffect pour la configuration de la connexion
  useEffect(() => {
    if (clientIp) {
      debugLog(`Configuration de la connexion WebSocket pour l'utilisateur: ${clientIp}`, 'info');
      
      // Nettoyer les connexions existantes pour éviter les doublons
      api.automata.record.closeConnections(clientIp);
      
      // Configurer la connexion WebSocket
      eventSourceRef.current = api.automata.record.createWebSocket(clientIp);
      
      // Ajout des gestionnaires d'événements
      eventSourceRef.current.addEventListener('open', (event) => {
        debugLog('Connexion WebSocket établie', 'success', event);
        setIsConnected(true);
        setConnectionState({ connected: true });
        setConnectionAlertOpen(false);
        setSnackbar({
          open: true,
          message: 'Connexion établie',
          severity: 'success'
        });
      });
      
      eventSourceRef.current.addEventListener('message', (event) => {
        try {
          // Traiter le message WebSocket
          let rawData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
          
          // Extraire le vrai message qui pourrait être encapsulé dans data
          let parsedData = rawData.data ? rawData.data : rawData;
          
          debugLog('Message WebSocket reçu brut', 'info', rawData);
          debugLog('Message WebSocket après extraction', 'info', parsedData);
          
          // Gérer différents types de messages
          if (parsedData.type === 'INTERACTION' && parsedData.interaction) {
            // Passer l'objet interaction directement
            handleServerEvent(parsedData.interaction);
          } else if (parsedData.type === 'HEARTBEAT') {
            // Ignorer les battements de cœur
            debugLog('Heartbeat reçu', 'info');
          } else {
            // Tenter d'extraire l'interaction si la structure est différente
            if (rawData.data && typeof rawData.data === 'object') {
              debugLog('Tentative d\'extraction alternative d\'interaction', 'info');
              // Tenter directement avec data comme une interaction
              handleServerEvent(rawData.data);
            } else {
              debugLog(`Message reçu de structure inconnue`, 'warn', parsedData);
            }
          }
        } catch (parseError) {
          debugLog('Erreur de parsing JSON', 'error', parseError);
        }
      });
      
      eventSourceRef.current.addEventListener('error', (error) => {
        debugLog('Erreur de connexion WebSocket', 'error', error);
        setIsConnected(false);
        setConnectionState({ connected: false });
        setConnectionAlertOpen(true);
      });
      
      eventSourceRef.current.addEventListener('close', () => {
        debugLog('Connexion WebSocket fermée', 'warn');
        setIsConnected(false);
        setConnectionState({ connected: false });
        setConnectionAlertOpen(true);
      });
      
      // Démarrer un intervalle de heartbeat pour maintenir la connexion
      const heartbeatInterval = setInterval(() => {
        if (eventSourceRef.current && eventSourceRef.current.getStatus().connected) {
          api.automata.record.sendWebSocketMessage(clientIp, {
            type: 'HEARTBEAT',
            timestamp: Date.now()
          });
        }
      }, 30000);
      
      return () => {
        debugLog('Nettoyage de la connexion WebSocket', 'info');
        clearInterval(heartbeatInterval);
        
        if (clientIp) {
          api.automata.record.closeConnections(clientIp);
          eventSourceRef.current = null;
        }
      };
    }
  }, [clientIp]);
  
  // Effet pour charger les détails de la fonction lorsque la sélection change
  useEffect(() => {
    if (selectedFunction) {
      fetchFunctionInfo(selectedFunction.id);
      fetchTasksForFunction(selectedFunction.id);
    } else {
      setFunctionInfo(null);
      setTasksForFunction([]);
    }
  }, [selectedFunction]);
  
  // Effet pour vérifier la compatibilité de l'agent lorsque l'environnement change
  useEffect(() => {
    if (selectedEnvironment) {
      const compatibility = checkAgentCompatibility(selectedEnvironment);
      setAgentCompatibility(compatibility);
      
      if (!compatibility.compatible) {
        debugLog(`L'environnement sélectionné n'a pas d'agent compatible`, 'warn');
      } else {
        debugLog(`Environnement avec agent ${compatibility.agentType} compatible détecté`, 'success');
      }
    } else {
      setAgentCompatibility({ compatible: false, agentType: null });
    }
  }, [selectedEnvironment]);
  
  // Effet pour scroller vers la dernière interaction
  useEffect(() => {
    if (interactionsEndRef.current) {
      interactionsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [interactions]);
  
  // Fonction pour obtenir l'IP client
  const fetchClientIp = async () => {
    try {
      // Utilisez votre propre méthode pour obtenir l'IP client ou utilisez un identifiant utilisateur à la place
      const userId = localStorage.getItem('userId') || 'default-user';
      setClientIp(userId);
      debugLog(`ID client récupéré: ${userId}`, 'info');
    } catch (error) {
      debugLog('Échec de la récupération de l\'ID client', 'error', error);
      setSnackbar({
        open: true,
        message: 'Échec de la récupération de l\'identifiant client',
        severity: 'error'
      });
    }
  };
  
  // Configuration de Server-Sent Events
  const setupEventSource = () => {
    // Fermer la connexion existante si nécessaire
    if (eventSourceRef.current) {
      try {
        eventSourceRef.current.close();
      } catch (e) {
        debugLog('Erreur lors de la fermeture de la connexion', 'warn', e);
      }
      eventSourceRef.current = null;
    }
    
    // Vérifier que clientIp est défini
    if (!clientIp) {
      debugLog('ClientIP non défini lors de la configuration SSE', 'error');
      return;
    }
    
    try {
      debugLog(`Configuration d'une nouvelle connexion SSE pour l'utilisateur: ${clientIp}`, 'info');
      
      // Créer une nouvelle connexion
      eventSourceRef.current = api.automata.record.createSseEmitter(clientIp);
      
      // Configurer les gestionnaires d'événements
      eventSourceRef.current.addEventListener('open', (event) => {
        debugLog('SSE connection established', 'success', event);
        
        // Nettoyer tout timeout de reconnexion
        if (window.reconnectTimeout) {
          clearTimeout(window.reconnectTimeout);
          window.reconnectTimeout = null;
        }
        
        setIsConnected(true);
        setConnectionState({ connected: true });
        setConnectionAlertOpen(false);
        setSnackbar({
          open: true,
          message: 'Connexion établie',
          severity: 'success'
        });
      });
      
      // Autres gestionnaires d'événements...
      
      return eventSourceRef.current;
    } catch (error) {
      debugLog('Erreur lors de la création de la connexion SSE', 'error', error);
      setSnackbar({
        open: true,
        message: 'Erreur de connexion: ' + error.message,
        severity: 'error'
      });
      return null;
    }
  };
  
  const closeEventSource = () => {
    if (eventSourceRef.current) {
      debugLog('Fermeture de la connexion SSE', 'info');
      api.automata.record.closeConnections(clientIp);
      eventSourceRef.current = null;
    }
  };
  
  const forceReconnect = () => {
    debugLog("Forçage de la reconnexion WebSocket", 'info');
    
    // Fermer la connexion actuelle
    if (eventSourceRef.current) {
      try {
        api.automata.record.closeConnections(clientIp);
      } catch (e) {
        debugLog('Erreur lors de la fermeture des connexions', 'warn', e);
      }
    }
    
    eventSourceRef.current = null;
    
    // Afficher un message pour indiquer la tentative de reconnexion
    setSnackbar({
      open: true,
      message: 'Tentative de reconnexion...',
      severity: 'info'
    });
    
    // Attendre un court instant avant de recréer la connexion
    setTimeout(() => {
      eventSourceRef.current = api.automata.record.createWebSocket(clientIp);
    }, 1000);
  };
  
  const handleServerEvent = (data) => {
    setPanelDisabled(false);
    
    // S'assurer que les données sont valides
    if (!data) {
      debugLog('Données d\'interaction invalides', 'warn', data);
      return;
    }
    
    debugLog('Événement serveur reçu', 'info', data);
    
    // Pas besoin d'extraire .data ou .interaction car on reçoit directement l'objet interaction
    const interactionData = data;
    
    // AJOUT: Vérifier si l'interaction est une confirmation d'une action utilisateur
    // Dans ce cas, ne pas l'ajouter une seconde fois au journal
    if (interactionData.direction === 'REQUEST' && interactionData.type === 'REC_ACTION') {
      // Vérifier si nous avons déjà une interaction similaire récente dans notre journal
      const alreadyExists = interactions.some(existing => 
        existing.direction === 'REQUEST' && 
        existing.type === 'REC_ACTION' &&
        // Vérification approximative basée sur le temps (moins de 2 secondes d'écart)
        Math.abs(new Date(existing.timestamp) - new Date(interactionData.timestamp)) < 2000
      );
      
      if (alreadyExists) {
        debugLog('Interaction REQUEST dupliquée détectée et ignorée', 'info', interactionData);
        return; // Ne pas ajouter cette interaction
      }
    }
    
    // Traiter l'interaction en fonction de son type
    switch (interactionData.type) {
      case 'REC_STATE':
        // Code existant pour REC_STATE
        setGeneroData(interactionData);
        
        addInteraction({
          interactionId: interactionData.interactionId || `resp_${Date.now()}`,
          direction: 'RESPONSE',
          type: 'REC_STATE',
          status: 'COMPLETED',
          name: interactionData.technicalProperties?.userInterface?.focus?.nodeName || 'State Update',
          value: 'JSON',
          technicalProperties: interactionData.technicalProperties,
          timestamp: new Date()
        });
        
        // Mise en évidence du panneau
        if (interactionData.technicalProperties?.userInterface?.focus) {
          setPanelHighlighted(true);
          setTimeout(() => setPanelHighlighted(false), 1000);
        }
        break;
        
      // Autres cas
      case 'REC_START':
      case 'REC_ACTION':
      case 'REC_ACTION_EXECUTED':
      case 'REC_STOP':
      case 'REC_ERROR':
      case 'REC_SUCCESS':
      case 'REC_COMPLETE':
        addInteraction({
          interactionId: interactionData.interactionId,
          direction: interactionData.direction,
          type: interactionData.type,
          status: interactionData.status,
          name: interactionData.technicalProperties?.nodeName || 
                interactionData.technicalProperties?.actionName || 
                interactionData.type,
          value: interactionData.technicalProperties?.nodeValue || 
                 interactionData.technicalProperties?.actionValue || 
                 'JSON',
          technicalProperties: interactionData.technicalProperties,
          timestamp: interactionData.timestamp ? new Date(interactionData.timestamp) : new Date()
        });
        break;
        
      default:
        // Ajouter quand même l'interaction même si type inconnu
        debugLog(`Type d'interaction non géré: ${interactionData.type}`, 'warn', interactionData);
        addInteraction({
          interactionId: interactionData.interactionId || `unknown_${Date.now()}`,
          direction: interactionData.direction || 'RESPONSE',
          type: interactionData.type || 'UNKNOWN',
          status: interactionData.status || 'RECEIVED',
          name: interactionData.technicalProperties?.nodeName || 
                interactionData.technicalProperties?.actionName || 
                interactionData.type || 'Interaction inconnue',
          value: 'JSON',
          technicalProperties: interactionData.technicalProperties || {},
          timestamp: interactionData.timestamp ? new Date(interactionData.timestamp) : new Date()
        });
    }
  };
  
  // Fonctions pour la gestion de l'arborescence
  const fetchEnvironments = async () => {
    try {
      setIsLoading(true);
      debugLog('Récupération des environnements...', 'info');
      const response = await api.automata.environments.getAll();
      
      // Formater les données pour l'arborescence
      const formattedEnvironments = response.map(env => ({
        id: env.id,
        name: env.appName,
        appType: env.appType,
        appVersion: env.appVersion,
        isActive: env.isActive,
        functions: [],
        raw: env // Conserver l'objet original pour accéder à toutes ses propriétés
      }));
      
      setEnvironments(formattedEnvironments);
      debugLog(`${formattedEnvironments.length} environnements récupérés`, 'success', formattedEnvironments);
    } catch (error) {
      debugLog('Échec de la récupération des environnements', 'error', error);
      setSnackbar({
        open: true,
        message: 'Échec de la récupération des environnements',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchFunctionsForEnvironment = async (environmentId) => {
    try {
      setIsTreeLoading(true);
      debugLog(`Récupération des fonctions pour l'environnement ${environmentId}`, 'info');
      const response = await api.automata.functions.getAll(environmentId);
      
      // Formater les données pour l'arborescence
      const formattedFunctions = response.map(func => ({
        id: func.id,
        name: func.functionName,
        description: func.functionDescription,
        type: func.functionType,
        domain: func.functionDomain,
        module: func.functionModule,
        program: func.functionProgram,
        isActive: func.isActive,
        tasks: [],
        raw: func
      }));
      
      // Mettre à jour l'environnement avec ses fonctions
      setEnvironments(prevEnvironments => {
        return prevEnvironments.map(env => {
          if (env.id === environmentId) {
            return { ...env, functions: formattedFunctions };
          }
          return env;
        });
      });
      
      debugLog(`${formattedFunctions.length} fonctions récupérées pour l'environnement ${environmentId}`, 'success', formattedFunctions);
    } catch (error) {
      debugLog(`Échec de la récupération des fonctions pour l'environnement ${environmentId}`, 'error', error);
      setSnackbar({
        open: true,
        message: 'Échec de la récupération des fonctions',
        severity: 'error'
      });
    } finally {
      setIsTreeLoading(false);
    }
  };
  
  const fetchFunctionInfo = async (functionId) => {
    try {
      setIsLoading(true);
      debugLog(`Récupération des informations pour la fonction ${functionId}`, 'info');
      const response = await api.automata.functions.getById(functionId);
      setFunctionInfo(response);
      setTask(prevTask => ({
        ...prevTask,
        function: { id: functionId }
      }));
      debugLog('Informations de fonction récupérées', 'success', response);
    } catch (error) {
      debugLog('Échec de la récupération des informations de la fonction', 'error', error);
      setSnackbar({
        open: true,
        message: 'Échec de la récupération des informations de la fonction',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchTasksForFunction = async (functionId) => {
    try {
      setIsTreeLoading(true);
      debugLog(`Récupération des tâches pour la fonction ${functionId}`, 'info');
      const response = await api.automata.tasks.getByFunction(functionId);
      
      // Formater les données pour l'arborescence
      const formattedTasks = response.map(task => ({
        id: task.id,
        name: task.taskName,
        description: task.taskDescription,
        status: task.taskStatus,
        executionStatus: task.executionStatus,
        raw: task
      }));
      
      setTasksForFunction(formattedTasks);
      debugLog(`${formattedTasks.length} tâches récupérées pour la fonction ${functionId}`, 'success', formattedTasks);
    } catch (error) {
      debugLog(`Échec de la récupération des tâches pour la fonction ${functionId}`, 'error', error);
      setSnackbar({
        open: true,
        message: 'Échec de la récupération des tâches',
        severity: 'error'
      });
      setTasksForFunction([]);
    } finally {
      setIsTreeLoading(false);
    }
  };
  
  const handleToggleEnvironment = (environmentId) => {
    const isExpanded = expandedEnvironments[environmentId];
    
    // Si on développe l'environnement et qu'il n'a pas encore ses fonctions chargées
    if (!isExpanded) {
      const environment = environments.find(env => env.id === environmentId);
      if (environment && environment.functions.length === 0) {
        fetchFunctionsForEnvironment(environmentId);
      }
    }
    
    setExpandedEnvironments(prev => ({
      ...prev,
      [environmentId]: !isExpanded
    }));
  };
  
  const handleToggleFunction = (functionId) => {
    const isExpanded = expandedFunctions[functionId];
    setExpandedFunctions(prev => ({
      ...prev,
      [functionId]: !isExpanded
    }));
  };
  
  const handleSelectEnvironment = (environment) => {
    setSelectedEnvironment(environment);
    setSelectedFunction(null);
  };
  
  const handleSelectFunction = (environment, functionItem) => {
    setSelectedEnvironment(environment);
    setSelectedFunction(functionItem);
  };
  
  const handleSelectTask = (task) => {
    debugLog(`Tâche sélectionnée: ${task.name} (ID: ${task.id})`, 'info', task);
    // Implémenter la logique pour charger ou utiliser une tâche existante
  };
  
  // Gestionnaire d'entrée pour les champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTask(prevTask => ({ ...prevTask, [name]: value }));
  };
  
  // Gestionnaire d'entrée pour les valeurs booléennes
  const handleBooleanChange = (e) => {
    const { name, checked } = e.target;
    setTask(prevTask => ({ ...prevTask, [name]: checked }));
  };
  
  // Fonction pour gérer l'envoi d'une interaction
  const handleInteractionSent = (interactionPayload) => {
    setPanelDisabled(true);
    
    // Ajouter l'ID client aux propriétés techniques si présent
    if (generoData?.technicalProperties?.clientId) {
      interactionPayload.technicalProperties = {
        ...interactionPayload.technicalProperties,
        clientId: generoData.technicalProperties.clientId,
        sessionId: generoData.technicalProperties.sessionId
      };
    }
    
    debugLog('Envoi d\'une interaction', 'info', interactionPayload);
    
    // Préparer l'interaction pour l'affichage
    const displayInteraction = {
      ...interactionPayload,
      direction: 'REQUEST',
      type: 'REC_ACTION',
      status: 'PENDING',
      name: interactionPayload.technicalProperties?.nodeName || 'Action',
      value: interactionPayload.technicalProperties?.nodeValue || 'N/A',
      timestamp: new Date(),
      interactionId: `INT_${Date.now()}`
    };
    
    // MODIFIÉ: Approche conditionnelle plutôt que combinée avec fallback
    
    // Vérifier si WebSocket est disponible et connecté
    if (eventSourceRef.current && eventSourceRef.current.getStatus().connected) {
      // Utiliser WebSocket uniquement
      debugLog('Tentative d\'envoi via WebSocket', 'info');
      
      try {
        api.automata.record.sendWebSocketMessage(clientIp, {
          type: 'INTERACTION',
          interaction: interactionPayload
        });
        
        debugLog('Interaction envoyée via WebSocket', 'success');
        addInteraction(displayInteraction);
      } catch (error) {
        debugLog('Erreur WebSocket, tentative via API REST', 'warn', error);
        sendViaRest();
      }
    } else {
      // WebSocket non disponible, utiliser REST
      debugLog('WebSocket non disponible, utilisation API REST', 'info');
      sendViaRest();
    }
    
    // Fonction interne pour l'envoi via REST API
    function sendViaRest() {
      api.automata.record.sendInteraction(clientIp, interactionPayload)
        .then(() => {
          debugLog('Interaction envoyée via API REST', 'success');
          addInteraction(displayInteraction);
        })
        .catch(error => {
          debugLog('Erreur lors de l\'envoi de l\'interaction', 'error', error);
          setSnackbar({
            open: true,
            message: 'Erreur lors de l\'envoi de l\'interaction: ' + error.message,
            severity: 'error'
          });
          setPanelDisabled(false);
        });
    }
  };
  
  const addInteraction = (interactionData) => {
    
    setInteractions(prev => {
      const newSequence = prev.length > 0 ? 
        Math.max(...prev.map(i => i.sequence || 0)) + 1 : 1;
      
      const newInteraction = {
        id: interactionData.interactionId || `int_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
        sequence: newSequence, // Utiliser la nouvelle séquence calculée
        timestamp: interactionData.timestamp || new Date(),
        direction: interactionData.direction,
        type: interactionData.type,
        status: interactionData.status || 'PENDING',
        name: interactionData.name,
        value: interactionData.value,
        technicalProperties: interactionData.technicalProperties || {}
      };
      
      
      return [newInteraction, ...prev]; // Pour ordre chronologique inverse (plus récent en haut)
    });
    
    // Mettre à jour le compteur après avoir ajouté l'interaction
    setInteractionCounter(prev => prev + 1);
    debugLog('Interaction ajoutée au journal', 'info');
  };
  
  const startRecording = async () => {
    if (!selectedEnvironment || !selectedFunction || !task.taskName || !task.login) {
      setSnackbar({
        open: true,
        message: 'Veuillez remplir tous les champs requis',
        severity: 'error'
      });
      return;
    }
    
    if (!agentCompatibility.compatible) {
      setSnackbar({
        open: true,
        message: 'L\'environnement sélectionné n\'a pas d\'agent compatible',
        severity: 'error'
      });
      return;
    }
    
    if (!isConnected) {
      setSnackbar({
        open: true,
        message: 'La connexion WebSocket n\'est pas établie',
        severity: 'warning'
      });
      return;
    }
    
    try {
      debugLog('Démarrage de l\'enregistrement...', 'info');
      
      const taskToSend = {
        function: { 
          id: selectedFunction.id,
          environment: {
            id: selectedEnvironment.id
          }
        },
        taskName: task.taskName,
        taskDescription: task.taskDescription,
        login: task.login,
        password: task.password,
        taskScenario: task.taskScenario,
        expectedResult: task.expectedResult,
        isActive: task.isActive,
        executionType_typ: 'AUTOMATIC',
        origin_typ: 'RECORD'
      };
      
      // MODIFIÉ: Approche conditionnelle plutôt que combinée
      let success = false;
      
      // Vérifier si WebSocket est disponible et connecté
      if (eventSourceRef.current && eventSourceRef.current.getStatus().connected) {
        // Utiliser WebSocket uniquement si disponible
        debugLog('Tentative de démarrage via WebSocket', 'info');
        api.automata.record.sendWebSocketMessage(clientIp, {
          type: 'START_CAPTURE',
          task: taskToSend
        });
        
        // Supposer que ça a fonctionné, les erreurs seront gérées par l'écouteur WebSocket
        success = true;
        debugLog('Commande de démarrage envoyée via WebSocket', 'success');
      } else {
        // Utiliser REST API comme fallback
        debugLog('WebSocket non disponible, tentative via API REST', 'info');
        const response = await api.automata.record.start(clientIp, taskToSend);
        
        if (response.success) {
          success = true;
          debugLog('Enregistrement démarré avec succès via API REST', 'success', response);
        } else {
          throw new Error(`Erreur serveur: ${response.message || 'Réponse non valide'}`);
        }
      }
      
      if (success) {
        setInteractions([]);
        setRecording(true);
        setSnackbar({
          open: true,
          message: 'Enregistrement démarré',
          severity: 'success'
        });
      }
    } catch (error) {
      debugLog('Erreur lors du démarrage de l\'enregistrement', 'error', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du démarrage: ' + (error.message || 'Connexion perdue'),
        severity: 'error'
      });
    }
  };
  
  const stopRecording = async () => {
    try {
      debugLog('Arrêt de l\'enregistrement...', 'info');
      
      // MODIFIÉ: Approche conditionnelle plutôt que combinée
      let success = false;
      
      // Vérifier si WebSocket est disponible et connecté
      if (eventSourceRef.current && eventSourceRef.current.getStatus().connected) {
        // Utiliser WebSocket uniquement si disponible
        debugLog('Tentative d\'arrêt via WebSocket', 'info');
        api.automata.record.sendWebSocketMessage(clientIp, {
          type: 'STOP_CAPTURE'
        });
        
        // Supposer que ça a fonctionné
        success = true;
        debugLog('Commande d\'arrêt envoyée via WebSocket', 'success');
      } else {
        // Utiliser REST API comme fallback
        debugLog('WebSocket non disponible, tentative via API REST', 'info');
        const response = await api.automata.record.stop(clientIp);
        success = true; // REST API ne renvoie pas de flag success/failure explicite
        debugLog('Enregistrement arrêté via API REST', 'success');
      }
      
      if (success) {
        setRecording(false);
        setSnackbar({
          open: true,
          message: 'Enregistrement arrêté',
          severity: 'info'
        });
      }
    } catch (error) {
      debugLog('Erreur lors de l\'arrêt de l\'enregistrement', 'error', error);
      
      // Même si une erreur se produit, on considère que l'enregistrement est arrêté
      setRecording(false);
      setSnackbar({
        open: true,
        message: 'Enregistrement arrêté (partiellement)',
        severity: 'warning'
      });
    }
  };
  
  // Fonction pour sauvegarder la tâche
  const saveTask = async () => {
    try {
      if (!selectedFunction) {
        setSnackbar({
          open: true,
          message: 'Aucune fonction sélectionnée',
          severity: 'error'
        });
        return;
      }
      
      debugLog('Sauvegarde de la tâche...', 'info');
      
      // Formatage amélioré des interactions pour l'API
      const formattedInteractions = interactions
        // Filtrer les interactions non pertinentes
        .filter(interaction => interaction.status !== 'FAILED')
        // Formater correctement chaque interaction
        .map(interaction => ({
          // Ne PAS inclure l'ID - il sera généré par le backend
          interactionId: interaction.interactionId || `int_${Date.now()}_${Math.random().toString(36).substr(2, 5)}`,
          sequence: interaction.sequence || 0,
          timestamp: interaction.timestamp instanceof Date ? 
            interaction.timestamp.toISOString() : interaction.timestamp,
          direction: interaction.direction || 'REQUEST',
          type: interaction.type || 'REC_ACTION',
          status: interaction.status || 'COMPLETED',
          // Simplifier les propriétés techniques pour éviter les problèmes de sérialisation
          technicalProperties: sanitizeTechnicalProperties(interaction.technicalProperties || {})
        }));
      
      // Construction de l'objet tâche
      const taskToSave = {
        ...task,
        function: { id: selectedFunction.id },
        interactions: formattedInteractions
      };
      
      debugLog('Envoi des données de tâche à sauvegarder', 'info', 
        {
          tâche: {
            ...taskToSave,
            interactions: `${taskToSave.interactions.length} interactions`
          },
          échantillonInteractions: taskToSave.interactions.slice(0, 2)
        }
      );
      
      const response = await api.automata.record.save(clientIp, taskToSave);
      
      if (response.success) {
        debugLog('Tâche sauvegardée avec succès', 'success', response);
        
        // Rafraîchir la liste des tâches pour la fonction
        fetchTasksForFunction(selectedFunction.id);
        
        setSnackbar({
          open: true,
          message: 'Tâche sauvegardée avec succès',
          severity: 'success'
        });
      } else {
        throw new Error(`Erreur lors de la sauvegarde: ${response.message || 'Réponse non valide'}`);
      }
    } catch (error) {
      debugLog('Erreur lors de la sauvegarde de la tâche', 'error', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la sauvegarde de la tâche: ' + error.message,
        severity: 'error'
      });
    }
  };
  
  // Fonction utilitaire pour nettoyer les propriétés techniques
  const sanitizeTechnicalProperties = (props) => {
    // Créer une copie pour éviter de modifier l'original
    const sanitized = {...props};
    
    // Supprimer les propriétés problématiques et inutiles
    delete sanitized.id;
    delete sanitized.task;
    
    // Pour chaque propriété, si c'est un objet complexe, simplifier récursivement
    Object.keys(sanitized).forEach(key => {
      if (sanitized[key] && typeof sanitized[key] === 'object' && !Array.isArray(sanitized[key])) {
        sanitized[key] = sanitizeTechnicalProperties(sanitized[key]);
      }
    });
    
    return sanitized;
  };
  
  // Fonction pour exporter les logs de débogage
  const exportLogs = () => {
    try {
      debugLog('Exportation des logs de débogage...', 'info');
      
      const content = debugLogs.map(log => {
        const timestamp = log.timestamp.toISOString();
        const level = log.type.toUpperCase();
        const message = log.message;
        const data = log.data ? JSON.stringify(log.data) : '';
        return `[${timestamp}] [${level}] ${message} ${data}`;
      }).join('\n');
      
      const blob = new Blob([content], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `record-capture-logs-${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.txt`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
      debugLog('Logs exportés avec succès', 'success');
    } catch (error) {
      debugLog('Erreur lors de l\'exportation des logs', 'error', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de l\'exportation des logs: ' + error.message,
        severity: 'error'
      });
    }
  };
  
  // Fonction pour effacer les logs de débogage
  const clearLogs = () => {
    debugLog('Effacement des logs de débogage...', 'info');
    setDebugLogs([]);
    debugLog('Logs effacés', 'success');
  };
  
  // Fonction pour copier les logs dans le presse-papiers
  const copyLogs = () => {
    try {
      debugLog('Copie des logs dans le presse-papiers...', 'info');
      
      const content = debugLogs.map(log => {
        const timestamp = log.timestamp.toISOString();
        const level = log.type.toUpperCase();
        const message = log.message;
        const data = log.data ? JSON.stringify(log.data) : '';
        return `[${timestamp}] [${level}] ${message} ${data}`;
      }).join('\n');
      
      navigator.clipboard.writeText(content);
      
      debugLog('Logs copiés dans le presse-papiers', 'success');
      setSnackbar({
        open: true,
        message: 'Logs copiés dans le presse-papiers',
        severity: 'success'
      });
    } catch (error) {
      debugLog('Erreur lors de la copie des logs', 'error', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la copie des logs: ' + error.message,
        severity: 'error'
      });
    }
  };
  
  // Fonction pour obtenir le composant de capture approprié en fonction du type d'agent
  const getCaptureComponent = (agentType) => {
    switch (agentType) {
      case 'GENERO_GDC':
      case 'GENERO_GAS':
        return GeneroCapture;
      case 'MOBILE_IOS':
        return iOSAppCapture;
      case 'MOBILE_ANDROID':
        return AndroidAppCapture;
      case 'DATABASE_IFX':
        return InformixCapture;
      case 'DATABASE_ORA':
        return OracleCapture;
      case 'OS_LINUX':
        return LinuxCapture;
      case 'OS_WINDOWS':
        return WindowsCapture;
      case 'API_REST':
        return RestApiCapture;
      case 'API_SOAP':
        return SoapApiCapture;
      case 'WEB_BROWSER':
        return WebBrowserCapture;
      default:
        // En cas de type non reconnu, on peut retourner null ou un composant par défaut
        return null;
    }
  };
  
  // Composant Tree Node pour l'arborescence
  const TreeNode = ({ 
    node, 
    level, 
    isEnvironment = false, 
    isFunction = false, 
    isTask = false,
    parent = null,
    expanded = false,
    onToggle,
    onSelect
  }) => {
    const getIcon = () => {
      if (isEnvironment) return <Server size={20} />;
      if (isFunction) return <Database size={20} />;
      if (isTask) return <FileText size={20} />;
      return <Folder size={20} />;
    };
    
    const getBadge = () => {
      if (isEnvironment) {
        return node.functions.length > 0 ? <Badge badgeContent={node.functions.length} color="primary" /> : null;
      }
      if (isFunction) {
        return tasksForFunction.length > 0 && node.id === selectedFunction?.id 
          ? <Badge badgeContent={tasksForFunction.length} color="secondary" /> 
          : null;
      }
      return null;
    };
    
    const getStatusChip = () => {
      if (isEnvironment) {
        const compatibility = checkAgentCompatibility(node);
        return (
          <>
            <Chip 
              size="small" 
              color={node.isActive ? "success" : "default"}
              label={node.isActive ? "Actif" : "Inactif"}
              sx={{ ml: 1, height: 20 }}
            />
            {compatibility.compatible && (
              <Chip 
                size="small" 
                color="info"
                label={compatibility.agentType || "Compatible"}
                sx={{ ml: 1, height: 20 }}
              />
            )}
          </>
        );
      }
      if (isFunction) {
        return (
          <Chip 
            size="small" 
            color={node.isActive ? "success" : "default"}
            label={node.isActive ? "Actif" : "Inactif"} 
            sx={{ ml: 1, height: 20 }}
          />
        );
      }
      if (isTask) {
        return (
          <Chip 
            size="small" 
            color={node.executionStatus === 'COMPLETED' ? "success" : "warning"}
            label={node.executionStatus || "Non exécutée"} 
            sx={{ ml: 1, height: 20 }}
          />
        );
      }
      return null;
    };
    
    return (
      <ListItem
        button
        alignItems="flex-start"
        sx={{ 
          pl: level * 2,
          pr: 1,
          py: 0.5,
          cursor: isConnected ? 'pointer' : 'not-allowed',
          borderLeft: isTask ? 'none' : '1px dashed',
          borderColor: 'divider',
          "&:hover": {
            backgroundColor: 'action.hover',
          },
          ...(
            (isEnvironment && selectedEnvironment?.id === node.id) ||
            (isFunction && selectedFunction?.id === node.id)
          ) && {
            backgroundColor: 'primary.light',
            fontWeight: 'bold',
          }
        }}
      >
        <ListItemIcon 
          sx={{ 
            minWidth: 36, 
            color: (isEnvironment && selectedEnvironment?.id === node.id) ||
                 (isFunction && selectedFunction?.id === node.id) 
              ? 'primary.contrastText' 
              : 'inherit'
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onToggle) onToggle();
          }}
        >
          {(isEnvironment || isFunction) && (
            <IconButton size="small" edge="start">
              {expanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </IconButton>
          )}
          {getIcon()}
          {getBadge()}
        </ListItemIcon>
        
        <ListItemText 
          primary={
            <Box 
              component="div" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                color: (isEnvironment && selectedEnvironment?.id === node.id) ||
                     (isFunction && selectedFunction?.id === node.id) 
                  ? 'primary.contrastText' 
                  : 'text.primary'
              }}
              onClick={() => {
                if (isEnvironment) {
                  onSelect(node);
                } else if (isFunction) {
                  onSelect(parent, node);
                } else if (isTask) {
                  onSelect(node);
                }
              }}
            >
              <Typography variant="body2" component="span">
                {node.name}
              </Typography>
              {getStatusChip()}
            </Box>
          }
          secondary={
            node.description ? (
              <Typography
                variant="caption"
                color="text.secondary"
                component="span"
                noWrap
                sx={{ display: 'block', maxWidth: '100%' }}
              >
                {node.description}
              </Typography>
            ) : null
          }
        />
      </ListItem>
    );
  };
  
  // Composant pour l'arborescence des environnements et fonctions
  const EnvironmentTreeView = () => {
    return (
      <Paper 
        sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column', 
          overflow: 'hidden',
          borderRadius: 1
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            px: 2, 
            py: 1, 
            bgcolor: 'primary.main', 
            color: 'primary.contrastText' 
          }}
        >
          <Typography variant="subtitle1">
            <Server size={16} style={{ verticalAlign: 'middle', marginRight: 8 }} />
            Environnements et Fonctions
          </Typography>
          <IconButton 
            size="small" 
            color="inherit" 
            onClick={fetchEnvironments} 
            title="Rafraîchir"
          >
            <RefreshCw size={16} />
          </IconButton>
        </Box>
        
        {isTreeLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <List 
            sx={{ 
              flex: 1, 
              overflow: 'auto',
              bgcolor: 'background.paper',
              py: 0
            }}
            dense
          >
            {environments.length === 0 ? (
              <ListItem>
                <ListItemText 
                  primary="Aucun environnement trouvé" 
                  secondary="Cliquez sur le bouton de rafraîchissement pour réessayer"
                />
              </ListItem>
            ) : (
              environments.map(env => (
                <React.Fragment key={env.id}>
                  <TreeNode 
                    node={env}
                    level={0}
                    isEnvironment
                    expanded={expandedEnvironments[env.id]}
                    onToggle={() => handleToggleEnvironment(env.id)}
                    onSelect={handleSelectEnvironment}
                  />
                  
                  <Collapse in={expandedEnvironments[env.id]} timeout="auto" unmountOnExit>
                    {env.functions.length === 0 ? (
                      <ListItem sx={{ pl: 4 }}>
                        <ListItemText 
                          secondary="Aucune fonction trouvée" 
                          primaryTypographyProps={{ variant: 'caption' }}
                        />
                      </ListItem>
                    ) : (
                      env.functions.map(func => (
                        <React.Fragment key={func.id}>
                          <TreeNode 
                            node={func}
                            parent={env}
                            level={1}
                            isFunction
                            expanded={expandedFunctions[func.id]}
                            onToggle={() => handleToggleFunction(func.id)}
                            onSelect={handleSelectFunction}
                          />
                          
                          {selectedFunction?.id === func.id && (
                            <Collapse in={expandedFunctions[func.id]} timeout="auto" unmountOnExit>
                              {tasksForFunction.length === 0 ? (
                                <ListItem sx={{ pl: 8 }}>
                                  <ListItemText 
                                    secondary="Aucune tâche trouvée" 
                                    primaryTypographyProps={{ variant: 'caption' }}
                                  />
                                </ListItem>
                              ) : (
                                tasksForFunction.map(task => (
                                  <TreeNode 
                                    key={task.id}
                                    node={task}
                                    level={2}
                                    isTask
                                    onSelect={handleSelectTask}
                                  />
                                ))
                              )}
                            </Collapse>
                          )}
                        </React.Fragment>
                      ))
                    )}
                  </Collapse>
                </React.Fragment>
              ))
            )}
          </List>
        )}
      </Paper>
    );
  };
  
  // Composant de dialogue pour afficher les détails techniques en JSON
  const JsonDetailsDialog = ({ open, onClose, data }) => {
    const [activeTab, setActiveTab] = useState('tree');
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedNodes, setExpandedNodes] = useState({});
    
    // Fonction pour extraire les informations principales
    const extractMainInfo = () => {
      const mainInfo = {};
      if (data?.clientId) mainInfo['ID Client'] = data.clientId;
      if (data?.sessionId) mainInfo['Session'] = data.sessionId;
      if (data?.clientState) mainInfo['État'] = data.clientState;
      if (data?.timestamp) mainInfo['Timestamp'] = new Date(data.timestamp).toLocaleString();
      return mainInfo;
    };
    
    // Fonction pour mettre en forme les valeurs
    const formatValue = (value) => {
      if (value === null) return <Typography component="span" color="text.disabled">null</Typography>;
      if (typeof value === 'boolean') return <Chip size="small" label={value.toString()} color={value ? "success" : "error"} />;
      if (typeof value === 'number') return <Typography component="span" color="success.main">{value}</Typography>;
      if (typeof value === 'string') return <Typography component="span" color="primary.main">"{value}"</Typography>;
      return value;
    };
    
    // Composant pour l'affichage en arbre
    const TreeView = ({ data, path = '', level = 0 }) => {
      if (!data || typeof data !== 'object') return formatValue(data);
      
      return (
        <Box sx={{ ml: level > 0 ? 3 : 0 }}>
          {Object.entries(data).map(([key, value]) => {
            const currentPath = path ? `${path}.${key}` : key;
            const isObject = value && typeof value === 'object';
            const isExpanded = expandedNodes[currentPath];
            const isHighlighted = searchTerm &&
              (key.toLowerCase().includes(searchTerm.toLowerCase()) ||
              (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())));
            
            return (
              <Box
                key={currentPath}
                sx={{
                  mb: 1,
                  bgcolor: isHighlighted ? 'warning.light' : 'transparent',
                  borderRadius: 1,
                  transition: 'background-color 0.2s'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: isObject ? 'pointer' : 'default',
                    p: 0.5,
                    '&:hover': {
                      bgcolor: 'action.hover',
                      borderRadius: 1
                    }
                  }}
                  onClick={() => {
                    if (isObject) {
                      setExpandedNodes(prev => ({ ...prev, [currentPath]: !prev[currentPath] }));
                    }
                  }}
                >
                  {isObject && (
                    <IconButton size="small">
                      {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                    </IconButton>
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: level === 0 ? 600 : 400,
                      color: isHighlighted ? 'warning.dark' : 'text.primary',
                      fontFamily: 'monospace'
                    }}
                  >
                    {key}:
                  </Typography>
                  {!isObject && (
                    <Box sx={{ ml: 1 }}>
                      {formatValue(value)}
                    </Box>
                  )}
                </Box>
                {isObject && isExpanded && (
                  <Box sx={{ ml: 3 }}>
                    <TreeView data={value} path={currentPath} level={level + 1} />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      );
    };
    
    // Composant pour l'affichage des informations rapides
    const QuickInfo = () => {
      const mainInfo = extractMainInfo();
      return (
        <Box>
          <Grid container spacing={2}>
            {Object.entries(mainInfo).map(([key, value]) => (
              <Grid item xs={12} sm={6} key={key}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}
                >
                  <Typography variant="caption" color="text.secondary">
                    {key}
                  </Typography>
                  <Typography variant="body1">
                    {value}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    };
    
    // Composant pour la vue JSON brute
    const RawView = () => (
      <Box
        sx={{
          fontFamily: 'monospace',
          fontSize: '0.875rem',
          bgcolor: 'grey.900',
          color: 'common.white',
          p: 2,
          borderRadius: 1,
          overflow: 'auto'
        }}
      >
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Box>
    );
    
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: { height: '90vh' }
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: 'primary.main',
            color: 'common.white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6">État de l'Application Client</Typography>
            <Chip
              label={data?.clientState || 'Inconnu'}
              color={data?.clientState === 'INTERACTIVE' ? 'success' : 'warning'}
              size="small"
            />
          </Box>
          <IconButton color="inherit" onClick={onClose}>
            <X size={20} />
          </IconButton>
        </DialogTitle>
        
        <Box sx={{ px: 2, py: 1, bgcolor: 'grey.100' }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Rechercher dans les données..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <Search size={18} style={{ marginRight: 8 }} />
            }}
          />
        </Box>
        
        <Tabs
          value={activeTab}
          onChange={(e, v) => setActiveTab(v)}
          sx={{ px: 2, borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab value="tree" label="Vue Arborescente" icon={<ListIcon size={16} />} iconPosition="start" />
          <Tab value="quick" label="Informations Rapides" icon={<Info size={16} />} iconPosition="start" />
          <Tab value="raw" label="JSON Brut" icon={<Code size={16} />} iconPosition="start" />
        </Tabs>
        
        <DialogContent>
          {activeTab === 'tree' && <TreeView data={data} />}
          {activeTab === 'quick' && <QuickInfo />}
          {activeTab === 'raw' && <RawView />}
        </DialogContent>
        
        <DialogActions sx={{ bgcolor: 'grey.50', borderTop: 1, borderColor: 'divider' }}>
          <Button
            variant="outlined"
            startIcon={<ChevronRight />}
            onClick={() => setExpandedNodes({})}
          >
            Tout Réduire
          </Button>
          <Button
            variant="outlined"
            startIcon={<ChevronDown />}
            onClick={() => {
              // Expansion de tous les nœuds
              const allPaths = {};
              const fillPaths = (obj, path = '') => {
                if (!obj || typeof obj !== 'object') return;
                Object.keys(obj).forEach(key => {
                  const currentPath = path ? `${path}.${key}` : key;
                  allPaths[currentPath] = true;
                  if (obj[key] && typeof obj[key] === 'object') {
                    fillPaths(obj[key], currentPath);
                  }
                });
              };
              fillPaths(data);
              setExpandedNodes(allPaths);
            }}
          >
            Tout Développer
          </Button>
          <Button variant="contained" onClick={onClose}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  // Composant pour la console de débogage
  const DebugConsole = ({ open, onClose }) => {
    return (
      <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { 
            height: `${consoleHeight}px`, 
            maxHeight: '60vh',
            backgroundColor: `rgba(33, 33, 33, ${consoleOpacity})`
          }
        }}
        hideBackdrop={true}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <Box sx={{ 
          p: 1, 
          bgcolor: `rgba(20, 20, 20, ${consoleOpacity + 0.05})`, 
          color: 'common.white', 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          userSelect: 'none', // Assure que les opérations de texte ne sont pas affectées
        }}>
          <Typography variant="subtitle1">
            <MessageSquare size={16} style={{ marginRight: 8, verticalAlign: 'middle' }} />
            Console de Débogage
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: 160 }}>
              <Tooltip title="Opacité">
                {consoleOpacity >= 0.8 ? (
                  <Eye size={16} color="white" />
                ) : (
                  <EyeOff size={16} color="white" />
                )}
              </Tooltip>
              <Slider
                size="small"
                value={consoleOpacity}
                min={0.1}
                max={1}
                step={0.1}
                onChange={(e, newValue) => setConsoleOpacity(newValue)}
                sx={{ 
                  color: 'white',
                  '& .MuiSlider-thumb': {
                    width: 12,
                    height: 12,
                  }
                }}
              />
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: 160 }}>
              <Tooltip title="Hauteur">
                <Activity size={16} color="white" />
              </Tooltip>
              <Slider
                size="small"
                value={consoleHeight}
                min={150}
                max={500}
                step={10}
                onChange={(e, newValue) => setConsoleHeight(newValue)}
                sx={{ 
                  color: 'white',
                  '& .MuiSlider-thumb': {
                    width: 12,
                    height: 12,
                  }
                }}
              />
            </Box>
            
            <Box>
              <Tooltip title="Copier les logs">
                <IconButton size="small" color="inherit" onClick={copyLogs}>
                  <Copy size={16} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Exporter les logs">
                <IconButton size="small" color="inherit" onClick={exportLogs}>
                  <Download size={16} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Effacer les logs">
                <IconButton size="small" color="inherit" onClick={clearLogs}>
                  <Trash2 size={16} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Fermer la console">
                <IconButton size="small" color="inherit" onClick={onClose}>
                  <X size={16} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box 
          ref={debugConsoleRef}
          sx={{ 
            p: 1, 
            height: 'calc(100% - 48px)', 
            overflow: 'auto',
            fontFamily: 'monospace',
            fontSize: '0.875rem',
            color: 'rgba(255, 255, 255, 0.9)',
            userSelect: 'text', // Permettre la sélection de texte
          }}
        >
          {debugLogs.map((log, index) => {
            let color;
            switch(log.type) {
              case 'error':
                color = '#ff5252';
                break;
              case 'warn':
                color = '#ffb74d';
                break;
              case 'success':
                color = '#69f0ae';
                break;
              default:
                color = '#90caf9';
            }
            
            return (
              <Box key={index} sx={{ mb: 0.5 }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color, 
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                >
                  [{log.timestamp.toLocaleTimeString()}] [{log.type.toUpperCase()}] {log.message}
                  {log.data && (
                    <Box component="span" sx={{ 
                      display: 'block', 
                      ml: 2,
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '0.75rem',
                      maxHeight: '100px',
                      overflow: 'auto'
                    }}>
                      {typeof log.data === 'object' ? JSON.stringify(log.data, null, 2) : log.data}
                    </Box>
                  )}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Drawer>
    );
  };
  
  // Composant pour afficher les détails de la fonction
  const FunctionDetailsCard = () => {
    if (!selectedFunction) return null;
    
    return (
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Database size={18} />
              <Typography variant="h6">{selectedFunction.name}</Typography>
              <Chip 
                size="small" 
                color={selectedFunction.isActive ? "success" : "default"}
                label={selectedFunction.isActive ? "Actif" : "Inactif"}
              />
            </Box>
          }
          subheader={
            <Typography variant="body2" color="text.secondary">
              Environnement: {selectedEnvironment?.name || 'N/A'}
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">Type:</Typography>
              <Typography variant="body2">{selectedFunction.type || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">Domaine:</Typography>
              <Typography variant="body2">{selectedFunction.domain || 'N/A'}</Typography>
            </Grid>
            {selectedFunction.module && (
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">Module:</Typography>
                <Typography variant="body2">{selectedFunction.module}</Typography>
              </Grid>
            )}
            {selectedFunction.program && (
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">Programme:</Typography>
                <Typography variant="body2">{selectedFunction.program}</Typography>
              </Grid>
            )}
            {selectedFunction.description && (
              <Grid item xs={12}>
                <Typography variant="caption" color="text.secondary">Description:</Typography>
                <Typography variant="body2">{selectedFunction.description}</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };
  
  // Composant pour afficher un message lorsque l'agent n'est pas compatible
  const AgentCompatibilityWarning = () => {
    return (
      <Box sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        p: 4,
        textAlign: 'center',
        bgcolor: 'background.paper',
        border: '1px dashed',
        borderColor: 'warning.main',
        borderRadius: 1
      }}>
        <AlertOctagon size={64} color="#f57c00" />
        <Typography variant="h5" color="warning.dark" sx={{ mt: 3, mb: 2 }}>
          Agent non compatible
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: 600, mb: 3 }}>
          L'environnement sélectionné n'est pas associé à un agent supporté.
          Veuillez sélectionner un environnement avec un agent compatible pour utiliser l'enregistreur de tâches.
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button 
            variant="outlined" 
            color="warning" 
            startIcon={<RefreshCw />}
            onClick={fetchEnvironments}
          >
            Rafraîchir les environnements
          </Button>
        </Box>
      </Box>
    );
  };
  
  // Vue de chargement
  if (isLoading && !environments.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ p: 0, height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils principale */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Terminal size={24} />
          Enregistrement de Tâche Automatisée
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PlayCircle />}
            onClick={startRecording}
            disabled={recording || !isConnected || !selectedFunction || !agentCompatibility.compatible}
          >
            Démarrer
          </Button>
          
          <Button
            variant="contained"
            color="error"
            startIcon={<StopCircle />}
            onClick={stopRecording}
            disabled={!recording}
          >
            Arrêter
          </Button>
          
          <Button
            variant="contained"
            color="success"
            startIcon={<Save />}
            onClick={saveTask}
            disabled={recording || interactions.length === 0}
          >
            Sauvegarder
          </Button>
          
          <Button
            variant="outlined"
            color="info"
            startIcon={<MessageSquare />}
            onClick={() => setDebugConsoleOpen(!debugConsoleOpen)}
          >
            Console {debugConsoleOpen ? 'Off' : 'On'}
          </Button>
        </Box>
      </Paper>
      
      {/* Contenu principal */}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Panneau latéral gauche - Arborescence et configuration */}
        <Box 
          sx={{ 
            width: 320, 
            display: 'flex',
            flexDirection: 'column',
            borderRight: 1,
            borderColor: 'divider',
            overflow: 'hidden'
          }}
        >
          {/* Arborescence */}
          <Box sx={{ flex: 1, overflow: 'auto', p: 1 }}>
            <EnvironmentTreeView />
          </Box>
          
          {/* Détails de la fonction sélectionnée */}
          <Box sx={{ p: 1, mt: 1 }}>
            {selectedFunction && <FunctionDetailsCard />}
          </Box>
          
          {/* Informations de la tâche */}
          <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
            <Typography variant="subtitle1" gutterBottom>
              Informations de la tâche
            </Typography>
            
            <TextField
              fullWidth
              label="Nom de la tâche"
              name="taskName"
              value={task.taskName}
              onChange={handleInputChange}
              disabled={recording}
              required
              margin="normal"
              size="small"
            />
            
            <TextField
              fullWidth
              label="Login"
              name="login"
              value={task.login}
              onChange={handleInputChange}
              disabled={recording}
              required
              margin="normal"
              size="small"
            />
            
            <TextField
              fullWidth
              label="Mot de passe"
              name="password"
              type="password"
              value={task.password}
              onChange={handleInputChange}
              disabled={recording}
              margin="normal"
              size="small"
            />
            
            <TextField
              fullWidth
              label="Description"
              name="taskDescription"
              value={task.taskDescription}
              onChange={handleInputChange}
              disabled={recording}
              multiline
              rows={2}
              margin="normal"
              size="small"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={task.isActive}
                  onChange={handleBooleanChange}
                  name="isActive"
                  disabled={recording}
                />
              }
              label="Actif"
            />
          </Box>
        </Box>
        
        {/* Zone centrale - Composant de capture approprié ou message d'avertissement */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Box sx={{ flex: 1, p: 2, overflow: 'hidden' }}>
            {agentCompatibility.compatible ? (
              (() => {
                const CaptureComponent = getCaptureComponent(agentCompatibility.agentType);
                
                return CaptureComponent ? (
                  <CaptureComponent 
                    data={generoData}
                    onInteractionSent={handleInteractionSent}
                    disabled={panelDisabled || !recording}
                    highlighted={panelHighlighted}
                    mode="record"
                    connectionStatus={isConnected}
                  />
                ) : (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}>
                    <AlertTriangle size={64} color="#f57c00" />
                    <Typography variant="h6" color="warning.dark" sx={{ mt: 2 }}>
                      Agent reconnu mais composant non disponible
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Agent de type {agentCompatibility.agentType}
                    </Typography>
                  </Box>
                );
              })()
            ) : (
              <AgentCompatibilityWarning />
            )}
          </Box>
        </Box>
        
        {/* Panneau latéral droit - Journal d'interactions */}
        <Paper 
          sx={{ 
            width: 500, 
            overflow: 'auto',
            borderLeft: 1,
            borderColor: 'divider'
          }}
        >
          <Box sx={{ p: 2, bgcolor: 'primary.dark', color: 'white' }}>
            <Typography variant="h6">Journal d'interactions</Typography>
            <Typography variant="caption">
              {interactions.length} interactions enregistrées
            </Typography>
          </Box>
          
          <TableContainer sx={{ maxHeight: 'calc(100vh - 184px)' }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={50}>Seq</TableCell>
                  <TableCell width={100}>Direction</TableCell>
                  <TableCell width={100}>Type</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell width={120}>Valeur</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interactions.map((interaction) => (
                  <TableRow 
                    key={interaction.id}
                    sx={{
                      bgcolor: interaction.direction === 'REQUEST' 
                        ? 'primary.50' 
                        : 'background.paper',
                      '&:hover': {
                        bgcolor: 'action.hover',
                      }
                    }}
                  >
                    <TableCell>{interaction.sequence}</TableCell>
                    <TableCell>
                      {interaction.direction === 'REQUEST' ? (
                        <Chip
                          icon={<ArrowUpRight size={14} />}
                          label="OUT"
                          size="small"
                          color="success"
                          variant="outlined"
                        />
                      ) : (
                        <Chip
                          icon={<ArrowDownLeft size={14} />}
                          label="IN"
                          size="small"
                          color="info"
                          variant="outlined"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={interaction.type}
                        size="small"
                        color={
                          interaction.type.includes('ERROR') 
                            ? 'error' 
                            : interaction.type.includes('SUCCESS') 
                              ? 'success' 
                              : 'default'
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title={interaction.name || ''}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            maxWidth: 150,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {interaction.name || 'N/A'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {interaction.direction === 'RESPONSE' && interaction.value === 'JSON' ? (
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setSelectedTechnicalProps(interaction.technicalProperties);
                            setJsonDialogOpen(true);
                          }}
                        >
                          JSON
                        </Button>
                      ) : (
                        <Tooltip title={interaction.value || ''}>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              maxWidth: 100,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {interaction.value || 'N/A'}
                          </Typography>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow ref={interactionsEndRef}></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      
      {/* Indicateur de statut de connexion */}
      <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}>
        <Chip
          icon={isConnected ? <CheckCircle size={16} /> : <AlertTriangle size={16} />}
          label={isConnected ? "Connecté" : "Déconnecté"}
          color={isConnected ? "success" : "error"}
          variant="filled"
        />
        <IconButton onClick={forceReconnect} title="Forcer la reconnexion">
          <RefreshCw size={16} />
        </IconButton>
      </Box>
      
      {/* Boîte de dialogue des détails JSON */}
      <JsonDetailsDialog
        open={jsonDialogOpen}
        onClose={() => setJsonDialogOpen(false)}
        data={selectedTechnicalProps}
      />
      
      {/* Console de débogage */}
      <DebugConsole
        open={debugConsoleOpen}
        onClose={() => setDebugConsoleOpen(false)}
      />
      
      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      {/* Alerte de connexion */}
      <Snackbar
        open={connectionAlertOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity="error"
          variant="filled"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setConnectionAlertOpen(false)}
            >
              <X />
            </IconButton>
          }
        >
          Connexion perdue. Tentative de reconnexion...
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RecordCapture;