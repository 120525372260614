import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  AlertTitle,
  Divider,
  Chip,
  Tooltip,
  CircularProgress,
  Switch,
  FormControlLabel,
  Card,
  CardContent,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
  Menu,
  ListItemButton,
  LinearProgress
} from '@mui/material';

import {
  Plus,
  Edit2,
  Trash2,
  Search,
  RefreshCw,
  ChevronDown,
  CheckCircle,
  XCircle,
  Copy,
  Star,
  Code,
  FileCode,
  PackageCheck,
  LinkIcon,
  Tag,
  Layers,
  Filter,
  Globe,
  BarChart2,
  ExternalLink,
  Settings,
  Info,
  List as ListIcon,
  Grid as GridIcon,
  Calendar,
  Clock,
  Play,
  Pause,
  Square,
  Eye,
  EyeOff,
  MoreVertical,
  ArrowDown,
  ArrowUp,
  FileText,
  AlertCircle,
  HelpCircle,
  Download,
  Upload,
  Share2,
  Monitor,
  UserCheck,
  AlertTriangle,
  ChevronRight,
  Terminal,
  PlayCircle,
  Briefcase,
  X
} from 'lucide-react';

import api from '../../services/api.service';
import DateFormatter from '../../components/shared/DateFormatter';
/**
 * Page de gestion des tâches d'automatisation
 */
const TasksPage = () => {
  // États pour les tâches
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [detailTab, setDetailTab] = useState(0);
  const [viewMode, setViewMode] = useState('table');
  
  // États pour les filtres
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [functionFilter, setFunctionFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const [userId] = useState(() => {
    return localStorage.getItem('user_id') || sessionStorage.getItem('user_id') || 'default-user';
  });
  // État pour le tri
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdDate');
  
  // États pour les dialogues
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [openRunDialog, setOpenRunDialog] = useState(false);
  
  // États pour les données de référence
  const [functions, setFunctions] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [loadingInteractions, setLoadingInteractions] = useState(false);
  
  // États d'exécution
  const [executionStatus, setExecutionStatus] = useState(null);
  const [executionProgress, setExecutionProgress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [executionLogs, setExecutionLogs] = useState([]);
  const [executedInteractions, setExecutedInteractions] = useState([]);
  const [reportReady, setReportReady] = useState(false);
  const [reportData, setReportData] = useState(null);
  
  // État pour les notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // État pour le formulaire
  const [formValues, setFormValues] = useState({
    taskName: '',
    taskDescription: '',
    taskScenario: '',
    login: '',
    password: '',
    notes: '',
    reserves: '',
    expectedResult: '',
    taskPriority: 'MEDIUM',
    taskType: 'UNDEFINED',
    taskNature: 'UNDEFINED',
    executionType: 'MANUAL',
    function: { id: '' }
  });
  
  // Chargement initial des données
  useEffect(() => {
    fetchFunctions();
    fetchTasks();
  }, []);
  
  // Application des filtres quand les tâches ou les filtres changent
  useEffect(() => {
    applyFilters();
  }, [tasks, searchQuery, statusFilter, functionFilter, typeFilter, order, orderBy]);
  
  // Fonction pour charger les fonctions
  const fetchFunctions = async () => {
    try {
      const response = await api.automata.functions.getAll();
      setFunctions(response);
    } catch (error) {
      console.error('Erreur lors du chargement des fonctions:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des fonctions',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour charger les tâches
  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await api.automata.tasks.getAll();
      setTasks(response);
      setTotalCount(response.length);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors du chargement des tâches:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des tâches',
        severity: 'error'
      });
      setLoading(false);
    }
  };
  
  // Fonction pour charger les tâches pour une fonction spécifique
  const fetchTasksByFunction = async (functionId) => {
    setLoading(true);
    try {
      const response = await api.automata.tasks.getByFunction(functionId);
      setTasks(response);
      setTotalCount(response.length);
      setLoading(false);
    } catch (error) {
      console.error(`Erreur lors du chargement des tâches pour la fonction ${functionId}:`, error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des tâches',
        severity: 'error'
      });
      setLoading(false);
    }
  };
  
  // Fonction pour charger les interactions d'une tâche
  const fetchInteractions = async (taskId) => {
    setLoadingInteractions(true);
    try {
      const response = await api.automata.execution.getInteractions(taskId);
      setInteractions(response);
      setLoadingInteractions(false);
    } catch (error) {
      console.error(`Erreur lors du chargement des interactions pour la tâche ${taskId}:`, error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des interactions',
        severity: 'error'
      });
      setLoadingInteractions(false);
    }
  };
  
  // Fonction pour appliquer les filtres
  const applyFilters = () => {
    let filtered = [...tasks];
    
    // Filtre par statut
    if (statusFilter !== 'all') {
      filtered = filtered.filter(task => task.executionStatus === statusFilter);
    }
    
    // Filtre par fonction
    if (functionFilter !== 'all') {
      filtered = filtered.filter(task => task.function && task.function.id.toString() === functionFilter);
    }
    
    // Filtre par type
    if (typeFilter !== 'all') {
      filtered = filtered.filter(task => task.taskType === typeFilter);
    }
    
    // Recherche textuelle
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        task =>
          (task.taskName && task.taskName.toLowerCase().includes(lowerQuery)) ||
          (task.taskDescription && task.taskDescription.toLowerCase().includes(lowerQuery)) ||
          (task.login && task.login.toLowerCase().includes(lowerQuery)) ||
          (task.function && task.function.functionName && task.function.functionName.toLowerCase().includes(lowerQuery))
      );
    }
    
    // Tri
    filtered = sortTasks(filtered);
    
    setFilteredTasks(filtered);
  };
  
  // Fonction pour trier les tâches
  const sortTasks = (tasksToSort) => {
    return tasksToSort.sort((a, b) => {
      let aValue = a[orderBy];
      let bValue = b[orderBy];
      
      // Gestion des propriétés imbriquées
      if (orderBy === 'function.functionName') {
        aValue = a.function ? a.function.functionName : '';
        bValue = b.function ? b.function.functionName : '';
      }
      
      // Gestion des dates
      if (orderBy === 'createdDate' || orderBy === 'startExecutionTimestamp' || orderBy === 'endExecutionTimestamp') {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      }
      
      if (order === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    });
  };
  
  // Gestionnaire pour le tri
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  // Fonction pour changer de page
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  
  // Fonction pour changer le nombre de lignes par page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  
  // Gestionnaire pour ouvrir le menu
  const handleOpenMenu = (event, task) => {
    setSelectedTask(task);
    setAnchorEl(event.currentTarget);
  };
  
  // Gestionnaire pour fermer le menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  // Gestionnaire pour changer d'onglet dans les détails
  const handleTabChange = (event, newValue) => {
    setDetailTab(newValue);
    
    // Charger les interactions si l'onglet Interactions est sélectionné
    if (newValue === 1 && selectedTask) {
      fetchInteractions(selectedTask.id);
    }
  };
  
  // Fonction pour ouvrir le dialogue de création
  const handleOpenCreateDialog = () => {
    setFormValues({
      taskName: '',
      taskDescription: '',
      taskScenario: '',
      login: '',
      password: '',
      notes: '',
      reserves: '',
      expectedResult: '',
      taskPriority: 'MEDIUM',
      taskType: 'UNDEFINED',
      taskNature: 'UNDEFINED',
      executionType: 'MANUAL',
      function: { id: '' }
    });
    setOpenCreateDialog(true);
  };
  
  // Fonction pour ouvrir le dialogue d'édition
  const handleOpenEditDialog = (task) => {
    setSelectedTask(task);
    setFormValues({
      taskName: task.taskName || '',
      taskDescription: task.taskDescription || '',
      taskScenario: task.taskScenario || '',
      login: task.login || '',
      password: task.password || '',
      notes: task.notes || '',
      reserves: task.reserves || '',
      expectedResult: task.expectedResult || '',
      taskPriority: task.taskPriority || 'MEDIUM',
      taskType: task.taskType || 'UNDEFINED',
      taskNature: task.taskNature || 'UNDEFINED',
      executionType: task.executionType || 'MANUAL',
      function: { id: task.function?.id || '' }
    });
    setOpenEditDialog(true);
    handleCloseMenu();
  };
  
  // Fonction pour ouvrir le dialogue de suppression
  const handleOpenDeleteDialog = (task) => {
    setSelectedTask(task);
    setOpenDeleteDialog(true);
    handleCloseMenu();
  };
  
  // Fonction pour ouvrir le tiroir de détails
  const handleOpenDetailsDrawer = (task) => {
    setSelectedTask(task);
    setOpenDetailsDrawer(true);
    setDetailTab(0);
    handleCloseMenu();
  };
  
  // Fonction pour ouvrir le dialogue d'exécution
  const handleOpenRunDialog = (task) => {
    setSelectedTask(task);
    setOpenRunDialog(true);
    setExecutionStatus('READY');
    setExecutionProgress(0);
    setExecutionLogs([]);
    setExecutedInteractions([]);
    setReportReady(false);
    setReportData(null);
    handleCloseMenu();
  };
  
  // Fonction pour fermer les dialogues
  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenDetailsDrawer(false);
    setOpenRunDialog(false);
  };
  
  // Gestionnaire pour les changements de champ dans le formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'functionId') {
      setFormValues({ ...formValues, function: { id: value } });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  
  // Fonction pour créer une tâche
  const handleCreateTask = async () => {
    try {
      await api.automata.tasks.create(formValues);
      setSnackbar({
        open: true,
        message: 'Tâche créée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchTasks();
    } catch (error) {
      console.error('Erreur lors de la création de la tâche:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la création de la tâche',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour mettre à jour une tâche
  const handleUpdateTask = async () => {
    try {
      await api.automata.tasks.update(selectedTask.id, formValues);
      setSnackbar({
        open: true,
        message: 'Tâche mise à jour avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchTasks();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la mise à jour de la tâche',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour supprimer une tâche
  const handleDeleteTask = async () => {
    try {
      await api.automata.tasks.delete(selectedTask.id);
      setSnackbar({
        open: true,
        message: 'Tâche supprimée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchTasks();
    } catch (error) {
      console.error('Erreur lors de la suppression de la tâche:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la suppression de la tâche',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour ajouter un message au log d'exécution
  const addExecutionLog = (type, message) => {
    setExecutionLogs(prevLogs => [
      ...prevLogs, 
      {
        timestamp: new Date(),
        type: type, // 'info', 'error', 'warning', 'success', 'action', 'sent', 'received', 'status'
        message: message
      }
    ]);
  };

  // Fonction pour mettre à jour la progression basée sur les interactions
  const updateProgressBasedOnInteractions = () => {
    if (selectedTask && selectedTask.interactions && selectedTask.interactions.length > 0) {
      const totalInteractions = selectedTask.interactions.length;
      const completedInteractions = executedInteractions.length;
      const newProgress = Math.min(Math.round((completedInteractions / totalInteractions) * 100), 95);
      setExecutionProgress(newProgress);
    }
  };
  
  // Fonction pour exécuter une tâche
  const handleRunTask = async () => {
    try {
      // Initialiser l'état d'exécution
      setExecutionStatus('READY');
      setExecutionProgress(0);
      
      // Initialiser le tableau des logs d'exécution
      setExecutionLogs([{
        timestamp: new Date(),
        type: 'info',
        message: 'Préparation de l\'exécution...'
      }]);
      
      // Initialiser le tableau des interactions exécutées
      setExecutedInteractions([]);
      setReportReady(false);
      setReportData(null);
      
      // Établir la connexion WebSocket pour l'exécution
      const wsConnection = api.automata.execution.createWebSocket(userId);
      
      // Configurer les gestionnaires d'événements WebSocket
      wsConnection.addEventListener('message', (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Execution event received:', data);
          
          // Ajouter le message au log d'exécution
          addExecutionLog('received', `Message reçu: ${data.type || 'Unknown'}`);
          
          if (data.type === 'EXECUTION_STATUS') {
            // Mettre à jour le statut d'exécution
            setExecutionStatus(data.executionStatus || 'IN_PROGRESS');
            
            // Mettre à jour la progression si disponible
            if (data.progress) {
              setExecutionProgress(data.progress);
            }
            
            // Extraire et afficher les informations de statut
            if (data.interaction && data.interaction.technicalProperties) {
              const props = data.interaction.technicalProperties;
              if (props.name) {
                addExecutionLog('status', `Statut: ${props.name}: ${props.status || ''}`);
              }
            }
            
            // Si l'exécution est terminée
            if (data.executionStatus === 'COMPLETED' || 
                data.executionStatus === 'SUCCESS') {
              setExecutionStatus('COMPLETED');
              setExecutionProgress(100);
              
              addExecutionLog('success', 'Exécution terminée avec succès');
              
              setSnackbar({
                open: true,
                message: 'Exécution terminée avec succès',
                severity: 'success'
              });
              
              // Générer le rapport d'exécution
              prepareExecutionReport();
            }
            // Si l'exécution a échoué
            else if (data.executionStatus === 'FAILURE' || 
                    data.executionStatus === 'FAILED' ||
                    data.executionStatus === 'ERROR') {
              setExecutionStatus('FAILED');
              
              addExecutionLog('error', `Erreur d'exécution: ${data.message || 'Échec de l\'exécution'}`);
              
              setSnackbar({
                open: true,
                message: `Erreur d'exécution: ${data.message || 'Échec de l\'exécution'}`,
                severity: 'error'
              });
            }
          }
          
          // Traitement de l'action exécutée
          else if (data.type === 'ACTION_EXECUTED' || data.type === 'RUN_ACTION_EXECUTED') {
            // Ajouter l'interaction au log d'exécution
            if (data.interaction) {
              const interaction = data.interaction;
              const props = interaction.technicalProperties || {};
              
              // Ajouter aux interactions exécutées
              setExecutedInteractions(prev => [...prev, interaction]);
              
              // Extraire les informations de l'action
              const actionType = props.actionType || props.nodeType || 'Action';
              const actionValue = props.actionValue || props.value || '';
              addExecutionLog('action', `Action exécutée: ${actionType} - ${actionValue}`);
              
              // Mettre à jour la progression
              updateProgressBasedOnInteractions();
            }
          }
          
          // Traitement de l'état de l'interface
          else if (data.type === 'RUN_STATE') {
            if (data.state) {
              const state = data.state;
              
              // Extraire les informations d'état intéressantes
              if (state.userInterface) {
                const ui = state.userInterface;
                
                // Extraire le focus actuel
                if (ui.focus) {
                  addExecutionLog('info', `Focus sur: ${ui.focus.name || ui.focus.nodeName || 'Élément inconnu'}`);
                }
                
                // Extraire les messages/erreurs
                if (ui.error) {
                  addExecutionLog('error', `Erreur UI: ${ui.error.text}`);
                }
              }
            }
          }
          
        } catch (error) {
          console.error('Error processing execution event:', error);
          addExecutionLog('error', `Erreur de traitement: ${error.message}`);
        }
      });
      
      wsConnection.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
        setExecutionStatus('FAILED');
        
        addExecutionLog('error', `Erreur de connexion WebSocket: ${error.message || 'Erreur inconnue'}`);
        
        setSnackbar({
          open: true,
          message: 'Erreur de connexion WebSocket',
          severity: 'error'
        });
      });
      
      wsConnection.addEventListener('close', () => {
        console.log('WebSocket connection closed');
        addExecutionLog('info', 'Connexion WebSocket fermée');
        
        if (executionStatus === 'IN_PROGRESS') {
          setExecutionStatus('STOPPED');
          addExecutionLog('warning', 'Exécution arrêtée (connexion fermée)');
        }
      });
      
      // Attendre que la connexion WebSocket s'établisse
      await new Promise(resolve => setTimeout(resolve, 500));
      
      addExecutionLog('info', 'Connexion WebSocket établie');
      
      // Envoyer la commande de démarrage via WebSocket
      addExecutionLog('sent', 'Envoi de la commande de démarrage...');
      
      const startSuccess = api.automata.execution.sendWebSocketMessage(userId, {
        type: "RUN_START",
        task: {
          id: selectedTask.id
        }
      });
      
      if (!startSuccess) {
        throw new Error("Échec de l'envoi de la commande de démarrage d'exécution");
      }
      
      setExecutionStatus('IN_PROGRESS');
      addExecutionLog('info', 'Exécution démarrée');
      
      setSnackbar({
        open: true,
        message: 'Exécution de la tâche lancée avec succès',
        severity: 'success'
      });
      
    } catch (error) {
      console.error('Erreur lors de l\'exécution de la tâche:', error);
      setExecutionStatus('FAILED');
      
      addExecutionLog('error', `Erreur: ${error.message}`);
      
      setSnackbar({
        open: true,
        message: `Erreur lors de l'exécution de la tâche: ${error.message}`,
        severity: 'error'
      });
      
      // Fermer la connexion WebSocket en cas d'erreur
      api.automata.execution.closeConnection(userId);
    }
  };

  // Ajouter une fonction pour arrêter proprement l'exécution
  const handleStopExecution = async () => {
    try {
      // Envoyer la commande d'arrêt via WebSocket
      addExecutionLog('sent', 'Envoi de la commande d\'arrêt...');
      
      const stopSuccess = api.automata.execution.sendWebSocketMessage(userId, {
        type: "RUN_STOP"
      });
      
      if (!stopSuccess) {
        throw new Error("Échec de l'envoi de la commande d'arrêt d'exécution");
      }
      
      setExecutionStatus('STOPPED');
      addExecutionLog('warning', 'Exécution arrêtée manuellement');
      
      setSnackbar({
        open: true,
        message: 'Exécution de la tâche arrêtée',
        severity: 'info'
      });
    } catch (error) {
      console.error('Erreur lors de l\'arrêt de la tâche:', error);
      addExecutionLog('error', `Erreur d'arrêt: ${error.message}`);
      
      setSnackbar({
        open: true,
        message: `Erreur lors de l'arrêt de la tâche: ${error.message}`,
        severity: 'error'
      });
    }
  };

  // Fonction pour nettoyer les ressources WebSocket à la fermeture du dialogue
  const handleCloseExecutionDialog = () => {
    // Si une exécution est en cours, l'arrêter proprement
    if (executionStatus === 'IN_PROGRESS') {
      handleStopExecution();
    }
    
    // Fermer la connexion WebSocket
    api.automata.execution.closeConnection(userId);
    
    // Fermer le dialogue
    handleCloseDialog();
  };

  // Fonction pour préparer le rapport d'exécution
  const prepareExecutionReport = () => {
    const report = {
      task: selectedTask,
      executionDate: new Date().toISOString(),
      status: executionStatus,
      duration: calculateExecutionDuration(),
      logs: executionLogs,
      interactions: executedInteractions
    };
    
    setReportData(report);
    setReportReady(true);
    addExecutionLog('info', 'Rapport d\'exécution prêt à être téléchargé');
  };

  // Fonction pour calculer la durée d'exécution
  const calculateExecutionDuration = () => {
    if (executionLogs.length < 2) return '0s';
    
    const startTime = executionLogs[0].timestamp;
    const endTime = executionLogs[executionLogs.length - 1].timestamp;
    
    return formatDuration(endTime - startTime);
  };

  // Fonction pour télécharger le rapport
  const downloadExecutionReport = () => {
    if (!reportData) return;
    
    const reportContent = generateReportContent(reportData);
    const blob = new Blob([reportContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = `execution-report-${selectedTask.id}-${new Date().toISOString().replace(/:/g, '-')}.html`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Fonction pour générer le contenu HTML du rapport
  const generateReportContent = (data) => {
    return `<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>Rapport d'exécution - ${data.task.taskName}</title>
  <style>
    body { font-family: Arial, sans-serif; margin: 20px; line-height: 1.6; }
    .header { background: #f5f5f5; padding: 20px; border-radius: 5px; margin-bottom: 20px; }
    .section { margin-bottom: 30px; border-bottom: 1px solid #eee; padding-bottom: 20px; }
    .section-title { font-size: 18px; font-weight: bold; margin-bottom: 10px; color: #333; }
    .info-row { display: flex; margin-bottom: 8px; }
    .info-label { font-weight: bold; width: 200px; }
    .info-value { flex: 1; }
    .log-table { width: 100%; border-collapse: collapse; }
    .log-table th, .log-table td { border: 1px solid #ddd; padding: 8px; text-align: left; }
    .log-table th { background-color: #f2f2f2; }
    .log-row-info { background-color: #e8f4fd; }
    .log-row-error { background-color: #ffebee; }
    .log-row-warning { background-color: #fff8e1; }
    .log-row-success { background-color: #e8f5e9; }
    .log-row-action { background-color: #f3e5f5; }
    .log-row-sent { background-color: #e8eaf6; }
    .log-row-received { background-color: #e0f2f1; }
    .log-row-status { background-color: #fce4ec; }
  </style>
</head>
<body>
  <div class="header">
    <h1>Rapport d'exécution</h1>
    <div class="info-row">
      <div class="info-label">Tâche:</div>
      <div class="info-value">${data.task.taskName} (ID: ${data.task.id})</div>
    </div>
    <div class="info-row">
      <div class="info-label">Date d'exécution:</div>
      <div class="info-value">${new Date(data.executionDate).toLocaleString()}</div>
    </div>
    <div class="info-row">
      <div class="info-label">Statut:</div>
      <div class="info-value">${data.status}</div>
    </div>
    <div class="info-row">
      <div class="info-label">Durée:</div>
      <div class="info-value">${data.duration}</div>
    </div>
    <div class="info-row">
      <div class="info-label">Fonction:</div>
      <div class="info-value">${data.task.function ? data.task.function.functionName : 'N/A'}</div>
    </div>
  </div>
  
  <div class="section">
    <div class="section-title">Description de la tâche</div>
    <p>${data.task.taskDescription || 'Aucune description'}</p>
    <div class="info-row">
      <div class="info-label">Login utilisé:</div>
      <div class="info-value">${data.task.login || 'N/A'}</div>
    </div>
  </div>
  
  <div class="section">
    <div class="section-title">Journal d'exécution</div>
    <table class="log-table">
      <thead>
        <tr>
          <th>Heure</th>
          <th>Type</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        ${data.logs.map(log => `
          <tr class="log-row-${log.type}">
            <td>${new Date(log.timestamp).toLocaleTimeString()}</td>
            <td>${log.type}</td>
            <td>${log.message}</td>
          </tr>
        `).join('')}
      </tbody>
    </table>
  </div>
  
  <div class="section">
    <div class="section-title">Interactions exécutées</div>
    <table class="log-table">
      <thead>
        <tr>
          <th>Séquence</th>
          <th>Type</th>
          <th>Direction</th>
          <th>Statut</th>
          <th>Détails</th>
        </tr>
      </thead>
      <tbody>
        ${data.interactions.map((interaction, index) => {
          const props = interaction.technicalProperties || {};
          return `
            <tr>
              <td>${interaction.sequence || index + 1}</td>
              <td>${interaction.type || 'N/A'}</td>
              <td>${interaction.direction || 'N/A'}</td>
              <td>${interaction.status || 'N/A'}</td>
              <td>${props.actionType || props.nodeType || ''} ${props.actionValue || props.value || ''}</td>
            </tr>
          `;
        }).join('')}
      </tbody>
    </table>
  </div>
  
  <div class="footer">
    <p>Rapport généré le ${new Date().toLocaleString()}</p>
  </div>
</body>
</html>`;
  };
  
  // Fonction pour dupliquer une tâche
  const handleDuplicateTask = async (task) => {
    try {
      const newTask = {
        ...task,
        taskName: `Copie de ${task.taskName}`,
        id: undefined
      };
      
      await api.automata.tasks.create(newTask);
      setSnackbar({
        open: true,
        message: 'Tâche dupliquée avec succès',
        severity: 'success'
      });
      fetchTasks();
      handleCloseMenu();
    } catch (error) {
      console.error('Erreur lors de la duplication de la tâche:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la duplication de la tâche',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour obtenir la couleur du statut d'exécution
  const getStatusColor = (status) => {
    switch (status) {
      case 'COMPLETED':
      case 'SUCCESS':
        return 'success';
      case 'PENDING':
      case 'TO_EXECUTE':
      case 'ARBITRATED':
        return 'info';
      case 'IN_PROGRESS':
        return 'primary';
      case 'FAILURE':
      case 'BLOCKED':
        return 'error';
      case 'STOPPED':
        return 'warning';
      case 'NOT_TESTABLE':
        return 'default';
      default:
        return 'default';
    }
  };
  
  // Fonction pour obtenir l'icône du statut d'exécution
  const getStatusIcon = (status) => {
    switch (status) {
      case 'COMPLETED':
      case 'SUCCESS':
        return <CheckCircle size={16} />;
      case 'PENDING':
      case 'TO_EXECUTE':
        return <Clock size={16} />;
      case 'IN_PROGRESS':
        return <Play size={16} />;
      case 'FAILURE':
        return <XCircle size={16} />;
      case 'BLOCKED':
        return <AlertCircle size={16} />;
      case 'STOPPED':
        return <Square size={16} />;
      case 'ARBITRATED':
        return <UserCheck size={16} />;
      case 'NOT_TESTABLE':
        return <EyeOff size={16} />;
      default:
        return <HelpCircle size={16} />;
    }
  };
  
  // Rendu des tâches en mode tableau
  const renderTasksTable = () => {
    const paginatedTasks = filteredTasks.slice(
      currentPage * rowsPerPage,
      currentPage * rowsPerPage + rowsPerPage
    );
    
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (filteredTasks.length === 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Typography variant="h6" color="textSecondary">
            Aucune tâche trouvée
          </Typography>
        </Box>
      );
    }
    
    return (
      <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'taskName'}
                  direction={orderBy === 'taskName' ? order : 'asc'}
                  onClick={() => handleRequestSort('taskName')}
                >
                  Nom
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'function.functionName'}
                  direction={orderBy === 'function.functionName' ? order : 'asc'}
                  onClick={() => handleRequestSort('function.functionName')}
                >
                  Fonction
                </TableSortLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Nature</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'executionStatus'}
                  direction={orderBy === 'executionStatus' ? order : 'asc'}
                  onClick={() => handleRequestSort('executionStatus')}
                >
                  Statut
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'createdDate'}
                  direction={orderBy === 'createdDate' ? order : 'asc'}
                  onClick={() => handleRequestSort('createdDate')}
                >
                  Date de création
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'startExecutionTimestamp'}
                  direction={orderBy === 'startExecutionTimestamp' ? order : 'asc'}
                  onClick={() => handleRequestSort('startExecutionTimestamp')}
                >
                  Dernière exécution
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTasks.map(task => (
              <TableRow
                key={task.id}
                hover
                onClick={() => handleOpenDetailsDrawer(task)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{task.id}</TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight="medium">
                    {task.taskName}
                  </Typography>
                  {task.taskDescription && (
                    <Typography variant="caption" color="textSecondary" noWrap>
                      {task.taskDescription}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {task.function ? (
                    <Chip
                      size="small"
                      label={task.function.functionName}
                      color="primary"
                      variant="outlined"
                    />
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>{task.taskType || '-'}</TableCell>
                <TableCell>{task.taskNature || '-'}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    icon={getStatusIcon(task.executionStatus)}
                    label={task.executionStatus || 'PENDING'}
                    color={getStatusColor(task.executionStatus)}
                  />
                </TableCell>
                <TableCell>
                  <DateFormatter
                    date={task.createdDate}
                    format="relative"
                  />
                </TableCell>
                <TableCell>
                  {task.startExecutionTimestamp ? (
                    <DateFormatter
                      date={task.startExecutionTimestamp}
                      format="relative"
                    />
                  ) : (
                    'Jamais'
                  )}
                </TableCell>
                <TableCell align="center">
                  <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenRunDialog(task);
                      }}
                    >
                      <PlayCircle size={18} />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenMenu(e, task);
                      }}
                    >
                      <MoreVertical size={18} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  // Rendu des tâches en mode grille
  const renderTasksGrid = () => {
    const paginatedTasks = filteredTasks.slice(
      currentPage * rowsPerPage,
      currentPage * rowsPerPage + rowsPerPage
    );
    
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (filteredTasks.length === 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Typography variant="h6" color="textSecondary">
            Aucune tâche trouvée
          </Typography>
        </Box>
      );
    }
    
    return (
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {paginatedTasks.map(task => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={task.id}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4
                }
              }}
              onClick={() => handleOpenDetailsDrawer(task)}
            >
              <CardContent sx={{ flexGrow: 1, pb: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="subtitle1" component="div" noWrap>
                    {task.taskName}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenMenu(e, task);
                    }}
                  >
                    <MoreVertical size={18} />
                  </IconButton>
                </Box>
                
                {task.taskDescription && (
                  <Typography 
                    variant="body2" 
                    color="textSecondary" 
                    sx={{ 
                      mb: 2, 
                      display: '-webkit-box',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical'
                    }}
                  >
                    {task.taskDescription}
                  </Typography>
                )}
                
                <Grid container spacing={1} sx={{ mb: 2 }}>
                  {task.function && (
                    <Grid item>
                      <Chip 
                        size="small" 
                        label={task.function.functionName}
                        color="primary"
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  
                  {task.taskType && task.taskType !== 'UNDEFINED' && (
                    <Grid item>
                      <Chip 
                        size="small" 
                        label={task.taskType}
                        color="secondary"
                        variant="outlined"
                      />
                    </Grid>
                  )}
                </Grid>
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Chip
                    size="small"
                    icon={getStatusIcon(task.executionStatus)}
                    label={task.executionStatus || 'PENDING'}
                    color={getStatusColor(task.executionStatus)}
                  />
                  
                  <Typography variant="caption" color="textSecondary">
                    {task.createdDate ? new Date(task.createdDate).toLocaleDateString() : ''}
                  </Typography>
                </Box>
              </CardContent>
              
              <Divider />
              
              <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="caption" color="textSecondary">
                  ID: {task.id}
                </Typography>
                
                <Tooltip title="Exécuter">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenRunDialog(task);
                    }}
                  >
                    <PlayCircle size={18} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  // Rendu des interactions d'une tâche
  const renderInteractions = () => {
    if (loadingInteractions) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (interactions.length === 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Typography variant="body1" color="textSecondary">
            Aucune interaction trouvée pour cette tâche
          </Typography>
        </Box>
      );
    }
    
    return (
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {interactions.map((interaction, index) => (
          <Accordion key={interaction.id || index} sx={{ mb: 1 }}>
            <AccordionSummary
              expandIcon={<ChevronDown />}
              aria-controls={`interaction-${index}-content`}
              id={`interaction-${index}-header`}
            >
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <Chip size="small" label={interaction.sequence || index + 1} />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {interaction.type}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Chip
                    size="small"
                    label={interaction.status}
                    color={getStatusColor(interaction.status)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">
                    {interaction.timestamp ? new Date(interaction.timestamp).toLocaleTimeString() : ''}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" color="textSecondary">
                    {interaction.direction}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Propriétés techniques
                </Typography>
                
                {interaction.technicalProperties && Object.entries(interaction.technicalProperties).map(([key, value]) => (
                  <Box key={key} sx={{ display: 'flex', mb: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '150px' }}>
                      {key}:
                    </Typography>
                    <Typography variant="body2">
                      {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    );
  };

  // Rendu des logs d'exécution
  const renderExecutionLogs = () => {
    if (executionLogs.length === 0) {
      return (
        <Typography variant="body2" color="textSecondary" sx={{ p: 2, fontStyle: 'italic' }}>
          En attente de démarrage...
        </Typography>
      );
    }

    return executionLogs.map((log, index) => (
      <Box 
        key={index}
        sx={{ 
          py: 0.5,
          borderBottom: '1px dashed #e0e0e0',
          color: log.type === 'error' ? 'error.main' : 
                log.type === 'warning' ? 'warning.main' : 
                log.type === 'success' ? 'success.main' : 
                log.type === 'action' ? 'secondary.main' : 
                log.type === 'sent' ? 'info.main' : 
                log.type === 'received' ? 'primary.main' : 
                log.type === 'status' ? 'text.secondary' : 'text.primary'
        }}
      >
        <Box component="span" sx={{ mr: 1, color: 'text.secondary' }}>
          [{new Date(log.timestamp).toLocaleTimeString()}]
        </Box>
        {log.message}
      </Box>
    ));
  };

  // Rendu du tableau des interactions exécutées
  const renderExecutedInteractions = () => {
    if (executedInteractions.length === 0) {
      return (
        <Typography variant="body2" color="textSecondary" sx={{ p: 2, fontStyle: 'italic' }}>
          Aucune interaction exécutée pour le moment...
        </Typography>
      );
    }

    return (
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Séq.</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {executedInteractions.map((interaction, index) => {
              const props = interaction.technicalProperties || {};
              return (
                <TableRow key={index}>
                  <TableCell>{interaction.sequence || index + 1}</TableCell>
                  <TableCell>{interaction.type || 'N/A'}</TableCell>
                  <TableCell>
                    {props.actionType || props.nodeType || ''} {props.actionValue || props.value || ''}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      size="small" 
                      label={interaction.status || 'N/A'} 
                      color={getStatusColor(interaction.status)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  // Rendu principal
  return (
    <Box sx={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Briefcase size={24} />
          Gestion des Tâches
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={handleOpenCreateDialog}
          >
            Nouvelle Tâche
          </Button>
          
          <IconButton 
            color="primary" 
            onClick={fetchTasks}
            title="Rafraîchir"
          >
            <RefreshCw />
          </IconButton>
          
          <IconButton
            color={viewMode === 'table' ? 'primary' : 'default'}
            onClick={() => setViewMode('table')}
            title="Vue tableau"
          >
            <ListIcon />
          </IconButton>
          
          <IconButton
            color={viewMode === 'grid' ? 'primary' : 'default'}
            onClick={() => setViewMode('grid')}
            title="Vue grille"
          >
            <GridIcon />
          </IconButton>
        </Box>
      </Paper>
      
      {/* Filtres et recherche */}
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant="outlined"
              label="Rechercher"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                )
              }}
              size="small"
            />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Fonction</InputLabel>
              <Select
                value={functionFilter}
                onChange={(e) => setFunctionFilter(e.target.value)}
                label="Fonction"
              >
                <MenuItem value="all">Toutes les fonctions</MenuItem>
                {functions.map((func) => (
                  <MenuItem key={func.id} value={func.id.toString()}>
                    {func.functionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Statut</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Statut"
              >
                <MenuItem value="all">Tous</MenuItem>
                <MenuItem value="PENDING">En attente</MenuItem>
                <MenuItem value="IN_PROGRESS">En cours</MenuItem>
                <MenuItem value="COMPLETED">Terminé</MenuItem>
                <MenuItem value="FAILURE">Échec</MenuItem>
                <MenuItem value="BLOCKED">Bloqué</MenuItem>
                <MenuItem value="STOPPED">Arrêté</MenuItem>
                <MenuItem value="SUCCESS">Réussi</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                label="Type"
              >
                <MenuItem value="all">Tous</MenuItem>
                <MenuItem value="ACCEPTABILITY">Acceptabilité</MenuItem>
                <MenuItem value="CORRECTIVE">Corrective</MenuItem>
                <MenuItem value="EVOLUTION">Évolution</MenuItem>
                <MenuItem value="NON_REGRESSION">Non-régression</MenuItem>
                <MenuItem value="END_TO_END">Bout en bout</MenuItem>
                <MenuItem value="PARTNER">Partenaire</MenuItem>
                <MenuItem value="UNDEFINED">Non défini</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Contenu principal */}
      <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
        {viewMode === 'table' ? renderTasksTable() : renderTasksGrid()}
      </Box>
      
      {/* Pagination */}
      <Paper sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body2" color="textSecondary">
          {filteredTasks.length} tâches trouvées sur {totalCount} au total
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControl size="small" variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Tâches par page</InputLabel>
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              label="Tâches par page"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              size="small"
              disabled={currentPage === 0}
              onClick={() => handleChangePage(null, currentPage - 1)}
              startIcon={<ArrowUp />}
            >
              Précédent
            </Button>
            <Button
              variant="outlined"
              size="small"
              disabled={(currentPage + 1) * rowsPerPage >= filteredTasks.length}
              onClick={() => handleChangePage(null, currentPage + 1)}
              endIcon={<ArrowDown />}
            >
              Suivant
            </Button>
          </Box>
        </Box>
      </Paper>
      
      {/* Menu contextuel */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleOpenDetailsDrawer(selectedTask)}>
          <ListItemIcon>
            <Eye size={18} />
          </ListItemIcon>
          <ListItemText primary="Voir les détails" />
        </MenuItem>
        
        <MenuItem onClick={() => handleOpenRunDialog(selectedTask)}>
          <ListItemIcon>
            <PlayCircle size={18} />
          </ListItemIcon>
          <ListItemText primary="Exécuter" />
        </MenuItem>
        
        <MenuItem onClick={() => handleOpenEditDialog(selectedTask)}>
          <ListItemIcon>
            <Edit2 size={18} />
          </ListItemIcon>
          <ListItemText primary="Modifier" />
        </MenuItem>
        
        <MenuItem onClick={() => handleDuplicateTask(selectedTask)}>
          <ListItemIcon>
            <Copy size={18} />
          </ListItemIcon>
          <ListItemText primary="Dupliquer" />
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={() => handleOpenDeleteDialog(selectedTask)}>
          <ListItemIcon>
            <Trash2 size={18} color="error" />
          </ListItemIcon>
          <ListItemText primary="Supprimer" sx={{ color: 'error.main' }} />
        </MenuItem>
      </Menu>
      
      {/* Dialogue de création */}
      <Dialog
        open={openCreateDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Plus size={20} />
            Créer une nouvelle tâche
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Tabs
              value={detailTab}
              onChange={handleTabChange}
              sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Informations générales" />
              <Tab label="Propriétés avancées" />
            </Tabs>
            
            {detailTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Nom de la tâche"
                    name="taskName"
                    value={formValues.taskName}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Fonction</InputLabel>
                    <Select
                      name="functionId"
                      value={formValues.function.id}
                      onChange={handleInputChange}
                      label="Fonction"
                    >
                      {functions.map((func) => (
                        <MenuItem key={func.id} value={func.id}>
                          {func.functionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="taskDescription"
                    value={formValues.taskDescription}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Scénario"
                    name="taskScenario"
                    value={formValues.taskScenario}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Login"
                    name="login"
                    value={formValues.login}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Mot de passe"
                    name="password"
                    type="password"
                    value={formValues.password}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Résultat attendu"
                    name="expectedResult"
                    value={formValues.expectedResult}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
            )}
            
            {detailTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Type de tâche</InputLabel>
                    <Select
                      name="taskType"
                      value={formValues.taskType}
                      onChange={handleInputChange}
                      label="Type de tâche"
                    >
                      <MenuItem value="UNDEFINED">Non défini</MenuItem>
                      <MenuItem value="ACCEPTABILITY">Acceptabilité</MenuItem>
                      <MenuItem value="CORRECTIVE">Corrective</MenuItem>
                      <MenuItem value="EVOLUTION">Évolution</MenuItem>
                      <MenuItem value="NON_REGRESSION">Non-régression</MenuItem>
                      <MenuItem value="END_TO_END">Bout en bout</MenuItem>
                      <MenuItem value="PARTNER">Partenaire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Nature</InputLabel>
                    <Select
                      name="taskNature"
                      value={formValues.taskNature}
                      onChange={handleInputChange}
                      label="Nature"
                    >
                      <MenuItem value="UNDEFINED">Non définie</MenuItem>
                      <MenuItem value="FUNCTIONAL">Fonctionnelle</MenuItem>
                      <MenuItem value="BUSINESS">Métier</MenuItem>
                      <MenuItem value="USER">Utilisateur</MenuItem>
                      <MenuItem value="NON_FUNCTIONAL">Non fonctionnelle</MenuItem>
                      <MenuItem value="PERFORMANCE">Performance</MenuItem>
                      <MenuItem value="SECURITY">Sécurité</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Priorité</InputLabel>
                    <Select
                      name="taskPriority"
                      value={formValues.taskPriority}
                      onChange={handleInputChange}
                      label="Priorité"
                    >
                      <MenuItem value="VERY_HIGH">Très haute</MenuItem>
                      <MenuItem value="HIGH">Haute</MenuItem>
                      <MenuItem value="MEDIUM">Moyenne</MenuItem>
                      <MenuItem value="LOW">Basse</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Type d'exécution</InputLabel>
                    <Select
                      name="executionType"
                      value={formValues.executionType}
                      onChange={handleInputChange}
                      label="Type d'exécution"
                    >
                      <MenuItem value="MANUAL">Manuelle</MenuItem>
                      <MenuItem value="AUTOMATIC">Automatique</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Notes"
                    name="notes"
                    value={formValues.notes}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Réserves"
                    name="reserves"
                    value={formValues.reserves}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateTask}
            disabled={!formValues.taskName || !formValues.function.id}
          >
            Créer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'édition */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Edit2 size={20} />
            Modifier la tâche
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Tabs
              value={detailTab}
              onChange={handleTabChange}
              sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Informations générales" />
              <Tab label="Propriétés avancées" />
            </Tabs>
            
            {detailTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Nom de la tâche"
                    name="taskName"
                    value={formValues.taskName}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Fonction</InputLabel>
                    <Select
                      name="functionId"
                      value={formValues.function.id}
                      onChange={handleInputChange}
                      label="Fonction"
                    >
                      {functions.map((func) => (
                        <MenuItem key={func.id} value={func.id}>
                          {func.functionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="taskDescription"
                    value={formValues.taskDescription}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Scénario"
                    name="taskScenario"
                    value={formValues.taskScenario}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Login"
                    name="login"
                    value={formValues.login}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Mot de passe"
                    name="password"
                    type="password"
                    value={formValues.password}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Résultat attendu"
                    name="expectedResult"
                    value={formValues.expectedResult}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
            )}
            
            {detailTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Type de tâche</InputLabel>
                    <Select
                      name="taskType"
                      value={formValues.taskType}
                      onChange={handleInputChange}
                      label="Type de tâche"
                    >
                      <MenuItem value="UNDEFINED">Non défini</MenuItem>
                      <MenuItem value="ACCEPTABILITY">Acceptabilité</MenuItem>
                      <MenuItem value="CORRECTIVE">Corrective</MenuItem>
                      <MenuItem value="EVOLUTION">Évolution</MenuItem>
                      <MenuItem value="NON_REGRESSION">Non-régression</MenuItem>
                      <MenuItem value="END_TO_END">Bout en bout</MenuItem>
                      <MenuItem value="PARTNER">Partenaire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Nature</InputLabel>
                    <Select
                      name="taskNature"
                      value={formValues.taskNature}
                      onChange={handleInputChange}
                      label="Nature"
                    >
                      <MenuItem value="UNDEFINED">Non définie</MenuItem>
                      <MenuItem value="FUNCTIONAL">Fonctionnelle</MenuItem>
                      <MenuItem value="BUSINESS">Métier</MenuItem>
                      <MenuItem value="USER">Utilisateur</MenuItem>
                      <MenuItem value="NON_FUNCTIONAL">Non fonctionnelle</MenuItem>
                      <MenuItem value="PERFORMANCE">Performance</MenuItem>
                      <MenuItem value="SECURITY">Sécurité</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Priorité</InputLabel>
                    <Select
                      name="taskPriority"
                      value={formValues.taskPriority}
                      onChange={handleInputChange}
                      label="Priorité"
                    >
                      <MenuItem value="VERY_HIGH">Très haute</MenuItem>
                      <MenuItem value="HIGH">Haute</MenuItem>
                      <MenuItem value="MEDIUM">Moyenne</MenuItem>
                      <MenuItem value="LOW">Basse</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Type d'exécution</InputLabel>
                    <Select
                      name="executionType"
                      value={formValues.executionType}
                      onChange={handleInputChange}
                      label="Type d'exécution"
                    >
                      <MenuItem value="MANUAL">Manuelle</MenuItem>
                      <MenuItem value="AUTOMATIC">Automatique</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Notes"
                    name="notes"
                    value={formValues.notes}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Réserves"
                    name="reserves"
                    value={formValues.reserves}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateTask}
            disabled={!formValues.taskName || !formValues.function.id}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer la tâche "{selectedTask?.taskName}" ?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Cette action est irréversible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteTask}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'exécution */}
      <Dialog
        open={openRunDialog}
        onClose={handleCloseExecutionDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PlayCircle size={20} />
            Exécution de la tâche
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedTask && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                {selectedTask.taskName}
              </Typography>
              
              {selectedTask.taskDescription && (
                <Typography variant="body2" color="textSecondary" paragraph>
                  {selectedTask.taskDescription}
                </Typography>
              )}
              
              <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
                <Chip
                  size="small"
                  label={selectedTask.function?.functionName || 'Sans fonction'}
                  color="primary"
                  variant="outlined"
                />
                
                {selectedTask.taskType && selectedTask.taskType !== 'UNDEFINED' && (
                  <Chip
                    size="small"
                    label={selectedTask.taskType}
                    color="secondary"
                    variant="outlined"
                  />
                )}
              </Box>
              
              <Divider sx={{ mb: 3 }} />
              
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Statut d'exécution
                </Typography>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <Chip
                    icon={getStatusIcon(executionStatus)}
                    label={executionStatus || 'READY'}
                    color={getStatusColor(executionStatus)}
                  />
                  
                  {executionStatus === 'IN_PROGRESS' && (
                    <Typography variant="body2">
                      {executionProgress}%
                    </Typography>
                  )}
                </Box>
                
                {executionStatus === 'IN_PROGRESS' && (
                  <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="determinate" value={executionProgress} />
                  </Box>
                )}
                
                {executionStatus === 'COMPLETED' && (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    Exécution terminée avec succès
                  </Alert>
                )}
                
                {executionStatus === 'FAILED' && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    L'exécution a échoué
                  </Alert>
                )}
              </Box>
              
              {/* Console d'exécution - Nouvelle partie améliorée */}
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Terminal size={16} />
                  Console d'exécution
                </Typography>
                
                <Paper
                  variant="outlined"
                  sx={{
                    p: 1,
                    height: 250,
                    overflowY: 'auto',
                    bgcolor: '#f5f5f5',
                    fontFamily: 'monospace',
                    fontSize: '0.85rem'
                  }}
                >
                  {renderExecutionLogs()}
                </Paper>
              </Box>
              
              {/* Tableau des interactions exécutées */}
              {executedInteractions.length > 0 && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <List size={16} />
                    Interactions exécutées
                  </Typography>
                  
                  <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 200 }}>
                    {renderExecutedInteractions()}
                  </TableContainer>
                </Box>
              )}
              
              {/* Section rapport d'exécution */}
              {reportReady && (
                <Box sx={{ mt: 3 }}>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    <AlertTitle>Rapport d'exécution disponible</AlertTitle>
                    Le rapport complet de l'exécution est prêt à être téléchargé
                  </Alert>
                  
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Download size={16} />}
                    onClick={downloadExecutionReport}
                    fullWidth
                  >
                    Télécharger le rapport d'exécution
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseExecutionDialog}
            startIcon={<X size={16} />}
          >
            Fermer
          </Button>
          
          {executionStatus === 'READY' && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayCircle size={16} />}
              onClick={handleRunTask}
            >
              Exécuter
            </Button>
          )}
          
          {executionStatus === 'IN_PROGRESS' && (
            <Button
              variant="contained"
              color="warning"
              startIcon={<Square size={16} />}
              onClick={handleStopExecution}
            >
              Arrêter
            </Button>
          )}
        </DialogActions>
      </Dialog>
      
      {/* Tiroir de détails */}
      <Drawer
        anchor="right"
        open={openDetailsDrawer}
        onClose={handleCloseDialog}
        sx={{
          width: 600,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 600,
          },
        }}
      >
        {selectedTask && (
          <Box>
            <Box sx={{ p: 3, bgcolor: 'background.paper', position: 'sticky', top: 0, zIndex: 10 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5">Détails de la tâche</Typography>
                <IconButton onClick={handleCloseDialog}>
                  <X size={20} />
                </IconButton>
              </Box>
              
              <Typography variant="h6" gutterBottom>
                {selectedTask.taskName}
              </Typography>
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                <Chip
                  icon={getStatusIcon(selectedTask.executionStatus)}
                  label={selectedTask.executionStatus || 'PENDING'}
                  color={getStatusColor(selectedTask.executionStatus)}
                />
                
                {selectedTask.function && (
                  <Chip
                    icon={<Code size={16} />}
                    label={selectedTask.function.functionName}
                    color="primary"
                    variant="outlined"
                  />
                )}
                
                {selectedTask.taskType && selectedTask.taskType !== 'UNDEFINED' && (
                  <Chip
                    label={selectedTask.taskType}
                    color="secondary"
                    variant="outlined"
                  />
                )}
                
                {selectedTask.taskPriority && (
                  <Chip
                    label={selectedTask.taskPriority}
                    color="default"
                    variant="outlined"
                  />
                )}
              </Box>
              
              <Tabs
                value={detailTab}
                onChange={handleTabChange}
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab label="Informations" icon={<Info size={18} />} iconPosition="start" />
                <Tab label="Interactions" icon={<Terminal size={18} />} iconPosition="start" />
                <Tab label="Exécution" icon={<PlayCircle size={18} />} iconPosition="start" />
              </Tabs>
            </Box>
            
            <Box sx={{ p: 3 }}>
              {detailTab === 0 && (
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Description
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="body1">
                          {selectedTask.taskDescription || 'Aucune description fournie.'}
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Scénario
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="body1" whiteSpace="pre-line">
                          {selectedTask.taskScenario || 'Aucun scénario fourni.'}
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        Informations d'accès
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Login</Typography>
                            <Typography variant="body1">
                              {selectedTask.login || 'Non spécifié'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Mot de passe</Typography>
                            <Typography variant="body1">
                              {selectedTask.password ? '••••••••' : 'Non spécifié'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        Détails de l'exécution
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Type d'exécution</Typography>
                            <Typography variant="body1">
                              {selectedTask.executionType || 'MANUAL'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Dernière exécution</Typography>
                            <Typography variant="body1">
                              {selectedTask.startExecutionTimestamp ? (
                                new Date(selectedTask.startExecutionTimestamp).toLocaleString()
                              ) : (
                                'Jamais'
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Résultat attendu
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="body1" whiteSpace="pre-line">
                          {selectedTask.expectedResult || 'Aucun résultat attendu spécifié.'}
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    {selectedTask.notes && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                          Notes
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Typography variant="body1" whiteSpace="pre-line">
                            {selectedTask.notes}
                          </Typography>
                        </Paper>
                      </Grid>
                    )}
                    
                    {selectedTask.reserves && (
                      <Grid item xs={12}><Typography variant="subtitle1" gutterBottom>
                          Réserves
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Typography variant="body1" whiteSpace="pre-line">
                            {selectedTask.reserves}
                          </Typography>
                        </Paper>
                      </Grid>
                    )}
                    
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Métadonnées
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">ID</Typography>
                            <Typography variant="body1">
                              {selectedTask.id}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">Créé par</Typography>
                            <Typography variant="body1">
                              {selectedTask.createdBy || 'Inconnu'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">Date de création</Typography>
                            <Typography variant="body1">
                              {selectedTask.createdDate ? (
                                new Date(selectedTask.createdDate).toLocaleString()
                              ) : (
                                'Inconnue'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">Dernière modification</Typography>
                            <Typography variant="body1">
                              {selectedTask.modifiedDate ? (
                                new Date(selectedTask.modifiedDate).toLocaleString()
                              ) : (
                                'Aucune'
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              )}
              
              {detailTab === 1 && (
                <Box>
                  {renderInteractions()}
                </Box>
              )}
              
              {detailTab === 2 && (
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Exécution de la tâche
                      </Typography>
                      
                      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">Statut actuel</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                              <Chip
                                icon={getStatusIcon(selectedTask.executionStatus)}
                                label={selectedTask.executionStatus || 'PENDING'}
                                color={getStatusColor(selectedTask.executionStatus)}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">Type d'exécution</Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                              {selectedTask.executionType || 'MANUAL'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      
                      <Typography variant="subtitle1" gutterBottom>
                        Historique des exécutions
                      </Typography>
                      
                      {selectedTask.startExecutionTimestamp ? (
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Box sx={{ borderLeft: 3, borderColor: 'primary.main', pl: 2, py: 1, mb: 2 }}>
                            <Typography variant="subtitle2">
                              Dernière exécution
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {new Date(selectedTask.startExecutionTimestamp).toLocaleString()}
                            </Typography>
                          </Box>
                          
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2">Démarré</Typography>
                              <Typography variant="body1">
                                {new Date(selectedTask.startExecutionTimestamp).toLocaleString()}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2">Terminé</Typography>
                              <Typography variant="body1">
                                {selectedTask.endExecutionTimestamp ? (
                                  new Date(selectedTask.endExecutionTimestamp).toLocaleString()
                                ) : (
                                  'Non terminé'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2">Durée</Typography>
                              <Typography variant="body1">
                                {selectedTask.endExecutionTimestamp ? (
                                  formatDuration(
                                    new Date(selectedTask.endExecutionTimestamp) -
                                    new Date(selectedTask.startExecutionTimestamp)
                                  )
                                ) : (
                                  'En cours'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2">Statut</Typography>
                              <Chip
                                size="small"
                                icon={getStatusIcon(selectedTask.executionStatus)}
                                label={selectedTask.executionStatus || 'PENDING'}
                                color={getStatusColor(selectedTask.executionStatus)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2">Exécuté par</Typography>
                              <Typography variant="body1">
                                {selectedTask.executedBy || 'Système'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      ) : (
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Typography variant="body1" align="center" sx={{ py: 4 }}>
                            Aucune exécution enregistrée
                          </Typography>
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
            
            <Box sx={{ position: 'sticky', bottom: 0, p: 2, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider', display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<Edit2 />}
                onClick={() => {
                  handleCloseDialog();
                  handleOpenEditDialog(selectedTask);
                }}
              >
                Modifier
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlayCircle />}
                onClick={() => {
                  handleCloseDialog();
                  handleOpenRunDialog(selectedTask);
                }}
              >
                Exécuter
              </Button>
            </Box>
          </Box>
        )}
      </Drawer>
      
      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

// Fonction utilitaire pour formater une durée en millisecondes
const formatDuration = (durationMs) => {
  const seconds = Math.floor(durationMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`;
  } else {
    return `${seconds}s`;
  }
};

export default TasksPage;