import React, { useState, useEffect } from 'react';
import {
  Shield,
  PlusCircle,
  Edit,
  Trash2,
  Search,
  RefreshCw,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  Lock,
  Info
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const RolesManagement = () => {
  // États de données
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  
  // États pour les formulaires
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [roleForm, setRoleForm] = useState({
    name: '',
    description: '',
    permissions: []
  });
  
  // États pour les groupes de permissions
  const [permissionGroups, setPermissionGroups] = useState({});
  
  // Chargement initial des rôles et des permissions
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [rolesData, permissionsData] = await Promise.all([
          apiService.security.roles.getAll(),
          apiService.security.permissions.getAll()
        ]);
        
        setRoles(rolesData || []);
        setPermissions(permissionsData || []);
        
        // Organiser les permissions par groupe
        const groups = {};
        permissionsData.forEach(permission => {
          const group = permission.group || 'Autres';
          if (!groups[group]) {
            groups[group] = [];
          }
          groups[group].push(permission);
        });
        
        setPermissionGroups(groups);
        setError(null);
      } catch (err) {
        console.error('Erreur lors du chargement des données:', err);
        setError('Impossible de charger les données. Veuillez réessayer.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);
  
  // Filtrage des rôles basé sur la recherche
  const filteredRoles = roles.filter(role => {
    const searchableValues = [
      role.name,
      role.description
    ].map(val => (val || '').toLowerCase());
    
    return searchableValues.some(val => val.includes(searchTerm.toLowerCase()));
  });
  
  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'name',
      header: 'Nom',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 bg-purple-100 dark:bg-purple-900/30 rounded-full flex items-center justify-center text-purple-600 dark:text-purple-300">
            <Shield size={20} />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900 dark:text-white">
              {row.name}
            </div>
            {row.description && (
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {row.description}
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      key: 'isSystem',
      header: 'Type',
      sortable: true,
      render: (row) => (
        <StatusBadge
          status={row.isSystem ? 'warning' : 'active'}
          customLabel={row.isSystem ? 'Système' : 'Personnalisé'}
        />
      )
    },
    {
      key: 'permissions',
      header: 'Permissions',
      render: (row) => (
        <div className="space-y-1">
          {row.permissions && row.permissions.length > 0 ? (
            <div className="flex flex-wrap gap-1">
              {row.permissions.slice(0, 3).map((permission, index) => (
                <span 
                  key={permission.id || index}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300"
                  title={permission.description}
                >
                  {permission.name || permission.code}
                </span>
              ))}{row.permissions && row.permissions.length > 3 && (
                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300">
                  +{row.permissions.length - 3}
                </span>
              )}
            </div>
          ) : (
            <span className="text-gray-400 dark:text-gray-500">Aucune permission</span>
          )}
        </div>
      )
    },
    {
      key: 'createdAt',
      header: 'Créé le',
      sortable: true,
      render: (row) => (
        <DateFormatter date={row.createdAt} format="full" />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => handleEditRole(row)}
            disabled={row.isSystem} // Désactiver l'édition pour les rôles système
            title={row.isSystem ? "Les rôles système ne peuvent pas être modifiés" : "Modifier"}
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => handleDeleteRole(row.id)}
            disabled={row.isSystem} // Désactiver la suppression pour les rôles système
            title={row.isSystem ? "Les rôles système ne peuvent pas être supprimés" : "Supprimer"}
          />
        </div>
      )
    }
  ];
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleCreateRole = () => {
    setRoleForm({
      name: '',
      description: '',
      permissions: []
    });
    setFormMode('create');
    setShowRoleModal(true);
  };
  
  const handleEditRole = (role) => {
    // Formater les permissions pour le formulaire
    const permissionCodes = role.permissions ? role.permissions.map(p => p.code || p.id) : [];
    
    setRoleForm({
      id: role.id,
      name: role.name,
      description: role.description || '',
      permissions: permissionCodes
    });
    setFormMode('edit');
    setShowRoleModal(true);
  };
  
  const handleFormChange = (field, value) => {
    setRoleForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handlePermissionToggle = (permission) => {
    setRoleForm(prev => {
      const permCode = permission.code;
      const isSelected = prev.permissions.includes(permCode);
      
      if (isSelected) {
        return {
          ...prev,
          permissions: prev.permissions.filter(p => p !== permCode)
        };
      } else {
        return {
          ...prev,
          permissions: [...prev.permissions, permCode]
        };
      }
    });
  };
  
  const handleGroupSelection = (group, select) => {
    const groupPermissions = permissionGroups[group].map(p => p.code);
    
    setRoleForm(prev => {
      let updatedPermissions = [...prev.permissions];
      
      if (select) {
        // Ajouter toutes les permissions du groupe qui ne sont pas déjà sélectionnées
        groupPermissions.forEach(permCode => {
          if (!updatedPermissions.includes(permCode)) {
            updatedPermissions.push(permCode);
          }
        });
      } else {
        // Retirer toutes les permissions du groupe
        updatedPermissions = updatedPermissions.filter(
          permCode => !groupPermissions.includes(permCode)
        );
      }
      
      return {
        ...prev,
        permissions: updatedPermissions
      };
    });
  };
  
  const handleSubmitRole = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      const roleData = {
        name: roleForm.name,
        description: roleForm.description,
        permissions: roleForm.permissions.map(code => ({ code }))
      };
      
      let response;
      
      if (formMode === 'create') {
        response = await apiService.security.roles.create(roleData);
        setSuccessMessage('Rôle créé avec succès');
      } else {
        // Pour l'édition, il faudrait un endpoint de mise à jour des rôles
        // Actuellement, nous devons supprimer et recréer le rôle
        await apiService.security.roles.delete(roleForm.id);
        response = await apiService.security.roles.create(roleData);
        setSuccessMessage('Rôle mis à jour avec succès');
      }
      
      // Actualiser la liste des rôles
      const updatedRoles = await apiService.security.roles.getAll();
      setRoles(updatedRoles || []);
      
      // Fermer le modal
      setShowRoleModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteRole = async (roleId) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce rôle ?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.security.roles.delete(roleId);
      
      // Actualiser la liste des rôles
      const updatedRoles = await apiService.security.roles.getAll();
      setRoles(updatedRoles || []);
      
      setSuccessMessage('Rôle supprimé avec succès');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError(err.message || 'Impossible de supprimer le rôle.');
    } finally {
      setLoading(false);
    }
  };
  
  const refreshData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const [rolesData, permissionsData] = await Promise.all([
        apiService.security.roles.getAll(),
        apiService.security.permissions.getAll()
      ]);
      
      setRoles(rolesData || []);
      setPermissions(permissionsData || []);
      
      // Organiser les permissions par groupe
      const groups = {};
      permissionsData.forEach(permission => {
        const group = permission.group || 'Autres';
        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(permission);
      });
      
      setPermissionGroups(groups);
    } catch (err) {
      console.error('Erreur lors de l\'actualisation:', err);
      setError('Impossible d\'actualiser les données.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Shield className="h-6 w-6 mr-2 text-purple-500" />
            Gestion des rôles
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Configurez les rôles et leurs permissions dans le système
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={handleCreateRole}
          >
            Nouveau rôle
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Filtres et recherche */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
          <div className="flex-1 relative">
            <input
              type="text"
              className="w-full p-2 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Rechercher un rôle..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>
      
      {/* Liste des rôles */}
      <Card>
        <DataGrid
          data={filteredRoles}
          columns={columns}
          loading={loading}
          emptyMessage="Aucun rôle trouvé."
        />
      </Card>
      
      {/* Modal de création/édition de rôle */}
      {showRoleModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-4xl overflow-hidden">
            <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 px-6 py-4 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {formMode === 'create' ? 'Nouveau rôle' : 'Modifier le rôle'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowRoleModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitRole} className="p-6 max-h-[80vh] overflow-y-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations du rôle */}
                <div className="space-y-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white">
                    Informations du rôle
                  </h4>
                  
                  {/* Nom du rôle */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du rôle *
                    </label>
                    <input
                      type="text"
                      className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                      value={roleForm.name}
                      onChange={(e) => handleFormChange('name', e.target.value)}
                      required
                    />
                  </div>
                  
                  {/* Description */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                      value={roleForm.description}
                      onChange={(e) => handleFormChange('description', e.target.value)}
                      rows={4}
                    />
                  </div>
                  
                  {/* Note informative */}
                  <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-md">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <Info className="h-5 w-5 text-blue-500" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-blue-700 dark:text-blue-300">
                          Les permissions définissent ce qu'un utilisateur avec ce rôle peut faire dans le système.
                          Sélectionnez les permissions appropriées pour ce rôle.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Sélection des permissions */}
                <div className="space-y-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white flex justify-between items-center">
                    <span>Permissions</span>
                    <span className="text-sm text-gray-500">
                      {roleForm.permissions.length} sélectionnée(s)
                    </span>
                  </h4>
                  
                  <div className="border border-gray-200 dark:border-gray-700 rounded-md overflow-hidden">
                    <div className="max-h-96 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-700">
                      {Object.entries(permissionGroups).map(([group, groupPermissions]) => (
                        <div key={group} className="p-4">
                          <div className="flex justify-between items-center mb-2">
                            <h5 className="font-medium text-gray-900 dark:text-white">{group}</h5>
                            <div className="flex space-x-2">
                              <button
                                type="button"
                                className="text-xs text-blue-600 dark:text-blue-400 hover:underline"
                                onClick={() => handleGroupSelection(group, true)}
                              >
                                Tout sélectionner
                              </button>
                              <button
                                type="button"
                                className="text-xs text-blue-600 dark:text-blue-400 hover:underline"
                                onClick={() => handleGroupSelection(group, false)}
                              >
                                Tout désélectionner
                              </button>
                            </div>
                          </div>
                          
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            {groupPermissions.map((permission) => (
                              <div key={permission.id} className="flex items-center">
                                <input
                                  id={`perm-${permission.id}`}
                                  type="checkbox"
                                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                  checked={roleForm.permissions.includes(permission.code)}
                                  onChange={() => handlePermissionToggle(permission)}
                                />
                                <label htmlFor={`perm-${permission.id}`} className="ml-2 block text-sm text-gray-900 dark:text-white">
                                  {permission.name}
                                  {permission.description && (
                                    <span className="block text-xs text-gray-500 dark:text-gray-400">
                                      {permission.description}
                                    </span>
                                  )}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Actions */}
              <div className="flex justify-end space-x-3 border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setShowRoleModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  icon={Save}
                  loading={loading}
                >
                  {formMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default RolesManagement;