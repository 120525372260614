import React, { useState, useEffect } from 'react';
import { 
  RefreshCw, 
  PlusCircle, 
  Trash2, 
  Edit, 
  Search, 
  Save,
  CheckCircle2, 
  XCircle, 
  Layers,
  Code,
  Link,
  LinkIcon,
  ArrowRight,
  Plus,
  X,
  AlignLeft,
  Check,
  AlertTriangle,
  Info
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const MappingsPage = () => {
  // États pour gérer les données et les états UI
  const [mappings, setMappings] = useState([]);
  const [interfaces, setInterfaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFieldMappingModal, setShowFieldMappingModal] = useState(false);
  const [showValidationRuleModal, setShowValidationRuleModal] = useState(false);
  const [showTransformationRuleModal, setShowTransformationRuleModal] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedInterfaceCode, setSelectedInterfaceCode] = useState('');
  
  // Form Data
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    sourceType: '',
    targetType: '',
    version: '1.0',
    active: true,
    fieldMappings: [],
    globalTransformations: [],
    globalValidations: []
  });

  // Field Mapping Form Data
  const [fieldMappingForm, setFieldMappingForm] = useState({
    sourceField: '',
    targetField: '',
    dataType: 'STRING',
    defaultValue: '',
    required: false,
    format: '',
    description: ''
  });

  // Validation Rule Form Data
  const [validationRuleForm, setValidationRuleForm] = useState({
    name: '',
    description: '',
    ruleType: 'REQUIRED',
    expression: '',
    errorCode: '',
    errorMessage: '',
    severity: 'ERROR',
    stopOnFailure: true,
    active: true
  });

  // Transformation Rule Form Data
  const [transformationRuleForm, setTransformationRuleForm] = useState({
    name: '',
    description: '',
    ruleType: 'DIRECT_COPY',
    expression: '',
    language: 'JEXL',
    inputParamName: '',
    outputParamName: '',
    order: 1,
    active: true
  });

  // Filtrer les mappings en fonction du terme de recherche
  const filteredMappings = mappings.filter(mapping => {
    return (
      mapping.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (mapping.description && mapping.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      mapping.sourceType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      mapping.targetType.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Effet pour charger les mappings et les interfaces au chargement de la page
  useEffect(() => {
    fetchMappings();
    fetchInterfaces();
  }, []);

  // Fonction pour charger les mappings
  const fetchMappings = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.mappings.getAll();
      setMappings(response);
    } catch (err) {
      console.error("Erreur lors du chargement des mappings :", err);
      setError("Impossible de charger les mappings. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger les interfaces
  const fetchInterfaces = async () => {
    try {
      const response = await apiService.integration.interfaces.getAll();
      setInterfaces(response);
    } catch (err) {
      console.error("Erreur lors du chargement des interfaces :", err);
    }
  };

  // Fonction pour charger un mapping spécifique
  const fetchMapping = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.mappings.getById(id);
      setSelectedMapping(response);
      
      // Préparer les données pour le formulaire d'édition
      setFormData({
        name: response.name,
        description: response.description || '',
        sourceType: response.sourceType || '',
        targetType: response.targetType || '',
        version: response.version || '1.0',
        active: response.active !== false,
        fieldMappings: response.fieldMappings || [],
        globalTransformations: response.globalTransformations || [],
        globalValidations: response.globalValidations || []
      });
    } catch (err) {
      console.error("Erreur lors du chargement du mapping :", err);
      setError("Impossible de charger les détails du mapping. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger les mappings d'une interface spécifique
  const fetchMappingsByInterface = async (interfaceCode) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.mappings.getMappingsForInterface(interfaceCode);
      setMappings(response);
      setSelectedInterfaceCode(interfaceCode);
    } catch (err) {
      console.error(`Erreur lors du chargement des mappings pour l'interface ${interfaceCode} :`, err);
      setError(`Impossible de charger les mappings pour l'interface ${interfaceCode}. Veuillez réessayer.`);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour créer un mapping
  const handleCreateMapping = async () => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.mappings.create(formData);
      setSuccessMessage("Mapping créé avec succès !");
      setShowCreateModal(false);
      fetchMappings();
      resetForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création du mapping :", err);
      setError("Impossible de créer le mapping. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour mettre à jour un mapping
  const handleUpdateMapping = async () => {
    if (!selectedMapping) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.mappings.update(selectedMapping.id, formData);
      setSuccessMessage("Mapping mis à jour avec succès !");
      setShowEditModal(false);
      fetchMappings();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du mapping :", err);
      setError("Impossible de mettre à jour le mapping. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour supprimer un mapping
  const handleDeleteMapping = async () => {
    if (!selectedMapping) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.mappings.delete(selectedMapping.id);
      setSuccessMessage("Mapping supprimé avec succès !");
      setShowDeleteModal(false);
      setSelectedMapping(null);
      fetchMappings();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression du mapping :", err);
      setError("Impossible de supprimer le mapping. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour ajouter un field mapping
  const handleAddFieldMapping = async () => {
    if (!selectedMapping) return;
    
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.mappings.addFieldMapping(selectedMapping.id, fieldMappingForm);
      
      // Mettre à jour le state local
      setFormData(prev => ({
        ...prev,
        fieldMappings: [...prev.fieldMappings, response]
      }));
      
      setSuccessMessage("Mapping de champ ajouté avec succès !");
      setShowFieldMappingModal(false);
      resetFieldMappingForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'ajout du mapping de champ :", err);
      setError("Impossible d'ajouter le mapping de champ. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour supprimer un field mapping
  const handleRemoveFieldMapping = async (fieldMappingId) => {
    if (!selectedMapping) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.mappings.removeFieldMapping(selectedMapping.id, fieldMappingId);
      
      // Mettre à jour le state local
      setFormData(prev => ({
        ...prev,
        fieldMappings: prev.fieldMappings.filter(fm => fm.id !== fieldMappingId)
      }));
      
      setSuccessMessage("Mapping de champ supprimé avec succès !");
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression du mapping de champ :", err);
      setError("Impossible de supprimer le mapping de champ. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour ajouter une règle de validation
  const handleAddValidationRule = async () => {
    if (!selectedMapping) return;
    
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.mappings.addValidationRule(selectedMapping.id, validationRuleForm);
      
      // Mettre à jour le state local
      setFormData(prev => ({
        ...prev,
        globalValidations: [...prev.globalValidations, response]
      }));
      
      setSuccessMessage("Règle de validation ajoutée avec succès !");
      setShowValidationRuleModal(false);
      resetValidationRuleForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'ajout de la règle de validation :", err);
      setError("Impossible d'ajouter la règle de validation. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour ajouter une règle de transformation
  const handleAddTransformationRule = async () => {
    if (!selectedMapping) return;
    
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.mappings.addTransformationRule(selectedMapping.id, transformationRuleForm);
      
      // Mettre à jour le state local
      setFormData(prev => ({
        ...prev,
        globalTransformations: [...prev.globalTransformations, response]
      }));
      
      setSuccessMessage("Règle de transformation ajoutée avec succès !");
      setShowTransformationRuleModal(false);
      resetTransformationRuleForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'ajout de la règle de transformation :", err);
      setError("Impossible d'ajouter la règle de transformation. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Réinitialisation du formulaire principal
  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      sourceType: '',
      targetType: '',
      version: '1.0',
      active: true,
      fieldMappings: [],
      globalTransformations: [],
      globalValidations: []
    });
  };

  // Réinitialisation du formulaire de mapping de champ
  const resetFieldMappingForm = () => {
    setFieldMappingForm({
      sourceField: '',
      targetField: '',
      dataType: 'STRING',
      defaultValue: '',
      required: false,
      format: '',
      description: ''
    });
  };

  // Réinitialisation du formulaire de règle de validation
  const resetValidationRuleForm = () => {
    setValidationRuleForm({
      name: '',
      description: '',
      ruleType: 'REQUIRED',
      expression: '',
      errorCode: '',
      errorMessage: '',
      severity: 'ERROR',
      stopOnFailure: true,
      active: true
    });
  };

  // Réinitialisation du formulaire de règle de transformation
  const resetTransformationRuleForm = () => {
    setTransformationRuleForm({
      name: '',
      description: '',
      ruleType: 'DIRECT_COPY',
      expression: '',
      language: 'JEXL',
      inputParamName: '',
      outputParamName: '',
      order: 1,
      active: true
    });
  };

  // Gestion des changements dans le formulaire principal
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Gestion des changements dans le formulaire de mapping de champ
  const handleFieldMappingFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFieldMappingForm({
      ...fieldMappingForm,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Gestion des changements dans le formulaire de règle de validation
  const handleValidationRuleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValidationRuleForm({
      ...validationRuleForm,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Gestion des changements dans le formulaire de règle de transformation
  const handleTransformationRuleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTransformationRuleForm({
      ...transformationRuleForm,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Colonnes pour la grille de données des mappings
  const mappingColumns = [
    {
      header: 'Nom',
      key: 'name',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.name}
        </div>
      )
    },
    {
      header: 'Description',
      key: 'description',
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300">
          {row.description || '-'}
        </div>
      )
    },
    {
      header: 'Source / Cible',
      key: 'types',
      render: (row) => (
        <div className="flex items-center space-x-1">
          <div className="text-gray-700 dark:text-gray-300">{row.sourceType}</div>
          <ArrowRight className="h-4 w-4 text-gray-400" />
          <div className="text-gray-700 dark:text-gray-300">{row.targetType}</div>
        </div>
      )
    },
    {
      header: 'Champs / Règles',
      key: 'counts',
      render: (row) => (
        <div className="space-y-1">
          <div className="flex items-center space-x-1">
            <LinkIcon className="h-4 w-4 text-blue-500" />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              {row.fieldMappings?.length || 0} champs
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <AlertTriangle className="h-4 w-4 text-yellow-500" />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              {row.globalValidations?.length || 0} validations
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Code className="h-4 w-4 text-green-500" />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              {row.globalTransformations?.length || 0} transformations
            </span>
          </div>
        </div>
      )
    },
    {
      header: 'Version',
      key: 'version',
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300">
          {row.version || '1.0'}
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => (
        <StatusBadge 
          status={row.active ? 'active' : 'inactive'} 
          customLabel={row.active ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            onClick={(e) => {
              e.stopPropagation();
              fetchMapping(row.id);
              setShowEditModal(true);
            }}
          >
            Modifier
          </Button>
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedMapping(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  // Colonnes pour la grille de données des field mappings
  const fieldMappingColumns = [
    {
      header: 'Champ source',
      key: 'sourceField',
      render: (row) => (
        <div className="font-medium text-gray-800 dark:text-gray-200">
          {row.sourceField}
        </div>
      )
    },
    {
      header: 'Champ cible',
      key: 'targetField',
      render: (row) => (
        <div className="font-medium text-gray-800 dark:text-gray-200">
          {row.targetField}
        </div>
      )
    },
    {
      header: 'Type',
      key: 'dataType',
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300">
          {row.dataType}
        </div>
      )
    },
    {
      header: 'Requis',
      key: 'required',
      render: (row) => (
        <div>
          {row.required ? (
            <CheckCircle2 className="h-5 w-5 text-green-500" />
          ) : (
            <XCircle className="h-5 w-5 text-gray-400" />
          )}
        </div>
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={() => handleRemoveFieldMapping(row.id)}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Layers className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Mappings
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Définissez des mappings pour transformer les données entre les systèmes
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchMappings}
            loading={loading && !showCreateModal && !showEditModal && !showDeleteModal && !showFieldMappingModal}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetForm();
              setShowCreateModal(true);
            }}
          >
            Nouveau mapping
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Filtre par interface et recherche */}
      <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Filtrer par interface
          </label>
          <select
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={selectedInterfaceCode}
            onChange={(e) => {
              const code = e.target.value;
              if (code) {
                fetchMappingsByInterface(code);
              } else {
                fetchMappings();
                setSelectedInterfaceCode('');
              }
            }}
          >
            <option value="">Tous les mappings</option>
            {interfaces.map(itf => (
              <option key={itf.code} value={itf.code}>{itf.name}</option>
            ))}
          </select>
        </div>
        
        <div className="sm:w-2/3">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Recherche
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Rechercher un mapping..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Liste des mappings */}
      <Card>
        {loading && !showCreateModal && !showEditModal && !showDeleteModal && !showFieldMappingModal ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <DataGrid
            data={filteredMappings}
            columns={mappingColumns}
            emptyMessage="Aucun mapping trouvé"
          />
        )}
      </Card>

      {/* Modal de création de mapping */}
      {showCreateModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-3xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer un nouveau mapping
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version
                    </label>
                    <input
                      type="text"
                      name="version"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.version}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.description}
                    onChange={handleFormChange}
                    rows={2}
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type source*
                    </label>
                    <input
                      type="text"
                      name="sourceType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.sourceType}
                      onChange={handleFormChange}
                      required
                      placeholder="Ex: JSON, XML, CSV..."
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type cible*
                    </label>
                    <input
                      type="text"
                      name="targetType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.targetType}
                      onChange={handleFormChange}
                      required
                      placeholder="Ex: JSON, XML, CSV..."
                    />
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="active"
                    name="active"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={formData.active}
                    onChange={handleFormChange}
                  />
                  <label htmlFor="active" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Actif
                  </label>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreateMapping}
                  loading={loading}
                  disabled={!formData.name || !formData.sourceType || !formData.targetType}
                >
                  Créer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'édition de mapping */}
      {showEditModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Modifier le mapping
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowEditModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version
                    </label>
                    <input
                      type="text"
                      name="version"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.version}
                      onChange={handleFormChange}
                      readOnly
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.description}
                    onChange={handleFormChange}
                    rows={2}
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type source*
                    </label>
                    <input
                      type="text"
                      name="sourceType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.sourceType}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type cible*
                    </label>
                    <input
                      type="text"
                      name="targetType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.targetType}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="activeEdit"
                    name="active"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={formData.active}
                    onChange={handleFormChange}
                  />
                  <label htmlFor="activeEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Actif
                  </label>
                </div>
                
                {/* Onglets */}
                <div className="border-b border-gray-200 dark:border-gray-700">
                  <nav className="-mb-px flex space-x-6">
                    <button
                      className={`py-2 px-1 border-b-2 font-medium text-sm ${
                        true // premier onglet actif par défaut
                          ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                      }`}
                    >
                      Mappings de champs
                    </button>
                    <button
                      className={`py-2 px-1 border-b-2 font-medium text-sm border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300`}
                    >
                      Règles de validation
                    </button>
                    <button
                      className={`py-2 px-1 border-b-2 font-medium text-sm border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300`}
                    >
                      Règles de transformation
                    </button>
                  </nav>
                </div>
                
                {/* Contenu des onglets - Mappings de champs */}
                <div>
                  <div className="flex justify-between items-center mb-3">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      Mappings de champs
                    </h3>
                    <Button
                      variant="outline"
                      size="sm"
                      icon={Plus}
                      onClick={() => {
                        resetFieldMappingForm();
                        setShowFieldMappingModal(true);
                      }}
                    >
                      Ajouter
                    </Button>
                  </div>
                  
                  <div className="border border-gray-200 dark:border-gray-700 rounded-md overflow-hidden">
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            {fieldMappingColumns.map((column) => (
                              <th
                                key={column.key}
                                className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                                  column.align === 'right' ? 'text-right' : ''
                                }`}
                              >
                                {column.header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                          {formData.fieldMappings.length > 0 ? (
                            formData.fieldMappings.map((item, index) => (
                              <tr key={index}>
                                {fieldMappingColumns.map((column) => (
                                  <td
                                    key={`${index}-${column.key}`}
                                    className={`px-6 py-4 whitespace-nowrap ${
                                      column.align === 'right' ? 'text-right' : ''
                                    }`}
                                  >
                                    {column.render ? column.render(item) : item[column.key]}
                                  </td>
                                ))}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={fieldMappingColumns.length}
                                className="px-6 py-4 text-center text-sm text-gray-500 dark:text-gray-400"
                              >
                                Aucun mapping de champ défini
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowEditModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUpdateMapping}
                  loading={loading}
                  disabled={!formData.name || !formData.sourceType || !formData.targetType}
                >
                  Mettre à jour
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20">
                  <Trash2 className="h-6 w-6 text-red-600 dark:text-red-500" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  Supprimer le mapping
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir supprimer le mapping "{selectedMapping?.name}" ? Cette action est irréversible.
                </p>
              </div>
              
              <div className="mt-6 flex justify-center space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  onClick={handleDeleteMapping}
                  loading={loading}
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'ajout de field mapping */}
      {showFieldMappingModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Ajouter un mapping de champ
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowFieldMappingModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Champ source*
                    </label>
                    <input
                      type="text"
                      name="sourceField"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={fieldMappingForm.sourceField}
                      onChange={handleFieldMappingFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Champ cible*
                    </label>
                    <input
                      type="text"
                      name="targetField"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={fieldMappingForm.targetField}
                      onChange={handleFieldMappingFormChange}
                      required
                    />
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de données
                    </label>
                    <select
                      name="dataType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={fieldMappingForm.dataType}
                      onChange={handleFieldMappingFormChange}
                    >
                      <option value="STRING">Chaîne de caractères</option>
                      <option value="INTEGER">Entier</option>
                      <option value="FLOAT">Décimal</option>
                      <option value="BOOLEAN">Booléen</option>
                      <option value="DATE">Date</option>
                      <option value="DATETIME">Date et heure</option>
                      <option value="OBJECT">Objet</option>
                      <option value="ARRAY">Tableau</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Valeur par défaut
                    </label>
                    <input
                      type="text"
                      name="defaultValue"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={fieldMappingForm.defaultValue}
                      onChange={handleFieldMappingFormChange}
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Format
                  </label>
                  <input
                    type="text"
                    name="format"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={fieldMappingForm.format}
                    onChange={handleFieldMappingFormChange}
                    placeholder="Ex: yyyy-MM-dd pour les dates"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={fieldMappingForm.description}
                    onChange={handleFieldMappingFormChange}
                    rows={2}
                  />
                </div>
                
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="required"
                    name="required"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={fieldMappingForm.required}
                    onChange={handleFieldMappingFormChange}
                  />
                  <label htmlFor="required" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Champ requis
                  </label>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowFieldMappingModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleAddFieldMapping}
                  loading={loading}
                  disabled={!fieldMappingForm.sourceField || !fieldMappingForm.targetField}
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'ajout de règle de validation */}
      {showValidationRuleModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Ajouter une règle de validation
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => {
                    setShowValidationRuleModal(false);
                    resetValidationRuleForm();
                  }}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={validationRuleForm.name}
                      onChange={handleValidationRuleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de règle
                    </label>
                    <select
                      name="ruleType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={validationRuleForm.ruleType}
                      onChange={handleValidationRuleFormChange}
                    >
                      <option value="REQUIRED">Obligatoire</option>
                      <option value="LENGTH">Longueur</option>
                      <option value="PATTERN">Motif (regex)</option>
                      <option value="RANGE">Plage de valeurs</option>
                      <option value="EMAIL">Email</option>
                      <option value="CUSTOM">Personnalisé</option>
                    </select>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={validationRuleForm.description}
                    onChange={handleValidationRuleFormChange}
                    rows={2}
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Expression
                  </label>
                  <textarea
                    name="expression"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={validationRuleForm.expression}
                    onChange={handleValidationRuleFormChange}
                    rows={3}
                    placeholder="Ex: value != null && value.length > 0"
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Code d'erreur
                    </label>
                    <input
                      type="text"
                      name="errorCode"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={validationRuleForm.errorCode}
                      onChange={handleValidationRuleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Sévérité
                    </label>
                    <select
                      name="severity"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={validationRuleForm.severity}
                      onChange={handleValidationRuleFormChange}
                    >
                      <option value="ERROR">Erreur</option>
                      <option value="WARNING">Avertissement</option>
                      <option value="INFO">Information</option>
                    </select>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Message d'erreur
                  </label>
                  <input
                    type="text"
                    name="errorMessage"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={validationRuleForm.errorMessage}
                    onChange={handleValidationRuleFormChange}
                  />
                </div>
                
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="stopOnFailure"
                    name="stopOnFailure"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={validationRuleForm.stopOnFailure}
                    onChange={handleValidationRuleFormChange}
                  />
                  <label htmlFor="stopOnFailure" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Arrêter en cas d'échec
                  </label>
                </div>
                
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="validationActive"
                    name="active"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={validationRuleForm.active}
                    onChange={handleValidationRuleFormChange}
                  />
                  <label htmlFor="validationActive" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Actif
                  </label>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowValidationRuleModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleAddValidationRule}
                  loading={loading}
                  disabled={!validationRuleForm.name}
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'ajout de règle de transformation */}
      {showTransformationRuleModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Ajouter une règle de transformation
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => {
                    setShowTransformationRuleModal(false);
                    resetTransformationRuleForm();
                  }}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={transformationRuleForm.name}
                      onChange={handleTransformationRuleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de règle
                    </label>
                    <select
                      name="ruleType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={transformationRuleForm.ruleType}
                      onChange={handleTransformationRuleFormChange}
                    >
                      <option value="DIRECT_COPY">Copie directe</option>
                      <option value="CONSTANT">Constante</option>
                      <option value="EXPRESSION">Expression</option>
                      <option value="FUNCTION">Fonction</option>
                      <option value="LOOKUP">Recherche</option>
                      <option value="CONCAT">Concaténation</option>
                      <option value="SPLIT">Séparation</option>
                      <option value="DATE_FORMAT">Format de date</option>
                      <option value="NUMBER_FORMAT">Format de nombre</option>
                      <option value="CONDITIONAL">Conditionnel</option>
                      <option value="CUSTOM">Personnalisé</option>
                    </select>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={transformationRuleForm.description}
                    onChange={handleTransformationRuleFormChange}
                    rows={2}
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Expression
                  </label>
                  <textarea
                    name="expression"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={transformationRuleForm.expression}
                    onChange={handleTransformationRuleFormChange}
                    rows={3}
                    placeholder="Ex: input.toUpperCase()"
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Langage
                    </label>
                    <select
                      name="language"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={transformationRuleForm.language}
                      onChange={handleTransformationRuleFormChange}
                    >
                      <option value="JEXL">JEXL</option>
                      <option value="JavaScript">JavaScript</option>
                      <option value="Groovy">Groovy</option>
                      <option value="SpEL">SpEL</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Ordre
                    </label>
                    <input
                      type="number"
                      name="order"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={transformationRuleForm.order}
                      onChange={handleTransformationRuleFormChange}
                      min="1"
                    />
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Paramètre d'entrée
                    </label>
                    <input
                      type="text"
                      name="inputParamName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={transformationRuleForm.inputParamName}
                      onChange={handleTransformationRuleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Paramètre de sortie
                    </label>
                    <input
                      type="text"
                      name="outputParamName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={transformationRuleForm.outputParamName}
                      onChange={handleTransformationRuleFormChange}
                    />
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="transformationActive"
                    name="active"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={transformationRuleForm.active}
                    onChange={handleTransformationRuleFormChange}
                  />
                  <label htmlFor="transformationActive" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Actif
                  </label>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowTransformationRuleModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleAddTransformationRule}
                  loading={loading}
                  disabled={!transformationRuleForm.name}
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default MappingsPage;