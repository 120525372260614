// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from './components/layout/Layout';
import AuthPage from './components/auth/AuthPage';
import apiService from './services/api.service';

// Composant de chargement
const LoadingScreen = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

// Déplacé dans le composant App
// const handleRouteChange = (location) => {
//   apiService.automata.record.closeAllConnections();
// };

// Composant Page Not Found
const NotFound = () => (
  <div className="flex flex-col items-center justify-center min-h-[60vh]">
    <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">404</h1>
    <p className="text-gray-600 dark:text-gray-400">Page non trouvée</p>
  </div>
);

// Fonction pour charger dynamiquement les features
const loadFeatures = async () => {
  const featureContext = require.context('./features', true, /\.js$/);
  
  const features = await Promise.all(
    featureContext.keys()
      // Exclure les fichiers commençant par '_' ou 'sub_'
      .filter(path => {
        const fileName = path.split('/').pop(); // Récupère juste le nom du fichier
        return !fileName.startsWith('_') && !fileName.startsWith('sub_');
      })
      .map(async (path) => {
        const [, module, featureFile] = path.match(/\.\/(.+?)\/(.+?)\.js$/);
        const featureName = featureFile.toLowerCase();
        const Component = React.lazy(() => import(`./features/${module}/${featureFile}`));

        return {
          module,
          name: featureName,
          component: Component
        };
      })
  );

  return features;
};

// Composant ProtectedRoute
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = apiService.auth.isAuthenticated();
  
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

// Gestionnaire de route pour les connexions SSE/WebSocket
const RouteChangeHandler = ({ children }) => {
  const location = useLocation();
  
  useEffect(() => {
    // Fonction qui s'exécute à chaque changement de route
    const handleRouteChange = () => {
      if (apiService.automata && apiService.automata.record && 
          typeof apiService.automata.record.closeAllConnections === 'function') {
        apiService.automata.record.closeAllConnections();
      }
    };
    
    // Exécuter au moment du montage
    handleRouteChange();
    
    // Nettoyer au démontage
    return () => {
      if (apiService.automata && apiService.automata.record && 
          typeof apiService.automata.record.closeAllConnections === 'function') {
        apiService.automata.record.closeAllConnections();
      }
    };
  }, [location]);
  
  return children;
};

// Composant principal App
const App = () => {
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const isAuthenticated = apiService.auth.isAuthenticated();

  useEffect(() => {
    loadFeatures()
      .then(loadedFeatures => {
        setFeatures(loadedFeatures);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading features:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <RouteChangeHandler>
        <React.Suspense fallback={<LoadingScreen />}>
          <Routes>
            {/* Page de login publique */}
            <Route path="/login" element={
              isAuthenticated ? <Navigate to="/" replace /> : <AuthPage />
            } />

            {/* Routes protégées */}
            <Route path="/" element={
              <ProtectedRoute>
                <Layout features={features} />
              </ProtectedRoute>
            }>
              {/* Route d'accueil */}
              <Route 
                index 
                element={
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <h1>Bienvenue</h1>
                  </motion.div>
                } 
              />

              {/* Routes dynamiques pour les features */}
              {features.map(({ module, name, component: Component }) => (
                <Route
                  key={`${module}-${name}`}
                  path={`/${module}/${name}`.toLowerCase()}
                  element={
                    <AnimatePresence mode="wait">
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                      >
                        <React.Suspense fallback={<LoadingScreen />}>
                          <Component />
                        </React.Suspense>
                      </motion.div>
                    </AnimatePresence>
                  }
                />
              ))}

              {/* Route 404 */}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </React.Suspense>
      </RouteChangeHandler>
    </Router>
  );
};

export default App;