import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button as MuiButton,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Divider,
  Chip,
  Tooltip,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardActions,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Switch,
  FormControlLabel,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
  CircularProgress,
  Stack,
  FormHelperText
} from '@mui/material';

import { 
  Search, Plus, Edit, Trash2, RefreshCw, Calendar, Check, X, 
  ChevronRight, ChevronLeft, Play, Clock, CheckCircle, XCircle,
  Settings, List as ListIcon, BarChart2, FileText, AlertTriangle, Users, Tag,
  ArrowRight, Pause, Archive, Target, FolderOpen, Layers, Filter, 
  Eye, Info, FileCode, Save, MoreVertical, Clipboard, Link
} from 'lucide-react';

// Importations existantes
import api from '../../services/api.service';
import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import DataGrid from '../../components/shared/DataGrid';

// Définition des couleurs pour harmoniser avec MissionControlDashboard
const COLORS = {
  primary: '#3f51b5',
  secondary: '#f50057',
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
  info: '#2196f3',
  campaign: '#3f51b5',
  testCase: '#673ab7',
  execution: '#009688',
  result: '#ff5722',
  chartColors: ['#3f51b5', '#03a9f4', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#673ab7', '#009688', '#ff5722']
};

const CampaignManager = () => {
  // États pour les données (conservés identiques)
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [testCases, setTestCases] = useState([]);
  const [availableTestCases, setAvailableTestCases] = useState([]);
  const [selectedTab, setSelectedTab] = useState('overview');
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  
  // États pour les modales
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTestCaseModal, setShowTestCaseModal] = useState(false);
  
  // États pour les formulaires
  const [campaignForm, setCampaignForm] = useState({
    name: '',
    description: '',
    objective: '',
    startDate: '',
    endDate: '',
    priority: 0,
    recurrenceType: 'PONCTUEL',
    isActive: true,
    tags: ''
  });
  
  // États pour le chargement et les erreurs
  const [loading, setLoading] = useState({
    campaigns: true,
    campaign: false,
    testCases: false,
    availableTestCases: false,
    form: false
  });
  const [error, setError] = useState({
    campaigns: null,
    campaign: null,
    testCases: null,
    availableTestCases: null,
    form: null
  });
  
  // Fonction pour charger toutes les campagnes
  const fetchCampaigns = useCallback(async () => {
    setLoading(prev => ({ ...prev, campaigns: true }));
    setError(prev => ({ ...prev, campaigns: null }));
    
    try {
      const response = await api.automata.campaigns.getAll();
      setCampaigns(response);
      
      // Si aucune campagne n'est sélectionnée, sélectionner la première
      if (!selectedCampaign && response.length > 0) {
        setSelectedCampaign(response[0]);
        fetchCampaignDetails(response[0].id);
      }
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      setError(prev => ({ ...prev, campaigns: 'Failed to load campaigns' }));
    } finally {
      setLoading(prev => ({ ...prev, campaigns: false }));
    }
  }, [selectedCampaign]);
  
  // Fonction pour récupérer les détails d'une campagne
  const fetchCampaignDetails = async (campaignId) => {
    if (!campaignId) return;
    
    setLoading(prev => ({ ...prev, campaign: true }));
    setError(prev => ({ ...prev, campaign: null }));
    
    try {
      const response = await api.automata.campaigns.getById(campaignId);
      setSelectedCampaign(response);
      
      // Récupérer les cas de test associés
      fetchTestCases(response);
    } catch (err) {
      console.error('Error fetching campaign details:', err);
      setError(prev => ({ ...prev, campaign: 'Failed to load campaign details' }));
    } finally {
      setLoading(prev => ({ ...prev, campaign: false }));
    }
  };
  
  // Fonction pour récupérer les cas de test associés à une campagne
  const fetchTestCases = async (campaign) => {
    if (!campaign || !campaign.id) return;
    
    setLoading(prev => ({ ...prev, testCases: true }));
    setError(prev => ({ ...prev, testCases: null }));
    
    try {
      // Dans le modèle actuel, les cas de test sont déjà inclus dans la campagne
      if (campaign.testCases) {
        setTestCases(campaign.testCases);
      }
    } catch (err) {
      console.error('Error fetching test cases:', err);
      setError(prev => ({ ...prev, testCases: 'Failed to load test cases' }));
    } finally {
      setLoading(prev => ({ ...prev, testCases: false }));
    }
  };
  
  // Fonction pour récupérer tous les cas de test disponibles
  const fetchAvailableTestCases = async () => {
    setLoading(prev => ({ ...prev, availableTestCases: true }));
    setError(prev => ({ ...prev, availableTestCases: null }));
    
    try {
      const response = await api.automata.testCases.getAll(true);
      
      // Filtrer les cas de test qui ne sont pas déjà associés à la campagne
      if (selectedCampaign && selectedCampaign.testCases) {
        const associatedIds = selectedCampaign.testCases.map(tc => tc.id);
        const availableTCs = response.filter(tc => !associatedIds.includes(tc.id));
        setAvailableTestCases(availableTCs);
      } else {
        setAvailableTestCases(response);
      }
    } catch (err) {
      console.error('Error fetching available test cases:', err);
      setError(prev => ({ ...prev, availableTestCases: 'Failed to load available test cases' }));
    } finally {
      setLoading(prev => ({ ...prev, availableTestCases: false }));
    }
  };
  
  // Fonction pour filtrer les campagnes
  const filterCampaigns = useCallback(() => {
    let filtered = [...campaigns];
    
    // Filtre par terme de recherche
    if (searchTerm) {
      filtered = filtered.filter(
        campaign => 
          campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (campaign.description && campaign.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (campaign.campaignId && campaign.campaignId.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    
    // Filtre par statut
    if (statusFilter) {
      filtered = filtered.filter(campaign => campaign.status === statusFilter);
    }
    
    setFilteredCampaigns(filtered);
  }, [campaigns, searchTerm, statusFilter]);

  // Effet pour charger les campagnes au chargement du composant
  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);
  
  // Effet pour filtrer les campagnes lorsque les filtres changent
  useEffect(() => {
    filterCampaigns();
  }, [filterCampaigns, campaigns, searchTerm, statusFilter]);
  
  // Fonctions pour la gestion des formulaires
  const resetCampaignForm = () => {
    setCampaignForm({
      name: '',
      description: '',
      objective: '',
      startDate: '',
      endDate: '',
      priority: 0,
      recurrenceType: 'PONCTUEL',
      isActive: true,
      tags: ''
    });
  };
  
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCampaignForm(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  const populateFormWithCampaign = (campaign) => {
    setCampaignForm({
      name: campaign.name || '',
      description: campaign.description || '',
      objective: campaign.objective || '',
      startDate: campaign.startDate ? new Date(campaign.startDate).toISOString().split('T')[0] : '',
      endDate: campaign.endDate ? new Date(campaign.endDate).toISOString().split('T')[0] : '',
      priority: campaign.priority || 0,
      recurrenceType: campaign.recurrenceType || 'PONCTUEL',
      isActive: campaign.isActive !== undefined ? campaign.isActive : true,
      tags: campaign.tags || ''
    });
  };
  
  // Fonctions pour les opérations CRUD
  const createCampaign = async () => {
    setLoading(prev => ({ ...prev, form: true }));
    setError(prev => ({ ...prev, form: null }));
    
    try {
      // Préparer l'objet campagne
      const campaignData = {
        ...campaignForm,
        creationDate: new Date().toISOString(),
        status: 'DRAFT'
      };
      
      // Créer la campagne
      const response = await api.automata.campaigns.create(campaignData);
      
      // Fermer le modal et actualiser la liste
      setShowCreateModal(false);
      await fetchCampaigns();
      
      // Sélectionner la nouvelle campagne
      setSelectedCampaign(response);
      fetchCampaignDetails(response.id);
      
      // Réinitialiser le formulaire
      resetCampaignForm();
    } catch (err) {
      console.error('Error creating campaign:', err);
      setError(prev => ({ ...prev, form: 'Failed to create campaign' }));
    } finally {
      setLoading(prev => ({ ...prev, form: false }));
    }
  };
  
  const updateCampaign = async () => {
    if (!selectedCampaign) return;
    
    setLoading(prev => ({ ...prev, form: true }));
    setError(prev => ({ ...prev, form: null }));
    
    try {
      // Préparer l'objet campagne
      const campaignData = {
        ...selectedCampaign,
        ...campaignForm
      };
      
      // Mettre à jour la campagne
      await api.automata.campaigns.update(selectedCampaign.id, campaignData);
      
      // Fermer le modal et actualiser les détails
      setShowEditModal(false);
      await fetchCampaignDetails(selectedCampaign.id);
      await fetchCampaigns();
      
      // Réinitialiser le formulaire
      resetCampaignForm();
    } catch (err) {
      console.error('Error updating campaign:', err);
      setError(prev => ({ ...prev, form: 'Failed to update campaign' }));
    } finally {
      setLoading(prev => ({ ...prev, form: false }));
    }
  };
  
  const deleteCampaign = async () => {
    if (!selectedCampaign) return;
    
    setLoading(prev => ({ ...prev, form: true }));
    setError(prev => ({ ...prev, form: null }));
    
    try {
      // Supprimer la campagne
      await api.automata.campaigns.delete(selectedCampaign.id);
      
      // Fermer le modal et actualiser la liste
      setShowDeleteModal(false);
      await fetchCampaigns();
      
      // Désélectionner la campagne
      setSelectedCampaign(null);
    } catch (err) {
      console.error('Error deleting campaign:', err);
      setError(prev => ({ ...prev, form: 'Failed to delete campaign' }));
    } finally {
      setLoading(prev => ({ ...prev, form: false }));
    }
  };
  
  const updateCampaignStatus = async (status) => {
    if (!selectedCampaign) return;
    
    try {
      // Mettre à jour le statut
      await api.automata.campaigns.updateStatus(selectedCampaign.id, status);
      
      // Actualiser les détails
      await fetchCampaignDetails(selectedCampaign.id);
      await fetchCampaigns();
    } catch (err) {
      console.error('Error updating campaign status:', err);
    }
  };
  
  // Fonctions pour la gestion des cas de test
  const addTestCase = async (testCaseId) => {
    if (!selectedCampaign || !testCaseId) return;
    
    try {
      // Ajouter le cas de test
      await api.automata.campaigns.linkTestCase(selectedCampaign.id, testCaseId);
      
      // Actualiser les détails
      await fetchCampaignDetails(selectedCampaign.id);
      setShowTestCaseModal(false);
    } catch (err) {
      console.error('Error adding test case:', err);
    }
  };
  
  const removeTestCase = async (testCaseId) => {
    if (!selectedCampaign || !testCaseId) return;
    
    try {
      // Supprimer le cas de test
      await api.automata.campaigns.removeTestCase(selectedCampaign.id, testCaseId);
      
      // Actualiser les détails
      await fetchCampaignDetails(selectedCampaign.id);
    } catch (err) {
      console.error('Error removing test case:', err);
    }
  };

  // Fonction pour déterminer la couleur du badge selon le statut
  const getStatusColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'IN_PROGRESS':
        return 'info';
      case 'DRAFT':
        return 'default';
      case 'COMPLETED':
        return 'success';
      case 'ARCHIVED':
        return 'secondary';
      case 'CANCELLED':
        return 'error';
      case 'ON_HOLD':
        return 'warning';
      default:
        return 'default';
    }
  };
  
  // Rendu du composant
  return (
    <Box sx={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils supérieure */}
      <Paper 
        elevation={1} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: 0
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Calendar size={24} color={COLORS.campaign} />
          Gestionnaire de campagnes
        </Typography>
        
        <MuiButton
          variant="contained"
          color="primary"
          startIcon={<Plus />}
          onClick={() => {
            resetCampaignForm();
            setShowCreateModal(true);
          }}
        >
          Nouvelle campagne
        </MuiButton>
      </Paper>
      
      {/* Contenu principal */}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Panneau latéral avec la liste des campagnes */}
        <Box sx={{ 
          width: 320, 
          display: 'flex', 
          flexDirection: 'column',
          borderRight: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper'
        }}>
          {/* Filtres */}
          <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Rechercher des campagnes..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={18} color="action" />
                  </InputAdornment>
                )
              }}
            />
            
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <FormControl fullWidth size="small">
                  <InputLabel>Statut</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    label="Statut"
                  >
                    <MenuItem value="">Tous les statuts</MenuItem>
                    <MenuItem value="DRAFT">Brouillon</MenuItem>
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="SCHEDULED">Planifiée</MenuItem>
                    <MenuItem value="IN_PROGRESS">En cours</MenuItem>
                    <MenuItem value="ON_HOLD">En attente</MenuItem>
                    <MenuItem value="COMPLETED">Terminée</MenuItem>
                    <MenuItem value="CANCELLED">Annulée</MenuItem>
                    <MenuItem value="ARCHIVED">Archivée</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <MuiButton
                  variant="outlined"
                  fullWidth
                  startIcon={<RefreshCw size={18} />}
                  onClick={fetchCampaigns}
                  sx={{ height: '100%' }}
                >
                  Actualiser
                </MuiButton>
              </Grid>
            </Grid>
          </Box>
          
          {/* Liste des campagnes */}
          <Box sx={{ 
            flex: 1, 
            overflow: 'auto',
            bgcolor: 'background.default' 
          }}>
            {loading.campaigns ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : error.campaigns ? (
              <Box sx={{ p: 2, color: 'error.main', textAlign: 'center' }}>
                {error.campaigns}
              </Box>
            ) : filteredCampaigns.length === 0 ? (
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100%',
                p: 2,
                color: 'text.secondary'
              }}>
                <FolderOpen size={48} style={{ opacity: 0.5, marginBottom: 16 }} />
                <Typography>Aucune campagne trouvée</Typography>
              </Box>
            ) : (
              <List sx={{ py: 0 }}>
                {filteredCampaigns.map((campaign) => (
                  <ListItem
                    key={campaign.id}
                    button
                    selected={selectedCampaign?.id === campaign.id}
                    onClick={() => {
                      setSelectedCampaign(campaign);
                      fetchCampaignDetails(campaign.id);
                    }}
                    sx={{ 
                      borderBottom: 1, 
                      borderColor: 'divider', 
                      py: 1.5,
                      pl: selectedCampaign?.id === campaign.id ? 2 : 3,
                      position: 'relative',
                      '&.Mui-selected': {
                        bgcolor: 'action.selected',
                        borderLeft: 4,
                        borderLeftColor: 'primary.main',
                      }
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" noWrap>
                          {campaign.name}
                        </Typography>
                      }
                      secondary={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
                          <Typography variant="caption" color="text.secondary" component="span">
                            {campaign.campaignId}
                          </Typography>
                          <Chip
                            label={campaign.status}
                            size="small"
                            color={getStatusColor(campaign.status)}
                            sx={{ height: 20 }}
                          />
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
        
        {/* Panneau principal avec les détails de la campagne */}
        <Box sx={{ flex: 1, overflow: 'auto', bgcolor: 'background.paper' }}>
          {selectedCampaign ? (
            <>
              {/* En-tête avec les informations de base et les actions */}
              <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                  <Box>
                    <Typography variant="h5" fontWeight="bold">
                      {selectedCampaign.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {selectedCampaign.campaignId} • Créée le{' '}
                      <DateFormatter date={selectedCampaign.creationDate} format="full" />
                    </Typography>
                  </Box>
                  
                  <Stack direction="row" spacing={1}>
                    <MuiButton
                      variant="outlined"
                      size="small"
                      startIcon={<Edit size={16} />}
                      onClick={() => {
                        populateFormWithCampaign(selectedCampaign);
                        setShowEditModal(true);
                      }}
                    >
                      Modifier
                    </MuiButton>
                    
                    <MuiButton
                      variant="outlined"
                      size="small"
                      color="error"
                      startIcon={<Trash2 size={16} />}
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Supprimer
                    </MuiButton>
                    
                    {/* Bouton d'exécution pour les campagnes actives */}
                    {selectedCampaign.status === 'ACTIVE' && (
                      <MuiButton
                        variant="contained"
                        size="small"
                        color="primary"
                        startIcon={<Play size={16} />}
                        onClick={() => window.location.href = `/atm-campaign/cpg-04-executioncenter?campaignId=${selectedCampaign.id}`}
                      >
                        Exécuter
                      </MuiButton>
                    )}
                  </Stack>
                </Box>
                
                {/* Status et actions sur le statut */}
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                  <Chip 
                    label={selectedCampaign.status}
                    color={getStatusColor(selectedCampaign.status)}
                  />
                  
                  {/* Actions de changement de statut */}
                  {selectedCampaign.status === 'DRAFT' && (
                    <MuiButton
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<Check size={16} />}
                      onClick={() => updateCampaignStatus('ACTIVE')}
                    >
                      Activer
                    </MuiButton>
                  )}
                  
                  {selectedCampaign.status === 'ACTIVE' && (
                    <MuiButton
                      variant="contained"
                      size="small"
                      color="warning"
                      startIcon={<Pause size={16} />}
                      onClick={() => updateCampaignStatus('ON_HOLD')}
                    >
                      Mettre en attente
                    </MuiButton>
                  )}
                  
                  {selectedCampaign.status === 'ON_HOLD' && (
                    <MuiButton
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<Play size={16} />}
                      onClick={() => updateCampaignStatus('ACTIVE')}
                    >
                      Reprendre
                    </MuiButton>
                  )}
                  
                  {(selectedCampaign.status === 'ACTIVE' || selectedCampaign.status === 'ON_HOLD') && (
                    <MuiButton
                      variant="contained"
                      size="small"
                      color="error"
                      startIcon={<X size={16} />}
                      onClick={() => updateCampaignStatus('CANCELLED')}
                    >
                      Annuler
                    </MuiButton>
                  )}
                  
                  {selectedCampaign.status === 'COMPLETED' && (
                    <MuiButton
                      variant="contained"
                      size="small"
                      color="secondary"
                      startIcon={<Archive size={16} />}
                      onClick={() => updateCampaignStatus('ARCHIVED')}
                    >
                      Archiver
                    </MuiButton>
                  )}
                </Box>
              </Box>
              
              {/* Onglets pour la navigation */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                  value={selectedTab} 
                  onChange={(e, newValue) => setSelectedTab(newValue)}
                  aria-label="campaign tabs"
                >
                  <Tab 
                    label="Vue d'ensemble" 
                    value="overview" 
                    icon={<Info size={18} />} 
                    iconPosition="start"
                  />
                  <Tab 
                    label="Cas de test" 
                    value="testcases" 
                    icon={<FileCode size={18} />} 
                    iconPosition="start"
                  />
                  <Tab 
                    label="Exécutions" 
                    value="executions" 
                    icon={<Play size={18} />} 
                    iconPosition="start"
                  />
                  <Tab 
                    label="Paramètres" 
                    value="settings" 
                    icon={<Settings size={18} />} 
                    iconPosition="start"
                  />
                </Tabs>
              </Box>
              
              {/* Contenu des onglets */}
              <Box sx={{ p: 3, overflowY: 'auto' }}>
                {loading.campaign ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                    <CircularProgress />
                  </Box>
                ) : error.campaign ? (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {error.campaign}
                  </Alert>
                ) : (
                  <>
                    {/* Onglet Vue d'ensemble */}
                    {selectedTab === 'overview' && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        {/* Description et objectif */}
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MuiCard sx={{ height: '100%', boxShadow: 2 }}>
                              <CardHeader 
                                title="Description" 
                                avatar={<FileText size={20} color={COLORS.primary} />}
                                titleTypographyProps={{ variant: 'h6' }}
                              />
                              <Divider />
                              <CardContent>
                                <Typography 
                                  variant="body2" 
                                  sx={{ whiteSpace: 'pre-line', color: selectedCampaign.description ? 'text.primary' : 'text.secondary' }}
                                >
                                  {selectedCampaign.description || 'Aucune description disponible'}
                                </Typography>
                              </CardContent>
                            </MuiCard>
                          </Grid>
                          
                          <Grid item xs={12} md={6}>
                            <MuiCard sx={{ height: '100%', boxShadow: 2 }}>
                              <CardHeader 
                                title="Objectif" 
                                avatar={<Target size={20} color={COLORS.primary} />}
                                titleTypographyProps={{ variant: 'h6' }}
                              />
                              <Divider />
                              <CardContent>
                                <Typography 
                                  variant="body2" 
                                  sx={{ whiteSpace: 'pre-line', color: selectedCampaign.objective ? 'text.primary' : 'text.secondary' }}
                                >
                                  {selectedCampaign.objective || 'Aucun objectif défini'}
                                </Typography>
                              </CardContent>
                            </MuiCard>
                          </Grid>
                        </Grid>
                        
                        {/* Détails de la campagne */}
                        <MuiCard sx={{ boxShadow: 2 }}>
                          <CardHeader 
                            title="Détails" 
                            avatar={<List size={20} color={COLORS.primary} />}
                            titleTypographyProps={{ variant: 'h6' }}
                          />
                          <Divider />
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                  Date de création
                                </Typography>
                                <Typography variant="body2">
                                  <DateFormatter date={selectedCampaign.creationDate} format="full" />
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                  Date de début
                                </Typography>
                                <Typography variant="body2">
                                  {selectedCampaign.startDate ? (
                                    <DateFormatter date={selectedCampaign.startDate} format="full" />
                                  ) : (
                                    <Typography variant="body2" color="text.secondary">Non définie</Typography>
                                  )}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                  Date de fin
                                </Typography>
                                <Typography variant="body2">
                                  {selectedCampaign.endDate ? (
                                    <DateFormatter date={selectedCampaign.endDate} format="full" />
                                  ) : (
                                    <Typography variant="body2" color="text.secondary">Non définie</Typography>
                                  )}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                  Type de récurrence
                                </Typography>
                                <Typography variant="body2">
                                  {selectedCampaign.recurrenceType || 'PONCTUEL'}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                  Priorité
                                </Typography>
                                <Typography variant="body2">
                                  {selectedCampaign.priority || 0}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                  Statut d'activité
                                </Typography>
                                <Chip 
                                  color={selectedCampaign.isActive ? "success" : "default"}
                                  size="small"
                                  label={selectedCampaign.isActive ? "Actif" : "Inactif"}
                                  icon={selectedCampaign.isActive ? <CheckCircle size={16} /> : <XCircle size={16} />}
                                />
                              </Grid>
                              
                              {selectedCampaign.createdBy && (
                                <Grid item xs={12} sm={6} md={4}>
                                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                    Créée par
                                  </Typography>
                                  <Typography variant="body2">
                                    {selectedCampaign.createdBy}
                                  </Typography>
                                </Grid>
                              )}
                              
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                  Tags
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                  {selectedCampaign.tags ? (
                                    selectedCampaign.tags.split(',').map((tag, index) => (
                                      <Chip 
                                        key={index}
                                        label={tag.trim()}
                                        size="small"
                                      />
                                    ))
                                  ) : (
                                    <Typography variant="body2" color="text.secondary">Aucun tag</Typography>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </MuiCard>
                        
                        {/* Résumé des cas de test */}
                        <MuiCard sx={{ boxShadow: 2 }}>
                          <CardHeader 
                            title="Cas de test" 
                            avatar={<CheckCircle size={20} color={COLORS.success} />}
                            titleTypographyProps={{ variant: 'h6' }}
                            action={
                              <MuiButton
                                variant="outlined"
                                size="small"
                                onClick={() => setSelectedTab('testcases')}
                              >
                                Voir tous les cas de test
                              </MuiButton>
                            }
                          />
                          <Divider />
                          <CardContent>
                            {testCases.length === 0 ? (
                              <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                py: 4
                              }}>
                                <FileText size={48} style={{ opacity: 0.5, marginBottom: 16 }} />
                                <Typography color="text.secondary" gutterBottom>
                                  Aucun cas de test associé à cette campagne
                                </Typography>
                                <MuiButton
                                  variant="outlined"
                                  size="small"
                                  sx={{ mt: 2 }}
                                  startIcon={<Plus size={16} />}
                                  onClick={() => {
                                    fetchAvailableTestCases();
                                    setShowTestCaseModal(true);
                                  }}
                                >
                                  Ajouter des cas de test
                                </MuiButton>
                              </Box>
                            ) : (
                              <>
                                <Box sx={{ mb: 2 }}>
                                  <Typography variant="body2" color="text.secondary">
                                    {testCases.length} cas de test associés
                                  </Typography>
                                </Box>
                                
                                <TableContainer>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Critique</TableCell>
                                        <TableCell>Ordre</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {testCases.slice(0, 3).map((testCase) => (
                                        <TableRow key={testCase.id} hover>
                                          <TableCell>
                                            <Typography variant="body2">
                                              {testCase.name}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                              {testCase.testCaseId}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            {testCase.isCritical ? (
                                              <Chip 
                                                size="small" 
                                                label="Critique"
                                                color="error"
                                              />
                                            ) : (
                                              <Typography variant="body2" color="text.secondary">
                                                Non
                                              </Typography>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {testCase.executionOrder || '-'}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                
                                {testCases.length > 3 && (
                                  <Box sx={{ mt: 2, textAlign: 'center' }}>
                                    <MuiButton
                                      variant="text"
                                      size="small"
                                      endIcon={<ChevronRight size={16} />}
                                      onClick={() => setSelectedTab('testcases')}
                                    >
                                      Voir {testCases.length - 3} cas de test supplémentaires
                                    </MuiButton>
                                  </Box>
                                )}
                              </>
                            )}
                          </CardContent>
                        </MuiCard>
                      </Box>
                    )}
                    
                    {/* Onglet Cas de test */}
                    {selectedTab === 'testcases' && (
                      <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                          <Typography variant="h6">
                            Cas de test associés
                          </Typography>
                          <MuiButton
                            variant="contained"
                            color="primary"
                            startIcon={<Plus size={16} />}
                            onClick={() => {
                              fetchAvailableTestCases();
                              setShowTestCaseModal(true);
                            }}
                          >
                            Ajouter des cas de test
                          </MuiButton>
                        </Box>
                        
                        {loading.testCases ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                            <CircularProgress />
                          </Box>
                        ) : error.testCases ? (
                          <Alert severity="error" sx={{ mb: 2 }}>
                            {error.testCases}
                          </Alert>
                        ) : testCases.length === 0 ? (
                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            py: 8
                          }}>
                            <FileText size={64} style={{ opacity: 0.5, marginBottom: 16 }} />
                            <Typography color="text.secondary">
                              Aucun cas de test associé à cette campagne
                            </Typography>
                          </Box>
                        ) : (
                          <MuiCard sx={{ boxShadow: 2 }}>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Critique</TableCell>
                                    <TableCell>Ordre</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {testCases.map((testCase) => (
                                    <TableRow key={testCase.id} hover>
                                      <TableCell>
                                        <Typography variant="body2" fontWeight="medium">
                                          {testCase.name}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                          {testCase.testCaseId}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2" noWrap sx={{ maxWidth: 300 }}>
                                          {testCase.description || 'Aucune description'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {testCase.isCritical ? (
                                          <Chip 
                                            size="small" 
                                            label="Critique"
                                            color="error"
                                          />
                                        ) : (
                                          <Typography variant="body2" color="text.secondary">
                                            Non
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {testCase.executionOrder || '-'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="right">
                                        <MuiButton
                                          variant="outlined"
                                          size="small"
                                          color="error"
                                          startIcon={<X size={16} />}
                                          onClick={() => removeTestCase(testCase.id)}
                                        >
                                          Retirer
                                        </MuiButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </MuiCard>
                        )}
                      </Box>
                    )}
                    
                    {/* Onglet Exécutions */}
                    {selectedTab === 'executions' && (
                      <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                          <Typography variant="h6">
                            Historique des exécutions
                          </Typography>
                          <MuiButton
                            variant="contained"
                            color="primary"
                            startIcon={<Play size={16} />}
                            onClick={() => window.location.href = `/atm-campaign/cpg-04-executioncenter?campaignId=${selectedCampaign.id}`}
                          >
                            Nouvelle exécution
                          </MuiButton>
                        </Box>
                        
                        {/* Ici on pourrait afficher l'historique des exécutions de cette campagne */}
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center', 
                          justifyContent: 'center',
                          p: 8,
                          bgcolor: 'background.paper',
                          borderRadius: 1,
                          boxShadow: 2
                        }}>
                          <Clock size={64} style={{ opacity: 0.5, marginBottom: 16 }} />
                          <Typography color="text.secondary" gutterBottom>
                            L'historique des exécutions sera disponible ici
                          </Typography>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            Consultez le centre d'exécution pour plus de détails
                          </Typography>
                          <MuiButton
                            variant="outlined"
                            size="small"
                            className="mt-4"
                            onClick={() => window.location.href = '/atm-campaign/cpg-04-executioncenter'}
                            sx={{ mt: 2 }}
                          >
                            Aller au centre d'exécution
                          </MuiButton>
                        </Box>
                      </Box>
                    )}
                    
                    {/* Onglet Paramètres */}
                    {selectedTab === 'settings' && (
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          Paramètres de la campagne
                        </Typography>
                        
                        <Grid container spacing={3}>
                          {/* Paramètres généraux */}
                          <Grid item xs={12} md={6}>
                            <MuiCard sx={{ boxShadow: 2 }}>
                              <CardHeader 
                                title="Paramètres généraux" 
                                avatar={<Settings size={20} color={COLORS.primary} />}
                                titleTypographyProps={{ variant: 'h6' }}
                              />
                              <Divider />
                              <CardContent>
                                <MuiButton
                                  variant="outlined"
                                  fullWidth
                                  startIcon={<Edit size={16} />}
                                  onClick={() => {
                                    populateFormWithCampaign(selectedCampaign);
                                    setShowEditModal(true);
                                  }}
                                  sx={{ mb: 2 }}
                                >
                                  Modifier les détails de la campagne
                                </MuiButton>
                                
                                <Divider sx={{ my: 2 }} />
                                
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={selectedCampaign.isActive}
                                      onChange={async (e) => {
                                        const updatedCampaign = {
                                          ...selectedCampaign,
                                          isActive: e.target.checked
                                        };
                                        await api.automata.campaigns.update(selectedCampaign.id, updatedCampaign);
                                        await fetchCampaignDetails(selectedCampaign.id);
                                      }}
                                      color="success"
                                    />
                                  }
                                  label="Campagne active"
                                />
                                <FormHelperText>
                                  Les campagnes inactives ne peuvent pas être exécutées
                                </FormHelperText>
                              </CardContent>
                            </MuiCard>
                          </Grid>
                          
                          {/* Actions avancées */}
                          <Grid item xs={12} md={6}>
                            <MuiCard sx={{ boxShadow: 2 }}>
                              <CardHeader 
                                title="Actions avancées" 
                                avatar={<AlertTriangle size={20} color={COLORS.warning} />}
                                titleTypographyProps={{ variant: 'h6' }}
                              />
                              <Divider />
                              <CardContent>
                                <Stack spacing={2}>
                                  <MuiButton
                                    variant="outlined"
                                    fullWidth
                                    startIcon={<ArrowRight size={16} />}
                                    onClick={() => window.location.href = `/atm-campaign/cpg-06-reportinghub?campaignId=${selectedCampaign.id}`}
                                  >
                                    Générer un rapport de campagne
                                  </MuiButton>
                                  
                                  <MuiButton
                                    variant="outlined"
                                    color="error"
                                    fullWidth
                                    startIcon={<Trash2 size={16} />}
                                    onClick={() => setShowDeleteModal(true)}
                                  >
                                    Supprimer la campagne
                                  </MuiButton>
                                </Stack>
                              </CardContent>
                            </MuiCard>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center', 
              height: '100%',
              p: 4
            }}>
              <FolderOpen size={64} style={{ opacity: 0.5, marginBottom: 16 }} />
              <Typography variant="h6" gutterBottom>
                Aucune campagne sélectionnée
              </Typography>
              <Typography color="text.secondary" textAlign="center" sx={{ mb: 4, maxWidth: 450 }}>
                Sélectionnez une campagne dans la liste pour voir ses détails ou créez-en une nouvelle.
              </Typography>
              <MuiButton 
                variant="contained" 
                color="primary"
                startIcon={<Plus size={18} />}
                onClick={() => {
                  resetCampaignForm();
                  setShowCreateModal(true);
                }}
              >
                Créer une campagne
              </MuiButton>
            </Box>
          )}
        </Box>
      </Box>
      
      {/* Modal de création de campagne */}
      <Dialog 
        open={showCreateModal} 
        onClose={() => setShowCreateModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Créer une nouvelle campagne
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nom de la campagne"
                name="name"
                value={campaignForm.name}
                onChange={handleFormChange}
                required
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Type de récurrence</InputLabel>
                <Select
                  name="recurrenceType"
                  value={campaignForm.recurrenceType}
                  onChange={handleFormChange}
                  label="Type de récurrence"
                >
                  <MenuItem value="PONCTUEL">Ponctuel</MenuItem>
                  <MenuItem value="RECURRENT">Récurrent</MenuItem>
                  <MenuItem value="PLANIFIE">Planifié</MenuItem>
                  <MenuItem value="A_LA_DEMANDE">À la demande</MenuItem>
                  <MenuItem value="EVENT_DRIVEN">Événementiel</MenuItem>
                  <MenuItem value="POST_DEPLOIEMENT">Post-déploiement</MenuItem>
                  <MenuItem value="HEBDOMADAIRE">Hebdomadaire</MenuItem>
                  <MenuItem value="JOURNALIER">Journalier</MenuItem>
                  <MenuItem value="MENSUEL">Mensuel</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={campaignForm.description}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Objectif"
                name="objective"
                value={campaignForm.objective}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date de début"
                name="startDate"
                type="date"
                value={campaignForm.startDate}
                onChange={handleFormChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date de fin"
                name="endDate"
                type="date"
                value={campaignForm.endDate}
                onChange={handleFormChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Priorité"
                name="priority"
                type="number"
                value={campaignForm.priority}
                onChange={handleFormChange}
                margin="normal"
                inputProps={{ min: 0, max: 10 }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Tags (séparés par des virgules)"
                name="tags"
                value={campaignForm.tags}
                onChange={handleFormChange}
                margin="normal"
                placeholder="tag1, tag2, tag3"
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={campaignForm.isActive}
                    onChange={handleFormChange}
                    color="success"
                  />
                }
                label="Campagne active"
              />
            </Grid>
          </Grid>
          
          {error.form && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error.form}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowCreateModal(false)}
            disabled={loading.form}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="primary"
            onClick={createCampaign}
            disabled={!campaignForm.name || loading.form}
            startIcon={loading.form ? <CircularProgress size={16} /> : <Save size={16} />}
          >
            Créer la campagne
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Modal de modification de campagne */}
      <Dialog 
        open={showEditModal} 
        onClose={() => setShowEditModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Modifier la campagne
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nom de la campagne"
                name="name"
                value={campaignForm.name}
                onChange={handleFormChange}
                required
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Type de récurrence</InputLabel>
                <Select
                  name="recurrenceType"
                  value={campaignForm.recurrenceType}
                  onChange={handleFormChange}
                  label="Type de récurrence"
                >
                  <MenuItem value="PONCTUEL">Ponctuel</MenuItem>
                  <MenuItem value="RECURRENT">Récurrent</MenuItem>
                  <MenuItem value="PLANIFIE">Planifié</MenuItem>
                  <MenuItem value="A_LA_DEMANDE">À la demande</MenuItem>
                  <MenuItem value="EVENT_DRIVEN">Événementiel</MenuItem>
                  <MenuItem value="POST_DEPLOIEMENT">Post-déploiement</MenuItem>
                  <MenuItem value="HEBDOMADAIRE">Hebdomadaire</MenuItem>
                  <MenuItem value="JOURNALIER">Journalier</MenuItem>
                  <MenuItem value="MENSUEL">Mensuel</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={campaignForm.description}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Objectif"
                name="objective"
                value={campaignForm.objective}
                onChange={handleFormChange}
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date de début"
                name="startDate"
                type="date"
                value={campaignForm.startDate}
                onChange={handleFormChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date de fin"
                name="endDate"
                type="date"
                value={campaignForm.endDate}
                onChange={handleFormChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Priorité"
                name="priority"
                type="number"
                value={campaignForm.priority}
                onChange={handleFormChange}
                margin="normal"
                inputProps={{ min: 0, max: 10 }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Tags (séparés par des virgules)"
                name="tags"
                value={campaignForm.tags}
                onChange={handleFormChange}
                margin="normal"
                placeholder="tag1, tag2, tag3"
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={campaignForm.isActive}
                    onChange={handleFormChange}
                    color="success"
                  />
                }
                label="Campagne active"
              />
            </Grid>
          </Grid>
          
          {error.form && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error.form}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowEditModal(false)}
            disabled={loading.form}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="primary"
            onClick={updateCampaign}
            disabled={!campaignForm.name || loading.form}
            startIcon={loading.form ? <CircularProgress size={16} /> : <Save size={16} />}
          >
            Enregistrer les modifications
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Modal de confirmation de suppression */}
      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Confirmer la suppression
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" paragraph>
            Êtes-vous sûr de vouloir supprimer la campagne "{selectedCampaign?.name}" ? Cette action est irréversible.
          </Typography>
          <Alert severity="warning">
            Cette action ne peut pas être annulée. Tous les cas de test associés seront dissociés de cette campagne.
          </Alert>
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowDeleteModal(false)}
            disabled={loading.form}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="error"
            onClick={deleteCampaign}
            disabled={loading.form}
            startIcon={loading.form ? <CircularProgress size={16} /> : <Trash2 size={16} />}
          >
            Supprimer
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Modal d'ajout de cas de test */}
      <Dialog 
        open={showTestCaseModal} 
        onClose={() => setShowTestCaseModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          Ajouter des cas de test
        </DialogTitle>
        <DialogContent dividers>
          {loading.availableTestCases ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          ) : error.availableTestCases ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error.availableTestCases}
            </Alert>
          ) : availableTestCases.length === 0 ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              py: 4
            }}>
              <FileText size={48} style={{ opacity: 0.5, marginBottom: 16 }} />
              <Typography color="text.secondary" gutterBottom>
                Aucun cas de test disponible
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Tous les cas de test sont déjà associés à cette campagne ou aucun cas de test n'a été créé.
              </Typography>
              <MuiButton
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
                onClick={() => window.location.href = '/atm-campaign/cpg-03-testcaselibrary'}
              >
                Aller à la bibliothèque de cas de test
              </MuiButton>
            </Box>
          ) : (
            <>
              <TextField
                fullWidth
                size="small"
                placeholder="Rechercher des cas de test..."
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={18} />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => {
                  const searchValue = e.target.value.toLowerCase();
                  if (searchValue) {
                    setAvailableTestCases(availableTestCases.filter(
                      tc => tc.name.toLowerCase().includes(searchValue) || 
                            (tc.description && tc.description.toLowerCase().includes(searchValue)) ||
                            (tc.testCaseId && tc.testCaseId.toLowerCase().includes(searchValue))
                    ));
                  } else {
                    fetchAvailableTestCases();
                  }
                }}
              />
              
              <TableContainer sx={{ mt: 2, maxHeight: 400 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Critique</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {availableTestCases.map((testCase) => (
                      <TableRow key={testCase.id} hover>
                        <TableCell>
                          <Typography variant="body2" fontWeight="medium">
                            {testCase.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {testCase.testCaseId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" noWrap sx={{ maxWidth: 300 }}>
                            {testCase.description || 'Aucune description'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {testCase.isCritical ? (
                            <Chip 
                              size="small" 
                              label="Critique"
                              color="error"
                            />
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              Non
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <MuiButton
                            variant="contained"
                            size="small"
                            color="primary"
                            startIcon={<Plus size={16} />}
                            onClick={() => addTestCase(testCase.id)}
                          >
                            Ajouter
                          </MuiButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowTestCaseModal(false)}
          >
            Fermer
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CampaignManager;