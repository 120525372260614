// real-time-utils.js - Version WebSocket

export const createRealTimeConnection = (options = {}) => {
  const {
    baseUrl,
    endpoint = '/ws',
    userId = 'default-user',
    debug = false,
    reconnectDelay = 2000,
    maxReconnectAttempts = 5
  } = options;
  
  let connectionMode = 'INITIALIZING';
  let webSocket = null;
  let reconnectAttempts = 0;
  let reconnectTimeout = null;
  
  const eventListeners = {
    message: [],
    open: [],
    error: [],
    close: []
  };
  
  const log = (...args) => {
    if (debug) console.log(`[RealTime:${connectionMode}]`, ...args);
  };
  
  // Déclenche les événements pour tous les listeners enregistrés
  const triggerEvent = (eventType, data) => {
    if (eventListeners[eventType]) {
      const evt = { type: eventType, data, timestamp: Date.now() };
      eventListeners[eventType].forEach(listener => listener(evt));
    }
  };
  
  // Fonction pour se connecter
  const connect = () => {
    try {
      // Construire l'URL avec les paramètres
      const wsUrl = new URL(`${baseUrl.replace(/^http/, 'ws')}${endpoint}`);
      wsUrl.searchParams.append('userId', userId);
      
      log(`Connecting to WebSocket: ${wsUrl.toString()}`);
      
      // Fermer toute connexion existante
      if (webSocket) {
        try {
          webSocket.close();
        } catch (e) {
          log('Error closing existing connection', e);
        }
      }
      
      // Créer une nouvelle connexion
      webSocket = new WebSocket(wsUrl.toString());
      
      webSocket.onopen = (event) => {
        connectionMode = 'CONNECTED';
        reconnectAttempts = 0;
        log('WebSocket connection established');
        triggerEvent('open', event);
      };
      
      webSocket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          log('Message received', data);
          triggerEvent('message', { data });
        } catch (e) {
          log('Error parsing message', e);
          triggerEvent('message', { data: event.data });
        }
      };
      
      webSocket.onerror = (error) => {
        log('WebSocket error', error);
        connectionMode = 'ERROR';
        triggerEvent('error', { error });
      };
      
      webSocket.onclose = (event) => {
        log(`WebSocket closed: ${event.code} - ${event.reason}`);
        connectionMode = 'CLOSED';
        triggerEvent('close', event);
        
        // Tenter de se reconnecter
        if (reconnectAttempts < maxReconnectAttempts) {
          reconnectAttempts++;
          const delay = reconnectDelay * Math.pow(1.5, reconnectAttempts - 1);
          log(`Reconnecting in ${delay}ms (attempt ${reconnectAttempts}/${maxReconnectAttempts})`);
          
          reconnectTimeout = setTimeout(() => {
            connect();
          }, delay);
        } else {
          log(`Max reconnect attempts reached (${maxReconnectAttempts}). Giving up.`);
        }
      };
      
      return webSocket;
    } catch (error) {
      log('Error creating WebSocket', error);
      connectionMode = 'ERROR';
      triggerEvent('error', { error });
      return null;
    }
  };
  
  // API publique
  return {
    connect,
    
    addEventListener: (eventType, callback) => {
      if (eventListeners[eventType]) {
        eventListeners[eventType].push(callback);
      }
    },
    
    removeEventListener: (eventType, callback) => {
      if (eventListeners[eventType]) {
        eventListeners[eventType] = eventListeners[eventType].filter(cb => cb !== callback);
      }
    },
    
    sendMessage: (message) => {
      if (!webSocket || webSocket.readyState !== WebSocket.OPEN) {
        log('Cannot send message: WebSocket not connected');
        return false;
      }
      
      try {
        const messageStr = typeof message === 'string' ? message : JSON.stringify(message);
        webSocket.send(messageStr);
        return true;
      } catch (error) {
        log('Error sending message', error);
        return false;
      }
    },
    
    close: () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
      
      if (webSocket) {
        try {
          webSocket.close();
        } catch (e) {
          log('Error closing WebSocket', e);
        }
        webSocket = null;
      }
      
      connectionMode = 'CLOSED';
    },
    
    getStatus: () => ({
      mode: connectionMode,
      connected: webSocket && webSocket.readyState === WebSocket.OPEN,
      reconnectAttempts
    })
  };
};