// components/shared/workflow/EnhancedWorkflowStudio.js
import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MiniMap,
  Panel
} from 'reactflow';
import 'reactflow/dist/style.css';
import { AlertTriangle, Save, Download, Upload, RotateCcw, Info, Check } from 'lucide-react';

// Import des composants améliorés
import { nodeTypes, defaultEdgeOptions } from './WorkflowNodes';
import ImprovedNodeModal from './ImprovedNodeModal';
import ImprovedEdgeModal from './ImprovedEdgeModal';
import { WorkflowSettingsModal, ConfirmResetModal } from './WorkflowModals';
import { 
  WorkflowHeader, 
  NodeToolsPanel, 
  SelectionControlsPanel 
} from './WorkflowControls';
import { 
  generateId, 
  convertToApiFormat, 
  processImportedWorkflow, 
  exportWorkflow 
} from './WorkflowUtils';
import { WorkflowValidator } from './WorkflowValidator';
import { WorkflowAnalyzer } from './WorkflowAnalyzer';
import WorkflowAnalysisPanel from './WorkflowAnalysisPanel';
import { workflowDomainManager } from './WorkflowDomainManager';

const NodeModal = ImprovedNodeModal;
const EdgeModal = ImprovedEdgeModal;

/**
 * Studio de workflow amélioré intégrant tous les composants avancés
 */
function EnhancedWorkflowStudio({
  initialWorkflow = null,
  workflowType = null,
  onSave = null,
  readOnly = false
}) {
  // États pour le flux de travail
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  
  // États pour les modaux et sélections
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [isAddingNode, setIsAddingNode] = useState(false);
  const [isEditingNode, setIsEditingNode] = useState(false);
  const [isAddingEdge, setIsAddingEdge] = useState(false);
  const [isEditingEdge, setIsEditingEdge] = useState(false);
  const [newNodeType, setNewNodeType] = useState('stateNode');
  const [newNode, setNewNode] = useState({ type: 'stateNode', data: { label: '', description: '' } });
  const [newEdge, setNewEdge] = useState({ source: '', target: '', label: '', data: { isAutomatic: false, roles: [], actions: [] } });
  
  // États pour les paramètres du workflow
  const [workflowName, setWorkflowName] = useState('Nouveau Workflow');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [workflowParameters, setWorkflowParameters] = useState({});
  
  // États pour les modaux de configuration
  const [showWorkflowSettings, setShowWorkflowSettings] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  
  // États pour les notifications
  const [notification, setNotification] = useState(null);
  
  // Référence au conteneur ReactFlow
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  
  // Charger un workflow initial si fourni
  useEffect(() => {
    if (initialWorkflow) {
      try {
        const { nodes: newNodes, edges: newEdges, name, description } = processImportedWorkflow(initialWorkflow);
        setNodes(newNodes);
        setEdges(newEdges);
        setWorkflowName(name || 'Workflow importé');
        setWorkflowDescription(description || '');
        setWorkflowParameters(initialWorkflow.parameters || {});
      } catch (error) {
        console.error('Erreur lors du chargement du workflow initial:', error);
        showNotification({
          type: 'error',
          message: 'Erreur lors du chargement du workflow initial'
        });
      }
    }
  }, [initialWorkflow]);
  
  // Gestionnaire de connexion
  const onConnect = useCallback((params) => {
    const sourceNode = nodes.find(node => node.id === params.source);
    const targetNode = nodes.find(node => node.id === params.target);
    
    setNewEdge({
      ...newEdge,
      source: params.source,
      target: params.target,
      sourceNode,
      targetNode
    });
    setIsAddingEdge(true);
  }, [newEdge, nodes]);
  
  // Sélection de nœud ou d'arête
  const onSelectionChange = useCallback(({ nodes, edges }) => {
    if (nodes && nodes.length === 1) {
      setSelectedNode(nodes[0]);
      setSelectedEdge(null);
    } else if (edges && edges.length === 1) {
      setSelectedEdge(edges[0]);
      setSelectedNode(null);
    } else {
      setSelectedNode(null);
      setSelectedEdge(null);
    }
  }, []);
  
  // Initialiser le gestionnaire de domaine
  useEffect(() => {
    const initializeDomainManager = async () => {
      try {
        await workflowDomainManager.initialize();
      } catch (error) {
        console.error('Erreur lors de l\'initialisation du gestionnaire de domaine:', error);
      }
    };
    
    initializeDomainManager();
  }, []);
  
  // Gestionnaire d'ajout de nœud
  const onAddNode = useCallback((nodeType) => {
    setNewNodeType(nodeType);
    setNewNode({
      type: nodeType,
      data: { label: '', description: '' }
    });
    setIsAddingNode(true);
  }, []);
  
  // Soumission d'un nouveau nœud
  const handleNodeSubmit = useCallback(() => {
    if (!newNode.data?.label) return;
    
    const position = reactFlowInstance 
      ? reactFlowInstance.project({ 
          x: window.innerWidth / 3, 
          y: window.innerHeight / 3 
        }) 
      : { x: 100, y: 100 };
    
    const nodeId = generateId();
    
    // Si le code n'est pas défini, le générer à partir du label
    if (!newNode.data.code) {
      newNode.data.code = newNode.data.label.toUpperCase().replace(/\s+/g, '_');
    }
    
    const newNodeObj = {
      id: nodeId,
      type: newNode.type,
      position,
      data: { ...newNode.data }
    };
    
    setNodes((nds) => [...nds, newNodeObj]);
    setNewNode({ type: 'stateNode', data: { label: '', description: '' } });
    setIsAddingNode(false);
    
    showNotification({
      type: 'success',
      message: `État "${newNode.data.label}" ajouté avec succès`
    });
  }, [newNode, reactFlowInstance, setNodes]);
  
  // Soumission d'une nouvelle connexion
  const handleEdgeSubmit = useCallback(() => {
    if (!newEdge.source || !newEdge.target || !newEdge.label) return;
    
    const edgeId = `edge-${newEdge.source}-${newEdge.target}-${Date.now()}`;
    
    const newEdgeObj = {
      id: edgeId,
      source: newEdge.source,
      target: newEdge.target,
      label: newEdge.label,
      data: { ...newEdge.data }
    };
    
    setEdges((eds) => addEdge(newEdgeObj, eds));
    setNewEdge({ 
      source: '', 
      target: '', 
      label: '', 
      data: { 
        isAutomatic: false, 
        roles: [], 
        actions: [] 
      } 
    });
    setIsAddingEdge(false);
    
    showNotification({
      type: 'success',
      message: `Transition "${newEdge.label}" ajoutée avec succès`
    });
  }, [newEdge, setEdges]);
  
  // Mise à jour d'un nœud
  const handleNodeUpdate = useCallback(() => {
    if (!selectedNode || !selectedNode.id) return;
    
    setNodes((nds) => nds.map((node) => {
      if (node.id === selectedNode.id) {
        return {
          ...node,
          data: { ...selectedNode.data }
        };
      }
      return node;
    }));
    
    setIsEditingNode(false);
    
    showNotification({
      type: 'success',
      message: `État "${selectedNode.data.label}" mis à jour avec succès`
    });
  }, [selectedNode, setNodes]);
  
  // Mise à jour d'une connexion
  const handleEdgeUpdate = useCallback(() => {
    if (!selectedEdge || !selectedEdge.id) return;
    
    setEdges((eds) => eds.map((edge) => {
      if (edge.id === selectedEdge.id) {
        return {
          ...edge,
          label: selectedEdge.label,
          data: { ...selectedEdge.data }
        };
      }
      return edge;
    }));
    
    setIsEditingEdge(false);
    
    showNotification({
      type: 'success',
      message: `Transition "${selectedEdge.label}" mise à jour avec succès`
    });
  }, [selectedEdge, setEdges]);
  
  // Suppression d'un élément sélectionné
  const handleDelete = useCallback(() => {
    if (selectedNode) {
      // Vérifier s'il s'agit d'un état initial ou final
      if (selectedNode.type === 'initialNode') {
        showNotification({
          type: 'error',
          message: 'L\'état initial ne peut pas être supprimé'
        });
        return;
      }
      
      const nodeName = selectedNode.data?.label || 'État sélectionné';
      
      setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id));
      
      // Supprimer également toutes les transitions liées à ce nœud
      setEdges((eds) => eds.filter((edge) => 
        edge.source !== selectedNode.id && edge.target !== selectedNode.id
      ));
      
      setSelectedNode(null);
      
      showNotification({
        type: 'success',
        message: `État "${nodeName}" supprimé avec succès`
      });
    } else if (selectedEdge) {
      const edgeLabel = selectedEdge.label || 'Transition sélectionnée';
      
      setEdges((eds) => eds.filter((edge) => edge.id !== selectedEdge.id));
      setSelectedEdge(null);
      
      showNotification({
        type: 'success',
        message: `Transition "${edgeLabel}" supprimée avec succès`
      });
    }
  }, [selectedNode, selectedEdge, setNodes, setEdges]);
  
  // Importation d'un workflow
  const handleImport = useCallback((event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const workflow = JSON.parse(e.target.result);
        const { nodes: newNodes, edges: newEdges, name, description } = processImportedWorkflow(workflow);
        
        setWorkflowName(name);
        setWorkflowDescription(description);
        setWorkflowParameters(workflow.parameters || {});
        setNodes(newNodes);
        setEdges(newEdges);
        
        showNotification({
          type: 'success',
          message: `Workflow "${name}" importé avec succès`
        });
      } catch (error) {
        console.error('Erreur lors de l\'importation du workflow:', error);
        showNotification({
          type: 'error',
          message: 'Le fichier importé n\'est pas valide'
        });
      }
    };
    reader.readAsText(file);
    
    // Réinitialiser l'input de fichier
    event.target.value = null;
  }, [setNodes, setEdges]);
  
  // Exportation du workflow
  const handleExport = useCallback(() => {
    // Valider le workflow avant l'export
    const validation = WorkflowValidator.validateWorkflow(nodes, edges, workflowName);
    
    if (!validation.isValid) {
      showNotification({
        type: 'error',
        message: 'Le workflow contient des erreurs. Veuillez les corriger avant d\'exporter.',
        details: validation.errors.join(', ')
      });
      return;
    }
    
    const workflow = convertToApiFormat(
      nodes, 
      edges, 
      workflowName, 
      workflowDescription,
      workflowParameters
    );
    exportWorkflow(workflow);
    
    showNotification({
      type: 'success',
      message: `Workflow "${workflowName}" exporté avec succès`
    });
  }, [nodes, edges, workflowName, workflowDescription, workflowParameters]);
  
  // Sauvegarde du workflow
  const handleSave = useCallback(() => {
    // Valider le workflow avant la sauvegarde
    const validation = WorkflowValidator.validateWorkflow(nodes, edges, workflowName);
    
    if (!validation.isValid) {
      showNotification({
        type: 'error',
        message: 'Le workflow contient des erreurs. Veuillez les corriger avant de sauvegarder.',
        details: validation.errors.join(', ')
      });
      return;
    }
    
    const workflow = convertToApiFormat(
      nodes, 
      edges, 
      workflowName, 
      workflowDescription,
      workflowParameters
    );
    
    if (onSave) {
      onSave(workflow);
    } else {
      console.log('Workflow sauvegardé:', workflow);
      showNotification({
        type: 'success',
        message: `Workflow "${workflowName}" sauvegardé avec succès`
      });
    }
  }, [nodes, edges, workflowName, workflowDescription, workflowParameters, onSave]);
  
  // Réinitialisation du workflow
  const resetWorkflow = useCallback(() => {
    setNodes([]);
    setEdges([]);
    setWorkflowName('Nouveau Workflow');
    setWorkflowDescription('');
    setWorkflowParameters({});
    setShowConfirmReset(false);
    
    showNotification({
      type: 'info',
      message: 'Workflow réinitialisé'
    });
  }, [setNodes, setEdges]);
  
  // Mise à jour des paramètres du workflow
  const handleWorkflowSettingsChange = useCallback((field, value) => {
    if (field === 'name') {
      setWorkflowName(value);
    } else if (field === 'description') {
      setWorkflowDescription(value);
    } else if (field === 'parameters') {
      setWorkflowParameters(value);
    }
  }, []);
  
  // Affichage d'une notification
  const showNotification = useCallback((notif) => {
    setNotification(notif);
    
    // Effacer la notification après un délai
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  }, []);
  
  // Définir la source et la cible lors de l'édition d'une arête
  useEffect(() => {
    if (selectedEdge) {
      const sourceNode = nodes.find(node => node.id === selectedEdge.source);
      const targetNode = nodes.find(node => node.id === selectedEdge.target);
      
      if (sourceNode && targetNode) {
        selectedEdge.sourceNode = sourceNode;
        selectedEdge.targetNode = targetNode;
      }
    }
  }, [selectedEdge, nodes]);
  
  const nodeColor = (node) => {
    switch (node.type) {
      case 'initialNode':
        return '#10B981';
      case 'finalNode':
        return '#EF4444';
      default:
        return '#64748B';
    }
  };
  
  return (
    <div className="flex flex-col h-full">
      {/* En-tête */}
      <WorkflowHeader
        name={workflowName}
        onSettingsClick={() => setShowWorkflowSettings(true)}
        onImport={handleImport}
        onExport={handleExport}
        onReset={() => setShowConfirmReset(true)}
        onSave={handleSave}
      />
      
      {/* Notification */}
      {notification && (
        <div className={`
          fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg max-w-sm
          ${notification.type === 'error' ? 'bg-red-50 text-red-800 dark:bg-red-900/30 dark:text-red-200' : 
            notification.type === 'success' ? 'bg-green-50 text-green-800 dark:bg-green-900/30 dark:text-green-200' :
            'bg-blue-50 text-blue-800 dark:bg-blue-900/30 dark:text-blue-200'}
        `}>
          <div className="flex items-start">
            {notification.type === 'error' ? (
              <AlertTriangle className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5" />
            ) : notification.type === 'success' ? (
              <Check className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5" />
            ) : (
              <Info className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5" />
            )}
            <div>
             

              <p className="font-medium">{notification.message}</p>
              {notification.details && (
                <p className="text-sm mt-1">{notification.details}</p>
              )}
            </div>
          </div>
        </div>
      )}
      
      {/* Panneau principal */}
      <div className="flex-1 relative">
        <ReactFlowProvider>
          <div className="h-full w-full" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onSelectionChange={onSelectionChange}
              nodeTypes={nodeTypes}
              defaultEdgeOptions={defaultEdgeOptions}
              fitView
              snapToGrid
              connectionLineStyle={{ stroke: '#b1b1b7', strokeWidth: 2 }}
              snapGrid={[15, 15]}
              attributionPosition="bottom-right"
              proOptions={{ hideAttribution: true }}
              nodesDraggable={!readOnly}
              nodesConnectable={!readOnly}
              elementsSelectable={!readOnly}
            >
              <Controls />
              <MiniMap
                nodeColor={nodeColor}
                maskColor="rgba(0, 0, 0, 0.1)"
              />
              <Background color="#aaa" gap={16} />
              
              {/* Panneau d'analyse */}
              <WorkflowAnalysisPanel
                nodes={nodes}
                edges={edges}
                workflowName={workflowName}
                workflowDescription={workflowDescription}
                onJumpToNode={(nodeId) => {
                  const node = nodes.find(n => n.id === nodeId);
                  if (node) {
                    setSelectedNode(node);
                  }
                }}
              />
              
              {/* Panneau d'outils */}
              {!readOnly && (
                <NodeToolsPanel onAddNode={onAddNode} />
              )}
              
              {/* Panneau de contrôle de sélection */}
              {!readOnly && (
                <SelectionControlsPanel
                  selectedNode={selectedNode}
                  selectedEdge={selectedEdge}
                  onEditNode={() => setIsEditingNode(true)}
                  onEditEdge={() => setIsEditingEdge(true)}
                  onDelete={handleDelete}
                />
              )}
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </div>
      
      {/* Modaux */}
      <NodeModal
        isOpen={isAddingNode}
        node={newNode}
        type="add"
        nodeType={newNodeType}
        workflowType={workflowType}
        onClose={() => setIsAddingNode(false)}
        onSubmit={handleNodeSubmit}
        onChange={setNewNode}
      />
      
      <NodeModal
        isOpen={isEditingNode}
        node={selectedNode || {}}
        type="edit"
        nodeType={selectedNode?.type || 'stateNode'}
        workflowType={workflowType}
        onClose={() => setIsEditingNode(false)}
        onSubmit={handleNodeUpdate}
        onChange={setSelectedNode}
      />
      
      <EdgeModal
        isOpen={isAddingEdge}
        edge={newEdge}
        type="add"
        workflowType={workflowType}
        sourceNode={nodes.find(n => n.id === newEdge.source)}
        targetNode={nodes.find(n => n.id === newEdge.target)}
        onClose={() => setIsAddingEdge(false)}
        onSubmit={handleEdgeSubmit}
        onChange={setNewEdge}
      />
      
      <EdgeModal
        isOpen={isEditingEdge}
        edge={selectedEdge || {}}
        type="edit"
        workflowType={workflowType}
        sourceNode={selectedEdge?.sourceNode}
        targetNode={selectedEdge?.targetNode}
        onClose={() => setIsEditingEdge(false)}
        onSubmit={handleEdgeUpdate}
        onChange={setSelectedEdge}
      />
      
      <WorkflowSettingsModal
        isOpen={showWorkflowSettings}
        name={workflowName}
        description={workflowDescription}
        parameters={workflowParameters}
        onClose={() => setShowWorkflowSettings(false)}
        onSubmit={() => setShowWorkflowSettings(false)}
        onChange={handleWorkflowSettingsChange}
      />
      
      <ConfirmResetModal
        isOpen={showConfirmReset}
        onClose={() => setShowConfirmReset(false)}
        onConfirm={resetWorkflow}
      />
    </div>
  );
}

// Wrapper pour assurer que ReactFlow est bien initialisé
export default function EnhancedWorkflowStudioWrapper(props) {
  return (
    <div style={{ width: '100%', height: '90vh' }}>
      <ReactFlowProvider>
        <EnhancedWorkflowStudio {...props} />
      </ReactFlowProvider>
    </div>
  );
}