import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';

import {
  Send,
  Save,
  Download,
  Upload,
  RefreshCw,
  Copy,
  Trash2,
  Plus,
  Minus,
  Globe,
  Server,
  Wifi,
  WifiOff,
  Clock,
  ChevronDown,
  ChevronRight,
  Check,
  X,
  Code,
  FileText,
  Settings,
  Eye,
  EyeOff,
  Search,
  AlertCircle,
  Info,
  HelpCircle,
  Edit,
  MoreVertical,
  Play,
  History,
  Bookmark,
  Link as LinkIcon,
  FileCode,
  XmlIcon,
  AlertTriangle,
  FileJson
} from 'lucide-react';

/**
 * SoapApiCapture Component - Interface réutilisable pour interagir avec des API SOAP
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles de l'API SOAP
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const SoapApiCapture = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États pour l'onglet Requête
  const [url, setUrl] = useState('');
  const [wsdlUrl, setWsdlUrl] = useState('');
  const [operation, setOperation] = useState('');
  const [availableOperations, setAvailableOperations] = useState([]);
  const [headers, setHeaders] = useState([{ key: '', value: '' }]);
  const [requestBody, setRequestBody] = useState('');
  const [soapVersion, setSoapVersion] = useState('1.1'); // '1.1' ou '1.2'
  const [wsdlLoaded, setWsdlLoaded] = useState(false);
  const [customEnvelope, setCustomEnvelope] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingWsdl, setLoadingWsdl] = useState(false);
  
  // États pour l'onglet Réponse
  const [response, setResponse] = useState(null);
  const [responseHeaders, setResponseHeaders] = useState({});
  const [responseTime, setResponseTime] = useState(null);
  const [responseSize, setResponseSize] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [prettyPrint, setPrettyPrint] = useState(true);
  const [showHeaders, setShowHeaders] = useState(false);
  const [responseTabs, setResponseTabs] = useState(0);
  
  // États pour l'onglet WSDL
  const [wsdlContent, setWsdlContent] = useState(null);
  const [operationDetails, setOperationDetails] = useState(null);
  const [wsdlStructure, setWsdlStructure] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  
  // États pour l'historique des requêtes
  const [requestHistory, setRequestHistory] = useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);
  
  // États pour les collections de requêtes
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionsOpen, setCollectionsOpen] = useState(false);
  
  // États généraux
  const [activeTab, setActiveTab] = useState(0);
  const [lastAction, setLastAction] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [connectionInfo, setConnectionInfo] = useState({
    baseUrl: '',
    authType: 'none', // 'none', 'basic', 'wss-username', 'wss-signature'
    authDetails: {},
    status: 'disconnected'
  });
  
  // États pour les templates XML
  const [templates, setTemplates] = useState({});
  const [xmlNamespaces, setXmlNamespaces] = useState([
    { prefix: 'soap', uri: 'http://schemas.xmlsoap.org/soap/envelope/' }
  ]);
  
  // Références
  const responseBodyRef = useRef(null);
  
  // Effets pour récupérer les informations de connexion
  useEffect(() => {
    if (data?.technicalProperties?.connection) {
      const conn = data.technicalProperties.connection;
      setConnectionInfo({
        baseUrl: conn.baseUrl || '',
        authType: conn.authType || 'none',
        authDetails: conn.authDetails || {},
        status: connectionStatus ? 'connected' : 'disconnected'
      });
    }
  }, [data, connectionStatus]);
  
  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les résultats de la requête SOAP
      if (data.technicalProperties.soapResponse) {
        handleSoapResponse(data.technicalProperties.soapResponse);
      }
      
      // Traiter le contenu WSDL
      if (data.technicalProperties.wsdlContent) {
        handleWsdlContent(data.technicalProperties.wsdlContent);
      }
      
      // Traiter les détails d'opération
      if (data.technicalProperties.operationDetails) {
        setOperationDetails(data.technicalProperties.operationDetails);
      }
    }
  }, [data]);
  
  // Handler pour la réponse SOAP
  const handleSoapResponse = (soapResponse) => {
    setIsLoading(false);
    
    // Traiter la réponse
    if (soapResponse) {
      setResponse(soapResponse.body || '');
      setResponseHeaders(soapResponse.headers || {});
      setResponseTime(soapResponse.responseTime || 0);
      setResponseSize(soapResponse.size || 0);
      setResponseStatus(soapResponse.status || 0);
      
      // Ajouter à l'historique
      const historyItem = {
        id: Date.now().toString(),
        operation,
        url,
        headers: [...headers],
        body: requestBody,
        soapVersion,
        status: soapResponse.status,
        timestamp: new Date(),
        responseTime: soapResponse.responseTime
      };
      
      setRequestHistory(prev => [historyItem, ...prev]);
      
      // Mettre à jour la dernière action
      setLastAction({
        type: 'SoapRequest',
        name: `${operation} @ ${url}`,
        timestamp: new Date()
      });
    }
  };
  
  const xmlPlaceholder = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<soap:Envelope>\n  <!-- Corps de la requête SOAP -->\n</soap:Envelope>";

  
  // Handler pour le contenu WSDL
  const handleWsdlContent = (wsdlData) => {
    setLoadingWsdl(false);
    setWsdlLoaded(true);
    
    if (wsdlData) {
      setWsdlContent(wsdlData.content);
      setAvailableOperations(wsdlData.operations || []);
      setWsdlStructure(wsdlData.structure || null);
      
      // Créer des templates XML pour les opérations disponibles
      const operationTemplates = {};
      (wsdlData.operations || []).forEach(op => {
        operationTemplates[op.name] = op.requestTemplate || generateDefaultTemplate(op);
      });
      
      setTemplates(operationTemplates);
      
      // Extraire les namespaces
      if (wsdlData.namespaces && wsdlData.namespaces.length > 0) {
        setXmlNamespaces([
          { prefix: 'soap', uri: getSoapNamespaceUri(soapVersion) },
          ...wsdlData.namespaces
        ]);
      }
      
      setSnackbar({
        open: true,
        message: `WSDL chargé avec succès: ${wsdlData.operations?.length || 0} opérations trouvées`,
        severity: 'success'
      });
    }
  };
  
  // Fonction pour obtenir l'URI d'espace de noms SOAP selon la version
  const getSoapNamespaceUri = (version) => {
    return version === '1.1' 
      ? 'http://schemas.xmlsoap.org/soap/envelope/' 
      : 'http://www.w3.org/2003/05/soap-envelope';
  };
  
  // Fonction pour générer un template XML par défaut
  const generateDefaultTemplate = (operation) => {
    const soapNs = getSoapNamespaceUri(soapVersion);
    const targetNs = operation.targetNamespace || 'http://tempuri.org/';
    
    return `<?xml version="1.0" encoding="UTF-8"?>
<soap:Envelope xmlns:soap="${soapNs}" xmlns:ns="${targetNs}">
  <soap:Header>
    <!-- En-têtes SOAP ici -->
  </soap:Header>
  <soap:Body>
    <ns:${operation.name}>
      <!-- Paramètres ici -->
    </ns:${operation.name}>
  </soap:Body>
</soap:Envelope>`;
  };
  
  // Fonction pour charger un WSDL
  const loadWsdl = () => {
    if (!wsdlUrl.trim() || disabled || !connectionStatus) return;
    
    setLoadingWsdl(true);
    setWsdlLoaded(false);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SoapApi",
        nodeName: "LoadWsdl",
        nodeValue: wsdlUrl,
        actionType: "SOAP",
        actionValue: "LoadWsdl",
        actionName: "LoadWsdl",
        timestamp: Date.now(),
        wsdlUrl
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'SoapApi',
      name: `Chargement du WSDL: ${wsdlUrl}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour envoyer une requête SOAP
  const sendSoapRequest = () => {
    if (!url.trim() || !operation || disabled || !connectionStatus) return;
    
    setIsLoading(true);
    
    // Construire les en-têtes
    const headerObject = {};
    headers.forEach(header => {
      if (header.key.trim() && header.value.trim()) {
        headerObject[header.key] = header.value;
      }
    });
    
    // S'assurer que le content-type est correct pour SOAP
    if (!headerObject['Content-Type']) {
      headerObject['Content-Type'] = soapVersion === '1.1' 
        ? 'text/xml; charset=utf-8' 
        : 'application/soap+xml; charset=utf-8';
    }
    
    // Construction du payload d'interaction
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SoapApi",
        nodeName: "SendSoapRequest",
        nodeValue: operation,
        actionType: "SOAP",
        actionValue: url,
        actionName: "SendSoapRequest",
        timestamp: Date.now(),
        operation,
        url,
        headers: headerObject,
        body: requestBody,
        soapVersion
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'SoapApi',
      name: `${operation} @ ${url}`,
      timestamp: new Date()
    });
  };
  
  // Handler pour ajouter un en-tête
  const addHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };
  
  // Handler pour supprimer un en-tête
  const removeHeader = (index) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 1);
    setHeaders(newHeaders);
  };
  
  // Handler pour mettre à jour un en-tête
  const updateHeader = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index] = { ...newHeaders[index], [field]: value };
    setHeaders(newHeaders);
  };
  
  // Handler pour ajouter un namespace XML
  const addNamespace = () => {
    setXmlNamespaces([...xmlNamespaces, { prefix: '', uri: '' }]);
  };
  
  // Handler pour supprimer un namespace XML
  const removeNamespace = (index) => {
    // Ne pas permettre la suppression du namespace SOAP
    if (index === 0) return;
    
    const newNamespaces = [...xmlNamespaces];
    newNamespaces.splice(index, 1);
    setXmlNamespaces(newNamespaces);
  };
  
  // Handler pour mettre à jour un namespace XML
  const updateNamespace = (index, field, value) => {
    // Ne pas permettre la modification du namespace SOAP
    if (index === 0 && field === 'uri') return;
    
    const newNamespaces = [...xmlNamespaces];
    newNamespaces[index] = { ...newNamespaces[index], [field]: value };
    setXmlNamespaces(newNamespaces);
  };
  
  // Fonction pour changer la version SOAP
  const handleSoapVersionChange = (newVersion) => {
    setSoapVersion(newVersion);
    
    // Mettre à jour l'URI du namespace SOAP
    const newNamespaces = [...xmlNamespaces];
    newNamespaces[0] = { 
      prefix: 'soap', 
      uri: getSoapNamespaceUri(newVersion) 
    };
    setXmlNamespaces(newNamespaces);
    
    // Mettre à jour le template si une opération est sélectionnée
    if (operation && templates[operation]) {
      const updatedTemplate = templates[operation].replace(
        /xmlns:soap="[^"]*"/,
        `xmlns:soap="${getSoapNamespaceUri(newVersion)}"`
      );
      
      const updatedTemplates = { ...templates };
      updatedTemplates[operation] = updatedTemplate;
      setTemplates(updatedTemplates);
      
      // Mettre à jour le corps de la requête si nécessaire
      if (!customEnvelope) {
        setRequestBody(updatedTemplate);
      }
    }
  };
  
  // Fonction pour sélectionner une opération
  const handleOperationSelect = (selectedOperation) => {
    setOperation(selectedOperation);
    
    // Mettre à jour le corps de la requête avec le template correspondant
    if (templates[selectedOperation] && !customEnvelope) {
      setRequestBody(templates[selectedOperation]);
    }
    
    // Récupérer les détails de l'opération
    const operationPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SoapApi",
        nodeName: "GetOperationDetails",
        nodeValue: selectedOperation,
        actionType: "SOAP",
        actionValue: wsdlUrl,
        actionName: "GetOperationDetails",
        timestamp: Date.now(),
        operation: selectedOperation,
        wsdlUrl
      }
    };
    
    onInteractionSent(operationPayload);
  };
  
  // Fonction pour copier la réponse
  const copyResponseBody = () => {
    if (response) {
      navigator.clipboard.writeText(response);
      
      setSnackbar({
        open: true,
        message: 'Réponse copiée dans le presse-papiers',
        severity: 'success'
      });
    }
  };
  
  // Fonction pour formatter le XML
  const formatXml = (xml) => {
    if (!xml) return '';
    
    // Si pretty print est activé, indenter le XML
    if (prettyPrint) {
      try {
        // Simple indentation XML (version basique)
        let formatted = '';
        let indent = '';
        const tab = '  '; // 2 espaces pour l'indentation
        
        xml.split(/>\s*</).forEach(node => {
          if (node.match(/^\/\w/)) { // Nœud fermant
            indent = indent.substring(tab.length);
          }
          
          formatted += indent + '<' + node + '>\r\n';
          
          if (node.match(/^<?\w[^>]*[^\/]$/) && !node.startsWith("?")) { // Nœud ouvrant
            indent += tab;
          }
        });
        
        return formatted.substring(1, formatted.length - 3);
      } catch (e) {
        console.error('Erreur lors du formatage XML', e);
        return xml;
      }
    }
    
    // Sinon, retourner tel quel
    return xml;
  };
  
  // Fonction pour déterminer la couleur d'état HTTP
  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    if (status < 300) return 'success';
    if (status < 400) return 'info';
    if (status < 500) return 'warning';
    return 'error';
  };
  
  // Fonction pour charger une requête de l'historique
  const loadFromHistory = (historyItem) => {
    setUrl(historyItem.url);
    setOperation(historyItem.operation);
    setHeaders(historyItem.headers || [{ key: '', value: '' }]);
    setRequestBody(historyItem.body || '');
    setSoapVersion(historyItem.soapVersion || '1.1');
    
    setSnackbar({
      open: true,
      message: 'Requête chargée de l\'historique',
      severity: 'success'
    });
  };
  
  // Fonction pour sauvegarder une requête dans une collection
  const saveToCollection = () => {
    // Implémentation de la sauvegarde dans une collection
    setSnackbar({
      open: true,
      message: 'Requête sauvegardée dans la collection',
      severity: 'success'
    });
  };
  
  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <FileCode sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              SOAP API {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionStatus ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionStatus ? 'success' : 'error'}
            label={connectionStatus ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Endpoint
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.baseUrl || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Authentication
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.authType === 'none' ? 'Aucune' : 
                 connectionInfo.authType === 'basic' ? 'Basic Auth' :
                 connectionInfo.authType === 'wss-username' ? 'WSS Username Token' :
                 connectionInfo.authType === 'wss-signature' ? 'WSS Signature' : 'N/A'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant Onglet WSDL
  const WsdlComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <FileCode size={16} style={{ marginRight: 8 }} />
          Configuration WSDL
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="URL du WSDL"
                variant="outlined"
                size="small"
                value={wsdlUrl}
                onChange={(e) => setWsdlUrl(e.target.value)}
                disabled={disabled || !connectionStatus || loadingWsdl}
                placeholder="https://exemple.com/service?wsdl"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={loadWsdl}
                        disabled={!wsdlUrl.trim() || disabled || !connectionStatus || loadingWsdl}
                      >
                        {loadingWsdl ? <CircularProgress size={16} /> : <Download size={16} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Box>
        
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {wsdlLoaded ? (
            <Box sx={{ display: 'flex', height: '100%' }}>
              {/* Liste des opérations */}
              <Box sx={{ width: 250, borderRight: 1, borderColor: 'divider', pr: 2, overflow: 'auto' }}>
                <Typography variant="subtitle2" gutterBottom>
                  Opérations disponibles
                </Typography>
                <List dense>
                  {availableOperations.map((op, index) => (
                    <ListItem 
                      key={index}
                      disablePadding
                    >
                      <ListItemButton
                        selected={operation === op.name}
                        onClick={() => handleOperationSelect(op.name)}
                        dense
                      >
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <FileCode size={16} />
                        </ListItemIcon>
                        <ListItemText primary={op.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
              
              {/* Détails du WSDL */}
              <Box sx={{ flex: 1, pl: 2, overflow: 'auto' }}>
                {operationDetails ? (
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      Détails de l'opération: {operationDetails.name}
                    </Typography>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            Entrée
                          </Typography>
                          <Typography variant="body2">
                            Message: {operationDetails.inputMessage}
                          </Typography>
                          <Typography variant="body2">
                            Action: {operationDetails.inputAction || 'N/A'}
                          </Typography>
                        </Paper>
                      </Grid>
                      
                      <Grid item xs={12} sm={6}>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            Sortie
                          </Typography>
                          <Typography variant="body2">
                            Message: {operationDetails.outputMessage}
                          </Typography>
                          <Typography variant="body2">
                            Action: {operationDetails.outputAction || 'N/A'}
                          </Typography>
                        </Paper>
                      </Grid>
                      
                      {operationDetails.documentation && (
                        <Grid item xs={12}>
                          <Paper variant="outlined" sx={{ p: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Documentation
                            </Typography>
                            <Typography variant="body2">
                              {operationDetails.documentation}
                            </Typography>
                          </Paper>
                        </Grid>
                      )}
                      
                      <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            Exemple de requête
                          </Typography>
                          <Box 
                            sx={{ 
                              fontFamily: 'monospace', 
                              fontSize: '0.875rem',
                              bgcolor: 'grey.900',
                              color: 'grey.100',
                              p: 2,
                              borderRadius: 1,
                              overflow: 'auto',
                              maxHeight: '200px',
                              whiteSpace: 'pre-wrap'
                            }}
                          >
                            {formatXml(operationDetails.requestExample || templates[operationDetails.name] || '')}
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography color="text.secondary">
                      Sélectionnez une opération pour voir ses détails
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center',
              flex: 1
            }}>
              {loadingWsdl ? (
                <>
                  <CircularProgress size={40} sx={{ mb: 2 }} />
                  <Typography>Chargement du WSDL...</Typography>
                </>
              ) : (
                <>
                  <FileCode size={48} strokeWidth={1} style={{ marginBottom: 16, opacity: 0.5 }} />
                  <Typography>Entrez l'URL du WSDL et cliquez sur Télécharger pour commencer</Typography>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  
  // Composant Onglet Requête
  const RequestComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Send size={16} style={{ marginRight: 8 }} />
          Requête SOAP
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Opération</InputLabel>
                <Select
                  value={operation}
                  onChange={(e) => handleOperationSelect(e.target.value)}
                  label="Opération"
                  disabled={disabled || !connectionStatus || !wsdlLoaded}
                >
                  {availableOperations.map((op, index) => (
                    <MenuItem key={index} value={op.name}>{op.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                fullWidth
                label="URL d'Endpoint"
                variant="outlined"
                size="small"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={disabled || !connectionStatus}
                placeholder="https://exemple.com/service"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Version SOAP</InputLabel>
                <Select
                  value={soapVersion}
                  onChange={(e) => handleSoapVersionChange(e.target.value)}
                  label="Version SOAP"
                  disabled={disabled || !connectionStatus}
                >
                  <MenuItem value="1.1">SOAP 1.1</MenuItem>
                  <MenuItem value="1.2">SOAP 1.2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        
        <Box sx={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ChevronDown size={16} />}>
              <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileText size={14} style={{ marginRight: 8 }} />
                En-têtes HTTP
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {headers.map((header, index) => (
                  <Box key={index} sx={{ display: 'flex', mb: 1 }}>
                    <TextField
                      size="small"
                      label="Clé"
                      value={header.key}
                      onChange={(e) => updateHeader(index, 'key', e.target.value)}
                      disabled={disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 1 }}
                    />
                    <TextField
                      size="small"
                      label="Valeur"
                      value={header.value}
                      onChange={(e) => updateHeader(index, 'value', e.target.value)}
                      disabled={disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 1 }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => removeHeader(index)}
                      disabled={headers.length === 1 || disabled || !connectionStatus}
                    >
                      <Minus size={16} />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<Plus size={16} />}
                  onClick={addHeader}
                  disabled={disabled || !connectionStatus}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  Ajouter un en-tête
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ChevronDown size={16} />}>
              <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                <LinkIcon size={14} style={{ marginRight: 8 }} />
                Namespaces XML
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {xmlNamespaces.map((ns, index) => (
                  <Box key={index} sx={{ display: 'flex', mb: 1 }}>
                    <TextField
                      size="small"
                      label="Préfixe"
                      value={ns.prefix}
                      onChange={(e) => updateNamespace(index, 'prefix', e.target.value)}
                      disabled={index === 0 || disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 1 }}
                    />
                    <TextField
                      size="small"
                      label="URI"
                      value={ns.uri}
                      onChange={(e) => updateNamespace(index, 'uri', e.target.value)}
                      disabled={index === 0 || disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 2 }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => removeNamespace(index)}
                      disabled={index === 0 || disabled || !connectionStatus}
                    >
                      <Minus size={16} />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<Plus size={16} />}
                  onClick={addNamespace}
                  disabled={disabled || !connectionStatus}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  Ajouter un namespace
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          
          <Box sx={{ flex: 1, mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileCode size={14} style={{ marginRight: 8 }} />
                Corps de la requête SOAP
              </Typography>
              
              <FormControlLabel
                control={
                  <Switch
                    checked={customEnvelope}
                    onChange={(e) => setCustomEnvelope(e.target.checked)}
                    disabled={disabled || !connectionStatus}
                    size="small"
                  />
                }
                label="Enveloppe personnalisée"
              />
            </Box>
            
           <TextField
  fullWidth
  multiline
  rows={15}
  value={requestBody}
  onChange={(e) => setRequestBody(e.target.value)}
  disabled={disabled || !connectionStatus || (!customEnvelope && !operation)}
  placeholder={xmlPlaceholder}
  InputProps={{
    sx: { fontFamily: 'monospace' }
  }}
/>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<History size={16} />}
            onClick={() => setHistoryOpen(true)}
            disabled={disabled || !connectionStatus}
          >
            Historique
          </Button>
          
          <Box>
            <Button
              variant="outlined"
              startIcon={<Save size={16} />}
              onClick={saveToCollection}
              disabled={disabled || !connectionStatus}
              sx={{ mr: 1 }}
            >
              Sauvegarder
            </Button>
            
            <Button
              variant="contained"
              startIcon={<Send size={16} />}
              onClick={sendSoapRequest}
              disabled={!url.trim() || !operation || disabled || !connectionStatus || isLoading}
              color="primary"
            >
              {isLoading ? <CircularProgress size={16} sx={{ mr: 1 }} /> : null}
              Envoyer
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  
  // Composant Onglet Réponse
  const ResponseComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <FileCode size={16} style={{ marginRight: 8 }} />
            Réponse SOAP
          </Typography>
          
          {responseStatus && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                size="small"
                label={`Status: ${responseStatus}`}
                color={getStatusColor(responseStatus)}
                sx={{ mr: 1 }}
              />
              {responseTime && (
                <Chip
                  size="small"
                  icon={<Clock size={14} />}
                  label={`${responseTime} ms`}
                  variant="outlined"
                  sx={{ mr: 1 }}
                />
              )}
              {responseSize && (
                <Chip
                  size="small"
                  label={`${Math.round(responseSize / 1024 * 100) / 100} KB`}
                  variant="outlined"
                />
              )}
            </Box>
          )}
        </Box>
        
        {response ? (
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Tabs 
                value={responseTabs} 
                onChange={(e, val) => setResponseTabs(val)}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Corps" />
                <Tab label="En-têtes" />
              </Tabs>
              
              <Box>
                <Tooltip title="Affichage formaté">
                  <IconButton 
                    size="small"
                    onClick={() => setPrettyPrint(!prettyPrint)}
                  >
                    {prettyPrint ? <Code size={16} /> : <FileText size={16} />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                  <IconButton 
                    size="small"
                    onClick={copyResponseBody}
                  >
                    <Copy size={16} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            
            <Box sx={{ flex: 1, overflow: 'auto', bgcolor: 'grey.900', p: 2, borderRadius: 1 }}>
              {responseTabs === 0 && (
                <Box ref={responseBodyRef} sx={{ 
                  fontFamily: 'monospace', 
                  fontSize: '0.875rem',
                  color: 'grey.100',
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word'
                }}>
                  {formatXml(response)}
                </Box>
              )}
              
              {responseTabs === 1 && (
                <TableContainer>
                  <Table size="small" sx={{ backgroundColor: 'grey.800' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: 'grey.100', borderBottom: '1px solid rgba(255,255,255,0.1)' }}>En-tête</TableCell>
                        <TableCell sx={{ color: 'grey.100', borderBottom: '1px solid rgba(255,255,255,0.1)' }}>Valeur</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(responseHeaders).map(([key, value], index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: 'grey.300', borderBottom: '1px solid rgba(255,255,255,0.05)' }}>
                            {key}
                          </TableCell>
                          <TableCell sx={{ color: 'grey.300', borderBottom: '1px solid rgba(255,255,255,0.05)' }}>
                            {value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            color: 'text.secondary'
          }}>
            {isLoading ? (
              <>
                <CircularProgress size={40} sx={{ mb: 2 }} />
                <Typography>Requête en cours...</Typography>
              </>
            ) : (
              <>
                <FileCode size={48} strokeWidth={1} style={{ marginBottom: 16, opacity: 0.5 }} />
                <Typography>Envoyez une requête SOAP pour voir la réponse</Typography>
              </>
            )}
          </Box>
        )}
      </Box>
    );
  };
  
  // Composant de dialogue d'historique
  const HistoryDialog = () => {
    return (
      <Dialog
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <History size={20} style={{ marginRight: 8 }} />
            Historique des requêtes
          </Typography>
          <IconButton onClick={() => setHistoryOpen(false)}>
            <X size={18} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {requestHistory.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography color="text.secondary">Aucune requête dans l'historique</Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Opération</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Temps</TableCell>
                    <TableCell>Date/Heure</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestHistory.map((item) => (
                    <TableRow key={item.id} hover>
                      <TableCell>
                        <Typography variant="body2">
                          {item.operation}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap sx={{ maxWidth: 250 }}>
                          {item.url}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={item.status}
                          size="small"
                          color={getStatusColor(item.status)}
                        />
                      </TableCell>
                      <TableCell>{item.responseTime} ms</TableCell>
                      <TableCell>{item.timestamp.toLocaleString()}</TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => {
                            loadFromHistory(item);
                            setHistoryOpen(false);
                          }}
                          title="Charger cette requête"
                        >
                          <Play size={16} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => saveToCollection(item)}
                          title="Sauvegarder dans les collections"
                        >
                          <Bookmark size={16} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Trash2 size={16} />} onClick={() => setRequestHistory([])}>
            Effacer l'historique
          </Button>
          <Button onClick={() => setHistoryOpen(false)}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionStatus) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionStatus ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          bgcolor: 'background.paper'
        }}
      >
        <Tab icon={<FileJson size={16} />} label="WSDL" />
        <Tab icon={<Send size={16} />} label="Requête" />
        <Tab icon={<FileCode size={16} />} label="Réponse" />
      </Tabs>
      
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {activeTab === 0 && <WsdlComponent />}
        {activeTab === 1 && <RequestComponent />}
        {activeTab === 2 && <ResponseComponent />}
      </Box>
      
      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
      
      <HistoryDialog />
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SoapApiCapture;