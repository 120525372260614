import React from 'react';
import { MoreVertical, ChevronUp, ChevronDown } from 'lucide-react';
import Card from './Card';
import Button from './Button';
import StatusBadge from './StatusBadge';
import DateFormatter from './DateFormatter';

const ListItem = ({ 
  item, 
  isSelected, 
  onSelect, 
  onAction,
  columns,
  showActions = true
}) => (
  <tr
    className={`
      transition-colors duration-200 group
      hover:bg-gray-50 dark:hover:bg-gray-700/50
      ${isSelected ? 'bg-blue-50/50 dark:bg-blue-900/20' : ''}
      cursor-pointer
    `}
    onClick={() => onSelect(item.id)}
  >
    {columns.map((column) => {
      const cellContent = (() => {
        switch (column.key) {
          case 'title':
            return (
              <div>
                <div className="font-medium text-gray-900 dark:text-white">
                  {item.title}
                </div>
                {item.subtitle && (
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {item.subtitle}
                  </div>
                )}
              </div>
            );

          case 'status':
            return <StatusBadge status={item.status} />;

          case 'date':
            return (
              <DateFormatter 
                date={item.date} 
                format={column.dateFormat || 'default'} 
              />
            );

          case 'actions':
            return showActions ? (
              <div className="flex justify-end space-x-2">
                {column.customActions?.(item)}
                <Button
                  variant="text"
                  icon={MoreVertical}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAction(item);
                  }}
                  className="opacity-0 group-hover:opacity-100 transition-opacity"
                />
              </div>
            ) : null;

          default:
            return item[column.key];
        }
      })();

      return (
        <td 
          key={column.key}
          className={`
            px-6 py-4 whitespace-nowrap text-sm
            ${column.align === 'right' ? 'text-right' : 'text-left'}
            ${column.className || ''}
          `}
        >
          {cellContent}
        </td>
      );
    })}
  </tr>
);

const SortHeader = ({ column, sortConfig, onSort }) => {
  const isSorted = sortConfig?.key === column.key;
  const isAscending = isSorted && sortConfig?.direction === 'asc';

  return (
    <th
      className={`
        px-6 py-3 text-xs font-medium tracking-wider
        text-gray-500 dark:text-gray-400 uppercase
        ${column.sortable ? 'cursor-pointer hover:text-gray-700 dark:hover:text-gray-200' : ''}
        ${column.align === 'right' ? 'text-right' : 'text-left'}
      `}
      onClick={() => column.sortable && onSort(column.key)}
    >
      <div className="flex items-center space-x-1">
        <span>{column.label}</span>
        {column.sortable && (
          <div className="flex flex-col">
            <ChevronUp 
              size={12}
              className={`${isSorted && !isAscending ? 'text-blue-500' : 'text-gray-400'}`}
            />
            <ChevronDown 
              size={12}
              className={`${isSorted && isAscending ? 'text-blue-500' : 'text-gray-400'}`}
            />
          </div>
        )}
      </div>
    </th>
  );
};

const ListView = ({ 
  data = [], 
  selectedItems = [], 
  onSelect, 
  onAction,
  onSort,
  sortConfig,
  loading = false,
  columns = [
    { key: 'title', label: 'Titre' },
    { key: 'status', label: 'Status' },
    { key: 'date', label: 'Date', sortable: true },
    { key: 'actions', label: 'Actions', align: 'right' }
  ],
  showActions = true,
  emptyMessage = "Aucune donnée à afficher"
}) => {
  if (loading) {
    return (
      <Card>
        <div className="animate-pulse">
          <div className="h-12 bg-gray-100 dark:bg-gray-800 mb-4" />
          {[...Array(3)].map((_, index) => (
            <div 
              key={index}
              className="h-16 bg-gray-50 dark:bg-gray-800/50 mb-2"
            />
          ))}
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
              {columns.map((column) => (
                <SortHeader
                  key={column.key}
                  column={column}
                  sortConfig={sortConfig}
                  onSort={onSort}
                />
              ))}
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {data.length > 0 ? (
              data.map((item) => (
                <ListItem
                  key={item.id}
                  item={item}
                  isSelected={selectedItems.includes(item.id)}
                  onSelect={onSelect}
                  onAction={onAction}
                  columns={columns}
                  showActions={showActions}
                />
              ))
            ) : (
              <tr>
                <td 
                  colSpan={columns.length}
                  className="px-6 py-8 text-center text-gray-500 dark:text-gray-400"
                >
                  {emptyMessage}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

ListView.defaultProps = {
  showActions: true,
  emptyMessage: "Aucune donnée à afficher",
  columns: [
    { key: 'title', label: 'Titre' },
    { key: 'status', label: 'Status' },
    { key: 'date', label: 'Date', sortable: true },
    { key: 'actions', label: 'Actions', align: 'right' }
  ]
};

export default ListView;