import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ArrowLeft, 
  Trash2,
  Clock,
  User,
  Tag,
  Globe,
  Server,
  AlertTriangle,
  Activity,
  Info
} from 'lucide-react';
import apiService from '../../services/api.service';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';

const AuditDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [auditLog, setAuditLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchAuditLog();
  }, [id, navigate]);

  const fetchAuditLog = async () => {
    try {
      const data = await apiService.audit.getById(id);
      setAuditLog(data);
      setError('');
    } catch (err) {
      setError('Erreur lors du chargement du log : ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await apiService.audit.delete(id);
      navigate('/audit');
    } catch (err) {
      setError('Erreur lors de la suppression : ' + err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Card>
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4" />
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4" />
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2" />
          </div>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <div className="text-center py-12">
          <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
            Erreur
          </h3>
          <p className="text-gray-500 dark:text-gray-400">{error}</p>
          <Button
            variant="outline"
            className="mt-4"
            onClick={() => navigate('/audit')}
          >
            Retour à la liste
          </Button>
        </div>
      </Card>
    );
  }

  if (!auditLog) {
    return (
      <Card>
        <div className="text-center py-12">
          <Info className="w-12 h-12 text-blue-500 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
            Log introuvable
          </h3>
          <Button
            variant="outline"
            className="mt-4"
            onClick={() => navigate('/audit')}
          >
            Retour à la liste
          </Button>
        </div>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <Card>
        <div className="flex justify-between items-start">
          <div className="flex items-center space-x-4">
            <Button
              variant="outline"
              icon={ArrowLeft}
              onClick={() => navigate('/audit')}
            >
              Retour
            </Button>
            <div>
              <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                Détails du log d'audit
              </h2>
              <p className="text-gray-500 dark:text-gray-400">
                ID: {auditLog.id}
              </p>
            </div>
          </div>
          <Button
            variant="danger"
            icon={Trash2}
            onClick={() => setShowDeleteConfirm(true)}
          >
            Supprimer
          </Button>
        </div>
      </Card>

      {/* Informations principales */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Action et contexte */}
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            Action
          </h3>
          <div className="space-y-4">
            <div className="flex items-start space-x-3">
              <Activity className="w-5 h-5 text-blue-500 mt-1" />
              <div>
                <p className="font-medium text-gray-900 dark:text-white">
                  {auditLog.action.actionName}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {auditLog.action.description}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <Clock className="w-5 h-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Effectué le
                </p>
                <DateFormatter 
                  date={auditLog.action.performedAt} 
                  format="full"
                  className="font-medium text-gray-900 dark:text-white"
                />
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <User className="w-5 h-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Effectué par
                </p>
                <p className="font-medium text-gray-900 dark:text-white">
                  {auditLog.action.performedBy}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <Tag className="w-5 h-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Ressource
                </p>
                <p className="font-medium text-gray-900 dark:text-white">
                  {auditLog.action.resourceType} / {auditLog.action.resourceId}
                </p>
              </div>
            </div>
          </div>
        </Card>

        {/* Statut et métadonnées */}
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            Statut
          </h3>
          <div className="space-y-4">
            <div className="flex justify-between items-center p-3 bg-gray-50 dark:bg-gray-800 rounded-lg">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Sévérité
                </p>
                <StatusBadge 
                  status={auditLog.severity.toLowerCase()}
                  customLabel={auditLog.severity}
                  className="mt-1"
                />
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Résultat
                </p>
                <StatusBadge 
                  status={auditLog.success ? 'success' : 'error'}
                  customLabel={auditLog.success ? 'Succès' : 'Échec'}
                  className="mt-1"
                />
              </div>
            </div>

            {auditLog.errorMessage && (
              <div className="p-3 bg-red-50 dark:bg-red-900/10 rounded-lg">
                <p className="text-sm font-medium text-red-800 dark:text-red-400">
                  Message d'erreur
                </p>
                <p className="mt-1 text-sm text-red-700 dark:text-red-300">
                  {auditLog.errorMessage}
                </p>
              </div>
            )}

            <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
              <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
                Contexte d'exécution
              </h4>
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Globe className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Application
                    </span>
                  </div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    {auditLog.context.applicationName}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Server className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Environnement
                    </span>
                  </div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    {auditLog.context.environment}
                  </span>
                </div>

                {auditLog.duration && (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <Clock className="w-4 h-4 text-gray-400" />
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        Durée
                      </span>
                    </div>
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      {auditLog.duration}ms
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>

      {/* Changements détaillés si présents */}
      {auditLog.changes && auditLog.changes.length > 0 && (
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            Changements
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Champ
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ancienne valeur
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nouvelle valeur
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                {auditLog.changes.map((change, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                      {change.fieldName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {change.oldValue || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {change.newValue || '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Card className="w-full max-w-md mx-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Confirmer la suppression
            </h3>
            <p className="text-gray-500 dark:text-gray-400 mb-6">
              Êtes-vous sûr de vouloir supprimer ce log d'audit ? Cette action est irréversible.
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                variant="outline"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Annuler
              </Button>
              <Button
                variant="danger"
                icon={Trash2}
                onClick={handleDelete}
                loading={loading}
              >
                Supprimer
              </Button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default AuditDetails;