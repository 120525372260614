import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  GitBranch, 
  Save, 
  ArrowLeft, 
  FileText, 
  AlertTriangle, 
  HelpCircle
} from 'lucide-react';
import EnhancedWorkflowStudio from '../../components/shared/workflow/EnhancedWorkflowStudio';
import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import apiService from '../../services/api.service';

/**
 * Page principale du studio de conception de workflow
 * 
 * Cette page permet la création et l'édition de workflows
 * en utilisant le studio visuel amélioré
 */
const WorkflowStudio = () => {
  const navigate = useNavigate();
  const { workflowCode } = useParams();
  const [workflow, setWorkflow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  
  // Charger la définition du workflow si un code est fourni
  useEffect(() => {
    if (workflowCode) {
      fetchWorkflow(workflowCode);
    }
  }, [workflowCode]);
  
  // Fonction pour charger un workflow existant
  const fetchWorkflow = async (code) => {
    setLoading(true);
    try {
      const response = await apiService.workflow.getWorkflowDefinition(code);
      setWorkflow(response);
      setError(null);
    } catch (err) {
      console.error('Error fetching workflow:', err);
      setError(`Erreur lors du chargement du workflow: ${err.message || 'Une erreur inconnue est survenue'}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction de sauvegarde du workflow
  const handleSave = async (workflowData) => {
    setLoading(true);
    setSaveSuccess(false);
    
    try {
      // Validation préalable
      const validationResponse = await apiService.workflow.validateDefinition(workflowData);
      
      if (!validationResponse.valid) {
        setError(`Validation échouée: ${validationResponse.errors.join(', ')}`);
        setLoading(false);
        return;
      }
      
      // Sauvegarde
      await apiService.workflow.saveDefinition(workflowData);
      setSaveSuccess(true);
      setError(null);
      
      // Effacer le message de succès après un délai
      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
      
      // Si c'est un nouveau workflow, rediriger vers la page d'édition
      if (!workflowCode) {
        navigate(`/workflow/workflowstudio/${workflowData.code}`);
      }
    } catch (err) {
      console.error('Error saving workflow:', err);
      setError(`Erreur lors de la sauvegarde: ${err.message || 'Une erreur inconnue est survenue'}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Gérer le retour à la liste des workflows
  const handleBack = () => {
    navigate('/workflow/workflowmanagement');
  };
  
  // Si chargement en cours, afficher un indicateur
  if (loading && !workflow) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }
  
  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Button
            variant="outline"
            icon={ArrowLeft}
            onClick={handleBack}
          >
            Retour
          </Button>
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
              <GitBranch className="h-6 w-6 mr-2 text-blue-500" />
              {workflowCode ? `Modifier le workflow: ${workflow?.name || workflowCode}` : 'Créer un nouveau workflow'}
            </h1>
            <p className="text-gray-500 dark:text-gray-400">
              Utilisez le studio visuel pour définir les états et transitions du workflow
            </p>
          </div>
        </div>
        
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            icon={HelpCircle}
            onClick={() => setShowHelp(!showHelp)}
          >
            Aide
          </Button>
          <Button
            variant="primary"
            icon={Save}
            loading={loading}
          >
            Enregistrer
          </Button>
        </div>
      </div>
      
      {/* Affichage des messages */}
      {error && (
        <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-900/10 dark:text-red-400">
          <AlertTriangle className="flex-shrink-0 inline w-5 h-5 mr-3" />
          <span className="font-medium">{error}</span>
        </div>
      )}
      
      {saveSuccess && (
        <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-green-900/10 dark:text-green-400">
          <span className="font-medium">Workflow enregistré avec succès!</span>
        </div>
      )}
      
      {/* Carte d'aide (conditionnelle) */}
      {showHelp && (
        <Card 
          title="Guide d'utilisation du studio de workflow" 
          icon={FileText}
          variant="primary"
          closeable={true}
          onClose={() => setShowHelp(false)}
        >
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-lg">Premiers pas</h3>
              <p>Pour créer un workflow, commencez par ajouter un état initial (vert) depuis le panneau d'outils à gauche.</p>
            </div>
            
            <div>
              <h3 className="font-medium text-lg">États et transitions</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Un <strong>état initial</strong> (vert) est le point de départ du workflow.</li>
                <li>Les <strong>états standard</strong> (gris) représentent les étapes intermédiaires.</li>
                <li>Les <strong>états finaux</strong> (rouge) sont les points de terminaison du workflow.</li>
                <li>Créez des <strong>transitions</strong> en glissant depuis un état vers un autre.</li>
              </ul>
            </div>
            
            <div>
              <h3 className="font-medium text-lg">Validation</h3>
              <p>Un workflow valide doit avoir:</p>
              <ul className="list-disc list-inside">
                <li>Un état initial</li>
                <li>Au moins un état final</li>
                <li>Toutes les transitions doivent avoir un événement</li>
                <li>Tous les états doivent être atteignables depuis l'état initial</li>
              </ul>
            </div>
          </div>
        </Card>
      )}
      
      {/* Studio de workflow */}
      <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden h-[calc(100vh-220px)]">
        <EnhancedWorkflowStudio 
          initialWorkflow={workflow} 
          onSave={handleSave}
          workflowType="standard"
        />
      </div>
    </div>
  );
};

export default WorkflowStudio;