import React, { useState, useEffect, useRef, Suspense, useMemo } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { 
  OrbitControls, Text, PerspectiveCamera, 
  Html, Stars, Environment, Float,
  useTexture, MeshDistortMaterial, useGLTF, Trail
} from '@react-three/drei';
import * as THREE from 'three';
import { 
  Box, Typography, Paper, Grid, Switch, FormControlLabel, 
  Button, Divider, IconButton, Tooltip, LinearProgress, Chip, Avatar
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Import d'icônes de Lucide React
import { 
  Server, Database, Monitor, Upload, Download, 
  ArrowRight, Play, Cloud, RefreshCw, BarChart,
  Shield, Activity, Cpu, Layers, Network, Globe,
  ChevronRight, AlertTriangle, CheckCircle, Lock,
  Zap, ExternalLink, Users, Code, PieChart
} from 'lucide-react';

// Thème Material UI personnalisé pour un look entreprise
const enterpriseTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0072E5',
      light: '#3399FF',
      dark: '#0059B2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#7928CA',
      light: '#8F49D9',
      dark: '#5B1E9A',
      contrastText: '#fff',
    },
    error: {
      main: '#FF4D4F',
      light: '#FF7875',
      dark: '#D9363E',
    },
    warning: {
      main: '#FAAD14',
      light: '#FFD666',
      dark: '#D48806',
    },
    info: {
      main: '#1890FF',
      light: '#69C0FF',
      dark: '#096DD9',
    },
    success: {
      main: '#52C41A',
      light: '#95DE64',
      dark: '#389E0D',
    },
    background: {
      default: '#0A1929',
      paper: '#132F4C',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          },
        },
        containedPrimary: {
          background: 'linear-gradient(45deg, #0072E5 30%, #1E88E5 90%)',
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #7928CA 30%, #9C27B0 90%)',
        },
        containedError: {
          background: 'linear-gradient(45deg, #FF4D4F 30%, #F5222D 90%)',
        },
        containedInfo: {
          background: 'linear-gradient(45deg, #1890FF 30%, #40A9FF 90%)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          backdropFilter: 'blur(20px)',
          background: 'rgba(19, 47, 76, 0.95)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          backdropFilter: 'blur(20px)',
          background: 'rgba(19, 47, 76, 0.95)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#0072E5',
              opacity: 1,
              border: 'none',
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#0072E5',
            border: '6px solid #fff',
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 26 / 2,
          border: '1px solid rgba(255,255,255,0.3)',
          backgroundColor: 'rgba(255,255,255,0.1)',
          opacity: 1,
        },
      },
    },
  },
});

// Couleurs professionnelles pour l'entreprise
const enterpriseColors = {
  primary: '#0072E5',       // Bleu primaire
  primaryLight: '#3399FF',  // Bleu primaire clair
  primaryDark: '#0059B2',   // Bleu primaire foncé
  
  secondary: '#7928CA',     // Violet secondaire
  secondaryLight: '#8F49D9', // Violet secondaire clair
  secondaryDark: '#5B1E9A',  // Violet secondaire foncé
  
  success: '#52C41A',       // Vert succès
  successLight: '#95DE64',  // Vert succès clair
  
  warning: '#FAAD14',       // Orange avertissement
  warningLight: '#FFD666',  // Orange avertissement clair
  
  error: '#FF4D4F',         // Rouge erreur
  errorLight: '#FF7875',    // Rouge erreur clair
  
  neutral1: '#001E3C',      // Fond foncé
  neutral2: '#132F4C',      // Fond moins foncé
  neutral3: '#255D9A',      // Accent bleu neutre
  
  apiGateway: '#2962FF',    // API Gateway
  coreDomain: '#00BFA5',    // Core Domain
  integration: '#6200EA',   // Integration
  infrastructure: '#263238' // Infrastructure
};

// Matériaux avancés et effets pour Three.js
const createAdvancedMaterial = (color, emissiveIntensity = 0.2, metalness = 0.7, roughness = 0.2) => {
  return new THREE.MeshStandardMaterial({
    color: new THREE.Color(color),
    emissive: new THREE.Color(color),
    emissiveIntensity: emissiveIntensity,
    metalness: metalness,
    roughness: roughness,
    envMapIntensity: 1.5,
  });
};

// Composant pour les particules animées (flux de données) amélioré
const EnhancedDataFlow = ({ from, to, color, speed, count, size, type = "data", intensity = 1, curvature = 1 }) => {
  const instancedMeshRef = useRef();
  const trailRef = useRef();
  const [dummy] = useState(() => new THREE.Object3D());
  const [particles, setParticles] = useState([]);
  
  // Initialiser les particules avec des positions aléatoires et des paramètres plus sophistiqués
  useEffect(() => {
    const newParticles = [];
    for (let i = 0; i < count; i++) {
      // Distribution des particules en clusters pour simuler des paquets de données
      const clusterIndex = Math.floor(i / (count / 7)); // 7 clusters
      const clusterOffset = clusterIndex * 0.15; // Espacement entre clusters
      
      newParticles.push({
        progress: (Math.random() * 0.15) + clusterOffset, // Grouper les particules
        offset: new THREE.Vector3(
          (Math.random() - 0.5) * 0.4,
          (Math.random() - 0.5) * 0.4,
          (Math.random() - 0.5) * 0.4
        ),
        speed: speed * (0.8 + Math.random() * 0.4), // Vitesse plus variée
        size: size * (0.7 + Math.random() * 0.6), // Tailles plus variées
        pulsePhase: Math.random() * Math.PI * 2, // Phase aléatoire pour l'effet de pulsation
        pathVariation: Math.random() * 0.3 * curvature, // Variation du chemin pour chaque particule
        rotationSpeed: Math.random() * 0.04 - 0.02, // Vitesse de rotation
        rotationAxis: new THREE.Vector3(
          Math.random() - 0.5,
          Math.random() - 0.5,
          Math.random() - 0.5
        ).normalize(),
        spinAxis: new THREE.Vector3(
          Math.random() - 0.5,
          Math.random() - 0.5,
          Math.random() - 0.5
        ).normalize()
      });
    }
    setParticles(newParticles);
  }, [count, speed, size, curvature]);
  
  // Obtenir la forme de la particule en fonction du type de données
  const getParticleGeometry = (type) => {
    switch(type) {
      case "command":
        return <octahedronGeometry args={[size * 1.1, 0]} />; // Commandes: forme octaédrique
      case "result":
        return <dodecahedronGeometry args={[size * 1.05, 0]} />; // Résultats: forme dodécaèdre
      case "notification":
        return <tetrahedronGeometry args={[size * 1.2, 0]} />; // Notifications: forme tétraèdre
      case "error":
        return <icosahedronGeometry args={[size * 1.1, 0]} />; // Erreurs: forme icosaèdre
      default:
        return <sphereGeometry args={[size, 10, 10]} />; // Données standard: sphères
    }
  };

  // Material dynamique selon le type
  const getMaterial = () => {
    const baseColor = color;
    let emissiveIntensity = 0.7 * intensity;
    let metalness = 0.7;
    let roughness = 0.3;
    
    if (type === "command") {
      emissiveIntensity = 0.9 * intensity;
      metalness = 0.9;
      roughness = 0.1;
    } else if (type === "result") {
      emissiveIntensity = 0.8 * intensity;
      metalness = 0.7;
      roughness = 0.2;
    } else if (type === "notification") {
      emissiveIntensity = 1.1 * intensity;
      metalness = 0.5;
      roughness = 0.3;
    } else if (type === "error") {
      emissiveIntensity = 1.2 * intensity;
      metalness = 0.6;
      roughness = 0.2;
    }
    
    return (
      <meshPhysicalMaterial 
        color={baseColor} 
        emissive={baseColor} 
        emissiveIntensity={emissiveIntensity} 
        metalness={metalness}
        roughness={roughness}
        clearcoat={0.4}
        clearcoatRoughness={0.2}
        toneMapped={false}
        transparent={true}
        opacity={0.95}
      />
    );
  };
  
  // Animer les particules avec des courbes et des mouvements plus complexes
  useFrame((state) => {
    if (!instancedMeshRef.current) return;
    
    // Temps global pour les effets d'animation
    const time = state.clock.getElapsedTime();
    
    particles.forEach((particle, i) => {
      // Mettre à jour la progression
      particle.progress += particle.speed;
      if (particle.progress > 1) particle.progress = 0;
      
      // Calculer le chemin avec une courbe plus complexe
      const t = particle.progress;
      const pathStrength = curvature * (type === "command" ? 1.8 : type === "result" ? 1.2 : 1.5);
      
      // Points de contrôle plus sophistiqués selon le type de données
      const midPoint = new THREE.Vector3().lerpVectors(from, to, 0.5);
      midPoint.y += pathStrength * 1.5 * Math.sin(t * Math.PI); // Arc plus prononcé
      
      const controlPoint1 = new THREE.Vector3(
        from.x + (to.x - from.x) * 0.3 + (Math.sin(t * Math.PI * 2 + time) * particle.pathVariation),
        from.y + Math.sin(t * Math.PI) * pathStrength * 1.2,
        from.z + (to.z - from.z) * 0.3 + (Math.cos(t * Math.PI * 2 + time) * particle.pathVariation)
      );
      
      const controlPoint2 = new THREE.Vector3(
        from.x + (to.x - from.x) * 0.7 + (Math.cos(t * Math.PI * 2 + time) * particle.pathVariation),
        to.y + Math.sin((1-t) * Math.PI) * pathStrength * 1.2,
        from.z + (to.z - from.z) * 0.7 + (Math.sin(t * Math.PI * 2 + time) * particle.pathVariation)
      );
      
      // Courbe de Bézier cubique pour un mouvement plus naturel
      const curve = new THREE.CubicBezierCurve3(
        from,
        controlPoint1,
        controlPoint2,
        to
      );
      
      // Obtenir la position sur la courbe
      const position = curve.getPoint(t);
      
      // Effet de pulsation pour simuler le traitement des données
      const pulseScale = 0.8 + Math.sin(time * 4 + particle.pulsePhase) * 0.2 * intensity;
      
      // Rotation autour de l'axe spécifique à la particule
      const rotationQuaternion = new THREE.Quaternion();
      rotationQuaternion.setFromAxisAngle(
        particle.rotationAxis,
        particle.rotationSpeed * time * 10
      );
      
      // Spin autour de l'axe spécifique à la particule
      const spinQuaternion = new THREE.Quaternion();
      spinQuaternion.setFromAxisAngle(
        particle.spinAxis,
        time * 2
      );
      
      // Combiner les rotations
      rotationQuaternion.multiply(spinQuaternion);
      
      // Mettre à jour la matrice de l'instance
      dummy.position.copy(position);
      dummy.scale.set(pulseScale, pulseScale, pulseScale);
      dummy.quaternion.copy(rotationQuaternion);
      dummy.updateMatrix();
      instancedMeshRef.current.setMatrixAt(i, dummy.matrix);
    });
    
    instancedMeshRef.current.instanceMatrix.needsUpdate = true;
  });
  
  // Ajouter un effet de traîl (effet de traînée)
  return (
    <>
      {/* Particules principales */}
      <instancedMesh ref={instancedMeshRef} args={[null, null, count]} castShadow>
        {getParticleGeometry(type)}
        {getMaterial()}
      </instancedMesh>
      
      {/* Effet de traînée lumineux avec gradient */}
      <mesh>
        <tubeGeometry 
          args={[
            new THREE.CatmullRomCurve3([
              from,
              new THREE.Vector3(
                (from.x + to.x) * 0.3,
                (from.y + to.y) * 0.5 + curvature * 0.5,
                (from.z + to.z) * 0.3
              ),
              new THREE.Vector3(
                (from.x + to.x) * 0.7,
                (from.y + to.y) * 0.5 + curvature * 0.5,
                (from.z + to.z) * 0.7
              ),
              to
            ], false, 'catmullrom', 16),
            64,  // tubularSegments
            0.05, // radius
            8,   // radialSegments
            false // closed
          ]}
        />
        <meshBasicMaterial 
          color={color} 
          transparent={true} 
          opacity={0.1 * intensity}
          depthWrite={false}
        />
      </mesh>
    </>
  );
};

// Composant pour un module du système amélioré avec des bords arrondis via shader
const EnterpriseModule = ({ position, color, size, name, isHighlighted, icon, description, status = "normal" }) => {
  const meshRef = useRef();
  const glowRef = useRef();
  const textRef = useRef();
  const [hovered, setHovered] = useState(false);
  
  // Déterminer la couleur d'état
  const getStatusColor = () => {
    switch(status) {
      case "warning": return enterpriseColors.warning;
      case "error": return enterpriseColors.error;
      case "success": return enterpriseColors.success;
      default: return color;
    }
  };
  
  const statusColor = getStatusColor();
  
  // Animation de mise en évidence améliorée
  useFrame(({ clock }) => {
    if (!meshRef.current || !glowRef.current) return;
    
    const time = clock.getElapsedTime();
    
    // Animation d'échelle plus sophistiquée
    meshRef.current.scale.lerp(
      new THREE.Vector3(
        isHighlighted ? 1.05 : hovered ? 1.03 : 1, 
        isHighlighted ? 1.05 : hovered ? 1.03 : 1, 
        isHighlighted ? 1.05 : hovered ? 1.03 : 1
      ), 
      0.1
    );
    
    // Animation d'intensité du glow
    if (glowRef.current.material) {
      const pulseIntensity = isHighlighted 
        ? 0.25 + Math.sin(time * 2) * 0.1 
        : hovered 
          ? 0.2 + Math.sin(time * 3) * 0.05 
          : 0.1 + Math.sin(time * 1.5) * 0.03;
          
      glowRef.current.material.opacity = pulseIntensity;
    }
    
    // Animation du texte
    if (textRef.current) {
      textRef.current.position.y = -size[1]/2 - 0.6 + Math.sin(time * 1.5) * 0.05;
    }
  });
  
  // Animation de la position du module
  return (
    <group position={position}>
      <Float 
        speed={isHighlighted ? 1.5 : 1}
        rotationIntensity={0.1} // Légère rotation
        floatIntensity={isHighlighted ? 0.3 : 0.15}
      >
        {/* Module principal */}
        <group
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          {/* Effet de glow */}
          <mesh ref={glowRef} scale={[1.15, 1.15, 1.15]}>
            <boxGeometry args={size} />
            <meshBasicMaterial 
              color={statusColor} 
              transparent={true} 
              opacity={0.15}
              depthWrite={false}
            />
          </mesh>
          
          {/* Module principal */}
          <mesh
            ref={meshRef}
            castShadow
          >
            <boxGeometry args={size} />
            <meshPhysicalMaterial 
              color={color} 
              metalness={0.8}
              roughness={0.15}
              clearcoat={0.4}
              clearcoatRoughness={0.2}
              emissive={statusColor}
              emissiveIntensity={isHighlighted ? 0.2 : hovered ? 0.15 : 0.08}
              envMapIntensity={1.5}
            />
          </mesh>
          
          {/* Petite lumière de statut */}
          <mesh position={[size[0]/2 - 0.2, size[1]/2 - 0.2, size[2]/2 + 0.01]}>
            <sphereGeometry args={[0.1, 16, 16]} />
            <meshBasicMaterial color={statusColor} />
          </mesh>
          
          {/* Contenu HTML pour l'icône et le titre */}
          <Html
            position={[0, 0, size[2] / 2 + 0.01]}
            center
            distanceFactor={8}
          >
            <div style={{ 
              width: '240px', 
              height: '240px', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              flexDirection: 'column',
              pointerEvents: 'none',
              transform: 'scale(0.6)',
              opacity: 0.97
            }}>
              <div style={{
                background: `radial-gradient(circle, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 70%)`,
                borderRadius: '50%',
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '15px'
              }}>
                {React.cloneElement(icon, { size: 54, strokeWidth: 1.5 })}
              </div>
              <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', textShadow: '0 0 10px rgba(0,0,0,0.5)' }}>
                {name}
              </Typography>
              {isHighlighted && description && (
                <Typography variant="caption" style={{ 
                  marginTop: '8px', 
                  color: 'rgba(255,255,255,0.8)', 
                  textAlign: 'center',
                  maxWidth: '200px',
                  textShadow: '0 0 10px rgba(0,0,0,0.5)'
                }}>
                  {description}
                </Typography>
              )}
            </div>
          </Html>
        </group>
      </Float>
      
      {/* Texte 3D pour le nom (qui flotte légèrement) */}
      <Text
        ref={textRef}
        position={[0, -size[1]/2 - 0.6, 0]}
        color="white"
        fontSize={0.4}
        font="/fonts/Inter-Bold.woff" // Assurez-vous que cette police est disponible
        anchorX="center"
        anchorY="middle"
        outlineWidth={0.01}
        outlineColor="#00000080"
      >
        {name}
      </Text>
    </group>
  );
};

// Composant pour le sol / plateforme amélioré
const EnterpriseFloor = () => {
  // Création d'une texture de grille procédurale plus sophistiquée
  const gridSize = 40;
  const lineWidth = 1;
  const size = 2048;
  
  const gridCanvas = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');
    
    // Fond dégradé radial
    const gradient = context.createRadialGradient(size/2, size/2, 0, size/2, size/2, size/2);
    gradient.addColorStop(0, '#0A2540');
    gradient.addColorStop(1, '#061528');
    context.fillStyle = gradient;
    context.fillRect(0, 0, size, size);
    
    // Dessiner la grille principale
    context.strokeStyle = 'rgba(0, 114, 229, 0.15)'; // Couleur primaire
    context.lineWidth = lineWidth;
    
    const step = size / gridSize;
    
    // Lignes horizontales et verticales
    for (let i = 0; i <= gridSize; i++) {
      const pos = i * step;
      
      // Ligne horizontale
      context.beginPath();
      context.moveTo(0, pos);
      context.lineTo(size, pos);
      context.stroke();
      
      // Ligne verticale
      context.beginPath();
      context.moveTo(pos, 0);
      context.lineTo(pos, size);
      context.stroke();
    }
    
    // Grille secondaire (plus fine)
    context.strokeStyle = 'rgba(0, 114, 229, 0.05)'; // Couleur primaire plus transparente
    context.lineWidth = lineWidth / 2;
    
    const subStep = step / 4;
    for (let i = 0; i <= gridSize * 4; i++) {
      if (i % 4 === 0) continue; // Éviter de redessiner les lignes principales
      
      const pos = i * subStep;
      
      // Ligne horizontale secondaire
      context.beginPath();
      context.moveTo(0, pos);
      context.lineTo(size, pos);
      context.stroke();
      
      // Ligne verticale secondaire
      context.beginPath();
      context.moveTo(pos, 0);
      context.lineTo(pos, size);
      context.stroke();
    }
    
    // Lignes d'accent
    context.strokeStyle = 'rgba(0, 114, 229, 0.3)'; // Couleur primaire plus visible
    context.lineWidth = lineWidth * 2;
    
    const accentStep = step * 10;
    for (let i = 0; i <= gridSize / 10; i++) {
      const pos = i * accentStep;
      
      // Ligne horizontale d'accent
      context.beginPath();
      context.moveTo(0, pos);
      context.lineTo(size, pos);
      context.stroke();
      
      // Ligne verticale d'accent
      context.beginPath();
      context.moveTo(pos, 0);
      context.lineTo(pos, size);
      context.stroke();
    }
    
    // Cercles concentriques
    const centerX = size / 2;
    const centerY = size / 2;
    const maxRadius = Math.min(size / 2, size / 2);
    const circleCount = 5;
    
    for (let i = 1; i <= circleCount; i++) {
      const radius = (maxRadius / circleCount) * i;
      context.beginPath();
      context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      context.strokeStyle = `rgba(0, 114, 229, ${0.05 + (i / circleCount) * 0.1})`;
      context.lineWidth = lineWidth * (0.5 + (i / circleCount) * 1);
      context.stroke();
    }
    
    return canvas;
  }, []);
  
  const texture = useMemo(() => {
    const t = new THREE.CanvasTexture(gridCanvas);
    t.wrapS = t.wrapT = THREE.RepeatWrapping;
    t.repeat.set(1, 1);
    return t;
  }, [gridCanvas]);
  
  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -2, 0]} receiveShadow>
      <planeGeometry args={[60, 60]} />
      <meshStandardMaterial 
        color="#0A1929" 
        map={texture} 
        roughness={0.8}
        metalness={0.3}
        envMapIntensity={0.3}
      />
    </mesh>
  );
};

// Composant pour les connexions entre modules amélioré
const EnterpriseConnection = ({ from, to, thickness = 0.05, color = "#4fc3f7", type = "standard", animated = false }) => {
  const pathRef = useRef();
  const [time, setTime] = useState(0);
  
  // Animation pour les connexions animées
  useFrame(({ clock }) => {
    if (animated) {
      setTime(clock.getElapsedTime());
    }
  });
  
  useEffect(() => {
    if (!pathRef.current || !from || !to) return;
    
    try {
      // Créer un chemin entre les deux points
      const direction = new THREE.Vector3().subVectors(to, from);
      const distance = direction.length();
      const mid = new THREE.Vector3().addVectors(from, to).multiplyScalar(0.5);
      
      // Courbe variable selon le type
      let curveHeight = 0.6;
      if (type === "command") curveHeight = 0.8;
      if (type === "result") curveHeight = 0.4;
      if (type === "bidirectional") curveHeight = 0.7;
      
      // Courbe vers le haut
      mid.y += curveHeight;
      
      const curve = new THREE.QuadraticBezierCurve3(from, mid, to);
      const points = curve.getPoints(20);
      
      // Mettre à jour la géométrie
      pathRef.current.geometry.setFromPoints(points);
    } catch (error) {
      console.error("Erreur lors de la création d'une connexion:", error);
    }
  }, [from, to, type]);
  
  // Si une des positions est undefined, ne pas rendre la connexion
  if (!from || !to) return null;
  
  // Calculer les propriétés selon le type
  let lineOpacity = 0.4;
  let lineColor = color;
  let dashSize = 0;
  let gapSize = 0;
  
  switch(type) {
    case "command":
      lineOpacity = 0.5;
      dashSize = 0.3;
      gapSize = 0.1;
      break;
    case "result":
      lineOpacity = 0.6;
      break;
    case "bidirectional":
      lineOpacity = 0.5;
      dashSize = 0.2;
      gapSize = 0.2;
      break;
    case "secure":
      lineOpacity = 0.7;
      lineColor = enterpriseColors.success;
      break;
    case "warning":
      lineOpacity = 0.7;
      lineColor = enterpriseColors.warning;
      break;
    case "error":
      lineOpacity = 0.7;
      lineColor = enterpriseColors.error;
      break;
    default:
      break;
  }
  
  // Effet d'animation pour les lignes en pointillés
  if (animated && (dashSize > 0 || gapSize > 0)) {
    const dashOffset = -time * 0.5; // Vitesse de l'animation
    
    return (
      <line ref={pathRef}>
        <bufferGeometry />
        <lineDashedMaterial 
          color={lineColor} 
          linewidth={thickness * 3} // Plus épais pour les lignes en pointillés
          transparent={true}
          opacity={lineOpacity}
          dashSize={dashSize}
          gapSize={gapSize}
          dashOffset={dashOffset}
        />
      </line>
    );
  }
  
  return (
    <line ref={pathRef}>
      <bufferGeometry />
      <lineBasicMaterial 
        color={lineColor} 
        linewidth={thickness * 3} // Plus épais
        transparent={true}
        opacity={lineOpacity}
      />
    </line>
  );
};

// Composant pour les zones de traitement des données amélioré
const EnterpriseProcessingZone = ({ position, size = 2, intensity = 1, color = "#ffffff", pulsate = true, type = "standard" }) => {
  const meshRef = useRef();
  const glowRef = useRef();
  
  // Animation de pulsation
  useFrame(({ clock }) => {
    if (!meshRef.current || !pulsate) return;
    
    const time = clock.getElapsedTime();
    
    // Différentes animations selon le type
    let pulseSpeed = 2;
    let pulseAmplitude = 0.3;
    
    switch(type) {
      case "compute":
        pulseSpeed = 3;
        pulseAmplitude = 0.4;
        break;
      case "data":
        pulseSpeed = 1.5;
        pulseAmplitude = 0.25;
        break;
      case "security":
        pulseSpeed = 1;
        pulseAmplitude = 0.2;
        break;
      default:
        break;
    }
    
    const pulse = 0.7 + Math.sin(time * pulseSpeed) * pulseAmplitude;
    
    meshRef.current.scale.set(pulse, pulse, pulse);
    
    if (glowRef.current && glowRef.current.material) {
      glowRef.current.material.opacity = 0.2 + Math.sin(time * pulseSpeed * 1.5) * 0.1;
    }
  });
  
  return (
    <group position={position}>
      {/* Sphère principale */}
      <mesh ref={meshRef}>
        <sphereGeometry args={[size, 32, 32]} />
        <meshBasicMaterial 
          color={color} 
          transparent={true} 
          opacity={0.3} 
          wireframe={true} 
          depthWrite={false}
        />
      </mesh>
      
      {/* Effet de glow */}
      <mesh ref={glowRef} scale={1.2}>
        <sphereGeometry args={[size, 32, 32]} />
        <meshBasicMaterial 
          color={color} 
          transparent={true} 
          opacity={0.15} 
          depthWrite={false}
        />
      </mesh>
      
      {/* Particules à l'intérieur de la zone */}
      {Array.from({ length: 10 }).map((_, i) => (
        <mesh 
          key={i} 
          position={[
            (Math.random() - 0.5) * size * 1.5,
            (Math.random() - 0.5) * size * 1.5,
            (Math.random() - 0.5) * size * 1.5
          ]}
          scale={0.1 + Math.random() * 0.2}
        >
          <sphereGeometry args={[1, 8, 8]} />
          <meshBasicMaterial 
            color={color} 
            transparent={true}
            opacity={0.5 + Math.random() * 0.5}
          />
        </mesh>
      ))}
    </group>
  );
};

// Composant pour les indications de processus amélioré
const EnterpriseProcessLabel = ({ position, text, icon, scale = 1.5, variant = "standard" }) => {
  return (
    <group position={position}>
      <Html
        position={[0, 0, 0]}
        center
        distanceFactor={10}
      >
        <div style={{ 
          background: variant === "primary" 
            ? `linear-gradient(135deg, rgba(0, 114, 229, 0.8) 0%, rgba(10, 25, 41, 0.95) 100%)`
            : variant === "secondary"
              ? `linear-gradient(135deg, rgba(121, 40, 202, 0.8) 0%, rgba(10, 25, 41, 0.95) 100%)`
              : variant === "success"
                ? `linear-gradient(135deg, rgba(82, 196, 26, 0.8) 0%, rgba(10, 25, 41, 0.95) 100%)`
                : variant === "warning"
                  ? `linear-gradient(135deg, rgba(250, 173, 20, 0.8) 0%, rgba(10, 25, 41, 0.95) 100%)`
                  : variant === "error"
                    ? `linear-gradient(135deg, rgba(255, 77, 79, 0.8) 0%, rgba(10, 25, 41, 0.95) 100%)`
                    : `linear-gradient(135deg, rgba(19, 47, 76, 0.8) 0%, rgba(10, 25, 41, 0.95) 100%)`,
          padding: '10px 16px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          transform: `scale(${scale})`,
          backdropFilter: 'blur(8px)',
          border: variant === "primary" 
            ? '1px solid rgba(0, 114, 229, 0.3)'
            : variant === "secondary"
              ? '1px solid rgba(121, 40, 202, 0.3)'
              : variant === "success"
                ? '1px solid rgba(82, 196, 26, 0.3)'
                : variant === "warning"
                  ? '1px solid rgba(250, 173, 20, 0.3)'
                  : variant === "error"
                    ? '1px solid rgba(255, 77, 79, 0.3)'
                    : '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)'
        }}>
          {React.cloneElement(icon, { 
            size: 20, 
            color: variant === "primary" 
              ? enterpriseColors.primary
              : variant === "secondary"
                ? enterpriseColors.secondary
                : variant === "success"
                  ? enterpriseColors.success
                  : variant === "warning"
                    ? enterpriseColors.warning
                    : variant === "error"
                      ? enterpriseColors.error
                      : 'white',
            strokeWidth: 2
          })}
          <Typography variant="body2" sx={{ 
            color: 'white',
            ml: 1.5,
            fontWeight: 'bold',
            fontSize: '0.9rem',
            letterSpacing: '0.01em'
          }}>
            {text}
          </Typography>
        </div>
      </Html>
    </group>
  );
};

// Nouvel objet pour contenir les positions des éléments
const getScenePositions = () => ({
  // Frontend et applications clientes
  frontend: new THREE.Vector3(-12, 1, 0),
  mobileApp: new THREE.Vector3(-12, 4, 3),
  webPortal: new THREE.Vector3(-12, -2, 3),
  
  // Architecture en couches du backend (style entreprise)
  backendAPIGateway: new THREE.Vector3(0, 5, 0),      // Couche supérieure: API Gateway
  backendCoreDomain: new THREE.Vector3(0, 2, 0),      // Couche suivante: Core Domain Services
  backendChannels: new THREE.Vector3(0, -1, 0),       // Couche suivante: Integration Services
  backendInfrastructure: new THREE.Vector3(0, -4, 0), // Couche inférieure: Infrastructure
  
  backendCenter: new THREE.Vector3(0, 0, 0),          // Point central du backend
  
  // Environnements et agents
  environmentDev: new THREE.Vector3(12, 5, 0),        // Environnement DEV
  environmentTest: new THREE.Vector3(12, 1, 0),       // Environnement TEST
  environmentProd: new THREE.Vector3(12, -3, 0),      // Environnement PROD
  
  // Points de processus intermédiaires plus sophistiqués 
  process1: new THREE.Vector3(-6, 3, 0),              // Entre frontend et backend (authentication)
  process2: new THREE.Vector3(6, 3, 0),               // Entre backend et environnements (orchestration)
  process3: new THREE.Vector3(0, -7, 0),              // Sous le backend (analytics)
  process4: new THREE.Vector3(-9, 7, 0),              // Process spécifique pour le mode record
  process5: new THREE.Vector3(9, 7, 0),               // Process spécifique pour le mode execute
});

// Composant pour configurer la caméra et les contrôles
const CameraController = ({ activeView }) => {
  const { camera } = useThree();
  const positions = getScenePositions();
  
  useEffect(() => {
    let targetPosition;
    let targetLookAt;
    
    // Position par défaut du backend (pour la compatibilité)
    const backendPosition = positions.backendCenter;
    
    switch(activeView) {
      case 'frontend':
        targetPosition = new THREE.Vector3(-12, 3, 10);
        targetLookAt = positions.frontend;
        break;
      case 'backend':
        targetPosition = new THREE.Vector3(0, 6, 12);
        targetLookAt = backendPosition;
        break;
      case 'agent':
        targetPosition = new THREE.Vector3(12, 5, 10);
        targetLookAt = positions.environmentTest;
        break;
      case 'record':
        targetPosition = new THREE.Vector3(-2, 6, 12);
        targetLookAt = new THREE.Vector3(-2, 0, 0);
        break;
      case 'execute':
        targetPosition = new THREE.Vector3(2, 6, 12);
        targetLookAt = new THREE.Vector3(2, 0, 0);
        break;
      default: // overview
        targetPosition = new THREE.Vector3(0, 10, 18);
        targetLookAt = new THREE.Vector3(0, 0, 0);
    }
    
    // Animation fluide de la caméra avec courbe d'accélération/décélération
    const duration = 45; // frames (plus lent pour plus de fluidité)
    let frame = 0;
    
    const initialPosition = camera.position.clone();
    const initialTarget = new THREE.Vector3(0, 0, 0);
    camera.getWorldDirection(initialTarget);
    initialTarget.multiplyScalar(10).add(camera.position);
    
    // Vérifier que les vecteurs existent
    if (!targetPosition || !targetLookAt) {
      console.error("Positions de caméra non définies", { targetPosition, targetLookAt });
      return;
    }
    
    const animateCamera = () => {
      if (frame >= duration) return;
      
      const t = frame / duration;
      // Courbe d'accélération/décélération cubique pour un mouvement très fluide
      const easeT = t < 0.5 
        ? 4 * t * t * t 
        : 1 - Math.pow(-2 * t + 2, 3) / 2;
      
      // Interpoler la position
      camera.position.lerpVectors(initialPosition, targetPosition, easeT);
      
      // Regarder progressivement vers la cible
      const currentLookAt = new THREE.Vector3().lerpVectors(initialTarget, targetLookAt, easeT);
      camera.lookAt(currentLookAt);
      
      frame++;
      requestAnimationFrame(animateCamera);
    };
    
    animateCamera();
  }, [activeView, camera]);
  
  return (
    <>
      <PerspectiveCamera
        makeDefault
        position={[0, 10, 18]}
        fov={45}
        near={0.1}
        far={1000}
      />
      <OrbitControls 
        enablePan={true}
        enableZoom={true}
        enableRotate={true}
        minDistance={5}
        maxDistance={30}
        minPolarAngle={Math.PI / 6}
        maxPolarAngle={Math.PI / 2}
        dampingFactor={0.1}
        rotateSpeed={0.7}
        zoomSpeed={0.8}
      />
    </>
  );
};

// Scène principale améliorée restructurée avec hooks R3F correctement utilisés
const EnterpriseScene = ({ activeView, mode }) => {
  const positions = getScenePositions();
  
  return (
    <>
      {/* Configuration de la caméra */}
      <CameraController activeView={activeView} />
      
      {/* Éclairage amélioré */}
      <ambientLight intensity={0.3} />
      <directionalLight 
        position={[10, 15, 5]} 
        intensity={0.7}
        castShadow 
        shadow-mapSize-width={2048} 
        shadow-mapSize-height={2048}
        shadow-camera-far={50}
        shadow-camera-left={-20}
        shadow-camera-right={20}
        shadow-camera-top={20}
        shadow-camera-bottom={-20}
      />
      <pointLight position={[-5, 8, -5]} intensity={0.5} color={enterpriseColors.primary} distance={25} />
      <pointLight position={[5, 8, -5]} intensity={0.5} color={enterpriseColors.secondary} distance={25} />
      <spotLight 
        position={[0, 15, 0]} 
        angle={0.3} 
        penumbra={0.8} 
        intensity={0.7} 
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
      
      {/* Environment */}
      <Environment preset="night" />
      <Stars radius={100} depth={50} count={3000} factor={4} saturation={0.5} fade speed={0.5} />
      
      {/* Composants du système */}
      
      {/* FRONTEND LAYER */}
      <EnterpriseModule 
        position={positions.frontend} 
        color={enterpriseColors.neutral2}
        size={[6, 3, 1.5]} 
        name="Frontend Portal" 
        description="User interface for system administration and monitoring"
        isHighlighted={activeView === 'frontend'}
        icon={<Monitor />}
        status={mode === 'record' || mode === 'execute' ? "success" : "normal"}
      />
      
      <EnterpriseModule 
        position={positions.mobileApp} 
        color={enterpriseColors.primaryDark}
        size={[3, 2, 1.2]} 
        name="Mobile App" 
        description="Native applications for iOS and Android devices"
        isHighlighted={activeView === 'frontend'}
        icon={<Cpu />}
      />
      
      <EnterpriseModule 
        position={positions.webPortal} 
        color={enterpriseColors.primaryDark}
        size={[3, 2, 1.2]} 
        name="Web Portal" 
        description="Customer-facing web interface"
        isHighlighted={activeView === 'frontend'}
        icon={<Globe />}
      />
      
      {/* BACKEND LAYER */}
      <EnterpriseModule 
        position={positions.backendAPIGateway} 
        color={enterpriseColors.apiGateway}
        size={[9, 1.6, 1.8]} 
        name="API Gateway / Authentication" 
        description="Secure entry point and authentication services"
        isHighlighted={activeView === 'backend'}
        icon={<Shield />}
        status={mode === 'record' || mode === 'execute' ? "success" : "normal"}
      />
      
      <EnterpriseModule 
        position={positions.backendCoreDomain} 
        color={enterpriseColors.coreDomain}
        size={[10, 2, 2]} 
        name="Core Domain Services" 
        description="Business logic and domain-specific services"
        isHighlighted={activeView === 'backend'}
        icon={<Layers />}
        status={mode === 'record' || mode === 'execute' ? "success" : "normal"}
      />
      
      <EnterpriseModule 
        position={positions.backendChannels} 
        color={enterpriseColors.integration}
        size={[9, 1.6, 1.8]} 
        name="Integration Services" 
        description="Integration with external systems and services"
        isHighlighted={activeView === 'backend'}
        icon={<Network />}
        status={mode === 'record' || mode === 'execute' ? "success" : "normal"}
      />
      
      <EnterpriseModule 
        position={positions.backendInfrastructure} 
        color={enterpriseColors.infrastructure}
        size={[10, 1.6, 1.8]} 
        name="Enterprise Infrastructure" 
        description="Core infrastructure services and event streaming"
        isHighlighted={activeView === 'backend'}
        icon={<Database />}
        status={mode === 'record' || mode === 'execute' ? "success" : "normal"}
      />
      
      {/* ENVIRONMENTS LAYER */}
      <EnterpriseModule 
        position={positions.environmentDev} 
        color={enterpriseColors.primaryDark}
        size={[5, 2.5, 1.6]} 
        name="Development" 
        description="Development environment for testing new features"
        isHighlighted={activeView === 'agent'}
        icon={<Code />}
        status={mode === 'record' ? "success" : "normal"}
      />
      
      <EnterpriseModule 
        position={positions.environmentTest} 
        color={enterpriseColors.secondaryDark}
        size={[5, 2.5, 1.6]} 
        name="Testing" 
        description="Test environment for QA and pre-production validation"
        isHighlighted={activeView === 'agent'}
        icon={<Activity />}
        status={mode === 'execute' ? "success" : "normal"}
      />
      
      <EnterpriseModule 
        position={positions.environmentProd} 
        color={enterpriseColors.neutral3}
        size={[5, 2.5, 1.6]} 
        name="Production" 
        description="Production environment for live systems"
        isHighlighted={activeView === 'agent'}
        icon={<Server />}
      />
      
      {/* PROCESSING ZONES */}
      {mode === 'record' && (
        <>
          <EnterpriseProcessingZone 
            position={positions.process1} 
            size={1.5} 
            color={enterpriseColors.error} 
            type="data"
          />
          <EnterpriseProcessingZone 
            position={positions.process4} 
            size={1.8} 
            color={enterpriseColors.error} 
            type="compute"
          />
          
          <EnterpriseProcessLabel 
            position={[positions.process1.x, positions.process1.y + 2, positions.process1.z]} 
            text="Event Processing" 
            icon={<RefreshCw />}
            variant="error"
          />
          <EnterpriseProcessLabel 
            position={[positions.process4.x, positions.process4.y + 2, positions.process4.z]} 
            text="Session Recording" 
            icon={<Upload />}
            variant="error"
          />
        </>
      )}
      
      {mode === 'execute' && (
        <>
          <EnterpriseProcessingZone 
            position={positions.process2} 
            size={1.5} 
            color={enterpriseColors.primary} 
            type="data"
          />
          <EnterpriseProcessingZone 
            position={positions.process5} 
            size={1.8} 
            color={enterpriseColors.primary} 
            type="compute"
          />
          
          <EnterpriseProcessLabel 
            position={[positions.process2.x, positions.process2.y + 2, positions.process2.z]} 
            text="Task Orchestration" 
            icon={<Play />}
            variant="primary"
          />
          <EnterpriseProcessLabel 
            position={[positions.process5.x, positions.process5.y + 2, positions.process5.z]} 
            text="Execution Engine" 
            icon={<Zap />}
            variant="primary"
          />
        </>
      )}
      
      <EnterpriseProcessingZone 
        position={positions.process3} 
        size={2} 
        color={enterpriseColors.secondary} 
        type="security"
      />
      <EnterpriseProcessLabel 
        position={[positions.process3.x, positions.process3.y - 2, positions.process3.z]} 
        text="Analytics & Monitoring" 
        icon={<BarChart />}
        variant="secondary"
      />
      
      {/* CONNECTIONS */}
      
      {/* Connexions standard toujours visibles */}
      <EnterpriseConnection from={positions.mobileApp} to={positions.frontend} thickness={0.08} color={enterpriseColors.primary} />
      <EnterpriseConnection from={positions.webPortal} to={positions.frontend} thickness={0.08} color={enterpriseColors.primary} />
      
      <EnterpriseConnection from={positions.backendAPIGateway} to={positions.backendCoreDomain} thickness={0.1} color={enterpriseColors.primary} />
      <EnterpriseConnection from={positions.backendCoreDomain} to={positions.backendChannels} thickness={0.1} color={enterpriseColors.primary} />
      <EnterpriseConnection from={positions.backendChannels} to={positions.backendInfrastructure} thickness={0.1} color={enterpriseColors.primary} />
      
      <EnterpriseConnection from={positions.backendInfrastructure} to={positions.process3} thickness={0.08} color={enterpriseColors.secondary} type="bidirectional" animated={true} />
      
      <EnterpriseConnection from={positions.environmentDev} to={positions.environmentTest} thickness={0.07} color={enterpriseColors.primary} type="command" />
      <EnterpriseConnection from={positions.environmentTest} to={positions.environmentProd} thickness={0.07} color={enterpriseColors.primary} type="command" />
      
      {/* Connexions spécifiques au mode */}
      {mode === 'record' && (
        <>
          <EnterpriseConnection from={positions.frontend} to={positions.process1} thickness={0.09} color={enterpriseColors.error} type="command" animated={true} />
          <EnterpriseConnection from={positions.process1} to={positions.process4} thickness={0.09} color={enterpriseColors.error} type="command" animated={true} />
          <EnterpriseConnection from={positions.process4} to={positions.backendAPIGateway} thickness={0.09} color={enterpriseColors.error} type="command" animated={true} />
          <EnterpriseConnection from={positions.backendChannels} to={positions.environmentDev} thickness={0.09} color={enterpriseColors.error} type="command" animated={true} />
        </>
      )}
      
      {mode === 'execute' && (
        <>
          <EnterpriseConnection from={positions.frontend} to={positions.backendAPIGateway} thickness={0.09} color={enterpriseColors.primary} type="command" animated={true} />
          <EnterpriseConnection from={positions.backendAPIGateway} to={positions.process5} thickness={0.09} color={enterpriseColors.primary} type="command" animated={true} />
          <EnterpriseConnection from={positions.process5} to={positions.process2} thickness={0.09} color={enterpriseColors.primary} type="command" animated={true} />
          <EnterpriseConnection from={positions.process2} to={positions.backendInfrastructure} thickness={0.09} color={enterpriseColors.primary} type="command" animated={true} />
          <EnterpriseConnection from={positions.backendInfrastructure} to={positions.environmentTest} thickness={0.09} color={enterpriseColors.primary} type="command" animated={true} />
        </>
      )}
      
      {/* DATA FLOWS */}
      
      {mode === 'record' && (
        <>
          {/* Flux de données pour le mode Record */}
          <EnhancedDataFlow 
            from={positions.frontend} 
            to={positions.process1} 
            color={enterpriseColors.error} 
            speed={0.007} 
            count={25} 
            size={0.07} 
            type="command"
            intensity={1.2}
          />
          <EnhancedDataFlow 
            from={positions.process1} 
            to={positions.process4} 
            color={enterpriseColors.error} 
            speed={0.008} 
            count={22} 
            size={0.07} 
            type="command"
            intensity={1.3}
          />
          <EnhancedDataFlow 
            from={positions.process4} 
            to={positions.backendAPIGateway} 
            color={enterpriseColors.error} 
            speed={0.009} 
            count={20} 
            size={0.07} 
            type="command"
            intensity={1.2}
          />
          <EnhancedDataFlow 
            from={positions.backendAPIGateway} 
            to={positions.backendCoreDomain} 
            color={enterpriseColors.error} 
            speed={0.01} 
            count={18} 
            size={0.06} 
            type="command"
            intensity={1.1}
          />
          <EnhancedDataFlow 
            from={positions.backendCoreDomain} 
            to={positions.backendChannels} 
            color={enterpriseColors.error} 
            speed={0.011} 
            count={15} 
            size={0.06} 
            type="command"
            intensity={1.0}
          />
          <EnhancedDataFlow 
            from={positions.backendChannels} 
            to={positions.environmentDev} 
            color={enterpriseColors.error} 
            speed={0.012} 
            count={30} 
            size={0.07} 
            type="command"
            intensity={1.5}
            curvature={1.5}
          />
          
          {/* Flux de retour */}
          <EnhancedDataFlow 
            from={positions.environmentDev} 
            to={positions.backendChannels} 
            color={enterpriseColors.errorLight} 
            speed={0.01} 
            count={25} 
            size={0.06} 
            type="result"
            intensity={1.2}
            curvature={0.8}
          />
          <EnhancedDataFlow 
            from={positions.backendChannels} 
            to={positions.backendCoreDomain} 
            color={enterpriseColors.errorLight} 
            speed={0.011} 
            count={22} 
            size={0.06} 
            type="result"
            intensity={1.1}
          />
          <EnhancedDataFlow 
            from={positions.backendCoreDomain} 
            to={positions.backendAPIGateway} 
            color={enterpriseColors.errorLight} 
            speed={0.011} 
            count={20} 
            size={0.06} 
            type="result"
            intensity={1.0}
          />
          <EnhancedDataFlow 
            from={positions.backendAPIGateway} 
            to={positions.frontend} 
            color={enterpriseColors.errorLight} 
            speed={0.008} 
            count={30} 
            size={0.07} 
            type="result"
            intensity={1.3}
            curvature={1.8}
          />
          
          {/* Flux vers l'analytics */}
          <EnhancedDataFlow 
            from={positions.backendInfrastructure} 
            to={positions.process3} 
            color={enterpriseColors.secondaryLight} 
            speed={0.006} 
            count={15} 
            size={0.05} 
            type="data"
            intensity={0.8}
            curvature={0.6}
          />
        </>
      )}
      
      {mode === 'execute' && (
        <>
          {/* Flux de commandes */}
          <EnhancedDataFlow 
            from={positions.frontend} 
            to={positions.backendAPIGateway} 
            color={enterpriseColors.primary} 
            speed={0.007} 
            count={25} 
            size={0.07} 
            type="command"
            intensity={1.2}
            curvature={1.8}
          />
          <EnhancedDataFlow 
            from={positions.backendAPIGateway} 
            to={positions.process5} 
            color={enterpriseColors.primary} 
            speed={0.01} 
            count={22} 
            size={0.07} 
            type="command"
            intensity={1.3}
            curvature={1.5}
          />
          <EnhancedDataFlow 
            from={positions.process5} 
            to={positions.process2} 
            color={enterpriseColors.primary} 
            speed={0.011} 
            count={20} 
            size={0.07} 
            type="command"
            intensity={1.4}
            curvature={1.2}
          />
          <EnhancedDataFlow 
            from={positions.process2} 
            to={positions.backendCoreDomain} 
            color={enterpriseColors.primary} 
            speed={0.01} 
            count={18} 
            size={0.06} 
            type="command"
            intensity={1.2}
            curvature={1.0}
          />
          <EnhancedDataFlow 
            from={positions.backendCoreDomain} 
            to={positions.backendChannels} 
            color={enterpriseColors.primary} 
            speed={0.009} 
            count={15} 
            size={0.06} 
            type="command"
            intensity={1.1}
          />
          <EnhancedDataFlow 
            from={positions.backendChannels} 
            to={positions.backendInfrastructure} 
            color={enterpriseColors.primary} 
            speed={0.01} 
            count={12} 
            size={0.06} 
            type="command"
            intensity={1.0}
          />
          <EnhancedDataFlow 
            from={positions.backendInfrastructure} 
            to={positions.environmentTest} 
            color={enterpriseColors.primary} 
            speed={0.011} 
            count={25} 
            size={0.07} 
            type="command"
            intensity={1.5}
            curvature={1.5}
          />
          
          {/* Flux de résultats */}
          <EnhancedDataFlow 
            from={positions.environmentTest} 
            to={positions.backendInfrastructure} 
            color={enterpriseColors.primaryLight} 
            speed={0.01} 
            count={22} 
            size={0.06} 
            type="result"
            intensity={1.3}
            curvature={0.8}
          />
          <EnhancedDataFlow 
            from={positions.backendInfrastructure} 
            to={positions.backendChannels} 
            color={enterpriseColors.primaryLight} 
            speed={0.009} 
            count={20} 
            size={0.06} 
            type="result"
            intensity={1.2}
          />
          <EnhancedDataFlow 
            from={positions.backendChannels} 
            to={positions.backendCoreDomain} 
            color={enterpriseColors.primaryLight} 
            speed={0.009} 
            count={18} 
            size={0.06} 
            type="result"
            intensity={1.1}
          />
          <EnhancedDataFlow 
            from={positions.backendCoreDomain} 
            to={positions.process2} 
            color={enterpriseColors.primaryLight} 
            speed={0.008} 
            count={15} 
            size={0.06} 
            type="result"
            intensity={1.0}
            curvature={0.7}
          />
          <EnhancedDataFlow 
            from={positions.process2} 
            to={positions.backendAPIGateway} 
            color={enterpriseColors.primaryLight} 
            speed={0.008} 
            count={20} 
            size={0.06} 
            type="result"
            intensity={1.2}
            curvature={0.9}
          />
          <EnhancedDataFlow 
            from={positions.backendAPIGateway} 
            to={positions.frontend} 
            color={enterpriseColors.primaryLight} 
            speed={0.007} 
            count={25} 
            size={0.07} 
            type="result"
            intensity={1.4}
            curvature={1.8}
          />
          
          {/* Flux vers l'analytics */}
          <EnhancedDataFlow 
            from={positions.backendInfrastructure} 
            to={positions.process3} 
            color={enterpriseColors.secondaryLight} 
            speed={0.006} 
            count={15} 
            size={0.05} 
            type="data"
            intensity={0.8}
            curvature={0.6}
          />
        </>
      )}
      
      {/* Sol / Plateforme améliorée */}
      <EnterpriseFloor />
    </>
  );
};

// Descriptions pour chaque vue avec contenu amélioré
const viewDescriptions = {
  overview: (
    <>
      <Typography variant="h5" gutterBottom fontWeight="600" sx={{ color: 'white', mb: 1.5 }}>
        Enterprise Architecture Overview
      </Typography>
      <Typography paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        Automata is a comprehensive enterprise-grade automation solution with a modern hexagonal architecture that enables recording
        and execution of automated test scenarios across multiple environments.
      </Typography>
      <Typography paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        The architecture consists of three main layers:
      </Typography>
      <Box sx={{ ml: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <Chip 
            label="Frontend" 
            icon={<Monitor size={16} />} 
            size="small" 
            sx={{ 
              bgcolor: 'rgba(0, 114, 229, 0.1)', 
              color: 'white',
              border: '1px solid rgba(0, 114, 229, 0.3)',
              mr: 1
            }} 
          />
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            Modern React interface for test management
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <Chip 
            label="Backend" 
            icon={<Layers size={16} />} 
            size="small" 
            sx={{ 
              bgcolor: 'rgba(0, 191, 165, 0.1)', 
              color: 'white',
              border: '1px solid rgba(0, 191, 165, 0.3)',
              mr: 1
            }} 
          />
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            Java Spring microservices with hexagonal architecture
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <Chip 
            label="Agents" 
            icon={<Server size={16} />} 
            size="small" 
            sx={{ 
              bgcolor: 'rgba(98, 0, 234, 0.1)', 
              color: 'white',
              border: '1px solid rgba(98, 0, 234, 0.3)',
              mr: 1
            }} 
          />
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            Distributed components deployed on target environments
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />
      <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', fontStyle: 'italic' }}>
        Select a specific view from the navigation panel to explore the architecture in detail.
      </Typography>
    </>
  ),
  frontend: (
    <>
      <Typography variant="h5" gutterBottom fontWeight="600" sx={{ color: 'white', mb: 1.5 }}>
        Frontend Layer
      </Typography>
      <Typography paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        The Automata Frontend layer provides an intuitive interface for comprehensive management of environments, functions, tasks, anomalies, and evolutions.
      </Typography>
      
      <Box sx={{ bgcolor: 'rgba(0, 114, 229, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(0, 114, 229, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Monitor size={16} style={{ marginRight: '8px' }} />
          Key Components
        </Typography>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Admin Portal: Complete system management dashboard
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Mobile App: Native iOS/Android control interface
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Web Portal: External user access portal
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            • Real-time monitoring and visualization tools
          </Typography>
        </Box>
      </Box>
      
      <Box sx={{ bgcolor: 'rgba(121, 40, 202, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(121, 40, 202, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Network size={16} style={{ marginRight: '8px' }} />
          Communication
        </Typography>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • WebSocket clients for real-time updates
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • RESTful API integration with backend services
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            • OAuth2/OIDC authentication flows
          </Typography>
        </Box>
      </Box>
      
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />
      <Typography variant="body2" sx={{ color: enterpriseColors.primary, fontStyle: 'italic', fontWeight: 500 }}>
        Built with React, Redux, and Material-UI for a responsive and intuitive user experience.
      </Typography>
    </>
  ),
  backend: (
    <>
      <Typography variant="h5" gutterBottom fontWeight="600" sx={{ color: 'white', mb: 1.5 }}>
        Backend Architecture
      </Typography>
      <Typography paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        The backend implements a sophisticated layered architecture providing flexibility, security, and separation of concerns.
      </Typography>
      
      <Box sx={{ bgcolor: 'rgba(41, 98, 255, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(41, 98, 255, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Shield size={16} style={{ marginRight: '8px' }} color={enterpriseColors.apiGateway} />
          API Gateway / Authentication
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)', ml: 2 }}>
          Single entry point for all client requests with comprehensive security, OAuth2/JWT authentication, and rate limiting.
        </Typography>
      </Box>
      
      <Box sx={{ bgcolor: 'rgba(0, 191, 165, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(0, 191, 165, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Layers size={16} style={{ marginRight: '8px' }} color={enterpriseColors.coreDomain} />
          Core Domain Services
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)', ml: 2 }}>
          Business logic implementation with DDD principles, CQRS pattern, and event-driven architecture for robust transactional processing.
        </Typography>
      </Box>
      
      <Box sx={{ bgcolor: 'rgba(98, 0, 234, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(98, 0, 234, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Network size={16} style={{ marginRight: '8px' }} color={enterpriseColors.integration} />
          Integration Services
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)', ml: 2 }}>
          Connectors for external system integration supporting various protocols (REST, SOAP, gRPC, messaging) with circuit breakers and retry policies.
        </Typography>
      </Box>
      
      <Box sx={{ bgcolor: 'rgba(38, 50, 56, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(38, 50, 56, 0.3)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Database size={16} style={{ marginRight: '8px' }} color={enterpriseColors.infrastructure} />
          Enterprise Infrastructure
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)', ml: 2 }}>
          Cross-cutting concerns including distributed caching, event streaming, monitoring, logging, and persistence with high availability.
        </Typography>
      </Box>
    </>
  ),
  agent: (
    <>
      <Typography variant="h5" gutterBottom fontWeight="600" sx={{ color: 'white', mb: 1.5 }}>
        Distributed Environments
      </Typography>
      <Typography paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        The Automata platform supports multiple environments, each with dedicated agents for test execution and monitoring.
      </Typography>
      
      <Box sx={{ bgcolor: 'rgba(0, 114, 229, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(0, 114, 229, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Code size={16} style={{ marginRight: '8px' }} />
          Development Environment
        </Typography>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Primary environment for scenario recording
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Lightweight agent with debugging capabilities
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            • Enhanced monitoring for developer feedback
          </Typography>
        </Box>
      </Box>
      
      <Box sx={{ bgcolor: 'rgba(121, 40, 202, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(121, 40, 202, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Activity size={16} style={{ marginRight: '8px' }} />
          Test Environment
        </Typography>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Primary environment for test execution
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Full-featured agent with comprehensive reporting
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            • Integration with CI/CD pipelines
          </Typography>
        </Box>
      </Box>
      
      <Box sx={{ bgcolor: 'rgba(37, 93, 154, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(37, 93, 154, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Server size={16} style={{ marginRight: '8px' }} />
          Production Environment
        </Typography>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Secure agent for production monitoring
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 0.5 }}>
            • Read-only monitoring capabilities
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            • Advanced security and limited permissions
          </Typography>
        </Box>
      </Box>
    </>
  ),
  record: (
    <>
      <Typography variant="h5" gutterBottom fontWeight="600" sx={{ color: 'white', mb: 1.5 }}>
        Record Mode
      </Typography>
      <Typography paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        Record mode enables capturing user interactions to create automated test scenarios with minimal effort.
      </Typography>
      
      <Box sx={{ bgcolor: 'rgba(255, 77, 79, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(255, 77, 79, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', fontWeight: 600 }}>
          Recording Process
        </Typography>
        <Box sx={{ ml: 1, mt: 1 }}>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(255, 77, 79, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              1
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              User initiates recording session through the frontend interface
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(255, 77, 79, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              2
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              WebSocket connection established with the backend events processor
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(255, 77, 79, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              3
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              DEV environment agent activated in recording mode
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(255, 77, 79, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              4
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              Agent captures all interactions with target application
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ 
              bgcolor: 'rgba(255, 77, 79, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              5
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              Events are processed, normalized, and stored as automation scenarios
            </Typography>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
        <AlertTriangle size={20} color={enterpriseColors.warning} style={{ marginRight: '8px' }} />
        <Typography variant="subtitle2" sx={{ color: enterpriseColors.warning }}>
          Active Recording Session
        </Typography>
      </Box>
    </>
  ),
  execute: (
    <>
      <Typography variant="h5" gutterBottom fontWeight="600" sx={{ color: 'white', mb: 1.5 }}>
        Execute Mode
      </Typography>
      <Typography paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        Execute mode replays recorded scenarios across environments for consistent testing and validation.
      </Typography>
      
      <Box sx={{ bgcolor: 'rgba(0, 114, 229, 0.1)', p: 1.5, borderRadius: 2, mb: 2, border: '1px solid rgba(0, 114, 229, 0.2)' }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', fontWeight: 600 }}>
          Execution Process
        </Typography>
        <Box sx={{ ml: 1, mt: 1 }}>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(0, 114, 229, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              1
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              User selects and initiates scenario execution from library
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(0, 114, 229, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              2
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              Execution engine orchestrates task distribution
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(0, 114, 229, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0}}>
              3
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              TEST environment agent executes actions on target systems
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ 
              bgcolor: 'rgba(0, 114, 229, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              4
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              Real-time results are streamed back to orchestration layer
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ 
              bgcolor: 'rgba(0, 114, 229, 0.3)', 
              color: 'white', 
              borderRadius: '50%', 
              width: 24, 
              height: 24, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              mr: 1.5,
              flexShrink: 0
            }}>
              5
            </Box>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              Test results are analyzed, validated and reported
            </Typography>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: 'white', mb: 1 }}>
          Current Execution Status:
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <Typography variant="body2" sx={{ color: 'white', mr: 1, minWidth: 100 }}>
            Progress:
          </Typography>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress 
              variant="determinate" 
              value={65} 
              sx={{
                borderRadius: 1,
                height: 8,
                backgroundColor: 'rgba(0, 114, 229, 0.2)',
                '& .MuiLinearProgress-bar': {
                  borderRadius: 1,
                  backgroundImage: 'linear-gradient(90deg, #0072E5 0%, #40A9FF 100%)',
                }
              }}
            />
          </Box>
          <Typography variant="body2" sx={{ color: 'white', minWidth: 45 }}>
            65%
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <Typography variant="body2" sx={{ color: 'white', mr: 1, minWidth: 100 }}>
            Status:
          </Typography>
          <Chip 
            icon={<Play size={14} />}
            label="Running" 
            size="small" 
            color="primary"
            sx={{ height: 24 }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ color: 'white', mr: 1, minWidth: 100 }}>
            Steps:
          </Typography>
          <Typography variant="body2" sx={{ color: 'white' }}>
            14/35 completed
          </Typography>
        </Box>
      </Box>
    </>
  )
};

// Composant principal simplifié
const AutomataEnterpriseSimplified = () => {
  const [activeView, setActiveView] = useState('overview');
  const [demoMode, setDemoMode] = useState(false);
  const [demoStep, setDemoStep] = useState(0);

  // Gestion du mode démo
  useEffect(() => {
    if (demoMode) {
      const interval = setInterval(() => {
        setDemoStep((prev) => {
          const next = (prev + 1) % 6;
          const viewTypes = ['overview', 'frontend', 'backend', 'agent', 'record', 'execute'];
          setActiveView(viewTypes[next]);
          return next;
        });
      }, 8000); // Durée plus longue pour permettre d'apprécier chaque vue
      
      return () => clearInterval(interval);
    }
  }, [demoMode]);

  // Vérifier si la vue est changée depuis le prop ou l'état
  const handleViewChange = (newView) => {
    // Ajouter un petit délai pour permettre au composant de s'initialiser
    setTimeout(() => {
      setActiveView(newView);
    }, 50);
  };

  return (
    <ThemeProvider theme={enterpriseTheme}>
      <Box sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        p: { xs: 2, md: 2 }, 
        backgroundColor: '#0A1929',
        backgroundImage: 'radial-gradient(circle at 25% 25%, rgba(5, 30, 60, 0.8) 0%, rgba(10, 25, 41, 1) 100%)',
        overflow: 'hidden'
      }}>
        <Paper 
          elevation={4} 
          sx={{ 
            p: { xs: 1.5, md: 2 }, 
            mb: 2, 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            backgroundImage: 'linear-gradient(135deg, rgba(19, 47, 76, 0.95) 0%, rgba(5, 30, 52, 0.95) 100%)',
            borderBottom: '1px solid rgba(0, 114, 229, 0.3)',
            borderRadius: '12px 12px 4px 4px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 46,
              height: 46,
              borderRadius: '12px',
              background: 'linear-gradient(135deg, #0072E5 0%, #1E88E5 100%)',
              boxShadow: '0 4px 12px rgba(0, 114, 229, 0.3)',
              mr: 2
            }}>
              <Cpu size={28} strokeWidth={1.5} />
            </Box>
            <Box>
              <Typography variant="h5" sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: 'white',
                fontWeight: 600,
                letterSpacing: '-0.01em',
                lineHeight: 1.2
              }}>
                Automata Enterprise
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                Advanced 3D Architecture Visualization
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title="Demo Mode">
              <FormControlLabel
                control={
                  <Switch
                    checked={demoMode}
                    onChange={() => setDemoMode(!demoMode)}
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2" sx={{ color: 'white', fontSize: '0.85rem' }}>
                    Auto Demo
                  </Typography>
                }
                sx={{ mr: 1 }}
              />
            </Tooltip>
          </Box>
        </Paper>

        <Grid container spacing={2} sx={{ flex: 1 }}>
          <Grid item xs={12} md={8}>
            <Paper 
              elevation={4} 
              sx={{ 
                p: 0, 
                height: '100%', 
                minHeight: { xs: '400px', md: '550px' }, 
                backgroundColor: 'rgba(5, 30, 52, 0.6)',
                backgroundImage: 'radial-gradient(circle at 75% 75%, rgba(19, 47, 76, 0.4) 0%, rgba(5, 30, 52, 0.6) 100%)',
                borderRadius: 3,
                overflow: 'hidden',
                border: '1px solid rgba(0, 114, 229, 0.1)',
                position: 'relative'
              }}
            >
              <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
                <Canvas shadows>
                  <Suspense fallback={null}>
                    <EnterpriseScene 
                      activeView={activeView} 
                      mode={activeView === 'record' ? 'record' : activeView === 'execute' ? 'execute' : null} 
                    />
                  </Suspense>
                </Canvas>
                
                {/* Overlay pour les instructions */}
                <Box 
                  sx={{ 
                    position: 'absolute', 
                    bottom: 16, 
                    left: 16, 
                    padding: '10px 16px', 
                    backgroundColor: 'rgba(0, 0, 0, 0.6)', 
                    borderRadius: 2,
                    color: 'white',
                    backdropFilter: 'blur(10px)',
                    maxWidth: '280px',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Users size={18} strokeWidth={1.5} />
                  <Typography variant="caption">
                    <b>Navigation</b>: Click and drag to rotate. Scroll to zoom in/out.
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Paper 
              elevation={4} 
              sx={{ 
                p: 2, 
                height: '100%',
                backgroundImage: 'linear-gradient(135deg, rgba(19, 47, 76, 0.95) 0%, rgba(5, 30, 52, 0.95) 100%)',
                borderRadius: 3
              }}
            >
              <Typography variant="h6" gutterBottom fontWeight={600}>Architecture Views</Typography>
              <Divider sx={{ mb: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
              
              {/* Boutons de navigation */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3 }}>
                <Button 
                  variant={activeView === 'overview' ? 'contained' : 'outlined'}
                  color="primary" 
                  startIcon={<PieChart size={18} />}
                  onClick={() => handleViewChange('overview')}
                  fullWidth
                  sx={{ 
                    justifyContent: 'flex-start', 
                    py: 0.8,
                    textAlign: 'left',
                    borderRadius: 2
                  }}
                >
                  System Overview
                </Button>
                <Button 
                  variant={activeView === 'frontend' ? 'contained' : 'outlined'}
                  color="primary" 
                  startIcon={<Monitor size={18} />}
                  onClick={() => handleViewChange('frontend')}
                  fullWidth
                  sx={{ 
                    justifyContent: 'flex-start', 
                    py: 0.8,
                    textAlign: 'left',
                    borderRadius: 2
                  }}
                >
                  Frontend Layer
                </Button>
                <Button 
                  variant={activeView === 'backend' ? 'contained' : 'outlined'}
                  color="primary" 
                  startIcon={<Layers size={18} />}
                  onClick={() => handleViewChange('backend')}
                  fullWidth
                  sx={{ 
                    justifyContent: 'flex-start', 
                    py: 0.8,
                    textAlign: 'left',
                    borderRadius: 2
                  }}
                >
                  Backend Services
                </Button>
                <Button 
                  variant={activeView === 'agent' ? 'contained' : 'outlined'}
                  color="primary" 
                  startIcon={<Server size={18} />}
                  onClick={() => handleViewChange('agent')}
                  fullWidth
                  sx={{ 
                    justifyContent: 'flex-start', 
                    py: 0.8,
                    textAlign: 'left',
                    borderRadius: 2
                  }}
                >
                  Environments
                </Button>
              </Box>
              
              <Divider sx={{ mb: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
              <Typography variant="h6" gutterBottom fontWeight={600}>Operation Modes</Typography>
              
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Button 
                  variant={activeView === 'record' ? 'contained' : 'outlined'}
                  color="error" 
                  onClick={() => handleViewChange('record')}
                  sx={{ flex: 1, borderRadius: 2 }}
                  startIcon={<Upload size={16} />}
                >
                  Record
                </Button>
                <Button 
                  variant={activeView === 'execute' ? 'contained' : 'outlined'}
                  color="info" 
                  onClick={() => handleViewChange('execute')}
                  sx={{ flex: 1, borderRadius: 2 }}
                  startIcon={<Play size={16} />}
                >
                  Execute
                </Button>
              </Box>
              
              <Divider sx={{ mb: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
              
              {/* Description de la vue actuelle */}
              <Box 
                sx={{ 
                  p: 2, 
                  backgroundColor: 'rgba(0, 0, 0, 0.1)', 
                  borderRadius: 2, 
                  flex: 1,
                  overflowY: 'auto',
                  border: '1px solid rgba(255, 255, 255, 0.05)'
                }}
              >
                {viewDescriptions[activeView]}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default AutomataEnterpriseSimplified;