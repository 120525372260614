// components/shared/workflow/WorkflowAnalysisPanel.js
import React, { useState, useEffect } from 'react';
import { 
  BarChart2, 
  AlertCircle, 
  Check, 
  Info, 
  ExternalLink, 
  Activity, 
  CornerUpRight, 
  Truck, 
  ChevronDown, 
  ChevronRight,
  RefreshCcw
} from 'lucide-react';
import { Panel } from 'reactflow';
import { WorkflowAnalyzer } from './WorkflowAnalyzer';
import { WorkflowValidator } from './WorkflowValidator';

/**
 * Panneau d'analyse du workflow qui fournit des informations
 * et des suggestions pour améliorer le workflow
 */
const WorkflowAnalysisPanel = ({ 
  nodes, 
  edges, 
  workflowName, 
  workflowDescription,
  onJumpToNode = () => {}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('validation');
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  // Générer le rapport d'analyse quand les nœuds ou les arêtes changent
  useEffect(() => {
    if (isOpen) {
      refreshAnalysis();
    }
  }, [isOpen, nodes, edges, workflowName]);
  
  const refreshAnalysis = () => {
    setIsLoading(true);
    
    // Utiliser un timeout pour permettre à l'UI de se mettre à jour
    setTimeout(() => {
      const newReport = WorkflowAnalyzer.generateReport(
        nodes, 
        edges, 
        workflowName, 
        workflowDescription
      );
      setReport(newReport);
      setIsLoading(false);
    }, 100);
  };
  
  // Si aucun rapport n'est disponible, afficher un bouton pour l'analyser
  if (!isOpen) {
    return (
      <Panel position="bottom-right" className="mb-20 mr-2">
        <button
          onClick={() => setIsOpen(true)}
          className="flex items-center bg-indigo-600 hover:bg-indigo-700 text-white px-3 py-2 rounded-md shadow-md transition-colors"
        >
          <BarChart2 size={16} className="mr-2" />
          Analyser le workflow
        </button>
      </Panel>
    );
  }
  
  return (
    <Panel position="bottom-right" className="w-96 max-w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-12 mr-2">
      {/* En-tête du panneau */}
      <div className="flex justify-between items-center p-3 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center">
          <BarChart2 size={18} className="text-indigo-600 dark:text-indigo-400 mr-2" />
          <h3 className="font-medium text-gray-800 dark:text-white">Analyse du workflow</h3>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={refreshAnalysis}
            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            title="Rafraîchir l'analyse"
          >
            <RefreshCcw size={16} className="text-gray-500 dark:text-gray-400" />
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <ChevronDown size={16} className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>
      </div>
      
      {/* Onglets */}
      <div className="flex border-b border-gray-200 dark:border-gray-700">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === 'validation'
              ? 'text-indigo-600 dark:text-indigo-400 border-b-2 border-indigo-600 dark:border-indigo-400'
              : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
          }`}
          onClick={() => setActiveTab('validation')}
        >
          Validation
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === 'metrics'
              ? 'text-indigo-600 dark:text-indigo-400 border-b-2 border-indigo-600 dark:border-indigo-400'
              : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
          }`}
          onClick={() => setActiveTab('metrics')}
        >
          Métriques
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === 'suggestions'
              ? 'text-indigo-600 dark:text-indigo-400 border-b-2 border-indigo-600 dark:border-indigo-400'
              : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
          }`}
          onClick={() => setActiveTab('suggestions')}
        >
          Suggestions
        </button>
      </div>
      
      {/* Contenu du panneau */}
      <div className="overflow-auto max-h-80 p-3">
        {isLoading ? (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
          </div>
        ) : report ? (
          <>
            {activeTab === 'validation' && (
              <ValidationTab 
                workflowName={workflowName}
                isValid={report.workflowInfo.isValid}
                errors={report.validation.errors}
                cycles={report.analysis.cycles}
                inconsistencies={WorkflowAnalyzer.detectInconsistencies(nodes, edges)}
                onJumpToNode={onJumpToNode}
              />
            )}
            
            {activeTab === 'metrics' && (
              <MetricsTab 
                metrics={report.metrics}
                paths={report.analysis.paths}
                bottlenecks={report.analysis.bottlenecks}
              />
            )}
            
            {activeTab === 'suggestions' && (
              <SuggestionsTab 
                suggestions={report.suggestions} 
              />
            )}
          </>
        ) : (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            Aucune donnée d'analyse disponible
          </div>
        )}
      </div>
    </Panel>
  );
};

// Onglet de validation
const ValidationTab = ({ workflowName, isValid, errors, cycles, inconsistencies, onJumpToNode }) => {
  return (
    <div className="space-y-4">
      {/* Statut global */}
      <div className={`p-3 rounded-lg ${
        isValid 
          ? 'bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800/30' 
          : 'bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800/30'
      }`}>
        <div className="flex items-start">
          <div className={`p-1 rounded-full ${
            isValid 
              ? 'bg-green-100 dark:bg-green-800/30 text-green-600 dark:text-green-400' 
              : 'bg-red-100 dark:bg-red-800/30 text-red-600 dark:text-red-400'
          }`}>
            {isValid ? <Check size={16} /> : <AlertCircle size={16} />}
          </div>
          <div className="ml-3">
            <h4 className={`text-sm font-medium ${
              isValid 
                ? 'text-green-800 dark:text-green-400' 
                : 'text-red-800 dark:text-red-400'
            }`}>
              {isValid ? 'Workflow valide' : 'Workflow invalide'}
            </h4>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              {isValid 
                ? `Le workflow "${workflowName}" est valide et peut être exporté.` 
                : `Le workflow "${workflowName}" contient des erreurs qui doivent être corrigées.`}
            </p>
          </div>
        </div>
      </div>
      
      {/* Liste des erreurs */}
      {errors && errors.length > 0 && (
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Erreurs de validation ({errors.length})
          </h4>
          <div className="space-y-2">
            {errors.map((error, index) => (
              <div 
                key={index}
                className="p-2 border border-red-200 dark:border-red-800/30 rounded-md bg-red-50 dark:bg-red-900/10 text-sm text-red-800 dark:text-red-400"
              >
                {error}
              </div>
            ))}
          </div>
        </div>
      )}
      
      {/* Cycles détectés */}
      {cycles && cycles.hasCycles && (
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Cycles détectés ({cycles.cycleCount})
          </h4>
          <div className="space-y-2">
            {cycles.details.slice(0, 3).map((cycle, index) => (
              <div 
                key={index}
                className="p-2 border border-yellow-200 dark:border-yellow-800/30 rounded-md bg-yellow-50 dark:bg-yellow-900/10 text-sm"
              >
                <div className="font-medium text-yellow-800 dark:text-yellow-400 mb-1">
                  Cycle #{index + 1}
                </div>
                <div className="text-gray-600 dark:text-gray-400 text-xs">
                  {cycle.nodes.join(' → ')} → {cycle.nodes[0]}
                </div>
              </div>
            ))}
            {cycles.cycleCount > 3 && (
              <div className="text-xs text-gray-500 dark:text-gray-400 text-center">
                + {cycles.cycleCount - 3} autres cycles non affichés
              </div>
            )}
          </div>
        </div>
      )}
      
      {/* Incohérences */}
      {inconsistencies && inconsistencies.length > 0 && (
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Incohérences potentielles ({inconsistencies.length})
          </h4>
          <div className="space-y-2">
            {inconsistencies.map((inconsistency, index) => (
              <div 
                key={index}
                className="p-2 border border-orange-200 dark:border-orange-800/30 rounded-md bg-orange-50 dark:bg-orange-900/10 text-sm"
              >
                <div className="text-orange-800 dark:text-orange-400">
                  {inconsistency.message}
                </div>
              </div>
            ))}
			{/* Suite du composant WorkflowAnalysisPanel.js */}

          </div>
        </div>
      )}
      
      {isValid && !cycles.hasCycles && (!inconsistencies || inconsistencies.length === 0) && (
        <div className="p-2 border border-green-200 dark:border-green-800/30 rounded-md bg-green-50 dark:bg-green-900/10 text-sm text-green-800 dark:text-green-400">
          Aucun problème détecté. Le workflow est valide et bien structuré.
        </div>
      )}
    </div>
  );
};

// Onglet de métriques
const MetricsTab = ({ metrics, paths, bottlenecks }) => {
  return (
    <div className="space-y-4">
      {/* Métriques de base */}
      <div>
        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
          <Activity size={16} className="mr-1" />
          Métriques générales
        </h4>
        <div className="grid grid-cols-2 gap-2">
          <MetricCard label="États" value={metrics.stateCount} />
          <MetricCard label="Transitions" value={metrics.transitionCount} />
          <MetricCard label="États initiaux" value={metrics.initialStateCount} />
          <MetricCard label="États finaux" value={metrics.finalStateCount} />
          <MetricCard 
            label="Complexité" 
            value={metrics.complexity} 
            color={
              metrics.complexity < 10 ? 'green' : 
              metrics.complexity < 20 ? 'yellow' : 'red'
            }
          />
          <MetricCard 
            label="Trans./État" 
            value={metrics.avgTransitionsPerState.toFixed(1)}
          />
        </div>
      </div>
      
      {/* Chemins */}
      {paths && (
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
            <CornerUpRight size={16} className="mr-1" />
            Analyse des chemins
          </h4>
          <div className="grid grid-cols-2 gap-2">
            <MetricCard label="Total chemins" value={paths.totalPaths} />
            <MetricCard 
              label="Longueur max"
              value={paths.longestPathLength} 
              color={
                paths.longestPathLength < 5 ? 'green' : 
                paths.longestPathLength < 10 ? 'yellow' : 'red'
              }
            />
          </div>
          
          {paths.details && paths.details.length > 0 && (
            <div className="mt-2">
              <PathsAccordion paths={paths.details} />
            </div>
          )}
        </div>
      )}
      
      {/* Goulots d'étranglement */}
      {bottlenecks && bottlenecks.length > 0 && (
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
            <Truck size={16} className="mr-1" />
            Goulots d'étranglement
          </h4>
          <div className="space-y-2">
            {bottlenecks.map((bottleneck, index) => (
              <div 
                key={index}
                className="p-2 border border-gray-200 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-800/50 text-sm"
              >
                <div className="font-medium text-gray-800 dark:text-gray-200 mb-1">
                  {bottleneck.label}
                </div>
                <div className="text-gray-600 dark:text-gray-400 text-xs">
                  {bottleneck.reason}{bottleneck.inDegree && ` (${bottleneck.inDegree} entrées, ${bottleneck.outDegree} sorties)`}
                  {bottleneck.pathPercentage && ` (Présent dans ${bottleneck.pathPercentage}% des chemins)`}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Onglet de suggestions
const SuggestionsTab = ({ suggestions = [] }) => {
  const getSuggestionColor = (type) => {
    switch (type) {
      case 'warning':
        return {
          bg: 'bg-yellow-50 dark:bg-yellow-900/10',
          border: 'border-yellow-200 dark:border-yellow-800/30',
          text: 'text-yellow-800 dark:text-yellow-400'
        };
      case 'error':
        return {
          bg: 'bg-red-50 dark:bg-red-900/10',
          border: 'border-red-200 dark:border-red-800/30',
          text: 'text-red-800 dark:text-red-400'
        };
      case 'info':
      default:
        return {
          bg: 'bg-blue-50 dark:bg-blue-900/10',
          border: 'border-blue-200 dark:border-blue-800/30',
          text: 'text-blue-800 dark:text-blue-400'
        };
    }
  };
  
  return (
    <div className="space-y-4">
      {suggestions.length > 0 ? (
        <div className="space-y-2">
          {suggestions.map((suggestion, index) => {
            const colors = getSuggestionColor(suggestion.type);
            
            return (
              <div 
                key={index}
                className={`p-3 border rounded-md ${colors.bg} ${colors.border}`}
              >
                <div className={`font-medium ${colors.text}`}>
                  {suggestion.message}
                </div>
                {suggestion.recommendation && (
                  <div className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                    {suggestion.recommendation}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          Aucune suggestion pour ce workflow
        </div>
      )}
    </div>
  );
};

// Composant de carte métrique
const MetricCard = ({ label, value, color }) => {
  const getColorClasses = () => {
    switch (color) {
      case 'green':
        return 'bg-green-50 dark:bg-green-900/10 text-green-700 dark:text-green-400';
      case 'yellow':
        return 'bg-yellow-50 dark:bg-yellow-900/10 text-yellow-700 dark:text-yellow-400';
      case 'red':
        return 'bg-red-50 dark:bg-red-900/10 text-red-700 dark:text-red-400';
      default:
        return 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300';
    }
  };
  
  return (
    <div className={`p-2 rounded-md border border-gray-200 dark:border-gray-700 ${getColorClasses()}`}>
      <div className="text-xs text-gray-500 dark:text-gray-400">{label}</div>
      <div className="text-lg font-semibold">{value}</div>
    </div>
  );
};

// Accordéon pour afficher les chemins
const PathsAccordion = ({ paths }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  
  return (
    <div className="border border-gray-200 dark:border-gray-700 rounded-md overflow-hidden">
      {paths.map((path, pathIndex) => (
        <div key={pathIndex} className="border-b border-gray-200 dark:border-gray-700 last:border-b-0">
          <div 
            className="flex justify-between items-center p-2 cursor-pointer bg-gray-50 dark:bg-gray-800/50 hover:bg-gray-100 dark:hover:bg-gray-800"
            onClick={() => setExpandedIndex(expandedIndex === pathIndex ? null : pathIndex)}
          >
            <div className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Chemin #{pathIndex + 1} ({path.length} transitions)
            </div>
            {expandedIndex === pathIndex ? (
              <ChevronDown size={16} className="text-gray-500" />
            ) : (
              <ChevronRight size={16} className="text-gray-500" />
            )}
          </div>
          
          {expandedIndex === pathIndex && (
            <div className="p-2 bg-white dark:bg-gray-800">
              <div className="space-y-2">
                {path.map((step, stepIndex) => (
                  <div 
                    key={stepIndex}
                    className="text-xs flex items-center p-1 border-b border-gray-100 dark:border-gray-700 last:border-b-0"
                  >
                    <span className="text-gray-800 dark:text-gray-200">{step.from}</span>
                    <span className="mx-2 text-gray-400">→</span>
                    <span className="text-gray-800 dark:text-gray-200">{step.to}</span>
                    <span className="ml-auto flex items-center">
                      {step.isAutomatic && (
                        <span className="bg-blue-100 dark:bg-blue-900/20 text-blue-800 dark:text-blue-400 text-xs px-1.5 py-0.5 rounded ml-1">
                          Auto
                        </span>
                      )}
                      <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 text-xs px-1.5 py-0.5 rounded ml-1">
                        {step.event}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WorkflowAnalysisPanel;