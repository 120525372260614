import React, { useState, useEffect, useCallback } from 'react';
import { 
  Activity, 
  RefreshCw, 
  AlertTriangle, 
  CheckCircle, 
  XCircle, 
  Shield, 
  Server,
  Power,
  Clock,
  Calendar,
  Lock,
  Unlock,
  Download,
  Upload,
  ArrowRightLeft,
  PlayCircle,
  PauseCircle,
  HelpCircle,
  FileText,
  Settings,
  ChevronDown,
  ChevronUp,
  Trash2,
  ExternalLink
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DateFormatter from '../../components/shared/DateFormatter';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

/**
 * Page d'état des connexions pour le module CEMAC ACH
 * Permet de visualiser et gérer les connexions JMS et les certificats
 */
const ConnectionStatus = () => {
  // États pour les différentes données
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jmsStatus, setJmsStatus] = useState({
    connected: false,
    lastCheck: null,
    queueStatus: [],
    messageCount: 0,
    brokerUrl: '',
    clientId: ''
  });
  const [certificates, setCertificates] = useState([]);
  const [refreshingJms, setRefreshingJms] = useState(false);
  const [refreshingCerts, setRefreshingCerts] = useState(false);
  const [activeTab, setActiveTab] = useState('connections'); // 'connections' ou 'certificates'
  const [successMessage, setSuccessMessage] = useState(null);
  const [showConnectionDetails, setShowConnectionDetails] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [filterExpiredCerts, setFilterExpiredCerts] = useState(false);
  const [filterValidCerts, setFilterValidCerts] = useState(false);
  const [filterWarningCerts, setFilterWarningCerts] = useState(false);
  const [expandedQueues, setExpandedQueues] = useState({});
  const [connectionStats, setConnectionStats] = useState({
    totalMessages: 0,
    sentMessages: 0,
    receivedMessages: 0,
    failedMessages: 0,
    uptime: '0h 0m 0s',
    messageRate: '0/s',
    averageMessageSize: '0 KB',
    peakRate: '0/s',
    totalConnections: 0,
    activeConnections: 0
  });

  // Function to get filtered certificates
  const getFilteredCertificates = useCallback(() => {
    let filtered = [...certificates];
    
    if (filterExpiredCerts) {
      filtered = filtered.filter(cert => cert.status === 'EXPIRED');
    }
    
    if (filterValidCerts) {
      filtered = filtered.filter(cert => cert.status === 'VALID');
    }
    
    if (filterWarningCerts) {
      filtered = filtered.filter(cert => cert.status === 'WARNING');
    }
    
    if (filterExpiredCerts || filterValidCerts || filterWarningCerts) {
      return filtered;
    }
    
    return certificates;
  }, [certificates, filterExpiredCerts, filterValidCerts, filterWarningCerts]);

  // Charger les données au chargement de la page
  useEffect(() => {
    fetchData();
    
    // Set up auto-refresh every 30 seconds
    const intervalId = setInterval(() => {
      refreshData();
    }, 30000);
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Toggle queue expanded state
  const toggleQueueExpanded = (queueName) => {
    setExpandedQueues(prev => ({
      ...prev,
      [queueName]: !prev[queueName]
    }));
  };

  // Function to refresh data without full loading state
  const refreshData = async () => {
    try {
      await Promise.all([
        fetchJmsStatus(false),
        fetchCertificates(false)
      ]);
    } catch (err) {
      console.error("Error during auto-refresh:", err);
    }
  };

  // Fonction pour charger toutes les données
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      await Promise.all([
        fetchJmsStatus(),
        fetchCertificates()
      ]);
    } catch (err) {
      console.error("Erreur lors du chargement des données :", err);
      setError("Impossible de charger les données de connexion. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger le statut JMS
  const fetchJmsStatus = async (showLoading = true) => {
    if (showLoading) setRefreshingJms(true);
    try {
      // Simulation de l'appel API - à remplacer par l'appel réel
      // const response = await apiService.cemacAch.getJmsStatus();
      
      // Données simulées pour la démonstration
      const mockData = {
        connected: Math.random() > 0.2, // 80% de chance d'être connecté
        lastCheck: new Date().toISOString(),
        brokerUrl: 'tcp://mq-systac2.cemac.int:61616',
        clientId: 'mxpay-cemac-ach',
        queueStatus: [
          { 
            name: 'ach.virement.outbound', 
            active: true, 
            messageCount: Math.floor(Math.random() * 100),
            consumerCount: 2,
            producerCount: 3,
            avgEnqueueTime: 45, // ms
            memoryUsage: Math.floor(Math.random() * 1024 * 10), // KB
            details: {
              queueSize: '2MB',
              oldest: new Date(Date.now() - Math.random() * 86400000).toISOString(),
              newest: new Date().toISOString(),
              persistenceEnabled: true,
              exclusive: false,
              routingType: 'MULTICAST',
              maxConsumers: -1
            }
          },
          { 
            name: 'ach.virement.inbound', 
            active: true, 
            messageCount: Math.floor(Math.random() * 50),
            consumerCount: 1,
            producerCount: 2,
            avgEnqueueTime: 32, // ms
            memoryUsage: Math.floor(Math.random() * 1024 * 5), // KB
            details: {
              queueSize: '1.2MB',
              oldest: new Date(Date.now() - Math.random() * 43200000).toISOString(),
              newest: new Date().toISOString(),
              persistenceEnabled: true,
              exclusive: false,
              routingType: 'MULTICAST',
              maxConsumers: -1
            }
          },
          { 
            name: 'ach.cheque.outbound', 
            active: true, 
            messageCount: Math.floor(Math.random() * 75),
            consumerCount: 2,
            producerCount: 2,
            avgEnqueueTime: 38, // ms
            memoryUsage: Math.floor(Math.random() * 1024 * 7), // KB
            details: {
              queueSize: '1.6MB',
              oldest: new Date(Date.now() - Math.random() * 64800000).toISOString(),
              newest: new Date().toISOString(),
              persistenceEnabled: true,
              exclusive: false,
              routingType: 'MULTICAST',
              maxConsumers: -1
            }
          },
          { 
            name: 'ach.cheque.inbound', 
            active: true, 
            messageCount: Math.floor(Math.random() * 30),
            consumerCount: 1,
            producerCount: 1,
            avgEnqueueTime: 29, // ms
            memoryUsage: Math.floor(Math.random() * 1024 * 3), // KB
            details: {
              queueSize: '800KB',
              oldest: new Date(Date.now() - Math.random() * 21600000).toISOString(),
              newest: new Date().toISOString(),
              persistenceEnabled: true,
              exclusive: false,
              routingType: 'MULTICAST',
              maxConsumers: -1
            }
          },
          { 
            name: 'ach.annulation.outbound', 
            active: Math.random() > 0.1,
            messageCount: Math.floor(Math.random() * 15),
            consumerCount: 1,
            producerCount: 1,
            avgEnqueueTime: 25, // ms
            memoryUsage: Math.floor(Math.random() * 1024 * 2), // KB
            details: {
              queueSize: '420KB',
              oldest: new Date(Date.now() - Math.random() * 10800000).toISOString(),
              newest: new Date().toISOString(),
              persistenceEnabled: true,
              exclusive: false,
              routingType: 'MULTICAST',
              maxConsumers: -1
            }
          },
          { 
            name: 'ach.rapport.outbound', 
            active: Math.random() > 0.1,
            messageCount: Math.floor(Math.random() * 8),
            consumerCount: 1,
            producerCount: 1,
            avgEnqueueTime: 22, // ms
            memoryUsage: Math.floor(Math.random() * 1024), // KB
            details: {
              queueSize: '320KB',
              oldest: new Date(Date.now() - Math.random() * 7200000).toISOString(),
              newest: new Date().toISOString(),
              persistenceEnabled: true,
              exclusive: false,
              routingType: 'MULTICAST',
              maxConsumers: -1
            }
          },
          { 
            name: 'ach.dlq', 
            active: true,
            messageCount: Math.floor(Math.random() * 3),
            consumerCount: 0,
            producerCount: 0,
            avgEnqueueTime: 0, // ms
            memoryUsage: Math.floor(Math.random() * 512), // KB
            details: {
              queueSize: '156KB',
              oldest: new Date(Date.now() - Math.random() * 172800000).toISOString(),
              newest: new Date(Date.now() - Math.random() * 3600000).toISOString(),
              persistenceEnabled: true,
              exclusive: false,
              routingType: 'ANYCAST',
              maxConsumers: -1
            }
          }
        ]
      };
      
      // Calculer le nombre total de messages
      const totalMessages = mockData.queueStatus.reduce((sum, queue) => sum + queue.messageCount, 0);
      
      setJmsStatus({
        ...mockData,
        messageCount: totalMessages
      });
      
      // Simuler les statistiques de connexion
      setConnectionStats({
        totalMessages: 8742,
        sentMessages: 3812,
        receivedMessages: 4930,
        failedMessages: 103,
        uptime: '72h 13m 45s',
        messageRate: '12.4/s',
        averageMessageSize: '24.3 KB',
        peakRate: '45.7/s',
        totalConnections: 12,
        activeConnections: 6
      });
      
      if (showLoading) {
        setSuccessMessage("Statut JMS mis à jour avec succès");
        setTimeout(() => setSuccessMessage(null), 3000);
      }
    } catch (err) {
      console.error("Erreur lors du chargement du statut JMS:", err);
      if (showLoading) {
        setError("Impossible de charger le statut JMS. Veuillez réessayer.");
      }
    } finally {
      if (showLoading) setRefreshingJms(false);
    }
  };

  // Fonction pour charger les certificats
  const fetchCertificates = async (showLoading = true) => {
    if (showLoading) setRefreshingCerts(true);
    try {
      // Simulation de l'appel API - à remplacer par l'appel réel
      // const response = await apiService.cemacAch.getCertificates();
      
      // Données simulées pour la démonstration
      const mockCertificates = [
        {
          id: 'cert001',
          alias: 'mxpay-beac-signature',
          type: 'SIGNATURE',
          issuer: 'BEAC Certification Authority',
          subject: 'MXPAY Signature Certificate',
          validFrom: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString(), // 90 jours dans le passé
          validTo: new Date(Date.now() + 275 * 24 * 60 * 60 * 1000).toISOString(), // 275 jours dans le futur
          status: 'VALID',
          keyStore: 'SYSTAC2-KeyStore',
          algorithm: 'RSA',
          keySize: '2048',
          serialNumber: '3048a7c92d83922a',
          version: 'V3',
          usage: 'Digital Signature, Non-Repudiation',
          thumbprint: 'a1:b2:c3:d4:e5:f6:a7:b8:c9:d0:e1:f2:a3:b4:c5:d6'
        },
        {
          id: 'cert002',
          alias: 'mxpay-beac-encryption',
          type: 'ENCRYPTION',
          issuer: 'BEAC Certification Authority',
          subject: 'MXPAY Encryption Certificate',
          validFrom: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString(), // 90 jours dans le passé
          validTo: new Date(Date.now() + 275 * 24 * 60 * 60 * 1000).toISOString(), // 275 jours dans le futur
          status: 'VALID',
          keyStore: 'SYSTAC2-KeyStore',
          algorithm: 'RSA',
          keySize: '2048',
          serialNumber: '2937b6d81c72811b',
          version: 'V3',
          usage: 'Key Encipherment, Data Encipherment',
          thumbprint: 'b2:c3:d4:e5:f6:a7:b8:c9:d0:e1:f2:a3:b4:c5:d6:e7'
        },
        {
          id: 'cert003',
          alias: 'beac-root-ca',
          type: 'TRUST',
          issuer: 'BEAC Root CA',
          subject: 'BEAC Root CA',
          validFrom: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString(), // 1 an dans le passé
          validTo: new Date(Date.now() + 4 * 365 * 24 * 60 * 60 * 1000).toISOString(), // 4 ans dans le futur
          status: 'VALID',
          keyStore: 'SYSTAC2-TrustStore',
          algorithm: 'RSA',
          keySize: '4096',
          serialNumber: '1a2b3c4d5e6f7g8h',
          version: 'V3',
          usage: 'Certificate Signing, CRL Signing',
          thumbprint: 'c3:d4:e5:f6:a7:b8:c9:d0:e1:f2:a3:b4:c5:d6:e7:f8'
        },
        {
          id: 'cert004',
          alias: 'beac-authentication',
          type: 'AUTHENTICATION',
          issuer: 'BEAC Certification Authority',
          subject: 'BEAC Authentication Certificate',
          validFrom: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000).toISOString(), // 180 jours dans le passé
          validTo: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(), // 30 jours dans le futur
          status: 'WARNING', // Expiration proche
          keyStore: 'SYSTAC2-TrustStore',
          algorithm: 'RSA',
          keySize: '2048',
          serialNumber: '4d5e6f7g8h9i0j1k',
          version: 'V3',
          usage: 'Client Authentication, Server Authentication',
          thumbprint: 'd4:e5:f6:a7:b8:c9:d0:e1:f2:a3:b4:c5:d6:e7:f8:a9'
        },
        {
          id: 'cert005',
          alias: 'old-cert-expired',
          type: 'SIGNATURE',
          issuer: 'BEAC Certification Authority',
          subject: 'Old Signature Certificate',
          validFrom: new Date(Date.now() - 730 * 24 * 60 * 60 * 1000).toISOString(), // 2 ans dans le passé
          validTo: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(), // 30 jours dans le passé
          status: 'EXPIRED',
          keyStore: 'SYSTAC2-KeyStore',
          algorithm: 'RSA',
          keySize: '1024',
          serialNumber: '5e6f7g8h9i0j1k2l',
          version: 'V3',
          usage: 'Digital Signature, Non-Repudiation (Obsolete)',
          thumbprint: 'e5:f6:a7:b8:c9:d0:e1:f2:a3:b4:c5:d6:e7:f8:a9:b0'
        }
      ];
      
      setCertificates(mockCertificates);
      if (showLoading) {
        setSuccessMessage("Liste des certificats mise à jour avec succès");
        setTimeout(() => setSuccessMessage(null), 3000);
      }
    } catch (err) {
      console.error("Erreur lors du chargement des certificats:", err);
      if (showLoading) {
        setError("Impossible de charger les certificats. Veuillez réessayer.");
      }
    } finally {
      if (showLoading) setRefreshingCerts(false);
    }
  };

  // Fonction pour redémarrer la connexion JMS
  const restartJmsConnection = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Simulation de l'appel API - à remplacer par l'appel réel
      // await apiService.cemacAch.restartJmsConnection();
      
      // Simuler un délai pour le redémarrage
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Recharger le statut JMS
      await fetchJmsStatus();
      
      setSuccessMessage("Connexion JMS redémarrée avec succès");
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors du redémarrage de la connexion JMS:", err);
      setError("Impossible de redémarrer la connexion JMS. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour purger une file d'attente
  const purgeQueue = async (queueName) => {
    setLoading(true);
    setError(null);
    
    try {
      // Simulation de l'appel API - à remplacer par l'appel réel
      // await apiService.cemacAch.purgeQueue(queueName);
      
      // Simuler un délai pour la purge
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Mettre à jour l'état local
      setJmsStatus(prevState => ({
        ...prevState,
        queueStatus: prevState.queueStatus.map(q => 
          q.name === queueName ? { ...q, messageCount: 0 } : q
        )
      }));
      
      setSuccessMessage(`File d'attente ${queueName} purgée avec succès`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error(`Erreur lors de la purge de la file d'attente ${queueName}:`, err);
      setError(`Impossible de purger la file d'attente ${queueName}. Veuillez réessayer.`);
    } finally {
      setLoading(false);
    }
  };

  // Function to show certificate details
  const showCertificateDetails = (cert) => {
    setSelectedCertificate(cert);
    setShowCertificateModal(true);
  };

  // Function to renew certificate
  const renewCertificate = async (certId) => {
    setLoading(true);
    setError(null);
    
    try {
      // Simulation de l'appel API - à remplacer par l'appel réel
      // await apiService.cemacAch.renewCertificate(certId);
      
      // Simuler un délai pour le renouvellement
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setSuccessMessage("Une demande de renouvellement de certificat a été initiée. Veuillez contacter votre administrateur BEAC pour finaliser le processus.");
      setTimeout(() => setSuccessMessage(null), 5000);
      
      // Recharger les certificats
      await fetchCertificates();
    } catch (err) {
      console.error("Erreur lors du renouvellement du certificat:", err);
      setError("Impossible de renouveler le certificat. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Retourne la classe de couleur en fonction de l'état d'expiration du certificat
  const getCertificateStatusClass = (cert) => {
    if (cert.status === 'EXPIRED') return 'text-red-600 dark:text-red-400';
    if (cert.status === 'WARNING') return 'text-yellow-600 dark:text-yellow-400';
    return 'text-green-600 dark:text-green-400';
  };

  // Retourne le nombre de jours restants avant expiration du certificat
  const getDaysUntilExpiration = (validTo) => {
    const today = new Date();
    const expiryDate = new Date(validTo);
    const diffTime = expiryDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays;
  };

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Activity className="h-6 w-6 mr-2 text-blue-500" />
            État des Connexions CEMAC ACH
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Surveillance et gestion des connexions JMS et des certificats
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchData}
            loading={loading && !refreshingJms && !refreshingCerts}
          >
            Actualiser Tout
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Navigation par onglets */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <nav className="-mb-px flex space-x-8">
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'connections'
                ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('connections')}
          >
            Connexions JMS
          </button>
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'certificates'
                ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('certificates')}
          >
            Certificats
          </button>
        </nav>
      </div>

      {/* Contenu des onglets */}
      <div className="mt-6">
        {/* Onglet Connexions JMS */}
        {activeTab === 'connections' && (
          <div className="space-y-6">
            {/* Carte d'état de la connexion JMS */}
            <Card
              title="État de la Connexion JMS"
              icon={Server}
              actions={
                <div className="flex space-x-2">
                  <Button
                    variant={jmsStatus.connected ? "outline" : "danger"}
                    size="sm"
                    icon={jmsStatus.connected ? RefreshCw : PlayCircle}
                    onClick={restartJmsConnection}
                    loading={refreshingJms}
                  >
                    {jmsStatus.connected ? "Redémarrer" : "Connecter"}
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    icon={RefreshCw}
                    onClick={fetchJmsStatus}
                    loading={refreshingJms}
                  >
                    Actualiser
                  </Button>
                </div>
              }
            >
              <div className="space-y-4">
                <div className="flex items-center">
                  <div className={`p-3 rounded-full mr-4 ${jmsStatus.connected ? 'bg-green-100 dark:bg-green-900/20' : 'bg-red-100 dark:bg-red-900/20'}`}>
                    {jmsStatus.connected ? (
                      <CheckCircle className="h-6 w-6 text-green-600 dark:text-green-400" />
                    ) : (
                      <XCircle className="h-6 w-6 text-red-600 dark:text-red-400" />
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      {jmsStatus.connected ? "Connexion Active" : "Déconnecté"}
                    </h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Dernière vérification: {jmsStatus.lastCheck ? (
                        <DateFormatter date={jmsStatus.lastCheck} format="full" showTime />
                      ): "Jamais"}
                    </p>
                  </div>
                </div>

                {jmsStatus.connected && (
                  <div>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <div className="bg-blue-50 dark:bg-blue-900/10 p-4 rounded-lg">
                        <h4 className="text-sm font-medium text-blue-700 dark:text-blue-300">Files d'attente actives</h4>
                        <p className="text-2xl font-bold text-blue-800 dark:text-blue-200">
                          {jmsStatus.queueStatus.filter(q => q.active).length}/{jmsStatus.queueStatus.length}
                        </p>
                      </div>
                      <div className="bg-green-50 dark:bg-green-900/10 p-4 rounded-lg">
                        <h4 className="text-sm font-medium text-green-700 dark:text-green-300">Messages en file d'attente</h4>
                        <p className="text-2xl font-bold text-green-800 dark:text-green-200">
                          {jmsStatus.messageCount}
                        </p>
                      </div>
                      <div className="bg-purple-50 dark:bg-purple-900/10 p-4 rounded-lg">
                        <h4 className="text-sm font-medium text-purple-700 dark:text-purple-300">Consommateurs</h4>
                        <p className="text-2xl font-bold text-purple-800 dark:text-purple-200">
                          {jmsStatus.queueStatus.reduce((sum, q) => sum + q.consumerCount, 0)}
                        </p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Détails de la connexion</h3>
                        <Button
                          variant="text"
                          size="sm"
                          onClick={() => setShowConnectionDetails(!showConnectionDetails)}
                        >
                          {showConnectionDetails ? "Masquer les détails" : "Afficher les détails"}
                        </Button>
                      </div>
                      
                      {showConnectionDetails && (
                        <div className="space-y-4 mt-4 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-gray-500 dark:text-gray-400">URL du broker</p>
                              <p className="font-medium">{jmsStatus.brokerUrl}</p>
                            </div>
                            <div>
                              <p className="text-sm text-gray-500 dark:text-gray-400">ID Client</p>
                              <p className="font-medium">{jmsStatus.clientId}</p>
                            </div>
                          </div>
                          
                          <div className="mt-4">
                            <h4 className="text-md font-medium text-gray-900 dark:text-white mb-2">Statistiques</h4>
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                              <div>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Total Messages</p>
                                <p className="font-medium">{connectionStats.totalMessages}</p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Envoyés</p>
                                <p className="font-medium text-green-600 dark:text-green-400">{connectionStats.sentMessages}</p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Reçus</p>
                                <p className="font-medium text-blue-600 dark:text-blue-400">{connectionStats.receivedMessages}</p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Échecs</p>
                                <p className="font-medium text-red-600 dark:text-red-400">{connectionStats.failedMessages}</p>
                              </div>
                            </div>
                          </div>
                          
                          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-2">
                            <div>
                              <p className="text-sm text-gray-500 dark:text-gray-400">Débit moyen</p>
                              <p className="font-medium">{connectionStats.messageRate}</p>
                            </div>
                            <div>
                              <p className="text-sm text-gray-500 dark:text-gray-400">Débit max</p>
                              <p className="font-medium">{connectionStats.peakRate}</p>
                            </div>
                            <div>
                              <p className="text-sm text-gray-500 dark:text-gray-400">Taille moyenne</p>
                              <p className="font-medium">{connectionStats.averageMessageSize}</p>
                            </div>
                            <div>
                              <p className="text-sm text-gray-500 dark:text-gray-400">Temps de fonctionnement</p>
                              <p className="font-medium">{connectionStats.uptime}</p>
                            </div>
                          </div>
                          
                          <div className="mt-4">
                            <h4 className="text-md font-medium text-gray-900 dark:text-white mb-2">Paramètres avancés</h4>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                              className="mb-2"
                            >
                              {showAdvancedSettings ? "Masquer les paramètres" : "Afficher les paramètres"}
                            </Button>
                            
                            {showAdvancedSettings && (
                              <div className="mt-2 p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">Reconnexion automatique</p>
                                    <div className="flex items-center mt-1">
                                      <input
                                        type="checkbox"
                                        className="rounded border-gray-300 text-blue-600"
                                        checked={true}
                                        readOnly
                                      />
                                      <span className="ml-2">Activé</span>
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">Délai de reconnexion</p>
                                    <p className="font-medium">5000 ms</p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">Timeout des requêtes</p>
                                    <p className="font-medium">30000 ms</p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">Préfetch size</p>
                                    <p className="font-medium">10</p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">Connection pool size</p>
                                    <p className="font-medium">20</p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">Utilisation SSL</p>
                                    <div className="flex items-center mt-1">
                                      <input
                                        type="checkbox"
                                        className="rounded border-gray-300 text-blue-600"
                                        checked={true}
                                        readOnly
                                      />
                                      <span className="ml-2">Activé</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-6">
                      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Files d'attente</h3>
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Nom
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                État
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Messages
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Consommateurs
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Producteurs
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Utilisation mémoire
                              </th>
                              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800">
                            {jmsStatus.queueStatus.map((queue, index) => (
                              <React.Fragment key={index}>
                                <tr className={`hover:bg-gray-50 dark:hover:bg-gray-800 ${expandedQueues[queue.name] ? 'bg-gray-50 dark:bg-gray-800' : ''}`}>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                                    <div className="flex items-center">
                                      <button 
                                        onClick={() => toggleQueueExpanded(queue.name)}
                                        className="mr-2 p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700"
                                      >
                                        {expandedQueues[queue.name] ? (
                                          <ChevronUp className="h-4 w-4 text-gray-500" />
                                        ) : (
                                          <ChevronDown className="h-4 w-4 text-gray-500" />
                                        )}
                                      </button>
                                      {queue.name}
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    <StatusBadge
                                      status={queue.active ? 'active' : 'inactive'}
                                      customLabel={queue.active ? 'Active' : 'Inactive'}
                                    />
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                    {queue.messageCount}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                    {queue.consumerCount}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                    {queue.producerCount}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                    {queue.memoryUsage} KB
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      disabled={queue.messageCount === 0}
                                      onClick={() => purgeQueue(queue.name)}
                                    >
                                      Purger
                                    </Button>
                                  </td>
                                </tr>
                                {expandedQueues[queue.name] && (
                                  <tr className="bg-gray-50 dark:bg-gray-800">
                                    <td colSpan="7" className="px-6 py-4">
                                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
                                        <div>
                                          <p className="font-medium mb-1">Informations générales</p>
                                          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                                            <span className="text-gray-500 dark:text-gray-400">Taille de la file:</span>
                                            <span>{queue.details?.queueSize || "N/A"}</span>
                                            <span className="text-gray-500 dark:text-gray-400">Temps moyen d'enfilage:</span>
                                            <span>{queue.avgEnqueueTime} ms</span>
                                            <span className="text-gray-500 dark:text-gray-400">Persistance:</span>
                                            <span>{queue.details?.persistenceEnabled ? "Activée" : "Désactivée"}</span>
                                            <span className="text-gray-500 dark:text-gray-400">Type de routage:</span>
                                            <span>{queue.details?.routingType || "N/A"}</span>
                                          </div>
                                        </div>
                                        <div>
                                          <p className="font-medium mb-1">État des messages</p>
                                          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                                            <span className="text-gray-500 dark:text-gray-400">Message le plus ancien:</span>
                                            <span>{queue.details?.oldest ? 
                                              <DateFormatter date={queue.details.oldest} format="relative" /> : "N/A"}
                                            </span>
                                            <span className="text-gray-500 dark:text-gray-400">Message le plus récent:</span>
                                            <span>{queue.details?.newest ? 
                                              <DateFormatter date={queue.details.newest} format="relative" /> : "N/A"}
                                            </span>
                                            <span className="text-gray-500 dark:text-gray-400">Queue exclusive:</span>
                                            <span>{queue.details?.exclusive ? "Oui" : "Non"}</span>
                                            <span className="text-gray-500 dark:text-gray-400">Nombre max de consommateurs:</span>
                                            <span>{queue.details?.maxConsumers === -1 ? "Illimité" : queue.details?.maxConsumers}</span>
                                          </div>
                                        </div>
                                        <div>
                                          <p className="font-medium mb-1">Actions</p>
                                          <div className="space-y-2">
                                            <Button
                                              variant="primary"
                                              size="sm"
                                              className="w-full"
                                              onClick={() => {
                                                // Browse messages
                                                alert(`Fonctionnalité à implémenter: Parcourir les messages de ${queue.name}`);
                                              }}
                                            >
                                              <FileText className="w-4 h-4 mr-2" />
                                              Parcourir les messages
                                            </Button>
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="w-full"
                                              onClick={() => {
                                                // Configure queue
                                                alert(`Fonctionnalité à implémenter: Configurer ${queue.name}`);
                                              }}
                                            >
                                              <Settings className="w-4 h-4 mr-2" />
                                              Configurer
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}

        {/* Onglet Certificats */}
        {activeTab === 'certificates' && (
          <div className="space-y-6">
            {/* Carte de gestion des certificats */}
            <Card
              title="Gestion des Certificats"
              icon={Shield}
              actions={
                <div className="flex space-x-2">
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Upload}
                    onClick={() => {
                      // Logique pour importer un certificat
                      alert("Fonctionnalité d'importation de certificat à implémenter");
                    }}
                  >
                    Importer
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    icon={RefreshCw}
                    onClick={fetchCertificates}
                    loading={refreshingCerts}
                  >
                    Actualiser
                  </Button>
                </div>
              }
            >
              <div className="space-y-6">
                {/* Résumé des certificats */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <div className="bg-green-50 dark:bg-green-900/10 p-4 rounded-lg">
                    <h4 className="text-sm font-medium text-green-700 dark:text-green-300">Certificats Valides</h4>
                    <p className="text-2xl font-bold text-green-800 dark:text-green-200">
                      {certificates.filter(cert => cert.status === 'VALID').length}
                    </p>
                  </div>
                  <div className="bg-yellow-50 dark:bg-yellow-900/10 p-4 rounded-lg">
                    <h4 className="text-sm font-medium text-yellow-700 dark:text-yellow-300">Expirations Proches</h4>
                    <p className="text-2xl font-bold text-yellow-800 dark:text-yellow-200">
                      {certificates.filter(cert => cert.status === 'WARNING').length}
                    </p>
                  </div>
                  <div className="bg-red-50 dark:bg-red-900/10 p-4 rounded-lg">
                    <h4 className="text-sm font-medium text-red-700 dark:text-red-300">Certificats Expirés</h4>
                    <p className="text-2xl font-bold text-red-800 dark:text-red-200">
                      {certificates.filter(cert => cert.status === 'EXPIRED').length}
                    </p>
                  </div>
                </div>

                {/* Filtres pour les certificats */}
                <div className="flex flex-wrap gap-2">
                  <Button
                    variant={filterValidCerts ? "primary" : "outline"}
                    size="sm"
                    onClick={() => setFilterValidCerts(!filterValidCerts)}
                  >
                    Valides
                  </Button>
                  <Button
                    variant={filterWarningCerts ? "warning" : "outline"}
                    size="sm"
                    onClick={() => setFilterWarningCerts(!filterWarningCerts)}
                  >
                    Expiration Proche
                  </Button>
                  <Button
                    variant={filterExpiredCerts ? "danger" : "outline"}
                    size="sm"
                    onClick={() => setFilterExpiredCerts(!filterExpiredCerts)}
                  >
                    Expirés
                  </Button>
                  {(filterValidCerts || filterWarningCerts || filterExpiredCerts) && (
                    <Button
                      variant="text"
                      size="sm"
                      onClick={() => {
                        setFilterValidCerts(false);
                        setFilterWarningCerts(false);
                        setFilterExpiredCerts(false);
                      }}
                    >
                      Réinitialiser
                    </Button>
                  )}
                </div>

                {/* Liste des certificats */}
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Liste des Certificats</h3>
                  
                  {getFilteredCertificates().length === 0 ? (
                    <div className="text-center p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
                      <HelpCircle className="mx-auto h-10 w-10 text-gray-400" />
                      <p className="mt-2 text-gray-500 dark:text-gray-400">Aucun certificat trouvé</p>
                    </div>
                  ) : (
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Alias
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Type
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Émetteur
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Validité
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              État
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                          {getFilteredCertificates().map((cert) => {
                            const daysUntilExpiration = getDaysUntilExpiration(cert.validTo);
                            const isExpired = daysUntilExpiration <= 0;
                            
                            return (
                              <tr key={cert.id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                                  {cert.alias}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                  {cert.type}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                  {cert.issuer}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                  <div className={getCertificateStatusClass(cert)}>
                                    {isExpired ? (
                                      <span>Expiré depuis {Math.abs(daysUntilExpiration)} jours</span>
                                    ) : (
                                      <span>Expire dans {daysUntilExpiration} jours</span>
                                    )}
                                  </div>
                                  <div className="text-xs text-gray-500 dark:text-gray-400">
                                    <DateFormatter date={cert.validFrom} format="short" /> - <DateFormatter date={cert.validTo} format="short" />
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                  <StatusBadge 
                                    status={
                                      cert.status === 'VALID' ? 'active' : 
                                      cert.status === 'WARNING' ? 'warning' : 'inactive'
                                    } 
                                    customLabel={
                                      cert.status === 'VALID' ? 'Valide' : 
                                      cert.status === 'WARNING' ? 'À Renouveler' : 'Expiré'
                                    } 
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                                  <div className="flex justify-end space-x-2">
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={() => showCertificateDetails(cert)}
                                    >
                                      Détails
                                    </Button>
                                    {cert.status !== 'VALID' && (
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={() => renewCertificate(cert.id)}
                                      >
                                        Renouveler
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>

      {/* Modal des détails de certificat */}
      {showCertificateModal && selectedCertificate && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Détails du Certificat
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowCertificateModal(false)}
                >
                  <XCircle className="w-6 h-6" />
                </button>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">Informations générales</h4>
                  <div className="space-y-2">
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Alias:</span>
                      <p className="font-medium">{selectedCertificate.alias}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Type:</span>
                      <p className="font-medium">{selectedCertificate.type}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Émetteur:</span>
                      <p className="font-medium">{selectedCertificate.issuer}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Sujet:</span>
                      <p className="font-medium">{selectedCertificate.subject}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Algorithme:</span>
                      <p className="font-medium">{selectedCertificate.algorithm}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Taille de la clé:</span>
                      <p className="font-medium">{selectedCertificate.keySize} bits</p>
                    </div>
                  </div>
                </div>

                <div>
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">Détails techniques</h4>
                  <div className="space-y-2">
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Numéro de série:</span>
                      <p className="font-medium">{selectedCertificate.serialNumber}</p>
					  </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Version:</span>
                      <p className="font-medium">{selectedCertificate.version}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Usage:</span>
                      <p className="font-medium">{selectedCertificate.usage}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Empreinte:</span>
                      <p className="font-medium break-all">{selectedCertificate.thumbprint}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">KeyStore:</span>
                      <p className="font-medium">{selectedCertificate.keyStore}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">État:</span>
                      <StatusBadge 
                        status={
                          selectedCertificate.status === 'VALID' ? 'active' : 
                          selectedCertificate.status === 'WARNING' ? 'warning' : 'inactive'
                        } 
                        customLabel={
                          selectedCertificate.status === 'VALID' ? 'Valide' : 
                          selectedCertificate.status === 'WARNING' ? 'À Renouveler' : 'Expiré'
                        } 
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <h4 className="font-medium text-gray-900 dark:text-white mb-2">Période de validité</h4>
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Valide depuis:</span>
                      <p className="font-medium">
                        <DateFormatter date={selectedCertificate.validFrom} format="full" />
                      </p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Valide jusqu'à:</span>
                      <p className={`font-medium ${getCertificateStatusClass(selectedCertificate)}`}>
                        <DateFormatter date={selectedCertificate.validTo} format="full" />
                      </p>
                    </div>
                  </div>
                  
                  <div className="mt-2">
                    <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2.5 mt-2">
                      {(() => {
                        const validFrom = new Date(selectedCertificate.validFrom).getTime();
                        const validTo = new Date(selectedCertificate.validTo).getTime();
                        const now = Date.now();
                        const total = validTo - validFrom;
                        const elapsed = now - validFrom;
                        const percentage = Math.max(0, Math.min(100, (elapsed / total) * 100));
                        
                        const colorClass = percentage > 85 
                          ? 'bg-red-500' 
                          : percentage > 70 
                            ? 'bg-yellow-500' 
                            : 'bg-green-500';
                        
                        return (
                          <div 
                            className={`h-2.5 rounded-full ${colorClass}`} 
                            style={{ width: `${percentage}%` }}
                          ></div>
                        );
                      })()}
                    </div>
                    <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 text-center">
                      {(() => {
                        const daysUntilExpiration = getDaysUntilExpiration(selectedCertificate.validTo);
                        if (daysUntilExpiration <= 0) {
                          return `Expiré depuis ${Math.abs(daysUntilExpiration)} jours`;
                        } else {
                          return `Expire dans ${daysUntilExpiration} jours`;
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                {selectedCertificate.status !== 'VALID' && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      renewCertificate(selectedCertificate.id);
                      setShowCertificateModal(false);
                    }}
                  >
                    Renouveler
                  </Button>
                )}
                <Button
                  variant="outline"
                  onClick={() => {
                    // Export certificate
                    alert(`Fonctionnalité à implémenter: Exporter ${selectedCertificate.alias}`);
                  }}
                >
                  <Download className="w-4 h-4 mr-2" />
                  Exporter
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setShowCertificateModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectionStatus;