// components/shared/workflow/WorkflowUtils.js

// Génère un ID unique pour les nœuds
export const generateId = () => `node_${Math.random().toString(36).substr(2, 9)}`;

// Convertit le workflow en format d'API
export const convertToApiFormat = (nodes, edges, workflowName, workflowDescription) => {
  // Trouver l'état initial
  const initialNode = nodes.find((node) => node.type === 'initialNode');
  
  // Trouver les états finaux
  const finalStates = nodes
    .filter((node) => node.type === 'finalNode')
    .map((node) => node.data.label);
  
  // Construire les transitions
  const transitions = edges.map((edge) => ({
    fromState: nodes.find((n) => n.id === edge.source)?.data.label || '',
    toState: nodes.find((n) => n.id === edge.target)?.data.label || '',
    event: edge.label || 'Event',
    isAutomatic: edge.data?.isAutomatic || false,
    requiredRoles: edge.data?.roles || [],
    actions: edge.data?.actions || []
  }));
  
  return {
    code: workflowName.replace(/\s+/g, '_').toUpperCase(),
    name: workflowName,
    description: workflowDescription,
    initialState: initialNode?.data.label || '',
    finalStates,
    transitions,
    parameters: {}
  };
};

// Traite un fichier de workflow importé pour le transformer en nœuds et arêtes
export const processImportedWorkflow = (workflow) => {
  // Créer les nœuds
  const nodeMap = {};
  const newNodes = [];
  
  // Créer l'état initial
  if (workflow.initialState) {
    const id = generateId();
    nodeMap[workflow.initialState] = id;
    newNodes.push({
      id,
      type: 'initialNode',
      position: { x: 100, y: 100 },
      data: { label: workflow.initialState }
    });
  }
  
  // Créer les états finaux
  (workflow.finalStates || []).forEach((state, index) => {
    const id = generateId();
    nodeMap[state] = id;
    newNodes.push({
      id,
      type: 'finalNode',
      position: { x: 500, y: 100 + index * 100 },
      data: { label: state }
    });
  });
  
  // Créer tous les autres états mentionnés dans les transitions
  const allStates = new Set();
  (workflow.transitions || []).forEach((transition) => {
    allStates.add(transition.fromState);
    allStates.add(transition.toState);
  });
  
  // Filtrer les états qui ont déjà été créés
  const remainingStates = [...allStates].filter(
    (state) => !nodeMap[state]
  );
  
  // Créer les états restants
  remainingStates.forEach((state, index) => {
    const id = generateId();
    nodeMap[state] = id;
    newNodes.push({
      id,
      type: 'stateNode',
      position: { x: 300, y: 100 + index * 100 },
      data: { label: state }
    });
  });
  
  // Créer les connexions
  const newEdges = (workflow.transitions || []).map((transition) => ({
    id: `edge-${nodeMap[transition.fromState]}-${nodeMap[transition.toState]}`,
    source: nodeMap[transition.fromState],
    target: nodeMap[transition.toState],
    label: transition.event,
    data: {
      isAutomatic: transition.isAutomatic,
      roles: transition.requiredRoles || [],
      actions: transition.actions || []
    }
  }));
  
  return {
    nodes: newNodes,
    edges: newEdges,
    name: workflow.name || 'Imported Workflow',
    description: workflow.description || ''
  };
};

// Export workflow as JSON file
export const exportWorkflow = (workflow) => {
  const blob = new Blob([JSON.stringify(workflow, null, 2)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${workflow.code}.json`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};