import React, { useState, useEffect } from 'react';
import { 
  Shield, 
  PlusCircle, 
  Trash2, 
  Upload, 
  RefreshCw, 
  Search, 
  FileText, 
  Download, 
  Clock, 
  CheckCircle, 
  XCircle,
  AlertTriangle,
  Lock,
  Eye,
  Calendar,
  RotateCcw,
  Database,
  Save
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const CertificatesPage = () => {
  // États
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCreateKeystoreModal, setShowCreateKeystoreModal] = useState(false);
  const [showCreateTruststoreModal, setShowCreateTruststoreModal] = useState(false);
  const [verificationResult, setVerificationResult] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFormData, setUploadFormData] = useState({
    name: '',
    type: 'X509',
    password: '',
    alias: ''
  });
  const [keystoreFormData, setKeystoreFormData] = useState({
    name: '',
    type: 'JKS',
    password: '',
    location: '',
    certificates: []
  });
  const [truststoreFormData, setTruststoreFormData] = useState({
    name: '',
    type: 'JKS',
    password: '',
    location: '',
    certificates: []
  });

  // Types de certificats disponibles
  const certificateTypes = [
    { value: 'X509', label: 'X.509' },
    { value: 'JKS', label: 'Java KeyStore (JKS)' },
    { value: 'PKCS12', label: 'PKCS#12' },
    { value: 'PEM', label: 'PEM' },
    { value: 'DER', label: 'DER' },
    { value: 'CUSTOM', label: 'Personnalisé' }
  ];

  // Types de keystores/truststores
  const storeTypes = [
    { value: 'JKS', label: 'Java KeyStore (JKS)' },
    { value: 'PKCS12', label: 'PKCS#12' }
  ];

  // Effet pour charger les certificats au chargement
  useEffect(() => {
    fetchCertificates();
  }, []);

  // Fonction pour charger les certificats
  const fetchCertificates = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.certificates.getAll();
      setCertificates(response);
    } catch (err) {
      console.error("Erreur lors du chargement des certificats:", err);
      setError("Impossible de charger les certificats. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger un certificat spécifique
  const fetchCertificate = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.certificates.getById(id);
      setSelectedCertificate(response);
      setShowDetailsModal(true);
    } catch (err) {
      console.error("Erreur lors du chargement du certificat:", err);
      setError("Impossible de charger les détails du certificat. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour vérifier un certificat
  const verifyCertificate = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.certificates.verify(id);
      setVerificationResult(response);
      
      // Mettre à jour le certificat dans la liste
      fetchCertificates();
      
      // Afficher le message de succès
      setSuccessMessage(`Certificat vérifié avec succès. Résultat: ${response ? 'Valide' : 'Invalide'}`);
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la vérification du certificat:", err);
      setError("Impossible de vérifier le certificat. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour renouveler un certificat
  const renewCertificate = async (id) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.certificates.renew(id);
      
      // Mettre à jour la liste des certificats
      fetchCertificates();
      
      // Afficher le message de succès
      setSuccessMessage("Certificat renouvelé avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors du renouvellement du certificat:", err);
      setError("Impossible de renouveler le certificat. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour supprimer un certificat
  const deleteCertificate = async () => {
    if (!selectedCertificate) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.certificates.delete(selectedCertificate.id);
      
      // Mettre à jour la liste des certificats
      fetchCertificates();
      
      // Fermer la modale de confirmation
      setShowDeleteModal(false);
      
      // Réinitialiser le certificat sélectionné
      setSelectedCertificate(null);
      
      // Afficher le message de succès
      setSuccessMessage("Certificat supprimé avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression du certificat:", err);
      setError("Impossible de supprimer le certificat. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour télécharger un certificat
  const uploadCertificate = async () => {
    if (!uploadFile) {
      setError("Veuillez sélectionner un fichier à télécharger");
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Lire le contenu du fichier
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(uploadFile);
      
      fileReader.onload = async () => {
        try {
          const content = fileReader.result;
          
          // Convertir le contenu en base64
          const base64Content = btoa(
            new Uint8Array(content)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          
          // Préparer les données du certificat
          const certificateData = {
            ...uploadFormData,
            content: base64Content
          };
          
          // Envoyer le certificat au serveur
          await apiService.integration.certificates.upload(certificateData);
          
          // Mettre à jour la liste des certificats
          fetchCertificates();
          
          // Fermer la modale de téléchargement
          setShowUploadModal(false);
          
          // Réinitialiser le formulaire
          setUploadFile(null);
          setUploadFormData({
            name: '',
            type: 'X509',
            password: '',
            alias: ''
          });
          
          // Afficher le message de succès
          setSuccessMessage("Certificat téléchargé avec succès");
          
          // Effacer le message après quelques secondes
          setTimeout(() => setSuccessMessage(null), 3000);
        } catch (err) {
          console.error("Erreur lors du téléchargement du certificat:", err);
          setError("Impossible de télécharger le certificat. Veuillez réessayer.");
          setLoading(false);
        }
      };
      
      fileReader.onerror = () => {
        setError("Erreur lors de la lecture du fichier");
        setLoading(false);
      };
    } catch (err) {
      console.error("Erreur lors du téléchargement du certificat:", err);
      setError("Impossible de télécharger le certificat. Veuillez réessayer.");
      setLoading(false);
    }
  };

  // Fonction pour créer un keystore
  const createKeystore = async () => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.integration.certificates.createKeyStore(keystoreFormData);
      
      // Mettre à jour la liste des certificats
      fetchCertificates();
      
      // Fermer la modale
      setShowCreateKeystoreModal(false);
      
      // Réinitialiser le formulaire
      setKeystoreFormData({
        name: '',
        type: 'JKS',
        password: '',
        location: '',
        certificates: []
      });
      
      // Afficher le message de succès
      setSuccessMessage("Keystore créé avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création du keystore:", err);
      setError("Impossible de créer le keystore. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour créer un truststore
  const createTruststore = async () => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.integration.certificates.createTrustStore(truststoreFormData);
      
      // Mettre à jour la liste des certificats
      fetchCertificates();
      
      // Fermer la modale
      setShowCreateTruststoreModal(false);
      
      // Réinitialiser le formulaire
      setTruststoreFormData({
        name: '',
        type: 'JKS',
        password: '',
        location: '',
        certificates: []
      });
      
      // Afficher le message de succès
      setSuccessMessage("Truststore créé avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création du truststore:", err);
      setError("Impossible de créer le truststore. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la sélection d'un fichier
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadFile(file);
      
      // Extraire le nom du fichier pour pré-remplir le champ de nom
      const fileName = file.name.split('.')[0];
      setUploadFormData(prev => ({
        ...prev,
        name: fileName
      }));
    }
  };

  // Fonction pour gérer les changements dans le formulaire de téléchargement
  const handleUploadFormChange = (e) => {
    const { name, value } = e.target;
    setUploadFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Fonction pour gérer les changements dans le formulaire de keystore
  const handleKeystoreFormChange = (e) => {
    const { name, value } = e.target;
    setKeystoreFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Fonction pour gérer les changements dans le formulaire de truststore
  const handleTruststoreFormChange = (e) => {
    const { name, value } = e.target;
    setTruststoreFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Fonction pour ajouter un certificat au keystore
  const addCertificateToKeystore = (certificateId) => {
    setKeystoreFormData(prev => ({
      ...prev,
      certificates: [...prev.certificates, certificateId]
    }));
  };

  // Fonction pour retirer un certificat du keystore
  const removeCertificateFromKeystore = (certificateId) => {
    setKeystoreFormData(prev => ({
      ...prev,
      certificates: prev.certificates.filter(id => id !== certificateId)
    }));
  };

  // Fonction pour ajouter un certificat au truststore
  const addCertificateToTruststore = (certificateId) => {
    setTruststoreFormData(prev => ({
      ...prev,
      certificates: [...prev.certificates, certificateId]
    }));
  };

  // Fonction pour retirer un certificat du truststore
  const removeCertificateFromTruststore = (certificateId) => {
    setTruststoreFormData(prev => ({
      ...prev,
      certificates: prev.certificates.filter(id => id !== certificateId)
    }));
  };

  // Fonction pour vérifier si un certificat est expiré
  const isCertificateExpired = (certificate) => {
    if (!certificate.validTo) return false;
    
    const expiryDate = new Date(certificate.validTo);
    const now = new Date();
    
    return expiryDate < now;
  };

  // Fonction pour vérifier si un certificat est sur le point d'expirer (moins de 30 jours)
  const isCertificateExpiringSoon = (certificate) => {
    if (!certificate.validTo) return false;
    
    const expiryDate = new Date(certificate.validTo);
    const now = new Date();
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(now.getDate() + 30);
    
    return expiryDate > now && expiryDate < thirtyDaysFromNow;
  };

  // Fonction pour obtenir le statut d'un certificat
  const getCertificateStatus = (certificate) => {
    if (certificate.revoked) {
      return { status: 'inactive', label: 'Révoqué' };
    } else if (isCertificateExpired(certificate)) {
      return { status: 'inactive', label: 'Expiré' };
    } else if (isCertificateExpiringSoon(certificate)) {
      return { status: 'warning', label: 'Expire bientôt' };
    } else {
      return { status: 'active', label: 'Valide' };
    }
  };

  // Colonnes pour la grille de données des certificats
  const columns = [
    {
      header: 'Nom / Empreinte',
      key: 'name',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.name}
          </div>
          {row.thumbprint && (
            <div className="text-xs text-gray-500 dark:text-gray-400 font-mono">
              {row.thumbprint.substring(0, 16)}...
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Type',
      key: 'type',
      render: (row) => (
        <div className="text-sm">
          {row.type}
        </div>
      )
    },
    {
      header: 'Émetteur / Sujet',
      key: 'issuer',
      render: (row) => (
        <div>
          <div className="text-sm text-gray-600 dark:text-gray-300">
            <span className="font-medium">Émetteur:</span> {row.issuer || 'N/A'}
          </div>
          <div className="text-sm text-gray-600 dark:text-gray-300">
            <span className="font-medium">Sujet:</span> {row.subject || 'N/A'}
          </div>
        </div>
      )
    },
    {
      header: 'Validité',
      key: 'validity',
      render: (row) => (
        <div>
          <div className="text-xs text-gray-500 dark:text-gray-400">
            <span className="font-medium">De:</span>{' '}
            {row.validFrom ? (
              <DateFormatter date={row.validFrom} format="short" />
            ) : 'N/A'}
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-400">
            <span className="font-medium">À:</span>{' '}
            {row.validTo ? (
              <DateFormatter date={row.validTo} format="short" />
            ) : 'N/A'}
          </div>
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => (
        <StatusBadge 
          status={getCertificateStatus(row).status} 
          customLabel={getCertificateStatus(row).label} 
        />
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              fetchCertificate(row.id);
            }}
          >
            Détails
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={CheckCircle}
            onClick={(e) => {
              e.stopPropagation();
              verifyCertificate(row.id);
            }}
          >
            Vérifier
          </Button>
          {(isCertificateExpired(row) || isCertificateExpiringSoon(row)) && (
            <Button
              variant="outline"
              size="sm"
              icon={RotateCcw}
              onClick={(e) => {
                e.stopPropagation();
                renewCertificate(row.id);
              }}
            >
              Renouveler
            </Button>
          )}
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCertificate(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Shield className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Certificats
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les certificats, keystores et truststores pour les intégrations sécurisées
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchCertificates}
            loading={loading && !showUploadModal && !showDeleteModal && !showDetailsModal && !showCreateKeystoreModal && !showCreateTruststoreModal}
          >
            Actualiser
          </Button>
          <Button
            variant="outline"
            icon={Database}
            onClick={() => setShowCreateKeystoreModal(true)}
          >
            Créer Keystore
          </Button>
          <Button
            variant="outline"
            icon={Lock}
            onClick={() => setShowCreateTruststoreModal(true)}
          >
            Créer Truststore
          </Button>
          <Button
            variant="primary"
            icon={Upload}
            onClick={() => setShowUploadModal(true)}
          >
            Télécharger Certificat
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher un certificat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des certificats */}
      <Card>
        <DataGrid
          data={certificates.filter(cert => 
            cert.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cert.subject?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cert.issuer?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cert.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cert.thumbprint?.toLowerCase().includes(searchTerm.toLowerCase())
          )}
          columns={columns}
          emptyMessage="Aucun certificat trouvé"
          loading={loading}
          onRowClick={(row) => fetchCertificate(row.id)}
        />
      </Card>

      {/* Modal de téléchargement de certificat */}
      {showUploadModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Télécharger un certificat
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowUploadModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Fichier du certificat
                  </label>
                  <div className="relative border border-gray-300 dark:border-gray-600 rounded-md p-4 text-center">
                    <input
                      type="file"
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      onChange={handleFileChange}
                    />
                    <div className="flex flex-col items-center justify-center py-4">
                      <Upload className="h-10 w-10 text-gray-400 mb-2" />
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {uploadFile ? uploadFile.name : "Cliquez ou glissez-déposez pour sélectionner un fichier"}
                      </p>
                      {uploadFile && (
                        <p className="text-xs text-gray-500 mt-1">
                          {(uploadFile.size / 1024).toFixed(2)} KB
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom*
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={uploadFormData.name}
                    onChange={handleUploadFormChange}
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Type de certificat
                  </label>
                  <select
                    name="type"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={uploadFormData.type}
                    onChange={handleUploadFormChange}
                  >
                    {certificateTypes.map(type => (
                      <option key={type.value} value={type.value}>{type.label}</option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Mot de passe
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={uploadFormData.password}
                    onChange={handleUploadFormChange}
                    placeholder="Seulement si le certificat est protégé par mot de passe"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Alias
                  </label>
                  <input
                    type="text"
                    name="alias"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={uploadFormData.alias}
                    onChange={handleUploadFormChange}
                    placeholder="Optionnel - Pour les keystores"
                  />
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowUploadModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Upload}
                  onClick={uploadCertificate}
                  loading={loading}
                  disabled={!uploadFile || !uploadFormData.name}
                >
                  Télécharger
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de détails du certificat */}
      {showDetailsModal && selectedCertificate && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Détails du certificat
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                {/* Informations de base */}
                <div className="grid gap-4">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">Nom:</span>
                    <span className="text-sm font-semibold text-gray-900 dark:text-white">{selectedCertificate.name}</span>
                  </div>
                  
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">Type:</span>
                    <span className="text-sm font-semibold text-gray-900 dark:text-white">{selectedCertificate.type}</span>
                  </div>
                  
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">Statut:</span>
                    <StatusBadge 
                      status={getCertificateStatus(selectedCertificate).status} 
                      customLabel={getCertificateStatus(selectedCertificate).label} 
                    />
                  </div>
                </div>
                
                {/* Détails du certificat */}
                <div className="bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4 space-y-3">
                  <h3 className="text-md font-medium text-gray-900 dark:text-white">Informations détaillées</h3>
                  
                  <div className="grid gap-3 text-sm">
                    <div className="grid grid-cols-3 items-center">
                      <span className="text-gray-500 dark:text-gray-400">Émetteur:</span>
                      <span className="col-span-2 text-gray-900 dark:text-white">{selectedCertificate.issuer || 'N/A'}</span>
                    </div>
                    
                    <div className="grid grid-cols-3 items-center">
                      <span className="text-gray-500 dark:text-gray-400">Sujet:</span>
                      <span className="col-span-2 text-gray-900 dark:text-white">{selectedCertificate.subject || 'N/A'}</span>
                    </div>
                    
                    <div className="grid grid-cols-3 items-center">
                      <span className="text-gray-500 dark:text-gray-400">Numéro de série:</span>
                      <span className="col-span-2 text-gray-900 dark:text-white font-mono">{selectedCertificate.serialNumber || 'N/A'}</span>
                    </div>
                    
                    <div className="grid grid-cols-3 items-center">
                      <span className="text-gray-500 dark:text-gray-400">Empreinte:</span>
                      <span className="col-span-2 text-gray-900 dark:text-white font-mono break-all">{selectedCertificate.thumbprint || 'N/A'}</span>
                    </div>
                    
                    <div className="grid grid-cols-3 items-center">
                      <span className="text-gray-500 dark:text-gray-400">Valide de:</span>
                      <span className="col-span-2 text-gray-900 dark:text-white">
                        {selectedCertificate.validFrom ? (
                          <DateFormatter date={selectedCertificate.validFrom} format="full" />
                        ) : 'N/A'}
                      </span>
                    </div>
                    
                    <div className="grid grid-cols-3 items-center">
                      <span className="text-gray-500 dark:text-gray-400">Valide jusqu'à:</span>
                      <span className="col-span-2 text-gray-900 dark:text-white">
                        {selectedCertificate.validTo ? (
                          <DateFormatter date={selectedCertificate.validTo} format="full" />
                        ) : 'N/A'}
                      </span>
                    </div>

                    {selectedCertificate.alias && (
                      <div className="grid grid-cols-3 items-center">
                        <span className="text-gray-500 dark:text-gray-400">Alias:</span>
                        <span className="col-span-2 text-gray-900 dark:text-white">{selectedCertificate.alias}</span>
                      </div>
                    )}
                    
                    {/* Résultat de vérification */}
                    {verificationResult !== null && selectedCertificate.id === selectedCertificate.id && (
                      <div className="grid grid-cols-3 items-center">
                        <span className="text-gray-500 dark:text-gray-400">Vérification:</span>
                        <span className="col-span-2">
                          {verificationResult ? (
                            <span className="flex items-center text-green-600 dark:text-green-400">
                              <CheckCircle className="w-4 h-4 mr-1" />
                              Valide
                            </span>
                          ) : (
                            <span className="flex items-center text-red-600 dark:text-red-400">
                              <XCircle className="w-4 h-4 mr-1" />
                              Invalide
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Boutons d'action */}
                <div className="flex justify-end space-x-3">
                  <Button
                    variant="outline"
                    icon={CheckCircle}
                    onClick={() => verifyCertificate(selectedCertificate.id)}
                    loading={loading}
                  >
                    Vérifier
                  </Button>
                  
                  {(isCertificateExpired(selectedCertificate) || isCertificateExpiringSoon(selectedCertificate)) && (
                    <Button
                      variant="outline"
                      icon={RotateCcw}
                      onClick={() => renewCertificate(selectedCertificate.id)}
                      loading={loading}
                    >
                      Renouveler
                    </Button>
                  )}
                  
                  <Button
                    variant="danger"
                    icon={Trash2}
                    onClick={() => {
                      setShowDetailsModal(false);
                      setShowDeleteModal(true);
                    }}
                  >
                    Supprimer
                  </Button>
                  
                  <Button
                    variant="primary"
                    onClick={() => setShowDetailsModal(false)}
                  >
                    Fermer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20 mb-4">
                  <AlertTriangle className="h-6 w-6 text-red-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Confirmer la suppression
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir supprimer le certificat <span className="font-semibold">{selectedCertificate?.name}</span> ? 
                  Cette action est irréversible.
                </p>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  icon={Trash2}
                  onClick={deleteCertificate}
                  loading={loading}
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de création de keystore */}
      {showCreateKeystoreModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer un KeyStore
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateKeystoreModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom*
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={keystoreFormData.name}
                    onChange={handleKeystoreFormChange}
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Type
                  </label>
                  <select
                    name="type"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={keystoreFormData.type}
                    onChange={handleKeystoreFormChange}
                  >
                    {storeTypes.map(type => (
                      <option key={type.value} value={type.value}>{type.label}</option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Mot de passe*
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={keystoreFormData.password}
                    onChange={handleKeystoreFormChange}
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Emplacement
                  </label>
                  <input
                    type="text"
                    name="location"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={keystoreFormData.location}
                    onChange={handleKeystoreFormChange}
                    placeholder="Optionnel - Chemin local ou URL"
                  />
                </div>
                
                {/* Sélection des certificats */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Certificats à inclure
                  </label>
                  
                  <div className="border border-gray-300 dark:border-gray-600 rounded-md max-h-40 overflow-y-auto">
                    {certificates.length > 0 ? (
                      <div className="divide-y divide-gray-200 dark:divide-gray-700">
                        {certificates.map(cert => (
                          <div 
                            key={cert.id}
                            className="flex items-center px-3 py-2 hover:bg-gray-50 dark:hover:bg-gray-700"
                          >
                            <input
                              type="checkbox"
                              id={`keystore-cert-${cert.id}`}
                              className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                              checked={keystoreFormData.certificates.includes(cert.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  addCertificateToKeystore(cert.id);
                                } else {
                                  removeCertificateFromKeystore(cert.id);
                                }
                              }}
                            />
                            <label 
                              htmlFor={`keystore-cert-${cert.id}`}
                              className="ml-2 block text-sm text-gray-900 dark:text-white"
                            >
                              {cert.name} ({cert.type})
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-4 text-sm text-gray-500 dark:text-gray-400">
                        Aucun certificat disponible
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateKeystoreModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Save}
                  onClick={createKeystore}
                  loading={loading}
                  disabled={!keystoreFormData.name || !keystoreFormData.password}
                >
                  Créer KeyStore
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de création de truststore */}
      {showCreateTruststoreModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer un TrustStore
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateTruststoreModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom*
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={truststoreFormData.name}
                    onChange={handleTruststoreFormChange}
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Type
                  </label>
                  <select
                    name="type"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={truststoreFormData.type}
                    onChange={handleTruststoreFormChange}
                  >
                    {storeTypes.map(type => (
                      <option key={type.value} value={type.value}>{type.label}</option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Mot de passe*
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={truststoreFormData.password}
                    onChange={handleTruststoreFormChange}
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Emplacement
                  </label>
                  <input
                    type="text"
                    name="location"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={truststoreFormData.location}
                    onChange={handleTruststoreFormChange}
                    placeholder="Optionnel - Chemin local ou URL"
                  />
                </div>
                
                {/* Sélection des certificats */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Certificats de confiance à inclure
                  </label>
                  
                  <div className="border border-gray-300 dark:border-gray-600 rounded-md max-h-40 overflow-y-auto">
                    {certificates.length > 0 ? (
                      <div className="divide-y divide-gray-200 dark:divide-gray-700">
                        {certificates.map(cert => (
                          <div 
                            key={cert.id}
                            className="flex items-center px-3 py-2 hover:bg-gray-50 dark:hover:bg-gray-700"
                          >
                            <input
                              type="checkbox"
                              id={`truststore-cert-${cert.id}`}
                              className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                              checked={truststoreFormData.certificates.includes(cert.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  addCertificateToTruststore(cert.id);
                                } else {
                                  removeCertificateFromTruststore(cert.id);
                                }
                              }}
                            />
                            <label 
                              htmlFor={`truststore-cert-${cert.id}`}
                              className="ml-2 block text-sm text-gray-900 dark:text-white"
                            >
                              {cert.name} ({cert.type})
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-4 text-sm text-gray-500 dark:text-gray-400">
                        Aucun certificat disponible
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateTruststoreModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Save}
                  onClick={createTruststore}
                  loading={loading}
                  disabled={!truststoreFormData.name || !truststoreFormData.password}
                >
                  Créer TrustStore
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificatesPage;