import React, { useState, useEffect } from 'react';
import { 
  Layers, 
  PlusCircle, 
  Trash2, 
  Edit, 
  Search, 
  RefreshCw, 
  Settings, 
  Play, 
  Pause, 
  Code,
  Info,
  CheckCircle2,
  XCircle,
  Eye,
  Save,
  X,
  FileJson
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const InterfacesPage = () => {
  // États pour gérer les données et les états UI
  const [interfaces, setInterfaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedInterface, setSelectedInterface] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'list'
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    description: '',
    type: '',
    status: 'ACTIVE',
    version: '1.0',
    metadata: {}
  });
  const [configData, setConfigData] = useState({
    connectionRef: '',
    timeout: '60',
    retryCount: 3,
    cronExpression: '',
    parameters: {},
    validationRules: '',
    transformationRules: '',
    errorHandlingStrategy: 'RETRY',
    securityLevel: 'MEDIUM',
    loggingEnabled: true,
    logLevel: 'INFO',
    monitoringEnabled: true,
    alertsEnabled: true
  });
  
  const [newParamKey, setNewParamKey] = useState('');
  const [newParamValue, setNewParamValue] = useState('');

  // Types d'interfaces disponibles
  const interfaceTypes = [
    { value: 'REST', label: 'REST' },
    { value: 'SOAP', label: 'SOAP' },
    { value: 'FILE', label: 'Fichier' },
    { value: 'JMS', label: 'JMS' },
    { value: 'DATABASE', label: 'Base de données' },
    { value: 'BATCH', label: 'Batch' },
    { value: 'CUSTOM', label: 'Personnalisé' }
  ];

  // Filtrer les interfaces en fonction du terme de recherche
  const filteredInterfaces = interfaces.filter(itf => {
    return (
      itf.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      itf.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (itf.description && itf.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (itf.type && itf.type.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  useEffect(() => {
    fetchInterfaces();
  }, []);

  // Fonction pour charger les interfaces
  const fetchInterfaces = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.interfaces.getAll();
      setInterfaces(response);
    } catch (err) {
      console.error("Erreur lors du chargement des interfaces :", err);
      setError("Impossible de charger les interfaces. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger une interface spécifique
  const fetchInterface = async (code) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.interfaces.getById(code);
      setSelectedInterface(response);
      
      // Préparer les données pour le formulaire d'édition
      setFormData({
        code: response.code,
        name: response.name,
        description: response.description || '',
        type: response.type || '',
        status: response.status || 'ACTIVE',
        version: response.version || '1.0',
        metadata: response.metadata || {}
      });
      
      // Préparer les données pour le formulaire de configuration
      if (response.config) {
        setConfigData({
          connectionRef: response.config.connectionRef || '',
          timeout: response.config.timeout || '60',
          retryCount: response.config.retryCount || 3,
          cronExpression: response.config.cronExpression || '',
          parameters: response.config.parameters || {},
          validationRules: response.config.validationRules || '',
          transformationRules: response.config.transformationRules || '',
          errorHandlingStrategy: response.config.errorHandlingStrategy || 'RETRY',
          securityLevel: response.config.securityLevel || 'MEDIUM',
          loggingEnabled: response.config.loggingEnabled !== false,
          logLevel: response.config.logLevel || 'INFO',
          monitoringEnabled: response.config.monitoringEnabled !== false,
          alertsEnabled: response.config.alertsEnabled !== false
        });
      }
    } catch (err) {
      console.error("Erreur lors du chargement de l'interface :", err);
      setError("Impossible de charger les détails de l'interface. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de création d'interface
  const handleCreateInterface = async () => {
    setLoading(true);
    setError(null);
    try {
      const newInterface = {
        ...formData,
        config: configData
      };
      
      await apiService.integration.interfaces.create(newInterface);
      setSuccessMessage("Interface créée avec succès !");
      setShowCreateModal(false);
      fetchInterfaces();
      resetForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création de l'interface :", err);
      setError("Impossible de créer l'interface. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de mise à jour d'interface
  const handleUpdateInterface = async () => {
    if (!selectedInterface) return;
    
    setLoading(true);
    setError(null);
    try {
      const updatedInterface = {
        ...formData,
        config: configData
      };
      
      await apiService.integration.interfaces.update(selectedInterface.code, updatedInterface);
      setSuccessMessage("Interface mise à jour avec succès !");
      setShowEditModal(false);
      fetchInterfaces();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour de l'interface :", err);
      setError("Impossible de mettre à jour l'interface. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de suppression d'interface
  const handleDeleteInterface = async () => {
    if (!selectedInterface) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.interfaces.delete(selectedInterface.code);
      setSuccessMessage("Interface supprimée avec succès !");
      setShowDeleteModal(false);
      setSelectedInterface(null);
      fetchInterfaces();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression de l'interface :", err);
      setError("Impossible de supprimer l'interface. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire d'activation d'interface
  const handleActivateInterface = async (code) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.interfaces.activate(code);
      setSuccessMessage("Interface activée avec succès !");
      fetchInterfaces();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'activation de l'interface :", err);
      setError("Impossible d'activer l'interface. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gestionnaire de désactivation d'interface
  const handleDeactivateInterface = async (code) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.interfaces.deactivate(code);
      setSuccessMessage("Interface désactivée avec succès !");
      fetchInterfaces();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la désactivation de l'interface :", err);
      setError("Impossible de désactiver l'interface. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Réinitialisation du formulaire
  const resetForm = () => {
    setFormData({
      code: '',
      name: '',
      description: '',
      type: '',
      status: 'ACTIVE',
      version: '1.0',
      metadata: {}
    });
    
    setConfigData({
      connectionRef: '',
      timeout: '60',
      retryCount: 3,
      cronExpression: '',
      parameters: {},
      validationRules: '',
      transformationRules: '',
      errorHandlingStrategy: 'RETRY',
      securityLevel: 'MEDIUM',
      loggingEnabled: true,
      logLevel: 'INFO',
      monitoringEnabled: true,
      alertsEnabled: true
    });
  };

  // Gestion des changements dans le formulaire
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Gestion des changements dans le formulaire de configuration
  const handleConfigChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConfigData({
      ...configData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Ajout d'un paramètre à la configuration
  const handleAddParameter = () => {
    if (newParamKey.trim() === '') return;
    
    setConfigData({
      ...configData,
      parameters: {
        ...configData.parameters,
        [newParamKey]: newParamValue
      }
    });
    setNewParamKey('');
    setNewParamValue('');
  };

  // Retrait d'un paramètre de la configuration
  const handleRemoveParameter = (key) => {
    const updatedParams = { ...configData.parameters };
    delete updatedParams[key];
    setConfigData({
      ...configData,
      parameters: updatedParams
    });
  };

  // Obtenir le statut d'une interface
  const getInterfaceStatusInfo = (status) => {
    const statusMap = {
      'ACTIVE': { status: 'active', label: 'Actif' },
      'INACTIVE': { status: 'inactive', label: 'Inactif' },
      'PENDING': { status: 'warning', label: 'En attente' },
      'ERROR': { status: 'danger', label: 'Erreur' },
      'MAINTENANCE': { status: 'paused', label: 'Maintenance' },
      'DEPRECATED': { status: 'warning', label: 'Déprécié' }
    };
    
    return statusMap[status] || { status: 'inactive', label: status };
  };

  // Configuration des colonnes pour la grille de données
  const columns = [
    {
      header: 'Code',
      key: 'code',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.code}
        </div>
      )
    },
    {
      header: 'Nom / Description',
      key: 'name',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.name}
          </div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Type',
      key: 'type',
      render: (row) => (
        <div className="font-medium">
          {row.type}
        </div>
      )
    },
    {
      header: 'Version',
      key: 'version',
      render: (row) => (
        <div className="text-gray-600 dark:text-gray-300">
          {row.version || '1.0'}
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => {
        const statusInfo = getInterfaceStatusInfo(row.status);
        return (
          <StatusBadge 
            status={statusInfo.status} 
            customLabel={statusInfo.label} 
          />
        );
      }
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Settings}
            onClick={(e) => {
              e.stopPropagation();
              fetchInterface(row.code);
              setShowConfigModal(true);
            }}
          >
            Config
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            onClick={(e) => {
              e.stopPropagation();
              fetchInterface(row.code);
              setShowEditModal(true);
            }}
          >
            Modifier
          </Button>
          {row.status === 'ACTIVE' ? (
            <Button
              variant="outline"
              size="sm"
              icon={Pause}
              onClick={(e) => {
                e.stopPropagation();
                handleDeactivateInterface(row.code);
              }}
            >
              Désactiver
            </Button>
          ) : (
            <Button
              variant="outline"
              size="sm"
              icon={Play}
              onClick={(e) => {
                e.stopPropagation();
                handleActivateInterface(row.code);
              }}
            >
              Activer
            </Button>
          )}
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedInterface(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  // Rendre une interface sous forme de carte pour la vue en grille
  const renderInterfaceCard = (itf) => {
    const statusInfo = getInterfaceStatusInfo(itf.status);
    
    return (
      <Card 
        key={itf.code}
        className="h-full hover:shadow-md transition-shadow duration-200"
      >
        <div className="h-full flex flex-col">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {itf.name}
              </h3>
              <p className="text-sm text-blue-600 dark:text-blue-400 font-medium">
                {itf.code}
              </p>
            </div>
            <StatusBadge status={statusInfo.status} customLabel={statusInfo.label} />
          </div>
          
          {itf.description && (
            <p className="text-sm text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
              {itf.description}
            </p>
          )}
          
          <div className="mt-auto pt-4 border-t border-gray-100 dark:border-gray-700">
            <div className="grid grid-cols-2 gap-2 mb-4">
              <div>
                <span className="text-xs text-gray-500 dark:text-gray-400">Type:</span>
                <p className="text-sm font-medium text-gray-900 dark:text-white">{itf.type}</p>
              </div>
              <div>
                <span className="text-xs text-gray-500 dark:text-gray-400">Version:</span>
                <p className="text-sm font-medium text-gray-900 dark:text-white">{itf.version || '1.0'}</p>
              </div>
            </div>
            
            <div className="flex justify-between items-center">
              <div className="space-x-1">
                <Button
                  variant="outline"
                  size="sm"
                  icon={Settings}
                  onClick={() => {
                    fetchInterface(itf.code);
                    setShowConfigModal(true);
                  }}
                >
                  Config
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  icon={Edit}
                  onClick={() => {
                    fetchInterface(itf.code);
                    setShowEditModal(true);
                  }}
                >
                  Edit
                </Button>
              </div>
              
              {itf.status === 'ACTIVE' ? (
                <Button
                  variant="outline"
                  size="sm"
                  icon={Pause}
                  onClick={() => handleDeactivateInterface(itf.code)}
                >
                  Désactiver
                </Button>
              ) : (
                <Button
                  variant="outline"
                  size="sm"
                  icon={Play}
                  onClick={() => handleActivateInterface(itf.code)}
                >
                  Activer
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Layers className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Interfaces d'Intégration
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez vos interfaces d'intégration et leurs configurations
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={viewMode === 'grid' ? Code : Layers}
            onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
          >
            Vue {viewMode === 'grid' ? 'Liste' : 'Grille'}
          </Button>
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchInterfaces}
            loading={loading && !showCreateModal && !showEditModal && !showConfigModal && !showDeleteModal}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetForm();
              setShowCreateModal(true);
            }}
          >
            Nouvelle interface
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher une interface..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des interfaces */}
      {loading && !showCreateModal && !showEditModal && !showConfigModal && !showDeleteModal ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : viewMode === 'list' ? (
        <Card>
          <DataGrid
            data={filteredInterfaces}
            columns={columns}
            emptyMessage="Aucune interface trouvée"
          />
        </Card>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredInterfaces.length > 0 ? (
            filteredInterfaces.map(renderInterfaceCard)
          ) : (
            <div className="col-span-3 flex justify-center items-center h-64">
              <div className="text-center text-gray-500 dark:text-gray-400">
                <Info className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                <p>Aucune interface trouvée</p>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Modal de création d'interface */}
      {showCreateModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer une nouvelle interface
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Informations de base
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Code*
                    </label>
                    <input
                      type="text"
                      name="code"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.code}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={3}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type*
                    </label>
                    <select
                      name="type"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.type}
                      onChange={handleFormChange}
                      required
                    >
                      <option value="">Sélectionner un type</option>
                      {interfaceTypes.map(type => (
                        <option key={type.value} value={type.value}>{type.label}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version
                    </label>
                    <input
                      type="text"
                      name="version"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.version}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Statut
                    </label>
                    <select
                      name="status"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.status}
                      onChange={handleFormChange}
                    >
                      <option value="ACTIVE">Actif</option>
                      <option value="INACTIVE">Inactif</option>
                      <option value="PENDING">En attente</option>
                      <option value="MAINTENANCE">Maintenance</option>
                      <option value="DEPRECATED">Déprécié</option>
                    </select>
                  </div>
                </div>
                
                {/* Configuration de l'interface */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Configuration
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Référence de connexion
                    </label>
                    <input
                      type="text"
                      name="connectionRef"
					  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.connectionRef}
                      onChange={handleConfigChange}
                      placeholder="ex: REST_CONNECTION_1"
                    />
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Délai d'attente (secondes)
                      </label>
                      <input
                        type="number"
                        name="timeout"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={configData.timeout}
                        onChange={handleConfigChange}
                        min="1"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nombre d'essais
                      </label>
                      <input
                        type="number"
                        name="retryCount"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={configData.retryCount}
                        onChange={handleConfigChange}
                        min="0"
                      />
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Expression Cron (planification)
                    </label>
                    <input
                      type="text"
                      name="cronExpression"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.cronExpression}
                      onChange={handleConfigChange}
                      placeholder="ex: 0 0 * * * *"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Stratégie de gestion des erreurs
                    </label>
                    <select
                      name="errorHandlingStrategy"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.errorHandlingStrategy}
                      onChange={handleConfigChange}
                    >
                      <option value="RETRY">Réessayer</option>
                      <option value="ABORT">Abandonner</option>
                      <option value="CONTINUE">Continuer</option>
                      <option value="SKIP">Ignorer</option>
                      <option value="CUSTOM">Personnalisé</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Niveau de sécurité
                    </label>
                    <select
                      name="securityLevel"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.securityLevel}
                      onChange={handleConfigChange}
                    >
                      <option value="LOW">Faible</option>
                      <option value="MEDIUM">Moyen</option>
                      <option value="HIGH">Élevé</option>
                      <option value="VERY_HIGH">Très élevé</option>
                    </select>
                  </div>
                  
                  <div className="flex space-x-2">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="loggingEnabled"
                        name="loggingEnabled"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={configData.loggingEnabled}
                        onChange={handleConfigChange}
                      />
                      <label htmlFor="loggingEnabled" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Activer journalisation
                      </label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="monitoringEnabled"
                        name="monitoringEnabled"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={configData.monitoringEnabled}
                        onChange={handleConfigChange}
                      />
                      <label htmlFor="monitoringEnabled" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Activer surveillance
                      </label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="alertsEnabled"
                        name="alertsEnabled"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={configData.alertsEnabled}
                        onChange={handleConfigChange}
                      />
                      <label htmlFor="alertsEnabled" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Activer alertes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Paramètres supplémentaires */}
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
                  Paramètres supplémentaires
                </h3>
                
                <div className="flex space-x-2 mb-3">
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newParamKey}
                    onChange={(e) => setNewParamKey(e.target.value)}
                    placeholder="Clé"
                  />
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newParamValue}
                    onChange={(e) => setNewParamValue(e.target.value)}
                    placeholder="Valeur"
                  />
                  <Button
                    variant="outline"
                    onClick={handleAddParameter}
                    disabled={newParamKey.trim() === ''}
                  >
                    Ajouter
                  </Button>
                </div>
                
                <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                  {Object.keys(configData.parameters).length > 0 ? (
                    <ul className="space-y-2">
                      {Object.entries(configData.parameters).map(([key, value]) => (
                        <li key={key} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                          <div>
                            <span className="font-medium text-gray-900 dark:text-white">{key}:</span>
                            <span className="ml-2 text-gray-700 dark:text-gray-300">{value}</span>
                          </div>
                          <button
                            className="text-red-500 hover:text-red-600"
                            onClick={() => handleRemoveParameter(key)}
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                      Aucun paramètre supplémentaire défini
                    </div>
                  )}
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreateInterface}
                  loading={loading}
                  disabled={!formData.code || !formData.name || !formData.type}
                >
                  Créer l'interface
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'édition d'interface */}
      {showEditModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Modifier l'interface
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowEditModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Informations de base
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Code*
                    </label>
                    <input
                      type="text"
                      name="code"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400 cursor-not-allowed"
                      value={formData.code}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={3}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type*
                    </label>
                    <select
                      name="type"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.type}
                      onChange={handleFormChange}
                      required
                    >
                      <option value="">Sélectionner un type</option>
                      {interfaceTypes.map(type => (
                        <option key={type.value} value={type.value}>{type.label}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version
                    </label>
                    <input
                      type="text"
                      name="version"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.version}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Statut
                    </label>
                    <select
                      name="status"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.status}
                      onChange={handleFormChange}
                    >
                      <option value="ACTIVE">Actif</option>
                      <option value="INACTIVE">Inactif</option>
                      <option value="PENDING">En attente</option>
                      <option value="MAINTENANCE">Maintenance</option>
                      <option value="DEPRECATED">Déprécié</option>
                    </select>
                  </div>
                </div>
                
                {/* Configuration de l'interface */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Configuration
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Référence de connexion
                    </label>
                    <input
                      type="text"
                      name="connectionRef"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.connectionRef}
                      onChange={handleConfigChange}
                      placeholder="ex: REST_CONNECTION_1"
                    />
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Délai d'attente (secondes)
                      </label>
                      <input
                        type="number"
                        name="timeout"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={configData.timeout}
                        onChange={handleConfigChange}
                        min="1"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nombre d'essais
                      </label>
                      <input
                        type="number"
                        name="retryCount"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={configData.retryCount}
                        onChange={handleConfigChange}
                        min="0"
                      />
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Expression Cron (planification)
                    </label>
                    <input
                      type="text"
                      name="cronExpression"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.cronExpression}
                      onChange={handleConfigChange}
                      placeholder="ex: 0 0 * * * *"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Stratégie de gestion des erreurs
                    </label>
                    <select
                      name="errorHandlingStrategy"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.errorHandlingStrategy}
                      onChange={handleConfigChange}
                    >
                      <option value="RETRY">Réessayer</option>
                      <option value="ABORT">Abandonner</option>
                      <option value="CONTINUE">Continuer</option>
                      <option value="SKIP">Ignorer</option>
                      <option value="CUSTOM">Personnalisé</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Niveau de sécurité
                    </label>
                    <select
                      name="securityLevel"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={configData.securityLevel}
                      onChange={handleConfigChange}
                    >
                      <option value="LOW">Faible</option>
                      <option value="MEDIUM">Moyen</option>
                      <option value="HIGH">Élevé</option>
                      <option value="VERY_HIGH">Très élevé</option>
                    </select>
                  </div>
                  
                  <div className="flex space-x-2">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="loggingEnabled_edit"
                        name="loggingEnabled"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={configData.loggingEnabled}
                        onChange={handleConfigChange}
                      />
                      <label htmlFor="loggingEnabled_edit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Activer journalisation
                      </label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="monitoringEnabled_edit"
                        name="monitoringEnabled"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={configData.monitoringEnabled}
                        onChange={handleConfigChange}
                      />
                      <label htmlFor="monitoringEnabled_edit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Activer surveillance
                      </label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="alertsEnabled_edit"
                        name="alertsEnabled"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={configData.alertsEnabled}
                        onChange={handleConfigChange}
                      />
                      <label htmlFor="alertsEnabled_edit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Activer alertes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Paramètres supplémentaires */}
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
                  Paramètres supplémentaires
                </h3>
                
                <div className="flex space-x-2 mb-3">
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newParamKey}
                    onChange={(e) => setNewParamKey(e.target.value)}
                    placeholder="Clé"
                  />
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newParamValue}
                    onChange={(e) => setNewParamValue(e.target.value)}
                    placeholder="Valeur"
                  />
                  <Button
                    variant="outline"
                    onClick={handleAddParameter}
                    disabled={newParamKey.trim() === ''}
                  >
                    Ajouter
                  </Button>
                </div>
                
                <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                  {Object.keys(configData.parameters).length > 0 ? (
                    <ul className="space-y-2">
                      {Object.entries(configData.parameters).map(([key, value]) => (
                        <li key={key} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                          <div>
                            <span className="font-medium text-gray-900 dark:text-white">{key}:</span>
                            <span className="ml-2 text-gray-700 dark:text-gray-300">{value}</span>
                          </div>
                          <button
                            className="text-red-500 hover:text-red-600"
                            onClick={() => handleRemoveParameter(key)}
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                      Aucun paramètre supplémentaire défini
                    </div>
                  )}
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowEditModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUpdateInterface}
                  loading={loading}
                  disabled={!formData.name || !formData.type}
                >
                  Mettre à jour
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de configuration de l'interface */}
      {showConfigModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Configuration de l'interface: {selectedInterface?.name}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowConfigModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Paramètres généraux */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Paramètres généraux
                  </h3>
                  
                  <div className="space-y-4">
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Code:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedInterface?.code}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Nom:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedInterface?.name}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Type:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedInterface?.type}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Version:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedInterface?.version || '1.0'}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                        <StatusBadge 
                          status={getInterfaceStatusInfo(selectedInterface?.status).status} 
                          customLabel={getInterfaceStatusInfo(selectedInterface?.status).label} 
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Créé le:</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                          {selectedInterface?.createdAt ? (
                            <DateFormatter 
                              date={selectedInterface.createdAt} 
                              format="full" 
                            />
                          ) : 'N/A'}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Dernière mise à jour:</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                          {selectedInterface?.updatedAt ? (
                            <DateFormatter 
                              date={selectedInterface.updatedAt} 
                              format="full" 
                            />
                          ) : 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Configuration actuelle */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Configuration actuelle
                  </h3>
                  
                  <div className="space-y-4">
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Référence de connexion:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{configData.connectionRef || 'N/A'}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Délai d'attente:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{configData.timeout || '60'} secondes</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Nombre d'essais:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{configData.retryCount}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Expression Cron:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{configData.cronExpression || 'Non défini'}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Gestion des erreurs:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{configData.errorHandlingStrategy}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Niveau de sécurité:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{configData.securityLevel}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500 dark:text-gray-400">Fonctionnalités activées:</span>
                        <div className="space-x-2">
                          {configData.loggingEnabled && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300">
                              Journalisation
                            </span>
                          )}
                          {configData.monitoringEnabled && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300">
                              Surveillance
                            </span>
                          )}
                          {configData.alertsEnabled && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300">
                              Alertes
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Paramètres supplémentaires */}
              {Object.keys(configData.parameters).length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">
                    Paramètres supplémentaires
                  </h3>
                  
                  <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-4">
                    <ul className="space-y-2">
                      {Object.entries(configData.parameters).map(([key, value]) => (
                        <li key={key} className="flex justify-between">
                          <span className="font-medium text-gray-700 dark:text-gray-300">{key}:</span>
                          <span className="text-gray-900 dark:text-white">{value}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              
              {/* Boutons d'action */}
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowConfigModal(false)}
                >
                  Fermer
                </Button>
                <Button
                  variant="primary"
                  icon={Edit}
                  onClick={() => {
                    setShowConfigModal(false);
                    setShowEditModal(true);
                  }}
                >
                  Modifier
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20">
                  <Trash2 className="h-6 w-6 text-red-600 dark:text-red-500" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  Supprimer l'interface
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir supprimer l'interface "{selectedInterface?.name}" ? Cette action est irréversible.
                </p>
              </div>
              
              <div className="mt-6 flex justify-center space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  onClick={handleDeleteInterface}
                  loading={loading}
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InterfacesPage;