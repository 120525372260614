import React, { useState, useEffect } from 'react';
import { CheckCircle, AlertTriangle, FileText, DollarSign } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/api.service';

const PaymentValidation = () => {
  const navigate = useNavigate();
  
  // États
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Vérification de l'authentification
  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  // Chargement initial des workflows
  useEffect(() => {
    fetchWorkflows();
  }, []);

  /*const fetchWorkflows = async () => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('Session expirée');
      navigate('/login');
      return;
    }

    try {
      const data = await apiService.declarationWorkflow.getAll();
      // Filtrer uniquement les workflows en attente de validation
      const pendingWorkflows = data.workflows.filter(w => 
        w.currentState === 'PAYMENT_INITIATED'
      );
      setWorkflows(pendingWorkflows);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors du chargement des workflows');
    }
  };*/
  
   const fetchWorkflows = async () => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('Session expirée');
      navigate('/login');
      return;
    }

    try {
      const data = await apiService.declarationWorkflow.getAll();
      setWorkflows(data.workflows || []);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors du chargement des workflows');
    }
  };

  const validatePayment = async () => {
    if (!selectedWorkflow?.reference) return;
    
    setLoading(true);
    try {
      const data = await apiService.declarationWorkflow.validatePayment(
        selectedWorkflow.reference
      );

      // Mettre à jour la liste des workflows
      setWorkflows(prevWorkflows => 
        prevWorkflows.filter(w => w.reference !== selectedWorkflow.reference)
      );
      setSelectedWorkflow(null);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors de la validation du paiement');
    } finally {
      setLoading(false);
    }
  };

  // Rendu du statut
  const Status = ({ currentState }) => {
    const statusConfigs = {
      'PAYMENT_INITIATED': {
        color: 'bg-yellow-100 text-yellow-800',
      },
      'PAYMENT_VALIDATED': {
        color: 'bg-green-100 text-green-800',
      }
    };

    const config = statusConfigs[currentState] || statusConfigs.PAYMENT_INITIATED;

    return (
      <span className={`px-2 py-1 text-xs rounded-full ${config.color}`}>
        {currentState}
      </span>
    );
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg border shadow-sm">
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Validation des paiements</h2>
          
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 text-red-700 mb-4">
              {error}
            </div>
          )}

          {/* Liste des workflows */}
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Référence</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Déclaration</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">État</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Montant</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {workflows.map((workflow) => (
                  <tr 
                    key={workflow.reference}
                    onClick={() => setSelectedWorkflow(workflow)}
                    className={`cursor-pointer hover:bg-gray-50 ${
                      selectedWorkflow?.reference === workflow.reference ? 'bg-blue-50' : ''
                    }`}
                  >
                    <td className="px-6 py-4 text-sm">{workflow.reference}</td>
                    <td className="px-6 py-4 text-sm">{workflow.declarationNumber}</td>
                    <td className="px-6 py-4">
                      <Status currentState={workflow.currentState} />
                    </td>
                    <td className="px-6 py-4 text-sm">
                      {workflow.totalAmount?.toLocaleString()} XAF
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Détails et validation du paiement */}
          {selectedWorkflow && (
            <div className="mt-6 border-t pt-6">
              <h3 className="text-lg font-medium mb-4">
                Validation du paiement - {selectedWorkflow.declarationNumber}
              </h3>

              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
                  <div>
                    <span className="text-sm text-gray-500">Mode de paiement</span>
                    <div className="flex items-center mt-1">
                      <DollarSign className="w-5 h-5 text-gray-400 mr-2" />
                      <span className="font-medium">{selectedWorkflow.paymentMethod}</span>
                    </div>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Banque</span>
                    <div className="flex items-center mt-1">
                      <FileText className="w-5 h-5 text-gray-400 mr-2" />
                      <span className="font-medium">{selectedWorkflow.bankCode}</span>
                    </div>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Montant</span>
                    <div className="flex items-center mt-1">
                      <span className="font-medium text-lg">
                        {Number(selectedWorkflow.totalAmount).toLocaleString()} XAF
                      </span>
                    </div>
                  </div>
                </div>

                <div className="bg-blue-50 rounded-lg p-4">
                  <div className="flex items-start">
                    <AlertTriangle className="w-5 h-5 text-blue-500 mt-0.5 mr-2" />
                    <div>
                      <h4 className="font-medium text-blue-700">Vérification requise</h4>
                      <p className="text-sm text-blue-600 mt-1">
                        Veuillez vérifier les informations avant de valider le paiement.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setSelectedWorkflow(null)}
                    className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={validatePayment}
                    disabled={loading}
                    className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 disabled:opacity-50"
                  >
                    <CheckCircle className="w-5 h-5 mr-2" />
                    {loading ? 'Validation...' : 'Valider le paiement'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentValidation;