import React, { useState, useEffect } from 'react';
import { 
  RefreshCw, 
  PlusCircle, 
  Trash2, 
  Edit, 
  Search, 
  Save,
  CheckCircle2, 
  XCircle, 
  FolderOpen,
  FilePlus,
  FileText,
  Upload,
  Download,
  X,
  Server,
  Folder
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const FileConnectionsPage = () => {
  // États pour gérer les données et les états UI
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [verificationResult, setVerificationResult] = useState(null);
  
  // Form Data
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    encoding: 'UTF-8',
    delimiter: ',',
    hasHeader: true,
    lineEnding: '\\n',
    useCompression: false,
    compressionType: '',
    appendTimestamp: false,
    folderStructure: '',
    filePattern: '',
    createMissingFolders: true,
    allowOverwrite: false,
    location: {
      protocol: 'FILE',
      host: '',
      port: null,
      basePath: '',
      username: '',
      password: '',
      privateKeyPath: '',
      privateKeyPassphrase: '',
      passive: true,
      connectionTimeout: 30,
      createMissingFolders: true
    },
    format: {
      type: 'CSV',
      version: '',
      charset: 'UTF-8',
      schema: '',
      schemaVersion: '',
      namespace: '',
      rootElement: '',
      validateSchema: false,
      headerIncluded: true,
      lineTerminator: '\\n',
      fieldDelimiter: ',',
      textQualifier: '"'
    }
  });

  // Filtrer les connexions en fonction du terme de recherche
  const filteredConnections = connections.filter(connection => {
    return (
      connection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (connection.description && connection.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (connection.location && connection.location.basePath && connection.location.basePath.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  // Effet pour charger les connexions au chargement de la page
  useEffect(() => {
    fetchConnections();
  }, []);

  // Fonction pour charger les connexions de fichiers
  const fetchConnections = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.fileConnections.getAll();
      setConnections(response);
    } catch (err) {
      console.error("Erreur lors du chargement des connexions de fichiers :", err);
      setError("Impossible de charger les connexions de fichiers. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger une connexion spécifique
  const fetchConnection = async (code) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.fileConnections.getById(code);
      setSelectedConnection(response);
      
      // Préparer les données pour le formulaire d'édition
      setFormData({
        name: response.name,
        description: response.description || '',
        encoding: response.encoding || 'UTF-8',
        delimiter: response.delimiter || ',',
        hasHeader: response.hasHeader !== false,
        lineEnding: response.lineEnding || '\\n',
        useCompression: response.useCompression || false,
        compressionType: response.compressionType || '',
        appendTimestamp: response.appendTimestamp || false,
        folderStructure: response.folderStructure || '',
        filePattern: response.filePattern || '',
        createMissingFolders: response.createMissingFolders !== false,
        allowOverwrite: response.allowOverwrite || false,
        location: {
          protocol: response.location?.protocol || 'FILE',
          host: response.location?.host || '',
          port: response.location?.port || null,
          basePath: response.location?.basePath || '',
          username: response.location?.username || '',
          password: response.location?.password || '',
          privateKeyPath: response.location?.privateKeyPath || '',
          privateKeyPassphrase: response.location?.privateKeyPassphrase || '',
          passive: response.location?.passive !== false,
          connectionTimeout: response.location?.connectionTimeout || 30,
          createMissingFolders: response.location?.createMissingFolders !== false
        },
        format: {
          type: response.format?.type || 'CSV',
          version: response.format?.version || '',
          charset: response.format?.charset || 'UTF-8',
          schema: response.format?.schema || '',
          schemaVersion: response.format?.schemaVersion || '',
          namespace: response.format?.namespace || '',
          rootElement: response.format?.rootElement || '',
          validateSchema: response.format?.validateSchema || false,
          headerIncluded: response.format?.headerIncluded !== false,
          lineTerminator: response.format?.lineTerminator || '\\n',
          fieldDelimiter: response.format?.fieldDelimiter || ',',
          textQualifier: response.format?.textQualifier || '"'
        }
      });
    } catch (err) {
      console.error("Erreur lors du chargement de la connexion de fichier :", err);
      setError("Impossible de charger les détails de la connexion de fichier. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour créer une connexion de fichier
  const handleCreateConnection = async () => {
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.fileConnections.create(formData);
      setSuccessMessage("Connexion de fichier créée avec succès !");
      setShowCreateModal(false);
      fetchConnections();
      resetForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création de la connexion de fichier :", err);
      setError("Impossible de créer la connexion de fichier. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour mettre à jour une connexion de fichier
  const handleUpdateConnection = async () => {
    if (!selectedConnection) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.fileConnections.update(selectedConnection.name, formData);
      setSuccessMessage("Connexion de fichier mise à jour avec succès !");
      setShowEditModal(false);
      fetchConnections();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la connexion de fichier :", err);
      setError("Impossible de mettre à jour la connexion de fichier. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour supprimer une connexion de fichier
  const handleDeleteConnection = async () => {
    if (!selectedConnection) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.fileConnections.delete(selectedConnection.name);
      setSuccessMessage("Connexion de fichier supprimée avec succès !");
      setShowDeleteModal(false);
      setSelectedConnection(null);
      fetchConnections();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression de la connexion de fichier :", err);
      setError("Impossible de supprimer la connexion de fichier. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour vérifier l'emplacement de fichier
  const handleVerifyLocation = async () => {
    setLoading(true);
    setError(null);
    setVerificationResult(null);
    try {
      await apiService.integration.fileConnections.verifyLocation(formData.location);
      setVerificationResult({
        success: true,
        message: "L'emplacement est accessible et valide."
      });
    } catch (err) {
      console.error("Erreur lors de la vérification de l'emplacement :", err);
      setVerificationResult({
        success: false,
        message: err.message || "Impossible d'accéder à l'emplacement spécifié. Vérifiez les paramètres et réessayez."
      });
    } finally {
      setLoading(false);
    }
  };

  // Réinitialisation du formulaire
  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      encoding: 'UTF-8',
      delimiter: ',',
      hasHeader: true,
      lineEnding: '\\n',
      useCompression: false,
      compressionType: '',
      appendTimestamp: false,
      folderStructure: '',
      filePattern: '',
      createMissingFolders: true,
      allowOverwrite: false,
      location: {
        protocol: 'FILE',
        host: '',
        port: null,
        basePath: '',
        username: '',
        password: '',
        privateKeyPath: '',
        privateKeyPassphrase: '',
        passive: true,
        connectionTimeout: 30,
        createMissingFolders: true
      },
      format: {
        type: 'CSV',
        version: '',
        charset: 'UTF-8',
        schema: '',
        schemaVersion: '',
        namespace: '',
        rootElement: '',
        validateSchema: false,
        headerIncluded: true,
        lineTerminator: '\\n',
        fieldDelimiter: ',',
        textQualifier: '"'
      }
    });
    setVerificationResult(null);
  };

  // Gestion des changements dans le formulaire
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => {
      if (name.includes('.')) {
        const [section, field] = name.split('.');
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [field]: type === 'checkbox' ? checked : value
          }
        };
      } else {
        return {
          ...prev,
          [name]: type === 'checkbox' ? checked : value
        };
      }
    });
  };

  // Colonnes pour la grille de données des connexions
  const connectionColumns = [
    {
      header: 'Nom',
      key: 'name',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.name}
        </div>
      )
    },
    {
      header: 'Description / Chemin',
      key: 'description',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.description || '-'}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
            {row.location?.basePath || '-'}
          </div>
        </div>
      )
    },
    {
      header: 'Protocole / Type',
      key: 'protocol',
      render: (row) => (
        <div>
          <div>{row.location?.protocol || '-'}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {row.format?.type || '-'}
          </div>
        </div>
      )
    },
    {
      header: 'Options',
      key: 'options',
      render: (row) => (
        <div className="space-y-1">
          {row.hasHeader && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300 mr-1">
              En-tête
            </span>
          )}
          {row.useCompression && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300 mr-1">
              Compression
            </span>
          )}
          {row.createMissingFolders && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300">
              Création de dossiers
            </span>
          )}
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => (
        <StatusBadge 
          status={row.status === 'ACTIVE' || row.active ? 'active' : 'inactive'} 
          customLabel={row.status === 'ACTIVE' || row.active ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={FolderOpen}
            onClick={(e) => {
              e.stopPropagation();
              fetchConnection(row.name);
              setShowVerifyModal(true);
            }}
          >
            Vérifier
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            onClick={(e) => {
              e.stopPropagation();
              fetchConnection(row.name);
              setShowEditModal(true);
            }}
          >
            Modifier
          </Button>
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedConnection(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Folder className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Connexions de Fichiers
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Configurez et gérez les connexions pour accéder aux fichiers
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchConnections}
            loading={loading && !showCreateModal && !showEditModal && !showDeleteModal && !showVerifyModal}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetForm();
              setShowCreateModal(true);
            }}
          >
            Nouvelle connexion
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher une connexion..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des connexions */}
      <Card>
        {loading && !showCreateModal && !showEditModal && !showDeleteModal && !showVerifyModal ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <DataGrid
            data={filteredConnections}
            columns={connectionColumns}
            emptyMessage="Aucune connexion de fichier trouvée"
          />
        )}
      </Card>

      {/* Modal de création de connexion de fichier */}
      {showCreateModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer une nouvelle connexion de fichier
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Informations générales
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={2}
                    />
                  </div>
                  
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
                    Emplacement du fichier
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Protocole*
                    </label>
                    <select
                      name="location.protocol"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.location.protocol}
                      onChange={handleFormChange}
                      required
                    >
                      <option value="FILE">Système de fichiers local (FILE)</option>
                      <option value="FTP">FTP</option>
                      <option value="SFTP">SFTP</option>
                      <option value="FTPS">FTPS</option>
                      <option value="HTTP">HTTP</option>
                      <option value="HTTPS">HTTPS</option>
                      <option value="S3">Amazon S3</option>
                    </select>
                  </div>
                  
                  {formData.location.protocol !== 'FILE' && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Hôte*
                        </label>
                        <input
                          type="text"
                          name="location.host"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.host}
                          onChange={handleFormChange}
                          required={formData.location.protocol !== 'FILE'}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Port
                        </label>
                        <input
                          type="number"
                          name="location.port"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.port || ''}
                          onChange={handleFormChange}
                        />
                      </div>
                    </>
                  )}
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Chemin de base*
                    </label>
                    <input
                      type="text"
                      name="location.basePath"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.location.basePath}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  
                  {(formData.location.protocol === 'FTP' || 
                    formData.location.protocol === 'SFTP' || 
                    formData.location.protocol === 'FTPS') && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Nom d'utilisateur
                        </label>
                        <input
                          type="text"
                          name="location.username"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.username}
                          onChange={handleFormChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Mot de passe
                        </label>
                        <input
                          type="password"
                          name="location.password"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.password}
                          onChange={handleFormChange}
                        />
                      </div>
                      
                      {formData.location.protocol === 'SFTP' && (
                        <>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Chemin de la clé privée
                            </label>
                            <input
                              type="text"
                              name="location.privateKeyPath"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.location.privateKeyPath}
                              onChange={handleFormChange}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Phrase secrète pour la clé privée
                            </label>
                            <input
                              type="password"
                              name="location.privateKeyPassphrase"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.location.privateKeyPassphrase}
                              onChange={handleFormChange}
                            />
                          </div>
                        </>
                      )}
                      
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="passive"
                          name="location.passive"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={formData.location.passive}
                          onChange={handleFormChange}
                        />
                        <label htmlFor="passive" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Mode passif
                        </label>
                      </div>
                    </>
                  )}
                </div>
                
                {/* Format de fichier et options */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Format de fichier
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type*
                    </label>
                    <select
                      name="format.type"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.format.type}
                      onChange={handleFormChange}
                      required
                    >
                      <option value="CSV">CSV</option>
                      <option value="XML">XML</option>
                      <option value="JSON">JSON</option>
                      <option value="FIXED_WIDTH">Largeur fixe</option>
                      <option value="EXCEL">Excel</option>
                      <option value="PDF">PDF</option>
                      <option value="TEXT">Texte</option>
                      <option value="BINARY">Binaire</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Jeu de caractères
                    </label>
                    <select
                      name="format.charset"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.format.charset}
                      onChange={handleFormChange}
                    >
                      <option value="UTF-8">UTF-8</option>
                      <option value="UTF-16">UTF-16</option>
                      <option value="ISO-8859-1">ISO-8859-1 (Latin-1)</option>
                      <option value="US-ASCII">US-ASCII</option>
                      <option value="WINDOWS-1252">Windows-1252</option>
                    </select>
                  </div>
                  
                  {formData.format.type === 'CSV' && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Délimiteur de champ
                        </label>
                        <input
                          type="text"
                          name="format.fieldDelimiter"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.format.fieldDelimiter}
                          onChange={handleFormChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Qualificateur de texte
                        </label>
                        <input
                          type="text"
                          name="format.textQualifier"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.format.textQualifier}
                          onChange={handleFormChange}
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="headerIncluded"
                          name="format.headerIncluded"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={formData.format.headerIncluded}
                          onChange={handleFormChange}
                        />
                        <label htmlFor="headerIncluded" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Inclut une ligne d'en-tête
                        </label>
                      </div>
                    </>
                  )}
                  
                  {(formData.format.type === 'XML' || formData.format.type === 'JSON') && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Schéma
                        </label>
                        <input
                          type="text"
                          name="format.schema"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.format.schema}
                          onChange={handleFormChange}
                        />
                      </div>
                      {formData.format.type === 'XML' && (
                        <>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Namespace
                            </label>
                            <input
                              type="text"
                              name="format.namespace"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.format.namespace}
                              onChange={handleFormChange}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Élément racine
                            </label>
                            <input
                              type="text"
                              name="format.rootElement"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.format.rootElement}
                              onChange={handleFormChange}
                            />
                          </div>
                        </>
                      )}
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="validateSchema"
                          name="format.validateSchema"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={formData.format.validateSchema}
                          onChange={handleFormChange}
                        />
                        <label htmlFor="validateSchema" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Valider le schéma
                        </label>
                      </div>
                    </>
                  )}
                  
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
                    Options supplémentaires
                  </h3>
                  
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="createMissingFolders"
                        name="createMissingFolders"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.createMissingFolders}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="createMissingFolders" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Créer les dossiers manquants
                      </label>
                    </div>
                    
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="allowOverwrite"
                        name="allowOverwrite"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.allowOverwrite}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="allowOverwrite" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Autoriser l'écrasement des fichiers
                      </label>
                    </div>
                    
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="useCompression"
                        name="useCompression"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.useCompression}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="useCompression" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Utiliser la compression
                      </label>
                    </div>
                    
                    {formData.useCompression && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Type de compression
                        </label>
                        <select
                          name="compressionType"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.compressionType}
                          onChange={handleFormChange}
                        >
                          <option value="">Sélectionnez...</option>
                          <option value="ZIP">ZIP</option>
                          <option value="GZIP">GZIP</option>
                          <option value="BZIP2">BZIP2</option>
                        </select>
                      </div>
                    )}
                    
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="appendTimestamp"
                        name="appendTimestamp"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.appendTimestamp}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="appendTimestamp" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Ajouter un horodatage aux noms de fichiers
                      </label>
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Modèle de nom de fichier
                    </label>
                    <input
                      type="text"
                      name="filePattern"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.filePattern}
                      onChange={handleFormChange}
                      placeholder="Ex: data_*.csv"
                    />
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-between">
                <Button
                  variant="outline"
                  icon={FolderOpen}
                  onClick={handleVerifyLocation}
                  disabled={!formData.location.basePath}
                >
                  Vérifier l'emplacement
                </Button>
                
                <div className="flex space-x-3">
                  <Button
                    variant="outline"
                    onClick={() => setShowCreateModal(false)}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleCreateConnection}
                    loading={loading}
                    disabled={!formData.name || !formData.location.basePath}
                  >
                    Créer la connexion
                  </Button>
                </div>
              </div>
              
              {/* Résultat de la vérification */}
              {verificationResult && (
                <div className={`mt-4 p-4 rounded-lg ${
                  verificationResult.success 
                    ? 'bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500' 
                    : 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500'
                }`}>
                  <div className="flex items-start">
                    {verificationResult.success ? (
                      <CheckCircle2 className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                    ) : (
                      <XCircle className="h-5 w-5 text-red-500 mr-2 mt-0.5" />
                    )}
                    <div>
                      <h3 className={`font-medium ${
                        verificationResult.success 
                          ? 'text-green-700 dark:text-green-300' 
                          : 'text-red-700 dark:text-red-300'
                      }`}>
                        {verificationResult.success ? 'Emplacement valide' : 'Erreur de vérification'}
                      </h3>
                      <p className={`text-sm ${
                        verificationResult.success 
                          ? 'text-green-600 dark:text-green-400' 
                          : 'text-red-600 dark:text-red-400'
                      }`}>
                        {verificationResult.message}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Modal d'édition de connexion de fichier */}
      {showEditModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Modifier la connexion de fichier
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowEditModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Informations générales
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400 cursor-not-allowed"
                      value={formData.name}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={2}
                    />
                  </div>
                  
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
                    Emplacement du fichier
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Protocole*
                    </label>
                    <select
                      name="location.protocol"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.location.protocol}
                      onChange={handleFormChange}
                      required
                    >
                      <option value="FILE">Système de fichiers local (FILE)</option>
                      <option value="FTP">FTP</option>
                      <option value="SFTP">SFTP</option>
                      <option value="FTPS">FTPS</option>
                      <option value="HTTP">HTTP</option>
                      <option value="HTTPS">HTTPS</option>
                      <option value="S3">Amazon S3</option>
                    </select>
                  </div>
                  
                  {formData.location.protocol !== 'FILE' && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Hôte*
                        </label>
                        <input
                          type="text"
                          name="location.host"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.host}
                          onChange={handleFormChange}
                          required={formData.location.protocol !== 'FILE'}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Port
                        </label>
                        <input
                          type="number"
                          name="location.port"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.port || ''}
                          onChange={handleFormChange}
                        />
                      </div>
                    </>
                  )}
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Chemin de base*
                    </label>
                    <input
                      type="text"
                      name="location.basePath"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.location.basePath}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  
                  {(formData.location.protocol === 'FTP' || 
                    formData.location.protocol === 'SFTP' || 
                    formData.location.protocol === 'FTPS') && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Nom d'utilisateur
                        </label>
                        <input
                          type="text"
                          name="location.username"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.username}
                          onChange={handleFormChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Mot de passe
                        </label>
                        <input
                          type="password"
                          name="location.password"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.location.password}
                          onChange={handleFormChange}
                        />
                      </div>
                      
                      {formData.location.protocol === 'SFTP' && (
                        <>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Chemin de la clé privée
                            </label>
                            <input
                              type="text"
                              name="location.privateKeyPath"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.location.privateKeyPath}
                              onChange={handleFormChange}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Phrase secrète pour la clé privée
                            </label>
                            <input
                              type="password"
                              name="location.privateKeyPassphrase"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.location.privateKeyPassphrase}
                              onChange={handleFormChange}
                            />
                          </div>
                        </>
                      )}
                      
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="passiveEdit"
                          name="location.passive"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={formData.location.passive}
                          onChange={handleFormChange}
                        />
                        <label htmlFor="passiveEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Mode passif
                        </label>
                      </div>
                    </>
                  )}
                </div>
                
                {/* Format de fichier et options */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Format de fichier
                  </h3>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type*
                    </label>
                    <select
                      name="format.type"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.format.type}
                      onChange={handleFormChange}
                      required
                    >
                      <option value="CSV">CSV</option>
                      <option value="XML">XML</option>
                      <option value="JSON">JSON</option>
                      <option value="FIXED_WIDTH">Largeur fixe</option>
                      <option value="EXCEL">Excel</option>
                      <option value="PDF">PDF</option>
                      <option value="TEXT">Texte</option>
                      <option value="BINARY">Binaire</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Jeu de caractères
                    </label>
                    <select
                      name="format.charset"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.format.charset}
                      onChange={handleFormChange}
                    >
                      <option value="UTF-8">UTF-8</option>
                      <option value="UTF-16">UTF-16</option>
                      <option value="ISO-8859-1">ISO-8859-1 (Latin-1)</option>
                      <option value="US-ASCII">US-ASCII</option>
                      <option value="WINDOWS-1252">Windows-1252</option>
                    </select>
                  </div>
                  
                  {formData.format.type === 'CSV' && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Délimiteur de champ
                        </label>
                        <input
                          type="text"
                          name="format.fieldDelimiter"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.format.fieldDelimiter}
                          onChange={handleFormChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Qualificateur de texte
                        </label>
                        <input
                          type="text"
                          name="format.textQualifier"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.format.textQualifier}
                          onChange={handleFormChange}
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="headerIncludedEdit"
                          name="format.headerIncluded"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={formData.format.headerIncluded}
                          onChange={handleFormChange}
                        />
                        <label htmlFor="headerIncludedEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Inclut une ligne d'en-tête
                        </label>
                      </div>
                    </>
                  )}
                  
                  {(formData.format.type === 'XML' || formData.format.type === 'JSON') && (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Schéma
                        </label>
                        <input
                          type="text"
                          name="format.schema"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.format.schema}
                          onChange={handleFormChange}
                        />
                      </div>
                      {formData.format.type === 'XML' && (
                        <>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Namespace
                            </label>
                            <input
                              type="text"
                              name="format.namespace"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.format.namespace}
                              onChange={handleFormChange}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                              Élément racine
                            </label>
                            <input
                              type="text"
                              name="format.rootElement"
                              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.format.rootElement}
                              onChange={handleFormChange}
                            />
                          </div>
                        </>
                      )}
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="validateSchemaEdit"
                          name="format.validateSchema"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={formData.format.validateSchema}
                          onChange={handleFormChange}
                        />
                        <label htmlFor="validateSchemaEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Valider le schéma
                        </label>
                      </div>
                    </>
                  )}
                  
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
                    Options supplémentaires
                  </h3>
                  
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="createMissingFoldersEdit"
                        name="createMissingFolders"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.createMissingFolders}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="createMissingFoldersEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Créer les dossiers manquants
                      </label>
                    </div>
                    
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="allowOverwriteEdit"
                        name="allowOverwrite"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.allowOverwrite}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="allowOverwriteEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Autoriser l'écrasement des fichiers
                      </label>
                    </div>
                    
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="useCompressionEdit"
                        name="useCompression"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.useCompression}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="useCompressionEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Utiliser la compression
                      </label>
                    </div>
                    
                    {formData.useCompression && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Type de compression
                        </label>
                        <select
                          name="compressionType"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.compressionType}
                          onChange={handleFormChange}
                        >
                          <option value="">Sélectionnez...</option>
                          <option value="ZIP">ZIP</option>
                          <option value="GZIP">GZIP</option>
                          <option value="BZIP2">BZIP2</option>
                        </select>
                      </div>
                    )}
                    
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="appendTimestampEdit"
                        name="appendTimestamp"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.appendTimestamp}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="appendTimestampEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Ajouter un horodatage aux noms de fichiers
                      </label>
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Modèle de nom de fichier
                    </label>
                    <input
                      type="text"
                      name="filePattern"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.filePattern}
                      onChange={handleFormChange}
                      placeholder="Ex: data_*.csv"
                    />
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-between">
                <Button
                  variant="outline"
                  icon={FolderOpen}
                  onClick={handleVerifyLocation}
                  disabled={!formData.location.basePath}
                >
                  Vérifier l'emplacement
                </Button>
                
                <div className="flex space-x-3">
                  <Button
                    variant="outline"
                    onClick={() => setShowEditModal(false)}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleUpdateConnection}
                    loading={loading}
                    disabled={!formData.location.basePath}
                  >
                    Mettre à jour
                  </Button>
                </div>
              </div>
              
              {/* Résultat de la vérification */}
              {verificationResult && (
                <div className={`mt-4 p-4 rounded-lg ${
                  verificationResult.success 
                    ? 'bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500' 
                    : 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500'
                }`}>
                  <div className="flex items-start">
                    {verificationResult.success ? (
                      <CheckCircle2 className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                    ) : (
                      <XCircle className="h-5 w-5 text-red-500 mr-2 mt-0.5" />
                    )}
                    <div>
                      <h3 className={`font-medium ${
                        verificationResult.success 
                          ? 'text-green-700 dark:text-green-300' 
                          : 'text-red-700 dark:text-red-300'
                      }`}>
                        {verificationResult.success ? 'Emplacement valide' : 'Erreur de vérification'}
                      </h3>
                      <p className={`text-sm ${
                        verificationResult.success 
                          ? 'text-green-600 dark:text-green-400' 
                          : 'text-red-600 dark:text-red-400'
                      }`}>
                        {verificationResult.message}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20">
                  <Trash2 className="h-6 w-6 text-red-600 dark:text-red-500" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  Supprimer la connexion de fichier
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir supprimer la connexion de fichier "{selectedConnection?.name}" ? Cette action est irréversible.
                </p>
              </div>
              
              <div className="mt-6 flex justify-center space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  onClick={handleDeleteConnection}
                  loading={loading}
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de vérification d'emplacement */}
      {showVerifyModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Vérifier l'emplacement du fichier
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowVerifyModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-medium text-gray-900 dark:text-white mb-2">
                    Informations sur l'emplacement
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-sm">
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Protocole:</span>
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{formData.location.protocol}</span>
                    </div>
                    {formData.location.protocol !== 'FILE' && (
                      <>
                        <div>
                          <span className="text-gray-500 dark:text-gray-400">Hôte:</span>
                          <span className="ml-2 text-gray-700 dark:text-gray-300">{formData.location.host}</span>
                        </div>
                        <div>
                          <span className="text-gray-500 dark:text-gray-400">Port:</span>
                          <span className="ml-2 text-gray-700 dark:text-gray-300">{formData.location.port || 'Par défaut'}</span>
                        </div>
                      </>
                    )}
                    <div className={formData.location.protocol !== 'FILE' ? 'col-span-2' : ''}>
                      <span className="text-gray-500 dark:text-gray-400">Chemin:</span>
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{formData.location.basePath}</span>
                    </div>
                  </div>
                </div>
                
                <div className="flex flex-col items-center justify-center p-6 border border-gray-200 dark:border-gray-700 rounded-lg">
                  <FolderOpen className="h-12 w-12 text-blue-500 mb-4" />
                  <p className="text-gray-700 dark:text-gray-300 text-center mb-4">
                    Cliquez sur le bouton ci-dessous pour vérifier si l'emplacement spécifié est accessible.
                  </p>
                  <Button
                    variant="primary"
                    icon={FolderOpen}
                    onClick={handleVerifyLocation}
                    loading={loading}
                  >
                    Vérifier l'emplacement
                  </Button>
                </div>
                
                {verificationResult && (
                  <div className={`p-4 rounded-lg ${
                    verificationResult.success 
                      ? 'bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500' 
                      : 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500'
                  }`}>
                    <div className="flex items-start">
                      {verificationResult.success ? (
                        <CheckCircle2 className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                      ) : (
                        <XCircle className="h-5 w-5 text-red-500 mr-2 mt-0.5" />
                      )}
                      <div>
                        <h3 className={`font-medium ${
                          verificationResult.success 
                            ? 'text-green-700 dark:text-green-300' 
                            : 'text-red-700 dark:text-red-300'
                        }`}>
                          {verificationResult.success ? 'Emplacement valide' : 'Erreur de vérification'}
                        </h3>
                        <p className={`text-sm ${
                          verificationResult.success 
                            ? 'text-green-600 dark:text-green-400' 
                            : 'text-red-600 dark:text-red-400'
                        }`}>
                          {verificationResult.message}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              <div className="mt-8 flex justify-end">
                <Button
                  variant="outline"
                  onClick={() => setShowVerifyModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default FileConnectionsPage;