import React, { useState, useEffect } from 'react';
import { CreditCard, Building2, Calculator, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/api.service';
const PaymentInitiationForm = () => {
  const navigate = useNavigate();
  
  // États
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  // État du formulaire
  const [formData, setFormData] = useState({
    paymentMethod: 'BANK_TRANSFER',
    bankCode: '',
    accountNumber: '',
    amount: ''
  });

  // Vérification de l'authentification
  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  // Chargement initial des workflows
  useEffect(() => {
    fetchWorkflows();
  }, []);

  const fetchWorkflows = async () => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('Session expirée');
      navigate('/login');
      return;
    }

    try {
      const data = await apiService.declarationWorkflow.getAll();
      setWorkflows(data.workflows || []);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors du chargement des workflows');
    }
  };

  const initiatePayment = async (e) => {
    e.preventDefault();
    if (!selectedWorkflow?.reference) return;

    setLoading(true);
    setError('');

    try {
      const response = await apiService.declarationWorkflow.initiatePayment(
        selectedWorkflow.reference,
        {
          ...formData,
          amount: Number(formData.amount)
        }
      );

      // Mise à jour du workflow
      const selectedIndex = workflows.findIndex(w => w.reference === selectedWorkflow.reference);
      if (selectedIndex !== -1) {
        const updatedWorkflows = [...workflows];
        updatedWorkflows[selectedIndex] = response;
        setWorkflows(updatedWorkflows);
      }

      // Réinitialisation du formulaire
      setSelectedWorkflow(null);
      setFormData({
        paymentMethod: 'BANK_TRANSFER',
        bankCode: '',
        accountNumber: '',
        amount: ''
      });

      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors de l\'initiation du paiement');
    } finally {
      setLoading(false);
    }
  };

  // Rendu du statut
  const Status = ({ currentState }) => {
    const statusConfigs = {
      'DRAFT': {
        color: 'bg-gray-100 text-gray-800',
      },
      'PAYMENT_INITIATED': {
        color: 'bg-blue-100 text-blue-800',
      },
      'PAYMENT_VALIDATED': {
        color: 'bg-green-100 text-green-800',
      },
      'COMPLETED': {
        color: 'bg-green-100 text-green-800',
      },
      'ERROR': {
        color: 'bg-red-100 text-red-800',
      }
    };

    const config = statusConfigs[currentState] || statusConfigs.DRAFT;

    return (
      <span className={`px-2 py-1 text-xs rounded-full ${config.color}`}>
        {currentState}
      </span>
    );
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg border shadow-sm">
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Initiation des paiements</h2>
          
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 text-red-700 mb-4">
              {error}
            </div>
          )}

          {/* Liste des workflows */}
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Référence</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Déclaration</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">État</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Montant</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {workflows.map((workflow) => (
                  <tr 
                    key={workflow.reference}
                    onClick={() => {
                      setSelectedWorkflow(workflow);
                      setFormData(prev => ({
                        ...prev,
                        amount: workflow.totalAmount || ''
                      }));
                    }}
                    className={`cursor-pointer hover:bg-gray-50 ${
                      selectedWorkflow?.reference === workflow.reference ? 'bg-blue-50' : ''
                    }`}
                  >
                    <td className="px-6 py-4 text-sm">{workflow.reference}</td>
                    <td className="px-6 py-4 text-sm">{workflow.declarationNumber}</td>
                    <td className="px-6 py-4">
                      <Status currentState={workflow.currentState} />
                    </td>
                    <td className="px-6 py-4 text-sm">
                      {workflow.totalAmount?.toLocaleString()} XAF
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Formulaire de paiement */}
          {selectedWorkflow && (
            <div className="mt-6 border-t pt-6">
              <h3 className="text-lg font-medium mb-4">
                Initier le paiement - {selectedWorkflow.declarationNumber}
              </h3>

              <form onSubmit={initiatePayment} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Mode de paiement
                  </label>
                  <div className="flex space-x-6">
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="BANK_TRANSFER"
                        checked={formData.paymentMethod === 'BANK_TRANSFER'}
                        onChange={(e) => setFormData({
                          ...formData,
                          paymentMethod: e.target.value
                        })}
                        className="text-blue-600"
                      />
                      <span>Virement</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="CASH"
                        checked={formData.paymentMethod === 'CASH'}
                        onChange={(e) => setFormData({
                          ...formData,
                          paymentMethod: e.target.value
                        })}
                        className="text-blue-600"
                      />
                      <span>Espèces</span>
                    </label>
                  </div>
                </div>

                {formData.paymentMethod === 'BANK_TRANSFER' && (
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Code banque
                      </label>
                      <div className="relative">
                        <Building2 className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                          type="text"
                          value={formData.bankCode}
                          onChange={(e) => setFormData({
                            ...formData,
                            bankCode: e.target.value.slice(0, 3)
                          })}
                          className="pl-10 w-full p-2 border rounded-lg"
                          placeholder="Ex: 001"
                          maxLength={3}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Numéro de compte
                      </label>
                      <div className="relative">
                        <CreditCard className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                          type="text"
                          value={formData.accountNumber}
                          onChange={(e) => setFormData({
                            ...formData,
                            accountNumber: e.target.value
                          })}
                          className="pl-10 w-full p-2 border rounded-lg"
                          placeholder="Numéro de compte"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium mb-1">
                    Montant
                  </label>
                  <div className="relative">
                    <Calculator className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                      type="number"
                      value={formData.amount}
                      onChange={(e) => setFormData({
                        ...formData,
                        amount: e.target.value
                      })}
                      className="pl-10 w-full p-2 border rounded-lg"
                      placeholder="0.00"
                    />
                  </div>
                </div>

                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => setSelectedWorkflow(null)}
                    className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50"
                  >
                    {loading ? 'En cours...' : 'Initier le paiement'}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentInitiationForm;