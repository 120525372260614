import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  InputAdornment,
  LinearProgress,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  Breadcrumbs,
  Link
} from '@mui/material';

import {
  Terminal,
  Folder,
  FolderOpen,
  File,
  ArrowUp,
  ArrowDown,
  Search,
  RefreshCw,
  HardDrive,
  Server,
  Cpu,
  Memory,
  Clock,
  User,
  Users,
  Wifi,
  WifiOff,
  Activity,
  AlertTriangle,
  CheckCircle,
  XCircle,
  MoreVertical,
  Play,
  StopCircle,
  Download,
  Upload,
  Edit,
  Trash2,
  Copy,
  FilePlus,
  FolderPlus,
  FileText,
  Eye,
  Settings,
  ChevronRight,
  ChevronDown,
  Home,
  Lock,
  Zap,
  Save,
  Shield,
  Monitor,
  Database,
  AlertCircle,
  Calendar,
  FileArchive,
  GitBranch,
  Power,
  Info
} from 'lucide-react';

/**
 * WindowsCapture Component - Interface réutilisable pour interagir avec des systèmes Windows
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles du système Windows
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const WindowsCapture = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États pour l'onglet PowerShell
  const [command, setCommand] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [terminalOutput, setTerminalOutput] = useState([]);
  const [isExecuting, setIsExecuting] = useState(false);
  const [shellType, setShellType] = useState('powershell'); // 'powershell' ou 'cmd'
  
  // États pour l'onglet Explorateur de fichiers
  const [currentPath, setCurrentPath] = useState('C:\\');
  const [drives, setDrives] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([{ name: 'C:', path: 'C:\\' }]);
  
  // États pour l'onglet Processus
  const [processList, setProcessList] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [processDetails, setProcessDetails] = useState(null);
  const [isLoadingProcesses, setIsLoadingProcesses] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(null);
  
  // États pour l'onglet Services
  const [servicesList, setServicesList] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [serviceFilter, setServiceFilter] = useState('all'); // 'all', 'running', 'stopped'
  
  // États pour l'onglet Système
  const [systemInfo, setSystemInfo] = useState(null);
  const [resourceUsage, setResourceUsage] = useState(null);
  const [isLoadingSystem, setIsLoadingSystem] = useState(false);
  
  // États pour l'onglet Registre
  const [registryPath, setRegistryPath] = useState('HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion');
  const [registryKeys, setRegistryKeys] = useState([]);
  const [registryValues, setRegistryValues] = useState([]);
  const [isLoadingRegistry, setIsLoadingRegistry] = useState(false);
  
  // États généraux
  const [activeTab, setActiveTab] = useState(0);
  const [lastAction, setLastAction] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [connectionInfo, setConnectionInfo] = useState({
    server: '',
    hostname: '',
    domain: '',
    user: '',
    connectionId: '',
    status: 'disconnected'
  });
  
  // Références
  const terminalRef = useRef(null);
  const commandInputRef = useRef(null);
  
  // Effets pour récupérer les informations de connexion
  useEffect(() => {
    if (data?.technicalProperties?.connection) {
      const conn = data.technicalProperties.connection;
      setConnectionInfo({
        server: conn.server || '',
        hostname: conn.hostname || '',
        domain: conn.domain || '',
        user: conn.user || '',
        connectionId: conn.connectionId || '',
        status: connectionStatus ? 'connected' : 'disconnected'
      });
    }
  }, [data, connectionStatus]);
  
  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les résultats de commande
      if (data.technicalProperties.commandOutput) {
        handleCommandResults(data.technicalProperties.commandOutput);
      }
      
      // Traiter les lecteurs
      if (data.technicalProperties.drives) {
        setDrives(data.technicalProperties.drives || []);
      }
      
      // Traiter les listes de fichiers
      if (data.technicalProperties.filesList) {
        handleFilesListResults(data.technicalProperties.filesList);
      }
      
      // Traiter le contenu d'un fichier
      if (data.technicalProperties.fileContent) {
        handleFileContentResults(data.technicalProperties.fileContent);
      }
      
      // Traiter la liste des processus
      if (data.technicalProperties.processList) {
        handleProcessListResults(data.technicalProperties.processList);
      }
      
      // Traiter les détails d'un processus
      if (data.technicalProperties.processDetails) {
        handleProcessDetailsResults(data.technicalProperties.processDetails);
      }
      
      // Traiter la liste des services
      if (data.technicalProperties.servicesList) {
        handleServicesListResults(data.technicalProperties.servicesList);
      }
      
      // Traiter les détails d'un service
      if (data.technicalProperties.serviceDetails) {
        handleServiceDetailsResults(data.technicalProperties.serviceDetails);
      }
      
      // Traiter les informations système
      if (data.technicalProperties.systemInfo) {
        handleSystemInfoResults(data.technicalProperties.systemInfo);
      }
      
      // Traiter l'utilisation des ressources
      if (data.technicalProperties.resourceUsage) {
        handleResourceUsageResults(data.technicalProperties.resourceUsage);
      }
      
      // Traiter les clés de registre
      if (data.technicalProperties.registryKeys) {
        setRegistryKeys(data.technicalProperties.registryKeys || []);
        setIsLoadingRegistry(false);
      }
      
      // Traiter les valeurs de registre
      if (data.technicalProperties.registryValues) {
        setRegistryValues(data.technicalProperties.registryValues || []);
      }
    }
  }, [data]);
  
  // Effet pour faire défiler le terminal vers le bas
  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [terminalOutput]);
  
  // Effet pour rafraîchir les processus automatiquement
  useEffect(() => {
    if (activeTab === 2 && connectionStatus && !disabled) {
      const interval = setInterval(() => {
        refreshProcessList();
      }, 10000); // Rafraîchir toutes les 10 secondes
      
      setRefreshInterval(interval);
      return () => clearInterval(interval);
    } else if (refreshInterval) {
      clearInterval(refreshInterval);
      setRefreshInterval(null);
    }
  }, [activeTab, connectionStatus, disabled]);
  
  // Handler pour les résultats de commande
  const handleCommandResults = (output) => {
    setIsExecuting(false);
    
    // Ajouter la sortie aux résultats du terminal
    setTerminalOutput(prev => [
      ...prev,
      {
        type: 'output',
        content: output.text || '',
        exitCode: output.exitCode,
        timestamp: new Date()
      }
    ]);
  };
  
  // Handler pour les résultats de liste de fichiers
  const handleFilesListResults = (filesList) => {
    setIsLoadingFiles(false);
    setFilesList(filesList.files || []);
    
    // Mettre à jour les fils d'Ariane
    const pathParts = currentPath.split('\\').filter(part => part);
    
    if (pathParts.length > 0 && pathParts[0].endsWith(':')) {
      // Chemin contenant un lecteur (ex: C:)
      const breadcrumbsItems = [{ name: pathParts[0], path: `${pathParts[0]}\\` }];
      
      let currentAccumulatedPath = `${pathParts[0]}\\`;
      for (let i = 1; i < pathParts.length; i++) {
        currentAccumulatedPath += `${pathParts[i]}\\`;
        breadcrumbsItems.push({
          name: pathParts[i],
          path: currentAccumulatedPath
        });
      }
      
      setBreadcrumbs(breadcrumbsItems);
    } else {
      setBreadcrumbs([{ name: currentPath, path: currentPath }]);
    }
  };
  
  // Handler pour les résultats de contenu de fichier
  const handleFileContentResults = (content) => {
    setFileContent({
      content: content.text || '',
      mimeType: content.mimeType || 'text/plain',
      size: content.size || 0,
      attributes: content.attributes || '',
      owner: content.owner || '',
      lastModified: content.lastModified ? new Date(content.lastModified) : null
    });
  };
  
  // Handler pour les résultats de liste de processus
  const handleProcessListResults = (processList) => {
    setIsLoadingProcesses(false);
    setProcessList(processList.processes || []);
  };
  
  // Handler pour les résultats de détails de processus
  const handleProcessDetailsResults = (details) => {
    setProcessDetails(details);
  };
  
  // Handler pour les résultats de liste de services
  const handleServicesListResults = (servicesList) => {
    setIsLoadingServices(false);
    setServicesList(servicesList.services || []);
  };
  
  // Handler pour les résultats de détails de service
  const handleServiceDetailsResults = (details) => {
    setServiceDetails(details);
  };
  
  // Handler pour les résultats d'informations système
  const handleSystemInfoResults = (info) => {
    setIsLoadingSystem(false);
    setSystemInfo(info);
  };
  
  // Handler pour les résultats d'utilisation de ressources
  const handleResourceUsageResults = (usage) => {
    setResourceUsage(usage);
  };
  
  // Fonction pour exécuter une commande
  const executeCommand = () => {
    if (!command.trim() || disabled || !connectionStatus) return;
    
    setIsExecuting(true);
    
    // Ajouter la commande à l'historique du terminal
    setTerminalOutput(prev => [
      ...prev,
      {
        type: 'command',
        content: command,
        timestamp: new Date()
      }
    ]);
    
    // Mettre à jour l'historique des commandes
    setCommandHistory(prev => [command, ...prev]);
    setHistoryIndex(-1);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ShellCommand",
        nodeName: "ExecuteCommand",
        nodeValue: command,
        actionType: "Windows",
        actionValue: command,
        actionName: "ExecuteCommand",
        shellType: shellType,
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ShellCommand',
      name: command.length > 30 ? `${command.substring(0, 30)}...` : command,
      timestamp: new Date()
    });
    
    // Réinitialiser la commande
    setCommand('');
  };
  
  // Fonction pour accéder à l'historique des commandes
  const navigateCommandHistory = (direction) => {
    if (commandHistory.length === 0) return;
    
    let newIndex;
    if (direction === 'up') {
      newIndex = historyIndex < commandHistory.length - 1 ? historyIndex + 1 : historyIndex;
    } else {
      newIndex = historyIndex > 0 ? historyIndex - 1 : -1;
    }
    
    if (newIndex === -1) {
      setCommand('');
    } else {
      setCommand(commandHistory[newIndex]);
    }
    
    setHistoryIndex(newIndex);
  };
  
  // Fonction pour naviguer dans le système de fichiers
  const navigateToPath = (path) => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingFiles(true);
    setCurrentPath(path);
    setSelectedFile(null);
    setFileContent(null);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "FileExplorer",
        nodeName: "ListFiles",
        nodeValue: path,
        actionType: "Windows",
        actionValue: `dir "${path}"`,
        actionName: "ListFiles",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'FileExplorer',
      name: `List files in ${path}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour visualiser un fichier
  const viewFile = (file) => {
    if (disabled || !connectionStatus || file.isDirectory) return;
    
    const filePath = `${currentPath}${file.name}`;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "FileExplorer",
        nodeName: "ViewFile",
        nodeValue: filePath,
        actionType: "Windows",
        actionValue: `type "${filePath}"`,
        actionName: "ViewFile",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'FileExplorer',
      name: `View file ${file.name}`,
      timestamp: new Date()
    });
    
    setSelectedFile(file);
  };
  
  // Fonction pour rafraîchir la liste des fichiers
  const refreshFilesList = () => {
    navigateToPath(currentPath);
  };
  
  // Fonction pour aller au dossier parent
  const navigateToParent = () => {
    // Cas spécial pour la racine d'un lecteur
    if (currentPath.match(/^[A-Z]:\\$/i)) {
      return; // Déjà à la racine d'un lecteur
    }
    
    const parts = currentPath.split('\\');
    parts.pop(); // Retirer le dernier élément
    
    // Si après avoir supprimé le dernier répertoire, nous sommes à la racine d'un lecteur
    if (parts.length === 1 && parts[0].endsWith(':')) {
      navigateToPath(`${parts[0]}\\`);
    } else {
      navigateToPath(parts.join('\\') + '\\');
    }
  };
  
  // Fonction pour rafraîchir la liste des processus
  const refreshProcessList = () => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingProcesses(true);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ProcessMonitor",
        nodeName: "ListProcesses",
        nodeValue: "tasklist",
        actionType: "Windows",
        actionValue: "Get-Process | Select-Object Name, Id, CPU, WorkingSet, Description, Company, Path",
        actionName: "ListProcesses",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ProcessMonitor',
      name: "List processes",
      timestamp: new Date()
    });
  };
  
  // Fonction pour visualiser les détails d'un processus
  const viewProcessDetails = (process) => {
    if (disabled || !connectionStatus) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ProcessMonitor",
        nodeName: "ProcessDetails",
        nodeValue: process.id.toString(),
        actionType: "Windows",
        actionValue: `Get-Process -Id ${process.id} | Format-List *`,
        actionName: "ProcessDetails",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ProcessMonitor',
      name: `View process ${process.id}`,
      timestamp: new Date()
    });
    
    setSelectedProcess(process);
  };
  
  // Fonction pour terminer un processus
  const terminateProcess = (process) => {
    if (disabled || !connectionStatus) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ProcessMonitor",
        nodeName: "TerminateProcess",
        nodeValue: process.id.toString(),
        actionType: "Windows",
        actionValue: `Stop-Process -Id ${process.id} -Force`,
        actionName: "TerminateProcess",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ProcessMonitor',
      name: `Terminate process ${process.id}`,
      timestamp: new Date()
    });
    
    setSnackbar({
      open: true,
      message: `Processus ${process.name} (${process.id}) terminé`,
      severity: 'info'
    });
    
    // Rafraîchir la liste après un court délai
    setTimeout(() => {
      refreshProcessList();
    }, 1000);
  };
  
  // Fonction pour rafraîchir la liste des services
  const refreshServicesList = () => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingServices(true);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ServiceMonitor",
        nodeName: "ListServices",
        nodeValue: "services",
        actionType: "Windows",
        actionValue: "Get-Service | Select-Object Name, DisplayName, Status, StartType",
        actionName: "ListServices",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ServiceMonitor',
      name: "List services",
      timestamp: new Date()
    });
  };
  
  // Fonction pour visualiser les détails d'un service
  const viewServiceDetails = (service) => {
    if (disabled || !connectionStatus) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ServiceMonitor",
        nodeName: "ServiceDetails",
        nodeValue: service.name,
        actionType: "Windows",
        actionValue: `Get-Service -Name "${service.name}" | Format-List *`,
        actionName: "ServiceDetails",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ServiceMonitor',
      name: `View service ${service.name}`,
      timestamp: new Date()
    });
    
    setSelectedService(service);
  };
  
  // Fonction pour contrôler un service (démarrer, arrêter, redémarrer)
  const controlService = (service, action) => {
    if (disabled || !connectionStatus) return;
    
    let command = '';
    let actionName = '';
    
    switch (action) {
      case 'start':
        command = `Start-Service -Name "${service.name}"`;
        actionName = 'StartService';
        break;
      case 'stop':
        command = `Stop-Service -Name "${service.name}"`;
        actionName = 'StopService';
        break;
      case 'restart':
        command = `Restart-Service -Name "${service.name}"`;
        actionName = 'RestartService';
        break;
      default:
        return;
    }
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ServiceMonitor",
        nodeName: actionName,
        nodeValue: service.name,
        actionType: "Windows",
        actionValue: command,
        actionName: actionName,
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ServiceMonitor',
      name: `${actionName} ${service.name}`,
      timestamp: new Date()
    });
    
    setSnackbar({
      open: true,
      message: `Service ${service.displayName}: ${action} en cours...`,
      severity: 'info'
    });
    
    // Rafraîchir la liste après un court délai
    setTimeout(() => {
      refreshServicesList();
    }, 2000);
  };
  
  // Fonction pour rafraîchir les informations système
  const refreshSystemInfo = () => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingSystem(true);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SystemMonitor",
        nodeName: "SystemInfo",
        nodeValue: "sysinfo",
        actionType: "Windows",
        actionValue: "Get-ComputerInfo",
        actionName: "SystemInfo",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    // Aussi demander l'utilisation des ressources
    const resourcePayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SystemMonitor",
        nodeName: "ResourceUsage",
        nodeValue: "resources",
        actionType: "Windows",
        actionValue: "Get-CimInstance Win32_OperatingSystem | Select-Object FreePhysicalMemory, TotalVisibleMemorySize",
        actionName: "ResourceUsage",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(resourcePayload);
    
    setLastAction({
      type: 'SystemMonitor',
      name: "Refresh system information",
      timestamp: new Date()
    });
  };
  
  // Fonction pour naviguer dans le registre
  const navigateToRegistryPath = (path) => {
    if (disabled || !connectionStatus) return;
    
    setIsLoadingRegistry(true);
    setRegistryPath(path);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "Registry",
        nodeName: "GetRegistryKeys",
        nodeValue: path,
        actionType: "Windows",
        actionValue: `Get-ChildItem -Path "Registry::${path}"`,
        actionName: "GetRegistryKeys",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(interactionPayload);
    
    // Aussi récupérer les valeurs à cette clé
    const valuesPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "Registry",
        nodeName: "GetRegistryValues",
        nodeValue: path,
        actionType: "Windows",
        actionValue: `Get-ItemProperty -Path "Registry::${path}"`,
        actionName: "GetRegistryValues",
        timestamp: Date.now(),
        server: connectionInfo.server,
        user: connectionInfo.user
      }
    };
    
    onInteractionSent(valuesPayload);
    
    setLastAction({
      type: 'Registry',
      name: `Navigate to ${path}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour aller à la clé de registre parente
  const navigateToParentRegistry = () => {
    const parts = registryPath.split('\\');
    if (parts.length <= 1) return; // Déjà à une clé racine
    
    parts.pop(); // Retirer la dernière partie
    navigateToRegistryPath(parts.join('\\'));
  };
  
  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Monitor sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              Windows OS {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionStatus ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionStatus ? 'success' : 'error'}
            label={connectionStatus ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Serveur
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.server || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Hostname
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.hostname || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Utilisateur
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.domain ? `${connectionInfo.domain}\\` : ''}{connectionInfo.user || 'N/A'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant de terminal PowerShell/CMD
  const TerminalComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
            <Terminal size={16} style={{ marginRight: 8 }} />
            Terminal Windows
          </Typography>
          
          <FormControl size="small" variant="outlined" sx={{ minWidth: 120 }}>
            <Select
              value={shellType}
              onChange={(e) => setShellType(e.target.value)}
              disabled={disabled || !connectionStatus}
            >
              <MenuItem value="powershell">PowerShell</MenuItem>
              <MenuItem value="cmd">Invite de commandes (CMD)</MenuItem>
            </Select>
          </FormControl>
        </Box>
        
        <Box 
          ref={terminalRef}
          sx={{
            flex: 1,
            bgcolor: shellType === 'powershell' ? '#012456' : 'black',
            color: shellType === 'powershell' ? 'white' : '#CCCCCC',
            p: 2,
            borderRadius: 1,
            fontFamily: 'Consolas, monospace',
            fontSize: '0.9rem',
            overflowY: 'auto',
            mb: 2
          }}
        >
          {terminalOutput.map((output, index) => (
            <Box key={index} sx={{ mb: 1 }}>
              {output.type === 'command' ? (
                <Box sx={{ display: 'flex' }}>
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontFamily: 'inherit', 
                      color: shellType === 'powershell' ? '#FFFF00' : '#CCCCCC',
                      mr: 1
                    }}
                  >
                    {shellType === 'powershell' ? 
                      `PS ${currentPath}> ` : 
                      `${currentPath}>`}
                  </Typography>
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontFamily: 'inherit'
                    }}
                  >
                    {output.content}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography 
                    component="div" 
                    sx={{ 
                      fontFamily: 'inherit',
                      color: output.exitCode === 0 ? 
                        (shellType === 'powershell' ? 'white' : '#CCCCCC') : 
                        '#FF6347',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {output.content}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
          {isExecuting && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ color: 'white', mr: 1 }} />
              <Typography sx={{ fontFamily: 'inherit' }}>Exécution en cours...</Typography>
            </Box>
          )}
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            sx={{ 
              color: shellType === 'powershell' ? '#FFFF00' : 'inherit', 
              fontFamily: 'Consolas, monospace',
              mr: 1
            }}
          >
            {shellType === 'powershell' ? 
              `PS ${currentPath}> ` : 
              `${currentPath}>`}
          </Typography>
          <TextField
            inputRef={commandInputRef}
            fullWidth
            variant="outlined"
            size="small"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                executeCommand();
              } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                navigateCommandHistory('up');
              } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                navigateCommandHistory('down');
              }
            }}
            placeholder={shellType === 'powershell' ? "Entrez une commande PowerShell..." : "Entrez une commande CMD..."}
            disabled={disabled || !connectionStatus || isExecuting}
            InputProps={{
              sx: { fontFamily: 'Consolas, monospace' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={executeCommand}
                    disabled={!command.trim() || disabled || !connectionStatus || isExecuting}
                  >
                    <Play size={16} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: shellType === 'powershell' ? '#012456' : 'primary.light',
                },
                backgroundColor: shellType === 'powershell' ? '#012456' : 'background.paper',
                color: shellType === 'powershell' ? 'white' : 'inherit',
              },
            }}
          />
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button
            size="small"
            startIcon={<Trash2 size={16} />}
            onClick={() => setTerminalOutput([])}
            disabled={terminalOutput.length === 0}
          >
            Effacer le terminal
          </Button>
        </Box>
      </Box>
    );
  };
  
  // Composant d'explorateur de fichiers
  const FileExplorerComponent = () => {
    // Format de la taille en unités lisibles
    const formatFileSize = (bytes) => {
      if (bytes === 0) return '0 B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };
    
    // Format des attributs Windows
    const formatAttributes = (attrs) => {
      if (!attrs) return '';
      
      const attributeMap = {
        'R': 'Lecture seule',
        'A': 'Archive',
        'S': 'Système',
        'H': 'Caché',
        'I': 'Non indexé',
        'N': 'Normal',
        'D': 'Répertoire',
        'C': 'Compressé',
        'E': 'Chiffré'
      };
      
      return attrs.split('').map(attr => attributeMap[attr] || attr).join(', ');
    };
    
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Folder size={16} style={{ marginRight: 8 }} />
          Explorateur de fichiers
        </Typography>
        
        {/* Lecteurs disponibles */}
        <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap' }}>
          {drives.map((drive, index) => (
            <Chip
              key={index}
              icon={<HardDrive size={16} />}
              label={`${drive.name} (${drive.volumeName || 'Local Disk'}) - ${drive.freeSpace ? formatFileSize(drive.freeSpace) + ' libre' : ''}`}
              onClick={() => navigateToPath(`${drive.name}\\`)}
              disabled={disabled || !connectionStatus}
              color={currentPath.startsWith(drive.name) ? 'primary' : 'default'}
              sx={{ cursor: 'pointer' }}
            />
          ))}
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Breadcrumbs separator={<ChevronRight size={16} />} aria-label="breadcrumb">
            {breadcrumbs.map((crumb, index) => (
              <Link
                key={index}
                component="button"
                underline="hover"
                color="inherit"
                onClick={() => navigateToPath(crumb.path)}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {index === 0 ? <HardDrive size={16} style={{ marginRight: 4 }} /> : null}
                {crumb.name}
              </Link>
            ))}
          </Breadcrumbs>
          
          <Box>
            <IconButton
              size="small"
              onClick={navigateToParent}
              disabled={currentPath.match(/^[A-Z]:\\$/i) || disabled || !connectionStatus}
              sx={{ mr: 1 }}
              title="Dossier parent"
            >
              <ArrowUp size={16} />
            </IconButton>
            <IconButton
              size="small"
              onClick={refreshFilesList}
              disabled={disabled || !connectionStatus}
              title="Rafraîchir"
            >
              <RefreshCw size={16} />
            </IconButton>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
          {/* Liste des fichiers */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '60%', 
              mr: 2, 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {isLoadingFiles ? (
              <Box sx={{ p: 2 }}>
                <LinearProgress />
              </Box>
            ) : filesList.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Aucun fichier trouvé
                </Typography>
              </Box>
            ) : (
              <List dense>
                {filesList.map((file, index) => (
                  <ListItem 
                    key={index}
                    disablePadding
                    secondaryAction={
                      <IconButton 
                        edge="end" 
                        size="small"
                        onClick={(e) => {
                          setContextMenu({
                            mouseX: e.clientX,
                            mouseY: e.clientY,
                            file
                          });
                        }}
                      >
                        <MoreVertical size={16} />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      selected={selectedFile && selectedFile.name === file.name}
                      onClick={() => {
                        if (file.isDirectory) {
                          navigateToPath(`${currentPath}${file.name}\\`);
                        } else {
                          viewFile(file);
                        }
                      }}
                      dense
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {file.isDirectory ? (
                          <FolderOpen size={20} color="#FFB74D" />
                        ) : (
                          <FileText size={20} color="#90CAF9" />
                        )}
                      </ListItemIcon>
                      <ListItemText 
                        primary={file.name} 
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.secondary"
                              sx={{ display: 'block' }}
                            >
                              {formatAttributes(file.attributes)} {file.owner}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.secondary"
                              sx={{ display: 'block' }}
                            >
                              {!file.isDirectory && formatFileSize(file.size)}
                              {file.lastModified && ` • ${new Date(file.lastModified).toLocaleString()}`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
          
          {/* Aperçu de fichier */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '40%', 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {selectedFile ? (
              <React.Fragment>
                <Box sx={{ 
                  p: 2, 
                  bgcolor: 'grey.100', 
                  borderBottom: 1, 
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Typography variant="subtitle2">
                    {selectedFile.name}
                  </Typography>
                  <Chip
                    size="small"
                    label={formatFileSize(selectedFile.size)}
                  />
                </Box>
                
                {fileContent ? (
                  <Box sx={{ p: 2 }}>
                    {/* Aperçu du contenu selon le type */}
                    <Box 
                      sx={{ 
                        fontFamily: 'Consolas, monospace', 
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        fontSize: '0.875rem',
                        p: 2,
                        bgcolor: 'grey.900',
                        color: 'grey.100',
                        borderRadius: 1,
                        overflow: 'auto',
                        maxHeight: '400px'
                      }}
                    >
                      {fileContent.content}
                    </Box>
                    
                    <Divider sx={{ my: 2 }} />
                    
                    {/* Informations du fichier */}
                    <Typography variant="subtitle2" gutterBottom>
                      Informations du fichier
                    </Typography>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Type:</Typography>
                        <Typography variant="body2">{fileContent.mimeType}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Taille:</Typography>
                        <Typography variant="body2">{formatFileSize(fileContent.size)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Attributs:</Typography>
                        <Typography variant="body2">{formatAttributes(fileContent.attributes)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Propriétaire:</Typography>
                        <Typography variant="body2">{fileContent.owner}</Typography>
                      </Grid>
                      {fileContent.lastModified && (
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">Dernière modification:</Typography>
                          <Typography variant="body2">{fileContent.lastModified.toLocaleString()}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <CircularProgress size={24} />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Chargement du contenu...
                    </Typography>
                  </Box>
                )}
              </React.Fragment>
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Sélectionnez un fichier pour voir son contenu
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    );
  };
  
  // Composant moniteur de processus
  const ProcessMonitorComponent = () => {
    // Format mémoire en MB
    const formatMemoryMB = (bytes) => {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    };
    
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Activity size={16} style={{ marginRight: 8 }} />
          Gestionnaire des tâches
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            startIcon={<RefreshCw size={16} />}
            onClick={refreshProcessList}
            disabled={disabled || !connectionStatus || isLoadingProcesses}
            size="small"
          >
            Rafraîchir
          </Button>
        </Box>
        
        <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
          {/* Liste des processus */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '60%', 
              mr: 2, 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {isLoadingProcesses ? (
              <Box sx={{ p: 2 }}>
                <LinearProgress />
              </Box>
            ) : processList.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Aucun processus trouvé
                </Typography>
              </Box>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>PID</TableCell>
                      <TableCell>Mémoire</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processList.map((process, index) => (
                      <TableRow key={index} hover
                        selected={selectedProcess && selectedProcess.id === process.id}
                        onClick={() => viewProcessDetails(process)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{process.name}</TableCell>
                        <TableCell>{process.id}</TableCell>
                        <TableCell>
                          {process.workingSet ? formatMemoryMB(process.workingSet) : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Tooltip title={process.description || 'Pas de description'}>
                            <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                              {process.description || 'N/A'}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              terminateProcess(process);
                            }}
                            title="Terminer le processus"
                          >
                            <StopCircle size={16} color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
          
          {/* Détails du processus */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '40%', 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {selectedProcess ? (
              <React.Fragment>
                <Box sx={{ 
                  p: 2, 
                  bgcolor: 'grey.100', 
                  borderBottom: 1, 
                  borderColor: 'divider'
                }}>
                  <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Activity size={16} style={{ marginRight: 8 }} />
                    Processus {selectedProcess.name} (PID: {selectedProcess.id})
                  </Typography>
                </Box>
                
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">PID:</Typography>
                      <Typography variant="body2">{selectedProcess.id}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Nom:</Typography>
                      <Typography variant="body2">{selectedProcess.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">CPU:</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">{selectedProcess.cpu ? `${selectedProcess.cpu}%` : 'N/A'}</Typography>
                        {selectedProcess.cpu && (
                          <LinearProgress 
                            variant="determinate" 
                            value={Math.min(parseFloat(selectedProcess.cpu), 100)} 
                            sx={{ 
                              ml: 1, 
                              width: 100,
                              height: 6,
                              borderRadius: 3,
                              backgroundColor: 'grey.300',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: parseFloat(selectedProcess.cpu) > 50 ? 'error.main' : 'success.main'
                              }
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Mémoire:</Typography>
                      <Typography variant="body2">
                        {selectedProcess.workingSet ? formatMemoryMB(selectedProcess.workingSet) : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Description:</Typography>
                      <Typography variant="body2">{selectedProcess.description || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Chemin:</Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontFamily: 'Consolas, monospace',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-all'
                        }}
                      >
                        {selectedProcess.path || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Société:</Typography>
                      <Typography variant="body2">{selectedProcess.company || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Actions:</Typography>
                      <Box sx={{ mt: 1 }}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          startIcon={<StopCircle size={16} />}
                          onClick={() => terminateProcess(selectedProcess)}
                        >
                          Terminer le processus
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Sélectionnez un processus pour voir ses détails
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    );
  };
  
  // Composant moniteur de services
  const ServicesMonitorComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Settings size={16} style={{ marginRight: 8 }} />
          Services Windows
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <FormControl size="small" variant="outlined" sx={{ minWidth: 150 }}>
            <Select
              value={serviceFilter}
              onChange={(e) => setServiceFilter(e.target.value)}
              disabled={disabled || !connectionStatus}
            >
              <MenuItem value="all">Tous les services</MenuItem>
              <MenuItem value="running">En cours d'exécution</MenuItem>
              <MenuItem value="stopped">Arrêtés</MenuItem>
              <MenuItem value="automatic">Démarrage auto</MenuItem>
              <MenuItem value="manual">Démarrage manuel</MenuItem>
            </Select>
          </FormControl>
          
          <Button
            startIcon={<RefreshCw size={16} />}
            onClick={refreshServicesList}
            disabled={disabled || !connectionStatus || isLoadingServices}
            size="small"
          >
            Rafraîchir
          </Button>
        </Box>
        
        <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
          {/* Liste des services */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '60%', 
              mr: 2, 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {isLoadingServices ? (
              <Box sx={{ p: 2 }}>
                <LinearProgress />
              </Box>
            ) : servicesList.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Aucun service trouvé
                </Typography>
              </Box>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>Statut</TableCell>
                      <TableCell>Type de démarrage</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicesList
                      .filter(service => {
                        if (serviceFilter === 'all') return true;
                        if (serviceFilter === 'running') return service.status === 'Running';
                        if (serviceFilter === 'stopped') return service.status === 'Stopped';
                        if (serviceFilter === 'automatic') return service.startType === 'Automatic';
                        if (serviceFilter === 'manual') return service.startType === 'Manual';
                        return true;
                      })
                      .map((service, index) => (
                        <TableRow key={index} hover
                          selected={selectedService && selectedService.name === service.name}
                          onClick={() => viewServiceDetails(service)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell>
                            <Tooltip title={service.displayName}>
                              <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                {service.displayName}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Chip
                              size="small"
                              icon={service.status === 'Running' ? <CheckCircle size={14} /> : <StopCircle size={14} />}
                              label={service.status}
                              color={service.status === 'Running' ? 'success' : 'default'}
                            />
                          </TableCell>
                          <TableCell>{service.startType}</TableCell>
                          <TableCell>
                            {service.status === 'Running' ? (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    controlService(service, 'restart');
                                  }}
                                  title="Redémarrer le service"
                                  sx={{ mr: 1 }}
                                  disabled={disabled || !connectionStatus}
                                >
                                  <RefreshCw size={16} color="primary" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    controlService(service, 'stop');
                                  }}
                                  title="Arrêter le service"
                                  disabled={disabled || !connectionStatus}
                                >
                                  <StopCircle size={16} color="error" />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  controlService(service, 'start');
                                }}
                                title="Démarrer le service"
                                disabled={disabled || !connectionStatus}
                              >
                                <Play size={16} color="success" />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
          
          {/* Détails du service */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '40%', 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {selectedService ? (
              <React.Fragment>
                <Box sx={{ 
                  p: 2, 
                  bgcolor: 'grey.100', 
                  borderBottom: 1, 
                  borderColor: 'divider'
                }}>
                  <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Settings size={16} style={{ marginRight: 8 }} />
                    Service: {selectedService.displayName}
                  </Typography>
                </Box>
                
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Nom:</Typography>
                      <Typography variant="body2">{selectedService.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Nom affiché:</Typography>
                      <Typography variant="body2">{selectedService.displayName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Statut:</Typography>
                      <Chip
                        size="small"
                        icon={selectedService.status === 'Running' ? <CheckCircle size={14} /> : <StopCircle size={14} />}
                        label={selectedService.status}
                        color={selectedService.status === 'Running' ? 'success' : 'default'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Type de démarrage:</Typography>
                      <Typography variant="body2">{selectedService.startType}</Typography>
                    </Grid>
                    
                    {serviceDetails && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">Chemin de l'exécutable:</Typography>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              fontFamily: 'Consolas, monospace',
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-all'
                            }}
                          >
                            {serviceDetails.binaryPath || 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">Compte de démarrage:</Typography>
                          <Typography variant="body2">{serviceDetails.startAccount || 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">Dépendances:</Typography>
                          <Typography variant="body2">{serviceDetails.dependencies?.join(', ') || 'Aucune'}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">Description:</Typography>
                          <Typography variant="body2">{serviceDetails.description || 'Pas de description'}</Typography>
                        </Grid>
                      </>
                    )}
                    
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Actions:</Typography>
                      <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                        {selectedService.status === 'Running' ? (
                          <>
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              startIcon={<RefreshCw size={16} />}
                              onClick={() => controlService(selectedService, 'restart')}
                              disabled={disabled || !connectionStatus}
                            >
                              Redémarrer
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              color="error"
                              startIcon={<StopCircle size={16} />}
                              onClick={() => controlService(selectedService, 'stop')}
                              disabled={disabled || !connectionStatus}
                            >
                              Arrêter
                            </Button>
                          </>
                        ) : (
                          <Button
                            size="small"
                            variant="outlined"
                            color="success"
                            startIcon={<Play size={16} />}
                            onClick={() => controlService(selectedService, 'start')}
                            disabled={disabled || !connectionStatus}
                          >
                            Démarrer
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Sélectionnez un service pour voir ses détails
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    );
  };
  
  // Composant moniteur système
  const SystemMonitorComponent = () => {
    const formatProgressColor = (value) => {
      if (value > 80) return 'error';
      if (value > 60) return 'warning';
      return 'success';
    };
    
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Monitor size={16} style={{ marginRight: 8 }} />
          Informations système
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            startIcon={<RefreshCw size={16} />}
            onClick={refreshSystemInfo}
            disabled={disabled || !connectionStatus || isLoadingSystem}
            size="small"
          >
            Rafraîchir
          </Button>
        </Box>
        
        {isLoadingSystem ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : !systemInfo ? (
          <Box sx={{ textAlign: 'center', p: 4 }}>
            <Typography variant="body2" color="text.secondary">
              Cliquez sur Rafraîchir pour charger les informations système
            </Typography>
          </Box>
        ) : (
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <Grid container spacing={3}>
              {/* Informations système */}
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Info size={16} style={{ marginRight: 8 }} />
                    Informations générales
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Système d'exploitation:</Typography>
                      <Typography variant="body2">{systemInfo.osName} {systemInfo.osVersion}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Build:</Typography>
                      <Typography variant="body2">{systemInfo.osBuild}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Architecture:</Typography>
                      <Typography variant="body2">{systemInfo.osArchitecture}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Nom de l'ordinateur:</Typography>
                      <Typography variant="body2">{systemInfo.computerName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Domaine:</Typography>
                      <Typography variant="body2">{systemInfo.domain || 'Groupe de travail'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">Démarré depuis:</Typography>
                      <Typography variant="body2">{systemInfo.uptime}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              
              {/* Matériel */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Cpu size={16} style={{ marginRight: 8 }} />
                    Processeur et mémoire
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Processeur:</Typography>
                      <Typography variant="body2">{systemInfo.processor}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Cœurs physiques:</Typography>
                      <Typography variant="body2">{systemInfo.cores}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Cœurs logiques:</Typography>
                      <Typography variant="body2">{systemInfo.logicalProcessors}</Typography>
                    </Grid>
                    
                    {resourceUsage && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">Mémoire physique totale:</Typography>
                          <Typography variant="body2">{resourceUsage.totalMemory} MB</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">Mémoire physique disponible:</Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                            <Typography variant="body2">{resourceUsage.freeMemory} MB</Typography>
                            <Box sx={{ flex: 1, ml: 2 }}>
                              <LinearProgress 
                                variant="determinate" 
                                value={100 - (resourceUsage.freeMemory / resourceUsage.totalMemory * 100)}
                                color={formatProgressColor(100 - (resourceUsage.freeMemory / resourceUsage.totalMemory * 100))}
                                sx={{ height: 10, borderRadius: 5 }}
                              />
                            </Box>
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              {Math.round(100 - (resourceUsage.freeMemory / resourceUsage.totalMemory * 100))}%
                            </Typography>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
              
              {/* Stockage */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <HardDrive size={16} style={{ marginRight: 8 }} />
                    Disques et stockage
                  </Typography>
                  
                  {systemInfo.disks && (
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Lecteur</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Utilisation</TableCell>
                            <TableCell>Espace</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {systemInfo.disks.map((disk, index) => (
                            <TableRow key={index}>
                              <TableCell>{disk.name} {disk.label ? `(${disk.label})` : ''}</TableCell>
                              <TableCell>{disk.fileSystem}</TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <LinearProgress 
                                    variant="determinate" 
                                    value={disk.usedPercent} 
                                    color={formatProgressColor(disk.usedPercent)}
                                    sx={{ width: 60, height: 6, borderRadius: 3, mr: 1 }}
                                  />
                                  <Typography variant="body2">
                                    {disk.usedPercent}%
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                {disk.free} libre sur {disk.size}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Paper>
              </Grid>
              
              {/* Réseau */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Wifi size={16} style={{ marginRight: 8 }} />
                    Configuration réseau
                  </Typography>
                  
                  {systemInfo.network && (
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Interface</TableCell>
                            <TableCell>Adresse IP</TableCell>
                            <TableCell>Masque</TableCell>
                            <TableCell>Passerelle</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {systemInfo.network.map((iface, index) => (
                            <TableRow key={index}>
                              <TableCell>{iface.name}</TableCell>
                              <TableCell>{iface.ipAddress}</TableCell>
                              <TableCell>{iface.subnetMask}</TableCell>
                              <TableCell>{iface.gateway}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Paper>
              </Grid>
              
              {/* Utilisateurs connectés */}
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Users size={16} style={{ marginRight: 8 }} />
                    Utilisateurs connectés
                  </Typography>
                  
                  {systemInfo.sessions && (
                    <List dense>
                      {systemInfo.sessions.map((session, index) => (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <User size={20} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={`${session.domain}\\${session.username}`} 
                            secondary={`Session ${session.id} - ${session.state}`}
                          />
                          {session.active && (
                            <Chip size="small" label="Active" color="success" />
                          )}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Paper>
              </Grid>
              
              {/* Mises à jour Windows */}
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Shield size={16} style={{ marginRight: 8 }} />
                    Mises à jour et protection
                  </Typography>
                  
                  {systemInfo.updates && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary">Dernière vérification:</Typography>
                        <Typography variant="body2">{systemInfo.updates.lastChecked || 'Inconnue'}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary">Dernière installation:</Typography>
                        <Typography variant="body2">{systemInfo.updates.lastInstalled || 'Inconnue'}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary">Protection antivirus:</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                          {systemInfo.updates.antivirusEnabled ? (
                            <Chip icon={<CheckCircle size={14} />} label="Activée" color="success" size="small" />
                          ) : (
                            <Chip icon={<AlertTriangle size={14} />} label="Désactivée" color="error" size="small" />
                          )}
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            {systemInfo.updates.antivirusName || ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary">Mises à jour en attente:</Typography>
                        {systemInfo.updates.pendingCount > 0 ? (
                          <Chip label={`${systemInfo.updates.pendingCount} mises à jour`} color="warning" size="small" />
                        ) : (
                          <Chip label="Système à jour" color="success" size="small" />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };
  
  // Composant du registre Windows
  const RegistryComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Database size={16} style={{ marginRight: 8 }} />
          Éditeur de registre
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            label="Chemin du registre"
            value={registryPath}
            onChange={(e) => setRegistryPath(e.target.value)}
            disabled={disabled || !connectionStatus || isLoadingRegistry}
            sx={{ mr: 1 }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => navigateToRegistryPath(registryPath)}
            disabled={disabled || !connectionStatus || isLoadingRegistry}
          >
            Accéder
          </Button>
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box>
            <Chip
              icon={<Database size={14} />}
              label="HKEY_LOCAL_MACHINE"
              onClick={() => navigateToRegistryPath('HKEY_LOCAL_MACHINE')}
              disabled={disabled || !connectionStatus}
              sx={{ mr: 1 }}
            />
            <Chip
              icon={<Database size={14} />}
              label="HKEY_CURRENT_USER"
              onClick={() => navigateToRegistryPath('HKEY_CURRENT_USER')}
              disabled={disabled || !connectionStatus}
              sx={{ mr: 1 }}
            />
            <Chip
              icon={<Database size={14} />}
              label="HKEY_CLASSES_ROOT"
              onClick={() => navigateToRegistryPath('HKEY_CLASSES_ROOT')}
              disabled={disabled || !connectionStatus}
            />
          </Box>
          
          <Box>
            <IconButton
              size="small"
              onClick={navigateToParentRegistry}
              disabled={registryPath.indexOf('\\') === -1 || disabled || !connectionStatus}
              sx={{ mr: 1 }}
              title="Clé parente"
            >
              <ArrowUp size={16} />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => navigateToRegistryPath(registryPath)}
              disabled={disabled || !connectionStatus}
              title="Rafraîchir"
            >
              <RefreshCw size={16} />
            </IconButton>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
          {/* Liste des clés */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '40%', 
              mr: 2, 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {isLoadingRegistry ? (
              <Box sx={{ p: 2 }}>
                <LinearProgress />
              </Box>
            ) : registryKeys.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Aucune clé trouvée
                </Typography>
              </Box>
            ) : (
              <List dense>
                {registryKeys.map((key, index) => (
                  <ListItem 
                    key={index}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => navigateToRegistryPath(`${registryPath}\\${key.name}`)}
                      dense
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <Database size={20} color="#90CAF9" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={key.name} 
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
          
          {/* Valeurs */}
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '60%', 
              overflow: 'auto',
              height: '100%'
            }}
          >
            {isLoadingRegistry ? (
              <Box sx={{ p: 2 }}>
                <LinearProgress />
              </Box>
            ) : registryValues.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Aucune valeur trouvée dans cette clé
                </Typography>
              </Box>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Valeur</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registryValues.map((value, index) => (
                      <TableRow key={index} hover>
                        <TableCell>{value.name}</TableCell>
                        <TableCell>{value.type}</TableCell>
                        <TableCell>
                          <Tooltip title={String(value.data)}>
                            <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                              {value.data !== null ? String(value.data) : '(Vide)'}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Box>
      </Box>
    );
  };
  
  // Menu contextuel pour les fichiers
  const FileContextMenu = () => {
    if (!contextMenu) return null;
    
    const handleClose = () => {
      setContextMenu(null);
    };
    
    const handleAction = (action) => {
      // Implémenter les actions du menu contextuel
      handleClose();
    };
    
    return (
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => handleAction('view')}>
          <ListItemIcon>
            <Eye size={16} />
          </ListItemIcon>
          <ListItemText>Visualiser</ListItemText>
        </MenuItem>
        {contextMenu.file && !contextMenu.file.isDirectory && (
          <MenuItem onClick={() => handleAction('edit')}>
            <ListItemIcon>
              <Edit size={16} />
            </ListItemIcon>
            <ListItemText>Éditer</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleAction('rename')}>
          <ListItemIcon>
            <Edit size={16} />
          </ListItemIcon>
          <ListItemText>Renommer</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAction('delete')}>
          <ListItemIcon>
            <Trash2 size={16} />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
        {contextMenu.file && !contextMenu.file.isDirectory && (
          <MenuItem onClick={() => handleAction('download')}>
            <ListItemIcon>
              <Download size={16} />
            </ListItemIcon>
            <ListItemText>Télécharger</ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={() => handleAction('properties')}>
          <ListItemIcon>
            <Info size={16} />
          </ListItemIcon>
          <ListItemText>Propriétés</ListItemText>
        </MenuItem>
      </Menu>
    );
  };
  
  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionStatus) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionStatus ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          bgcolor: 'background.paper'
        }}
      >
        <Tab icon={<Terminal size={16} />} label="Terminal" />
        <Tab icon={<Folder size={16} />} label="Fichiers" />
        <Tab icon={<Activity size={16} />} label="Processus" />
        <Tab icon={<Settings size={16} />} label="Services" />
        <Tab icon={<Server size={16} />} label="Système" />
        <Tab icon={<Database size={16} />} label="Registre" />
      </Tabs>
      
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {activeTab === 0 && <TerminalComponent />}
        {activeTab === 1 && <FileExplorerComponent />}
        {activeTab === 2 && <ProcessMonitorComponent />}
        {activeTab === 3 && <ServicesMonitorComponent />}
        {activeTab === 4 && <SystemMonitorComponent />}
        {activeTab === 5 && <RegistryComponent />}
      </Box>
      
      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
      
      <FileContextMenu />
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default WindowsCapture;