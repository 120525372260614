import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Switch,
  Alert,
  Snackbar
} from '@mui/material';

import {
  Plus,
  MoreVertical,
  Edit,
  Trash2,
  Power,
  PowerOff,
  Server,
  RefreshCw,
  CheckCircle,
  XCircle,
  Link,
  Info,
  // Suppression de l'importation incorrecte de Functions
  // Remplacez par une icône existante si vous en avez besoin, par exemple:
  // Code, FileCode, ou FunctionSquare si vous avez besoin d'une icône similaire
  Code,
  Search,
  Settings,
  Filter,
  Download
} from 'lucide-react';

import api from '../../services/api.service';

/**
 * Page de gestion des environnements d'automatisation
 */
const EnvironmentsPage = () => {
  // États pour les environnements
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [detailTab, setDetailTab] = useState(0);
  
  // États pour les fonctions associées
  const [associatedFunctions, setAssociatedFunctions] = useState([]);
  const [loadingFunctions, setLoadingFunctions] = useState(false);
  
  // États pour les agents
  const [activeAgents, setActiveAgents] = useState([]);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [agentDeployed, setAgentDeployed] = useState(false);
  
  // États pour les dialogues
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('create'); // 'create' ou 'edit'
  const [dialogData, setDialogData] = useState({
    appName: '',
    appType: '',
    appVersion: '',
    isActive: true
  });
  
  // États pour le menu contextuel
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuEnvironment, setMenuEnvironment] = useState(null);
  
  // État pour les notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // État pour le dialogue d'assignation d'agent
  const [assignAgentDialog, setAssignAgentDialog] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState('');
  
  // État pour le dialogue de confirmation de suppression
  const [deleteDialog, setDeleteDialog] = useState(false);
  
  // Effet pour charger les environnements au montage
  useEffect(() => {
    fetchEnvironments();
    fetchActiveAgents();
  }, []);
  
  // Effet pour charger les fonctions quand un environnement est sélectionné
  useEffect(() => {
    if (selectedEnvironment) {
      fetchFunctions(selectedEnvironment.id);
      checkAgentDeployed(selectedEnvironment.id);
    }
  }, [selectedEnvironment]);
  
  // Fonction pour récupérer tous les environnements
  const fetchEnvironments = async () => {
    setLoading(true);
    try {
      const response = await api.automata.environments.getAll();
      setEnvironments(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des environnements:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la récupération des environnements',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour récupérer les fonctions d'un environnement
  const fetchFunctions = async (environmentId) => {
    setLoadingFunctions(true);
    try {
      const response = await api.automata.environments.getFunctions(environmentId);
      setAssociatedFunctions(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des fonctions:', error);
      setAssociatedFunctions([]);
    } finally {
      setLoadingFunctions(false);
    }
  };
  
  // Fonction pour récupérer les agents actifs
  const fetchActiveAgents = async () => {
    setLoadingAgents(true);
    try {
      const response = await api.automata.environments.getActiveAgents();
      setActiveAgents(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des agents actifs:', error);
      setActiveAgents([]);
    } finally {
      setLoadingAgents(false);
    }
  };
  
  // Fonction pour vérifier si un agent est déployé dans l'environnement
  const checkAgentDeployed = async (environmentId) => {
    try {
      const response = await api.automata.environments.isAgentDeployed(environmentId);
      setAgentDeployed(response.agentDeployed);
    } catch (error) {
      console.error('Erreur lors de la vérification du déploiement d\'agent:', error);
      setAgentDeployed(false);
    }
  };
  
  // Gestionnaire pour ouvrir le menu contextuel
  const handleMenuOpen = (event, environment) => {
    setAnchorEl(event.currentTarget);
    setMenuEnvironment(environment);
  };
  
  // Gestionnaire pour fermer le menu contextuel
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuEnvironment(null);
  };
  
  // Gestionnaire pour changer d'onglet dans les détails
  const handleTabChange = (event, newValue) => {
    setDetailTab(newValue);
  };
  
  // Gestionnaire pour les changements de champ dans le formulaire
  const handleDialogChange = (e) => {
    const { name, value } = e.target;
    setDialogData({
      ...dialogData,
      [name]: value
    });
  };
  
  // Gestionnaire pour les changements de valeur booléenne
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setDialogData({
      ...dialogData,
      [name]: checked
    });
  };
  
  // Fonction pour ouvrir le dialogue de création
  const handleCreateDialog = () => {
    setDialogMode('create');
    setDialogData({
      appName: '',
      appType: '',
      appVersion: '',
      isActive: true
    });
    setOpenDialog(true);
  };
  
  // Fonction pour ouvrir le dialogue de modification
  const handleEditDialog = (environment) => {
    setDialogMode('edit');
    setDialogData({
      id: environment.id,
      appName: environment.appName,
      appType: environment.appType,
      appVersion: environment.appVersion,
      isActive: environment.isActive
    });
    setOpenDialog(true);
    handleMenuClose();
  };
  
  // Fonction pour ouvrir le dialogue de confirmation de suppression
  const handleDeleteDialog = (environment) => {
    setMenuEnvironment(environment);
    setDeleteDialog(true);
    handleMenuClose();
  };
  
  // Fonction pour créer un environnement
  const createEnvironment = async () => {
    try {
      await api.automata.environments.create(dialogData);
      setSnackbar({
        open: true,
        message: 'Environnement créé avec succès',
        severity: 'success'
      });
      fetchEnvironments();
      setOpenDialog(false);
    } catch (error) {
      console.error('Erreur lors de la création de l\'environnement:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la création de l\'environnement',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour mettre à jour un environnement
  const updateEnvironment = async () => {
    try {
      await api.automata.environments.update(dialogData.id, dialogData);
      setSnackbar({
        open: true,
        message: 'Environnement mis à jour avec succès',
        severity: 'success'
      });
      fetchEnvironments();
      
      // Mettre à jour l'environnement sélectionné si c'est celui qui a été modifié
      if (selectedEnvironment && selectedEnvironment.id === dialogData.id) {
        setSelectedEnvironment({
          ...selectedEnvironment,
          ...dialogData
        });
      }
      
      setOpenDialog(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'environnement:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la mise à jour de l\'environnement',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour supprimer un environnement
  const deleteEnvironment = async () => {
    if (!menuEnvironment) return;
    
    try {
      await api.automata.environments.delete(menuEnvironment.id);
      
      // Si l'environnement supprimé est sélectionné, le désélectionner
      if (selectedEnvironment && selectedEnvironment.id === menuEnvironment.id) {
        setSelectedEnvironment(null);
      }
      
      setSnackbar({
        open: true,
        message: 'Environnement supprimé avec succès',
        severity: 'success'
      });
      fetchEnvironments();
      setDeleteDialog(false);
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'environnement:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'Erreur lors de la suppression de l\'environnement',
        severity: 'error'
      });
      setDeleteDialog(false);
    }
  };
  
  // Fonction pour définir l'état actif d'un environnement
  const toggleEnvironmentActive = async (environment) => {
    try {
      await api.automata.environments.setActive(environment.id, !environment.isActive);
      
      // Mettre à jour l'état localement sans refaire un appel API complet
      setEnvironments(environments.map(env => 
        env.id === environment.id ? { ...env, isActive: !env.isActive } : env
      ));
      
      // Mettre à jour l'environnement sélectionné si c'est celui qui a été modifié
      if (selectedEnvironment && selectedEnvironment.id === environment.id) {
        setSelectedEnvironment({
          ...selectedEnvironment,
          isActive: !environment.isActive
        });
      }
      
      setSnackbar({
        open: true,
        message: `Environnement ${!environment.isActive ? 'activé' : 'désactivé'} avec succès`,
        severity: 'success'
      });
      
      handleMenuClose();
    } catch (error) {
      console.error('Erreur lors de la modification de l\'état actif:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la modification de l\'état actif',
        severity: 'error'
      });
    }
  };
  
  // Ouvrir le dialogue d'assignation d'agent
  const openAssignAgentDialog = () => {
    setSelectedAgent('');
    setAssignAgentDialog(true);
    handleMenuClose();
  };
  
  // Assigner un agent à un environnement
  const assignAgent = async () => {
    if (!selectedAgent || !menuEnvironment) {
      setSnackbar({
        open: true,
        message: 'Veuillez sélectionner un agent',
        severity: 'warning'
      });
      return;
    }
    
    try {
      await api.automata.environments.assignAgent(menuEnvironment.id, selectedAgent);
      
      setSnackbar({
        open: true,
        message: 'Agent assigné avec succès',
        severity: 'success'
      });
      
      // Rafraîchir l'état
      fetchEnvironments();
      if (selectedEnvironment && selectedEnvironment.id === menuEnvironment.id) {
        checkAgentDeployed(selectedEnvironment.id);
      }
      
      setAssignAgentDialog(false);
    } catch (error) {
      console.error('Erreur lors de l\'assignation de l\'agent:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de l\'assignation de l\'agent',
        severity: 'error'
      });
    }
  };
  
  // Rendu du tableau des environnements
  const renderEnvironmentsTable = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (environments.length === 0) {
      return (
        <Box p={4} textAlign="center">
          <Typography variant="body1" color="textSecondary">
            Aucun environnement trouvé. Cliquez sur "Ajouter un environnement" pour en créer un.
          </Typography>
        </Box>
      );
    }
    
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Agent Déployé</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {environments.map((environment) => (
              <TableRow 
                key={environment.id}
                hover
                selected={selectedEnvironment?.id === environment.id}
                onClick={() => setSelectedEnvironment(environment)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{environment.appName}</TableCell>
                <TableCell>{environment.appType}</TableCell>
                <TableCell>{environment.appVersion}</TableCell>
                <TableCell>
                  <Chip 
                    color={environment.isActive ? "success" : "default"}
                    size="small"
                    icon={environment.isActive ? <CheckCircle size={14} /> : <XCircle size={14} />}
                    label={environment.isActive ? "Actif" : "Inactif"}
                  />
                </TableCell>
                <TableCell>
                  <Chip 
                    color={environment.isAgentDeployed ? "primary" : "default"}
                    size="small"
                    icon={environment.isAgentDeployed ? <Link size={14} /> : <XCircle size={14} />}
                    label={environment.isAgentDeployed ? "Déployé" : "Non déployé"}
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton 
                    aria-label="options" 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMenuOpen(e, environment);
                    }}
                  >
                    <MoreVertical size={18} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  // Rendu de la section de détails d'un environnement
  const renderEnvironmentDetails = () => {
    if (!selectedEnvironment) {
      return (
        <Box p={4} textAlign="center">
          <Typography variant="body1" color="textSecondary">
            Sélectionnez un environnement pour voir ses détails.
          </Typography>
        </Box>
      );
    }
    
    return (
      <Box>
        <Box p={2} bgcolor="background.paper">
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Server size={24} color={selectedEnvironment.isActive ? "#4caf50" : "#9e9e9e"} />
            </Grid>
            <Grid item xs>
              <Typography variant="h6">{selectedEnvironment.appName}</Typography>
              <Typography variant="body2" color="textSecondary">
                {selectedEnvironment.appType} - Version {selectedEnvironment.appVersion}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color={selectedEnvironment.isActive ? "error" : "success"}
                startIcon={selectedEnvironment.isActive ? <PowerOff /> : <Power />}
                onClick={() => toggleEnvironmentActive(selectedEnvironment)}
              >
                {selectedEnvironment.isActive ? "Désactiver" : "Activer"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<Edit />}
                onClick={() => handleEditDialog(selectedEnvironment)}
              >
                Modifier
              </Button>
            </Grid>
          </Grid>
        </Box>
        
        <Tabs value={detailTab} onChange={handleTabChange}>
          <Tab label="Informations" icon={<Info size={18} />} iconPosition="start" />
          <Tab label="Fonctions" icon={<Code size={18} />} iconPosition="start" />
          <Tab label="Agent" icon={<Server size={18} />} iconPosition="start" />
        </Tabs>
        
        <Divider />
        
        <Box p={2}>
          {detailTab === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card variant="outlined">
                  <CardHeader title="Détails de l'environnement" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">ID:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">{selectedEnvironment.id}</Typography>
                      </Grid>
                      
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">Nom:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">{selectedEnvironment.appName}</Typography>
                      </Grid>
                      
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">Type:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">{selectedEnvironment.appType}</Typography>
                      </Grid>
                      
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">Version:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">{selectedEnvironment.appVersion}</Typography>
                      </Grid>
                      
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">Statut:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Chip 
                          color={selectedEnvironment.isActive ? "success" : "default"}
                          size="small"
                          icon={selectedEnvironment.isActive ? <CheckCircle size={14} /> : <XCircle size={14} />}
                          label={selectedEnvironment.isActive ? "Actif" : "Inactif"}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Card variant="outlined">
                  <CardHeader title="Statistiques" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Fonctions associées:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">{associatedFunctions.length}</Typography>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Agent déployé:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Chip 
                          color={agentDeployed ? "primary" : "default"}
                          size="small"
                          icon={agentDeployed ? <CheckCircle size={14} /> : <XCircle size={14} />}
                          label={agentDeployed ? "Oui" : "Non"}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          
          {detailTab === 1 && (
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Fonctions associées</Typography>
                <Button 
                  variant="outlined" 
                  startIcon={<RefreshCw size={18} />}
                  onClick={() => fetchFunctions(selectedEnvironment.id)}
                >
                  Rafraîchir
                </Button>
              </Box>
              
              {loadingFunctions ? (
                <Box display="flex" justifyContent="center" p={4}>
                  <CircularProgress />
                </Box>
              ) : associatedFunctions.length === 0 ? (
                <Box p={4} textAlign="center">
                  <Typography variant="body1" color="textSecondary">
                    Aucune fonction associée à cet environnement.
                  </Typography>
                </Box>
              ) : (
                <TableContainer component={Paper} variant="outlined">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Domaine</TableCell>
                        <TableCell>Programme</TableCell>
                        <TableCell>Statut</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {associatedFunctions.map((func) => (
                        <TableRow key={func.id}>
                          <TableCell>{func.functionName}</TableCell>
                          <TableCell>{func.functionType}</TableCell>
                          <TableCell>{func.functionDomain}</TableCell>
                          <TableCell>{func.functionProgram}</TableCell>
                          <TableCell>
                            <Chip 
                              color={func.isActive ? "success" : "default"}
                              size="small"
                              label={func.isActive ? "Actif" : "Inactif"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
          
          {detailTab === 2 && (
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Informations de l'agent</Typography>
                <Button
                  variant="contained" 
                  color="primary"
                  startIcon={<Link size={18} />}
                  disabled={!selectedEnvironment.isActive}
                  onClick={() => {
                    setMenuEnvironment(selectedEnvironment);
                    openAssignAgentDialog();
                  }}
                >
                  Assigner un agent
                </Button>
              </Box>
              
              {agentDeployed ? (
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Alert severity="success">
                          Un agent est déployé dans cet environnement.
                        </Alert>
                      </Grid>
                      
                      {selectedEnvironment.agent && (
                        <>
                          <Grid item xs={3}>
                            <Typography variant="body2" color="textSecondary">Type d'agent:</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="body2">{selectedEnvironment.agent.agentType}</Typography>
                          </Grid>
                          
                          <Grid item xs={3}>
                            <Typography variant="body2" color="textSecondary">Adresse IP:</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="body2">{selectedEnvironment.agent.ipAddress}</Typography>
                          </Grid>
                          
                          <Grid item xs={3}>
                            <Typography variant="body2" color="textSecondary">Port:</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="body2">{selectedEnvironment.agent.port}</Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                <Alert severity="info">
                  Aucun agent n'est actuellement déployé dans cet environnement. Utilisez le bouton "Assigner un agent" pour en déployer un.
                </Alert>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Box sx={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Server size={24} />
          Gestion des Environnements
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            startIcon={<RefreshCw size={18} />}
            onClick={fetchEnvironments}
          >
            Rafraîchir
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus size={18} />}
            onClick={handleCreateDialog}
          >
            Ajouter un environnement
          </Button>
        </Box>
      </Paper>
      
      {/* Contenu principal */}
      <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        {/* Liste des environnements */}
        <Box sx={{ 
          width: '40%', 
          height: '100%', 
          borderRight: 1, 
          borderColor: 'divider', 
          overflow: 'auto'
        }}>
          {renderEnvironmentsTable()}
        </Box>
        
        {/* Détails de l'environnement */}
        <Box sx={{ 
          flex: 1, 
          height: '100%', 
          overflow: 'auto'
        }}>
          {renderEnvironmentDetails()}
        </Box>
      </Box>
      
      {/* Menu contextuel pour les actions sur un environnement */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleEditDialog(menuEnvironment)}>
          <Edit size={18} style={{ marginRight: 8 }} />
          Modifier
        </MenuItem>
        
        <MenuItem onClick={() => toggleEnvironmentActive(menuEnvironment)}>
          {menuEnvironment?.isActive ? (
            <>
              <PowerOff size={18} style={{ marginRight: 8, color: '#f44336' }} />
              Désactiver
            </>
          ) : (
            <>
              <Power size={18} style={{ marginRight: 8, color: '#4caf50' }} />
              Activer
            </>
          )}
        </MenuItem>
        
        <MenuItem onClick={openAssignAgentDialog}>
          <Link size={18} style={{ marginRight: 8 }} />
          Assigner un agent
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={() => handleDeleteDialog(menuEnvironment)}>
          <Trash2 size={18} style={{ marginRight: 8, color: '#f44336' }} />
          Supprimer
        </MenuItem>
      </Menu>
      
      {/* Dialogue pour créer/modifier un environnement */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {dialogMode === 'create' ? 'Créer un nouvel environnement' : 'Modifier l\'environnement'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField 
                fullWidth
                label="Nom de l'application"
                name="appName"
                value={dialogData.appName}
                onChange={handleDialogChange}
                required
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField 
                fullWidth
                label="Version de l'application"
                name="appVersion"
                value={dialogData.appVersion}
                onChange={handleDialogChange}
                required
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Type d'application</InputLabel>
                <Select
                  name="appType"
                  value={dialogData.appType}
                  onChange={handleDialogChange}
                  label="Type d'application"
                >
                  <MenuItem value="CORE_BANKING">Système bancaire central</MenuItem>
                  <MenuItem value="PAYMENT_SYSTEM">Système de paiement</MenuItem>
                  <MenuItem value="TRADING_PLATFORM">Plateforme de trading</MenuItem>
                  <MenuItem value="ACCOUNTING_SYSTEM">Système comptable</MenuItem>
                  <MenuItem value="CRM">Gestion de la relation client</MenuItem>
                  <MenuItem value="ERP">Planification des ressources</MenuItem>
                  <MenuItem value="HR_SYSTEM">Système de ressources humaines</MenuItem>
                  <MenuItem value="LOGISTICS">Logistique</MenuItem>
                  <MenuItem value="SUPPLY_CHAIN">Chaîne d'approvisionnement</MenuItem>
                  <MenuItem value="INVENTORY">Gestion des stocks</MenuItem>
                  <MenuItem value="E_COMMERCE">Commerce électronique</MenuItem>
                  <MenuItem value="CMS">Système de gestion de contenu</MenuItem>
                  <MenuItem value="REPORTING">Système de reporting</MenuItem>
                  <MenuItem value="ANALYTICS">Analytique et décisionnel</MenuItem>
                  <MenuItem value="RISK_MANAGEMENT">Gestion des risques</MenuItem>
                  <MenuItem value="COMPLIANCE">Conformité réglementaire</MenuItem>
                  <MenuItem value="SECURITY">Sécurité</MenuItem>
                  <MenuItem value="CUSTOMER_PORTAL">Portail client</MenuItem>
                  <MenuItem value="INTERNAL_PORTAL">Portail interne</MenuItem>
                  <MenuItem value="INTEGRATION_HUB">Hub d'intégration</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={dialogData.isActive}
                    onChange={handleSwitchChange}
                  />
                }
                label="Actif"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>
            Annuler
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={dialogMode === 'create' ? createEnvironment : updateEnvironment}
            disabled={!dialogData.appName || !dialogData.appType || !dialogData.appVersion}
          >
            {dialogMode === 'create' ? 'Créer' : 'Enregistrer'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'assignation d'agent */}
      <Dialog
        open={assignAgentDialog}
        onClose={() => setAssignAgentDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Assigner un agent à l'environnement</DialogTitle>
        <DialogContent>
          {loadingAgents ? (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          ) : activeAgents.length === 0 ? (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Aucun agent actif disponible. Veuillez activer au moins un agent avant de continuer.
            </Alert>
          ) : (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Agent</InputLabel>
              <Select
                value={selectedAgent}
                onChange={(e) => setSelectedAgent(e.target.value)}
                label="Agent"
              >
                {activeAgents.map((agent) => (
                  <MenuItem key={agent.id} value={agent.id}>
                    {agent.agentType} - {agent.ipAddress}:{agent.port}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignAgentDialog(false)}>
            Annuler
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={assignAgent}
            disabled={!selectedAgent || loadingAgents || activeAgents.length === 0}
          >
            Assigner
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de confirmation de suppression */}
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer l'environnement "{menuEnvironment?.appName}" ?
            Cette action ne peut pas être annulée.
          </Typography>
          <Alert severity="warning" sx={{ mt: 2 }}>
            Note: Si cet environnement contient des fonctions, la suppression échouera.
            Veuillez d'abord supprimer toutes les fonctions associées.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>
            Annuler
          </Button>
          <Button 
            variant="contained" 
            color="error"
            onClick={deleteEnvironment}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EnvironmentsPage;