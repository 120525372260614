import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Divider,
  Chip,
  Tooltip,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Switch,
  FormControlLabel,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
  Menu,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  LinearProgress,
  Fab,
  AppBar,
  Toolbar,
  Collapse,
  Zoom
} from '@mui/material';

import {
  Plus,
  Edit2,
  Trash2,
  Search,
  RefreshCw,
  Box as BoxIcon,
  ChevronDown,
  CheckCircle,
  XCircle,
  Copy,
  Star,
  Code,
  FileCode,
  Package,
  Link,
  Tag,
  Layers,
  Filter,
  Globe,
  BarChart2,
  ExternalLink,
  Settings,
  Info,
  List as ListIcon,
  Grid as GridIcon,
  Calendar,
  Clock,
  Play,
  Activity,
  Server,
  Database,
  Monitor,
  Cpu,
  HardDrive,
  Layout,
  Zap,
  AlertTriangle,
  ArrowUp,
  ArrowDown,
  Eye,
  MoreVertical,
  X,
  HelpCircle,
  Check,
  CloudOff,
  Terminal,
  Users,
  PieChart,
  Sliders,
  Briefcase,
  PlayCircle,
  Pause,
  Home,
  BarChart,
  LineChart,
  Network,
  GitCommit,
  Hexagon,
  Layers as LayersSolid,
  AlertCircle,
  Workflow,
  BellRing,
  Command,
  ChevronsUp,
  ChevronsDown,
  GanttChartSquare,
  Heart,
  CloudLightning,
  Share2,
  Gauge,
  ChevronUp,
  ChevronRight,
  Cable
} from 'lucide-react';

import { 
  LineChart as RLineChart, 
  Line, 
  BarChart as RBarChart, 
  Bar, 
  PieChart as RPieChart, 
  Pie, 
  Cell, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RTooltip, 
  Legend, 
  ResponsiveContainer,
  AreaChart,
  Area,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Treemap,
  ComposedChart,
  Scatter
} from 'recharts';

import api from '../../services/api.service';
import DateFormatter from '../../components/shared/DateFormatter';

// Importation du composant pour afficher un graphe de relations
import ForceGraph2D from 'react-force-graph-2d';

// Définir les couleurs du thème
const COLORS = {
  primary: '#3f51b5',
  secondary: '#f50057',
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
  info: '#2196f3',
  environment: '#3f51b5',
  agent: '#673ab7',
  function: '#009688',
  task: '#ff5722',
  chartColors: ['#3f51b5', '#03a9f4', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#673ab7', '#009688', '#ff5722']
};

/**
 * Dashboard Mission Control pour le système d'automatisation
 */
const MissionControlDashboard = () => {
  // États principaux pour les données
  const [environments, setEnvironments] = useState([]);
  const [agents, setAgents] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [relationshipData, setRelationshipData] = useState({ nodes: [], links: [] });
  
  // État pour le chargement et les erreurs
  const [loading, setLoading] = useState({
    environments: true,
    agents: true,
    functions: true,
    tasks: true,
    relationships: true
  });
  const [error, setError] = useState({
    environments: null,
    agents: null,
    functions: null,
    tasks: null,
    relationships: null
  });
  
  // État pour le tableau de bord actif
  const [activeTab, setActiveTab] = useState('overview');
  const [detailView, setDetailView] = useState({
    type: null,
    id: null,
    data: null,
    open: false
  });
  
  // États pour les filtres et le tri
  const [filters, setFilters] = useState({
    environment: { search: '', type: 'all', status: 'all' },
    agent: { search: '', type: 'all', status: 'all' },
    function: { search: '', domain: 'all', type: 'all' },
    task: { search: '', status: 'all', type: 'all' }
  });
  const [sort, setSort] = useState({
    environment: { field: 'appName', direction: 'asc' },
    agent: { field: 'agentType', direction: 'asc' },
    function: { field: 'functionName', direction: 'asc' },
    task: { field: 'taskName', direction: 'asc' }
  });
  
  // État pour les options d'affichage
  const [viewOptions, setViewOptions] = useState({
    environmentView: 'grid',
    agentView: 'grid',
    functionView: 'grid',
    taskView: 'grid',
    showInactive: false,
    darkMode: false
  });
  
  // État pour les éléments sélectionnés
  const [selection, setSelection] = useState({
    environments: [],
    agents: [],
    functions: [],
    tasks: []
  });
  
  // États pour les dialogues
  const [dialogState, setDialogState] = useState({
    type: null, // 'create', 'edit', 'delete', 'deploy', etc.
    entityType: null, // 'environment', 'agent', 'function', 'task'
    open: false,
    data: null
  });
  
  // État pour la barre de notification
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  // État pour le mode de visualisation du réseau
  const [networkView, setNetworkView] = useState({
    active: false,
    focusNode: null,
    showLabels: true,
    showLinks: true
  });
  
  // État pour les alertes système
  const [systemAlerts, setSystemAlerts] = useState([]);
  
  // État pour les statistiques de performance
  const [performanceStats, setPerformanceStats] = useState({
    cpuUsage: [],
    memoryUsage: [],
    networkTraffic: [],
    responseTime: []
  });
  
  // Effets pour charger les données
  useEffect(() => {
    // Charger toutes les données nécessaires
    loadAllData();
    
    // Simuler la mise à jour des statistiques de performance toutes les 5 secondes
    const perfInterval = setInterval(() => {
      updatePerformanceStats();
    }, 5000);
    
    // Simuler la génération d'alertes système
    const alertInterval = setInterval(() => {
      generateRandomAlert();
    }, 30000);
    
    // Nettoyage
    return () => {
      clearInterval(perfInterval);
      clearInterval(alertInterval);
    };
  }, []);
  
  // Effet pour construire les données de relation
  useEffect(() => {
    if (!loading.environments && !loading.agents && !loading.functions && !loading.tasks) {
      buildRelationshipData();
    }
  }, [environments, agents, functions, tasks]);
  
  // Fonction pour charger toutes les données
  const loadAllData = async () => {
    fetchEnvironments();
    fetchAgents();
    fetchFunctions();
    fetchTasks();
    generateInitialPerformanceData();
  };
  
  // Fonctions de récupération des données
  const fetchEnvironments = async () => {
    setLoading(prev => ({ ...prev, environments: true }));
    try {
      const data = await api.automata.environments.getAll();
      setEnvironments(data);
      setError(prev => ({ ...prev, environments: null }));
    } catch (err) {
      console.error("Erreur lors du chargement des environnements:", err);
      setError(prev => ({ ...prev, environments: "Erreur lors du chargement des environnements" }));
    } finally {
      setLoading(prev => ({ ...prev, environments: false }));
    }
  };
  
  const fetchAgents = async () => {
    setLoading(prev => ({ ...prev, agents: true }));
    try {
      const data = await api.automata.agents.getAll();
      setAgents(data);
      setError(prev => ({ ...prev, agents: null }));
    } catch (err) {
      console.error("Erreur lors du chargement des agents:", err);
      setError(prev => ({ ...prev, agents: "Erreur lors du chargement des agents" }));
    } finally {
      setLoading(prev => ({ ...prev, agents: false }));
    }
  };
  
  const fetchFunctions = async () => {
    setLoading(prev => ({ ...prev, functions: true }));
    try {
      const data = await api.automata.functions.getAll();
      setFunctions(data);
      setError(prev => ({ ...prev, functions: null }));
    } catch (err) {
      console.error("Erreur lors du chargement des fonctions:", err);
      setError(prev => ({ ...prev, functions: "Erreur lors du chargement des fonctions" }));
    } finally {
      setLoading(prev => ({ ...prev, functions: false }));
    }
  };
  
  const fetchTasks = async () => {
    setLoading(prev => ({ ...prev, tasks: true }));
    try {
      const data = await api.automata.tasks.getAll();
      setTasks(data);
      setError(prev => ({ ...prev, tasks: null }));
    } catch (err) {
      console.error("Erreur lors du chargement des tâches:", err);
      setError(prev => ({ ...prev, tasks: "Erreur lors du chargement des tâches" }));
    } finally {
      setLoading(prev => ({ ...prev, tasks: false }));
    }
  };
  
  // Fonction pour construire les données de relation
  const buildRelationshipData = () => {
    setLoading(prev => ({ ...prev, relationships: true }));
    try {
      // Créer les nœuds
      const nodes = [
        ...environments.map(env => ({
          id: `env-${env.id}`,
          name: env.appName,
          type: 'environment',
          data: env,
          val: 20 // Taille du nœud
        })),
        ...agents.map(agent => ({
          id: `agent-${agent.id}`,
          name: `Agent ${agent.id}`,
          type: 'agent',
          data: agent,
          val: 15
        })),
        ...functions.map(func => ({
          id: `func-${func.id}`,
          name: func.functionName,
          type: 'function',
          data: func,
          val: 10
        })),
        ...tasks.map(task => ({
          id: `task-${task.id}`,
          name: task.taskName,
          type: 'task',
          data: task,
          val: 5
        }))
      ];
      
      // Créer les liens
      const links = [];
      
      // Relations environnement-agent
      environments.forEach(env => {
        if (env.agent) {
          links.push({
            source: `env-${env.id}`,
            target: `agent-${env.agent.id}`,
            type: 'env-agent'
          });
        }
      });
      
      // Relations environnement-fonction
      environments.forEach(env => {
        (env.functions || []).forEach(func => {
          links.push({
            source: `env-${env.id}`,
            target: `func-${func.id}`,
            type: 'env-func'
          });
        });
      });
      
      // Relations fonction-tâche
      tasks.forEach(task => {
        if (task.function) {
          links.push({
            source: `func-${task.function.id}`,
            target: `task-${task.id}`,
            type: 'func-task'
          });
        }
      });
      
      setRelationshipData({ nodes, links });
      setError(prev => ({ ...prev, relationships: null }));
    } catch (err) {
      console.error("Erreur lors de la construction des relations:", err);
      setError(prev => ({ ...prev, relationships: "Erreur lors de la construction des relations" }));
    } finally {
      setLoading(prev => ({ ...prev, relationships: false }));
    }
  };
  
  // Fonctions pour générer des données de performance (simulation)
  const generateInitialPerformanceData = () => {
    // Simulation des données de performance sur une journée (24 points)
    const now = new Date();
    const hours24 = Array.from({ length: 24 }, (_, i) => {
      const time = new Date(now);
      time.setHours(now.getHours() - 23 + i);
      return time.getTime();
    });
    
    // CPU Usage (10-80%)
    const cpuUsage = hours24.map(time => ({
      time,
      value: 10 + Math.random() * 70
    }));
    
    // Memory Usage (20-90%)
    const memoryUsage = hours24.map(time => ({
      time,
      value: 20 + Math.random() * 70
    }));
    
    // Network Traffic (0-100 Mbps)
    const networkTraffic = hours24.map(time => ({
      time,
      value: Math.random() * 100
    }));
    
    // Response Time (50-500ms)
    const responseTime = hours24.map(time => ({
      time,
      value: 50 + Math.random() * 450
    }));
    
    setPerformanceStats({
      cpuUsage,
      memoryUsage,
      networkTraffic,
      responseTime
    });
  };
  
  // Fonction pour mettre à jour les statistiques de performance
  const updatePerformanceStats = () => {
    setPerformanceStats(prevStats => {
      const now = new Date().getTime();
      
      // Ajouter un nouveau point de données pour chaque métrique
      const updateStat = (stat) => {
        const newStat = [...stat.slice(1), {
          time: now,
          value: stat[stat.length - 1].value + (Math.random() * 20 - 10)
        }];
        
        // S'assurer que les valeurs restent dans une plage raisonnable
        if (newStat[newStat.length - 1].value < 5) newStat[newStat.length - 1].value = 5;
        if (newStat[newStat.length - 1].value > 95) newStat[newStat.length - 1].value = 95;
        
        return newStat;
      };
      
      return {
        cpuUsage: updateStat(prevStats.cpuUsage),
        memoryUsage: updateStat(prevStats.memoryUsage),
        networkTraffic: updateStat(prevStats.networkTraffic),
        responseTime: updateStat(prevStats.responseTime)
      };
    });
  };
  
  // Fonction pour générer une alerte aléatoire (simulation)
  const generateRandomAlert = () => {
    // 30% de chance de générer une alerte
    if (Math.random() > 0.3) return;
    
    const alertTypes = [
      { type: 'info', message: 'Synchronisation des agents terminée', icon: 'info' },
      { type: 'success', message: 'Déploiement automatique réussi', icon: 'check-circle' },
      { type: 'warning', message: 'Utilisation CPU élevée sur le serveur principal', icon: 'alert-triangle' },
      { type: 'error', message: 'Échec de connexion à l\'agent', icon: 'x-circle' }
    ];
    
    const randomAlert = alertTypes[Math.floor(Math.random() * alertTypes.length)];
    const newAlert = {
      id: Date.now(),
      type: randomAlert.type,
      message: randomAlert.message,
      icon: randomAlert.icon,
      time: new Date()
    };
    
    setSystemAlerts(prev => [newAlert, ...prev].slice(0, 5));
  };
  
  // Fonctions de filtrage pour chaque type d'entité
  const filteredEnvironments = useMemo(() => {
    return environments.filter(env => {
      const { search, type, status } = filters.environment;
      
      // Filtrer par recherche
      if (search && !env.appName.toLowerCase().includes(search.toLowerCase())) {
        return false;
      }
      
      // Filtrer par type
      if (type !== 'all' && env.appType !== type) {
        return false;
      }
      
      // Filtrer par statut
      if (status === 'active' && !env.isActive) {
        return false;
      }
      if (status === 'inactive' && env.isActive) {
        return false;
      }
      if (status === 'withAgent' && !env.agent) {
        return false;
      }
      if (status === 'withoutAgent' && env.agent) {
        return false;
      }
      
      return true;
    });
  }, [environments, filters.environment]);
  
  const filteredAgents = useMemo(() => {
    return agents.filter(agent => {
      const { search, type, status } = filters.agent;
      
      // Filtrer par recherche
      if (search && !(`Agent ${agent.id} ${agent.agentType}`).toLowerCase().includes(search.toLowerCase())) {
        return false;
      }
      
      // Filtrer par type
      if (type !== 'all' && agent.agentType !== type) {
        return false;
      }
      
      // Filtrer par statut
      if (status === 'active' && !agent.isActive) {
        return false;
      }
      if (status === 'inactive' && agent.isActive) {
        return false;
      }
      
      return true;
    });
  }, [agents, filters.agent]);
  
  const filteredFunctions = useMemo(() => {
    return functions.filter(func => {
      const { search, domain, type } = filters.function;
      
      // Filtrer par recherche
      if (search && !func.functionName.toLowerCase().includes(search.toLowerCase())) {
        return false;
      }
      
      // Filtrer par domaine
      if (domain !== 'all' && func.functionDomain !== domain) {
        return false;
      }
      
      // Filtrer par type
      if (type !== 'all' && func.functionType !== type) {
        return false;
      }
      
      return true;
    });
  }, [functions, filters.function]);
  
  const filteredTasks = useMemo(() => {
    return tasks.filter(task => {
      const { search, status, type } = filters.task;
      
      // Filtrer par recherche
      if (search && !task.taskName.toLowerCase().includes(search.toLowerCase())) {
        return false;
      }
      
      // Filtrer par statut
      if (status !== 'all' && task.executionStatus !== status) {
        return false;
      }
      
      // Filtrer par type
      if (type !== 'all' && task.taskType !== type) {
        return false;
      }
      
      return true;
    });
  }, [tasks, filters.task]);
  
  // Statistiques pour l'aperçu
  const stats = useMemo(() => {
    return {
      environments: {
        total: environments.length,
        active: environments.filter(env => env.isActive).length,
        withAgent: environments.filter(env => env.agent).length
      },
      agents: {
        total: agents.length,
        active: agents.filter(agent => agent.isActive).length,
        deployed: environments.filter(env => env.isAgentDeployed).length
      },
      functions: {
        total: functions.length,
        active: functions.filter(func => func.isActive).length,
        byDomain: functions.reduce((acc, func) => {
          const domain = func.functionDomain || 'Undefined';
          acc[domain] = (acc[domain] || 0) + 1;
          return acc;
        }, {})
      },
      tasks: {
        total: tasks.length,
        pending: tasks.filter(task => task.executionStatus === 'PENDING').length,
        inProgress: tasks.filter(task => task.executionStatus === 'IN_PROGRESS').length,
        completed: tasks.filter(task => task.executionStatus === 'COMPLETED').length,
        failed: tasks.filter(task => task.executionStatus === 'FAILED').length
      }
    };
  }, [environments, agents, functions, tasks]);
  
  // Préparation des données pour les graphiques
  const chartData = useMemo(() => {
    return {
      // Environnements par type
      envByType: Object.entries(
        environments.reduce((acc, env) => {
          acc[env.appType] = (acc[env.appType] || 0) + 1;
          return acc;
        }, {})
      ).map(([name, value]) => ({ name, value })),
      
      // Distribution des statuts de tâches
      taskStatus: [
        { name: 'En attente', value: stats.tasks.pending },
        { name: 'En cours', value: stats.tasks.inProgress },
        { name: 'Terminées', value: stats.tasks.completed },
        { name: 'Échouées', value: stats.tasks.failed }
      ],
      
      // Fonctions par domaine
      funcByDomain: Object.entries(stats.functions.byDomain)
        .map(([name, value]) => ({ name, value })),
      
      // Types d'agents
      agentTypes: Object.entries(
        agents.reduce((acc, agent) => {
          acc[agent.agentType] = (acc[agent.agentType] || 0) + 1;
          return acc;
        }, {})
      ).map(([name, value]) => ({ name, value }))
    };
  }, [environments, tasks, functions, agents, stats]);
  
  // Gestionnaires d'événements
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  const handleOpenDetail = (type, id) => {
    let data = null;
    
    switch (type) {
      case 'environment':
        data = environments.find(env => env.id === id);
        break;
      case 'agent':
        data = agents.find(agent => agent.id === id);
        break;
      case 'function':
        data = functions.find(func => func.id === id);
        break;
      case 'task':
        data = tasks.find(task => task.id === id);
        break;
    }
    
    setDetailView({
      type,
      id,
      data,
      open: true
    });
  };
  
  const handleCloseDetail = () => {
    setDetailView({
      type: null,
      id: null,
      data: null,
      open: false
    });
  };
  
  const handleFilterChange = (entityType, filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [entityType]: {
        ...prev[entityType],
        [filterType]: value
      }
    }));
  };
  
  const handleOpenDialog = (type, entityType, data = null) => {
    setDialogState({
      type,
      entityType,
      open: true,
      data
    });
  };
  
  const handleCloseDialog = () => {
    setDialogState({
      type: null,
      entityType: null,
      open: false,
      data: null
    });
  };
  
  const showNotification = (message, severity = 'info') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };
  
  const handleCloseNotification = () => {
    setNotification(prev => ({
      ...prev,
      open: false
    }));
  };
  
  const handleRefreshAll = () => {
    loadAllData();
    showNotification('Données actualisées avec succès');
  };
  
  const toggleNetworkView = () => {
    setNetworkView(prev => ({
      ...prev,
      active: !prev.active
    }));
  };
  
  // Helper function to get task status color
  const getTaskStatusColor = (status) => {
    switch (status) {
      case 'COMPLETED':
        return 'success';
      case 'IN_PROGRESS':
        return 'info';
      case 'PENDING':
        return 'warning';
      case 'FAILED':
      case 'BLOCKED':
      case 'STOPPED':
        return 'error';
      default:
        return 'default';
    }
  };
  
  // Rendu du graphe de relations
  const renderRelationshipGraph = () => {
    if (loading.relationships) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      );
    }
    
    return (
      <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 10 }}>
          <Paper sx={{ p: 1 }}>
            <Stack direction="row" spacing={1}>
              <Chip
                icon={<Layers size={14} />}
                label="Environnements"
                size="small"
                sx={{ bgcolor: COLORS.environment, color: 'white' }}
              />
              <Chip
                icon={<Server size={14} />}
                label="Agents"
                size="small"
                sx={{ bgcolor: COLORS.agent, color: 'white' }}
              />
              <Chip
                icon={<Code size={14} />}
                label="Fonctions"
                size="small"
                sx={{ bgcolor: COLORS.function, color: 'white' }}
              />
              <Chip
                icon={<Briefcase size={14} />}
                label="Tâches"
                size="small"
                sx={{ bgcolor: COLORS.task, color: 'white' }}
              />
            </Stack>
          </Paper>
        </Box>
        
        <ForceGraph2D
          graphData={relationshipData}
          nodeLabel={node => `${node.name} (${node.type})`}
          nodeAutoColorBy="type"
          nodeCanvasObject={(node, ctx, globalScale) => {
            const label = node.name;
            const fontSize = 12 / globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            
            // Définir la couleur en fonction du type
            let color = '#666';
            switch (node.type) {
              case 'environment':
                color = COLORS.environment;
                break;
              case 'agent':
                color = COLORS.agent;
                break;
              case 'function':
                color = COLORS.function;
                break;
              case 'task':
                color = COLORS.task;
                break;
            }
            
            // Dessiner le cercle
            ctx.beginPath();
            ctx.arc(node.x, node.y, node.val, 0, 2 * Math.PI, false);
            ctx.fillStyle = color;
            ctx.fill();
            
            // Dessiner le texte si le zoom est suffisant
            if (globalScale > 0.8 && networkView.showLabels) {
              const textWidth = ctx.measureText(label).width;
              const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2);
              
              ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
              ctx.fillRect(
                node.x - bckgDimensions[0] / 2,
                node.y + node.val + 2,
                bckgDimensions[0],
                bckgDimensions[1]
              );
              
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = '#000';
              ctx.fillText(
                label,
                node.x,
                node.y + node.val + 2 + fontSize / 2
              );
            }
          }}
          linkColor={() => 'rgba(150, 150, 150, 0.6)'}
          linkWidth={2}
          linkDirectionalArrowLength={3}
          linkDirectionalArrowRelPos={1}
          onNodeClick={(node) => {
            handleOpenDetail(node.type, node.data.id);
          }}
          cooldownTicks={100}
          enableNodeDrag={true}
          enableZoomInteraction={true}
          enablePanInteraction={true}
          height={window.innerHeight - 150}
        />
      </Box>
    );
  };
  
  // Rendu du tableau de bord de Mission Control
  const renderMissionControl = () => {
    return (
      <Box sx={{ p: 2, height: 'calc(100vh - 130px)' }}>
        <Grid container spacing={2}>
          {/* Ligne supérieure - Statistiques clés */}
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: COLORS.environment, color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {stats.environments.total}
                    </Typography>
                    <Typography variant="subtitle1">Environnements</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    <Layers size={32} />
                  </Avatar>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.environments.active / stats.environments.total) * 100 || 0} 
                  sx={{ mt: 2, height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{stats.environments.active} actifs</Typography>
                  <Typography variant="caption">{stats.environments.withAgent} avec agent</Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  variant="text" 
                  sx={{ color: 'white' }}
                  onClick={() => setActiveTab('environments')}
                  endIcon={<ChevronRight size={16} />}
                >
                  Détails
                </Button>
              </CardActions>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: COLORS.agent, color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {stats.agents.total}
                    </Typography>
                    <Typography variant="subtitle1">Agents</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    <Server size={32} />
                  </Avatar>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.agents.active / stats.agents.total) * 100 || 0} 
                  sx={{ mt: 2, height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{stats.agents.active} actifs</Typography>
                  <Typography variant="caption">{stats.agents.deployed} déployés</Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  variant="text" 
                  sx={{ color: 'white' }}
                  onClick={() => setActiveTab('agents')}
                  endIcon={<ChevronRight size={16} />}
                >
                  Détails
                </Button>
              </CardActions>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: COLORS.function, color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {stats.functions.total}
                    </Typography>
                    <Typography variant="subtitle1">Fonctions</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    <Code size={32} />
                  </Avatar>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.functions.active / stats.functions.total) * 100 || 0} 
                  sx={{ mt: 2, height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{stats.functions.active} actives</Typography>
                  <Typography variant="caption">
                    {Object.keys(stats.functions.byDomain).length} domaines
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  variant="text" 
                  sx={{ color: 'white' }}
                  onClick={() => setActiveTab('functions')}
                  endIcon={<ChevronRight size={16} />}
                >
                  Détails
                </Button>
              </CardActions>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: COLORS.task, color: 'white', boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {stats.tasks.total}
                    </Typography>
                    <Typography variant="subtitle1">Tâches</Typography>
                  </div>
                  <Avatar 
                    sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                  >
                    <Briefcase size={32} />
                  </Avatar>
                </Box>
                <Box sx={{ mt: 2, position: 'relative', height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}>
                  <Box 
                    sx={{ 
                      position: 'absolute', 
                      left: 0, 
                      top: 0, 
                      height: '100%', 
                      width: `${(stats.tasks.completed / stats.tasks.total) * 100 || 0}%`,
                      bgcolor: '#4caf50',
                      borderRadius: 4
                    }} 
                  />
                  <Box 
                    sx={{ 
                      position: 'absolute', 
                      left: `${(stats.tasks.completed / stats.tasks.total) * 100 || 0}%`, 
                      top: 0, 
                      height: '100%', 
                      width: `${(stats.tasks.inProgress / stats.tasks.total) * 100 || 0}%`,
                      bgcolor: '#ff9800',
                      borderRadius: 4
                    }} 
                  />
                  <Box 
                    sx={{ 
                      position: 'absolute', 
                      left: `${((stats.tasks.completed + stats.tasks.inProgress) / stats.tasks.total) * 100 || 0}%`, 
                      top: 0, 
                      height: '100%', 
                      width: `${(stats.tasks.failed / stats.tasks.total) * 100 || 0}%`,
                      bgcolor: '#f44336',
                      borderRadius: 4
                    }} 
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">
                    {stats.tasks.completed} terminées
                  </Typography>
                  <Typography variant="caption">
                    {stats.tasks.inProgress} en cours
                  </Typography>
                  <Typography variant="caption">
                    {stats.tasks.failed} échouées
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  variant="text" 
                  sx={{ color: 'white' }}
                  onClick={() => setActiveTab('tasks')}
                  endIcon={<ChevronRight size={16} />}
                >
                  Détails
                </Button>
              </CardActions>
            </Card>
          </Grid>
          
          {/* Ligne du milieu - Graphiques d'aperçu */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardHeader 
                title="Relations entre entités" 
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <IconButton onClick={toggleNetworkView}>
                    <Network size={20} />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ height: 300 }}>
                {networkView.active ? renderRelationshipGraph() : (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center', 
                      height: '100%', 
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <Network size={48} color="#9e9e9e" />
                    <Typography variant="body1" color="textSecondary">
                      Cliquez sur l'icône pour activer la vue du réseau de relations
                    </Typography>
                    <Button 
                      variant="outlined" 
                      color="primary" 
                      startIcon={<Network />}
                      onClick={toggleNetworkView}
                    >
                      Activer la vue réseau
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardHeader 
                title="Métriques système en temps réel" 
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <IconButton onClick={updatePerformanceStats}>
                    <RefreshCw size={20} />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ height: 300 }}>
                <Grid container spacing={2} height="100%">
                  {/* CPU Usage Chart */}
                  <Grid item xs={6} height="50%">
                    <Typography variant="subtitle2" gutterBottom>
                      Utilisation CPU
                    </Typography>
                    <ResponsiveContainer width="100%" height="80%">
                      <RLineChart data={performanceStats.cpuUsage.slice(-12)}>
                        <XAxis dataKey="time" hide />
                        <YAxis type="number" domain={[0, 100]} hide />
                        <Line 
                          type="monotone" 
                          dataKey="value" 
                          stroke={COLORS.primary} 
                          strokeWidth={2} 
                          dot={false}
                        />
                        <RTooltip 
                          formatter={(value) => [`${value.toFixed(1)}%`, 'CPU']}
                          labelFormatter={() => 'Utilisation CPU'}
                        />
                      </RLineChart>
                    </ResponsiveContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Gauge size={16} color="#666" />
                      <Typography variant="h6" sx={{ ml: 1 }}>
                        {performanceStats.cpuUsage[performanceStats.cpuUsage.length - 1]?.value.toFixed(1)}%
                      </Typography>
                    </Box>
                  </Grid>
                  
                  {/* Memory Usage Chart */}
                  <Grid item xs={6} height="50%">
                    <Typography variant="subtitle2" gutterBottom>
                      Utilisation mémoire
                    </Typography>
                    <ResponsiveContainer width="100%" height="80%">
                      <AreaChart data={performanceStats.memoryUsage.slice(-12)}>
                        <XAxis dataKey="time" hide />
                        <YAxis type="number" domain={[0, 100]} hide />
                        <Area 
                          type="monotone" 
                          dataKey="value" 
                          stroke={COLORS.secondary} 
                          fill={COLORS.secondary + '40'} 
                          strokeWidth={2} 
                          dot={false}
                        />
                        <RTooltip 
                          formatter={(value) => [`${value.toFixed(1)}%`, 'Mémoire']}
                          labelFormatter={() => 'Utilisation mémoire'}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Database size={16} color="#666" />
                      <Typography variant="h6" sx={{ ml: 1 }}>
                        {performanceStats.memoryUsage[performanceStats.memoryUsage.length - 1]?.value.toFixed(1)}%
                      </Typography>
                    </Box>
                  </Grid>
                  
                  {/* Network Traffic Chart */}
                  <Grid item xs={6} height="50%">
                    <Typography variant="subtitle2" gutterBottom>
                      Trafic réseau
                    </Typography>
                    <ResponsiveContainer width="100%" height="80%">
                      <RBarChart data={performanceStats.networkTraffic.slice(-6)}>
                        <XAxis dataKey="time" hide />
                        <YAxis type="number" domain={[0, 100]} hide />
                        <Bar 
                          dataKey="value" 
                          fill={COLORS.info} 
                        />
                        <RTooltip 
                          formatter={(value) => [`${value.toFixed(1)} Mbps`, 'Trafic']}
                          labelFormatter={() => 'Trafic réseau'}
                        />
                      </RBarChart>
                    </ResponsiveContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Network size={16} color="#666" />
                      <Typography variant="h6" sx={{ ml: 1 }}>
                        {performanceStats.networkTraffic[performanceStats.networkTraffic.length - 1]?.value.toFixed(1)} Mbps
                      </Typography>
                    </Box>
                  </Grid>
                  
                  {/* Response Time Chart */}
                  <Grid item xs={6} height="50%">
                    <Typography variant="subtitle2" gutterBottom>
                      Temps de réponse
                    </Typography>
                    <ResponsiveContainer width="100%" height="80%">
                      <RLineChart data={performanceStats.responseTime.slice(-12)}>
                        <XAxis dataKey="time" hide />
                        <YAxis type="number" domain={[0, 500]} hide />
                        <Line 
                          type="monotone" 
                          dataKey="value" 
                          stroke={COLORS.warning} 
                          strokeWidth={2} 
                          dot={false}
                        />
                        <RTooltip 
                          formatter={(value) => [`${value.toFixed(0)} ms`, 'Réponse']}
                          labelFormatter={() => 'Temps de réponse'}
                        />
                      </RLineChart>
                    </ResponsiveContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Clock size={16} color="#666" />
                      <Typography variant="h6" sx={{ ml: 1 }}>
                        {performanceStats.responseTime[performanceStats.responseTime.length - 1]?.value.toFixed(0)} ms
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Ligne inférieure - Graphiques et alertes */}
          <Grid item xs={12} md={3}>
            <Card sx={{ height: '350px' }}>
              <CardHeader 
                title="Distribution des environnements" 
                titleTypographyProps={{ variant: 'h6' }}
                subheaderTypographyProps={{ variant: 'caption' }}
                subheader="Par type d'application"
              />
              <Divider />
              <CardContent sx={{ height: 270 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <RPieChart>
                    <Pie
                      data={chartData.envByType}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {chartData.envByType.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS.chartColors[index % COLORS.chartColors.length]} />
                      ))}
                    </Pie>
                    <RTooltip />
                  </RPieChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ height: '350px' }}>
              <CardHeader 
                title="Types d'agents" 
                titleTypographyProps={{ variant: 'h6' }}
                subheaderTypographyProps={{ variant: 'caption' }}
                subheader="Distribution des agents par type"
              />
              <Divider />
              <CardContent sx={{ height: 270 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <RBarChart
                    data={chartData.agentTypes}
                    layout="vertical"
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" width={100} />
                    <RTooltip />
                    <Bar dataKey="value" fill={COLORS.agent} />
                  </RBarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ height: '350px' }}>
              <CardHeader 
                title="Statuts des tâches" 
                titleTypographyProps={{ variant: 'h6' }}
                subheaderTypographyProps={{ variant: 'caption' }}
                subheader="Distribution des tâches par statut"
              />
              <Divider />
              <CardContent sx={{ height: 270 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <RadarChart cx="50%" cy="50%" outerRadius={80} data={chartData.taskStatus}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="name" />
                    <PolarRadiusAxis angle={30} domain={[0, Math.max(...chartData.taskStatus.map(item => item.value)) + 5]} />
                    <Radar name="Tâches" dataKey="value" stroke={COLORS.task} fill={COLORS.task} fillOpacity={0.6} />
                    <Legend />
                    <RTooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ height: '350px' }}>
              <CardHeader 
                title="Alertes système" 
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <IconButton>
                    <BellRing size={18} />
                  </IconButton>
                }
              />
              <Divider />
              <List sx={{ height: 270, overflow: 'auto' }}>
                {systemAlerts.length === 0 ? (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    height: '100%',
                    p: 2
                  }}>
                    <BellRing size={32} color="#9e9e9e" />
                    <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
                      Aucune alerte système pour le moment
                    </Typography>
                  </Box>
                ) : (
                  systemAlerts.map(alert => (
                    <React.Fragment key={alert.id}>
                      <ListItem>
                        <ListItemIcon>
                          {alert.type === 'info' && <Info color={COLORS.info} />}
                          {alert.type === 'success' && <CheckCircle color={COLORS.success} />}
                          {alert.type === 'warning' && <AlertTriangle color={COLORS.warning} />}
                          {alert.type === 'error' && <XCircle color={COLORS.error} />}
                        </ListItemIcon>
                        <ListItemText 
                          primary={alert.message}
                          secondary={
                            <DateFormatter date={alert.time} format="relative" />
                          }
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))
                )}
              </List>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Rendu de la liste des environnements
  const renderEnvironmentsList = () => {
    if (loading.environments) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }
    
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Environnements ({filteredEnvironments.length})
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                placeholder="Rechercher un environnement..."
                value={filters.environment.search}
                onChange={(e) => handleFilterChange('environment', 'search', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Type d'application</InputLabel>
                <Select
                  value={filters.environment.type}
                  onChange={(e) => handleFilterChange('environment', 'type', e.target.value)}
                  label="Type d'application"
                >
                  <MenuItem value="all">Tous les types</MenuItem>
                  {Array.from(new Set(environments.map(env => env.appType))).map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Statut</InputLabel>
                <Select
                  value={filters.environment.status}
                  onChange={(e) => handleFilterChange('environment', 'status', e.target.value)}
                  label="Statut"
                >
                  <MenuItem value="all">Tous les statuts</MenuItem>
                  <MenuItem value="active">Actifs</MenuItem>
                  <MenuItem value="inactive">Inactifs</MenuItem>
                  <MenuItem value="withAgent">Avec agent</MenuItem>
                  <MenuItem value="withoutAgent">Sans agent</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={2}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, environmentView: 'list' }))}>
                  <ListIcon color={viewOptions.environmentView === 'list' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, environmentView: 'grid' }))}>
                  <GridIcon color={viewOptions.environmentView === 'grid' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={fetchEnvironments}>
                  <RefreshCw size={20} />
                </IconButton>
                <IconButton color="primary" onClick={() => handleOpenDialog('create', 'environment')}>
                  <Plus size={20} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        
        {viewOptions.environmentView === 'grid' ? (
          <Grid container spacing={2}>
            {filteredEnvironments.map(env => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={env.id}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6
                    },
                    boxShadow: 2,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'visible'
                  }}
                  onClick={() => handleOpenDetail('environment', env.id)}
                >
                  {env.isAgentDeployed && (
                    <Box 
                      sx={{ 
                        position: 'absolute', 
                        top: -8, 
                        right: -8, 
                        zIndex: 1, 
                        bgcolor: COLORS.success,
                        borderRadius: '50%',
                        width: 24,
                        height: 24,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Tooltip title="Agent déployé">
                        <Zap size={14} color="white" />
                      </Tooltip>
                    </Box>
                  )}
                  <CardHeader
                    title={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Tooltip title={env.appName}>
                          <Typography variant="h6" noWrap>
                            {env.appName}
                          </Typography>
                        </Tooltip>
                        <Chip
                          size="small"
                          label={env.isActive ? "Actif" : "Inactif"}
                          color={env.isActive ? "success" : "default"}
                        />
                      </Box>
                    }
                    subheader={env.appVersion}
                    avatar={
                      <Avatar sx={{ bgcolor: COLORS.environment }}>
                        <Layers size={20} />
                      </Avatar>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <Box sx={{ mb: 2 }}>
                      <Chip 
                        size="small" 
                        label={env.appType} 
                        color="primary" 
                        variant="outlined"
                        sx={{ mr: 1 }}
                      />
                      {env.agent && (
                        <Chip 
                          size="small" 
                          icon={<Server size={14} />}
                          label={env.agent.agentType}
                          color="secondary"
                        />
                      )}
                    </Box>
                    
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {env.functions ? `${env.functions.length} fonctions associées` : 'Aucune fonction associée'}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                      <Typography variant="caption" color="text.secondary">
                        ID: {env.id}
                      </Typography>
                      <DateFormatter date={env.createdDate} format="relative" variant="caption" />
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      startIcon={<Edit2 size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog('edit', 'environment', env);
                      }}
                    >
                      Modifier
                    </Button>
                    <Button 
                      size="small" 
                      color="error" 
                      startIcon={<Trash2 size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog('delete', 'environment', env);
                      }}
                    >
                      Supprimer
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Agent</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Créé le</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEnvironments.map(env => (
                  <TableRow 
                    key={env.id}
                    hover
                    onClick={() => handleOpenDetail('environment', env.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{env.id}</TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight="bold">
                        {env.appName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip size="small" label={env.appType} />
                    </TableCell>
                    <TableCell>{env.appVersion}</TableCell>
                    <TableCell>
                      {env.agent ? (
                        <Chip 
                          size="small" 
                          icon={<Server size={14} />}
                          label={env.agent.agentType}
                          color="secondary"
                        />
                      ) : (
                        <Chip 
                          size="small" 
                          label="Aucun" 
                          variant="outlined" 
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        label={env.isActive ? "Actif" : "Inactif"} 
                        color={env.isActive ? "success" : "default"}
                        icon={env.isActive ? <CheckCircle size={14} /> : <XCircle size={14} />}
                      />
                      {env.isAgentDeployed && (
                        <Chip 
                          size="small" 
                          label="Déployé" 
                          color="info"
                          icon={<Zap size={14} />}
                          sx={{ ml: 1 }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <DateFormatter date={env.createdDate} format="relative" />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog('edit', 'environment', env);
                        }}
                      >
                        <Edit2 size={18} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog('delete', 'environment', env);
                        }}
                      >
                        <Trash2 size={18} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    );
  };
  
  // Rendu de la liste des agents
  const renderAgentsList = () => {
    if (loading.agents) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }
    
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Agents ({filteredAgents.length})
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                placeholder="Rechercher un agent..."
                value={filters.agent.search}
                onChange={(e) => handleFilterChange('agent', 'search', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Type d'agent</InputLabel>
                <Select
                  value={filters.agent.type}
                  onChange={(e) => handleFilterChange('agent', 'type', e.target.value)}
                  label="Type d'agent"
                >
                  <MenuItem value="all">Tous les types</MenuItem>
                  {Array.from(new Set(agents.map(agent => agent.agentType))).map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Statut</InputLabel>
                <Select
                  value={filters.agent.status}
                  onChange={(e) => handleFilterChange('agent', 'status', e.target.value)}
                  label="Statut"
                >
                  <MenuItem value="all">Tous les statuts</MenuItem>
                  <MenuItem value="active">Actifs</MenuItem>
                  <MenuItem value="inactive">Inactifs</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={2}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, agentView: 'list' }))}>
                  <ListIcon color={viewOptions.agentView === 'list' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, agentView: 'grid' }))}>
                  <GridIcon color={viewOptions.agentView === 'grid' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={fetchAgents}>
                  <RefreshCw size={20} />
                </IconButton>
                <IconButton color="primary" onClick={() => handleOpenDialog('create', 'agent')}>
                  <Plus size={20} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        
        {viewOptions.agentView === 'grid' ? (
          <Grid container spacing={2}>
            {filteredAgents.map(agent => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={agent.id}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6
                    },
                    boxShadow: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => handleOpenDetail('agent', agent.id)}
                >
                  <CardHeader
                    title={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6" noWrap>
                          Agent {agent.id}
                        </Typography>
                        <Chip
                          size="small"
                          label={agent.isActive ? "Actif" : "Inactif"}
                          color={agent.isActive ? "success" : "default"}
                        />
                      </Box>
                    }
                    subheader={agent.agentType}
                    avatar={
                      <Avatar sx={{ bgcolor: COLORS.agent }}>
                        <Server size={20} />
                      </Avatar>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="caption" color="text.secondary">
                          Adresse IP
                        </Typography>
                        <Typography variant="body2">
                          {agent.ipAddress}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="caption" color="text.secondary">
                          Port
                        </Typography>
                        <Typography variant="body2">
                          {agent.port}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" color="text.secondary">
                          Chemin d'installation
                        </Typography>
                        <Typography variant="body2" noWrap>
                          {agent.installationPath || 'Non spécifié'}
                        </Typography>
                      </Grid>
                    </Grid>
                    
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="caption" color="text.secondary">
                        Environnement associé
                      </Typography>
                      <Typography variant="body2">
                        {environments.find(env => env.agent && env.agent.id === agent.id)?.appName || 'Aucun'}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      startIcon={<Edit2 size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog('edit', 'agent', agent);
                      }}
                    >
                      Modifier
                    </Button>
                    <Button 
                      size="small" 
                      color="secondary" 
                      startIcon={<Cable size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        // Code pour tester la connectivité
                        showNotification('Test de connectivité en cours...', 'info');
                        setTimeout(() => {
                          showNotification('Agent connecté avec succès', 'success');
                        }, 1500);
                      }}
                    >
                      Tester
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Adresse IP</TableCell>
                  <TableCell>Port</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Environnement</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAgents.map(agent => (
                  <TableRow 
                    key={agent.id}
                    hover
                    onClick={() => handleOpenDetail('agent', agent.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{agent.id}</TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        icon={<Server size={14} />}
                        label={agent.agentType}
                        color="secondary"
                      />
                    </TableCell>
                    <TableCell>{agent.ipAddress}</TableCell>
                    <TableCell>{agent.port}</TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        label={agent.isActive ? "Actif" : "Inactif"} 
                        color={agent.isActive ? "success" : "default"}
                        icon={agent.isActive ? <CheckCircle size={14} /> : <XCircle size={14} />}
                      />
                    </TableCell>
                    <TableCell>
                      {environments.find(env => env.agent && env.agent.id === agent.id)?.appName || '-'}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog('edit', 'agent', agent);
                        }}
                      >
                        <Edit2 size={18} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // Code pour tester la connectivité
                          showNotification('Test de connectivité en cours...', 'info');
                          setTimeout(() => {
                            showNotification('Agent connecté avec succès', 'success');
                          }, 1500);
                        }}
                      >
                        <Cable size={18} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    );
  };
  
  // Rendu de la liste des fonctions
  const renderFunctionsList = () => {
    if (loading.functions) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }
    
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Fonctions ({filteredFunctions.length})
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                placeholder="Rechercher une fonction..."
                value={filters.function.search}
                onChange={(e) => handleFilterChange('function', 'search', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Domaine</InputLabel>
                <Select
                  value={filters.function.domain}
                  onChange={(e) => handleFilterChange('function', 'domain', e.target.value)}
                  label="Domaine"
                >
                  <MenuItem value="all">Tous les domaines</MenuItem>
                  {Array.from(new Set(functions.map(func => func.functionDomain))).map(domain => (
                    <MenuItem key={domain} value={domain}>{domain}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  value={filters.function.type}
                  onChange={(e) => handleFilterChange('function', 'type', e.target.value)}
                  label="Type"
                >
                  <MenuItem value="all">Tous les types</MenuItem>
                  {Array.from(new Set(functions.map(func => func.functionType))).map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={2}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, functionView: 'list' }))}>
                  <ListIcon color={viewOptions.functionView === 'list' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, functionView: 'grid' }))}>
                  <GridIcon color={viewOptions.functionView === 'grid' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={fetchFunctions}>
                  <RefreshCw size={20} />
                </IconButton>
                <IconButton color="primary" onClick={() => handleOpenDialog('create', 'function')}>
                  <Plus size={20} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        
        {viewOptions.functionView === 'grid' ? (
          <Grid container spacing={2}>
            {filteredFunctions.map(func => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={func.id}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6
                    },
                    boxShadow: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => handleOpenDetail('function', func.id)}
                >
                  <CardHeader
                    title={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Tooltip title={func.functionName}>
                          <Typography variant="h6" noWrap>
                            {func.functionName}
                          </Typography>
                        </Tooltip>
                        <Chip
                          size="small"
                          label={func.isActive ? "Active" : "Inactive"}
                          color={func.isActive ? "success" : "default"}
                        />
                      </Box>
                    }
                    subheader={func.functionType}
                    avatar={
                      <Avatar sx={{ bgcolor: COLORS.function }}>
                        <Code size={20} />
                      </Avatar>
                    }
                  />
                  <Divider />
                  <CardContent>
                    {func.functionDescription && (
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {func.functionDescription.length > 100 
                          ? func.functionDescription.substring(0, 100) + '...' 
                          : func.functionDescription}
                      </Typography>
                    )}
                    
                    <Box sx={{ mb: 2 }}>
                      <Chip 
                        size="small" 
                        label={func.functionDomain} 
                        color="info" 
                        variant="outlined"
                        sx={{ mr: 1 }}
                      />
                      {func.usedByClient && (
                        <Chip 
                          size="small" 
                          label="Utilisée par client" 
                          color="warning"
                        />
                      )}
                    </Box>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                      <Typography variant="caption" color="text.secondary">
                        {func.lastExecutionDate ? (
                          <>Dernière exécution: <DateFormatter date={func.lastExecutionDate} format="relative" /></>
                        ) : 'Jamais exécutée'}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      startIcon={<Edit2 size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog('edit', 'function', func);
                      }}
                    >
                      Modifier
                    </Button>
                    <Button 
                      size="small" 
                      color="error" 
                      startIcon={<Trash2 size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog('delete', 'function', func);
                      }}
                    >
                      Supprimer
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Domaine</TableCell>
                  <TableCell>Module</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Dernière exécution</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFunctions.map(func => (
                  <TableRow 
                    key={func.id}
                    hover
                    onClick={() => handleOpenDetail('function', func.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{func.id}</TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight="bold">
                        {func.functionName}
                      </Typography>
                      {func.functionDescription && (
                        <Typography variant="caption" color="text.secondary" noWrap sx={{ display: 'block', maxWidth: 200 }}>
                          {func.functionDescription}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>{func.functionType}</TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        label={func.functionDomain} 
                        color="info" 
                      />
                    </TableCell>
                    <TableCell>{func.functionModule || '-'}</TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        label={func.isActive ? "Active" : "Inactive"} 
                        color={func.isActive ? "success" : "default"}
                        icon={func.isActive ? <CheckCircle size={14} /> : <XCircle size={14} />}
                      />
                    </TableCell>
                    <TableCell>
                      {func.lastExecutionDate ? (
                        <DateFormatter date={func.lastExecutionDate} format="relative" />
                      ) : 'Jamais'}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog('edit', 'function', func);
                        }}
                      >
                        <Edit2 size={18} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog('delete', 'function', func);
                        }}
                      >
                        <Trash2 size={18} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    );
  };
  
  // Rendu de la liste des tâches
  const renderTasksList = () => {
    if (loading.tasks) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }
    
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Tâches ({filteredTasks.length})
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                placeholder="Rechercher une tâche..."
                value={filters.task.search}
                onChange={(e) => handleFilterChange('task', 'search', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Statut</InputLabel>
                <Select
                  value={filters.task.status}
                  onChange={(e) => handleFilterChange('task', 'status', e.target.value)}
                  label="Statut"
                >
                  <MenuItem value="all">Tous les statuts</MenuItem>
                  <MenuItem value="PENDING">En attente</MenuItem>
                  <MenuItem value="IN_PROGRESS">En cours</MenuItem>
                  <MenuItem value="COMPLETED">Terminée</MenuItem>
                  <MenuItem value="FAILURE">Échec</MenuItem>
                  <MenuItem value="BLOCKED">Bloquée</MenuItem>
                  <MenuItem value="STOPPED">Arrêtée</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  value={filters.task.type}
                  onChange={(e) => handleFilterChange('task', 'type', e.target.value)}
                  label="Type"
                >
                  <MenuItem value="all">Tous les types</MenuItem>
                  <MenuItem value="ACCEPTABILITY">Acceptabilité</MenuItem>
                  <MenuItem value="CORRECTIVE">Corrective</MenuItem>
                  <MenuItem value="EVOLUTION">Évolution</MenuItem>
                  <MenuItem value="NON_REGRESSION">Non-régression</MenuItem>
                  <MenuItem value="END_TO_END">Bout en bout</MenuItem>
                  <MenuItem value="PARTNER">Partenaire</MenuItem>
                  <MenuItem value="UNDEFINED">Non défini</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={2}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, taskView: 'list' }))}>
                  <ListIcon color={viewOptions.taskView === 'list' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={() => setViewOptions(prev => ({ ...prev, taskView: 'grid' }))}>
                  <GridIcon color={viewOptions.taskView === 'grid' ? COLORS.primary : 'inherit'} />
                </IconButton>
                <IconButton onClick={fetchTasks}>
                  <RefreshCw size={20} />
                </IconButton>
                <IconButton color="primary" onClick={() => handleOpenDialog('create', 'task')}>
                  <Plus size={20} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        
        {viewOptions.taskView === 'grid' ? (
          <Grid container spacing={2}>
            {filteredTasks.map(task => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={task.id}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6
                    },
                    boxShadow: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => handleOpenDetail('task', task.id)}
                >
                  <CardHeader
                    title={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Tooltip title={task.taskName}>
                          <Typography variant="h6" noWrap>
                            {task.taskName}
                          </Typography>
                        </Tooltip><Chip
                          size="small"
                          label={task.executionStatus || 'PENDING'}
                          color={getTaskStatusColor(task.executionStatus)}
                        />
                      </Box>
                    }
                    subheader={task.taskType || 'UNDEFINED'}
                    avatar={
                      <Avatar sx={{ bgcolor: COLORS.task }}>
                        <Briefcase size={20} />
                      </Avatar>
                    }
                  />
                  <Divider />
                  <CardContent>
                    {task.taskDescription && (
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {task.taskDescription.length > 100 
                          ? task.taskDescription.substring(0, 100) + '...' 
                          : task.taskDescription}
                      </Typography>
                    )}
                    
                    <Box sx={{ mb: 2 }}>
                      {task.function && (
                        <Chip 
                          size="small" 
                          icon={<Code size={14} />}
                          label={task.function.functionName}
                          color="info" 
                          sx={{ mr: 1 }}
                        />
                      )}
                      {task.taskNature && (
                        <Chip 
                          size="small" 
                          label={task.taskNature}
                          variant="outlined"
                        />
                      )}
                    </Box>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                      <Typography variant="caption" color="text.secondary">
                        {task.interactions ? `${task.interactions.length} interactions` : 'Aucune interaction'}
                      </Typography>
                      {task.startExecutionTimestamp && (
                        <Typography variant="caption" color="text.secondary">
                          Exécutée <DateFormatter date={task.startExecutionTimestamp} format="relative" />
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      startIcon={<Edit2 size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog('edit', 'task', task);
                      }}
                    >
                      Modifier
                    </Button>
                    <Button 
                      size="small" 
                      color="primary" 
                      startIcon={<Play size={14} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        // Logique pour exécuter la tâche
                        showNotification(`Exécution de la tâche ${task.taskName} en cours...`, 'info');
                      }}
                    >
                      Exécuter
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Fonction</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Créé le</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTasks.map(task => (
                  <TableRow 
                    key={task.id}
                    hover
                    onClick={() => handleOpenDetail('task', task.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{task.id}</TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight="bold">
                        {task.taskName}
                      </Typography>
                      {task.taskDescription && (
                        <Typography variant="caption" color="text.secondary" noWrap sx={{ display: 'block', maxWidth: 200 }}>
                          {task.taskDescription}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip size="small" label={task.taskType || 'UNDEFINED'} />
                    </TableCell>
                    <TableCell>
                      {task.function ? (
                        <Chip 
                          size="small" 
                          icon={<Code size={14} />}
                          label={task.function.functionName}
                          color="info"
                        />
                      ) : (
                        <Chip 
                          size="small" 
                          label="Aucune" 
                          variant="outlined" 
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        label={task.executionStatus || 'PENDING'} 
                        color={getTaskStatusColor(task.executionStatus)}
                      />
                    </TableCell>
                    <TableCell>
                      <DateFormatter date={task.createdDate} format="relative" />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog('edit', 'task', task);
                        }}
                      >
                        <Edit2 size={18} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // Logique pour exécuter la tâche
                          showNotification(`Exécution de la tâche ${task.taskName} en cours...`, 'info');
                        }}
                      >
                        <Play size={18} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    );
  };

  // Rendu du détail d'une entité
  const renderDetailView = () => {
    const { type, data } = detailView;
    
    if (!data) return null;

    switch (type) {
      case 'environment':
        return (
          <Dialog
            open={detailView.open}
            onClose={handleCloseDetail}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: COLORS.environment, mr: 2 }}>
                    <Layers size={20} />
                  </Avatar>
                  <Typography variant="h6">
                    {data.appName}
                  </Typography>
                </Box>
                <Chip
                  size="small"
                  label={data.isActive ? "Actif" : "Inactif"}
                  color={data.isActive ? "success" : "default"}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Informations générales</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">ID</Typography>
                        <Typography variant="body2">{data.id}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Type</Typography>
                        <Typography variant="body2">{data.appType}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Version</Typography>
                        <Typography variant="body2">{data.appVersion}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Créé le</Typography>
                        <Typography variant="body2">
                          <DateFormatter date={data.createdDate} format="full" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, height: '100%' }}>
                    <Typography variant="subtitle1" gutterBottom>Agent</Typography>
                    {data.agent ? (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Avatar sx={{ bgcolor: COLORS.agent, mr: 2 }}>
                            <Server size={16} />
                          </Avatar>
                          <Typography variant="body2">
                            Agent {data.agent.id} ({data.agent.agentType})
                          </Typography>
                          <Chip 
                            size="small" 
                            label={data.agent.isActive ? "Actif" : "Inactif"} 
                            color={data.agent.isActive ? "success" : "default"}
                            sx={{ ml: 1 }}
                          />
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">Adresse IP</Typography>
                            <Typography variant="body2">{data.agent.ipAddress}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">Port</Typography>
                            <Typography variant="body2">{data.agent.port}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption" color="text.secondary">Chemin d'installation</Typography>
                            <Typography variant="body2">{data.agent.installationPath || 'Non spécifié'}</Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 150 }}>
                        <CloudOff size={32} color="#9e9e9e" />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Aucun agent associé
                        </Typography>
                        <Button 
                          variant="outlined" 
                          size="small" 
                          startIcon={<Plus />}
                          sx={{ mt: 2 }}
                          onClick={() => handleOpenDialog('assignAgent', 'environment', data)}
                        >
                          Assigner un agent
                        </Button>
                      </Box>
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, height: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle1">Fonctions ({data.functions?.length || 0})</Typography>
                      <Button 
                        size="small" 
                        startIcon={<Plus size={14} />}
                        onClick={() => handleOpenDialog('addFunction', 'environment', data)}
                      >
                        Ajouter
                      </Button>
                    </Box>
                    {data.functions && data.functions.length > 0 ? (
                      <List sx={{ maxHeight: 200, overflow: 'auto' }}>
                        {data.functions.map(func => (
                          <ListItem
                            key={func.id}
                            secondaryAction={
                              <IconButton edge="end" size="small">
                                <MoreVertical size={16} />
                              </IconButton>
                            }
                            disablePadding
                          >
                            <ListItemButton dense onClick={() => handleOpenDetail('function', func.id)}>
                              <ListItemIcon sx={{ minWidth: 36 }}>
                                <Code size={16} color={COLORS.function} />
                              </ListItemIcon>
                              <ListItemText 
                                primary={func.functionName} 
                                secondary={func.functionType}
                                primaryTypographyProps={{ variant: 'body2' }}
                                secondaryTypographyProps={{ variant: 'caption' }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 150 }}>
                        <Code size={32} color="#9e9e9e" />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Aucune fonction associée
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDetail}>
                Fermer
              </Button>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => {
                  handleCloseDetail();
                  handleOpenDialog('edit', 'environment', data);
                }}
              >
                Modifier
              </Button>
            </DialogActions>
          </Dialog>
        );
      
      case 'agent':
        return (
          <Dialog
            open={detailView.open}
            onClose={handleCloseDetail}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: COLORS.agent, mr: 2 }}>
                    <Server size={20} />
                  </Avatar>
                  <Typography variant="h6">
                    Agent {data.id}
                  </Typography>
                </Box>
                <Chip
                  size="small"
                  label={data.isActive ? "Actif" : "Inactif"}
                  color={data.isActive ? "success" : "default"}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Informations générales</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">ID</Typography>
                        <Typography variant="body2">{data.id}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Type</Typography>
                        <Typography variant="body2">{data.agentType}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Adresse IP</Typography>
                        <Typography variant="body2">{data.ipAddress}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Port</Typography>
                        <Typography variant="body2">{data.port}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" color="text.secondary">Chemin d'installation</Typography>
                        <Typography variant="body2">{data.installationPath || 'Non spécifié'}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Environnement associé</Typography>
                    {data.environment ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: COLORS.environment, mr: 2 }}>
                          <Layers size={16} />
                        </Avatar>
                        <Box>
                          <Typography variant="body2">
                            {data.environment.appName} ({data.environment.appType})
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            Version: {data.environment.appVersion}
                          </Typography>
                        </Box>
                        <Chip 
                          size="small" 
                          label={data.environment.isActive ? "Actif" : "Inactif"} 
                          color={data.environment.isActive ? "success" : "default"}
                          sx={{ ml: 2 }}
                        />
                        <Button 
                          variant="outlined" 
                          size="small" 
                          sx={{ ml: 2 }}
                          onClick={() => handleOpenDetail('environment', data.environment.id)}
                        >
                          Voir
                        </Button>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 100 }}>
                        <LayersSolid size={32} color="#9e9e9e" />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Aucun environnement associé
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Statut de connectivité</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                      <Button 
                        variant="contained" 
                        color="secondary" 
                        startIcon={<Cable />}
                        onClick={() => showNotification('Test de connectivité réussi', 'success')}
                      >
                        Tester la connectivité
                      </Button>
                      <Button 
                        variant="outlined" 
                        startIcon={<Terminal />}
                        sx={{ ml: 2 }}
                      >
                        Console
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDetail}>
                Fermer
              </Button>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => {
                  handleCloseDetail();
                  handleOpenDialog('edit', 'agent', data);
                }}
              >
                Modifier
              </Button>
            </DialogActions>
          </Dialog>
        );

      case 'function':
        return (
          <Dialog
            open={detailView.open}
            onClose={handleCloseDetail}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: COLORS.function, mr: 2 }}>
                    <Code size={20} />
                  </Avatar>
                  <Typography variant="h6">
                    {data.functionName}
                  </Typography>
                </Box>
                <Chip
                  size="small"
                  label={data.isActive ? "Active" : "Inactive"}
                  color={data.isActive ? "success" : "default"}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Informations générales</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">ID</Typography>
                        <Typography variant="body2">{data.id}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Type</Typography>
                        <Typography variant="body2">{data.functionType}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Domaine</Typography>
                        <Typography variant="body2">{data.functionDomain}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Module</Typography>
                        <Typography variant="body2">{data.functionModule || 'Non spécifié'}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Description</Typography>
                    <Typography variant="body2">
                      {data.functionDescription || 'Aucune description disponible'}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, height: '100%' }}>
                    <Typography variant="subtitle1" gutterBottom>Programme</Typography>
                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, maxHeight: 150, overflow: 'auto' }}>
                      <pre style={{ margin: 0, fontFamily: 'monospace', fontSize: '0.875rem' }}>
                        {data.functionProgram || 'Aucun code défini'}
                      </pre>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, height: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle1">Tâches associées</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {data.tasks?.length || 0} tâches
                      </Typography>
                    </Box>
                    {data.tasks && data.tasks.length > 0 ? (
                      <List sx={{ maxHeight: 150, overflow: 'auto' }}>
                        {data.tasks.map(task => (
                          <ListItem
                            key={task.id}
                            disablePadding
                            secondaryAction={
                              <Chip 
                                size="small" 
                                label={task.executionStatus || 'PENDING'} 
                                color={getTaskStatusColor(task.executionStatus)}
                              />
                            }
                          >
                            <ListItemButton dense onClick={() => handleOpenDetail('task', task.id)}>
                              <ListItemIcon sx={{ minWidth: 36 }}>
                                <Briefcase size={16} color={COLORS.task} />
                              </ListItemIcon>
                              <ListItemText 
                                primary={task.taskName} 
                                primaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 100 }}>
                        <Briefcase size={32} color="#9e9e9e" />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Aucune tâche associée
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Métadonnées</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Dernière exécution</Typography>
                        <Typography variant="body2">
                          {data.lastExecutionDate ? (
                            <DateFormatter date={data.lastExecutionDate} format="full" />
                          ) : 'Jamais'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Dernière mise à jour</Typography>
                        <Typography variant="body2">
                          {data.lastUpdateDate ? (
                            <DateFormatter date={data.lastUpdateDate} format="full" />
                          ) : 'Jamais'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Consommation de ressources</Typography>
                        <Typography variant="body2">{data.resourceConsumption || 'Non défini'}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">MD5</Typography>
                        <Typography variant="body2">{data.md5Key || 'Non défini'}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDetail}>
                Fermer
              </Button>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => {
                  handleCloseDetail();
                  handleOpenDialog('edit', 'function', data);
                }}
              >
                Modifier
              </Button>
            </DialogActions>
          </Dialog>
        );
      
      case 'task':
        return (
          <Dialog
            open={detailView.open}
            onClose={handleCloseDetail}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: COLORS.task, mr: 2 }}>
                    <Briefcase size={20} />
                  </Avatar>
                  <Typography variant="h6">
                    {data.taskName}
                  </Typography>
                </Box>
                <Chip
                  size="small"
                  label={data.executionStatus || 'PENDING'}
                  color={getTaskStatusColor(data.executionStatus)}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Informations générales</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">ID</Typography>
                        <Typography variant="body2">{data.id}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Type</Typography>
                        <Typography variant="body2">{data.taskType || 'UNDEFINED'}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Nature</Typography>
                        <Typography variant="body2">{data.taskNature || 'UNDEFINED'}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography variant="caption" color="text.secondary">Origine</Typography>
                        <Typography variant="body2">{data.origin || 'Non spécifiée'}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Description</Typography>
                    <Typography variant="body2">
                      {data.taskDescription || 'Aucune description disponible'}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, height: '100%' }}>
                    <Typography variant="subtitle1" gutterBottom>Scénario</Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                      {data.taskScenario || 'Aucun scénario défini'}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2, height: '100%' }}>
                    <Typography variant="subtitle1" gutterBottom>Résultats</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="caption" color="text.secondary">Résultat attendu</Typography>
                        <Typography variant="body2">{data.expectedResult || 'Non défini'}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" color="text.secondary">Résultat réel</Typography>
                        <Typography variant="body2">{data.actualResult || 'Non disponible'}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle1">Interactions</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {data.interactions?.length || 0} interactions
                      </Typography>
                    </Box>
                    {data.interactions && data.interactions.length > 0 ? (
                      <TableContainer sx={{ maxHeight: 200 }}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Seq.</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Direction</TableCell>
                              <TableCell>Statut</TableCell>
                              <TableCell>Horodatage</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.interactions.map(interaction => (
                              <TableRow key={interaction.id}>
                                <TableCell>{interaction.sequence}</TableCell>
                                <TableCell>{interaction.type}</TableCell>
                                <TableCell>{interaction.direction}</TableCell>
                                <TableCell>
                                  <Chip 
                                    size="small" 
                                    label={interaction.status} 
                                    color={interaction.status === 'COMPLETED' ? 'success' : 'default'}
                                  />
                                </TableCell>
                                <TableCell>
                                  <DateFormatter date={interaction.timestamp} format="short" />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 100 }}>
                        <Activity size={32} color="#9e9e9e" />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Aucune interaction enregistrée
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Fonction associée</Typography>
                    {data.function ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: COLORS.function, mr: 2 }}>
                          <Code size={16} />
                        </Avatar>
                        <Box>
                          <Typography variant="body2">
                            {data.function.functionName}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {data.function.functionType} - {data.function.functionDomain}
                          </Typography>
                        </Box>
                        <Button 
                          variant="outlined" 
                          size="small" 
                          sx={{ ml: 2 }}
                          onClick={() => handleOpenDetail('function', data.function.id)}
                        >
                          Voir
                        </Button>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 100 }}>
                        <Code size={32} color="#9e9e9e" />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Aucune fonction associée
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDetail}>
                Fermer
              </Button>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => {
                  handleCloseDetail();
                  handleOpenDialog('edit', 'task', data);
                }}
              >
                Modifier
              </Button>
              <Button 
                variant="contained" 
                color="success"
                startIcon={<Play />}
                onClick={() => {
                  handleCloseDetail();
                  showNotification(`Exécution de la tâche ${data.taskName} en cours...`, 'info');
                }}
              >
                Exécuter
              </Button>
            </DialogActions>
          </Dialog>
        );
      
      default:
        return null;
    }
  };

  // Rendu des dialogues
  const renderDialogs = () => {
    // Implementations des différents dialogs selon dialogState.type et dialogState.entityType
    // Ici juste un placeholder pour le principe
    return (
      <Dialog
        open={dialogState.open}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {dialogState.type === 'create' && `Créer un nouvel élément: ${dialogState.entityType}`}
          {dialogState.type === 'edit' && `Modifier l'élément: ${dialogState.entityType}`}
          {dialogState.type === 'delete' && `Supprimer l'élément: ${dialogState.entityType}`}
          {dialogState.type === 'assignAgent' && 'Assigner un agent'}
          {dialogState.type === 'addFunction' && 'Ajouter une fonction'}
        </DialogTitle>
        <DialogContent>
          <Typography>Formulaire de {dialogState.type} pour {dialogState.entityType}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            variant="contained" 
            color={dialogState.type === 'delete' ? 'error' : 'primary'}
            onClick={() => {
              handleCloseDialog();
              showNotification('Opération effectuée avec succès!', 'success');
            }}
          >
            {dialogState.type === 'delete' ? 'Supprimer' : 'Enregistrer'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Rendu principal du tableau de bord
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Barre d'outils supérieure */}
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Mission Control
          </Typography>
          <IconButton onClick={handleRefreshAll}>
            <RefreshCw size={20} />
          </IconButton>
          <IconButton>
            <Settings size={20} />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Onglets de navigation */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="dashboard navigation tabs">
          <Tab icon={<Home size={16} />} iconPosition="start" label="Vue d'ensemble" value="overview" />
          <Tab icon={<Layers size={16} />} iconPosition="start" label="Environnements" value="environments" />
          <Tab icon={<Server size={16} />} iconPosition="start" label="Agents" value="agents" />
          <Tab icon={<Code size={16} />} iconPosition="start" label="Fonctions" value="functions" />
          <Tab icon={<Briefcase size={16} />} iconPosition="start" label="Tâches" value="tasks" />
          <Tab icon={<Network size={16} />} iconPosition="start" label="Relations" value="relationships" />
        </Tabs>
      </Box>

      {/* Contenu principal */}
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        {activeTab === 'overview' && renderMissionControl()}
        {activeTab === 'environments' && renderEnvironmentsList()}
        {activeTab === 'agents' && renderAgentsList()}
        {activeTab === 'functions' && renderFunctionsList()}
        {activeTab === 'tasks' && renderTasksList()}
        {activeTab === 'relationships' && (
          <Box sx={{ p: 2, height: 'calc(100vh - 130px)' }}>
            {renderRelationshipGraph()}
          </Box>
        )}
      </Box>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      {/* Modales et dialogues */}
      {renderDetailView()}
      {renderDialogs()}
    </Box>
  );
};

export default MissionControlDashboard;