// components/shared/workflow/ImprovedEdgeModal.js
import React, { useState, useEffect } from 'react';
import { AlertTriangle, X, ArrowRight, Tag, Shield, Zap } from 'lucide-react';
import WorkflowDomainSelector from './WorkflowDomainSelector';
import { workflowDomainManager } from './WorkflowDomainManager';

/**
 * Modal amélioré pour l'ajout et l'édition des transitions
 * Utilise les sélecteurs de domaine pour les événements, rôles et actions
 */
export function ImprovedEdgeModal({ 
  isOpen, 
  edge, 
  type = 'add', // 'add' ou 'edit'
  workflowType = null,
  onClose, 
  onSubmit, 
  onChange,
  sourceNode = null,
  targetNode = null
}) {
  const [validationErrors, setValidationErrors] = useState([]);
  const [availableEvents, setAvailableEvents] = useState([]);
  
  // Réinitialisation des erreurs à l'ouverture
  useEffect(() => {
    if (isOpen) {
      setValidationErrors([]);
    }
  }, [isOpen]);
  
  // Charger les événements disponibles quand les états source/cible changent
  useEffect(() => {
    const loadAvailableEvents = async () => {
      if (sourceNode && targetNode) {
        const sourceStateCode = sourceNode.data?.code || sourceNode.data?.label;
        const targetStateCode = targetNode.data?.code || targetNode.data?.label;
        
        if (sourceStateCode && targetStateCode) {
          const events = await workflowDomainManager.getEventsBetweenStates(
            sourceStateCode, 
            targetStateCode
          );
          setAvailableEvents(events);
        }
      }
    };
    
    if (isOpen && sourceNode && targetNode) {
      loadAvailableEvents();
    }
  }, [isOpen, sourceNode, targetNode]);
  
  if (!isOpen) return null;
  
  // Obtenir le label des nœuds source et cible
  const sourceLabel = sourceNode?.data?.label || 'État source';
  const targetLabel = targetNode?.data?.label || 'État cible';
  
  // Validation du formulaire avant soumission
  const handleSubmit = () => {
    const errors = [];
    
    // Vérifier que l'événement est défini
    if (!edge.label || edge.label.trim() === '') {
      errors.push('L\'événement est obligatoire');
    }
    
    // Si des rôles sont spécifiés, vérifier qu'ils sont valides
    if (edge.data?.roles && edge.data.roles.length > 0) {
      // Des validations spécifiques pourraient être ajoutées ici
    }
    
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }
    
    onSubmit();
  };
  
  // Mettre à jour les données de l'arête
  const updateEdgeData = (key, value) => {
    const newData = { ...(edge.data || {}) };
    newData[key] = value;
    
    onChange({
      ...edge,
      data: newData
    });
  };
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-[500px] max-w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {type === 'add' ? 'Ajouter une transition' : 'Modifier la transition'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
          >
            <X size={20} />
          </button>
        </div>
        
        {/* Afficher les erreurs de validation */}
        {validationErrors.length > 0 && (
          <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800/30 rounded-md">
            <div className="flex">
              <AlertTriangle className="h-5 w-5 text-red-400 dark:text-red-500 mr-2" />
              <div className="text-sm text-red-700 dark:text-red-400">
                <div className="font-medium">Veuillez corriger les erreurs suivantes :</div>
                <ul className="mt-1 list-disc list-inside">
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        
        {/* Affichage des états source et cible */}
        <div className="mb-4 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-md">
          <div className="flex items-center justify-between text-sm">
            <div className="font-medium text-gray-700 dark:text-gray-300">
              {sourceLabel}
            </div>
            <ArrowRight className="h-4 w-4 text-gray-400 mx-2" />
            <div className="font-medium text-gray-700 dark:text-gray-300">
              {targetLabel}
            </div>
          </div>
        </div>
        
        {/* Section des champs de formulaire */}
        <div className="space-y-4">
          {/* Sélection de l'événement */}
          <div>
            <WorkflowDomainSelector
              type="event"
              label="Événement"
              placeholder="Sélectionner un événement..."
              selectedItems={edge.label ? [edge.label] : []}
              onChange={(selected) => onChange({ ...edge, label: selected })}
              multiple={false}
              required={true}
              workflowType={workflowType}
              allowCustom={true}
              availableItems={availableEvents.length > 0 ? availableEvents : undefined}
            />
            <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
              Événement qui déclenche la transition d'un état à l'autre
            </p>
          </div>
          
          {/* Option de transition automatique */}
          <div className="flex items-center">
            <input
              id="automatic-transition"
              type="checkbox"
              checked={edge.data?.isAutomatic || false}
              onChange={(e) => updateEdgeData('isAutomatic', e.target.checked)}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="automatic-transition" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
              Transition automatique
            </label>
            <div className="ml-1">
              <div className="relative group">
                <div className="cursor-help text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                  La transition sera déclenchée automatiquement dès que l'état source est atteint, sans action utilisateur.
                </div>
              </div>
            </div>
          </div>
          
          {/* Sélection des rôles requis */}
          <div>
            <WorkflowDomainSelector
              type="role"
              label="Rôles requis"
              placeholder="Sélectionner les rôles autorisés..."
              selectedItems={edge.data?.roles || []}
              onChange={(selected) => updateEdgeData('roles', selected)}
              multiple={true}
              allowCustom={true}
            />
            <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
              Rôles qui sont autorisés à déclencher cette transition
            </p>
          </div>
          
          {/* Sélection des actions à exécuter */}
          <div>
            <WorkflowDomainSelector
              type="action"
              label="Actions"
              placeholder="Sélectionner les actions à exécuter..."
              selectedItems={edge.data?.actions || []}
              onChange={(selected) => updateEdgeData('actions', selected)}
              multiple={true}
              allowCustom={true}
            />
            <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
              Actions système à exécuter lors de cette transition
            </p>
          </div>
          
          {/* Options avancées */}
          <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
            <h4 className="font-medium text-gray-700 dark:text-gray-300 text-sm mb-2">Options avancées</h4>
            
            {/* Conditions */}
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Conditions (format JSON)
              </label>
              <textarea
                value={edge.data?.conditions || ''}
                onChange={(e) => updateEdgeData('conditions', e.target.value)}
                placeholder='{"key": "value"}'
                className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                rows={3}
              />
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Conditions à vérifier avant d'autoriser la transition (JSON)
              </p>
            </div>
            
            {/* Priorité */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Priorité
              </label>
              <input
                type="number"
                min="0"
                max="100"
                value={edge.data?.priority || 0}
                onChange={(e) => updateEdgeData('priority', parseInt(e.target.value) || 0)}
                className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Priorité de la transition (utile si plusieurs transitions automatiques sont possibles)
              </p>
            </div>
          </div>
        </div>
        
        {/* Pied de modal avec boutons d'action */}
        <div className="mt-6 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {type === 'add' ? 'Ajouter' : 'Mettre à jour'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImprovedEdgeModal;