import React, { useState, useEffect } from 'react';
import { 
  Users, 
  UserPlus, 
  Edit, 
  Trash2, 
  Search, 
  Shield, 
  RefreshCw, 
  CheckCircle, 
  AlertTriangle,
  X,
  Eye,
  EyeOff,
  Save,
  Lock,
  Mail,
  User
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const UsersManagement = () => {
  // États de données
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les filtres et la pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  
  // États pour les formulaires
  const [showUserModal, setShowUserModal] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [userForm, setUserForm] = useState({
    username: '',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    enabled: true,
    roles: []
  });
  const [showPassword, setShowPassword] = useState(false);
  
  // Chargement initial des utilisateurs et des rôles
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [usersData, rolesData] = await Promise.all([
          apiService.security.users.getAll(),
          apiService.security.roles.getAll()
        ]);
        
        setUsers(usersData || []);
        setRoles(rolesData || []);
        setError(null);
      } catch (err) {
        console.error('Erreur lors du chargement des données:', err);
        setError('Impossible de charger les données. Veuillez réessayer.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);
  
  // Filtrage des utilisateurs basé sur la recherche
  const filteredUsers = users.filter(user => {
    const searchableValues = [
      user.username,
      user.email,
      user.firstName,
      user.lastName,
      user.phoneNumber
    ].map(val => (val || '').toLowerCase());
    
    return searchableValues.some(val => val.includes(searchTerm.toLowerCase()));
  });
  
  // Pagination
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'username',
      header: 'Nom d\'utilisateur',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 bg-gray-200 dark:bg-gray-700 rounded-full flex items-center justify-center text-gray-700 dark:text-gray-300 font-semibold">
            {row.firstName?.charAt(0) || row.username?.charAt(0) || 'U'}
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900 dark:text-white">
              {row.username}
            </div>
            {row.email && (
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {row.email}
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      key: 'fullName',
      header: 'Nom complet',
      sortable: true,
      render: (row) => (
        <span>
          {row.firstName && row.lastName 
            ? `${row.firstName} ${row.lastName}`
            : <span className="text-gray-400 dark:text-gray-500">Non renseigné</span>}
        </span>
      )
    },
    {
      key: 'status',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge
          status={row.enabled ? 'active' : 'inactive'}
          customLabel={row.enabled ? 'Actif' : 'Inactif'}
        />
      )
    },
    {
      key: 'roles',
      header: 'Rôles',
      render: (row) => (
        <div className="flex flex-wrap gap-1">
          {row.roles && row.roles.length > 0 ? (
            row.roles.map((role, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300"
              >
                {role}
              </span>
            ))
          ) : (
            <span className="text-gray-400 dark:text-gray-500">Aucun rôle</span>
          )}
        </div>
      )
    },
    {
      key: 'createdAt',
      header: 'Créé le',
      sortable: true,
      render: (row) => (
        <DateFormatter date={row.createdAt} format="full" />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => handleEditUser(row)}
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => handleDeleteUser(row.id)}
          />
        </div>
      )
    }
  ];
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Réinitialiser à la première page lors d'une recherche
  };
  
  const handleCreateUser = () => {
    setUserForm({
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      enabled: true,
      roles: []
    });
    setFormMode('create');
    setShowUserModal(true);
    setShowPassword(true);
  };
  
  const handleEditUser = (user) => {
    setUserForm({
      ...user,
      password: '', // Ne pas remplir le mot de passe lors de l'édition
      roles: user.roles || []
    });
    setFormMode('edit');
    setShowUserModal(true);
    setShowPassword(false);
  };
  
  const handleFormChange = (field, value) => {
    setUserForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleRoleToggle = (roleName) => {
    setUserForm(prev => {
      const updatedRoles = prev.roles.includes(roleName)
        ? prev.roles.filter(r => r !== roleName)
        : [...prev.roles, roleName];
      
      return {
        ...prev,
        roles: updatedRoles
      };
    });
  };
  
  const handleSubmitUser = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      let response;
      
      // Préparer les données du formulaire
      const userData = { ...userForm };
      
      // Supprimer le mot de passe s'il est vide en mode édition
      if (formMode === 'edit' && !userData.password) {
        delete userData.password;
      }
      
      if (formMode === 'create') {
        response = await apiService.security.users.create(userData);
        setSuccessMessage('Utilisateur créé avec succès');
      } else {
        response = await apiService.security.users.update(userData.id, userData);
        setSuccessMessage('Utilisateur mis à jour avec succès');
      }
      
      // Actualiser la liste des utilisateurs
      const updatedUsers = await apiService.security.users.getAll();
      setUsers(updatedUsers || []);
      
      // Fermer le modal
      setShowUserModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.security.users.delete(userId);
      
      // Actualiser la liste des utilisateurs
      const updatedUsers = await apiService.security.users.getAll();
      setUsers(updatedUsers || []);
      
      setSuccessMessage('Utilisateur supprimé avec succès');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError(err.message || 'Impossible de supprimer l\'utilisateur.');
    } finally {
      setLoading(false);
    }
  };
  
  const refreshData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const [usersData, rolesData] = await Promise.all([
        apiService.security.users.getAll(),
        apiService.security.roles.getAll()
      ]);
      
      setUsers(usersData || []);
      setRoles(rolesData || []);
    } catch (err) {
      console.error('Erreur lors de l\'actualisation:', err);
      setError('Impossible d\'actualiser les données.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Users className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des utilisateurs
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les utilisateurs et leurs accès au système
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={UserPlus}
            onClick={handleCreateUser}
          >
            Nouvel utilisateur
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Filtres et recherche */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
          <div className="flex-1 relative">
            <input
              type="text"
              className="w-full p-2 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Rechercher par nom, email..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>
      
      {/* Liste des utilisateurs */}
      <Card>
        <DataGrid
          data={paginatedUsers}
          columns={columns}
          loading={loading}
          onPageChange={(page) => setCurrentPage(page)}
          totalItems={filteredUsers.length}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          emptyMessage="Aucun utilisateur trouvé."
        />
      </Card>
      
      {/* Modal de création/édition d'utilisateur */}
      {showUserModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-lg overflow-hidden">
            <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 px-6 py-4 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {formMode === 'create' ? 'Nouvel utilisateur' : 'Modifier l\'utilisateur'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowUserModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitUser} className="p-6 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Nom d'utilisateur */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom d'utilisateur *
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                      value={userForm.username}
                      onChange={(e) => handleFormChange('username', e.target.value)}
                      required
                    />
                  </div>
                </div>
                
                {/* Adresse email */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Email *
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                      value={userForm.email}
                      onChange={(e) => handleFormChange('email', e.target.value)}
                      required
                    />
                  </div>
                </div>
                
                {/* Prénom */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Prénom
                  </label>
                  <input
                    type="text"
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={userForm.firstName || ''}
                    onChange={(e) => handleFormChange('firstName', e.target.value)}
                  />
                </div>
                
                {/* Nom */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom
                  </label>
                  <input
                    type="text"
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={userForm.lastName || ''}
                    onChange={(e) => handleFormChange('lastName', e.target.value)}
                  />
                </div>
                
                {/* Numéro de téléphone */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Téléphone
                  </label>
                  <input
                    type="tel"
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={userForm.phoneNumber || ''}
                    onChange={(e) => handleFormChange('phoneNumber', e.target.value)}
                  />
                </div>
                
                {/* Mot de passe */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {formMode === 'create' ? 'Mot de passe *' : 'Mot de passe (laisser vide pour inchangé)'}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="block w-full pl-10 pr-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                      value={userForm.password}
                      onChange={(e) => handleFormChange('password', e.target.value)}
                      required={formMode === 'create'}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOff className="h-5 w-5" />
                        ) : (
                          <Eye className="h-5 w-5" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Activé/Désactivé */}
              <div className="flex items-center">
                <input
                  id="user-enabled"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={userForm.enabled}
                  onChange={(e) => handleFormChange('enabled', e.target.checked)}
                />
                <label htmlFor="user-enabled" className="ml-2 block text-sm text-gray-900 dark:text-white">
                  Utilisateur actif
                </label>
              </div>
              
              {/* Rôles */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Rôles
                </label>
                <div className="max-h-60 overflow-y-auto p-3 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-50 dark:bg-gray-700">
                  {roles.length > 0 ? (
                    <div className="space-y-2">
                      {roles.map((role) => (
                        <div key={role.id} className="flex items-center">
                          <input
                            id={`role-${role.id}`}
                            type="checkbox"
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            checked={userForm.roles.includes(role.name)}
                            onChange={() => handleRoleToggle(role.name)}
                          />
                          <label htmlFor={`role-${role.id}`} className="ml-2 block text-sm text-gray-900 dark:text-white">
                            {role.name}
                            {role.description && (
                              <span className="text-xs text-gray-500 dark:text-gray-400 ml-2">
                                {role.description}
                              </span>
                            )}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-500 dark:text-gray-400 text-sm">Aucun rôle disponible</p>
                  )}
                </div>
              </div>
              
              {/* Actions */}
              <div className="flex justify-end space-x-3 border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setShowUserModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  icon={Save}
                  loading={loading}
                >
                  {formMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersManagement;