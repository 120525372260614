import React, { useState, useEffect } from 'react';
import { GitBranch, Info, RefreshCw, AlertTriangle, Search, Filter } from 'lucide-react';
import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';

const WorkflowStateTransitionDiagram = ({ workflowDefinition, onRefresh, loading = false }) => {
  const [states, setStates] = useState([]);
  const [transitions, setTransitions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [filteredTransitions, setFilteredTransitions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActive, setFilterActive] = useState(false);

  // Process workflow data
  useEffect(() => {
    if (!workflowDefinition) return;

    // Extract all states
    const statesSet = new Set();
    statesSet.add(workflowDefinition.initialState);
    if (workflowDefinition.finalStates) {
      workflowDefinition.finalStates.forEach(state => statesSet.add(state));
    }
    
    if (workflowDefinition.transitions) {
      workflowDefinition.transitions.forEach(transition => {
        statesSet.add(transition.fromState);
        statesSet.add(transition.toState);
      });
    }

    // Create state objects
    const statesArray = Array.from(statesSet).map(state => ({
      id: state,
      isInitial: state === workflowDefinition.initialState,
      isFinal: workflowDefinition.finalStates?.includes(state) || false
    }));

    setStates(statesArray);
    setTransitions(workflowDefinition.transitions || []);
    setFilteredTransitions(workflowDefinition.transitions || []);
  }, [workflowDefinition]);

  // Filter transitions based on search and selected state
  useEffect(() => {
    let filtered = transitions;
    
    if (searchTerm) {
      filtered = filtered.filter(transition => 
        transition.event.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transition.fromState.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transition.toState.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    if (selectedState) {
      filtered = filtered.filter(transition => 
        transition.fromState === selectedState || transition.toState === selectedState
      );
      setFilterActive(true);
    } else {
      setFilterActive(false);
    }
    
    setFilteredTransitions(filtered);
  }, [searchTerm, selectedState, transitions]);

  // Reset filter
  const resetFilter = () => {
    setSelectedState(null);
    setSearchTerm('');
  };

  if (loading) {
    return (
      <Card title="Workflow State Transitions" icon={GitBranch}>
        <div className="flex justify-center items-center py-16">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </Card>
    );
  }

  if (!workflowDefinition) {
    return (
      <Card title="Workflow State Transitions" icon={GitBranch}>
        <div className="flex items-center justify-center p-8 text-gray-500 dark:text-gray-400">
          <AlertTriangle className="mr-2 w-5 h-5" />
          <span>No workflow definition available</span>
        </div>
      </Card>
    );
  }

  return (
    <Card 
      title="Workflow State Transitions" 
      icon={GitBranch}
      actions={
        <div className="flex space-x-2">
          {filterActive && (
            <Button
              variant="outline"
              size="sm"
              onClick={resetFilter}
            >
              Clear Filters
            </Button>
          )}
          <Button
            variant="outline"
            size="sm"
            icon={RefreshCw}
            onClick={onRefresh}
          >
            Refresh
          </Button>
        </div>
      }
    >
      {/* Search and filter bar */}
      <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="text-gray-400 h-5 w-5" />
          </div>
          <input
            type="text"
            className="pl-10 pr-4 py-2 w-full text-sm rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search states or events..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex space-x-1">
          <Button
            variant="outline"
            size="sm"
            icon={Filter}
            onClick={() => setSelectedState(null)}
          >
            All States
          </Button>
          <Button
            variant={selectedState === workflowDefinition.initialState ? 'primary' : 'outline'}
            size="sm"
            onClick={() => setSelectedState(workflowDefinition.initialState)}
          >
            Initial
          </Button>
          {workflowDefinition.finalStates && workflowDefinition.finalStates.length > 0 && (
            <Button
              variant={workflowDefinition.finalStates.includes(selectedState) ? 'primary' : 'outline'}
              size="sm"
              onClick={() => setSelectedState(workflowDefinition.finalStates[0])}
            >
              Final
            </Button>
          )}
        </div>
      </div>

      {/* States section */}
      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
          <div className="mr-2 p-1 rounded-full bg-gray-100 dark:bg-gray-800">
            <Info size={16} className="text-gray-500" />
          </div>
          States
        </h3>
        <div className="flex flex-wrap gap-2">
          {states.map(state => (
            <div
              key={state.id}
              className={`cursor-pointer transition-all duration-200 ${
                selectedState === state.id ? 'ring-2 ring-blue-500' : ''
              }`}
              onClick={() => setSelectedState(state.id === selectedState ? null : state.id)}
            >
              <StatusBadge
                status={state.isFinal ? 'inactive' : state.isInitial ? 'active' : 'warning'}
                customLabel={state.id}
                size="md"
                showIcon={true}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Transitions section */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
          <div className="mr-2 p-1 rounded-full bg-gray-100 dark:bg-gray-800">
            <GitBranch size={16} className="text-gray-500" />
          </div>
          Transitions
        </h3>

        {filteredTransitions.length === 0 ? (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            No transitions match your search criteria
          </div>
        ) : (
          <div className="space-y-3">
            {filteredTransitions.map((transition, index) => (
              <div 
                key={`${transition.fromState}-${transition.event}-${transition.toState}`}
                className="p-3 border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors"
              >
                <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
                  <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                    <StatusBadge
                      status={states.find(s => s.id === transition.fromState)?.isInitial ? 'active' : 'warning'}
                      customLabel={transition.fromState}
                      size="sm"
                    />
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="text-gray-400">
                      <path d="M5 12h14m-7-7l7 7-7 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <StatusBadge
                      status={states.find(s => s.id === transition.toState)?.isFinal ? 'inactive' : 'warning'}
                      customLabel={transition.toState}
                      size="sm"
                    />
                  </div>
                  <div className="flex items-center">
                    <span className="px-3 py-1 text-sm font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300 rounded-full">
                      {transition.event}
                    </span>
                  </div>
                </div>
                
                <div className="text-sm text-gray-500 dark:text-gray-400 flex flex-wrap items-center gap-2 mt-2">
                  {transition.isAutomatic && (
                    <span className="inline-flex items-center px-2 py-1 bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300 rounded text-xs">
                      Automatic
                    </span>
                  )}
                  
                  {transition.requiredRoles && transition.requiredRoles.length > 0 && (
                    <div className="inline-flex items-center">
                      <span className="mr-1 text-xs">Roles:</span>
                      {transition.requiredRoles.map(role => (
                        <span 
                          key={role} 
                          className="mx-1 px-2 py-0.5 bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200 rounded text-xs"
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  )}
                  
                  {transition.actions && transition.actions.length > 0 && (
                    <div className="inline-flex items-center flex-wrap">
                      <span className="mr-1 text-xs">Actions:</span>
                      {transition.actions.map(action => (
                        <span 
                          key={action} 
                          className="mx-1 px-2 py-0.5 bg-purple-100 text-purple-800 dark:bg-purple-900/20 dark:text-purple-300 rounded text-xs"
                        >
                          {action}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default WorkflowStateTransitionDiagram;