import React, { useState, useEffect } from 'react';
import { 
  Calendar, 
  Clock, 
  PlayCircle, 
  PauseCircle, 
  RefreshCw, 
  Calendar as CalendarIcon, 
  AlertTriangle, 
  CheckCircle, 
  Save,
  X,
  Edit,
  Info,
  HelpCircle,
  ChevronRight
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

/**
 * Gestion des planifications de tâches
 * Permet de configurer, activer et désactiver les planifications
 */
const SchedulesPage = () => {
  // États pour les données et le chargement
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [nextExecution, setNextExecution] = useState(null);

  // Formulaire pour la planification
  const [scheduleForm, setScheduleForm] = useState({
    cronExpression: '',
    timezone: 'UTC',
    startDate: '',
    endDate: '',
    maxExecutions: null,
    fixedDelay: null,
    pauseOnFailure: false
  });

  // Chargement initial des jobs
  useEffect(() => {
    fetchJobs();
  }, []);

  // Chargement de la planification lorsqu'un job est sélectionné
  useEffect(() => {
    if (selectedJob && selectedJob.id) {
      fetchSchedule(selectedJob.id);
      fetchNextExecution(selectedJob.id);
    }
  }, [selectedJob]);

  // Fonction pour charger la liste des tâches
  const fetchJobs = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await apiService.scheduler.jobs.getAll();
      
      // Normaliser les données pour assurer que chaque job a une propriété 'id'
      const formattedJobs = response.map(job => ({
        ...job,
        id: job.jobId || job.id // Utiliser jobId s'il existe, sinon utiliser id
      }));
      
      console.log('Jobs récupérés avec succès:', formattedJobs);
      setJobs(formattedJobs);
      
      // Sélectionner automatiquement le premier job s'il y en a et qu'aucun n'est déjà sélectionné
      if (formattedJobs.length > 0 && !selectedJob) {
        setSelectedJob(formattedJobs[0]);
      }
    } catch (err) {
      console.error('Erreur lors du chargement des tâches:', err);
      setError("Impossible de charger la liste des tâches.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger la planification d'une tâche
  const fetchSchedule = async (jobId) => {
    if (!jobId) {
      console.warn('fetchSchedule appelé sans jobId valide');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      const response = await apiService.scheduler.schedules.getForJob(jobId);
      console.log(`Planification récupérée pour le job ${jobId}:`, response);
      setSelectedSchedule(response);
      
      // Pré-remplir le formulaire avec les valeurs actuelles
      if (response) {
        setScheduleForm({
          cronExpression: response.cronExpression || '',
          timezone: response.timeZone || 'UTC',
          startDate: response.startDate ? formatDateForInput(response.startDate) : '',
          endDate: response.endDate ? formatDateForInput(response.endDate) : '',
          maxExecutions: response.remainingExecutions || null,
          fixedDelay: response.fixedDelay || null,
          pauseOnFailure: response.pauseOnFailure || false
        });
      } else {
        // Réinitialiser le formulaire si aucune planification n'est trouvée
        resetForm();
      }
    } catch (err) {
      console.error(`Erreur lors du chargement de la planification pour le job ${jobId}:`, err);
      setError("Impossible de charger la planification de cette tâche.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger la prochaine exécution planifiée
  const fetchNextExecution = async (jobId) => {
    if (!jobId) {
      console.warn('fetchNextExecution appelé sans jobId valide');
      return;
    }
    
    try {
      // On fait l'appel API mais on gère le cas où la réponse n'est pas du JSON valide
      const response = await apiService.scheduler.schedules.getNextExecution(jobId)
        .catch(err => {
          // Si l'erreur est liée au parsing JSON, on essaie de la gérer différemment
          if (err instanceof SyntaxError && err.message.includes('Unexpected')) {
            console.warn(`Réponse non-JSON de l'API pour la prochaine exécution:`, err);
            // On peut retourner une chaîne de date directement si l'API renvoie juste une date sous forme de texte
            return null;
          }
          throw err; // Sinon, on propage l'erreur
        });
      
      if (response) {
        console.log(`Prochaine exécution pour le job ${jobId}:`, response);
        setNextExecution(response);
      } else {
        setNextExecution(null);
      }
    } catch (err) {
      console.error(`Erreur lors de la récupération de la prochaine exécution pour le job ${jobId}:`, err);
      setNextExecution(null);
    }
  };

  // Fonction pour formater la date pour les champs input
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      return date.toISOString().slice(0, 16); // Format 'YYYY-MM-DDThh:mm'
    } catch (e) {
      console.error('Erreur lors du formatage de la date:', e);
      return '';
    }
  };

  // Réinitialiser le formulaire
  const resetForm = () => {
    setScheduleForm({
      cronExpression: '',
      timezone: 'UTC',
      startDate: '',
      endDate: '',
      maxExecutions: null,
      fixedDelay: null,
      pauseOnFailure: false
    });
  };

  // Gérer les changements dans le formulaire
  const handleFormChange = (field, value) => {
    setScheduleForm(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Activer/désactiver le mode édition
  const toggleEditMode = () => {
    setEditMode(!editMode);
    
    if (!editMode) {
      // Si on entre en mode édition, pré-remplir le formulaire
      if (selectedSchedule) {
        setScheduleForm({
          cronExpression: selectedSchedule.cronExpression || '',
          timezone: selectedSchedule.timeZone || 'UTC',
          startDate: selectedSchedule.startDate ? formatDateForInput(selectedSchedule.startDate) : '',
          endDate: selectedSchedule.endDate ? formatDateForInput(selectedSchedule.endDate) : '',
          maxExecutions: selectedSchedule.remainingExecutions || null,
          fixedDelay: selectedSchedule.fixedDelay || null,
          pauseOnFailure: selectedSchedule.pauseOnFailure || false
        });
      }
    }
  };

  // Soumettre le formulaire
  const submitScheduleForm = async (e) => {
    e.preventDefault();
    
    if (!selectedJob || !selectedJob.id) {
      setError("Aucune tâche sélectionnée.");
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Préparer les données de planification
      const scheduleData = {
        cronExpression: scheduleForm.cronExpression || null,
        timezone: scheduleForm.timezone || 'UTC',
        startDate: scheduleForm.startDate ? scheduleForm.startDate : null,
        endDate: scheduleForm.endDate ? scheduleForm.endDate : null,
        maxExecutions: scheduleForm.maxExecutions,
        fixedDelay: scheduleForm.fixedDelay,
        pauseOnFailure: scheduleForm.pauseOnFailure
      };
      
      console.log(`Mise à jour de la planification pour le job ${selectedJob.id}:`, scheduleData);
      
      // Mettre à jour la planification
      await apiService.scheduler.schedules.update(selectedJob.id, scheduleData);
      
      setSuccessMessage("Planification mise à jour avec succès!");
      setEditMode(false);
      
      // Actualiser les données
      fetchSchedule(selectedJob.id);
      fetchNextExecution(selectedJob.id);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error(`Erreur lors de la mise à jour de la planification pour le job ${selectedJob.id}:`, err);
      setError("Impossible de mettre à jour la planification.");
    } finally {
      setLoading(false);
    }
  };

  // Mettre en pause une planification
  const pauseSchedule = async () => {
    if (!selectedJob || !selectedJob.id) {
      setError("Aucune tâche sélectionnée.");
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.scheduler.schedules.pause(selectedJob.id);
      setSuccessMessage("Planification mise en pause.");
      
      // Actualiser les données
      fetchSchedule(selectedJob.id);
      fetchNextExecution(selectedJob.id);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error(`Erreur lors de la mise en pause de la planification pour le job ${selectedJob.id}:`, err);
      setError("Impossible de mettre en pause la planification.");
    } finally {
      setLoading(false);
    }
  };

  // Reprendre une planification
  const resumeSchedule = async () => {
    if (!selectedJob || !selectedJob.id) {
      setError("Aucune tâche sélectionnée.");
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.scheduler.schedules.resume(selectedJob.id);
      setSuccessMessage("Planification reprise.");
      
      // Actualiser les données
      fetchSchedule(selectedJob.id);
      fetchNextExecution(selectedJob.id);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error(`Erreur lors de la reprise de la planification pour le job ${selectedJob.id}:`, err);
      setError("Impossible de reprendre la planification.");
    } finally {
      setLoading(false);
    }
  };

  // Rafraîchir les données
  const refreshData = () => {
    if (selectedJob && selectedJob.id) {
      fetchSchedule(selectedJob.id);
      fetchNextExecution(selectedJob.id);
    } else {
      fetchJobs();
    }
  };

  // Aider l'utilisateur à choisir une expression cron
  const showCronHelper = () => {
    // Exemples d'expressions cron
    const cronExamples = [
      { expression: "0 0 * * * ?", description: "Tous les jours à minuit" },
      { expression: "0 0 12 * * ?", description: "Tous les jours à midi" },
      { expression: "0 15 10 ? * MON-FRI", description: "En semaine à 10h15" },
      { expression: "0 0/15 * * * ?", description: "Toutes les 15 minutes" },
      { expression: "0 0 0 1 * ?", description: "Le premier jour de chaque mois" }
    ];
    
    const example = prompt(
      "Sélectionnez une expression cron prédéfinie ou entrez la vôtre:\n\n" +
      cronExamples.map((ex, i) => `${i+1}. ${ex.expression} - ${ex.description}`).join("\n")
    );
    
    if (example) {
      // Vérifier si l'utilisateur a sélectionné un exemple ou entré une expression manuelle
      const selectedExample = cronExamples[parseInt(example) - 1];
      const cronExpression = selectedExample ? selectedExample.expression : example;
      
      setScheduleForm(prev => ({
        ...prev,
        cronExpression
      }));
    }
  };

  // Gérer le changement de job sélectionné
  const handleJobChange = (e) => {
    const jobId = parseInt(e.target.value);
    const job = jobs.find(j => j.id === jobId || j.jobId === jobId);
    if (job) {
      setSelectedJob(job);
    }
  };

  // Fonction pour afficher la date de prochaine exécution
  const renderNextExecution = () => {
    if (!nextExecution) return null;
    
    // Si c'est déjà une chaîne de caractères (au cas où l'API renvoie directement une chaîne)
    if (typeof nextExecution === 'string') {
      return <DateFormatter date={nextExecution} format="full" showTime />;
    }
    
    return <DateFormatter date={nextExecution} format="full" showTime />;
  };

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Calendar className="h-6 w-6 mr-2 text-blue-500" />
            Planifications
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Configurez et gérez les planifications des tâches
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          {selectedSchedule && !editMode && (
            <Button
              variant="primary"
              icon={Edit}
              onClick={toggleEditMode}
            >
              Modifier
            </Button>
          )}
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Sélection de la tâche */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Sélectionnez une tâche
        </label>
        <select
          className="w-full md:w-1/3 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          value={selectedJob?.id || ''}
          onChange={handleJobChange}
        >
          {jobs.length === 0 && <option value="">Chargement des tâches...</option>}
          {jobs.map(job => (
            <option key={job.id || job.jobId} value={job.id || job.jobId}>
              {job.name}
            </option>
          ))}
        </select>
      </div>

      {/* Détails de la tâche sélectionnée */}
      {selectedJob && (
        <Card
          title="Détails de la tâche"
          icon={Info}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                {selectedJob.name}
              </h3>
              {selectedJob.description && (
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  {selectedJob.description}
                </p>
              )}
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-500 dark:text-gray-400">Type:</span>
                  <span className="font-medium text-gray-900 dark:text-white">{selectedJob.type}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                  <StatusBadge 
                    status={selectedJob.active ? 'active' : 'inactive'} 
                    customLabel={selectedJob.active ? 'Actif' : 'Inactif'} 
                  />
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500 dark:text-gray-400">Priorité:</span>
                  <span className="font-medium text-gray-900 dark:text-white">{selectedJob.priority}</span>
                </div>
              </div>
            </div>
            
            <div className="space-y-2 bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">Classe:</span>
                <span className="font-mono text-sm text-gray-900 dark:text-white">{selectedJob.className}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">Méthode:</span>
                <span className="font-mono text-sm text-gray-900 dark:text-white">{selectedJob.methodName}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">Tentatives max:</span>
                <span className="text-gray-900 dark:text-white">{selectedJob.maxRetries || 0}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 dark:text-gray-400">Timeout:</span>
                <span className="text-gray-900 dark:text-white">{selectedJob.timeout ? `${selectedJob.timeout} ms` : 'Non défini'}</span>
              </div>
              {nextExecution && (
                <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
                  <div className="flex items-center">
                    <Clock className="h-4 w-4 text-blue-500 mr-2" />
                    <span className="text-gray-700 dark:text-gray-300">Prochaine exécution:</span>
                  </div>
                  <div className="mt-1 text-sm text-blue-600 dark:text-blue-400 font-medium bg-blue-50 dark:bg-blue-900/20 px-3 py-2 rounded-md">
                    {renderNextExecution()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      )}

      {/* Planification */}
      {selectedJob && (
        <Card
          title="Planification"
          icon={Clock}
          actions={
            !editMode && selectedSchedule && (
              <div className="flex space-x-2">
                {selectedSchedule.active ? (
                  <Button
                    variant="outline"
                    size="sm"
                    icon={PauseCircle}
                    onClick={pauseSchedule}
                    loading={loading}
                  >
                    Mettre en pause
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    size="sm"
                    icon={PlayCircle}
                    onClick={resumeSchedule}
                    loading={loading}
                  >
                    Reprendre
                  </Button>
                )}
              </div>
            )
          }
        >
          {loading ? (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div>
              {editMode ? (
                <form onSubmit={submitScheduleForm}>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                            Expression Cron
                          </label>
                          <button
                            type="button"
                            className="text-sm text-blue-600 dark:text-blue-400"
                            onClick={showCronHelper}
                          >
                            <HelpCircle className="h-4 w-4 inline mr-1" />
                            Aide
                          </button>
                        </div>
                        <input
                          type="text"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={scheduleForm.cronExpression}
                          onChange={(e) => handleFormChange('cronExpression', e.target.value)}
                          placeholder="0 0 * * * ?"
                        />
                        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                          Format: secondes minutes heures jour-du-mois mois jour-de-semaine
                        </p>
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Fuseau horaire
                        </label>
                        <select
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={scheduleForm.timezone}
                          onChange={(e) => handleFormChange('timezone', e.target.value)}
                        >
                          <option value="UTC">UTC</option>
                          <option value="Europe/Paris">Europe/Paris</option>
                          <option value="America/New_York">America/New_York</option>
                          <option value="Asia/Tokyo">Asia/Tokyo</option>
                          <option value="Africa/Douala">Africa/Douala</option>
                        </select>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Date de début
                        </label>
                        <input
                          type="datetime-local"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={scheduleForm.startDate}
                          onChange={(e) => handleFormChange('startDate', e.target.value)}
                        />
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Date de fin
                        </label>
                        <input
                          type="datetime-local"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={scheduleForm.endDate}
                          onChange={(e) => handleFormChange('endDate', e.target.value)}
                        />
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Délai fixe (ms)
                        </label>
                        <input
                          type="number"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={scheduleForm.fixedDelay || ''}
                          onChange={(e) => handleFormChange('fixedDelay', e.target.value ? parseInt(e.target.value) : null)}
                          placeholder="Délai en millisecondes"
                        />
                        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                          Alternative à l'expression Cron. Délai fixe entre les exécutions.
                        </p>
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Nombre max d'exécutions
                        </label>
                        <input
                          type="number"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={scheduleForm.maxExecutions || ''}
                          onChange={(e) => handleFormChange('maxExecutions', e.target.value ? parseInt(e.target.value) : null)}
                          placeholder="Nombre maximum d'exécutions"
                        />
                      </div>
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="pauseOnFailure"
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={scheduleForm.pauseOnFailure}
                        onChange={(e) => handleFormChange('pauseOnFailure', e.target.checked)}
                      />
                      <label htmlFor="pauseOnFailure" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                        Mettre en pause en cas d'échec
                      </label>
                    </div>
                    
                    <div className="flex justify-end space-x-2 pt-4 mt-4 border-t border-gray-200 dark:border-gray-700">
                      <Button
                        variant="outline"
                        onClick={() => {
                          setEditMode(false);
                          if (selectedSchedule) {
                            // Rétablir les valeurs actuelles
                            setScheduleForm({
                              cronExpression: selectedSchedule.cronExpression || '',
                              timezone: selectedSchedule.timeZone || 'UTC',startDate: selectedSchedule.startDate ? formatDateForInput(selectedSchedule.startDate) : '',
                              endDate: selectedSchedule.endDate ? formatDateForInput(selectedSchedule.endDate) : '',
                              maxExecutions: selectedSchedule.remainingExecutions || null,
                              fixedDelay: selectedSchedule.fixedDelay || null,
                              pauseOnFailure: selectedSchedule.pauseOnFailure || false
                            });
                          }
                        }}
                        type="button"
                      >
                        Annuler
                      </Button>
                      <Button
                        variant="primary"
                        icon={Save}
                        type="submit"
                        loading={loading}
                      >
                        Enregistrer
                      </Button>
                    </div>
                  </div>
                </form>
              ) : selectedSchedule ? (
                <div className="space-y-6">
                  {/* État de la planification */}
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <span className="text-gray-700 dark:text-gray-300 mr-2">État:</span>
                      <StatusBadge 
                        status={selectedSchedule.active ? 'active' : 'inactive'} 
                        customLabel={selectedSchedule.active ? 'Actif' : 'En pause'}
                        showIcon={true}
                      />
                    </div>
                    {nextExecution && (
                      <div className="flex items-center text-sm">
                        <CalendarIcon className="h-4 w-4 text-blue-500 mr-1" />
                        <span className="text-gray-700 dark:text-gray-300 mr-1">Prochaine exécution:</span>
                        <span className="font-medium text-blue-600 dark:text-blue-400">
                          {renderNextExecution()}
                        </span>
                      </div>
                    )}
                  </div>
                  
                  {/* Informations de planification */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                        Configuration temporelle
                      </h3>
                      
                      {selectedSchedule.cronExpression ? (
                        <div className="space-y-2">
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Expression Cron:</span>
                            <span className="font-mono text-gray-900 dark:text-white">
                              {selectedSchedule.cronExpression}
                            </span>
                          </div>
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Fuseau horaire:</span>
                            <span className="text-gray-900 dark:text-white">
                              {selectedSchedule.timeZone || 'UTC'}
                            </span>
                          </div>
                        </div>
                      ) : selectedSchedule.fixedDelay ? (
                        <div className="flex justify-between">
                          <span className="text-gray-500 dark:text-gray-400">Délai fixe:</span>
                          <span className="text-gray-900 dark:text-white">
                            {selectedSchedule.fixedDelay} ms
                          </span>
                        </div>
                      ) : (
                        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-md">
                          <p className="text-gray-500 dark:text-gray-400 text-sm">
                            Aucune planification temporelle définie
                          </p>
                        </div>
                      )}
                      
                      {(selectedSchedule.startDate || selectedSchedule.endDate) && (
                        <div className="pt-3 space-y-2">
                          {selectedSchedule.startDate && (
                            <div className="flex justify-between">
                              <span className="text-gray-500 dark:text-gray-400">Date de début:</span>
                              <DateFormatter date={selectedSchedule.startDate} format="full" showTime />
                            </div>
                          )}
                          {selectedSchedule.endDate && (
                            <div className="flex justify-between">
                              <span className="text-gray-500 dark:text-gray-400">Date de fin:</span>
                              <DateFormatter date={selectedSchedule.endDate} format="full" showTime />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    
                    <div className="space-y-4">
                      <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                        Paramètres avancés
                      </h3>
                      
                      <div className="space-y-2">
                        {selectedSchedule.remainingExecutions !== undefined && selectedSchedule.remainingExecutions !== null && (
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Nombre max d'exécutions:</span>
                            <span className="text-gray-900 dark:text-white">
                              {selectedSchedule.remainingExecutions + 
                                (selectedSchedule.currentExecutions ? selectedSchedule.currentExecutions : 0)}
                            </span>
                          </div>
                        )}
                        
                        {selectedSchedule.currentExecutions !== undefined && (
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Exécutions actuelles:</span>
                            <span className="text-gray-900 dark:text-white">{selectedSchedule.currentExecutions}</span>
                          </div>
                        )}
                        
                        {selectedSchedule.remainingExecutions !== undefined && selectedSchedule.remainingExecutions !== null && (
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Exécutions restantes:</span>
                            <span className="text-gray-900 dark:text-white">
                              {selectedSchedule.remainingExecutions}
                            </span>
                          </div>
                        )}
                        
                        <div className="flex justify-between">
                          <span className="text-gray-500 dark:text-gray-400">Pause en cas d'échec:</span>
                          <span className="text-gray-900 dark:text-white">
                            {selectedSchedule.pauseOnFailure ? 'Oui' : 'Non'}
                          </span>
                        </div>
                      </div>
                      
                      {selectedSchedule.startTime && selectedSchedule.endTime && (
                        <div className="pt-3 space-y-2">
                          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            Fenêtre d'activité
                          </h4>
                          
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Heures actives:</span>
                            <span className="text-gray-900 dark:text-white">
                              {selectedSchedule.startTime} - {selectedSchedule.endTime}
                            </span>
                          </div>
                          
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Actif le weekend:</span>
                            <span className="text-gray-900 dark:text-white">
                              {selectedSchedule.activeOnWeekends ? 'Oui' : 'Non'}
                            </span>
                          </div>
                          
                          <div className="flex justify-between">
                            <span className="text-gray-500 dark:text-gray-400">Actif les jours fériés:</span>
                            <span className="text-gray-900 dark:text-white">
                              {selectedSchedule.activeOnHolidays ? 'Oui' : 'Non'}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-8 text-center">
                  <Calendar className="h-16 w-16 text-gray-300 dark:text-gray-600 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    Aucune planification
                  </h3>
                  <p className="text-gray-500 dark:text-gray-400 mb-4">
                    Cette tâche n'a pas de planification définie
                  </p>
                  <Button
                    variant="primary"
                    icon={Edit}
                    onClick={toggleEditMode}
                  >
                    Créer une planification
                  </Button>
                </div>
              )}
            </div>
          )}
        </Card>
      )}

      {/* Guide d'utilisation */}
      <Card
        title="Guide d'utilisation"
        icon={HelpCircle}
        collapsible={true}
      >
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              Expressions Cron
            </h3>
            <p className="text-gray-700 dark:text-gray-300 mb-2">
              Les expressions Cron permettent de définir des planifications précises et récurrentes. Voici quelques exemples:
            </p>
            <div className="bg-gray-50 dark:bg-gray-800 rounded-md p-4">
              <div className="space-y-3">
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 * * * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Tous les jours à minuit</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 12 * * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Tous les jours à midi</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 15 10 ? * MON-FRI</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">En semaine à 10h15</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0/15 * * * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Toutes les 15 minutes</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 0 1 * ?</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Le premier jour de chaque mois</span>
                  </div>
                  <div>
                    <code className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">0 0 0 ? * MON</code>
                    <span className="ml-2 text-gray-600 dark:text-gray-400">Tous les lundis à minuit</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              Délai fixe vs. Expression Cron
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Vous pouvez choisir entre deux modes de planification:
            </p>
            <ul className="list-disc pl-5 space-y-2 mt-2 text-gray-700 dark:text-gray-300">
              <li>
                <strong>Expression Cron:</strong> Permet de définir des planifications précises et récurrentes basées sur le calendrier.
              </li>
              <li>
                <strong>Délai fixe:</strong> Définit un intervalle constant entre la fin d'une exécution et le début de la suivante, indépendamment de l'heure.
              </li>
            </ul>
          </div>
          
          <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              Conseils pratiques
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-700 dark:text-gray-300">
              <li>
                Définissez des dates de début et de fin pour limiter la période d'activité de la planification.
              </li>
              <li>
                Utilisez un nombre maximum d'exécutions pour les tâches qui doivent s'arrêter après un certain nombre de fois.
              </li>
              <li>
                Activez "Mettre en pause en cas d'échec" pour les tâches critiques afin d'éviter des erreurs répétées.
              </li>
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SchedulesPage;