import React, { useState, useEffect } from 'react';
import { 
  Globe, 
  PlusCircle, 
  Trash2, 
  Edit, 
  RefreshCw, 
  Search, 
  LinkIcon, 
  Zap, 
  CheckCircle, 
  XCircle,
  AlertTriangle,
  Plus,
  Play,
  Settings,
  Code,
  Eye,
  X
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const RestConnectionsPage = () => {
  // États
  const [connections, setConnections] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [endpoints, setEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [endpointsLoading, setEndpointsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  // États pour les modales
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEndpointModal, setShowEndpointModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showExecuteModal, setShowExecuteModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  
  // États pour les formulaires
  const [connectionForm, setConnectionForm] = useState({
    name: '',
    baseUrl: '',
    description: '',
    contentType: 'application/json',
    acceptType: 'application/json',
    defaultHeaders: {},
    followRedirects: true,
    useCompression: false,
    compressionType: '',
    connectionPoolSize: 10,
    validateSsl: true,
    apiKey: '',
    apiKeyHeaderName: ''
  });
  
  const [endpointForm, setEndpointForm] = useState({
    name: '',
    path: '',
    description: '',
    methods: [],
    headers: {},
    contentType: '',
    acceptType: '',
    timeout: 30,
    version: '1.0'
  });
  
  const [testResult, setTestResult] = useState(null);
  const [executeParams, setExecuteParams] = useState({});
  const [executeMethod, setExecuteMethod] = useState(null);
  const [executeResult, setExecuteResult] = useState(null);
  
  // Champs de saisie temporaires pour les en-têtes et méthodes
  const [newHeaderKey, setNewHeaderKey] = useState('');
  const [newHeaderValue, setNewHeaderValue] = useState('');
  const [newMethod, setNewMethod] = useState({
    httpMethod: 'GET',
    operationId: '',
    description: '',
    requiresAuthentication: false,
    requiresRequestBody: false,
    consumes: ['application/json'],
    produces: ['application/json'],
    queryParameters: {},
    pathParameters: {},
    headerParameters: {},
    requestSchema: '',
    responseSchema: '',
    successCode: 200
  });
  
  // Les types de méthodes HTTP disponibles
  const httpMethods = [
    'GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'HEAD', 'OPTIONS'
  ];

  // Effet pour charger les connexions au chargement
  useEffect(() => {
    fetchConnections();
  }, []);

  // Fonction pour charger les connexions
  const fetchConnections = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.restConnections.getAll();
      setConnections(response);
    } catch (err) {
      console.error("Erreur lors du chargement des connexions REST:", err);
      setError("Impossible de charger les connexions REST. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger une connexion spécifique
  const fetchConnection = async (code) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.restConnections.getById(code);
      setSelectedConnection(response);
      
      // Préremplir le formulaire d'édition
      setConnectionForm({
        name: response.name,
        baseUrl: response.baseUrl || '',
        description: response.description || '',
        contentType: response.contentType || 'application/json',
        acceptType: response.acceptType || 'application/json',
        defaultHeaders: response.defaultHeaders || {},
        followRedirects: response.followRedirects !== false,
        useCompression: response.useCompression || false,
        compressionType: response.compressionType || '',
        connectionPoolSize: response.connectionPoolSize || 10,
        validateSsl: response.validateSsl !== false,
        apiKey: response.apiKey || '',
        apiKeyHeaderName: response.apiKeyHeaderName || ''
      });
      
      setShowDetailsModal(true);
    } catch (err) {
      console.error("Erreur lors du chargement de la connexion REST:", err);
      setError("Impossible de charger les détails de la connexion. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger les endpoints d'une connexion
  const fetchEndpoints = async (code) => {
    setEndpointsLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.restConnections.getEndpoints(code);
      setEndpoints(response);
    } catch (err) {
      console.error("Erreur lors du chargement des endpoints:", err);
      setError("Impossible de charger les endpoints. Veuillez réessayer.");
    } finally {
      setEndpointsLoading(false);
    }
  };

  // Fonction pour créer une connexion REST
  const createConnection = async () => {
    setLoading(true);
    setError(null);
    try {
      const connectionData = {
        ...connectionForm,
        endpoints: []
      };
      
      await apiService.integration.restConnections.create(connectionData);
      
      // Mettre à jour la liste
      fetchConnections();
      
      // Fermer la modale et réinitialiser le formulaire
      setShowCreateModal(false);
      resetConnectionForm();
      
      // Afficher le message de succès
      setSuccessMessage("Connexion REST créée avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création de la connexion REST:", err);
      setError("Impossible de créer la connexion. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour mettre à jour une connexion REST
  const updateConnection = async () => {
    if (!selectedConnection) return;
    
    setLoading(true);
    setError(null);
    try {
      const connectionData = {
        ...connectionForm,
        code: selectedConnection.name
      };
      
      await apiService.integration.restConnections.update(selectedConnection.name, connectionData);
      
      // Mettre à jour la liste
      fetchConnections();
      
      // Fermer la modale et réinitialiser le formulaire
      setShowEditModal(false);
      resetConnectionForm();
      
      // Afficher le message de succès
      setSuccessMessage("Connexion REST mise à jour avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la connexion REST:", err);
      setError("Impossible de mettre à jour la connexion. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour ajouter un endpoint
  const addEndpoint = async () => {
    if (!selectedConnection) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.restConnections.addEndpoint(selectedConnection.name, endpointForm);
      
      // Recharger les endpoints
      fetchEndpoints(selectedConnection.name);
      
      // Fermer la modale et réinitialiser le formulaire
      setShowEndpointModal(false);
      resetEndpointForm();
      
      // Afficher le message de succès
      setSuccessMessage("Endpoint ajouté avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'ajout de l'endpoint:", err);
      setError("Impossible d'ajouter l'endpoint. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour supprimer un endpoint
  const removeEndpoint = async () => {
    if (!selectedConnection || !selectedEndpoint) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.restConnections.removeEndpoint(selectedConnection.name, selectedEndpoint.name);
      
      // Recharger les endpoints
      fetchEndpoints(selectedConnection.name);
      
      // Réinitialiser l'endpoint sélectionné
      setSelectedEndpoint(null);
      
      // Afficher le message de succès
      setSuccessMessage("Endpoint supprimé avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression de l'endpoint:", err);
      setError("Impossible de supprimer l'endpoint. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour tester un endpoint
  const testEndpoint = async () => {
    if (!selectedConnection || !selectedEndpoint) return;
    
    setLoading(true);
    setError(null);
    try {
      const result = await apiService.integration.restConnections.testEndpoint(
        selectedConnection.name, 
        selectedEndpoint.name
      );
      
      setTestResult(result);
      
      // Afficher le message de succès
      setSuccessMessage("Test de l'endpoint effectué avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors du test de l'endpoint:", err);
      setError("Échec du test de l'endpoint. Veuillez réessayer.");
      setTestResult({
        error: err.message || "Échec de la connexion"
      });
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour exécuter une méthode
  const executeEndpointMethod = async () => {
    if (!selectedConnection || !selectedEndpoint || !executeMethod) return;
    
    setLoading(true);
    setError(null);
    try {
      const result = await apiService.integration.restConnections.executeMethod(
        selectedConnection.name,
        selectedEndpoint.name,
        executeMethod.httpMethod,
        executeParams
      );
      
      setExecuteResult(result);
      
      // Afficher le message de succès
      setSuccessMessage("Méthode exécutée avec succès");
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'exécution de la méthode:", err);
      setError("Échec de l'exécution de la méthode. Veuillez réessayer.");
      setExecuteResult({
        error: err.message || "Échec de l'exécution"
      });
    } finally {
      setLoading(false);
    }
  };

  // Réinitialisation du formulaire de connexion
  const resetConnectionForm = () => {
    setConnectionForm({
      name: '',
      baseUrl: '',
      description: '',
      contentType: 'application/json',
      acceptType: 'application/json',
      defaultHeaders: {},
      followRedirects: true,
      useCompression: false,
      compressionType: '',
      connectionPoolSize: 10,
      validateSsl: true,
      apiKey: '',
      apiKeyHeaderName: ''
    });
  };

  // Réinitialisation du formulaire d'endpoint
  const resetEndpointForm = () => {
    setEndpointForm({
      name: '',
      path: '',
      description: '',
      methods: [],
      headers: {},
      contentType: '',
      acceptType: '',
      timeout: 30,
      version: '1.0'
    });
  };

  // Gestion des changements dans le formulaire de connexion
  const handleConnectionFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConnectionForm(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Gestion des changements dans le formulaire d'endpoint
  const handleEndpointFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEndpointForm(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Ajouter un en-tête à la connexion
  const addHeader = () => {
    if (newHeaderKey.trim() === '') return;
    
    setConnectionForm(prev => ({
      ...prev,
      defaultHeaders: {
        ...prev.defaultHeaders,
        [newHeaderKey]: newHeaderValue
      }
    }));
    
    // Réinitialiser les champs
    setNewHeaderKey('');
    setNewHeaderValue('');
  };

  // Supprimer un en-tête de la connexion
  const removeHeader = (key) => {
    const updatedHeaders = { ...connectionForm.defaultHeaders };
    delete updatedHeaders[key];
    
    setConnectionForm(prev => ({
      ...prev,
      defaultHeaders: updatedHeaders
    }));
  };

  // Ajouter un en-tête à l'endpoint
  const addEndpointHeader = () => {
    if (newHeaderKey.trim() === '') return;
    
    setEndpointForm(prev => ({
      ...prev,
      headers: {
        ...prev.headers,
        [newHeaderKey]: newHeaderValue
      }
    }));
    
    // Réinitialiser les champs
    setNewHeaderKey('');
    setNewHeaderValue('');
  };

  // Supprimer un en-tête de l'endpoint
  const removeEndpointHeader = (key) => {
    const updatedHeaders = { ...endpointForm.headers };
    delete updatedHeaders[key];
    
    setEndpointForm(prev => ({
      ...prev,
      headers: updatedHeaders
    }));
  };

  // Gestion des changements dans le formulaire de méthode
  const handleMethodFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewMethod(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Ajouter une méthode à l'endpoint
  const addMethod = () => {
    if (newMethod.operationId.trim() === '') return;
    
    setEndpointForm(prev => ({
      ...prev,
      methods: [...prev.methods, { ...newMethod }]
    }));
    
    // Réinitialiser le formulaire de méthode
    setNewMethod({
      httpMethod: 'GET',
      operationId: '',
      description: '',
      requiresAuthentication: false,
      requiresRequestBody: false,
      consumes: ['application/json'],
      produces: ['application/json'],
      queryParameters: {},
      pathParameters: {},
      headerParameters: {},
      requestSchema: '',
      responseSchema: '',
      successCode: 200
    });
  };

  // Supprimer une méthode de l'endpoint
  const removeMethod = (index) => {
    const updatedMethods = [...endpointForm.methods];
    updatedMethods.splice(index, 1);
    
    setEndpointForm(prev => ({
      ...prev,
      methods: updatedMethods
    }));
  };

  // Gestion des paramètres d'exécution
  const handleExecuteParamChange = (key, value) => {
    setExecuteParams(prev => ({
      ...prev,
      [key]: value
    }));
  };

  // Colonnes pour la grille de données des connexions
  const columns = [
    {
      header: 'Nom',
      key: 'name',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.name}
        </div>
      )
    },
    {
      header: 'URL de base',
      key: 'baseUrl',
      render: (row) => (
        <div className="text-sm font-mono">
          {row.baseUrl || 'N/A'}
        </div>
      )
    },
    {
      header: 'Description',
      key: 'description',
      render: (row) => (
        <div className="text-sm text-gray-600 dark:text-gray-300 truncate max-w-md">
          {row.description || 'Aucune description'}
        </div>
      )
    },
    {
      header: 'Paramètres',
      key: 'settings',
      render: (row) => (
        <div className="flex flex-wrap gap-1">
          <div className="text-xs bg-gray-100 dark:bg-gray-700 py-1 px-2 rounded-full">
            {row.contentType || 'application/json'}
          </div>
          {row.validateSsl && (
            <div className="text-xs bg-green-100 dark:bg-green-900/20 text-green-800 dark:text-green-400 py-1 px-2 rounded-full">
              SSL
            </div>
          )}
          {row.followRedirects && (
            <div className="text-xs bg-blue-100 dark:bg-blue-900/20 text-blue-800 dark:text-blue-400 py-1 px-2 rounded-full">
              Redirects
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Endpoints',
      key: 'endpoints',
      render: (row) => (
        <div className="text-sm">
          {row.endpoints?.length || 0} endpoint(s)
        </div>
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              fetchConnection(row.name);
              fetchEndpoints(row.name);
            }}
          >
            Détails
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            onClick={(e) => {
              e.stopPropagation();
              fetchConnection(row.name);
              setShowEditModal(true);
            }}
          >
            Modifier
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Plus}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedConnection(row);
              resetEndpointForm();
              setShowEndpointModal(true);
            }}
          >
            Endpoint
          </Button>
        </div>
      )
    }
  ];

  // Colonnes pour la grille de données des endpoints
  const endpointColumns = [
    {
      header: 'Nom',
      key: 'name',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.name}
        </div>
      )
    },
    {
      header: 'Chemin',
      key: 'path',
      render: (row) => (
        <div className="text-sm font-mono">
          {row.path || '/'}
        </div>
      )
    },
    {
      header: 'Description',
      key: 'description',
      render: (row) => (
        <div className="text-sm text-gray-600 dark:text-gray-300 truncate max-w-xs">
          {row.description || 'Aucune description'}
        </div>
      )
    },
    {
      header: 'Méthodes',
      key: 'methods',
      render: (row) => (
        <div className="flex flex-wrap gap-1">
          {row.methods?.map((method, index) => (
            <div 
              key={index}
              className={`text-xs py-1 px-2 rounded-full
                ${method.httpMethod === 'GET' ? 'bg-blue-100 dark:bg-blue-900/20 text-blue-800 dark:text-blue-400' : ''}
                ${method.httpMethod === 'POST' ? 'bg-green-100 dark:bg-green-900/20 text-green-800 dark:text-green-400' : ''}
                ${method.httpMethod === 'PUT' ? 'bg-yellow-100 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-400' : ''}
                ${method.httpMethod === 'DELETE' ? 'bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-400' : ''}
                ${method.httpMethod === 'PATCH' ? 'bg-purple-100 dark:bg-purple-900/20 text-purple-800 dark:text-purple-400' : ''}
                ${method.httpMethod === 'HEAD' || method.httpMethod === 'OPTIONS' ? 'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400' : ''}
              `}
            >
              {method.httpMethod}
            </div>
          ))}
          {(!row.methods || row.methods.length === 0) && (
            <div className="text-xs text-gray-500 dark:text-gray-400">
              Aucune méthode
            </div>
          )}
        </div>
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Zap}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedEndpoint(row);
              setTestResult(null);
              setShowTestModal(true);
            }}
          >
            Tester
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Play}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedEndpoint(row);
              setExecuteMethod(null);
              setExecuteParams({});
              setExecuteResult(null);
              setShowExecuteModal(true);
            }}
            disabled={!row.methods || row.methods.length === 0}
          >
            Exécuter
          </Button>
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedEndpoint(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Globe className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Connexions REST
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les connexions REST pour vos intégrations
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchConnections}
            loading={loading && !showCreateModal && !showEditModal && !showEndpointModal && !showDeleteModal && !showTestModal && !showExecuteModal}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetConnectionForm();
              setShowCreateModal(true);
            }}
          >
            Nouvelle Connexion
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher une connexion..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des connexions */}
      <Card>
        <DataGrid
          data={connections.filter(conn => 
            conn.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            conn.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            conn.baseUrl?.toLowerCase().includes(searchTerm.toLowerCase())
          )}
          columns={columns}
          emptyMessage="Aucune connexion REST trouvée"
          loading={loading && !showDetailsModal}
          onRowClick={(row) => {
            fetchConnection(row.name);
            fetchEndpoints(row.name);
          }}
        />
      </Card>

      {/* Modal de création de connexion */}
      {showCreateModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer une nouvelle connexion REST
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={connectionForm.name}
                      onChange={handleConnectionFormChange}
                      required
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      URL de base*
                    </label>
                    <input
                      type="text"
                      name="baseUrl"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={connectionForm.baseUrl}
                      onChange={handleConnectionFormChange}
                      placeholder="https://api.example.com"
                      required
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    rows="2"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={connectionForm.description}
                    onChange={handleConnectionFormChange}
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de contenu
                    </label>
                    <input
                      type="text"
                      name="contentType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={connectionForm.contentType}
                      onChange={handleConnectionFormChange}
                      placeholder="application/json"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type accepté
                    </label>
                    <input
                      type="text"
                      name="acceptType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={connectionForm.acceptType}
                      onChange={handleConnectionFormChange}
                      placeholder="application/json"
                    />
                  </div>
                </div>
                
                {/* En-têtes par défaut */}
                <div>
                  <div className="flex justify-between items-center mb-1">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      En-têtes par défaut
                    </label>
                  </div>
                  
                  <div className="flex space-x-2 mb-2">
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Clé"
                      value={newHeaderKey}
                      onChange={(e) => setNewHeaderKey(e.target.value)}
                    />
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Valeur"
                      value={newHeaderValue}
                      onChange={(e) => setNewHeaderValue(e.target.value)}
                    />
                    <Button
                      variant="outline"
                      icon={Plus}
                      onClick={addHeader}
                      disabled={!newHeaderKey.trim()}
                    >
                      Ajouter
                    </Button>
                  </div>
                  
                  <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                    {Object.keys(connectionForm.defaultHeaders).length > 0 ? (
                      <ul className="space-y-2">
                        {Object.entries(connectionForm.defaultHeaders).map(([key, value]) => (
                          <li key={key} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                            <div>
                              <span className="font-medium text-gray-900 dark:text-white">{key}:</span>
                              <span className="ml-2 text-gray-700 dark:text-gray-300">{value}</span>
                            </div>
                            <button
                              className="text-red-500 hover:text-red-600"
                              onClick={() => removeHeader(key)}
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                        Aucun en-tête défini
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Paramètres avancés */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                  <h3 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                    Paramètres avancés
                  </h3>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="followRedirects"
                        name="followRedirects"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        checked={connectionForm.followRedirects}
                        onChange={handleConnectionFormChange}
                      />
                      <label htmlFor="followRedirects" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Suivre les redirections
                      </label>
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="validateSsl"
                        name="validateSsl"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        checked={connectionForm.validateSsl}
                        onChange={handleConnectionFormChange}
                      />
                      <label htmlFor="validateSsl" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Valider le certificat SSL
                      </label>
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="useCompression"
                        name="useCompression"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        checked={connectionForm.useCompression}
                        onChange={handleConnectionFormChange}
                      />
                      <label htmlFor="useCompression" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Utiliser la compression
                      </label>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Type de compression
                      </label>
                      <input
                        type="text"
                        name="compressionType"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.compressionType}
                        onChange={handleConnectionFormChange}
                        placeholder="gzip"
                        disabled={!connectionForm.useCompression}
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Taille du pool de connexions
                      </label>
                      <input
                        type="number"
                        name="connectionPoolSize"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.connectionPoolSize}
                        onChange={handleConnectionFormChange}
                        min="1"
                      />
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Clé API
                      </label>
                      <input
                        type="text"
                        name="apiKey"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.apiKey}
                        onChange={handleConnectionFormChange}
                        placeholder="Optionnel"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nom d'en-tête pour la clé API
                      </label>
                      <input
                        type="text"
                        name="apiKeyHeaderName"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.apiKeyHeaderName}
                        onChange={handleConnectionFormChange}
                        placeholder="X-API-Key"
                        disabled={!connectionForm.apiKey}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={PlusCircle}
                  onClick={createConnection}
                  loading={loading}
                  disabled={!connectionForm.name || !connectionForm.baseUrl}
                >
                  Créer la connexion
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'édition de connexion - Similaire à la création mais avec les données remplies */}
      {showEditModal && selectedConnection && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Modifier la connexion REST: {selectedConnection.name}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowEditModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              {/* Même contenu que le modal de création mais avec le champ name désactivé */}
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400 cursor-not-allowed"
                      value={connectionForm.name}
                      readOnly
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      URL de base*
                    </label>
                    <input
                      type="text"
                      name="baseUrl"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={connectionForm.baseUrl}
                      onChange={handleConnectionFormChange}
                      placeholder="https://api.example.com"
                      required
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    rows="2"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={connectionForm.description}
                    onChange={handleConnectionFormChange}
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de contenu
                    </label>
                    <input
                      type="text"
                      name="contentType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={connectionForm.contentType}
                      onChange={handleConnectionFormChange}
                      placeholder="application/json"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type accepté
                    </label>
                    <input
                      type="text"
                      name="acceptType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={connectionForm.acceptType}
                      onChange={handleConnectionFormChange}
                      placeholder="application/json"
                    />
                  </div>
                </div>
                
                {/* En-têtes par défaut */}
                <div>
                  <div className="flex justify-between items-center mb-1">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      En-têtes par défaut
                    </label>
                  </div>
                  
                  <div className="flex space-x-2 mb-2">
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Clé"
                      value={newHeaderKey}
                      onChange={(e) => setNewHeaderKey(e.target.value)}
                    />
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Valeur"
                      value={newHeaderValue}
                      onChange={(e) => setNewHeaderValue(e.target.value)}
                    />
                    <Button
                      variant="outline"
                      icon={Plus}
                      onClick={addHeader}
                      disabled={!newHeaderKey.trim()}
                    >
                      Ajouter
                    </Button>
                  </div>
                  
                  <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                    {Object.keys(connectionForm.defaultHeaders).length > 0 ? (
                      <ul className="space-y-2">
                        {Object.entries(connectionForm.defaultHeaders).map(([key, value]) => (
                          <li key={key} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                            <div>
                              <span className="font-medium text-gray-900 dark:text-white">{key}:</span>
                              <span className="ml-2 text-gray-700 dark:text-gray-300">{value}</span>
                            </div>
                            <button
                              className="text-red-500 hover:text-red-600"
                              onClick={() => removeHeader(key)}
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                        Aucun en-tête défini
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Paramètres avancés */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                  <h3 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                    Paramètres avancés
                  </h3>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="followRedirects_edit"
                        name="followRedirects"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        checked={connectionForm.followRedirects}
                        onChange={handleConnectionFormChange}
                      />
                      <label htmlFor="followRedirects_edit" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Suivre les redirections
                      </label>
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="validateSsl_edit"
                        name="validateSsl"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        checked={connectionForm.validateSsl}
                        onChange={handleConnectionFormChange}
                      />
                      <label htmlFor="validateSsl_edit" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Valider le certificat SSL
                      </label>
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="useCompression_edit"
                        name="useCompression"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        checked={connectionForm.useCompression}
                        onChange={handleConnectionFormChange}
                      />
                      <label htmlFor="useCompression_edit" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Utiliser la compression
                      </label>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Type de compression
                      </label>
                      <input
                        type="text"
                        name="compressionType"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.compressionType}
                        onChange={handleConnectionFormChange}
                        placeholder="gzip"
                        disabled={!connectionForm.useCompression}
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Taille du pool de connexions
                      </label>
                      <input
                        type="number"
                        name="connectionPoolSize"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.connectionPoolSize}
                        onChange={handleConnectionFormChange}
                        min="1"
                      />
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Clé API
                      </label>
                      <input
                        type="text"
                        name="apiKey"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.apiKey}
                        onChange={handleConnectionFormChange}
                        placeholder="Optionnel"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nom d'en-tête pour la clé API
                      </label>
                      <input
                        type="text"
                        name="apiKeyHeaderName"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={connectionForm.apiKeyHeaderName}
                        onChange={handleConnectionFormChange}
                        placeholder="X-API-Key"
                        disabled={!connectionForm.apiKey}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowEditModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Edit}
                  onClick={updateConnection}
                  loading={loading}
                  disabled={!connectionForm.baseUrl}
                >
                  Mettre à jour
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de détails de connexion avec liste des endpoints */}
      {showDetailsModal && selectedConnection && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Détails de la connexion: {selectedConnection.name}
                </h2>
                <div className="flex items-center space-x-2">
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Plus}
                    onClick={() => {
                      setShowDetailsModal(false);
                      resetEndpointForm();
                      setShowEndpointModal(true);
                    }}
                  >
                    Ajouter un endpoint
                  </Button>
                  <button 
                    className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    onClick={() => setShowDetailsModal(false)}
                  >
                    <XCircle className="h-6 w-6" />
                  </button>
                </div>
              </div>
              
              {/* Informations de base de la connexion */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div>
                  <h3 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                    Informations de base
                  </h3>
                  
                  <div className="space-y-2">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500 dark:text-gray-400">URL de base:</span>
                      <span className="text-sm font-medium text-gray-900 dark:text-white">{selectedConnection.baseUrl}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500 dark:text-gray-400">Type de contenu:</span>
                      <span className="text-sm font-medium text-gray-900 dark:text-white">{selectedConnection.contentType}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500 dark:text-gray-400">Type accepté:</span>
                      <span className="text-sm font-medium text-gray-900 dark:text-white">{selectedConnection.acceptType}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500 dark:text-gray-400">Suit les redirections:</span>
                      <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {selectedConnection.followRedirects ? 'Oui' : 'Non'}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500 dark:text-gray-400">Validation SSL:</span>
                      <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {selectedConnection.validateSsl ? 'Oui' : 'Non'}
                      </span>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h3 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                    En-têtes par défaut
                  </h3>
                  
                  <div className="bg-gray-50 dark:bg-gray-700/30 rounded-md p-3 max-h-40 overflow-y-auto">
                    {Object.keys(selectedConnection.defaultHeaders || {}).length > 0 ? (
                      <ul className="space-y-2">
                        {Object.entries(selectedConnection.defaultHeaders).map(([key, value]) => (
                          <li key={key} className="flex justify-between">
                            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{key}:</span>
                            <span className="text-sm text-gray-600 dark:text-gray-400">{value}</span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center py-3 text-sm text-gray-500 dark:text-gray-400">
                        Aucun en-tête défini
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              {/* Liste des endpoints */}
              <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Endpoints ({endpoints.length})
                </h3>
                
                {endpointsLoading ? (
                  <div className="flex justify-center items-center h-32">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  </div>
                ) : (
                  <DataGrid
                    data={endpoints}
                    columns={endpointColumns}
                    emptyMessage="Aucun endpoint trouvé"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'ajout d'endpoint */}
      {showEndpointModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Ajouter un endpoint à {selectedConnection?.name}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowEndpointModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={endpointForm.name}
                      onChange={handleEndpointFormChange}
                      required
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Chemin*
                    </label>
                    <input
                      type="text"
                      name="path"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={endpointForm.path}
                      onChange={handleEndpointFormChange}
                      placeholder="/users"
                      required
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    rows="2"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={endpointForm.description}
                    onChange={handleEndpointFormChange}
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type de contenu
                    </label>
                    <input
                      type="text"
                      name="contentType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={endpointForm.contentType}
                      onChange={handleEndpointFormChange}
                      placeholder="Hérité de la connexion"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type accepté
                    </label>
                    <input
                      type="text"
                      name="acceptType"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={endpointForm.acceptType}
                      onChange={handleEndpointFormChange}
                      placeholder="Hérité de la connexion"
                    />
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Timeout (secondes)
                    </label>
                    <input
                      type="number"
                      name="timeout"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={endpointForm.timeout}
                      onChange={handleEndpointFormChange}
                      min="1"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version
                    </label>
                    <input
                      type="text"
                      name="version"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={endpointForm.version}
                      onChange={handleEndpointFormChange}
                    />
                  </div>
                </div>
                
                {/* En-têtes spécifiques */}
                <div>
                  <div className="flex justify-between items-center mb-1">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      En-têtes spécifiques
                    </label>
                  </div>
                  
                  <div className="flex space-x-2 mb-2">
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Clé"
                      value={newHeaderKey}
                      onChange={(e) => setNewHeaderKey(e.target.value)}
                    />
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Valeur"
                      value={newHeaderValue}
                      onChange={(e) => setNewHeaderValue(e.target.value)}
                    />
                    <Button
                      variant="outline"
                      icon={Plus}
                      onClick={addEndpointHeader}
                      disabled={!newHeaderKey.trim()}
                    >
                      Ajouter
                    </Button>
                  </div>
                  
                  <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                    {Object.keys(endpointForm.headers).length > 0 ? (
                      <ul className="space-y-2">
                        {Object.entries(endpointForm.headers).map(([key, value]) => (
                          <li key={key} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                            <div>
                              <span className="font-medium text-gray-900 dark:text-white">{key}:</span>
                              <span className="ml-2 text-gray-700 dark:text-gray-300">{value}</span>
                            </div>
                            <button
                              className="text-red-500 hover:text-red-600"
                              onClick={() => removeEndpointHeader(key)}
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                        Aucun en-tête défini
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Méthodes HTTP */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                  <h3 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                    Méthodes HTTP
                  </h3>
                  
                  <div className="space-y-4 mb-4 bg-gray-50 dark:bg-gray-800 p-4 rounded-md">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Méthode HTTP*
                        </label>
                        <select
                          name="httpMethod"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={newMethod.httpMethod}
                          onChange={handleMethodFormChange}
                        >
                          {httpMethods.map(method => (
                            <option key={method} value={method}>{method}</option>
                          ))}
                        </select>
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          ID d'opération*
                        </label>
                        <input
                          type="text"
                          name="operationId"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={newMethod.operationId}
                          onChange={handleMethodFormChange}
                          placeholder="getUserDetails"
                          required
                        />
                      </div>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Description
                      </label>
                      <input
                        type="text"
                        name="description"
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={newMethod.description}
                        onChange={handleMethodFormChange}
                      />
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="requiresAuthentication"
                          name="requiresAuthentication"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          checked={newMethod.requiresAuthentication}
                          onChange={handleMethodFormChange}
                        />
                        <label htmlFor="requiresAuthentication" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                          Nécessite authentification
                        </label>
                      </div>
                      
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="requiresRequestBody"
                          name="requiresRequestBody"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          checked={newMethod.requiresRequestBody}
                          onChange={handleMethodFormChange}
                        />
                        <label htmlFor="requiresRequestBody" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                          Nécessite corps de requête
                        </label>
                      </div>
                    </div>
                    
                    <div className="flex justify-end">
                      <Button
                        variant="outline"
                        icon={Plus}
                        onClick={addMethod}
                        disabled={!newMethod.operationId.trim()}
                      >
                        Ajouter méthode
                      </Button>
                    </div>
                  </div>
                  
                  {/* Liste des méthodes ajoutées */}
                  <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                    {endpointForm.methods.length > 0 ? (
                      <ul className="space-y-2">
                        {endpointForm.methods.map((method, index) => (
                          <li key={index} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                            <div>
                              <span className={`px-2 py-1 rounded-full text-xs
                                ${method.httpMethod === 'GET' ? 'bg-blue-100 dark:bg-blue-900/20 text-blue-800 dark:text-blue-400' : ''}
                                ${method.httpMethod === 'POST' ? 'bg-green-100 dark:bg-green-900/20 text-green-800 dark:text-green-400' : ''}
                                ${method.httpMethod === 'PUT' ? 'bg-yellow-100 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-400' : ''}
                                ${method.httpMethod === 'DELETE' ? 'bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-400' : ''}
                                ${method.httpMethod === 'PATCH' ? 'bg-purple-100 dark:bg-purple-900/20 text-purple-800 dark:text-purple-400' : ''}
                                ${method.httpMethod === 'HEAD' || method.httpMethod === 'OPTIONS' ? 'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-400' : ''}
                              `}>
                                {method.httpMethod}
                              </span>
                              <span className="ml-2 font-medium text-gray-900 dark:text-white">{method.operationId}</span>
                              {method.description && (
                                <span className="ml-2 text-gray-500 dark:text-gray-400">
                                  - {method.description}
                                </span>
                              )}
                            </div>
                            <button
                              className="text-red-500 hover:text-red-600"
                              onClick={() => removeMethod(index)}
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center py-3 text-gray-500 dark:text-gray-400">
                        Aucune méthode définie
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowEndpointModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Plus}
                  onClick={addEndpoint}
                  loading={loading}
                  disabled={!endpointForm.name || !endpointForm.path}
                >
                  Ajouter l'endpoint
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de test d'endpoint */}
      {showTestModal && selectedEndpoint && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Tester l'endpoint: {selectedEndpoint.name}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowTestModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-md">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Chemin:
                      </p>
                      <p className="text-sm font-mono text-blue-600 dark:text-blue-400">
                        {selectedEndpoint.path}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        URL complète:
                      </p>
                      <p className="text-sm font-mono text-blue-600 dark:text-blue-400 break-all">
                        {selectedConnection?.baseUrl}{selectedEndpoint.path.startsWith('/') ? '' : '/'}{selectedEndpoint.path}
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="flex justify-center">
                  <Button
                    variant="primary"
                    icon={Zap}
                    onClick={testEndpoint}
                    loading={loading}
                  >
                    Tester la connexion
                  </Button>
                </div>
                
                {/* Résultat du test */}
                {testResult && (
                  <div className={`mt-4 p-4 rounded-md ${testResult.error ? 'bg-red-50 dark:bg-red-900/20' : 'bg-green-50 dark:bg-green-900/20'}`}>
                    <h3 className={`text-md font-medium mb-2 ${testResult.error ? 'text-red-800 dark:text-red-400' : 'text-green-800 dark:text-green-400'}`}>
                      {testResult.error ? 'Échec du test' : 'Test réussi'}
                    </h3>
                    
                    {testResult.error ? (
                      <p className="text-sm text-red-700 dark:text-red-300">
                        {testResult.error}
                      </p>
                    ) : (
                      <div className="text-sm text-green-700 dark:text-green-300">
                        <div className="grid grid-cols-2 gap-2">
                          <div>
                            <p className="font-medium">Statut:</p>
                            <p>{testResult.status || '200 OK'}</p>
                          </div>
                          <div>
                            <p className="font-medium">Temps de réponse:</p>
                            <p>{testResult.responseTime || '0'} ms</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              
              <div className="mt-6 flex justify-end">
                <Button
                  variant="outline"
                  onClick={() => setShowTestModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression d'endpoint */}
      {showDeleteModal && selectedEndpoint && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20 mb-4">
                  <AlertTriangle className="h-6 w-6 text-red-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Confirmer la suppression
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir supprimer l'endpoint <span className="font-semibold">{selectedEndpoint.name}</span> ? 
                  Cette action est irréversible.
                </p>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  icon={Trash2}
                  onClick={removeEndpoint}
                  loading={loading}
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'exécution de méthode */}
      {showExecuteModal && selectedEndpoint && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Exécuter une méthode: {selectedEndpoint.name}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowExecuteModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                {/* Sélection de la méthode */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Méthode à exécuter*
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={executeMethod ? JSON.stringify(executeMethod) : ''}
                    onChange={(e) => setExecuteMethod(e.target.value ? JSON.parse(e.target.value) : null)}
                  >
                    <option value="">Sélectionner une méthode</option>
                    {selectedEndpoint.methods?.map((method, index) => (
                      <option key={index} value={JSON.stringify(method)}>
                        {method.httpMethod} - {method.operationId}
                      </option>
                    ))}
                  </select>
                </div>
                
                {executeMethod && (
                  <>
                    {/* Paramètres d'exécution */}
                    <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                      <h3 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                        Paramètres
                      </h3>
                      
                      {/* Paramètres spécifiques basés sur le type de méthode */}
                      {executeMethod.requiresRequestBody && (
                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Corps de la requête (JSON)
                          </label>
                          <textarea
                            rows="5"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-mono"
                            value={executeParams.body ? JSON.stringify(executeParams.body, null, 2) : '{}'}
                            onChange={(e) => {
                              try {
                                const parsed = JSON.parse(e.target.value);
                                handleExecuteParamChange('body', parsed);
                              } catch (error) {
                                // Ignorer les erreurs de parsing pendant la saisie
                              }
                            }}
                            placeholder="{ ... }"
                          />
                        </div>
                      )}
                      
                     {/* Paramètres d'URL pour GET, DELETE, etc. */}
                      {(executeMethod.httpMethod === 'GET' || executeMethod.httpMethod === 'DELETE') && (
                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Paramètres de requête
                          </label>
                          <div className="space-y-2">
                            {Object.keys(executeMethod.queryParameters || {}).map((param) => (
                              <div key={param} className="grid grid-cols-3 gap-2">
                                <div className="text-sm text-gray-700 dark:text-gray-300 py-2">
                                  {param}:
                                </div>
                                <div className="col-span-2">
                                  <input
                                    type="text"
                                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    value={executeParams[param] || ''}
                                    onChange={(e) => handleExecuteParamChange(param, e.target.value)}
                                    placeholder={executeMethod.queryParameters[param] || ''}
                                  />
                                </div>
                              </div>
                            ))}
                            
                            {/* Si pas de paramètres définis, permettre de saisir des paramètres librement */}
                            {Object.keys(executeMethod.queryParameters || {}).length === 0 && (
  <div className="grid grid-cols-3 gap-2">
    <div className="text-sm text-gray-700 dark:text-gray-300 py-2">
      Paramètres:
    </div>
    <div className="col-span-2">
      <textarea
        rows="3"
        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-mono"
        value={executeParams.queryParams ? JSON.stringify(executeParams.queryParams, null, 2) : '{}'}
        onChange={(e) => {
          try {
            const parsed = JSON.parse(e.target.value);
            handleExecuteParamChange('queryParams', parsed);
          } catch (error) {
            // Ignorer les erreurs de parsing pendant la saisie
          }
        }}
        placeholder={'{ "param1": "value1", "param2": "value2" }'}
      />
    </div>
  </div>
)}
                          </div>
                        </div>
                      )}
                      
                      {/* Paramètres de chemin pour tous les types de méthodes */}
                      {Object.keys(executeMethod.pathParameters || {}).length > 0 && (
                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Paramètres de chemin
                          </label>
                          <div className="space-y-2">
                            {Object.keys(executeMethod.pathParameters).map((param) => (
                              <div key={param} className="grid grid-cols-3 gap-2">
                                <div className="text-sm text-gray-700 dark:text-gray-300 py-2">
                                  {param}:
                                </div>
                                <div className="col-span-2">
                                  <input
                                    type="text"
                                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    value={executeParams[param] || ''}
                                    onChange={(e) => handleExecuteParamChange(param, e.target.value)}
                                    placeholder={executeMethod.pathParameters[param] || ''}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      
                      {/* En-têtes personnalisés */}
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          En-têtes personnalisés
                        </label>
                        <textarea
                          rows="3"
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-mono"
                          value={executeParams.headers ? JSON.stringify(executeParams.headers, null, 2) : '{}'}
                          onChange={(e) => {
                            try {
                              const parsed = JSON.parse(e.target.value);
                              handleExecuteParamChange('headers', parsed);
                            } catch (error) {
                              // Ignorer les erreurs de parsing pendant la saisie
                            }
                          }}
                          placeholder={'{ "X-Custom-Header": "value" }'}
                        />
                      </div>
                    </div>
                    
                    {/* Bouton d'exécution */}
                    <div className="flex justify-center">
                      <Button
                        variant="primary"
                        icon={Play}
                        onClick={executeEndpointMethod}
                        loading={loading}
                      >
                        Exécuter
                      </Button>
                    </div>
                    
                    {/* Résultat de l'exécution */}
                    {executeResult && (
                      <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                        <h3 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                          Résultat
                        </h3>
                        
                        {executeResult.error ? (
                          <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-md">
                            <h4 className="text-sm font-medium text-red-800 dark:text-red-400 mb-1">
                              Erreur
                            </h4>
                            <p className="text-sm text-red-700 dark:text-red-300">
                              {executeResult.error}
                            </p>
                          </div>
                        ) : (
                          <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3">
                            <div className="max-h-64 overflow-y-auto">
                              <pre className="text-sm text-gray-800 dark:text-gray-200 font-mono whitespace-pre-wrap">
                                {JSON.stringify(executeResult, null, 2)}
                              </pre>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              
              <div className="mt-6 flex justify-end">
                <Button
                  variant="outline"
                  onClick={() => setShowExecuteModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestConnectionsPage;