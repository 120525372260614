import React, { useState, useEffect } from 'react';
import { 
  Settings, 
  Plus, 
  Edit, 
  Trash2, 
  RefreshCw, 
  CheckCircle, 
  AlertTriangle,
  X,
  Save,
  Filter,
  Search,
  ExternalLink,
  List,
  Key
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const Configurations = () => {
  // États
  const [configurations, setConfigurations] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [filterGroup, setFilterGroup] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  
  // Formulaire configuration
  const [formValues, setFormValues] = useState({
    key: '',
    description: '',
    scope: 'GLOBAL',
    groupName: '',
    isMandatory: false,
    defaultValue: '',
    validationRule: '',
    isActive: true
  });
  
  // Scopes disponibles
  const configScopes = [
    { value: 'GLOBAL', label: 'Global' },
    { value: 'ENVIRONMENT', label: 'Environnement' },
    { value: 'TENANT', label: 'Tenant' },
    { value: 'APPLICATION', label: 'Application' }
  ];
  
  // Chargement des données
  const fetchConfigurations = async () => {
    setLoading(true);
    try {
      const data = await apiService.config.configurations.getAll();
      setConfigurations(data || []);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des configurations:', err);
      setError('Impossible de charger les configurations. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchGroups = async () => {
    try {
      const data = await apiService.config.groups.getAll();
      setGroups(data || []);
    } catch (err) {
      console.error('Erreur lors du chargement des groupes:', err);
    }
  };
  
  useEffect(() => {
    Promise.all([fetchConfigurations(), fetchGroups()]);
  }, []);
  
  // Gestionnaires d'événements
  const handleOpenModal = (mode, config = null) => {
    setModalMode(mode);
    if (config) {
      setSelectedConfig(config);
      setFormValues({
        key: config.key,
        description: config.description || '',
        scope: config.scope,
        groupName: config.group ? config.group.name : '',
        isMandatory: config.isMandatory,
        defaultValue: config.defaultValue || '',
        validationRule: config.validationRule || '',
        isActive: config.isActive
      });
    } else {
      setSelectedConfig(null);
      setFormValues({
        key: '',
        description: '',
        scope: 'GLOBAL',
        groupName: '',
        isMandatory: false,
        defaultValue: '',
        validationRule: '',
        isActive: true
      });
    }
    setShowModal(true);
  };
  
  const handleFilterChange = (e) => {
    setFilterGroup(e.target.value);
    
    if (e.target.value === 'all') {
      fetchConfigurations();
    } else {
      fetchConfigurationsByGroup(e.target.value);
    }
  };
  
  const fetchConfigurationsByGroup = async (groupName) => {
    setLoading(true);
    try {
      const data = await apiService.config.configurations.getByGroup(groupName);
      setConfigurations(data || []);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des configurations par groupe:', err);
      setError('Impossible de charger les configurations. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const refreshCache = async () => {
    setLoading(true);
    try {
      await apiService.config.configurations.refreshCache();
      setSuccessMessage('Cache rafraîchi avec succès');
      fetchConfigurations();
    } catch (err) {
      console.error('Erreur lors du rafraîchissement du cache:', err);
      setError('Impossible de rafraîchir le cache. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (modalMode === 'create') {
        await apiService.config.configurations.create(formValues);
        setSuccessMessage('Configuration créée avec succès');
      } else {
        await apiService.config.configurations.update(selectedConfig.key, formValues);
        setSuccessMessage('Configuration mise à jour avec succès');
      }
      
      setShowModal(false);
      if (filterGroup === 'all') {
        fetchConfigurations();
      } else {
        fetchConfigurationsByGroup(filterGroup);
      }
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDelete = async (key) => {
    if (!window.confirm(`Êtes-vous sûr de vouloir supprimer la configuration ${key} ?`)) {
      return;
    }
    
    setLoading(true);
    try {
      await apiService.config.configurations.delete(key);
      setSuccessMessage('Configuration supprimée avec succès');
      if (filterGroup === 'all') {
        fetchConfigurations();
      } else {
        fetchConfigurationsByGroup(filterGroup);
      }
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError('Impossible de supprimer la configuration. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrage local par recherche
  const filteredConfigurations = configurations.filter(config => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      config.key.toLowerCase().includes(searchTermLower) ||
      (config.description && config.description.toLowerCase().includes(searchTermLower)) ||
      (config.group && config.group.name.toLowerCase().includes(searchTermLower))
    );
  });
  
  // Configuration des colonnes
  const columns = [
    {
      key: 'key',
      header: 'Clé',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <Key className="h-4 w-4 mr-2 text-gray-500" />
          <span className="font-medium text-gray-900 dark:text-white">{row.key}</span>
        </div>
      ),
    },
    {
      key: 'description',
      header: 'Description',
      sortable: true,
      render: (row) => (
        <span className="text-gray-700 dark:text-gray-300">
          {row.description || '-'}
        </span>
      ),
    },
    {
      key: 'group',
      header: 'Groupe',
      sortable: true,
      render: (row) => (
        <span className="text-gray-700 dark:text-gray-300">
          {row.group ? row.group.name : '-'}
        </span>
      ),
    },
    {
      key: 'scope',
      header: 'Portée',
      sortable: true,
      render: (row) => {
        const scopeColors = {
          GLOBAL: 'blue',
          ENVIRONMENT: 'green',
          TENANT: 'purple',
          APPLICATION: 'orange'
        };
        
        return (
          <StatusBadge 
            status={scopeColors[row.scope] || 'gray'} 
            customLabel={row.scope}
          />
        );
      },
    },
    {
      key: 'isActive',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={row.isActive ? 'active' : 'inactive'} 
          customLabel={row.isActive ? 'Actif' : 'Inactif'}
        />
      ),
    },
    {
      key: 'isMandatory',
      header: 'Obligatoire',
      sortable: true,
      render: (row) => (
        <span className={`text-${row.isMandatory ? 'red' : 'gray'}-500`}>
          {row.isMandatory ? 'Oui' : 'Non'}
        </span>
      ),
    },
    {
      key: 'values',
      header: 'Valeurs',
      render: (row) => (
        <div className="flex items-center">
          <Button
            variant="text"
            size="sm"
            icon={List}
            title="Voir les valeurs"
            onClick={() => window.open(`/CFG/configvalues?key=${row.key}`, '_self')}
          >
            {row.values && row.values.length > 0 ? `${row.values.length}` : '0'}
          </Button>
        </div>
      ),
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            title="Modifier"
            onClick={() => handleOpenModal('edit', row)}
          />
          <Button
            variant="outline"
            size="sm"
            icon={Trash2}
            title="Supprimer"
            onClick={() => handleDelete(row.key)}
          />
        </div>
      ),
    },
  ];
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Settings className="mr-2 h-6 w-6 text-blue-500" />
            Configurations
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les clés de configuration du système
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshCache}
            title="Rafraîchir le cache"
          >
            Rafraîchir le cache
          </Button>
          <Button
            variant="primary"
            icon={Plus}
            onClick={() => handleOpenModal('create')}
          >
            Nouvelle configuration
          </Button>
        </div>
      </div>
      
      {/* Messages */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 mb-4">
          <div className="flex">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <span className="text-green-700 dark:text-green-300">{successMessage}</span>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 mb-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-red-700 dark:text-red-300">{error}</span>
          </div>
        </div>
      )}
      
      {/* Filtres */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <div className="w-full md:w-1/4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Filtrer par groupe
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              value={filterGroup}
              onChange={handleFilterChange}
            >
              <option value="all">Tous les groupes</option>
              {groups.map((group) => (
                <option key={group.id} value={group.name}>
                  {group.name}
                </option>
              ))}
            </select>
          </div>
          
          <div className="flex-1 relative">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Rechercher
            </label>
            <div className="relative">
              <input
                type="text"
                className="w-full px-3 py-2 pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                placeholder="Rechercher par clé, description..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            </div>
          </div>
        </div>
      </div>
      
      {/* Liste des configurations */}
      <Card>
        <DataGrid
          data={filteredConfigurations}
          columns={columns}
          loading={loading}
          emptyMessage="Aucune configuration trouvée"
        />
      </Card>
      
      {/* Modal de création/édition */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                {modalMode === 'create' ? 'Nouvelle configuration' : 'Modifier la configuration'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmit} className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Clé - ne peut pas être modifiée en mode édition */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Clé *
                  </label>
                  <input
                    type="text"
                    name="key"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.key}
                    onChange={handleInputChange}
                    required
                    disabled={modalMode === 'edit'}
                  />
                </div>
                
                {/* Groupe */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Groupe
                  </label>
                  <select
                    name="groupName"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.groupName}
                    onChange={handleInputChange}
                  >
                    <option value="">Aucun groupe</option>
                    {groups.map((group) => (
                      <option key={group.id} value={group.name}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </div>
                
                {/* Portée */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Portée *
                  </label>
                  <select
                    name="scope"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.scope}
                    onChange={handleInputChange}
                    required
                  >
                    {configScopes.map((scope) => (
                      <option key={scope.value} value={scope.value}>
                        {scope.label}
                      </option>
                    ))}
                  </select>
                </div>
                
                {/* Valeur par défaut */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Valeur par défaut
                  </label>
                  <input
                    type="text"
                    name="defaultValue"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.defaultValue}
                    onChange={handleInputChange}
                  />
                </div>
                
                {/* Règle de validation */}
                <div className="col-span-1 md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Règle de validation
                  </label>
                  <input
                    type="text"
                    name="validationRule"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.validationRule}
                    onChange={handleInputChange}
                    placeholder="Ex: ^[0-9]+$ pour les nombres uniquement"
                  />
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Expression régulière pour valider les valeurs (optionnel)
                  </p>
                </div>
                
                {/* Description */}
                <div className="col-span-1 md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description *
                  </label>
                  <textarea
                    name="description"
                    rows="3"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.description}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                
                {/* Checkboxes */}
                <div className="col-span-1 md:col-span-2 space-y-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="isMandatory"
                      id="isMandatory"
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                      checked={formValues.isMandatory}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="isMandatory" className="ml-2 block text-sm text-gray-900 dark:text-white">
                      Configuration obligatoire
                    </label>
                  </div>
                  
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="isActive"
                      id="isActive"
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                      checked={formValues.isActive}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900 dark:text-white">
                      Configuration active
                    </label>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  icon={Save}
                  loading={loading}
                >
                  {modalMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Configurations;