import React from 'react';
import { Loader2 } from 'lucide-react';

const Button = ({ 
  children, 
  variant = 'primary', 
  size = 'md',
  icon: Icon,
  iconPosition = 'left',
  loading = false,
  disabled = false,
  fullWidth = false,
  type = 'button',
  onClick,
  className = '',
  ...props 
}) => {
  // Base styles
  const baseStyles = "inline-flex items-center justify-center font-medium rounded-lg transition-all duration-200";
  
  // Variant styles
  const variants = {
    primary: "bg-blue-500 hover:bg-blue-600 text-white disabled:bg-blue-300",
    secondary: "bg-gray-100 hover:bg-gray-200 text-gray-700 disabled:bg-gray-100 disabled:text-gray-400",
    outline: "border-2 border-gray-300 hover:border-gray-400 text-gray-700 disabled:border-gray-200 disabled:text-gray-400",
    danger: "bg-red-500 hover:bg-red-600 text-white disabled:bg-red-300",
    success: "bg-green-500 hover:bg-green-600 text-white disabled:bg-green-300",
    text: "hover:bg-gray-100 text-gray-700 disabled:text-gray-400",
    link: "text-blue-500 hover:text-blue-600 underline disabled:text-blue-300"
  };

  // Size styles
  const sizes = {
    sm: "px-3 py-1.5 text-sm",
    md: "px-4 py-2 text-base",
    lg: "px-6 py-3 text-lg",
    xl: "px-8 py-4 text-xl"
  };

  // Icon size mapping based on button size
  const iconSizes = {
    sm: 16,
    md: 18,
    lg: 20,
    xl: 24
  };

  // Combine all styles
  const buttonStyles = [
    baseStyles,
    variants[variant],
    sizes[size],
    fullWidth ? "w-full" : "",
    className
  ].join(" ");

  // Icon rendering
  const renderIcon = () => {
    if (loading) {
      return <Loader2 className="animate-spin" size={iconSizes[size]} />;
    }
    if (Icon) {
      return <Icon size={iconSizes[size]} />;
    }
    return null;
  };

  return (
    <button
      type={type}
      className={buttonStyles}
      disabled={disabled || loading}
      onClick={onClick}
      {...props}
    >
      {loading && iconPosition === 'left' && (
        <Loader2 className="mr-2 animate-spin" size={iconSizes[size]} />
      )}
      {!loading && Icon && iconPosition === 'left' && (
        <Icon className="mr-2" size={iconSizes[size]} />
      )}
      
      {children}
      
      {loading && iconPosition === 'right' && (
        <Loader2 className="ml-2 animate-spin" size={iconSizes[size]} />
      )}
      {!loading && Icon && iconPosition === 'right' && (
        <Icon className="ml-2" size={iconSizes[size]} />
      )}
    </button>
  );
};

// Constants pour l'autocomplétion
Button.variants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINE: 'outline',
  DANGER: 'danger',
  SUCCESS: 'success',
  TEXT: 'text',
  LINK: 'link'
};

Button.sizes = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
};

export default Button;