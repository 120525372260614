import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Chip,
  Grid,
  Alert,
  Tooltip,
  CircularProgress,
  Snackbar,
  Switch,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Badge
} from '@mui/material';

import {
  Plus,
  Search,
  RefreshCw,
  Trash2,
  Edit,
  Save,
  X,
  CheckCircle,
  XCircle,
  AlertTriangle,
  Wifi,
  WifiOff,
  Database,
  Server,
  Activity,
  Send,
  Settings,
  Terminal,
  Filter,
  Layers
} from 'lucide-react';

import api from '../../services/api.service';

/**
 * Page de gestion des agents d'automatisation
 */
const Agents = () => {
  // États pour les données
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [environments, setEnvironments] = useState([]);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  
  // États pour les dialogues
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [newAgentDialogOpen, setNewAgentDialogOpen] = useState(false);
  const [deployDialogOpen, setDeployDialogOpen] = useState(false);
  
  // État pour le formulaire d'édition/création
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    agentType: '',
    ipAddress: '',
    port: '',
    installationPath: '',
    login: '',
    password: '',
    isActive: true
  });
  
  // État pour le déploiement
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  
  // État pour les notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  
  // État pour les opérations asynchrones
  const [testingConnectivity, setTestingConnectivity] = useState(false);
  const [deploying, setDeploying] = useState(false);
  const [connectivityStatus, setConnectivityStatus] = useState(null);
  
  // État pour les onglets
  const [activeTab, setActiveTab] = useState(0);
  
  // Liste des types d'agents
  const agentTypes = [
    { value: 'GENERO_GDC', label: 'Genero GDC' },
    { value: 'GENERO_GAS', label: 'Genero GAS' },
    { value: 'DATABASE_IFX', label: 'Informix Database' },
    { value: 'DATABASE_ORA', label: 'Oracle Database' },
    { value: 'DATABASE_POSTGRES', label: 'PostgreSQL Database' },
    { value: 'OS_LINUX', label: 'Linux OS' },
    { value: 'OS_WINDOWS', label: 'Windows OS' },
    { value: 'OS_AIX', label: 'AIX' },
    { value: 'OS_SOLARIS', label: 'Solaris' },
    { value: 'API_REST', label: 'REST API' },
    { value: 'API_SOAP', label: 'SOAP API' },
    { value: 'API_GRAPHQL', label: 'GraphQL API' },
    { value: 'JAVA_SE', label: 'Java Standard Edition' },
    { value: 'JAVA_EE', label: 'Java Enterprise Edition' }
  ];
  
  // Effet pour le chargement initial
  useEffect(() => {
    fetchAgents();
    fetchEnvironments();
  }, []);
  
  // Filtrage des agents basé sur les filtres et la recherche
  const filteredAgents = useMemo(() => {
    return agents.filter(agent => {
      // Filtre de recherche
      const searchMatch = 
        agent.ipAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.agentType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (agent.installationPath && agent.installationPath.toLowerCase().includes(searchTerm.toLowerCase()));
      
      // Filtre de type
      const typeMatch = typeFilter === '' || agent.agentType === typeFilter;
      
      // Filtre d'activité
      const activeMatch = 
        activeFilter === 'all' || 
        (activeFilter === 'active' && agent.isActive) || 
        (activeFilter === 'inactive' && !agent.isActive);
      
      return searchMatch && typeMatch && activeMatch;
    });
  }, [agents, searchTerm, typeFilter, activeFilter]);
  
  // Chargement des agents
  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await api.automata.agents.getAll();
      setAgents(response);
      // Si un agent est sélectionné, rafraîchir ses données
      if (selectedAgent) {
        const updatedAgent = response.find(a => a.id === selectedAgent.id);
        if (updatedAgent) {
          setSelectedAgent(updatedAgent);
        }
      }
    } catch (error) {
      console.error('Erreur lors du chargement des agents:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des agents',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Chargement des environnements
  const fetchEnvironments = async () => {
    try {
      const response = await api.automata.environments.getAll();
      setEnvironments(response);
    } catch (error) {
      console.error('Erreur lors du chargement des environnements:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des environnements',
        severity: 'error',
      });
    }
  };
  
  // Sélection d'un agent
  const handleSelectAgent = (agent) => {
    setSelectedAgent(agent);
    setFormData({
      agentType: agent.agentType,
      ipAddress: agent.ipAddress,
      port: agent.port,
      installationPath: agent.installationPath || '',
      login: agent.login || '',
      password: '', // Ne pas afficher le mot de passe pour des raisons de sécurité
      isActive: agent.isActive
    });
    setEditMode(false);
    setConnectivityStatus(null);
  };
  
  // Activation du mode édition
  const handleEditMode = () => {
    setEditMode(true);
  };
  
  // Annulation de l'édition
  const handleCancelEdit = () => {
    if (selectedAgent) {
      setFormData({
        agentType: selectedAgent.agentType,
        ipAddress: selectedAgent.ipAddress,
        port: selectedAgent.port,
        installationPath: selectedAgent.installationPath || '',
        login: selectedAgent.login || '',
        password: '',
        isActive: selectedAgent.isActive
      });
    }
    setEditMode(false);
  };
  
  // Mise à jour des champs du formulaire
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  // Sauvegarde des modifications
  const handleSaveAgent = async () => {
    try {
      const agentData = {
        ...formData,
        port: parseInt(formData.port, 10)
      };
      
      let response;
      if (selectedAgent) {
        // Mise à jour d'un agent existant
        response = await api.automata.agents.update(selectedAgent.id, agentData);
        setSnackbar({
          open: true,
          message: 'Agent mis à jour avec succès',
          severity: 'success',
        });
      } else {
        // Création d'un nouvel agent
        response = await api.automata.agents.create(agentData);
        setSnackbar({
          open: true,
          message: 'Agent créé avec succès',
          severity: 'success',
        });
        setNewAgentDialogOpen(false);
      }
      
      await fetchAgents();
      
      if (selectedAgent) {
        setSelectedAgent(response);
      }
      
      setEditMode(false);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de l\'agent:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la sauvegarde de l\'agent',
        severity: 'error',
      });
    }
  };
  
  // Suppression d'un agent
  const handleDeleteAgent = async () => {
    if (!selectedAgent) return;
    
    try {
      await api.automata.agents.delete(selectedAgent.id);
      setSnackbar({
        open: true,
        message: 'Agent supprimé avec succès',
        severity: 'success',
      });
      setSelectedAgent(null);
      setDeleteConfirmOpen(false);
      await fetchAgents();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'agent:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la suppression de l\'agent',
        severity: 'error',
      });
    }
  };
  
  // Activation/désactivation d'un agent
  const handleToggleActive = async () => {
    if (!selectedAgent) return;
    
    try {
      await api.automata.agents.setActive(selectedAgent.id, !selectedAgent.isActive);
      setSnackbar({
        open: true,
        message: `Agent ${!selectedAgent.isActive ? 'activé' : 'désactivé'} avec succès`,
        severity: 'success',
      });
      await fetchAgents();
    } catch (error) {
      console.error('Erreur lors de la modification du statut de l\'agent:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la modification du statut de l\'agent',
        severity: 'error',
      });
    }
  };
  
  // Test de connectivité d'un agent
  const handleTestConnectivity = async () => {
    if (!selectedAgent) return;
    
    setTestingConnectivity(true);
    setConnectivityStatus(null);
    
    try {
      const response = await api.automata.agents.testConnection(selectedAgent.id);
      setConnectivityStatus(response.connected);
      setSnackbar({
        open: true,
        message: response.connected 
          ? 'Connexion à l\'agent établie avec succès' 
          : 'Impossible de se connecter à l\'agent',
        severity: response.connected ? 'success' : 'error',
      });
    } catch (error) {
      console.error('Erreur lors du test de connectivité:', error);
      setConnectivityStatus(false);
      setSnackbar({
        open: true,
        message: 'Erreur lors du test de connectivité',
        severity: 'error',
      });
    } finally {
      setTestingConnectivity(false);
    }
  };
  
  // Déploiement d'un agent
  const handleDeployAgent = async () => {
    if (!selectedAgent || !selectedEnvironment) return;
    
    setDeploying(true);
    
    try {
      const response = await api.automata.agents.deploy(selectedAgent.id, selectedEnvironment);
      setSnackbar({
        open: true,
        message: response.success 
          ? 'Agent déployé avec succès' 
          : 'Échec du déploiement de l\'agent',
        severity: response.success ? 'success' : 'warning',
      });
      setDeployDialogOpen(false);
      await fetchAgents();
    } catch (error) {
      console.error('Erreur lors du déploiement de l\'agent:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du déploiement de l\'agent',
        severity: 'error',
      });
    } finally {
      setDeploying(false);
    }
  };
  
  // Ouverture du dialogue de nouvel agent
  const handleOpenNewAgentDialog = () => {
    setFormData({
      agentType: '',
      ipAddress: '',
      port: '',
      installationPath: '',
      login: '',
      password: '',
      isActive: true
    });
    setNewAgentDialogOpen(true);
  };
  
  // Fermeture du dialogue de nouvel agent
  const handleCloseNewAgentDialog = () => {
    setNewAgentDialogOpen(false);
  };
  
  // Obtenir le label d'un type d'agent
  const getAgentTypeLabel = (type) => {
    const agentType = agentTypes.find(t => t.value === type);
    return agentType ? agentType.label : type;
  };
  
  // Obtenir l'icône d'un type d'agent
  const getAgentTypeIcon = (type) => {
    if (type.startsWith('DATABASE')) return <Database size={20} />;
    if (type.startsWith('OS')) return <Terminal size={20} />;
    if (type.startsWith('API')) return <Server size={20} />;
    if (type.startsWith('JAVA')) return <Layers size={20} />;
    if (type.startsWith('GENERO')) return <Settings size={20} />;
    return <Terminal size={20} />;
  };
  
  // Rendu du composant
  return (
    <Box sx={{ p: 0, height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils principale */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Terminal size={24} />
          Gestion des Agents
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={handleOpenNewAgentDialog}
          >
            Nouvel Agent
          </Button>
          
          <Button
            variant="outlined"
            startIcon={<RefreshCw />}
            onClick={fetchAgents}
          >
            Actualiser
          </Button>
        </Box>
      </Paper>
      
      {/* Corps principal */}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Panneau gauche - Liste des agents */}
        <Paper 
          sx={{ 
            width: 350, 
            overflow: 'auto',
            borderRight: 1,
            borderColor: 'divider'
          }}
        >
          {/* Barre de filtres et recherche */}
          <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <TextField
              placeholder="Rechercher un agent..."
              fullWidth
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton 
                      size="small" 
                      onClick={() => setSearchTerm('')}
                      edge="end"
                    >
                      <X size={16} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            
            <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
              <FormControl size="small" fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e.target.value)}
                  label="Type"
                >
                  <MenuItem value="">Tous les types</MenuItem>
                  {agentTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <FormControl size="small" fullWidth>
                <InputLabel>Statut</InputLabel>
                <Select
                  value={activeFilter}
                  onChange={(e) => setActiveFilter(e.target.value)}
                  label="Statut"
                >
                  <MenuItem value="all">Tous</MenuItem>
                  <MenuItem value="active">Actifs</MenuItem>
                  <MenuItem value="inactive">Inactifs</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          
          {/* Liste des agents */}
          <Box>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <CircularProgress />
              </Box>
            ) : filteredAgents.length === 0 ? (
              <Box p={4} textAlign="center">
                <Typography variant="body2" color="text.secondary">
                  Aucun agent trouvé
                </Typography>
              </Box>
            ) : (
              filteredAgents.map((agent) => (
                <Box
                  key={agent.id}
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                    borderBottom: 1,
                    borderColor: 'divider',
                    backgroundColor: 
                      selectedAgent && selectedAgent.id === agent.id 
                        ? 'primary.50' 
                        : 'transparent',
                    '&:hover': {
                      backgroundColor: selectedAgent && selectedAgent.id === agent.id 
                        ? 'primary.100' 
                        : 'action.hover',
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                  onClick={() => handleSelectAgent(agent)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getAgentTypeIcon(agent.agentType)}
                    <Box>
                      <Typography variant="subtitle2" noWrap>
                        {agent.ipAddress}:{agent.port}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" noWrap>
                        {getAgentTypeLabel(agent.agentType)}
                      </Typography>
                    </Box>
                  </Box>
                  <Chip
                    size="small"
                    color={agent.isActive ? 'success' : 'default'}
                    icon={agent.isActive ? <Wifi size={14} /> : <WifiOff size={14} />}
                    label={agent.isActive ? 'Actif' : 'Inactif'}
                    variant={agent.isActive ? 'filled' : 'outlined'}
                  />
                </Box>
              ))
            )}
          </Box>
        </Paper>
        
        {/* Panneau central - Détails de l'agent */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          {selectedAgent ? (
            <>
              {/* Entête du panneau de détails */}
              <Box 
                sx={{ 
                  p: 2, 
                  borderBottom: 1, 
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'background.paper'
                }}
              >
                <Box>
                  <Typography variant="h6">
                    Détails de l'agent
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    ID: {selectedAgent.id}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {editMode ? (
                    <>
                      <Button
                        variant="outlined"
                        color="inherit"
                        startIcon={<X />}
                        onClick={handleCancelEdit}
                      >
                        Annuler
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Save />}
                        onClick={handleSaveAgent}
                      >
                        Enregistrer
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Edit />}
                        onClick={handleEditMode}
                      >
                        Modifier
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<Trash2 />}
                        onClick={() => setDeleteConfirmOpen(true)}
                      >
                        Supprimer
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              
              {/* Contenu du panneau - Onglets */}
              <Box sx={{ p: 0, flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs 
                    value={activeTab} 
                    onChange={(e, newValue) => setActiveTab(newValue)}
                  >
                    <Tab label="Informations générales" />
                    <Tab label="Actions" />
                  </Tabs>
                </Box>
                
                {/* Onglet Informations générales */}
                {activeTab === 0 && (
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      {/* État actif */}
                      <Grid item xs={12}>
                        <Card>
                          <CardContent 
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'space-between' 
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              {selectedAgent.isActive ? (
                                <Wifi size={24} color="#4caf50" />
                              ) : (
                                <WifiOff size={24} color="#9e9e9e" />
                              )}
                              <Box>
                                <Typography variant="subtitle1">
                                  État de l'agent
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {selectedAgent.isActive ? 'Actif' : 'Inactif'}
                                </Typography>
                              </Box>
                            </Box>
                            
                            {!editMode && (
                              <Button
                                variant="outlined"
                                color={selectedAgent.isActive ? 'error' : 'success'}
                                onClick={handleToggleActive}
                              >
                                {selectedAgent.isActive ? 'Désactiver' : 'Activer'}
                              </Button>
                            )}
                            
                            {editMode && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={formData.isActive}
                                    onChange={handleFormChange}
                                    name="isActive"
                                    color="primary"
                                  />
                                }
                                label={formData.isActive ? 'Actif' : 'Inactif'}
                              />
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                      
                      {/* Type d'agent */}
                      <Grid item xs={12} md={6}>
                        <FormControl
                          fullWidth
                          required
                          disabled={!editMode}
                        >
                          <InputLabel>Type d'agent</InputLabel>
                          <Select
                            name="agentType"
                            value={editMode ? formData.agentType : selectedAgent.agentType}
                            onChange={handleFormChange}
                            label="Type d'agent"
                          >
                            {agentTypes.map((type) => (
                              <MenuItem key={type.value} value={type.value}>
                                {type.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      
                      {/* Adresse IP */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Adresse IP"
                          name="ipAddress"
                          required
                          value={editMode ? formData.ipAddress : selectedAgent.ipAddress}
                          onChange={handleFormChange}
                          disabled={!editMode}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Server size={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      
                      {/* Port */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Port"
                          name="port"
                          type="number"
                          required
                          value={editMode ? formData.port : selectedAgent.port}
                          onChange={handleFormChange}
                          disabled={!editMode}
                        />
                      </Grid>
                      
                      {/* Chemin d'installation */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Chemin d'installation"
                          name="installationPath"
                          value={editMode ? formData.installationPath : (selectedAgent.installationPath || '')}
                          onChange={handleFormChange}
                          disabled={!editMode}
                        />
                      </Grid>
                      
                      {/* Login */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Login"
                          name="login"
                          value={editMode ? formData.login : (selectedAgent.login || '')}
                          onChange={handleFormChange}
                          disabled={!editMode}
                        />
                      </Grid>
                      
                      {/* Mot de passe */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Mot de passe"
                          name="password"
                          type="password"
                          value={editMode ? formData.password : ''}
                          onChange={handleFormChange}
                          disabled={!editMode}
                          placeholder={editMode ? 'Laisser vide pour conserver le mot de passe actuel' : '********'}
                        />
                      </Grid>
                      
                      {/* Informations additionnelles */}
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Informations additionnelles
                            </Typography>
                            
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="text.secondary">
                                  Créé le:
                                </Typography>
                                <Typography variant="body2">
                                  {selectedAgent.createdDate ? new Date(selectedAgent.createdDate).toLocaleString() : 'N/A'}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={6}>
                                <Typography variant="body2" color="text.secondary">
                                  Dernière modification:
                                </Typography>
                                <Typography variant="body2">
                                  {selectedAgent.modifiedDate ? new Date(selectedAgent.modifiedDate).toLocaleString() : 'N/A'}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={6}>
                                <Typography variant="body2" color="text.secondary">
                                  Créé par:
                                </Typography>
                                <Typography variant="body2">
                                  {selectedAgent.createdBy || 'N/A'}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={6}>
                                <Typography variant="body2" color="text.secondary">
                                  Modifié par:
                                </Typography>
                                <Typography variant="body2">
                                  {selectedAgent.modifiedBy || 'N/A'}
                                </Typography>
                              </Grid>
                              
                              <Grid item xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                  Environnement associé:
                                </Typography>
                                <Typography variant="body2">
                                  {selectedAgent.environment ? selectedAgent.environment.appName : 'Aucun'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                
                {/* Onglet Actions */}
                {activeTab === 1 && (
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      {/* Test de connectivité */}
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardHeader 
                            title="Test de connectivité" 
                            subheader="Vérifier si l'agent est accessible" 
                          />
                          <CardContent>
                            {connectivityStatus !== null && (
                              <Alert 
                                severity={connectivityStatus ? 'success' : 'error'}
                                sx={{ mb: 2 }}
                              >
                                {connectivityStatus 
                                  ? 'Connexion à l\'agent établie avec succès' 
                                  : 'Impossible de se connecter à l\'agent'
                                }
                              </Alert>
                            )}
                            
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              startIcon={<Activity />}
                              onClick={handleTestConnectivity}
                              disabled={testingConnectivity}
                            >
                              {testingConnectivity ? (
                                <>
                                  <CircularProgress size={20} sx={{ mr: 1 }} />
                                  Test en cours...
                                </>
                              ) : (
                                'Tester la connectivité'
                              )}
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                      
                      {/* Déploiement dans un environnement */}
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardHeader 
                            title="Déploiement" 
                            subheader="Déployer l'agent dans un environnement" 
                          />
                          <CardContent>
                            <Button
                              variant="contained"
                              color="secondary"
                              fullWidth
                              startIcon={<Send />}
                              onClick={() => setDeployDialogOpen(true)}
                              disabled={!selectedAgent.isActive}
                            >
                              Déployer l'agent
                            </Button>
                            
                            {!selectedAgent.isActive && (
                              <Alert severity="warning" sx={{ mt: 2 }}>
                                L'agent doit être actif pour être déployé
                              </Alert>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100%',
                p: 4,
                textAlign: 'center'
              }}
            >
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 4, 
                  maxWidth: 500, 
                  bgcolor: 'primary.50'
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Aucun agent sélectionné
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Sélectionnez un agent dans la liste à gauche pour voir ses détails
                  ou créez un nouvel agent en cliquant sur le bouton "Nouvel Agent".
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Plus />}
                  onClick={handleOpenNewAgentDialog}
                >
                  Créer un nouvel agent
                </Button>
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
      
      {/* Dialogue de confirmation de suppression */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer cet agent ? Cette action est irréversible.
          </Typography>
          {selectedAgent?.environment && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Attention : Cet agent est actuellement associé à l'environnement "{selectedAgent.environment.appName}".
              La suppression peut impacter les fonctionnalités d'automatisation.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="inherit">
            Annuler
          </Button>
          <Button onClick={handleDeleteAgent} color="error" variant="contained">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de création d'un nouvel agent */}
      <Dialog
        open={newAgentDialogOpen}
        onClose={handleCloseNewAgentDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Créer un nouvel agent</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                required
              >
                <InputLabel>Type d'agent</InputLabel>
                <Select
                  name="agentType"
                  value={formData.agentType}
                  onChange={handleFormChange}
                  label="Type d'agent"
                >
                  {agentTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Adresse IP"
                name="ipAddress"
                required
                value={formData.ipAddress}
                onChange={handleFormChange}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Port"
                name="port"
                type="number"
                required
                value={formData.port}
                onChange={handleFormChange}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Chemin d'installation"
                name="installationPath"
                value={formData.installationPath}
                onChange={handleFormChange}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Login"
                name="login"
                value={formData.login}
                onChange={handleFormChange}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Mot de passe"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleFormChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isActive}
                    onChange={handleFormChange}
                    name="isActive"
                    color="primary"
                  />
                }
                label="Activer l'agent"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewAgentDialog} color="inherit">
            Annuler
          </Button>
          <Button onClick={handleSaveAgent} color="primary" variant="contained">
            Créer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de déploiement */}
      <Dialog
        open={deployDialogOpen}
        onClose={() => setDeployDialogOpen(false)}
      >
        <DialogTitle>Déployer l'agent</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Sélectionnez l'environnement dans lequel vous souhaitez déployer cet agent.
          </Typography>
          
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Environnement</InputLabel>
            <Select
              value={selectedEnvironment}
              onChange={(e) => setSelectedEnvironment(e.target.value)}
              label="Environnement"
            >
              {environments.map((env) => (
                <MenuItem key={env.id} value={env.id}>
                  {env.appName} {env.appVersion && `(${env.appVersion})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Alert severity="info">
            Si l'environnement sélectionné a déjà un agent déployé, celui-ci sera remplacé.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeployDialogOpen(false)} color="inherit">
            Annuler
          </Button>
          <Button 
            onClick={handleDeployAgent} 
            color="primary" 
            variant="contained"
            disabled={!selectedEnvironment || deploying}
          >
            {deploying ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Déploiement...
              </>
            ) : (
              'Déployer'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Agents;