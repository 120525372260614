// components/shared/workflow/WorkflowDomainSelector.js
import React, { useState, useEffect } from 'react';
import { Search, Plus, Check, X, ChevronDown } from 'lucide-react';
import { workflowDomainManager } from './WorkflowDomainManager';

/**
 * Composant de sélection d'éléments de domaine pour les workflows
 * (événements, rôles, actions, etc.)
 */
const WorkflowDomainSelector = ({
  type = 'event', // 'event', 'role', 'status', 'action'
  selectedItems = [],
  onChange,
  multiple = false,
  required = false,
  disabled = false,
  label = '',
  placeholder = 'Sélectionner...',
  className = '',
  allowCustom = false,
  workflowType = null,
  availableItems = null
}) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [customValue, setCustomValue] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  
  // Chargement des éléments de domaine
  useEffect(() => {
    const loadItems = async () => {
      setIsLoading(true);
      try {
        // Si availableItems est fourni, l'utiliser directement
        if (availableItems) {
          setItems(availableItems);
          setFilteredItems(availableItems);
          setIsLoading(false);
          return;
        }
        
        // Sinon, charger depuis le domainManager
        let domainItems = [];
        
        switch (type) {
          case 'event':
            domainItems = await workflowDomainManager.getEvents(workflowType);
            break;
          case 'role':
            domainItems = await workflowDomainManager.getRoles();
            break;
          case 'status':
            domainItems = await workflowDomainManager.getStatuses(workflowType);
            break;
          case 'action':
            domainItems = await workflowDomainManager.getActions();
            break;
          default:
            console.warn(`Type de domaine inconnu: ${type}`);
            domainItems = [];
        }
        
        setItems(domainItems);
        setFilteredItems(domainItems);
      } catch (error) {
        console.error(`Erreur lors du chargement des ${type}s:`, error);
        setItems([]);
        setFilteredItems([]);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadItems();
  }, [type, workflowType, availableItems]);
  
  // Filtrer les éléments lors de la recherche
  useEffect(() => {
    if (!searchTerm) {
      setFilteredItems(items);
      return;
    }
    
    const filtered = items.filter(item => {
      const searchIn = [
        item.name?.toLowerCase(),
        item.code?.toLowerCase(),
        item.description?.toLowerCase()
      ].filter(Boolean);
      
      return searchIn.some(text => text.includes(searchTerm.toLowerCase()));
    });
    
    setFilteredItems(filtered);
  }, [searchTerm, items]);
  
  // Fermer le sélecteur lors d'un clic à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.domain-selector')) {
        setIsOpen(false);
        setSearchTerm('');
        setCustomValue('');
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  // Détermine si un élément est sélectionné
  const isSelected = (item) => {
    if (!selectedItems || selectedItems.length === 0) return false;
    
    // Pour les chaînes de caractères simples
    if (typeof selectedItems[0] === 'string') {
      return selectedItems.includes(item.code);
    }
    
    // Pour les objets
    return selectedItems.some(selected => selected.code === item.code);
  };
  
  // Gérer la sélection d'un élément
  const handleSelect = (item) => {
    if (disabled) return;
    
    if (multiple) {
      const newSelection = [...selectedItems];
      
      if (typeof selectedItems[0] === 'string' || !selectedItems[0]) {
        // Mode sélection par code
        const itemCode = item.code;
        
        if (newSelection.includes(itemCode)) {
          // Retirer l'élément
          const index = newSelection.indexOf(itemCode);
          newSelection.splice(index, 1);
        } else {
          // Ajouter l'élément
          newSelection.push(itemCode);
        }
      } else {
        // Mode sélection par objet
        const isItemSelected = newSelection.some(selected => selected.code === item.code);
        
        if (isItemSelected) {
          // Retirer l'élément
          const index = newSelection.findIndex(selected => selected.code === item.code);
          newSelection.splice(index, 1);
        } else {
          // Ajouter l'élément
          newSelection.push(item);
        }
      }
      
      onChange(newSelection);
    } else {
      // Mode sélection unique
      onChange(typeof selectedItems[0] === 'string' || !selectedItems[0] ? item.code : item);
      setIsOpen(false);
    }
  };
  
  // Gérer l'ajout d'une valeur personnalisée
  const handleAddCustom = () => {
    if (!customValue || disabled) return;
    
    const newItem = {
      code: customValue.toUpperCase().replace(/\s+/g, '_'),
      name: customValue,
      description: `Élément personnalisé: ${customValue}`,
      isCustom: true
    };
    
    // Ajouter la valeur personnalisée aux éléments disponibles
    setItems(prevItems => [...prevItems, newItem]);
    
    // Sélectionner la valeur personnalisée
    handleSelect(newItem);
    
    // Réinitialiser
    setCustomValue('');
    setSearchTerm('');
    
    if (!multiple) {
      setIsOpen(false);
    }
  };
  
  // Formatter l'affichage de la sélection
  const formatSelection = () => {
    if (!selectedItems || selectedItems.length === 0) {
      return <span className="text-gray-400 dark:text-gray-500">{placeholder}</span>;
    }
    
    if (!multiple) {
      // Affichage pour une sélection unique
      const selectedValue = typeof selectedItems === 'string' ? selectedItems : selectedItems.code;
      const selectedItem = items.find(item => item.code === selectedValue);
      
      return selectedItem ? (
        <span className="flex items-center truncate">
          <span className="font-medium">{selectedItem.name}</span>
          <span className="ml-1.5 text-xs text-gray-500 dark:text-gray-400">({selectedItem.code})</span>
        </span>
      ) : (
        <span>{selectedValue}</span>
      );
    } else {
      // Affichage pour une sélection multiple
      if (selectedItems.length === 1) {
        const selectedValue = typeof selectedItems[0] === 'string' ? selectedItems[0] : selectedItems[0].code;
        const selectedItem = items.find(item => item.code === selectedValue);
        
        return selectedItem ? (
          <span className="flex items-center truncate">
            <span className="font-medium">{selectedItem.name}</span>
            <span className="ml-1.5 text-xs text-gray-500 dark:text-gray-400">({selectedItem.code})</span>
          </span>
        ) : (
          <span>{selectedValue}</span>
        );
      } else {
        return (
          <span>{selectedItems.length} sélectionné(s)</span>
        );
      }
    }
  };
  
  // Classe TailwindCSS pour le conteneur
  const containerClassName = `
    domain-selector
    relative w-full border rounded-lg transition-all duration-200
    ${disabled ? 'bg-gray-100 dark:bg-gray-800 cursor-not-allowed opacity-60' : 'bg-white dark:bg-gray-800 cursor-pointer'}
    ${isOpen ? 'border-indigo-500 dark:border-indigo-400 shadow-sm' : 'border-gray-300 dark:border-gray-600'}
    ${className}
  `;
  
  return (
    <div className="space-y-1">
      {label && (
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      
      <div className={containerClassName}>
        {/* Sélection affichée */}
        <div
          className="flex items-center justify-between p-2.5 min-h-[42px]"
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          <div className="truncate">{formatSelection()}</div>
          <ChevronDown 
            size={18} 
            className={`text-gray-400 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`}
          />
        </div>
        
        {/* Dropdown */}
        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
            {/* Barre de recherche */}
            <div className="p-2 border-b border-gray-200 dark:border-gray-700">
              <div className="relative">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-400" />
                <input
                  type="text"
                  className="w-full pl-9 pr-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md 
                           bg-white dark:bg-gray-700 text-gray-900 dark:text-white 
                           focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder={`Rechercher un ${type}...`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            
            {/* Liste des éléments */}
            <div className="max-h-56 overflow-y-auto">
              {isLoading ? (
                <div className="flex justify-center items-center py-4">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
                </div>
              ) : filteredItems.length > 0 ? (
                <div className="p-1">
                  {filteredItems.map((item) => (
                    <div
                      key={item.code}
                      className={`
                        flex items-center p-2 rounded-md text-sm cursor-pointer
                        ${isSelected(item) 
                          ? 'bg-indigo-50 dark:bg-indigo-900/20 text-indigo-700 dark:text-indigo-300' 
                          : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300'}
                      `}
                      onClick={() => handleSelect(item)}
                    >
                      <div className="flex-1 min-w-0">
                        <div className="font-medium truncate">{item.name}</div>
                        <div className="text-xs text-gray-500 dark:text-gray-400 truncate">
                          {item.code} {item.isCustom && '(personnalisé)'}
                        </div>
                      </div>
                      
                      {isSelected(item) && (
                        <Check size={16} className="text-indigo-600 dark:text-indigo-400 ml-2" />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="py-3 px-2 text-center text-sm text-gray-500 dark:text-gray-400">
                  Aucun résultat trouvé
                </div>
              )}
            </div>
            
            {/* Option d'ajout personnalisé */}
            {allowCustom && (
              <div className="p-2 border-t border-gray-200 dark:border-gray-700">
                <div className="flex items-center">
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-l-md 
                             bg-white dark:bg-gray-700 text-gray-900 dark:text-white 
                             focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder={`Ajouter un ${type} personnalisé...`}
                    value={customValue}
                    onChange={(e) => setCustomValue(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleAddCustom()}
                  />
                  <button
                    className="px-3 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-medium 
                             text-sm rounded-r-md focus:outline-none focus:ring-2 focus:ring-indigo-500
                             disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={handleAddCustom}
                    disabled={!customValue}
                  >
                    <Plus size={16} />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkflowDomainSelector;