import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextareaAutosize
} from '@mui/material';
import {
  AlertCircle,
  Monitor,
  PlayCircle,
  Timer,
  ChevronRight,
  AlertTriangle,
  CheckCircle,
  KeyRound,
  MousePointer,
  Type,
  X,
  ArrowLeft,
  ArrowRight,
  Info,
  Eye,
  EyeOff,
  AlertOctagon,
  Table as TableIcon,
  Database,
  Tag,
  Edit,
  ChevronDown,
  Circle,
  Command,
  Wifi,
  WifiOff
} from 'lucide-react';

/**
 * GeneroCapture Component - Interface réutilisable pour interagir avec des applications Genero
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles de l'application
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion SSE
 */
const GeneroCapture = ({ 
  data, 
  onInteractionSent, 
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États locaux
  const [lastAction, setLastAction] = useState(null);
  const [tempInputValue, setTempInputValue] = useState('');
  const [localInputValue, setLocalInputValue] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [actionButtonValue, setActionButtonValue] = useState('Action');
  const [connectionState, setConnectionState] = useState({
    connected: false,
    clientState: null
  });
  
  // Effet pour gérer les mises à jour de l'état de connexion
  useEffect(() => {
    // Mettre à jour l'état local avec la prop de connexion
    setConnectionState(prev => ({
      ...prev,
      connected: connectionStatus,
      clientState: data?.technicalProperties?.clientState || null
    }));
  }, [connectionStatus, data?.technicalProperties?.clientState]);
  
  // Fonctions utilitaires pour extraire les données
  const getTechnicalProps = () => {
    const tech = data?.technicalProperties || {};
    return {
      clientId: tech.clientId,
      clientState: tech.clientState,
      sessionId: tech.sessionId,
      timestamp: tech.timestamp,
    };
  };

  const getUserInterface = () => {
    return data?.technicalProperties?.userInterface || {};
  };

  // Gestion des événements clavier
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Empêcher le traitement si un champ texte est actif
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        return;
      }

      let action = null;

      switch (event.key) {
        case 'Tab':
          event.preventDefault();
          action = {
            name: 'Tab',
            text: 'Tab',
            nodeType: 'KeyAction'
          };
          break;

        case 'Escape':
          event.preventDefault();
          action = {
            name: 'Esc',
            text: 'Esc',
            nodeType: 'KeyAction'
          };
          break;

        case 'F3':
          event.preventDefault();
          action = {
            name: 'F3',
            text: 'f3',
            nodeType: 'KeyAction'
          };
          break;

        default:
          return;
      }

      if (action) {
        handleAction(action, 'KeyAction');
      }
    };

    // Ajout de l'écouteur d'événement
    window.addEventListener('keydown', handleKeyDown);

    // Nettoyage de l'écouteur d'événement
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getFocusInfo = () => {
    const focus = getUserInterface()?.focus || {};
    return {
      id: focus.id,
      nodeName: focus.nodeName,
      name: focus.name,
      nodeType: focus.nodeType,
      text: focus.text,
      value: focus.value,
      isActive: focus.isActive,
      colName: focus.colName,
      tabName: focus.tabName,
      isRequired: focus.isRequired,
      isVisible: focus.isVisible,
      options: focus.options,
      multiline: focus.multiline,
      inputType: focus.inputType,
      placeholder: focus.placeholder,
      // Propriétés supplémentaires pour plus de détails
      fieldType: focus.fieldType,
      maxLength: focus.maxLength,
      minLength: focus.minLength,
      pattern: focus.pattern,
      readOnly: focus.readOnly,
      description: focus.description,
      defaultValue: focus.defaultValue,
      validation: focus.validation,
      parentForm: focus.parentForm,
      path: focus.path
    };
  };

  const getActions = () => {
    return getUserInterface()?.dialog?.actions || [];
  };

  // Gestion des actions
 const handleAction = (action, type) => {
  if (disabled || !connectionState.connected) return;
  
  // Vérifier la cohérence entre le type d'action et la présence d'une valeur
  const isMenuOrAction = type === "MenuAction" || type === "ACTION";
  const hasValue = action.value !== undefined;
  
  // Afficher un avertissement en cas d'incohérence
  if (isMenuOrAction && hasValue) {
    console.warn(`Attention: tentative d'envoi d'une valeur sur un élément de type ${type}`);
  }
  
  const actionPayload = {
    direction: "REQUEST",
    type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
    timestamp: new Date().toISOString(),
    technicalProperties: {
      nodeType: type,
      nodeName: action.name,
      nodeValue: action.text,
      actionType: action.nodeType || type,
      // Ne pas inclure de valeur pour les MenuAction
      actionValue: isMenuOrAction ? action.text : (action.value || action.text),
      actionName: action.name,
      timestamp: Date.now(),
      clientId: getTechnicalProps().clientId,
      sessionId: getTechnicalProps().sessionId
    }
  };

  // Personnalisation selon le type
  switch (action.nodeType || type) {
    case "MenuAction":
      actionPayload.technicalProperties = {
        ...actionPayload.technicalProperties,
        menuPath: action.text,
        menuId: action.id,
        actionValue: action.text
      };
      break;

    case "Action":
      actionPayload.technicalProperties = {
        ...actionPayload.technicalProperties,
        actionText: action.text,
        dialogName: getUserInterface()?.dialog?.name,
        actionValue: action.text
      };
      break;

    case "FormField":
      actionPayload.technicalProperties = {
        ...actionPayload.technicalProperties,
        fieldName: action.name,
        fieldValue: action.value,
        actionValue: action.value,
        colName: action.colName,
        tabName: action.tabName,
        fieldType: action.fieldType,
        validation: action.validation
      };
      break;

    case "KeyAction":
      actionPayload.technicalProperties = {
        ...actionPayload.technicalProperties,
        keyName: action.name,
        keyText: action.text,
        actionValue: action.text || action.name
      };
      break;
  }

  console.log('Envoi du payload d\'action:', actionPayload);
  onInteractionSent(actionPayload);
  setLastAction({
    type: type,
    name: action.text || action.name,
    timestamp: new Date()
  });
};

  // Gestion de la sélection de menu
  const handleMenuSelect = (node) => {
    if (disabled || !connectionState.connected) return;
    
    // Mise à jour du nœud sélectionné
    setSelectedMenuItem(node);

    if (node.type === 'group') {
      console.log('Groupe de menu sélectionné:', node.text);

      onInteractionSent({
        direction: "REQUEST",
        type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
        technicalProperties: {
          nodeType: 'MenuNavigation',
          nodeName: node.text,
          nodeValue: node.text,
          actionType: 'MenuGroup',
          actionValue: node.text,
          actionName: node.text,
          menuId: node.id,
          menuPath: node.path,
          isGroup: true,
          hasItems: node.items?.length > 0
        }
      });

    } else if (node.type === 'command' && !node.disabled) {
      console.log('Commande de menu sélectionnée:', node.text);

      onInteractionSent({
        direction: "REQUEST",
        type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
        technicalProperties: {
          nodeType: 'MenuAction',
          nodeName: node.text,
          nodeValue: node.text,
          actionType: 'MenuCommand',
          actionValue: node.text,
          actionName: node.text,
          menuId: node.id,
          menuPath: node.path,
          isCommand: true,
          parentGroup: node.parentId
        }
      });

      setLastAction({
        type: 'MenuCommand',
        name: node.text,
        timestamp: new Date()
      });
    }
  };

  // Composant d'en-tête
  const Header = () => {
    const { clientId, clientState, sessionId } = getTechnicalProps();
    const ui = getUserInterface();

    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Monitor sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              Interface Genero {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionState.connected ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionState.connected ? 'success' : 'error'}
            label={connectionState.connected ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                ID Client
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {clientId || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Session
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {sessionId?.substring(0, 8) || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Fenêtre
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {ui?.window?.title || 'N/A'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // Composant Node d'arbre
  const TreeNode = ({ node, level = 0, onSelect }) => {
    const [isExpanded, setIsExpanded] = useState(node.expanded);
    const isGroup = node.type === 'group';
    const hasItems = isGroup && node.items && node.items.length > 0;

    const handleToggle = (e) => {
      e.stopPropagation();
      setIsExpanded(!isExpanded);
    };

    const handleSelect = () => {
      onSelect(node);
    };

    return (
      <Box>
        <Box
          onClick={handleSelect}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: level * 2,
            pr: 1,
            py: 0.75,
            cursor: (disabled || !connectionState.connected) ? 'not-allowed' : 'pointer',
            borderRadius: 1,
            transition: 'all 0.2s',
            position: 'relative',
            opacity: (disabled || node.disabled || !connectionState.connected) ? 0.5 : 1,
            '&:hover': {
              bgcolor: (disabled || node.disabled || !connectionState.connected) ? undefined : 'primary.50',
            },
            ...(node === selectedMenuItem && {
              bgcolor: 'primary.100',
              '&:hover': {
                bgcolor: 'primary.200',
              },
            }),
          }}
        >
          <Box sx={{
            minWidth: 28,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {hasItems ? (
              <Button
                onClick={handleToggle}
                sx={{
                  minWidth: 'auto',
                  p: 0.5,
                  borderRadius: '50%',
                  '&:hover': {
                    bgcolor: 'primary.100',
                  },
                }}
              >
                {isExpanded ?
                  <ChevronDown size={18} /> :
                  <ChevronRight size={18} />
                }
              </Button>
            ) : (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary',
                pl: 1
              }}>
                {isGroup ? (
                  <Circle size={8} />
                ) : (
                  <Command size={16} />
                )}
              </Box>
            )}
          </Box>

          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            ml: 0.5
          }}>
            <Typography
              variant="body2"
              noWrap
              sx={{
                color: node.disabled ? 'text.disabled' : 'text.primary',
                fontWeight: isGroup ? 500 : 400,
              }}
            >
              {node.text}
            </Typography>
          </Box>
        </Box>

        {hasItems && isExpanded && (
          <Box
            sx={{
              ml: 1,
              borderLeft: 1,
              borderColor: 'divider',
              position: 'relative',
            }}
          >
            {node.items.map((item, index) => (
              <TreeNode
                key={item.id || index}
                node={item}
                level={level + 1}
                onSelect={onSelect}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  };

  // Composant Menu Arborescent
  const TreeMenu = ({ structure, onSelect }) => {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderRadius: 1,
          border: 1,
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            p: 1.5,
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'grey.50',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Tag size={16} />
          <Typography variant="subtitle2">
            Menu de Navigation
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            p: 1
          }}
        >
          {structure?.map((node, index) => (
            <TreeNode
              key={node.id || index}
              node={node}
              onSelect={onSelect}
            />
          ))}
        </Box>
      </Box>
    );
  };

  // Composant Actions Rapides
  const QuickActions = () => {
    const quickActions = [
      {
        key: 'Esc',
        label: 'Esc',
        type: 'KeyAction',
        icon: <CheckCircle size={16} />,
        color: 'success',
        shortcut: 'Echap'
      },
      {
        key: 'F3',
        label: 'f3',
        type: 'KeyAction',
        icon: <X size={16} />,
        color: 'error',
        shortcut: 'F3'
      },
      {
        key: 'Tab',
        label: 'Tab',
        type: 'KeyAction',
        icon: <ArrowRight size={16} />,
        color: 'primary',
        shortcut: 'Tab'
      }
    ];

    return (
      <Box sx={{
        p: 1.5,
        bgcolor: 'primary.50',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: 1,
        pl: 3
      }}>
        {quickActions.map((action) => (
          <Button
            key={action.key}
            size="small"
            variant="contained"
            startIcon={action.icon}
            onClick={() => handleAction({
              name: action.key,
              text: action.label,
              nodeType: 'KeyAction'
            }, 'KeyAction')}
            disabled={disabled || !connectionState.connected}
            sx={{
              bgcolor: 'background.paper',
              color: `${action.color}.main`,
              borderColor: `${action.color}.main`,
              '&:hover': {
                bgcolor: `${action.color}.light`,
                color: 'white'
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <span>{action.key}</span>
              <Chip
                size="small"
                label={action.shortcut}
                variant="outlined"
                sx={{
                  height: '16px',
                  '& .MuiChip-label': {
                    fontSize: '0.625rem',
                    px: 0.5
                  }
                }}
              />
            </Box>
          </Button>
        ))}
      </Box>
    );
  };

  // Composant Section Focus
  const FocusSection = () => {
    const focus = getFocusInfo();
    const isInteractable = focus.isActive && focus.isVisible && !disabled && connectionState.connected;

    const handleSubmit = (event, value) => {
  event.preventDefault();
  
  // Vérifier le type d'élément avant d'essayer de définir une valeur
  if (focus.nodeType === "MenuAction" || focus.nodeType === "ACTION") {
    console.warn(`Impossible de définir une valeur sur un élément de type ${focus.nodeType}`);
    // Exécuter l'action correctement selon son type réel
    handleAction(focus, focus.nodeType);
  } else {
    // Pour les vrais champs de formulaire
    handleAction({
      ...focus,
      value: localInputValue !== null ? localInputValue : value
    }, focus.nodeType || 'FormField'); // Utiliser le type réel ou par défaut FormField
  }
  
  setLocalInputValue(null);
};

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSubmit(event, event.target.value);
      }
    };

    const InfoCard = ({ title, children, icon, color = 'primary' }) => (
      <Paper sx={{ p: 2, bgcolor: `${color}.50`, height: '100%' }}>
        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          {icon}
          <Typography variant="subtitle2" color={`${color}.main`}>
            {title}
          </Typography>
        </Stack>
        {children}
      </Paper>
    );

    const StatusChip = ({ label, value, color = 'default' }) => (
      <Chip
        size="small"
        label={`${label}: ${value ? 'Oui' : 'Non'}`}
        color={value ? color : 'default'}
        variant={value ? 'filled' : 'outlined'}
        sx={{ m: 0.5 }}
      />
    );

    return (
      <Box sx={{ 
        p: 3, 
        bgcolor: 'background.paper',
        transition: 'background-color 0.3s ease',
        ...(highlighted && { bgcolor: 'primary.50' })
      }}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 2 }}>
          <AlertCircle sx={{ width: 20, height: 20, color: 'primary.main' }} />
          <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
            Focus Actuel{focus.nodeType ? ` - ${focus.nodeType}` : ''}
          </Typography>
        </Stack>

        <Grid container spacing={2}>
          {/* Informations de base */}
          <Grid item xs={12}>
            <InfoCard
              title="Informations de base"
              icon={<Info size={16} color="primary" />}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">Nom:</Typography>
                  <Typography variant="body1">{focus.name || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">Type:</Typography>
                  <Typography variant="body1">{focus.nodeType || 'N/A'}</Typography>
                </Grid>
                {focus.description && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Description:</Typography>
                    <Typography variant="body1">{focus.description}</Typography>
                  </Grid>
                )}
              </Grid>
            </InfoCard>
          </Grid>

          {/* Informations de statut */}
          <Grid item xs={12} sm={6}>
            <InfoCard
              title="Statut"
              icon={<Eye size={16} />}
              color="info"
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <StatusChip label="Actif" value={focus.isActive} color="success" />
                <StatusChip label="Visible" value={focus.isVisible} color="primary" />
                <StatusChip label="Requis" value={focus.isRequired} color="warning" />
                <StatusChip label="Lecture seule" value={focus.readOnly} color="error" />
              </Box>
            </InfoCard>
          </Grid>

          {/* Propriétés du champ */}
          <Grid item xs={12} sm={6}>
            <InfoCard
              title="Propriétés du champ"
              icon={<Tag size={16} />}
              color="success"
            >
              <Stack spacing={1}>
                {focus.fieldType && (
                  <Typography variant="body2">
                    Type de champ: <Chip size="small" label={focus.fieldType} />
                  </Typography>
                )}
                {focus.maxLength && (
                  <Typography variant="body2">
                    Longueur max: <Chip size="small" label={focus.maxLength} />
                  </Typography>
                )}
                {focus.pattern && (
                  <Typography variant="body2">
                    Modèle: <Chip size="small" label={focus.pattern} />
                  </Typography>
                )}
              </Stack>
            </InfoCard>
          </Grid>

          {/* Informations de base de données */}
          {(focus.colName || focus.tabName) && (
            <Grid item xs={12}>
              <InfoCard
                title="Informations de base de données"
                icon={<Database size={16} />}
                color="warning"
              >
                <Grid container spacing={2}>
                  {focus.tabName && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">Table:</Typography>
                      <Typography variant="body1">{focus.tabName}</Typography>
                    </Grid>
                  )}
                  {focus.colName && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">Colonne:</Typography>
                      <Typography variant="body1">{focus.colName}</Typography>
                    </Grid>
                  )}
                </Grid>
              </InfoCard>
            </Grid>
          )}
          {/* Valeur actuelle */}
          {focus.value && (
            <Grid item xs={12}>
              <InfoCard
                title="Valeur actuelle"
                icon={<Edit size={16} />}
                color="error"
              >
                <Paper sx={{ p: 2, bgcolor: 'background.paper' }}>
                  <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                    {focus.value}
                  </Typography>
                </Paper>
              </InfoCard>
            </Grid>
          )}

          {/* Section d'entrée interactive */}
<Grid item xs={12}>
  {focus.nodeType === "FORM_FIELD" || focus.nodeType === "FormField" ? (
    <Paper sx={{ p: 2, bgcolor: 'background.paper' }}>
      <Typography variant="subtitle2" gutterBottom>
        Champ de saisie
      </Typography>
      <form onSubmit={(e) => handleSubmit(e, tempInputValue)}>
        {focus.multiline ? (
          <TextareaAutosize
            minRows={4}
            value={tempInputValue || focus.value || ''}
            disabled={!isInteractable}
            placeholder={focus.placeholder}
            onChange={(e) => setTempInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{
              width: '100%',
              padding: '8px',
              marginTop: '8px',
              resize: 'vertical',
              minHeight: '100px',
              fontFamily: 'inherit',
              borderRadius: '4px',
              border: '1px solid #ddd'
            }}
          />
        ) : focus.options ? (
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel>{focus.name}</InputLabel>
            <Select
              value={tempInputValue || focus.value || ''}
              disabled={!isInteractable}
              onChange={(e) => {
                handleAction({
                  ...focus,
                  value: e.target.value
                }, focus.nodeType || 'FormField');
              }}
            >
              {focus.options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            fullWidth
            autoFocus
            value={localInputValue !== null ? localInputValue : (focus.value || '')}
            disabled={!isInteractable}
            placeholder={focus.placeholder}
            type={focus.inputType || 'text'}
            onChange={(e) => setLocalInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            onBlur={() => setLocalInputValue(null)}
            sx={{ mt: 1 }}
            InputProps={{
              startAdornment: focus.fieldType && (
                <Chip
                  size="small"
                  label={focus.fieldType}
                  sx={{ mr: 1 }}
                />
              ),
            }}
          />
        )}

        {!focus.options && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ mt: 1, display: 'block' }}
          >
            Appuyez sur Entrée pour soumettre
          </Typography>
        )}
      </form>
    </Paper>
  ) : focus.nodeType === "ACTION" || focus.nodeType === "MenuAction" ? (
    <Button
      fullWidth
      variant="contained"
      color={focus.nodeType === "MenuAction" ? "info" : "primary"}
      startIcon={focus.nodeType === "MenuAction" ? <MousePointer /> : <PlayCircle />}
      onClick={() => handleAction(focus, focus.nodeType)}
      disabled={!isInteractable}
      sx={{ mt: 1 }}
    >
      {focus.text || focus.name || actionButtonValue}
    </Button>
  ) : focus.nodeType === "KeyAction" ? (
    <Button
      fullWidth
      variant="outlined"
      startIcon={<KeyRound />}
      onClick={() => handleAction(focus, focus.nodeType)}
      disabled={!isInteractable}
      sx={{ mt: 1 }}
    >
      {focus.text || focus.name}
    </Button>
  ) : (
    <Paper sx={{ p: 2, mt: 1, bgcolor: 'grey.100' }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <AlertOctagon size={16} color="error" />
        <Typography variant="body2" color="error">
          Type de focus non pris en charge: {focus.nodeType}
        </Typography>
      </Stack>
    </Paper>
  )}
</Grid>
          
        </Grid>
      </Box>
    );
  };

  // Composant Section d'Actions
  const ActionsSection = () => {
    const actions = getActions();

    const ActionButton = ({ action }) => (
      <Button
        fullWidth
        variant="outlined"
        disabled={!action.isActive || disabled || !connectionState.connected}
        onClick={() => handleAction(action, action.nodeType || 'Action')}
        endIcon={<ChevronRight />}
        sx={{
          justifyContent: 'space-between',
          textAlign: 'left',
          py: 1.5,
          px: 2,
          bgcolor: action.isActive ? 'background.paper' : 'action.disabledBackground',
          '&:hover': {
            bgcolor: action.isActive ? 'primary.50' : 'action.disabledBackground',
          }
        }}
      >
        <Stack spacing={0.5}>
          <Typography variant="body1">
            {action.text || action.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              size="small"
              label={action.nodeType || 'Action'}
              color={action.isActive ? 'primary' : 'default'}
              variant="outlined"
            />
            {action.shortcut && (
              <Chip
                size="small"
                label={action.shortcut}
                color={action.isActive ? 'info' : 'default'}
                variant="outlined"
              />
            )}
          </Stack>
        </Stack>
      </Button>
    );

    return (
      <Box sx={{ p: 3 }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
            mb: 2
          }}
        >
          <Timer sx={{
            width: 20,
            height: 20,
            color: 'primary.main'
          }} />
          <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
            Actions disponibles
          </Typography>
        </Stack>

        <Stack spacing={1}>
          {actions.map((action) => (
            <ActionButton key={action.id || action.name} action={action} />
          ))}
        </Stack>
      </Box>
    );
  };

  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionState.connected) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };

  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionState.connected ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />

      <QuickActions />
      <Divider />

      <Box sx={{
        display: 'flex',
        flex: 1,
        overflow: 'hidden'
      }}>
        {/* Barre latérale de menu */}
        <Box sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          overflow: 'auto'
        }}>
          <TreeMenu
            structure={data?.technicalProperties?.userInterface?.startMenu?.structure || []}
            onSelect={handleMenuSelect}
          />
        </Box>

        {/* Contenu principal */}
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <FocusSection />
          <Divider />
          <ActionsSection />
        </Box>
      </Box>

      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Timer size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export default GeneroCapture;