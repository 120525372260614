import React from 'react';
import { X, Filter, Check } from 'lucide-react';
import Button from './Button';
import Card from './Card';

const DataFilters = ({
  isOpen,
  onClose,
  filters,
  onChange,
  options = {
    status: [
      { value: 'all', label: 'Tous' },
      { value: 'active', label: 'Actif' },
      { value: 'pending', label: 'En attente' },
      { value: 'inactive', label: 'Inactif' }
    ],
    priority: [
      { value: 'all', label: 'Toutes' },
      { value: 'high', label: 'Haute' },
      { value: 'medium', label: 'Moyenne' },
      { value: 'low', label: 'Basse' }
    ],
    category: [
      { value: 'all', label: 'Toutes' },
      { value: 'feature', label: 'Fonctionnalité' },
      { value: 'bug', label: 'Bug' },
      { value: 'improvement', label: 'Amélioration' }
    ]
  }
}) => {
  // État local pour gérer les filtres temporaires
  const [tempFilters, setTempFilters] = React.useState(filters);

  // Réinitialiser les filtres temporaires à l'ouverture
  React.useEffect(() => {
    if (isOpen) {
      setTempFilters(filters);
    }
  }, [isOpen, filters]);

  if (!isOpen) return null;

  // Gestion des changements de filtres
  const handleFilterChange = (key, value) => {
    setTempFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  // Application des filtres
  const handleApply = () => {
    onChange(tempFilters);
    onClose();
  };

  // Réinitialisation des filtres
  const handleReset = () => {
    const resetFilters = Object.keys(filters).reduce((acc, key) => {
      acc[key] = 'all';
      return acc;
    }, {});
    setTempFilters(resetFilters);
    onChange(resetFilters);
    onClose();
  };

  // Style personnalisé pour les options sélectionnées
  const getOptionStyle = (key, optionValue) => {
    const isSelected = tempFilters[key] === optionValue;
    return `
      flex items-center justify-between w-full px-4 py-2.5 text-left 
      rounded-lg transition-all duration-200
      ${isSelected 
        ? 'bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400' 
        : 'hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300'}
    `;
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/20 backdrop-blur-sm">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Overlay avec effet de flou */}
        <div 
          className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 backdrop-blur-sm" 
          onClick={onClose}
        />

        {/* Modal */}
        <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl dark:bg-gray-800 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          {/* En-tête */}
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-2">
              <Filter className="w-5 h-5 text-blue-500" />
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Filtres
              </h3>
            </div>
            <button
              onClick={onClose}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          </div>

          {/* Corps du modal */}
          <div className="space-y-6">
            {Object.entries(options).map(([key, values]) => (
              <div key={key} className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 capitalize">
                  {key}
                </label>
                <div className="space-y-1">
                  {values.map(({ value, label }) => (
                    <button
                      key={value}
                      className={getOptionStyle(key, value)}
                      onClick={() => handleFilterChange(key, value)}
                    >
                      <span>{label}</span>
                      {tempFilters[key] === value && (
                        <Check className="w-4 h-4 text-blue-500" />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Pied du modal */}
          <div className="mt-6 flex justify-end space-x-3">
            <Button
              variant="outline"
              onClick={handleReset}
            >
              Réinitialiser
            </Button>
            <Button
              variant="primary"
              onClick={handleApply}
            >
              Appliquer
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataFilters;