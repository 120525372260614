// config/env.js

const ENV = process.env.REACT_APP_ENV || 'development';

const configs = {
  // Configuration de développement
  development: {
    API_URL: process.env.REACT_APP_API_URL || 'http://144.76.167.16:8080/api',
     // API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8088/api',
    TIMEOUT: 15000,
    DEBUG: true,
    AUTH: {
      TOKEN_KEY: 'auth_token',
      REFRESH_TOKEN_KEY: 'refresh_token',
      TOKEN_EXPIRY: '1h',
    },
    FEATURES: {
      ENABLE_LOGS: true,
      ENABLE_MOCK: true,
    }
  },

  // Configuration de test
  testing: {
    API_URL: process.env.REACT_APP_API_URL || 'http://api.test.com/api',
    TIMEOUT: 15000,
    DEBUG: true,
    AUTH: {
      TOKEN_KEY: 'auth_token_test',
      REFRESH_TOKEN_KEY: 'refresh_token_test',
      TOKEN_EXPIRY: '1h',
    },
    FEATURES: {
      ENABLE_LOGS: true,
      ENABLE_MOCK: true,
    }
  },

  // Configuration de production
  production: {
    API_URL: process.env.REACT_APP_API_URL,
    TIMEOUT: 30000,
    DEBUG: false,
    AUTH: {
      TOKEN_KEY: 'auth_token_prod',
      REFRESH_TOKEN_KEY: 'refresh_token_prod',
      TOKEN_EXPIRY: '1h',
    },
    FEATURES: {
      ENABLE_LOGS: false,
      ENABLE_MOCK: false,
    }
  }
};

// Configuration par défaut
const defaultConfig = {
  VERSION: process.env.REACT_APP_VERSION || '1.0.0',
  APP_NAME: 'Mon Application',
  SUPPORTED_LOCALES: ['fr', 'en', 'es'],
  DEFAULT_LOCALE: 'fr',
  THEME: {
    DEFAULT_THEME: 'light',
    SUPPORTED_THEMES: ['light', 'dark'],
  },
  PAGINATION: {
    DEFAULT_PAGE_SIZE: 10,
    PAGE_SIZES: [10, 20, 50, 100],
  },
  DATE_FORMAT: 'DD/MM/YYYY',
  DATETIME_FORMAT: 'DD/MM/YYYY HH:mm',
  UPLOADS: {
    MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
    ALLOWED_TYPES: ['image/jpeg', 'image/png', 'application/pdf'],
  }
};

// Fusionner la configuration spécifique à l'environnement avec la configuration par défaut
const config = {
  ...defaultConfig,
  ...configs[ENV],
  ENV
};

// Helper pour vérifier l'environnement
config.isDev = ENV === 'development';
config.isProd = ENV === 'production';
config.isTest = ENV === 'testing';

// Logger conditionnel
config.log = config.DEBUG 
  ? console.log.bind(console, '[APP]')
  : () => {};

// Fonction pour obtenir une valeur de configuration
export const get = (path, defaultValue = null) => {
  return path.split('.').reduce((acc, part) => {
    return acc && acc[part] !== undefined ? acc[part] : defaultValue;
  }, config);
};

// Validation des variables d'environnement requises
const requiredVars = ['API_URL'];

requiredVars.forEach(varName => {
  if (!get(varName)) {
    throw new Error(`Variable d'environnement manquante: ${varName}`);
  }
});

export default config;