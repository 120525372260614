import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  InputAdornment,
  LinearProgress,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  Breadcrumbs,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  TextareaAutosize
} from '@mui/material';

import {
  Globe,
  Server,
  Code,
  MousePointer,
  Keyboard,
  Eye,
  RefreshCw,
  Search,
  X,
  ArrowLeft,
  ArrowRight,
  Home,
  Plus,
  Download,
  Upload,
  Play,
  Square,
  Trash2,
  Copy,
  CheckCircle,
  AlertTriangle,
  Info,
  Settings,
  ChevronDown,
  ChevronRight,
  Clock,
  Save,
  Edit,
  Maximize,
  Minimize,
  Monitor,
  Layout,
  Image,
  RotateCcw,
  Database,
  Cookie,
  FileText,
  Layers,
  Wifi,
  WifiOff,
  Terminal,
  Send,
  AlertOctagon,
  CornerDownRight,
  PlayCircle,
  StopCircle
} from 'lucide-react';

/**
 * WebBrowserCapture Component - Interface réutilisable pour interagir avec des applications web dans un navigateur
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles de l'application web
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const WebBrowserCapture = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États de navigation
  const [currentUrl, setCurrentUrl] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [navigationHistory, setNavigationHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [tabsList, setTabsList] = useState([{ id: 1, title: 'Nouvel onglet', url: '', active: true }]);
  const [activeTabId, setActiveTabId] = useState(1);
  const [nextTabId, setNextTabId] = useState(2);
  
  // États pour la console et les outils de développement
  const [consoleOutput, setConsoleOutput] = useState([]);
  const [consoleInput, setConsoleInput] = useState('');
  const [networkRequests, setNetworkRequests] = useState([]);
  const [domTree, setDomTree] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [cookies, setCookies] = useState([]);
  const [localStorage, setLocalStorage] = useState([]);
  const [screenshots, setScreenshots] = useState([]);
  
  // États pour le panneau d'enregistrement
  const [recordedActions, setRecordedActions] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [elementSelector, setElementSelector] = useState('');
  const [selectedAction, setSelectedAction] = useState('click');
  const [actionValue, setActionValue] = useState('');
  const [waitConditions, setWaitConditions] = useState({
    waitForNavigation: true,
    timeout: 5000,
    waitForSelector: '',
    waitForPageLoad: true
  });
  
  // États pour les préférences et configurations
  const [viewport, setViewport] = useState({ width: 1280, height: 720 });
  const [userAgent, setUserAgent] = useState('Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36');
  const [headless, setHeadless] = useState(false);
  const [proxySettings, setProxySettings] = useState({ enabled: false, server: '', port: '', username: '', password: '' });
  
  // États UI généraux
  const [activeTab, setActiveTab] = useState(0);
  const [lastAction, setLastAction] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [expandedPanel, setExpandedPanel] = useState('browser');
  const [browserInfo, setBrowserInfo] = useState({ type: 'Chrome', version: '121.0.0.0', platform: 'Windows' });
  const [connectionState, setConnectionState] = useState({ connected: false, clientState: null });
  
  // Références
  const consoleOutputRef = useRef(null);
  const browserFrameRef = useRef(null);
  
  // Effet pour gérer les mises à jour de l'état de connexion
  useEffect(() => {
    setConnectionState(prev => ({
      ...prev,
      connected: connectionStatus,
      clientState: data?.technicalProperties?.clientState || null
    }));
  }, [connectionStatus, data?.technicalProperties?.clientState]);
  
  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les résultats de navigation
      if (data.technicalProperties.navigation) {
        handleNavigationResults(data.technicalProperties.navigation);
      }
      
      // Traiter les logs de console
      if (data.technicalProperties.consoleLogs) {
        handleConsoleResults(data.technicalProperties.consoleLogs);
      }
      
      // Traiter les requêtes réseau
      if (data.technicalProperties.networkRequests) {
        handleNetworkResults(data.technicalProperties.networkRequests);
      }
      
      // Traiter la structure DOM
      if (data.technicalProperties.domTree) {
        handleDomTreeResults(data.technicalProperties.domTree);
      }
      
      // Traiter les cookies
      if (data.technicalProperties.cookies) {
        handleCookiesResults(data.technicalProperties.cookies);
      }
      
      // Traiter le localStorage
      if (data.technicalProperties.localStorage) {
        handleLocalStorageResults(data.technicalProperties.localStorage);
      }
      
      // Traiter les captures d'écran
      if (data.technicalProperties.screenshot) {
        handleScreenshotResults(data.technicalProperties.screenshot);
      }
    }
  }, [data]);
  
  // Effet pour faire défiler la console vers le bas
  useEffect(() => {
    if (consoleOutputRef.current) {
      consoleOutputRef.current.scrollTop = consoleOutputRef.current.scrollHeight;
    }
  }, [consoleOutput]);
  
  // Handler pour les résultats de navigation
  const handleNavigationResults = (navigation) => {
    setIsLoading(false);
    setCurrentUrl(navigation.url || '');
    setUrlInput(navigation.url || '');
    setPageTitle(navigation.title || 'Sans titre');
    
    // Mettre à jour l'historique de navigation
    if (navigation.url && (navigationHistory.length === 0 || navigationHistory[historyIndex]?.url !== navigation.url)) {
      if (historyIndex < navigationHistory.length - 1) {
        // Si on a navigué en arrière puis à une nouvelle URL, tronquer l'historique
        const newHistory = navigationHistory.slice(0, historyIndex + 1);
        setNavigationHistory([...newHistory, { url: navigation.url, title: navigation.title }]);
        setHistoryIndex(newHistory.length);
      } else {
        setNavigationHistory([...navigationHistory, { url: navigation.url, title: navigation.title }]);
        setHistoryIndex(navigationHistory.length);
      }
    }
    
    // Mettre à jour l'onglet actif
    setTabsList(prev => prev.map(tab => 
      tab.id === activeTabId 
        ? { ...tab, url: navigation.url, title: navigation.title || 'Sans titre' } 
        : tab
    ));
  };
  
  // Handler pour les résultats de console
  const handleConsoleResults = (consoleLogs) => {
    const logsFormatted = consoleLogs.logs.map(log => ({
      type: log.type || 'log',
      content: log.content,
      timestamp: new Date(log.timestamp || Date.now())
    }));
    
    setConsoleOutput(prev => [...prev, ...logsFormatted]);
  };
  
  // Handler pour les résultats de réseau
  const handleNetworkResults = (networkData) => {
    setNetworkRequests(networkData.requests || []);
  };
  
  // Handler pour les résultats de l'arbre DOM
  const handleDomTreeResults = (domData) => {
    setDomTree(domData);
  };
  
  // Handler pour les résultats de cookies
  const handleCookiesResults = (cookiesData) => {
    setCookies(cookiesData.cookies || []);
  };
  
  // Handler pour les résultats du localStorage
  const handleLocalStorageResults = (storageData) => {
    setLocalStorage(storageData.items || []);
  };
  
  // Handler pour les résultats de capture d'écran
  const handleScreenshotResults = (screenshotData) => {
    if (screenshotData.data) {
      const newScreenshot = {
        id: Date.now(),
        data: screenshotData.data,
        timestamp: new Date(),
        url: currentUrl,
        title: pageTitle
      };
      
      setScreenshots(prev => [newScreenshot, ...prev]);
    }
  };
  
  // Fonction pour naviguer vers une URL
  const navigateTo = (url) => {
    if (disabled || !connectionState.connected) return;
    
    // Ajouter http:// si nécessaire
    let formattedUrl = url;
    if (!/^https?:\/\//i.test(url)) {
      formattedUrl = 'http://' + url;
    }
    
    setIsLoading(true);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserNavigation",
        nodeName: "Navigate",
        nodeValue: formattedUrl,
        actionType: "Browser",
        actionValue: `navigateTo('${formattedUrl}')`,
        actionName: "Navigate",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'navigation',
        action: 'navigate',
        value: formattedUrl,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserNavigation',
      name: `Navigate to ${formattedUrl}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour recharger la page
  const reloadPage = () => {
    if (disabled || !connectionState.connected || !currentUrl) return;
    
    setIsLoading(true);
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserNavigation",
        nodeName: "Reload",
        nodeValue: currentUrl,
        actionType: "Browser",
        actionValue: "reload()",
        actionName: "Reload",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'navigation',
        action: 'reload',
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserNavigation',
      name: 'Reload page',
      timestamp: new Date()
    });
  };
  
  // Fonction pour naviguer en avant/arrière
  const navigateHistory = (direction) => {
    if (disabled || !connectionState.connected) return;
    
    if (direction === 'back' && historyIndex > 0) {
      const newIndex = historyIndex - 1;
      const destination = navigationHistory[newIndex];
      
      const interactionPayload = {
        direction: "REQUEST",
        type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
        timestamp: new Date().toISOString(),
        technicalProperties: {
          nodeType: "BrowserNavigation",
          nodeName: "Back",
          nodeValue: destination.url,
          actionType: "Browser",
          actionValue: "goBack()",
          actionName: "Back",
          timestamp: Date.now()
        }
      };
      
      onInteractionSent(interactionPayload);
      
      setIsLoading(true);
      setHistoryIndex(newIndex);
      
      if (isRecording) {
        addRecordedAction({
          type: 'navigation',
          action: 'back',
          timestamp: new Date()
        });
      }
      
      setLastAction({
        type: 'BrowserNavigation',
        name: 'Navigate back',
        timestamp: new Date()
      });
    } else if (direction === 'forward' && historyIndex < navigationHistory.length - 1) {
      const newIndex = historyIndex + 1;
      const destination = navigationHistory[newIndex];
      
      const interactionPayload = {
        direction: "REQUEST",
        type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
        timestamp: new Date().toISOString(),
        technicalProperties: {
          nodeType: "BrowserNavigation",
          nodeName: "Forward",
          nodeValue: destination.url,
          actionType: "Browser",
          actionValue: "goForward()",
          actionName: "Forward",
          timestamp: Date.now()
        }
      };
      
      onInteractionSent(interactionPayload);
      
      setIsLoading(true);
      setHistoryIndex(newIndex);
      
      if (isRecording) {
        addRecordedAction({
          type: 'navigation',
          action: 'forward',
          timestamp: new Date()
        });
      }
      
      setLastAction({
        type: 'BrowserNavigation',
        name: 'Navigate forward',
        timestamp: new Date()
      });
    }
  };
  
  // Fonction pour exécuter du JavaScript dans la page
  const executeJavaScript = (script, startRecording = false) => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserScript",
        nodeName: "ExecuteScript",
        nodeValue: script,
        actionType: "Browser",
        actionValue: `executeScript('${script}')`,
        actionName: "ExecuteScript",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    // Si cette action démarre un enregistrement, activer l'enregistrement
    if (startRecording) {
      setIsRecording(true);
      setRecordedActions([]);
      setSnackbar({
        open: true,
        message: 'Enregistrement démarré',
        severity: 'success'
      });
    }
    
    if (isRecording) {
      addRecordedAction({
        type: 'script',
        action: 'executeScript',
        value: script,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserScript',
      name: `Execute script: ${script.length > 30 ? script.substring(0, 30) + '...' : script}`,
      timestamp: new Date()
    });
    
    // Effacer l'entrée de la console
    setConsoleInput('');
  };
  
  // Fonction pour cliquer sur un élément
  const clickElement = (selector, x = null, y = null) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      x: x,
      y: y
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserElement",
        nodeName: "ClickElement",
        nodeValue: JSON.stringify(payload),
        actionType: "Browser",
        actionValue: `click('${selector}')`,
        actionName: "ClickElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'element',
        action: 'click',
        selector: selector,
        coordinates: x !== null && y !== null ? {x, y} : undefined,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserElement',
      name: `Click element: ${selector}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour saisir du texte dans un élément
  const typeText = (selector, text) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      text: text
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserElement",
        nodeName: "TypeText",
        nodeValue: JSON.stringify(payload),
        actionType: "Browser",
        actionValue: `type('${selector}', '${text}')`,
        actionName: "TypeText",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'element',
        action: 'type',
        selector: selector,
        value: text,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserElement',
      name: `Type text in ${selector}: ${text.length > 20 ? text.substring(0, 20) + '...' : text}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour sélectionner une option dans un select
  const selectOption = (selector, value) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      value: value
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserElement",
        nodeName: "SelectOption",
        nodeValue: JSON.stringify(payload),
        actionType: "Browser",
        actionValue: `select('${selector}', '${value}')`,
        actionName: "SelectOption",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'element',
        action: 'select',
        selector: selector,
        value: value,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserElement',
      name: `Select option in ${selector}: ${value}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour attendre un élément
  const waitForElement = (selector, timeout = 5000) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      timeout: timeout
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserWait",
        nodeName: "WaitForElement",
        nodeValue: JSON.stringify(payload),
        actionType: "Browser",
        actionValue: `waitForSelector('${selector}', { timeout: ${timeout} })`,
        actionName: "WaitForElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'wait',
        action: 'waitForElement',
        selector: selector,
        timeout: timeout,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserWait',
      name: `Wait for element: ${selector}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour prendre une capture d'écran
  const takeScreenshot = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserCapture",
        nodeName: "Screenshot",
        nodeValue: "fullPage",
        actionType: "Browser",
        actionValue: "screenshot({ fullPage: true })",
        actionName: "Screenshot",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'capture',
        action: 'screenshot',
        fullPage: true,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserCapture',
      name: 'Take screenshot',
      timestamp: new Date()
    });
  };
  
  // Fonction pour obtenir l'arbre DOM
  const getDomTree = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserInspect",
        nodeName: "GetDomTree",
        nodeValue: "document.body",
        actionType: "Browser",
        actionValue: "getDomTree()",
        actionName: "GetDomTree",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'BrowserInspect',
      name: 'Get DOM tree',
      timestamp: new Date()
    });
  };
  
  // Fonction pour obtenir les cookies
  const getCookies = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserStorage",
        nodeName: "GetCookies",
        nodeValue: currentUrl,
        actionType: "Browser",
        actionValue: "getCookies()",
        actionName: "GetCookies",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'BrowserStorage',
      name: 'Get cookies',
      timestamp: new Date()
    });
  };
  
  // Fonction pour obtenir le localStorage
  const getLocalStorage = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserStorage",
        nodeName: "GetLocalStorage",
        nodeValue: currentUrl,
        actionType: "Browser",
        actionValue: "getLocalStorage()",
        actionName: "GetLocalStorage",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'BrowserStorage',
      name: 'Get localStorage',
      timestamp: new Date()
    });
  };
  
  // Fonction pour ajouter une action enregistrée
  const addRecordedAction = (action) => {
    setRecordedActions(prev => [...prev, action]);
  };
  
  // Fonction pour démarrer l'enregistrement
  const startRecording = () => {
    setIsRecording(true);
    setRecordedActions([]);
    
    // Notifier l'agent de démarrer l'enregistrement côté navigateur également
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserRecord",
        nodeName: "StartRecording",
        nodeValue: "true",
        actionType: "Browser",
        actionValue: "startRecording()",
        actionName: "StartRecording",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setSnackbar({
      open: true,
      message: 'Enregistrement démarré',
      severity: 'success'
    });
    
    setLastAction({
      type: 'BrowserRecord',
      name: 'Start recording',
      timestamp: new Date()
    });
  };
  
  // Fonction pour arrêter l'enregistrement
  const stopRecording = () => {
    setIsRecording(false);
    
    // Notifier l'agent d'arrêter l'enregistrement côté navigateur également
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserRecord",
        nodeName: "StopRecording",
        nodeValue: "true",
        actionType: "Browser",
        actionValue: "stopRecording()",
        actionName: "StopRecording",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setSnackbar({
      open: true,
      message: `Enregistrement arrêté - ${recordedActions.length} actions enregistrées`,
      severity: 'info'
    });
    
    setLastAction({
      type: 'BrowserRecord',
      name: 'Stop recording',
      timestamp: new Date()
    });
  };
  
  // Fonction pour exécuter une action sur un élément
  const executeElementAction = () => {
    if (!elementSelector || !selectedAction || disabled || !connectionState.connected) return;
    
    switch (selectedAction) {
      case 'click':
        clickElement(elementSelector);
        break;
      case 'type':
        if (actionValue) {
          typeText(elementSelector, actionValue);
        }
        break;
      case 'select':
        if (actionValue) {
          selectOption(elementSelector, actionValue);
        }
        break;
      case 'wait':
        waitForElement(elementSelector, waitConditions.timeout);
        break;
      case 'hover':
        // Implémentation de l'action hover
        const interactionPayload = {
          direction: "REQUEST",
          type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
          timestamp: new Date().toISOString(),
          technicalProperties: {
            nodeType: "BrowserElement",
            nodeName: "HoverElement",
            nodeValue: elementSelector,
            actionType: "Browser",
            actionValue: `hover('${elementSelector}')`,
            actionName: "HoverElement",
            timestamp: Date.now()
          }
        };
        
        onInteractionSent(interactionPayload);
        
        if (isRecording) {
          addRecordedAction({
            type: 'element',
            action: 'hover',
            selector: elementSelector,
            timestamp: new Date()
          });
        }
        
        setLastAction({
          type: 'BrowserElement',
          name: `Hover over element: ${elementSelector}`,
          timestamp: new Date()
        });
        break;
      default:
        console.warn(`Action non prise en charge: ${selectedAction}`);
    }
    
    // Réinitialiser les champs après l'action
    setActionValue('');
  };
  
  // Fonction pour gérer la création d'un nouvel onglet
  const createNewTab = () => {
    const newTab = {
      id: nextTabId,
      title: 'Nouvel onglet',
      url: '',
      active: true
    };
    
    // Désactiver tous les autres onglets
    setTabsList(prev => prev.map(tab => ({...tab, active: false})));
    
    // Ajouter le nouvel onglet
    setTabsList(prev => [...prev, newTab]);
    setActiveTabId(nextTabId);
    setNextTabId(prevId => prevId + 1);
    
    // Réinitialiser l'URL et le titre
    setCurrentUrl('');
    setUrlInput('');
    setPageTitle('Nouvel onglet');
    setNavigationHistory([]);
    setHistoryIndex(-1);
    
    // Notifier l'agent de créer un nouvel onglet
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserTab",
        nodeName: "CreateTab",
        nodeValue: nextTabId.toString(),
        actionType: "Browser",
        actionValue: "newTab()",
        actionName: "CreateTab",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'tab',
        action: 'create',
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserTab',
      name: 'Create new tab',
      timestamp: new Date()
    });
  };
  
  // Fonction pour fermer un onglet
  const closeTab = (tabId) => {
    // Vérifier qu'il reste au moins un onglet après fermeture
    if (tabsList.length <= 1) return;
    
    // Trouver l'onglet à fermer
    const tabToClose = tabsList.find(tab => tab.id === tabId);
    if (!tabToClose) return;
    
    // Vérifier si c'est l'onglet actif
    const isActive = tabToClose.active;
    
    // Supprimer l'onglet
    const newTabsList = tabsList.filter(tab => tab.id !== tabId);
    setTabsList(newTabsList);
    
    // Si c'était l'onglet actif, activer le dernier onglet restant
    if (isActive && newTabsList.length > 0) {
      const lastTab = newTabsList[newTabsList.length - 1];
      
      // Activer cet onglet
      setTabsList(newTabsList.map(tab => 
        tab.id === lastTab.id ? {...tab, active: true} : tab
      ));
      
      setActiveTabId(lastTab.id);
      setCurrentUrl(lastTab.url);
      setUrlInput(lastTab.url);
      setPageTitle(lastTab.title);
    }
    
    // Notifier l'agent de fermer l'onglet
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserTab",
        nodeName: "CloseTab",
        nodeValue: tabId.toString(),
        actionType: "Browser",
        actionValue: `closeTab(${tabId})`,
        actionName: "CloseTab",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'tab',
        action: 'close',
        tabId: tabId,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserTab',
      name: `Close tab ${tabId}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour changer d'onglet
  const switchTab = (tabId) => {
    // Vérifier que l'onglet existe
    const targetTab = tabsList.find(tab => tab.id === tabId);
    if (!targetTab) return;
    
    // Mettre à jour tous les onglets
    setTabsList(tabsList.map(tab => ({
      ...tab,
      active: tab.id === tabId
    })));
    
    setActiveTabId(tabId);
    setCurrentUrl(targetTab.url);
    setUrlInput(targetTab.url);
    setPageTitle(targetTab.title);
    
    // Notifier l'agent de changer d'onglet
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "BrowserTab",
        nodeName: "SwitchTab",
        nodeValue: tabId.toString(),
        actionType: "Browser",
        actionValue: `switchTab(${tabId})`,
        actionName: "SwitchTab",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'tab',
        action: 'switch',
        tabId: tabId,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'BrowserTab',
      name: `Switch to tab ${tabId}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour exporter les actions enregistrées sous forme de script
  const exportRecordedActions = () => {
    if (recordedActions.length === 0) {
      setSnackbar({
        open: true,
        message: 'Aucune action à exporter',
        severity: 'warning'
      });
      return;
    }
    
    // Convertir les actions en script JavaScript pour Playwright
    let script = `// Script généré le ${new Date().toLocaleString()}\n`;
    script += `const { chromium } = require('playwright');\n\n`;
    script += `(async () => {\n`;
    script += `  const browser = await chromium.launch({\n`;
    script += `    headless: ${headless},\n`;
    script += `    viewport: { width: ${viewport.width}, height: ${viewport.height} }\n`;
    script += `  });\n`;
    script += `  const context = await browser.newContext({\n`;
    script += `    userAgent: '${userAgent}'\n`;
    script += `  });\n`;
    script += `  const page = await context.newPage();\n\n`;
    
    // Ajouter chaque action
    for (const action of recordedActions) {
      switch (action.type) {
        case 'navigation':
          if (action.action === 'navigate') {
            script += `  await page.goto('${action.value}');\n`;
          } else if (action.action === 'reload') {
            script += `  await page.reload();\n`;
          } else if (action.action === 'back') {
            script += `  await page.goBack();\n`;
          } else if (action.action === 'forward') {
            script += `  await page.goForward();\n`;
          }
          break;
          
        case 'element':
          if (action.action === 'click') {
            if (action.coordinates) {
              script += `  await page.mouse.click(${action.coordinates.x}, ${action.coordinates.y});\n`;
            } else {
              script += `  await page.click('${action.selector}');\n`;
            }
          } else if (action.action === 'type') {
            script += `  await page.fill('${action.selector}', '${action.value}');\n`;
          } else if (action.action === 'select') {
            script += `  await page.selectOption('${action.selector}', '${action.value}');\n`;
          } else if (action.action === 'hover') {
            script += `  await page.hover('${action.selector}');\n`;
          }
          break;
          
        case 'wait':
          if (action.action === 'waitForElement') {
            script += `  await page.waitForSelector('${action.selector}', { timeout: ${action.timeout} });\n`;
          } else if (action.action === 'waitForNavigation') {
            script += `  await page.waitForNavigation();\n`;
          } else if (action.action === 'waitForTimeout') {
            script += `  await page.waitForTimeout(${action.timeout});\n`;
          }
          break;
          
        case 'script':
          script += `  await page.evaluate(() => {\n    ${action.value}\n  });\n`;
          break;
          
        case 'capture':
          if (action.action === 'screenshot') {
            script += `  await page.screenshot({ path: 'screenshot-${new Date().getTime()}.png', fullPage: ${action.fullPage} });\n`;
          }
          break;
          
        case 'tab':
          if (action.action === 'create') {
            script += `  const newPage = await context.newPage();\n`;
            script += `  page = newPage; // Switch context to new page\n`;
          } else if (action.action === 'close') {
            script += `  await page.close();\n`;
          }
          break;
      }
      
      // Ajouter un petit délai entre les actions pour améliorer la stabilité
      script += `  await page.waitForTimeout(500); // Stabilization delay\n`;
    }
    
    script += `\n  await browser.close();\n`;
    script += `})();`;
    
    // Créer un blob et déclencher le téléchargement
    const blob = new Blob([script], { type: 'text/javascript' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `browser-script-${new Date().getTime()}.js`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    
    setSnackbar({
      open: true,
      message: 'Script exporté avec succès',
      severity: 'success'
    });
  };
  
  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Globe sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              Web Browser {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionState.connected ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionState.connected ? 'success' : 'error'}
            label={connectionState.connected ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Navigateur
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {`${browserInfo.type} ${browserInfo.version}`}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Plateforme
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {browserInfo.platform}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                URL Actuelle
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {currentUrl || 'Aucune page chargée'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant de navigateur web
  const BrowserComponent = () => {
    return (
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%' 
      }}>
        {/* Barre d'onglets */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          borderBottom: 1, 
          borderColor: 'divider',
          overflowX: 'auto',
          py: 0.5
        }}>
          {tabsList.map(tab => (
            <Box
              key={tab.id}
              sx={{
                px: 2,
                py: 1,
                minWidth: 120,
                maxWidth: 200,
                borderRight: 1,
                borderColor: 'divider',
                bgcolor: tab.active ? 'primary.50' : 'background.paper',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: tab.active ? 'primary.100' : 'grey.100'
                }
              }}
              onClick={() => switchTab(tab.id)}
            >
              <Typography
                variant="body2"
                noWrap
                sx={{
                  flex: 1,
                  fontWeight: tab.active ? 'bold' : 'normal'
                }}
              >
                {tab.title || 'Nouvel onglet'}
              </Typography>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  closeTab(tab.id);
                }}
                disabled={tabsList.length <= 1}
              >
                <X size={14} />
              </IconButton>
            </Box>
          ))}
          
          <IconButton
            size="small"
            onClick={createNewTab}
            sx={{ ml: 1 }}
          >
            <Plus size={18} />
          </IconButton>
        </Box>

        {/* Barre de navigation */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          my: 1, 
          gap: 1 
        }}>
          <IconButton
            size="small"
            onClick={() => navigateHistory('back')}
            disabled={historyIndex <= 0 || disabled || !connectionState.connected}
          >
            <ArrowLeft size={18} />
          </IconButton>
          
          <IconButton
            size="small"
            onClick={() => navigateHistory('forward')}
            disabled={historyIndex >= navigationHistory.length - 1 || disabled || !connectionState.connected}
          >
            <ArrowRight size={18} />
          </IconButton>
          
          <IconButton
            size="small"
            onClick={reloadPage}
            disabled={!currentUrl || disabled || !connectionState.connected}
          >
            <RefreshCw size={18} />
          </IconButton>
          
          <IconButton
            size="small"
            onClick={() => navigateTo('about:blank')}
            disabled={disabled || !connectionState.connected}
          >
            <Home size={18} />
          </IconButton>
          
          <TextField
            fullWidth
            size="small"
            value={urlInput}
            onChange={(e) => setUrlInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigateTo(urlInput);
              }
            }}
            placeholder="Entrez une URL..."
            disabled={disabled || !connectionState.connected}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Globe size={16} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => navigateTo(urlInput)}
                    disabled={!urlInput || disabled || !connectionState.connected}
                  >
                    <Search size={16} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          
          <IconButton
            size="small"
            onClick={takeScreenshot}
            disabled={!currentUrl || disabled || !connectionState.connected}
            title="Prendre une capture d'écran"
          >
            <Image size={18} />
          </IconButton>
        </Box>

        {/* Cadre du navigateur */}
        <Paper
          ref={browserFrameRef}
          variant="outlined"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden',
            bgcolor: '#f5f5f5'
          }}
        >
          {isLoading && (
            <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />
          )}
          
          {!currentUrl ? (
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100%',
                color: 'text.secondary'
              }}
            >
              <Globe size={64} strokeWidth={1} />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Nouvel onglet
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Entrez une URL dans la barre d'adresse pour commencer.
              </Typography>
            </Box>
          ) : (
            <Box 
              sx={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                position: 'relative'
              }}
            >
              <Paper
                sx={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #ddd',
                  overflow: 'hidden',
                  position: 'relative',
                  bgcolor: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {`Affichage de la page: ${pageTitle}`}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                  {currentUrl}
                </Typography>
                
                <Box sx={{ mt: 4 }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    La représentation visuelle n'est pas disponible dans cette interface.
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block', mt: 1 }}>
                    Utilisez les outils de développement pour interagir avec la page.
                  </Typography>
                </Box>
              </Paper>
            </Box>
          )}
        </Paper>
        
        {/* Barre d'état */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mt: 1,
          px: 1,
          py: 0.5,
          bgcolor: 'grey.100',
          borderRadius: 1
        }}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {currentUrl ? `${currentUrl} - ${pageTitle}` : 'Aucune page chargée'}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Viewport: {viewport.width}x{viewport.height}
            </Typography>
            
            <Chip
              size="small"
              label={browserInfo.type}
              color="primary"
              variant="outlined"
              sx={{ height: 20 }}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  
  // Composant de console développeur
  const ConsoleComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Terminal size={16} style={{ marginRight: 8 }} />
          Console JavaScript
        </Typography>
        
        <Box 
          ref={consoleOutputRef}
          sx={{
            flex: 1,
            bgcolor: 'black',
            color: 'lightgreen',
            p: 2,
            borderRadius: 1,
            fontFamily: 'monospace',
            fontSize: '0.9rem',
            overflowY: 'auto',
            mb: 2
          }}
        >
          {consoleOutput.length === 0 ? (
            <Typography sx={{ color: 'gray', fontStyle: 'italic', fontFamily: 'inherit' }}>
              La console est vide. Exécutez du code JavaScript pour voir les résultats ici.
            </Typography>
          ) : (
            consoleOutput.map((output, index) => (
              <Box key={index} sx={{ mb: 1 }}>
                <Box sx={{ 
                  color: output.type === 'error' ? 'lightcoral' : 
                         output.type === 'warn' ? 'orange' : 
                         output.type === 'info' ? 'lightblue' : 'lightgreen',
                  display: 'flex'
                }}>
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontFamily: 'inherit', 
                      color: 'gray',
                      mr: 1
                    }}
                  >
                    [{output.timestamp.toLocaleTimeString()}]
                  </Typography>
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontFamily: 'inherit'
                    }}
                  >
                    {output.content}
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={consoleInput}
            onChange={(e) => setConsoleInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                executeJavaScript(consoleInput);
              }
            }}
            placeholder="Exécuter du JavaScript..."
            disabled={disabled || !connectionState.connected}
            multiline
            maxRows={4}
            InputProps={{
              sx: { fontFamily: 'monospace' },
              startAdornment: (
                <InputAdornment position="start">
                  <Code size={16} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => executeJavaScript(consoleInput)}
                    disabled={!consoleInput || disabled || !connectionState.connected}
                  >
                    <Send size={16} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button
            size="small"
            startIcon={<Trash2 size={16} />}
            onClick={() => setConsoleOutput([])}
            disabled={consoleOutput.length === 0}
          >
            Effacer la console
          </Button>
        </Box>
      </Box>
    );
  };
  
  // Composant pour le réseau
  const NetworkComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Globe size={16} style={{ marginRight: 8 }} />
          Requêtes réseau
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
          <Button
            size="small"
            startIcon={<RefreshCw size={16} />}
            onClick={() => getDomTree()}
            disabled={!currentUrl || disabled || !connectionState.connected}
          >
            Rafraîchir
          </Button>
        </Box>
        
        <TableContainer component={Paper} sx={{ flex: 1, overflow: 'auto' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell>Méthode</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Taille</TableCell>
                <TableCell>Temps</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {networkRequests.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Aucune requête réseau enregistrée
                  </TableCell>
                </TableRow>
              ) : (
                networkRequests.map((request, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Tooltip title={request.url}>
                        <Typography noWrap sx={{ maxWidth: 200 }}>
                          {request.url}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{request.method}</TableCell>
                    <TableCell>{request.resourceType}</TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        label={request.status || 'N/A'} 
                        color={
                          request.status >= 200 && request.status < 300 ? 'success' :
                          request.status >= 400 ? 'error' :
                          'warning'
                        }
                      />
                    </TableCell>
                    <TableCell>{request.size ? `${(request.size / 1024).toFixed(1)} KB` : 'N/A'}</TableCell>
                    <TableCell>{request.time ? `${request.time} ms` : 'N/A'}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  // Composant pour l'enregistrement d'actions
  const RecordingComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <PlayCircle size={16} style={{ marginRight: 8 }} />
          Enregistrement d'actions
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2,
          pb: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Box>
            <Button
              variant={isRecording ? "contained" : "outlined"}
              color={isRecording ? "error" : "primary"}
              startIcon={isRecording ? <StopCircle /> : <PlayCircle />}
              onClick={isRecording ? stopRecording : startRecording}
              disabled={disabled || !connectionState.connected}
            >
              {isRecording ? 'Arrêter l\'enregistrement' : 'Démarrer l\'enregistrement'}
            </Button>
          </Box>
          
          <Box>
            <Button
              variant="outlined"
              startIcon={<Download />}
              onClick={exportRecordedActions}
              disabled={recordedActions.length === 0}
            >
              Exporter le script
            </Button>
          </Box>
        </Box>
        
        <Typography variant="subtitle2" gutterBottom>
          Exécuter une action manuelle
        </Typography>
        
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              size="small"
              label="Sélecteur CSS"
              placeholder="Ex: #loginButton, .form-input"
              value={elementSelector}
              onChange={(e) => setElementSelector(e.target.value)}
              disabled={disabled || !connectionState.connected}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={16} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Action</InputLabel>
              <Select
                value={selectedAction}
                onChange={(e) => setSelectedAction(e.target.value)}
                disabled={disabled || !connectionState.connected}
              >
                <MenuItem value="click">Click</MenuItem>
                <MenuItem value="type">Saisir texte</MenuItem>
                <MenuItem value="select">Sélectionner option</MenuItem>
                <MenuItem value="hover">Survoler</MenuItem>
                <MenuItem value="wait">Attendre</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={6} sm={4}>
            {(selectedAction === 'type' || selectedAction === 'select') && (
              <TextField
                fullWidth
                size="small"
                label="Valeur"
                value={actionValue}
                onChange={(e) => setActionValue(e.target.value)}
                disabled={disabled || !connectionState.connected}
              />
            )}
            
            {selectedAction === 'wait' && (
              <TextField
                fullWidth
                size="small"
                label="Timeout (ms)"
                type="number"
                value={waitConditions.timeout}
                onChange={(e) => setWaitConditions(prev => ({
                  ...prev,
                  timeout: parseInt(e.target.value) || 5000
                }))}
                disabled={disabled || !connectionState.connected}
              />
            )}
            
            {selectedAction === 'click' && (
              <Button
                fullWidth
                variant="outlined"
                startIcon={<MousePointer />}
                onClick={() => executeElementAction()}
                disabled={!elementSelector || disabled || !connectionState.connected}
              >
                Cliquer
              </Button>
            )}
          </Grid>
          
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={executeElementAction}
              disabled={!elementSelector || disabled || !connectionState.connected}
              startIcon={<PlayCircle />}
            >
              Exécuter l'action
            </Button>
          </Grid>
        </Grid>
        
        <Typography variant="subtitle2" gutterBottom>
          Actions enregistrées ({recordedActions.length})
        </Typography>
        
        <Box sx={{ flex: 1, overflow: 'auto', border: 1, borderColor: 'divider', borderRadius: 1 }}>
          <List dense sx={{ p: 0 }}>
            {recordedActions.length === 0 ? (
              <ListItem>
                <ListItemText 
                  primary="Aucune action enregistrée" 
                  secondary="Démarrez l'enregistrement et interagissez avec la page"
                />
              </ListItem>
            ) : (
              recordedActions.map((action, index) => (
                <ListItem key={index} divider>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    {action.type === 'navigation' && <Globe size={18} />}
                    {action.type === 'element' && <MousePointer size={18} />}
                    {action.type === 'wait' && <Clock size={18} />}
                    {action.type === 'script' && <Code size={18} />}
                    {action.type === 'capture' && <Image size={18} />}
                    {action.type === 'tab' && <Layout size={18} />}
                  </ListItemIcon>
                  
                  <ListItemText
                    primary={
                      action.type === 'navigation' ? 
                        (action.action === 'navigate' ? `Naviguer vers: ${action.value}` : 
                         action.action === 'reload' ? 'Recharger la page' : 
                         action.action === 'back' ? 'Retour arrière' : 
                         'Avancer') :
                      action.type === 'element' ?
                        (action.action === 'click' ? `Cliquer sur: ${action.selector}` :
                         action.action === 'type' ? `Saisir "${action.value}" dans: ${action.selector}` :
                         action.action === 'select' ? `Sélectionner "${action.value}" dans: ${action.selector}` :
                         `Survoler: ${action.selector}`) :
                      action.type === 'wait' ?
                        `Attendre ${action.selector ? `l'élément: ${action.selector}` : 'la navigation'}` :
                      action.type === 'script' ?
                        `Exécuter script: ${action.value.length > 30 ? action.value.substring(0, 30) + '...' : action.value}` :
                      action.type === 'capture' ?
                        'Capture d\'écran' :
                      action.type === 'tab' ?
                        (action.action === 'create' ? 'Nouvel onglet' : 
                         action.action === 'close' ? 'Fermer onglet' : 
                         'Changer d\'onglet')
                        : action.action
                    }
                    secondary={new Date(action.timestamp).toLocaleTimeString()}
                  />
                  
                  <IconButton 
                    size="small" 
                    onClick={() => {
                      setRecordedActions(recordedActions.filter((_, i) => i !== index));
                    }}
                  >
                    <Trash2 size={14} />
                  </IconButton>
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Box>
    );
  };
  
  // Composant pour les cookies et le stockage
  const StorageComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Database size={16} style={{ marginRight: 8 }} />
          Cookies et stockage
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Button
            size="small"
            startIcon={<Cookie />}
            onClick={getCookies}
            disabled={!currentUrl || disabled || !connectionState.connected}
          >
            Récupérer les cookies
          </Button>
          
          <Button
            size="small"
            startIcon={<Database />}
            onClick={getLocalStorage}
            disabled={!currentUrl || disabled || !connectionState.connected}
          >
            Récupérer le localStorage
          </Button>
        </Box>
        
        <Tabs value={activeTab === 4 ? 0 : 1} onChange={(e, val) => setActiveTab(val === 0 ? 4 : 5)}>
          <Tab label="Cookies" />
          <Tab label="LocalStorage" />
        </Tabs>
        
        <Box sx={{ flex: 1, mt: 2, overflow: 'auto' }}>
          {activeTab === 4 ? (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Valeur</TableCell>
                    <TableCell>Domaine</TableCell>
                    <TableCell>Chemin</TableCell>
                    <TableCell>Expiration</TableCell>
                    <TableCell>Secure</TableCell>
                    <TableCell>HttpOnly</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cookies.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        Aucun cookie trouvé
                      </TableCell>
                    </TableRow>
                  ) : (
                    cookies.map((cookie, index) => (
                      <TableRow key={index}>
                        <TableCell>{cookie.name}</TableCell>
                        <TableCell>
                          <Tooltip title={cookie.value}>
                            <Typography noWrap sx={{ maxWidth: 150 }}>
                              {cookie.value}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{cookie.domain}</TableCell>
                        <TableCell>{cookie.path}</TableCell>
                        <TableCell>{cookie.expires ? new Date(cookie.expires).toLocaleString() : 'Session'}</TableCell>
                        <TableCell>
                          <Chip 
                            size="small" 
                            label={cookie.secure ? 'Oui' : 'Non'} 
                            color={cookie.secure ? 'success' : 'default'} 
                          />
                        </TableCell>
                        <TableCell>
                          <Chip 
                            size="small" 
                            label={cookie.httpOnly ? 'Oui' : 'Non'} 
                            color={cookie.httpOnly ? 'info' : 'default'} 
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Clé</TableCell>
                    <TableCell>Valeur</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {localStorage.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Aucune donnée dans le localStorage
                      </TableCell>
                    </TableRow>
                  ) : (
                    localStorage.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.key}</TableCell>
                        <TableCell>
                          <Tooltip title={item.value}>
                            <Typography noWrap sx={{ maxWidth: 300 }}>
                              {item.value}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    );
  };
  
  // Composant pour les captures d'écran
  const ScreenshotsComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Image size={16} style={{ marginRight: 8 }} />
          Captures d'écran
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button
            size="small"
            startIcon={<Image />}
            onClick={takeScreenshot}
            disabled={!currentUrl || disabled || !connectionState.connected}
          >
            Prendre une capture d'écran
          </Button>
          
          <Button
            size="small"
            startIcon={<Trash2 />}
            onClick={() => setScreenshots([])}
            disabled={screenshots.length === 0}
            color="error"
          >
            Effacer toutes les captures
          </Button>
        </Box>
        
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Grid container spacing={2}>
            {screenshots.length === 0 ? (
              <Grid item xs={12}>
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                  <Typography variant="body1" color="text.secondary">
                    Aucune capture d'écran disponible
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Cliquez sur "Prendre une capture d'écran" pour créer une nouvelle capture.
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              screenshots.map((screenshot) => (
                <Grid item xs={12} sm={6} md={4} key={screenshot.id}>
                  <Paper 
                    sx={{ 
                      p: 1, 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      '&:hover': {
                        boxShadow: 3
                      }
                    }}
                  >
                    <Box 
                      sx={{ 
                        flex: 1, 
                        display: 'flex', 
                        flexDirection: 'column',
                        overflow: 'hidden',
                        position: 'relative'
                      }}
                    >
                      <Typography variant="subtitle2" noWrap>
                        {screenshot.title || 'Sans titre'}
                      </Typography>
                      
                      <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }} noWrap>
                        {screenshot.url}
                      </Typography>
                      
                      <Box 
                        sx={{ 
                          flex: 1, 
                          bgcolor: 'grey.100', 
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 150,
                          p: 1
                        }}
                      >
                        <Typography variant="caption" color="text.secondary" align="center">
                          Aperçu de capture d'écran
                        </Typography>
                      </Box>
                      
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          mt: 1
                        }}
                      >
                        <Typography variant="caption" color="text.secondary">
                          {screenshot.timestamp.toLocaleString()}
                        </Typography>
                        
                        <IconButton
                          size="small"
                          onClick={() => {
                            setScreenshots(screenshots.filter(s => s.id !== screenshot.id));
                          }}
                        >
                          <Trash2 size={14} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Box>
    );
  };
  
  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionState.connected) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionState.connected ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          bgcolor: 'background.paper'
        }}
      >
        <Tab icon={<Globe size={16} />} label="Navigateur" />
        <Tab icon={<Terminal size={16} />} label="Console" />
        <Tab icon={<Server size={16} />} label="Réseau" />
        <Tab icon={<PlayCircle size={16} />} label="Enregistrement" />
        <Tab icon={<Cookie size={16} />} label="Cookies" />
        <Tab icon={<Database size={16} />} label="LocalStorage" />
        <Tab icon={<Image size={16} />} label="Captures" />
      </Tabs>
      
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {activeTab === 0 && <BrowserComponent />}
        {activeTab === 1 && <ConsoleComponent />}
        {activeTab === 2 && <NetworkComponent />}
        {activeTab === 3 && <RecordingComponent />}
        {(activeTab === 4 || activeTab === 5) && <StorageComponent />}
        {activeTab === 6 && <ScreenshotsComponent />}
      </Box>
      
      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default WebBrowserCapture;