// components/shared/workflow/WorkflowAnalyzer.js
import { WorkflowValidator } from './WorkflowValidator';

/**
 * Classe d'analyse avancée des workflows
 * Fournit des métriques et des suggestions d'optimisation
 */
export class WorkflowAnalyzer {
  /**
   * Analyse complète du workflow
   *
   * @param {Array} nodes - Les nœuds du workflow
   * @param {Array} edges - Les arêtes du workflow
   * @returns {Object} - Résultat de l'analyse
   */
  static analyzeWorkflow(nodes, edges) {
    const basicMetrics = this.calculateBasicMetrics(nodes, edges);
    const complexity = this.calculateComplexity(nodes, edges);
    const { cycles, paths } = this.analyzePathsAndCycles(nodes, edges);
    const bottlenecks = this.identifyBottlenecks(nodes, edges);
    const suggestions = this.generateSuggestions(nodes, edges, { 
      ...basicMetrics, 
      complexity, 
      cycles, 
      bottlenecks 
    });
    
    return {
      metrics: {
        ...basicMetrics,
        complexity
      },
      analysis: {
        cycles,
        paths,
        bottlenecks
      },
      suggestions
    };
  }
  
  /**
   * Calcule les métriques de base du workflow
   */
  static calculateBasicMetrics(nodes, edges) {
    const stateCount = nodes.length;
    const transitionCount = edges.length;
    const initialStateCount = nodes.filter(node => node.type === 'initialNode').length;
    const finalStateCount = nodes.filter(node => node.type === 'finalNode').length;
    const standardStateCount = nodes.filter(node => node.type === 'stateNode').length;
    
    // Calculer le nombre moyen de transitions par état
    const avgTransitionsPerState = stateCount > 0 ? transitionCount / stateCount : 0;
    
    // Calculer le nombre d'états sans transitions sortantes (hors états finaux)
    const deadEnds = nodes.filter(node => {
      if (node.type === 'finalNode') return false;
      return !edges.some(edge => edge.source === node.id);
    }).length;
    
    // Calculer le nombre d'états sans transitions entrantes (hors état initial)
    const unreachableStates = nodes.filter(node => {
      if (node.type === 'initialNode') return false;
      return !edges.some(edge => edge.target === node.id);
    }).length;
    
    return {
      stateCount,
      transitionCount,
      initialStateCount,
      finalStateCount,
      standardStateCount,
      avgTransitionsPerState,
      deadEnds,
      unreachableStates
    };
  }
  
  /**
   * Calcule la complexité du workflow
   */
  static calculateComplexity(nodes, edges) {
    if (nodes.length === 0) return 0;
    
    // Complexité de base basée sur le nombre d'états et de transitions
    const baseComplexity = (nodes.length + edges.length) / 2;
    
    // Facteur basé sur le nombre de chemins possibles
    const { paths } = this.analyzePathsAndCycles(nodes, edges);
    const totalPaths = paths?.totalPaths || 1;
    const pathFactor = Math.log(totalPaths + 1) / Math.log(10); // Log base 10 pour réduire l'échelle
    
    // Facteur basé sur les cycles
    const { cycles } = this.analyzePathsAndCycles(nodes, edges);
    const cycleFactor = cycles?.hasCycles ? 1 + (cycles.cycleCount * 0.5) : 1;
    
    // Calculer la complexité finale
    return Math.round((baseComplexity * pathFactor * cycleFactor) * 10) / 10;
  }
  
  /**
   * Analyse les chemins et cycles dans le workflow
   */
  static analyzePathsAndCycles(nodes, edges) {
    // Analyser les cycles
    const cyclesInfo = WorkflowValidator.detectCycles(nodes, edges);
    
    // Construire un graphe orienté
    const graph = {};
    nodes.forEach(node => {
      graph[node.id] = [];
    });
    
    edges.forEach(edge => {
      if (graph[edge.source]) {
        graph[edge.source].push({
          target: edge.target,
          label: edge.label || 'Transition',
          data: edge.data
        });
      }
    });
    
    // Trouver tous les chemins de l'état initial aux états finaux
    const initialNode = nodes.find(node => node.type === 'initialNode');
    const finalNodes = nodes.filter(node => node.type === 'finalNode');
    
    let paths = [];
    let totalPaths = 0;
    
    if (initialNode && finalNodes.length > 0) {
      const findAllPaths = (currentId, targetId, visited = new Set(), path = []) => {
        // Limite de la récursion pour éviter les boucles infinies
        if (path.length > nodes.length * 2) {
          return [];
        }
        
        visited.add(currentId);
        path.push(currentId);
        
        if (currentId === targetId) {
          return [path];
        }
        
        const result = [];
        const neighbors = graph[currentId] || [];
        
        for (const { target } of neighbors) {
          if (!visited.has(target)) {
            const newVisited = new Set(visited);
            const newPaths = findAllPaths(target, targetId, newVisited, [...path]);
            result.push(...newPaths);
          }
        }
        
        return result;
      };
      
      finalNodes.forEach(finalNode => {
        const pathsToFinal = findAllPaths(initialNode.id, finalNode.id);
        paths.push(...pathsToFinal);
        totalPaths += pathsToFinal.length;
      });
    }
    
    // Formater les chemins pour une meilleure lisibilité
    const formattedPaths = paths.map(path => {
      const pathDetails = [];
      
      for (let i = 0; i < path.length - 1; i++) {
        const sourceId = path[i];
        const targetId = path[i + 1];
        const sourceNode = nodes.find(n => n.id === sourceId);
        const targetNode = nodes.find(n => n.id === targetId);
        const transition = edges.find(e => e.source === sourceId && e.target === targetId);
        
        pathDetails.push({
          from: sourceNode?.data?.label || sourceId,
          to: targetNode?.data?.label || targetId,
          event: transition?.label || 'Transition',
          isAutomatic: transition?.data?.isAutomatic || false
        });
      }
      
      return pathDetails;
    });
    
    return {
      cycles: {
        hasCycles: cyclesInfo.hasCycles,
        cycleCount: cyclesInfo.cycles.length,
        details: cyclesInfo.cycles
      },
      paths: {
        totalPaths,
        shortestPathLength: Math.min(...paths.map(p => p.length), Infinity),
        longestPathLength: Math.max(...paths.map(p => p.length), 0),
        details: formattedPaths.slice(0, 5) // Limiter à 5 chemins pour ne pas surcharger
      }
    };
  }
  
  /**
   * Identifie les goulots d'étranglement potentiels dans le workflow
   */
  static identifyBottlenecks(nodes, edges) {
    const bottlenecks = [];
    
    // Calculer le nombre de transitions entrantes et sortantes pour chaque nœud
    const nodeStats = {};
    nodes.forEach(node => {
      nodeStats[node.id] = {
        id: node.id,
        label: node.data?.label || node.id,
        type: node.type,
        inDegree: 0,
        outDegree: 0
      };
    });
    
    edges.forEach(edge => {
      if (nodeStats[edge.source]) {
        nodeStats[edge.source].outDegree++;
      }
      if (nodeStats[edge.target]) {
        nodeStats[edge.target].inDegree++;
      }
    });
    
    // Identifier les nœuds avec beaucoup d'entrées et peu de sorties (goulots)
    Object.values(nodeStats).forEach(nodeStat => {
      if (nodeStat.type !== 'finalNode' && nodeStat.inDegree > 2 && nodeStat.outDegree <= 1) {
        bottlenecks.push({
          id: nodeStat.id,
          label: nodeStat.label,
          inDegree: nodeStat.inDegree,
          outDegree: nodeStat.outDegree,
          reason: 'Plusieurs entrées vers une seule sortie'
        });
      }
    });
    
    // Identifier les nœuds qui sont sur de nombreux chemins critiques
    const { paths } = this.analyzePathsAndCycles(nodes, edges);
    
    if (paths.details && paths.details.length > 0) {
      const nodeCounts = {};
      
      // Compter l'apparition des nœuds dans les chemins
      paths.details.forEach(path => {
        path.forEach(step => {
          // Utiliser le nom de l'état comme clé
          const fromKey = step.from;
          const toKey = step.to;
          
          nodeCounts[fromKey] = (nodeCounts[fromKey] || 0) + 1;
          nodeCounts[toKey] = (nodeCounts[toKey] || 0) + 1;
        });
      });
      
      // Identifier les nœuds les plus fréquents
      const totalPaths = paths.totalPaths || 1;
      Object.entries(nodeCounts).forEach(([nodeLabel, count]) => {
        // Si le nœud apparaît dans plus de 70% des chemins, c'est un goulot potentiel
        if (count / totalPaths > 0.7) {
          const node = nodes.find(n => n.data?.label === nodeLabel);
          if (node && !bottlenecks.some(b => b.id === node.id)) {
            bottlenecks.push({
              id: node.id,
              label: nodeLabel,
              pathCount: count,
              pathPercentage: Math.round((count / totalPaths) * 100),
              reason: 'Point de passage fréquent'
            });
          }
        }
      });
    }
    
    return bottlenecks;
  }
  
  /**
   * Génère des suggestions pour améliorer le workflow
   */
  static generateSuggestions(nodes, edges, analysis) {
    const suggestions = [];
    
    // Suggestions basées sur les métriques de base
    if (analysis.deadEnds > 0) {
      suggestions.push({
        type: 'warning',
        message: `${analysis.deadEnds} état(s) sans transitions sortantes (hors états finaux)`,
        recommendation: 'Ajouter des transitions sortantes ou marquer ces états comme finaux'
      });
    }
    
    if (analysis.unreachableStates > 0) {
      suggestions.push({
        type: 'warning',
        message: `${analysis.unreachableStates} état(s) non accessibles depuis l'état initial`,
        recommendation: 'Ajouter des transitions entrantes ou supprimer ces états'
      });
    }
    
    // Suggestions basées sur les cycles
    if (analysis.cycles && analysis.cycles.hasCycles) {
      if (analysis.cycles.cycleCount > 2) {
        suggestions.push({
          type: 'info',
          message: `Workflow complexe avec ${analysis.cycles.cycleCount} cycles`,
          recommendation: 'Considérer simplifier certains cycles pour réduire la complexité'
        });
      }
    }
    
    // Suggestions basées sur les goulots d'étranglement
    if (analysis.bottlenecks && analysis.bottlenecks.length > 0) {
      analysis.bottlenecks.forEach(bottleneck => {
        suggestions.push({
          type: 'info',
          message: `État "${bottleneck.label}" identifié comme goulot d'étranglement (${bottleneck.reason})`,
          recommendation: 'Considérer ajouter des chemins alternatifs pour réduire la dépendance à cet état'
        });
      });
    }
    
    // Suggestions sur l'équilibre du workflow
    if (analysis.avgTransitionsPerState < 1.2) {
      suggestions.push({
        type: 'info',
        message: 'Workflow linéaire avec peu d\'embranchements',
        recommendation: 'Considérer ajouter des chemins alternatifs pour plus de flexibilité'
      });
    } else if (analysis.avgTransitionsPerState > 3) {
      suggestions.push({
        type: 'info',
        message: 'Workflow très ramifié avec de nombreuses transitions',
        recommendation: 'Considérer simplifier certaines parties pour améliorer la lisibilité'
      });
    }
    
    // Suggestions sur la complexité globale
    if (analysis.complexity > 20) {
      suggestions.push({
        type: 'warning',
        message: `Complexité élevée (${analysis.complexity})`,
        recommendation: 'Envisager de diviser ce workflow en plusieurs sous-workflows plus simples'
      });
    }
    
    return suggestions;
  }

  /**
   * Génère un rapport d'analyse complet du workflow
   */
  static generateReport(nodes, edges, workflowName, workflowDescription) {
    const analysisResults = this.analyzeWorkflow(nodes, edges);
    const validation = WorkflowValidator.validateWorkflow(nodes, edges, workflowName);
    
    return {
      workflowInfo: {
        name: workflowName,
        description: workflowDescription,
        isValid: validation.isValid
      },
      validation: {
        errors: validation.errors || []
      },
      metrics: analysisResults.metrics,
      analysis: analysisResults.analysis,
      suggestions: analysisResults.suggestions
    };
  }
  
  /**
   * Identifie les chemins critiques dans le workflow
   * Un chemin critique est le plus long chemin de l'état initial à un état final
   */
  static identifyCriticalPaths(nodes, edges) {
    const { paths } = this.analyzePathsAndCycles(nodes, edges);
    
    if (!paths.details || paths.details.length === 0) {
      return [];
    }
    
    // Trouver le chemin le plus long
    let longestPathLength = 0;
    let longestPaths = [];
    
    paths.details.forEach((path, index) => {
      if (path.length > longestPathLength) {
        longestPathLength = path.length;
        longestPaths = [path];
      } else if (path.length === longestPathLength) {
        longestPaths.push(path);
      }
    });
    
    return longestPaths.map(path => ({
      length: path.length,
      transitions: path
    }));
  }
  
  /**
   * Détecte les incohérences potentielles dans le workflow
   */
  static detectInconsistencies(nodes, edges) {
    const inconsistencies = [];
    
    // Vérifier les transitions avec les mêmes événements sortant du même état
    const stateEventMap = {};
    
    edges.forEach(edge => {
      const key = `${edge.source}|${edge.label}`;
      if (!stateEventMap[key]) {
        stateEventMap[key] = [];
      }
      stateEventMap[key].push(edge);
    });
    
    // Rechercher les états avec plusieurs transitions pour le même événement
    Object.entries(stateEventMap).forEach(([key, edgeList]) => {
      if (edgeList.length > 1) {
        const [sourceId, eventName] = key.split('|');
        const sourceNode = nodes.find(n => n.id === sourceId);
        
        inconsistencies.push({
          type: 'ambiguousTransition',
          message: `État "${sourceNode?.data?.label || sourceId}" a ${edgeList.length} transitions pour l'événement "${eventName}"`,
          details: {
            sourceId,
            eventName,
            targetIds: edgeList.map(e => e.target)
          }
        });
      }
    });
    
    // Vérifier les transitions automatiques contradictoires
    const automaticTransitions = edges.filter(edge => edge.data?.isAutomatic);
    const sourceIds = new Set(automaticTransitions.map(edge => edge.source));
    
    sourceIds.forEach(sourceId => {
      const autoTransitionsForSource = automaticTransitions.filter(edge => edge.source === sourceId);
      
      if (autoTransitionsForSource.length > 1) {
        const sourceNode = nodes.find(n => n.id === sourceId);
        
        inconsistencies.push({
          type: 'multipleAutomaticTransitions',
          message: `État "${sourceNode?.data?.label || sourceId}" a ${autoTransitionsForSource.length} transitions automatiques`,
          details: {
            sourceId,
            transitions: autoTransitionsForSource.map(e => ({
              target: e.target,
              event: e.label
            }))
          }
        });
      }
    });
    
    return inconsistencies;
  }
}