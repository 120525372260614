import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  FormGroup,
  FormLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';

import {
  Send,
  Save,
  Download,
  Upload,
  RefreshCw,
  Copy,
  Trash2,
  Plus,
  Minus,
  Globe,
  Server,
  Wifi,
  WifiOff,
  Clock,
  ChevronDown,
  ChevronRight,
  Check,
  X,
  Code,
  FileText,
  Settings,
  Eye,
  EyeOff,
  Search,
  AlertCircle,
  Info,
  HelpCircle,
  Edit,
  MoreVertical,
  Play,
  History,
  Bookmark,
  Link as LinkIcon,
  FilePlus,
  RotateCcw,
  FileJson,
  FileCode,
  ExternalLink,
  Zap
} from 'lucide-react';

/**
 * RestApiCapture Component - Interface réutilisable pour interagir avec des API REST
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles de l'API
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const RestApiCapture = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États pour l'onglet Requête
  const [method, setMethod] = useState('GET');
  const [url, setUrl] = useState('');
  const [headers, setHeaders] = useState([{ key: '', value: '' }]);
  const [queryParams, setQueryParams] = useState([{ key: '', value: '' }]);
  const [requestBody, setRequestBody] = useState('');
  const [bodyType, setBodyType] = useState('json'); // 'json', 'form', 'xml', 'raw'
  const [formData, setFormData] = useState([{ key: '', value: '', type: 'text' }]);
  const [isLoading, setIsLoading] = useState(false);
  
  // États pour l'onglet Réponse
  const [response, setResponse] = useState(null);
  const [responseHeaders, setResponseHeaders] = useState({});
  const [responseTime, setResponseTime] = useState(null);
  const [responseSize, setResponseSize] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [responseFormat, setResponseFormat] = useState('json'); // 'json', 'xml', 'html', 'text'
  const [prettyPrint, setPrettyPrint] = useState(true);
  const [showHeaders, setShowHeaders] = useState(false);
  const [responseTabs, setResponseTabs] = useState(0);
  
  // États pour l'historique des requêtes
  const [requestHistory, setRequestHistory] = useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);
  
  // États pour les collections de requêtes
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionsOpen, setCollectionsOpen] = useState(false);
  
  // États pour l'environnement et les variables
  const [environments, setEnvironments] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [variables, setVariables] = useState({});
  const [showVariables, setShowVariables] = useState(false);
  
  // États généraux
  const [activeTab, setActiveTab] = useState(0);
  const [lastAction, setLastAction] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [connectionInfo, setConnectionInfo] = useState({
    baseUrl: '',
    authType: 'none', // 'none', 'basic', 'bearer', 'apikey', 'oauth2'
    authDetails: {},
    status: 'disconnected'
  });
  
  // Références
  const responseBodyRef = useRef(null);
  
  // Effets pour récupérer les informations de connexion
  useEffect(() => {
    if (data?.technicalProperties?.connection) {
      const conn = data.technicalProperties.connection;
      setConnectionInfo({
        baseUrl: conn.baseUrl || '',
        authType: conn.authType || 'none',
        authDetails: conn.authDetails || {},
        status: connectionStatus ? 'connected' : 'disconnected'
      });
    }
  }, [data, connectionStatus]);
  
  // Effet pour construire l'URL complète avec paramètres
  useEffect(() => {
    const buildFullUrl = () => {
      try {
        const baseUrl = url.trim();
        if (!baseUrl) return;
        
        // Filtrer les paramètres valides
        const validParams = queryParams.filter(param => param.key.trim() && param.value.trim());
        if (validParams.length === 0) return baseUrl;
        
        // Construire la chaîne de requête
        const urlObj = new URL(baseUrl.includes('://') ? baseUrl : `http://${baseUrl}`);
        validParams.forEach(param => {
          urlObj.searchParams.append(param.key, param.value);
        });
        
        // Mettre à jour l'URL avec les paramètres
        return urlObj.toString();
      } catch (error) {
        console.error('Erreur lors de la construction de l\'URL', error);
        return url;
      }
    };
    
    // Mise à jour de l'URL si necessaire
  }, [url, queryParams]);
  
  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les résultats de la requête
      if (data.technicalProperties.apiResponse) {
        handleApiResponse(data.technicalProperties.apiResponse);
      }
    }
  }, [data]);
  
  // Handler pour la réponse de l'API
  const handleApiResponse = (apiResponse) => {
    setIsLoading(false);
    
    // Traiter la réponse
    if (apiResponse) {
      setResponse(apiResponse.body || '');
      setResponseHeaders(apiResponse.headers || {});
      setResponseTime(apiResponse.responseTime || 0);
      setResponseSize(apiResponse.size || 0);
      setResponseStatus(apiResponse.status || 0);
      
      // Déterminer le format de la réponse
      const contentType = apiResponse.headers?.['content-type'] || '';
      if (contentType.includes('application/json')) {
        setResponseFormat('json');
      } else if (contentType.includes('text/xml') || contentType.includes('application/xml')) {
        setResponseFormat('xml');
      } else if (contentType.includes('text/html')) {
        setResponseFormat('html');
      } else {
        setResponseFormat('text');
      }
      
      // Ajouter à l'historique
      const historyItem = {
        id: Date.now().toString(),
        method,
        url,
        queryParams: [...queryParams],
        headers: [...headers],
        body: requestBody,
        bodyType,
        status: apiResponse.status,
        timestamp: new Date(),
        responseTime: apiResponse.responseTime
      };
      
      setRequestHistory(prev => [historyItem, ...prev]);
      
      // Mettre à jour la dernière action
      setLastAction({
        type: 'ApiRequest',
        name: `${method} ${url}`,
        timestamp: new Date()
      });
    }
  };
  
  // Fonction pour envoyer une requête
  const sendRequest = () => {
    if (!url.trim() || disabled || !connectionStatus) return;
    
    setIsLoading(true);
    
    // Construire l'objet de requête
    let requestUrl = url;
    
    // Ajouter les paramètres de requête
    const validParams = queryParams.filter(param => param.key.trim() && param.value.trim());
    if (validParams.length > 0) {
      try {
        const urlObj = new URL(url.includes('://') ? url : `http://${url}`);
        validParams.forEach(param => {
          urlObj.searchParams.append(param.key, param.value);
        });
        requestUrl = urlObj.toString();
      } catch (error) {
        console.error('Erreur lors de la construction de l\'URL', error);
        requestUrl = url;
      }
    }
    
    // Construire les en-têtes
    const headerObject = {};
    headers.forEach(header => {
      if (header.key.trim() && header.value.trim()) {
        headerObject[header.key] = header.value;
      }
    });
    
    // Préparer le corps de la requête
    let bodyContent = '';
    if (method !== 'GET' && method !== 'HEAD') {
      if (bodyType === 'json') {
        bodyContent = requestBody;
        if (!headerObject['Content-Type']) {
          headerObject['Content-Type'] = 'application/json';
        }
      } else if (bodyType === 'form') {
        const formDataObj = {};
        formData.forEach(item => {
          if (item.key.trim()) {
            formDataObj[item.key] = item.value;
          }
        });
        bodyContent = JSON.stringify(formDataObj);
        if (!headerObject['Content-Type']) {
          headerObject['Content-Type'] = 'application/x-www-form-urlencoded';
        }
      } else if (bodyType === 'xml') {
        bodyContent = requestBody;
        if (!headerObject['Content-Type']) {
          headerObject['Content-Type'] = 'application/xml';
        }
      } else {
        bodyContent = requestBody;
      }
    }
    
    // Construction du payload d'interaction
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ApiRequest",
        nodeName: "SendRequest",
        nodeValue: requestUrl,
        actionType: "REST",
        actionValue: method,
        actionName: "SendRequest",
        timestamp: Date.now(),
        method,
        url: requestUrl,
        headers: headerObject,
        body: bodyContent,
        bodyType
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'ApiRequest',
      name: `${method} ${requestUrl}`,
      timestamp: new Date()
    });
  };
  
  // Handler pour ajouter un en-tête
  const addHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };
  
  // Handler pour supprimer un en-tête
  const removeHeader = (index) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 1);
    setHeaders(newHeaders);
  };
  
  // Handler pour mettre à jour un en-tête
  const updateHeader = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index] = { ...newHeaders[index], [field]: value };
    setHeaders(newHeaders);
  };
  
  // Handler pour ajouter un paramètre de requête
  const addQueryParam = () => {
    setQueryParams([...queryParams, { key: '', value: '' }]);
  };
  
  // Handler pour supprimer un paramètre de requête
  const removeQueryParam = (index) => {
    const newParams = [...queryParams];
    newParams.splice(index, 1);
    setQueryParams(newParams);
  };
  
  // Handler pour mettre à jour un paramètre de requête
  const updateQueryParam = (index, field, value) => {
    const newParams = [...queryParams];
    newParams[index] = { ...newParams[index], [field]: value };
    setQueryParams(newParams);
  };
  
  // Handler pour ajouter un champ de formulaire
  const addFormField = () => {
    setFormData([...formData, { key: '', value: '', type: 'text' }]);
  };
  
  // Handler pour supprimer un champ de formulaire
  const removeFormField = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };
  
  // Handler pour mettre à jour un champ de formulaire
  const updateFormField = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index] = { ...newFormData[index], [field]: value };
    setFormData(newFormData);
  };
  
  // Fonction pour charger une requête de l'historique
  const loadFromHistory = (historyItem) => {
    setMethod(historyItem.method);
    setUrl(historyItem.url);
    setQueryParams(historyItem.queryParams || [{ key: '', value: '' }]);
    setHeaders(historyItem.headers || [{ key: '', value: '' }]);
    setRequestBody(historyItem.body || '');
    setBodyType(historyItem.bodyType || 'json');
    
    setSnackbar({
      open: true,
      message: 'Requête chargée de l\'historique',
      severity: 'success'
    });
  };
  
  // Fonction pour sauvegarder une requête dans une collection
  const saveToCollection = () => {
    // Implémentation de la sauvegarde dans une collection
    setSnackbar({
      open: true,
      message: 'Requête sauvegardée dans la collection',
      severity: 'success'
    });
  };
  
  // Fonction pour copier le corps de la réponse
  const copyResponseBody = () => {
    if (response) {
      navigator.clipboard.writeText(
        typeof response === 'object' ? JSON.stringify(response, null, 2) : response
      );
      
      setSnackbar({
        open: true,
        message: 'Réponse copiée dans le presse-papiers',
        severity: 'success'
      });
    }
  };
  
  // Fonction pour formater le JSON
  const formatJson = (jsonString) => {
    try {
      if (!jsonString) return '';
      
      // Si c'est déjà un objet, le convertir en chaîne
      const jsonObj = typeof jsonString === 'object' ? jsonString : JSON.parse(jsonString);
      return JSON.stringify(jsonObj, null, prettyPrint ? 2 : 0);
    } catch (error) {
      console.error('Erreur lors du formatage JSON', error);
      return jsonString;
    }
  };
  
  // Fonction pour déterminer la couleur d'état HTTP
  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    if (status < 300) return 'success';
    if (status < 400) return 'info';
    if (status < 500) return 'warning';
    return 'error';
  };
  
  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Globe sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              REST API {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionStatus ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionStatus ? 'success' : 'error'}
            label={connectionStatus ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                URL de base
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.baseUrl || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Authentication
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.authType === 'none' ? 'Aucune' : 
                 connectionInfo.authType === 'basic' ? 'Basic Auth' :
                 connectionInfo.authType === 'bearer' ? 'Bearer Token' :
                 connectionInfo.authType === 'apikey' ? 'API Key' : 'OAuth 2.0'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant Onglet Requête
  const RequestComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Send size={16} style={{ marginRight: 8 }} />
          Requête
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Méthode</InputLabel>
                <Select
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                  label="Méthode"
                  disabled={disabled || !connectionStatus}
                >
                  <MenuItem value="GET">GET</MenuItem>
                  <MenuItem value="POST">POST</MenuItem>
                  <MenuItem value="PUT">PUT</MenuItem>
                  <MenuItem value="DELETE">DELETE</MenuItem>
                  <MenuItem value="PATCH">PATCH</MenuItem>
                  <MenuItem value="HEAD">HEAD</MenuItem>
                  <MenuItem value="OPTIONS">OPTIONS</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                label="URL"
                variant="outlined"
                size="small"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={disabled || !connectionStatus}
                placeholder="https://api.example.com/endpoint"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={sendRequest}
                        disabled={!url.trim() || disabled || !connectionStatus || isLoading}
                      >
                        <Send size={16} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Box>
        
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ChevronDown size={16} />}>
              <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                <LinkIcon size={14} style={{ marginRight: 8 }} />
                Paramètres de requête
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {queryParams.map((param, index) => (
                  <Box key={index} sx={{ display: 'flex', mb: 1 }}>
                    <TextField
                      size="small"
                      label="Clé"
                      value={param.key}
                      onChange={(e) => updateQueryParam(index, 'key', e.target.value)}
                      disabled={disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 1 }}
                    />
                    <TextField
                      size="small"
                      label="Valeur"
                      value={param.value}
                      onChange={(e) => updateQueryParam(index, 'value', e.target.value)}
                      disabled={disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 1 }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => removeQueryParam(index)}
                      disabled={queryParams.length === 1 || disabled || !connectionStatus}
                    >
                      <Minus size={16} />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<Plus size={16} />}
                  onClick={addQueryParam}
                  disabled={disabled || !connectionStatus}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  Ajouter un paramètre
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          
          <Accordion>
            <AccordionSummary expandIcon={<ChevronDown size={16} />}>
              <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileText size={14} style={{ marginRight: 8 }} />
                En-têtes
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {headers.map((header, index) => (
                  <Box key={index} sx={{ display: 'flex', mb: 1 }}>
                    <TextField
                      size="small"
                      label="Clé"
                      value={header.key}
                      onChange={(e) => updateHeader(index, 'key', e.target.value)}
                      disabled={disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 1 }}
                    />
                    <TextField
                      size="small"
                      label="Valeur"
                      value={header.value}
                      onChange={(e) => updateHeader(index, 'value', e.target.value)}
                      disabled={disabled || !connectionStatus}
                      sx={{ mr: 1, flex: 1 }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => removeHeader(index)}
                      disabled={headers.length === 1 || disabled || !connectionStatus}
                    >
                      <Minus size={16} />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<Plus size={16} />}
                  onClick={addHeader}
                  disabled={disabled || !connectionStatus}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  Ajouter un en-tête
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          
          {(method === 'POST' || method === 'PUT' || method === 'PATCH') && (
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ChevronDown size={16} />}>
                <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                  <FileCode size={14} style={{ marginRight: 8 }} />
                  Corps de la requête
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <RadioGroup
                      row
                      name="bodyType"
                      value={bodyType}
                      onChange={(e) => setBodyType(e.target.value)}
                    >
                      <FormControlLabel 
                        value="json" 
                        control={<Radio size="small" />} 
                        label="JSON" 
                        disabled={disabled || !connectionStatus}
                      />
                      <FormControlLabel 
                        value="form" 
                        control={<Radio size="small" />} 
                        label="Form Data" 
                        disabled={disabled || !connectionStatus}
                      />
                      <FormControlLabel 
                        value="xml" 
                        control={<Radio size="small" />} 
                        label="XML" 
                        disabled={disabled || !connectionStatus}
                      />
                      <FormControlLabel 
                        value="raw" 
                        control={<Radio size="small" />} 
                        label="Raw" 
                        disabled={disabled || !connectionStatus}
                      />
                    </RadioGroup>
                  </FormControl>
                  
                  {bodyType === 'form' ? (
                    <Box>
                      {formData.map((field, index) => (
                        <Box key={index} sx={{ display: 'flex', mb: 1 }}>
                          <TextField
                            size="small"
                            label="Clé"
                            value={field.key}
                            onChange={(e) => updateFormField(index, 'key', e.target.value)}
                            disabled={disabled || !connectionStatus}
                            sx={{ mr: 1, flex: 1 }}
                          />
                          <TextField
                            size="small"
                            label="Valeur"
                            value={field.value}
                            onChange={(e) => updateFormField(index, 'value', e.target.value)}
                            disabled={disabled || !connectionStatus}
                            sx={{ mr: 1, flex: 1 }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => removeFormField(index)}
                            disabled={formData.length === 1 || disabled || !connectionStatus}
                          >
                            <Minus size={16} />
                          </IconButton>
                        </Box>
                      ))}
                      <Button
                        startIcon={<Plus size={16} />}
                        onClick={addFormField}
                        disabled={disabled || !connectionStatus}
                        variant="outlined"
                        size="small"
                        sx={{ mt: 1 }}
                      >
                        Ajouter un champ
                      </Button>
                    </Box>
                  ) : (
                    <TextField
                      fullWidth
                      multiline
                      rows={10}
                      value={requestBody}
                      onChange={(e) => setRequestBody(e.target.value)}
                      disabled={disabled || !connectionStatus}
                      placeholder={
                        bodyType === 'json' ? '{\n  "key": "value"\n}' :
                        bodyType === 'xml' ? '<?xml version="1.0"?>\n<root>\n  <element>value</element>\n</root>' :
                        'Corps de la requête'
                      }
                      InputProps={{
                        sx: { fontFamily: 'monospace' }
                      }}
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<History size={16} />}
            onClick={() => setHistoryOpen(true)}
            disabled={disabled || !connectionStatus}
          >
            Historique
          </Button>
          
          <Box>
            <Button
              variant="outlined"
              startIcon={<Save size={16} />}
              onClick={saveToCollection}
              disabled={disabled || !connectionStatus}
              sx={{ mr: 1 }}
            >
              Sauvegarder
            </Button>
            
            <Button
              variant="contained"
              startIcon={<Send size={16} />}
              onClick={sendRequest}
              disabled={!url.trim() || disabled || !connectionStatus || isLoading}
              color="primary"
            >
              {isLoading ? <CircularProgress size={16} sx={{ mr: 1 }} /> : null}
              Envoyer
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  
  // Composant Onglet Réponse
  const ResponseComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Globe size={16} style={{ marginRight: 8 }} />
            Réponse
          </Typography>
          
          {responseStatus && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                size="small"
                label={`Status: ${responseStatus}`}
                color={getStatusColor(responseStatus)}
                sx={{ mr: 1 }}
              />
              {responseTime && (
                <Chip
                  size="small"
                  icon={<Clock size={14} />}
                  label={`${responseTime} ms`}
                  variant="outlined"
                  sx={{ mr: 1 }}
                />
              )}
              {responseSize && (
                <Chip
                  size="small"
                  label={`${Math.round(responseSize / 1024 * 100) / 100} KB`}
                  variant="outlined"
                />
              )}
            </Box>
          )}
        </Box>
        
        {response ? (
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Tabs 
                value={responseTabs} 
                onChange={(e, val) => setResponseTabs(val)}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Corps" />
                <Tab label="En-têtes" />
                <Tab label="Visualisation" />
              </Tabs>
              
              <Box>
                <Tooltip title="Affichage brut/formaté">
                  <IconButton 
                    size="small"
                    onClick={() => setPrettyPrint(!prettyPrint)}
                    disabled={responseFormat !== 'json' && responseFormat !== 'xml'}
                  >
                    {prettyPrint ? <Code size={16} /> : <FileText size={16} />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                  <IconButton 
                    size="small"
                    onClick={copyResponseBody}
                  >
                    <Copy size={16} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            
            <Box sx={{ flex: 1, overflow: 'auto', bgcolor: 'grey.900', p: 2, borderRadius: 1 }}>
              {responseTabs === 0 && (
                <Box ref={responseBodyRef} sx={{ 
                  fontFamily: 'monospace', 
                  fontSize: '0.875rem',
                  color: 'grey.100',
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word'
                }}>
                  {responseFormat === 'json' ? (
                    formatJson(response)
                  ) : (
                    response
                  )}
                </Box>
              )}
              
              {responseTabs === 1 && (
                <TableContainer>
                  <Table size="small" sx={{ backgroundColor: 'grey.800' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: 'grey.100', borderBottom: '1px solid rgba(255,255,255,0.1)' }}>En-tête</TableCell>
                        <TableCell sx={{ color: 'grey.100', borderBottom: '1px solid rgba(255,255,255,0.1)' }}>Valeur</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(responseHeaders).map(([key, value], index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: 'grey.300', borderBottom: '1px solid rgba(255,255,255,0.05)' }}>
                            {key}
                          </TableCell>
                          <TableCell sx={{ color: 'grey.300', borderBottom: '1px solid rgba(255,255,255,0.05)' }}>
                            {value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              
              {responseTabs === 2 && (
                <Box sx={{ color: 'grey.100', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography>Fonctionnalité de visualisation en développement</Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            color: 'text.secondary'
          }}>
            {isLoading ? (
              <>
                <CircularProgress size={40} sx={{ mb: 2 }} />
                <Typography>Requête en cours...</Typography>
              </>
            ) : (
              <>
                <Globe size={48} strokeWidth={1} style={{ marginBottom: 16, opacity: 0.5 }} />
                <Typography>Envoyez une requête pour voir la réponse</Typography>
              </>
            )}
          </Box>
        )}
      </Box>
    );
  };
  
  // Composant de dialogue d'historique
  const HistoryDialog = () => {
    return (
      <Dialog
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <History size={20} style={{ marginRight: 8 }} />
            Historique des requêtes
          </Typography>
          <IconButton onClick={() => setHistoryOpen(false)}>
            <X size={18} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {requestHistory.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography color="text.secondary">Aucune requête dans l'historique</Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Méthode</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Temps</TableCell>
                    <TableCell>Date/Heure</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestHistory.map((item) => (
                    <TableRow key={item.id} hover>
                      <TableCell>
                        <Chip
                          label={item.method}
                          size="small"
                          color={
                            item.method === 'GET' ? 'primary' :
                            item.method === 'POST' ? 'success' :
                            item.method === 'PUT' ? 'info' :
                            item.method === 'DELETE' ? 'error' : 'default'
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap sx={{ maxWidth: 250 }}>
                          {item.url}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={item.status}
                          size="small"
                          color={getStatusColor(item.status)}
                        />
                      </TableCell>
                      <TableCell>{item.responseTime} ms</TableCell>
                      <TableCell>{item.timestamp.toLocaleString()}</TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => {
                            loadFromHistory(item);
                            setHistoryOpen(false);
                          }}
                          title="Charger cette requête"
                        >
                          <Play size={16} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => saveToCollection(item)}
                          title="Sauvegarder dans les collections"
                        >
                          <Bookmark size={16} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Trash2 size={16} />} onClick={() => setRequestHistory([])}>
            Effacer l'historique
          </Button>
          <Button onClick={() => setHistoryOpen(false)}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionStatus) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionStatus ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          bgcolor: 'background.paper'
        }}
      >
        <Tab icon={<Send size={16} />} label="Requête" />
        <Tab icon={<Globe size={16} />} label="Réponse" />
      </Tabs>
      
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {activeTab === 0 && <RequestComponent />}
        {activeTab === 1 && <ResponseComponent />}
      </Box>
      
      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
      
      <HistoryDialog />
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default RestApiCapture;