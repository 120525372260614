import React, { useState, useEffect, useRef } from 'react';
import { 
  RotateCcw, 
  RefreshCw, 
  Search, 
  Filter, 
  Eye, 
  Download, 
  Upload, 
  Check, 
  X, 
  AlertTriangle,
  Clock,
  Calendar,
  ArrowRightLeft,
  FileText,
  MoreHorizontal,
  Loader,
  Plus,
  Edit,
  BarChart2,
  Send,
  Clipboard,
  FilePlus,
  ExternalLink,
  GitBranch,
  Info
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import WorkflowStateTransitionDiagram from '../../components/shared/WorkflowStateTransitionDiagram';
import apiService from '../../services/api.service';

/**
 * Composant pour la gestion des annulations et inversions dans le système CEMAC-ACH
 */
const AnnulationsInversions = () => {
  // États pour les opérations d'annulation
  const [annulations, setAnnulations] = useState([]);
  const [filteredAnnulations, setFilteredAnnulations] = useState([]);
  const [selectedAnnulation, setSelectedAnnulation] = useState(null);
  
  // États pour les opérations d'inversion
  const [inversions, setInversions] = useState([]);
  const [filteredInversions, setFilteredInversions] = useState([]);
  const [selectedInversion, setSelectedInversion] = useState(null);
  
  // États pour les filtres
  const [searchTermAnnulation, setSearchTermAnnulation] = useState('');
  const [searchTermInversion, setSearchTermInversion] = useState('');
  const [statusFilterAnnulation, setStatusFilterAnnulation] = useState('all');
  const [statusFilterInversion, setStatusFilterInversion] = useState('all');
  const [dateRangeFilterAnnulation, setDateRangeFilterAnnulation] = useState({
    start: null,
    end: null
  });
  const [dateRangeFilterInversion, setDateRangeFilterInversion] = useState({
    start: null,
    end: null
  });
  const [showFiltersAnnulation, setShowFiltersAnnulation] = useState(false);
  const [showFiltersInversion, setShowFiltersInversion] = useState(false);
  
  // États pour l'interface
  const [activeTab, setActiveTab] = useState('annulations'); // 'annulations' ou 'inversions'
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showNewForm, setShowNewForm] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const [showWorkflowDiagram, setShowWorkflowDiagram] = useState(false);
  const [workflowDefinition, setWorkflowDefinition] = useState(null);
  const [workflowHistory, setWorkflowHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  
  // États pour le formulaire de nouvelle opération
  const [newOperationForm, setNewOperationForm] = useState({
    type: 'annulation', // 'annulation' ou 'inversion'
    referenceOperation: '',
    motif: '',
    montant: '',
    devise: 'XAF',
    beneficiaire: {
      nom: '',
      rib: ''
    },
    donneur: {
      nom: '',
      rib: ''
    },
    commentaire: ''
  });
  
  // Références pour l'upload de fichiers
  const fileInputRef = useRef(null);
  
  // Charger les données initiales
  useEffect(() => {
    Promise.all([
      fetchAnnulations(),
      fetchInversions(),
      fetchWorkflowDefinitions()
    ]).then(() => setLoading(false))
      .catch(err => {
        console.error("Erreur lors du chargement des données:", err);
        setError("Une erreur s'est produite lors du chargement des données. Veuillez réessayer.");
        setLoading(false);
      });
  }, []);
  
  // Filtrer les annulations lorsque les termes de recherche ou les filtres changent
  useEffect(() => {
    if (!annulations.length) return;
    
    let filtered = [...annulations];
    
    // Filtrer par terme de recherche
    if (searchTermAnnulation.trim()) {
      const searchLower = searchTermAnnulation.toLowerCase();
      filtered = filtered.filter(annulation => 
        annulation.reference.toLowerCase().includes(searchLower) ||
        annulation.operationOriginale.reference.toLowerCase().includes(searchLower) ||
        annulation.demandeur.nom.toLowerCase().includes(searchLower) ||
        annulation.beneficiaire.nom.toLowerCase().includes(searchLower)
      );
    }
    
    // Filtrer par statut
    if (statusFilterAnnulation !== 'all') {
      filtered = filtered.filter(annulation => annulation.statut === statusFilterAnnulation);
    }
    
    // Filtrer par date
    if (dateRangeFilterAnnulation.start) {
      const startDate = new Date(dateRangeFilterAnnulation.start);
      filtered = filtered.filter(annulation => new Date(annulation.dateCreation) >= startDate);
    }
    
    if (dateRangeFilterAnnulation.end) {
      const endDate = new Date(dateRangeFilterAnnulation.end);
      filtered = filtered.filter(annulation => new Date(annulation.dateCreation) <= endDate);
    }
    
    setFilteredAnnulations(filtered);
  }, [annulations, searchTermAnnulation, statusFilterAnnulation, dateRangeFilterAnnulation]);
  
  // Filtrer les inversions lorsque les termes de recherche ou les filtres changent
  useEffect(() => {
    if (!inversions.length) return;
    
    let filtered = [...inversions];
    
    // Filtrer par terme de recherche
    if (searchTermInversion.trim()) {
      const searchLower = searchTermInversion.toLowerCase();
      filtered = filtered.filter(inversion => 
        inversion.reference.toLowerCase().includes(searchLower) ||
        inversion.operationOriginale.reference.toLowerCase().includes(searchLower) ||
        inversion.demandeur.nom.toLowerCase().includes(searchLower) ||
        inversion.beneficiaire.nom.toLowerCase().includes(searchLower)
      );
    }
    
    // Filtrer par statut
    if (statusFilterInversion !== 'all') {
      filtered = filtered.filter(inversion => inversion.statut === statusFilterInversion);
    }
    
    // Filtrer par date
    if (dateRangeFilterInversion.start) {
      const startDate = new Date(dateRangeFilterInversion.start);
      filtered = filtered.filter(inversion => new Date(inversion.dateCreation) >= startDate);
    }
    
    if (dateRangeFilterInversion.end) {
      const endDate = new Date(dateRangeFilterInversion.end);
      filtered = filtered.filter(inversion => new Date(inversion.dateCreation) <= endDate);
    }
    
    setFilteredInversions(filtered);
  }, [inversions, searchTermInversion, statusFilterInversion, dateRangeFilterInversion]);
  
  // Récupérer les opérations d'annulation
  const fetchAnnulations = async () => {
    try {
      // Tentative d'appel à l'API réelle
      let response;
      try {
        response = await apiService.cemacAch.getAnnulations();
      } catch (apiError) {
        console.warn("API non disponible, utilisation des données simulées:", apiError);
        
        // Données simulées pour la démonstration
        const mockAnnulations = [
          {
            id: "ANN-2024-001",
            reference: "ANN20240215001",
            dateCreation: "2024-02-15T10:30:45Z",
            dateTraitement: "2024-02-15T14:15:20Z",
            statut: "TRAITE",
            motif: "Erreur destinataire",
            operationOriginale: {
              reference: "VIR20240214001",
              type: "VIREMENT",
              date: "2024-02-14T08:45:30Z",
              montant: 450000,
              devise: "XAF"
            },
            demandeur: {
              nom: "ENTREPRISE ALPHA SARL",
              rib: "10001-00022-12345678901-23"
            },
            beneficiaire: {
              nom: "SOCIETE BETA SA",
              rib: "10002-00033-98765432109-45"
            },
            workflowInstanceId: "wf-ann-001",
            workflowStatus: "EMISSION"
          },
          {
            id: "ANN-2024-002",
            reference: "ANN20240217002",
            dateCreation: "2024-02-17T11:22:15Z",
            dateTraitement: null,
            statut: "EN_COURS",
            motif: "Montant incorrect",
            operationOriginale: {
              reference: "VIR20240216002",
              type: "VIREMENT",
              date: "2024-02-16T15:30:10Z",
              montant: 275850,
              devise: "XAF"
            },
            demandeur: {
              nom: "ENTREPRISE ALPHA SARL",
              rib: "10001-00022-12345678901-23"
            },
            beneficiaire: {
              nom: "COMPAGNIE GAMMA",
              rib: "10003-00044-56781234567-12"
            },
            workflowInstanceId: "wf-ann-002",
            workflowStatus: "VALIDATION_ENTREE"
          },
          {
            id: "ANN-2024-003",
            reference: "ANN20240213003",
            dateCreation: "2024-02-13T14:05:33Z",
            dateTraitement: "2024-02-13T16:45:12Z",
            statut: "REJETE",
            motif: "Duplicata de paiement",
            operationOriginale: {
              reference: "VIR20240212003",
              type: "VIREMENT",
              date: "2024-02-12T10:15:22Z",
              montant: 125000,
              devise: "XAF"
            },
            demandeur: {
              nom: "SOCIÉTÉ DELTA SARL",
              rib: "10004-00055-98765432111-78"
            },
            beneficiaire: {
              nom: "COMPAGNIE OMEGA",
              rib: "10005-00066-12345678911-34"
            },
            commentaireRejet: "Délai d'annulation dépassé (>24h)",
            workflowInstanceId: "wf-ann-003",
            workflowStatus: "REJECTED"
          }
        ];
        
        response = { data: mockAnnulations };
      }
      
      setAnnulations(response.data);
      setFilteredAnnulations(response.data);
    } catch (err) {
      console.error("Erreur lors du chargement des annulations:", err);
      setError("Impossible de charger les annulations. Veuillez réessayer.");
    }
  };
  
  // Récupérer les opérations d'inversion
  const fetchInversions = async () => {
    try {
      // Tentative d'appel à l'API réelle
      let response;
      try {
        response = await apiService.cemacAch.getInversions();
      } catch (apiError) {
        console.warn("API non disponible, utilisation des données simulées:", apiError);
        
        // Données simulées pour la démonstration
        const mockInversions = [
          {
            id: "INV-2024-001",
            reference: "INV20240216001",
            dateCreation: "2024-02-16T09:12:30Z",
            dateTraitement: "2024-02-16T11:45:15Z",
            statut: "TRAITE",
            motif: "Compte bénéficiaire inexistant",
            operationOriginale: {
              reference: "VIR20240215001",
              type: "VIREMENT",
              date: "2024-02-15T16:30:45Z",
              montant: 350000,
              devise: "XAF"
            },
            demandeur: {
              nom: "COMPAGNIE GAMMA",
              rib: "10003-00044-56781234567-12"
            },
            beneficiaire: {
              nom: "ENTREPRISE ALPHA SARL",
              rib: "10001-00022-12345678901-23"
            },
            workflowInstanceId: "wf-inv-001",
            workflowStatus: "EMISSION"
          },
          {
            id: "INV-2024-002",
            reference: "INV20240214002",
            dateCreation: "2024-02-14T13:40:22Z",
            dateTraitement: null,
            statut: "EN_ATTENTE",
            motif: "Opération frauduleuse",
            operationOriginale: {
              reference: "VIR20240213002",
              type: "VIREMENT",
              date: "2024-02-13T10:25:18Z",
              montant: 520000,
              devise: "XAF"
            },
            demandeur: {
              nom: "SOCIÉTÉ DELTA SARL",
              rib: "10004-00055-98765432111-78"
            },
            beneficiaire: {
              nom: "COMPAGNIE OMEGA",
              rib: "10005-00066-12345678911-34"
            },
            workflowInstanceId: "wf-inv-002",
            workflowStatus: "VERIFICATION_ELIGIBILITE"
          },
          {
            id: "INV-2024-003",
            reference: "INV20240212003",
            dateCreation: "2024-02-12T15:10:05Z",
            dateTraitement: "2024-02-12T16:30:40Z",
            statut: "REJETE",
            motif: "Erreur destinataire",
            operationOriginale: {
              reference: "VIR20240211003",
              type: "VIREMENT",
              date: "2024-02-11T14:20:35Z",
              montant: 175000,
              devise: "XAF"
            },
            demandeur: {
              nom: "ENTREPRISE ALPHA SARL",
              rib: "10001-00022-12345678901-23"
            },
            beneficiaire: {
              nom: "SOCIETE BETA SA",
              rib: "10002-00033-98765432109-45"
            },
            commentaireRejet: "Justificatifs insuffisants pour l'inversion demandée",
            workflowInstanceId: "wf-inv-003",
            workflowStatus: "REJECTED"
          }
        ];
        
        response = { data: mockInversions };
      }
      
      setInversions(response.data);
      setFilteredInversions(response.data);
    } catch (err) {
      console.error("Erreur lors du chargement des inversions:", err);
      setError("Impossible de charger les inversions. Veuillez réessayer.");
    }
  };

  // Récupérer les définitions de workflow
  const fetchWorkflowDefinitions = async () => {
    try {
      // Tentative d'appel à l'API réelle
      let annulationDefinition, inversionDefinition;
      try {
        annulationDefinition = await apiService.workflow.getWorkflowDefinition("ENVOI_ANNULATION_PROCESS");
        inversionDefinition = await apiService.workflow.getWorkflowDefinition("ENVOI_INVERSION_PROCESS");
      } catch (apiError) {
        console.warn("API non disponible, utilisation des définitions simulées:", apiError);
        
        // Définitions simulées basées sur votre backend
        annulationDefinition = {
          code: "ENVOI_ANNULATION_PROCESS",
          name: "[Annulation] [Envoi]",
          description: "Processus de traitement des demandes d'annulation d'opération depuis CBS vers PS-ACH",
          initialState: "RECEPTION_CBS",
          finalStates: ["EMISSION"],
          transitions: [
            {
              fromState: "RECEPTION_CBS",
              toState: "VALIDATION_ENTREE",
              event: "VALIDATE_CONTENT",
              actions: ["FILE_READER", "FORMAT_VALIDATOR"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: false
            },
            {
              fromState: "VALIDATION_ENTREE",
              toState: "VERIFICATION_ELIGIBILITE",
              event: "VERIFY_ELIGIBILITY",
              actions: ["ELIGIBILITY_CHECKER"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            },
            {
              fromState: "VERIFICATION_ELIGIBILITE",
              toState: "TRANSFORMATION",
              event: "TRANSFORM_MESSAGE",
              actions: ["MESSAGE_TRANSFORMER"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            },
            {
              fromState: "TRANSFORMATION",
              toState: "SIGNED",
              event: "SIGN_MESSAGE",
              actions: ["SIGNATURE_MANAGER"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            },
            {
              fromState: "SIGNED",
              toState: "EMISSION",
              event: "EMIT_TO_PSACH",
              actions: ["MESSAGE_QUEUE", "NOTIFICATION_SERVICE"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            }
          ]
        };
        
        inversionDefinition = {
          code: "ENVOI_INVERSION_PROCESS",
          name: "[Inversion] [Envoi]",
          description: "Processus de traitement des demandes d'inversion depuis CBS vers PS-ACH",
          initialState: "RECEPTION_CBS",
          finalStates: ["EMISSION"],
          transitions: [
            {
              fromState: "RECEPTION_CBS",
              toState: "VALIDATION_ENTREE",
              event: "VALIDATE_CONTENT",
              actions: ["FileReader", "FormatValidator"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: false
            },
            {
              fromState: "VALIDATION_ENTREE",
              toState: "VERIFICATION_ELIGIBILITE",
              event: "VERIFY_ELIGIBILITY",
              actions: ["EligibilityChecker"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            },
            {
              fromState: "VERIFICATION_ELIGIBILITE",
              toState: "PREPARATION_MESSAGE",
              event: "PREPARE_MESSAGE",
              actions: ["MessagePreparator"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            },
            {
              fromState: "PREPARATION_MESSAGE",
              toState: "TRANSFORMATION",
              event: "TRANSFORM_MESSAGE",
              actions: ["MessageTransformer"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            },
            {
              fromState: "TRANSFORMATION",
              toState: "SIGNED",
              event: "SIGN_MESSAGE",
              actions: ["SignatureManager"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            },
            {
              fromState: "SIGNED",
              toState: "EMISSION",
              event: "EMIT_TO_PSACH",
              actions: ["MessageQueue", "NotificationService"],
              requiredRoles: ["MXPAY_PROCESSOR"],
              isAutomatic: true
            }
          ]
        };
      }

      // Stocker les définitions pour une utilisation ultérieure
      setWorkflowDefinition({
        annulation: annulationDefinition,
        inversion: inversionDefinition
      });
    } catch (err) {
      console.error("Erreur lors du chargement des définitions de workflow:", err);
    }
  };

  // Récupérer l'historique d'un workflow
  const fetchWorkflowHistory = async (instanceId) => {
    setHistoryLoading(true);
    try {
      // Tentative d'appel à l'API réelle
      let response;
      try {
        response = await apiService.workflow.getWorkflowHistory(instanceId);
      } catch (apiError) {
        console.warn("API non disponible, utilisation de l'historique simulé:", apiError);
        
        // Historique simulé
        const mockHistory = [
          {
            id: "hist-1",
            timestamp: new Date(Date.now() - 86400000).toISOString(), // 1 jour avant
            fromState: "RECEPTION_CBS",
            toState: "VALIDATION_ENTREE",
            event: "VALIDATE_CONTENT",
            user: "admin",
            comment: "Validation initiale"
          },
          {
            id: "hist-2",
            timestamp: new Date(Date.now() - 43200000).toISOString(), // 12 heures avant
            fromState: "VALIDATION_ENTREE",
            toState: "VERIFICATION_ELIGIBILITE",
            event: "VERIFY_ELIGIBILITY",
            user: "system",
            comment: "Vérification automatique de l'éligibilité"
          },
          {
            id: "hist-3",
            timestamp: new Date(Date.now() - 21600000).toISOString(), // 6 heures avant
            fromState: "VERIFICATION_ELIGIBILITE",
            toState: "TRANSFORMATION",
            event: "TRANSFORM_MESSAGE",
            user: "system",
            comment: "Transformation du message au format ISO20022"
          }
        ];
        
        response = { data: mockHistory };
      }
      
      setWorkflowHistory(response.data);
    } catch (err) {
      console.error("Erreur lors du chargement de l'historique du workflow:", err);
      setError("Impossible de charger l'historique du workflow. Veuillez réessayer.");
    } finally {
      setHistoryLoading(false);
    }
  };
  
  // Créer une nouvelle opération d'annulation ou d'inversion
  const handleCreateOperation = async () => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Vérification des champs obligatoires
      if (!newOperationForm.referenceOperation || !newOperationForm.motif || !newOperationForm.montant) {
        throw new Error("Veuillez remplir tous les champs obligatoires.");
      }
      
      // Tentative d'appel à l'API réelle
      let response;
      try {
        if (newOperationForm.type === 'annulation') {
          response = await apiService.cemacAch.createAnnulation(newOperationForm);
        } else {
          response = await apiService.cemacAch.createInversion(newOperationForm);
        }
      } catch (apiError) {
        console.warn("API non disponible, simulation de la création:", apiError);
        
        // Simuler un délai pour l'opération
        await new Promise(resolve => setTimeout(resolve, 1500));
        
        // Créer une nouvelle entrée simulée
        const newOperation = {
          id: newOperationForm.type === 'annulation' ? `ANN-2024-${String(annulations.length + 1).padStart(3, '0')}` : `INV-2024-${String(inversions.length + 1).padStart(3, '0')}`,
          reference: newOperationForm.type === 'annulation' ? `ANN${new Date().toISOString().substring(0, 10).replace(/-/g, '')}${String(annulations.length + 1).padStart(3, '0')}` : `INV${new Date().toISOString().substring(0, 10).replace(/-/g, '')}${String(inversions.length + 1).padStart(3, '0')}`,
          dateCreation: new Date().toISOString(),
          dateTraitement: null,
          statut: "EN_ATTENTE",
          motif: newOperationForm.motif,
          operationOriginale: {
            reference: newOperationForm.referenceOperation,
            type: "VIREMENT", // Par défaut, à modifier si nécessaire
            date: new Date().toISOString(),
            montant: parseFloat(newOperationForm.montant),
            devise: newOperationForm.devise || "XAF"
          },
          demandeur: {
            nom: newOperationForm.donneur.nom,
            rib: newOperationForm.donneur.rib
          },
          beneficiaire: {
            nom: newOperationForm.beneficiaire.nom,
            rib: newOperationForm.beneficiaire.rib
          },
          workflowInstanceId: newOperationForm.type === 'annulation' ? `wf-ann-new-${Date.now()}` : `wf-inv-new-${Date.now()}`,
          workflowStatus: "RECEPTION_CBS"
        };
        
        response = { data: newOperation };
      }
      
      // Mettre à jour l'état en fonction du type d'opération
      if (newOperationForm.type === 'annulation') {
        setAnnulations([response.data, ...annulations]);
      } else {
        setInversions([response.data, ...inversions]);
      }
      
      setSuccessMessage(`L'opération d'${newOperationForm.type === 'annulation' ? 'annulation' : 'inversion'} a été créée avec succès.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      // Réinitialiser le formulaire
      setNewOperationForm({
        type: 'annulation',
        referenceOperation: '',
        motif: '',
        montant: '',
        devise: 'XAF',
        beneficiaire: {
          nom: '',
          rib: ''
        },
        donneur: {
          nom: '',
          rib: ''
        },
        commentaire: ''
      });
      
      // Fermer le formulaire
      setShowNewForm(false);
      
    } catch (err) {
      console.error("Erreur lors de la création de l'opération:", err);
      setError(err.message || `Impossible de créer l'opération d'${newOperationForm.type === 'annulation' ? 'annulation' : 'inversion'}. Veuillez réessayer.`);
    } finally {
      setActionLoading(false);
    }
  };
  
  // Annuler la création d'une nouvelle opération
  const handleCancelNewOperation = () => {
    setNewOperationForm({
      type: 'annulation',
      referenceOperation: '',
      motif: '',
      montant: '',
      devise: 'XAF',
      beneficiaire: {
        nom: '',
        rib: ''
      },
      donneur: {
        nom: '',
        rib: ''
      },
      commentaire: ''
    });
    setShowNewForm(false);
  };
  
  // Traiter une opération (passage à l'étape suivante dans le workflow)
  const handleProcessOperation = async (operation, type) => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Tenter de récupérer l'instance de workflow actuelle
      let workflowInstance;
      try {
        workflowInstance = await apiService.workflow.getWorkflowDetails(operation.workflowInstanceId);
      } catch (apiError) {
        console.warn("API non disponible, simulation du traitement:", apiError);
        
        // Simuler un délai pour le traitement
        await new Promise(resolve => setTimeout(resolve, 1200));
        
        // Transitions possibles simulées en fonction du statut actuel
        const possibleTransitions = {
          "RECEPTION_CBS": "VALIDATION_ENTREE",
          "VALIDATION_ENTREE": "VERIFICATION_ELIGIBILITE",
          "VERIFICATION_ELIGIBILITE": type === 'annulation' ? "TRANSFORMATION" : "PREPARATION_MESSAGE",
          "PREPARATION_MESSAGE": "TRANSFORMATION",
          "TRANSFORMATION": "SIGNED",
          "SIGNED": "EMISSION",
          "EN_ATTENTE": "EN_COURS",
          "EN_COURS": "TRAITE"
        };
        
        // Déterminer le nouvel état
        const currentStatus = operation.workflowStatus || operation.statut;
        const newStatus = possibleTransitions[currentStatus] || "TRAITE";
        
        // Mettre à jour l'état de l'opération
        if (type === 'annulation') {
          const updatedAnnulations = annulations.map(ann => 
            ann.id === operation.id 
              ? { 
                  ...ann, 
                  statut: newStatus === "EMISSION" ? "TRAITE" : "EN_COURS", 
                  dateTraitement: newStatus === "EMISSION" ? new Date().toISOString() : null,
                  workflowStatus: newStatus
                }
              : ann
          );setAnnulations(updatedAnnulations);
        } else {
          const updatedInversions = inversions.map(inv => 
            inv.id === operation.id 
              ? { 
                  ...inv, 
                  statut: newStatus === "EMISSION" ? "TRAITE" : "EN_COURS", 
                  dateTraitement: newStatus === "EMISSION" ? new Date().toISOString() : null,
                  workflowStatus: newStatus
                }
              : inv
          );
          setInversions(updatedInversions);
        }
        
        return;
      }
      
      // Récupérer les transitions disponibles
      const availableTransitions = workflowInstance.availableTransitions || [];
      if (availableTransitions.length === 0) {
        throw new Error("Aucune transition disponible pour cette opération.");
      }
      
      // Sélectionner la première transition disponible (ou demander à l'utilisateur de choisir)
      const transitionToUse = availableTransitions[0];
      
      // Appliquer la transition
      await apiService.workflow.processEvent(
        operation.workflowInstanceId, 
        transitionToUse.event, 
        { operationId: operation.id }
      );
      
      // Mettre à jour l'interface
      if (type === 'annulation') {
        await fetchAnnulations();
      } else {
        await fetchInversions();
      }
      
      setSuccessMessage(`L'opération ${operation.reference} a été traitée avec succès.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
    } catch (err) {
      console.error(`Erreur lors du traitement de l'opération ${operation.id}:`, err);
      setError(`Impossible de traiter l'opération. ${err.message || "Veuillez réessayer."}`);
    } finally {
      setActionLoading(false);
    }
  };
  
  // Rejeter une opération
  const handleRejectOperation = async (operation, type, reason) => {
    if (!reason || reason.trim() === '') {
      setError("Veuillez fournir un motif de rejet.");
      return;
    }

    setActionLoading(true);
    setError(null);
    
    try {
      // Tenter d'appeler l'API réelle
      let response;
      try {
        response = await apiService.workflow.processEvent(
          operation.workflowInstanceId,
          "REJECT_PROCESS",
          { 
            operationId: operation.id,
            rejectionReason: reason
          }
        );
      } catch (apiError) {
        console.warn("API non disponible, simulation du rejet:", apiError);
        
        // Simuler un délai pour le rejet
        await new Promise(resolve => setTimeout(resolve, 1200));
        
        // Mettre à jour l'état de l'opération
        if (type === 'annulation') {
          const updatedAnnulations = annulations.map(ann => 
            ann.id === operation.id 
              ? { 
                  ...ann, 
                  statut: 'REJETE', 
                  dateTraitement: new Date().toISOString(), 
                  commentaireRejet: reason,
                  workflowStatus: "REJECTED"
                }
              : ann
          );
          setAnnulations(updatedAnnulations);
        } else {
          const updatedInversions = inversions.map(inv => 
            inv.id === operation.id 
              ? { 
                  ...inv, 
                  statut: 'REJETE', 
                  dateTraitement: new Date().toISOString(), 
                  commentaireRejet: reason,
                  workflowStatus: "REJECTED"
                }
              : inv
          );
          setInversions(updatedInversions);
        }
        
        return;
      }
      
      // Mettre à jour l'interface
      if (type === 'annulation') {
        await fetchAnnulations();
      } else {
        await fetchInversions();
      }
      
      setSuccessMessage(`L'opération ${operation.reference} a été rejetée.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
    } catch (err) {
      console.error(`Erreur lors du rejet de l'opération ${operation.id}:`, err);
      setError(`Impossible de rejeter l'opération. ${err.message || "Veuillez réessayer."}`);
    } finally {
      setActionLoading(false);
      // Fermer la modal des détails si ouverte
      setShowDetailsModal(false);
    }
  };
  
  // Télécharger les opérations en CSV
  const handleExportCsv = () => {
    const operations = activeTab === 'annulations' ? filteredAnnulations : filteredInversions;
    const fileName = `${activeTab}_${new Date().toISOString().split('T')[0]}.csv`;
    
    // Création de l'en-tête CSV
    let csvContent = "Référence,Date,Statut,Motif,Montant,Devise,Référence Originale,Type Original,Demandeur,RIB Demandeur,Bénéficiaire,RIB Bénéficiaire,État Workflow\n";
    
    // Ajout des lignes de données
    operations.forEach(op => {
      csvContent += `${op.reference},${op.dateCreation},${op.statut},${op.motif},${op.operationOriginale.montant},${op.operationOriginale.devise},${op.operationOriginale.reference},${op.operationOriginale.type},${op.demandeur.nom},${op.demandeur.rib},${op.beneficiaire.nom},${op.beneficiaire.rib},${op.workflowStatus || ''}\n`;
    });
    
    // Création du blob et téléchargement
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Gérer le téléchargement d'un fichier d'opérations
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    setActionLoading(true);
    setError(null);
    
    try {
      // Vérifier l'extension du fichier
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        throw new Error("Format de fichier non pris en charge. Seuls les fichiers CSV et Excel sont acceptés.");
      }
      
      // Préparer les données pour l'envoi
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', activeTab);
      
      // Tenter d'appeler l'API réelle
      let response;
      try {
        response = await apiService.cemacAch.uploadOperationsFile(formData);
      } catch (apiError) {
        console.warn("API non disponible, simulation de l'upload:", apiError);
        
        // Simuler un délai pour l'upload
        await new Promise(resolve => setTimeout(resolve, 1800));
        
        // Simuler un nombre aléatoire d'opérations créées
        const nbOperations = Math.floor(Math.random() * 5) + 1;
        
        setSuccessMessage(`Le fichier ${file.name} a été téléchargé avec succès. ${nbOperations} opérations seront traitées en arrière-plan.`);
        setTimeout(() => setSuccessMessage(null), 5000);
        
        // Actualiser les listes après un court délai pour simuler le traitement en arrière-plan
        setTimeout(() => {
          if (activeTab === 'annulations') {
            fetchAnnulations();
          } else {
            fetchInversions();
          }
        }, 3000);
        
        return;
      }
      
      setSuccessMessage(`Le fichier ${file.name} a été téléchargé avec succès. ${response.data.operationsCreated || 0} opérations seront traitées en arrière-plan.`);
      setTimeout(() => setSuccessMessage(null), 5000);
      
      // Rafraîchir la liste des opérations après un délai pour laisser le temps au traitement en arrière-plan
      setTimeout(() => {
        if (activeTab === 'annulations') {
          fetchAnnulations();
        } else {
          fetchInversions();
        }
      }, 3000);
      
    } catch (err) {
      console.error("Erreur lors du téléchargement du fichier:", err);
      setError(err.message || "Impossible de télécharger le fichier. Veuillez réessayer.");
    } finally {
      setActionLoading(false);
      // Réinitialiser l'input file
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };
  
  // Télécharger le modèle de fichier d'importation
  const handleDownloadTemplate = () => {
    const fileName = `modele_${activeTab}.xlsx`;
    
    // Création d'un en-tête CSV spécifique au type d'opération
    let csvContent;
    
    if (activeTab === 'annulations') {
      csvContent = "ReferenceOrigine,Montant,Devise,MotifAnnulation,NomDemandeur,RibDemandeur,NomBeneficiaire,RibBeneficiaire,Commentaire\n";
      csvContent += "VIR20240301001,100000,XAF,Erreur destinataire,ENTREPRISE EXEMPLE,11111-00000-12345678901-99,SOCIETE DESTINATAIRE,22222-00000-98765432101-99,Demande d'annulation suite à erreur\n";
    } else {
      csvContent = "ReferenceOrigine,Montant,Devise,MotifInversion,NomDemandeur,RibDemandeur,NomBeneficiaire,RibBeneficiaire,Commentaire\n";
      csvContent += "VIR20240301001,100000,XAF,Compte inexistant,ENTREPRISE EXEMPLE,11111-00000-12345678901-99,SOCIETE DESTINATAIRE,22222-00000-98765432101-99,Demande d'inversion pour compte inexistant\n";
    }
    
    // Création du blob et téléchargement
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Afficher les détails d'une opération
  const handleShowDetails = (operation, type) => {
    setModalDetails({ ...operation, type });
    setShowDetailsModal(true);
    
    // Charger l'historique du workflow si disponible
    if (operation.workflowInstanceId) {
      fetchWorkflowHistory(operation.workflowInstanceId);
    } else {
      setWorkflowHistory([]);
    }
  };
  
  // Formater le montant
  const formatAmount = (amount, currency = 'XAF') => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0
    }).format(amount);
  };
  
  // Afficher le statut avec le badge approprié
  const renderStatus = (status) => {
    switch (status) {
      case 'TRAITE':
      case 'EMISSION':
      case 'EMISSION_CBS':
      case 'EMISSION_PSACH':
        return <StatusBadge status="active" customLabel="Traité" />;
      case 'EN_COURS':
      case 'EN_ATTENTE':
      case 'VALIDATION_ENTREE':
      case 'VERIFICATION_ELIGIBILITE':
      case 'PREPARATION_MESSAGE':
      case 'TRANSFORMATION':
      case 'SIGNED':
      case 'RECEPTION_CBS':
        return <StatusBadge status="warning" customLabel="En traitement" />;
      case 'REJETE':
      case 'REJECTED':
        return <StatusBadge status="inactive" customLabel="Rejeté" />;
      default:
        return <StatusBadge status="warning" customLabel={status} />;
    }
  };
  
  // Réinitialiser les filtres d'annulation
  const resetAnnulationFilters = () => {
    setSearchTermAnnulation('');
    setStatusFilterAnnulation('all');
    setDateRangeFilterAnnulation({
      start: null,
      end: null
    });
  };
  
  // Réinitialiser les filtres d'inversion
  const resetInversionFilters = () => {
    setSearchTermInversion('');
    setStatusFilterInversion('all');
    setDateRangeFilterInversion({
      start: null,
      end: null
    });
  };
  
  // Afficher le diagramme de workflow
  const handleShowWorkflowDiagram = () => {
    setShowWorkflowDiagram(!showWorkflowDiagram);
  };
  
  // Formatter la description du statut du workflow
  const getWorkflowStatusDescription = (status) => {
    const statusDescriptions = {
      "RECEPTION_CBS": "Réception depuis CBS",
      "VALIDATION_ENTREE": "Validation des données d'entrée",
      "VERIFICATION_ELIGIBILITE": "Vérification de l'éligibilité",
      "PREPARATION_MESSAGE": "Préparation du message",
      "TRANSFORMATION": "Transformation du message",
      "SIGNED": "Message signé électroniquement",
      "EMISSION": "Émission du message",
      "EMISSION_CBS": "Émission vers CBS",
      "EMISSION_PSACH": "Émission vers PS-ACH",
      "REJECTED": "Opération rejetée"
    };
    
    return statusDescriptions[status] || status;
  };

  // Rendu du composant
  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <RotateCcw className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Annulations et Inversions
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les demandes d'annulation et d'inversion des opérations SYSTAC
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline"
            icon={Plus}
            onClick={() => setShowNewForm(true)}
          >
            Nouvelle demande
          </Button>
          <Button
            variant="outline" 
            icon={RefreshCw}
            onClick={() => activeTab === 'annulations' ? fetchAnnulations() : fetchInversions()}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="outline"
            icon={Download}
            onClick={handleExportCsv}
            disabled={loading || (activeTab === 'annulations' ? filteredAnnulations.length === 0 : filteredInversions.length === 0)}
          >
            Exporter
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            className="hidden"
            accept=".csv,.xlsx,.xls"
          />
          <Button
            variant="outline"
            icon={Upload}
            onClick={() => fileInputRef.current.click()}
            loading={actionLoading}
          >
            Importer
          </Button>
          <Button
            variant={showWorkflowDiagram ? "primary" : "outline"}
            icon={GitBranch}
            onClick={handleShowWorkflowDiagram}
          >
            Workflow
          </Button>
        </div>
      </div>
      
      {/* Messages de succès et d'erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Diagramme de workflow (conditionnel) */}
      {showWorkflowDiagram && workflowDefinition && (
        <Card
          title={`Diagramme de Workflow - ${activeTab === 'annulations' ? 'Annulations' : 'Inversions'}`}
          icon={GitBranch}
          actions={
            <Button
              variant="outline"
              size="sm"
              icon={X}
              onClick={handleShowWorkflowDiagram}
            >
              Fermer
            </Button>
          }
        >
          <WorkflowStateTransitionDiagram
            workflowDefinition={workflowDefinition[activeTab === 'annulations' ? 'annulation' : 'inversion']}
            onRefresh={() => fetchWorkflowDefinitions()}
          />
        </Card>
      )}
      
      {/* Navigation par onglets */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <nav className="-mb-px flex">
          <button
            className={`py-4 px-6 border-b-2 font-medium text-sm ${
              activeTab === 'annulations'
                ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('annulations')}
          >
            Annulations
          </button>
          <button
            className={`py-4 px-6 border-b-2 font-medium text-sm ${
              activeTab === 'inversions'
                ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('inversions')}
          >
            Inversions
          </button>
        </nav>
      </div>
      
      {/* Contenu principal - Annulations */}
      {activeTab === 'annulations' && (
        <div>
          {/* Filtres pour annulations */}
          <div className="mb-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Rechercher une annulation..."
                value={searchTermAnnulation}
                onChange={(e) => setSearchTermAnnulation(e.target.value)}
              />
            </div>
            <div className="flex gap-2">
              <Button
                variant={showFiltersAnnulation ? "primary" : "outline"}
                icon={Filter}
                onClick={() => setShowFiltersAnnulation(!showFiltersAnnulation)}
              >
                Filtres
              </Button>
            </div>
          </div>
          
          {showFiltersAnnulation && (
            <Card className="mb-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label htmlFor="statusFilterAnnulation" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Statut
                  </label>
                  <select
                    id="statusFilterAnnulation"
                    value={statusFilterAnnulation}
                    onChange={(e) => setStatusFilterAnnulation(e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="all">Tous</option>
                    <option value="EN_ATTENTE">En attente</option>
                    <option value="EN_COURS">En cours</option>
                    <option value="TRAITE">Traité</option>
                    <option value="REJETE">Rejeté</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="startDateAnnulation" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Date de début
                  </label>
                  <input
                    type="date"
                    id="startDateAnnulation"
                    value={dateRangeFilterAnnulation.start || ''}
                    onChange={(e) => setDateRangeFilterAnnulation({...dateRangeFilterAnnulation, start: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="endDateAnnulation" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Date de fin
                  </label>
                  <input
                    type="date"
                    id="endDateAnnulation"
                    value={dateRangeFilterAnnulation.end || ''}
                    onChange={(e) => setDateRangeFilterAnnulation({...dateRangeFilterAnnulation, end: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              
              <div className="mt-4 flex justify-end space-x-2">
                <Button
                  variant="outline"
                  onClick={resetAnnulationFilters}
                >
                  Réinitialiser
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    // Appliquer les filtres, actuellement ils sont appliqués automatiquement
                    setShowFiltersAnnulation(false);
                  }}
                >
                  Appliquer
                </Button>
              </div>
            </Card>
          )}
          
          {/* Liste des annulations */}
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          ) : filteredAnnulations.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64 bg-white dark:bg-gray-800 rounded-lg shadow">
              <RotateCcw className="h-16 w-16 text-gray-300 dark:text-gray-600 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">Aucune annulation trouvée</h3>
              <p className="text-gray-500 dark:text-gray-400 mt-2 text-center max-w-md">
                Aucune opération d'annulation ne correspond à vos critères de recherche. Vous pouvez créer une nouvelle demande d'annulation ou modifier vos filtres.
              </p>
              <Button
                variant="outline"
                icon={Plus}
                onClick={() => setShowNewForm(true)}
                className="mt-4"
              >
                Nouvelle annulation
              </Button>
            </div>
          ) : (
            <div className="overflow-x-auto bg-white dark:bg-gray-800 shadow-md rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr className="bg-gray-50 dark:bg-gray-700">
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Référence
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Statut
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Opération originale
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Montant
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Motif
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      État Workflow
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredAnnulations.map((annulation) => (
                    <tr key={annulation.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600 dark:text-blue-400">
                        <div className="flex items-center">
                          <span>{annulation.reference}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <DateFormatter date={annulation.dateCreation} format="short" showTime />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {renderStatus(annulation.statut)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <div className="flex flex-col">
                          <span>{annulation.operationOriginale.reference}</span>
                          <span className="text-xs text-gray-400 dark:text-gray-500">
                            {annulation.operationOriginale.type} - <DateFormatter date={annulation.operationOriginale.date} format="short" />
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                        {formatAmount(annulation.operationOriginale.montant, annulation.operationOriginale.devise)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <span className="truncate max-w-xs inline-block">{annulation.motif}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {annulation.workflowStatus && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300">
                            {getWorkflowStatusDescription(annulation.workflowStatus)}
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex space-x-2 justify-end">
                          <Button
                            variant="outline"
                            size="sm"
                            icon={Eye}
                            onClick={() => handleShowDetails(annulation, 'annulation')}
                          >
                            Détails
                          </Button>
                          {(annulation.statut === 'EN_ATTENTE' || annulation.statut === 'EN_COURS') && !['EMISSION', 'REJETE', 'REJECTED'].includes(annulation.workflowStatus) && (
                            <Button
                              variant="outline"
                              size="sm"
                              icon={Check}
                              onClick={() => handleProcessOperation(annulation, 'annulation')}
                              disabled={actionLoading}
                            >
                              Traiter
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      
      {/* Contenu principal - Inversions */}
      {activeTab === 'inversions' && (
        <div>
          {/* Filtres pour inversions */}
          <div className="mb-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Rechercher une inversion..."
                value={searchTermInversion}
                onChange={(e) => setSearchTermInversion(e.target.value)}
              />
            </div>
            <div className="flex gap-2">
              <Button
                variant={showFiltersInversion ? "primary" : "outline"}
                icon={Filter}
                onClick={() => setShowFiltersInversion(!showFiltersInversion)}
              >
                Filtres
              </Button>
            </div>
          </div>
          
          {showFiltersInversion && (
            <Card className="mb-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label htmlFor="statusFilterInversion" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Statut
                  </label>
                  <select
                    id="statusFilterInversion"
                    value={statusFilterInversion}
                    onChange={(e) => setStatusFilterInversion(e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="all">Tous</option>
                    <option value="EN_ATTENTE">En attente</option>
                    <option value="EN_COURS">En cours</option>
                    <option value="TRAITE">Traité</option>
                    <option value="REJETE">Rejeté</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="startDateInversion" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Date de début
                  </label>
                  <input
                    type="date"
                    id="startDateInversion"
                    value={dateRangeFilterInversion.start || ''}
                    onChange={(e) => setDateRangeFilterInversion({...dateRangeFilterInversion, start: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="endDateInversion" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Date de fin
                  </label>
                  <input
                    type="date"
                    id="endDateInversion"
                    value={dateRangeFilterInversion.end || ''}
                    onChange={(e) => setDateRangeFilterInversion({...dateRangeFilterInversion, end: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              
              <div className="mt-4 flex justify-end space-x-2">
                <Button
                  variant="outline"
                  onClick={resetInversionFilters}
                >
                  Réinitialiser
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    // Appliquer les filtres, actuellement ils sont appliqués automatiquement
                    setShowFiltersInversion(false);
                  }}
                >
                  Appliquer
                </Button>
              </div>
            </Card>
          )}
          
          {/* Liste des inversions */}
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          ) : filteredInversions.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64 bg-white dark:bg-gray-800 rounded-lg shadow">
              <ArrowRightLeft className="h-16 w-16 text-gray-300 dark:text-gray-600 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">Aucune inversion trouvée</h3>
              <p className="text-gray-500 dark:text-gray-400 mt-2 text-center max-w-md">
                Aucune opération d'inversion ne correspond à vos critères de recherche. Vous pouvez créer une nouvelle demande d'inversion ou modifier vos filtres.
              </p>
              <Button
                variant="outline"
                icon={Plus}
                onClick={() => {
                  setNewOperationForm({...newOperationForm, type: 'inversion'});
                  setShowNewForm(true);
                }}
                className="mt-4"
              >
                Nouvelle inversion
              </Button>
            </div>
          ) : (
            <div className="overflow-x-auto bg-white dark:bg-gray-800 shadow-md rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr className="bg-gray-50 dark:bg-gray-700">
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Référence
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Statut
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Opération originale
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Montant
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Motif
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      État Workflow
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredInversions.map((inversion) => (
                    <tr key={inversion.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600 dark:text-blue-400">
                        <div className="flex items-center">
                          <span>{inversion.reference}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <DateFormatter date={inversion.dateCreation} format="short" showTime />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {renderStatus(inversion.statut)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <div className="flex flex-col">
                          <span>{inversion.operationOriginale.reference}</span>
                          <span className="text-xs text-gray-400 dark:text-gray-500">
                            {inversion.operationOriginale.type} - <DateFormatter date={inversion.operationOriginale.date} format="short" />
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                        {formatAmount(inversion.operationOriginale.montant, inversion.operationOriginale.devise)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <span className="truncate max-w-xs inline-block">{inversion.motif}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {inversion.workflowStatus && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300">
                            {getWorkflowStatusDescription(inversion.workflowStatus)}
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex space-x-2 justify-end">
                          <Button
                            variant="outline"
                            size="sm"
                            icon={Eye}
                            onClick={() => handleShowDetails(inversion, 'inversion')}
                          >
                            Détails
                          </Button>
                          {(inversion.statut === 'EN_ATTENTE' || inversion.statut === 'EN_COURS') && !['EMISSION', 'REJETE', 'REJECTED'].includes(inversion.workflowStatus) && (
                            <Button
                              variant="outline"
                              size="sm"
                              icon={Check}
                              onClick={() => handleProcessOperation(inversion, 'inversion')}
                              disabled={actionLoading}
                            >
                              Traiter
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      
      {/* Modal pour nouvelle opération */}
      {showNewForm && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Nouvelle demande d'{ newOperationForm.type === 'annulation' ? 'annulation' : 'inversion' }
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={handleCancelNewOperation}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                {/* Type d'opération */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Type d'opération
                  </label>
                  <div className="mt-2 flex space-x-4">
                    <div className="flex items-center">
                      <input
                        id="annulation"
                        name="type"
                        type="radio"
                        value="annulation"
                        checked={newOperationForm.type === 'annulation'}
                        onChange={(e) => setNewOperationForm({...newOperationForm, type: e.target.value})}
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                      />
                      <label htmlFor="annulation" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Annulation
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="inversion"
                        name="type"
                        type="radio"
                        value="inversion"
                        checked={newOperationForm.type === 'inversion'}
                        onChange={(e) => setNewOperationForm({...newOperationForm, type: e.target.value})}
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                      />
                      <label htmlFor="inversion" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                        Inversion
                      </label>
                    </div>
                  </div>
                </div>
                
                {/* Référence de l'opération originale */}
                <div>
                  <label htmlFor="referenceOperation" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Référence de l'opération originale *
                  </label>
                  <input
                    type="text"
                    id="referenceOperation"
                    value={newOperationForm.referenceOperation}
                    onChange={(e) => setNewOperationForm({...newOperationForm, referenceOperation: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="VIR20240215001"
                    required
                  />
                </div>
                
                {/* Motif */}
                <div>
                  <label htmlFor="motif" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Motif de l'{ newOperationForm.type === 'annulation' ? 'annulation' : 'inversion' } *
                  </label>
                  <select
                    id="motif"
                    value={newOperationForm.motif}
                    onChange={(e) => setNewOperationForm({...newOperationForm, motif: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  >
                    <option value="">Sélectionner un motif</option>
                    <option value="Erreur destinataire">Erreur destinataire</option>
                    <option value="Montant incorrect">Montant incorrect</option>
                    <option value="Duplicata de paiement">Duplicata de paiement</option>
                    <option value="Compte bénéficiaire inexistant">Compte bénéficiaire inexistant</option>
                    <option value="Opération frauduleuse">Opération frauduleuse</option>
                    <option value="Autre">Autre</option>
                  </select>
                </div>
                
                {/* Montant */}
                <div>
                  <label htmlFor="montant" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Montant *
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="number"
                      id="montant"
                      value={newOperationForm.montant}
                      onChange={(e) => setNewOperationForm({...newOperationForm, montant: e.target.value})}
                      className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="0.00"
                      required
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <select
                        id="devise"
                        name="devise"
                        value={newOperationForm.devise}
                        onChange={(e) => setNewOperationForm({...newOperationForm, devise: e.target.value})}
                        className="h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 dark:text-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="XAF">XAF</option>
                        <option value="EUR">EUR</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                {/* Informations du donneur d'ordre */}
                <div>
                  <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Informations du donneur d'ordre *
                  </h4>
                  <div className="space-y-3">
                    <div>
                      <label htmlFor="donneurNom" className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="donneurNom"
                        value={newOperationForm.donneur.nom}
                        onChange={(e) => setNewOperationForm({
                          ...newOperationForm, 
                          donneur: {
                            ...newOperationForm.donneur,
                            nom: e.target.value
                          }
                        })}
                        className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="NOM ENTREPRISE"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="donneurRib" className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                        RIB
                      </label>
                      <input
                        type="text"
                        id="donneurRib"
                        value={newOperationForm.donneur.rib}
                        onChange={(e) => setNewOperationForm({
                          ...newOperationForm, 
                          donneur: {
                            ...newOperationForm.donneur,
                            rib: e.target.value
                          }
                        })}
                        className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="XXXXX-XXXXX-XXXXXXXXXXX-XX"
                        required
                      />
                    </div>
                  </div>
                </div>
                
                {/* Informations du bénéficiaire */}
                <div>
                  <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Informations du bénéficiaire *
                  </h4>
                  <div className="space-y-3">
                    <div>
                      <label htmlFor="beneficiaireNom" className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="beneficiaireNom"
                        value={newOperationForm.beneficiaire.nom}
                        onChange={(e) => setNewOperationForm({
                          ...newOperationForm, 
                          beneficiaire: {
                            ...newOperationForm.beneficiaire,
                            nom: e.target.value
                          }
                        })}
                        className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="NOM BENEFICIAIRE"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="beneficiaireRib" className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                        RIB
                      </label>
                      <input
                        type="text"
                        id="beneficiaireRib"
                        value={newOperationForm.beneficiaire.rib}
                        onChange={(e) => setNewOperationForm({
                          ...newOperationForm, 
                          beneficiaire: {
                            ...newOperationForm.beneficiaire,
                            rib: e.target.value
                          }
                        })}
                        className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="XXXXX-XXXXX-XXXXXXXXXXX-XX"
                        required
                      />
                    </div>
                  </div>
                </div>
                
                {/* Commentaire */}
                <div>
                  <label htmlFor="commentaire" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Commentaire
                  </label>
                  <textarea
                    id="commentaire"
                    value={newOperationForm.commentaire}
                    onChange={(e) => setNewOperationForm({...newOperationForm, commentaire: e.target.value})}
                    rows={3}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Informations complémentaires..."
                  />
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={handleCancelNewOperation}
                  disabled={actionLoading}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreateOperation}
                  loading={actionLoading}
                >
                  Créer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Modal de détails */}
      {showDetailsModal && modalDetails && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-4xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  {modalDetails.type === 'annulation' ? (
                    <RotateCcw className="w-5 h-5 mr-2 text-blue-500" />
                  ) : (
                    <ArrowRightLeft className="w-5 h-5 mr-2 text-blue-500" />
                  )}
                  Détails de l'opération {modalDetails.reference}
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                {/* Informations principales */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    Informations générales
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Date de création:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        <DateFormatter date={modalDetails.dateCreation} format="full" showTime />
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Statut:
                      </p>
                      <div className="text-sm font-medium">
                        {renderStatus(modalDetails.statut)}
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Date de traitement:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {modalDetails.dateTraitement ? (
                          <DateFormatter date={modalDetails.dateTraitement} format="full" showTime />
                        ) : (
                          <span className="text-gray-400 dark:text-gray-500">Non traitée</span>
                        )}
                      </p>
                    </div>
                    <div><p className="text-sm text-gray-500 dark:text-gray-400">
                        Motif:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {modalDetails.motif}
                      </p>
                    </div>
                    {modalDetails.commentaireRejet && (
                      <div className="col-span-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Motif de rejet:
                        </p>
                        <p className="text-sm font-medium text-red-600 dark:text-red-400">
                          {modalDetails.commentaireRejet}
                        </p>
                      </div>
                    )}
                    <div className="col-span-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        État du workflow:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {modalDetails.workflowStatus ? (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300">
                            {getWorkflowStatusDescription(modalDetails.workflowStatus)}
                          </span>
                        ) : (
                          <span className="text-gray-400 dark:text-gray-500">Non disponible</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* Historique du workflow */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                    <GitBranch className="w-5 h-5 mr-2 text-blue-500" />
                    Historique du workflow
                  </h4>
                  
                  {historyLoading ? (
                    <div className="flex justify-center items-center py-8">
                      <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                    </div>
                  ) : workflowHistory.length === 0 ? (
                    <p className="text-sm text-gray-500 dark:text-gray-400 py-4">
                      Aucun historique disponible pour cette opération.
                    </p>
                  ) : (
                    <div className="space-y-2 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
                      {workflowHistory.map((entry, index) => (
                        <div key={entry.id || index} className="relative">
                          <div className="absolute -left-[25px] mt-1.5">
                            <div className="w-5 h-5 rounded-full bg-blue-500"></div>
                          </div>
                          <div className="pl-4 pb-4">
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {entry.fromState} &rarr; {entry.toState}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              <DateFormatter date={entry.timestamp} format="full" showTime /> | Événement: {entry.event}
                            </p>
                            {entry.comment && (
                              <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                                {entry.comment}
                              </p>
                            )}
                            {entry.user && (
                              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                Utilisateur: {entry.user}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                {/* Détails de l'opération originale */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    Détails de l'opération originale
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Référence:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {modalDetails.operationOriginale.reference}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Type:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {modalDetails.operationOriginale.type}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Date:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        <DateFormatter date={modalDetails.operationOriginale.date} format="full" showTime />
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Montant:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {formatAmount(modalDetails.operationOriginale.montant, modalDetails.operationOriginale.devise)}
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* Informations des parties */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                      Informations du demandeur
                    </h4>
                    <div className="space-y-2">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Nom:
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {modalDetails.demandeur.nom}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          RIB:
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {modalDetails.demandeur.rib}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                      Informations du bénéficiaire
                    </h4>
                    <div className="space-y-2">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Nom:
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {modalDetails.beneficiaire.nom}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          RIB:
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                          {modalDetails.beneficiaire.rib}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Actions */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                  <div className="flex justify-between">
                    <div>
                      {modalDetails.statut === 'EN_ATTENTE' || modalDetails.statut === 'EN_COURS' ? (
                        <div className="flex space-x-3">
                          <Button
                            variant="outline"
                            icon={X}
                            onClick={() => {
                              const reason = window.prompt(`Veuillez indiquer le motif du rejet pour l'opération ${modalDetails.reference}:`);
                              if (reason) {
                                handleRejectOperation(modalDetails, modalDetails.type, reason);
                              }
                            }}
                            disabled={actionLoading}
                          >
                            Rejeter
                          </Button>
                          {!['EMISSION', 'REJETE', 'REJECTED'].includes(modalDetails.workflowStatus) && (
                            <Button
                              variant="primary"
                              icon={Check}
                              onClick={() => {
                                handleProcessOperation(modalDetails, modalDetails.type);
                                setShowDetailsModal(false);
                              }}
                              disabled={actionLoading}
                            >
                              Traiter
                            </Button>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <Button
                      variant="outline"
                      onClick={() => setShowDetailsModal(false)}
                    >
                      Fermer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Bouton d'aide fixe en bas à droite */}
      <div className="fixed bottom-6 right-6">
        <button 
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-3 shadow-lg transition-all"
          onClick={() => {
            // Afficher une aide contextuelle
            alert(`Guide rapide:
1. Créez de nouvelles demandes d'annulation ou d'inversion avec le bouton "Nouvelle demande".
2. Consultez les détails d'une opération en cliquant sur "Détails".
3. Traitez les opérations en attente avec le bouton "Traiter".
4. Visualisez le workflow complet avec le bouton "Workflow".
5. Importez/exportez des opérations avec les boutons correspondants.
            `);
          }}
        >
          <Info className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default AnnulationsInversions;