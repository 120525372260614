import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Divider,
  Chip,
  Tooltip,
  CircularProgress,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Drawer
} from '@mui/material';

import {
  Plus,
  Edit2,
  Trash2,
  Search,
  RefreshCw,
  CheckCircle,
  XCircle,
  AlertTriangle,
  Code,
  FileText,
  Calendar,
  Clock,
  User,
  Star,
  Filter,
  ChevronRight,
  ChevronDown,
  MoreVertical,
  Info,
  ArrowUp,
  ArrowDown,
  Send,
  ThumbsUp,
  Check,
  X,
  Activity
} from 'lucide-react';

import api from '../../services/api.service';

/**
 * Page de gestion des évolutions
 */
const EvolutionsPage = () => {
  // États pour les évolutions
  const [evolutions, setEvolutions] = useState([]);
  const [filteredEvolutions, setFilteredEvolutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvolution, setSelectedEvolution] = useState(null);
  
  // États pour les fonctions et filtres
  const [functions, setFunctions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  
  // État pour les filtres
  const [searchQuery, setSearchQuery] = useState('');
  const [functionFilter, setFunctionFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [priorityFilter, setPriorityFilter] = useState('all');
  
  // États pour le tri
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('requestDate');
  
  // États pour les dialogues
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('create'); // 'create' ou 'edit'
  const [dialogData, setDialogData] = useState({
    title: '',
    description: '',
    type: 'FEATURE',
    priority: 'MEDIUM',
    status: 'REQUESTED',
    function: { id: '' },
    implementation: ''
  });
  
  // État pour la notification
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // État pour la vue détaillée
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  
  // État pour le dialogue d'assignation
  const [assignDialog, setAssignDialog] = useState(false);
  const [assignUserId, setAssignUserId] = useState('');
  
  // État pour le dialogue d'approbation
  const [approveDialog, setApproveDialog] = useState(false);
  
  // État pour le dialogue de suppression
  const [deleteDialog, setDeleteDialog] = useState(false);
  
  // État pour le dialogue d'implémentation
  const [implementDialog, setImplementDialog] = useState(false);
  const [implementationNote, setImplementationNote] = useState('');
  
  // Chargement initial des données
  useEffect(() => {
    Promise.all([
      fetchEvolutions(),
      fetchFunctions(),
      fetchStatusOptions(),
      fetchPriorityOptions()
    ]).then(() => setLoading(false));
  }, []);
  
  // Effet pour appliquer les filtres
  useEffect(() => {
    applyFilters();
  }, [evolutions, searchQuery, functionFilter, statusFilter, priorityFilter, order, orderBy]);
  
  // Fonction pour récupérer toutes les évolutions
  const fetchEvolutions = async () => {
    try {
      const response = await api.automata.evolutions.getAll();
      setEvolutions(response);
      return response;
    } catch (error) {
      console.error('Erreur lors de la récupération des évolutions:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la récupération des évolutions',
        severity: 'error'
      });
      return [];
    }
  };
  
  // Fonction pour récupérer les fonctions
  const fetchFunctions = async () => {
    try {
      const response = await api.automata.functions.getAll();
      setFunctions(response);
      return response;
    } catch (error) {
      console.error('Erreur lors de la récupération des fonctions:', error);
      setFunctions([]);
      return [];
    }
  };
  
  // Fonction pour récupérer les statuts d'évolution
  const fetchStatusOptions = async () => {
    // Dans une application réelle, cela pourrait être un appel API
    // Pour l'exemple, nous utilisons des valeurs statiques basées sur l'enum EvolutionStatus
    const statuses = [
      { value: 'REQUESTED', label: 'Demandée' },
      { value: 'APPROVED', label: 'Approuvée' },
      { value: 'ASSIGNED', label: 'Assignée' },
      { value: 'IN_PROGRESS', label: 'En cours' },
      { value: 'IMPLEMENTED', label: 'Implémentée' },
      { value: 'TESTED', label: 'Testée' },
      { value: 'DEPLOYED', label: 'Déployée' },
      { value: 'REJECTED', label: 'Rejetée' }
    ];
    
    setStatusOptions(statuses);
    return statuses;
  };
  
  // Fonction pour récupérer les priorités
  const fetchPriorityOptions = async () => {
    // Pour l'exemple, nous utilisons des valeurs statiques basées sur l'enum EvolutionPriority
    const priorities = [
      { value: 'CRITICAL', label: 'Critique' },
      { value: 'HIGH', label: 'Élevée' },
      { value: 'MEDIUM', label: 'Moyenne' },
      { value: 'LOW', label: 'Faible' }
    ];
    
    setPriorityOptions(priorities);
    return priorities;
  };
  
  // Fonction pour appliquer les filtres
  const applyFilters = () => {
    if (!evolutions) return;
    
    let filtered = [...evolutions];
    
    // Filtrer par fonction
    if (functionFilter !== 'all') {
      filtered = filtered.filter(
        evolution => evolution.function && evolution.function.id === functionFilter
      );
    }
    
    // Filtrer par statut
    if (statusFilter !== 'all') {
      filtered = filtered.filter(evolution => evolution.status === statusFilter);
    }
    
    // Filtrer par priorité
    if (priorityFilter !== 'all') {
      filtered = filtered.filter(evolution => evolution.priority === priorityFilter);
    }
    
    // Recherche textuelle
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        evolution =>
          (evolution.title && evolution.title.toLowerCase().includes(lowerQuery)) ||
          (evolution.description && evolution.description.toLowerCase().includes(lowerQuery)) ||
          (evolution.implementation && evolution.implementation.toLowerCase().includes(lowerQuery))
      );
    }
    
    // Appliquer le tri
    filtered = stableSort(filtered, getComparator(order, orderBy));
    
    setFilteredEvolutions(filtered);
  };
  
  // Fonctions de tri
  const descendingComparator = (a, b, orderBy) => {
    if (!a[orderBy] || !b[orderBy]) return 0;
    
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  
  const stableSort = (array, comparator) => {
    if (!array) return [];
    
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  // Formatage de date relative sans date-fns
  const formatRelativeDate = (date) => {
    if (!date) return 'N/A';
    
    try {
      const now = new Date();
      const dateObj = new Date(date);
      
      // Vérifier si la date est valide
      if (isNaN(dateObj.getTime())) {
        return 'Date invalide';
      }
      
      const diffInSeconds = Math.floor((now - dateObj) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);
      const diffInMonths = Math.floor(diffInDays / 30);
      const diffInYears = Math.floor(diffInDays / 365);
      
      if (diffInYears > 0) {
        return `Il y a ${diffInYears} an${diffInYears > 1 ? 's' : ''}`;
      } else if (diffInMonths > 0) {
        return `Il y a ${diffInMonths} mois`;
      } else if (diffInDays > 7) {
        return dateObj.toLocaleDateString('fr-FR');
      } else if (diffInDays > 0) {
        return `Il y a ${diffInDays} jour${diffInDays > 1 ? 's' : ''}`;
      } else if (diffInHours > 0) {
        return `Il y a ${diffInHours} heure${diffInHours > 1 ? 's' : ''}`;
      } else if (diffInMinutes > 0) {
        return `Il y a ${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
      } else if (diffInSeconds > 30) {
        return `Il y a ${diffInSeconds} seconde${diffInSeconds > 1 ? 's' : ''}`;
      } else {
        return 'À l\'instant';
      }
    } catch (e) {
      console.error('Erreur de formatage de date:', e);
      return 'Date invalide';
    }
  };
  
  // Fonctions pour les dialogues
  const handleOpenCreateDialog = () => {
    setDialogMode('create');
    setDialogData({
      title: '',
      description: '',
      type: 'FEATURE',
      priority: 'MEDIUM',
      status: 'REQUESTED',
      function: { id: '' },
      implementation: ''
    });
    setOpenDialog(true);
  };
  
  const handleOpenEditDialog = (evolution) => {
    setDialogMode('edit');
    setDialogData({
      id: evolution.id,
      title: evolution.title || '',
      description: evolution.description || '',
      type: evolution.type || 'FEATURE',
      priority: evolution.priority || 'MEDIUM',
      status: evolution.status || 'REQUESTED',
      function: { id: evolution.function?.id || '' },
      implementation: evolution.implementation || ''
    });
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAssignDialog(false);
    setApproveDialog(false);
    setDeleteDialog(false);
    setImplementDialog(false);
  };
  
  // Fonction pour gérer les changements dans le formulaire
  const handleDialogChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'functionId') {
      setDialogData({ ...dialogData, function: { id: value } });
    } else {
      setDialogData({ ...dialogData, [name]: value });
    }
  };
  
  // Fonction pour créer une évolution
  const handleCreateEvolution = async () => {
    try {
      await api.automata.evolutions.create(dialogData);
      setSnackbar({
        open: true,
        message: 'Évolution créée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchEvolutions();
    } catch (error) {
      console.error('Erreur lors de la création de l\'évolution:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la création de l\'évolution',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour mettre à jour une évolution
  const handleUpdateEvolution = async () => {
    try {
      await api.automata.evolutions.update(dialogData.id, dialogData);
      setSnackbar({
        open: true,
        message: 'Évolution mise à jour avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchEvolutions();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'évolution:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la mise à jour de l\'évolution',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour supprimer une évolution
  const handleDeleteEvolution = async () => {
    if (!selectedEvolution) return;
    
    try {
      await api.automata.evolutions.delete(selectedEvolution.id);
      setSnackbar({
        open: true,
        message: 'Évolution supprimée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchEvolutions();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'évolution:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la suppression de l\'évolution',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour assigner une évolution
  const handleAssignEvolution = async () => {
    if (!selectedEvolution || !assignUserId) return;
    
    try {
      await api.automata.evolutions.assign(selectedEvolution.id, assignUserId);
      setSnackbar({
        open: true,
        message: 'Évolution assignée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchEvolutions();
    } catch (error) {
      console.error('Erreur lors de l\'assignation de l\'évolution:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de l\'assignation de l\'évolution',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour approuver une évolution
  const handleApproveEvolution = async () => {
    if (!selectedEvolution) return;
    
    try {
      await api.automata.evolutions.approve(selectedEvolution.id);
      setSnackbar({
        open: true,
        message: 'Évolution approuvée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchEvolutions();
    } catch (error) {
      console.error('Erreur lors de l\'approbation de l\'évolution:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de l\'approbation de l\'évolution',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour mettre à jour le statut d'une évolution
  const handleUpdateStatus = async (id, status) => {
    try {
      await api.automata.evolutions.updateStatus(id, status);
      setSnackbar({
        open: true,
        message: 'Statut mis à jour avec succès',
        severity: 'success'
      });
      fetchEvolutions();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la mise à jour du statut',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour marquer une évolution comme implémentée
  const handleImplementEvolution = async () => {
    if (!selectedEvolution) return;
    
    try {
      // Mettre à jour le statut et ajouter une note d'implémentation
      await api.automata.evolutions.update(selectedEvolution.id, {
        status: 'IMPLEMENTED',
        implementation: implementationNote
      });
      
      setSnackbar({
        open: true,
        message: 'Évolution marquée comme implémentée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchEvolutions();
    } catch (error) {
      console.error('Erreur lors du marquage de l\'évolution comme implémentée:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du marquage de l\'évolution comme implémentée',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour ouvrir le tiroir de détails
  const handleOpenDetails = (evolution) => {
    setSelectedEvolution(evolution);
    setDetailsDrawerOpen(true);
  };
  
  // Fonction pour ouvrir le dialogue d'assignation
  const handleOpenAssignDialog = (evolution) => {
    setSelectedEvolution(evolution);
    setAssignUserId('');
    setAssignDialog(true);
  };
  
  // Fonction pour ouvrir le dialogue d'approbation
  const handleOpenApproveDialog = (evolution) => {
    setSelectedEvolution(evolution);
    setApproveDialog(true);
  };
  
  // Fonction pour ouvrir le dialogue de suppression
  const handleOpenDeleteDialog = (evolution) => {
    setSelectedEvolution(evolution);
    setDeleteDialog(true);
  };
  
  // Fonction pour ouvrir le dialogue d'implémentation
  const handleOpenImplementDialog = (evolution) => {
    setSelectedEvolution(evolution);
    setImplementationNote(evolution.implementation || '');
    setImplementDialog(true);
  };
  
  // Fonction pour obtenir la couleur de la priorité
  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'CRITICAL':
        return 'error';
      case 'HIGH':
        return 'warning';
      case 'MEDIUM':
        return 'info';
      case 'LOW':
        return 'success';
      default:
        return 'default';
    }
  };
  
  // Fonction pour obtenir la couleur du statut
  const getStatusColor = (status) => {
    switch (status) {
      case 'REQUESTED':
        return 'info';
      case 'APPROVED':
        return 'success';
      case 'ASSIGNED':
        return 'secondary';
      case 'IN_PROGRESS':
        return 'warning';
      case 'IMPLEMENTED':
        return 'success';
      case 'TESTED':
        return 'success';
      case 'DEPLOYED':
        return 'success';
      case 'REJECTED':
        return 'error';
      default:
        return 'default';
    }
  };
  
  // Fonction pour obtenir l'icône du statut
  const getStatusIcon = (status) => {
    switch (status) {
      case 'REQUESTED':
        return <Send size={16} />;
      case 'APPROVED':
        return <ThumbsUp size={16} />;
      case 'ASSIGNED':
        return <User size={16} />;
      case 'IN_PROGRESS':
        return <Activity size={16} />;
      case 'IMPLEMENTED':
        return <Check size={16} />;
      case 'TESTED':
        return <CheckCircle size={16} />;
      case 'DEPLOYED':
        return <Star size={16} />;
      case 'REJECTED':
        return <X size={16} />;
      default:
        return null;
    }
  };
  
  // Fonction pour obtenir le libellé de type d'évolution
  const getTypeLabel = (type) => {
    switch (type) {
      case 'FEATURE':
        return 'Nouvelle fonctionnalité';
      case 'ENHANCEMENT':
        return 'Amélioration';
      case 'OPTIMIZATION':
        return 'Optimisation';
      case 'REFACTORING':
        return 'Refactoring';
      default:
        return type;
    }
  };
  
  // Rendu du tableau des évolutions
  const renderEvolutionsTable = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (!filteredEvolutions || filteredEvolutions.length === 0) {
      return (
        <Paper sx={{ p: 4, textAlign: 'center', mt: 2 }}>
          <Typography variant="body1" color="textSecondary">
            Aucune évolution ne correspond aux critères de recherche. Ajustez vos filtres ou créez une nouvelle évolution.
          </Typography>
        </Paper>
      );
    }
    
    return (
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={orderBy === 'title' ? order : 'asc'}
                  onClick={() => handleRequestSort('title')}
                >
                  Titre
                </TableSortLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Fonction</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'priority'}
                  direction={orderBy === 'priority' ? order : 'asc'}
                  onClick={() => handleRequestSort('priority')}
                >
                  Priorité
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => handleRequestSort('status')}
                >
                  Statut
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'requestDate'}
                  direction={orderBy === 'requestDate' ? order : 'asc'}
                  onClick={() => handleRequestSort('requestDate')}
                >
                  Demandée
                </TableSortLabel>
              </TableCell>
              <TableCell>Assignée à</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEvolutions.map((evolution) => (
              <TableRow
                key={evolution.id}
                hover
                onClick={() => handleOpenDetails(evolution)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{evolution.id}</TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight="medium">
                    {evolution.title}
                  </Typography>
                  {evolution.description && (
                    <Typography variant="caption" color="textSecondary" noWrap sx={{ display: 'block', maxWidth: 250 }}>
                      {evolution.description.substring(0, 50)}
                      {evolution.description.length > 50 ? '...' : ''}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={getTypeLabel(evolution.type)}
                    color="primary"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  {evolution.function ? (
                    <Tooltip title={evolution.function.functionDescription || ''}>
                      <Typography variant="body2" noWrap sx={{ maxWidth: 150 }}>
                        {evolution.function.functionName}
                      </Typography>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={evolution.priority}
                    color={getPriorityColor(evolution.priority)}
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    icon={getStatusIcon(evolution.status)}
                    label={statusOptions.find(s => s.value === evolution.status)?.label || evolution.status}
                    color={getStatusColor(evolution.status)}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title={evolution.requestDate ? new Date(evolution.requestDate).toLocaleString() : 'N/A'}>
                    <Typography variant="body2">
                      {formatRelativeDate(evolution.requestDate)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {evolution.assignedTo || '-'}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenEditDialog(evolution);
                      }}
                    >
                      <Edit2 size={18} />
                    </IconButton>
                    
                    {evolution.status === 'REQUESTED' && (
                      <IconButton
                        size="small"
                        color="success"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenApproveDialog(evolution);
                        }}
                      >
                        <ThumbsUp size={18} />
                      </IconButton>
                    )}
                    
                    {(evolution.status === 'APPROVED' || evolution.status === 'REQUESTED') && (
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenAssignDialog(evolution);
                        }}
                      >
                        <User size={18} />
                      </IconButton>
                    )}
                    
                    {evolution.status === 'IN_PROGRESS' && (
                      <IconButton
                        size="small"
                        color="warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenImplementDialog(evolution);
                        }}
                      >
                        <Check size={18} />
                      </IconButton>
                    )}
                    
                    <IconButton
                      size="small"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDeleteDialog(evolution);
                      }}
                    >
                      <Trash2 size={18} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  // Rendu de la page
  return (
    <Box sx={{ p: 0, height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils principale */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Activity size={24} />
          Gestion des Évolutions
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={handleOpenCreateDialog}
          >
            Nouvelle Évolution
          </Button>
          
          <IconButton 
            color="primary" 
            onClick={fetchEvolutions}
            title="Rafraîchir"
          >
            <RefreshCw />
          </IconButton>
        </Box>
      </Paper>
      
      {/* Section des filtres */}
      <Paper sx={{ p: 2, mt: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              fullWidth
              variant="outlined"
              label="Rechercher"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                )
              }}
              size="small"
            />
          </Grid>
          
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Fonction</InputLabel>
              <Select
                value={functionFilter}
                onChange={(e) => setFunctionFilter(e.target.value)}
                label="Fonction"
              >
                <MenuItem value="all">Toutes les fonctions</MenuItem>
                {functions.map((func) => (
                  <MenuItem key={func.id} value={func.id}>
                    {func.functionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Statut</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Statut"
              >
                <MenuItem value="all">Tous les statuts</MenuItem>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Priorité</InputLabel>
              <Select
                value={priorityFilter}
                onChange={(e) => setPriorityFilter(e.target.value)}
                label="Priorité"
              >
                <MenuItem value="all">Toutes les priorités</MenuItem>
                {priorityOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Tableau des évolutions */}
      {renderEvolutionsTable()}
      
      {/* Dialogue de création/édition */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {dialogMode === 'create' ? 'Créer une nouvelle évolution' : 'Modifier l\'évolution'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Titre"
                name="title"
                value={dialogData.title}
                onChange={handleDialogChange}
                required
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={dialogData.description}
                onChange={handleDialogChange}
                multiline
                rows={4}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Fonction</InputLabel>
                <Select
                  name="functionId"
                  value={dialogData.function.id}
                  onChange={handleDialogChange}
                  label="Fonction"
                >
                  {functions.map((func) => (
                    <MenuItem key={func.id} value={func.id}>
                      {func.functionName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  value={dialogData.type}
                  onChange={handleDialogChange}
                  label="Type"
                >
                  <MenuItem value="FEATURE">Nouvelle fonctionnalité</MenuItem>
                  <MenuItem value="ENHANCEMENT">Amélioration</MenuItem>
                  <MenuItem value="OPTIMIZATION">Optimisation</MenuItem>
                  <MenuItem value="REFACTORING">Refactoring</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Priorité</InputLabel>
                <Select
                  name="priority"
                  value={dialogData.priority}
                  onChange={handleDialogChange}
                  label="Priorité"
                >
                  <MenuItem value="CRITICAL">Critique</MenuItem>
                  <MenuItem value="HIGH">Élevée</MenuItem>
                  <MenuItem value="MEDIUM">Moyenne</MenuItem>
                  <MenuItem value="LOW">Faible</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Statut</InputLabel>
                <Select
                  name="status"
                  value={dialogData.status}
                  onChange={handleDialogChange}
                  label="Statut"
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            {dialogMode === 'edit' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Notes d'implémentation"
                  name="implementation"
                  value={dialogData.implementation}
                  onChange={handleDialogChange}
                  multiline
                  rows={3}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={dialogMode === 'create' ? handleCreateEvolution : handleUpdateEvolution}
            disabled={!dialogData.title || !dialogData.function.id}
          >
            {dialogMode === 'create' ? 'Créer' : 'Enregistrer'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'assignation */}
      <Dialog
        open={assignDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Assigner l'évolution</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Assigner l'évolution "{selectedEvolution?.title}" à un utilisateur.
          </Typography>
          <TextField
            fullWidth
            label="ID de l'utilisateur"
            value={assignUserId}
            onChange={(e) => setAssignUserId(e.target.value)}
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssignEvolution}
            disabled={!assignUserId}
          >
            Assigner
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'approbation */}
      <Dialog
        open={approveDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Approuver l'évolution</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir approuver l'évolution "{selectedEvolution?.title}" ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleApproveEvolution}
          >
            Approuver
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de suppression */}
      <Dialog
        open={deleteDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer l'évolution "{selectedEvolution?.title}" ?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Cette action est irréversible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteEvolution}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'implémentation */}
      <Dialog
        open={implementDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Marquer comme implémentée</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Marquer l'évolution "{selectedEvolution?.title}" comme implémentée.
          </Typography>
          <TextField
            fullWidth
            label="Notes d'implémentation"
            value={implementationNote}
            onChange={(e) => setImplementationNote(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleImplementEvolution}
            disabled={!implementationNote}
          >
            Marquer comme implémentée
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Tiroir de détails */}
      <Drawer
        anchor="right"
        open={detailsDrawerOpen}
        onClose={() => setDetailsDrawerOpen(false)}
        sx={{
          width: 450,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 450,
          },
        }}
      >
        {selectedEvolution && (
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5">Détails de l'évolution</Typography>
              <IconButton onClick={() => setDetailsDrawerOpen(false)}>
                <X size={20} />
              </IconButton>
            </Box>
            
            <Chip
              label={statusOptions.find(s => s.value === selectedEvolution.status)?.label || selectedEvolution.status}
              color={getStatusColor(selectedEvolution.status)}
              icon={getStatusIcon(selectedEvolution.status)}
              sx={{ mb: 2 }}
            />
            
            <Typography variant="h6" gutterBottom>{selectedEvolution.title}</Typography>
            
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                {selectedEvolution.description}
              </Typography>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Informations</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">ID</Typography>
                <Typography variant="body2">{selectedEvolution.id}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Type</Typography>
                <Typography variant="body2">{getTypeLabel(selectedEvolution.type)}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Priorité</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip
                    size="small"
                    label={selectedEvolution.priority}
                    color={getPriorityColor(selectedEvolution.priority)}
                  />
                </Box>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Demandée le</Typography>
                <Typography variant="body2">
                  {selectedEvolution.requestDate
                    ? new Date(selectedEvolution.requestDate).toLocaleDateString()
                    : 'N/A'}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Statut</Typography>
                <Typography variant="body2">
                  {statusOptions.find(s => s.value === selectedEvolution.status)?.label || selectedEvolution.status}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Implémentée le</Typography>
                <Typography variant="body2">
                  {selectedEvolution.implementationDate
                    ? new Date(selectedEvolution.implementationDate).toLocaleDateString()
                    : 'Non implémentée'}
                </Typography>
              </Grid>
            </Grid>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Fonction associée</Typography>
            
            {selectedEvolution.function ? (
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6">{selectedEvolution.function.functionName}</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                    {selectedEvolution.function.functionDescription || 'Aucune description'}
                  </Typography>
                  
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="textSecondary">Type</Typography>
                      <Typography variant="body2">{selectedEvolution.function.functionType || '-'}</Typography>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Typography variant="caption" color="textSecondary">Programme</Typography>
                      <Typography variant="body2">{selectedEvolution.function.functionProgram || '-'}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Aucune fonction associée
              </Typography>
            )}
            
            {selectedEvolution.implementation && (
              <>
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="subtitle1" gutterBottom>Notes d'implémentation</Typography>
                
                <Paper variant="outlined" sx={{ p: 2, backgroundColor: 'background.default' }}>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                    {selectedEvolution.implementation}
                  </Typography>
                </Paper>
              </>
            )}
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Assignation</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Demandée par</Typography>
                <Typography variant="body2">{selectedEvolution.requestedBy || '-'}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Assignée à</Typography>
                <Typography variant="body2">{selectedEvolution.assignedTo || 'Non assignée'}</Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">Implémentée par</Typography>
                <Typography variant="body2">{selectedEvolution.implementedBy || '-'}</Typography>
              </Grid>
            </Grid>
            
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                startIcon={<Edit2 />}
                onClick={() => {
                  setDetailsDrawerOpen(false);
                  handleOpenEditDialog(selectedEvolution);
                }}
              >
                Modifier
              </Button>
              
              {selectedEvolution.status === 'REQUESTED' && (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<ThumbsUp />}
                  onClick={() => {
                    setDetailsDrawerOpen(false);
                    handleOpenApproveDialog(selectedEvolution);
                  }}
                >
                  Approuver
                </Button>
              )}
              
              {selectedEvolution.status === 'APPROVED' && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<User />}
                  onClick={() => {
                    setDetailsDrawerOpen(false);
                    handleOpenAssignDialog(selectedEvolution);
                  }}
                >
                  Assigner
                </Button>
              )}
              
              {selectedEvolution.status === 'IN_PROGRESS' && (
                <Button
                  variant="contained"
                  color="warning"
                  startIcon={<Check />}
                  onClick={() => {
                    setDetailsDrawerOpen(false);
                    handleOpenImplementDialog(selectedEvolution);
                  }}
                >
                  Marquer comme implémentée
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Drawer>
      
      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EvolutionsPage;