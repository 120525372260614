import React, { useState, useEffect } from 'react';
import { 
  Database, 
  Plus, 
  Edit, 
  Trash2, 
  Play, 
  Pause, 
  Copy, 
  RefreshCw, 
  CheckCircle, 
  AlertTriangle,
  X,
  Save
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const Environments = () => {
  // États
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [copySource, setCopySource] = useState('');
  const [copyTarget, setCopyTarget] = useState('');
  
  // Formulaire environnement
  const [formValues, setFormValues] = useState({
    code: '',
    name: '',
    description: '',
    type: 'DEVELOPMENT',
    url: '',
    priority: 0,
    isActive: true,
    lastModifiedBy: 'currentUser' // À remplacer par l'utilisateur connecté
  });
  
  // Types d'environnements
  const environmentTypes = [
    { value: 'DEVELOPMENT', label: 'Développement' },
    { value: 'TESTING', label: 'Test' },
    { value: 'STAGING', label: 'Préproduction' },
    { value: 'PRODUCTION', label: 'Production' },
    { value: 'DR', label: 'Disaster Recovery' }
  ];
  
  // Chargement des données
  const fetchEnvironments = async () => {
    setLoading(true);
    try {
      const data = await apiService.config.environments.getAll();
      setEnvironments(data || []);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des environnements:', err);
      setError('Impossible de charger les environnements. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchEnvironments();
  }, []);
  
  // Gestionnaires d'événements
  const handleOpenModal = (mode, environment = null) => {
    setModalMode(mode);
    if (environment) {
      setSelectedEnvironment(environment);
      setFormValues({
        code: environment.code,
        name: environment.name,
        description: environment.description || '',
        type: environment.type,
        url: environment.url || '',
        priority: environment.priority || 0,
        isActive: environment.isActive,
        lastModifiedBy: 'currentUser' // À remplacer par l'utilisateur connecté
      });
    } else {
      setSelectedEnvironment(null);
      setFormValues({
        code: '',
        name: '',
        description: '',
        type: 'DEVELOPMENT',
        url: '',
        priority: 0,
        isActive: true,
        lastModifiedBy: 'currentUser' // À remplacer par l'utilisateur connecté
      });
    }
    setShowModal(true);
  };
  
  const handleOpenCopyModal = () => {
    setCopySource('');
    setCopyTarget('');
    setShowCopyModal(true);
  };
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (modalMode === 'create') {
        await apiService.config.environments.create(formValues);
        setSuccessMessage('Environnement créé avec succès');
      } else {
        await apiService.config.environments.update(selectedEnvironment.code, formValues);
        setSuccessMessage('Environnement mis à jour avec succès');
      }
      
      setShowModal(false);
      fetchEnvironments();
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleCopy = async () => {
    if (!copySource || !copyTarget) {
      setError('Veuillez sélectionner les environnements source et cible');
      return;
    }
    
    setLoading(true);
    try {
      await apiService.config.environments.copyConfiguration(copySource, copyTarget);
      setSuccessMessage('Configuration copiée avec succès');
      setShowCopyModal(false);
    } catch (err) {
      console.error('Erreur lors de la copie:', err);
      setError('Impossible de copier la configuration. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleActivate = async (code) => {
    setLoading(true);
    try {
      await apiService.config.environments.activate(code);
      setSuccessMessage('Environnement activé avec succès');
      fetchEnvironments();
    } catch (err) {
      console.error('Erreur lors de l\'activation:', err);
      setError('Impossible d\'activer l\'environnement. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeactivate = async (code) => {
    setLoading(true);
    try {
      await apiService.config.environments.deactivate(code);
      setSuccessMessage('Environnement désactivé avec succès');
      fetchEnvironments();
    } catch (err) {
      console.error('Erreur lors de la désactivation:', err);
      setError('Impossible de désactiver l\'environnement. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDelete = async (code) => {
    if (!window.confirm(`Êtes-vous sûr de vouloir supprimer l'environnement ${code} ?`)) {
      return;
    }
    
    setLoading(true);
    try {
      await apiService.config.environments.delete(code);
      setSuccessMessage('Environnement supprimé avec succès');
      fetchEnvironments();
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError('Impossible de supprimer l\'environnement. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Configuration des colonnes
  const columns = [
    {
      key: 'code',
      header: 'Code',
      sortable: true,
      render: (row) => (
        <div className="font-medium text-gray-900 dark:text-white">
          {row.code}
        </div>
      ),
    },
    {
      key: 'name',
      header: 'Nom',
      sortable: true,
    },
    {
      key: 'type',
      header: 'Type',
      sortable: true,
      render: (row) => {
        const typeColors = {
          DEVELOPMENT: 'blue',
          TESTING: 'purple',
          STAGING: 'orange',
          PRODUCTION: 'red',
          DR: 'yellow'
        };
        
        return (
          <StatusBadge 
            status={typeColors[row.type] || 'gray'} 
            customLabel={row.type}
          />
        );
      },
    },
    {
      key: 'isActive',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={row.isActive ? 'active' : 'inactive'} 
          customLabel={row.isActive ? 'Actif' : 'Inactif'}
        />
      ),
    },
    {
      key: 'priority',
      header: 'Priorité',
      sortable: true,
    },
    {
      key: 'updatedAt',
      header: 'Dernière mise à jour',
      sortable: true,
      render: (row) => <DateFormatter date={row.updatedAt} format="full" />,
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          {!row.isActive ? (
            <Button
              variant="outline"
              size="sm"
              icon={Play}
              title="Activer"
              onClick={() => handleActivate(row.code)}
            />
          ) : (
            <Button
              variant="outline"
              size="sm"
              icon={Pause}
              title="Désactiver"
              onClick={() => handleDeactivate(row.code)}
            />
          )}
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            title="Modifier"
            onClick={() => handleOpenModal('edit', row)}
          />
          <Button
            variant="outline"
            size="sm"
            icon={Trash2}
            title="Supprimer"
            onClick={() => handleDelete(row.code)}
          />
        </div>
      ),
    },
  ];
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Database className="mr-2 h-6 w-6 text-blue-500" />
            Gestion des environnements
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les environnements et leurs configurations associées
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={Copy}
            onClick={handleOpenCopyModal}
          >
            Copier configuration
          </Button>
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchEnvironments}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={Plus}
            onClick={() => handleOpenModal('create')}
          >
            Nouvel environnement
          </Button>
        </div>
      </div>
      
      {/* Messages */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 mb-4">
          <div className="flex">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <span className="text-green-700 dark:text-green-300">{successMessage}</span>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 mb-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-red-700 dark:text-red-300">{error}</span>
          </div>
        </div>
      )}
      
      {/* Liste des environnements */}
      <Card>
        <DataGrid
          data={environments}
          columns={columns}
          loading={loading}
          emptyMessage="Aucun environnement configuré"
        />
      </Card>
      
      {/* Modal de création/édition */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                {modalMode === 'create' ? 'Nouvel environnement' : 'Modifier l\'environnement'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmit} className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Code - ne peut pas être modifié en mode édition */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Code *
                  </label>
                  <input
                    type="text"
                    name="code"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.code}
                    onChange={handleInputChange}
                    required
                    disabled={modalMode === 'edit'}
                  />
                </div>
                
                {/* Nom */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom *
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                {/* Type */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Type *
                  </label>
                  <select
                    name="type"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.type}
                    onChange={handleInputChange}
                    required
                  >
                    {environmentTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>
                
                {/* URL */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    URL
                  </label>
                  <input
                    type="text"
                    name="url"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.url}
                    onChange={handleInputChange}
                  />
                </div>
                
                {/* Priorité */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Priorité
                  </label>
                  <input
                    type="number"
                    name="priority"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.priority}
                    onChange={handleInputChange}
                  />
                </div>
                
                {/* Description - largeur complète */}
                <div className="col-span-1 md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    rows="3"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.description}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                
                {/* Statut */}
                <div className="col-span-1 md:col-span-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="isActive"
                      id="isActive"
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                      checked={formValues.isActive}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900 dark:text-white">
                      Environnement actif
                    </label>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  icon={Save}
                  loading={loading}
                >
                  {modalMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
      
      {/* Modal de copie de configuration */}
      {showCopyModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Copier la configuration
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowCopyModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="p-6">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Environnement source *
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={copySource}
                    onChange={(e) => setCopySource(e.target.value)}
                    required
                  >
                    <option value="">Sélectionner un environnement</option>
                    {environments.map((env) => (
                      <option key={env.code} value={env.code}>
                        {env.name} ({env.code})
                      </option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Environnement cible *
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={copyTarget}
                    onChange={(e) => setCopyTarget(e.target.value)}
                    required
                  >
                    <option value="">Sélectionner un environnement</option>
                    {environments.map((env) => (
                      <option key={env.code} value={env.code} disabled={env.code === copySource}>
                        {env.name} ({env.code})
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-md">
                  <div className="flex">
                    <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2 flex-shrink-0" />
                    <div className="text-sm text-yellow-700 dark:text-yellow-300">
                      Cette action remplacera les configurations existantes de l'environnement cible par celles de l'environnement source.
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowCopyModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  icon={Copy}
                  onClick={handleCopy}
                  loading={loading}
                >
                  Copier
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Environments;