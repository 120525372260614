import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  InputAdornment,
  LinearProgress,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  Slider,
  Badge,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from '@mui/material';

import {
  Smartphone,
  Package,
  Play,
  StopCircle,
  Rotate3d,
  RefreshCw,
  Plus,
  Search,
  X,
  ZoomIn,
  ZoomOut,
  Trash2,
  Copy,
  CheckCircle,
  AlertTriangle,
  Info,
  Settings,
  ChevronDown,
  ChevronRight,
  Clock,
  Save,
  Edit,
  Download,
  Upload,
  Image,
  Database,
  FileText,
  Layers,
  WifiOff,
  Terminal,
  Send,
  TouchpadOff,
  RotateCw,
  Battery,
  BatteryCharging,
  BatteryLow,
  Navigation,
  Compass,
  Volume2,
  VolumeX,
  Bluetooth,
  BluetoothOff,
  Map,
  Home,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  BellRing,
  Mail,
  PhoneCall,
  PhoneOff,
  CameraOff,
  Camera,
  Lock,
  Unlock,
  Fingerprint,
  Mic,
  Radio,
  Tv,
  Wifi,
  Cast,
  BarChart,
  MessageSquare,
  MousePointer,
  Hand,
  PlayCircle,
  FilePlus,
  AlertOctagon,
  Monitor,
  Type,
  Layout 
} from 'lucide-react';

/**
 * AndroidAppCapture Component - Interface réutilisable pour interagir avec des applications Android
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles de l'application
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const AndroidAppCapture = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États pour le dispositif
  const [deviceInfo, setDeviceInfo] = useState({
    model: 'Unknown',
    manufacturer: 'Unknown',
    androidVersion: 'Unknown',
    apiLevel: 0,
    screenResolution: '1080x1920',
    orientation: 'portrait',
    batteryLevel: 100,
    batteryCharging: false,
    connected: false
  });
  
  // États pour les applications
  const [installedApps, setInstalledApps] = useState([]);
  const [currentApp, setCurrentApp] = useState(null);
  const [appInfo, setAppInfo] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [appPermissions, setAppPermissions] = useState([]);
  const [isAppRunning, setIsAppRunning] = useState(false);
  const [appProcessInfo, setAppProcessInfo] = useState(null);
  
  // États pour la visualisation
  const [screenshot, setScreenshot] = useState(null);
  const [screenshotHistory, setScreenshotHistory] = useState([]);
  const [viewHierarchy, setViewHierarchy] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [screenOrientation, setScreenOrientation] = useState('portrait');
  const [highlightBounds, setHighlightBounds] = useState(false);
  
  // États pour l'enregistrement
  const [recordedActions, setRecordedActions] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [selectedActionType, setSelectedActionType] = useState('tap');
  const [elementSelector, setElementSelector] = useState('');
  const [actionInput, setActionInput] = useState('');
  const [waitTimeout, setWaitTimeout] = useState(5000);
  
  // États pour le système
  const [deviceLogs, setDeviceLogs] = useState([]);
  const [networkStatus, setNetworkStatus] = useState({
    wifiEnabled: true,
    wifiConnected: true,
    mobileDataEnabled: true,
    mobileDataConnected: true,
    airplaneMode: false,
    bluetoothEnabled: false
  });
  const [deviceSettings, setDeviceSettings] = useState({
    brightness: 80,
    volume: 50,
    gpsEnabled: true,
    animationScale: 1.0
  });
  
  // États pour les logs et monitoring
  const [logcatOutput, setLogcatOutput] = useState([]);
  const [logcatFilter, setLogcatFilter] = useState({ 
    tag: '', 
    priority: 'V', // Verbose, Debug, Info, Warning, Error, Fatal
    grep: '' 
  });
  const [performanceStats, setPerformanceStats] = useState({
    cpuUsage: 0,
    memoryUsage: 0,
    batteryUsage: 0,
    networkUsage: { rx: 0, tx: 0 }
  });
  const [refreshRate, setRefreshRate] = useState(5); // Secondes
  
  // États pour l'interface
  const [activeTab, setActiveTab] = useState(0);
  const [lastAction, setLastAction] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedAppPackage, setSelectedAppPackage] = useState('');
  const [appSearchQuery, setAppSearchQuery] = useState('');
  const [viewSearchQuery, setViewSearchQuery] = useState('');
  const [expandedSections, setExpandedSections] = useState({
    deviceInfo: true,
    appControl: true,
    elementInteraction: false,
    systemControls: false
  });
  const [isDeviceMenuOpen, setIsDeviceMenuOpen] = useState(false);
  const [deviceMenuAnchor, setDeviceMenuAnchor] = useState(null);
  const [connectionState, setConnectionState] = useState({
    connected: false,
    clientState: null
  });
  
  // Références
  const screenshotContainerRef = useRef(null);
  const logcatContainerRef = useRef(null);
  
  // Effet pour gérer les mises à jour de l'état de connexion
  useEffect(() => {
    setConnectionState(prev => ({
      ...prev,
      connected: connectionStatus,
      clientState: data?.technicalProperties?.clientState || null
    }));
  }, [connectionStatus, data?.technicalProperties?.clientState]);
  
  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les informations du dispositif
      if (data.technicalProperties.deviceInfo) {
        handleDeviceInfoResults(data.technicalProperties.deviceInfo);
      }
      
      // Traiter les informations des applications
      if (data.technicalProperties.appsList) {
        handleAppsListResults(data.technicalProperties.appsList);
      }
      
      // Traiter les informations de l'application actuelle
      if (data.technicalProperties.appInfo) {
        handleAppInfoResults(data.technicalProperties.appInfo);
      }
      
      // Traiter les captures d'écran
      if (data.technicalProperties.screenshot) {
        handleScreenshotResults(data.technicalProperties.screenshot);
      }
      
      // Traiter la hiérarchie des vues
      if (data.technicalProperties.viewHierarchy) {
        handleViewHierarchyResults(data.technicalProperties.viewHierarchy);
      }
      
      // Traiter les logs du système
      if (data.technicalProperties.logcat) {
        handleLogcatResults(data.technicalProperties.logcat);
      }
      
      // Traiter les statistiques de performances
      if (data.technicalProperties.performanceStats) {
        handlePerformanceResults(data.technicalProperties.performanceStats);
      }
      
      // Traiter le statut du réseau
      if (data.technicalProperties.networkStatus) {
        handleNetworkStatusResults(data.technicalProperties.networkStatus);
      }
      
      // Traiter les actions enregistrées
      if (data.technicalProperties.recordedAction) {
        handleRecordedActionResults(data.technicalProperties.recordedAction);
      }
    }
  }, [data]);
  
  // Effet pour faire défiler les logs
  useEffect(() => {
    if (logcatContainerRef.current) {
      logcatContainerRef.current.scrollTop = logcatContainerRef.current.scrollHeight;
    }
  }, [logcatOutput]);
  
  // Handler pour les résultats d'informations du dispositif
  const handleDeviceInfoResults = (deviceInfoData) => {
    setDeviceInfo({
      model: deviceInfoData.model || 'Unknown',
      manufacturer: deviceInfoData.manufacturer || 'Unknown',
      androidVersion: deviceInfoData.androidVersion || 'Unknown',
      apiLevel: deviceInfoData.apiLevel || 0,
      screenResolution: deviceInfoData.screenResolution || '1080x1920',
      orientation: deviceInfoData.orientation || 'portrait',
      batteryLevel: deviceInfoData.batteryLevel || 100,
      batteryCharging: deviceInfoData.batteryCharging || false,
      connected: true
    });
    
    setScreenOrientation(deviceInfoData.orientation || 'portrait');
  };
  
  // Handler pour les résultats de liste d'applications
  const handleAppsListResults = (appsListData) => {
    setInstalledApps(appsListData.apps || []);
  };
  
  // Handler pour les résultats d'informations d'application
  const handleAppInfoResults = (appInfoData) => {
    setAppInfo(appInfoData);
    setCurrentActivity(appInfoData.currentActivity);
    setAppPermissions(appInfoData.permissions || []);
    setIsAppRunning(appInfoData.isRunning || false);
    setAppProcessInfo(appInfoData.processInfo);
    
    if (appInfoData.package) {
      setCurrentApp(appInfoData.package);
    }
  };
  
  // Handler pour les résultats de capture d'écran
  const handleScreenshotResults = (screenshotData) => {
    if (screenshotData.base64Data) {
      const newScreenshot = {
        id: Date.now(),
        data: screenshotData.base64Data,
        timestamp: new Date(),
        appPackage: currentApp,
        activity: currentActivity
      };
      
      setScreenshot(newScreenshot);
      setScreenshotHistory(prev => [newScreenshot, ...prev]);
    }
  };
  
  // Handler pour les résultats de hiérarchie des vues
  const handleViewHierarchyResults = (viewHierarchyData) => {
    setViewHierarchy(viewHierarchyData);
  };
  
  // Handler pour les résultats de logcat
  const handleLogcatResults = (logcatData) => {
    const newLogs = logcatData.logs.map(log => ({
      timestamp: new Date(log.timestamp || Date.now()),
      priority: log.priority || 'I',
      tag: log.tag || 'System',
      message: log.message || '',
      pid: log.pid,
      tid: log.tid
    }));
    
    setLogcatOutput(prev => [...prev, ...newLogs]);
  };
  
  // Handler pour les résultats de performances
  const handlePerformanceResults = (performanceData) => {
    setPerformanceStats({
      cpuUsage: performanceData.cpuUsage || 0,
      memoryUsage: performanceData.memoryUsage || 0,
      batteryUsage: performanceData.batteryUsage || 0,
      networkUsage: performanceData.networkUsage || { rx: 0, tx: 0 }
    });
  };
  
  // Handler pour les résultats de statut réseau
  const handleNetworkStatusResults = (networkStatusData) => {
    setNetworkStatus({
      wifiEnabled: networkStatusData.wifiEnabled || false,
      wifiConnected: networkStatusData.wifiConnected || false,
      mobileDataEnabled: networkStatusData.mobileDataEnabled || false,
      mobileDataConnected: networkStatusData.mobileDataConnected || false,
      airplaneMode: networkStatusData.airplaneMode || false,
      bluetoothEnabled: networkStatusData.bluetoothEnabled || false
    });
  };
  
  // Handler pour les résultats d'action enregistrée
  const handleRecordedActionResults = (actionData) => {
    if (isRecording) {
      addRecordedAction(actionData);
    }
  };
  
  // Fonction pour démarrer une application
  const launchApp = (packageName) => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidApp",
        nodeName: "LaunchApp",
        nodeValue: packageName,
        actionType: "Android",
        actionValue: `am start -n ${packageName}/.MainActivity`,
        actionName: "LaunchApp",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'app',
        action: 'launch',
        value: packageName,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidApp',
      name: `Launch app: ${packageName}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    setSelectedAppPackage(packageName);
    setCurrentApp(packageName);
    setIsAppRunning(true);
  };
  
  // Fonction pour arrêter une application
  const stopApp = (packageName = currentApp) => {
    if (disabled || !connectionState.connected || !packageName) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidApp",
        nodeName: "StopApp",
        nodeValue: packageName,
        actionType: "Android",
        actionValue: `am force-stop ${packageName}`,
        actionName: "StopApp",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'app',
        action: 'stop',
        value: packageName,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidApp',
      name: `Stop app: ${packageName}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    setIsAppRunning(false);
  };
  
  // Fonction pour effacer les données d'une application
  const clearAppData = (packageName = currentApp) => {
    if (disabled || !connectionState.connected || !packageName) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidApp",
        nodeName: "ClearAppData",
        nodeValue: packageName,
        actionType: "Android",
        actionValue: `pm clear ${packageName}`,
        actionName: "ClearAppData",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'app',
        action: 'clearData',
        value: packageName,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidApp',
      name: `Clear app data: ${packageName}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour obtenir les informations d'une application
  const getAppInfo = (packageName = currentApp) => {
    if (disabled || !connectionState.connected || !packageName) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidApp",
        nodeName: "GetAppInfo",
        nodeValue: packageName,
        actionType: "Android",
        actionValue: `dumpsys package ${packageName}`,
        actionName: "GetAppInfo",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'AndroidApp',
      name: `Get app info: ${packageName}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour capture d'écran
  const takeScreenshot = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidCapture",
        nodeName: "TakeScreenshot",
        nodeValue: "screenshot",
        actionType: "Android",
        actionValue: "screencap -p /sdcard/screenshot.png",
        actionName: "TakeScreenshot",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'capture',
        action: 'screenshot',
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidCapture',
      name: 'Take screenshot',
      timestamp: new Date()
    });
  };
  
  // Fonction pour obtenir la hiérarchie des vues
  const getViewHierarchy = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidUI",
        nodeName: "GetViewHierarchy",
        nodeValue: "uiautomator",
        actionType: "Android",
        actionValue: "uiautomator dump /sdcard/hierarchy.xml",
        actionName: "GetViewHierarchy",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'AndroidUI',
      name: 'Get view hierarchy',
      timestamp: new Date()
    });
  };
  
  // Fonction pour appuyer sur un élément
  const tapElement = (selector, x = null, y = null) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      x: x,
      y: y
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidUI",
        nodeName: "TapElement",
        nodeValue: JSON.stringify(payload),
        actionType: "Android",
        actionValue: x !== null && y !== null 
          ? `input tap ${x} ${y}` 
          : `uiautomator tap ${selector}`,
        actionName: "TapElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'tap',
        selector: selector,
        coordinates: x !== null && y !== null ? { x, y } : undefined,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidUI',
      name: `Tap element: ${selector}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour appui long sur un élément
  const longPressElement = (selector, duration = 1000, x = null, y = null) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      duration: duration,
      x: x,
      y: y
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidUI",
        nodeName: "LongPressElement",
        nodeValue: JSON.stringify(payload),
        actionType: "Android",
        actionValue: x !== null && y !== null 
          ? `input swipe ${x} ${y} ${x} ${y} ${duration}` 
          : `uiautomator longPress ${selector} ${duration}`,
        actionName: "LongPressElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'longPress',
        selector: selector,
        duration: duration,
        coordinates: x !== null && y !== null ? { x, y } : undefined,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidUI',
      name: `Long press element: ${selector} (${duration}ms)`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour saisir du texte
  const typeText = (selector, text) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      text: text
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidUI",
        nodeName: "TypeText",
        nodeValue: JSON.stringify(payload),
        actionType: "Android",
        actionValue: `uiautomator setText ${selector} "${text}"`,
        actionName: "TypeText",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'type',
        selector: selector,
        value: text,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidUI',
      name: `Type text in ${selector}: ${text.length > 20 ? text.substring(0, 20) + '...' : text}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour faire défiler (swipe)
  const swipeScreen = (direction, startX = null, startY = null, endX = null, endY = null, duration = 500) => {
    if (disabled || !connectionState.connected) return;
    
    // Coordonnées par défaut selon la direction
    let coords = {};
    
    if (startX === null || startY === null || endX === null || endY === null) {
      const screenWidth = parseInt(deviceInfo.screenResolution.split('x')[0]);
      const screenHeight = parseInt(deviceInfo.screenResolution.split('x')[1]);
      
      switch (direction) {
        case 'up':
          coords = {
            startX: screenWidth / 2,
            startY: screenHeight * 0.7,
            endX: screenWidth / 2,
            endY: screenHeight * 0.3
          };
          break;
        case 'down':
          coords = {
            startX: screenWidth / 2,
            startY: screenHeight * 0.3,
            endX: screenWidth / 2,
            endY: screenHeight * 0.7
          };
          break;
        case 'left':
          coords = {
            startX: screenWidth * 0.7,
            startY: screenHeight / 2,
            endX: screenWidth * 0.3,
            endY: screenHeight / 2
          };
          break;
        case 'right':
          coords = {
            startX: screenWidth * 0.3,
            startY: screenHeight / 2,
            endX: screenWidth * 0.7,
            endY: screenHeight / 2
          };
          break;
        default:
          coords = {
            startX: screenWidth / 2,
            startY: screenHeight / 2,
            endX: screenWidth / 2,
            endY: screenHeight / 2
          };
      }
    } else {
      coords = { startX, startY, endX, endY };
    }
    
    const payload = {
      direction,
      startX: coords.startX,
      startY: coords.startY,
      endX: coords.endX,
      endY: coords.endY,
      duration
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidUI",
        nodeName: "SwipeScreen",
        nodeValue: JSON.stringify(payload),
        actionType: "Android",
        actionValue: `input swipe ${Math.round(coords.startX)} ${Math.round(coords.startY)} ${Math.round(coords.endX)} ${Math.round(coords.endY)} ${duration}`,
        actionName: "SwipeScreen",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'swipe',
        direction: direction,
        coordinates: coords,
        duration: duration,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidUI',
      name: `Swipe ${direction}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour appuyer sur une touche système
  const pressKey = (keycode) => {
    if (disabled || !connectionState.connected) return;
    
    const keyMapping = {
      'home': 3,
      'back': 4,
      'menu': 82,
      'power': 26,
      'volume_up': 24,
      'volume_down': 25,
      'camera': 27,
      'search': 84,
      'enter': 66,
      'delete': 67,
      'recent_apps': 187
    };
    
    const keyValue = keyMapping[keycode] || keycode;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidUI",
        nodeName: "PressKey",
        nodeValue: keycode,
        actionType: "Android",
        actionValue: `input keyevent ${keyValue}`,
        actionName: "PressKey",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'pressKey',
        value: keycode,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidUI',
      name: `Press key: ${keycode}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour attendre un élément
  const waitForElement = (selector, timeout = 5000) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      timeout: timeout
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidWait",
        nodeName: "WaitForElement",
        nodeValue: JSON.stringify(payload),
        actionType: "Android",
        actionValue: `uiautomator waitForExists ${selector} ${timeout}`,
        actionName: "WaitForElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'wait',
        action: 'waitForElement',
        selector: selector,
        timeout: timeout,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidWait',
      name: `Wait for element: ${selector}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour modifier l'orientation de l'écran
  const rotateScreen = () => {
    if (disabled || !connectionState.connected) return;
    
    const newOrientation = screenOrientation === 'portrait' ? 'landscape' : 'portrait';
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidDevice",
        nodeName: "RotateScreen",
        nodeValue: newOrientation,
        actionType: "Android",
        actionValue: `content insert --uri content://settings/system --bind name:s:accelerometer_rotation --bind value:i:0 && content insert --uri content://settings/system --bind name:s:user_rotation --bind value:i:${newOrientation === 'landscape' ? 1 : 0}`,
        actionName: "RotateScreen",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'device',
        action: 'rotate',
        value: newOrientation,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidDevice',
      name: `Rotate screen to ${newOrientation}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    setScreenOrientation(newOrientation);
  };
  
  // Fonction pour changer un paramètre du système
  const changeSystemSetting = (setting, value) => {
    if (disabled || !connectionState.connected) return;
    
    let command = '';
    
    switch (setting) {
      case 'wifi':
        command = `svc wifi ${value ? 'enable' : 'disable'}`;
        break;
      case 'data':
        command = `svc data ${value ? 'enable' : 'disable'}`;
        break;
      case 'airplane':
        command = `settings put global airplane_mode_on ${value ? '1' : '0'}`;
        break;
      case 'bluetooth':
        command = `settings put global bluetooth_on ${value ? '1' : '0'}`;
        break;
      case 'gps':
        command = `settings put secure location_mode ${value ? '3' : '0'}`;
        break;
      case 'brightness':
        command = `settings put system screen_brightness ${value}`;
        break;
      case 'volume':
        command = `media volume --stream 3 --set ${value * 15 / 100}`; // 0-15 volume range
        break;
      default:
        return;
    }
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidDevice",
        nodeName: "ChangeSystemSetting",
        nodeValue: `${setting}:${value}`,
        actionType: "Android",
        actionValue: command,
        actionName: "ChangeSystemSetting",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'device',
        action: 'changeSetting',
        setting: setting,
        value: value,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'AndroidDevice',
      name: `Change ${setting} to ${value}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    switch (setting) {
      case 'wifi':
        setNetworkStatus(prev => ({ ...prev, wifiEnabled: value }));
        break;
      case 'data':
        setNetworkStatus(prev => ({ ...prev, mobileDataEnabled: value }));
        break;
      case 'airplane':
        setNetworkStatus(prev => ({ ...prev, airplaneMode: value }));
        break;
      case 'bluetooth':
        setNetworkStatus(prev => ({ ...prev, bluetoothEnabled: value }));
        break;
      case 'gps':
        setDeviceSettings(prev => ({ ...prev, gpsEnabled: value }));
        break;
      case 'brightness':
        setDeviceSettings(prev => ({ ...prev, brightness: value }));
        break;
      case 'volume':
        setDeviceSettings(prev => ({ ...prev, volume: value }));
        break;
    }
  };
  
  // Fonction pour gérer les logcat
  const getLogcat = (filter = logcatFilter) => {
    if (disabled || !connectionState.connected) return;
    
    let command = 'logcat -d';
    
    if (filter.priority && filter.priority !== 'V') {
      command += ` *:${filter.priority}`;
    }
    
    if (filter.tag) {
      command += ` -s ${filter.tag}`;
    }
    
    if (filter.grep) {
      command += ` | grep "${filter.grep}"`;
    }
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidDebug",
        nodeName: "GetLogcat",
        nodeValue: JSON.stringify(filter),
        actionType: "Android",
        actionValue: command,
        actionName: "GetLogcat",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'AndroidDebug',
      name: 'Get logcat',
      timestamp: new Date()
    });
  };
  
  // Fonction pour effacer les logcat
  const clearLogcat = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidDebug",
        nodeName: "ClearLogcat",
        nodeValue: "clear",
        actionType: "Android",
        actionValue: "logcat -c",
        actionName: "ClearLogcat",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'AndroidDebug',
      name: 'Clear logcat',
      timestamp: new Date()
    });
    
    // Effacer les logs locaux
    setLogcatOutput([]);
  };
  
  // Fonction pour obtenir les statistiques de performances
  const getPerformanceStats = (packageName = currentApp) => {
    if (disabled || !connectionState.connected || !packageName) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidDebug",
        nodeName: "GetPerformanceStats",
        nodeValue: packageName,
        actionType: "Android",
        actionValue: `dumpsys meminfo ${packageName}`,
        actionName: "GetPerformanceStats",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'AndroidDebug',
      name: `Get performance stats for ${packageName}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour démarrer l'enregistrement
  const startRecording = () => {
    setIsRecording(true);
    setRecordedActions([]);
    setRecordingStartTime(new Date());
    
    // Notifier l'agent de démarrer l'enregistrement
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidRecord",
        nodeName: "StartRecording",
        nodeValue: "true",
        actionType: "Android",
        actionValue: "startRecording",
        actionName: "StartRecording",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setSnackbar({
      open: true,
      message: 'Enregistrement démarré',
      severity: 'success'
    });
    
    setLastAction({
      type: 'AndroidRecord',
      name: 'Start recording',
      timestamp: new Date()
    });
  };
  
  // Fonction pour arrêter l'enregistrement
  const stopRecording = () => {
    setIsRecording(false);
    
    // Notifier l'agent d'arrêter l'enregistrement
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "AndroidRecord",
        nodeName: "StopRecording",
        nodeValue: "true",
        actionType: "Android",
        actionValue: "stopRecording",
        actionName: "StopRecording",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setSnackbar({
      open: true,
      message: `Enregistrement arrêté - ${recordedActions.length} actions enregistrées`,
      severity: 'info'
    });
    
    setLastAction({
      type: 'AndroidRecord',
      name: 'Stop recording',
      timestamp: new Date()
    });
  };
  
  // Fonction pour ajouter une action enregistrée
  const addRecordedAction = (action) => {
    setRecordedActions(prev => [...prev, action]);
  };
  
  // Fonction pour exécuter une action sur un élément de l'interface
  const executeElementAction = () => {
    if (!elementSelector || !selectedActionType || disabled || !connectionState.connected) return;
    
    switch (selectedActionType) {
      case 'tap':
        tapElement(elementSelector);
        break;
      case 'longPress':
        longPressElement(elementSelector, parseInt(actionInput) || 1000);
        break;
      case 'type':
        if (actionInput) {
          typeText(elementSelector, actionInput);
        }
        break;
      case 'wait':
        waitForElement(elementSelector, parseInt(waitTimeout) || 5000);
        break;
      default:
        console.warn(`Action non prise en charge: ${selectedActionType}`);
    }
    
    // Réinitialiser les champs après l'action
    setActionInput('');
  };
  
  // Fonction pour exporter les actions enregistrées
  const exportRecordedActions = () => {
    if (recordedActions.length === 0) {
      setSnackbar({
        open: true,
        message: 'Aucune action à exporter',
        severity: 'warning'
      });
      return;
    }
    
    // Convertir les actions en script Python pour Appium
    let script = `# Script généré le ${new Date().toLocaleString()}\n`;
    script += `from appium import webdriver\n`;
    script += `from appium.webdriver.common.touch_action import TouchAction\n`;
    script += `from appium.webdriver.common.mobileby import MobileBy\n`;
    script += `from selenium.webdriver.support.ui import WebDriverWait\n`;
    script += `from selenium.webdriver.support import expected_conditions as EC\n`;
    script += `import time\n\n`;
    
    script += `# Configuration des capacités Appium\n`;
    script += `desired_caps = {\n`;
    script += `    'platformName': 'Android',\n`;
    script += `    'platformVersion': '${deviceInfo.androidVersion}',\n`;
    script += `    'deviceName': '${deviceInfo.model}',\n`;
    if (currentApp) {
      script += `    'appPackage': '${currentApp}',\n`;
      if (currentActivity) {
        script += `    'appActivity': '${currentActivity}',\n`;
      }
    }
    script += `    'automationName': 'UiAutomator2',\n`;
    script += `    'noReset': False\n`;
    script += `}\n\n`;
    
    script += `# Initialiser le driver\n`;
    script += `driver = webdriver.Remote('http://localhost:4723/wd/hub', desired_caps)\n`;
    script += `wait = WebDriverWait(driver, 30)\n\n`;
    
    script += `# Exécuter la séquence d'actions\n`;
    script += `try:\n`;
    
    // Indentation pour le bloc try
    const indent = '    ';
    
    // Ajouter chaque action
    recordedActions.forEach((action, index) => {
      if (index > 0) {
        script += `${indent}# Petit délai entre les actions\n`;
        script += `${indent}time.sleep(0.5)\n\n`;
      }
      
      switch (action.type) {
        case 'app':
          if (action.action === 'launch') {
            script += `${indent}# Lancer l'application\n`;
            script += `${indent}driver.activate_app('${action.value}')\n`;
          } else if (action.action === 'stop') {
            script += `${indent}# Arrêter l'application\n`;
            script += `${indent}driver.terminate_app('${action.value}')\n`;
          } else if (action.action === 'clearData') {
            script += `${indent}# Effacer les données de l'application\n`;
            script += `${indent}driver.execute_script('mobile: shell', {'command': 'pm', 'args': ['clear', '${action.value}']})\n`;
          }
          break;
          
        case 'ui':
          if (action.action === 'tap') {
            if (action.coordinates) {
              script += `${indent}# Appuyer aux coordonnées (${action.coordinates.x}, ${action.coordinates.y})\n`;
              script += `${indent}TouchAction(driver).tap(x=${action.coordinates.x}, y=${action.coordinates.y}).perform()\n`;
            } else {
              script += `${indent}# Appuyer sur l'élément: ${action.selector}\n`;
              script += `${indent}element = wait.until(EC.presence_of_element_located((MobileBy.XPATH, '${action.selector}')))\n`;
              script += `${indent}element.click()\n`;
            }
          } else if (action.action === 'longPress') {
            if (action.coordinates) {
              script += `${indent}# Appui long aux coordonnées (${action.coordinates.x}, ${action.coordinates.y})\n`;
              script += `${indent}TouchAction(driver).long_press(x=${action.coordinates.x}, y=${action.coordinates.y}, duration=${action.duration}).perform()\n`;
            } else {
              script += `${indent}# Appui long sur l'élément: ${action.selector}\n`;
              script += `${indent}element = wait.until(EC.presence_of_element_located((MobileBy.XPATH, '${action.selector}')))\n`;
              script += `${indent}TouchAction(driver).long_press(element, duration=${action.duration}).perform()\n`;
            }
          } else if (action.action === 'type') {
            script += `${indent}# Saisir du texte dans l'élément: ${action.selector}\n`;
            script += `${indent}element = wait.until(EC.presence_of_element_located((MobileBy.XPATH, '${action.selector}')))\n`;
            script += `${indent}element.clear()\n`;
            script += `${indent}element.send_keys("${action.value}")\n`;
          } else if (action.action === 'swipe') {
            const { startX, startY, endX, endY } = action.coordinates;
            script += `${indent}# Défilement de (${startX}, ${startY}) à (${endX}, ${endY})\n`;
            script += `${indent}TouchAction(driver).press(x=${startX}, y=${startY}).wait(${action.duration}).move_to(x=${endX}, y=${endY}).release().perform()\n`;
          } else if (action.action === 'pressKey') {
            script += `${indent}# Appuyer sur la touche: ${action.value}\n`;
            if (action.value === 'back') {
              script += `${indent}driver.press_keycode(4)\n`;
            } else if (action.value === 'home') {
              script += `${indent}driver.press_keycode(3)\n`;
            } else if (action.value === 'menu') {
              script += `${indent}driver.press_keycode(82)\n`;
            } else {
              script += `${indent}driver.press_keycode(${action.value})\n`;
            }
          }
          break;
          
        case 'wait':
          if (action.action === 'waitForElement') {
            script += `${indent}# Attendre l'élément: ${action.selector}\n`;
            script += `${indent}wait_element = WebDriverWait(driver, ${action.timeout / 1000})\n`;
            script += `${indent}wait_element.until(EC.presence_of_element_located((MobileBy.XPATH, '${action.selector}')))\n`;
          }
          break;
          
        case 'device':
          if (action.action === 'rotate') {
            script += `${indent}# Changer l'orientation en ${action.value}\n`;
            script += `${indent}driver.orientation = '${action.value.toUpperCase()}'\n`;
          } else if (action.action === 'changeSetting') {
            script += `${indent}# Changer le paramètre système: ${action.setting}\n`;
            if (action.setting === 'wifi') {
              script += `${indent}driver.set_network_connection(${action.value ? 2 : 0})\n`;
            } else {
              script += `${indent}# Cette action peut nécessiter des permissions spéciales\n`;
              script += `${indent}driver.execute_script('mobile: shell', {'command': 'settings', 'args': ['put', 'system', '${action.setting}', '${action.value}']})\n`;
            }
          }
          break;
          
        case 'capture':
          if (action.action === 'screenshot') {
            script += `${indent}# Prendre une capture d'écran\n`;
            script += `${indent}driver.save_screenshot(f"screenshot_{int(time.time())}.png")\n`;
          }
          break;
      }
    });
    
    script += `finally:\n`;
    script += `    # Terminer la session\n`;
    script += `    driver.quit()\n`;
    
    // Créer un blob et déclencher le téléchargement
    const blob = new Blob([script], { type: 'text/python' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `android-script-${new Date().getTime()}.py`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    
    setSnackbar({
      open: true,
      message: 'Script exporté avec succès',
      severity: 'success'
    });
  };
  
  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Smartphone sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              Android {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionState.connected ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionState.connected ? 'success' : 'error'}
            label={connectionState.connected ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Appareil
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {deviceInfo.manufacturer} {deviceInfo.model}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Android
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {deviceInfo.androidVersion} (API {deviceInfo.apiLevel})
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Application active
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {currentApp || 'Aucune'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant d'informations du dispositif
  const DeviceInfoComponent = () => {
    return (
      <Accordion 
        expanded={expandedSections.deviceInfo} 
        onChange={() => setExpandedSections(prev => ({ ...prev, deviceInfo: !prev.deviceInfo }))}
        sx={{ mb: 2 }}
      >
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Smartphone size={18} style={{ marginRight: 8 }} />
            Informations sur l'appareil
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Smartphone size={16} style={{ marginRight: 8 }} />
                  Appareil
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Modèle:</Typography>
                    <Typography variant="body1">{deviceInfo.model}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Fabricant:</Typography>
                    <Typography variant="body1">{deviceInfo.manufacturer}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Android:</Typography>
                    <Typography variant="body1">{deviceInfo.androidVersion}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">API Level:</Typography>
                    <Typography variant="body1">{deviceInfo.apiLevel}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Monitor size={16} style={{ marginRight: 8 }} />
                  Écran
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Résolution:</Typography>
                    <Typography variant="body1">{deviceInfo.screenResolution}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Orientation:</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1">{deviceInfo.orientation}</Typography>
                      <IconButton size="small" onClick={rotateScreen} disabled={disabled || !connectionState.connected}>
                        <Rotate3d size={16} />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Batterie:</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {deviceInfo.batteryCharging ? (
                        <BatteryCharging size={18} color="green" style={{ marginRight: 8 }} />
                      ) : deviceInfo.batteryLevel < 20 ? (
                        <BatteryLow size={18} color="red" style={{ marginRight: 8 }} />
                      ) : (
                        <Battery size={18} style={{ marginRight: 8 }} />
                      )}
                      <LinearProgress 
                        variant="determinate" 
                        value={deviceInfo.batteryLevel} 
                        sx={{ flex: 1, height: 8, borderRadius: 4 }}
                        color={deviceInfo.batteryLevel < 20 ? "error" : "success"}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {deviceInfo.batteryLevel}%
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Wifi size={16} style={{ marginRight: 8 }} />
                  Réseau
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.wifiEnabled}
                            onChange={(e) => changeSystemSetting('wifi', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="WiFi"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.mobileDataEnabled}
                            onChange={(e) => changeSystemSetting('data', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="Données mobiles"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.bluetoothEnabled}
                            onChange={(e) => changeSystemSetting('bluetooth', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="Bluetooth"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.airplaneMode}
                            onChange={(e) => changeSystemSetting('airplane', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="Mode avion"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Settings size={16} style={{ marginRight: 8 }} />
                  Paramètres système
                </Typography>
                
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Luminosité:</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <ZoomOut size={16} style={{ marginRight: 8 }} />
                      <Slider
                        value={deviceSettings.brightness}
                        min={0}
                        max={255}
                        onChange={(e, val) => setDeviceSettings(prev => ({ ...prev, brightness: val }))}
                        onChangeCommitted={(e, val) => changeSystemSetting('brightness', val)}
                        disabled={disabled || !connectionState.connected}
                        sx={{ mx: 2 }}
                      />
                      <ZoomIn size={16} style={{ marginLeft: 8 }} />
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Volume:</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <VolumeX size={16} style={{ marginRight: 8 }} />
                      <Slider
                        value={deviceSettings.volume}
                        min={0}
                        max={100}
                        onChange={(e, val) => setDeviceSettings(prev => ({ ...prev, volume: val }))}
                        onChangeCommitted={(e, val) => changeSystemSetting('volume', val)}
                        disabled={disabled || !connectionState.connected}
                        sx={{ mx: 2 }}
                      />
                      <Volume2 size={16} style={{ marginLeft: 8 }} />
                    </Box>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={deviceSettings.gpsEnabled}
                            onChange={(e) => changeSystemSetting('gps', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="GPS"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Composant de contrôle d'application
  const AppControlComponent = () => {
    const filteredApps = appSearchQuery
      ? installedApps.filter(app => 
          app.label.toLowerCase().includes(appSearchQuery.toLowerCase()) ||
          app.packageName.toLowerCase().includes(appSearchQuery.toLowerCase())
        )
      : installedApps;
    
    return (
      <Accordion 
        expanded={expandedSections.appControl} 
        onChange={() => setExpandedSections(prev => ({ ...prev, appControl: !prev.appControl }))}
        sx={{ mb: 2 }}
      >
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Package size={18} style={{ marginRight: 8 }} />
            Contrôle des applications
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>Applications installées</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Rechercher une application..."
                  value={appSearchQuery}
                  onChange={(e) => setAppSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search size={18} />
                      </InputAdornment>
                    )
                  }}
                  sx={{ mb: 1 }}
                />
                
                <Paper variant="outlined" sx={{ height: 200, overflow: 'auto' }}>
                  <List dense sx={{ pt: 0 }}>
                    {filteredApps.length === 0 ? (
                      <ListItem>
                        <ListItemText primary="Aucune application trouvée" />
                      </ListItem>
                    ) : (
                      filteredApps.map((app, index) => (
                        <ListItem 
                          key={index}
                          disablePadding
                          secondaryAction={
                            <IconButton 
                              edge="end" 
                              size="small"
                              onClick={() => launchApp(app.packageName)}
                              disabled={disabled || !connectionState.connected}
                            >
                              <Play size={16} />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            dense
                            onClick={() => {
                              setSelectedAppPackage(app.packageName);
                              getAppInfo(app.packageName);
                            }}
                            selected={selectedAppPackage === app.packageName}
                          >
                            <ListItemIcon sx={{ minWidth: 36 }}>
                              <Package size={20} />
                            </ListItemIcon>
                            <ListItemText 
                              primary={app.label} 
                              secondary={app.packageName}
                              primaryTypographyProps={{ noWrap: true }}
                              secondaryTypographyProps={{ noWrap: true }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))
                    )}
                  </List>
                </Paper>
              </Box>
              
              <Box sx={{ mb: 2, display: 'flex', gap: 1 }}>
                <Button
                  variant="outlined"
                  startIcon={<Play />}
                  onClick={() => launchApp(selectedAppPackage)}
                  disabled={!selectedAppPackage || disabled || !connectionState.connected}
                  size="small"
                >
                  Lancer
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<StopCircle />}
                  onClick={() => stopApp(selectedAppPackage)}
                  disabled={!selectedAppPackage || disabled || !connectionState.connected}
                  size="small"
                  color="error"
                >
                  Arrêter
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Trash2 />}
                  onClick={() => clearAppData(selectedAppPackage)}
                  disabled={!selectedAppPackage || disabled || !connectionState.connected}
                  size="small"
                >
                  Effacer les données
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
                <Typography variant="subtitle2" gutterBottom>
                  {currentApp ? `Informations: ${currentApp}` : 'Aucune application sélectionnée'}
                </Typography>
                
                {appInfo ? (
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          Activité actuelle:
                        </Typography>
                        <Typography variant="body2" noWrap>
                          {currentActivity || 'Inconnue'}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Version:
                        </Typography>
                        <Typography variant="body2">
                          {appInfo.versionName || 'Inconnue'} ({appInfo.versionCode || '?'})
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          État:
                        </Typography>
                        <Chip 
                          size="small" 
                          label={isAppRunning ? "En cours d'exécution" : "Arrêtée"} 
                          color={isAppRunning ? "success" : "default"} 
                        />
                      </Grid>
                      
                      {appProcessInfo && (
                        <>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              PID:
                            </Typography>
                            <Typography variant="body2">
                              {appProcessInfo.pid || 'N/A'}
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              Utilisation mémoire:
                            </Typography>
                            <Typography variant="body2">
                              {appProcessInfo.memoryUsage ? `${Math.round(appProcessInfo.memoryUsage / 1024 / 1024)} MB` : 'N/A'}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    
                    <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                      Permissions
                    </Typography>
                    
                    <Box sx={{ maxHeight: 100, overflow: 'auto', mb: 1 }}>
                      {appPermissions.length === 0 ? (
                        <Typography variant="body2" color="text.secondary">
                          Aucune permission
                        </Typography>
                      ) : (
                        <Grid container spacing={0.5}>
                          {appPermissions.map((permission, index) => (
                            <Grid item key={index}>
                              <Chip 
                                size="small" 
                                label={permission.name.replace('android.permission.', '')} 
                                color={permission.granted ? "success" : "default"} 
                                variant={permission.granted ? "filled" : "outlined"}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                      Sélectionnez une application pour voir ses détails
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Composant d'interface et contrôles systèmes
  const UiControlsComponent = () => {
    return (
      <Accordion 
        expanded={expandedSections.elementInteraction} 
        onChange={() => setExpandedSections(prev => ({ ...prev, elementInteraction: !prev.elementInteraction }))}
        sx={{ mb: 2 }}
      >
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <TouchpadOff size={18} style={{ marginRight: 8 }} />
            Contrôles et interactions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>Navigation système</Typography>
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<Home />}
                  onClick={() => pressKey('home')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Accueil
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<ArrowLeft />}
                  onClick={() => pressKey('back')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Retour
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Search />}
                  onClick={() => pressKey('menu')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Menu
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<RefreshCw />}
                  onClick={() => pressKey('recent_apps')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Récents
                </Button>
              </Box>
              
              <Typography variant="subtitle2" gutterBottom>Défilement</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => swipeScreen('up')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                  sx={{ my: 1, width: 120 }}
                >
                  <ArrowUp />
                </Button>
                
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Button
                    variant="outlined"
                    onClick={() => swipeScreen('left')}
                    disabled={disabled || !connectionState.connected}
                    size="small"
                    sx={{ width: 120 }}
                  >
                    <ArrowLeft />
                  </Button>
                  
                  <Button
                    variant="outlined"
                    onClick={() => rotateScreen()}
                    disabled={disabled || !connectionState.connected}
                    size="small"
                    sx={{ width: 60, height: 60, minWidth: 60 }}
                  >
                    <Rotate3d />
                  </Button>
                  
                  <Button
                    variant="outlined"
                    onClick={() => swipeScreen('right')}
                    disabled={disabled || !connectionState.connected}
                    size="small"
                    sx={{ width: 120 }}
                  >
                    <ArrowRight />
                  </Button>
                </Box>
                
                <Button
                  variant="outlined"
                  onClick={() => swipeScreen('down')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                  sx={{ my: 1, width: 120 }}
                >
                  <ArrowDown />
                </Button>
              </Box>
              
              <Typography variant="subtitle2" gutterBottom>Volume et alimentation</Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => pressKey('volume_up')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Vol +
                </Button>
                
                <Button
                  variant="outlined"
                  onClick={() => pressKey('volume_down')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Vol -
                </Button>
                
                <Button
                  variant="outlined"
                  onClick={() => pressKey('power')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                  color="error"
                >
                  Power
                </Button>
                
                <Button
                  variant="outlined"
                  onClick={() => takeScreenshot()}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Capture
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>Interaction avec les éléments</Typography>
              <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                <InputLabel>Type d'action</InputLabel>
                <Select
                  value={selectedActionType}
                  onChange={(e) => setSelectedActionType(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                >
                  <MenuItem value="tap">Appuyer (tap)</MenuItem>
                  <MenuItem value="longPress">Appui long</MenuItem>
                  <MenuItem value="type">Saisir du texte</MenuItem>
                  <MenuItem value="wait">Attendre l'élément</MenuItem>
                </Select>
              </FormControl>
              
              <TextField
                fullWidth
                size="small"
                label="Sélecteur ou XPath"
                placeholder="Ex: //android.widget.Button[@text='Login']"
                value={elementSelector}
                onChange={(e) => setElementSelector(e.target.value)}
                disabled={disabled || !connectionState.connected}
                sx={{ mb: 1 }}
              />
              
              {selectedActionType === 'type' && (
                <TextField
                  fullWidth
                  size="small"
                  label="Texte à saisir"
                  value={actionInput}
                  onChange={(e) => setActionInput(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                />
              )}
              
              {selectedActionType === 'longPress' && (
                <TextField
                  fullWidth
                  size="small"
                  label="Durée (ms)"
                  type="number"
                  value={actionInput || '1000'}
                  onChange={(e) => setActionInput(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                />
              )}
              
              {selectedActionType === 'wait' && (
                <TextField
                  fullWidth
                  size="small"
                  label="Timeout (ms)"
                  type="number"
                  value={waitTimeout}
                  onChange={(e) => setWaitTimeout(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                />
              )}
              
              <Button
                fullWidth
                variant="contained"
                onClick={executeElementAction}
                disabled={!elementSelector || disabled || !connectionState.connected}
                startIcon={<PlayCircle />}
              >
                Exécuter l'action
              </Button>
              
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>Informations sur l'interface</Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="outlined"
                  startIcon={<Layers />}
                  onClick={getViewHierarchy}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Hiérarchie des vues
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Search />}
                  onClick={() => {/* Ouvrir un dialogue de recherche d'élément */}}
                  disabled={disabled || !connectionState.connected || !viewHierarchy}
                  size="small"
                >
                  Rechercher élément
                </Button>
                
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={highlightBounds}
                      onChange={(e) => setHighlightBounds(e.target.checked)}
                      disabled={disabled || !connectionState.connected || !viewHierarchy}
                    />
                  }
                  label="Afficher les bordures"
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Composant de capture d'écran
  const ScreenshotComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Image size={16} style={{ marginRight: 8 }} />
          Capture d'écran
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button
            size="small"
            startIcon={<Image />}
            onClick={takeScreenshot}
            disabled={disabled || !connectionState.connected}
          >
            Prendre une capture d'écran
          </Button>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 1 }}>Zoom:</Typography>
            <IconButton
              size="small"
              onClick={() => setZoomLevel(prev => Math.max(0.1, prev - 0.1))}
              disabled={zoomLevel <= 0.1}
            >
              <ZoomOut size={16} />
            </IconButton>
            
            <Typography variant="body2" sx={{ mx: 1 }}>
              {Math.round(zoomLevel * 100)}%
            </Typography>
            
            <IconButton
              size="small"
              onClick={() => setZoomLevel(prev => Math.min(2, prev + 0.1))}
              disabled={zoomLevel >= 2}
            >
              <ZoomIn size={16} />
            </IconButton>
          </Box>
        </Box>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Paper 
              variant="outlined" 
              sx={{ 
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                overflow: 'hidden',
                minHeight: 400
              }}
              ref={screenshotContainerRef}
            >
              {screenshot ? (
                <Box
                  sx={{
                    position: 'relative',
                    transform: `scale(${zoomLevel})`,
                    transition: 'transform 0.2s',
                    transformOrigin: 'center',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                >
                  <Typography variant="caption" sx={{ position: 'absolute', top: 4, left: 4, bgcolor: 'rgba(0,0,0,0.5)', color: 'white', px: 1, borderRadius: 1 }}>
                    {new Date(screenshot.timestamp).toLocaleTimeString()}
                  </Typography>
                  
                  <img
                    src={`data:image/png;base64,${screenshot.data}`}
                    alt="Capture d'écran de l'appareil"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '60vh',
                      objectFit: 'contain',
                      transform: screenOrientation === 'landscape' ? 'rotate(90deg)' : 'none'
                    }}
                  />
                </Box>
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <AlertTriangle size={48} color="#f57c00" />
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Aucune capture d'écran disponible
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Utilisez le bouton "Prendre une capture d'écran" pour capturer l'écran actuel
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle2" gutterBottom>
              Historique des captures
            </Typography>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 'calc(100% - 24px)',
                overflow: 'auto'
              }}
            >
              {screenshotHistory.length === 0 ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    Aucun historique
                  </Typography>
                </Box>
              ) : (
                <List dense sx={{ pt: 0 }}>
                  {screenshotHistory.map((shot, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      divider={index < screenshotHistory.length - 1}
                    >
                      <ListItemButton 
                        dense
                        onClick={() => setScreenshot(shot)}
                        selected={screenshot && screenshot.id === shot.id}
                      >
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <Image size={20} />
                        </ListItemIcon>
                        <ListItemText
                          primary={new Date(shot.timestamp).toLocaleTimeString()}
                          secondary={shot.appPackage || 'N/A'}
                          primaryTypographyProps={{ variant: 'body2' }}
                          secondaryTypographyProps={{ variant: 'caption', noWrap: true }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant d'enregistrement d'actions
  const RecordingComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <PlayCircle size={16} style={{ marginRight: 8 }} />
          Enregistrement d'actions
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2,
          pb: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Box>
            <Button
              variant={isRecording ? "contained" : "outlined"}
              color={isRecording ? "error" : "primary"}
              startIcon={isRecording ? <StopCircle /> : <PlayCircle />}
              onClick={isRecording ? stopRecording : startRecording}
              disabled={disabled || !connectionState.connected}
            >
              {isRecording ? 'Arrêter l\'enregistrement' : 'Démarrer l\'enregistrement'}
            </Button>
            
            {isRecording && recordingStartTime && (
              <Typography variant="caption" sx={{ ml: 2, color: 'text.secondary' }}>
                Enregistrement en cours: {Math.floor((new Date() - recordingStartTime) / 1000)} secondes
              </Typography>
            )}
          </Box>
          
          <Box>
            <Button
              variant="outlined"
              startIcon={<Download />}
              onClick={exportRecordedActions}
              disabled={recordedActions.length === 0}
            >
              Exporter le script
            </Button>
          </Box>
        </Box>
        
        <Typography variant="subtitle2" gutterBottom>
          Actions enregistrées ({recordedActions.length})
        </Typography>
        
        <Box sx={{ flex: 1, overflow: 'auto', border: 1, borderColor: 'divider', borderRadius: 1 }}>
          <List dense sx={{ p: 0 }}>
            {recordedActions.length === 0 ? (
              <ListItem>
                <ListItemText 
                  primary="Aucune action enregistrée" 
                  secondary="Démarrez l'enregistrement et interagissez avec l'appareil"
                />
              </ListItem>
            ) : (
              recordedActions.map((action, index) => (
                <ListItem key={index} divider>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    {action.type === 'app' && <Package size={18} />}
                    {action.type === 'ui' && <MousePointer size={18} />}
                    {action.type === 'wait' && <Clock size={18} />}
                    {action.type === 'device' && <Smartphone size={18} />}
                    {action.type === 'capture' && <Image size={18} />}
                  </ListItemIcon>
                  
                  <ListItemText
                    primary={
                      action.type === 'app' ? 
                        (action.action === 'launch' ? `Lancer l'app: ${action.value}` : 
                         action.action === 'stop' ? `Arrêter l'app: ${action.value}` : 
                         `Effacer les données de l'app: ${action.value}`) :
                      action.type === 'ui' ?
                        (action.action === 'tap' ? `Appuyer sur: ${action.selector || 'coordonnées'}` :
                         action.action === 'longPress' ? `Appui long sur: ${action.selector || 'coordonnées'}` :
                         action.action === 'type' ? `Saisir "${action.value}" dans: ${action.selector}` :
                         action.action === 'swipe' ? `Défilement: ${action.direction}` :
                         `Appuyer sur touche: ${action.value}`) :
                      action.type === 'wait' ?
                        `Attendre l'élément: ${action.selector}` :
                      action.type === 'device' ?
                        (action.action === 'rotate' ? `Rotation: ${action.value}` :
                         `Changer ${action.setting} à ${action.value}`) :
                      action.type === 'capture' ?
                        'Capture d\'écran' :
                        action.action
                    }
                    secondary={new Date(action.timestamp).toLocaleTimeString()}
                  />
                  
                  <IconButton 
                    size="small" 
                    onClick={() => {
                      setRecordedActions(recordedActions.filter((_, i) => i !== index));
                    }}
                  >
                    <Trash2 size={14} />
                  </IconButton>
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Box>
    );
  };
  
  // Composant de logs et monitoring
  const LogsComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Terminal size={16} style={{ marginRight: 8 }} />
          Logs et monitoring
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography variant="subtitle2" gutterBottom>Logcat</Typography>
            
            <Box sx={{ display: 'flex', mb: 1, flexWrap: 'wrap', gap: 1 }}>
              <TextField
                size="small"
                label="Tag"
                value={logcatFilter.tag}
                onChange={(e) => setLogcatFilter(prev => ({ ...prev, tag: e.target.value }))}
                disabled={disabled || !connectionState.connected}
                sx={{ width: 150 }}
              />
              
              <FormControl size="small" sx={{ width: 150 }}>
                <InputLabel>Priorité</InputLabel>
                <Select
                  value={logcatFilter.priority}
                  onChange={(e) => setLogcatFilter(prev => ({ ...prev, priority: e.target.value }))}
                  disabled={disabled || !connectionState.connected}
                >
                  <MenuItem value="V">Verbose (V)</MenuItem>
                  <MenuItem value="D">Debug (D)</MenuItem>
                  <MenuItem value="I">Info (I)</MenuItem>
                  <MenuItem value="W">Warning (W)</MenuItem>
                  <MenuItem value="E">Error (E)</MenuItem>
                  <MenuItem value="F">Fatal (F)</MenuItem>
                </Select>
              </FormControl>
              
              <TextField
                size="small"
                label="Filtrer par mot-clé"
                value={logcatFilter.grep}
                onChange={(e) => setLogcatFilter(prev => ({ ...prev, grep: e.target.value }))}
                disabled={disabled || !connectionState.connected}
                sx={{ flex: 1, minWidth: 150 }}
              />
              
              <Button
                variant="outlined"
                size="small"
                onClick={() => getLogcat()}
                disabled={disabled || !connectionState.connected}
                startIcon={<RefreshCw />}
              >
                Rafraîchir
              </Button>
              
              <Button
                variant="outlined"
                size="small"
                onClick={clearLogcat}
                disabled={disabled || !connectionState.connected}
                startIcon={<Trash2 />}
              >
                Effacer
              </Button>
            </Box>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 300,
                overflow: 'auto',
                fontFamily: 'monospace'
              }}
              ref={logcatContainerRef}
            >
              {logcatOutput.length === 0 ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    Aucun log disponible
                  </Typography>
                </Box>
              ) : (
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell width={100}>Heure</TableCell>
                      <TableCell width={60}>Priorité</TableCell>
                      <TableCell width={150}>Tag</TableCell>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logcatOutput.map((log, index) => (
                      <TableRow key={index}>
                        <TableCell>{log.timestamp.toLocaleTimeString()}</TableCell>
                        <TableCell>
                          <Chip 
                            size="small" 
                            label={log.priority} 
                            color={
                              log.priority === 'E' || log.priority === 'F' ? 'error' :
                              log.priority === 'W' ? 'warning' :
                              log.priority === 'I' ? 'info' :
                              log.priority === 'D' ? 'primary' :
                              'default'
                            }
                            sx={{ height: 20, minWidth: 20 }}
                          />
                        </TableCell>
                        <TableCell>{log.tag}</TableCell>
                        <TableCell sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                          {log.message}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>
          
          <Grid item xs={12} lg={4}>
            <Typography variant="subtitle2" gutterBottom>Monitoring des performances</Typography>
            
            <Box sx={{ display: 'flex', mb: 1, justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => getPerformanceStats()}
                disabled={!currentApp || disabled || !connectionState.connected}
                startIcon={<RefreshCw />}
              >
                Rafraîchir
              </Button>
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" sx={{ mr: 1 }}>
                  Rafraîchir toutes les:
                </Typography>
                <Select
                  size="small"
                  value={refreshRate}
                  onChange={(e) => setRefreshRate(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ width: 80 }}
                >
                  <MenuItem value={1}>1s</MenuItem>
                  <MenuItem value={5}>5s</MenuItem>
                  <MenuItem value={10}>10s</MenuItem>
                  <MenuItem value={30}>30s</MenuItem>
                  <MenuItem value={60}>1m</MenuItem>
                </Select>
              </Box>
            </Box>
            
            <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
              {currentApp ? (
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Utilisation CPU:</span>
                      <span>{performanceStats.cpuUsage.toFixed(1)}%</span>
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={performanceStats.cpuUsage} 
                      color={
                        performanceStats.cpuUsage > 80 ? "error" : 
                        performanceStats.cpuUsage > 50 ? "warning" : 
                        "success"
                      }
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                  
                  <Box>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Utilisation mémoire:</span>
                      <span>{Math.round(performanceStats.memoryUsage)} MB</span>
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={Math.min(performanceStats.memoryUsage / 2000 * 100, 100)} 
                      color={
                        performanceStats.memoryUsage > 1500 ? "error" : 
                        performanceStats.memoryUsage > 1000 ? "warning" : 
                        "success"
                      }
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                  
                  <Box>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Utilisation batterie:</span>
                      <span>{performanceStats.batteryUsage.toFixed(1)}%</span>
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={performanceStats.batteryUsage} 
                      color={
                        performanceStats.batteryUsage > 10 ? "error" : 
                        performanceStats.batteryUsage > 5 ? "warning" : 
                        "success"
                      }
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                  
                  <Box>
                    <Typography variant="body2">Utilisation réseau:</Typography>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <span>Réception:</span>
                      <span>{(performanceStats.networkUsage.rx / 1024).toFixed(2)} KB</span>
                    </Typography>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <span>Transfert:</span>
                      <span>{(performanceStats.networkUsage.tx / 1024).toFixed(2)} KB</span>
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                  Sélectionnez une application pour voir ses performances
                </Typography>
              )}
            </Paper>
            
            <Typography variant="subtitle2" gutterBottom>État du système</Typography>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">WiFi:</Typography>
                  <Chip 
                    size="small" 
                    icon={networkStatus.wifiEnabled ? <Wifi size={14} /> : <WifiOff size={14} />}
                    label={networkStatus.wifiEnabled ? (networkStatus.wifiConnected ? "Connecté" : "Activé") : "Désactivé"} 
                    color={networkStatus.wifiEnabled && networkStatus.wifiConnected ? "success" : "default"} 
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Données mobiles:</Typography>
                  <Chip 
                    size="small" 
                    label={networkStatus.mobileDataEnabled ? (networkStatus.mobileDataConnected ? "Connecté" : "Activé") : "Désactivé"} 
                    color={networkStatus.mobileDataEnabled && networkStatus.mobileDataConnected ? "success" : "default"} 
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Bluetooth:</Typography>
                  <Chip 
                    size="small" 
                    icon={networkStatus.bluetoothEnabled ? <Bluetooth size={14} /> : <BluetoothOff size={14} />}
                    label={networkStatus.bluetoothEnabled ? "Activé" : "Désactivé"} 
                    color={networkStatus.bluetoothEnabled ? "info" : "default"} 
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Mode avion:</Typography>
                  <Chip 
                    size="small" 
                    label={networkStatus.airplaneMode ? "Activé" : "Désactivé"} 
                    color={networkStatus.airplaneMode ? "warning" : "default"} 
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant pour la hiérarchie des vues
  const ViewHierarchyComponent = () => {
    // Récursion pour afficher la hiérarchie des vues
    const renderViewNode = (node, depth = 0, index = 0) => {
      // Filtrer par recherche
      if (viewSearchQuery && !node.className.toLowerCase().includes(viewSearchQuery.toLowerCase()) && 
          !node.text?.toLowerCase().includes(viewSearchQuery.toLowerCase()) &&
          !node.resourceId?.toLowerCase().includes(viewSearchQuery.toLowerCase())) {
        // Voir si les enfants correspondent
        const hasMatchingChild = (node.children || []).some(child => 
          child.className.toLowerCase().includes(viewSearchQuery.toLowerCase()) ||
          child.text?.toLowerCase().includes(viewSearchQuery.toLowerCase()) ||
          child.resourceId?.toLowerCase().includes(viewSearchQuery.toLowerCase())
        );
        
        if (!hasMatchingChild) return null;
      }
      
      const isSelected = selectedElement && 
        selectedElement.resourceId === node.resourceId && 
        selectedElement.bounds?.toString() === node.bounds?.toString();
      
      return (
        <div key={`${depth}-${index}`}>
          <ListItemButton 
            sx={{ 
              pl: depth * 2 + 1,
              py: 0.5,
              borderLeft: '1px dashed',
              borderColor: 'divider',
              ...(isSelected && {
                bgcolor: 'primary.light',
                '&:hover': {
                  bgcolor: 'primary.light',
                }
              })
            }}
            dense
            onClick={() => setSelectedElement(node)}
          >
            <ListItemIcon sx={{ minWidth: 36 }}>
              {node.className.includes('Button') ? (
                <TouchpadOff size={16} />
              ) : node.className.includes('Text') ? (
                <Type size={16} />
              ) : node.className.includes('Image') ? (
                <Image size={16} />
              ) : node.className.includes('Layout') ? (
                <Layout size={16} />
              ) : (
                <Box size={16} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography 
                    variant="body2" 
                    component="span" 
                    sx={{ 
                      fontWeight: node.clickable ? 'bold' : 'normal',
                      color: isSelected ? 'primary.contrastText' : 'text.primary'
                    }}
                  >
                    {node.className.split('.').pop()}
                  </Typography>
                  {node.text && (
                    <Typography 
                      variant="caption" 
                      component="span" 
                      sx={{ ml: 1, color: isSelected ? 'primary.contrastText' : 'text.secondary' }}
                    >
                      "{node.text}"
                    </Typography>
                  )}
                </React.Fragment>
              }
              secondary={
                node.resourceId ? (
                  <Typography 
                    variant="caption" 
                    component="span" 
                    sx={{ color: isSelected ? 'primary.contrastText' : 'text.secondary' }}
                  >
                    ID: {node.resourceId.split('/').pop()}
                  </Typography>
                ) : null
              }
            />
          </ListItemButton>
          
          {node.children && node.children.map((child, childIndex) => 
            renderViewNode(child, depth + 1, childIndex)
          )}
        </div>
      );
    };
    
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Layers size={16} style={{ marginRight: 8 }} />
          Hiérarchie des vues
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button
            size="small"
            startIcon={<Layers />}
            onClick={getViewHierarchy}
            disabled={disabled || !connectionState.connected}
          >
            Rafraîchir la hiérarchie
          </Button>
          
          <TextField
            size="small"
            placeholder="Rechercher dans les vues..."
            value={viewSearchQuery}
            onChange={(e) => setViewSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={16} />
                </InputAdornment>
              )
            }}
            sx={{ width: 250 }}
          />
        </Box>
        
        <Grid container spacing={2} sx={{ flex: 1, overflow: 'hidden' }}>
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Typography variant="subtitle2" gutterBottom>Structure</Typography>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 'calc(100% - 30px)',
                overflow: 'auto'
              }}
            >
              {!viewHierarchy ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <AlertTriangle size={32} color="#f57c00" />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Aucune hiérarchie de vues disponible
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                    Cliquez sur "Rafraîchir la hiérarchie" pour l'obtenir
                  </Typography>
                </Box>
              ) : (
                <List dense disablePadding sx={{ mt: 0 }}>
                  {renderViewNode(viewHierarchy)}
                </List>
              )}
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Typography variant="subtitle2" gutterBottom>Détails de l'élément</Typography>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 'calc(100% - 30px)',
                overflow: 'auto'
              }}
            >
              {!selectedElement ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Info size={32} color="#42a5f5" />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Sélectionnez un élément pour voir ses détails
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">{selectedElement.className.split('.').pop()}</Typography>
                  
                  {selectedElement.text && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">Texte:</Typography>
                      <Paper variant="outlined" sx={{ p: 1, mt: 0.5 }}>
                        <Typography variant="body2">{selectedElement.text}</Typography>
                      </Paper>
                    </Box>
                  )}
                  
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>Propriétés</Typography>
                  
                  <TableContainer component={Paper} variant="outlined" sx={{ mt: 1 }}>
                    <Table size="small">
                      <TableBody>
                        {selectedElement.resourceId && (
                          <TableRow>
                            <TableCell component="th" scope="row" width={120}>
                              Resource ID
                            </TableCell>
                            <TableCell>{selectedElement.resourceId}</TableCell>
                          </TableRow>
                        )}
                        {selectedElement.bounds && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Bounds
                            </TableCell>
                            <TableCell>{selectedElement.bounds.toString()}</TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Clickable
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small" 
                              label={selectedElement.clickable ? "Oui" : "Non"} 
                              color={selectedElement.clickable ? "success" : "default"} 
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Enabled
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small" 
                              label={selectedElement.enabled ? "Oui" : "Non"} 
                              color={selectedElement.enabled ? "success" : "default"} 
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Visible
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small" 
                              label={selectedElement.visible ? "Oui" : "Non"} 
                              color={selectedElement.visible ? "success" : "default"} 
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Focusable
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small" 
                              label={selectedElement.focusable ? "Oui" : "Non"} 
                              color={selectedElement.focusable ? "success" : "default"} 
                            />
                          </TableCell>
                        </TableRow>
                        {selectedElement.contentDescription && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Description
                            </TableCell>
                            <TableCell>{selectedElement.contentDescription}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                  <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
                    <Button
                      variant="contained"
                      startIcon={<TouchpadOff />}
                      onClick={() => tapElement(selectedElement.xpath || selectedElement.resourceId)}
                      disabled={disabled || !connectionState.connected || !selectedElement.clickable}
                      size="small"
                    >
                      Appuyer
                    </Button>
                    
                    {selectedElement.className.includes('EditText') && (
                      <Button
                        variant="outlined"
                        startIcon={<Type />}
                        onClick={() => {
                          const text = prompt("Entrez le texte à saisir:", selectedElement.text || "");
                          if (text !== null) {
                            typeText(selectedElement.xpath || selectedElement.resourceId, text);
                          }
                        }}
                        disabled={disabled || !connectionState.connected || !selectedElement.enabled}
                        size="small"
                      >
                        Saisir texte
                      </Button>
                    )}
                    
                    <Button
                      variant="outlined"
                      startIcon={<Copy />}
                      onClick={() => {
                        navigator.clipboard.writeText(selectedElement.xpath || selectedElement.resourceId);
                        setSnackbar({
                          open: true,
                          message: 'Sélecteur copié dans le presse-papiers',
                          severity: 'success'
                        });
                      }}
                      size="small"
                    >
                      Copier sélecteur
                    </Button>
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionState.connected) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionState.connected ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
        <DeviceInfoComponent />
        <AppControlComponent />
        <UiControlsComponent />
        
        <Tabs 
          value={activeTab} 
          onChange={(e, newValue) => setActiveTab(newValue)} 
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            bgcolor: 'background.paper',
            mb: 2
          }}
        >
          <Tab icon={<Image size={16} />} label="Capture d'écran" />
          <Tab icon={<PlayCircle size={16} />} label="Enregistrement" />
          <Tab icon={<Layers size={16} />} label="Hiérarchie des vues" />
          <Tab icon={<Terminal size={16} />} label="Logs" />
        </Tabs>
        
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {activeTab === 0 && <ScreenshotComponent />}
          {activeTab === 1 && <RecordingComponent />}
          {activeTab === 2 && <ViewHierarchyComponent />}
          {activeTab === 3 && <LogsComponent />}
        </Box>
      </Box>
      
      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AndroidAppCapture;