import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  InputAdornment,
  LinearProgress,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  Slider,
  Badge,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from '@mui/material';

import {
  Smartphone,
  Package,
  Play,
  StopCircle,
  Rotate3d,
  RefreshCw,
  Plus,
  Search,
  X,
  ZoomIn,
  ZoomOut,
  Trash2,
  Copy,
  CheckCircle,
  AlertTriangle,
  Info,
  Settings,
  ChevronDown,
  ChevronRight,
  Clock,
  Save,
  Edit,
  Download,
  Upload,
  Image,
  Database,
  FileText,
  Layers,
  Wifi,
  WifiOff,
  Terminal,
  Send,
  TouchpadOff,
  RotateCw,
  Battery,
  BatteryCharging,
  BatteryLow,
  Navigation,
  Compass,
  Volume2,
  VolumeX,
  Bluetooth,
  BluetoothOff,
  Map,
  Home,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  BellRing,
  Mail,
  PhoneCall,
  PhoneOff,
  CameraOff,
  Camera,
  Lock,
  Unlock,
  Fingerprint,
  Mic,
  Radio,
  Tv,
  Cast,
  BarChart,
  MessageSquare,
  MousePointer,
  Hand,
  PlayCircle,
  FilePlus,
  AlertOctagon,
  Apple,
  Move,
  ZoomIn as ZoomInIcon,
  CornerUpLeft,
  Minimize2,
  Maximize2,
  PanelRight,
  Monitor,
  Type,
  Table as TableIcon,
} from 'lucide-react';

/**
 * iOSAppCapture Component - Interface réutilisable pour interagir avec des applications iOS
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles de l'application
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const IOSAppCapture  = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États pour le dispositif
  const [deviceInfo, setDeviceInfo] = useState({
    model: 'iPhone',
    modelIdentifier: 'iPhone12,1',
    osVersion: 'iOS 16.5',
    buildNumber: '20F66',
    screenResolution: '1170x2532',
    orientation: 'portrait',
    batteryLevel: 85,
    batteryCharging: false,
    connected: false,
    deviceId: 'XXXXX-XXXXX-XXXXX-XXXXX',
    isSimulator: false
  });
  
  // États pour les applications
  const [installedApps, setInstalledApps] = useState([]);
  const [currentApp, setCurrentApp] = useState(null);
  const [appInfo, setAppInfo] = useState(null);
  const [currentView, setCurrentView] = useState(null);
  const [appPermissions, setAppPermissions] = useState([]);
  const [isAppRunning, setIsAppRunning] = useState(false);
  const [appProcessInfo, setAppProcessInfo] = useState(null);
  
  // États pour la visualisation
  const [screenshot, setScreenshot] = useState(null);
  const [screenshotHistory, setScreenshotHistory] = useState([]);
  const [viewHierarchy, setViewHierarchy] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [screenOrientation, setScreenOrientation] = useState('portrait');
  const [highlightBounds, setHighlightBounds] = useState(false);
  
  // États pour l'enregistrement
  const [recordedActions, setRecordedActions] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [selectedActionType, setSelectedActionType] = useState('tap');
  const [elementSelector, setElementSelector] = useState('');
  const [actionInput, setActionInput] = useState('');
  const [waitTimeout, setWaitTimeout] = useState(5000);
  
  // États pour le système
  const [deviceLogs, setDeviceLogs] = useState([]);
  const [networkStatus, setNetworkStatus] = useState({
    wifiEnabled: true,
    wifiConnected: true,
    cellularEnabled: true,
    cellularConnected: true,
    airplaneMode: false,
    bluetoothEnabled: false
  });
  const [deviceSettings, setDeviceSettings] = useState({
    brightness: 75,
    volume: 50,
    locationEnabled: true,
    animationScale: 1.0
  });
  
  // États pour les logs et monitoring
  const [consoleOutput, setConsoleOutput] = useState([]);
  const [consoleFilter, setConsoleFilter] = useState({ 
    subsystem: '', 
    level: 'default', // default, debug, info, error
    category: '', 
    search: ''
  });
  const [performanceStats, setPerformanceStats] = useState({
    cpuUsage: 0,
    memoryUsage: 0,
    energy: 0,
    networkUsage: { rx: 0, tx: 0 }
  });
  const [refreshRate, setRefreshRate] = useState(5); // Secondes
  
  // États pour l'interface
  const [activeTab, setActiveTab] = useState(0);
  const [lastAction, setLastAction] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedAppBundleId, setSelectedAppBundleId] = useState('');
  const [appSearchQuery, setAppSearchQuery] = useState('');
  const [viewSearchQuery, setViewSearchQuery] = useState('');
  const [expandedSections, setExpandedSections] = useState({
    deviceInfo: true,
    appControl: true,
    elementInteraction: false,
    systemControls: false
  });
  const [isDeviceMenuOpen, setIsDeviceMenuOpen] = useState(false);
  const [deviceMenuAnchor, setDeviceMenuAnchor] = useState(null);
  const [connectionState, setConnectionState] = useState({
    connected: false,
    clientState: null
  });
  
  // Références
  const screenshotContainerRef = useRef(null);
  const consoleContainerRef = useRef(null);
  
  // Effet pour gérer les mises à jour de l'état de connexion
  useEffect(() => {
    setConnectionState(prev => ({
      ...prev,
      connected: connectionStatus,
      clientState: data?.technicalProperties?.clientState || null
    }));
  }, [connectionStatus, data?.technicalProperties?.clientState]);
  
  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les informations du dispositif
      if (data.technicalProperties.deviceInfo) {
        handleDeviceInfoResults(data.technicalProperties.deviceInfo);
      }
      
      // Traiter les informations des applications
      if (data.technicalProperties.appsList) {
        handleAppsListResults(data.technicalProperties.appsList);
      }
      
      // Traiter les informations de l'application actuelle
      if (data.technicalProperties.appInfo) {
        handleAppInfoResults(data.technicalProperties.appInfo);
      }
      
      // Traiter les captures d'écran
      if (data.technicalProperties.screenshot) {
        handleScreenshotResults(data.technicalProperties.screenshot);
      }
      
      // Traiter la hiérarchie des vues
      if (data.technicalProperties.viewHierarchy) {
        handleViewHierarchyResults(data.technicalProperties.viewHierarchy);
      }
      
      // Traiter les logs du système
      if (data.technicalProperties.console) {
        handleConsoleResults(data.technicalProperties.console);
      }
      
      // Traiter les statistiques de performances
      if (data.technicalProperties.performanceStats) {
        handlePerformanceResults(data.technicalProperties.performanceStats);
      }
      
      // Traiter le statut du réseau
      if (data.technicalProperties.networkStatus) {
        handleNetworkStatusResults(data.technicalProperties.networkStatus);
      }
      
      // Traiter les actions enregistrées
      if (data.technicalProperties.recordedAction) {
        handleRecordedActionResults(data.technicalProperties.recordedAction);
      }
    }
  }, [data]);
  
  // Effet pour faire défiler les logs
  useEffect(() => {
    if (consoleContainerRef.current) {
      consoleContainerRef.current.scrollTop = consoleContainerRef.current.scrollHeight;
    }
  }, [consoleOutput]);
  
  // Handler pour les résultats d'informations du dispositif
  const handleDeviceInfoResults = (deviceInfoData) => {
    setDeviceInfo({
      model: deviceInfoData.model || 'iPhone',
      modelIdentifier: deviceInfoData.modelIdentifier || 'iPhone12,1',
      osVersion: deviceInfoData.osVersion || 'iOS 16.5',
      buildNumber: deviceInfoData.buildNumber || '20F66',
      screenResolution: deviceInfoData.screenResolution || '1170x2532',
      orientation: deviceInfoData.orientation || 'portrait',
      batteryLevel: deviceInfoData.batteryLevel || 85,
      batteryCharging: deviceInfoData.batteryCharging || false,
      connected: true,
      deviceId: deviceInfoData.deviceId || 'XXXXX-XXXXX-XXXXX-XXXXX',
      isSimulator: deviceInfoData.isSimulator || false
    });
    
    setScreenOrientation(deviceInfoData.orientation || 'portrait');
  };
  
  // Handler pour les résultats de liste d'applications
  const handleAppsListResults = (appsListData) => {
    setInstalledApps(appsListData.apps || []);
  };
  
  // Handler pour les résultats d'informations d'application
  const handleAppInfoResults = (appInfoData) => {
    setAppInfo(appInfoData);
    setCurrentView(appInfoData.currentView);
    setAppPermissions(appInfoData.permissions || []);
    setIsAppRunning(appInfoData.isRunning || false);
    setAppProcessInfo(appInfoData.processInfo);
    
    if (appInfoData.bundleId) {
      setCurrentApp(appInfoData.bundleId);
    }
  };
  
  // Handler pour les résultats de capture d'écran
  const handleScreenshotResults = (screenshotData) => {
    if (screenshotData.base64Data) {
      const newScreenshot = {
        id: Date.now(),
        data: screenshotData.base64Data,
        timestamp: new Date(),
        appBundleId: currentApp,
        view: currentView
      };
      
      setScreenshot(newScreenshot);
      setScreenshotHistory(prev => [newScreenshot, ...prev]);
    }
  };
  
  // Handler pour les résultats de hiérarchie des vues
  const handleViewHierarchyResults = (viewHierarchyData) => {
    setViewHierarchy(viewHierarchyData);
  };
  
  // Handler pour les résultats de console
  const handleConsoleResults = (consoleData) => {
    const newLogs = consoleData.logs.map(log => ({
      timestamp: new Date(log.timestamp || Date.now()),
      level: log.level || 'default',
      category: log.category || 'general',
      subsystem: log.subsystem || '',
      message: log.message || ''
    }));
    
    setConsoleOutput(prev => [...prev, ...newLogs]);
  };
  
  // Handler pour les résultats de performances
  const handlePerformanceResults = (performanceData) => {
    setPerformanceStats({
      cpuUsage: performanceData.cpuUsage || 0,
      memoryUsage: performanceData.memoryUsage || 0,
      energy: performanceData.energy || 0,
      networkUsage: performanceData.networkUsage || { rx: 0, tx: 0 }
    });
  };
  
  // Handler pour les résultats de statut réseau
  const handleNetworkStatusResults = (networkStatusData) => {
    setNetworkStatus({
      wifiEnabled: networkStatusData.wifiEnabled || false,
      wifiConnected: networkStatusData.wifiConnected || false,
      cellularEnabled: networkStatusData.cellularEnabled || false,
      cellularConnected: networkStatusData.cellularConnected || false,
      airplaneMode: networkStatusData.airplaneMode || false,
      bluetoothEnabled: networkStatusData.bluetoothEnabled || false
    });
  };
  
  // Handler pour les résultats d'action enregistrée
  const handleRecordedActionResults = (actionData) => {
    if (isRecording) {
      addRecordedAction(actionData);
    }
  };
  
  // Fonction pour démarrer une application
  const launchApp = (bundleId) => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSApp",
        nodeName: "LaunchApp",
        nodeValue: bundleId,
        actionType: "iOS",
        actionValue: `-[XCUIApplication launchWithOptions:]`,
        actionName: "LaunchApp",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'app',
        action: 'launch',
        value: bundleId,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSApp',
      name: `Launch app: ${bundleId}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    setSelectedAppBundleId(bundleId);
    setCurrentApp(bundleId);
    setIsAppRunning(true);
  };
  
  // Fonction pour arrêter une application
  const terminateApp = (bundleId = currentApp) => {
    if (disabled || !connectionState.connected || !bundleId) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSApp",
        nodeName: "TerminateApp",
        nodeValue: bundleId,
        actionType: "iOS",
        actionValue: `-[XCUIApplication terminate]`,
        actionName: "TerminateApp",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'app',
        action: 'terminate',
        value: bundleId,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSApp',
      name: `Terminate app: ${bundleId}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    setIsAppRunning(false);
  };
  
  // Fonction pour réinitialiser une application
  const resetApp = (bundleId = currentApp) => {
    if (disabled || !connectionState.connected || !bundleId) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSApp",
        nodeName: "ResetApp",
        nodeValue: bundleId,
        actionType: "iOS",
        actionValue: `sim.resetContentAndSettings()`,
        actionName: "ResetApp",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'app',
        action: 'reset',
        value: bundleId,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSApp',
      name: `Reset app data: ${bundleId}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour obtenir les informations d'une application
  const getAppInfo = (bundleId = currentApp) => {
    if (disabled || !connectionState.connected || !bundleId) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSApp",
        nodeName: "GetAppInfo",
        nodeValue: bundleId,
        actionType: "iOS",
        actionValue: `getAppInfo('${bundleId}')`,
        actionName: "GetAppInfo",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'iOSApp',
      name: `Get app info: ${bundleId}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour capture d'écran
  const takeScreenshot = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSCapture",
        nodeName: "TakeScreenshot",
        nodeValue: "screenshot",
        actionType: "iOS",
        actionValue: `XCUIScreen.mainScreen().screenshot()`,
        actionName: "TakeScreenshot",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'capture',
        action: 'screenshot',
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSCapture',
      name: 'Take screenshot',
      timestamp: new Date()
    });
  };
  
  // Fonction pour obtenir la hiérarchie des vues
  const getViewHierarchy = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "GetViewHierarchy",
        nodeValue: "viewHierarchy",
        actionType: "iOS",
        actionValue: `app.debugDescription`,
        actionName: "GetViewHierarchy",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'iOSUI',
      name: 'Get view hierarchy',
      timestamp: new Date()
    });
  };
  
  // Fonction pour appuyer sur un élément
  const tapElement = (selector, x = null, y = null) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      x: x,
      y: y
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "TapElement",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: x !== null && y !== null 
          ? `app.tapCoordinate(${x}, ${y})` 
          : `app.${selector}.tap()`,
        actionName: "TapElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'tap',
        selector: selector,
        coordinates: x !== null && y !== null ? { x, y } : undefined,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Tap element: ${selector}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour appui long sur un élément
  const longPressElement = (selector, duration = 1.0, x = null, y = null) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      duration: duration,
      x: x,
      y: y
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "LongPressElement",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: x !== null && y !== null 
          ? `longPressCoordinate(${x}, ${y}, ${duration})` 
          : `app.${selector}.press(forDuration: ${duration})`,
        actionName: "LongPressElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'longPress',
        selector: selector,
        duration: duration,
        coordinates: x !== null && y !== null ? { x, y } : undefined,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Long press element: ${selector} (${duration}s)`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour saisir du texte
  const typeText = (selector, text) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      text: text
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "TypeText",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: `app.${selector}.typeText("${text}")`,
        actionName: "TypeText",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'type',
        selector: selector,
        value: text,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Type text in ${selector}: ${text.length > 20 ? text.substring(0, 20) + '...' : text}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour faire défiler (swipe)
  const swipeScreen = (direction, startX = null, startY = null, endX = null, endY = null) => {
    if (disabled || !connectionState.connected) return;
    
    // Coordonnées par défaut selon la direction
    let coords = {};
    
    if (startX === null || startY === null || endX === null || endY === null) {
      const screenWidth = parseInt(deviceInfo.screenResolution.split('x')[0]);
      const screenHeight = parseInt(deviceInfo.screenResolution.split('x')[1]);
      
      switch (direction) {
        case 'up':
          coords = {
            startX: screenWidth / 2,
            startY: screenHeight * 0.7,
            endX: screenWidth / 2,
            endY: screenHeight * 0.3
          };
          break;
        case 'down':
          coords = {
            startX: screenWidth / 2,
            startY: screenHeight * 0.3,
            endX: screenWidth / 2,
            endY: screenHeight * 0.7
          };
          break;
        case 'left':
          coords = {
            startX: screenWidth * 0.7,
            startY: screenHeight / 2,
            endX: screenWidth * 0.3,
            endY: screenHeight / 2
          };
          break;
        case 'right':
          coords = {
            startX: screenWidth * 0.3,
            startY: screenHeight / 2,
            endX: screenWidth * 0.7,
            endY: screenHeight / 2
          };
          break;
        default:
          coords = {
            startX: screenWidth / 2,
            startY: screenHeight / 2,
            endX: screenWidth / 2,
            endY: screenHeight / 2
          };
      }
    } else {
      coords = { startX, startY, endX, endY };
    }
    
    const payload = {
      direction,
      startX: coords.startX,
      startY: coords.startY,
      endX: coords.endX,
      endY: coords.endY
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "SwipeScreen",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: `app.swipeFrom(CGPoint(x: ${Math.round(coords.startX)}, y: ${Math.round(coords.startY)}), to: CGPoint(x: ${Math.round(coords.endX)}, y: ${Math.round(coords.endY)}))`,
        actionName: "SwipeScreen",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'swipe',
        direction: direction,
        coordinates: coords,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Swipe ${direction}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour le double tap
  const doubleTapElement = (selector, x = null, y = null) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      x: x,
      y: y
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "DoubleTapElement",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: x !== null && y !== null 
          ? `doubleTapCoordinate(${x}, ${y})` 
          : `app.${selector}.doubleTap()`,
        actionName: "DoubleTapElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'doubleTap',
        selector: selector,
        coordinates: x !== null && y !== null ? { x, y } : undefined,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Double tap element: ${selector}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour le pinch
  const pinchElement = (selector, scale, velocity = 1.0) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      scale: scale,
      velocity: velocity
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "PinchElement",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: `app.${selector}.pinch(withScale: ${scale}, velocity: ${velocity})`,
        actionName: "PinchElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'pinch',
        selector: selector,
        scale: scale,
        velocity: velocity,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Pinch element: ${selector} (scale: ${scale})`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour la rotation
  const rotateElement = (selector, rotation, velocity = 1.0) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      rotation: rotation,
      velocity: velocity
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "RotateElement",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: `app.${selector}.rotate(${rotation}, withVelocity: ${velocity})`,
        actionName: "RotateElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'rotate',
        selector: selector,
        rotation: rotation,
        velocity: velocity,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Rotate element: ${selector} (rotation: ${rotation})`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour appuyer sur un bouton système
  const pressButton = (button) => {
    if (disabled || !connectionState.connected) return;
    
    const buttonMapping = {
      'home': 'XCUIDevice.shared.press(.home)',
      'volumeUp': 'XCUIDevice.shared.press(.volumeUp)',
      'volumeDown': 'XCUIDevice.shared.press(.volumeDown)',
      'power': 'XCUIDevice.shared.press(.power)',
      'sideButton': 'XCUIDevice.shared.press(.sideButton)'
    };
    
    const buttonAction = buttonMapping[button] || `XCUIDevice.shared.press(.${button})`;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSUI",
        nodeName: "PressButton",
        nodeValue: button,
        actionType: "iOS",
        actionValue: buttonAction,
        actionName: "PressButton",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'ui',
        action: 'pressButton',
        value: button,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSUI',
      name: `Press button: ${button}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour attendre un élément
  const waitForElement = (selector, timeout = 5) => {
    if (disabled || !connectionState.connected) return;
    
    const payload = {
      selector: selector,
      timeout: timeout
    };
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSWait",
        nodeName: "WaitForElement",
        nodeValue: JSON.stringify(payload),
        actionType: "iOS",
        actionValue: `app.${selector}.waitForExistence(timeout: ${timeout})`,
        actionName: "WaitForElement",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'wait',
        action: 'waitForElement',
        selector: selector,
        timeout: timeout,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSWait',
      name: `Wait for element: ${selector}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour modifier l'orientation de l'écran
  const rotateScreen = () => {
    if (disabled || !connectionState.connected) return;
    
    const newOrientation = screenOrientation === 'portrait' ? 'landscape' : 'portrait';
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSDevice",
        nodeName: "RotateScreen",
        nodeValue: newOrientation,
        actionType: "iOS",
        actionValue: `XCUIDevice.shared.orientation = .${newOrientation}`,
        actionName: "RotateScreen",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'device',
        action: 'rotate',
        value: newOrientation,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSDevice',
      name: `Rotate screen to ${newOrientation}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    setScreenOrientation(newOrientation);
  };
  
  // Fonction pour changer un paramètre du système
  const changeSystemSetting = (setting, value) => {
    if (disabled || !connectionState.connected) return;
    
    let command = '';
    
    switch (setting) {
      case 'wifi':
        command = `toggleWiFi(${value})`;
        break;
      case 'cellular':
        command = `toggleCellular(${value})`;
        break;
      case 'airplane':
        command = `toggleAirplaneMode(${value})`;
        break;
      case 'bluetooth':
        command = `toggleBluetooth(${value})`;
        break;
      case 'location':
        command = `toggleLocationServices(${value})`;
        break;
      case 'brightness':
        command = `setScreenBrightness(${value})`;
        break;
      case 'volume':
        command = `setSystemVolume(${value * 100})`;
        break;
      default:
        return;
    }
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSDevice",
        nodeName: "ChangeSystemSetting",
        nodeValue: `${setting}:${value}`,
        actionType: "iOS",
        actionValue: command,
        actionName: "ChangeSystemSetting",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    if (isRecording) {
      addRecordedAction({
        type: 'device',
        action: 'changeSetting',
        setting: setting,
        value: value,
        timestamp: new Date()
      });
    }
    
    setLastAction({
      type: 'iOSDevice',
      name: `Change ${setting} to ${value}`,
      timestamp: new Date()
    });
    
    // Mettre à jour l'état local
    switch (setting) {
      case 'wifi':
        setNetworkStatus(prev => ({ ...prev, wifiEnabled: value }));
        break;
      case 'cellular':
        setNetworkStatus(prev => ({ ...prev, cellularEnabled: value }));
        break;
      case 'airplane':
        setNetworkStatus(prev => ({ ...prev, airplaneMode: value }));
        break;
      case 'bluetooth':
        setNetworkStatus(prev => ({ ...prev, bluetoothEnabled: value }));
        break;
      case 'location':
        setDeviceSettings(prev => ({ ...prev, locationEnabled: value }));
        break;
      case 'brightness':
        setDeviceSettings(prev => ({ ...prev, brightness: value }));
        break;
      case 'volume':
        setDeviceSettings(prev => ({ ...prev, volume: value }));
        break;
    }
  };
  
  // Fonction pour gérer les logs console
  const getConsoleLogs = (filter = consoleFilter) => {
    if (disabled || !connectionState.connected) return;
    
    let command = 'getConsoleLogs(';
    
    if (filter.subsystem) {
      command += `subsystem: "${filter.subsystem}", `;
    }
    
    if (filter.level !== 'default') {
      command += `level: "${filter.level}", `;
    }
    
    if (filter.category) {
      command += `category: "${filter.category}", `;
    }
    
    if (filter.search) {
      command += `search: "${filter.search}", `;
    }
    
    command += 'limit: 100)';
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSDebug",
        nodeName: "GetConsoleLogs",
        nodeValue: JSON.stringify(filter),
        actionType: "iOS",
        actionValue: command,
        actionName: "GetConsoleLogs",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'iOSDebug',
      name: 'Get console logs',
      timestamp: new Date()
    });
  };
  
  // Fonction pour effacer les logs console
  const clearConsoleLogs = () => {
    if (disabled || !connectionState.connected) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSDebug",
        nodeName: "ClearConsoleLogs",
        nodeValue: "clear",
        actionType: "iOS",
        actionValue: "clearConsoleLogs()",
        actionName: "ClearConsoleLogs",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'iOSDebug',
      name: 'Clear console logs',
      timestamp: new Date()
    });
    
    // Effacer les logs locaux
    setConsoleOutput([]);
  };
  
  // Fonction pour obtenir les statistiques de performances
  const getPerformanceStats = (bundleId = currentApp) => {
    if (disabled || !connectionState.connected || !bundleId) return;
    
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSDebug",
        nodeName: "GetPerformanceStats",
        nodeValue: bundleId,
        actionType: "iOS",
        actionValue: `getPerformanceStats('${bundleId}')`,
        actionName: "GetPerformanceStats",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setLastAction({
      type: 'iOSDebug',
      name: `Get performance stats for ${bundleId}`,
      timestamp: new Date()
    });
  };
  
  // Fonction pour démarrer l'enregistrement
  const startRecording = () => {
    setIsRecording(true);
    setRecordedActions([]);
    setRecordingStartTime(new Date());
    
    // Notifier l'agent de démarrer l'enregistrement
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSRecord",
        nodeName: "StartRecording",
        nodeValue: "true",
        actionType: "iOS",
        actionValue: "startRecording()",
        actionName: "StartRecording",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setSnackbar({
      open: true,
      message: 'Enregistrement démarré',
      severity: 'success'
    });
    
    setLastAction({
      type: 'iOSRecord',
      name: 'Start recording',
      timestamp: new Date()
    });
  };
  
  // Fonction pour arrêter l'enregistrement
  const stopRecording = () => {
    setIsRecording(false);
    
    // Notifier l'agent d'arrêter l'enregistrement
    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "iOSRecord",
        nodeName: "StopRecording",
        nodeValue: "true",
        actionType: "iOS",
        actionValue: "stopRecording()",
        actionName: "StopRecording",
        timestamp: Date.now()
      }
    };
    
    onInteractionSent(interactionPayload);
    
    setSnackbar({
      open: true,
      message: `Enregistrement arrêté - ${recordedActions.length} actions enregistrées`,
      severity: 'info'
    });
    
    setLastAction({
      type: 'iOSRecord',
      name: 'Stop recording',
      timestamp: new Date()
    });
  };
  
  // Fonction pour ajouter une action enregistrée
  const addRecordedAction = (action) => {
    setRecordedActions(prev => [...prev, action]);
  };
  
  // Fonction pour exécuter une action sur un élément de l'interface
  const executeElementAction = () => {
    if (!elementSelector || !selectedActionType || disabled || !connectionState.connected) return;
    
    switch (selectedActionType) {
      case 'tap':
        tapElement(elementSelector);
        break;
      case 'longPress':
        longPressElement(elementSelector, parseFloat(actionInput) || 1.0);
        break;
      case 'doubleTap':
        doubleTapElement(elementSelector);
        break;
      case 'type':
        if (actionInput) {
          typeText(elementSelector, actionInput);
        }
        break;
      case 'wait':
        waitForElement(elementSelector, parseInt(waitTimeout) || 5);
        break;
      case 'pinch':
        pinchElement(elementSelector, parseFloat(actionInput) || 0.5);
        break;
      case 'rotate':
        rotateElement(elementSelector, parseFloat(actionInput) || 90);
        break;
      default:
        console.warn(`Action non prise en charge: ${selectedActionType}`);
    }
    
    // Réinitialiser les champs après l'action
    setActionInput('');
  };
  
  // Fonction pour exporter les actions enregistrées
  const exportRecordedActions = () => {
    if (recordedActions.length === 0) {
      setSnackbar({
        open: true,
        message: 'Aucune action à exporter',
        severity: 'warning'
      });
      return;
    }
    
    // Convertir les actions en script XCTest pour iOS
    let script = `// Script XCTest généré le ${new Date().toLocaleString()}\n`;
    script += `import XCTest\n\n`;
    script += `class iOSTestCase: XCTestCase {\n\n`;
    script += `    let app = XCUIApplication()\n`;
    script += `    \n`;
    script += `    override func setUp() {\n`;
    script += `        super.setUp()\n`;
    script += `        continueAfterFailure = false\n`;
    
    // Trouver la première action de lancement d'application
    const launchAction = recordedActions.find(action => action.type === 'app' && action.action === 'launch');
    if (launchAction) {
      script += `        app.bundleIdentifier = "${launchAction.value}"\n`;
    }
    
    script += `        app.launch()\n`;
    script += `    }\n\n`;
    
    script += `    func testSequence() {\n`;
    
    // Ajouter chaque action
    const launchExcluded = !!launchAction; // Si on a déjà inclus l'action de lancement dans setUp
    
    // Indentation pour le bloc func
    const indent = '        ';
    
    // Ajouter chaque action
    recordedActions.forEach((action, index) => {
      // Sauter l'action de lancement si elle a déjà été incluse dans setUp
      if (index === 0 && launchExcluded && action.type === 'app' && action.action === 'launch') {
        return;
      }
      
      if (index > 0) {
        script += `\n${indent}// Petit délai entre les actions\n`;
        script += `${indent}sleep(0.5)\n\n`;
      }
      
      switch (action.type) {
        case 'app':
          if (action.action === 'launch') {
            script += `${indent}// Lancer l'application\n`;
            script += `${indent}let app = XCUIApplication(bundleIdentifier: "${action.value}")\n`;
            script += `${indent}app.launch()\n`;
          } else if (action.action === 'terminate') {
            script += `${indent}// Arrêter l'application\n`;
            script += `${indent}app.terminate()\n`;
          } else if (action.action === 'reset') {
            script += `${indent}// Réinitialiser les données de l'application\n`;
            script += `${indent}// Note: Cette fonctionnalité nécessite une configuration spéciale\n`;
            script += `${indent}// et n'est pas directement disponible dans XCTest\n`;
          }
          break;
          
        case 'ui':
          if (action.action === 'tap') {
            if (action.coordinates) {
              script += `${indent}// Appuyer aux coordonnées (${action.coordinates.x}, ${action.coordinates.y})\n`;
              script += `${indent}app.coordinate(withNormalizedOffset: CGVector(dx: 0, dy: 0))\n`;
              script += `${indent}    .withOffset(CGVector(dx: ${action.coordinates.x}, dy: ${action.coordinates.y}))\n`;
              script += `${indent}    .tap()\n`;
            } else {
              script += `${indent}// Appuyer sur l'élément\n`;
              script += `${indent}app.${action.selector}.tap()\n`;
            }
          } else if (action.action === 'longPress') {
            if (action.coordinates) {
              script += `${indent}// Appui long aux coordonnées (${action.coordinates.x}, ${action.coordinates.y})\n`;
              script += `${indent}app.coordinate(withNormalizedOffset: CGVector(dx: 0, dy: 0))\n`;
              script += `${indent}    .withOffset(CGVector(dx: ${action.coordinates.x}, dy: ${action.coordinates.y}))\n`;
              script += `${indent}    .press(forDuration: ${action.duration || 1.0})\n`;
            } else {
              script += `${indent}// Appui long sur l'élément\n`;
              script += `${indent}app.${action.selector}.press(forDuration: ${action.duration || 1.0})\n`;
            }
          } else if (action.action === 'doubleTap') {
            script += `${indent}// Double tap sur l'élément\n`;
            script += `${indent}app.${action.selector}.doubleTap()\n`;
          } else if (action.action === 'type') {
            script += `${indent}// Saisir du texte dans l'élément\n`;
            script += `${indent}app.${action.selector}.tap()\n`;
            script += `${indent}app.${action.selector}.typeText("${action.value}")\n`;
          } else if (action.action === 'swipe') {
            const { direction, coordinates } = action;
            script += `${indent}// Défilement ${direction}\n`;
            
            if (coordinates) {
              script += `${indent}// De (${coordinates.startX}, ${coordinates.startY}) à (${coordinates.endX}, ${coordinates.endY})\n`;
              script += `${indent}let start = app.coordinate(withNormalizedOffset: CGVector(dx: 0, dy: 0))\n`;
              script += `${indent}    .withOffset(CGVector(dx: ${coordinates.startX}, dy: ${coordinates.startY}))\n`;
              script += `${indent}let end = app.coordinate(withNormalizedOffset: CGVector(dx: 0, dy: 0))\n`;
              script += `${indent}    .withOffset(CGVector(dx: ${coordinates.endX}, dy: ${coordinates.endY}))\n`;
              script += `${indent}start.press(forDuration: 0.1, thenDragTo: end)\n`;
            } else {
              script += `${indent}app.swipeUp()\n`;
            }
          } else if (action.action === 'pinch') {
            script += `${indent}// Pinch sur l'élément (zoom: ${action.scale})\n`;
            script += `${indent}app.${action.selector}.pinch(withScale: ${action.scale}, velocity: ${action.velocity || 1.0})\n`;
          } else if (action.action === 'rotate') {
            script += `${indent}// Rotation de l'élément (${action.rotation} degrés)\n`;
            script += `${indent}app.${action.selector}.rotate(${action.rotation}, withVelocity: ${action.velocity || 1.0})\n`;
          } else if (action.action === 'pressButton') {
            script += `${indent}// Appuyer sur le bouton système: ${action.value}\n`;
            script += `${indent}XCUIDevice.shared.press(.${action.value})\n`;
          }
          break;
          
        case 'wait':
          if (action.action === 'waitForElement') {
            script += `${indent}// Attendre l'élément: ${action.selector}\n`;
            script += `${indent}let exists = app.${action.selector}.waitForExistence(timeout: ${action.timeout || 5})\n`;
            script += `${indent}XCTAssert(exists, "L'élément n'est pas apparu dans le délai imparti")\n`;
          }
          break;
          
        case 'device':
          if (action.action === 'rotate') {
            script += `${indent}// Changer l'orientation en ${action.value}\n`;
            script += `${indent}XCUIDevice.shared.orientation = .${action.value}\n`;
          } else if (action.action === 'changeSetting') {
            script += `${indent}// Cette action nécessite l'utilisation d'une extension privée ou d'un framework spécifique\n`;
            script += `${indent}// Modifier le paramètre: ${action.setting} à ${action.value}\n`;
          }
          break;
          
        case 'capture':
          if (action.action === 'screenshot') {
            script += `${indent}// Prendre une capture d'écran\n`;
            script += `${indent}let screenshot = XCUIScreen.main.screenshot()\n`;
            script += `${indent}let attachment = XCTAttachment(screenshot: screenshot)\n`;
            script += `${indent}attachment.lifetime = .keepAlways\n`;
            script += `${indent}add(attachment)\n`;
          }
          break;
      }
    });
    
    script += `    }\n`;
    script += `}\n`;
    
    // Créer un blob et déclencher le téléchargement
    const blob = new Blob([script], { type: 'text/swift' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `ios-test-${new Date().getTime()}.swift`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    
    setSnackbar({
      open: true,
      message: 'Script exporté avec succès',
      severity: 'success'
    });
  };
  
  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Apple sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              iOS {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionState.connected ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionState.connected ? 'success' : 'error'}
            label={connectionState.connected ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Appareil
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {deviceInfo.model} ({deviceInfo.modelIdentifier})
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                iOS
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {deviceInfo.osVersion} (Build {deviceInfo.buildNumber})
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Application active
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {currentApp || 'Aucune'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant d'informations du dispositif
  const DeviceInfoComponent = () => {
    return (
      <Accordion 
        expanded={expandedSections.deviceInfo} 
        onChange={() => setExpandedSections(prev => ({ ...prev, deviceInfo: !prev.deviceInfo }))}
        sx={{ mb: 2 }}
      >
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Apple size={18} style={{ marginRight: 8 }} />
            Informations sur l'appareil
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Smartphone size={16} style={{ marginRight: 8 }} />
                  Appareil
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Modèle:</Typography>
                    <Typography variant="body1">{deviceInfo.model}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Identifiant:</Typography>
                    <Typography variant="body1">{deviceInfo.modelIdentifier}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">iOS:</Typography>
                    <Typography variant="body1">{deviceInfo.osVersion}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Build:</Typography>
                    <Typography variant="body1">{deviceInfo.buildNumber}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Type:</Typography>
                    <Chip 
                      size="small" 
                      label={deviceInfo.isSimulator ? "Simulateur" : "Appareil physique"} 
                      color={deviceInfo.isSimulator ? "warning" : "success"} 
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Monitor size={16} style={{ marginRight: 8 }} />
                  Écran
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Résolution:</Typography>
                    <Typography variant="body1">{deviceInfo.screenResolution}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Orientation:</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1">{deviceInfo.orientation}</Typography>
                      <IconButton size="small" onClick={rotateScreen} disabled={disabled || !connectionState.connected}>
                        <Rotate3d size={16} />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Batterie:</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {deviceInfo.batteryCharging ? (
                        <BatteryCharging size={18} color="green" style={{ marginRight: 8 }} />
                      ) : deviceInfo.batteryLevel < 20 ? (
                        <BatteryLow size={18} color="red" style={{ marginRight: 8 }} />
                      ) : (
                        <Battery size={18} style={{ marginRight: 8 }} />
                      )}
                      <LinearProgress 
                        variant="determinate" 
                        value={deviceInfo.batteryLevel} 
                        sx={{ flex: 1, height: 8, borderRadius: 4 }}
                        color={deviceInfo.batteryLevel < 20 ? "error" : "success"}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {deviceInfo.batteryLevel}%
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Wifi size={16} style={{ marginRight: 8 }} />
                  Réseau
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.wifiEnabled}
                            onChange={(e) => changeSystemSetting('wifi', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="WiFi"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.cellularEnabled}
                            onChange={(e) => changeSystemSetting('cellular', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="Cellulaire"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.bluetoothEnabled}
                            onChange={(e) => changeSystemSetting('bluetooth', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="Bluetooth"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={networkStatus.airplaneMode}
                            onChange={(e) => changeSystemSetting('airplane', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="Mode avion"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <Settings size={16} style={{ marginRight: 8 }} />
                  Paramètres système
                </Typography>
                
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Luminosité:</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <ZoomOut size={16} style={{ marginRight: 8 }} />
                      <Slider
                        value={deviceSettings.brightness}
                        min={0}
                        max={100}
                        onChange={(e, val) => setDeviceSettings(prev => ({ ...prev, brightness: val }))}
                        onChangeCommitted={(e, val) => changeSystemSetting('brightness', val / 100)}
                        disabled={disabled || !connectionState.connected}
                        sx={{ mx: 2 }}
                      />
                      <ZoomIn size={16} style={{ marginLeft: 8 }} />
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Volume:</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <VolumeX size={16} style={{ marginRight: 8 }} />
                      <Slider
                        value={deviceSettings.volume}
                        min={0}
                        max={100}
                        onChange={(e, val) => setDeviceSettings(prev => ({ ...prev, volume: val }))}
                        onChangeCommitted={(e, val) => changeSystemSetting('volume', val / 100)}
                        disabled={disabled || !connectionState.connected}
                        sx={{ mx: 2 }}
                      />
                      <Volume2 size={16} style={{ marginLeft: 8 }} />
                    </Box>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={deviceSettings.locationEnabled}
                            onChange={(e) => changeSystemSetting('location', e.target.checked)}
                            disabled={disabled || !connectionState.connected}
                          />
                        }
                        label="Localisation"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Composant de contrôle d'application
  const AppControlComponent = () => {
    const filteredApps = appSearchQuery
      ? installedApps.filter(app => 
          app.name.toLowerCase().includes(appSearchQuery.toLowerCase()) ||
          app.bundleId.toLowerCase().includes(appSearchQuery.toLowerCase())
        )
      : installedApps;
    
    return (
      <Accordion 
        expanded={expandedSections.appControl} 
        onChange={() => setExpandedSections(prev => ({ ...prev, appControl: !prev.appControl }))}
        sx={{ mb: 2 }}
      >
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Package size={18} style={{ marginRight: 8 }} />
            Contrôle des applications
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>Applications installées</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Rechercher une application..."
                  value={appSearchQuery}
                  onChange={(e) => setAppSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search size={18} />
                      </InputAdornment>
                    )
                  }}
                  sx={{ mb: 1 }}
                />
                
                <Paper variant="outlined" sx={{ height: 200, overflow: 'auto' }}>
                  <List dense sx={{ pt: 0 }}>
                    {filteredApps.length === 0 ? (
                      <ListItem>
                        <ListItemText primary="Aucune application trouvée" />
                      </ListItem>
                    ) : (
                      filteredApps.map((app, index) => (
                        <ListItem 
                          key={index}
                          disablePadding
                          secondaryAction={
                            <IconButton 
                              edge="end" 
                              size="small"
                              onClick={() => launchApp(app.bundleId)}
                              disabled={disabled || !connectionState.connected}
                            >
                              <Play size={16} />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            dense
                            onClick={() => {
                              setSelectedAppBundleId(app.bundleId);
                              getAppInfo(app.bundleId);
                            }}
                            selected={selectedAppBundleId === app.bundleId}
                          >
                            <ListItemIcon sx={{ minWidth: 36 }}>
                              <Package size={20} />
                            </ListItemIcon>
                            <ListItemText 
                              primary={app.name} 
                              secondary={app.bundleId}
                              primaryTypographyProps={{ noWrap: true }}
                              secondaryTypographyProps={{ noWrap: true }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))
                    )}
                  </List>
                </Paper>
              </Box>
              
              <Box sx={{ mb: 2, display: 'flex', gap: 1 }}>
                <Button
                  variant="outlined"
                  startIcon={<Play />}
                  onClick={() => launchApp(selectedAppBundleId)}
                  disabled={!selectedAppBundleId || disabled || !connectionState.connected}
                  size="small"
                >
                  Lancer
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<StopCircle />}
                  onClick={() => terminateApp(selectedAppBundleId)}
                  disabled={!selectedAppBundleId || disabled || !connectionState.connected}
                  size="small"
                  color="error"
                >
                  Terminer
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Trash2 />}
                  onClick={() => resetApp(selectedAppBundleId)}
                  disabled={!selectedAppBundleId || disabled || !connectionState.connected}
                  size="small"
                >
                  Réinitialiser
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
                <Typography variant="subtitle2" gutterBottom>
                  {currentApp ? `Informations: ${currentApp}` : 'Aucune application sélectionnée'}
                </Typography>
                
                {appInfo ? (
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          Vue actuelle:
                        </Typography>
                        <Typography variant="body2" noWrap>
                          {currentView || 'Inconnue'}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Version:
                        </Typography>
                        <Typography variant="body2">
                          {appInfo.version || 'Inconnue'} ({appInfo.buildNumber || '?'})
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          État:
                        </Typography>
                        <Chip 
                          size="small" 
                          label={isAppRunning ? "En cours d'exécution" : "Arrêtée"} 
                          color={isAppRunning ? "success" : "default"} 
                        />
                      </Grid>
                      
                      {appProcessInfo && (
                        <>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              PID:
                            </Typography>
                            <Typography variant="body2">
                              {appProcessInfo.pid || 'N/A'}
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              Mémoire utilisée:
                            </Typography>
                            <Typography variant="body2">
                              {appProcessInfo.memoryUsage ? `${Math.round(appProcessInfo.memoryUsage)} MB` : 'N/A'}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    
                    <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                      Permissions
                    </Typography>
                    
                    <Box sx={{ maxHeight: 100, overflow: 'auto', mb: 1 }}>
                      {appPermissions.length === 0 ? (
                        <Typography variant="body2" color="text.secondary">
                          Aucune permission
                        </Typography>
                      ) : (
                        <Grid container spacing={0.5}>
                          {appPermissions.map((permission, index) => (
                            <Grid item key={index}>
                              <Chip 
                                size="small" 
                                label={permission.name} 
                                color={permission.status === 'granted' ? "success" : "default"} 
                                variant={permission.status === 'granted' ? "filled" : "outlined"}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                      Sélectionnez une application pour voir ses détails
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Composant d'interface et contrôles systèmes
  const UiControlsComponent = () => {
    return (
      <Accordion 
        expanded={expandedSections.elementInteraction} 
        onChange={() => setExpandedSections(prev => ({ ...prev, elementInteraction: !prev.elementInteraction }))}
        sx={{ mb: 2 }}
      >
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <TouchpadOff size={18} style={{ marginRight: 8 }} />
            Contrôles et interactions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>Navigation système</Typography>
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<Home />}
                  onClick={() => pressButton('home')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Accueil
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Volume2 />}
                  onClick={() => pressButton('volumeUp')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Vol +
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<VolumeX />}
                  onClick={() => pressButton('volumeDown')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Vol -
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Lock />}
                  onClick={() => pressButton('sideButton')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                  color="error"
                >
                  Verrouiller
                </Button>
              </Box>
              
              <Typography variant="subtitle2" gutterBottom>Défilement</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => swipeScreen('up')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                  sx={{ my: 1, width: 120 }}
                >
                  <ArrowUp />
                </Button>
                
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Button
                    variant="outlined"
                    onClick={() => swipeScreen('left')}
                    disabled={disabled || !connectionState.connected}
                    size="small"
                    sx={{ width: 120 }}
                  >
                    <ArrowLeft />
                  </Button>
                  
                  <Button
                    variant="outlined"
                    onClick={() => rotateScreen()}
                    disabled={disabled || !connectionState.connected}
                    size="small"
                    sx={{ width: 60, height: 60, minWidth: 60 }}
                  >
                    <Rotate3d />
                  </Button>
                  
                  <Button
                    variant="outlined"
                    onClick={() => swipeScreen('right')}
                    disabled={disabled || !connectionState.connected}
                    size="small"
                    sx={{ width: 120 }}
                  >
                    <ArrowRight />
                  </Button>
                </Box>
                
                <Button
                  variant="outlined"
                  onClick={() => swipeScreen('down')}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                  sx={{ my: 1, width: 120 }}
                >
                  <ArrowDown />
                </Button>
              </Box>
              
              <Typography variant="subtitle2" gutterBottom>Gestes avancés</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                <Button
                  variant="outlined"
                  startIcon={<Minimize2 />}
                  onClick={() => {
                    if (selectedElement) {
                      pinchElement(selectedElement.identifier || 'buttons.button', 0.5);
                    } else {
                      setSnackbar({
                        open: true,
                        message: 'Sélectionnez d\'abord un élément dans la hiérarchie des vues',
                        severity: 'warning'
                      });
                    }
                  }}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Pinch
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Maximize2 />}
                  onClick={() => {
                    if (selectedElement) {
                      pinchElement(selectedElement.identifier || 'buttons.button', 2.0);
                    } else {
                      setSnackbar({
                        open: true,
                        message: 'Sélectionnez d\'abord un élément dans la hiérarchie des vues',
                        severity: 'warning'
                      });
                    }
                  }}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Spread
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<RotateCw />}
                  onClick={() => {
                    if (selectedElement) {
                      rotateElement(selectedElement.identifier || 'buttons.button', 90);
                    } else {
                      setSnackbar({
                        open: true,
                        message: 'Sélectionnez d\'abord un élément dans la hiérarchie des vues',
                        severity: 'warning'
                      });
                    }
                  }}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Rotation
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<ZoomInIcon />}
                  onClick={() => {
                    if (selectedElement) {
                      doubleTapElement(selectedElement.identifier || 'buttons.button');
                    } else {
                      setSnackbar({
                        open: true,
                        message: 'Sélectionnez d\'abord un élément dans la hiérarchie des vues',
                        severity: 'warning'
                      });
                    }
                  }}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Double tap
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>Interaction avec les éléments</Typography>
              <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                <InputLabel>Type d'action</InputLabel>
                <Select
                  value={selectedActionType}
                  onChange={(e) => setSelectedActionType(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                >
                  <MenuItem value="tap">Appuyer (tap)</MenuItem>
                  <MenuItem value="longPress">Appui long</MenuItem>
                  <MenuItem value="doubleTap">Double tap</MenuItem>
                  <MenuItem value="type">Saisir texte</MenuItem>
                  <MenuItem value="wait">Attendre l'élément</MenuItem>
                  <MenuItem value="pinch">Pinch</MenuItem>
                  <MenuItem value="rotate">Rotation</MenuItem>
                </Select>
              </FormControl>
              
              <TextField
                fullWidth
                size="small"
                label="Sélecteur"
                placeholder="Ex: buttons.button OR textFields.textField"
                value={elementSelector}
                onChange={(e) => setElementSelector(e.target.value)}
                disabled={disabled || !connectionState.connected}
                sx={{ mb: 1 }}
                helperText="Format: type.identifier OR * identifiant"
              />
              
              {(selectedActionType === 'type') && (
                <TextField
                  fullWidth
                  size="small"
                  label="Texte à saisir"
                  value={actionInput}
                  onChange={(e) => setActionInput(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                />
              )}
              
              {(selectedActionType === 'longPress') && (
                <TextField
                  fullWidth
                  size="small"
                  label="Durée (secondes)"
                  type="number"
                  value={actionInput || '1.0'}
                  onChange={(e) => setActionInput(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                  inputProps={{ step: 0.1, min: 0.1, max: 10 }}
                />
              )}
              
              {selectedActionType === 'wait' && (
                <TextField
                  fullWidth
                  size="small"
                  label="Timeout (secondes)"
                  type="number"
                  value={waitTimeout}
                  onChange={(e) => setWaitTimeout(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                  inputProps={{ step: 1, min: 1, max: 60 }}
                />
              )}
              
              {(selectedActionType === 'pinch') && (
                <TextField
                  fullWidth
                  size="small"
                  label="Scale (0.1-2.0)"
                  type="number"
                  value={actionInput || '0.5'}
                  onChange={(e) => setActionInput(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                  inputProps={{ step: 0.1, min: 0.1, max: 2.0 }}
                  helperText="0.5 = pinch, 2.0 = spread"
                />
              )}
              
              {(selectedActionType === 'rotate') && (
                <TextField
                  fullWidth
                  size="small"
                  label="Rotation (degrés)"
                  type="number"
                  value={actionInput || '90'}
                  onChange={(e) => setActionInput(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ mb: 1 }}
                  inputProps={{ step: 15, min: -180, max: 180 }}
                />
              )}
              
              <Button
                fullWidth
                variant="contained"
                onClick={executeElementAction}
                disabled={!elementSelector || disabled || !connectionState.connected}
                startIcon={<PlayCircle />}
              >
                Exécuter l'action
              </Button>
              
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>Informations sur l'interface</Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="outlined"
                  startIcon={<Layers />}
                  onClick={getViewHierarchy}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Hiérarchie des vues
                </Button>
                
                <Button
                  variant="outlined"
                  startIcon={<Camera />}
                  onClick={takeScreenshot}
                  disabled={disabled || !connectionState.connected}
                  size="small"
                >
                  Capture d'écran
                </Button><FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={highlightBounds}
                      onChange={(e) => setHighlightBounds(e.target.checked)}
                      disabled={disabled || !connectionState.connected || !viewHierarchy}
                    />
                  }
                  label="Afficher les bordures"
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Composant de capture d'écran
  const ScreenshotComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Image size={16} style={{ marginRight: 8 }} />
          Capture d'écran
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button
            size="small"
            startIcon={<Image />}
            onClick={takeScreenshot}
            disabled={disabled || !connectionState.connected}
          >
            Prendre une capture d'écran
          </Button>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 1 }}>Zoom:</Typography>
            <IconButton
              size="small"
              onClick={() => setZoomLevel(prev => Math.max(0.1, prev - 0.1))}
              disabled={zoomLevel <= 0.1}
            >
              <ZoomOut size={16} />
            </IconButton>
            
            <Typography variant="body2" sx={{ mx: 1 }}>
              {Math.round(zoomLevel * 100)}%
            </Typography>
            
            <IconButton
              size="small"
              onClick={() => setZoomLevel(prev => Math.min(2, prev + 0.1))}
              disabled={zoomLevel >= 2}
            >
              <ZoomIn size={16} />
            </IconButton>
          </Box>
        </Box>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Paper 
              variant="outlined" 
              sx={{ 
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                overflow: 'hidden',
                minHeight: 400
              }}
              ref={screenshotContainerRef}
            >
              {screenshot ? (
                <Box
                  sx={{
                    position: 'relative',
                    transform: `scale(${zoomLevel})`,
                    transition: 'transform 0.2s',
                    transformOrigin: 'center',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                >
                  <Typography variant="caption" sx={{ position: 'absolute', top: 4, left: 4, bgcolor: 'rgba(0,0,0,0.5)', color: 'white', px: 1, borderRadius: 1 }}>
                    {new Date(screenshot.timestamp).toLocaleTimeString()}
                  </Typography>
                  
                  <img
                    src={`data:image/png;base64,${screenshot.data}`}
                    alt="Capture d'écran de l'appareil"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '60vh',
                      objectFit: 'contain',
                      transform: screenOrientation === 'landscape' ? 'rotate(90deg)' : 'none'
                    }}
                  />
                </Box>
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <AlertTriangle size={48} color="#f57c00" />
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Aucune capture d'écran disponible
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Utilisez le bouton "Prendre une capture d'écran" pour capturer l'écran actuel
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle2" gutterBottom>
              Historique des captures
            </Typography>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 'calc(100% - 24px)',
                overflow: 'auto'
              }}
            >
              {screenshotHistory.length === 0 ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    Aucun historique
                  </Typography>
                </Box>
              ) : (
                <List dense sx={{ pt: 0 }}>
                  {screenshotHistory.map((shot, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      divider={index < screenshotHistory.length - 1}
                    >
                      <ListItemButton 
                        dense
                        onClick={() => setScreenshot(shot)}
                        selected={screenshot && screenshot.id === shot.id}
                      >
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <Image size={20} />
                        </ListItemIcon>
                        <ListItemText
                          primary={new Date(shot.timestamp).toLocaleTimeString()}
                          secondary={shot.appBundleId || 'N/A'}
                          primaryTypographyProps={{ variant: 'body2' }}
                          secondaryTypographyProps={{ variant: 'caption', noWrap: true }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant d'enregistrement d'actions
  const RecordingComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <PlayCircle size={16} style={{ marginRight: 8 }} />
          Enregistrement d'actions
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2,
          pb: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Box>
            <Button
              variant={isRecording ? "contained" : "outlined"}
              color={isRecording ? "error" : "primary"}
              startIcon={isRecording ? <StopCircle /> : <PlayCircle />}
              onClick={isRecording ? stopRecording : startRecording}
              disabled={disabled || !connectionState.connected}
            >
              {isRecording ? 'Arrêter l\'enregistrement' : 'Démarrer l\'enregistrement'}
            </Button>
            
            {isRecording && recordingStartTime && (
              <Typography variant="caption" sx={{ ml: 2, color: 'text.secondary' }}>
                Enregistrement en cours: {Math.floor((new Date() - recordingStartTime) / 1000)} secondes
              </Typography>
            )}
          </Box>
          
          <Box>
            <Button
              variant="outlined"
              startIcon={<Download />}
              onClick={exportRecordedActions}
              disabled={recordedActions.length === 0}
            >
              Exporter le script
            </Button>
          </Box>
        </Box>
        
        <Typography variant="subtitle2" gutterBottom>
          Actions enregistrées ({recordedActions.length})
        </Typography>
        
        <Box sx={{ flex: 1, overflow: 'auto', border: 1, borderColor: 'divider', borderRadius: 1 }}>
          <List dense sx={{ p: 0 }}>
            {recordedActions.length === 0 ? (
              <ListItem>
                <ListItemText 
                  primary="Aucune action enregistrée" 
                  secondary="Démarrez l'enregistrement et interagissez avec l'appareil"
                />
              </ListItem>
            ) : (
              recordedActions.map((action, index) => (
                <ListItem key={index} divider>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    {action.type === 'app' && <Package size={18} />}
                    {action.type === 'ui' && <MousePointer size={18} />}
                    {action.type === 'wait' && <Clock size={18} />}
                    {action.type === 'device' && <Smartphone size={18} />}
                    {action.type === 'capture' && <Image size={18} />}
                  </ListItemIcon>
                  
                  <ListItemText
                    primary={
                      action.type === 'app' ? 
                        (action.action === 'launch' ? `Lancer l'app: ${action.value}` : 
                         action.action === 'terminate' ? `Arrêter l'app: ${action.value}` : 
                         `Réinitialiser l'app: ${action.value}`) :
                      action.type === 'ui' ?
                        (action.action === 'tap' ? `Appuyer sur: ${action.selector || 'coordonnées'}` :
                         action.action === 'longPress' ? `Appui long sur: ${action.selector || 'coordonnées'}` :
                         action.action === 'type' ? `Saisir "${action.value}" dans: ${action.selector}` :
                         action.action === 'swipe' ? `Défilement: ${action.direction}` :
                         action.action === 'pinch' ? `Pinch: ${action.scale}` :
                         action.action === 'rotate' ? `Rotation: ${action.rotation}° sur ${action.selector}` :
                         action.action === 'doubleTap' ? `Double tap sur: ${action.selector}` :
                         action.action === 'pressButton' ? `Appui bouton: ${action.value}` :
                         `Action: ${action.action}`) :
                      action.type === 'wait' ?
                        `Attendre l'élément: ${action.selector}` :
                      action.type === 'device' ?
                        (action.action === 'rotate' ? `Rotation écran: ${action.value}` :
                         `Changer ${action.setting} à ${action.value}`) :
                      action.type === 'capture' ?
                        'Capture d\'écran' :
                        action.action
                    }
                    secondary={new Date(action.timestamp).toLocaleTimeString()}
                  />
                  
                  <IconButton 
                    size="small" 
                    onClick={() => {
                      setRecordedActions(recordedActions.filter((_, i) => i !== index));
                    }}
                  >
                    <Trash2 size={14} />
                  </IconButton>
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Box>
    );
  };
  
  // Composant de visualisation de la hiérarchie des vues
  const ViewHierarchyComponent = () => {
    // Fonction récursive pour afficher la hiérarchie des éléments
    const renderElement = (element, level = 0, index = 0) => {
      if (!element) return null;
      
      // Filtrer par recherche si une requête existe
      if (viewSearchQuery && 
          !element.type?.toLowerCase().includes(viewSearchQuery.toLowerCase()) &&
          !element.label?.toLowerCase().includes(viewSearchQuery.toLowerCase()) &&
          !element.identifier?.toLowerCase().includes(viewSearchQuery.toLowerCase())) {
        
        // Vérifier si des enfants correspondent à la recherche
        const hasMatchingChild = (element.children || []).some(child => 
          child.type?.toLowerCase().includes(viewSearchQuery.toLowerCase()) ||
          child.label?.toLowerCase().includes(viewSearchQuery.toLowerCase()) ||
          child.identifier?.toLowerCase().includes(viewSearchQuery.toLowerCase())
        );
        
        if (!hasMatchingChild) return null;
      }
      
      const isSelected = selectedElement && 
        selectedElement.identifier === element.identifier &&
        selectedElement.type === element.type;
      
      return (
        <React.Fragment key={`${level}-${index}`}>
          <ListItem
            button
            sx={{
              pl: level * 2,
              borderLeft: '1px dashed',
              borderColor: 'divider',
              backgroundColor: isSelected ? 'primary.light' : 'transparent',
              '&:hover': {
                backgroundColor: isSelected ? 'primary.light' : 'action.hover',
              }
            }}
            onClick={() => setSelectedElement(element)}
          >
            <ListItemIcon sx={{ minWidth: 36 }}>
              {element.type === 'XCUIElementTypeButton' ? (
                <TouchpadOff size={16} />
              ) : element.type?.includes('Text') ? (
                <Type size={16} />
              ) : element.type?.includes('Image') ? (
                <Image size={16} />
              ) : element.type?.includes('Cell') ? (
                <TableIcon size={16} />
              ) : (
                <Box size={16} />
              )}
            </ListItemIcon>
            
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" component="span" sx={{ fontWeight: element.enabled ? 'normal' : 'lighter' }}>
                    {element.type?.replace('XCUIElementType', '')}
                  </Typography>
                  {element.label && (
                    <Typography variant="caption" component="span" sx={{ ml: 1, color: 'text.secondary' }}>
                      "{element.label}"
                    </Typography>
                  )}
                </Box>
              }
              secondary={
                element.identifier ? (
                  <Typography variant="caption" color="text.secondary">
                    ID: {element.identifier}
                  </Typography>
                ) : null
              }
            />
            
            {element.enabled === false && (
              <Chip size="small" label="Désactivé" variant="outlined" sx={{ mr: 1 }} />
            )}
            
            {!element.visible && (
              <Chip size="small" label="Invisible" variant="outlined" />
            )}
          </ListItem>
          
          {element.children && element.children.map((child, childIndex) => 
            renderElement(child, level + 1, childIndex)
          )}
        </React.Fragment>
      );
    };
    
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Layers size={16} style={{ marginRight: 8 }} />
          Hiérarchie des vues
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button
            size="small"
            startIcon={<Layers />}
            onClick={getViewHierarchy}
            disabled={disabled || !connectionState.connected}
          >
            Rafraîchir la hiérarchie
          </Button>
          
          <TextField
            size="small"
            placeholder="Rechercher dans les vues..."
            value={viewSearchQuery}
            onChange={(e) => setViewSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={16} />
                </InputAdornment>
              )
            }}
            sx={{ width: 250 }}
          />
        </Box>
        
        <Grid container spacing={2} sx={{ flex: 1, overflow: 'hidden' }}>
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Typography variant="subtitle2" gutterBottom>Structure</Typography>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 'calc(100% - 30px)',
                overflow: 'auto'
              }}
            >
              {!viewHierarchy ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <AlertTriangle size={32} color="#f57c00" />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Aucune hiérarchie de vues disponible
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                    Cliquez sur "Rafraîchir la hiérarchie" pour l'obtenir
                  </Typography>
                </Box>
              ) : (
                <List dense disablePadding>
                  {renderElement(viewHierarchy)}
                </List>
              )}
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Typography variant="subtitle2" gutterBottom>Détails de l'élément</Typography>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 'calc(100% - 30px)',
                overflow: 'auto'
              }}
            >
              {!selectedElement ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Info size={32} color="#42a5f5" />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Sélectionnez un élément pour voir ses détails
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">{selectedElement.type.replace('XCUIElementType', '')}</Typography>
                  
                  {selectedElement.label && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">Label:</Typography>
                      <Paper variant="outlined" sx={{ p: 1, mt: 0.5 }}>
                        <Typography variant="body2">{selectedElement.label}</Typography>
                      </Paper>
                    </Box>
                  )}
                  
                  {selectedElement.value && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">Valeur:</Typography>
                      <Paper variant="outlined" sx={{ p: 1, mt: 0.5 }}>
                        <Typography variant="body2">{selectedElement.value}</Typography>
                      </Paper>
                    </Box>
                  )}
                  
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>Propriétés</Typography>
                  
                  <TableContainer component={Paper} variant="outlined" sx={{ mt: 1 }}>
                    <Table size="small">
                      <TableBody>
                        {selectedElement.identifier && (
                          <TableRow>
                            <TableCell component="th" scope="row" width={120}>
                              Identifier
                            </TableCell>
                            <TableCell>{selectedElement.identifier}</TableCell>
                          </TableRow>
                        )}
                        {selectedElement.frame && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Frame
                            </TableCell>
                            <TableCell>
                              X: {selectedElement.frame.x}, Y: {selectedElement.frame.y}, 
                              W: {selectedElement.frame.width}, H: {selectedElement.frame.height}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Enabled
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small" 
                              label={selectedElement.enabled ? "Oui" : "Non"} 
                              color={selectedElement.enabled ? "success" : "default"} 
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Visible
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small" 
                              label={selectedElement.visible ? "Oui" : "Non"} 
                              color={selectedElement.visible ? "success" : "default"} 
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Accessible
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small" 
                              label={selectedElement.accessible ? "Oui" : "Non"} 
                              color={selectedElement.accessible ? "success" : "default"} 
                            />
                          </TableCell>
                        </TableRow>
                        {selectedElement.hittable !== undefined && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Hittable
                            </TableCell>
                            <TableCell>
                              <Chip 
                                size="small" 
                                label={selectedElement.hittable ? "Oui" : "Non"} 
                                color={selectedElement.hittable ? "success" : "default"} 
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                  <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
                    <Button
                      variant="contained"
                      startIcon={<TouchpadOff />}
                      onClick={() => tapElement(selectedElement.identifier || `${selectedElement.type}s.element`)}
                      disabled={disabled || !connectionState.connected || !selectedElement.enabled}
                      size="small"
                    >
                      Appuyer
                    </Button>
                    
                    {selectedElement.type.includes('TextField') && (
                      <Button
                        variant="outlined"
                        startIcon={<Type />}
                        onClick={() => {
                          const text = prompt("Entrez le texte à saisir:", selectedElement.value || "");
                          if (text !== null) {
                            typeText(selectedElement.identifier || `${selectedElement.type}s.element`, text);
                          }
                        }}
                        disabled={disabled || !connectionState.connected || !selectedElement.enabled}
                        size="small"
                      >
                        Saisir texte
                      </Button>
                    )}
                    
                    <Button
                      variant="outlined"
                      startIcon={<Copy />}
                      onClick={() => {
                        const selector = selectedElement.identifier 
                          ? selectedElement.identifier 
                          : `${selectedElement.type}s.element`;
                        
                        navigator.clipboard.writeText(selector);
                        setSnackbar({
                          open: true,
                          message: 'Sélecteur copié dans le presse-papiers',
                          severity: 'success'
                        });
                        
                        // Mettre à jour le champ de sélecteur avec la valeur copiée
                        setElementSelector(selector);
                      }}
                      size="small"
                    >
                      Copier sélecteur
                    </Button>
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Composant pour les logs de console
  const ConsoleComponent = () => {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Terminal size={16} style={{ marginRight: 8 }} />
          Console et Monitoring
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography variant="subtitle2" gutterBottom>Console</Typography>
            
            <Box sx={{ display: 'flex', mb: 1, flexWrap: 'wrap', gap: 1 }}>
              <TextField
                size="small"
                label="Subsystem"
                value={consoleFilter.subsystem}
                onChange={(e) => setConsoleFilter(prev => ({ ...prev, subsystem: e.target.value }))}
                disabled={disabled || !connectionState.connected}
                sx={{ width: 150 }}
              />
              
              <FormControl size="small" sx={{ width: 150 }}>
                <InputLabel>Niveau</InputLabel>
                <Select
                  value={consoleFilter.level}
                  onChange={(e) => setConsoleFilter(prev => ({ ...prev, level: e.target.value }))}
                  disabled={disabled || !connectionState.connected}
                >
                  <MenuItem value="default">Tous</MenuItem>
                  <MenuItem value="debug">Debug</MenuItem>
                  <MenuItem value="info">Info</MenuItem>
                  <MenuItem value="error">Error</MenuItem>
                </Select>
              </FormControl>
              
              <TextField
                size="small"
                label="Catégorie"
                value={consoleFilter.category}
                onChange={(e) => setConsoleFilter(prev => ({ ...prev, category: e.target.value }))}
                disabled={disabled || !connectionState.connected}
                sx={{ width: 150 }}
              />
              
              <TextField
                size="small"
                label="Recherche"
                value={consoleFilter.search}
                onChange={(e) => setConsoleFilter(prev => ({ ...prev, search: e.target.value }))}
                disabled={disabled || !connectionState.connected}
                sx={{ flex: 1, minWidth: 150 }}
              />
              
              <Button
                variant="outlined"
                size="small"
                onClick={() => getConsoleLogs()}
                disabled={disabled || !connectionState.connected}
                startIcon={<RefreshCw />}
              >
                Rafraîchir
              </Button>
              
              <Button
                variant="outlined"
                size="small"
                onClick={clearConsoleLogs}
                disabled={disabled || !connectionState.connected}
                startIcon={<Trash2 />}
              >
                Effacer
              </Button>
            </Box>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                height: 300,
                overflow: 'auto',
                fontFamily: 'monospace'
              }}
              ref={consoleContainerRef}
            >
              {consoleOutput.length === 0 ? (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    Aucun log disponible
                  </Typography>
                </Box>
              ) : (
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell width={100}>Heure</TableCell>
                      <TableCell width={60}>Niveau</TableCell>
                      <TableCell width={100}>Subsystem</TableCell>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {consoleOutput.map((log, index) => (
                      <TableRow key={index}>
                        <TableCell>{log.timestamp.toLocaleTimeString()}</TableCell>
                        <TableCell>
                          <Chip 
                            size="small" 
                            label={log.level} 
                            color={
                              log.level === 'error' ? 'error' :
                              log.level === 'warning' ? 'warning' :
                              log.level === 'info' ? 'info' :
                              'default'
                            }
                            sx={{ height: 20, minWidth: 20 }}
                          />
                        </TableCell>
                        <TableCell>{log.subsystem}</TableCell>
                        <TableCell sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                          {log.message}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>
          
          <Grid item xs={12} lg={4}>
            <Typography variant="subtitle2" gutterBottom>Monitoring des performances</Typography>
            
            <Box sx={{ display: 'flex', mb: 1, justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => getPerformanceStats()}
                disabled={!currentApp || disabled || !connectionState.connected}
                startIcon={<RefreshCw />}
              >
                Rafraîchir
              </Button>
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" sx={{ mr: 1 }}>
                  Rafraîchir toutes les:
                </Typography>
                <Select
                  size="small"
                  value={refreshRate}
                  onChange={(e) => setRefreshRate(e.target.value)}
                  disabled={disabled || !connectionState.connected}
                  sx={{ width: 80 }}
                >
                  <MenuItem value={1}>1s</MenuItem>
                  <MenuItem value={5}>5s</MenuItem>
                  <MenuItem value={10}>10s</MenuItem>
                  <MenuItem value={30}>30s</MenuItem>
                  <MenuItem value={60}>1m</MenuItem>
                </Select>
              </Box>
            </Box>
            
            <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
              {currentApp ? (
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Utilisation CPU:</span>
                      <span>{performanceStats.cpuUsage.toFixed(1)}%</span>
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={performanceStats.cpuUsage} 
                      color={
                        performanceStats.cpuUsage > 80 ? "error" : 
                        performanceStats.cpuUsage > 50 ? "warning" : 
                        "success"
                      }
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                  
                  <Box>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Utilisation mémoire:</span>
                      <span>{Math.round(performanceStats.memoryUsage)} MB</span>
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={Math.min(performanceStats.memoryUsage / 2000 * 100, 100)} 
                      color={
                        performanceStats.memoryUsage > 1500 ? "error" : 
                        performanceStats.memoryUsage > 1000 ? "warning" : 
                        "success"
                      }
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                  
                  <Box>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Consommation d'énergie:</span>
                      <span>{performanceStats.energy.toFixed(1)}</span>
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={performanceStats.energy * 10} 
                      color={
                        performanceStats.energy > 8 ? "error" : 
                        performanceStats.energy > 5 ? "warning" : 
                        "success"
                      }
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                  
                  <Box>
                    <Typography variant="body2">Utilisation réseau:</Typography>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <span>Réception:</span>
                      <span>{(performanceStats.networkUsage.rx / 1024).toFixed(2)} KB</span>
                    </Typography>
                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <span>Envoi:</span>
                      <span>{(performanceStats.networkUsage.tx / 1024).toFixed(2)} KB</span>
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                  Sélectionnez une application pour voir ses performances
                </Typography>
              )}
            </Paper>
            
            <Typography variant="subtitle2" gutterBottom>État du système</Typography>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">WiFi:</Typography>
                  <Chip 
                    size="small" 
                    icon={networkStatus.wifiEnabled ? <Wifi size={14} /> : <WifiOff size={14} />}
                    label={networkStatus.wifiEnabled ? (networkStatus.wifiConnected ? "Connecté" : "Activé") : "Désactivé"} 
                    color={networkStatus.wifiEnabled && networkStatus.wifiConnected ? "success" : "default"} 
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Cellulaire:</Typography>
                  <Chip 
                    size="small" 
                    label={networkStatus.cellularEnabled ? (networkStatus.cellularConnected ? "Connecté" : "Activé") : "Désactivé"} 
                    color={networkStatus.cellularEnabled && networkStatus.cellularConnected ? "success" : "default"} 
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Bluetooth:</Typography>
                  <Chip 
                    size="small" 
                    icon={networkStatus.bluetoothEnabled ? <Bluetooth size={14} /> : <BluetoothOff size={14} />}
                    label={networkStatus.bluetoothEnabled ? "Activé" : "Désactivé"} 
                    color={networkStatus.bluetoothEnabled ? "info" : "default"} 
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Mode avion:</Typography>
                  <Chip 
                    size="small" 
                    label={networkStatus.airplaneMode ? "Activé" : "Désactivé"} 
                    color={networkStatus.airplaneMode ? "warning" : "default"} 
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionState.connected) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };
  
  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionState.connected ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      
      <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Tabs 
          value={activeTab} 
          onChange={(e, newValue) => setActiveTab(newValue)} 
          sx={{ 
            px: 2,
            borderBottom: 1, 
            borderColor: 'divider',
            bgcolor: 'background.paper',
          }}
        >
          <Tab icon={<Smartphone size={16} />} label="Appareil" value={0} />
          <Tab icon={<Package size={16} />} label="Applications" value={1} />
          <Tab icon={<TouchpadOff size={16} />} label="Interactions" value={2} />
          <Tab icon={<Image size={16} />} label="Capture d'écran" value={3} />
          <Tab icon={<Layers size={16} />} label="Hiérarchie" value={4} />
          <Tab icon={<PlayCircle size={16} />} label="Enregistrement" value={5} />
          <Tab icon={<Terminal size={16} />} label="Console" value={6} />
        </Tabs>
        
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {activeTab === 0 && <DeviceInfoComponent />}
          {activeTab === 1 && <AppControlComponent />}
          {activeTab === 2 && <UiControlsComponent />}
          {activeTab === 3 && <ScreenshotComponent />}
          {activeTab === 4 && <ViewHierarchyComponent />}
          {activeTab === 5 && <RecordingComponent />}
          {activeTab === 6 && <ConsoleComponent />}
        </Box>
      </Box>
      
      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default IOSAppCapture;