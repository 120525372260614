import React, { useState, useEffect } from 'react';
import { 
  Box,
  Typography,
  Paper,
  Grid,
  Button as MuiButton,
  IconButton,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Chip,
  Tooltip,
  Avatar,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Stack,
  CircularProgress
} from '@mui/material';

import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RTooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell, LineChart, Line, AreaChart, Area
} from 'recharts';

import { 
  Play, Pause, Clock, CheckCircle, XCircle, AlertTriangle, 
  BarChart2, List, FileText, RefreshCw, Calendar,
  Info, Eye, Server, Database, Link, Activity, Gauge,
  Settings, Edit, Trash2, Filter, Search, Plus, 
  ChevronRight, ArrowRight, Zap, Network, Code
} from 'lucide-react';

import api from '../../services/api.service';
import Button from '../../components/shared/Button';
import Card from '../../components/shared/Card';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';

// Définir les couleurs du thème comme dans MissionControlDashboard
const COLORS = {
  primary: '#3f51b5',
  secondary: '#f50057',
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
  info: '#2196f3',
  campaign: '#3f51b5',
  testCase: '#673ab7',
  execution: '#009688',
  result: '#ff5722',
  chartColors: ['#3f51b5', '#03a9f4', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#673ab7', '#009688', '#ff5722']
};

const TestCampaignDashboard = () => {
  // Conserver les états existants
  const [campaigns, setCampaigns] = useState([]);
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [activeExecutions, setActiveExecutions] = useState([]);
  const [recentExecutions, setRecentExecutions] = useState([]);
  const [statistics, setStatistics] = useState({
    totalCampaigns: 0,
    activeCampaigns: 0,
    completedCampaigns: 0,
    successRate: 0,
    totalTestCases: 0,
    passedTests: 0,
    failedTests: 0,
    skippedTests: 0
  });
  
  // États pour le chargement et les erreurs
  const [loading, setLoading] = useState({
    campaigns: true,
    activeCampaigns: true,
    activeExecutions: true,
    recentExecutions: true
  });
  const [error, setError] = useState({
    campaigns: null,
    activeCampaigns: null,
    activeExecutions: null,
    recentExecutions: null
  });
  
  // Effet pour charger les données au montage du composant
  useEffect(() => {
    fetchDashboardData();
  }, []);
  
  // Conserver les fonctions existantes
  const fetchDashboardData = async () => {
    fetchCampaigns();
    fetchActiveCampaigns();
    fetchActiveExecutions();
    fetchRecentExecutions();
  };
  
  const fetchCampaigns = async () => {
    setLoading(prev => ({ ...prev, campaigns: true }));
    setError(prev => ({ ...prev, campaigns: null }));
    
    try {
      const response = await api.automata.campaigns.getAll();
      setCampaigns(response);
      
      // Calculer les statistiques globales
      const totalCampaigns = response.length;
      const completedCampaigns = response.filter(campaign => 
        campaign.status === 'COMPLETED' || campaign.status === 'ARCHIVED'
      ).length;
      
      let totalTestCases = 0;
      response.forEach(campaign => {
        if (campaign.testCases) {
          totalTestCases += campaign.testCases.length;
        }
      });
      
      setStatistics(prev => ({
        ...prev,
        totalCampaigns,
        completedCampaigns,
        totalTestCases
      }));
      
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setError(prev => ({ ...prev, campaigns: 'Failed to load campaigns data' }));
    } finally {
      setLoading(prev => ({ ...prev, campaigns: false }));
    }
  };
  
  const fetchActiveCampaigns = async () => {
    setLoading(prev => ({ ...prev, activeCampaigns: true }));
    setError(prev => ({ ...prev, activeCampaigns: null }));
    
    try {
      const response = await api.automata.campaigns.getAll(null, true);
      setActiveCampaigns(response);
      
      setStatistics(prev => ({
        ...prev,
        activeCampaigns: response.length
      }));
      
    } catch (error) {
      console.error('Error fetching active campaigns:', error);
      setError(prev => ({ ...prev, activeCampaigns: 'Failed to load active campaigns data' }));
    } finally {
      setLoading(prev => ({ ...prev, activeCampaigns: false }));
    }
  };
  
  const fetchActiveExecutions = async () => {
    setLoading(prev => ({ ...prev, activeExecutions: true }));
    setError(prev => ({ ...prev, activeExecutions: null }));
    
    try {
      const runningStatus = 'RUNNING'; // Utiliser l'énumération réelle de votre backend
      const response = await api.automata.executions.getAll(null, runningStatus);
      setActiveExecutions(response);
      
      // Pour chaque exécution active, récupérer ses statistiques
      if (response && response.length > 0) {
        const executionPromises = response.map(execution => 
          api.automata.executions.calculateStatistics(execution.id)
        );
        
        const executionsWithStats = await Promise.all(executionPromises);
        
        // Agréger les statistiques
        let totalPassed = 0;
        let totalFailed = 0;
        let totalSkipped = 0;
        
        executionsWithStats.forEach(execution => {
          totalPassed += execution.passedTests || 0;
          totalFailed += execution.failedTests || 0;
          totalSkipped += execution.skippedTests || 0;
        });
        
        const totalTests = totalPassed + totalFailed + totalSkipped;
        const successRate = totalTests > 0 ? (totalPassed / totalTests) * 100 : 0;
        
        setStatistics(prev => ({
          ...prev,
          passedTests: totalPassed,
          failedTests: totalFailed,
          skippedTests: totalSkipped,
          successRate: Math.round(successRate)
        }));
      }
      
    } catch (error) {
      console.error('Error fetching active executions:', error);
      setError(prev => ({ ...prev, activeExecutions: 'Failed to load active executions data' }));
    } finally {
      setLoading(prev => ({ ...prev, activeExecutions: false }));
    }
  };
  
  const fetchRecentExecutions = async () => {
    setLoading(prev => ({ ...prev, recentExecutions: true }));
    setError(prev => ({ ...prev, recentExecutions: null }));
    
    try {
      // On récupère toutes les exécutions et on les triera après
      const response = await api.automata.executions.getAll();
      
      // Trier par date et prendre les 5 plus récentes
      const sortedExecutions = response.sort((a, b) => {
        return new Date(b.startTime) - new Date(a.startTime);
      }).slice(0, 5);
      
      setRecentExecutions(sortedExecutions);
      
    } catch (error) {
      console.error('Error fetching recent executions:', error);
      setError(prev => ({ ...prev, recentExecutions: 'Failed to load recent executions data' }));
    } finally {
      setLoading(prev => ({ ...prev, recentExecutions: false }));
    }
  };
  
  // Fonction pour générer des données pour le graphique de répartition des tests
  const getTestDistributionData = () => {
    return [
      { name: 'Réussis', value: statistics.passedTests, color: COLORS.success },
      { name: 'Échoués', value: statistics.failedTests, color: COLORS.error },
      { name: 'Ignorés', value: statistics.skippedTests, color: COLORS.warning }
    ];
  };
  
  // Fonction pour générer des données pour le graphique de statut des campagnes
  const getCampaignStatusData = () => {
    if (!campaigns || campaigns.length === 0) return [];
    
    const statusCount = campaigns.reduce((acc, campaign) => {
      acc[campaign.status] = (acc[campaign.status] || 0) + 1;
      return acc;
    }, {});
    
    return Object.keys(statusCount).map(status => ({
      name: status,
      count: statusCount[status]
    }));
  };
  
  // Fonction pour gérer le statut d'exécution (représentation visuelle)
  const getExecutionStatusIcon = (status) => {
    switch (status) {
      case 'RUNNING':
        return <Play style={{ color: COLORS.info }} size={18} />;
      case 'PAUSED':
        return <Pause style={{ color: COLORS.warning }} size={18} />;
      case 'COMPLETED_SUCCESS':
        return <CheckCircle style={{ color: COLORS.success }} size={18} />;
      case 'COMPLETED_WITH_FAILURES':
        return <AlertTriangle style={{ color: COLORS.warning }} size={18} />;
      case 'COMPLETED_WITH_ERRORS':
        return <XCircle style={{ color: COLORS.error }} size={18} />;
      default:
        return <Clock style={{ color: '#9e9e9e' }} size={18} />;
    }
  };
  
  return (
    <Box sx={{ p: 2, height: 'calc(100vh - 100px)', overflow: 'auto' }}>
      {/* Titre de la page */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" fontWeight="bold">
          Tableau de bord des campagnes de test
        </Typography>
        <MuiButton 
          variant="outlined" 
          startIcon={<RefreshCw size={18} />}
          onClick={fetchDashboardData}
        >
          Actualiser
        </MuiButton>
      </Box>
      
      {/* KPIs principaux */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <MuiCard sx={{ bgcolor: COLORS.campaign, color: 'white', boxShadow: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {statistics.totalCampaigns}
                  </Typography>
                  <Typography variant="subtitle1">Campagnes totales</Typography>
                </div>
                <Avatar 
                  sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                >
                  <List size={32} />
                </Avatar>
              </Box>
              <LinearProgress 
                variant="determinate" 
                value={(statistics.activeCampaigns / statistics.totalCampaigns) * 100 || 0} 
                sx={{ mt: 2, height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="caption">{statistics.activeCampaigns} actives</Typography>
                <Typography variant="caption">{statistics.completedCampaigns} terminées</Typography>
              </Box>
            </CardContent>
            <CardActions>
              <MuiButton 
                size="small" 
                variant="text" 
                sx={{ color: 'white' }}
                onClick={() => window.location.href = '/atm-campaign/cpg-02-campaignmanager'}
                endIcon={<ChevronRight size={16} />}
              >
                Détails
              </MuiButton>
            </CardActions>
          </MuiCard>
        </Grid>
        
        <Grid item xs={12} md={3}>
          <MuiCard sx={{ bgcolor: COLORS.success, color: 'white', boxShadow: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {statistics.successRate}%
                  </Typography>
                  <Typography variant="subtitle1">Taux de réussite</Typography>
                </div>
                <Avatar 
                  sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                >
                  <CheckCircle size={32} />
                </Avatar>
              </Box>
              <Box sx={{ mt: 2, position: 'relative', height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}>
                <Box 
                  sx={{ 
                    position: 'absolute', 
                    left: 0, 
                    top: 0, 
                    height: '100%', 
                    width: `${(statistics.passedTests / (statistics.passedTests + statistics.failedTests + statistics.skippedTests)) * 100 || 0}%`,
                    bgcolor: 'rgba(255,255,255,0.5)',
                    borderRadius: 4
                  }} 
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Typography variant="caption">
                  {statistics.passedTests} tests réussis sur {statistics.passedTests + statistics.failedTests + statistics.skippedTests}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <MuiButton 
                size="small" 
                variant="text" 
                sx={{ color: 'white' }}
                onClick={() => window.location.href = '/atm-campaign/cpg-05-testresultanalyzer'}
                endIcon={<ChevronRight size={16} />}
              >
                Détails
              </MuiButton>
            </CardActions>
          </MuiCard>
        </Grid>
        
        <Grid item xs={12} md={3}>
          <MuiCard sx={{ bgcolor: COLORS.warning, color: 'white', boxShadow: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {activeExecutions.length}
                  </Typography>
                  <Typography variant="subtitle1">Exécutions actives</Typography>
                </div>
                <Avatar 
                  sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                >
                  <Play size={32} />
                </Avatar>
              </Box>
              <LinearProgress 
                variant="indeterminate" 
                sx={{ mt: 2, height: 8, borderRadius: 4, bgcolor: 'rgba(255,255,255,0.2)' }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Typography variant="caption">
                  {activeExecutions.length > 0 ? 'En cours d\'exécution' : 'Aucune exécution active'}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <MuiButton 
                size="small" 
                variant="text" 
                sx={{ color: 'white' }}
                onClick={() => window.location.href = '/atm-campaign/cpg-04-executioncenter'}
                endIcon={<ChevronRight size={16} />}
              >
                Détails
              </MuiButton>
            </CardActions>
          </MuiCard>
        </Grid>
        
        <Grid item xs={12} md={3}>
          <MuiCard sx={{ bgcolor: COLORS.secondary, color: 'white', boxShadow: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {statistics.totalTestCases}
                  </Typography>
                  <Typography variant="subtitle1">Cas de test</Typography>
                </div>
                <Avatar 
                  sx={{ bgcolor: 'rgba(255,255,255,0.2)', width: 56, height: 56 }}
                >
                  <FileText size={32} />
                </Avatar>
              </Box>
              <Box sx={{ mt: 2, height: 8 }} />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Typography variant="caption">
                  Dans toutes les campagnes
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <MuiButton 
                size="small" 
                variant="text" 
                sx={{ color: 'white' }}
                onClick={() => window.location.href = '/atm-campaign/cpg-03-testcaselibrary'}
                endIcon={<ChevronRight size={16} />}
              >
                Détails
              </MuiButton>
            </CardActions>
          </MuiCard>
        </Grid>
      </Grid>
      
      {/* Graphiques */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {/* Répartition des résultats de test */}
        <Grid item xs={12} md={6}>
          <MuiCard sx={{ boxShadow: 2, height: '100%' }}>
            <CardHeader 
              title="Répartition des résultats de test" 
              titleTypographyProps={{ variant: 'h6' }}
              avatar={<BarChart2 size={20} />}
            />
            <Divider />
            <CardContent sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={getTestDistributionData()}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {getTestDistributionData().map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <RTooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </MuiCard>
        </Grid>
        
        {/* Statut des campagnes */}
        <Grid item xs={12} md={6}>
          <MuiCard sx={{ boxShadow: 2, height: '100%' }}>
            <CardHeader 
              title="Statut des campagnes" 
              titleTypographyProps={{ variant: 'h6' }}
              avatar={<BarChart2 size={20} />}
            />
            <Divider />
            <CardContent sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={getCampaignStatusData()}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <RTooltip />
                  <Legend />
                  <Bar dataKey="count" name="Nombre de campagnes" fill={COLORS.primary} />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </MuiCard>
        </Grid>
      </Grid>
      
      {/* Campagnes actives */}
      <MuiCard sx={{ mb: 3, boxShadow: 2 }}>
        <CardHeader 
          title="Campagnes actives" 
          titleTypographyProps={{ variant: 'h6' }}
          avatar={<Calendar size={20} />}
          action={
            <MuiButton 
              variant="outlined" 
              size="small"
              endIcon={<ArrowRight size={16} />}
              onClick={() => window.location.href = '/atm-campaign/cpg-02-campaignmanager'}
            >
              Voir toutes les campagnes
            </MuiButton>
          }
        />
        <Divider />
        <CardContent>
          {loading.activeCampaigns ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          ) : error.activeCampaigns ? (
            <Box sx={{ textAlign: 'center', p: 4, color: COLORS.error }}>
              {error.activeCampaigns}
            </Box>
          ) : activeCampaigns.length === 0 ? (
            <Box sx={{ textAlign: 'center', p: 4, color: 'text.secondary' }}>
              Aucune campagne active pour le moment
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Cas de test</TableCell>
                    <TableCell>Créée le</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeCampaigns.slice(0, 5).map((campaign) => (
                    <TableRow 
                      key={campaign.id}
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => window.location.href = `/atm-campaign/cpg-02-campaignmanager?id=${campaign.id}`}
                    >
                      <TableCell>
                        <Box>
                          <Typography variant="body2" fontWeight="medium">
                            {campaign.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {campaign.campaignId}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Chip 
                          label={campaign.status}
                          size="small"
                          color={
                            campaign.status === 'ACTIVE' ? 'success' :
                            campaign.status === 'IN_PROGRESS' ? 'info' :
                            campaign.status === 'DRAFT' ? 'default' :
                            'default'
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {campaign.testCases ? campaign.testCases.length : 0} cas de test
                      </TableCell>
                      <TableCell>
                        <DateFormatter date={campaign.creationDate} format="default" />
                      </TableCell>
                      <TableCell align="right">
                        <MuiButton
                          variant="contained"
                          size="small"
                          startIcon={<Play size={16} />}
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = `/atm-campaign/cpg-04-executioncenter?campaignId=${campaign.id}`;
                          }}
                        >
                          Exécuter
                        </MuiButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </MuiCard>
      
      {/* Exécutions récentes */}
      <MuiCard sx={{ mb: 3, boxShadow: 2 }}>
        <CardHeader 
          title="Exécutions récentes" 
          titleTypographyProps={{ variant: 'h6' }}
          avatar={<Clock size={20} />}
          action={
            <MuiButton 
              variant="outlined" 
              size="small"
              endIcon={<ArrowRight size={16} />}
              onClick={() => window.location.href = '/atm-campaign/cpg-04-executioncenter'}
            >
              Voir toutes les exécutions
            </MuiButton>
          }
        />
        <Divider />
        <CardContent>
          {loading.recentExecutions ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          ) : error.recentExecutions ? (
            <Box sx={{ textAlign: 'center', p: 4, color: COLORS.error }}>
              {error.recentExecutions}
            </Box>
          ) : recentExecutions.length === 0 ? (
            <Box sx={{ textAlign: 'center', p: 4, color: 'text.secondary' }}>
              Aucune exécution récente
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Exécution</TableCell>
                    <TableCell>Campagne</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Démarré le</TableCell>
                    <TableCell>Résultats</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentExecutions.map((execution) => (
                    <TableRow 
                      key={execution.id}
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => window.location.href = `/atm-campaign/cpg-05-testresultanalyzer?executionId=${execution.id}`}
                    >
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {getExecutionStatusIcon(execution.status)}
                          <Box sx={{ ml: 1 }}>
                            <Typography variant="body2" fontWeight="medium">
                              {execution.executionName || `Exécution #${execution.id}`}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {execution.executionId}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {execution.campaign ? execution.campaign.name : 'N/A'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip 
                          label={execution.status}
                          size="small"
                          color={
                            execution.status === 'RUNNING' ? 'info' :
                            execution.status === 'PAUSED' ? 'warning' :
                            execution.status === 'COMPLETED_SUCCESS' ? 'success' :
                            execution.status === 'COMPLETED_WITH_FAILURES' ? 'warning' :
                            'error'
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <DateFormatter date={execution.startTime} format="default" showTime={true} />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Chip 
                            size="small" 
                            icon={<CheckCircle size={14} />} 
                            label={execution.passedTests || 0}
                            sx={{ bgcolor: 'rgba(76, 175, 80, 0.1)', color: COLORS.success, borderColor: COLORS.success }}
                            variant="outlined"
                          />
                          <Chip 
                            size="small" 
                            icon={<XCircle size={14} />} 
                            label={execution.failedTests || 0}
                            sx={{ bgcolor: 'rgba(244, 67, 54, 0.1)', color: COLORS.error, borderColor: COLORS.error }}
                            variant="outlined"
                          />
                          <Chip 
                            size="small" 
                            icon={<AlertTriangle size={14} />} 
                            label={execution.skippedTests || 0}
                            sx={{ bgcolor: 'rgba(255, 152, 0, 0.1)', color: COLORS.warning, borderColor: COLORS.warning }}
                            variant="outlined"
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <MuiButton
                          variant="outlined"
                          size="small"
                          startIcon={<FileText size={16} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = `/atm-campaign/cpg-06-reportinghub?executionId=${execution.id}`;
                          }}
                        >
                          Rapports
                        </MuiButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </MuiCard>
      
      {/* Accès rapides */}
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <MuiButton
            variant="contained"
            fullWidth
            startIcon={<List size={18} />}
            color="primary"
            sx={{ py: 1.5 }}
            onClick={() => window.location.href = '/atm-campaign/cpg-02-campaignmanager'}
          >
            Gérer les campagnes
          </MuiButton>
        </Grid>
        
        <Grid item xs={6} md={3}>
          <MuiButton
            variant="contained"
            fullWidth
            startIcon={<FileText size={18} />}
            color="secondary"
            sx={{ py: 1.5 }}
            onClick={() => window.location.href = '/atm-campaign/cpg-03-testcaselibrary'}
          >
            Bibliothèque de cas de test
          </MuiButton>
        </Grid>
        
        <Grid item xs={6} md={3}>
          <MuiButton
            variant="outlined"
            fullWidth
            startIcon={<Play size={18} />}
            sx={{ py: 1.5 }}
            onClick={() => window.location.href = '/atm-campaign/cpg-04-executioncenter'}
          >
            Centre d'exécution
          </MuiButton>
        </Grid>
        
        <Grid item xs={6} md={3}>
          <MuiButton
            variant="outlined"
            fullWidth
            startIcon={<BarChart2 size={18} />}
            sx={{ py: 1.5 }}
            onClick={() => window.location.href = '/atm-campaign/cpg-06-reportinghub'}
          >
            Rapports
          </MuiButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TestCampaignDashboard;