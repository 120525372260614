// components/shared/workflow/WorkflowValidator.js

/**
 * Utilitaire de validation des workflows conformément aux contraintes métier
 */
export class WorkflowValidator {
  /**
   * Vérifie si le workflow est valide pour être enregistré ou exporté
   * 
   * @param {Object} nodes - Les nœuds du workflow
   * @param {Object} edges - Les arêtes du workflow
   * @param {String} workflowName - Le nom du workflow
   * @returns {Object} - Résultat de validation {isValid, errors}
   */
  static validateWorkflow(nodes, edges, workflowName) {
    const errors = [];
    
    // Valider le nom du workflow
    if (!workflowName || workflowName.trim() === '') {
      errors.push('Le nom du workflow est obligatoire');
    }
    
    // Vérification de l'état initial
    const initialNodes = nodes.filter(node => node.type === 'initialNode');
    if (initialNodes.length === 0) {
      errors.push('Le workflow doit avoir un état initial');
    } else if (initialNodes.length > 1) {
      errors.push('Le workflow ne peut avoir qu\'un seul état initial');
    }
    
    // Vérification des états finaux
    const finalNodes = nodes.filter(node => node.type === 'finalNode');
    if (finalNodes.length === 0) {
      errors.push('Le workflow doit avoir au moins un état final');
    }
    
    // Vérifier que tous les nœuds ont un label
    const nodesWithoutLabel = nodes.filter(node => !node.data?.label || node.data.label.trim() === '');
    if (nodesWithoutLabel.length > 0) {
      errors.push(`${nodesWithoutLabel.length} état(s) n'ont pas de nom`);
    }
    
    // Vérifier que tous les nœuds ont des connexions
    const connectedNodeIds = new Set();
    edges.forEach(edge => {
      connectedNodeIds.add(edge.source);
      connectedNodeIds.add(edge.target);
    });
    
    const disconnectedNodes = nodes.filter(node => !connectedNodeIds.has(node.id));
    if (disconnectedNodes.length > 0) {
      errors.push(`${disconnectedNodes.length} état(s) ne sont pas connectés`);
    }
    
    // Vérifier que toutes les transitions ont un événement
    const edgesWithoutLabel = edges.filter(edge => !edge.label || edge.label.trim() === '');
    if (edgesWithoutLabel.length > 0) {
      errors.push(`${edgesWithoutLabel.length} transition(s) n'ont pas d'événement`);
    }
    
    // Vérifier l'accessibilité des états finaux
    const canReachFinalState = this.checkFinalStateReachability(nodes, edges);
    if (!canReachFinalState) {
      errors.push('Au moins un état final n\'est pas accessible depuis l\'état initial');
    }
    
    // Vérifier la cohérence des rôles requis
    const invalidRoleEdges = edges.filter(edge => {
      const roles = edge.data?.roles || [];
      return roles.some(role => !role || role.trim() === '');
    });
    
    if (invalidRoleEdges.length > 0) {
      errors.push(`${invalidRoleEdges.length} transition(s) ont des rôles vides`);
    }
    
    return {
      isValid: errors.length === 0,
      errors
    };
  }
  
  /**
   * Vérifie si au moins un état final est accessible depuis l'état initial
   */
  static checkFinalStateReachability(nodes, edges) {
    const initialNode = nodes.find(node => node.type === 'initialNode');
    const finalNodeIds = nodes.filter(node => node.type === 'finalNode').map(node => node.id);
    
    if (!initialNode || finalNodeIds.length === 0) {
      return false;
    }
    
    // Construire un graphe de dépendances
    const graph = {};
    nodes.forEach(node => {
      graph[node.id] = [];
    });
    
    edges.forEach(edge => {
      if (graph[edge.source]) {
        graph[edge.source].push(edge.target);
      }
    });
    
    // Utiliser BFS pour vérifier l'accessibilité
    const visited = new Set();
    const queue = [initialNode.id];
    
    while (queue.length > 0) {
      const currentNode = queue.shift();
      
      if (visited.has(currentNode)) {
        continue;
      }
      
      visited.add(currentNode);
      
      // Vérifier si on a atteint un état final
      if (finalNodeIds.includes(currentNode)) {
        return true;
      }
      
      // Ajouter les voisins à la file
      const neighbors = graph[currentNode] || [];
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          queue.push(neighbor);
        }
      }
    }
    
    return false;
  }
  
  /**
   * Vérifie les cycles dans le workflow et retourne les informations
   */
  static detectCycles(nodes, edges) {
    // Construire un graphe orienté
    const graph = {};
    nodes.forEach(node => {
      graph[node.id] = [];
    });
    
    edges.forEach(edge => {
      if (graph[edge.source]) {
        graph[edge.source].push(edge.target);
      }
    });
    
    const cycles = [];
    const visited = new Set();
    const recStack = new Set();
    
    // DFS pour détecter les cycles
    const findCycles = (nodeId, path = []) => {
      if (recStack.has(nodeId)) {
        // Cycle détecté
        const cycleStart = path.indexOf(nodeId);
        const cycle = path.slice(cycleStart);
        cycle.push(nodeId); // Fermer le cycle
        cycles.push(cycle);
        return;
      }
      
      if (visited.has(nodeId)) {
        return;
      }
      
      visited.add(nodeId);
      recStack.add(nodeId);
      path.push(nodeId);
      
      const neighbors = graph[nodeId] || [];
      for (const neighbor of neighbors) {
        findCycles(neighbor, [...path]);
      }
      
      recStack.delete(nodeId);
    };
    
    // Démarrer DFS à partir de chaque nœud
    nodes.forEach(node => {
      if (!visited.has(node.id)) {
        findCycles(node.id);
      }
    });
    
    // Convertir les IDs de nœuds en noms lisibles
    const nodeMap = {};
    nodes.forEach(node => {
      nodeMap[node.id] = node.data?.label || node.id;
    });
    
    const cycleInfo = cycles.map(cycle => ({
      nodes: cycle.map(id => nodeMap[id]),
      nodeIds: cycle
    }));
    
    return {
      hasCycles: cycles.length > 0,
      cycles: cycleInfo
    };
  }
}