import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box,
  Typography,
  Paper,
  Grid,
  Button as MuiButton,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Divider,
  Chip,
  Tooltip,
  CircularProgress,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardActions,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Avatar,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  ListItemButton
} from '@mui/material';
import { 
  FileText, Download, Archive, Trash2, RefreshCw, 
  Search, Filter, Plus, X, Calendar, Check, 
  FileSpreadsheet, FileImage, FileJson, File,
  Info, Eye, AlertTriangle, Clock, CheckCircle,
  XCircle, ChevronDown, ExternalLink, Copy, Settings,
  Edit, MoreVertical, ChevronRight, ArrowRight, ChevronUp,
  Play // Ajout de l'icône Play qui était manquante
} from 'lucide-react';
import api from '../../services/api.service';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import DataGrid from '../../components/shared/DataGrid';

// Définir les couleurs du thème comme dans MissionControlDashboard
const COLORS = {
  primary: '#3f51b5',
  secondary: '#f50057',
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
  info: '#2196f3',
  report: '#3f51b5',
  campaign: '#673ab7',
  execution: '#009688',
  chartColors: ['#3f51b5', '#03a9f4', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#673ab7', '#009688', '#ff5722']
};

const ReportingHub = () => {
  // États pour les données
  const [reports, setReports] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [executions, setExecutions] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedTab, setSelectedTab] = useState('all'); // 'all', 'campaign', 'execution', 'archived'
  
  // États pour les filtres
  const [searchTerm, setSearchTerm] = useState('');
  const [formatFilter, setFormatFilter] = useState('');
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });
  const [campaignFilter, setCampaignFilter] = useState('');
  const [executionFilter, setExecutionFilter] = useState('');
  const [showArchivedOnly, setShowArchivedOnly] = useState(false);
  const [filteredReports, setFilteredReports] = useState([]);
  
  // États pour les modales
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [generationType, setGenerationType] = useState('execution'); // 'execution' ou 'campaign'
  
  // État pour le formulaire de génération
  const [generationForm, setGenerationForm] = useState({
    sourceId: '',
    format: 'PDF',
    metadata: {}
  });
  
  // États pour le chargement et les erreurs
  const [loading, setLoading] = useState({
    reports: true,
    campaigns: false,
    executions: false,
    report: false,
    action: false
  });
  const [error, setError] = useState({
    reports: null,
    campaigns: null,
    executions: null,
    report: null,
    action: null
  });
  
  // Effet pour charger les données initiales
  useEffect(() => {
    // Analyser les paramètres d'URL pour les filtres initiaux
    const params = new URLSearchParams(window.location.search);
    const campaignId = params.get('campaignId');
    const executionId = params.get('executionId');
    
    if (campaignId) {
      setCampaignFilter(campaignId);
      setSelectedTab('campaign');
    } else if (executionId) {
      setExecutionFilter(executionId);
      setSelectedTab('execution');
    }
    
    fetchReports();
  }, []);
  
  // Effet pour filtrer les rapports quand les critères changent
  useEffect(() => {
    filterReports();
  }, [reports, searchTerm, formatFilter, dateFilter, selectedTab, showArchivedOnly]);
  
  // Fonction pour récupérer tous les rapports
  const fetchReports = useCallback(async () => {
    setLoading(prev => ({ ...prev, reports: true }));
    setError(prev => ({ ...prev, reports: null }));
    
    try {
      let endpoint = '/api/automata/reports';
      let params = {};
      
      // Appliquer les filtres sur le backend si nécessaire
      if (campaignFilter) {
        params.campaignId = campaignFilter;
      } else if (executionFilter) {
        params.executionId = executionFilter;
      }
      
      const response = await api.automata.reports.getAll(params.campaignId, params.executionId);
      setReports(response);
    } catch (err) {
      console.error('Error fetching reports:', err);
      setError(prev => ({ ...prev, reports: 'Failed to load reports' }));
    } finally {
      setLoading(prev => ({ ...prev, reports: false }));
    }
  }, [campaignFilter, executionFilter]);
  
  // Fonction pour récupérer les campagnes disponibles
  const fetchCampaigns = async () => {
    setLoading(prev => ({ ...prev, campaigns: true }));
    setError(prev => ({ ...prev, campaigns: null }));
    
    try {
      const response = await api.automata.campaigns.getAll();
      setCampaigns(response);
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      setError(prev => ({ ...prev, campaigns: 'Failed to load campaigns' }));
    } finally {
      setLoading(prev => ({ ...prev, campaigns: false }));
    }
  };
  
  // Fonction pour récupérer les exécutions disponibles
  const fetchExecutions = async () => {
    setLoading(prev => ({ ...prev, executions: true }));
    setError(prev => ({ ...prev, executions: null }));
    
    try {
      // Ici, nous récupérons toutes les exécutions sans filtre spécifique
      // Dans un cas réel, vous pourriez vouloir filtrer par statut "COMPLETED"
      const response = await api.automata.executions.getAll();
      setExecutions(response);
    } catch (err) {
      console.error('Error fetching executions:', err);
      setError(prev => ({ ...prev, executions: 'Failed to load executions' }));
    } finally {
      setLoading(prev => ({ ...prev, executions: false }));
    }
  };
  
  // Fonction pour récupérer les détails d'un rapport
  const fetchReportDetails = async (reportId) => {
    if (!reportId) return;
    
    setLoading(prev => ({ ...prev, report: true }));
    setError(prev => ({ ...prev, report: null }));
    
    try {
      const response = await api.automata.reports.getById(reportId);
      setSelectedReport(response);
    } catch (err) {
      console.error('Error fetching report details:', err);
      setError(prev => ({ ...prev, report: 'Failed to load report details' }));
    } finally {
      setLoading(prev => ({ ...prev, report: false }));
    }
  };
  
  // Fonction pour filtrer les rapports
  const filterReports = useCallback(() => {
    let filtered = [...reports];
    
    // Filtre par terme de recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(report => 
        report.name?.toLowerCase().includes(term) ||
        report.reportId?.toLowerCase().includes(term) ||
        report.generatedBy?.toLowerCase().includes(term)
      );
    }
    
    // Filtre par format
    if (formatFilter) {
      filtered = filtered.filter(report => report.format === formatFilter);
    }
    
    // Filtre par date
    if (dateFilter.start) {
      const startDate = new Date(dateFilter.start);
      filtered = filtered.filter(report => {
        const reportDate = new Date(report.generationDate);
        return reportDate >= startDate;
      });
    }
    if (dateFilter.end) {
      const endDate = new Date(dateFilter.end);
      endDate.setHours(23, 59, 59, 999); // Fin de la journée
      filtered = filtered.filter(report => {
        const reportDate = new Date(report.generationDate);
        return reportDate <= endDate;
      });
    }
    
    // Filtre par archivage
    if (selectedTab === 'archived' || showArchivedOnly) {
      filtered = filtered.filter(report => report.isArchived);
    } else if (selectedTab !== 'archived') {
      filtered = filtered.filter(report => !report.isArchived);
    }
    
    // Filtres par type d'onglet
    if (selectedTab === 'campaign' && !campaignFilter) {
      filtered = filtered.filter(report => report.campaign && !report.execution);
    } else if (selectedTab === 'execution' && !executionFilter) {
      filtered = filtered.filter(report => report.execution);
    }
    
    setFilteredReports(filtered);
  }, [reports, searchTerm, formatFilter, dateFilter, selectedTab, showArchivedOnly, campaignFilter, executionFilter]);
  
  // Fonctions pour les actions sur les rapports
  const downloadReport = async (reportId) => {
    setLoading(prev => ({ ...prev, action: true }));
    
    try {
      // Pour un téléchargement, nous devons gérer la réponse comme un blob
      const response = await api.automata.reports.download(reportId);
      
      // Créer un URL pour le blob et déclencher le téléchargement
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      
      // Trouver le rapport pour obtenir le nom du fichier
      const report = reports.find(r => r.id === reportId);
      link.setAttribute('download', report?.fileName || `report-${reportId}.pdf`);
      
      document.body.appendChild(link);
      link.click();
      
      // Nettoyer
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error downloading report:', err);
      // Afficher une notification d'erreur
    } finally {
      setLoading(prev => ({ ...prev, action: false }));
    }
  };
  
  const archiveReport = async (reportId) => {
    setLoading(prev => ({ ...prev, action: true }));
    
    try {
      await api.automata.reports.archive(reportId);
      
      // Mettre à jour la liste des rapports
      await fetchReports();
      
      // Si le rapport archivé est sélectionné, actualiser les détails
      if (selectedReport && selectedReport.id === reportId) {
        await fetchReportDetails(reportId);
      }
      
      // Afficher une notification de succès
    } catch (err) {
      console.error('Error archiving report:', err);
      // Afficher une notification d'erreur
    } finally {
      setLoading(prev => ({ ...prev, action: false }));
    }
  };
  
  const deleteReport = async () => {
    if (!selectedReport) return;
    
    setLoading(prev => ({ ...prev, action: true }));
    
    try {
      await api.automata.reports.delete(selectedReport.id);
      
      // Actualiser la liste des rapports
      await fetchReports();
      
      // Fermer la modale et désélectionner le rapport
      setShowDeleteModal(false);
      setSelectedReport(null);
      
      // Afficher une notification de succès
    } catch (err) {
      console.error('Error deleting report:', err);
      // Afficher une notification d'erreur
    } finally {
      setLoading(prev => ({ ...prev, action: false }));
    }
  };
  
  const generateReport = async () => {
    setLoading(prev => ({ ...prev, action: true }));
    setError(prev => ({ ...prev, action: null }));
    
    try {
      // Récupérer l'ID utilisateur (à adapter selon votre système d'authentification)
      const userId = localStorage.getItem('user_id') || 'anonymous';
      
      // Générer le rapport selon le type
      let response;
      
      if (generationType === 'execution') {
        response = await api.automata.reports.generateExecutionReport(
          generationForm.sourceId,
          generationForm.format,
          generationForm.metadata,
          userId
        );
      } else {
        response = await api.automata.reports.generateCampaignReport(
          generationForm.sourceId,
          generationForm.format,
          generationForm.metadata,
          userId
        );
      }
      
      // Actualiser la liste et fermer la modale
      await fetchReports();
      setShowGenerateModal(false);
      
      // Sélectionner le rapport généré
      setSelectedReport(response);
      
      // Afficher une notification de succès
    } catch (err) {
      console.error('Error generating report:', err);
      setError(prev => ({ ...prev, action: 'Failed to generate report' }));
    } finally {
      setLoading(prev => ({ ...prev, action: false }));
    }
  };
  
  // Fonctions pour gérer les formulaires et modales
  const handleGenerationFormChange = (e) => {
    const { name, value } = e.target;
    setGenerationForm(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const openGenerateModal = (type) => {
    setGenerationType(type);
    setGenerationForm({
      sourceId: '',
      format: 'PDF',
      metadata: {}
    });
    
    // Charger les données nécessaires
    if (type === 'campaign') {
      fetchCampaigns();
    } else {
      fetchExecutions();
    }
    
    setShowGenerateModal(true);
  };
  
  // Obtenez une icône pour un format de rapport
  const getFormatIcon = (format) => {
    switch (format) {
      case 'PDF':
        return <FileText size={16} style={{ color: COLORS.error }} />;
      case 'EXCEL':
        return <FileSpreadsheet size={16} style={{ color: COLORS.success }} />;
      case 'HTML':
        return <FileImage size={16} style={{ color: COLORS.info }} />;
      case 'JSON':
        return <FileJson size={16} style={{ color: COLORS.warning }} />;
      case 'XML':
        return <File size={16} style={{ color: '#9c27b0' }} />;
      case 'TEXT':
        return <File size={16} style={{ color: '#9e9e9e' }} />;
      default:
        return <File size={16} />;
    }
  };
  
  // Rendu principal
  return (
    <Box sx={{ p: 2, height: 'calc(100vh - 100px)', overflow: 'auto' }}>
      {/* En-tête */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" fontWeight="bold">
          Gestion des rapports
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <MuiButton
            variant="outlined"
            startIcon={<Plus />}
            onClick={() => openGenerateModal('execution')}
          >
            Rapport d'exécution
          </MuiButton>
          <MuiButton
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={() => openGenerateModal('campaign')}
          >
            Rapport de campagne
          </MuiButton>
        </Box>
      </Box>
      
      {/* Onglets de navigation */}
      <Paper sx={{ mb: 3, borderRadius: 1, overflow: 'hidden' }}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(newValue);
            setCampaignFilter('');
            setExecutionFilter('');
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab 
            icon={<FileText size={18} />}
            iconPosition="start"
            label="Tous les rapports" 
            value="all" 
          />
          <Tab 
            icon={<Calendar size={18} />}
            iconPosition="start"
            label="Rapports de campagne" 
            value="campaign" 
          />
          <Tab 
            icon={<Play size={18} />}
            iconPosition="start"
            label="Rapports d'exécution" 
            value="execution" 
          />
          <Tab 
            icon={<Archive size={18} />}
            iconPosition="start"
            label="Rapports archivés" 
            value="archived" 
          />
        </Tabs>
      </Paper>
      
      {/* Filtres */}
      <MuiCard sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                placeholder="Rechercher des rapports..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  )
                }}
                size="small"
              />
            </Grid>
            
            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Format</InputLabel>
                <Select
                  value={formatFilter}
                  onChange={(e) => setFormatFilter(e.target.value)}
                  label="Format"
                >
                  <MenuItem value="">Tous les formats</MenuItem>
                  <MenuItem value="PDF">PDF</MenuItem>
                  <MenuItem value="EXCEL">Excel</MenuItem>
                  <MenuItem value="HTML">HTML</MenuItem>
                  <MenuItem value="XML">XML</MenuItem>
                  <MenuItem value="JSON">JSON</MenuItem>
                  <MenuItem value="TEXT">Texte</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={6} md={2}>
              <TextField
                fullWidth
                type="date"
                label="Date début"
                InputLabelProps={{ shrink: true }}
                value={dateFilter.start}
                onChange={(e) => setDateFilter(prev => ({ ...prev, start: e.target.value }))}
                size="small"
              />
            </Grid>
            
            <Grid item xs={6} md={2}>
              <TextField
                fullWidth
                type="date"
                label="Date fin"
                InputLabelProps={{ shrink: true }}
                value={dateFilter.end}
                onChange={(e) => setDateFilter(prev => ({ ...prev, end: e.target.value }))}
                size="small"
              />
            </Grid>
            
            <Grid item xs={6} md={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showArchivedOnly}
                    onChange={(e) => setShowArchivedOnly(e.target.checked)}
                    color="primary"
                  />
                }
                label="Archivés"
                sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
              />
            </Grid>
            
            <Grid item xs={6} md={1}>
              <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title="Actualiser">
                  <IconButton onClick={fetchReports} color="primary">
                    <RefreshCw size={20} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          
          {/* Filtres spécifiques selon l'onglet */}
          {selectedTab === 'campaign' && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel>Campagne</InputLabel>
                <Select
                  value={campaignFilter}
                  onChange={(e) => setCampaignFilter(e.target.value)}
                  label="Campagne"
                >
                  <MenuItem value="">Toutes les campagnes</MenuItem>
                  {campaigns.map(campaign => (
                    <MenuItem key={campaign.id} value={campaign.id}>
                      {campaign.name} ({campaign.campaignId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {!campaigns.length && !loading.campaigns && (
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <MuiButton
                    variant="text"
                    size="small"
                    startIcon={<RefreshCw size={16} />}
                    onClick={fetchCampaigns}
                  >
                    Charger les campagnes
                  </MuiButton>
                </Box>
              )}
            </Box>
          )}
          
          {selectedTab === 'execution' && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel>Exécution</InputLabel>
                <Select
                  value={executionFilter}
                  onChange={(e) => setExecutionFilter(e.target.value)}
                  label="Exécution"
                >
                  <MenuItem value="">Toutes les exécutions</MenuItem>
                  {executions.map(execution => (
                    <MenuItem key={execution.id} value={execution.id}>
                      {execution.executionName || `Exécution #${execution.id}`} ({execution.executionId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {!executions.length && !loading.executions && (
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <MuiButton
                    variant="text"
                    size="small"
                    startIcon={<RefreshCw size={16} />}
                    onClick={fetchExecutions}
                  >
                    Charger les exécutions
                  </MuiButton>
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </MuiCard>
      
      {/* Liste des rapports */}
      <MuiCard sx={{ boxShadow: 2 }}>
        <CardHeader 
          title={
            <Typography variant="h6">
              {selectedTab === 'all' && 'Tous les rapports'}
              {selectedTab === 'campaign' && 'Rapports de campagne'}
              {selectedTab === 'execution' && 'Rapports d\'exécution'}
              {selectedTab === 'archived' && 'Rapports archivés'}
              <Typography 
                component="span" 
                variant="subtitle1" 
                color="text.secondary" 
                sx={{ ml: 1 }}
              >
                ({filteredReports.length})
              </Typography>
            </Typography>
          }
          action={
            <MuiButton
              variant="text"
              startIcon={<Download />}
              disabled={filteredReports.length === 0}
              onClick={() => {
                // Fonctionnalité pour exporter la liste des rapports
              }}
            >
              Exporter la liste
            </MuiButton>
          }
        />
        <Divider />
        
        {loading.reports ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : error.reports ? (
          <Box sx={{ p: 4, textAlign: 'center', color: COLORS.error }}>
            <Typography>{error.reports}</Typography>
          </Box>
        ) : filteredReports.length === 0 ? (
          <Box sx={{ p: 4, textAlign: 'center', color: 'text.secondary' }}>
            <FileText size={48} style={{ color: '#9e9e9e', marginBottom: 16 }} />
            <Typography>Aucun rapport trouvé</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Générez un nouveau rapport ou modifiez vos filtres
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Format</TableCell>
                  <TableCell>Date de génération</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredReports.map((report) => (
                  <TableRow 
                    key={report.id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedReport(report);
                      fetchReportDetails(report.id);
                      setShowPreviewModal(true);
                    }}
                  >
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {getFormatIcon(report.format)}
                        <Box sx={{ ml: 1 }}>
                          <Typography variant="body2" fontWeight="medium">
                            {report.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {report.reportId}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        label={report.execution ? 'Exécution' : report.campaign ? 'Campagne' : 'Autre'}
                        color={report.execution ? 'info' : report.campaign ? 'secondary' : 'default'}
                      />
                    </TableCell>
                    <TableCell>
                      {report.execution ? (
                        <Tooltip title={`Voir l'exécution ${report.execution.executionId}`}>
                          <Box>
                            <Typography variant="body2">
                              {report.execution.executionName || `Exécution #${report.execution.id}`}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {report.execution.executionId}
                            </Typography>
                          </Box>
                        </Tooltip>
                      ) : report.campaign ? (
                        <Tooltip title={`Voir la campagne ${report.campaign.campaignId}`}>
                          <Box>
                            <Typography variant="body2">
                              {report.campaign.name}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {report.campaign.campaignId}
                            </Typography>
                          </Box>
                        </Tooltip>
                      ) : (
                        <Typography variant="body2" color="text.secondary">-</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        icon={getFormatIcon(report.format)} 
                        label={report.format}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>
                      <DateFormatter date={report.generationDate} format="full" />
                    </TableCell>
                    <TableCell>
                      <Chip 
                        size="small" 
                        icon={report.isArchived ? <Archive size={14} /> : <CheckCircle size={14} />}
                        label={report.isArchived ? 'Archivé' : 'Actif'}
                        color={report.isArchived ? 'default' : 'success'}
                        variant={report.isArchived ? 'outlined' : 'filled'}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <IconButton 
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadReport(report.id);
                          }}
                        >
                          <Download size={18} />
                        </IconButton>
                        
                        {!report.isArchived ? (
                          <IconButton 
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              archiveReport(report.id);
                            }}
                          >
                            <Archive size={18} />
                          </IconButton>
                        ) : (
                          <IconButton 
                            size="small"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedReport(report);
                              setShowDeleteModal(true);
                            }}
                          >
                            <Trash2 size={18} />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </MuiCard>
      
      {/* Modal de génération de rapport */}
      <Dialog
        open={showGenerateModal}
        onClose={() => setShowGenerateModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Générer un rapport {generationType === 'execution' ? 'd\'exécution' : 'de campagne'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 0.5 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>
                  {generationType === 'execution' ? 'Exécution' : 'Campagne'}*
                </InputLabel>
                <Select
                  name="sourceId"
                  value={generationForm.sourceId}
                  onChange={handleGenerationFormChange}
                  label={generationType === 'execution' ? 'Exécution' : 'Campagne'}
                  required
                >
                  <MenuItem value="">
                    Sélectionnez {generationType === 'execution' ? 'une exécution' : 'une campagne'}
                  </MenuItem>
                  {generationType === 'execution' ? (
                    executions.map(execution => (
                      <MenuItem key={execution.id} value={execution.id}>
                        {execution.executionName || `Exécution #${execution.id}`} ({execution.executionId})
                      </MenuItem>
                    ))
                  ) : (
                    campaigns.map(campaign => (
                      <MenuItem key={campaign.id} value={campaign.id}>
                        {campaign.name} ({campaign.campaignId})
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              
              {generationType === 'execution' && loading.executions && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              )}
              
              {generationType === 'campaign' && loading.campaigns && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              )}
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Format du rapport*</InputLabel>
                <Select
                  name="format"
                  value={generationForm.format}
                  onChange={handleGenerationFormChange}
                  label="Format du rapport*"
                >
                  <MenuItem value="PDF">PDF</MenuItem>
                  <MenuItem value="EXCEL">Excel</MenuItem>
                  <MenuItem value="HTML">HTML</MenuItem>
                  <MenuItem value="XML">XML</MenuItem>
                  <MenuItem value="JSON">JSON</MenuItem>
                  <MenuItem value="TEXT">Texte</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ChevronDown />}>
                  <Typography>Métadonnées additionnelles</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder='{"title": "Mon rapport", "author": "John Doe", ...}'
                    onChange={(e) => {
                      try {
                        const metadata = e.target.value ? JSON.parse(e.target.value) : {};
                        setGenerationForm(prev => ({ ...prev, metadata }));
                      } catch (err) {
                        // JSON non valide, ignorer
                      }
                    }}
                    helperText="Format JSON, optionnel"
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            
            {error.action && (
              <Grid item xs={12}>
                <Alert severity="error">
                  {error.action}
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowGenerateModal(false)}
            disabled={loading.action}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="primary"
            onClick={generateReport}
            disabled={!generationForm.sourceId || !generationForm.format || loading.action}
            startIcon={loading.action ? <CircularProgress size={20} /> : <FileText />}
          >
            Générer le rapport
          </MuiButton>
        </DialogActions>
      </Dialog>
      
      {/* Modal de prévisualisation de rapport */}
      <Dialog
        open={showPreviewModal && selectedReport !== null}
        onClose={() => setShowPreviewModal(false)}
        maxWidth="lg"
        fullWidth
      >
        {selectedReport && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: COLORS.primary, mr: 2 }}>
                    {getFormatIcon(selectedReport.format)}
                  </Avatar>
                  <Box>
                    <Typography variant="h6">{selectedReport.name}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {selectedReport.reportId}
                    </Typography>
                  </Box>
                </Box>
                <Chip
                  label={selectedReport.isArchived ? "Archivé" : "Actif"}
                  color={selectedReport.isArchived ? "default" : "success"}
                  size="small"
                />
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              {loading.report ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              ) : error.report ? (
                <Box sx={{ p: 4, textAlign: 'center', color: COLORS.error }}>
                  <Typography>{error.report}</Typography>
                </Box>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MuiCard variant="outlined">
                      <CardHeader 
                        title="Informations"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        avatar={<Info size={18} />}
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary">
                              Date de génération
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              <DateFormatter date={selectedReport.generationDate} format="full" />
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary">
                              Généré par
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {selectedReport.generatedBy || 'Inconnu'}
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary">
                              Taille
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {selectedReport.sizeInKb ? `${selectedReport.sizeInKb} KB` : 'Non disponible'}
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary">
                              Disponible
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Chip 
                              size="small" 
                              label={selectedReport.isAvailable ? "Oui" : "Non"}
                              color={selectedReport.isAvailable ? "success" : "default"}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </MuiCard>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <MuiCard variant="outlined">
                      <CardHeader 
                        title="Source"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        avatar={
                          selectedReport.execution 
                            ? <Play size={18} /> 
                            : selectedReport.campaign 
                              ? <Calendar size={18} /> 
                              : <Info size={18} />
                        }
                      />
                      <Divider />
                      <CardContent>
                        {selectedReport.execution ? (
                          <List disablePadding>
                            <ListItem disablePadding>
                              <ListItemText
                                primary="Type"
                                secondary="Exécution"
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <ListItem disablePadding sx={{ mt: 1 }}>
                              <ListItemText
                                primary="Nom"
                                secondary={selectedReport.execution.executionName || `Exécution #${selectedReport.execution.id}`}
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <ListItem disablePadding sx={{ mt: 1 }}>
                              <ListItemText
                                primary="ID"
                                secondary={selectedReport.execution.executionId}
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <ListItem disablePadding sx={{ mt: 1 }}>
                              <ListItemText
                                primary="Statut"
                                secondary={selectedReport.execution.status}
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <Box sx={{ mt: 2 }}>
                              <MuiButton 
                                variant="outlined"
                                size="small"
                                startIcon={<ExternalLink size={16} />}
                                onClick={() => window.location.href = `/atm-campaign/cpg-05-testresultanalyzer?executionId=${selectedReport.execution.id}`}
                              >
                                Voir l'exécution
                              </MuiButton>
                            </Box>
                          </List>
                        ) : selectedReport.campaign ? (
                          <List disablePadding>
                            <ListItem disablePadding>
                              <ListItemText
                                primary="Type"
                                secondary="Campagne"
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <ListItem disablePadding sx={{ mt: 1 }}>
                              <ListItemText
                                primary="Nom"
                                secondary={selectedReport.campaign.name}
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <ListItem disablePadding sx={{ mt: 1 }}>
                              <ListItemText
                                primary="ID"
                                secondary={selectedReport.campaign.campaignId}
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <ListItem disablePadding sx={{ mt: 1 }}>
                              <ListItemText
                                primary="Statut"
                                secondary={selectedReport.campaign.status}
                                primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                            <Box sx={{ mt: 2 }}>
                              <MuiButton 
                                variant="outlined"
                                size="small"
                                startIcon={<ExternalLink size={16} />}
                                onClick={() => window.location.href = `/atm-campaign/cpg-02-campaignmanager?id=${selectedReport.campaign.id}`}
                              >
                                Voir la campagne
                              </MuiButton>
                            </Box>
                          </List>
                        ) : (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
                            <Typography variant="body2" color="text.secondary">
                              Aucune source disponible
                            </Typography>
                          </Box>
                        )}
                      </CardContent>
                    </MuiCard>
                  </Grid>
                  
                  {/* Métadonnées */}
                  {selectedReport.metadata && Object.keys(selectedReport.metadata).length > 0 && (
                    <Grid item xs={12}>
                      <MuiCard variant="outlined">
                        <CardHeader 
                          title="Métadonnées"
                          titleTypographyProps={{ variant: 'subtitle1' }}
                          avatar={<Settings size={18} />}
                        />
                        <Divider />
                        <CardContent>
                          <TableContainer>
                            <Table size="small">
                              <TableBody>
                                {Object.entries(selectedReport.metadata).map(([key, value]) => (
                                  <TableRow key={key}>
                                    <TableCell width="30%" variant="head">
                                      <Typography variant="body2">{key}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {typeof value === 'object' ? JSON.stringify(value) : value.toString()}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </MuiCard>
                    </Grid>
                  )}
                  
                  {/* Prévisualisation uniquement pour les formats supportés */}
                  {selectedReport.format === 'HTML' && selectedReport.content && (
                    <Grid item xs={12}>
                      <MuiCard variant="outlined">
                        <CardHeader 
                          title="Prévisualisation"
                          titleTypographyProps={{ variant: 'subtitle1' }}
                          avatar={<Eye size={18} />}
                        />
                        <Divider />
                        <CardContent>
                          <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1, overflow: 'hidden' }}>
                            <iframe
                              srcDoc={new TextDecoder().decode(selectedReport.content)}
                              style={{ width: '100%', height: '400px', border: 'none' }}
                              title="HTML Preview"
                            ></iframe>
                          </Box>
                        </CardContent>
                      </MuiCard>
                    </Grid>
                  )}
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <MuiButton 
                onClick={() => setShowPreviewModal(false)}
              >
                Fermer
              </MuiButton>
              <MuiButton 
                variant="outlined"
                startIcon={<Download />}
                onClick={() => downloadReport(selectedReport.id)}
              >
                Télécharger
              </MuiButton>
              {!selectedReport.isArchived ? (
                <MuiButton
                  variant="outlined"
                  startIcon={<Archive />}
                  onClick={() => {
                    archiveReport(selectedReport.id);
                    setShowPreviewModal(false);
                  }}
                >
                  Archiver
                </MuiButton>
              ) : (
                <MuiButton
                  variant="contained"
                  color="error"
                  startIcon={<Trash2 />}
                  onClick={() => {
                    setShowPreviewModal(false);
                    setShowDeleteModal(true);
                  }}
                >
                  Supprimer
                </MuiButton>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      
      {/* Modal de confirmation de suppression */}
      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <DialogTitle sx={{ color: COLORS.error, display: 'flex', alignItems: 'center', gap: 1 }}>
          <AlertTriangle size={24} color={COLORS.error} />
          Confirmer la suppression
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Êtes-vous sûr de vouloir supprimer définitivement le rapport "{selectedReport?.name}" ?
            Cette action est irréversible.
          </Typography>
          <Alert severity="warning" sx={{ mt: 2 }}>
            Les données liées à ce rapport seront définitivement supprimées.
          </Alert>
        </DialogContent>
        <DialogActions>
          <MuiButton 
            onClick={() => setShowDeleteModal(false)}
            disabled={loading.action}
          >
            Annuler
          </MuiButton>
          <MuiButton 
            variant="contained" 
            color="error"
            onClick={deleteReport}
            disabled={loading.action}
            startIcon={loading.action ? <CircularProgress size={20} /> : <Trash2 />}
          >
            Supprimer
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReportingHub;