import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Wifi, ArrowDown, ArrowUp, Clock,
  RefreshCw, AlertTriangle, Activity,
  BatteryWarning
} from 'lucide-react';
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import DateFormatter from '../../components/shared/DateFormatter';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

// Fonction utilitaire pour formater les bytes
const formatBytes = (bytes) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const NetworkMetrics = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState({
    startTime: new Date(Date.now() - 3600000).toISOString(),
    endTime: new Date().toISOString()
  });

  const [autoRefresh, setAutoRefresh] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(30); // secondes

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchMetrics();

    if (autoRefresh) {
      const interval = setInterval(fetchMetrics, refreshInterval * 1000);
      return () => clearInterval(interval);
    }
  }, [navigate, timeRange, autoRefresh, refreshInterval]);

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const response = await apiService.monitoring.metrics.network.getAll(
        timeRange.startTime,
        timeRange.endTime
      );
      setMetrics(response);
      setError(null);
    } catch (err) {
      setError(`Erreur lors de la récupération des métriques : ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const timeRangeOptions = [
    { value: '5m', label: '5 minutes', ms: 300000 },
    { value: '15m', label: '15 minutes', ms: 900000 },
    { value: '1h', label: '1 heure', ms: 3600000 },
    { value: '6h', label: '6 heures', ms: 21600000 }
  ];

  const MetricCard = ({ title, value, secondaryValue, icon: Icon, status, trend }) => (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className={`p-3 rounded-lg ${
            status === 'warning' ? 'bg-yellow-100 text-yellow-600' :
            status === 'critical' ? 'bg-red-100 text-red-600' :
            'bg-blue-100 text-blue-600'
          }`}>
            <Icon size={24} />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">{title}</h3>
            <div className="flex items-baseline space-x-2">
              <p className="text-2xl font-semibold">{value}</p>
              {secondaryValue && (
                <p className="text-sm text-gray-500">{secondaryValue}</p>
              )}
            </div>
          </div>
        </div>
        {trend && (
          <div className={`flex items-center text-sm ${
            trend > 0 ? 'text-green-500' : 'text-red-500'
          }`}>
            {trend > 0 ? '+' : ''}{trend}%
          </div>
        )}
      </div>
    </Card>
  );

  const getLatestMetrics = () => {
    if (!metrics.length) return null;
    return metrics[metrics.length - 1];
  };

  const latestMetrics = getLatestMetrics();

  const columns = [
    {
      key: 'timestamp',
      header: 'Horodatage',
      render: (row) => <DateFormatter date={row.timestamp} format="full" />
    },
    {
      key: 'bytesIn',
      header: 'Trafic entrant',
      render: (row) => formatBytes(row.bytesIn)
    },
    {
      key: 'bytesOut',
      header: 'Trafic sortant',
      render: (row) => formatBytes(row.bytesOut)
    },
    {
      key: 'latency',
      header: 'Latence',
      render: (row) => `${row.latency?.toFixed(2)} ms`
    },
    {
      key: 'packetLoss',
      header: 'Perte de paquets',
      render: (row) => `${row.packetLoss || 0}%`
    },
    {
      key: 'errors',
      header: 'Erreurs',
      render: (row) => (
        <div className="flex items-center">
          <span className={row.errorCount > 0 ? 'text-red-500' : 'text-gray-500'}>
            {row.errorCount}
          </span>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Métriques Réseau
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Surveillance du trafic et des performances réseau
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            value={timeRangeOptions.find(opt => opt.ms === (new Date(timeRange.endTime) - new Date(timeRange.startTime)))?.value}
            onChange={(e) => setTimeRange({
              startTime: new Date(Date.now() - timeRangeOptions.find(opt => opt.value === e.target.value).ms).toISOString(),
              endTime: new Date().toISOString()
            })}
            className="rounded-lg border border-gray-300 p-2"
          >
            {timeRangeOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <select
            value={refreshInterval}
            onChange={(e) => setRefreshInterval(Number(e.target.value))}
            className="rounded-lg border border-gray-300 p-2"
          >
            <option value={10}>10s</option>
            <option value={30}>30s</option>
            <option value={60}>1min</option>
          </select>

          <Button
            variant={autoRefresh ? 'primary' : 'outline'}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            Auto-refresh
          </Button>

          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchMetrics}
          >
            Actualiser
          </Button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700">{error}</p>
          </div>
        </div>
      )}

      {/* Métriques clés */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <MetricCard
          title="Latence"
          value={`${latestMetrics?.latency?.toFixed(2) || '-'} ms`}
          icon={Clock}
          status={latestMetrics?.latency > 200 ? 'critical' : 
                 latestMetrics?.latency > 100 ? 'warning' : 'normal'}
        />
        <MetricCard
          title="Perte de paquets"
          value={`${latestMetrics?.packetLoss || 0}%`}
          icon={BatteryWarning}
          status={latestMetrics?.packetLoss > 5 ? 'critical' :
                 latestMetrics?.packetLoss > 1 ? 'warning' : 'normal'}
        />
        <MetricCard
          title="Trafic entrant"
          value={latestMetrics?.bytesIn ? formatBytes(latestMetrics.bytesIn) : '-'}
          icon={ArrowDown}
        />
        <MetricCard
          title="Trafic sortant"
          value={latestMetrics?.bytesOut ? formatBytes(latestMetrics.bytesOut) : '-'}
          icon={ArrowUp}
        />
      </div>

      {/* Graphiques */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card>
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Trafic réseau</h2>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={metrics}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                  />
                  <YAxis tickFormatter={(value) => formatBytes(value)} />
                  <Tooltip
                    labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                    formatter={(value) => [formatBytes(value), '']}
                  />
                  <Area
                    type="monotone"
                    dataKey="bytesIn"
                    stackId="1"
                    stroke="#3B82F6"
                    fill="#3B82F6"
                    fillOpacity={0.4}
                    name="Trafic entrant"
                  />
                  <Area
                    type="monotone"
                    dataKey="bytesOut"
                    stackId="1"
                    stroke="#10B981"
                    fill="#10B981"
                    fillOpacity={0.4}
                    name="Trafic sortant"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Latence et perte de paquets</h2>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={metrics}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                  />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip
                    labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                  />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="latency"
                    stroke="#3B82F6"
                    name="Latence (ms)"
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="packetLoss"
                    stroke="#EF4444"
                    name="Perte de paquets (%)"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Card>
      </div>

      {/* Tableau détaillé */}
      <Card>
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Historique détaillé</h2>
          <DataGrid
            data={metrics}
            columns={columns}
            loading={loading}
            emptyMessage="Aucune donnée disponible pour la période sélectionnée"
          />
        </div>
      </Card>
    </div>
  );
};

export default NetworkMetrics;