// components/shared/workflow/WorkflowNodes.js
import React from 'react';
import { Handle, Position } from 'reactflow';

// Node représentant un état standard
export function StateNode({ data, selected }) {
  return (
    <div className={`px-4 py-2 rounded-lg shadow-md transition-all duration-200 ${
      selected ? 'ring-2 ring-blue-500 shadow-lg' : ''
    } bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700`}>
      {/* Points de connexion d'entrée et de sortie */}
      <Handle
        type="target"
        position={Position.Top}
        className="w-2 h-2 bg-blue-500"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-2 h-2 bg-blue-500"
      />
      
      <div className="text-center">
        <div className="font-medium text-gray-800 dark:text-white">{data.label}</div>
        {data.description && (
          <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
            {data.description}
          </div>
        )}
      </div>
    </div>
  );
}

// Node représentant l'état initial
export function InitialNode({ data, selected }) {
  return (
    <div className={`px-4 py-2 rounded-lg shadow-md transition-all duration-200 ${
      selected ? 'ring-2 ring-blue-500 shadow-lg' : ''
    } bg-green-500 text-white border border-green-600`}>
      {/* Point de connexion uniquement en sortie */}
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-2 h-2 bg-white"
      />
      
      <div className="text-center">
        <div className="font-medium">{data.label}</div>
        <div className="text-xs mt-1">État Initial</div>
      </div>
    </div>
  );
}

// Node représentant un état final
export function FinalNode({ data, selected }) {
  return (
    <div className={`px-4 py-2 rounded-lg shadow-md transition-all duration-200 ${
      selected ? 'ring-2 ring-blue-500 shadow-lg' : ''
    } bg-red-500 text-white border border-red-600`}>
      {/* Point de connexion uniquement en entrée */}
      <Handle
        type="target"
        position={Position.Top}
        className="w-2 h-2 bg-white"
      />
      
      <div className="text-center">
        <div className="font-medium">{data.label}</div>
        <div className="text-xs mt-1">État Final</div>
      </div>
    </div>
  );
}

// Configuration des nodeTypes pour ReactFlow
export const nodeTypes = {
  stateNode: StateNode,
  initialNode: InitialNode,
  finalNode: FinalNode
};

// Props de connexion personnalisés
export const defaultEdgeOptions = {
  style: { strokeWidth: 2, stroke: '#b1b1b7' },
  type: 'smoothstep',
  markerEnd: {
    type: 'arrowclosed',
    width: 20,
    height: 20,
    color: '#b1b1b7',
  },
  animated: true
};