import React, { useState, useEffect } from 'react';
import { 
  Folder, 
  Plus, 
  Edit, 
  Trash2, 
  RefreshCw, 
  CheckCircle, 
  AlertTriangle,
  X,
  Save,
  FolderOpen
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const ConfigGroups = () => {
  // États
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [selectedGroup, setSelectedGroup] = useState(null);
  
  // Formulaire groupe
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    path: '',
    isActive: true
  });
  
  // Chargement des données
  const fetchGroups = async () => {
    setLoading(true);
    try {
      const data = await apiService.config.groups.getAll();
      setGroups(data || []);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des groupes:', err);
      setError('Impossible de charger les groupes. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchGroups();
  }, []);
  
  // Gestionnaires d'événements
  const handleOpenModal = (mode, group = null) => {
    setModalMode(mode);
    if (group) {
      setSelectedGroup(group);
      setFormValues({
        name: group.name,
        description: group.description || '',
        path: group.path || '',
        isActive: group.isActive
      });
    } else {
      setSelectedGroup(null);
      setFormValues({
        name: '',
        description: '',
        path: '',
        isActive: true
      });
    }
    setShowModal(true);
  };
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (modalMode === 'create') {
        await apiService.config.groups.create(formValues);
        setSuccessMessage('Groupe créé avec succès');
      } else {
        await apiService.config.groups.update(selectedGroup.id, formValues);
        setSuccessMessage('Groupe mis à jour avec succès');
      }
      
      setShowModal(false);
      fetchGroups();
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDelete = async (id) => {
    if (!window.confirm(`Êtes-vous sûr de vouloir supprimer ce groupe ?`)) {
      return;
    }
    
    setLoading(true);
    try {
      await apiService.config.groups.delete(id);
      setSuccessMessage('Groupe supprimé avec succès');
      fetchGroups();
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError('Impossible de supprimer le groupe. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Configuration des colonnes
  const columns = [
    {
      key: 'name',
      header: 'Nom',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <FolderOpen className="h-5 w-5 mr-2 text-blue-500" />
          <span className="font-medium text-gray-900 dark:text-white">
            {row.name}
          </span>
        </div>
      ),
    },
    {
      key: 'path',
      header: 'Chemin',
      sortable: true,
      render: (row) => (
        <span className="text-gray-700 dark:text-gray-300">
          {row.path || '-'}
        </span>
      ),
    },
    {
      key: 'description',
      header: 'Description',
      sortable: true,
      render: (row) => (
        <span className="text-gray-700 dark:text-gray-300">
          {row.description || '-'}
        </span>
      ),
    },
    {
      key: 'isActive',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={row.isActive ? 'active' : 'inactive'} 
          customLabel={row.isActive ? 'Actif' : 'Inactif'}
        />
      ),
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            title="Modifier"
            onClick={() => handleOpenModal('edit', row)}
          />
          <Button
            variant="outline"
            size="sm"
            icon={Trash2}
            title="Supprimer"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
    },
  ];
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Folder className="mr-2 h-6 w-6 text-blue-500" />
            Groupes de configuration
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les groupes pour organiser vos configurations
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchGroups}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={Plus}
            onClick={() => handleOpenModal('create')}
          >
            Nouveau groupe
          </Button>
        </div>
      </div>
      
      {/* Messages */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 mb-4">
          <div className="flex">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <span className="text-green-700 dark:text-green-300">{successMessage}</span>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 mb-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-red-700 dark:text-red-300">{error}</span>
          </div>
        </div>
      )}
      
      {/* Liste des groupes */}
      <Card>
        <DataGrid
          data={groups}
          columns={columns}
          loading={loading}
          emptyMessage="Aucun groupe configuré"
        />
      </Card>
      
      {/* Modal de création/édition */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                {modalMode === 'create' ? 'Nouveau groupe' : 'Modifier le groupe'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmit} className="p-6">
              <div className="space-y-4">
                {/* Nom */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom *
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                {/* Chemin */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Chemin
                  </label>
                  <input
                    type="text"
                    name="path"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.path}
                    onChange={handleInputChange}
                    placeholder="Ex: /parent/child"
                  />
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Chemin d'accès hiérarchique (optionnel)
                  </p>
                </div>
                
                {/* Description */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    rows="3"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formValues.description}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                
                {/* Statut */}
                <div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="isActive"
                      id="isActive"
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                      checked={formValues.isActive}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900 dark:text-white">
                      Groupe actif
                    </label>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  icon={Save}
                  loading={loading}
                >
                  {modalMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigGroups;