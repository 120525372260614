import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Paper,
  Chip,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';

import {
  Database,
  Table as TableIcon,
  Play,
  StopCircle,
  Save,
  Trash2,
  RefreshCw,
  Clock,
  FileText,
  CheckCircle,
  XCircle,
  AlertTriangle,
  ChevronDown,
  ChevronRight,
  Eye,
  Search,
  Download,
  Copy,
  RotateCcw,
  RotateCw,
  Settings,
  Wifi,
  WifiOff,
  Folder,
  FolderOpen,
  Code,
  Edit,
  Info,
  Package,
  Layers,
  Lock,
  User,
  Server,
  Layout,
  Zap,
  Hash,
  Key,
  ScrollText,
  Locate,
  ArrowRight
} from 'lucide-react';

/**
 * OracleCapture Component - Interface réutilisable pour interagir avec des bases de données Oracle
 * 
 * @param {Object} props - Propriétés du composant
 * @param {Object} props.data - Données actuelles de la base de données
 * @param {Function} props.onInteractionSent - Callback lors de l'envoi d'une interaction
 * @param {boolean} props.disabled - Si le panneau est désactivé
 * @param {boolean} props.highlighted - Si le panneau doit être mis en évidence
 * @param {string} props.mode - Mode d'opération ('record' ou 'execute')
 * @param {boolean} props.connectionStatus - État de la connexion
 */
const OracleCapture = ({
  data,
  onInteractionSent,
  disabled = false,
  highlighted = false,
  mode = 'record',
  connectionStatus = false
}) => {
  // États locaux
  const [lastAction, setLastAction] = useState(null);
  const [sqlQuery, setSqlQuery] = useState('');
  const [queryResults, setQueryResults] = useState(null);
  const [isExecuting, setIsExecuting] = useState(false);
  const [databaseObjects, setDatabaseObjects] = useState({
    schemas: [],
    selectedSchema: null,
    selectedObject: null,
    selectedObjectType: null
  });
  const [expandedSchemas, setExpandedSchemas] = useState({});
  const [expandedObjectTypes, setExpandedObjectTypes] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [transactionActive, setTransactionActive] = useState(false);
  const [queryHistory, setQueryHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [connectionInfo, setConnectionInfo] = useState({
    database: '',
    instance: '',
    server: '',
    port: '',
    user: '',
    connectionId: '',
    status: 'disconnected'
  });
  const [objectDetails, setObjectDetails] = useState(null);
  const [objectData, setObjectData] = useState(null);

  const sqlEditorRef = useRef(null);

  // Extraire les infos de connexion depuis les données reçues
  useEffect(() => {
    if (data?.technicalProperties?.connection) {
      const conn = data.technicalProperties.connection;
      setConnectionInfo({
        database: conn.database || '',
        instance: conn.instance || '',
        server: conn.server || '',
        port: conn.port || '',
        user: conn.user || '',
        connectionId: conn.connectionId || '',
        status: connectionStatus ? 'connected' : 'disconnected'
      });
    }
  }, [data, connectionStatus]);

  // Fonction pour gérer l'envoi de requête SQL
  const handleSqlSubmit = () => {
    if (!sqlQuery.trim() || disabled || !connectionStatus) return;

    setIsExecuting(true);

    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "SqlQuery",
        nodeName: "ExecuteQuery",
        nodeValue: sqlQuery,
        actionType: "Database",
        actionValue: sqlQuery,
        actionName: "ExecuteQuery",
        timestamp: Date.now(),
        database: connectionInfo.database,
        instance: connectionInfo.instance,
        connectionId: connectionInfo.connectionId
      }
    };

    onInteractionSent(interactionPayload);

    // Sauvegarder dans l'historique
    setQueryHistory(prev => [
      { 
        query: sqlQuery, 
        timestamp: new Date(), 
        status: 'pending' 
      },
      ...prev
    ]);

    setLastAction({
      type: 'SqlQuery',
      name: sqlQuery.length > 30 ? `${sqlQuery.substring(0, 30)}...` : sqlQuery,
      timestamp: new Date()
    });
  };

  // Fonction pour gérer la réception des résultats
  const handleQueryResults = (results) => {
    setIsExecuting(false);
    setQueryResults(results);

    // Mettre à jour le statut dans l'historique
    setQueryHistory(prev => {
      const updated = [...prev];
      if (updated.length > 0) {
        updated[0] = {
          ...updated[0],
          status: results.error ? 'error' : 'success',
          error: results.error,
          affectedRows: results.affectedRows,
          duration: results.duration
        };
      }
      return updated;
    });

    // Vérifier si la requête a modifié une table
    const isDML = /^(INSERT|UPDATE|DELETE|ALTER|CREATE|DROP|MERGE)/i.test(sqlQuery.trim());
    if (isDML && !results.error) {
      setTransactionActive(true);
    }
  };

  // Fonction pour explorer un objet de la base de données
  const handleExploreObject = (schema, objectName, objectType) => {
    if (disabled || !connectionStatus) return;

    setDatabaseObjects(prev => ({
      ...prev,
      selectedSchema: schema,
      selectedObject: objectName,
      selectedObjectType: objectType
    }));
    setIsExecuting(true);

    let queryToSend = '';
    
    // Adapté les requêtes au type d'objet Oracle
    switch (objectType) {
      case 'TABLE':
      case 'VIEW':
        queryToSend = `SELECT * FROM ${schema}.${objectName} WHERE ROWNUM <= 100`;
        break;
      case 'PROCEDURE':
      case 'FUNCTION':
        queryToSend = `SELECT TEXT FROM ALL_SOURCE WHERE OWNER='${schema}' AND NAME='${objectName}' AND TYPE='${objectType}' ORDER BY LINE`;
        break;
      case 'PACKAGE':
        queryToSend = `SELECT TEXT FROM ALL_SOURCE WHERE OWNER='${schema}' AND NAME='${objectName}' AND TYPE IN ('PACKAGE', 'PACKAGE BODY') ORDER BY TYPE, LINE`;
        break;
      case 'SEQUENCE':
        queryToSend = `SELECT * FROM ALL_SEQUENCES WHERE SEQUENCE_OWNER='${schema}' AND SEQUENCE_NAME='${objectName}'`;
        break;
      case 'INDEX':
        queryToSend = `SELECT * FROM ALL_INDEXES WHERE OWNER='${schema}' AND INDEX_NAME='${objectName}'`;
        break;
      case 'TRIGGER':
        queryToSend = `SELECT TRIGGER_BODY FROM ALL_TRIGGERS WHERE OWNER='${schema}' AND TRIGGER_NAME='${objectName}'`;
        break;
      default:
        queryToSend = `SELECT * FROM ${schema}.${objectName} WHERE ROWNUM <= 100`;
    }

    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ObjectExplore",
        nodeName: "ExploreObject",
        nodeValue: `${schema}.${objectName}`,
        actionType: "Database",
        actionValue: queryToSend,
        actionName: "ExploreObject",
        objectType: objectType,
        timestamp: Date.now(),
        database: connectionInfo.database,
        instance: connectionInfo.instance,
        connectionId: connectionInfo.connectionId
      }
    };

    onInteractionSent(interactionPayload);

    setLastAction({
      type: 'ObjectExplore',
      name: `Explore ${schema}.${objectName} (${objectType})`,
      timestamp: new Date()
    });
  };

  // Fonction pour obtenir les détails d'un objet
  const handleGetObjectDetails = (schema, objectName, objectType) => {
    if (disabled || !connectionStatus) return;

    setIsExecuting(true);

    let queryToSend = '';
    
    // Adapter les requêtes selon le type d'objet
    switch (objectType) {
      case 'TABLE':
        queryToSend = `SELECT COLUMN_NAME, DATA_TYPE, NULLABLE, DATA_DEFAULT, 
                      (SELECT CASE WHEN POSITION IS NOT NULL THEN 'YES' ELSE 'NO' END
                       FROM ALL_CONS_COLUMNS ACC
                       JOIN ALL_CONSTRAINTS AC ON ACC.CONSTRAINT_NAME = AC.CONSTRAINT_NAME
                       WHERE AC.CONSTRAINT_TYPE = 'P'
                       AND AC.TABLE_NAME = '${objectName}'
                       AND AC.OWNER = '${schema}'
                       AND ACC.COLUMN_NAME = C.COLUMN_NAME
                       AND ROWNUM = 1) AS IS_PRIMARY_KEY
                       FROM ALL_TAB_COLUMNS C
                       WHERE OWNER = '${schema}' AND TABLE_NAME = '${objectName}'
                       ORDER BY COLUMN_ID`;
        break;
      case 'VIEW':
        queryToSend = `SELECT TEXT FROM ALL_VIEWS WHERE OWNER='${schema}' AND VIEW_NAME='${objectName}'`;
        break;
      case 'PROCEDURE':
      case 'FUNCTION':
      case 'PACKAGE':
        queryToSend = `SELECT TEXT FROM ALL_SOURCE WHERE OWNER='${schema}' AND NAME='${objectName}' ORDER BY TYPE, LINE`;
        break;
      case 'SEQUENCE':
        queryToSend = `SELECT * FROM ALL_SEQUENCES WHERE SEQUENCE_OWNER='${schema}' AND SEQUENCE_NAME='${objectName}'`;
        break;
      case 'INDEX':
        queryToSend = `SELECT * FROM ALL_INDEXES WHERE OWNER='${schema}' AND INDEX_NAME='${objectName}'`;
        break;
      case 'TRIGGER':
        queryToSend = `SELECT * FROM ALL_TRIGGERS WHERE OWNER='${schema}' AND TRIGGER_NAME='${objectName}'`;
        break;
      default:
        queryToSend = `SELECT * FROM ALL_OBJECTS WHERE OWNER='${schema}' AND OBJECT_NAME='${objectName}' AND OBJECT_TYPE='${objectType}'`;
    }

    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "ObjectDetails",
        nodeName: "GetObjectDetails",
        nodeValue: `${schema}.${objectName}`,
        actionType: "Database",
        actionValue: queryToSend,
        actionName: "GetObjectDetails",
        objectType: objectType,
        timestamp: Date.now(),
        database: connectionInfo.database,
        instance: connectionInfo.instance,
        connectionId: connectionInfo.connectionId
      }
    };

    onInteractionSent(interactionPayload);

    setLastAction({
      type: 'ObjectDetails',
      name: `Get Details ${schema}.${objectName} (${objectType})`,
      timestamp: new Date()
    });
  };

  // Fonction pour la gestion des transactions
  const handleTransaction = (action) => {
    if (disabled || !connectionStatus) return;

    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "Transaction",
        nodeName: action === 'commit' ? "Commit" : "Rollback",
        nodeValue: action,
        actionType: "Database",
        actionValue: action.toUpperCase(),
        actionName: action === 'commit' ? "Commit" : "Rollback",
        timestamp: Date.now(),
        database: connectionInfo.database,
        instance: connectionInfo.instance,
        connectionId: connectionInfo.connectionId
      }
    };

    onInteractionSent(interactionPayload);

    setLastAction({
      type: 'Transaction',
      name: action === 'commit' ? "Commit Transaction" : "Rollback Transaction",
      timestamp: new Date()
    });

    if (action === 'commit' || action === 'rollback') {
      setTransactionActive(false);
    }
  };

  // Fonction pour rafraîchir la structure de la base de données
  const handleRefreshStructure = () => {
    if (disabled || !connectionStatus) return;

    const interactionPayload = {
      direction: "REQUEST",
      type: mode === 'record' ? "REC_ACTION" : "RUN_ACTION",
      timestamp: new Date().toISOString(),
      technicalProperties: {
        nodeType: "DatabaseStructure",
        nodeName: "RefreshStructure",
        nodeValue: "refresh",
        actionType: "Database",
        actionValue: "REFRESH_STRUCTURE",
        actionName: "RefreshStructure",
        timestamp: Date.now(),
        database: connectionInfo.database,
        instance: connectionInfo.instance,
        connectionId: connectionInfo.connectionId
      }
    };

    onInteractionSent(interactionPayload);

    setLastAction({
      type: 'RefreshStructure',
      name: "Refresh Database Structure",
      timestamp: new Date()
    });
  };

  // Effet pour traiter les données reçues
  useEffect(() => {
    if (data && data.technicalProperties) {
      // Traiter les résultats de requête
      if (data.technicalProperties.queryResults) {
        handleQueryResults(data.technicalProperties.queryResults);
      }

      // Traiter la structure de la base de données
      if (data.technicalProperties.databaseStructure) {
        setDatabaseObjects(prev => ({
          ...prev,
          schemas: data.technicalProperties.databaseStructure || []
        }));
      }

      // Traiter les détails de l'objet
      if (data.technicalProperties.objectDetails) {
        setObjectDetails(data.technicalProperties.objectDetails);
        setIsExecuting(false);
      }

      // Traiter les données de l'objet
      if (data.technicalProperties.objectData) {
        setObjectData(data.technicalProperties.objectData);
        setIsExecuting(false);
      }
    }
  }, [data]);

  // Composant d'en-tête
  const Header = () => {
    return (
      <Box sx={{
        bgcolor: 'primary.dark',
        p: 3,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        color: 'white'
      }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center' }}
          >
            <Database sx={{ width: 20, height: 20 }} />
            <Typography variant="h6">
              Oracle Database {mode === 'record' ? 'Enregistreur' : 'Exécuteur'}
            </Typography>
          </Stack>

          <Chip
            size="small"
            icon={connectionStatus ? 
              <Wifi sx={{ fontSize: 16 }} /> : 
              <WifiOff sx={{ fontSize: 16 }} />}
            color={connectionStatus ? 'success' : 'error'}
            label={connectionStatus ? 'CONNECTÉ' : 'DÉCONNECTÉ'}
          />
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Instance
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.instance || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Base de données
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.database || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{
              p: 1,
              bgcolor: 'primary.main'
            }}>
              <Typography variant="caption" sx={{ color: 'primary.light' }}>
                Utilisateur
              </Typography>
              <Typography noWrap sx={{ color: 'white' }}>
                {connectionInfo.user || 'N/A'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // Composant pour l'éditeur SQL/PL-SQL
  const SqlEditor = () => {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Code size={16} style={{ marginRight: 8 }} />
          Éditeur SQL/PL-SQL
        </Typography>
        <TextField
          inputRef={sqlEditorRef}
          fullWidth
          multiline
          rows={8}
          variant="outlined"
          value={sqlQuery}
          onChange={(e) => setSqlQuery(e.target.value)}
          placeholder="Entrez votre requête SQL ou PL/SQL ici..."
          disabled={disabled || !connectionStatus}
          sx={{
            fontFamily: 'monospace',
            '& .MuiInputBase-root': {
              fontFamily: 'monospace'
            }
          }}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Play />}
              onClick={handleSqlSubmit}
              disabled={isExecuting || disabled || !connectionStatus || !sqlQuery.trim()}
              sx={{ mr: 1 }}
            >
              Exécuter
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<Trash2 />}
              onClick={() => setSqlQuery('')}
              disabled={disabled || !sqlQuery}
              sx={{ mr: 1 }}
            >
              Effacer
            </Button>
            <Button
              variant="outlined"
              startIcon={<Save />}
              onClick={() => {
                navigator.clipboard.writeText(sqlQuery);
                setSnackbar({
                  open: true,
                  message: 'SQL copié dans le presse-papiers',
                  severity: 'success'
                });
              }}
              disabled={!sqlQuery.trim()}
            >
              Copier
            </Button>
          </Box>
          <Box>
            {transactionActive && (
              <>
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<RotateCw />}
                  onClick={() => handleTransaction('commit')}
                  disabled={disabled || !connectionStatus}
                  sx={{ mr: 1 }}
                >
                  Commit
                </Button>
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<RotateCcw />}
                  onClick={() => handleTransaction('rollback')}
                  disabled={disabled || !connectionStatus}
                >
                  Rollback
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  // Composant pour l'affichage des résultats
  const ResultsPanel = () => {
    // Si en cours d'exécution
    if (isExecuting) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <CircularProgress size={40} />
          <Typography variant="body1" sx={{ ml: 2 }}>
            Exécution de la requête...
          </Typography>
        </Box>
      );
    }

    // Si pas de résultats
    if (!queryResults) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Info size={48} color="#9e9e9e" />
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            Aucun résultat à afficher. Exécutez une requête pour voir les résultats ici.
          </Typography>
        </Box>
      );
    }

    // Si erreur
    if (queryResults.error) {
      return (
        <Box sx={{ p: 3, bgcolor: 'error.light', borderRadius: 1 }}>
          <Typography variant="subtitle1" color="error" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AlertTriangle size={20} style={{ marginRight: 8 }} />
            Erreur ORA
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
            {queryResults.error}
          </Typography>
        </Box>
      );
    }

    // Si requête de modification (INSERT, UPDATE, DELETE)
    if (queryResults.affectedRows !== undefined) {
      return (
        <Box sx={{ p: 3, bgcolor: 'success.light', borderRadius: 1 }}>
          <Typography variant="subtitle1" color="success.dark" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CheckCircle size={20} style={{ marginRight: 8 }} />
            Opération réussie
          </Typography>
          <Typography variant="body1">
            {queryResults.affectedRows} ligne{queryResults.affectedRows !== 1 ? 's' : ''} affectée{queryResults.affectedRows !== 1 ? 's' : ''}
          </Typography>
          {queryResults.duration && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Durée: {queryResults.duration} ms
            </Typography>
          )}
          {transactionActive && (
            <Typography variant="body2" color="warning.dark" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              <AlertTriangle size={16} style={{ marginRight: 8 }} />
              Transaction active. N'oubliez pas de faire un COMMIT ou un ROLLBACK.
            </Typography>
          )}
        </Box>
      );
    }

    // Si données de résultat
    if (queryResults.columns && queryResults.rows) {
      return (
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
              <TableIcon size={16} style={{ marginRight: 8 }} />
              Résultats ({queryResults.rows.length} lignes)
            </Typography>
            <Box>
              <Button
                size="small"
                startIcon={<Download />}
                onClick={() => exportResults()}
                disabled={queryResults.rows.length === 0}
                sx={{ mr: 1 }}
              >
                Exporter CSV
              </Button>
              <Button
                size="small"
                startIcon={<Copy />}
                onClick={() => copyToClipboard()}
                disabled={queryResults.rows.length === 0}
              >
                Copier
              </Button>
            </Box>
          </Box>

          <TextField
            size="small"
            fullWidth
            placeholder="Filtrer les résultats..."
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            InputProps={{
              startAdornment: <Search size={16} style={{ marginRight: 8 }} />,
              endAdornment: filterValue ? (
                <IconButton size="small" onClick={() => setFilterValue('')}>
                  <XCircle size={16} />
                </IconButton>
              ) : null
            }}
            sx={{ mb: 2 }}
          />

          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {queryResults.columns.map((column, index) => (
                    <TableCell key={index} sx={{ fontWeight: 'bold' }}>
                      {column.name}
                      <Typography variant="caption" display="block" color="text.secondary">
                        {column.type}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {queryResults.rows
                  .filter(row => {
                    if (!filterValue) return true;
                    return Object.values(row).some(value => 
                      value !== null && value.toString().toLowerCase().includes(filterValue.toLowerCase())
                    );
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow key={rowIndex} hover>
                      {queryResults.columns.map((column, colIndex) => (
                        <TableCell key={colIndex}>
                          {formatCellValue(row[column.name])}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                {queryResults.rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={queryResults.columns.length} align="center">
                      Aucune donnée
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={queryResults.rows.filter(row => {
              if (!filterValue) return true;
              return Object.values(row).some(value => 
                value !== null && value.toString().toLowerCase().includes(filterValue.toLowerCase())
              );
            }).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />

          {queryResults.duration && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              <Clock size={14} style={{ verticalAlign: 'middle', marginRight: 4 }} />
              Durée: {queryResults.duration} ms
            </Typography>
          )}
        </Box>
      );
    }

    return null;
  };

  // Formater les valeurs de cellule selon leur type
  const formatCellValue = (value) => {
    if (value === null) {
      return <Typography variant="body2" color="text.disabled" sx={{ fontStyle: 'italic' }}>NULL</Typography>;
    }
    if (typeof value === 'object' && value instanceof Date) {
      return value.toLocaleString();
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    
    // Pour gérer les CLOB/BLOB
    if (typeof value === 'string' && value.length > 100) {
      return (
        <Tooltip title={value}>
          <Typography variant="body2" noWrap sx={{ maxWidth: 300 }}>
            {value.substring(0, 100)}...
          </Typography>
        </Tooltip>
      );
    }
    
    return value.toString();
  };

  // Exporter les résultats en CSV
  const exportResults = () => {
    if (!queryResults || !queryResults.columns || !queryResults.rows) return;
    
    // Créer l'en-tête
    const header = queryResults.columns.map(col => col.name).join(',');
    
    // Créer les lignes
    const rows = queryResults.rows.map(row => 
      queryResults.columns.map(col => {
        const val = row[col.name];
        if (val === null) return '';
        if (typeof val === 'string') return `"${val.replace(/"/g, '""')}"`;
        return val;
      }).join(',')
    );
    
    // Combiner l'en-tête et les lignes
    const csv = [header, ...rows].join('\n');
    
    // Créer un Blob et déclencher le téléchargement
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `oracle_results_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Copier les résultats dans le presse-papiers
  const copyToClipboard = () => {
    if (!queryResults || !queryResults.columns || !queryResults.rows) return;
    
    // Créer l'en-tête
    const header = queryResults.columns.map(col => col.name).join('\t');
    
    // Créer les lignes
    const rows = queryResults.rows.map(row => 
      queryResults.columns.map(col => {
        const val = row[col.name];
        return val === null ? 'NULL' : val.toString();
      }).join('\t')
    );
    
    // Combiner l'en-tête et les lignes
    const text = [header, ...rows].join('\n');
    
    // Copier dans le presse-papiers
    navigator.clipboard.writeText(text)
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Résultats copiés dans le presse-papiers',
          severity: 'success'
        });
      })
      .catch(err => {
        setSnackbar({
          open: true,
          message: `Échec de la copie: ${err.message}`,
          severity: 'error'
        });
      });
  };

  // Composant pour l'historique des requêtes
  const QueryHistory = () => {
    if (queryHistory.length === 0) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Clock size={48} color="#9e9e9e" />
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            L'historique des requêtes s'affichera ici.
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <Clock size={16} style={{ marginRight: 8 }} />
          Historique des requêtes
        </Typography>
        <List>
          {queryHistory.map((item, index) => (
            <Paper 
              key={index} 
              sx={{ 
                mb: 2, 
                p: 2,
                borderLeft: '4px solid',
                borderColor: item.status === 'error' ? 'error.main' : 
                             item.status === 'success' ? 'success.main' : 
                             item.status === 'pending' ? 'warning.main' : 'primary.main'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                <Typography variant="subtitle2" component="div">
                  {item.status === 'error' && <XCircle size={16} color="error" style={{ verticalAlign: 'middle', marginRight: 4 }} />}
                  {item.status === 'success' && <CheckCircle size={16} color="success" style={{ verticalAlign: 'middle', marginRight: 4 }} />}
                  {item.status === 'pending' && <AlertTriangle size={16} color="warning" style={{ verticalAlign: 'middle', marginRight: 4 }} />}
                  {item.timestamp.toLocaleTimeString()}
                </Typography>
                <IconButton 
                  size="small" 
                  onClick={() => {
                    setSqlQuery(item.query);
                    sqlEditorRef.current?.focus();
                  }}
                  title="Utiliser cette requête"
                >
                  <Edit size={16} />
                </IconButton>
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontFamily: 'monospace',
                  whiteSpace: 'pre-wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  maxHeight: '4.5em',
                  bgcolor: 'background.paper',
                  p: 1,
                  borderRadius: 1
                }}
              >
                {item.query}
              </Typography>
              {item.affectedRows !== undefined && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {item.affectedRows} ligne{item.affectedRows !== 1 ? 's' : ''} affectée{item.affectedRows !== 1 ? 's' : ''}
                </Typography>
              )}
              {item.error && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {item.error.length > 100 ? `${item.error.substring(0, 100)}...` : item.error}
                </Typography>
              )}
              {item.duration && (
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                  Durée: {item.duration} ms
                </Typography>
              )}
            </Paper>
          ))}
        </List>
      </Box>
    );
  };

  // Composant pour la structure de la base de données
  const DatabaseExplorer = () => {
    const { schemas, selectedSchema, selectedObject, selectedObjectType } = databaseObjects;
    
    const handleToggleSchema = (schemaName) => {
      setExpandedSchemas(prev => ({
        ...prev,
        [schemaName]: !prev[schemaName]
      }));
    };
    
    const handleToggleObjectType = (schemaName, objectType) => {
      const key = `${schemaName}-${objectType}`;
      setExpandedObjectTypes(prev => ({
        ...prev,
        [key]: !prev[key]
      }));
    };
    
    // Fonction pour obtenir l'icône selon le type d'objet
    const getObjectIcon = (objectType) => {
      switch (objectType) {
        case 'TABLE': return <TableIcon size={16} />;
        case 'VIEW': return <Layers size={16} />;
        case 'PROCEDURE': return <Code size={16} />;
        case 'FUNCTION': return <Code size={16} />;
        case 'PACKAGE': return <Package size={16} />;
        case 'SEQUENCE': return <Hash size={16} />;
        case 'INDEX': return <Locate size={16} />;
        case 'TRIGGER': return <Zap size={16} />;
        case 'SYNONYM': return <ArrowRight size={16} />;
        case 'TYPE': return <ScrollText size={16} />;
        default: return <Info size={16} />;
      }
    };

    if (!schemas || schemas.length === 0) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Database size={48} color="#9e9e9e" />
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            Aucun schéma disponible.
          </Typography>
          <Button
            variant="outlined"
            startIcon={<RefreshCw />}
            onClick={handleRefreshStructure}
            disabled={disabled || !connectionStatus}
            sx={{ mt: 2 }}
          >
            Rafraîchir
          </Button>
        </Box>
      );
    }

    return (
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Database size={16} style={{ marginRight: 8 }} />
            Structure de la base de données
          </Typography>
          <IconButton 
            size="small"
            onClick={handleRefreshStructure}
            disabled={disabled || !connectionStatus}
            title="Rafraîchir la structure"
          >
            <RefreshCw size={16} />
          </IconButton>
        </Box>

        <TextField
          fullWidth
          size="small"
          placeholder="Rechercher des objets..."
          variant="outlined"
          onChange={(e) => setFilterValue(e.target.value)}
          InputProps={{
            startAdornment: <Search size={16} style={{ marginRight: 8 }} />
          }}
          sx={{ mb: 2 }}
        />

        <Box sx={{ maxHeight: 500, overflow: 'auto' }}>
          {schemas
            .filter(schema => 
              !filterValue || 
              schema.name.toLowerCase().includes(filterValue.toLowerCase()) ||
              Object.keys(schema.objects || {}).some(objType => 
                schema.objects[objType].some(obj => 
                  obj.toLowerCase().includes(filterValue.toLowerCase())
                )
              )
            )
            .map((schema) => (
              <Accordion
                key={schema.name}
                expanded={expandedSchemas[schema.name] || false}
                onChange={() => handleToggleSchema(schema.name)}
                sx={{ 
                  mb: 1,
                  '&:before': {
                    display: 'none',
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ChevronDown size={16} />}
                  sx={{ 
                    bgcolor: 'background.paper',
                    '&.Mui-expanded': {
                      minHeight: 48,
                    }
                  }}
                >
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <User size={16} style={{ marginRight: 8 }} />
                    {schema.name}
                    {schema.objects && (
                      <Chip
                        size="small"
                        label={Object.values(schema.objects).reduce((sum, arr) => sum + arr.length, 0)}
                        sx={{ ml: 1, height: 20 }}
                      />
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  {schema.objects && Object.keys(schema.objects).map((objectType) => {
                    // Filtrer les objets par la recherche si nécessaire
                    const filteredObjects = filterValue 
                      ? schema.objects[objectType].filter(obj => 
                          obj.toLowerCase().includes(filterValue.toLowerCase())
                        )
                      : schema.objects[objectType];
                      
                    if (filteredObjects.length === 0) return null;
                      
                    return (
                      <Accordion
                        key={`${schema.name}-${objectType}`}
                        expanded={expandedObjectTypes[`${schema.name}-${objectType}`] || false}
                        onChange={() => handleToggleObjectType(schema.name, objectType)}
                        sx={{ 
                          '&:before': {
                            display: 'none',
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ChevronDown size={16} />}
                          sx={{ 
                            pl: 4,
                            minHeight: 'auto',
                            '&.Mui-expanded': {
                              minHeight: 'auto',
                            }
                          }}
                        >
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            {getObjectIcon(objectType)}
                            <Box component="span" sx={{ ml: 1 }}>
                              {objectType}
                            </Box>
                            <Chip
                              size="small"
                              label={filteredObjects.length}
                              sx={{ ml: 1, height: 16, '& .MuiChip-label': { px: 1, fontSize: '0.625rem' } }}
                            />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <List dense disablePadding>
                            {filteredObjects.map((objectName) => (
                              <ListItem
                                key={`${schema.name}-${objectType}-${objectName}`}
                                sx={{
                                  pl: 6,
                                  cursor: disabled || !connectionStatus ? 'not-allowed' : 'pointer',
                                  '&:hover': {
                                    bgcolor: 'action.hover',
                                  },
                                  ...(selectedSchema === schema.name && 
                                    selectedObject === objectName && 
                                    selectedObjectType === objectType) && {
                                    bgcolor: 'primary.light',
                                    color: 'primary.contrastText',
                                  }
                                }}
                                onClick={() => handleExploreObject(schema.name, objectName, objectType)}
                                disabled={disabled || !connectionStatus}
                                secondaryAction={
                                  <IconButton 
                                    edge="end" 
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleGetObjectDetails(schema.name, objectName, objectType);
                                    }}
                                  >
                                    <Info size={16} />
                                  </IconButton>
                                }
                              >
                                <ListItemText 
                                  primary={objectName} 
                                  primaryTypographyProps={{ 
                                    variant: 'body2',
                                    sx: {
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block'
                                    }
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
      </Box>
    );
  };

  // Composant pour afficher les détails de l'objet
  const ObjectDetailsPanel = () => {
    const { selectedSchema, selectedObject, selectedObjectType } = databaseObjects;
    
    if (!selectedObject) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Info size={48} color="#9e9e9e" />
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            Sélectionnez un objet dans l'explorateur pour voir ses détails.
          </Typography>
        </Box>
      );
    }
    
    if (isExecuting) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <CircularProgress size={40} />
          <Typography variant="body1" sx={{ ml: 2 }}>
            Chargement des détails...
          </Typography>
        </Box>
      );
    }
    
    if (!objectDetails && !objectData) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <AlertTriangle size={48} color="#f57c00" />
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            Aucun détail disponible pour cet objet.
          </Typography>
          <Button
            variant="outlined"
            startIcon={<RefreshCw />}
            onClick={() => handleGetObjectDetails(selectedSchema, selectedObject, selectedObjectType)}
            disabled={disabled || !connectionStatus}
            sx={{ mt: 2 }}
          >
            Charger les détails
          </Button>
        </Box>
      );
    }
    
    const generateSQLForObject = () => {
      let sql = '';
      
      switch (selectedObjectType) {
        case 'TABLE':
          sql = `SELECT * FROM ${selectedSchema}.${selectedObject} WHERE ROWNUM <= 100`;
          break;
        case 'VIEW':
          sql = `SELECT * FROM ${selectedSchema}.${selectedObject} WHERE ROWNUM <= 100`;
          break;
        case 'SEQUENCE':
          sql = `SELECT ${selectedSchema}.${selectedObject}.NEXTVAL FROM DUAL`;
          break;
        case 'PROCEDURE':
          sql = `BEGIN\n  ${selectedSchema}.${selectedObject}();\nEND;`;
          break;
        default:
          sql = `SELECT * FROM ${selectedSchema}.${selectedObject} WHERE ROWNUM <= 100`;
      }
      
      setSqlQuery(sql);
      setActiveTab(0); // Basculer vers l'onglet d'éditeur SQL
    };
    
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          {getObjectIcon(selectedObjectType)}
          <Box component="span" sx={{ ml: 1 }}>
            {selectedSchema}.{selectedObject} ({selectedObjectType})
          </Box>
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            size="small"
            startIcon={<Code />}
            onClick={generateSQLForObject}
            sx={{ mr: 1 }}
          >
            Générer SQL
          </Button>
          <Button
            size="small"
            startIcon={<RefreshCw />}
            onClick={() => handleGetObjectDetails(selectedSchema, selectedObject, selectedObjectType)}
            disabled={disabled || !connectionStatus}
          >
            Rafraîchir
          </Button>
        </Box>
        
        {/* Affichage des détails selon le type d'objet */}
        {selectedObjectType === 'TABLE' && objectDetails && (
          <Paper variant="outlined" sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ p: 2, borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
              Structure de la Table
            </Typography>
            <TableContainer sx={{ maxHeight: 300 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom de Colonne</TableCell>
                    <TableCell>Type de Données</TableCell>
                    <TableCell>Nullable</TableCell>
                    <TableCell>Clé Primaire</TableCell>
                    <TableCell>Valeur par Défaut</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {objectDetails.rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.COLUMN_NAME}</TableCell>
                      <TableCell>{row.DATA_TYPE}</TableCell>
                      <TableCell>{row.NULLABLE === 'Y' ? 'Oui' : 'Non'}</TableCell>
                      <TableCell>
                        {row.IS_PRIMARY_KEY === 'YES' && (
                          <Chip size="small" label="PK" color="primary" />
                        )}
                      </TableCell>
                      <TableCell>{row.DATA_DEFAULT || ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        
        {/* Affichage du code source pour les objets comme PROCEDURE, FUNCTION, PACKAGE */}
        {['PROCEDURE', 'FUNCTION', 'PACKAGE', 'VIEW'].includes(selectedObjectType) && objectDetails && (
          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <Code size={16} style={{ marginRight: 8 }} />
              Code Source
            </Typography>
            <Box 
              sx={{ 
                fontFamily: 'monospace', 
                whiteSpace: 'pre-wrap', 
                bgcolor: 'grey.900', 
                color: 'common.white',
                p: 2,
                borderRadius: 1,
                overflow: 'auto',
                maxHeight: 400
              }}
            >
              {objectDetails.rows.map(row => row.TEXT).join('')}
            </Box>
          </Paper>
        )}
        
        {/* Affichage des données pour les tables et vues */}
        {['TABLE', 'VIEW'].includes(selectedObjectType) && objectData && (
          <Paper variant="outlined">
            <Typography variant="subtitle2" sx={{ p: 2, borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
              Aperçu des Données
            </Typography>
            <TableContainer sx={{ maxHeight: 300 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {objectData.columns.map((column, index) => (
                      <TableCell key={index}>{column.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {objectData.rows.length > 0 ? (
                    objectData.rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {objectData.columns.map((column, colIndex) => (
                          <TableCell key={colIndex}>{formatCellValue(row[column.name])}</TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={objectData.columns.length} align="center">
                        Aucune donnée
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        
        {/* Affichage des détails de séquence */}
        {selectedObjectType === 'SEQUENCE' && objectDetails && (
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <Hash size={16} style={{ marginRight: 8 }} />
              Propriétés de la Séquence
            </Typography>
            <Grid container spacing={2}>
              {objectDetails.rows[0] && Object.entries(objectDetails.rows[0]).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="caption" color="text.secondary">{key}</Typography>
                  <Typography variant="body2">{value !== null ? value.toString() : 'NULL'}</Typography>
                </Grid>
              ))}
            </Grid>
          </Paper>
        )}
      </Box>
    );
  };
  
  // Fonction pour obtenir l'icône selon le type d'objet
  const getObjectIcon = (objectType) => {
    switch (objectType) {
      case 'TABLE': return <TableIcon size={16} style={{ marginRight: 8 }} />;
      case 'VIEW': return <Layers size={16} style={{ marginRight: 8 }} />;
      case 'PROCEDURE': return <Code size={16} style={{ marginRight: 8 }} />;
      case 'FUNCTION': return <Code size={16} style={{ marginRight: 8 }} />;
      case 'PACKAGE': return <Package size={16} style={{ marginRight: 8 }} />;
      case 'SEQUENCE': return <Hash size={16} style={{ marginRight: 8 }} />;
      case 'INDEX': return <Locate size={16} style={{ marginRight: 8 }} />;
      case 'TRIGGER': return <Zap size={16} style={{ marginRight: 8 }} />;
      default: return <Info size={16} style={{ marginRight: 8 }} />;
    }
  };

  // Barre de transaction active
  const TransactionBar = () => {
    if (!transactionActive) return null;

    return (
      <Box sx={{ 
        p: 2, 
        bgcolor: 'warning.light', 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center' 
      }}>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          <AlertTriangle size={16} style={{ marginRight: 8 }} color="warning" />
          Transaction active - Les modifications ne sont pas encore enregistrées
        </Typography>
        <Box>
          <Button
            variant="outlined"
            color="success"
            size="small"
            startIcon={<RotateCw />}
            onClick={() => handleTransaction('commit')}
            disabled={disabled || !connectionStatus}
            sx={{ mr: 1 }}
          >
            Commit
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            startIcon={<RotateCcw />}
            onClick={() => handleTransaction('rollback')}
            disabled={disabled || !connectionStatus}
          >
            Rollback
          </Button>
        </Box>
      </Box>
    );
  };

  // Bannière d'état de connexion
  const ConnectionStatusBanner = () => {
    if (connectionStatus) return null;
    
    return (
      <Box sx={{
        p: 2,
        bgcolor: 'error.light',
        color: 'error.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <WifiOff size={20} />
        <Typography variant="subtitle1">
          Connexion non établie. Les interactions sont désactivées.
        </Typography>
      </Box>
    );
  };

  // Rendu principal
  return (
    <Paper 
      sx={{
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: highlighted ? '2px solid #2196f3' : (connectionStatus ? 'none' : '2px solid #f44336'),
        transition: 'border-color 0.3s ease',
        ...(disabled && {
          opacity: 0.7,
          pointerEvents: 'none'
        })
      }}
    >
      <Header />
      <Divider />
      
      <ConnectionStatusBanner />
      {transactionActive && <TransactionBar />}

      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Panneau latéral - Structure de la base de données */}
        <Box sx={{ width: 320, borderRight: 1, borderColor: 'divider', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <DatabaseExplorer />
        </Box>

        {/* Panneau principal */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Tabs 
            value={activeTab} 
            onChange={(e, newValue) => setActiveTab(newValue)} 
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="Éditeur SQL" icon={<Code size={16} />} iconPosition="start" />
            <Tab label="Résultats" icon={<TableIcon size={16} />} iconPosition="start" />
            <Tab label="Historique" icon={<Clock size={16} />} iconPosition="start" />
            <Tab label="Détails Objet" icon={<Info size={16} />} iconPosition="start" />
          </Tabs>

          <Box sx={{ flex: 1, overflow: 'auto' }}>
            {activeTab === 0 && <SqlEditor />}
            {activeTab === 1 && <ResultsPanel />}
            {activeTab === 2 && <QueryHistory />}
            {activeTab === 3 && <ObjectDetailsPanel />}
          </Box>
        </Box>
      </Box>

      {lastAction && (
        <Box sx={{
          p: 2,
          bgcolor: 'grey.50',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Clock size={16} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Dernière action: {lastAction.type} - {lastAction.name}
              {' à '}{lastAction.timestamp.toLocaleString()}
            </Typography>
          </Stack>
        </Box>
      )}

      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default OracleCapture;