import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Divider,
  Chip,
  Tooltip,
  CircularProgress,
  Card,
  CardContent,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  Stack,
  Drawer,
  FormControlLabel,
  Switch,
  Avatar,
  Badge
} from '@mui/material';

import {
  Plus,
  Edit2,
  Trash2,
  Search,
  RefreshCw,
  CheckCircle,
  XCircle,
  AlertCircle,
  AlertTriangle,
  AlertOctagon,
  Code,
  Tag,
  Link as LinkIcon,
  FileText,
  Clock,
  Calendar,
  User,
  X,
  Filter,
  BarChart2,
  List as ListIcon,
  Grid as GridIcon,
  ExternalLink,
  ArrowUpRight,
  UserCheck,
  Info,
  MessageCircle,
  ShieldAlert,
  PenTool,
  CheckSquare,
  Slash,
  HelpCircle
} from 'lucide-react';

import api from '../../services/api.service';

/**
 * Page de gestion des anomalies d'automatisation
 */
const AnomaliesPage = () => {
  // États des anomalies
  const [anomalies, setAnomalies] = useState([]);
  const [filteredAnomalies, setFilteredAnomalies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAnomaly, setSelectedAnomaly] = useState(null);
  const [functions, setFunctions] = useState([]);
  const [anomalyTypes, setAnomalyTypes] = useState([]);
  
  // États pour les filtres
  const [selectedFunction, setSelectedFunction] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [severityFilter, setSeverityFilter] = useState('all');
  const [impactFilter, setImpactFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  
  // État pour la pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  // État pour le tri
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('detectedDate');
  
  // États pour les dialogues
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  
  // État pour le formulaire
  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    type: '',
    severity: '',
    impact: '',
    status: 'NEW',
    rootCause: '',
    resolution: '',
    function: { id: '' }
  });
  
  // État pour l'affichage
  const [viewMode, setViewMode] = useState('table');
  const [activeTab, setActiveTab] = useState(0);
  
  // État pour l'assignation
  const [assignToUser, setAssignToUser] = useState('');
  const [users, setUsers] = useState([]);
  
  // État pour le changement de statut
  const [newStatus, setNewStatus] = useState('');
  
  // État pour les messages
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // Définition des statuts d'anomalie possibles
  const anomalyStatuses = [
    { value: 'NEW', label: 'Nouvelle', color: 'info', icon: <AlertCircle size={16} /> },
    { value: 'ASSIGNED', label: 'Assignée', color: 'secondary', icon: <UserCheck size={16} /> },
    { value: 'IN_PROGRESS', label: 'En cours', color: 'warning', icon: <PenTool size={16} /> },
    { value: 'RESOLVED', label: 'Résolue', color: 'success', icon: <CheckSquare size={16} /> },
    { value: 'CLOSED', label: 'Fermée', color: 'default', icon: <CheckCircle size={16} /> },
    { value: 'REJECTED', label: 'Rejetée', color: 'error', icon: <Slash size={16} /> }
  ];
  
  // Définition des sévérités d'anomalie possibles
  const anomalySeverities = [
    { value: 'CRITICAL', label: 'Critique', color: 'error', icon: <AlertOctagon size={16} /> },
    { value: 'HIGH', label: 'Élevée', color: 'error', icon: <AlertTriangle size={16} /> },
    { value: 'MEDIUM', label: 'Moyenne', color: 'warning', icon: <AlertCircle size={16} /> },
    { value: 'LOW', label: 'Faible', color: 'info', icon: <Info size={16} /> }
  ];
  
  // Définition des impacts d'anomalie possibles
  const anomalyImpacts = [
    { value: 'BLOCKING', label: 'Bloquant', color: 'error' },
    { value: 'MAJOR', label: 'Majeur', color: 'warning' },
    { value: 'MINOR', label: 'Mineur', color: 'info' },
    { value: 'COSMETIC', label: 'Cosmétique', color: 'default' }
  ];
  
  // Définition des types d'anomalie possibles
  const anomalyTypesList = [
    { value: 'FUNCTIONAL', label: 'Fonctionnelle', icon: <Code size={16} /> },
    { value: 'TECHNICAL', label: 'Technique', icon: <ShieldAlert size={16} /> },
    { value: 'PERFORMANCE', label: 'Performance', icon: <BarChart2 size={16} /> },
    { value: 'SECURITY', label: 'Sécurité', icon: <ShieldAlert size={16} /> },
    { value: 'USER_INTERFACE', label: 'Interface utilisateur', icon: <FileText size={16} /> }
  ];
  
  // Chargement initial des données
  useEffect(() => {
    fetchFunctions();
    fetchAnomalies();
    fetchUsers();
  }, []);
  
  // Application des filtres quand les anomalies ou les filtres changent
  useEffect(() => {
    applyFilters();
  }, [anomalies, selectedFunction, searchQuery, statusFilter, severityFilter, impactFilter, typeFilter]);
  
  // Fonction pour charger les fonctions
  const fetchFunctions = async () => {
    try {
      const response = await api.automata.functions.getAll();
      setFunctions(response);
    } catch (error) {
      console.error('Erreur lors du chargement des fonctions:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des fonctions',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour charger les utilisateurs
  const fetchUsers = async () => {
    try {
      // Cette fonction est simulée car nous n'avons pas l'API réelle pour les utilisateurs
      // Dans une application réelle, vous devriez appeler l'API appropriée
      const mockUsers = [
        { id: '1', name: 'John Doe', email: 'john.doe@example.com' },
        { id: '2', name: 'Jane Smith', email: 'jane.smith@example.com' },
        { id: '3', name: 'Robert Johnson', email: 'robert.johnson@example.com' },
        { id: '4', name: 'Sarah Williams', email: 'sarah.williams@example.com' }
      ];
      setUsers(mockUsers);
    } catch (error) {
      console.error('Erreur lors du chargement des utilisateurs:', error);
      setUsers([]);
    }
  };
  
  // Fonction pour charger toutes les anomalies
  const fetchAnomalies = async () => {
    setLoading(true);
    try {
      const response = await api.automata.anomalies.getAll();
      setAnomalies(response);
      
      // Extraire les types uniques des anomalies
      const uniqueTypes = [...new Set(response.map(anomaly => anomaly.type).filter(Boolean))];
      setAnomalyTypes(uniqueTypes);
    } catch (error) {
      console.error('Erreur lors du chargement des anomalies:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du chargement des anomalies',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour charger les anomalies par fonction
  const fetchAnomaliesByFunction = async (functionId) => {
    setLoading(true);
    try {
      const response = await api.automata.anomalies.getAll(functionId);
      setAnomalies(response);
    } catch (error) {
      console.error(`Erreur lors du chargement des anomalies pour la fonction ${functionId}:`, error);
      setSnackbar({
        open: true,
        message: `Erreur lors du chargement des anomalies pour la fonction sélectionnée`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour appliquer les filtres
  const applyFilters = () => {
    let filteredData = [...anomalies];
    
    // Filtre par fonction
    if (selectedFunction !== 'all') {
      filteredData = filteredData.filter(
        anomaly => anomaly.function && anomaly.function.id === selectedFunction
      );
    }
    
    // Filtre par statut
    if (statusFilter !== 'all') {
      filteredData = filteredData.filter(anomaly => anomaly.status === statusFilter);
    }
    
    // Filtre par sévérité
    if (severityFilter !== 'all') {
      filteredData = filteredData.filter(anomaly => anomaly.severity === severityFilter);
    }
    
    // Filtre par impact
    if (impactFilter !== 'all') {
      filteredData = filteredData.filter(anomaly => anomaly.impact === impactFilter);
    }
    
    // Filtre par type
    if (typeFilter !== 'all') {
      filteredData = filteredData.filter(anomaly => anomaly.type === typeFilter);
    }
    
    // Recherche textuelle
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter(
        anomaly =>
          (anomaly.title && anomaly.title.toLowerCase().includes(lowerQuery)) ||
          (anomaly.description && anomaly.description.toLowerCase().includes(lowerQuery)) ||
          (anomaly.rootCause && anomaly.rootCause.toLowerCase().includes(lowerQuery))
      );
    }
    
    // Appliquer le tri
    filteredData = stableSort(filteredData, getComparator(order, orderBy));
    
    setFilteredAnomalies(filteredData);
  };
  
  // Fonctions de tri
  const descendingComparator = (a, b, orderBy) => {
    if (!a[orderBy] && !b[orderBy]) return 0;
    if (!a[orderBy]) return 1;
    if (!b[orderBy]) return -1;
    
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  // Fonctions pour les dialogues
  const handleOpenCreateDialog = () => {
    setFormValues({
      title: '',
      description: '',
      type: '',
      severity: 'MEDIUM',
      impact: 'MINOR',
      status: 'NEW',
      rootCause: '',
      resolution: '',
      function: { id: '' }
    });
    setOpenCreateDialog(true);
  };
  
  const handleOpenEditDialog = (anomaly) => {
    setSelectedAnomaly(anomaly);
    setFormValues({
      title: anomaly.title || '',
      description: anomaly.description || '',
      type: anomaly.type || '',
      severity: anomaly.severity || 'MEDIUM',
      impact: anomaly.impact || 'MINOR',
      status: anomaly.status || 'NEW',
      rootCause: anomaly.rootCause || '',
      resolution: anomaly.resolution || '',
      function: { id: anomaly.function?.id || '' }
    });
    setOpenEditDialog(true);
  };
  
  const handleOpenDeleteDialog = (anomaly) => {
    setSelectedAnomaly(anomaly);
    setOpenDeleteDialog(true);
  };
  
  const handleOpenDetailsDrawer = (anomaly) => {
    setSelectedAnomaly(anomaly);
    setOpenDetailsDrawer(true);
  };
  
  const handleOpenAssignDialog = (anomaly) => {
    setSelectedAnomaly(anomaly);
    setAssignToUser('');
    setOpenAssignDialog(true);
  };
  
  const handleOpenStatusDialog = (anomaly) => {
    setSelectedAnomaly(anomaly);
    setNewStatus(anomaly.status);
    setOpenStatusDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenDetailsDrawer(false);
    setOpenAssignDialog(false);
    setOpenStatusDialog(false);
    setSelectedAnomaly(null);
  };
  
  // Fonctions pour les formulaires
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'functionId') {
      setFormValues({ ...formValues, function: { id: value } });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  
  // Fonction pour créer une anomalie
  const handleCreateAnomaly = async () => {
    try {
      await api.automata.anomalies.create(formValues);
      setSnackbar({
        open: true,
        message: 'Anomalie créée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAnomalies();
    } catch (error) {
      console.error('Erreur lors de la création de l\'anomalie:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la création de l\'anomalie',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour mettre à jour une anomalie
  const handleUpdateAnomaly = async () => {
    try {
      await api.automata.anomalies.update(selectedAnomaly.id, formValues);
      setSnackbar({
        open: true,
        message: 'Anomalie mise à jour avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAnomalies();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'anomalie:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la mise à jour de l\'anomalie',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour supprimer une anomalie
  const handleDeleteAnomaly = async () => {
    try {
      await api.automata.anomalies.delete(selectedAnomaly.id);
      setSnackbar({
        open: true,
        message: 'Anomalie supprimée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAnomalies();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'anomalie:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la suppression de l\'anomalie',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour assigner une anomalie
  const handleAssignAnomaly = async () => {
    if (!assignToUser) {
      setSnackbar({
        open: true,
        message: 'Veuillez sélectionner un utilisateur',
        severity: 'warning'
      });
      return;
    }
    
    try {
      await api.automata.anomalies.assignAnomaly(selectedAnomaly.id, assignToUser);
      setSnackbar({
        open: true,
        message: 'Anomalie assignée avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAnomalies();
    } catch (error) {
      console.error('Erreur lors de l\'assignation de l\'anomalie:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de l\'assignation de l\'anomalie',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour changer le statut d'une anomalie
  const handleUpdateStatus = async () => {
    try {
      await api.automata.anomalies.updateAnomalyStatus(selectedAnomaly.id, newStatus);
      setSnackbar({
        open: true,
        message: 'Statut de l\'anomalie mis à jour avec succès',
        severity: 'success'
      });
      handleCloseDialog();
      fetchAnomalies();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut de l\'anomalie:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la mise à jour du statut de l\'anomalie',
        severity: 'error'
      });
    }
  };
  
  // Fonction pour changer de fonction
  const handleFunctionChange = (e) => {
    const value = e.target.value;
    setSelectedFunction(value);
    
    if (value === 'all') {
      fetchAnomalies();
    } else {
      fetchAnomaliesByFunction(value);
    }
  };
  
  // Fonction pour obtenir le chip de statut d'une anomalie
  const getStatusChip = (status) => {
    const statusConfig = anomalyStatuses.find(s => s.value === status) || anomalyStatuses[0];
    return (
      <Chip
        size="small"
        color={statusConfig.color}
        icon={statusConfig.icon}
        label={statusConfig.label}
      />
    );
  };
  
  // Fonction pour obtenir le chip de sévérité d'une anomalie
  const getSeverityChip = (severity) => {
    const severityConfig = anomalySeverities.find(s => s.value === severity) || anomalySeverities[2];
    return (
      <Chip
        size="small"
        color={severityConfig.color}
        icon={severityConfig.icon}
        label={severityConfig.label}
      />
    );
  };
  
  // Fonction pour obtenir le chip d'impact d'une anomalie
  const getImpactChip = (impact) => {
    const impactConfig = anomalyImpacts.find(i => i.value === impact) || anomalyImpacts[2];
    return (
      <Chip
        size="small"
        color={impactConfig.color}
        label={impactConfig.label}
        variant="outlined"
      />
    );
  };
  
  // Fonction pour obtenir le chip de type d'une anomalie
  const getTypeChip = (type) => {
    const typeConfig = anomalyTypesList.find(t => t.value === type) || { value: type, label: type, icon: <HelpCircle size={16} /> };
    return (
      <Chip
        size="small"
        icon={typeConfig.icon}
        label={typeConfig.label || type}
        variant="outlined"
      />
    );
  };
  
  // Fonction pour formater une date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Rendu du composant
  return (
    <Box sx={{ p: 0, height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      {/* Barre d'outils principale */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AlertCircle size={24} />
          Gestion des Anomalies
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={handleOpenCreateDialog}
          >
            Nouvelle Anomalie
          </Button>
          
          <IconButton 
            color="primary" 
            onClick={fetchAnomalies}
            title="Rafraîchir"
          >
            <RefreshCw />
          </IconButton>
          
          <IconButton
            color={viewMode === 'table' ? 'primary' : 'default'}
            onClick={() => setViewMode('table')}
            title="Vue tableau"
          >
            <ListIcon />
          </IconButton>
          
          <IconButton
            color={viewMode === 'grid' ? 'primary' : 'default'}
            onClick={() => setViewMode('grid')}
            title="Vue grille"
          >
            <GridIcon />
          </IconButton>
        </Box>
      </Paper>
      
      {/* Filtres et recherche */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant="outlined"
              label="Rechercher"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                )
              }}
              size="small"
            />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Fonction</InputLabel>
              <Select
                value={selectedFunction}
                onChange={handleFunctionChange}
                label="Fonction"
              >
                <MenuItem value="all">Toutes les fonctions</MenuItem>
                {functions.map((func) => (
                  <MenuItem key={func.id} value={func.id}>
                    {func.functionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Statut</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Statut"
              >
                <MenuItem value="all">Tous</MenuItem>
                {anomalyStatuses.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {status.icon}
                      {status.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Sévérité</InputLabel>
              <Select
                value={severityFilter}
                onChange={(e) => setSeverityFilter(e.target.value)}
                label="Sévérité"
              >
                <MenuItem value="all">Toutes</MenuItem>
                {anomalySeverities.map((severity) => (
                  <MenuItem key={severity.value} value={severity.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {severity.icon}
                      {severity.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                label="Type"
              >
                <MenuItem value="all">Tous</MenuItem>
                {anomalyTypesList.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {type.icon}
                      {type.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Vue tableau ou grille selon le mode */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Vue tableau */}
          {viewMode === 'table' && (
            <TableContainer component={Paper} sx={{ flex: 1, overflow: 'auto' }}>
              <Table stickyHeader aria-label="tableau des anomalies">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 80 }}>#</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'title'}
                        direction={orderBy === 'title' ? order : 'asc'}
                        onClick={() => handleRequestSort('title')}
                      >
                        Titre
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Sévérité</TableCell>
                    <TableCell>Impact</TableCell>
                    <TableCell>Fonction</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'detectedDate'}
                        direction={orderBy === 'detectedDate' ? order : 'desc'}
                        onClick={() => handleRequestSort('detectedDate')}
                      >
                        Date de détection
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Assigné à</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAnomalies.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <Typography variant="body1">Aucune anomalie trouvée</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredAnomalies.map((anomaly) => (
                      <TableRow 
                        key={anomaly.id}
                        hover
                        onClick={() => handleOpenDetailsDrawer(anomaly)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{anomaly.id}</TableCell>
                        <TableCell>
                          <Typography variant="body2" fontWeight="medium">
                            {anomaly.title}
                          </Typography>
                          {anomaly.description && (
                            <Typography 
                              variant="caption" 
                              color="text.secondary" 
                              sx={{ 
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                              }}
                            >
                              {anomaly.description}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {getTypeChip(anomaly.type)}
                        </TableCell>
                        <TableCell>
                          {getSeverityChip(anomaly.severity)}
                        </TableCell>
                        <TableCell>
                          {getImpactChip(anomaly.impact)}
                        </TableCell>
                        <TableCell>
                          {anomaly.function ? (
                            <Tooltip title={`Fonction: ${anomaly.function.functionName}`}>
                              <Chip 
                                size="small" 
                                label={anomaly.function.functionName}
                                color="info"
                                variant="outlined"
                              />
                            </Tooltip>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>
                          <Tooltip title={formatDate(anomaly.detectedDate)}>
                            <Typography variant="body2">
                              {anomaly.detectedDate 
                                ? new Date(anomaly.detectedDate).toLocaleDateString() 
                                : 'N/A'}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {anomaly.assignedTo ? (
                            <Chip
                              size="small"
                              icon={<User size={14} />}
                              label={anomaly.assignedTo}
                              variant="outlined"
                            />
                          ) : (
                            <Chip
                              size="small"
                              label="Non assignée"
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {getStatusChip(anomaly.status)}
                        </TableCell>
                        <TableCell align="center">
                          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenEditDialog(anomaly);
                              }}
                            >
                              <Edit2 size={18} />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDeleteDialog(anomaly);
                              }}
                            >
                              <Trash2 size={18} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          
          {/* Vue grille */}
          {viewMode === 'grid' && (
            <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
              <Grid container spacing={2}>
                {filteredAnomalies.length === 0 ? (
                  <Grid item xs={12}>
                    <Paper sx={{ p: 3, textAlign: 'center' }}>
                      <Typography variant="body1">Aucune anomalie trouvée</Typography>
                    </Paper>
                  </Grid>
                ) : (
                  filteredAnomalies.map((anomaly) => (
                    <Grid item xs={12} sm={6} md={4} key={anomaly.id}>
                      <Card 
                        sx={{ 
                          height: '100%', 
                          display: 'flex', 
                          flexDirection: 'column',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: 6
                          },
                          position: 'relative',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleOpenDetailsDrawer(anomaly)}
                      >
                        <Box 
                          sx={{ 
                            position: 'absolute', 
                            top: 12, 
                            right: 12, 
                            display: 'flex', 
                            gap: 1 
                          }}
                        >
                          {getSeverityChip(anomaly.severity)}
                        </Box>
                        
                        <CardContent sx={{ pt: 4, flexGrow: 1 }}>
                          <Typography variant="h6" component="div" gutterBottom>
                            {anomaly.title}
                          </Typography>
                          
                          <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap' }}>
                            {getStatusChip(anomaly.status)}
                            {getTypeChip(anomaly.type)}
                            {getImpactChip(anomaly.impact)}
                          </Box>
                          
                          {anomaly.description && (
                            <Typography 
                              variant="body2" 
                              color="text.secondary" 
                              sx={{ 
                                mb: 2,
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                              }}
                            >
                              {anomaly.description}
                            </Typography>
                          )}
                          
                          <Grid container spacing={1} sx={{ mt: 2 }}>
                            <Grid item xs={6}>
                              <Typography variant="caption" color="text.secondary">
                                Date de détection
                              </Typography>
                              <Typography variant="body2">
                                {anomaly.detectedDate 
                                  ? new Date(anomaly.detectedDate).toLocaleDateString() 
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            
                            <Grid item xs={6}>
                              <Typography variant="caption" color="text.secondary">
                                Fonction
                              </Typography>
                              <Typography variant="body2" noWrap>
                                {anomaly.function ? anomaly.function.functionName : '-'}
                              </Typography>
                            </Grid>
                            
                            <Grid item xs={12}>
                              <Typography variant="caption" color="text.secondary">
                                Assigné à
                              </Typography>
                              <Typography variant="body2">
                                {anomaly.assignedTo || 'Non assignée'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                        
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          px: 2,
                          py: 1,
                          borderTop: 1,
                          borderColor: 'divider'
                        }}>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenEditDialog(anomaly);
                              }}
                            >
                              <Edit2 size={18} />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDeleteDialog(anomaly);
                              }}
                            >
                              <Trash2 size={18} />
                            </IconButton>
                          </Box>
                          
                          <Button
                            variant="text"
                            size="small"
                            endIcon={<Info size={16} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDetailsDrawer(anomaly);
                            }}
                          >
                            Détails
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </Box>
          )}
        </>
      )}
      
      {/* Dialogue de création */}
      <Dialog 
        open={openCreateDialog} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Plus size={20} />
            Créer une nouvelle anomalie
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Tabs 
              value={activeTab} 
              onChange={(e, newValue) => setActiveTab(newValue)}
              sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Informations de base" />
              <Tab label="Description détaillée" />
            </Tabs>
            
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Titre de l'anomalie"
                    name="title"
                    value={formValues.title}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Fonction</InputLabel>
                    <Select
                      name="functionId"
                      value={formValues.function.id}
                      onChange={handleInputChange}
                      label="Fonction"
                    >
                      {functions.map((func) => (
                        <MenuItem key={func.id} value={func.id}>
                          {func.functionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Type d'anomalie</InputLabel>
                    <Select
                      name="type"
                      value={formValues.type}
                      onChange={handleInputChange}
                      label="Type d'anomalie"
                    >
                      {anomalyTypesList.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {type.icon}
                            {type.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Sévérité</InputLabel>
                    <Select
                      name="severity"
                      value={formValues.severity}
                      onChange={handleInputChange}
                      label="Sévérité"
                    >
                      {anomalySeverities.map((severity) => (
                        <MenuItem key={severity.value} value={severity.value}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {severity.icon}
                            {severity.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Impact</InputLabel>
                    <Select
                      name="impact"
                      value={formValues.impact}
                      onChange={handleInputChange}
                      label="Impact"
                    >
                      {anomalyImpacts.map((impact) => (
                        <MenuItem key={impact.value} value={impact.value}>
                          {impact.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={formValues.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            )}
            
            {activeTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Cause racine"
                    name="rootCause"
                    value={formValues.rootCause}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    placeholder="Décrivez la cause racine identifiée ou suspectée de l'anomalie"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Résolution"
                    name="resolution"
                    value={formValues.resolution}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    placeholder="Décrivez la résolution ou le contournement à appliquer"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Statut</InputLabel>
                    <Select
                      name="status"
                      value={formValues.status}
                      onChange={handleInputChange}
                      label="Statut"
                    >
                      {anomalyStatuses.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {status.icon}
                            {status.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateAnomaly}
            disabled={!formValues.title || !formValues.function.id || !formValues.type}
          >
            Créer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'édition */}
      <Dialog 
        open={openEditDialog} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Edit2 size={20} />
            Modifier l'anomalie
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Tabs 
              value={activeTab} 
              onChange={(e, newValue) => setActiveTab(newValue)}
              sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Informations de base" />
              <Tab label="Description détaillée" />
            </Tabs>
            
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Titre de l'anomalie"
                    name="title"
                    value={formValues.title}
                    onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Fonction</InputLabel>
                    <Select
                      name="functionId"
                      value={formValues.function.id}
                      onChange={handleInputChange}
                      label="Fonction"
                    >
                      {functions.map((func) => (
                        <MenuItem key={func.id} value={func.id}>
                          {func.functionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Type d'anomalie</InputLabel>
                    <Select
                      name="type"
                      value={formValues.type}
                      onChange={handleInputChange}
                      label="Type d'anomalie"
                    >
                      {anomalyTypesList.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {type.icon}
                            {type.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Sévérité</InputLabel>
                    <Select
                      name="severity"
                      value={formValues.severity}
                      onChange={handleInputChange}
                      label="Sévérité"
                    >
                      {anomalySeverities.map((severity) => (
                        <MenuItem key={severity.value} value={severity.value}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {severity.icon}
                            {severity.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Impact</InputLabel>
                    <Select
                      name="impact"
                      value={formValues.impact}
                      onChange={handleInputChange}
                      label="Impact"
                    >
                      {anomalyImpacts.map((impact) => (
                        <MenuItem key={impact.value} value={impact.value}>
                          {impact.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={formValues.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            )}
            
            {activeTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Cause racine"
                    name="rootCause"
                    value={formValues.rootCause}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    placeholder="Décrivez la cause racine identifiée ou suspectée de l'anomalie"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Résolution"
                    name="resolution"
                    value={formValues.resolution}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    placeholder="Décrivez la résolution ou le contournement à appliquer"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Statut</InputLabel>
                    <Select
                      name="status"
                      value={formValues.status}
                      onChange={handleInputChange}
                      label="Statut"
                    >
                      {anomalyStatuses.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {status.icon}
                            {status.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUpdateAnomaly}
            disabled={!formValues.title || !formValues.function.id || !formValues.type}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Êtes-vous sûr de vouloir supprimer l'anomalie "{selectedAnomaly?.title}" ?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Cette action est irréversible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button variant="contained" color="error" onClick={handleDeleteAnomaly}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue d'assignation */}
      <Dialog 
        open={openAssignDialog} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <UserCheck size={20} />
            Assigner l'anomalie
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Assignation de l'anomalie : {selectedAnomaly?.title}
            </Typography>
            
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Assigner à</InputLabel>
              <Select
                value={assignToUser}
                onChange={(e) => setAssignToUser(e.target.value)}
                label="Assigner à"
              >
                <MenuItem value="">
                  <em>Aucun</em>
                </MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar sx={{ width: 24, height: 24, fontSize: 12 }}>
                        {user.name.charAt(0)}
                      </Avatar>
                      {user.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleAssignAnomaly}
          >
            Assigner
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialogue de changement de statut */}
      <Dialog 
        open={openStatusDialog} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tag size={20} />
            Changer le statut
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Changement de statut pour : {selectedAnomaly?.title}
            </Typography>
            
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Nouveau statut</InputLabel>
              <Select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                label="Nouveau statut"
              >
                {anomalyStatuses.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {status.icon}
                      {status.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            {newStatus === 'RESOLVED' && (
              <Alert severity="info" sx={{ mt: 2 }}>
                Le changement de statut à "Résolue" définira automatiquement la date de résolution à aujourd'hui.
              </Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleUpdateStatus}
          >
            Mettre à jour
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Tiroir de détails */}
      <Drawer
        anchor="right"
        open={openDetailsDrawer}
        onClose={handleCloseDialog}
        sx={{
          width: 450,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 450,
          },
        }}
      >
        {selectedAnomaly && (
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5">Détails de l'anomalie</Typography>
              <IconButton onClick={handleCloseDialog}>
                <X size={20} />
              </IconButton>
            </Box>
            
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                {getSeverityChip(selectedAnomaly.severity)}
                {getStatusChip(selectedAnomaly.status)}
              </Box>
              
              <Typography variant="h6" gutterBottom>{selectedAnomaly.title}</Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                {selectedAnomaly.description || 'Aucune description disponible'}
              </Typography>
              
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="caption" color="text.secondary">ID</Typography>
                  <Typography variant="body2">{selectedAnomaly.id}</Typography>
                </Grid>
                
                {selectedAnomaly.anomalyId && (
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary">ID d'anomalie</Typography>
                    <Typography variant="body2">{selectedAnomaly.anomalyId}</Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Caractéristiques</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Type</Typography>
                <Box sx={{ mt: 0.5 }}>{getTypeChip(selectedAnomaly.type)}</Box>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Impact</Typography>
                <Box sx={{ mt: 0.5 }}>{getImpactChip(selectedAnomaly.impact)}</Box>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Date de détection</Typography>
                <Typography variant="body2">
                  {formatDate(selectedAnomaly.detectedDate)}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Date de résolution</Typography>
                <Typography variant="body2">
                  {formatDate(selectedAnomaly.resolutionDate)}
                </Typography>
              </Grid>
            </Grid>
            
            <Divider sx={{ my: 2 }} />
            
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>Cause racine</Typography>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="body2">
                  {selectedAnomaly.rootCause || 'Non spécifiée'}
                </Typography>
              </Paper>
            </Box>
            
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>Résolution</Typography>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="body2">
                  {selectedAnomaly.resolution || 'Non spécifiée'}
                </Typography>
              </Paper>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Fonction associée</Typography>
            
            {selectedAnomaly.function ? (
              <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1, border: 1, borderColor: 'divider' }}>
                <Typography variant="subtitle2">{selectedAnomaly.function.functionName}</Typography>
                {selectedAnomaly.function.functionDescription && (
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {selectedAnomaly.function.functionDescription}
                  </Typography>
                )}
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Aucune fonction associée
              </Typography>
            )}
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Personnes concernées</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Détectée par</Typography>
                <Typography variant="body2">
                  {selectedAnomaly.detectedBy || '-'}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Assignée à</Typography>
                <Typography variant="body2">
                  {selectedAnomaly.assignedTo || 'Non assignée'}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Résolue par</Typography>
                <Typography variant="body2">
                  {selectedAnomaly.resolvedBy || '-'}
                </Typography>
              </Grid>
            </Grid>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle1" gutterBottom>Métadonnées</Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Date de création</Typography>
                <Typography variant="body2">
                  {selectedAnomaly.createdDate 
                    ? new Date(selectedAnomaly.createdDate).toLocaleString()
                    : '-'}
                </Typography>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">Date de modification</Typography>
                <Typography variant="body2">
                  {selectedAnomaly.modifiedDate 
                    ? new Date(selectedAnomaly.modifiedDate).toLocaleString()
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
            
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                startIcon={<UserCheck />}
                onClick={() => {
                  handleCloseDialog();
                  handleOpenAssignDialog(selectedAnomaly);
                }}
              >
                Assigner
              </Button>
              
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<Tag />}
                  onClick={() => {
                    handleCloseDialog();
                    handleOpenStatusDialog(selectedAnomaly);
                  }}
                >
                  Changer le statut
                </Button>
                
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Edit2 />}
                  onClick={() => {
                    handleCloseDialog();
                    handleOpenEditDialog(selectedAnomaly);
                  }}
                >
                  Modifier
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Drawer>
      
      {/* Snackbar pour les messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AnomaliesPage;