import React, { useState, useEffect } from 'react';
import { 
  FileText, 
  Plus, 
  Edit, 
  Trash2, 
  RefreshCw, 
  Eye, 
  Code,
  File,
  Folder,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  List,
  ChevronDown,
  FileSymlink
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import ListView from '../../components/shared/ListView';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

/**
 * Document Templates Management
 * 
 * This component provides a complete interface for managing document templates
 * with features including:
 * - List view with filtering
 * - Template editor with content preview
 * - Creation/editing form
 * - Category selection
 * - Format selection
 * - Placeholder selection
 */
const DocumentTemplates = () => {
  // State for templates data
  const [templates, setTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [placeholders, setPlaceholders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // State for selected template and modal visibility
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [previewMode, setPreviewMode] = useState(false);
  
  // State for form validation
  const [validationErrors, setValidationErrors] = useState({});

  // State for placeholders selection
  const [selectedPlaceholders, setSelectedPlaceholders] = useState([]);

  // Available document formats
  const documentFormats = [
    { value: 'PDF', label: 'PDF' },
    { value: 'DOCX', label: 'Microsoft Word (.docx)' },
    { value: 'HTML', label: 'HTML' },
    { value: 'TXT', label: 'Texte brut (.txt)' },
    { value: 'RTF', label: 'Rich Text Format (.rtf)' }
  ];

  // Template types
  const templateTypes = [
    { value: 'LETTER', label: 'Lettre' },
    { value: 'CONTRACT', label: 'Contrat' },
    { value: 'INVOICE', label: 'Facture' },
    { value: 'REPORT', label: 'Rapport' },
    { value: 'FORM', label: 'Formulaire' },
    { value: 'CERTIFICATE', label: 'Certificat' },
    { value: 'CUSTOM', label: 'Personnalisé' }
  ];

  // Form state
  const [templateForm, setTemplateForm] = useState({
    code: '',
    name: '',
    description: '',
    content: '',
    type: 'LETTER',
    defaultFormat: 'PDF',
    supportedFormats: ['PDF'],
    placeholderCodes: [],
    categoryId: ''
  });

  // Reset form state
  const resetForm = () => {
    setTemplateForm({
      code: '',
      name: '',
      description: '',
      content: '',
      type: 'LETTER',
      defaultFormat: 'PDF',
      supportedFormats: ['PDF'],
      placeholderCodes: [],
      categoryId: categories.length > 0 ? categories[0].id : ''
    });
    setSelectedPlaceholders([]);
    setValidationErrors({});
  };

  // Load templates, categories, and placeholders on component mount
  useEffect(() => {
    fetchTemplates();
    fetchCategories();
    fetchPlaceholders();
  }, []);

  // Fetch templates from API
  const fetchTemplates = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await apiService.editic.templates.getAll();
      setTemplates(response);
    } catch (err) {
      console.error('Error fetching document templates:', err);
      setError('Impossible de charger les modèles de documents. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch categories from API
  const fetchCategories = async () => {
    try {
      const response = await apiService.editic.categories.getAll(true); // Get only active categories
      setCategories(response);
      
      // Set default category in form if not already set
      if (response.length > 0 && !templateForm.categoryId) {
        setTemplateForm(prev => ({
          ...prev,
          categoryId: response[0].id
        }));
      }
    } catch (err) {
      console.error('Error fetching document categories:', err);
    }
  };

  // Fetch placeholders from API
  const fetchPlaceholders = async () => {
    try {
      const response = await apiService.editic.placeholders.getAll();
      setPlaceholders(response);
    } catch (err) {
      console.error('Error fetching placeholders:', err);
    }
  };
	
  // Handle opening the create template modal
  const openCreateModal = () => {
    resetForm();
    setIsCreateMode(true);
    setShowModal(true);
    setPreviewMode(false);
  };

  // Handle opening the edit template modal
  const openEditModal = (template) => {
    const supportedFormats = template.supportedFormats || [template.defaultFormat];
    
    setTemplateForm({
      code: template.code,
      name: template.name,
      description: template.description || '',
      content: template.content || '',
      type: template.type,
      defaultFormat: template.defaultFormat,
      supportedFormats: supportedFormats,
      placeholderCodes: template.placeholders?.map(p => p.code) || [],
      categoryId: template.categoryId
    });
    
    // Set selected placeholders for the UI
    const selectedPlaceholders = (template.placeholders || []).map(p => ({
      id: p.id,
      code: p.code,
      name: p.name
    }));
    setSelectedPlaceholders(selectedPlaceholders);
    
    setSelectedTemplate(template);
    setIsCreateMode(false);
    setShowModal(true);
    setPreviewMode(false);
  };

  // Handle form change
  const handleFormChange = (field, value) => {
    // Clear validation error when field is updated
    if (validationErrors[field]) {
      setValidationErrors({
        ...validationErrors,
        [field]: null
      });
    }

    setTemplateForm(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Handle supported formats change
  const handleFormatChange = (format, checked) => {
    const currentFormats = [...templateForm.supportedFormats];
    
    if (checked) {
      // Add format if checked
      if (!currentFormats.includes(format)) {
        currentFormats.push(format);
      }
    } else {
      // Remove format if unchecked
      const index = currentFormats.indexOf(format);
      if (index > -1) {
        currentFormats.splice(index, 1);
      }
    }
    
    // If default format is removed, set a new default from remaining formats
    let defaultFormat = templateForm.defaultFormat;
    if (!currentFormats.includes(defaultFormat) && currentFormats.length > 0) {
      defaultFormat = currentFormats[0];
    }
    
    setTemplateForm(prev => ({
      ...prev,
      supportedFormats: currentFormats,
      defaultFormat: defaultFormat
    }));
  };

  // Handle placeholder selection
  const handlePlaceholderSelection = (placeholder, selected) => {
    let updatedPlaceholders = [...selectedPlaceholders];
    let updatedCodes = [...templateForm.placeholderCodes];
    
    if (selected) {
      // Add placeholder if selected
      if (!updatedCodes.includes(placeholder.code)) {
        updatedPlaceholders.push(placeholder);
        updatedCodes.push(placeholder.code);
      }
    } else {
      // Remove placeholder if unselected
      updatedPlaceholders = updatedPlaceholders.filter(p => p.code !== placeholder.code);
      updatedCodes = updatedCodes.filter(code => code !== placeholder.code);
    }
    
    setSelectedPlaceholders(updatedPlaceholders);
    setTemplateForm(prev => ({
      ...prev,
      placeholderCodes: updatedCodes
    }));
  };

  // Insert placeholder into content
  const insertPlaceholder = (placeholder) => {
    const textArea = document.getElementById('templateContent');
    if (!textArea) return;
    
    const cursorPos = textArea.selectionStart;
    const textBefore = templateForm.content.substring(0, cursorPos);
    const textAfter = templateForm.content.substring(textArea.selectionEnd);
    
    const placeholderTag = `{{${placeholder.code}}}`;
    const newContent = textBefore + placeholderTag + textAfter;
    
    setTemplateForm(prev => ({
      ...prev,
      content: newContent
    }));
    
    // Set cursor position after inserted placeholder
    setTimeout(() => {
      textArea.focus();
      textArea.setSelectionRange(cursorPos + placeholderTag.length, cursorPos + placeholderTag.length);
    }, 0);
  };

  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!templateForm.code.trim()) {
      errors.code = 'Le code est requis';
    } else if (!/^[A-Z0-9_]+$/.test(templateForm.code)) {
      errors.code = 'Le code doit contenir uniquement des lettres majuscules, des chiffres et des underscores';
    }
    
    if (!templateForm.name.trim()) {
      errors.name = 'Le nom est requis';
    }
    
    if (!templateForm.content.trim()) {
      errors.content = 'Le contenu du modèle est requis';
    }
    
    if (!templateForm.categoryId) {
      errors.categoryId = 'La catégorie est requise';
    }
    
    if (templateForm.supportedFormats.length === 0) {
      errors.supportedFormats = 'Au moins un format doit être sélectionné';
    }
    
    if (!templateForm.defaultFormat) {
      errors.defaultFormat = 'Le format par défaut est requis';
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit form (create or update)
  const submitForm = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      if (isCreateMode) {
        // Create template
        await apiService.editic.templates.create(templateForm);
        setSuccessMessage('Modèle créé avec succès!');
      } else {
        // Currently, there's no update endpoint in the API
        // This would be replaced with actual update API call when available
        setError('La mise à jour des modèles n\'est pas prise en charge actuellement.');
        setLoading(false);
        return;
      }
      
      // Refresh templates and close modal
      fetchTemplates();
      setShowModal(false);
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error saving template:', err);
      setError(`Impossible de ${isCreateMode ? 'créer' : 'mettre à jour'} le modèle.`);
    } finally {
      setLoading(false);
    }
  };

  // Delete a template
  const deleteTemplate = async (id) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce modèle?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.editic.templates.delete(id);
      setSuccessMessage('Modèle supprimé avec succès!');
      fetchTemplates();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error deleting template:', err);
      setError('Impossible de supprimer le modèle.');
    } finally {
      setLoading(false);
    }
  };

  // Toggle preview mode
  const togglePreviewMode = () => {
    setPreviewMode(!previewMode);
  };

  // Find category name by ID
  const getCategoryName = (categoryId) => {
    const category = categories.find(c => c.id === categoryId);
    return category ? category.name : 'Inconnu';
  };

  // Get placeholder label by code
  const getPlaceholderLabel = (code) => {
    const placeholder = placeholders.find(p => p.code === code);
    return placeholder ? placeholder.name : code;
  };

  // Prepare template content for preview (highlight placeholders)
  const getHighlightedContent = () => {
    if (!templateForm.content) return '';
    
    // Replace placeholders with highlighted spans
    let content = templateForm.content;
    const placeholderRegex = /\{\{([A-Z0-9_]+)\}\}/g;
    
    // Replace with HTML for highlighting
    content = content.replace(placeholderRegex, (match, code) => {
      const label = getPlaceholderLabel(code);
      return `<span class="bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-300 px-1 rounded">${match} <span class="text-xs italic">${label}</span></span>`;
    });
    
    return content;
  };

  // Column configuration for the ListView
  const columns = [
    {
      key: 'name',
      label: 'Nom',
      sortable: true,
      render: (row) => (
        <div>
          <div className="font-medium text-blue-600 dark:text-blue-400">{row.name}</div>
          {row.description && (
            <div className="text-sm text-gray-500 dark:text-gray-400 truncate">
              {row.description}
            </div>
          )}
        </div>
      )
    },
    {
      key: 'code',
      label: 'Code',
      sortable: true,
      render: (row) => (
        <span className="font-mono text-sm bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded">
          {row.code}
        </span>
      )
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      render: (row) => {
        const typeInfo = templateTypes.find(t => t.value === row.type) || 
                         { label: row.type };
        return (
          <span className="text-gray-700 dark:text-gray-300">
            {typeInfo.label}
          </span>
        );
      }
    },
    {
      key: 'category',
      label: 'Catégorie',
      sortable: false,
      render: (row) => (
        <span className="px-2 py-1 bg-gray-100 dark:bg-gray-800 rounded-full text-sm text-gray-700 dark:text-gray-300">
          {getCategoryName(row.categoryId)}
        </span>
      )
    },
    {
      key: 'format',
      label: 'Format',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <span className="font-medium">
            {row.defaultFormat}
          </span>
          {row.supportedFormats && row.supportedFormats.length > 1 && (
            <span className="ml-1 text-xs text-gray-500 dark:text-gray-400">
              (+{row.supportedFormats.length - 1})
            </span>
          )}
        </div>
      )
    },
    {
      key: 'active',
      label: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={row.active ? 'active' : 'inactive'} 
          customLabel={row.active ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      key: 'actions',
      label: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex items-center justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            onClick={() => openEditModal(row)}
            title="Voir"
          />
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => openEditModal(row)}
            title="Modifier"
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => deleteTemplate(row.id)}
            title="Supprimer"
          />
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <FileText className="h-6 w-6 mr-2 text-blue-500" />
            Modèles de Documents
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les modèles pour générer des documents personnalisés
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchTemplates}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={Plus}
            onClick={openCreateModal}
          >
            Nouveau Modèle
          </Button>
        </div>
      </div>

      {/* Success message */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Error message */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Templates list */}
      <Card>
        <ListView
          data={templates}
          columns={columns}
          loading={loading}
          emptyMessage="Aucun modèle trouvé"
        />
      </Card>

      {/* Modal for creating/editing templates */}
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-5xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <FileText className="h-5 w-5 mr-2 text-blue-500" />
                {isCreateMode ? 'Créer un nouveau modèle' : `Modifier: ${templateForm.name}`}
              </h3>
              <div className="flex space-x-2">
                <Button
                  variant={previewMode ? 'primary' : 'outline'}
                  size="sm"
                  icon={Eye}
                  onClick={togglePreviewMode}
                >
                  {previewMode ? 'Éditer' : 'Aperçu'}
                </Button>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <form onSubmit={submitForm}>
              <div className="max-h-[70vh] overflow-y-auto">
                <div className="px-6 py-4 space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className={`w-full px-3 py-2 border ${
                          validationErrors.code 
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                            : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                        } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                        value={templateForm.code}
                        onChange={(e) => handleFormChange('code', e.target.value)}
                        placeholder="CODE_TEMPLATE"
                        disabled={!isCreateMode} // Code cannot be changed when editing
                      />
                      {validationErrors.code && (
                        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                          {validationErrors.code}
                        </p>
                      )}
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Nom <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className={`w-full px-3 py-2 border ${
                          validationErrors.name 
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                            : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                        } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                        value={templateForm.name}
                        onChange={(e) => handleFormChange('name', e.target.value)}
                        placeholder="Nom du modèle"
                      />
                      {validationErrors.name && (
                        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                          {validationErrors.name}
                        </p>
                      )}
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={templateForm.description}
                      onChange={(e) => handleFormChange('description', e.target.value)}
                      placeholder="Description du modèle (optionnel)"
                      rows={2}
                    />
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Type <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={templateForm.type}
                        onChange={(e) => handleFormChange('type', e.target.value)}
                      >
                        {templateTypes.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Catégorie <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`w-full px-3 py-2 border ${
                          validationErrors.categoryId 
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                            : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                        } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                        value={templateForm.categoryId || ''}
                        onChange={(e) => handleFormChange('categoryId', e.target.value)}
                      >
                        <option value="" disabled>Sélectionnez une catégorie</option>
                        {categories.map(category => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      {validationErrors.categoryId && (
                        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                          {validationErrors.categoryId}
                        </p>
                      )}
                    </div>
                  </div>
                  
                  {/* Formats section */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Formats pris en charge <span className="text-red-500">*</span>
                    </label>
                    <div className="flex flex-wrap gap-2 bg-gray-50 dark:bg-gray-700 p-2 rounded-md border border-gray-200 dark:border-gray-600">
                      {documentFormats.map(format => (
                        <div key={format.value} className="flex items-center">
                          <input
                            type="checkbox"
                            id={`format-${format.value}`}
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 mr-2"
                            checked={templateForm.supportedFormats.includes(format.value)}
                            onChange={(e) => handleFormatChange(format.value, e.target.checked)}
                          />
                          <label htmlFor={`format-${format.value}`} className="text-sm text-gray-700 dark:text-gray-300">
                            {format.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {validationErrors.supportedFormats && (
                      <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                        {validationErrors.supportedFormats}
                      </p>
                    )}
                    
                    <div className="mt-3">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Format par défaut <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`w-full px-3 py-2 border ${
                          validationErrors.defaultFormat 
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                            : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                        } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2`}
                        value={templateForm.defaultFormat}
                        onChange={(e) => handleFormChange('defaultFormat', e.target.value)}
                      >
                        <option value="" disabled>Sélectionnez un format par défaut</option>
                        {templateForm.supportedFormats.map(format => (
                          <option key={format} value={format}>
                            {format}
                          </option>
                        ))}
                      </select>
                      {validationErrors.defaultFormat && (
                        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                          {validationErrors.defaultFormat}
                        </p>
                      )}
                    </div>
                  </div>
                  
                  {/* Placeholders section */}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="md:col-span-2">
                      {previewMode ? (
                        // Preview mode
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Aperçu du contenu
                          </label>
                          <div 
                            className="prose prose-sm dark:prose-invert max-w-none p-4 h-80 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700"
                            dangerouslySetInnerHTML={{ __html: getHighlightedContent() }}
                          ></div>
                        </div>
                      ) : (
                        // Edit mode
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Contenu du modèle <span className="text-red-500">*</span>
                          </label>
                          <textarea
                            id="templateContent"
                            className={`w-full px-3 py-2 border ${
                              validationErrors.content 
                                ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
                                : 'border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500'
                            } rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 font-mono`}
                            value={templateForm.content}
                            onChange={(e) => handleFormChange('content', e.target.value)}
                            placeholder="Contenu du modèle avec placeholders {{PLACEHOLDER_CODE}}"
                            rows={15}
                          />
                          {validationErrors.content && (
                            <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                              {validationErrors.content}
                            </p>
                          )}
                          <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                            Utilisez la syntaxe {'{{'}<span>CODE_PLACEHOLDER</span>{'}}'}  pour insérer des variables.
                          </p>
                        </div>
                      )}
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Placeholders disponibles
                      </label>
                      <div className="h-80 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 p-2">
                        {placeholders.length === 0 ? (
                          <p className="text-gray-500 dark:text-gray-400 text-sm p-2">
                            Aucun placeholder disponible
                          </p>
                        ) : (
                          <div className="space-y-2">
                            {placeholders.map(placeholder => {
                              const isSelected = selectedPlaceholders.some(p => p.code === placeholder.code);
                              return (
                                <div 
                                  key={placeholder.id}
                                  className={`flex items-center justify-between p-2 rounded text-sm ${
                                    isSelected 
                                      ? 'bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800' 
                                      : 'hover:bg-gray-50 dark:hover:bg-gray-800/50'
                                  }`}
                                >
                                  <div className="flex items-center">
                                    <input
                                      type="checkbox"
                                      id={`placeholder-${placeholder.id}`}
                                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 mr-2"
                                      checked={isSelected}
                                      onChange={(e) => handlePlaceholderSelection(placeholder, e.target.checked)}
                                    />
                                    <label htmlFor={`placeholder-${placeholder.id}`} className="cursor-pointer">
                                      <div className="font-medium">{placeholder.name}</div>
                                      <div className="text-xs text-gray-500 dark:text-gray-400">
                                        {placeholder.code}
                                      </div>
                                    </label>
                                  </div>
                                  {!previewMode && (
                                    <Button
                                      variant="text"
                                      size="sm"
                                      icon={FileSymlink}
                                      onClick={() => insertPlaceholder(placeholder)}
                                      title="Insérer dans le contenu"
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 flex justify-end space-x-2">
                <Button
                  variant="outline" onClick={() => setShowModal(false)}
                  type="button"
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  loading={loading}
                >
                  {isCreateMode ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentTemplates;