import React, { useState, useEffect, useRef } from 'react';
import { 
  Play, 
  Layers,
  RotateCcw, 
  RefreshCw, 
  Search, 
  Clock, 
  BarChart2, 
  AlertCircle, 
  CheckCircle, 
  XCircle,
  AlertTriangle,
  Trash2,
  FileText,
  Eye,
  Heart,
  ArrowRight,
  StopCircle
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const InterfaceExecutionPage = () => {
  // États
  const [interfaces, setInterfaces] = useState([]);
  const [executions, setExecutions] = useState([]);
  const [selectedInterface, setSelectedInterface] = useState(null);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [showExecutionModal, setShowExecutionModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showMetricsModal, setShowMetricsModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const [interfaceMetrics, setInterfaceMetrics] = useState(null);
  const [healthStatus, setHealthStatus] = useState({});
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    hasMore: true
  });
  const [executionContext, setExecutionContext] = useState({
    parameters: {},
    variables: {},
    headers: {},
    priority: 'NORMAL'
  });
  const [newParamKey, setNewParamKey] = useState('');
  const [newParamValue, setNewParamValue] = useState('');
  const [paramType, setParamType] = useState('parameters'); // peut être 'parameters', 'variables' ou 'headers'

  const pageEndRef = useRef(null);

  // Obtenir les statuts de tous les interfaces et les executions au chargement
  useEffect(() => {
    fetchAllInterfaceStatus();
    fetchHealthStatus();
  }, []);

  // Chargement des exécutions quand un interface est sélectionné
  useEffect(() => {
    if (selectedInterface) {
      fetchExecutions(selectedInterface.code, 0);
      fetchInterfaceMetrics(selectedInterface.code);
    }
  }, [selectedInterface]);

  // Observer pour la pagination infinie
  useEffect(() => {
    if (!pageEndRef.current || !selectedInterface || !pagination.hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          // Charger plus d'exécutions
          fetchExecutions(selectedInterface.code, pagination.page + 1, true);
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(pageEndRef.current);

    return () => {
      if (pageEndRef.current) {
        observer.unobserve(pageEndRef.current);
      }
    };
  }, [selectedInterface, pagination, loading]);

  // Fetch tous les status d'interfaces
  const fetchAllInterfaceStatus = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiService.integration.execution.getAllStatus();
      setInterfaces(response);
    } catch (err) {
      console.error("Erreur lors du chargement des statuts d'interfaces:", err);
      setError("Impossible de charger les interfaces. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch le statut de santé de toutes les interfaces
  const fetchHealthStatus = async () => {
    try {
      const response = await apiService.integration.execution.getHealth();
      setHealthStatus(response);
    } catch (err) {
      console.error("Erreur lors du chargement de l'état de santé:", err);
    }
  };

  // Fetch des exécutions pour une interface spécifique
  const fetchExecutions = async (code, page, append = false) => {
    if (!code) return;

    setLoading(true);
    setError(null);

    try {
      const response = await apiService.integration.execution.getExecutions(code, page, pagination.size);
      
      if (append) {
        setExecutions(prev => [...prev, ...response]);
      } else {
        setExecutions(response);
      }

      setPagination({
        page,
        size: pagination.size,
        hasMore: response.length === pagination.size
      });
    } catch (err) {
      console.error("Erreur lors du chargement des exécutions:", err);
      setError("Impossible de charger les exécutions. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch des métriques pour une interface
  const fetchInterfaceMetrics = async (code) => {
    if (!code) return;

    setMetricsLoading(true);

    try {
      const response = await apiService.integration.execution.getMetrics(code);
      setInterfaceMetrics(response);
    } catch (err) {
      console.error("Erreur lors du chargement des métriques:", err);
    } finally {
      setMetricsLoading(false);
    }
  };

  // Fetch des détails d'une exécution
  const fetchExecutionDetails = async (executionId) => {
    setDetailsLoading(true);
    setError(null);

    try {
      const response = await apiService.integration.execution.getExecution(executionId);
      setSelectedExecution(response);
      setShowDetailsModal(true);
    } catch (err) {
      console.error("Erreur lors du chargement des détails de l'exécution:", err);
      setError("Impossible de charger les détails de l'exécution. Veuillez réessayer.");
    } finally {
      setDetailsLoading(false);
    }
  };

  // Exécuter une interface
  const executeInterface = async () => {
    if (!selectedInterface) return;

    setLoading(true);
    setError(null);

    try {
      await apiService.integration.execution.execute(selectedInterface.code, executionContext);
      setSuccessMessage(`L'interface ${selectedInterface.code} a été exécutée avec succès`);
      setShowExecutionModal(false);
      
      // Réinitialiser le contexte d'exécution
      setExecutionContext({
        parameters: {},
        variables: {},
        headers: {},
        priority: 'NORMAL'
      });
      
      // Rafraîchir les exécutions
      await fetchExecutions(selectedInterface.code, 0);
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'exécution de l'interface:", err);
      setError("Impossible d'exécuter l'interface. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Réessayer une exécution
  const retryExecution = async (executionId) => {
    setLoading(true);
    setError(null);

    try {
      await apiService.integration.execution.retry(executionId);
      setSuccessMessage("Exécution relancée avec succès");
      
      // Mettre à jour l'exécution dans la liste
      if (selectedInterface) {
        await fetchExecutions(selectedInterface.code, 0);
      } else {
        await fetchAllInterfaceStatus();
      }
      
      // Fermer les modals si elles sont ouvertes
      setShowDetailsModal(false);
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la relance de l'exécution:", err);
      setError("Impossible de relancer l'exécution. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Annuler une exécution
  const cancelExecution = async () => {
    if (!selectedExecution) return;

    setLoading(true);
    setError(null);

    try {
      await apiService.integration.execution.cancel(selectedExecution.executionId);
      setSuccessMessage("Exécution annulée avec succès");
      setShowCancelModal(false);
      
      // Mettre à jour l'exécution dans la liste
      if (selectedInterface) {
        await fetchExecutions(selectedInterface.code, 0);
      } else {
        await fetchAllInterfaceStatus();
      }
      
      // Fermer les modals si elles sont ouvertes
      setShowDetailsModal(false);
      
      // Effacer le message après quelques secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'annulation de l'exécution:", err);
      setError("Impossible d'annuler l'exécution. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Gérer l'ajout d'un paramètre au contexte d'exécution
  const handleAddParam = () => {
    if (newParamKey.trim() === '') return;

    const updatedContext = { ...executionContext };
    updatedContext[paramType] = {
      ...updatedContext[paramType],
      [newParamKey]: newParamValue
    };

    setExecutionContext(updatedContext);
    setNewParamKey('');
    setNewParamValue('');
  };

  // Gérer la suppression d'un paramètre du contexte d'exécution
  const handleRemoveParam = (type, key) => {
    const updatedContext = { ...executionContext };
    const updatedParams = { ...updatedContext[type] };
    delete updatedParams[key];
    updatedContext[type] = updatedParams;
    setExecutionContext(updatedContext);
  };

  // Formatage du statut d'exécution
  const getExecutionStatusInfo = (status) => {
    const statusMap = {
      'PENDING': { status: 'warning', label: 'En attente' },
      'RUNNING': { status: 'warning', label: 'En cours' },
      'COMPLETED': { status: 'active', label: 'Terminé' },
      'FAILED': { status: 'inactive', label: 'Échoué' },
      'TIMEOUT': { status: 'inactive', label: 'Timeout' },
      'CANCELLED': { status: 'paused', label: 'Annulé' },
      'PARTIAL_SUCCESS': { status: 'warning', label: 'Succès partiel' }
    };
    
    return statusMap[status] || { status: 'warning', label: status };
  };

  // Formatage de l'état de santé
  const getHealthStatusInfo = (status) => {
    const statusMap = {
      'UP': { status: 'active', label: 'Actif' },
      'DOWN': { status: 'inactive', label: 'Inactif' },
      'STALLED': { status: 'warning', label: 'Bloqué' },
      'UNKNOWN': { status: 'warning', label: 'Inconnu' }
    };
    
    return statusMap[status] || { status: 'warning', label: status };
  };

  // Colonnes pour la liste des interfaces
  const interfaceColumns = [
    {
      header: 'Interface',
      key: 'code',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">{row.itfDef?.code || row.code}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">{row.itfDef?.name || row.name}</div>
        </div>
      )
    },
    {
      header: 'Type',
      key: 'type',
      render: (row) => (
        <div className="text-gray-600 dark:text-gray-300">
          {row.itfDef?.type || row.type || 'N/A'}
        </div>
      )
    },
    {
      header: 'Dernière exécution',
      key: 'lastExecution',
      render: (row) => (
        <div>
          {row.startTime ? (
            <DateFormatter 
              date={row.startTime} 
              format="relative"
            />
          ) : (
            <span className="text-gray-500 dark:text-gray-400">Jamais</span>
          )}
        </div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => {
        const statusInfo = getExecutionStatusInfo(row.status);
        return (
          <StatusBadge 
            status={statusInfo.status} 
            customLabel={statusInfo.label} 
          />
        );
      }
    },
    {
      header: 'Santé',
      key: 'health',
      render: (row) => {
        const code = row.itfDef?.code || row.code;
        const health = healthStatus[code];
        const healthInfo = getHealthStatusInfo(health || 'UNKNOWN');
        return (
          <StatusBadge 
            status={healthInfo.status} 
            customLabel={healthInfo.label} 
          />
        );
      }
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Play}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedInterface(row.itfDef || row);
              setShowExecutionModal(true);
            }}
          >
            Exécuter
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={BarChart2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedInterface(row.itfDef || row);
              fetchInterfaceMetrics(row.itfDef?.code || row.code);
              setShowMetricsModal(true);
            }}
          >
            Métriques
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={FileText}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedInterface(row.itfDef || row);
              fetchExecutions(row.itfDef?.code || row.code, 0);
            }}
          >
            Exécutions
          </Button>
        </div>
      )
    }
  ];

  // Colonnes pour la liste des exécutions
  const executionColumns = [
    {
      header: 'ID',
      key: 'executionId',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400 truncate max-w-xs">
          {row.executionId}
        </div>
      )
    },
    {
      header: 'Démarré le',
      key: 'startTime',
      render: (row) => (
        <div>
          <DateFormatter 
            date={row.startTime} 
            format="default"
            showTime={true}
          />
          <div className="text-xs text-gray-500 dark:text-gray-400">
            <DateFormatter 
              date={row.startTime} 
              format="relative"
            />
          </div>
        </div>
      )
    },
    {
      header: 'Durée',
      key: 'duration',
      render: (row) => {
        if (!row.startTime) return <span>-</span>;
        
        const startTime = new Date(row.startTime);
        const endTime = row.endTime ? new Date(row.endTime) : new Date();
        const durationMs = endTime - startTime;
        
        if (durationMs < 1000) {
          return <span>{durationMs}ms</span>;
        } else if (durationMs < 60000) {
          return <span>{Math.floor(durationMs / 1000)}s</span>;
        } else {
          const minutes = Math.floor(durationMs / 60000);
          const seconds = Math.floor((durationMs % 60000) / 1000);
          return <span>{minutes}m {seconds}s</span>;
        }
      }
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => {
        const statusInfo = getExecutionStatusInfo(row.status);
        return (
          <StatusBadge 
            status={statusInfo.status} 
            customLabel={statusInfo.label} 
          />
        );
      }
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              fetchExecutionDetails(row.executionId);
            }}
          >
            Détails
          </Button>
          {row.status === 'FAILED' && (
            <Button
              variant="outline"
              size="sm"
              icon={RotateCcw}
              onClick={(e) => {
                e.stopPropagation();
                retryExecution(row.executionId);
              }}
            >
              Réessayer
            </Button>
          )}
          {(row.status === 'RUNNING' || row.status === 'PENDING') && (
            <Button
              variant="danger"
              size="sm"
              icon={StopCircle}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedExecution(row);
                setShowCancelModal(true);
              }}
            >
              Annuler
            </Button>
          )}
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Play className="h-6 w-6 mr-2 text-blue-500" />
            Exécution et Surveillance des Interfaces
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Exécutez, surveillez et analysez les interfaces d'intégration
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={() => {
              fetchAllInterfaceStatus();
              fetchHealthStatus();
              if (selectedInterface) {
                fetchExecutions(selectedInterface.code, 0);
                fetchInterfaceMetrics(selectedInterface.code);
              }
            }}
            loading={loading && !showExecutionModal && !showDetailsModal && !showMetricsModal && !showCancelModal}
          >
            Actualiser
          </Button>
          {selectedInterface && (
            <>
              <Button
                variant="outline"
                icon={Layers}
                onClick={() => {
                  setSelectedInterface(null);
                  setExecutions([]);
                }}
              >
                Toutes les interfaces
              </Button>
              <Button
                variant="primary"
                icon={Play}
                onClick={() => setShowExecutionModal(true)}
              >
                Exécuter
              </Button>
            </>
          )}
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder={selectedInterface ? "Rechercher une exécution..." : "Rechercher une interface..."}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Tableau d'état de santé global */}
      {!selectedInterface && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Interfaces actives */}
          <Card 
            title="Interfaces actives"
            icon={Heart}
            className="bg-gradient-to-r from-green-50 to-emerald-50 dark:from-green-900/10 dark:to-emerald-900/10"
          >
            <div className="text-center">
              <div className="text-4xl font-bold text-green-600 dark:text-green-400">
                {Object.values(healthStatus).filter(status => status === 'UP').length}
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                sur {Object.keys(healthStatus).length} interfaces
              </div>
            </div>
          </Card>
          
          {/* Interfaces avec des problèmes */}
          <Card 
            title="Interfaces avec problèmes"
            icon={AlertTriangle}
            className="bg-gradient-to-r from-red-50 to-orange-50 dark:from-red-900/10 dark:to-orange-900/10"
          >
            <div className="text-center">
              <div className="text-4xl font-bold text-red-600 dark:text-red-400">
                {Object.values(healthStatus).filter(status => status === 'DOWN' || status === 'STALLED').length}
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                Interfaces nécessitant attention
              </div>
            </div>
          </Card>
          
          {/* Exécutions en cours */}
          <Card 
            title="Exécutions en cours"
            icon={Clock}
            className="bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-900/10 dark:to-indigo-900/10"
          >
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-600 dark:text-blue-400">
                {interfaces.filter(intf => intf.status === 'RUNNING' || intf.status === 'PENDING').length}
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                Exécutions en attente ou en cours
              </div>
            </div>
          </Card>
          
          {/* Exécutions réussies / échouées aujourd'hui */}
          <Card 
            title="Taux de succès aujourd'hui"
            icon={BarChart2}
            className="bg-gradient-to-r from-purple-50 to-violet-50 dark:from-purple-900/10 dark:to-violet-900/10"
          >
            <div className="text-center">
              <div className="text-4xl font-bold text-purple-600 dark:text-purple-400">
                {/* Valeur factice, à remplacer par des données réelles */}
                85%
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                Ratio de réussite des exécutions
              </div>
            </div>
          </Card>
        </div>
      )}

      {/* Liste des interfaces ou des exécutions */}
      {selectedInterface ? (
        // Liste des exécutions pour l'interface sélectionnée
        <div className="space-y-4">
          <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {selectedInterface.name || selectedInterface.code}
                </h2>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {selectedInterface.description || `Exécutions de l'interface ${selectedInterface.code}`}
                </p>
                <div className="mt-2 flex items-center space-x-4">
                  <div className="flex items-center">
                    <span className="text-sm text-gray-500 dark:text-gray-400 mr-2">Type:</span>
                    <span className="text-sm font-medium text-gray-900 dark:text-white">{selectedInterface.type || 'N/A'}</span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-sm text-gray-500 dark:text-gray-400 mr-2">Version:</span>
                    <span className="text-sm font-medium text-gray-900 dark:text-white">{selectedInterface.version || '1.0'}</span>
                  </div>
                </div>
              </div>
              
              <div className="flex space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  icon={BarChart2}
                  onClick={() => {
                    fetchInterfaceMetrics(selectedInterface.code);
                    setShowMetricsModal(true);
                  }}
                >
                  Métriques
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  icon={Play}
                  onClick={() => setShowExecutionModal(true)}
                >
                  Exécuter
                </Button>
              </div>
            </div>
          </div>
          
          <Card>
            <DataGrid
              data={executions.filter(exec => 
                exec.executionId.toLowerCase().includes(searchTerm.toLowerCase())
              )}
              columns={executionColumns}
              emptyMessage="Aucune exécution trouvée"
              loading={loading}
            />
            
            {/* Indicateur de pagination infinie */}
            {pagination.hasMore && !loading && (
              <div 
                ref={pageEndRef} 
                className="py-4 text-center text-gray-500 dark:text-gray-400"
              >
                Chargement de plus d'exécutions...
              </div>
            )}
          </Card>
        </div>
      ) : (
        // Liste de toutes les interfaces
        <Card>
          <DataGrid
            data={interfaces.filter(intf => {
              const searchTermLower = searchTerm.toLowerCase();
              return (
                (intf.code || intf.itfDef?.code || '').toLowerCase().includes(searchTermLower) ||
                (intf.name || intf.itfDef?.name || '').toLowerCase().includes(searchTermLower) ||
                (intf.type || intf.itfDef?.type || '').toLowerCase().includes(searchTermLower)
              );
            })}
            columns={interfaceColumns}
            emptyMessage="Aucune interface trouvée"
            loading={loading}
            onRowClick={(row) => {
              setSelectedInterface(row.itfDef || row);
              fetchExecutions(row.itfDef?.code || row.code, 0);
            }}
          />
        </Card>
      )}

      {/* Modal d'exécution */}
      {showExecutionModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-3xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Exécuter l'interface {selectedInterface.code}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowExecutionModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="mb-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Contexte d'exécution
                  </h3>
                </div>
                
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Priorité
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={executionContext.priority}
                    onChange={(e) => setExecutionContext({
                      ...executionContext,
                      priority: e.target.value
                    })}
                  >
                    <option value="LOW">Basse</option>
                    <option value="NORMAL">Normale</option>
                    <option value="HIGH">Haute</option>
                    <option value="URGENT">Urgente</option>
                  </select>
                </div>
                
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Ajouter des paramètres
                    </label>
                    <div className="flex space-x-2">
                      <button
                        className={`px-3 py-1 text-xs rounded-md ${paramType === 'parameters' ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300' : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'}`}
                        onClick={() => setParamType('parameters')}
                      >
                        Paramètres
                      </button>
                      <button
                        className={`px-3 py-1 text-xs rounded-md ${paramType === 'variables' ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300' : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'}`}
                        onClick={() => setParamType('variables')}
                      >
                        Variables
                      </button>
                      <button
                        className={`px-3 py-1 text-xs rounded-md ${paramType === 'headers' ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300' : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'}`}
                        onClick={() => setParamType('headers')}
                      >
                        En-têtes
                      </button>
                    </div>
                  </div>
                  
                  <div className="flex space-x-2 mb-2">
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Clé"
                      value={newParamKey}
                      onChange={(e) => setNewParamKey(e.target.value)}
                    />
                    <input
                      type="text"
                      className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Valeur"
                      value={newParamValue}
                      onChange={(e) => setNewParamValue(e.target.value)}
                    />
                    <Button
                      variant="outline"
                      onClick={handleAddParam}
                      disabled={newParamKey.trim() === ''}
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
                
                {/* Affichage des paramètres ajoutés */}
                <div>
                  <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Paramètres configurés
                  </h4>
                  
                  <div className="space-y-4">
                    {['parameters', 'variables', 'headers'].map((type) => (
                      <div key={type}>
                        {Object.keys(executionContext[type]).length > 0 && (
                          <div>
                            <h5 className="text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400 mb-2">
                              {type === 'parameters' ? 'Paramètres' : type === 'variables' ? 'Variables' : 'En-têtes'}
                            </h5>
                            <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-32 overflow-y-auto">
                              <ul className="space-y-1">
                                {Object.entries(executionContext[type]).map(([key, value]) => (
                                  <li key={key} className="flex justify-between items-center p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm">
                                    <div>
                                      <span className="font-medium text-gray-900 dark:text-white">{key}:</span>
                                      <span className="ml-2 text-gray-700 dark:text-gray-300">{value}</span>
                                    </div>
                                    <button
                                      className="text-red-500 hover:text-red-600"
                                      onClick={() => handleRemoveParam(type, key)}
                                    >
                                      <XCircle className="h-4 w-4" />
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    
                    {['parameters', 'variables', 'headers'].every(type => 
                      Object.keys(executionContext[type]).length === 0
                    ) && (
                      <div className="text-center py-4 text-gray-500 dark:text-gray-400">
                        Aucun paramètre configuré
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowExecutionModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Play}
                  onClick={executeInterface}
                  loading={loading}
                >
                  Exécuter
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de détails d'exécution */}
      {showDetailsModal && selectedExecution && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Détails de l'exécution
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Informations générales */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Informations générales
                  </h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">ID d'exécution:</span>
                      <span className="font-medium text-gray-900 dark:text-white">{selectedExecution.executionId}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Interface:</span>
                      <span className="font-medium text-gray-900 dark:text-white">
                        {selectedExecution.itfDef?.code || 'N/A'}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Démarré le:</span>
                      <span className="font-medium text-gray-900 dark:text-white">
                        <DateFormatter date={selectedExecution.startTime} format="full" showTime={true} />
                      </span>
                    </div>
                    {selectedExecution.endTime && (
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Terminé le:</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                          <DateFormatter date={selectedExecution.endTime} format="full" showTime={true} />
                        </span>
                      </div>
                    )}
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                      <StatusBadge 
                        status={getExecutionStatusInfo(selectedExecution.status).status} 
                        customLabel={getExecutionStatusInfo(selectedExecution.status).label} 
                      />
                    </div>
                    {selectedExecution.retryCount > 0 && (
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Tentatives:</span>
                        <span className="font-medium text-gray-900 dark:text-white">{selectedExecution.retryCount}</span>
                      </div>
                    )}
                    {selectedExecution.errorMessage && (
                      <div>
                        <span className="text-gray-500 dark:text-gray-400">Message d'erreur:</span>
                        <div className="mt-1 p-2 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded text-sm text-red-700 dark:text-red-300">
                          {selectedExecution.errorMessage}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Résultats et métriques */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Résultats et métriques
                  </h3>
                  
                  {selectedExecution.result ? (
                    <div className="space-y-3">
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Enregistrements traités:</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                          {selectedExecution.result.recordsProcessed}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Réussites:</span>
                        <span className="font-medium text-green-600 dark:text-green-400">
                          {selectedExecution.result.successCount}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Échecs:</span>
                        <span className="font-medium text-red-600 dark:text-red-400">
                          {selectedExecution.result.failureCount}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500 dark:text-gray-400">Temps d'exécution:</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                          {selectedExecution.result.executionTimeMs} ms
                        </span>
                      </div>
                      
                      {selectedExecution.result.warnings && selectedExecution.result.warnings.length > 0 && (
                        <div>
                          <span className="text-gray-500 dark:text-gray-400">Avertissements:</span>
                          <div className="mt-1 p-2 bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded text-sm text-yellow-700 dark:text-yellow-300">
                            <ul className="list-disc list-inside space-y-1">
                              {selectedExecution.result.warnings.map((warning, index) => (
                                <li key={index}>{warning}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                      
                      {selectedExecution.result.metrics && Object.keys(selectedExecution.result.metrics).length > 0 && (
                        <div>
                          <span className="text-gray-500 dark:text-gray-400">Métriques détaillées:</span>
                          <div className="mt-1 p-2 bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded">
                            <ul className="space-y-1">
                              {Object.entries(selectedExecution.result.metrics).map(([key, value]) => (
                                <li key={key} className="flex justify-between text-sm">
                                  <span className="text-gray-600 dark:text-gray-300">{key}:</span>
                                  <span className="font-medium text-gray-900 dark:text-white">{value}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-center py-4 text-gray-500 dark:text-gray-400">
                      Aucun résultat disponible
                    </div>
                  )}
                </div>
              </div>
              
              {/* Contexte d'exécution */}
              {selectedExecution.context && (
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Contexte d'exécution
                  </h3>
                  
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Paramètres */}
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                        Paramètres
                      </h4>
                      
                      {selectedExecution.context.parameters && 
                      Object.keys(selectedExecution.context.parameters).length > 0 ? (
                        <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                          <ul className="space-y-1">
                            {Object.entries(selectedExecution.context.parameters).map(([key, value]) => (
                              <li key={key} className="flex justify-between text-sm">
                                <span className="font-medium text-gray-600 dark:text-gray-300">{key}:</span>
                                <span className="text-gray-900 dark:text-white">{value}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <div className="text-center py-3 text-gray-500 dark:text-gray-400 text-sm">
                          Aucun paramètre défini
                        </div>
                      )}
                    </div>
                    
                    {/* Variables et en-têtes */}
                    <div>
                      <div className="mb-4">
                        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Variables
                        </h4>
                        
                        {selectedExecution.context.variables && 
                        Object.keys(selectedExecution.context.variables).length > 0 ? (
                          <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                            <ul className="space-y-1">
                              {Object.entries(selectedExecution.context.variables).map(([key, value]) => (
                                <li key={key} className="flex justify-between text-sm">
                                  <span className="font-medium text-gray-600 dark:text-gray-300">{key}:</span>
                                  <span className="text-gray-900 dark:text-white">{value}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <div className="text-center py-3 text-gray-500 dark:text-gray-400 text-sm">
                            Aucune variable définie
                          </div>
                        )}
                      </div>
                      
                      <div>
                        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                          En-têtes
                        </h4>
                        
                        {selectedExecution.context.headers && 
                        Object.keys(selectedExecution.context.headers).length > 0 ? (
                          <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                            <ul className="space-y-1">
                              {Object.entries(selectedExecution.context.headers).map(([key, value]) => (
                                <li key={key} className="flex justify-between text-sm">
                                  <span className="font-medium text-gray-600 dark:text-gray-300">{key}:</span>
                                  <span className="text-gray-900 dark:text-white">{value}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <div className="text-center py-3 text-gray-500 dark:text-gray-400 text-sm">
                            Aucun en-tête défini
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              <div className="mt-8 flex justify-end space-x-3">
                {selectedExecution.status === 'FAILED' && (
                  <Button
                    variant="outline"
                    icon={RotateCcw}
                    onClick={() => {
                      retryExecution(selectedExecution.executionId);
                    }}
                  >
                    Réessayer
                  </Button>
                )}
                {(selectedExecution.status === 'RUNNING' || selectedExecution.status === 'PENDING') && (
                  <Button
                    variant="danger"
                    icon={StopCircle}
                    onClick={() => {
                      setShowDetailsModal(false);
                      setShowCancelModal(true);
                    }}
                  >
                    Annuler
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={() => setShowDetailsModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de métriques */}
      {showMetricsModal && selectedInterface && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Métriques de l'interface: {selectedInterface.code}
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowMetricsModal(false)}
                >
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              {metricsLoading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
              ) : interfaceMetrics ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  {/* Statistiques de base */}
                  <div className="space-y-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      Statistiques générales
                    </h3>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
                        <div className="text-sm text-blue-600 dark:text-blue-400 font-medium">
                          Exécutions totales
                        </div>
                        <div className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                          {interfaceMetrics.totalExecutions || 0}
                        </div>
                      </div>
                      
                      <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg">
                        <div className="text-sm text-green-600 dark:text-green-400 font-medium">
                          Taux de succès
                        </div>
                        <div className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                          {interfaceMetrics.successRate || 0}%
                        </div>
                      </div>
                      
                      <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg">
                        <div className="text-sm text-red-600 dark:text-red-400 font-medium">
                          Taux d'échec
                        </div>
                        <div className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                          {interfaceMetrics.failureRate || 0}%
                        </div>
                      </div>
                      
                      <div className="bg-purple-50 dark:bg-purple-900/20 p-4 rounded-lg">
                        <div className="text-sm text-purple-600 dark:text-purple-400 font-medium">
                          Temps moyen d'exécution
                        </div>
                        <div className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                          {interfaceMetrics.averageExecutionTimeMs ? 
                            `${Math.round(interfaceMetrics.averageExecutionTimeMs)} ms` : 'N/A'}
                        </div>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                      <div className="text-sm text-gray-500 dark:text-gray-400 font-medium mb-2">
                        Dernière exécution
                      </div>
                      <div className="text-base text-gray-900 dark:text-white">
                        {interfaceMetrics.lastExecutionTime ? (
                          <DateFormatter 
                            date={interfaceMetrics.lastExecutionTime} 
                            format="full"
                            showTime={true}
                          />
                        ) : 'Jamais'}
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                      <div className="text-sm text-gray-500 dark:text-gray-400 font-medium mb-2">
                        Exécutions par heure
                      </div>
                      <div className="text-2xl font-bold text-gray-900 dark:text-white">
                        {interfaceMetrics.executionsPerHour || 0}
                      </div>
                    </div>
                  </div>
                  
                  {/* Graphiques et détails supplémentaires */}
                  <div className="space-y-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      Tendances et détails
                    </h3>
                    
                    {/* Ici, on pourrait ajouter des graphiques avec des bibliothèques comme recharts */}
                    <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg h-64 flex items-center justify-center">
                      <div className="text-center text-gray-500 dark:text-gray-400">
                        <BarChart2 className="h-12 w-12 mx-auto mb-2" />
                        <p>Des graphiques détaillés pourraient être affichés ici</p>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                      <div className="text-sm text-gray-500 dark:text-gray-400 font-medium mb-2">
                        État de santé
                      </div>
                      <div className="flex items-center">
                        <StatusBadge 
                          status={getHealthStatusInfo(healthStatus[selectedInterface.code] || 'UNKNOWN').status}
                          customLabel={getHealthStatusInfo(healthStatus[selectedInterface.code] || 'UNKNOWN').label}
                          size="lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center py-12 text-gray-500 dark:text-gray-400">
                  <AlertCircle className="h-12 w-12 mx-auto mb-4" />
                  <p>Aucune métrique disponible pour cette interface</p>
                </div>
              )}
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  icon={RefreshCw}
                  onClick={() => fetchInterfaceMetrics(selectedInterface.code)}
                  loading={metricsLoading}
                >
                  Actualiser
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setShowMetricsModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation d'annulation */}
      {showCancelModal && selectedExecution && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20">
                  <StopCircle className="h-6 w-6 text-red-600 dark:text-red-500" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  Annuler l'exécution
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir annuler cette exécution ? Cette action est irréversible.
                </p>
              </div>
			  <div className="mt-6 flex justify-center space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCancelModal(false)}
                >
                  Non, continuer
                </Button>
                <Button
                  variant="danger"
                  icon={StopCircle}
                  onClick={cancelExecution}
                  loading={loading}
                >
                  Oui, annuler
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InterfaceExecutionPage;