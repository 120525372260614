import React, { useState, useEffect } from 'react';
import {
  Lock,
  PlusCircle,
  Edit,
  Trash2,
  RefreshCw,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  Search,
  ChevronDown,
  ChevronRight,
  Folder,
  FileText,
  Info
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const PermissionsManagement = () => {
  // États de données
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [groups, setGroups] = useState([]);
  
  // États pour les formulaires
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [permissionForm, setPermissionForm] = useState({
    code: '',
    name: '',
    description: '',
    group: '',
    isActive: true
  });
  
  // Chargement initial des permissions
  useEffect(() => {
    fetchPermissions();
  }, []);
  
  // Fonction pour récupérer les permissions
  const fetchPermissions = async () => {
    setLoading(true);
    try {
      const permissionsData = await apiService.security.permissions.getAll();
      setPermissions(permissionsData || []);
      
      // Extraire les groupes uniques
      const uniqueGroups = [...new Set(permissionsData.map(p => p.group))].filter(Boolean);
      setGroups(uniqueGroups);
      
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des permissions:', err);
      setError('Impossible de charger les permissions. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour récupérer les permissions par groupe
  const fetchPermissionsByGroup = async (group) => {
    if (group === 'all') {
      fetchPermissions();
      return;
    }
    
    setLoading(true);
    try {
      const permissionsData = await apiService.security.permissions.getByGroup(group);
      setPermissions(permissionsData || []);
      setError(null);
    } catch (err) {
      console.error(`Erreur lors du chargement des permissions du groupe ${group}:`, err);
      setError('Impossible de charger les permissions. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrage des permissions basé sur la recherche
  const filteredPermissions = permissions.filter(permission => {
    if (selectedGroup !== 'all' && permission.group !== selectedGroup) {
      return false;
    }
    
    const searchableValues = [
      permission.code,
      permission.name,
      permission.description
    ].map(val => (val || '').toLowerCase());
    
    return searchTerm === '' || searchableValues.some(val => val.includes(searchTerm.toLowerCase()));
  });
  
  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'code',
      header: 'Code',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center text-blue-600 dark:text-blue-300">
            <Lock size={18} />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900 dark:text-white">
              {row.code}
            </div>
            {row.name && (
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {row.name}
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      key: 'description',
      header: 'Description',
      sortable: true,
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300">
          {row.description || <span className="text-gray-400 dark:text-gray-500">Aucune description</span>}
        </div>
      )
    },
    {
      key: 'group',
      header: 'Groupe',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300">
            <Folder className="w-3 h-3 mr-1" />
            {row.group || 'Autres'}
          </span>
        </div>
      )
    },
    {
      key: 'isActive',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge
          status={row.isActive ? 'active' : 'inactive'}
          customLabel={row.isActive ? 'Actif' : 'Inactif'}
        />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => handleEditPermission(row)}
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => handleDeletePermission(row.id)}
          />
        </div>
      )
    }
  ];
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleGroupChange = (e) => {
    const group = e.target.value;
    setSelectedGroup(group);
    if (group !== 'all') {
      fetchPermissionsByGroup(group);
    } else {
      fetchPermissions();
    }
  };
  
  const handleCreatePermission = () => {
    setPermissionForm({
      code: '',
      name: '',
      description: '',
      group: '',
      isActive: true
    });
    setFormMode('create');
    setShowPermissionModal(true);
  };
  
  const handleEditPermission = (permission) => {
    setPermissionForm({
      id: permission.id,
      code: permission.code,
      name: permission.name || '',
      description: permission.description || '',
      group: permission.group || '',
      isActive: permission.isActive
    });
    setFormMode('edit');
    setShowPermissionModal(true);
  };
  
  const handleFormChange = (field, value) => {
    setPermissionForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleSubmitPermission = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      if (formMode === 'create') {
        await apiService.security.permissions.create(permissionForm);
        setSuccessMessage('Permission créée avec succès');
      } else {
        // Note: Il semble qu'il n'y ait pas d'endpoint spécifique pour mettre à jour une permission
        // En considérant qu'une telle fonctionnalité existerait, voici comment ça se passerait:
        // await apiService.security.permissions.update(permissionForm.id, permissionForm);
        
        // En absence d'un tel endpoint, nous pouvons simuler par une suppression suivie d'une création
        await apiService.security.permissions.delete(permissionForm.id);
        await apiService.security.permissions.create(permissionForm);
        setSuccessMessage('Permission mise à jour avec succès');
      }
      
      // Actualiser la liste des permissions
      if (selectedGroup !== 'all') {
        await fetchPermissionsByGroup(selectedGroup);
      } else {
        await fetchPermissions();
      }
      
      // Fermer le modal
      setShowPermissionModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeletePermission = async (permissionId) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette permission ?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.security.permissions.delete(permissionId);
      
      // Actualiser la liste des permissions
      if (selectedGroup !== 'all') {
        await fetchPermissionsByGroup(selectedGroup);
      } else {
        await fetchPermissions();
      }
      
      setSuccessMessage('Permission supprimée avec succès');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError(err.message || 'Impossible de supprimer la permission.');
    } finally {
      setLoading(false);
    }
  };
  
  const refreshData = async () => {
    if (selectedGroup !== 'all') {
      await fetchPermissionsByGroup(selectedGroup);
    } else {
      await fetchPermissions();
    }
  };
  
  // Visualisation des permissions par groupe
  const groupedPermissions = {};
  permissions.forEach(permission => {
    const group = permission.group || 'Autres';
    if (!groupedPermissions[group]) {
      groupedPermissions[group] = [];
    }
    groupedPermissions[group].push(permission);
  });
  
  // Tableau de bord des permissions
  const renderPermissionDashboard = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.entries(groupedPermissions).map(([group, perms]) => (
          <Card key={group} className="h-full">
            <div className="p-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center">
                  <Folder className="h-5 w-5 mr-2 text-purple-500" />
                  {group}
                </h3><span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 text-xs font-medium rounded-full px-2 py-1">
                  {perms.length}
                </span>
              </div>
              
              <div className="space-y-2 max-h-60 overflow-y-auto pr-2">
                {perms.map(permission => (
                  <div 
                    key={permission.id}
                    className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-md"
                  >
                    <div className="flex-shrink-0 mt-1">
                      <FileText className="h-4 w-4 text-blue-500" />
                    </div>
                    <div className="ml-3 flex-1">
                      <div className="flex justify-between">
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {permission.code}
                        </div>
                        <StatusBadge
                          status={permission.isActive ? 'active' : 'inactive'}
                          size="sm"
                        />
                      </div>
                      <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                        {permission.name}
                      </div>
                      {permission.description && (
                        <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                          {permission.description}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Lock className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des permissions
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Configurez les permissions du système
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={handleCreatePermission}
          >
            Nouvelle permission
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Filtres et recherche */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/3">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Groupe
            </label>
            <select
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              value={selectedGroup}
              onChange={handleGroupChange}
            >
              <option value="all">Tous les groupes</option>
              {groups.map(group => (
                <option key={group} value={group}>{group}</option>
              ))}
            </select>
          </div>
          
          <div className="flex-1 relative">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Recherche
            </label>
            <div className="relative">
              <input
                type="text"
                className="w-full p-2 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                placeholder="Rechercher par code, nom..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>
      
      {/* Vue par tableau ou par groupe */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
        <div className="p-5">
          <ul className="flex flex-wrap border-b border-gray-200 dark:border-gray-700">
            <li className="mr-2">
              <a 
                href="#table-view" 
                className={`inline-block py-2 px-4 text-sm font-medium rounded-t-lg ${
                  !selectedGroup || selectedGroup === 'all' 
                    ? 'active text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400' 
                    : 'hover:text-gray-700 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedGroup('all');
                  fetchPermissions();
                }}
              >
                Vue tableau
              </a>
            </li>
            <li className="mr-2">
              <a 
                href="#grouped-view" 
                className={`inline-block py-2 px-4 text-sm font-medium rounded-t-lg ${
                  selectedGroup !== 'all' 
                    ? 'active text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400' 
                    : 'hover:text-gray-700 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  if (groups.length > 0) {
                    setSelectedGroup(groups[0]);
                    fetchPermissionsByGroup(groups[0]);
                  }
                }}
              >
                Vue par groupe
              </a>
            </li>
          </ul>
        </div>
        
        <div className="px-5 pb-5">
          {selectedGroup !== 'all' ? (
            renderPermissionDashboard()
          ) : (
            <DataGrid
              data={filteredPermissions}
              columns={columns}
              loading={loading}
              emptyMessage="Aucune permission trouvée."
            />
          )}
        </div>
      </div>
      
      {/* Modal de création/édition de permission */}
      {showPermissionModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md overflow-hidden">
            <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 px-6 py-4 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {formMode === 'create' ? 'Nouvelle permission' : 'Modifier la permission'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowPermissionModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitPermission} className="p-6 space-y-4">
              {/* Code de permission */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Code de permission *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={permissionForm.code}
                  onChange={(e) => handleFormChange('code', e.target.value)}
                  required
                  disabled={formMode === 'edit'} // Ne pas permettre l'édition du code
                />
                {formMode === 'edit' && (
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Le code de permission ne peut pas être modifié après création.
                  </p>
                )}
              </div>
              
              {/* Nom de la permission */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nom *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={permissionForm.name}
                  onChange={(e) => handleFormChange('name', e.target.value)}
                  required
                />
              </div>
              
              {/* Description */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Description
                </label>
                <textarea
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={permissionForm.description}
                  onChange={(e) => handleFormChange('description', e.target.value)}
                  rows={3}
                />
              </div>
              
              {/* Groupe */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Groupe
                </label>
                <div className="relative">
                  <select
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white appearance-none"
                    value={permissionForm.group}
                    onChange={(e) => handleFormChange('group', e.target.value)}
                  >
                    <option value="">Sélectionner un groupe</option>
                    {groups.map(group => (
                      <option key={group} value={group}>{group}</option>
                    ))}
                    <option value="new">Nouveau groupe...</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
                
                {permissionForm.group === 'new' && (
                  <div className="mt-2">
                    <input
                      type="text"
                      className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                      placeholder="Nom du nouveau groupe"
                      onChange={(e) => handleFormChange('group', e.target.value)}
                      autoFocus
                    />
                  </div>
                )}
              </div>
              
              {/* Activé/Désactivé */}
              <div className="flex items-center">
                <input
                  id="permission-active"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={permissionForm.isActive}
                  onChange={(e) => handleFormChange('isActive', e.target.checked)}
                />
                <label htmlFor="permission-active" className="ml-2 block text-sm text-gray-900 dark:text-white">
                  Permission active
                </label>
              </div>
              
              {/* Note informative */}
              <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-md">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <Info className="h-5 w-5 text-blue-500" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-blue-700 dark:text-blue-300">
                      Les permissions définissent des capacités granulaires qui peuvent être assignées aux rôles.
                      Utilisez des noms clairs et des descriptions détaillées pour faciliter leur utilisation.
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Actions */}
              <div className="flex justify-end space-x-3 border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setShowPermissionModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  icon={Save}
                  loading={loading}
                >
                  {formMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PermissionsManagement;