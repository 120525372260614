import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Bell, User, Settings, LogOut, ChevronDown, HelpCircle, Shield,
  Search, X, Sun, Moon, Menu, AlertCircle, Info, CheckCircle
} from 'lucide-react';
import apiService from '../../services/api.service';

// Composant pour les notifications
const NotificationItem = ({ notification, onDismiss }) => {
  const typeConfig = {
    info: {
      icon: Info,
      color: 'text-blue-600',
      bg: 'bg-blue-50 dark:bg-blue-900/10',
      border: 'border-blue-100 dark:border-blue-800'
    },
    success: {
      icon: CheckCircle,
      color: 'text-green-600',
      bg: 'bg-green-50 dark:bg-green-900/10',
      border: 'border-green-100 dark:border-green-800'
    },
    warning: {
      icon: AlertCircle,
      color: 'text-amber-600',
      bg: 'bg-amber-50 dark:bg-amber-900/10',
      border: 'border-amber-100 dark:border-amber-800'
    }
  };

  const config = typeConfig[notification.type] || typeConfig.info;
  const Icon = config.icon;

  return (
    <div className={`px-4 py-3 border-l-2 ${config.border} hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200`}>
      <div className="flex items-start space-x-3">
        <div className={`${config.bg} p-1 rounded-md mt-0.5`}>
          <Icon size={14} className={config.color} />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
            {notification.text}
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
            {notification.time}
          </p>
        </div>
        <button onClick={() => onDismiss(notification.id)} className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
          <X size={14} />
        </button>
      </div>
    </div>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [notifications, setNotifications] = useState([
    { id: 1, text: "Mise à jour système", time: "Il y a 5 min", type: "info" },
    { id: 2, text: "Sauvegarde effectuée", time: "Il y a 1 heure", type: "success" },
    { id: 3, text: "Tâches en attente", time: "Il y a 3 heures", type: "warning" }
  ]);
  
  const profileRef = useRef(null);
  const notificationsRef = useRef(null);
  const searchRef = useRef(null);

  const checkAndDecodeToken = () => {
    const token = localStorage.getItem('auth_token');
    if (!token) return null;
    
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));
      return {
        name: payload.sub || 'Utilisateur',
        role: payload.roles?.[0] || 'Utilisateur',
        email: payload.email || '',
        username: payload.preferred_username || payload.sub || ''
      };
    } catch (error) {
      console.error('Erreur décodage token:', error);
      return null;
    }
  };

  const handleLogout = async () => {
    try {
      if (localStorage.getItem('auth_token')) {
        await apiService.auth.logout();
      }
    } catch (error) {
      console.error('Erreur logout:', error);
    } finally {
      localStorage.removeItem('auth_token');
      localStorage.removeItem('refresh_token');
      setUserInfo(null);
      navigate('/login');
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const userToken = checkAndDecodeToken();
      if (!userToken) {
        navigate('/login');
        return;
      }
      setUserInfo(userToken);
    };
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setIsNotificationsOpen(false);
      }
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const dismissNotification = (id) => {
    setNotifications(prev => prev.filter(n => n.id !== id));
  };

  const IconButton = ({ icon: Icon, onClick, badge, active }) => (
    <button
      onClick={onClick}
      className={`relative p-2 rounded-md text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 ${
        active ? 'bg-gray-100 dark:bg-gray-800' : ''
      }`}
    >
      <Icon size={18} />
      {badge && (
        <span className="absolute top-0.5 right-0.5 w-4 h-4 bg-red-500 rounded-full flex items-center justify-center">
          <span className="text-[10px] font-medium text-white">{badge}</span>
        </span>
      )}
    </button>
  );

  return (
    <nav className="fixed w-full top-0 z-50 bg-white/90 dark:bg-gray-900/90 backdrop-blur border-b border-gray-200/50 dark:border-gray-800/50">
      <div className="max-w-8xl mx-auto">
        <div className="flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8">
          {/* Logo et titre */}
          <div className="flex items-center">
            <div className="flex items-center space-x-3">
              <div className="w-9 h-9 bg-gray-800 dark:bg-gray-700 rounded-lg flex items-center justify-center">
                <span className="text-xs font-semibold text-white">MP</span>
              </div>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                MXPAY
              </h1>
            </div>
          </div>

          {/* Boutons d'action */}
          <div className="flex items-center space-x-4">
            {/* Barre de recherche */}
            <div ref={searchRef} className="relative">
              <IconButton 
                icon={Search} 
                onClick={() => setIsSearchOpen(!isSearchOpen)}
                active={isSearchOpen}
              />
              {isSearchOpen && (
                <div className="absolute right-0 mt-2 w-80">
                  <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 p-3">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Rechercher..."
                        className="w-full pl-10 pr-4 py-2 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 dark:text-gray-100"
                        autoFocus
                      />
                      <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Aide */}
            <IconButton icon={HelpCircle} onClick={() => {}} />

            {/* Notifications */}
            <div ref={notificationsRef} className="relative">
              <IconButton 
                icon={Bell} 
                onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                badge={notifications.length}
                active={isNotificationsOpen}
              />
              {isNotificationsOpen && (
                <div className="absolute right-0 mt-2 w-96">
                  <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
                      <div className="flex justify-between items-center">
                        <h3 className="font-semibold text-gray-900 dark:text-white">
                          Notifications
                        </h3>
                        <span className="text-sm text-gray-500">
                          {notifications.length} nouvelle{notifications.length > 1 ? 's' : ''}
                        </span>
                      </div>
                    </div>
                    <div className="max-h-96 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-700">
                      {notifications.map(notification => (
                        <NotificationItem
                          key={notification.id}
                          notification={notification}
                          onDismiss={dismissNotification}
                        />
                      ))}
                    </div>
                    <div className="p-3 bg-gray-50 dark:bg-gray-800/50 border-t border-gray-200 dark:border-gray-700">
                      <button className="w-full text-sm text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 font-medium">
                        Voir toutes les notifications
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="h-6 w-px bg-gray-200 dark:bg-gray-700" />

            {/* Menu profil */}
            <div ref={profileRef} className="relative">
              <button 
                onClick={() => setIsProfileOpen(!isProfileOpen)}
                className="flex items-center space-x-3 p-1.5 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
              >
                <div className="w-8 h-8 rounded-md bg-gray-800 dark:bg-gray-700 flex items-center justify-center">
                  <span className="text-sm font-medium text-white">
                    {userInfo?.name?.charAt(0) || 'U'}
                  </span>
                </div>
                <div className="hidden md:block text-left">
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {userInfo?.name || 'Utilisateur'}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {userInfo?.role || 'Utilisateur'}
                  </p>
                </div>
                <ChevronDown 
                  size={16} 
                  className={`text-gray-500 transform transition-transform duration-200 ${isProfileOpen ? 'rotate-180' : ''}`}
                />
              </button>

              {isProfileOpen && (
                <div className="absolute right-0 mt-2 w-64">
                  <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {userInfo?.name || 'Utilisateur'}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                        {userInfo?.email || ''}
                      </p>
                    </div>
                    <div className="p-2">
                      {[
                        { icon: User, label: 'Mon Profil', path: '/profile' },
                        { icon: Settings, label: 'Paramètres', path: '/settings' },
                        { icon: Shield, label: 'Sécurité', path: '/security' }
                      ].map((item, index) => (
                        <Link
                          key={index}
                          to={item.path}
                          className="flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
                        >
                          <item.icon size={16} className="mr-2" />
                          {item.label}
                        </Link>
                      ))}
                    </div>
                    <div className="p-2 bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
                      <button
                        className="flex w-full items-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 dark:text-red-500 dark:hover:bg-red-900/20 rounded-md transition-colors duration-200"
                        onClick={handleLogout}
                      >
                        <LogOut size={16} className="mr-2" />
                        Déconnexion
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;