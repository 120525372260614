import React, { useState, useEffect } from 'react';
import { Check, Building2, Calendar, Receipt } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/api.service';

const PaymentCompletion = () => {
  const navigate = useNavigate();

  // États
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // État du formulaire
  const [formData, setFormData] = useState({
    officeCode: '',
    declarantCode: '',
    companyCode: '',
    registrationYear: new Date().getFullYear(),
    assessmentSerial: '',
    assessmentNumber: '',
    meanOfPayment: '',
    bankCode: '',
    checkReference: '',
    paymentDate: new Date().toISOString().split('T')[0]
  });

  // Vérification de l'authentification
  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  // Chargement initial des workflows
  useEffect(() => {
    fetchWorkflows();
  }, []);

   /*const fetchWorkflows = async () => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('Session expirée');
      navigate('/login');
      return;
    }

    try {
      const data = await apiService.declarationWorkflow.getAll();
      // Filtrer uniquement les workflows en attente de validation
      const pendingWorkflows = data.workflows.filter(w => 
        w.currentState === 'PAYMENT_INITIATED'
      );
      setWorkflows(pendingWorkflows);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors du chargement des workflows');
    }
  };*/
  
   const fetchWorkflows = async () => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('Session expirée');
      navigate('/login');
      return;
    }

    try {
      const data = await apiService.declarationWorkflow.getAll();
      setWorkflows(data.workflows || []);
      setError('');
    } catch (err) {
      setError(err.message || 'Erreur lors du chargement des workflows');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedWorkflow?.reference) return;

    setLoading(true);
    
    try {
      const response = await apiService.declarationWorkflow.completePayment(
        selectedWorkflow.reference,
        formData
      );

      // Mettre à jour la liste des workflows
      setWorkflows(prevWorkflows => 
        prevWorkflows.filter(w => w.reference !== selectedWorkflow.reference)
      );
      setSelectedWorkflow(null);
      setError('');

      // Réinitialiser le formulaire
      setFormData({
        officeCode: '',
        declarantCode: '',
        companyCode: '',
        registrationYear: new Date().getFullYear(),
        assessmentSerial: '',
        assessmentNumber: '',
        meanOfPayment: '',
        bankCode: '',
        checkReference: '',
        paymentDate: new Date().toISOString().split('T')[0]
      });
    } catch (err) {
      setError(err.message || 'Erreur lors de la finalisation du paiement');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Rendu du statut
  const Status = ({ currentState }) => {
    const statusConfigs = {
      'PAYMENT_VALIDATED': {
        color: 'bg-green-100 text-green-800',
      },
      'COMPLETED': {
        color: 'bg-blue-100 text-blue-800',
      }
    };

    const config = statusConfigs[currentState] || statusConfigs.PAYMENT_VALIDATED;

    return (
      <span className={`px-2 py-1 text-xs rounded-full ${config.color}`}>
        {currentState}
      </span>
    );
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg border shadow-sm">
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Finalisation des paiements</h2>
          
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 text-red-700 mb-4">
              {error}
            </div>
          )}

          {/* Liste des workflows */}
          <div className="overflow-x-auto mb-6">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Référence</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Déclaration</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">État</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Montant</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {workflows.map((workflow) => (
                  <tr 
                    key={workflow.reference}
                    onClick={() => {
                      setSelectedWorkflow(workflow);
                      // Pré-remplir le formulaire avec les données du workflow
                      setFormData(prev => ({
                        ...prev,
                        officeCode: workflow.officeCode || '',
                        declarantCode: workflow.declarantCode || '',
                        companyCode: workflow.companyCode || '',
                      }));
                    }}
                    className={`cursor-pointer hover:bg-gray-50 ${
                      selectedWorkflow?.reference === workflow.reference ? 'bg-blue-50' : ''
                    }`}
                  >
                    <td className="px-6 py-4 text-sm">{workflow.reference}</td>
                    <td className="px-6 py-4 text-sm">{workflow.declarationNumber}</td>
                    <td className="px-6 py-4">
                      <Status currentState={workflow.currentState} />
                    </td>
                    <td className="px-6 py-4 text-sm">
                      {workflow.totalAmount?.toLocaleString()} XAF
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Formulaire de finalisation */}
          {selectedWorkflow && (
            <div className="mt-6 border-t pt-6">
              <h3 className="text-lg font-medium mb-4">
                Finaliser le paiement - {selectedWorkflow.declarationNumber}
              </h3>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">Code bureau</label>
                    <div className="relative">
                      <Building2 className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                      <input
                        required
                        type="text"
                        name="officeCode"
                        value={formData.officeCode}
                        onChange={handleChange}
                        className="pl-10 w-full p-2 border rounded-lg"
                      />
                    </div>
                  </div>

                  {/* ... autres champs du formulaire ... */}
                  
                  <div className="flex justify-end space-x-4 pt-6 border-t">
                    <button
                      type="button"
                      onClick={() => setSelectedWorkflow(null)}
                      className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      disabled={loading}
                      className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 disabled:opacity-50"
                    >
                      <Check className="w-5 h-5 mr-2" />
                      {loading ? 'Finalisation...' : 'Finaliser le paiement'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentCompletion;