import React, { useState, useEffect } from 'react';
import { 
  RefreshCw, 
  PlusCircle, 
  Trash2, 
  Edit, 
  Search, 
  Play, 
  Download, 
  Settings, 
  CheckCircle2, 
  XCircle, 
  Info, 
  Upload,
  Save,
  X,
  Server,
  Wrench
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const SoapConnectionsPage = () => {
  // États pour gérer les données et les états UI
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOperationsModal, setShowOperationsModal] = useState(false);
  const [showWsdlModal, setShowWsdlModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showExecuteModal, setShowExecuteModal] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [operations, setOperations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [testResult, setTestResult] = useState(null);
  const [executeResult, setExecuteResult] = useState(null);
  const [executionParams, setExecutionParams] = useState({});
  
  // Form Data
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    wsdlUrl: '',
    endpointUrl: '',
    soapVersion: '1.1',
    namespaceUri: '',
    serviceName: '',
    portName: '',
    defaultHeaders: {},
    mtomEnabled: false,
    useWsSecurity: false,
    securityUsername: '',
    securityPassword: '',
    securityType: 'UsernameToken',
    securityTimeToLive: '300'
  });
  
  // WSDL Form Data
  const [wsdlFormData, setWsdlFormData] = useState({
    url: '',
    targetNamespace: '',
    serviceName: '',
    portTypeName: '',
    bindingName: '',
    importedSchemas: [],
    namespaces: {},
    wsdlVersion: '',
    operations: []
  });

  // Form data for parsing WSDL
  const [wsdlParseUrl, setWsdlParseUrl] = useState('');
  
  // Parameters for operation execution
  const [newParamKey, setNewParamKey] = useState('');
  const [newParamValue, setNewParamValue] = useState('');

  // Filtrer les connexions en fonction du terme de recherche
  const filteredConnections = connections.filter(connection => {
    return (
      connection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (connection.description && connection.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      connection.wsdlUrl.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Effet pour charger les connexions au chargement de la page
  useEffect(() => {
    fetchConnections();
  }, []);

  // Fonction pour charger les connexions SOAP
  const fetchConnections = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.soapConnections.getAll();
      setConnections(response);
    } catch (err) {
      console.error("Erreur lors du chargement des connexions SOAP :", err);
      setError("Impossible de charger les connexions SOAP. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger une connexion spécifique
  const fetchConnection = async (code) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.soapConnections.getById(code);
      setSelectedConnection(response);
      
      // Préparer les données pour le formulaire d'édition
      setFormData({
        name: response.name,
        description: response.description || '',
        wsdlUrl: response.wsdlUrl || '',
        endpointUrl: response.endpointUrl || '',
        soapVersion: response.soapVersion || '1.1',
        namespaceUri: response.namespaceUri || '',
        serviceName: response.serviceName || '',
        portName: response.portName || '',
        defaultHeaders: response.defaultHeaders || {},
        mtomEnabled: response.mtomEnabled || false,
        useWsSecurity: response.useWsSecurity || false,
        securityUsername: response.securityUsername || '',
        securityPassword: response.securityPassword || '',
        securityType: response.securityType || 'UsernameToken',
        securityTimeToLive: response.securityTimeToLive || '300'
      });

      if (response.wsdlDefinition) {
        setWsdlFormData({
          url: response.wsdlDefinition.url || '',
          targetNamespace: response.wsdlDefinition.targetNamespace || '',
          serviceName: response.wsdlDefinition.serviceName || '',
          portTypeName: response.wsdlDefinition.portTypeName || '',
          bindingName: response.wsdlDefinition.bindingName || '',
          importedSchemas: response.wsdlDefinition.importedSchemas || [],
          namespaces: response.wsdlDefinition.namespaces || {},
          wsdlVersion: response.wsdlDefinition.wsdlVersion || '',
          operations: response.wsdlDefinition.operations || []
        });
      }
    } catch (err) {
      console.error("Erreur lors du chargement de la connexion SOAP :", err);
      setError("Impossible de charger les détails de la connexion SOAP. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger les opérations d'une connexion
  const fetchOperations = async (code) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.soapConnections.getOperations(code);
      setOperations(response);
    } catch (err) {
      console.error("Erreur lors du chargement des opérations SOAP :", err);
      setError("Impossible de charger les opérations SOAP. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour créer une connexion SOAP
  const handleCreateConnection = async () => {
    setLoading(true);
    setError(null);
    try {
      const connectionData = {
        ...formData,
        defaultHeaders: parseHeadersFromForm(),
        active: true
      };
      await apiService.integration.soapConnections.create(connectionData);
      setSuccessMessage("Connexion SOAP créée avec succès !");
      setShowCreateModal(false);
      fetchConnections();
      resetForm();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la création de la connexion SOAP :", err);
      setError("Impossible de créer la connexion SOAP. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour mettre à jour une connexion SOAP
  const handleUpdateConnection = async () => {
    if (!selectedConnection) return;
    
    setLoading(true);
    setError(null);
    try {
      const connectionData = {
        ...formData,
        defaultHeaders: parseHeadersFromForm(),
        active: selectedConnection.active
      };
      await apiService.integration.soapConnections.update(selectedConnection.name, connectionData);
      setSuccessMessage("Connexion SOAP mise à jour avec succès !");
      setShowEditModal(false);
      fetchConnections();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la connexion SOAP :", err);
      setError("Impossible de mettre à jour la connexion SOAP. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour supprimer une connexion SOAP
  const handleDeleteConnection = async () => {
    if (!selectedConnection) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.soapConnections.delete(selectedConnection.name);
      setSuccessMessage("Connexion SOAP supprimée avec succès !");
      setShowDeleteModal(false);
      setSelectedConnection(null);
      fetchConnections();
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la suppression de la connexion SOAP :", err);
      setError("Impossible de supprimer la connexion SOAP. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour analyser un WSDL
  const handleParseWsdl = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.integration.soapConnections.parseWsdl(wsdlParseUrl);
      
      // Mettre à jour le formulaire avec les informations du WSDL
      setFormData(prev => ({
        ...prev,
        wsdlUrl: wsdlParseUrl,
        namespaceUri: response.targetNamespace || '',
        serviceName: response.serviceName || '',
        portName: response.portTypeName || ''
      }));
      
      setWsdlFormData(response);
      
      setSuccessMessage("WSDL analysé avec succès !");
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de l'analyse du WSDL :", err);
      setError("Impossible d'analyser le WSDL. Veuillez vérifier l'URL et réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour tester une opération SOAP
  const handleTestOperation = async () => {
    if (!selectedConnection || !selectedOperation) return;
    
    setLoading(true);
    setError(null);
    setTestResult(null);
    try {
      const response = await apiService.integration.soapConnections.testOperation(
        selectedConnection.name, 
        selectedOperation.name
      );
      setTestResult({
        success: true,
        data: response
      });
    } catch (err) {
      console.error("Erreur lors du test de l'opération SOAP :", err);
      setTestResult({
        success: false,
        error: err.message || "Une erreur s'est produite pendant le test"
      });
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour exécuter une opération SOAP
  const handleExecuteOperation = async () => {
    if (!selectedConnection || !selectedOperation) return;
    
    setLoading(true);
    setError(null);
    setExecuteResult(null);
    try {
      const response = await apiService.integration.soapConnections.executeOperation(
        selectedConnection.name, 
        selectedOperation.name, 
        executionParams
      );
      setExecuteResult({
        success: true,
        data: response
      });
    } catch (err) {
      console.error("Erreur lors de l'exécution de l'opération SOAP :", err);
      setExecuteResult({
        success: false,
        error: err.message || "Une erreur s'est produite pendant l'exécution"
      });
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour mettre à jour la définition WSDL
  const handleUpdateWsdlDefinition = async () => {
    if (!selectedConnection) return;
    
    setLoading(true);
    setError(null);
    try {
      await apiService.integration.soapConnections.updateWsdlDefinition(
        selectedConnection.name, 
        wsdlFormData
      );
      setSuccessMessage("Définition WSDL mise à jour avec succès !");
      setShowWsdlModal(false);
      fetchConnection(selectedConnection.name);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la définition WSDL :", err);
      setError("Impossible de mettre à jour la définition WSDL. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Réinitialisation du formulaire
  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      wsdlUrl: '',
      endpointUrl: '',
      soapVersion: '1.1',
      namespaceUri: '',
      serviceName: '',
      portName: '',
      defaultHeaders: {},
      mtomEnabled: false,
      useWsSecurity: false,
      securityUsername: '',
      securityPassword: '',
      securityType: 'UsernameToken',
      securityTimeToLive: '300'
    });
    setWsdlFormData({
      url: '',
      targetNamespace: '',
      serviceName: '',
      portTypeName: '',
      bindingName: '',
      importedSchemas: [],
      namespaces: {},
      wsdlVersion: '',
      operations: []
    });
    setWsdlParseUrl('');
  };

  // Gestion des changements dans le formulaire
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Gestion des changements dans le formulaire WSDL
  const handleWsdlFormChange = (e) => {
    const { name, value } = e.target;
    setWsdlFormData({
      ...wsdlFormData,
      [name]: value
    });
  };

  // Gestion des changements dans les paramètres d'exécution
  const handleParamChange = (e) => {
    const { name, value } = e.target;
    setExecutionParams({
      ...executionParams,
      [name]: value
    });
  };

  // Fonction utilitaire pour parser les en-têtes depuis le formulaire
  const parseHeadersFromForm = () => {
    // Ici, vous pourriez avoir un champ spécifique pour les en-têtes
    // Pour cet exemple, nous retournons simplement un objet vide ou les en-têtes existants
    return formData.defaultHeaders || {};
  };

  // Colonne pour la grille de données des connexions
  const connectionColumns = [
    {
      header: 'Nom',
      key: 'name',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.name}
        </div>
      )
    },
    {
      header: 'Description / URL',
      key: 'description',
      render: (row) => (
        <div>
          <div className="font-medium text-gray-900 dark:text-white">
            {row.description || '-'}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-md">
            {row.wsdlUrl}
          </div>
        </div>
      )
    },
    {
      header: 'Service / Port',
      key: 'service',
      render: (row) => (
        <div>
          <div>{row.serviceName || '-'}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {row.portName || '-'}
          </div>
        </div>
      )
    },
    {
      header: 'Version SOAP',
      key: 'soapVersion',
      render: (row) => (
        <div>{row.soapVersion || '-'}</div>
      )
    },
    {
      header: 'Statut',
      key: 'status',
      render: (row) => (
        <StatusBadge 
          status={row.status === 'ACTIVE' || row.active ? 'active' : 'inactive'} 
          customLabel={row.status === 'ACTIVE' || row.active ? 'Actif' : 'Inactif'} 
        />
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Play}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedConnection(row);
              fetchOperations(row.name);
              setShowOperationsModal(true);
            }}
          >
            Opérations
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Settings}
            onClick={(e) => {
              e.stopPropagation();
              fetchConnection(row.name);
              setShowWsdlModal(true);
            }}
          >
            WSDL
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Edit}
            onClick={(e) => {
              e.stopPropagation();
              fetchConnection(row.name);
              setShowEditModal(true);
            }}
          >
            Modifier
          </Button>
          <Button
            variant="danger"
            size="sm"
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedConnection(row);
              setShowDeleteModal(true);
            }}
          >
            Supprimer
          </Button>
        </div>
      )
    }
  ];

  // Colonnes pour la grille de données des opérations
  const operationColumns = [
    {
      header: 'Nom',
      key: 'name',
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400">
          {row.name}
        </div>
      )
    },
    {
      header: 'Action SOAP',
      key: 'soapAction',
      render: (row) => (
        <div>{row.soapAction || '-'}</div>
      )
    },
    {
      header: 'Description',
      key: 'description',
      render: (row) => (
        <div>{row.description || '-'}</div>
      )
    },
    {
      header: 'Timeout',
      key: 'timeout',
      render: (row) => (
        <div>{row.timeout ? `${row.timeout} ms` : '-'}</div>
      )
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'right',
      render: (row) => (
        <div className="flex space-x-2 justify-end">
          <Button
            variant="outline"
            size="sm"
            icon={Play}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedOperation(row);
              setTestResult(null);
              setShowTestModal(true);
            }}
          >
            Tester
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={Play}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedOperation(row);
              setExecuteResult(null);
              setExecutionParams({});
              setShowExecuteModal(true);
            }}
          >
            Exécuter
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Server className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des Connexions SOAP
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Configurez et gérez les connexions vers les services web SOAP
          </p>
        </div>
        
        <div className="flex space-x-3">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchConnections}
            loading={loading && !showCreateModal && !showEditModal && !showDeleteModal && !showOperationsModal && !showWsdlModal && !showTestModal && !showExecuteModal}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={() => {
              resetForm();
              setShowCreateModal(true);
            }}
          >
            Nouvelle connexion
          </Button>
        </div>
      </div>

      {/* Message de succès */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher une connexion..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Liste des connexions */}
      <Card>
        {loading && !showCreateModal && !showEditModal && !showDeleteModal && !showOperationsModal && !showWsdlModal && !showTestModal && !showExecuteModal ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <DataGrid
            data={filteredConnections}
            columns={connectionColumns}
            emptyMessage="Aucune connexion SOAP trouvée"
          />
        )}
      </Card>

      {/* Modal de création de connexion SOAP */}
      {showCreateModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Créer une nouvelle connexion SOAP
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              {/* WSDL Parser */}
              <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                <h3 className="text-md font-medium text-gray-900 dark:text-white mb-2">
                  Analyser un WSDL
                </h3>
                <div className="flex space-x-2">
                  <input
                    type="text"
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="URL du WSDL"
                    value={wsdlParseUrl}
                    onChange={(e) => setWsdlParseUrl(e.target.value)}
                  />
                  <Button
                    variant="primary"
                    icon={Download}
                    onClick={handleParseWsdl}
                    loading={loading}
                    disabled={!wsdlParseUrl}
                  >
                    Analyser
                  </Button>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.name}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={2}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      URL du WSDL*
                    </label>
                    <input
                      type="text"
                      name="wsdlUrl"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.wsdlUrl}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      URL du Endpoint*
                    </label>
                    <input
                      type="text"
                      name="endpointUrl"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.endpointUrl}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version SOAP
                    </label>
                    <select
                      name="soapVersion"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.soapVersion}
                      onChange={handleFormChange}
                    >
                      <option value="1.1">SOAP 1.1</option>
                      <option value="1.2">SOAP 1.2</option>
                    </select>
                  </div>
                </div>
                
                {/* Informations avancées */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Namespace URI
                    </label>
                    <input
                      type="text"
                      name="namespaceUri"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.namespaceUri}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du Service
                    </label>
                    <input
                      type="text"
                      name="serviceName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.serviceName}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du Port
                    </label>
                    <input
                      type="text"
                      name="portName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.portName}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="mtomEnabled"
                      name="mtomEnabled"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={formData.mtomEnabled}
                      onChange={handleFormChange}
                    />
                    <label htmlFor="mtomEnabled" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Activer MTOM (Message Transmission Optimization Mechanism)
                    </label>
                  </div>
                  <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                    <div className="flex items-center space-x-2 mb-2">
                      <input
                        type="checkbox"
                        id="useWsSecurity"
                        name="useWsSecurity"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.useWsSecurity}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="useWsSecurity" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Utiliser WS-Security
                      </label>
                    </div>
                    
                    {formData.useWsSecurity && (
                      <div className="space-y-2 pl-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Type de sécurité
                          </label>
                          <select
                            name="securityType"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityType}
                            onChange={handleFormChange}
                          >
                            <option value="UsernameToken">Username Token</option>
                            <option value="Timestamp">Timestamp</option>
                            <option value="Signature">Signature</option>
                            <option value="Encryption">Encryption</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Nom d'utilisateur
                          </label>
                          <input
                            type="text"
                            name="securityUsername"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityUsername}
                            onChange={handleFormChange}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Mot de passe
                          </label>
                          <input
                            type="password"
                            name="securityPassword"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityPassword}
                            onChange={handleFormChange}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Durée de validité (en secondes)
                          </label>
                          <input
                            type="text"
                            name="securityTimeToLive"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityTimeToLive}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreateConnection}
                  loading={loading}
                  disabled={!formData.name || !formData.wsdlUrl || !formData.endpointUrl}
                >
                  Créer la connexion
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'édition de connexion SOAP */}
      {showEditModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Modifier la connexion SOAP
                </h2>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowEditModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations de base */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400 cursor-not-allowed"
                      value={formData.name}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.description}
                      onChange={handleFormChange}
                      rows={2}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      URL du WSDL*
                    </label>
                    <input
                      type="text"
                      name="wsdlUrl"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.wsdlUrl}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      URL du Endpoint*
                    </label>
                    <input
                      type="text"
                      name="endpointUrl"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.endpointUrl}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version SOAP
                    </label>
                    <select
                      name="soapVersion"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.soapVersion}
                      onChange={handleFormChange}
                    >
                      <option value="1.1">SOAP 1.1</option>
                      <option value="1.2">SOAP 1.2</option>
                    </select>
                  </div>
                </div>
                
                {/* Informations avancées */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Namespace URI
                    </label>
                    <input
                      type="text"
                      name="namespaceUri"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.namespaceUri}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du Service
                    </label>
                    <input
                      type="text"
                      name="serviceName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.serviceName}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du Port
                    </label>
                    <input
                      type="text"
                      name="portName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={formData.portName}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="mtomEnabled"
                      name="mtomEnabled"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={formData.mtomEnabled}
                      onChange={handleFormChange}
                    />
                    <label htmlFor="mtomEnabled" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Activer MTOM (Message Transmission Optimization Mechanism)
                    </label>
                  </div>
                  <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                    <div className="flex items-center space-x-2 mb-2">
                      <input
                        type="checkbox"
                        id="useWsSecurityEdit"
                        name="useWsSecurity"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={formData.useWsSecurity}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="useWsSecurityEdit" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Utiliser WS-Security
                      </label>
                    </div>
                    
                    {formData.useWsSecurity && (
                      <div className="space-y-2 pl-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Type de sécurité
                          </label>
                          <select
                            name="securityType"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityType}
                            onChange={handleFormChange}
                          >
                            <option value="UsernameToken">Username Token</option>
                            <option value="Timestamp">Timestamp</option>
                            <option value="Signature">Signature</option>
                            <option value="Encryption">Encryption</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Nom d'utilisateur
                          </label>
                          <input
                            type="text"
                            name="securityUsername"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityUsername}
                            onChange={handleFormChange}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Mot de passe
                          </label>
                          <input
                            type="password"
                            name="securityPassword"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityPassword}
                            onChange={handleFormChange}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Durée de validité (en secondes)
                          </label>
                          <input
                            type="text"
                            name="securityTimeToLive"
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.securityTimeToLive}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowEditModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUpdateConnection}
                  loading={loading}
                  disabled={!formData.wsdlUrl || !formData.endpointUrl}
                >
                  Mettre à jour
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900/20">
                  <Trash2 className="h-6 w-6 text-red-600 dark:text-red-500" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  Supprimer la connexion SOAP
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Êtes-vous sûr de vouloir supprimer la connexion SOAP "{selectedConnection?.name}" ? Cette action est irréversible.
                </p>
              </div>
              
              <div className="mt-6 flex justify-center space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  onClick={handleDeleteConnection}
                  loading={loading}
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal des opérations */}
      {showOperationsModal && selectedConnection && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Opérations SOAP 
                  </h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {selectedConnection.name}
                  </p>
                </div>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowOperationsModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
              ) : (
                <DataGrid
                  data={operations}
                  columns={operationColumns}
                  emptyMessage="Aucune opération disponible"
                />
              )}
              
              <div className="mt-8 flex justify-end">
                <Button
                  variant="outline"
                  onClick={() => setShowOperationsModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de gestion WSDL */}
      {showWsdlModal && selectedConnection && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-3xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Définition WSDL
                  </h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {selectedConnection.name}
                  </p>
                </div>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowWsdlModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    URL
                  </label>
                  <input
                    type="text"
                    name="url"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={wsdlFormData.url}
                    onChange={handleWsdlFormChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Target Namespace
                  </label>
                  <input
                    type="text"
                    name="targetNamespace"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={wsdlFormData.targetNamespace}
                    onChange={handleWsdlFormChange}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du Service
                    </label>
                    <input
                      type="text"
                      name="serviceName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={wsdlFormData.serviceName}
                      onChange={handleWsdlFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Version WSDL
                    </label>
                    <input
                      type="text"
                      name="wsdlVersion"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={wsdlFormData.wsdlVersion}
                      onChange={handleWsdlFormChange}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du Port Type
                    </label>
                    <input
                      type="text"
                      name="portTypeName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={wsdlFormData.portTypeName}
                      onChange={handleWsdlFormChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Nom du Binding
                    </label>
                    <input
                      type="text"
                      name="bindingName"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={wsdlFormData.bindingName}
                      onChange={handleWsdlFormChange}
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Opérations
                  </label>
                  <div className="bg-gray-50 dark:bg-gray-700 rounded-md p-3 max-h-40 overflow-y-auto">
                    {wsdlFormData.operations && wsdlFormData.operations.length > 0 ? (
                      <ul className="space-y-1">
                        {wsdlFormData.operations.map((operation, index) => (
                          <li key={index} className="flex justify-between items-center py-1 px-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-600">
                            <span className="text-gray-700 dark:text-gray-300">{operation}</span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center text-gray-500 dark:text-gray-400 py-2">
                        Aucune opération disponible
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowWsdlModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Save}
                  onClick={handleUpdateWsdlDefinition}
                  loading={loading}
                >
                  Enregistrer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de test d'opération */}
      {showTestModal && selectedOperation && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Tester l'opération SOAP
                  </h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {selectedConnection?.name} / {selectedOperation.name}
                  </p>
                </div>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowTestModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-medium text-gray-900 dark:text-white mb-2">
                    Informations sur l'opération
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Cette opération sera testée avec ses paramètres par défaut pour vérifier la connectivité.
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-sm">
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Action SOAP:</span>
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{selectedOperation.soapAction || 'Non spécifié'}</span>
                    </div>
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Timeout:</span>
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{selectedOperation.timeout ? `${selectedOperation.timeout} ms` : 'Par défaut'}</span>
                    </div>
                  </div>
                </div>
                
                {testResult && (
                  <div className={`p-4 rounded-lg ${
                    testResult.success 
                      ? 'bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500' 
                      : 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500'
                  }`}>
                    <div className="flex items-start">
                      {testResult.success ? (
                        <CheckCircle2 className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                      ) : (
                        <XCircle className="h-5 w-5 text-red-500 mr-2 mt-0.5" />
                      )}
                      <div>
                        <h3 className={`font-medium ${
                          testResult.success 
                            ? 'text-green-700 dark:text-green-300' 
                            : 'text-red-700 dark:text-red-300'
                        }`}>
                          {testResult.success ? 'Test réussi' : 'Échec du test'}
                        </h3>
                        <p className={`text-sm ${
                          testResult.success 
                            ? 'text-green-600 dark:text-green-400' 
                            : 'text-red-600 dark:text-red-400'
                        }`}>
                          {testResult.success 
                            ? 'La connexion au service SOAP et l\'opération testée fonctionnent correctement.' 
                            : `Erreur: ${testResult.error}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowTestModal(false)}
                >
                  Fermer
                </Button>
                <Button
                  variant="primary"
                  icon={Play}
                  onClick={handleTestOperation}
                  loading={loading}
                >
                  Lancer le test
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'exécution d'opération */}
      {showExecuteModal && selectedOperation && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50 dark:bg-gray-900/80">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-3xl w-full p-6 mx-4">
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Exécuter l'opération SOAP
                  </h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {selectedConnection?.name} / {selectedOperation.name}
                  </p>
                </div>
                <button 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  onClick={() => setShowExecuteModal(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg mb-4">
                  <h3 className="font-medium text-gray-900 dark:text-white mb-2">
                    Paramètres d'opération
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                    Définissez les paramètres pour cette opération SOAP. Ajoutez autant de paramètres que nécessaire.
                  </p>
                  
                  <div className="space-y-4">
                    {/* Zone de création des paramètres */}
                    <div className="grid grid-cols-2 gap-2">
                      <input
                        type="text"
                        className="px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Nom du paramètre"
                        onChange={(e) => setNewParamKey(e.target.value)}
                        value={newParamKey}
                      />
                      <div className="flex space-x-2">
                        <input
                          type="text"
                          className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Valeur du paramètre"
                          onChange={(e) => setNewParamValue(e.target.value)}
                          value={newParamValue}
                        />
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            if (newParamKey.trim()) {
                              setExecutionParams({
                                ...executionParams,
                                [newParamKey]: newParamValue
                              });
                              setNewParamKey('');
                              setNewParamValue('');
                            }
                          }}
                        >
                          Ajouter
                        </Button>
                      </div>
                    </div>
                    
                    {/* Liste des paramètres ajoutés */}
                    {Object.keys(executionParams).length > 0 && (
                      <div className="mt-4">
                        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Paramètres définis
                        </h4>
                        <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md overflow-hidden">
                          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead className="bg-gray-50 dark:bg-gray-700">
                              <tr>
                                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                  Paramètre
                                </th>
                                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                  Valeur
                                </th>
                                <th className="px-4 py-2 w-10"></th>
                              </tr>
                            </thead>
                            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                              {Object.entries(executionParams).map(([key, value]) => (
                                <tr key={key}>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                    {key}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                    {value}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                                    <button
                                      className="text-red-500 hover:text-red-700"
                                      onClick={() => {
                                        const newParams = {...executionParams};
                                        delete newParams[key];
                                        setExecutionParams(newParams);
                                      }}
                                    >
                                      <X className="h-4 w-4" />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {executeResult && (
                  <div className={`p-4 rounded-lg ${
                    executeResult.success 
                      ? 'bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500' 
                      : 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500'
                  }`}>
                    <div className="flex items-start">
                      {executeResult.success ? (
                        <CheckCircle2 className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                      ) : (
                        <XCircle className="h-5 w-5 text-red-500 mr-2 mt-0.5" />
                      )}
                      <div>
                        <h3 className={`font-medium ${
                          executeResult.success 
                            ? 'text-green-700 dark:text-green-300' 
                            : 'text-red-700 dark:text-red-300'
                        }`}>
                          {executeResult.success ? 'Exécution réussie' : 'Échec de l\'exécution'}
                        </h3>
                        {executeResult.success ? (
                          <div className="mt-2">
                            <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-3 mt-2 max-h-40 overflow-y-auto">
                              <pre className="text-xs text-gray-800 dark:text-gray-200 overflow-x-auto">
                                {JSON.stringify(executeResult.data, null, 2)}
                              </pre>
                            </div>
                          </div>
                        ) : (
                          <p className="text-sm text-red-600 dark:text-red-400">
                            Erreur: {executeResult.error}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              <div className="mt-8 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowExecuteModal(false)}
                >
                  Fermer
                </Button>
                <Button
                  variant="primary"
                  icon={Play}
                  onClick={handleExecuteOperation}
                  loading={loading}
                >
                  Exécuter
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SoapConnectionsPage;