import React, { useState, useEffect, useMemo } from 'react';
import { 
  ArrowDownCircle, 
  ArrowUpCircle, 
  Filter, 
  RefreshCw, 
  Download, 
  Clock, 
  Search, 
  CheckCircle, 
  XCircle, 
  AlertTriangle, 
  Globe, 
  Eye, 
  Settings, 
  ChevronDown,
  GitBranch,
  Calendar,
  FileText,
  X,
  Info,
  Send,
  Package
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import DataGrid from '../../components/shared/DataGrid';
import DataFilters from '../../components/shared/DataFilters';
import apiService from '../../services/api.service';

/**
 * Composant pour afficher un tableau de tous les flux CEMAC ACH échangés
 */
const AchFluxTable = () => {
  // États pour les données et filtres
  const [fluxData, setFluxData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(30);
  const [timeLeft, setTimeLeft] = useState(refreshInterval);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [instanceHistory, setInstanceHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  
  // États des filtres
  const [filters, setFilters] = useState({
    direction: 'all',
    workflow: 'all',
    status: 'all',
    startDate: null,
    endDate: null,
    searchTerm: ''
  });
  
  // États pour le tri
  const [sortConfig, setSortConfig] = useState({
    key: 'startedAt',
    direction: 'desc'
  });
  
  // Fonction pour charger les données
  const fetchFluxData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Utiliser le même endpoint que dans le monitoring des workflows
      const response = await apiService.declarationWorkflow.getAll();
      
      if (response && response.workflows && Array.isArray(response.workflows)) {
        // Adapter les données au format attendu pour le tableau
        const formattedData = response.workflows.map(workflow => {
          // Extraire des informations clés du contexte du workflow
          const context = workflow.context || {};
          
          // Déterminer la direction du flux (entrant/sortant) en se basant sur le code du workflow
          const isIncoming = workflow.workflowCode && (
            workflow.workflowCode.includes('_ENTRANT_') || 
            workflow.workflowCode.includes('_IN_')
          );
          
          // Extraire le type de message du code ou du contexte
          let messageType = context.messageType || '';
          if (!messageType && workflow.workflowCode) {
            if (workflow.workflowCode.includes('VIREMENT')) messageType = 'PACS.008';
            else if (workflow.workflowCode.includes('CHEQUE')) messageType = 'PASC.003';
            else if (workflow.workflowCode.includes('RETOUR_FONDS')) messageType = 'PACS.004';
            else if (workflow.workflowCode.includes('INVERSION')) messageType = 'PACS.007';
            else if (workflow.workflowCode.includes('ANNULATION')) messageType = 'CAMT.056';
            else if (workflow.workflowCode.includes('STATUT')) messageType = 'PACS.002';
          }
          
          // Extraire le montant du contexte
          let amount = null;
          if (context.fichierEnteteMontant) {
            amount = context.fichierEnteteMontant;
          } else if (context.totalAmount) {
            amount = context.totalAmount;
          } else if (context.amount) {
            amount = context.amount;
          }
          
          // Extraire les informations du fichier
          const fileName = context.fichierNom || 
                         context.fileName || 
                         (context.filePath ? context.filePath.split('/').pop() : null);
          
          const filePath = context.filePath || '';
          
          // Extraire le type d'opération
          const typeOperation = context.fichierTypeOp || 
                              (context.systac1Fichier && context.systac1Fichier.typeOperation) || 
                              '';
          
          // Extraire le code d'enregistrement
          const codeEnregistrement = context.codeEnregistrement || 
                                  (context.entete && context.entete.codeEnregistrementEnum) ||
                                  '';
          
          return {
            id: workflow.id || workflow.reference,
            reference: workflow.reference,
            workflowCode: workflow.workflowCode,
            workflowName: workflow.name || workflow.workflowCode,
            direction: isIncoming ? 'incoming' : 'outgoing',
            messageType: messageType,
            status: workflow.currentState,
            statusLabel: getStatusLabel(workflow.currentState),
            initialState: workflow.initialState,
            currentState: workflow.currentState,
            finalState: workflow.finalState || false,
            completed: workflow.completed || workflow.status === 'COMPLETED',
            error: workflow.error || workflow.status === 'ERROR' || workflow.status === 'REJECTED',
            startedAt: workflow.startedAt || workflow.createdAt,
            lastUpdatedAt: workflow.lastUpdatedAt || workflow.updatedAt,
            amount: amount,
            currency: getValidCurrencyCode(context.currency || context.fichierEnteteDevise),
            sourceIdentifier: context.fichierEnteteParticipant || context.sourceIdentifier,
            destinationIdentifier: context.destinationIdentifier,
            fileName: fileName,
            filePath: filePath,
            typeOperation: typeOperation,
            codeEnregistrement: codeEnregistrement,
            messageId: context.pacs008MessageId || context.messageId,
            processingDate: context.processingDate || null,
            additionalInfo: {
              pacs008MessageId: context.pacs008MessageId,
              batchId: context.batchId,
              jmsMessageId: context.jmsMessageId,
              numberOfTransactions: context.fichierEnteteNombreTotal || context.numberOfTransactions
            },
            context: workflow.context
          };
        });
        
        setFluxData(formattedData);
        
        // Appliquer les filtres initiaux
        applyFilters(formattedData, filters);
      } else {
        setError("Format de réponse invalide pour les flux ACH");
      }
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      setError("Une erreur s'est produite lors du chargement des données.");
    } finally {
      setLoading(false);
    }
  };
  
  // Fonction pour obtenir le libellé d'un statut
  const getStatusLabel = (status) => {
    if (!status) return 'Inconnu';
    
    // Mapper les statuts spécifiques
    const statusMapping = {
      'COMPLETED': 'Terminé',
      'REJECTED': 'Rejeté',
      'INITIAL': 'Initial',
      'RECEPTION_CBS': 'Réception CBS',
      'VALIDATION_ENTREE': 'Validation entrée',
      'VERIFICATION_ELIGIBILITE': 'Vérification éligibilité',
      'VERIFICATION_SIGNATURE': 'Vérification signature',
      'VERIFICATION_COHERENCE': 'Vérification cohérence',
      'TRANSFORMATION': 'Transformation',
      'ENRICHED': 'Enrichi',
      'CONVERTED': 'Converti',
      'SIGNED': 'Signé',
      'VALIDATED': 'Validé',
      'TRANSMITTED': 'Transmis',
      'EMISSION': 'Émission',
      'EMISSION_CBS': 'Émission CBS',
      'EMISSION_PSACH': 'Émission PS-ACH'
    };
    
    return statusMapping[status] || status.replace(/_/g, ' ');
  };
  
  // Fonction pour convertir un code de devise numérique en code ISO
  const getValidCurrencyCode = (currency) => {
    if (!currency) return 'XAF';
    
    // Si c'est déjà un code alphabétique ISO valide (3 lettres), le retourner
    if (typeof currency === 'string' && currency.length === 3 && isNaN(currency)) {
      return currency;
    }
    
    // Mapper les codes numériques aux codes ISO
    const currencyMapping = {
      '709': 'XAF',  // Franc CFA BEAC
      '950': 'XAF',  // Autre code possible pour XAF
      '952': 'XOF',  // Franc CFA BCEAO
      '978': 'EUR',  // Euro
      '840': 'USD',  // Dollar US
    };
    
    return currencyMapping[currency] || 'XAF';  // Par défaut, utiliser XAF
  };
  
  // Fonction pour formater une date de traitement qui peut être au format tableau
  const formatProcessingDate = (dateValue) => {
    if (!dateValue) return null;
    
    try {
      // Si c'est déjà une chaîne de date formatée
      if (typeof dateValue === 'string') {
        return new Date(dateValue);
      }
      
      // Si c'est un tableau [année, mois, jour, heure, minute, seconde, nano]
      if (Array.isArray(dateValue) && dateValue.length >= 3) {
        const [year, month, day, hour=0, minute=0, second=0] = dateValue;
        return new Date(year, month-1, day, hour, minute, second);
      }
      
      return new Date(dateValue);
    } catch (e) {
      return null;
    }
  };
  
  // Détermine la couleur du statut
  const getStatusVariant = (status) => {
    if (!status) return 'warning';
    
    if (status === 'COMPLETED' || status === 'EMISSION' || 
        status === 'EMISSION_CBS' || status === 'EMISSION_PSACH') {
      return 'active';
    } else if (status === 'REJECTED') {
      return 'inactive';
    } else {
      return 'warning';
    }
  };
  
  // Effet d'initialisation
  useEffect(() => {
    fetchFluxData();
  }, []);
  
  // Auto-rafraîchissement
  useEffect(() => {
    let intervalId;
    
    if (autoRefresh) {
      setTimeLeft(refreshInterval);
      
      // Décompte du temps restant
      const countdownId = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            return refreshInterval;
          }
          return prev - 1;
        });
      }, 1000);
      
      // Rafraîchissement périodique
      intervalId = setInterval(() => {
        fetchFluxData();
      }, refreshInterval * 1000);
      
      return () => {
        clearInterval(intervalId);
        clearInterval(countdownId);
      };
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [autoRefresh, refreshInterval]);
  
  // Appliquer les filtres aux données
  const applyFilters = (data, currentFilters) => {
    let filtered = [...data];
    
    // Filtre par direction
    if (currentFilters.direction !== 'all') {
      filtered = filtered.filter(item => item.direction === currentFilters.direction);
    }
    
    // Filtre par workflow
    if (currentFilters.workflow !== 'all') {
      filtered = filtered.filter(item => item.workflowCode === currentFilters.workflow);
    }
    
    // Filtre par statut
    if (currentFilters.status !== 'all') {
      if (currentFilters.status === 'active') {
        filtered = filtered.filter(item => !item.completed && !item.error);
      } else if (currentFilters.status === 'completed') {
        filtered = filtered.filter(item => item.completed && !item.error);
      } else if (currentFilters.status === 'failed') {
        filtered = filtered.filter(item => item.error);
      } else {
        filtered = filtered.filter(item => item.currentState === currentFilters.status);
      }
    }
    
    // Filtre par date
    if (currentFilters.startDate) {
      const startDate = new Date(currentFilters.startDate);
      filtered = filtered.filter(item => new Date(item.startedAt) >= startDate);
    }
    
    if (currentFilters.endDate) {
      const endDate = new Date(currentFilters.endDate);
      endDate.setHours(23, 59, 59, 999); // Fin de journée
      filtered = filtered.filter(item => new Date(item.startedAt) <= endDate);
    }
    
    // Recherche textuelle
    if (currentFilters.searchTerm) {
      const searchLower = currentFilters.searchTerm.toLowerCase();
      filtered = filtered.filter(item => 
        (item.reference && item.reference.toLowerCase().includes(searchLower)) ||
        (item.workflowName && item.workflowName.toLowerCase().includes(searchLower)) ||
        (item.messageType && item.messageType.toLowerCase().includes(searchLower)) ||
        (item.sourceIdentifier && item.sourceIdentifier.toLowerCase().includes(searchLower)) ||
        (item.destinationIdentifier && item.destinationIdentifier.toLowerCase().includes(searchLower)) ||
        (item.status && item.status.toLowerCase().includes(searchLower))
      );
    }
    
    // Tri des données
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    
    setFilteredData(filtered);
  };
  
  // Effet pour appliquer les filtres quand ils changent
  useEffect(() => {
    applyFilters(fluxData, filters);
  }, [filters, sortConfig, fluxData]);
  
  // Gestionnaire pour le changement de filtre
  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  // Gestionnaire pour la réinitialisation des filtres
  const resetFilters = () => {
    setFilters({
      direction: 'all',
      workflow: 'all',
      status: 'all',
      startDate: null,
      endDate: null,
      searchTerm: ''
    });
  };
  
  // Gestionnaire pour le tri
  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };
  
  // Récupérer l'historique d'un flux
  const fetchFluxHistory = async (reference) => {
    setHistoryLoading(true);
    setError(null);
    
    try {
      // Utiliser le même endpoint que dans le monitoring des workflows
      const historyResponse = await apiService.workflow.getWorkflowHistory(reference);
      
      // Vérifier si les réponses contiennent des données
      const history = Array.isArray(historyResponse) ? historyResponse : [];
      
      setInstanceHistory(history);
    } catch (err) {
      console.error("Erreur lors du chargement de l'historique du flux:", err);
      setError("Impossible de charger l'historique du flux. Veuillez réessayer.");
      setInstanceHistory([]);
    } finally {
      setHistoryLoading(false);
    }
  };
  
  // Fonction pour afficher les détails d'un flux
  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setShowDetailsModal(true);
    fetchFluxHistory(item.reference);
  };
  
  // Extraction de listes uniques pour les filtres
  const uniqueWorkflows = useMemo(() => {
    const workflows = [...new Set(fluxData.map(item => item.workflowCode))];
    return workflows.filter(code => code).sort();
  }, [fluxData]);
  
  const uniqueStatuses = useMemo(() => {
    const statuses = [...new Set(fluxData.map(item => item.currentState))];
    return statuses.filter(status => status).sort();
  }, [fluxData]);
  
  // Définition des colonnes pour le tableau
  const columns = [
    {
      key: 'reference',
      label: 'Référence',
      sortable: true,
      render: (row) => (
        <div className="font-medium text-blue-600 dark:text-blue-400 cursor-pointer hover:underline"
             onClick={() => handleViewDetails(row)}>
          {row.reference}
        </div>
      )
    },
    {
      key: 'fileName',
      label: 'Nom du fichier',
      sortable: true,
      render: (row) => (
        <div className="truncate max-w-[200px]" title={row.fileName}>
          {row.fileName || '-'}
        </div>
      )
    },
    {
      key: 'messageType',
      label: 'Type Message',
      sortable: true,
      className: 'text-center',
      render: (row) => (
        <div className="inline-flex items-center px-2 py-1 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 rounded text-xs">
          {row.messageType || '-'}
        </div>
      )
    },
    {
      key: 'typeOperation',
      label: 'Type Op',
      sortable: true,
      className: 'text-center',
      render: (row) => (
        <span className="text-sm">{row.typeOperation || '-'}</span>
      )
    },
    {
      key: 'codeEnregistrement',
      label: 'CENR',
      sortable: true,
      className: 'text-center',
      render: (row) => (
        <span className="text-sm">{row.codeEnregistrement || '-'}</span>
      )
    },
    {
      key: 'direction',
      label: 'Direction',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          {row.direction === 'incoming' ? (
            <>
              <ArrowDownCircle size={16} className="text-indigo-500 mr-2" />
              <span>Entrant</span>
            </>
          ) : (
            <>
              <ArrowUpCircle size={16} className="text-purple-500 mr-2" />
              <span>Sortant</span>
            </>
          )}
        </div>
      )
    },
    {
      key: 'startedAt',
      label: 'Date',
      sortable: true,
      render: (row) => (
        <DateFormatter date={row.startedAt} format="short" showTime />
      )
    },
    {
      key: 'status',
      label: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge 
          status={getStatusVariant(row.currentState)} 
          customLabel={row.statusLabel} 
          showIcon={true}
        />
      )
    },
    {
      key: 'sourceIdentifier',
      label: 'Émetteur',
      sortable: true,
      className: 'text-center',
      render: (row) => (
        row.sourceIdentifier ? (
          <div className="inline-flex items-center px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded text-xs">
            <Globe size={12} className="mr-1 text-gray-500" />
            {row.sourceIdentifier}
          </div>
        ) : <span className="text-gray-400">-</span>
      )
    },
    {
      key: 'destinationIdentifier',
      label: 'Destinataire',
      sortable: true,
      className: 'text-center',
      render: (row) => (
        row.destinationIdentifier ? (
          <div className="inline-flex items-center px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded text-xs">
            <Globe size={12} className="mr-1 text-gray-500" />
            {row.destinationIdentifier}
          </div>
        ) : <span className="text-gray-400">-</span>
      )
    },
    {
      key: 'amount',
      label: 'Montant',
      sortable: true,
      className: 'text-right',
      render: (row) => (
        row.amount ? (
          <span className="font-medium">
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: getValidCurrencyCode(row.currency),
              minimumFractionDigits: 0
            }).format(row.amount)}
          </span>
        ) : <span className="text-gray-400">-</span>
      )
    },
    {
      key: 'actions',
      label: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-1">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetails(row);
            }}
          />
        </div>
      )
    }
  ];
  
  // Calcul des statistiques
  const stats = useMemo(() => {
    return {
      total: fluxData.length,
      completed: fluxData.filter(item => item.completed && !item.error).length,
      active: fluxData.filter(item => !item.completed && !item.error).length,
      failed: fluxData.filter(item => item.error).length,
      incoming: fluxData.filter(item => item.direction === 'incoming').length,
      outgoing: fluxData.filter(item => item.direction === 'outgoing').length
    };
  }, [fluxData]);
  
  // Exporter les données
  const exportData = () => {
    // Transformer les données pour l'export
    const exportData = filteredData.map(item => ({
      Référence: item.reference,
      Date: new Date(item.startedAt).toLocaleString(),
      Direction: item.direction === 'incoming' ? 'Entrant' : 'Sortant',
      'Type de Message': item.messageType,
      Workflow: item.workflowName,
      Statut: item.statusLabel,
      État: item.currentState,
      Émetteur: item.sourceIdentifier || '',
      Destinataire: item.destinationIdentifier || '',
      Montant: item.amount ? `${item.amount} ${item.currency}` : '',
      'Date de mise à jour': new Date(item.lastUpdatedAt).toLocaleString()
    }));
    
    // Convertir en CSV
    const headers = Object.keys(exportData[0]);
    const csvContent = [
      headers.join(','),
      ...exportData.map(row => 
        headers.map(header => 
          `"${row[header] ? row[header].toString().replace(/"/g, '""') : ''}"`
        ).join(',')
      )
    ].join('\n');
    
    // Télécharger le fichier
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `flux-cemac-ach-${new Date().toISOString().slice(0, 10)}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Formatter la liste des champs pour un affichage plus lisible
  const formatObjectForDisplay = (obj, excludeKeys = []) => {
    if (!obj) return null;
    
    return Object.entries(obj)
      .filter(([key]) => !excludeKeys.includes(key) && obj[key] !== undefined && obj[key] !== null)
      .map(([key, value]) => {
        // Formater la clé
        const formattedKey = key
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, str => str.toUpperCase());
        
        // Formater la valeur
        let formattedValue = value;
        if (typeof value === 'object' && value !== null) {
          formattedValue = JSON.stringify(value, null, 2);
        } else if (key.toLowerCase().includes('date') && typeof value === 'string') {
          try {
            formattedValue = new Date(value).toLocaleString();
          } catch(e) {
            // En cas d'erreur, conserver la valeur originale
          }
        }
        
        return { key: formattedKey, value: formattedValue };
      });
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Send className="h-6 w-6 mr-2 text-blue-500" />
            Tableau des flux CEMAC ACH
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Liste de tous les flux échangés via les adaptateurs ACH SYSTAC2
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline" 
            icon={RefreshCw}
            onClick={fetchFluxData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant={autoRefresh ? "primary" : "outline"}
            icon={Clock}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            {autoRefresh ? `Auto (${timeLeft}s)` : "Auto-refresh"}
          </Button>
          <Button
            variant="outline"
            icon={Filter}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filtres
          </Button>
          <Button
            variant="outline"
            icon={Download}
            onClick={exportData}
            disabled={filteredData.length === 0}
          >
            Exporter
          </Button>
        </div>
      </div>
      
      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Statistiques */}
      <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Total</p>
              <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">{stats.total}</p>
            </div>
            <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full">
              <Package className="h-5 w-5 text-blue-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Terminés</p>
              <p className="text-2xl font-bold text-green-600 dark:text-green-400">{stats.completed}</p>
            </div>
            <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-full">
              <CheckCircle className="h-5 w-5 text-green-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">En cours</p>
              <p className="text-2xl font-bold text-yellow-600 dark:text-yellow-400">{stats.active}</p>
            </div>
            <div className="p-2 bg-yellow-100 dark:bg-yellow-900/30 rounded-full">
              <Clock className="h-5 w-5 text-yellow-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Échecs</p>
              <p className="text-2xl font-bold text-red-600 dark:text-red-400">{stats.failed}</p>
            </div>
            <div className="p-2 bg-red-100 dark:bg-red-900/30 rounded-full">
              <XCircle className="h-5 w-5 text-red-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Entrants</p>
              <p className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">{stats.incoming}</p>
            </div>
            <div className="p-2 bg-indigo-100 dark:bg-indigo-900/30 rounded-full">
              <ArrowDownCircle className="h-5 w-5 text-indigo-500" />
            </div>
          </div>
        </Card>
        
        <Card className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Sortants</p>
              <p className="text-2xl font-bold text-purple-600 dark:text-purple-400">{stats.outgoing}</p>
            </div>
            <div className="p-2 bg-purple-100 dark:bg-purple-900/30 rounded-full">
              <ArrowUpCircle className="h-5 w-5 text-purple-500" />
            </div>
          </div>
        </Card>
      </div>
      
      {/* Barre de recherche */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Rechercher par référence, workflow, statut..."
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          value={filters.searchTerm}
          onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
        />
      </div>
      
      {/* Filtres avancés */}
      {showFilters && (
        <Card>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Direction
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={filters.direction}
                onChange={(e) => handleFilterChange('direction', e.target.value)}
              >
                <option value="all">Toutes les directions</option>
                <option value="incoming">Entrant</option>
                <option value="outgoing">Sortant</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Workflow
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={filters.workflow}
                onChange={(e) => handleFilterChange('workflow', e.target.value)}
              >
                <option value="all">Tous les workflows</option>
                {uniqueWorkflows.map(workflow => (
                  <option key={workflow} value={workflow}>{workflow}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Statut
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
              >
                <option value="all">Tous les statuts</option>
                <option value="active">En cours</option>
                <option value="completed">Terminés</option>
                <option value="failed">Échoués</option>
                {uniqueStatuses.map(status => (
                  <option key={status} value={status}>{getStatusLabel(status)}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Date de début
              </label>
              <input
                type="date"
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={filters.startDate || ''}
                onChange={(e) => handleFilterChange('startDate', e.target.value)}
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Date de fin
              </label>
              <input
                type="date"
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={filters.endDate || ''}
                onChange={(e) => handleFilterChange('endDate', e.target.value)}
              />
            </div>
          </div>
          
          <div className="flex justify-end gap-2 p-4 border-t border-gray-200 dark:border-gray-700">
            <Button
              variant="outline"
              onClick={resetFilters}
            >
              Réinitialiser
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowFilters(false)}
            >
              Appliquer
            </Button>
          </div>
        </Card>
      )}
      
      {/* Tableau principal */}
      <DataGrid
        data={filteredData}
        columns={columns}
        selectable={false}
        sortable={true}
        onSort={handleSort}
        loading={loading}
        emptyMessage="Aucun flux ACH trouvé"
      />
      
      {/* Modal de détails */}
      {showDetailsModal && selectedItem && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/20 backdrop-blur-sm flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl mx-auto max-w-3xl w-full p-6">
            {/* En-tête du modal */}
            <div className="flex justify-between items-center mb-6 border-b pb-4">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                <GitBranch className="h-5 w-5 mr-2 text-blue-500" />
                Détails du flux: {selectedItem.reference}
              </h3>
              <button
                onClick={() => setShowDetailsModal(false)}
                className="text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            {/* Contenu du modal */}
            <div className="space-y-6">
              {/* Information générales */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card title="Informations générales" icon={Info}>
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Référence:</span>
                      <span className="font-medium">{selectedItem.reference}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Workflow:</span>
                      <span className="font-medium">{selectedItem.workflowName}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Date:</span>
                      <DateFormatter date={selectedItem.startedAt} format="full" showTime />
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Direction:</span>
                      <div className="flex items-center">
                        {selectedItem.direction === 'incoming' ? (
                          <>
                            <ArrowDownCircle size={16} className="text-indigo-500 mr-2" />
                            <span>Entrant</span>
                          </>
                        ) : (
                          <>
                            <ArrowUpCircle size={16} className="text-purple-500 mr-2" />
                            <span>Sortant</span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Type de message:</span>
                      <span className="font-medium">{selectedItem.messageType}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Nom du fichier:</span>
                      <span className="font-medium">{selectedItem.fileName || '-'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Type opération:</span>
                      <span className="font-medium">{selectedItem.typeOperation || '-'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Code enregistrement:</span>
                      <span className="font-medium">{selectedItem.codeEnregistrement || '-'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Dernière mise à jour:</span>
                      <DateFormatter date={selectedItem.lastUpdatedAt} format="full" showTime />
                    </div>
                  </div>
                </Card>
                
                <Card title="État et traitement" icon={Settings}>
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">Statut:</span>
                      <StatusBadge 
                        status={getStatusVariant(selectedItem.currentState)} 
                        customLabel={selectedItem.statusLabel} 
                        showIcon={true}
                      />
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">État initial:</span>
                      <span className="font-medium">{getStatusLabel(selectedItem.initialState)}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-500 dark:text-gray-400">État actuel:</span>
                      <span className="font-medium">{getStatusLabel(selectedItem.currentState)}</span>
                    </div>
                    {selectedItem.error && (
                      <div className="pt-2 border-t border-gray-200 dark:border-gray-700">
                        <span className="text-gray-500 dark:text-gray-400">Erreur:</span>
                        <div className="mt-1 p-2 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded">
                          {selectedItem.context?.errorMessage || 
                           selectedItem.context?.validationError || 
                           selectedItem.context?.transmissionError || 
                           "Une erreur est survenue"}
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              </div>
              
              {/* Détails du message */}
              <Card title="Détails du message" icon={FileText}>
                <div className="space-y-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Émetteur:</span>
                      <div className="mt-1 flex items-center space-x-2">
                        <Globe size={16} className="text-gray-500" />
                        <span className="font-medium">{selectedItem.sourceIdentifier || '-'}</span>
                      </div>
                    </div>
                    <div>
                      <span className="text-gray-500 dark:text-gray-400">Destinataire:</span>
                      <div className="mt-1 flex items-center space-x-2">
                        <Globe size={16} className="text-gray-500" />
                        <span className="font-medium">{selectedItem.destinationIdentifier || '-'}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-3 border-t border-gray-200 dark:border-gray-700">
                    {selectedItem.messageId && (
                      <div>
                        <span className="text-gray-500 dark:text-gray-400">ID du message:</span>
                        <div className="mt-1 font-medium">{selectedItem.messageId}</div>
                      </div>
                    )}
                    {selectedItem.additionalInfo?.batchId && (
                      <div>
                        <span className="text-gray-500 dark:text-gray-400">ID du lot:</span>
                        <div className="mt-1 font-medium">{selectedItem.additionalInfo.batchId}</div>
                      </div>
                    )}
                  </div>
                  
                  <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
                    <span className="text-gray-500 dark:text-gray-400">Chemin du fichier:</span>
                    <div className="mt-1 text-sm bg-gray-50 dark:bg-gray-700 p-2 rounded-lg break-all">
                      {selectedItem.filePath || '-'}
                    </div>
                  </div>
                  
                  {/* Montant si présent */}
                  {selectedItem.amount && (
                    <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
                      <span className="text-gray-500 dark:text-gray-400">Montant:</span>
                      <div className="mt-1 text-xl font-bold">
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: getValidCurrencyCode(selectedItem.currency),
                          minimumFractionDigits: 0
                        }).format(selectedItem.amount)}
                      </div>
                    </div>
                  )}
                  
                  {/* Nombre de transactions si présent */}
                  {selectedItem.additionalInfo?.numberOfTransactions && (
                    <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
                      <span className="text-gray-500 dark:text-gray-400">Nombre de transactions:</span>
                      <div className="mt-1 font-medium">{selectedItem.additionalInfo.numberOfTransactions}</div>
                    </div>
                  )}
                  
                  {/* Informations contextuelles significatives */}
                  {selectedItem.context && (
                    <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
                      <h4 className="font-medium text-gray-900 dark:text-white mb-2">Données contextuelles</h4>
                      
                      <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg max-h-64 overflow-y-auto">
                        <div className="space-y-2">
                          {formatObjectForDisplay(selectedItem.context, ['errorMessage', 'validationError', 'transmissionError'])
                            .slice(0, 10) // Limiter le nombre d'éléments affichés
                            .map((item, index) => (
                              <div key={index} className="flex justify-between">
                                <span className="text-gray-600 dark:text-gray-400 text-sm">{item.key}:</span>
                                <span className="text-gray-900 dark:text-gray-200 text-sm font-medium ml-2">
                                  {typeof item.value === 'string' && item.value.length > 50 
                                    ? item.value.substring(0, 50) + '...' 
                                    : String(item.value)}
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
              
              {/* Historique des états */}
              <Card title="Historique des états" icon={Clock}>
                {historyLoading ? (
                  <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  </div>
                ) : instanceHistory.length === 0 ? (
                  <p className="text-gray-500 dark:text-gray-400 py-4">
                    Aucun historique disponible pour ce flux.
                  </p>
                ) : (
                  <div className="space-y-2 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
                    {instanceHistory.map((entry, index) => (
                      <div key={index} className="relative">
                        <div className="absolute -left-[9px] mt-1.5">
                          <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                        </div>
                        <div className="pl-4 pb-4">
                          <p className="text-sm font-medium text-gray-900 dark:text-white">
                            {getStatusLabel(entry.fromState)} &rarr; {getStatusLabel(entry.toState)}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            <DateFormatter date={entry.transitionDate || entry.timestamp} format="full" showTime /> | Événement: {entry.event}
                          </p>
                          {(entry.comment || entry.contextSnapshot?.comment) && (
                            <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                              {entry.comment || entry.contextSnapshot?.comment}
                            </p>
                          )}
                          {(entry.initiatedBy || entry.user) && (
                            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                              Utilisateur: {entry.initiatedBy || entry.user}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Card>
              
              {/* Pied du modal */}
              <div className="flex justify-end space-x-3 pt-4 border-t border-gray-200 dark:border-gray-700">
                <Button
                  variant="outline"
                  icon={RefreshCw}
                  onClick={() => {
                    fetchFluxData();
                    setShowDetailsModal(false);
                  }}
                >
                  Actualiser et fermer
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setShowDetailsModal(false)}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AchFluxTable;