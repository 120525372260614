import React, { useState, useEffect } from 'react';
import {
  Building,
  Plus,
  Edit,
  Trash2,
  Search,
  RefreshCw,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  Mail,
  Phone,
  Globe,
  Clock,
  DollarSign,
  Calendar,
  Map,
  CheckSquare,
  Eye,
  Settings
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const OrganizationsManagement = () => {
  // États de données
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  
  // États pour les formulaires
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create', 'edit' ou 'view'
  const [currentStep, setCurrentStep] = useState(1); // Pour formulaire multi-étapes
  const [organizationForm, setOrganizationForm] = useState({
    code: '',
    name: '',
    shortName: '',
    description: '',
    type: '',
    registrationNumber: '',
    license: '',
    email: '',
    phone: '',
    address: '',
    timezone: 'UTC',
    locale: 'fr-FR',
    currency: 'XAF',
    multiFactorEnabled: false,
    sessionTimeout: 30,
    passwordExpiryDays: 90,
    allowWeekendOperations: false,
    maxTransactionAmount: 0,
    status: 'PENDING',
    isActive: false
  });
  
  // Liste des types d'organisations
  const organizationTypes = [
    'BANK',
    'FINTECH',
    'GOVERNMENT',
    'CORPORATE',
    'NGO',
    'OTHER'
  ];
  
  // Liste des fuseaux horaires
  const timezones = [
    'UTC',
    'Africa/Douala',
    'Africa/Lagos',
    'Africa/Kinshasa',
    'Africa/Libreville',
    'Africa/Brazzaville',
    'Africa/Bangui',
    'Europe/Paris'
  ];
  
  // Liste des devises
  const currencies = [
    { code: 'XAF', name: 'Franc CFA BEAC' },
    { code: 'EUR', name: 'Euro' },
    { code: 'USD', name: 'Dollar américain' }
  ];
  
  // Liste des locales
  const locales = [
    { code: 'fr-FR', name: 'Français (France)' },
    { code: 'en-US', name: 'Anglais (États-Unis)' },
    { code: 'fr-CM', name: 'Français (Cameroun)' },
    { code: 'en-CM', name: 'Anglais (Cameroun)' }
  ];
  
  // Chargement initial des organisations
  useEffect(() => {
    fetchOrganizations();
  }, []);
  
  // Fonction pour récupérer les organisations
  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const organizationsData = await apiService.security.organizations.getAll();
      setOrganizations(organizationsData || []);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des organisations:', err);
      setError('Impossible de charger les organisations. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrage des organisations basé sur la recherche et le statut
  const filteredOrganizations = organizations.filter(org => {
    // Filtrer par statut
    if (statusFilter !== 'all' && org.status !== statusFilter) {
      return false;
    }
    
    // Filtrer par recherche
    if (searchTerm) {
      const searchableValues = [
        org.code,
        org.name,
        org.shortName,
        org.email,
        org.phone,
        org.registrationNumber
      ].map(val => (val || '').toLowerCase());
      
      return searchableValues.some(val => val.includes(searchTerm.toLowerCase()));
    }
    
    return true;
  });
  
  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'code',
      header: 'Code',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 bg-gray-200 dark:bg-gray-700 rounded-full flex items-center justify-center text-gray-700 dark:text-gray-300">
            {row.code?.substring(0, 2) || 'OR'}
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900 dark:text-white">
              {row.code}
            </div>
            {row.shortName && (
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {row.shortName}
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      key: 'name',
      header: 'Nom',
      sortable: true,
      render: (row) => (
        <div className="font-medium text-gray-900 dark:text-white">
          {row.name}
        </div>
      )
    },
    {
      key: 'type',
      header: 'Type',
      sortable: true,
      render: (row) => (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300">
          {row.type}
        </span>
      )
    },
    {
      key: 'email',
      header: 'Contact',
      render: (row) => (
        <div className="space-y-1">
          {row.email && (
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
              <Mail className="h-4 w-4 mr-1" />
              {row.email}
            </div>
          )}
          {row.phone && (
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
              <Phone className="h-4 w-4 mr-1" />
              {row.phone}
            </div>
          )}
        </div>
      )
    },
    {
      key: 'status',
      header: 'Statut',
      sortable: true,
      render: (row) => {
        let status;
        switch (row.status) {
          case 'ACTIVE':
            status = 'active';
            break;
          case 'PENDING':
            status = 'warning';
            break;
          case 'SUSPENDED':
            status = 'inactive';
            break;
          case 'BLOCKED':
            status = 'danger';
            break;
          default:
            status = 'inactive';
        }
        
        return (
          <StatusBadge
            status={status}
            customLabel={row.status}
          />
        );
      }
    },
    {
      key: 'createdAt',
      header: 'Créé le',
      sortable: true,
      render: (row) => (
        <DateFormatter date={row.createdAt} format="full" />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            onClick={() => handleViewOrganization(row)}
          />
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => handleEditOrganization(row)}
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => handleDeleteOrganization(row.id)}
          />
        </div>
      )
    }
  ];
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };
  
  const handleCreateOrganization = () => {
    setOrganizationForm({
      code: '',
      name: '',
      shortName: '',
      description: '',
      type: '',
      registrationNumber: '',
      license: '',
      email: '',
      phone: '',
      address: '',
      timezone: 'UTC',
      locale: 'fr-FR',
      currency: 'XAF',
      multiFactorEnabled: false,
      sessionTimeout: 30,
      passwordExpiryDays: 90,
      allowWeekendOperations: false,
      maxTransactionAmount: 0,
      status: 'PENDING',
      isActive: false
    });
    setFormMode('create');
    setCurrentStep(1);
    setShowOrganizationModal(true);
  };
  
  const handleViewOrganization = (org) => {
    setOrganizationForm({
      ...org
    });
    setFormMode('view');
    setShowOrganizationModal(true);
  };
  
  const handleEditOrganization = (org) => {
    setOrganizationForm({
      ...org
    });
    setFormMode('edit');
    setCurrentStep(1);
    setShowOrganizationModal(true);
  };
  
  const handleFormChange = (field, value) => {
    setOrganizationForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleNextStep = () => {
    setCurrentStep(prev => prev + 1);
  };
  
  const handlePrevStep = () => {
    setCurrentStep(prev => prev - 1);
  };
  
  const handleSubmitOrganization = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      // Nettoyer le formulaire pour l'API
      const formData = { ...organizationForm };
      
      // Convertir maxTransactionAmount en valeur numérique
      if (typeof formData.maxTransactionAmount === 'string') {
        formData.maxTransactionAmount = parseFloat(formData.maxTransactionAmount) || 0;
      }
      
      let response;
      
      if (formMode === 'create') {
        response = await apiService.security.organizations.create(formData);
        setSuccessMessage('Organisation créée avec succès');
      } else if (formMode === 'edit') {
        const { id, ...updateData } = formData;
        response = await apiService.security.organizations.update(id, updateData);
        setSuccessMessage('Organisation mise à jour avec succès');
      }
      
      // Actualiser la liste des organisations
      await fetchOrganizations();
      
      // Fermer le modal
      setShowOrganizationModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleUpdateStatus = async (id, newStatus) => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.security.organizations.updateStatus(id, newStatus);
      
      // Actualiser la liste des organisations
      await fetchOrganizations();
      
      setSuccessMessage(`Statut de l'organisation mis à jour: ${newStatus}`);
      
      // Fermer le modal si ouvert
      setShowOrganizationModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la mise à jour du statut:', err);
      setError(err.message || 'Impossible de mettre à jour le statut.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteOrganization = async (organizationId) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette organisation ?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.security.organizations.delete(organizationId);
      
      // Actualiser la liste des organisations
      await fetchOrganizations();
      
      setSuccessMessage('Organisation supprimée avec succès');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError(err.message || 'Impossible de supprimer l\'organisation.');
    } finally {
      setLoading(false);
    }
  };
  
  const refreshData = async () => {
    await fetchOrganizations();
  };
  
  // Rendu du formulaire par étapes
  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-4">
            <h4 className="text-lg font-medium text-gray-900 dark:text-white">
              Informations générales
            </h4>
            
            <div className="grid grid-cols-1md:grid-cols-2 gap-4">
              {/* Code */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Code de l'organisation *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.code}
                  onChange={(e) => handleFormChange('code', e.target.value)}
                  required
                  disabled={formMode === 'view' || formMode === 'edit'}
                  placeholder="Ex: ACME001"
                />
                {(formMode === 'create') && (
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Code unique pour l'organisation (3-10 caractères, majuscules et chiffres)
                  </p>
                )}
              </div>
              
              {/* Nom */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nom complet *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.name}
                  onChange={(e) => handleFormChange('name', e.target.value)}
                  required
                  disabled={formMode === 'view'}
                  placeholder="Ex: Acme Corporation"
                />
              </div>
              
              {/* Nom court */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nom court
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.shortName}
                  onChange={(e) => handleFormChange('shortName', e.target.value)}
                  disabled={formMode === 'view'}
                  placeholder="Ex: ACME"
                />
              </div>
              
              {/* Type */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Type d'organisation *
                </label>
                <select
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.type}
                  onChange={(e) => handleFormChange('type', e.target.value)}
                  required
                  disabled={formMode === 'view'}
                >
                  <option value="">Sélectionner un type</option>
                  {organizationTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              
              {/* Numéro d'enregistrement */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Numéro d'enregistrement *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.registrationNumber}
                  onChange={(e) => handleFormChange('registrationNumber', e.target.value)}
                  required
                  disabled={formMode === 'view'}
                  placeholder="Ex: RCCM/123456"
                />
              </div>
              
              {/* Licence */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Licence
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.license}
                  onChange={(e) => handleFormChange('license', e.target.value)}
                  disabled={formMode === 'view'}
                  placeholder="Ex: LIC-12345"
                />
              </div>
              
              {/* Description */}
              <div className="col-span-1 md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Description
                </label>
                <textarea
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.description}
                  onChange={(e) => handleFormChange('description', e.target.value)}
                  rows={3}
                  disabled={formMode === 'view'}
                  placeholder="Description de l'organisation..."
                ></textarea>
              </div>
            </div>
          </div>
        );
      
      case 2:
        return (
          <div className="space-y-4">
            <h4 className="text-lg font-medium text-gray-900 dark:text-white">
              Coordonnées
            </h4>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Email */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Adresse email *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={organizationForm.email}
                    onChange={(e) => handleFormChange('email', e.target.value)}
                    required
                    disabled={formMode === 'view'}
                    placeholder="contact@organisation.com"
                  />
                </div>
              </div>
              
              {/* Téléphone */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Téléphone
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Phone className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="tel"
                    className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={organizationForm.phone}
                    onChange={(e) => handleFormChange('phone', e.target.value)}
                    disabled={formMode === 'view'}
                    placeholder="+237 123 456 789"
                  />
                </div>
              </div>
              
              {/* Adresse */}
              <div className="col-span-1 md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Adresse
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Map className="h-5 w-5 text-gray-400" />
                  </div>
                  <textarea
                    className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={organizationForm.address}
                    onChange={(e) => handleFormChange('address', e.target.value)}
                    rows={2}
                    disabled={formMode === 'view'}
                    placeholder="Adresse complète..."
                  ></textarea>
                </div>
              </div>
              
              {/* Fuseau horaire */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Fuseau horaire
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Clock className="h-5 w-5 text-gray-400" />
                  </div>
                  <select
                    className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={organizationForm.timezone}
                    onChange={(e) => handleFormChange('timezone', e.target.value)}
                    disabled={formMode === 'view'}
                  >
                    {timezones.map(tz => (
                      <option key={tz} value={tz}>{tz}</option>
                    ))}
                  </select>
                </div>
              </div>
              
              {/* Locale */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Locale
                </label>
                <select
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.locale}
                  onChange={(e) => handleFormChange('locale', e.target.value)}
                  disabled={formMode === 'view'}
                >
                  {locales.map(locale => (
                    <option key={locale.code} value={locale.code}>{locale.name}</option>
                  ))}
                </select>
              </div>
              
              {/* Devise */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Devise
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <select
                    className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={organizationForm.currency}
                    onChange={(e) => handleFormChange('currency', e.target.value)}
                    disabled={formMode === 'view'}
                  >
                    {currencies.map(currency => (
                      <option key={currency.code} value={currency.code}>
                        {currency.code} - {currency.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              
              {/* Statut */}
              {formMode === 'view' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Statut
                  </label>
                  <div className="mt-1">
                    <StatusBadge
                      status={
                        organizationForm.status === 'ACTIVE' ? 'active' :
                        organizationForm.status === 'PENDING' ? 'warning' :
                        'inactive'
                      }
                      customLabel={organizationForm.status}
                      size="lg"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      
      case 3:
        return (
          <div className="space-y-4">
            <h4 className="text-lg font-medium text-gray-900 dark:text-white">
              Paramètres avancés
            </h4>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Délai d'expiration de session */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Délai d'expiration de session (minutes)
                </label>
                <input
                  type="number"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.sessionTimeout}
                  onChange={(e) => handleFormChange('sessionTimeout', parseInt(e.target.value) || 30)}
                  min="5"
                  max="240"
                  disabled={formMode === 'view'}
                />
              </div>
              
              {/* Durée de validité du mot de passe */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Durée de validité du mot de passe (jours)
                </label>
                <input
                  type="number"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={organizationForm.passwordExpiryDays}
                  onChange={(e) => handleFormChange('passwordExpiryDays', parseInt(e.target.value) || 90)}
                  min="30"
                  max="365"
                  disabled={formMode === 'view'}
                />
              </div>
              
              {/* Montant maximum de transaction */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Montant maximum de transaction
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    className="block w-full pl-10 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={organizationForm.maxTransactionAmount}
                    onChange={(e) => handleFormChange('maxTransactionAmount', parseFloat(e.target.value) || 0)}
                    min="0"
                    step="0.01"
                    disabled={formMode === 'view'}
                  />
                </div>
                <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                  0 = Pas de limite
                </p>
              </div>
              
              {/* Options avancées */}
              <div className="col-span-1 md:col-span-2 space-y-2">
                <div className="flex items-center">
                  <input
                    id="multiFactorEnabled"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={organizationForm.multiFactorEnabled}
                    onChange={(e) => handleFormChange('multiFactorEnabled', e.target.checked)}
                    disabled={formMode === 'view'}
                  />
                  <label htmlFor="multiFactorEnabled" className="ml-2 block text-sm text-gray-900 dark:text-white">
                    Authentification à deux facteurs obligatoire
                  </label>
                </div>
                
                <div className="flex items-center">
                  <input
                    id="allowWeekendOperations"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={organizationForm.allowWeekendOperations}
                    onChange={(e) => handleFormChange('allowWeekendOperations', e.target.checked)}
                    disabled={formMode === 'view'}
                  />
                  <label htmlFor="allowWeekendOperations" className="ml-2 block text-sm text-gray-900 dark:text-white">
                    Autoriser les opérations le weekend
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Building className="h-6 w-6 mr-2 text-green-500" />
            Gestion des organisations
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Créez et configurez les organisations utilisant la plateforme
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={Plus}
            onClick={handleCreateOrganization}
          >
            Nouvelle organisation
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Filtres et recherche */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/3">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Statut
            </label>
            <select
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="all">Tous les statuts</option>
              <option value="ACTIVE">Actif</option>
              <option value="PENDING">En attente</option>
              <option value="SUSPENDED">Suspendu</option>
              <option value="BLOCKED">Bloqué</option>
            </select>
          </div>
          
          <div className="flex-1 relative">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Recherche
            </label>
            <div className="relative">
              <input
                type="text"
                className="w-full p-2 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                placeholder="Rechercher par code, nom, email..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>
      
      {/* Liste des organisations */}
      <Card>
        <DataGrid
          data={filteredOrganizations}
          columns={columns}
          loading={loading}
          emptyMessage="Aucune organisation trouvée."
        />
      </Card>
      
      {/* Modal de création/édition/visualisation */}
      {showOrganizationModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-4xl overflow-hidden">
            <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 px-6 py-4 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {formMode === 'create' 
                  ? 'Nouvelle organisation' 
                  : formMode === 'edit' 
                    ? 'Modifier l\'organisation' 
                    : 'Détails de l\'organisation'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowOrganizationModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitOrganization} className="p-6">
              {/* Étapes du formulaire */}
              {formMode !== 'view' && (
                <div className="mb-6">
                  <div className="flex items-center justify-between mb-4">
                    <div 
                      className={`flex items-center ${currentStep >= 1 ? 'text-blue-600' : 'text-gray-400'}`}
                      onClick={() => currentStep > 1 && setCurrentStep(1)}
                    >
                      <div className={`flex items-center justify-center h-8 w-8 rounded-full ${
                        currentStep >= 1 ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-400'
                      }`}>
                        1
                      </div>
                      <span className="ml-2 font-medium">Informations</span>
                    </div>
                    <div className="h-0.5 w-12 bg-gray-200"></div>
                    <div 
                      className={`flex items-center ${currentStep >= 2 ? 'text-blue-600' : 'text-gray-400'}`}
                      onClick={() => currentStep > 2 && setCurrentStep(2)}
                    >
                      <div className={`flex items-center justify-center h-8 w-8 rounded-full ${
                        currentStep >= 2 ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-400'
                      }`}>
                        2
                      </div>
                      <span className="ml-2 font-medium">Coordonnées</span>
                    </div>
                    <div className="h-0.5 w-12 bg-gray-200"></div>
                    <div 
                      className={`flex items-center ${currentStep >= 3 ? 'text-blue-600' : 'text-gray-400'}`}
                      onClick={() => currentStep > 3 && setCurrentStep(3)}
                    >
                      <div className={`flex items-center justify-center h-8 w-8 rounded-full ${
                        currentStep >= 3 ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-400'
                      }`}>
                        3
                      </div>
                      <span className="ml-2 font-medium">Paramètres</span>
                    </div>
                  </div>
                </div>
              )}
              
              {/* Contenu du formulaire */}
              <div className="max-h-[60vh] overflow-y-auto pr-2">
                {renderFormStep()}
              </div>
              
              {/* Actions pour le mode visualisation */}
              {formMode === 'view' && (
                <div className="flex flex-wrap justify-end gap-2 pt-6 border-t border-gray-200 dark:border-gray-700 mt-6">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => setShowOrganizationModal(false)}
                  >
                    Fermer
                  </Button>
                  <Button
                    variant="primary"
                    icon={Edit}
                    type="button"
                    onClick={() => {
                      setFormMode('edit');
                      setCurrentStep(1);
                    }}
                  >
                    Modifier
                  </Button>
                  {organizationForm.status === 'PENDING' && (
                    <Button
                      variant="success"
                      icon={CheckSquare}
                      type="button"
                      onClick={() => handleUpdateStatus(organizationForm.id, 'ACTIVE')}
                    >
                      Activer
                    </Button>
                  )}
                  {organizationForm.status === 'ACTIVE' && (
                    <Button
                      variant="warning"
                      icon={Settings}
                      type="button"
                      onClick={() => handleUpdateStatus(organizationForm.id, 'SUSPENDED')}
                    >
                      Suspendre
                    </Button>
                  )}
                </div>
              )}
              
              {/* Navigation entre les étapes */}
              {formMode !== 'view' && (
                <div className="flex justify-between pt-6 border-t border-gray-200 dark:border-gray-700 mt-6">
                  <div>
                    {currentStep > 1 && (
                      <Button
                        variant="outline"
                        type="button"
                        onClick={handlePrevStep}
                      >
                        Précédent
                      </Button>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      variant="outline"
                      type="button"
                      onClick={() => setShowOrganizationModal(false)}
                    >
                      Annuler
                    </Button>
                    {currentStep < 3 ? (
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleNextStep}
                      >
                        Suivant
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        icon={Save}
                        loading={loading}
                      >
                        {formMode === 'create' ? 'Créer' : 'Enregistrer'}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizationsManagement;