import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ChevronRight,
  Home,
  FileText,
  Settings,
  Users,
  Database,
  Package,
  Monitor,
  Search,
  Menu,
  X,
  Info,
  Circle,
  Command,
  CreditCard,
  BarChart2,
  Bell,
  Shield,
  ListChecks,
  FolderOpen,
  Folder,
  File,
  Plus,
  Minus,
  ChevronDown,
  ChevronUp,
  Layers,
  Activity,
  Zap,
  Code,
  Grid,
  Sliders
} from 'lucide-react';

// Palette de couleurs par catégorie de module
const moduleColors = {
  asycuda: { light: '#6366F1', dark: '#818CF8', gradient: 'from-indigo-500 to-indigo-600' }, // Indigo
  monitoring: { light: '#EC4899', dark: '#F472B6', gradient: 'from-pink-500 to-pink-600' }, // Pink
  security: { light: '#10B981', dark: '#34D399', gradient: 'from-emerald-500 to-emerald-600' }, // Emerald
  audit: { light: '#F59E0B', dark: '#FBBF24', gradient: 'from-amber-500 to-amber-600' }, // Amber
  notification: { light: '#3B82F6', dark: '#60A5FA', gradient: 'from-blue-500 to-blue-600' }, // Blue
  configuration: { light: '#8B5CF6', dark: '#A78BFA', gradient: 'from-violet-500 to-violet-600' }, // Violet
  editic: { light: '#EF4444', dark: '#F87171', gradient: 'from-red-500 to-red-600' }, // Red
  license: { light: '#14B8A6', dark: '#2DD4BF', gradient: 'from-teal-500 to-teal-600' }, // Teal
  scheduler: { light: '#F97316', dark: '#FB923C', gradient: 'from-orange-500 to-orange-600' }, // Orange
  workflow: { light: '#0EA5E9', dark: '#38BDF8', gradient: 'from-sky-500 to-sky-600' }, // Sky
  utilisateurs: { light: '#06B6D4', dark: '#22D3EE', gradient: 'from-cyan-500 to-cyan-600' }, // Cyan
  connector: { light: '#8B5CF6', dark: '#A78BFA', gradient: 'from-purple-500 to-purple-600' }, // Purple
  default: { light: '#6B7280', dark: '#9CA3AF', gradient: 'from-gray-500 to-gray-600' } // Gray
};

// Fonction pour obtenir la couleur d'un module
const getModuleColor = (moduleId) => {
  const id = moduleId.toLowerCase();
  
  // Vérifier les connecteurs spéciaux
  if (id.includes('connector')) {
    return moduleColors.connector;
  }
  
  // Chercher une correspondance directe
  for (const key in moduleColors) {
    if (id.includes(key)) {
      return moduleColors[key];
    }
  }
  
  // Couleur par défaut
  return moduleColors.default;
};

// Configuration des icônes par catégorie de module
const moduleIcons = {
  asycuda: CreditCard,
  monitoring: Activity,
  security: Shield,
  audit: ListChecks,
  notification: Bell,
  analytics: BarChart2,
  documents: FileText,
  settings: Settings,
  configuration: Sliders,
  editic: FileText,
  license: Shield,
  scheduler: Bell,
  connector: Database,
  workflow: Layers,
  utilisateurs: Users,
  default: Package
};

// Fonction pour obtenir l'icône d'un module
const getModuleIcon = (moduleId) => {
  const id = moduleId.toLowerCase();
  
  // Vérifier les connecteurs spéciaux
  if (id.includes('connector')) {
    return moduleIcons.connector;
  }
  
  // Chercher une correspondance directe
  for (const key in moduleIcons) {
    if (id.includes(key)) {
      return moduleIcons[key];
    }
  }
  
  // Icône par défaut
  return moduleIcons.default;
};

const TreeNode = ({ 
  item, 
  level = 0, 
  basePath = '', 
  expandedItems, 
  onToggle, 
  activeItem, 
  isLastChild = false 
}) => {
  const hasChildren = item.children && item.children.length > 0;
  const isExpanded = expandedItems[item.id];
  const isLeaf = !hasChildren;
  const itemPath = `${basePath}/${item.id}`.toLowerCase();
  const isActive = activeItem === itemPath;
  
  // Obtenir l'icône du module
  const ModuleIcon = getModuleIcon(item.id);
  
  // Obtenir la couleur du module
  const moduleColor = getModuleColor(item.id);

  // Déterminer les classes de couleur en fonction du niveau
  let colorClasses = '';
  if (level === 0) {
    colorClasses = isActive 
      ? `bg-gradient-to-r ${moduleColor.gradient} text-white font-medium shadow-sm` 
      : `hover:bg-gray-50 dark:hover:bg-gray-800/50 text-gray-700 dark:text-gray-300`;
  } else {
    colorClasses = isActive 
      ? `bg-${moduleColor.light}/10 dark:bg-${moduleColor.dark}/10 text-${moduleColor.light} dark:text-${moduleColor.dark} font-medium` 
      : `hover:bg-gray-50 dark:hover:bg-gray-800/50 text-gray-600 dark:text-gray-400`;
  }
  
  // Classes pour l'icône
  const iconClasses = level === 0 
    ? (isActive ? 'text-white' : `text-${moduleColor.light} dark:text-${moduleColor.dark}`) 
    : (isActive ? `text-${moduleColor.light} dark:text-${moduleColor.dark}` : 'text-gray-400');

  return (
    <div className="relative mt-1">
      {/* Ligne verticale de connexion */}
      {level > 0 && !isLastChild && (
        <div 
          className={`absolute left-[19px] top-8 w-px h-full bg-gray-200 dark:bg-gray-700`}
        />
      )}

      <div className="relative">
        {/* Ligne horizontale de connexion */}
        {level > 0 && (
          <div className="absolute left-[19px] top-[50%] w-4 h-px bg-gray-200 dark:bg-gray-700" />
        )}

        <div
          className={`
            flex items-center px-3 py-2.5 text-sm rounded-lg cursor-pointer transition-all duration-200
            ${level > 0 ? 'ml-' + (level * 6) : ''}
            ${colorClasses}
            ${level === 0 ? 'rounded-md' : 'rounded-md'}
            ${level === 0 && !isActive ? 'hover:translate-x-1' : ''}
          `}
        >
          {/* Icône d'expansion pour les dossiers */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (hasChildren) {
                onToggle(item.id);
              }
            }}
            className={`
              w-6 h-6 flex items-center justify-center
              ${hasChildren ? 'visible' : 'invisible'}
            `}
          >
            {hasChildren && (
              isExpanded ? (
                <Minus size={14} className={isActive ? (level === 0 ? 'text-white' : iconClasses) : 'text-gray-400'} />
              ) : (
                <Plus size={14} className={isActive ? (level === 0 ? 'text-white' : iconClasses) : 'text-gray-400'} />
              )
            )}
          </button>

          {/* Icône de l'item */}
          {level === 0 ? (
            <div className={`mr-2 flex items-center justify-center ${isActive ? '' : 'bg-white dark:bg-gray-800 p-1 rounded-md shadow-sm'}`}>
              <ModuleIcon 
                size={18} 
                className={isActive ? 'text-white' : iconClasses}
              />
            </div>
          ) : (
            <div className="w-6 h-6 flex items-center justify-center">
              {hasChildren ? (
                isExpanded ? (
                  <FolderOpen size={18} className={isActive ? iconClasses : 'text-gray-400'} />
                ) : (
                  <Folder size={18} className={isActive ? iconClasses : 'text-gray-400'} />
                )
              ) : (
                <File size={18} className={isActive ? iconClasses : 'text-gray-400'} />
              )}
            </div>
          )}

          {/* Label de l'item */}
          <span 
            className="ml-2 flex-1 truncate"
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              } else if (hasChildren) {
                onToggle(item.id);
              }
            }}
          >
            {item.title || item.name}
          </span>
        </div>

        {/* Sous-items */}
        {hasChildren && isExpanded && (
          <div className={`ml-4 pl-2 border-l-2 border-${moduleColor.light}/20 dark:border-${moduleColor.dark}/20`}>
            {item.children.map((child, index) => (
              <TreeNode
                key={child.id}
                item={child}
                level={level + 1}
                basePath={itemPath}
                expandedItems={expandedItems}
                onToggle={onToggle}
                activeItem={activeItem}
                isLastChild={index === item.children.length - 1}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Sidebar = ({ features = [], isOpen = true, onToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedItems, setExpandedItems] = useState({});
  const navRef = useRef(null);

  const organizeFeatures = () => {
    const modules = {};
    
    features.forEach(feature => {
      const [mainModule, ...subModules] = feature.module.split('/');
      
      if (!modules[mainModule]) {
        modules[mainModule] = {
          id: mainModule,
          title: mainModule.charAt(0).toUpperCase() + mainModule.slice(1),
          children: []
        };
      }

      let currentLevel = modules[mainModule];
      
      for (let i = 0; i < subModules.length; i++) {
        const subModule = subModules[i];
        let subModuleObj = currentLevel.children.find(child => child.id === subModule);
        
        if (!subModuleObj) {
          subModuleObj = {
            id: subModule,
            title: subModule.charAt(0).toUpperCase() + subModule.slice(1),
            children: []
          };
          currentLevel.children.push(subModuleObj);
        }
        
        currentLevel = subModuleObj;
      }

      // Formatage du nom de la feature pour une meilleure lisibilité
      let featureName = feature.name.replace(/\[[^\]]+\]/g, ''); // Supprime les [tags]
      featureName = featureName.replace(/([A-Z])/g, ' $1').trim(); // Ajoute des espaces avant les majuscules
      featureName = featureName.charAt(0).toUpperCase() + featureName.slice(1); // Première lettre en majuscule

      currentLevel.children.push({
        id: feature.name,
        title: featureName,
        path: `/${feature.module}/${feature.name}`.toLowerCase(),
        onClick: () => navigate(`/${feature.module}/${feature.name}`.toLowerCase())
      });
    });

    return Object.values(modules);
  };

  const toggleItem = (itemId) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  const expandAll = () => {
    const allExpanded = {};
    const expandModule = (items) => {
      items.forEach(item => {
        allExpanded[item.id] = true;
        if (item.children) {
          expandModule(item.children);
        }
      });
    };
    expandModule(menuItems);
    setExpandedItems(allExpanded);
  };

  const collapseAll = () => {
    setExpandedItems({});
  };

  const filterItems = (items, searchTerm) => {
    if (!searchTerm) return items;

    return items.reduce((acc, item) => {
      const matchesSearch = item.title.toLowerCase().includes(searchTerm.toLowerCase());
      
      if (item.children) {
        const filteredChildren = filterItems(item.children, searchTerm);
        if (matchesSearch || filteredChildren.length > 0) {
          acc.push({
            ...item,
            children: filteredChildren
          });
        }
      } else if (matchesSearch) {
        acc.push(item);
      }
      
      return acc;
    }, []);
  };

  const menuItems = filterItems(organizeFeatures(), searchTerm);

  // Auto-expansion des sections active
  useEffect(() => {
    if (location.pathname) {
      const parts = location.pathname.split('/').filter(Boolean);
      if (parts.length > 0) {
        // Étendre automatiquement le module actif
        setExpandedItems(prev => ({
          ...prev,
          [parts[0]]: true
        }));
      }
    }
  }, [location.pathname]);

  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-900 border-r border-gray-200/80 dark:border-gray-800/80 shadow-md">
      {/* En-tête avec recherche */}
      <div className="flex-shrink-0 p-4 border-b border-gray-200 dark:border-gray-800 bg-gradient-to-r from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-bold text-gray-900 dark:text-white flex items-center">
            <span className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-2 py-1 rounded text-xs mr-2 shadow-sm">MP</span>
            MXPAY
          </h2>
          <div className="flex space-x-1">
            <button 
              onClick={expandAll}
              className="p-1.5 bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded text-gray-500 shadow-sm transition-all duration-200"
              title="Déplier tout"
            >
              <ChevronDown size={14} />
            </button>
            <button 
              onClick={collapseAll}
              className="p-1.5 bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded text-gray-500 shadow-sm transition-all duration-200"
              title="Replier tout"
            >
              <ChevronRight size={14} />
            </button>
          </div>
        </div>

        <div className="relative">
          <Search className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-10 py-2.5 text-sm bg-white dark:bg-gray-800 
                     border border-gray-200 dark:border-gray-700 rounded-lg
                     focus:outline-none focus:ring-2 focus:ring-blue-500
                     text-gray-900 dark:text-white shadow-sm"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute right-3 top-3 text-gray-400 hover:text-gray-600"
            >
              <X size={14} />
            </button>
          )}
        </div>
      </div>

      {/* PARTIE PRINCIPALE MODIFIÉE - Container avec défilement */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Navigation principale avec défilement */}
        <nav 
          ref={navRef} 
          className="flex-1 h-0 overflow-y-auto py-3 px-3 scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-700"
        >
          <div className="space-y-1.5 pb-20"> {/* Padding en bas pour éviter que le contenu soit masqué par le footer */}
            {menuItems.map((item, index) => (
              <TreeNode
                key={item.id}
                item={item}
                expandedItems={expandedItems}
                onToggle={toggleItem}
                activeItem={location.pathname}
                isLastChild={index === menuItems.length - 1}
              />
            ))}

            {menuItems.length === 0 && searchTerm && (
              <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                Aucun résultat pour "{searchTerm}"
              </div>
            )}
          </div>
        </nav>
      </div>

      {/* Pied de la barre latérale */}
      <div className="flex-shrink-0 p-4 border-t border-gray-200 dark:border-gray-800 bg-gradient-to-r from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
        <div className="flex items-center gap-3 px-2">
          <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-700 shadow-sm">
            <Info size={14} className="text-gray-600 dark:text-gray-400" />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-700 dark:text-gray-300 truncate">
              Version 1.3.2
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
              © 2025 KG-TECHNOLOGIES
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;