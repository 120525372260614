// AuthPage.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Eye, EyeOff, Lock, User, AlertCircle } from 'lucide-react';
import apiService from '../../services/api.service';

const AuthPage = () => {
 const navigate = useNavigate();
 const location = useLocation();
 const [formData, setFormData] = useState({
   login: '',
   password: ''
 });
 const [showPassword, setShowPassword] = useState(false);
 const [error, setError] = useState('');
 const [isLoading, setIsLoading] = useState(false);

 const handleChange = (e) => {
   const { name, value } = e.target;
   setFormData(prev => ({...prev, [name]: value }));
 };

const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setIsLoading(true);

  try {
    const response = await apiService.auth.login({
      username: formData.login,
      password: formData.password
    });

    if (response.accessToken) {
      navigate(location.state?.from || '/');
    } else {
      setError('Erreur d\'authentification');
    }
  } catch (error) {
    console.error('Login error:', error);
    setError(error.response?.data?.message || 'Identifiants invalides');
  } finally {
    setIsLoading(false);
  }
};

 return (
   <div className="min-h-screen flex flex-col justify-center bg-gray-50 dark:bg-gray-900 py-12 sm:px-6 lg:px-8">
     <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
       <div className="mx-auto h-12 w-12 rounded-xl bg-gray-900 dark:bg-gray-800 flex items-center justify-center">
         <span className="text-2xl font-bold text-white">MP</span>
       </div>
       <h2 className="mt-6 text-3xl font-bold text-gray-900 dark:text-white">
         MXPAY
       </h2>
       <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
         Connectez-vous à votre compte pour continuer
       </p>
     </div>

     <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
       <div className="bg-white dark:bg-gray-800 py-8 px-4 shadow-lg rounded-lg sm:px-10">
         <form className="space-y-6" onSubmit={handleSubmit}>
           {error && (
             <div className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-900/10 dark:text-red-400">
               <AlertCircle className="w-4 h-4 mr-2" />
               {error}
             </div>
           )}

           <div>
             <label htmlFor="login" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
               Identifiant
             </label>
             <div className="mt-1 relative">
               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                 <User className="h-5 w-5 text-gray-400" />
               </div>
               <input
                 id="login"
                 name="login"
                 type="text"
                 autoComplete="username"
                 required
                 value={formData.login}
                 onChange={handleChange}
                 className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500
                          bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                 placeholder="Nom d'utilisateur"
               />
             </div>
           </div>

           <div>
             <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
               Mot de passe
             </label>
             <div className="mt-1 relative">
               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                 <Lock className="h-5 w-5 text-gray-400" />
               </div>
               <input
                 id="password"
                 name="password"
                 type={showPassword ? "text" : "password"}
                 autoComplete="current-password"
                 required
                 value={formData.password}
                 onChange={handleChange}
                 className="appearance-none block w-full pl-10 pr-10 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500
                          bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                 placeholder="••••••••"
               />
               <button
                 type="button"
                 className="absolute inset-y-0 right-0 pr-3 flex items-center"
                 onClick={() => setShowPassword(!showPassword)}
               >
                 {showPassword ? (
                   <EyeOff className="h-5 w-5 text-gray-400" />
                 ) : (
                   <Eye className="h-5 w-5 text-gray-400" />
                 )}
               </button>
             </div>
           </div>

           <div className="flex items-center justify-between">
             <div className="flex items-center">
               <input
                 id="remember-me"
                 name="remember-me"
                 type="checkbox"
                 className="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded"
               />
               <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                 Se souvenir de moi
               </label>
             </div>

             <div>
               <a href="#" className="text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-500">
                 Mot de passe oublié ?
               </a>
             </div>
           </div>

           <div>
             <button
               type="submit"
               disabled={isLoading}
               className="w-full flex justify-center py-2.5 px-4 border border-transparent rounded-lg
                        text-sm font-medium text-white bg-gray-900 hover:bg-gray-800
                        dark:bg-gray-700 dark:hover:bg-gray-600
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                        disabled:opacity-50 disabled:cursor-not-allowed
                        transition-colors duration-200"
             >
               {isLoading ? (
                 <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                   <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                   <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                 </svg>
               ) : (
                 'Se connecter'
               )}
             </button>
           </div>
         </form>
       </div>
     </div>
   </div>
 );
};

export default AuthPage;