import React from 'react';
import { ChevronRight, X } from 'lucide-react';

const Card = ({
  children,
  title,
  subtitle,
  icon: Icon,
  actions,
  footer,
  collapsible = false,
  closeable = false,
  noPadding = false,
  variant = 'default',
  className = '',
  onClose,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  // Base styles for card variants
  const variants = {
    default: "bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700",
    primary: "bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800",
    success: "bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800",
    warning: "bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800",
    danger: "bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800",
    ghost: "bg-transparent border border-gray-200 dark:border-gray-700",
    flat: "bg-gray-50 dark:bg-gray-900/50"
  };

  // Header icon variants
  const iconVariants = {
    default: "text-gray-500 dark:text-gray-400",
    primary: "text-blue-500 dark:text-blue-400",
    success: "text-green-500 dark:text-green-400",
    warning: "text-yellow-500 dark:text-yellow-400",
    danger: "text-red-500 dark:text-red-400",
    ghost: "text-gray-500 dark:text-gray-400",
    flat: "text-gray-500 dark:text-gray-400"
  };

  return (
    <div 
      className={`
        rounded-lg shadow-sm overflow-hidden transition-all duration-200
        hover:shadow-md
        ${variants[variant]}
        ${className}
      `}
      {...props}
    >
      {/* Card Header */}
      {(title || subtitle || Icon || actions || closeable) && (
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-3">
            {Icon && (
              <div className="flex-shrink-0">
                <Icon 
                  size={24} 
                  className={iconVariants[variant]}
                />
              </div>
            )}
            <div>
              {title && (
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {title}
                </h3>
              )}
              {subtitle && (
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {subtitle}
                </p>
              )}
            </div>
          </div>
          
          <div className="flex items-center space-x-2">
            {actions}
            {collapsible && (
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full
                         transition-colors duration-200"
              >
                <ChevronRight
                  size={20}
                  className={`transform transition-transform duration-200 
                    ${isCollapsed ? 'rotate-90' : ''}
                    text-gray-500 dark:text-gray-400`}
                />
              </button>
            )}
            {closeable && (
              <button
                onClick={onClose}
                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full
                         transition-colors duration-200"
              >
                <X 
                  size={20}
                  className="text-gray-500 dark:text-gray-400" 
                />
              </button>
            )}
          </div>
        </div>
      )}

      {/* Card Content */}
      <div
        className={`
          transition-all duration-200 ease-in-out
          ${isCollapsed ? 'h-0 overflow-hidden' : 'h-auto'}
          ${noPadding ? '' : 'p-6'}
          ${variant === 'primary' && 'text-blue-900 dark:text-blue-100'}
          ${variant === 'success' && 'text-green-900 dark:text-green-100'}
          ${variant === 'warning' && 'text-yellow-900 dark:text-yellow-100'}
          ${variant === 'danger' && 'text-red-900 dark:text-red-100'}
          ${variant === 'default' && 'text-gray-900 dark:text-gray-100'}
          ${variant === 'ghost' && 'text-gray-900 dark:text-gray-100'}
          ${variant === 'flat' && 'text-gray-900 dark:text-gray-100'}
        `}
      >
        {children}
      </div>

      {/* Card Footer */}
      {footer && (
        <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700/50 border-t border-gray-200 dark:border-gray-700">
          {footer}
        </div>
      )}
    </div>
  );
};

// Constants pour l'autocomplétion
Card.variants = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  GHOST: 'ghost',
  FLAT: 'flat'
};

export default Card;