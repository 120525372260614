import React, { useState, useEffect } from 'react';
import { 
  Database, 
  Sliders, 
  Save, 
  CheckCircle, 
  AlertTriangle,
  X,
  RefreshCw,
  Lock,
  Unlock,
  ArrowLeft,
  List,
  Edit,
  ExternalLink
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const ConfigValues = () => {
  // États
  const [environments, setEnvironments] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [configValues, setConfigValues] = useState({});
  const [editableValues, setEditableValues] = useState({});
  const [selectedEnv, setSelectedEnv] = useState('');
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [savingKey, setSavingKey] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [bulkMode, setBulkMode] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  
  // État pour le suivi des paramètres de l'URL
  const [urlParams, setUrlParams] = useState({
    key: null, 
    env: null
  });
  
  // Chargement des données
  const fetchEnvironments = async () => {
    try {
      const data = await apiService.config.environments.getAll();
      setEnvironments(data || []);
      
      // Sélectionner le premier environnement actif par défaut, sauf si l'URL en spécifie un
      if (!selectedEnv && data && data.length > 0) {
        const activeEnv = data.find(env => env.isActive);
        const envToSelect = urlParams.env || (activeEnv ? activeEnv.code : data[0].code);
        setSelectedEnv(envToSelect);
      }
    } catch (err) {
      console.error('Erreur lors du chargement des environnements:', err);
      setError('Impossible de charger les environnements.');
    }
  };
  
  const fetchConfigurations = async () => {
    try {
      const data = await apiService.config.configurations.getAll();
      setConfigurations(data || []);
      
      // Si une clé est spécifiée dans l'URL, sélectionner cette configuration
      if (urlParams.key && data) {
        const config = data.find(c => c.key === urlParams.key);
        if (config) {
          setSelectedConfig(config);
        }
      }
    } catch (err) {
      console.error('Erreur lors du chargement des configurations:', err);
      setError('Impossible de charger les configurations.');
    }
  };
  
  const fetchConfigValue = async (key, envCode) => {
    if (!key || !envCode) return;
    
    try {
      const value = await apiService.config.configurations.getValue(key, envCode);
      setConfigValues(prev => ({
        ...prev,
        [key]: value
      }));
      
      setEditableValues(prev => ({
        ...prev,
        [key]: value
      }));
      
      return value;
    } catch (err) {
      console.error(`Erreur lors du chargement de la valeur pour ${key}:`, err);
      return null;
    }
  };
  
  const fetchSelectedConfigValues = async () => {
    if (!selectedEnv) return;
    
    if (selectedConfig) {
      await fetchConfigValue(selectedConfig.key, selectedEnv);
    } else if (bulkMode && selectedKeys.length > 0) {
      // En mode bulk, charger les valeurs pour toutes les clés sélectionnées
      const promises = selectedKeys.map(key => fetchConfigValue(key, selectedEnv));
      await Promise.all(promises);
    }
  };
  
  // Extraction des paramètres de l'URL au chargement
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get('key');
    const env = queryParams.get('env');
    
    setUrlParams({ key, env });
  }, []);
  
  // Chargement initial des données
  useEffect(() => {
    setLoading(true);
    
    Promise.all([
      fetchEnvironments(),
      fetchConfigurations()
    ]).finally(() => setLoading(false));
  }, []);
  
  // Charger les valeurs de configuration lorsque l'environnement ou la configuration change
  useEffect(() => {
    if (selectedEnv) {
      fetchSelectedConfigValues();
    }
  }, [selectedEnv, selectedConfig, selectedKeys]);
  
  // Gestionnaires d'événements
  const handleSelectEnvironment = (envCode) => {
    setSelectedEnv(envCode);
  };
  
  const handleSelectConfiguration = (config) => {
    setSelectedConfig(config);
    setBulkMode(false);
    setSelectedKeys([]);
    setIsEditMode(false);
    
    // Mettre à jour l'URL sans recharger la page
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('key', config.key);
    if (selectedEnv) {
      newUrl.searchParams.set('env', selectedEnv);
    }
    window.history.pushState({}, '', newUrl);
  };
  
  const handleToggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  
  const handleValueChange = (key, value) => {
    setEditableValues(prev => ({
      ...prev,
      [key]: value
    }));
  };
  
  const handleSaveValue = async (key) => {
    if (!key || !selectedEnv) return;
    
    setSavingKey(key);
    
    try {
      await apiService.config.configurations.setValue(key, editableValues[key], selectedEnv);
      
      // Mettre à jour la valeur dans l'état local
      setConfigValues(prev => ({
        ...prev,
        [key]: editableValues[key]
      }));
      
      setSuccessMessage(`Valeur pour "${key}" enregistrée avec succès`);
      
      // Cacher le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error(`Erreur lors de l'enregistrement de la valeur pour ${key}:`, err);
      setError(`Impossible d'enregistrer la valeur pour "${key}". Veuillez réessayer.`);
    } finally {
      setSavingKey(null);
    }
  };
  
  const handleToggleBulkMode = () => {
    setBulkMode(!bulkMode);
    setSelectedConfig(null);
    setSelectedKeys([]);
    setIsEditMode(false);
    
    // Nettoyer l'URL
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete('key');
    window.history.pushState({}, '', newUrl);
  };
  
  const handleToggleSelectKey = (key) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(selectedKeys.filter(k => k !== key));
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
  };
  
  const filteredConfigurations = configurations.filter(config => {
    const searchLower = searchTerm.toLowerCase();
    return (
      config.key.toLowerCase().includes(searchLower) ||
      (config.description && config.description.toLowerCase().includes(searchLower)) ||
      (config.group && config.group.name.toLowerCase().includes(searchLower))
    );
  });
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Sliders className="mr-2 h-6 w-6 text-blue-500" />
            Valeurs de Configuration
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les valeurs de configuration pour chaque environnement
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={() => {
              fetchEnvironments();
              fetchConfigurations();
              fetchSelectedConfigValues();
            }}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant={bulkMode ? "primary" : "outline"}
            icon={List}
            onClick={handleToggleBulkMode}
          >
            {bulkMode ? "Mode Bulk Actif" : "Mode Bulk"}
          </Button>
        </div>
      </div>
      
      {/* Messages */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 mb-4">
          <div className="flex">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <span className="text-green-700 dark:text-green-300">{successMessage}</span>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 mb-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-red-700 dark:text-red-300">{error}</span>
          </div>
        </div>
      )}
      
      {/* Section principale */}
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        {/* Sélection de l'environnement */}
        <div className="lg:col-span-12">
          <Card>
            <div className="p-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                Sélectionner un environnement
              </h3>
              <div className="flex flex-wrap gap-2">
                {environments.map((env) => (
                  <Button
                    key={env.code}
                    variant={selectedEnv === env.code ? "primary" : "outline"}
                    size="sm"
                    onClick={() => handleSelectEnvironment(env.code)}
                    disabled={!env.isActive}
                  >
                    {env.name} ({env.code})
                    {!env.isActive && <Lock className="ml-1 h-3 w-3" />}
                  </Button>
                ))}
              </div>
            </div>
          </Card>
        </div>
        
        {/* Liste des configurations */}
        <div className="lg:col-span-4">
          <Card>
            <div className="p-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                Configurations
              </h3>
              
              {/* Recherche */}
              <div className="mb-4">
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  placeholder="Rechercher..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              {/* Liste */}
              <div className="h-[calc(100vh-350px)] overflow-y-auto pr-2">
                {bulkMode ? (
                  <div className="space-y-2">
                    {filteredConfigurations.map((config) => (
                      <div 
                        key={config.key}
                        className={`
                          flex items-center justify-between p-3 rounded-md cursor-pointer
                          ${selectedKeys.includes(config.key) 
                            ? 'bg-blue-50 dark:bg-blue-900/20 border-blue-200 dark:border-blue-800' 
                            : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50'}
                          border border-gray-200 dark:border-gray-700
                        `}
                        onClick={() => handleToggleSelectKey(config.key)}
                      >
                        <div className="flex items-center">
                          <input 
                            type="checkbox" 
                            checked={selectedKeys.includes(config.key)}
                            onChange={() => {}}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 font-medium text-gray-900 dark:text-white">
                            {config.key}
                          </span>
                        </div>
                        {config.group && (
                          <span className="text-xs text-gray-500 dark:text-gray-400 bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">
                            {config.group.name}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="space-y-2">
                    {filteredConfigurations.map((config) => (
                      <div 
                        key={config.key}
                        className={`
                          p-3 rounded-md cursor-pointer
                          ${selectedConfig && selectedConfig.key === config.key 
                            ? 'bg-blue-50 dark:bg-blue-900/20 border-blue-200 dark:border-blue-800' 
                            : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50'}
                          border border-gray-200 dark:border-gray-700
                        `}
                        onClick={() => handleSelectConfiguration(config)}
                      >
                        <div className="font-medium text-gray-900 dark:text-white">
                          {config.key}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                          {config.description}
                        </div>
                        {config.group && (
                          <div className="flex items-center mt-2">
                            <span className="text-xs text-gray-500 dark:text-gray-400 bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">
                              {config.group.name}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
        
        {/* Détails de la configuration et valeurs */}
        <div className="lg:col-span-8">
          <Card>
            <div className="p-4">
              {bulkMode && selectedKeys.length > 0 ? (
                <>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      Édition en masse ({selectedKeys.length} configurations)
                    </h3>
                    <div className="flex space-x-2">
                      <Button
                        variant={isEditMode ? "primary" : "outline"}
                        size="sm"
                        icon={isEditMode ? Edit : Unlock}
                        onClick={handleToggleEditMode}
                      >
                        {isEditMode ? "Mode édition" : "Déverrouiller l'édition"}
                      </Button>
                    </div>
                  </div>
                  
                  <div className="space-y-4">
                    {selectedKeys.map(key => {
                      const config = configurations.find(c => c.key === key);
                      const currentValue = configValues[key] || '';
                      const editValue = editableValues[key] || '';
                      const isSaving = savingKey === key;
                      
                      return (
                        <div key={key} className="border border-gray-200 dark:border-gray-700 rounded-md p-4">
                          <div className="flex justify-between items-start mb-2">
                            <div>
                              <h4 className="font-medium text-gray-900 dark:text-white">{key}</h4>
                              {config && <p className="text-sm text-gray-500 dark:text-gray-400">{config.description}</p>}
                            </div>
                            {isEditMode && (
                              <Button
                                variant="primary"
                                size="sm"
                                icon={Save}
                                onClick={() => handleSaveValue(key)}
                                loading={isSaving}
                              >
                                Enregistrer
                              </Button>
                            )}
                          </div>
                          
                          <div>
                            {isEditMode ? (
                              <textarea
                                value={editValue}
                                onChange={(e) => handleValueChange(key, e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                                rows={3}
                              />
                            ) : (
                              <pre className="bg-gray-50 dark:bg-gray-900/50 p-3 rounded-md overflow-x-auto text-gray-800 dark:text-gray-200">
                                {currentValue || 'Non défini'}
                              </pre>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : selectedConfig ? (
                <>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      Configuration: {selectedConfig.key}
                    </h3>
                    <div className="flex space-x-2">
                      <Button
                        variant="outline"
                        size="sm"
                        icon={ArrowLeft}
                        onClick={() => {
                          setSelectedConfig(null);
                          // Nettoyer l'URL
                          const newUrl = new URL(window.location);
                          newUrl.searchParams.delete('key');
                          window.history.pushState({}, '', newUrl);
                        }}
                      >
                        Retour
                      </Button>
                      <Button
                        variant={isEditMode ? "primary" : "outline"}
                        size="sm"
                        icon={isEditMode ? Edit : Unlock}
                        onClick={handleToggleEditMode}
                      >
                        {isEditMode ? "Mode édition" : "Déverrouiller l'édition"}
                      </Button>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Description</p>
                      <p className="text-gray-900 dark:text-white">{selectedConfig.description}</p>
                    </div>
                    
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Groupe</p>
                      <p className="text-gray-900 dark:text-white">
                        {selectedConfig.group ? selectedConfig.group.name : 'Aucun groupe'}
                      </p>
                    </div>
                    
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Portée</p>
                      <StatusBadge 
                        status={
                          selectedConfig.scope === 'GLOBAL' ? 'blue' :
                          selectedConfig.scope === 'ENVIRONMENT' ? 'green' :
                          selectedConfig.scope === 'TENANT' ? 'purple' :
                          selectedConfig.scope === 'APPLICATION' ? 'orange' : 'gray'
                        } 
                        customLabel={selectedConfig.scope}
                      />
                    </div>
                    
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Statut</p>
                      <StatusBadge 
                        status={selectedConfig.isActive ? 'active' : 'inactive'} 
                        customLabel={selectedConfig.isActive ? 'Actif' : 'Inactif'}
                      />
                    </div>
                  </div>
                  
                  <div className="mt-6">
                    <h4 className="text-md font-medium text-gray-900 dark:text-white mb-4">
                      Valeur pour l'environnement: <span className="text-blue-600 dark:text-blue-400">{selectedEnv}</span>
                    </h4>
                    
                    <div className="space-y-4">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Valeur par défaut: {selectedConfig.defaultValue || 'Non définie'}
                          </p>
                        </div>
                        
                        {isEditMode && (
                          <Button
                            variant="primary"
                            size="sm"
                            icon={Save}
                            onClick={() => handleSaveValue(selectedConfig.key)}
                            loading={savingKey === selectedConfig.key}
                          >
                            Enregistrer
                          </Button>
                        )}
                      </div>
                      
                      {isEditMode ? (
                        <textarea
                          value={editableValues[selectedConfig.key] || ''}
                          onChange={(e) => handleValueChange(selectedConfig.key, e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                          rows={8}
                          placeholder={selectedConfig.defaultValue || 'Entrer une valeur...'}
                        />
                      ) : (
                        <div className="bg-gray-50 dark:bg-gray-900/50 p-4 rounded-md">
                          <pre className="whitespace-pre-wrap overflow-x-auto text-gray-800 dark:text-gray-200">
                            {configValues[selectedConfig.key] || 'Non défini pour cet environnement'}
                          </pre>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex flex-col items-center justify-center py-12">
                  <Sliders className="h-12 w-12 text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Aucune configuration sélectionnée
                  </h3>
                  <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
                    {bulkMode 
                      ? "Sélectionnez une ou plusieurs configurations à gauche pour modifier leurs valeurs en masse."
                      : "Sélectionnez une configuration à gauche pour voir et modifier sa valeur."
                    }
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ConfigValues;