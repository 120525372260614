import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Settings, RefreshCw, Download, Filter,
  Cpu,  HardDrive, Activity
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Imports corrects des composants partagés
import Card from '../../components/shared/Card';
import DataGrid from '../../components/shared/DataGrid';
import Button from '../../components/shared/Button';
import DateFormatter from '../../components/shared/DateFormatter';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const MetricThresholdModal = ({ isOpen, onClose, onSubmit, currentThresholds }) => {
  const [thresholds, setThresholds] = useState({
    metricName: '',
    warningThreshold: 80,
    criticalThreshold: 90,
    evaluationType: 'IMMEDIATE',
    evaluationPeriod: 5
  });

  useEffect(() => {
    if (currentThresholds) {
      setThresholds(currentThresholds);
    }
  }, [currentThresholds]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(thresholds);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <Card className="w-full max-w-lg">
        <form onSubmit={handleSubmit} className="space-y-4 p-6">
          <h2 className="text-xl font-bold mb-4">Configuration des seuils</h2>
          
          <div>
            <label className="block mb-2">Métrique</label>
            <select
              value={thresholds.metricName}
              onChange={(e) => setThresholds({...thresholds, metricName: e.target.value})}
              className="w-full p-2 border rounded"
            >
              <option value="CPU_USAGE">Utilisation CPU</option>
              <option value="MEMORY_USAGE">Utilisation Mémoire</option>
              <option value="DISK_USAGE">Utilisation Disque</option>
            </select>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Seuil d'avertissement (%)</label>
              <input
                type="number"
                value={thresholds.warningThreshold}
                onChange={(e) => setThresholds({...thresholds, warningThreshold: Number(e.target.value)})}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-2">Seuil critique (%)</label>
              <input
                type="number"
                value={thresholds.criticalThreshold}
                onChange={(e) => setThresholds({...thresholds, criticalThreshold: Number(e.target.value)})}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Type d'évaluation</label>
              <select
                value={thresholds.evaluationType}
                onChange={(e) => setThresholds({...thresholds, evaluationType: e.target.value})}
                className="w-full p-2 border rounded"
              >
                <option value="IMMEDIATE">Immédiat</option>
                <option value="AVERAGE">Moyenne</option>
                <option value="TREND">Tendance</option>
              </select>
            </div>
            <div>
              <label className="block mb-2">Période (minutes)</label>
              <input
                type="number"
                value={thresholds.evaluationPeriod}
                onChange={(e) => setThresholds({...thresholds, evaluationPeriod: Number(e.target.value)})}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <Button variant="secondary" onClick={onClose}>Annuler</Button>
            <Button type="submit" variant="primary">Enregistrer</Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

const MetricChart = ({ data, dataKey, color }) => (
  <ResponsiveContainer width="100%" height={200}>
    <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis 
        dataKey="timestamp" 
        tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
      />
      <YAxis />
      <Tooltip 
        labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
      />
      <Line 
        type="monotone" 
        dataKey={dataKey} 
        stroke={color} 
        strokeWidth={2}
        dot={false}
      />
    </LineChart>
  </ResponsiveContainer>
);

const SystemMetrics = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState({
    startTime: new Date(Date.now() - 3600000).toISOString(), // Last hour
    endTime: new Date().toISOString()
  });
  const [showThresholdModal, setShowThresholdModal] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(null);

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchMetrics();
  }, [navigate, timeRange]);

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const response = await apiService.monitoring.metrics.system.getAll(
        timeRange.startTime,
        timeRange.endTime
      );
      setMetrics(response);
      setError(null);
    } catch (err) {
      setError(`Erreur lors de la récupération des métriques : ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleThresholdSubmit = async (thresholdData) => {
    try {
      await apiService.monitoring.metrics.thresholds.set(thresholdData);
      // Rafraîchir les données après la mise à jour des seuils
      fetchMetrics();
    } catch (err) {
      setError(`Erreur lors de la mise à jour des seuils : ${err.message}`);
    }
  };

  const columns = [
    {
      key: 'timestamp',
      header: 'Horodatage',
      render: (row) => <DateFormatter date={row.timestamp} format="full" />
    },
    {
      key: 'cpuUsage',
      header: 'CPU (%)',
      render: (row) => row.cpuUsage?.toFixed(2)
    },
    {
      key: 'memoryUsage',
      header: 'Mémoire (%)',
      render: (row) => row.memoryUsage?.toFixed(2)
    },
    {
      key: 'diskUsage',
      header: 'Disque (%)',
      render: (row) => row.diskUsage?.toFixed(2)
    },
    {
      key: 'threadCount',
      header: 'Threads',
      render: (row) => row.threadCount
    },
    {
      key: 'loadAverage',
      header: 'Charge',
      render: (row) => row.loadAverage?.toFixed(2)
    }
  ];

  const timeRangeOptions = [
    { value: '1h', label: 'Dernière heure', ms: 3600000 },
    { value: '6h', label: '6 heures', ms: 21600000 },
    { value: '24h', label: '24 heures', ms: 86400000 }
  ];

  const updateTimeRange = (ms) => {
    setTimeRange({
      startTime: new Date(Date.now() - ms).toISOString(),
      endTime: new Date().toISOString()
    });
  };

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Métriques Système
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Analyse détaillée des performances système
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            value={timeRangeOptions.find(opt => opt.ms === (new Date(timeRange.endTime) - new Date(timeRange.startTime)))?.value}
            onChange={(e) => updateTimeRange(timeRangeOptions.find(opt => opt.value === e.target.value).ms)}
            className="rounded-lg border border-gray-300 p-2"
          >
            {timeRangeOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <Button
            variant="outline"
            icon={Settings}
            onClick={() => setShowThresholdModal(true)}
          >
            Configurer les seuils
          </Button>
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchMetrics}
          >
            Actualiser
          </Button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      {/* Graphiques */}
      <div className="grid grid-cols-2 gap-6">
        <Card>
          <div className="p-4">
            <div className="flex items-center space-x-2 mb-4">
              <Cpu className="h-5 w-5 text-blue-500" />
              <h3 className="font-semibold">Utilisation CPU</h3>
            </div>
            <MetricChart data={metrics} dataKey="cpuUsage" color="#3B82F6" />
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <div className="flex items-center space-x-2 mb-4">
              <HardDrive className="h-5 w-5 text-green-500" />
              <h3 className="font-semibold">Utilisation Mémoire</h3>
            </div>
            <MetricChart data={metrics} dataKey="memoryUsage" color="#10B981" />
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <div className="flex items-center space-x-2 mb-4">
              <HardDrive className="h-5 w-5 text-purple-500" />
              <h3 className="font-semibold">Utilisation Disque</h3>
            </div>
            <MetricChart data={metrics} dataKey="diskUsage" color="#8B5CF6" />
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <div className="flex items-center space-x-2 mb-4">
              <Activity className="h-5 w-5 text-orange-500" />
              <h3 className="font-semibold">Charge Système</h3>
            </div>
            <MetricChart data={metrics} dataKey="loadAverage" color="#F97316" />
          </div>
        </Card>
      </div>

      {/* Tableau des données */}
      <Card>
        <div className="p-4">
          <h3 className="font-semibold mb-4">Historique détaillé</h3>
          <DataGrid
            data={metrics}
            columns={columns}
            loading={loading}
            emptyMessage="Aucune donnée disponible pour la période sélectionnée"
          />
        </div>
      </Card>

      {/* Modal de configuration des seuils */}
      <MetricThresholdModal
        isOpen={showThresholdModal}
        onClose={() => setShowThresholdModal(false)}
        onSubmit={handleThresholdSubmit}
        currentThresholds={selectedMetric}
      />
    </div>
  );
};

export default SystemMetrics;