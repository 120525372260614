import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Database, Clock, Users, Activity,
  RefreshCw, Filter, BarChart2, 
  HardDrive, AlertCircle
} from 'lucide-react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import DateFormatter from '../../components/shared/DateFormatter';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const DatabaseMetrics = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDb, setSelectedDb] = useState('');
  const [timeRange, setTimeRange] = useState({
    startTime: new Date(Date.now() - 3600000).toISOString(),
    endTime: new Date().toISOString()
  });

  const databases = [
    { id: 'main-db', name: 'Base de données principale' },
    { id: 'reporting-db', name: 'Base de données reporting' }
  ];

  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    if (selectedDb) {
      fetchMetrics();
    }
  }, [navigate, selectedDb, timeRange]);

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const response = await apiService.monitoring.metrics.database.getAll(
        selectedDb,
        timeRange.startTime,
        timeRange.endTime
      );
      setMetrics(response);
      setError(null);
    } catch (err) {
      setError(`Erreur lors de la récupération des métriques : ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const timeRangeOptions = [
    { value: '1h', label: 'Dernière heure', ms: 3600000 },
    { value: '6h', label: '6 heures', ms: 21600000 },
    { value: '24h', label: '24 heures', ms: 86400000 }
  ];

  const updateTimeRange = (ms) => {
    setTimeRange({
      startTime: new Date(Date.now() - ms).toISOString(),
      endTime: new Date().toISOString()
    });
  };

  const getLatestMetrics = () => {
    if (!metrics.length) return null;
    return metrics[metrics.length - 1];
  };

  const latestMetrics = getLatestMetrics();

  const MetricCard = ({ title, value, unit, icon: Icon, status, trend }) => (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className={`p-3 rounded-lg ${
            status === 'warning' ? 'bg-yellow-100 text-yellow-600' :
            status === 'critical' ? 'bg-red-100 text-red-600' :
            'bg-blue-100 text-blue-600'
          }`}>
            <Icon size={24} />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">{title}</h3>
            <div className="flex items-baseline space-x-2">
              <p className="text-2xl font-semibold">{value}</p>
              {unit && <span className="text-sm text-gray-500">{unit}</span>}
            </div>
          </div>
        </div>
        {trend && (
          <div className={`flex items-center text-sm ${
            trend > 0 ? 'text-green-500' : 'text-red-500'
          }`}>
            {trend > 0 ? '+' : ''}{trend}%
          </div>
        )}
      </div>
    </Card>
  );

  const columns = [
    {
      key: 'timestamp',
      header: 'Horodatage',
      render: (row) => <DateFormatter date={row.timestamp} format="full" />
    },
    {
      key: 'metricName',
      header: 'Métrique',
      render: (row) => row.metricName
    },
    {
      key: 'connectionCount',
      header: 'Connexions',
      render: (row) => row.connectionCount
    },
    {
      key: 'queryResponseTime',
      header: 'Temps de réponse',
      render: (row) => `${row.queryResponseTime?.toFixed(2)} ms`
    },
    {
      key: 'activeTransactions',
      header: 'Transactions actives',
      render: (row) => row.activeTransactions
    }
  ];

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Métriques Base de Données
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Surveillance des performances des bases de données
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            value={selectedDb}
            onChange={(e) => setSelectedDb(e.target.value)}
            className="rounded-lg border border-gray-300 p-2"
          >
            <option value="">Sélectionner une base de données</option>
            {databases.map(db => (
              <option key={db.id} value={db.id}>{db.name}</option>
            ))}
          </select>

          <select
            value={timeRangeOptions.find(opt => opt.ms === (new Date(timeRange.endTime) - new Date(timeRange.startTime)))?.value}
            onChange={(e) => updateTimeRange(timeRangeOptions.find(opt => opt.value === e.target.value).ms)}
            className="rounded-lg border border-gray-300 p-2"
          >
            {timeRangeOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={fetchMetrics}
            disabled={!selectedDb}
          >
            Actualiser
          </Button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      {selectedDb ? (
        <>
          {/* Indicateurs clés */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <MetricCard
              title="Temps de réponse moyen"
              value={latestMetrics?.queryResponseTime?.toFixed(2) || '-'}
              unit="ms"
              icon={Clock}
              status={latestMetrics?.queryResponseTime > 1000 ? 'critical' : 
                     latestMetrics?.queryResponseTime > 500 ? 'warning' : 'normal'}
            />
            <MetricCard
              title="Connexions actives"
              value={latestMetrics?.connectionCount || '-'}
              icon={Users}
              status={latestMetrics?.connectionCount > 100 ? 'warning' : 'normal'}
            />
            <MetricCard
              title="Transactions actives"
              value={latestMetrics?.activeTransactions || '-'}
              icon={Activity}
              status={latestMetrics?.activeTransactions > 50 ? 'warning' : 'normal'}
            />
            <MetricCard
              title="Espace disque utilisé"
              value={latestMetrics?.diskUsage?.toFixed(1) || '-'}
              unit="%"
              icon={HardDrive}
              status={latestMetrics?.diskUsage > 90 ? 'critical' :
                     latestMetrics?.diskUsage > 80 ? 'warning' : 'normal'}
            />
          </div>

          {/* Graphiques de performance */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <Card>
              <div className="p-6">
                <h2 className="text-lg font-semibold mb-4">Temps de réponse des requêtes</h2>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={metrics}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="timestamp"
                        tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                      />
                      <YAxis />
                      <Tooltip 
                        labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                      />
                      <Line
                        type="monotone"
                        dataKey="queryResponseTime"
                        stroke="#3B82F6"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Card>

            <Card>
              <div className="p-6">
                <h2 className="text-lg font-semibold mb-4">Connexions et transactions</h2>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={metrics}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="timestamp"
                        tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                      />
                      <YAxis />
                      <Tooltip
                        labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                      />
                      <Bar dataKey="connectionCount" fill="#3B82F6" name="Connexions" />
                      <Bar dataKey="activeTransactions" fill="#10B981" name="Transactions" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Card>
          </div>

          {/* Tableau des métriques détaillées */}
          <Card>
            <div className="p-6">
              <h2 className="text-lg font-semibold mb-4">Historique détaillé</h2>
              <DataGrid
                data={metrics}
                columns={columns}
                loading={loading}
                emptyMessage="Aucune donnée disponible pour la période sélectionnée"
              />
            </div>
          </Card>
        </>
      ) : (
        <Card>
          <div className="p-6 text-center">
            <Database className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Aucune base de données sélectionnée</h3>
            <p className="mt-1 text-sm text-gray-500">
              Sélectionnez une base de données pour voir ses métriques
            </p>
          </div>
        </Card>
      )}
    </div>
  );
};

export default DatabaseMetrics;